define("lh4/utils/validate-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Validate if selected type does not match any of the previous ones
   *
   * @param {Array<Object>} value - list of a type changeset to validate
   * @param {Array<Object>} valueTypes - options of types available
   * @param {string} validationType - value type to validate
   * @param {string} field - the name of the field to validate
   * @returns {void} - returns nothing if value is unique and if its not sets overlapErrorMessage
   */
  var validateType = function validateType(value, valueTypes, validationType) {
    var field = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'type';
    value.forEach(function (s) {
      return s.set('overlapErrorMessage', undefined);
    });

    for (var i = 0; i < value.length; i += 1) {
      var next = i + 1;

      var _loop = function _loop(j) {
        var valueToValidate1 = value[i];
        var valueToValidate2 = value[j];
        var getValueToValidate1Type = valueToValidate1.get(field);
        var getValueToValidate2Type = valueToValidate2.get(field);
        valueTypes.forEach(function () {
          if (!(getValueToValidate1Type && getValueToValidate2Type)) {
            return;
          }

          try {
            if (getValueToValidate2Type === getValueToValidate1Type) {
              switch (validationType) {
                case 'email':
                  valueToValidate2.set('overlapErrorMessage', "Can only have one Email of the same type \"".concat(getValueToValidate1Type, "\""));
                  break;

                case 'phone':
                  valueToValidate2.set('overlapErrorMessage', "Can only have one Phone of the same type \"".concat(getValueToValidate1Type, "\""));
                  break;

                case 'address':
                  valueToValidate2.set('overlapErrorMessage', "Can only have one Address of the same type \"".concat(getValueToValidate1Type, "\""));
                  break;

                default:
                  valueToValidate2.set('overlapErrorMessage', "Type \"".concat(getValueToValidate2Type, "\" overlaps type \"").concat(getValueToValidate1Type, "\""));
              }
            }
          } catch (error) {// Do nothing, types must be unique - validation fails
            // Helper throws because it can't determine the correct result
            // And ember validator will handle this
          }
        });
      };

      for (var j = next; j < value.length; j += 1) {
        _loop(j);
      }
    }
  };

  var _default = validateType;
  _exports.default = _default;
});