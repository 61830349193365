define("lh4/adapters/settings/user-permissions", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _application.default.extend({
    tokenData: Ember.inject.service(),
    namespace: 'api/v2',
    pathForType: function pathForType(type) {
      return type;
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType) {
      var _this$tokenData$data = this.tokenData.data,
          userId = _this$tokenData$data.sub,
          parentUserId = _this$tokenData$data.parentUserId;

      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      if (requestType === 'findRecord') {
        var model = "accounts/".concat(parentUserId || userId, "/sub-accounts/").concat(id);
        var recordId = 'permissions';
        return this._super.apply(this, [model, recordId, snapshot, requestType].concat(args));
      }

      return this._super.apply(this, [modelName, id, snapshot, requestType].concat(args));
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 4 ? _len2 - 4 : 0), _key2 = 4; _key2 < _len2; _key2++) {
        args[_key2 - 4] = arguments[_key2];
      }

      var response = this._super.apply(this, [status, headers, payload, requestData].concat(args));

      var locationPermissionsMap = (response.permissions || []).reduce(function (acc, _ref) {
        var locationId = _ref.locationId,
            userPermissionId = _ref.userPermissionId;

        if (!acc[locationId]) {
          acc[locationId] = [];
        }

        acc[locationId].push(parseInt(userPermissionId, 10));
        return acc;
      }, {});
      var permissionSets = Object.entries(locationPermissionsMap).map(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 2),
            locationId = _ref3[0],
            permissionIds = _ref3[1];

        return {
          locationId: parseInt(locationId, 10),
          permissions: permissionIds
        };
      });
      return {
        userPermissionMaps: response.permissions,
        permissionSets: permissionSets
      };
    }
  });

  _exports.default = _default;
});