define("lh4/adapters/settings/user-permission-template", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    ajaxOptions: function ajaxOptions(url, type, hash) {
      var options = this._super(url, type, hash);

      if (type === 'DELETE') {
        options.dataType = 'text';
      }

      return options;
    },
    buildURL: function buildURL(modelName, id) {
      return "".concat(this.host, "/api/v1/accounts/permissions/templates").concat(id === null ? '' : "/".concat(id));
    }
  });

  _exports.default = _default;
});