define("lh4/models/service", ["exports", "ember-data", "ember-data/attr", "ember-cp-validations"], function (_exports, _emberData, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Service name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    'configuration.decorations.name': {
      description: 'Online Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    'configuration.decorations.phone': (0, _emberCpValidations.validator)('phone-number', {
      message: 'Phone number is incorrect',
      allowBlank: true
    }),
    'configuration.decorations.email': (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'email',
      allowNonTld: true,
      message: 'Email format incorrect.'
    }),
    'configuration.decorations.address.addressLine1': {
      description: 'Address',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    'configuration.decorations.address.state': {
      description: 'State',
      validators: [(0, _emberCpValidations.validator)('region', {
        allowBlank: true
      })]
    },
    'configuration.decorations.address.city': {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    'configuration.decorations.address.zip': {
      description: 'Zip',
      validators: [(0, _emberCpValidations.validator)('zip-code', {
        allowBlank: true
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    configuration: (0, _attr.default)(),
    isActive: (0, _attr.default)('boolean'),
    name: (0, _attr.default)('string')
  });

  _exports.default = _default;
});