define("lh4/mirage/routes/echo-pro/advantage/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageEchoProAdvantageSettingsServer;

  function setMirageEchoProAdvantageSettingsServer(server) {
    var settings = [// Not Enrolled
    {
      mid: '0',
      status: 'INCOMPLETE',
      current: {}
    }, // Pending
    {
      mid: '1',
      status: 'PENDING',
      current: {
        advantageProgramPercent: '3.12%',
        advantageProgramType: 'SUPPLEMENTAL_FEE',
        options: {
          tipReductionEnabled: false,
          tipReductionPercent: '2.57%',
          receiptAppearance: 'Test receipt description'
        }
      },
      pending: {
        expectedConversionDate: '04/01/2024',
        pendingPercentage: '1.5%',
        pendingProgram: 'CASH_DISCOUNT'
      }
    }, // Enrolled with CASH_DISCOUNT
    {
      mid: '2',
      status: 'INCOMPLETE',
      current: {
        advantageProgramPercent: '1.5%',
        advantageProgramType: 'CASH_DISCOUNT',
        options: {
          tipReductionEnabled: true,
          tipReductionPercent: '3.44%',
          receiptAppearance: 'Test receipt description'
        }
      }
    }, // Enrolled with SUPPLEMENTAL_FEE
    {
      mid: '3',
      status: 'INCOMPLETE',
      current: {
        advantageProgramPercent: '1.5%',
        advantageProgramType: 'SUPPLEMENTAL_FEE',
        options: {
          tipReductionEnabled: true,
          tipReductionPercent: '3.44%',
          receiptAppearance: 'Test receipt description'
        }
      }
    }, // Enrolled with DUAL_PRICING
    {
      mid: '4',
      status: 'INCOMPLETE',
      current: {
        advantageProgramPercent: '1.5%',
        advantageProgramType: 'DUAL_PRICING',
        options: {
          tipReductionEnabled: true,
          tipReductionPercent: '3.44%',
          receiptAppearance: 'Test receipt description'
        }
      }
    }];
    server.get('/echo-pro/advantage/settings/:mid', function (_, _ref) {
      var params = _ref.params;
      return settings[params.mid] || settings[0];
    });
    server.put('/echo-pro/advantage/settings/:mid', function () {
      return {
        conversionDate: '04/01/2024',
        advantageProgramPercent: '4%',
        advantageProgramType: 'DUAL_PRICING'
      };
    });
  }
});