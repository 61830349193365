define("lh4/components/location-settings/bulk-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    corporation: Ember.inject.service(),
    classNames: ['form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    actions: {
      handleFieldSelectorGroupChange: function handleFieldSelectorGroupChange(field, included) {
        this.onFieldSelectorGroupChange(field, included);
      }
    }
  });

  _exports.default = _default;
});