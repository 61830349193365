define("lh4/templates/components/ui/form-fields/time-picker-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iwxNLRPd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"fieldClasses\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[4,\"ui/form-fields/shared/label\",null,[[\"fullWidth\",\"required\"],[[24,[\"fullWidth\"]],[24,[\"required\"]]]],{\"statements\":[[1,[22,\"label\"],false]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\\t\"],[1,[28,\"ui/time-picker/time-picker\",null,[[\"value\",\"classNames\",\"onChange\",\"rightAnchored\"],[[24,[\"value\"]],\"time-picker-column eight wide column\",[28,\"action\",[[23,0,[]],\"onChange\"],null],[24,[\"rightAnchored\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showErrorTooltip\"]]],null,{\"statements\":[[4,\"ui/popup/error-popup\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"get\",[[28,\"get\",[[24,[\"changeset\",\"error\"]],[24,[\"property\"]]],null],\"validation\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/time-picker-field.hbs"
    }
  });

  _exports.default = _default;
});