define("lh4/components/ui/s4/icons/invisible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  	<path d="M16.1959 5.64785C14.9857 5.22869 13.6862 5.00098 12.3333 5.00098C7.33325 5.00098 3.06325 8.11098 1.33325 12.501C2.05114 14.3227 3.2064 15.9239 4.6697 17.1741L7.63343 14.2103C7.43921 13.6769 7.33325 13.1013 7.33325 12.501C7.33325 9.74098 9.57325 7.50098 12.3333 7.50098C12.9335 7.50098 13.5092 7.60693 14.0426 7.80116L16.1959 5.64785Z" fill="black" fill-opacity="0.6"/>
  	<path d="M12.3428 9.50099C12.3396 9.50098 12.3364 9.50098 12.3333 9.50098C10.6733 9.50098 9.33325 10.841 9.33325 12.501C9.33325 12.5041 9.33326 12.5073 9.33327 12.5105L12.3428 9.50099Z" fill="black" fill-opacity="0.6"/>
  	<path d="M12.1857 15.4974L15.3297 12.3534C15.3321 12.4023 15.3333 12.4515 15.3333 12.501C15.3333 14.161 13.9933 15.501 12.3333 15.501C12.2838 15.501 12.2346 15.4998 12.1857 15.4974Z" fill="black" fill-opacity="0.6"/>
  	<path d="M10.5211 17.1621C11.083 17.3809 11.6942 17.501 12.3333 17.501C15.0933 17.501 17.3333 15.261 17.3333 12.501C17.3333 11.8619 17.2132 11.2507 16.9943 10.6888L19.9201 7.76296C21.4198 9.02235 22.6029 10.6476 23.3333 12.501C21.6033 16.891 17.3333 20.001 12.3333 20.001C10.9412 20.001 9.60569 19.7599 8.36585 19.3173L10.5211 17.1621Z" fill="black" fill-opacity="0.6"/>
  	<path d="M20.3333 3.00098L21.7475 4.41519L4.7769 21.3858L3.36269 19.9715L20.3333 3.00098Z" fill="black" fill-opacity="0.6"/>
  </svg>
  */
  {
    id: "bfuVChnw",
    block: "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"25\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 25 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M16.1959 5.64785C14.9857 5.22869 13.6862 5.00098 12.3333 5.00098C7.33325 5.00098 3.06325 8.11098 1.33325 12.501C2.05114 14.3227 3.2064 15.9239 4.6697 17.1741L7.63343 14.2103C7.43921 13.6769 7.33325 13.1013 7.33325 12.501C7.33325 9.74098 9.57325 7.50098 12.3333 7.50098C12.9335 7.50098 13.5092 7.60693 14.0426 7.80116L16.1959 5.64785Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M12.3428 9.50099C12.3396 9.50098 12.3364 9.50098 12.3333 9.50098C10.6733 9.50098 9.33325 10.841 9.33325 12.501C9.33325 12.5041 9.33326 12.5073 9.33327 12.5105L12.3428 9.50099Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M12.1857 15.4974L15.3297 12.3534C15.3321 12.4023 15.3333 12.4515 15.3333 12.501C15.3333 14.161 13.9933 15.501 12.3333 15.501C12.2838 15.501 12.2346 15.4998 12.1857 15.4974Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M10.5211 17.1621C11.083 17.3809 11.6942 17.501 12.3333 17.501C15.0933 17.501 17.3333 15.261 17.3333 12.501C17.3333 11.8619 17.2132 11.2507 16.9943 10.6888L19.9201 7.76296C21.4198 9.02235 22.6029 10.6476 23.3333 12.501C21.6033 16.891 17.3333 20.001 12.3333 20.001C10.9412 20.001 9.60569 19.7599 8.36585 19.3173L10.5211 17.1621Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M20.3333 3.00098L21.7475 4.41519L4.7769 21.3858L3.36269 19.9715L20.3333 3.00098Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/invisible.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});