define("lh4/templates/components/reports/deposit-breakdown-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x+k+GTMw",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"reports/report-table-raw\",null,[[\"currentReport\",\"isLoading\",\"displayedRows\"],[[24,[\"report\"]],[24,[\"isLoading\"]],[24,[\"displayedRows\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reports/deposit-breakdown-table.hbs"
    }
  });

  _exports.default = _default;
});