define("lh4/mirage/routes/transactions/day-parts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMirageTransactionsDayPartsServer;

  function getMirageTransactionsDayPartsServer(server) {
    server.get('/transactions/day-parts', function () {
      return {
        dayParts: ['Brunch', 'Dinner', 'Late Night']
      };
    });
  }
});