define("lh4/components/ui/tree/report-tree", ["exports", "lh4/classes/tree/report-tree-node"], function (_exports, _reportTreeNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['report-tree'],
    location: Ember.inject.service(),
    actions: {
      toggleIsExpanded: function toggleIsExpanded() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var node = [].concat(args).pop();

        if (node.isExpanded) {
          node.collapseAll();
          return Ember.notifyPropertyChange(node, 'iconCssClass');
        } // children are already loaded, just expand


        if (node.children.length > 0) {
          node.expand();
          return Ember.notifyPropertyChange(node, 'iconCssClass');
        } // lazy load children


        Ember.set(node, 'isLoading', true);
        Ember.notifyPropertyChange(node, 'iconCssClass');
        var fetchFunction = this.fetchFunction;
        return fetchFunction({
          rootGuid: node.id,
          maxLevel: 1
        }).then(function (_ref) {
          var trees = _ref.trees;
          Ember.set(node, 'children', new _reportTreeNode.default(trees[0]).children);
          return node;
        }).then(function () {
          return node.expand();
        }).then(function () {
          return Ember.notifyPropertyChange(node, 'isLeaf');
        }).then(function () {
          return Ember.notifyPropertyChange(node, 'iconCssClass');
        }).finally(function () {
          return Ember.set(node, 'isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});