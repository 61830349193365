define("lh4/templates/components/charts/line/date-range-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Op6Ekd99",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"inner-wrapper\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"outer-box\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"inner-box\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"svg\",true],[10,\"class\",\"chart-area\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"g\",true],[10,\"class\",\"chart-group\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"g\",true],[10,\"class\",\"axes\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"g\",true],[10,\"class\",\"x-axis\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[7,\"text\",true],[10,\"class\",\"label\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"g\",true],[10,\"class\",\"y-axis\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[7,\"text\",true],[10,\"class\",\"label\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"g\",true],[10,\"class\",\"chart-lines\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"rect\",true],[10,\"class\",\"overlay\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"tooltips-container\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"tooltip-line\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"tooltip-legend-wrapper\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"tooltip-legend\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"legend\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/charts/line/date-range-chart.hbs"
    }
  });

  _exports.default = _default;
});