define("lh4/components/ui/s4/icons/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z" fill="black" fill-opacity="0.87"/>
  </svg>
  */
  {
    id: "YwEymWIA",
    block: "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/filter.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});