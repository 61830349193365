define("lh4/components/ui/s4/icons/heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21.351L10.55 20.031C5.4 15.361 2 12.281 2 8.50098C2 5.42098 4.42 3.00098 7.5 3.00098C9.24 3.00098 10.91 3.81098 12 5.09098C13.09 3.81098 14.76 3.00098 16.5 3.00098C19.58 3.00098 22 5.42098 22 8.50098C22 12.281 18.6 15.361 13.45 20.041L12 21.351Z" fill="black" fill-opacity="0.6"/>
  </svg>
  */
  {
    id: "GOTq81Ih",
    block: "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n  \"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M12 21.351L10.55 20.031C5.4 15.361 2 12.281 2 8.50098C2 5.42098 4.42 3.00098 7.5 3.00098C9.24 3.00098 10.91 3.81098 12 5.09098C13.09 3.81098 14.76 3.00098 16.5 3.00098C19.58 3.00098 22 5.42098 22 8.50098C22 12.281 18.6 15.361 13.45 20.041L12 21.351Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/heart.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});