define("lh4/components/ui/form-fields/checkbox-field", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    disabled: false,
    type: 'checkbox',
    isHeader: false,
    isToggle: false,
    dataTest: 'form-field-checkbox-field',
    classes: [],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var changeset = this.changeset,
          property = this.property;
      var propertyString = "changeset.".concat(property); // redefine this property on every render & re-render, as clicking on checkbox doesn't do anything after
      // initial render was performed and changeset was changed in route when 'Save & duplicate'
      // (cloning) is performed or another edit tray was opened

      Ember.defineProperty(this, 'isChecked', Ember.computed(propertyString, function () {
        return changeset.get(property);
      }));
    },
    fieldClasses: Ember.computed('isChecked', 'showErrorTooltip', 'changeset.error', 'disabled', function getter() {
      var hasError = this.changeset && this.property ? this.changeset.get("error.".concat(this.property)) : false;
      var verticalClass = this.isToggle ? ' vertical-toggle' : ' vertical-checkbox';
      var stateClasses = ["".concat(this.disabled ? ' disabled' : ''), "".concat(this.hasFocus ? ' focused' : ''), "".concat(this.showErrorTooltip ? ' show-error-tooltip' : ''), "".concat(hasError ? ' error' : ''), "".concat(this.isChecked ? ' checked' : ''), "".concat(this.isToggle ? ' toggle' : ''), "".concat(this.isHeader ? '' : verticalClass)];
      var classNames = stateClasses.join('');

      if (this.classes.length) {
        classNames = classNames.concat(' ', this.classes.join(' '));
      }

      return "ui checkbox".concat(classNames);
    }),
    actions: {
      onClick: function onClick(event) {
        if (this.disabled) {
          event.stopPropagation();
          return;
        }

        if (event && event.target && event.target instanceof HTMLAnchorElement) {
          event.stopPropagation();
          return;
        }

        if (this.onChange) {
          this.onChange(this.isChecked);
        } else {
          this.changeset.set(this.property, !this.isChecked);
        }
      }
    }
  });

  _exports.default = _default;
});