define("lh4/models/hardware/item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    priceSuffix: _emberData.default.attr('string'),
    paymentInterval: _emberData.default.attr('string'),
    maxQuantity: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    imageUrl: _emberData.default.attr('string'),
    dependencies: _emberData.default.hasMany('hardware/dependency'),
    // Dynamic display fields
    displayEnabled: _emberData.default.attr('boolean'),
    listItemDetails: _emberData.default.attr(),
    productPageEnabled: _emberData.default.attr('boolean'),
    productPageDetails: _emberData.default.attr(),
    options: _emberData.default.attr()
  });

  _exports.default = _default;
});