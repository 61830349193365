define("lh4/helpers/breakline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.breakline = breakline;
  _exports.default = void 0;

  /**
   * Replaces new line chars with a <br> tag
   * @param {String} params - text to perform the replacement on
   * @returns {String} - text with new lines replaced with <br> tags
   */
  function breakline(params) {
    if (params[1] && params[0].length >= params[1]) {
      var text = params[0].match(new RegExp(".{0,".concat(params[1], "}"), 'g')).join('<br>');
      return Ember.String.htmlSafe(Ember.Handlebars.Utils.escapeExpression(text).replace(/&lt;br&gt;/g, '<br>'));
    }

    if (params[0]) {
      var _text = params[0].replace(/(\r\n|\n|\r)/gm, '<br>');

      return Ember.String.htmlSafe(Ember.Handlebars.Utils.escapeExpression(_text).replace(/&lt;br&gt;/g, '<br>'));
    }

    return '';
  }

  var _default = Ember.Helper.helper(breakline);

  _exports.default = _default;
});