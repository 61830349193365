define("lh4/services/logger", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    logger: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('logger', new LogglyTracker());
      this.logger.push(_environment.default.loggly);
    },
    log: function log(message) {
      // eslint-disable-next-line no-console
      console.log(message);
      this.logger.push(message);
    }
  });

  _exports.default = _default;
});