define("lh4/components/ui/form-fields/items-tree", ["exports", "jquery", "lh4/classes/tree/tree", "lh4/utils/get-array-difference-count", "lh4/components/ui/form-fields/field"], function (_exports, _jquery, _tree, _getArrayDifferenceCount, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _field.default.extend({
    media: Ember.inject.service(),
    classNames: ['stretched', 'column', 'items-list'],
    showAll: false,
    showSelectAll: true,
    readOnly: false,
    dataTest: 'form-field-items-tree',
    showAddedRemoved: false,
    currentSelectedItems: Ember.A([]),
    initialItems: [],
    independentParents: false,

    /**
     * @param {Boolean} includeInactiveStyle - decides whether to render items where isActive == false with
     * a different style. NOTE: could be removed someday if the inactive style is used in all pages of the application
     */
    includeInactiveStyle: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setProperties({
        currentSelectedItems: _toConsumableArray(this.initialItems),
        tree: new _tree.default(this.items, {
          isVisible: true,
          isSelected: false,
          isExpanded: false
        }, this.independentParents)
      });
    },
    listItems: Ember.computed('currentSelectedItems.[]', 'items.[]', function listItems() {
      var _this = this;

      if (!this.currentSelectedItems.length) {
        return [];
      }

      return this.items.filter(function (_ref) {
        var id = _ref.id;
        return _this.currentSelectedItems.includes(id);
      });
    }),
    listClasses: Ember.computed('fullWidth', function listClasses() {
      var width = this.fullWidth ? 'twelve' : 'eight';
      return "".concat(width, " wide column");
    }),
    selectedItemsClasses: Ember.computed('showAll', 'listItems.[]', function selectedItemClasses() {
      var shortList = !this.showAll && ' short-list';
      var hasGradient = this.items.length > 10 && this.media.isDesktop;
      return "".concat(shortList).concat(hasGradient ? ' add-gradient-height' : '');
    }),
    deselectedItemCount: Ember.computed('currentSelectedItems.@each', function deselectedItemCount() {
      return (0, _getArrayDifferenceCount.default)(this.initialItems, this.currentSelectedItems);
    }),
    selectedItemCount: Ember.computed('currentSelectedItems.@each', function selectedItemCount() {
      return (0, _getArrayDifferenceCount.default)(this.currentSelectedItems, this.initialItems);
    }),
    closeModal: function closeModal() {
      (0, _jquery.default)("#".concat(this.elementId, "-checktree-modal")).modal('hide');
    },
    fillCheckedItems: function fillCheckedItems(node, array) {
      var _this2 = this;

      if (node.isLeaf || this.tree.independentParents) {
        if (node.isSelected && !array.includes(node.id)) {
          array.pushObject(node.id);
        }

        if (!node.isSelected && array.includes(node.id)) {
          array.removeObject(node.id);
        }
      } else {
        node.children.forEach(function (item) {
          _this2.fillCheckedItems(item, array);
        });
      }
    },
    actions: {
      onCheck: function onCheck(value) {
        this.fillCheckedItems(value, this.currentSelectedItems);
      },
      launchModal: function launchModal() {
        (0, _jquery.default)('#modal-discard-button').removeClass('loading');
        (0, _jquery.default)('#modal-save-button').removeClass('loading');
        (0, _jquery.default)("#".concat(this.elementId, "-checktree-modal")).modal('show');
      },
      removeRecord: function removeRecord(id) {
        this.currentSelectedItems.removeObject(id);

        if (this.onRemoveRecord) {
          this.onRemoveRecord(this.currentSelectedItems, this.property);
        } else {
          this.changeset.set(this.property, this.currentSelectedItems);
        }
      },
      saveModal: function saveModal() {
        var _this3 = this;

        (0, _jquery.default)('#modal-save-button').addClass('loading');
        Ember.run.later('actions', function () {
          var selected = _this3.tree.selected.filter(function (_ref2) {
            var isLeaf = _ref2.isLeaf;
            return isLeaf || _this3.tree.independentParents;
          });

          if (_this3.onSaveModal) {
            _this3.onSaveModal(selected, _this3.property);
          } else {
            _this3.changeset.set(_this3.property, selected.map(function (_ref3) {
              var id = _ref3.id;
              return id;
            }));
          }

          _this3.closeModal();
        });
      },
      toggleList: function toggleList() {
        this.toggleProperty('showAll');
      },
      modalDeny: function modalDeny(_, modal) {
        var _this4 = this;

        (0, _jquery.default)('#modal-discard-button').addClass('loading');
        Ember.run.later(function () {
          if (!modal || modal && modal.get('show')) {
            _this4.closeModal();
          }

          _this4.setProperties({
            currentSelectedItems: _toConsumableArray(_this4.initialItems),
            tree: new _tree.default(_this4.items, {
              isVisible: true,
              isSelected: false,
              isExpanded: false
            }, _this4.independentParents)
          });
        });
      },
      onDelete: function onDelete() {
        this.set('link', null);
        this.sendAction('onDelete');
      }
    }
  });

  _exports.default = _default;
});