define("lh4/templates/components/ui/buttons/button-confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1TG1Wuw1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",[22,\"buttonClass\"]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"displayConfirmModal\"]],[8],[1,[22,\"buttonText\"],false],[9],[0,\"\\n\\n\"],[1,[28,\"modal/action-confirmation\",null,[[\"onApprove\",\"icon\",\"question\",\"description\",\"approveText\",\"denyText\",\"id\"],[[28,\"action\",[[23,0,[]],\"actionConfirmed\"],null],\"trash alternate\",[24,[\"confirmationQuestion\"]],[24,[\"confirmationDescription\"]],[24,[\"confirmationApproveText\"]],[24,[\"confirmationDenyText\"]],[24,[\"id\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/buttons/button-confirm-modal.hbs"
    }
  });

  _exports.default = _default;
});