define("lh4/components/epro/manage-employees/employee-form", ["exports", "moment", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _moment, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['employee-form'],
    launchDarkly: Ember.inject.service('launch-darkly'),
    currentDate: (0, _moment.default)(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    region: Ember.inject.service('locale/region'),
    maxBirthDate: (0, _moment.default)(),
    permissionsList: Ember.computed.map('permissions', function (_ref) {
      var id = _ref.id,
          name = _ref.description,
          type = _ref.type;
      return {
        id: id,
        name: name,
        type: type
      };
    }),
    jobOptions: Ember.computed.map('jobs', function (_ref2) {
      var name = _ref2.name,
          value = _ref2.guid,
          isActive = _ref2.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    payRateSortOptions: ['name:asc'],
    sortedPayRateChangesets: Ember.computed.sort('changeset.payRateChangesets', 'payRateSortOptions'),
    printerOptions: Ember.computed.map('printers', function (_ref3) {
      var name = _ref3.name,
          value = _ref3.guid,
          isActive = _ref3.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    buttonLabel: Ember.computed('sortedPayRateChangesets', function getter() {
      return this.sortedPayRateChangesets.length !== this.jobOptions.length ? 'Add Job' : null;
    }),
    isPhoneNumberIdentifyCallerEnabled: Ember.computed('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.PHONE_NUMBER_IDENTIFY_CALLER];
    }),
    hideDeleteButton: Ember.computed('launchDarkly.featureFlags', 'changeset.phoneChangesets.length', function getter() {
      return this.isPhoneNumberIdentifyCallerEnabled && this.changeset.phoneChangesets.length === 1;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.changeset.phoneChangesets.length && this.isPhoneNumberIdentifyCallerEnabled) {
        this.send('addPhone');
      }
    },
    actions: {
      changeProperty: function changeProperty(record, value, property) {
        if (property === 'jobGuid') {
          var job = this.jobs.findBy('id', value);
          record.set('payRate', job.payRate);
          record.set('jobValue', job.payRate);
        }

        record.set(property, value);
      },
      addDate: function addDate() {
        var newDateRecord = this.changeset.data.addDate();
        var changeset = this.buildChildChangeset(newDateRecord);
        this.changeset.dateChangesets.pushObject(changeset);
      },
      deleteDate: function deleteDate(record) {
        this.changeset.dateChangesets.removeObject(record);
      },
      addPhone: function addPhone() {
        var newPhoneRecord = this.changeset.data.addPhone();
        var changeset = this.buildChildChangeset(newPhoneRecord);
        this.changeset.phoneChangesets.pushObject(changeset);
      },
      deletePhone: function deletePhone(record) {
        this.changeset.phoneChangesets.removeObject(record);
      },
      addPayrate: function addPayrate() {
        var newPayrateRecord = this.changeset.data.addPayRate(this.changeset.payRateChangesets);
        var changeset = this.buildChildChangeset(newPayrateRecord);
        this.changeset.payRateChangesets.pushObject(changeset);
      },
      deletePayrate: function deletePayrate(record) {
        this.changeset.payRateChangesets.removeObject(record);
      }
    }
  });

  _exports.default = _default;
});