define("lh4/mirage/factories/subscription", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    createdAt: '2022-07-18T13:56:19.597Z',
    day: 3,
    frequency: 'monthly',
    lastDeliveredAt: '2022-08-03T07:15:25.545Z',
    lastError: null,
    options: {
      format: 'pdf',
      subject: 'gdfg',
      timezone: 'Europe/Vilnius',
      locations: ['162']
    },
    product: 'echo-pro',
    report: 'customer-house-accounts-balance',
    reportSubscriptionRecipients: [{
      destination: 'laurynas.paradauskas@shift4.com',
      id: 778,
      lastDeliveredAt: '2022-08-03T07:15:25.546Z',
      lastError: null,
      reportSubscriptionId: 540
    }],
    time: '00:15',
    updatedAt: '2022-08-03T07:15:25.673Z',
    userId: 811
  });

  _exports.default = _default;
});