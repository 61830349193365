define("lh4/mirage/factories/epro-pay-period", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    locationId: 1,
    start: '2021-01-03T03:00:00.000-05:00',
    dayOfWeek: 0
  });

  _exports.default = _default;
});