define("lh4/enums/report-subscription-frequency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REPORT_SUBSCRIPTION_FREQUENCY = {
    YEARLY: 'yearly',
    QUARTERLY: 'quarterly',
    MONTHLY: 'monthly',
    WEEKLY: 'weekly',
    DAILY: 'daily'
  };
  var _default = REPORT_SUBSCRIPTION_FREQUENCY;
  _exports.default = _default;
});