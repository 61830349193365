define("lh4/routes/authenticated/locations/location/hospitality/customize-menu/category/edit-category/item/edit-item", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(_ref) {
      var itemId = _ref.itemId;

      var _this$modelFor = this.modelFor('authenticated.locations.location'),
          location = _this$modelFor.id;

      return Ember.RSVP.hash({
        choiceSets: this.store.query('hospitality/choice-set', {
          location: location
        }),
        editItem: this.store.findRecord('hospitality/menu-item', itemId, {
          reload: true
        }),
        category: this.store.query('hospitality/menu-category', {
          location: location
        }),
        revClass: this.store.query('hospitality/revenue-class', {
          location: location
        }),
        itemGroup: this.store.query('hospitality/item-group', {
          location: location
        }),
        printers: this.store.query('hospitality/printer', {
          location: location
        })
      });
    }
  });

  _exports.default = _default;
});