define("lh4/utils/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INTERNET_PAYMENT_PERMISSIONS = _exports.default = void 0;
  var PERMISSIONS = {
    GENERAL: {
      LIGHTHOUSE_ACCESS: 10,
      SALES_INFO: 11,
      TICKET_INFO: 12,
      PAYMENT_INFO: 13,
      PROCESSING_DETAIL: 14,
      MONTHLY_CC_STATEMENTS: 15,
      VIEW_1099K: 16,
      ACCOUNT_NOTICES: 17,
      BATCH_HISTORY: 18,
      LABOR_PERCENTAGE: 19,
      EMPLOYEE_TIMECLOCK: 20,
      REPORTS: 21
    },
    OLD_DASHBOARD: {
      SALES_INFO: 11,
      TICKET_INFO: 12,
      PAYMENT_INFO: 13,
      PROCESSING_DETAIL: 14
    },
    DOCUMENTS: {
      MONTHLY_CC_STATEMENTS: 15,
      VIEW_TAX_DOCUMENTS: 16,
      ACCOUNT_NOTICES: 17
    },
    LOCATION_OVERVIEW: {
      BATCH_HISTORY: 18,
      LABOR_PERCENTAGE: 19,
      EMPLOYEE_TIMECLOCK: 20
    },
    REPORTING: {
      REPORTS: 21,
      ACCESS_REPORTING: 113,
      MANAGE_USERS_SUBSCRIPTIONS: 116,
      CREATE_OWN_SUBSCRIPTIONS: 121,
      GIFT_CARDS: 142
    },
    HBR: {
      EMPLOYEES_AND_JOBS: 22,
      MENU: 23,
      ACCOUNTING: 24,
      TABLE_LAYOUT: 25,
      TIMECLOCK: 26,
      ONLINE_ORDERING: 27,
      EMPLOYEE_SCHEDULING: 28,
      RESERVATIONS: 29,
      TABLESIDE: 30,
      PAYMENTS: 45,
      CUSTOMERS: 46
    },
    HOSP: {
      EMPLOYEES: 31,
      MENU: 32,
      ITEM_GROUPS: 33,
      CHOICES_AND_CHOICE_SETS: 34,
      ONLINE_ORDERING: 35,
      RESERVATIONS: 3
    },
    ECHO: {
      POS: 36
    },
    SALON: {
      POS: 38
    },
    ORDERS: {
      PLACE_SUPPLY_ORDER: 39,
      VIEW_SUPPLY_ORDER: 40,
      VIEW_POS_ORDER: 41
    },
    SUPPORT: {
      VIEW_CONTACT_INFO: 42,
      SEND_TICKETS: 43,
      VIEW_DOCS: 44,
      SEND_SUPPORT_MESSAGES: 122
    },
    SOCIAL_MEDIA: {
      MANAGE_ACCOUNTS: 51,
      MANAGE_POSTS: 52
    },
    MARKETPLACE: {
      ACCESS: 60
    },
    REPUTATION_MANAGEMENT: {
      MANAGE_ACCOUNTS: 70,
      MANAGE_REVIEWS: 71
    },
    CUSTOMER_ENGAGEMENT: {
      ACCESS: 75
    },
    PROCESSING: {
      CUSTOMERS: 80
    },
    SKYTAB: {
      ACCESS: 90
    },
    LOYALTY: {
      MANAGE_PROGRAMS: 101,
      MANAGE_DISCOUNTS: 102,
      MANAGE_MEMBERS: 103,
      MANAGE_PROMOTIONS: 104
    },
    DISPUTES: {
      VIEW_DISPUTE: 110,
      SUBMIT_DISPUTE_INFO: 111,
      VIEW_SUBMITTED_INFO: 112
    },
    CONFIGURATION: {
      MANAGE_SETTINGS: 114
    },
    USERS: {
      MANAGE_USERS: 115,
      GRANT_MANAGE_USERS: 117
    },
    IN_CHARGE_APP: {
      ACCESS_LIGHTHOUSE_MOBILE_APP: 120
    },
    TRANSACTIONS: {
      VIEW_TRANSACTIONS: 123,
      CREATE_TRANSACTIONS: 124,
      REFUND_TRANSACTIONS: 125
    },
    DASHBOARD: {
      VIEW_DASHBOARD: 126
    },
    RISK: {
      MANAGE_BLOCK_LISTS: 127,
      VIEW_RISK_RULES: 128,
      MANAGE_RISK_RULES: 129
    },
    DEVELOPERS: {
      MANAGE_DEVELOPER_SETTINGS: 130
    },
    INTERNET_PAYMENTS_DISPUTES: {
      IP_VIEW_DISPUTES: 131,
      IP_SUBMIT_DISPUTE_EVIDENCE: 132
    },
    INSIGHTS: {
      VIEW_AUTH: 133,
      VIEW_RISK: 134,
      VIEW_CARD_VERIFY: 135,
      VIEW_CHARGEBACK: 136
    },
    BUSINESS_SETTINGS: {
      MANAGE_DAY_PARTS_AND_REVENUE_CENTERS: 137
    },
    FINARO: {
      FINARO_INSIGHT_ACCESS: 139
    },
    GIFT_CARDS: {
      ACCESS: 140,
      ORDERS_ACCESS: 141
    },
    INVOICES: {
      VIEW_INVOICES: 143
    }
  };
  var _default = PERMISSIONS;
  _exports.default = _default;
  var INTERNET_PAYMENT_PERMISSIONS = [PERMISSIONS.DOCUMENTS.MONTHLY_CC_STATEMENTS, PERMISSIONS.DOCUMENTS.VIEW_TAX_DOCUMENTS, PERMISSIONS.DOCUMENTS.ACCOUNT_NOTICES, PERMISSIONS.REPORTING.REPORTS, PERMISSIONS.REPORTING.CREATE_OWN_SUBSCRIPTIONS, PERMISSIONS.SUPPORT.VIEW_CONTACT_INFO, PERMISSIONS.SUPPORT.SEND_SUPPORT_MESSAGES, PERMISSIONS.USERS.MANAGE_USERS, PERMISSIONS.USERS.GRANT_MANAGE_USERS, PERMISSIONS.TRANSACTIONS.VIEW_TRANSACTIONS, PERMISSIONS.TRANSACTIONS.CREATE_TRANSACTIONS, PERMISSIONS.TRANSACTIONS.REFUND_TRANSACTIONS, PERMISSIONS.DASHBOARD.VIEW_DASHBOARD, PERMISSIONS.RISK.MANAGE_BLOCK_LISTS, PERMISSIONS.RISK.VIEW_RISK_RULES, PERMISSIONS.RISK.MANAGE_RISK_RULES, PERMISSIONS.DEVELOPERS.MANAGE_DEVELOPER_SETTINGS, PERMISSIONS.INTERNET_PAYMENTS_DISPUTES.IP_VIEW_DISPUTES, PERMISSIONS.INTERNET_PAYMENTS_DISPUTES.IP_SUBMIT_DISPUTE_EVIDENCE, PERMISSIONS.INSIGHTS.VIEW_AUTH, PERMISSIONS.INSIGHTS.VIEW_RISK, PERMISSIONS.INSIGHTS.VIEW_CARD_VERIFY, PERMISSIONS.INSIGHTS.VIEW_CHARGEBACK];
  _exports.INTERNET_PAYMENT_PERMISSIONS = INTERNET_PAYMENT_PERMISSIONS;
});