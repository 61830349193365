define("lh4/templates/components/internet-payments/risk/risk-block-list-icon-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UsaNWiQR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"i\",true],[11,\"class\",[29,[\"icon \",[22,\"iconClass\"],\" risk-color-\",[22,\"dataStatus\"]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toogleMobileTooltip\"],null]],[10,\"data-test-risk-block\",\"tooltip\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"tooltip \",[22,\"tooltipClass\"]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[22,\"tooltipHeaderText\"],false],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[1,[22,\"tooltipBodyText\"],false],[9],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/risk/risk-block-list-icon-tooltip.hbs"
    }
  });

  _exports.default = _default;
});