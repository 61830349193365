define("lh4/templates/components/reputation/account-header-ratings-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zx2qc7ez",
    "block": "{\"symbols\":[\"account\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"displayAccountIcons\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"three wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"reputation/account-header-ratings\",null,[[\"account\"],[[23,1,[]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"no-reviews\"],[8],[0,\"No Records To Display\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reputation/account-header-ratings-list.hbs"
    }
  });

  _exports.default = _default;
});