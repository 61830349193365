define("lh4/routes/authenticated/internet-payments/risk/risk-rules", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/enums/ip-risk-rules-groups-hidden"], function (_exports, _authenticatedRouteMixin, _ipRiskRulesGroupsHidden) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: 'Risk Rules',
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('view risk menu item in internet-payment')) {
        this.transitionTo('authenticated.internet-payments.dashboard');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        riskRules: this.ajax.request("/api/v1/internet-payments/risk-rules/".concat(this.location.model.id)).catch(function () {
          return [];
        })
      });
    },
    afterModel: function afterModel(model) {
      var routeName = this.routeName;
      var groups = {};
      model.riskRules.forEach(function (rule) {
        if (_ipRiskRulesGroupsHidden.default.includes(rule.group.toLowerCase())) {
          return;
        }

        if (!groups[rule.group]) {
          groups[rule.group] = {
            groupeName: rule.group,
            scoreRule: rule.score !== null,
            rules: []
          };
        }

        groups[rule.group].rules.push(rule);
      });
      var groupedRules = Object.keys(groups).map(function (key) {
        return groups[key];
      });
      this.controllerFor(routeName).set('groupedRules', groupedRules);
    },
    actions: {
      riskRuleSaved: function riskRuleSaved() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});