define("lh4/mirage/factories/epro-advantage-setting", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    mid: '1',
    status: 'PENDING',
    current: {
      advantageProgramPercent: '',
      advantageProgramType: 'DUAL_PRICING',
      options: {
        tipReductionEnabled: false,
        tipReductionPercent: '0%',
        receiptAppearance: 'This is a message for all who listen'
      }
    },
    pending: {
      expectedConversionDate: '04/01/2024',
      pendingPercentage: '3%',
      pendingProgram: 'CASH_DISCOUNT'
    }
  });

  _exports.default = _default;
});