define("lh4/mirage/routes/alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageAlertsServer;

  function setMirageAlertsServer(server) {
    server.get('/alerts', function () {
      return {
        alerts: [{
          header: 'Maintenance Message Type Test',
          message: 'This is an info message. You have been informed.',
          type: 'info',
          isActive: true
        }, {
          header: 'Maintenance Message Type Test',
          message: 'This is a warning message. You have been warned.',
          type: 'warning',
          isActive: true
        }, {
          header: 'Maintenance Message Type Test',
          message: 'This is a success message. You have success.',
          type: 'success',
          isActive: true
        }, {
          header: 'Maintenance Message Type Test',
          message: 'This is a danger message. Be careful.',
          type: 'danger',
          isActive: true
        }, {
          header: 'Maintenance Message isActive Test',
          message: 'If you see this message we have a problem.',
          type: 'info',
          isActive: false
        }, {
          header: 'Maintenance Message Start Date Test',
          message: 'It\'s year 2030 and we have flying cars now.',
          type: 'info',
          isActive: true,
          startDate: '2030-01-01T00:00:00.000Z'
        }, {
          header: 'Maintenance Message Start Date Test',
          message: 'Welcome to year 2020.',
          type: 'info',
          isActive: true,
          startDate: '2020-01-01T00:00:00.000Z'
        }, {
          header: 'Maintenance Message End Date Test',
          message: 'Back in my day... we did not display messages after their end date.',
          type: 'info',
          isActive: true,
          endDate: '2020-01-01T00:00:00.000Z'
        }, {
          header: 'Maintenance Message End Date Test',
          message: 'I\'m here for you until the next decade.',
          type: 'info',
          isActive: true,
          endDate: '2030-01-01T00:00:00.000Z'
        }, {
          header: 'Maintenance Message Start & End Date Test',
          message: 'I\'m here for you for this decade.',
          type: 'info',
          isActive: true,
          startDate: '2020-01-01T00:00:00.000Z',
          endDate: '2030-01-01T00:00:00.000Z'
        }, {
          broken: 'state'
        }]
      };
    });
  }
});