define("lh4/routes/authenticated/support/support-links", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    requiredPermissions: 'view docs in support',
    model: function model() {
      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations
      });
    }
  });

  _exports.default = _default;
});