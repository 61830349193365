define("lh4/abilities/hardware-ordering", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    canViewProductList: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewProductList() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.HARDWARE_ORDERING_PRODUCT_LIST_VISIBILITY];
    }),
    canCheckSkytabGlassRouterRequirement: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canCheckSkytabGlassRouterRequirement() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SKYTAB_GLASS_ROUTER_CHECK_ENABLED];
    }),
    canSelectShippingMethod: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canSelectShippingMethod() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SHIPPING_METHOD_VISIBILITY];
    })
  });

  _exports.default = _default;
});