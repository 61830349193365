define("lh4/templates/authenticated/locations/location/echo-pro/employee-schedule/template/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F1Ge1FUB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal/route-modal\",null,[[\"class\"],[\"tiny\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"ui/modal/dialog-header\",null,[[\"title\",\"close\"],[\"Save as template\",[28,\"action\",[[23,0,[]],\"clear\"],null]]]],false],[0,\"\\n\\n\"],[4,\"ui/modal/dialog-content\",null,null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"flash-messages\",null,[[\"flashMessages\"],[[24,[\"flashMessages\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"segment\"],[8],[0,\"\\n\\t\\t\\t\\t\\tSave \"],[1,[28,\"moment-format\",[[24,[\"model\",\"range\",\"from\"]],\"D MMM\"],null],false],[0,\" - \"],[1,[28,\"moment-format\",[[24,[\"model\",\"range\",\"to\"]],\"D MMM\"],null],false],[0,\" schedule as template\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui hidden divider\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"label\",true],[8],[0,\"Name\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"input\",null,[[\"value\",\"placeholder\"],[[24,[\"model\",\"template\",\"name\"]],\"Name\"]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[1,[28,\"ui/modal/dialog-buttons\",null,[[\"accept\",\"decline\",\"isLoading\"],[[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"clear\"],null],[24,[\"isLoading\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/employee-schedule/template/create.hbs"
    }
  });

  _exports.default = _default;
});