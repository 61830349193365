define("lh4/templates/components/ui/icons/internet-payments/plus-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4YSUbcvi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"height\",\"24px\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"width\",\"24px\"],[10,\"fill\",\"currentColor\"],[10,\"data-test-icon\",\"plus-circle\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/plus-circle.hbs"
    }
  });

  _exports.default = _default;
});