define("lh4/helpers/dash-splice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dashSplice = dashSplice;
  _exports.default = void 0;

  /**
   * Returns a portion of the string after the last occurance of dash
   * @param {String} params[0] - string to perform the splice on
   * @returns {String} - portion of the string after the last dash or the unmodified string if no dashes
   */
  function dashSplice(params
  /* , hash */
  ) {
    return params[0].split('-').pop();
  }

  var _default = Ember.Helper.helper(dashSplice);

  _exports.default = _default;
});