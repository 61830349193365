define("lh4/routes/authenticated/solo/items/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'item',
    model: function model() {
      return Ember.RSVP.hash({
        item: this.store.createRecord('solo/item')
      });
    }
  });

  _exports.default = _default;
});