define("lh4/components/hospitality/customize-menu/item/options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    originalTareWeight: 0,
    hasTareWeight: true,
    setTareWeightValue: function setTareWeightValue() {
      if (!this.get('model.tareWeight')) {
        this.set('hasTareWeight', false);
      } else {
        this.set('hasTareWeight', true);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.setTareWeightValue();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.setTareWeightValue();
    },
    actions: {
      toggleTareWeight: function toggleTareWeight() {
        if (this.get('hasTareWeight')) {
          this.set('hasTareWeight', false);
          this.set('originalTareWeight', this.get('model.tareWeight'));
          this.set('model.tareWeight', 0);
        } else {
          this.set('hasTareWeight', true);
          this.set('model.tareWeight', this.get('originalTareWeight') ? this.get('originalTareWeight') : 0);
        }
      }
    }
  });

  _exports.default = _default;
});