define("lh4/components/ui/form-fields/items-list", ["exports", "jquery", "lh4/components/ui/form-fields/field"], function (_exports, _jquery, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _field.default.extend({
    media: Ember.inject.service(),
    classNames: ['stretched', 'column', 'items-list'],
    showAll: false,
    showSelectAll: true,
    readOnly: false,
    modalSelected: [],
    dataTest: 'form-field-items-list',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var changeset = this.changeset,
          property = this.property;
      var propertyString = "changeset.".concat(property, ".[]"); // redefine this property on every render & re-render, as clicking on checkbox doesn't do anything after
      // initial render was performed and changeset was changed in route when 'Save & duplicate'
      // (cloning) is performed or another edit tray was opened

      Ember.defineProperty(this, 'selected', Ember.computed(propertyString, function () {
        return _toConsumableArray(changeset.get(property));
      }));
    },
    willRender: function willRender() {
      // access computed property to trigger changes
      // this updates UI on initial render and re-render after changeset is changed in route when 'Save & duplicate'
      // (cloning) is performed or another edit tray was opened
      // eslint considers this oddity to be an unused expresssion and there doesn't seem to be an obvious
      // way to silence the problem without making this really weird. ignoring for now.

      /* eslint-disable no-unused-expressions */
      this.selected;
    },

    /**
     * @param {Boolean} includeInactiveStyle - decides whether to render items where isActive == false with
     * a different style. NOTE: could be removed someday if the inactive style is used in all pages of the application
     */
    includeInactiveStyle: false,
    listItems: Ember.computed('selected.[]', 'items.[]', function getter() {
      var _this = this;

      if (!this.selected.length) {
        return [];
      }

      return this.items.filter(function (_ref) {
        var id = _ref.id;
        return _this.selected.includes(id);
      });
    }),
    listClasses: Ember.computed('fullWidth', function getter() {
      var width = this.fullWidth ? 'twelve' : 'eight';
      return "".concat(width, " wide column");
    }),
    selectedItemsClasses: Ember.computed('showAll', 'listItems.[]', function getter() {
      var shortList = !this.showAll && ' short-list';
      var hasGradient = this.listItems.length > 10 && this.media.isDesktop;
      return "".concat(shortList).concat(hasGradient ? ' add-gradient-height' : '');
    }),
    closeModal: function closeModal() {
      (0, _jquery.default)("#".concat(this.elementId, "-checklist-modal")).modal('hide');
      this.set('modalSelected', []);

      if (this.resetSearch) {
        this.resetSearch();
      }
    },
    actions: {
      launchModal: function launchModal() {
        this.set('modalSelected', _toConsumableArray(this.selected));
        (0, _jquery.default)("#".concat(this.elementId, "-checklist-modal")).modal({
          closable: false,
          detachable: false
        }).modal('show');
      },
      removeRecord: function removeRecord(id) {
        var selectedItems = _toConsumableArray(this.selected);

        selectedItems.removeObject(id);

        if (this.onRemoveRecord) {
          this.onRemoveRecord(selectedItems, this.property);
        } else {
          this.changeset.set(this.property, selectedItems);
        }
      },
      saveModal: function saveModal() {
        if (this.onSaveModal) {
          this.onSaveModal(this.modalSelected, this.property);
        } else {
          this.changeset.set(this.property, this.modalSelected);
        }

        this.closeModal();
      },
      toggleList: function toggleList() {
        this.toggleProperty('showAll');
      },
      modalDeny: function modalDeny() {
        this.closeModal();
      }
    }
  });

  _exports.default = _default;
});