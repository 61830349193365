define("lh4/serializers/reservation", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var notificationTypes = [{
    name: 'Text',
    enumName: 'text'
  }, {
    name: 'Call',
    enumName: 'call'
  }, {
    name: 'None',
    enumName: null
  }];

  var _default = _application.default.extend({
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;
      var type = notificationTypes.find(function (notificationType) {
        return notificationType.enumName === json.notificationType;
      });
      json.notificationType = type && type.name;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.notificationType) {
        var type = notificationTypes.find(function (notificationType) {
          return notificationType.name === json.notificationType;
        });
        json.notificationType = type ? type.enumName : null;
      }

      return json;
    }
  });

  _exports.default = _default;
});