define("lh4/templates/components/ui/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4BxKmsRX",
    "block": "{\"symbols\":[\"@checked\",\"@disabled\",\"@label\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"ui checkbox \",[28,\"if\",[[23,2,[]],\"disabled\"],null],\" \",[28,\"if\",[[23,1,[]],\"checked\"],null]]]],[11,\"onclick\",[22,\"handleClick\"]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\"],[7,\"input\",true],[10,\"class\",\"hidden\"],[11,\"checked\",[23,1,[]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\\t\"],[7,\"label\",true],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/checkbox.hbs"
    }
  });

  _exports.default = _default;
});