define("lh4/abilities/hospitality/reservation", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    canManage: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('hospitality') && this.hasLocationPermission(_permissions.default.HOSP.RESERVATIONS) && this.hasService('reservations');
    })
  });

  _exports.default = _default;
});