define("lh4/components/ui/endless-scroll-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /* @params */
    isLoading: false,
    height: '300px',
    actions: {
      loadMore: function loadMore() {
        this.sendAction('onLoadMore');
      }
    }
  });

  _exports.default = _default;
});