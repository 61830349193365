define("lh4/mirage/routes/internet-payments/customers", ["exports", "lh4/mirage/fixtures/internet-payments/customers"], function (_exports, _customers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsCustomersServer;

  function setMirageInternetPaymentsCustomersServer(server) {
    server.get('/internet-payments/customers', function (db, request) {
      var offset = request.queryParams.offset;
      var limit = request.queryParams.limit;
      return {
        meta: {
          count: _customers.default.customers.length
        },
        customers: _customers.default.customers.slice(offset, offset + limit)
      };
    });
  }
});