define("lh4/controllers/general-list-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['searchName', 'offset', 'limit', 'order', 'sort'],
    router: Ember.inject.service(),
    offset: 0,
    limit: 10,
    searchName: null,
    order: 'name',
    sort: 'asc',
    sortAsc: Ember.computed.equal('sort', 'asc'),
    locationChanged: Ember.observer('location.model', function locationChanged() {
      // resets offset and search value on location change
      this.setProperties({
        searchName: null,
        offset: 0
      });
    }),
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('searchName', search);
        this.set('offset', 0);
      },
      filterChanged: function filterChanged(filter) {
        var limit = filter.limit,
            offset = filter.offset,
            sortAsc = filter.sortAsc,
            order = filter.sortBy;
        var sort = sortAsc ? 'asc' : 'desc';
        this.setProperties({
          limit: limit,
          offset: offset,
          sort: sort,
          order: order
        });
      },
      onRowClick: function onRowClick(record) {
        this.transitionToRoute("".concat(this.router.currentRoute.parent.name, ".edit"), record.id);
      },
      create: function create() {
        this.transitionToRoute("".concat(this.router.currentRoute.parent.name, ".create"));
      }
    }
  });

  _exports.default = _default;
});