define("lh4/mirage/factories/epro-modifier-set-map", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    modifierGuid: '1',
    modifierSetGuid: '1',
    ordinal: '0',
    value: '1.00',
    isFlatAmount: true,
    onlineValue: '1.00',
    isFlatAmountOnline: true
  });

  _exports.default = _default;
});