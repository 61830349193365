define("lh4/templates/authenticated/locations/location/echo-pro/reservations/reservation/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Ht5bcBF",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"reservations/reservation-details\",null,[[\"type\",\"save\",\"clear\",\"isLoading\",\"model\",\"settings\"],[\"create\",[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"clear\"],null],[24,[\"isLoading\"]],[24,[\"model\",\"reservation\"]],[24,[\"model\",\"settings\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/reservations/reservation/create.hbs"
    }
  });

  _exports.default = _default;
});