define("lh4/models/echo-pro/terminals/terminals-management", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    guid: _emberData.default.attr('string'),
    origin: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean'),
    isDefault: _emberData.default.attr('boolean'),
    isDeleted: _emberData.default.attr('boolean'),
    isOnlineOrder: _emberData.default.attr('boolean'),
    locationId: _emberData.default.attr('number')
  });

  _exports.default = _default;
});