define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/waitlist/create", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    displayErrors: false,
    partySizeOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    phoneNumberTypes: ['Cell', 'Home', 'Office'],
    tableReadyNotifications: ['Text', 'Call'],
    actions: {
      saveChanges: function saveChanges() {
        var _this = this;

        this.get('model.newRes').setProperties({
          partyTime: (0, _moment.default)().format('hh:mmA'),
          arrivedAt: (0, _moment.default)().toISOString()
        });
        this.get('model.newRes').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.get('model.newRes').save();

            _this.set('model.newRes', _this.store.createRecord('reservation', {
              statusId: 0,
              type: 'waitlist',
              locationId: _this.get('location.model.id')
            }));
          } else {
            _this.set('displayErrors', true);

            _this.get('model.newRes').setProperties({
              partyTime: undefined,
              arrivedAt: undefined
            });
          }
        });
      },
      discardChanges: function discardChanges() {
        this.set('displayErrors', false);
        this.get('model.newRes').setProperties({
          customerFullName: undefined,
          partySize: undefined,
          customerPhoneType: undefined,
          customerPhone: undefined,
          notificationType: undefined,
          customerEmail: undefined,
          notes: undefined
        });
      }
    }
  });

  _exports.default = _default;
});