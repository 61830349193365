define("lh4/models/bank-account/bank", ["exports", "ember-data", "ember-data/attr", "ember-cp-validations"], function (_exports, _emberData, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    accountName: {
      description: 'Account name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 50
      })]
    },
    accountNumber: {
      description: 'Bank Account #',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 50
      })]
    },
    confirmNumber: {
      description: 'Confirm Bank Account #',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('confirmation', {
        on: 'accountNumber',
        message: 'Bank Account numbers do not match'
      })]
    },
    accountType: {
      description: 'Account type',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    routingNumber: {
      description: 'Routing #',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 9
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    accountName: (0, _attr.default)('string'),
    accountNumber: (0, _attr.default)('string'),
    confirmNumber: (0, _attr.default)('string'),
    accountType: (0, _attr.default)('string', {
      defaultValue: 'Checking'
    }),
    routingNumber: (0, _attr.default)('string'),
    status: (0, _attr.default)('number')
  });

  _exports.default = _default;
});