define("lh4/utils/time/time-array", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns an array of times (in milliseconds) starting at the given startTime.
   * The array can extend into future days, and the milliseconds values will always be relative to
   * the iniital startTime.
   *
   * @param {string} startTime - start time of day, given in "hh:mm A" format
   * @param {number} intervalMinutes - number of minutes between each entry
   * @param {number} totalMinutes - total number of minutes before stopping
   * @returns {number[]} an array of times in milliseconds
   */
  var generateTimeArray = function generateTimeArray(startTime) {
    var intervalMinutes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 60;
    var totalMinutes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1440;

    var start = _moment.default.utc(startTime, 'hh:mm A');

    var end = (0, _moment.default)(start).add(totalMinutes, 'minutes');
    var timeArray = [];

    while (start <= end) {
      var isOvernight = start.dayOfYear() === end.dayOfYear();
      var milliseconds = (isOvernight ? 86400000 : 0) + start.hour() * 3600000 + start.minute() * 60000;
      timeArray.push(milliseconds);
      start.add(intervalMinutes, 'minutes');
    }

    return timeArray;
  };

  var _default = generateTimeArray;
  _exports.default = _default;
});