define("lh4/controllers/authenticated/hospitality/customer-database", ["exports", "jquery", "lh4/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var buildUrl = function buildUrl(format) {
    return Ember.computed('model.location.id', 'model.location.product', function getter() {
      var query = _jquery.default.param({
        locations: [this.get('model.location.id')],
        token: this.get('session.data.authenticated.token')
      });

      return "".concat(_environment.default.host, "/api/v1/reports/lighthouse/").concat(format, "/customer-directory?").concat(query);
    });
  };

  var _default = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    offset: 0,
    limit: 25,
    sortBy: 'firstName',
    sortAsc: true,
    search: null,
    headers: [{
      name: 'Name',
      field: 'firstName',
      width: 'four',
      sortable: true
    }, {
      name: 'Phone',
      field: 'primaryPhone.number',
      width: 'three',
      sortable: false
    }, {
      name: 'Email',
      field: 'primaryEmail.email',
      width: 'three',
      sortable: false
    }, {
      name: 'Source',
      field: 'source',
      width: 'three',
      sortable: true
    }],
    mergeQueryParams: function mergeQueryParams(updated) {
      var filter = {
        search: this.search,
        offset: this.offset,
        limit: this.limit,
        order: this.sortBy,
        sortAsc: this.sortAsc
      };
      return {
        queryParams: Object.assign(filter, updated)
      };
    },
    csvURL: buildUrl('csv'),
    xlsURL: buildUrl('xls'),
    pdfURL: buildUrl('pdf'),
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      },
      filterChanged: function filterChanged(filter) {
        this.transitionToRoute('authenticated.hospitality.customer-database', this.mergeQueryParams(filter));
      },
      customerSelected: function customerSelected(customer) {
        this.transitionToRoute('authenticated.hospitality.customer-database.view', customer.id);
      },
      trackReportDownload: function trackReportDownload(type) {
        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'reports',
          eventAction: 'download',
          eventLabel: "hospitality - customer-directory - ".concat(type)
        });
      }
    }
  });

  _exports.default = _default;
});