define("lh4/serializers/settings/user-permissions", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeFindRecordResponse: function normalizeFindRecordResponse() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var payload = args[2],
          id = args[3];
      var normalizedPayload = {
        data: {
          id: id,
          type: 'settings/user-permissions',
          attributes: payload
        }
      };
      return normalizedPayload;
    }
  });

  _exports.default = _default;
});