define("lh4/routes/authenticated/internet-payments/reporting/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    redirect: function redirect() {
      if (this.can.can('view location reports in general', null, {
        locationId: this.location.model.id
      })) {
        return this.transitionTo('authenticated.internet-payments.reporting.reports');
      }

      if (this.can.can('create own subscriptions in general', null, {
        locationId: this.location.model.id
      })) {
        return this.transitionTo('authenticated.internet-payments.reporting.subscriptions');
      }

      if (this.can.can('view documents in general', null, {
        locationId: this.location.model.id
      })) {
        return this.transitionTo('authenticated.internet-payments.reporting.documents');
      }

      return this.transitionTo('/');
    }
  });

  _exports.default = _default;
});