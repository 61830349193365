define("lh4/mirage/factories/location", ["exports", "ember-cli-mirage", "faker", "lh4/enums/country-code"], function (_exports, _emberCliMirage, _faker, _countryCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    merchantId: function merchantId(i) {
      return String(i).padStart(10, '0');
    },
    name: function name() {
      return _faker.default.company.companyName();
    },
    sicCode: '0000000',
    phone: function phone() {
      return _faker.default.phone.phoneNumber();
    },
    email: function email() {
      return _faker.default.internet.email();
    },
    website: function website() {
      return _faker.default.internet.domainName();
    },
    addressLine1: function addressLine1() {
      return _faker.default.address.streetAddress();
    },
    addressLine2: function addressLine2() {
      return _faker.default.address.streetAddress();
    },
    city: function city() {
      return _faker.default.address.city();
    },
    state: function state() {
      return _faker.default.address.state();
    },
    zip: function zip() {
      return _faker.default.address.zipCode();
    },
    country: function country() {
      return _faker.default.address.country();
    },
    product: function product() {
      return 'echo-pro';
    },
    terminalProducts: [],
    services: [],
    locationKey: null,
    logoUrl: function logoUrl() {
      return '//lighthouse-image-uploads.s3.amazonaws.com/development/263-logo.png';
    },
    usesShift4PaymentProcessor: function usesShift4PaymentProcessor() {
      return this.product === 'internet-payments';
    },
    features: {
      loyalty1: true,
      newTimeClockManagement: true
    },
    countryCode: _countryCode.default.US
  });

  _exports.default = _default;
});