define("lh4/templates/authenticated/internet-payments/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D2v03uWz",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"error-modal\",null,[[\"model\"],[[24,[\"model\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/error.hbs"
    }
  });

  _exports.default = _default;
});