define("lh4/components/updates/update-edit-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    classNames: ['update-edit-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    minDate: Ember.computed('model.menuVersionList', 'model.menuVersion', function getter() {
      var updateList = this.get('model.menuVersionList');
      var update = this.get('model.menuVersion');
      var currentDate = (0, _moment.default)().format('YYYY-MM-DD');
      var dateList = [currentDate].concat(_toConsumableArray(updateList.map(function (item) {
        return item.scheduledFor;
      }))).filter(function (item) {
        return item < update.scheduledFor;
      });
      return update.status === 'error' || update.status === 'complete' || update.instant ? null : (0, _moment.default)(Math.max.apply(Math, _toConsumableArray(dateList.map(function (date) {
        return new Date(date);
      })))).add(2, 'day');
    })
  });

  _exports.default = _default;
});