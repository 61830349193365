define("lh4/routes/authenticated/reporting/subscriptions/edit-subscription", ["exports", "ember-changeset", "ember-changeset-cp-validations", "lh4/mixins/changeset-transition-route-mixin"], function (_exports, _emberChangeset, _emberChangesetCpValidations, _changesetTransitionRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, {
    modelName: 'subscription',
    childChangesets: {
      reportSubscriptionRecipients: 'reportSubscriptionRecipientsChangesets'
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations,
        subscription: this.store.peekRecord('reports/subscription', params.subscriptionId),
        products: this.modelFor('authenticated.reporting.subscriptions').products,
        reportTypes: this.modelFor('authenticated.reporting.subscriptions').reportTypes
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model.subscription),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(model.subscription, validateFn, validationMap, {
        skipValidate: true
      });
      changeset.reportSubscriptionRecipientsChangesets = model.subscription.reportSubscriptionRecipients.map(this.buildChildChangeset);
      controller.set('changeset', changeset);
      var modelName = this.modelName,
          childChangesets = this.childChangesets; // clears main model created in create routes in case model was not saved

      var clearTemporaryMainModel = function clearTemporaryMainModel() {
        if (model[modelName].isNew) {
          model[modelName].unloadRecord();
        }
      };

      if (!childChangesets) {
        return;
      } // set loaded data on controller to check if anything changed when exiting


      var childChangesetsSnapshotMap = {}; // build child changesets

      Object.keys(childChangesets).forEach(function (childModelName) {
        var childChangesetName = childChangesets[childModelName];
        changeset[childChangesetName] = model[modelName][childModelName].map(_this.buildChildChangeset);
        childChangesetsSnapshotMap[childChangesetName] = changeset[childChangesetName].map(function (c) {
          return JSON.stringify(c.data.toJSON());
        });
      }); // Clears new models and changesets after save
      // to prevent unsaved changes detection and to cleanup objects that
      // are no longer used

      var clearTemporaryData = function clearTemporaryData() {
        Object.keys(childChangesets).forEach(function (childModelName) {
          var childChangesetName = childChangesets[childModelName]; // unload new records that were created when creating new childChangesets

          model[modelName][childModelName].filter(function (m) {
            return m.isNew;
          }).forEach(function (m) {
            return m.unloadRecord();
          }); // update childChangesetsSnapshots to match childChangesets
          // this prevents false positives when detecting unsaved changes

          childChangesetsSnapshotMap[childChangesetName] = changeset[childChangesetName].map(function (c) {
            return JSON.stringify(c.data.toJSON());
          });
        }); // used after clearing child records as unloading main model leaves child models in !isNew state

        clearTemporaryMainModel();
      };

      controller.setProperties({
        childChangesetsSnapshotMap: childChangesetsSnapshotMap,
        clearTemporaryData: clearTemporaryData,
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        }
      });
    },
    buildChildChangeset: function buildChildChangeset(model) {
      var _buildChangeset2 = (0, _emberChangesetCpValidations.buildChangeset)(model),
          validateFn = _buildChangeset2.validateFn,
          validationMap = _buildChangeset2.validationMap;

      return new _emberChangeset.default(model, validateFn, validationMap, {
        skipValidate: true
      });
    }
  });

  _exports.default = _default;
});