define("lh4/components/echo-pro/tableside/device-setup-wizard", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var firstStep = 1;
  var lastStep = 6;

  var _toNextStep = function toNextStep(context) {
    var step = context.get('step');

    if (step >= lastStep) {
      return;
    }

    context.incrementProperty('step');
  };

  var _toPreviousStep = function toPreviousStep(context) {
    var step = context.get('step');

    if (step <= firstStep) {
      return;
    }

    context.decrementProperty('step');
  };

  var _default = Ember.Component.extend({
    dropdownId: 'wizard-dropdown',
    ajax: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service('flash-messages-helper'),
    responseMessages: Ember.inject.service('response-messages'),
    step: 1,
    lastStep: Ember.computed(function () {
      return lastStep;
    }).readOnly(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('#device-wizard').modal('show');
      (0, _jquery.default)('#device-wizard-cancel').modal('attach events', '#device-wizard .remove');
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)('#device-wizard').modal('hide');

      this._super.apply(this, arguments);
    },
    actions: {
      toNextStep: function toNextStep() {
        _toNextStep(this);
      },
      toPreviousStep: function toPreviousStep() {
        _toPreviousStep(this);
      },
      handleError: function handleError(message) {
        this.flashMessagesHelper.clearMessages();
        this.flashMessagesHelper.pushMessage(message, 'negative');
      },
      createTerminal: function createTerminal() {
        var _this = this;

        this.get('createTerminalHandler')(this.get('terminal')).then(function () {
          _toNextStep(_this);

          _this.flashMessagesHelper.clearMessages();

          _this.flashMessagesHelper.pushMessage('Successfully created Tableside Device', 'success');
        }).catch(function (messages) {
          _this.flashMessagesHelper.pushMessages(messages, 'error');
        });
      },
      finishWizard: function finishWizard() {
        this.sendAction('onFinish');
      },
      onCancelWizard: function onCancelWizard() {
        this.sendAction('onCancel');
      },
      changePrinter: function changePrinter(printer) {
        var _this2 = this;

        (0, _jquery.default)("#".concat(this.get('dropdownId'))).addClass('loading disabled');
        this.ajax.post('/api/v1/echo-pro/printers/default/tableside', {
          data: JSON.stringify({
            locationId: printer.get('locationId'),
            guid: printer.get('id')
          })
        }).then(function () {
          return _this2.get('printers').forEach(function (p) {
            return p.set('isDefault', p === printer);
          });
        }).catch(function (err) {
          _this2.flashMessagesHelper.pushMessages(_this2.responseMessages.getResponseErrors(err));
        }).finally(function () {
          (0, _jquery.default)("#".concat(_this2.get('dropdownId'))).removeClass('loading disabled');
        });
      }
    }
  });

  _exports.default = _default;
});