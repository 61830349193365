define("lh4/templates/components/internet-payments/reporting/reports/report-card-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j/o21Aab",
    "block": "{\"symbols\":[\"report\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"report-card-group\"],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[10,\"data-test\",\"ip-report-card-group-title\"],[8],[1,[23,0,[\"args\",\"groupTitle\"]],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"card-list\"],[10,\"data-test\",\"ip-report-card-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"args\",\"reports\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"internet-payments/reporting/reports/report-card\",[],[[\"@title\",\"@icon\",\"@description\",\"@onGenerateReport\"],[[23,1,[\"name\"]],[23,1,[\"slug\"]],[23,1,[\"description\"]],[28,\"fn\",[[23,0,[\"onGenerateReport\"]],[23,1,[\"slug\"]],[23,1,[\"params\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/reporting/reports/report-card-group.hbs"
    }
  });

  _exports.default = _default;
});