define("lh4/templates/components/internet-payments/ui/exporting-tray-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LZ+W7sSW",
    "block": "{\"symbols\":[\"@from\",\"@to\",\"@maxDate\",\"@ranges\",\"@maxDays\"],\"statements\":[[4,\"if\",[[23,0,[\"media\",\"isMobile\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"ui/dates/mobile-datetime-range-picker\",[],[[\"@class\",\"@start\",\"@end\",\"@maxDays\",\"@showTimePickers\",\"@rangeTitle\",\"@ranges\",\"@isStarlink\",\"@onChange\"],[\"customer-mobile-datepicker\",[23,1,[]],[23,2,[]],[23,5,[]],false,\"@rangeTitle\",[23,4,[]],true,[28,\"action\",[[23,0,[]],\"onMobileRangeChange\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[5,\"internet-payments/ui/dates/datetime-range-picker\",[[12,\"onfirstselect\",[28,\"action\",[[23,0,[]],\"onFirstSelect\"],null]],[12,\"onafterselect\",[28,\"action\",[[23,0,[]],\"onAfterSelect\"],null]],[12,\"onBlur\",[28,\"action\",[[23,0,[]],\"onDesktopBlur\"],null]]],[[\"@start\",\"@end\",\"@maxDays\",\"@ranges\",\"@onDateRangeChanged\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[28,\"action\",[[23,0,[]],\"onRangeSelect\"],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/exporting-tray-date-picker.hbs"
    }
  });

  _exports.default = _default;
});