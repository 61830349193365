define("lh4/mirage/factories/integration/s4/api-key", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    environment: 'Development',
    expireDate: _faker.default.date.future(),
    key: function key() {
      return Buffer.from(_faker.default.datatype.string(16)).toString('hex');
    },
    locationId: 1,
    notificationEmail: null
  });

  _exports.default = _default;
});