define("lh4/mirage/routes/social-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageSocialMediaServer;

  function setMirageSocialMediaServer(server) {
    // Customer Engagement
    server.get('social-media/accounts', function (schema) {
      return {
        accounts: schema.socialMediaAccounts.all().models
      };
    });
  }
});