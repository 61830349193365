define("lh4/components/social-media/post-preview/post-footer-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui secondary segment post-meta']
  });

  _exports.default = _default;
});