define("lh4/utils/menu-handle-changeset-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Update changeset after values selected from dropdowns
   *
   * @param {array} attrs - change set attributes
   * @param {number} value - model value
   */
  var updateChangeset = function updateChangeset(attrs, value) {
    attrs.forEach(function (attr) {
      if (value[attr[0]].length !== value[attr[1]].length) {
        var ids = value[attr[0]].map(function (modifier) {
          return modifier.id;
        });
        value.set(attr[1], ids);
      }
    });
  };

  var _default = updateChangeset;
  _exports.default = _default;
});