define("lh4/templates/authenticated/settings/users/create-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZoQbKAuq",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"modal/user-modal\",null,[[\"visible\",\"user\",\"permissions\",\"locations\",\"type\",\"permissionSets\",\"loading\",\"modalDeny\",\"saveUser\"],[true,[24,[\"changeset\"]],[24,[\"model\",\"permissions\"]],[24,[\"model\",\"locations\"]],\"create\",[24,[\"permissionSets\"]],[24,[\"loading\"]],[28,\"action\",[[23,0,[]],\"modalDeny\"],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/settings/users/create-user.hbs"
    }
  });

  _exports.default = _default;
});