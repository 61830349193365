define("lh4/controllers/authenticated/social-media/analytics/account/index", ["exports", "moment", "jquery", "d3-format", "lh4/services/social-media/facebook", "lh4/services/social-media/twitter"], function (_exports, _moment, _jquery, _d3Format, _facebook, _twitter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    // init() {
    // 	this._super(...arguments);
    // 	Ember.run.schedule('afterRender', this, function() {
    // 		$('.charts-tab-menu .item').tab();
    // 	});
    // },
    queryParams: ['from', 'to'],
    isChartsLoading: false,
    postsSortByMap: Ember.computed('model.account.type', function getter() {
      var accType = this.get('model.account.type');
      var analyticsFields;

      switch (accType) {
        case _twitter.ACCOUNT_TYPE_TWITTER:
          analyticsFields = _twitter.ANALYTICS_FIELDS;
          break;

        case _facebook.ACCOUNT_TYPE_FB_PAGE:
        default:
          analyticsFields = _facebook.ANALYTICS_FIELDS;
          break;
      }

      var sortMap = [{
        publishTime: 'Date'
      }];
      analyticsFields.forEach(function (value) {
        if (value !== 'totalViews') {
          sortMap.push(_defineProperty({}, "analytics.".concat(value), value.capitalize()));
        } else {
          sortMap.push(_defineProperty({}, "analytics.".concat(value), 'Reached'));
        }
      });
      return sortMap;
    }),
    accountApi: Ember.inject.service('social-media/account-api'),
    facebook: Ember.inject.service('social-media/facebook'),
    errorHandler: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    isLoading: false,
    maxDate: (0, _moment.default)().toDate(),
    from: (0, _moment.default)().startOf('week').format('YYYY-MM-DD'),
    to: (0, _moment.default)().endOf('week').format('YYYY-MM-DD'),
    chartYNamesMap: {
      Impressions: {
        key: 'totalViews'
      }
    },
    accountType: Ember.computed('model.account.type', function getter() {
      switch (this.get('model.account.type')) {
        case _facebook.ACCOUNT_TYPE_FB_PAGE:
        case _facebook.ACCOUNT_TYPE_FB_GROUP:
        case _facebook.ACCOUNT_TYPE_FB_PROFILE:
          return 'facebook';

        case _twitter.ACCOUNT_TYPE_TWITTER:
          return 'twitter';

        default:
          return '';
      }
    }),
    impressionsTotalCount: Ember.computed('model.analytics.graphData.data.totalViews.[]', function getter() {
      var totalViewsArray = this.get('model.analytics.graphData.data.totalViews');

      if (!totalViewsArray) {
        return null;
      }

      var totalViews = totalViewsArray.reduce(function (prev, curr) {
        return prev + curr.value;
      }, 0);

      if (totalViews >= 1000) {
        return (0, _d3Format.format)('.2s')(totalViews);
      }

      return totalViews;
    }),
    rateAverage: Ember.computed('model.analytics.graphData.data.rate.[]', function getter() {
      var rates = this.get('model.analytics.graphData.data.rate');

      if (!rates) {
        return null;
      }

      var rateTotals = rates.reduce(function (prev, curr) {
        return prev + curr.value;
      }, 0);
      return Math.round(rateTotals / rates.length);
    }),
    likesTotalCount: Ember.computed('model.analytics.graphData.data.likes.[]', function getter() {
      var likes = this.get('model.analytics.graphData.data.likes');

      if (!likes) {
        return null;
      }

      return likes.reduce(function (prev, curr) {
        return prev + curr.value;
      }, 0);
    }),
    engagementTotalCount: Ember.computed('model.analytics.graphData.data.engagement.[]', function getter() {
      var engagements = this.get('model.analytics.graphData.data.engagement');

      if (!engagements) {
        return null;
      }

      return engagements.reduce(function (prev, curr) {
        return prev + curr.value;
      }, 0);
    }),
    sharesTotalCount: Ember.computed('model.analytics.graphData.data.shares.[]', function getter() {
      var shares = this.get('model.analytics.graphData.data.shares');

      if (!shares) {
        return null;
      }

      return shares.reduce(function (prev, curr) {
        return prev + curr.value;
      }, 0);
    }),
    commentsTotalCount: Ember.computed('model.analytics.graphData.data.comments.[]', function getter() {
      var comments = this.get('model.analytics.graphData.data.comments');

      if (!comments) {
        return null;
      }

      return comments.reduce(function (prev, curr) {
        return prev + curr.value;
      }, 0);
    }),
    _postsSortBy: 'publishTime',
    _postsSortDesc: true,
    postsSortBy: Ember.computed('model.analytics.postData.posts.[]', '_postsSortBy', '_postsSortDesc', function getter() {
      var sortBy = this.get('_postsSortBy');
      var sortDesc = this.get('_postsSortDesc') ? ':desc' : '';
      return ["".concat(sortBy).concat(sortDesc)];
    }),
    postsSorted: Ember.computed.sort('model.analytics.postData.posts', 'postsSortBy'),
    actions: {
      postsSortBy: function postsSortBy(selected) {
        if (this.get('_postsSortBy') === selected) {
          this.toggleProperty('_postsSortDesc');
        } else {
          this.set('_postsSortBy', selected);
        }
      },
      postsSortOrder: function postsSortOrder() {
        this.toggleProperty('_postsSortDesc');
      },
      selectAccount: function selectAccount(selectedHTML, value) {
        if (this.get('model.account.id') !== parseInt(value, 10)) {
          this.transitionToRoute('authenticated.social-media.analytics.account', value);
        } else {
          (0, _jquery.default)('.ui.dropdown.accounts-dropdown').dropdown('hide');
        }
      },
      onDateChange: function onDateChange(_ref) {
        var from = _ref.from,
            to = _ref.to;
        this.set('from', (0, _moment.default)(from).format('YYYY-MM-DD'));
        this.set('to', (0, _moment.default)(to).format('YYYY-MM-DD'));
        this.send('loadMore');
      },
      relinkAccount: function relinkAccount() {
        this.facebook.redirectToAccountRelink(this.get('model.account'));
      },
      mobileChartTabSelect: function mobileChartTabSelect(chartName) {
        (0, _jquery.default)('.charts-tab-menu .item.active').removeClass('active');
        (0, _jquery.default)(window.event.target).addClass('active');
        (0, _jquery.default)('.analytics-content .analytics-chart').removeClass('active');
        var chartSelector = (0, _jquery.default)(".analytics-content .analytics-chart[data-chart-name=".concat(chartName, "]"));
        chartSelector.addClass('active');
        var chartId = chartSelector.find('.date-range-chart-wrapper').attr('id');
        (0, _jquery.default)(window).trigger("resize.#".concat(chartId));
      },
      loadMore: function loadMore() {
        var _this = this;

        if (this.get('isLoading') || this.get('isChartsLoading')) {
          return;
        }

        var pagination = this.get('model.analytics.postData.meta.pagination');
        var dateRange = {
          from: this.get('from'),
          to: this.get('to')
        };
        var isDateChanged = dateRange.from !== pagination.from || dateRange.to !== pagination.to;

        if (isDateChanged || pagination.after) {
          this.set('isLoading', true);
          var cursor = isDateChanged ? null : pagination.after;
          this.accountApi.loadPosts(this.get('model.account.id'), dateRange, 100, cursor).then(function (_ref2) {
            var posts = _ref2.posts,
                meta = _ref2.meta;

            if (isDateChanged) {
              _this.set('model.analytics.postData.posts', posts);
            } else {
              _this.get('model.analytics.postData.posts').pushObjects(posts);
            }

            _this.set('model.analytics.postData.meta.pagination', meta.pagination);
          }).catch(function (err) {
            _this.set('model.analytics.postData', {
              posts: []
            });

            _this.errorHandler.handleResponseError(err, 'Could not load account posts');
          }).finally(function () {
            _this.set('isLoading', false);
          });
        }

        if (isDateChanged) {
          this.set('isChartsLoading', true);
          this.accountApi.loadAnalytics(this.get('model.account.id'), dateRange).then(function (response) {
            _this.set('model.analytics.graphData', response);
          }).catch(function (err) {
            _this.set('model.analytics.graphData', {
              data: []
            });

            _this.errorHandler.handleResponseError(err, 'Could not analytics information');
          }).finally(function () {
            _this.set('isChartsLoading', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});