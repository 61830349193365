define("lh4/mirage/routes/internet-payments/insights/cards", ["exports", "lh4/mirage/fixtures/internet-payments/insights/cards"], function (_exports, _cards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsInsightsCardsServer;

  function setMirageInternetPaymentsInsightsCardsServer(server) {
    server.get('/internet-payments/insights/cards', function () {
      return _cards.INSIGHTS_CARDS_FIXTURE;
    });
  }
});