define("lh4/controllers/authenticated/locations/location/hospitality/manage-employees/edit-employee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MESSAGE_CANCELED = 'unexpected error occurred: Platform Client responded with error: 500: message was cancelled';

  var _default = Ember.Controller.extend({
    logger: Ember.inject.service(),
    isLoading: false,
    setLoading: Ember.computed('isLoading', function getter() {
      return this.get('isLoading') ? 'loading' : '';
    }),
    tabs: ['Employee Information', 'Security Settings', 'Jobs'],
    actions: {
      save: function save() {
        var _this = this;

        var editEmployee = this.get('model.employee');
        this.set('isLoading', true);
        editEmployee.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            editEmployee.save().then(function () {
              _this.set('isLoading', false);

              _this.transitionToRoute('authenticated.locations.location.hospitality.manage-employees');

              _this.logger.log('Employee edit saved');
            }).catch(function (err) {
              _this.set('isLoading', false);

              _this.logger.log("Error: ".concat(err));

              if (err.errors[0].message === MESSAGE_CANCELED) {
                _this.flashMessages.danger('Terminal is busy', {
                  class: 'ui negative message'
                });
              } else if (err.errors[0].message === 'unexpected error occurred: login must be unique') {
                _this.flashMessages.danger('Login pin must be unique.', {
                  class: 'ui negative message'
                });
              } else {
                _this.flashMessages.danger('Error connecting to terminal. Please make sure your unit is online.', {
                  class: 'ui negative message'
                });
              }
            });
          } else {
            return Ember.RSVP.Promise.reject(validations);
          }

          return false;
        }).catch(function (validations) {
          _this.set('isLoading', false);

          validations.forEach(function (v) {
            if (v.get('message')) {
              _this.flashMessages.danger("".concat(v.get('message'), "."), {
                class: 'ui negative message'
              });
            }
          });
        });
      },
      clear: function clear() {
        this.get('model.employee').rollbackAttributes();
        this.transitionToRoute('authenticated.locations.location.hospitality.manage-employees');
      }
    }
  });

  _exports.default = _default;
});