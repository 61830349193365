define("lh4/controllers/authenticated/customer-engagement/campaigns/edit", ["exports", "jquery", "lh4/mixins/controllers/customer-engagement/edit-controller-mixin"], function (_exports, _jquery, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {
    actions: {
      notifyAboutStatusChange: function notifyAboutStatusChange() {
        Ember.run.next(function () {
          (0, _jquery.default)('#campaign-status-changed').modal('show');
        });
      }
    }
  });

  _exports.default = _default;
});