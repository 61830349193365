define("lh4/mirage/factories/epro-surcharge", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    locationId: 1,
    name: function name() {
      return _faker.default.commerce.product();
    },
    value: 1,
    isFlatAmount: true,
    useGross: false,
    gratuity: false,
    isPaidTipsFromDrawer: true,
    isActive: true
  });

  _exports.default = _default;
});