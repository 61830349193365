define("lh4/enums/date-range-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isCustom = _exports.isCurrentBusinessDay = _exports.DATE_RANGE_TITLE = void 0;
  var DATE_RANGE_TITLE = Object.freeze({
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    CUSTOM: 'Custom',
    BUSINESS_DAY: Object.freeze({
      CURRENT: 'Current business day',
      LAST: 'Last business day'
    }),
    WEEK: Object.freeze({
      THIS: 'This week',
      TO_DATE: 'Week to date',
      LAST: 'Last week'
    }),
    WEEKS: Object.freeze({
      TWO: 'Two weeks',
      TWO_TO_DATE: 'Two weeks to date'
    }),
    MONTH: Object.freeze({
      THIS: 'This month',
      TO_DATE: 'Month to date',
      LAST: 'Last month'
    }),
    SEVEN_DAYS: Object.freeze({
      THIS: '7 Days',
      TO_DATE: '7 Days to date',
      LAST: 'Last 7 Days'
    }),
    THIRTY_DAYS: Object.freeze({
      THIS: '30 Days',
      TO_DATE: '30 Days to date',
      LAST: 'Last 30 Days'
    }),
    NINETY_DAYS: Object.freeze({
      THIS: '90 Days',
      TO_DATE: '90 Days to date',
      LAST: 'Last 90 Days'
    }),
    YEAR: Object.freeze({
      THIS: 'This year',
      TO_DATE: 'Year to date',
      LAST: 'Last year'
    })
  });
  /**
   * Check if value is current business day. Case insensitive.
   *
   * @param {string} value - value to check
   * @returns {boolean} - true if value is current business day
   */

  _exports.DATE_RANGE_TITLE = DATE_RANGE_TITLE;

  var isCurrentBusinessDay = function isCurrentBusinessDay(value) {
    return String(value).toLowerCase() === DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT.toLowerCase();
  };
  /**
   * Check if value is custom. Case insensitive.
   *
   * @param {string} value - value to check
   * @returns {boolean} - true if value is current business day
   */


  _exports.isCurrentBusinessDay = isCurrentBusinessDay;

  var isCustom = function isCustom(value) {
    return String(value).toLowerCase() === DATE_RANGE_TITLE.CUSTOM.toLowerCase();
  };

  _exports.isCustom = isCustom;
});