define("lh4/controllers/authenticated/supply-orders/location/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    supplyCart: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ajax: Ember.inject.service(),
    submitOrderLoading: false,
    loadingShipping: '',
    actions: {
      productTransition: function productTransition() {
        this.transitionToRoute('authenticated.supply-orders.location.supplies');
      },
      selectShipping: function selectShipping() {
        var _this = this;

        var data = {
          addressId: this.get('supplyCart.addressId'),
          serviceId: this.get('supplyCart.serviceId'),
          products: this.get('supplyCart.orderDetails')
        };
        this.set('loadingShipping', 'active');
        this.ajax.request('/api/v1/supply-orders/shipping-orders', {
          type: 'POST',
          data: JSON.stringify({
            shippingOrder: data
          })
        }).then(function (orderObj) {
          _this.set('supplyCart.shippingOrderId', orderObj.order.id);

          _this.set('supplyCart.rate', orderObj.order.rate);

          _this.set('loadingShipping', '');
        }).catch(function () {
          _this.flashMessages.danger('Shipping information request failed', {
            class: 'ui error message'
          });

          _this.set('loadingShipping', '');
        });
      },
      submitOrder: function submitOrder() {
        var _this2 = this;

        this.set('submitOrderLoading', true);
        var data = {
          mid: this.get('supplyCart.locationId'),
          addressId: this.get('supplyCart.addressId'),
          serviceId: this.get('supplyCart.serviceId'),
          shippingOrderId: this.get('supplyCart.shippingOrderId'),
          orderDetails: this.get('supplyCart.orderDetails'),
          orderComments: []
        };

        if (this.get('supplyCart.comment').trim()) {
          data.orderComments.push({
            comment: this.get('supplyCart.comment')
          });
        }

        this.ajax.request('/api/v1/supply-orders/orders', {
          type: 'POST',
          data: JSON.stringify({
            order: data
          })
        }).then(function () {
          _this2.flashMessages.success('Your order has been placed!', {
            class: 'ui success message'
          });

          setTimeout(function () {
            window.location.reload();

            _this2.set('submitOrderLoading', false);
          }, 3000);
        }).catch(function () {
          _this2.set('submitOrderLoading', false);

          _this2.flashMessages.danger('Order submission failed', {
            class: 'ui error message'
          });
        });
      }
    }
  });

  _exports.default = _default;
});