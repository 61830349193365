define("lh4/routes/authenticated/loyalty/promotions", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/routes/array-qp-serialization-fix-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin, _lighthousePermissionsMixin, _arrayQpSerializationFixMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, _lighthousePermissionsMixin.default, _arrayQpSerializationFixMixin.default, {
    requiredPermissions: 'view promotions in loyalty',
    location: Ember.inject.service(),
    queryParams: {
      loyaltyProgramGuid: {
        refreshModel: true
      },
      isActive: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      searchName: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        loyaltyProgramGuid: params.loyaltyProgramGuid,
        loyaltyPromotions: this.store.query('loyalty/promotion', params),
        programs: this.store.query('loyalty/program', {
          order: 'name',
          sort: 'asc',
          limit: 'all'
        })
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      if (!model.loyaltyProgramGuid.length) {
        var loyaltyProgramGuid = model.programs.map(function (program) {
          return program.guid;
        });
        controller.set('loyaltyProgramGuid', loyaltyProgramGuid);
        this.replaceWith({
          queryParams: {
            loyaltyProgramGuid: loyaltyProgramGuid
          }
        });
      }
    }
  });

  _exports.default = _default;
});