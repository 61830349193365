define("lh4/routes/authenticated/settings/users/create-user", ["exports", "lh4/mixins/authenticated-route-mixin", "ember-changeset", "ember-changeset-cp-validations"], function (_exports, _authenticatedRouteMixin, _emberChangeset, _emberChangesetCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.store.createRecord('settings/user'),
        permissions: this.store.findAll('accounts/permission'),
        locations: this.modelFor('authenticated').locations,
        permissionTemplates: this.store.findAll('settings/user-permission-template')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model.user),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(model.user, validateFn, validationMap, {
        skipValidate: true
      });
      controller.set('changeset', changeset);
    }
  });

  _exports.default = _default;
});