define("lh4/routes/authenticated/transactions/master-transactions/edit/refund", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/config/environment"], function (_exports, _lighthousePermissionsMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lighthousePermissionsMixin.default, {
    requiredPermissions: ['refund transactions in general'],
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    model: function model(_, transition) {
      var _this$store$peekRecor = this.store.peekRecord('internet-payments/transaction', transition.to.parent.params.transactionId),
          invoice = _this$store$peekRecor.invoice,
          cardToken = _this$store$peekRecor.cardToken,
          total = _this$store$peekRecor.amount;

      var qs = new URLSearchParams({
        token: this.tokenData.data.token,
        locationId: this.location.model.id,
        type: 'refund',
        invoice: invoice,
        cardToken: cardToken,
        tipAmount: 0,
        total: total
      });
      return Ember.RSVP.hash({
        origin: this.modelFor('authenticated.transactions.master-transactions').origin,
        url: "".concat(_environment.default.host, "/api/v1/virtual-terminal/url?").concat(qs.toString())
      });
    },
    setupController: function setupController(controller, model, transition) {
      var _this = this;

      this._super(controller, model, transition);

      var closeRoute = transition.from ? transition.from.name : 'authenticated.transactions.master-transactions';
      controller.setProperties({
        closeRoute: closeRoute,
        hasLoaded: false
      });

      if (model.origin) {
        this.onIframeClose = function (event) {
          if (event.origin === model.origin) {
            // Make a call for the parent route to refresh since we know
            // the child iframe has gotten to a point where data has been changed
            _this.send('generateNewSearch');

            _this.transitionTo(closeRoute);
          }
        };

        window.addEventListener('message', this.onIframeClose, false);
      }
    },
    deactivate: function deactivate() {
      window.removeEventListener('message', this.onIframeClose);
    }
  });

  _exports.default = _default;
});