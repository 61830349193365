define("lh4/routes/authenticated/epro/payments", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    intl: Ember.inject.service(),
    requiredPermissions: 'manage echo-pro/payment',
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.titleToken = this.intl.t('routes:authenticated:epro:payments:title:token');
      controller.setProperties({
        routeData: [{
          name: 'authenticated.epro.payments.tenders',
          title: this.intl.t('controllers:authenticated:epro:payments:route:tender')
        }, {
          name: 'authenticated.epro.payments.discounts',
          title: this.intl.t('controllers:authenticated:epro:payments:route:discount')
        }]
      });
    }
  });

  _exports.default = _default;
});