define("lh4/serializers/echo-pro/time-clock-shift", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    attrs: {
      timeClockSegments: {
        embedded: 'always'
      }
    },
    normalize: function normalize() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var json = args[1];
      json.isLegacyRecord = !json.payPeriodGuid;
      return this._super.apply(this, args);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.isLegacyRecord;
      return json;
    }
  });

  _exports.default = _default;
});