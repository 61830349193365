define("lh4/controllers/authenticated/settings/reporting-settings", ["exports", "lh4/mixins/controllers/discard-changes-mixin", "moment"], function (_exports, _discardChangesMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_discardChangesMixin.default, {
    modelNameToTrack: 'model.preferences',
    days: _moment.default.weekdays(),
    selectedCountry: null,
    actions: {
      selectWeekStart: function selectWeekStart(value) {
        // this is done with an action so that the value can be stored as an integer
        this.set('model.preferences.weekStart', parseInt(value, 10));
      },
      selectCountry: function selectCountry(value) {
        var _this = this;

        if (this.selectedCountry !== value) {
          this.set('selectedCountry', value);
          this.set('timeZones', this.setTimeZones(value, this.model.timeZonesByCountry));
          var foundTimeZoneInArray = this.model.timeZonesByCountry[this.selectedCountry].find(function (element) {
            return element.name === _this.model.preferences.timeZone;
          });

          if (!foundTimeZoneInArray) {
            Ember.run.next(this, function callback() {
              this.set('model.preferences.timeZone', this.model.timeZonesByCountry[this.selectedCountry][0].name);
            });
          }
        }
      },
      selectTimeZone: function selectTimeZone(value) {
        // `next` is being used because changing this value while the dropdown
        // menu is open prevents it from hiding.
        Ember.run.next(this, function callback() {
          this.set('model.preferences.timeZone', value);
        });
      },
      savePreferences: function savePreferences() {
        var _this2 = this;

        this.set('loading', true);
        this.model.preferences.save().then(function () {
          _this2.flashMessages.success('Settings saved.', {
            class: 'ui success message'
          });
        }).catch(function () {
          _this2.flashMessages.danger('Settings failed to save.', {
            class: 'ui error message settings-message'
          });
        }).finally(function () {
          return _this2.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});