define("lh4/controllers/authenticated/epro/menu/modifier-sets/create", ["exports", "lh4/controllers/general-form-controller", "jquery"], function (_exports, _generalFormController, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    metrics: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    location: Ember.inject.service(),
    saveChangesets: function saveChangesets(changesets) {
      var _this = this;

      /*
      	Run through and execute each changeset
      	Rollback internal list of changes
      	Push record into modifiersMap array
      */
      changesets.forEach(function (record) {
        record.execute();
        record.rollback();

        _this.model.modifierSet.modifiersMap.pushObject(record.data);
      });
    },
    removeRecordsFromModel: function removeRecordsFromModel(model, changesets) {
      var _this2 = this;

      model.filter(function (_ref) {
        var modifierGuid = _ref.modifierGuid;
        return !changesets.findBy('modifierGuid', modifierGuid);
      }).forEach(function (record) {
        return _this2.model.modifierSet.modifiersMap.removeObject(record);
      });
    },
    duplicateModifierSet: function duplicateModifierSet(clone) {
      this.transitionToRoute('authenticated.epro.menu.modifier-sets.create', {
        queryParams: {
          clone: clone
        }
      });
      (0, _jquery.default)('.fullscreen-modal > .content').animate({
        scrollTop: 0
      }, 'fast');
    },
    trackUpdateCompleted: function trackUpdateCompleted() {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Completed',
        'Entry Type': 'New',
        'Entry ID': this.changeset.get('id'),
        'Number of Updates': Object.keys(this.changeset.change).length,
        'Entries Updated': Object.keys(this.changeset.change),
        'Menu Component': 'Modifier Set'
      });
    },
    actions: {
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      create: function create() {
        var _this3 = this;

        var cloning = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError();

        if (this.changeset.get('isRequired') && this.changeset.mapChangesets.length < this.changeset.get('minModifiersPerSet')) {
          // Add error when Modifier is required is set and no modifiers are being selected
          this.changeset.addError('modifiersMap', 'Should add not less modifiers than minimum required amount per set');
        } else {
          this.changeset.mapChangesets.set('modifiersMap', this.changeset.mapChangesets);
          this.changeset.rollbackInvalid('modifiersMap');
        } // we have to check for pristine before we validate and save


        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.modifierSet.modifiersMap, this.changeset.mapChangesets);
        this.changeset.validate().then(function () {
          if (_this3.changeset.isInvalid) {
            return Ember.RSVP.reject(_this3.changeset.errors);
          }

          _this3.trackUpdateCompleted();

          _this3.removeRecordsFromModel(_this3.model.modifierSet.modifiersMap, _this3.changeset.mapChangesets);

          if (isPristine) {
            _this3.clearTemporaryData();

            if (cloning) {
              _this3.duplicateModifierSet(_this3.changeset.get('id'));
            } else {
              _this3.closeModal();
            }

            return Ember.RSVP.resolve();
          }

          _this3.saveChangesets(_this3.changeset.mapChangesets);

          return _this3.changeset.save().then(function (data) {
            _this3.clearTemporaryData();

            if (cloning) {
              _this3.duplicateModifierSet(data.id);
            } else {
              _this3.closeModal();
            }
          }).catch(function (_ref2) {
            var errors = _ref2.errors;

            if (!(errors && errors[0])) {
              return;
            } // TODO normalize how we handle these error messages


            var message = "Something went wrong, ".concat(_this3.instanceName, " could not be created.");
            errors.forEach(function (error) {
              var errorMessage = error.details && error.details[0] || error.message || message;

              _this3.set('serverError', errorMessage);

              _this3.set('serverErrorTimeoutId', Ember.run.later(_this3, function () {
                _this3.set('serverError', null);
              }, _this3.serverErrorTimeoutDelay));
            });
          });
        }).catch(function (errors) {
          // We currently don't have a field component for checkboxes
          // Because of this there is no standard way of displaying
          // error messages for boolean fields
          // https://jira.shift4payments.com/browse/LH-8853
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this3.set('booleanError', booleanError.validation);

              _this3.set('serverErrorTimeoutId', Ember.run.later(_this3, function () {
                _this3.set('booleanError', null);
              }, _this3.serverErrorTimeoutDelay));
            }
          }
        }).finally(function () {
          return _this3.set('isSaving', false);
        });
      },
      createAndDuplicate: function createAndDuplicate() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.send.apply(this, ['create', true].concat(args));
      }
    }
  });

  _exports.default = _default;
});