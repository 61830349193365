define("lh4/abilities/gift-card", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin"], function (_exports, _emberCan, _abilityMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    canManage: (0, _abilityMixin.computedAbility)(function getter() {
      var hasRightProduct = this.hasProduct('echo-pro') || this.hasProduct('hospitality');
      var hasServicePermission = this.hasService('gift-cards') && this.hasPermission(6);
      return hasRightProduct && hasServicePermission;
    })
  });

  _exports.default = _default;
});