define("lh4/controllers/authenticated/solo/items/edit", ["exports", "jquery", "lh4/controllers/general-form-controller"], function (_exports, _jquery, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    actions: {
      delete: function _delete(itemId) {
        var _this = this;

        this.changeset.rollback();
        var item = this.store.peekRecord('solo/item', itemId);
        item.destroyRecord().then(function () {
          return _this.set('shouldCloseTray', true);
        });
      },
      showDeleteModal: function showDeleteModal() {
        (0, _jquery.default)('#deleteModal').modal('show');
      }
    }
  });

  _exports.default = _default;
});