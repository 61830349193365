define("lh4/mixins/routes/discard-changes-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    modalName: '',
    modelNameToTrack: 'model',
    actions: {
      willTransition: function willTransition(transition) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        this._super.apply(this, [transition].concat(args));

        var model = this.controller.get(this.get('modelNameToTrack'));
        var canBeDiscarded = !model.get('isDeleted') && model.get('isDirty') && Object.keys(model.changedAttributes()).length;

        if (canBeDiscarded) {
          (0, _jquery.default)("#".concat(this.get('modalName'))).modal('show');
          this.controller.set('transition', transition);
          transition.abort();
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});