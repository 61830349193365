define("lh4/abilities/support", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions", "lh4/config/environment", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _permissions, _environment, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    canViewInfo: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.SUPPORT.VIEW_CONTACT_INFO);
    }),
    canManageTickets: (0, _abilityMixin.computedAbility)(function getter() {
      return _environment.default.featureFlags.supportTickets && this.hasPermission(_permissions.default.SUPPORT.SEND_TICKETS);
    }),
    canViewDocs: (0, _abilityMixin.computedAbility)(function getter() {
      return _environment.default.branding.name === _environment.default.BrandNames.Harbortouch && this.hasPermission(_permissions.default.SUPPORT.VIEW_DOCS);
    }),
    canSendSupportMessages: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.hasPermission(_permissions.default.SUPPORT.SEND_SUPPORT_MESSAGES) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.NICE_LIVECHAT_VISIBILITY];
    }),
    canSendSupportMessagesForLocation: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.SUPPORT.SEND_SUPPORT_MESSAGES);
    }),
    canViewInfoForLocation: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.SUPPORT.VIEW_CONTACT_INFO);
    })
  });

  _exports.default = _default;
});