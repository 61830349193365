define("lh4/serializers/echo-pro/gratuity", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    attrs: {
      orderTypes: {
        embedded: 'always',
        serialize: 'id'
      }
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (!json.isFlatAmount) {
        json.value *= 100;
      }

      json.value = Number.parseFloat(json.value).toFixed(2);

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.value = Number.parseFloat(json.value).toFixed(2);

      if (!json.isFlatAmount) {
        json.value /= 100;
      }

      return json;
    }
  });

  _exports.default = _default;
});