define("lh4/components/pikaday-input", ["exports", "moment", "ember-pikaday/components/pikaday-input", "jquery"], function (_exports, _moment, _pikadayInput, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_RANGE = 10;
  /**
   * Parse year range part. For example: 'currentYear-10', 'currentYear', 'currentYear+10'.
   * @param {string} value to parse
   * @returns {string} parsed value
   */

  function parseCurrentYear(value) {
    if (!value.includes('currentYear')) {
      return value;
    }

    var currentYear = new Date().getFullYear();
    var offset = parseInt(value.replace('currentYear', ''), 10) || 0;
    return currentYear + offset;
  }

  var _default = _pikadayInput.default.extend({
    _defaultOptions: function _defaultOptions() {
      var initial = this._super.apply(this, arguments);

      initial.toString = function toString(date, format) {
        return (0, _moment.default)(date).local().format(format);
      };

      return initial;
    },
    onPikadayRedraw: function onPikadayRedraw() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var el = args[0].el;

      this._super.apply(this, args);

      this.replaceSelectWithDropdown(el);
    },
    determineYearRange: function determineYearRange() {
      var yearRange = this.get('yearRange');

      if (yearRange && yearRange.includes(',')) {
        var parts = yearRange.split(',');
        return [parseCurrentYear(parts[0]), parseCurrentYear(parts[1])];
      }

      return yearRange || DEFAULT_RANGE;
    },
    replaceSelectWithDropdown: function replaceSelectWithDropdown(element) {
      var component = this.get('pikaday');
      (0, _jquery.default)(element).find('.pika-label').replaceWith(function replaceCallback() {
        return (0, _jquery.default)(this).find('select');
      });
      (0, _jquery.default)(element).find('select').dropdown({
        duration: 0,
        delay: {
          hide: 0
        },
        className: {
          dropdown: 'ui inline scrolling dropdown',
          selection: ''
        },
        onChange: function onChange(value, text, source) {
          var isMonth = (0, _jquery.default)(source).parents('.pika-select-month').length === 1;
          Ember.run.next(function () {
            return isMonth ? component.gotoMonth(value) : component.gotoYear(value);
          });
        }
      });
      (0, _jquery.default)(document).off('touchend', '.pika-select .menu .item').on('touchend', '.pika-select .menu .item', function (_ref) {
        var target = _ref.target;
        return (0, _jquery.default)(target).click();
      });
    },
    _updateOptions: function _updateOptions() {
      if (this.isDestroyed) {
        return;
      }

      this._super();
    }
  });

  _exports.default = _default;
});