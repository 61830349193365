define("lh4/models/echo-pro/customer/address", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/utils/array-join"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _arrayJoin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addressTypes = _exports.default = void 0;

  var _Ember;

  var addressTypes = [{
    name: 'Home',
    id: 0
  }, {
    name: 'Work',
    id: 1
  }, {
    name: 'PO Box',
    id: 2
  }, {
    name: 'Unknown',
    id: 3
  }];
  _exports.addressTypes = addressTypes;
  var KEY_ATTRIBUTES = ['streetAddress1', 'streetAddress2', 'city', 'state', 'postalCode'];
  var Validations = (0, _emberCpValidations.buildValidations)({
    city: {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Address must have a city'
      })]
    },
    streetAddress1: {
      description: 'Street Address 1',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Address must have at least 1 street address'
      })]
    },
    streetAddress2: {
      description: 'Street Address 2',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    postalCode: {
      description: 'ZIP',
      validators: [(0, _emberCpValidations.validator)('zip-code', {
        allowBlank: true
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Address must have a ZIP code'
      })]
    },
    state: {
      description: 'State',
      validators: [(0, _emberCpValidations.validator)('region', {
        allowBlank: true
      })]
    },
    type: {
      description: 'Type',
      validators: [(0, _emberCpValidations.validator)(function (value) {
        return addressTypes.some(function (type) {
          return type.name === value;
        }) || 'Incorrect Address Type';
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    city: (0, _attr.default)('string'),
    streetAddress1: (0, _attr.default)('string'),
    streetAddress2: (0, _attr.default)('string'),
    postalCode: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    customer: (0, _relationships.belongsTo)('echo-pro/customer'),
    createdAt: (0, _attr.default)('date'),
    addressType: (0, _attr.default)('string'),
    addressTypes: addressTypes.map(function (_ref) {
      var name = _ref.name;
      return {
        name: name,
        value: name
      };
    }),
    full: (_Ember = Ember).computed.apply(_Ember, KEY_ATTRIBUTES.concat([function getter() {
      return (0, _arrayJoin.default)(this, KEY_ATTRIBUTES, ', ');
    }])),
    contains: function contains(term) {
      var _this = this;

      return KEY_ATTRIBUTES.some(function (attribute) {
        return (_this.get(attribute) || '').toLowerCase().includes(term);
      });
    }
  });

  _exports.default = _default;
});