define("lh4/mirage/routes/locations-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageLocationsOverviewServer;

  function setMirageLocationsOverviewServer(server) {
    server.get('/locations/overview/:locationId', function (_ref, request) {
      var overviews = _ref.overviews;
      var merchantId = request.params.locationId;
      return overviews.find(merchantId);
    });
    server.get('/statements');
  }
});