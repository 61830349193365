define("lh4/routes/authenticated/epro/invoices/index", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/routes/authenticated/epro/invoices/sso"], function (_exports, _authenticatedRouteMixin, _sso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sso.default.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    redirect: function redirect() {
      if (this.can.can('view invoices in echo-pro/invoices', this.location.model)) {
        return this.transitionTo('authenticated.epro.invoices');
      }

      return this.transitionTo('/');
    }
  });

  _exports.default = _default;
});