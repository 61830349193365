define("lh4/controllers/authenticated/add-on-devices", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    routeData: Ember.computed('location.model.terminalProducts.[]', 'launchDarkly.featureFlags', function getter() {
      var routeData = [];

      if (this.can.can('view gpatt for add-on-devices', this.location.model)) {
        routeData.push({
          name: 'authenticated.add-on-devices.gpatt',
          title: 'SkyTab Mobile'
        });
      }

      if (this.can.can('view tableside for add-on-devices')) {
        routeData.push({
          name: 'authenticated.add-on-devices.tableside',
          title: 'Tableside'
        });
      }

      if (this.can.can('view customer display for add-on-devices')) {
        routeData.push({
          name: 'authenticated.add-on-devices.customer-display',
          title: 'Customer Display'
        });
      }

      if (this.can.can('view printers for add-on-devices')) {
        routeData.push({
          name: 'authenticated.add-on-devices.printers',
          title: 'Printers'
        });
      }

      return routeData;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.get('launchDarkly.featureFlags');
    }
  });

  _exports.default = _default;
});