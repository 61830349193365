define("lh4/templates/components/ui/icons/internet-payments/important-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E/cC7mNe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M12 23C13.1 23 13.99 22.11 13.99 21.01H10.01C10.01 22.11 10.9 23 12 23ZM19 17V11C19 7.65 16.64 4.85 13.5 4.17V3C13.5 2.17 12.83 1.5 12 1.5C11.17 1.5 10.5 2.17 10.5 3V4.17C7.36 4.85 5 7.65 5 11V17L3 19V20H21V19L19 17ZM13 16H11V14H13V16ZM13 12H11V8H13V12Z\"],[10,\"fill\",\"#0E5BF3\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/important-notification.hbs"
    }
  });

  _exports.default = _default;
});