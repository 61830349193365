define("lh4/mirage/routes/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageAccountsServer;

  function setMirageAccountsServer(server) {
    server.get('/accounts/:locationId/walkthrough', function () {
      return {
        walkthrough: {
          dashboard: true,
          dashboard2: true,
          managePos: {
            echoPro: {
              customizeMenu: true,
              manageEmployees: true,
              manageJobs: true,
              modifiersAndModifierSets: true,
              orderTypes: true,
              tableLayout: true
            },
            hospitality: {
              customizeMenu: true,
              manageEmployees: true,
              customizeItemGroups: true,
              choicesAndChoiceSets: true
            }
          },
          orders: true,
          overview: true,
          reports: true,
          support: true
        }
      };
    });
    server.put('/accounts/:locationId/walkthrough', function () {
      return {
        walkthrough: {
          dashboard: true,
          managePos: {
            echoPro: {
              customizeMenu: true,
              manageEmployees: true,
              manageJobs: true,
              modifiersAndModifierSets: true,
              orderTypes: true,
              tableLayout: true
            },
            hospitality: {
              customizeMenu: true,
              manageEmployees: true,
              customizeItemGroups: true,
              choicesAndChoiceSets: true
            }
          },
          orders: true,
          overview: true,
          reports: true,
          support: true
        }
      };
    });
    server.put('/accounts/:locationId/sub-account/:id', function () {
      return {
        subAccounts: [{
          createdAt: '2017-06-07T12:43:02.739Z',
          emailAddress: 'tom@devups.com',
          firstName: 'Tom',
          id: 18,
          isActive: true,
          isLocked: false,
          isRedeemed: true,
          lastLoggedInAt: null,
          lastLoginAttempt: null,
          lastName: 'Schnaars',
          lockedAt: null,
          loginAttempts: 0,
          metadata: {},
          middleName: null,
          parentUserId: 1,
          phoneNumber: null,
          preferences: {
            eod: '04:00'
          },
          updatedAt: '2017-06-16T18:29:51.049Z',
          userPermissionMaps: [{
            createdAt: '2017-11-14T11:48:57.667Z',
            locationId: 1,
            updatedAt: '2017-11-14T11:48:57.667Z',
            userId: 18,
            userPermissionId: 1
          }]
        }]
      };
    });
    server.get('/accounts/permissions', function () {
      return {
        permissions: [{
          description: 'Read merchant DBA information',
          id: 1,
          name: 'read-profile',
          category: 'DBA Information',
          parentCategory: 'Business Information'
        }, {
          description: 'Write merchant DBA information',
          id: 2,
          name: 'write-profile',
          category: 'DBA Information',
          parentCategory: 'Business Information'
        }]
      };
    });
    server.get('/accounts/:locationId/sub-accounts', function () {
      return {
        subAccounts: [{
          createdAt: '2017-06-07T12:43:02.739Z',
          emailAddress: 'tom@devups.com',
          firstName: 'Tom',
          id: 18,
          isActive: true,
          isLocked: false,
          isRedeemed: true,
          lastLoggedInAt: null,
          lastLoginAttempt: null,
          lastName: 'Schnaars',
          lockedAt: null,
          loginAttempts: 0,
          metadata: {},
          middleName: null,
          parentUserId: 1,
          phoneNumber: null,
          preferences: {
            eod: '04:00'
          },
          updatedAt: '2017-06-16T18:29:51.049Z',
          userPermissionMaps: [{
            createdAt: '2017-11-14T11:48:57.667Z',
            locationId: 1,
            updatedAt: '2017-11-14T11:48:57.667Z',
            userId: 18,
            userPermissionId: 1
          }]
        }]
      };
    });
    server.get('/accounts/:userId/integration-token', function () {
      return {
        token: 'testToken'
      };
    });
  }
});