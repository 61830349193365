define("lh4/services/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    location: Ember.inject.service(),
    hardware: Ember.inject.service(),
    session: Ember.inject.service(),
    cartLifespan: 1000 * 60 * 60 * 24 * 7,
    // 7 days
    items: Ember.A([]),
    init: function init() {
      this._super.apply(this, arguments);

      this.checkForCartExpiration();
    },
    checkForCartExpiration: function checkForCartExpiration() {
      var cart = this.readFromLocalStorage();

      if (cart) {
        var updatedAt = cart.updatedAt;

        if (Date.now() - updatedAt > this.cartLifespan) {
          this.clear();
        }
      }
    },
    writeToLocalStorage: function writeToLocalStorage() {
      window.localStorage.setItem("cart-".concat(this.session.data.authenticated.sub), JSON.stringify({
        items: this.items,
        updatedAt: Date.now()
      }));
    },
    readFromLocalStorage: function readFromLocalStorage() {
      var cart = JSON.parse(window.localStorage.getItem("cart-".concat(this.session.data.authenticated.sub)) || null);

      if (!cart) {
        cart = {
          items: [],
          updatedAt: Date.now()
        };
      }

      this.items = Ember.A(cart.items);
      return cart;
    },
    addItem: function addItem(id, quantity) {
      if (quantity <= 0) {
        throw new Error('Quantity must be greater than 0');
      }

      var item = {
        id: id,
        quantity: this.getQuantityToSet(id, quantity)
      }; // Check if item exists, if so, update quantity

      var existingItem = this.items.findBy('id', id);

      if (existingItem) {
        this.updateItemQuantity(id, Number.parseInt(existingItem.quantity, 10) + Number.parseInt(quantity, 10));
      } else {
        this.items.pushObject(item);
      }

      this.writeToLocalStorage();
    },
    updateItemQuantity: function updateItemQuantity(id, quantity) {
      var _this = this;

      if (quantity <= 0) {
        throw new Error('Quantity must be greater than 0');
      }

      var coDependencies = this.hardware.items.filter(function (hardwareItem) {
        return _this.items.findBy('id', hardwareItem.id);
      }).map(function (hardwareItem) {
        var dependency = hardwareItem.dependencies.find(function (dep) {
          return dep.id === id;
        });

        if (!dependency || !dependency.ratio) {
          return null;
        }

        return {
          id: hardwareItem.id,
          ratio: dependency.ratio
        };
      }).filter(function (dep) {
        return dep;
      });
      var updatedItems = this.items.map(function (item) {
        var coDependency = coDependencies.find(function (dep) {
          return dep.id === item.id;
        });

        if (item.id === id || coDependency) {
          return _objectSpread(_objectSpread({}, item), {}, {
            quantity: _this.getQuantityToSet(id, quantity) * (coDependency ? coDependency.ratio : 1)
          });
        }

        return item;
      });
      this.items.setObjects(updatedItems);
      this.writeToLocalStorage();
    },
    getQuantityToSet: function getQuantityToSet(id, quantity) {
      var hardwareItem = this.hardware.items.findBy('id', id);
      return Math.min(quantity, hardwareItem.maxQuantity);
    },
    removeItem: function removeItem(id) {
      var _this2 = this;

      this.items.removeObject(this.items.findBy('id', id)); // Remove any co-dependencies

      this.hardware.items.filter(function (hardwareItem) {
        return _this2.items.findBy('id', hardwareItem.id);
      }).forEach(function (hardwareItem) {
        var dependency = hardwareItem.dependencies.find(function (dep) {
          return dep.id === id;
        });

        if (dependency) {
          _this2.items.removeObject(_this2.items.findBy('id', hardwareItem.id));
        }
      });
      this.writeToLocalStorage();
    },
    clear: function clear() {
      this.items.clear();
      this.writeToLocalStorage();
    }
  });

  _exports.default = _default;
});