define("lh4/mirage/routes/device-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageDeviceManagementServer;

  function setMirageDeviceManagementServer(server) {
    server.get('/device-management/lighthouse/terminals', function (schema) {
      return {
        terminals: schema.deviceManagementLighthouseTerminals.all().models
      };
    }); // Get Department by ID

    server.get('/device-management/lighthouse/terminals/:terminalId', function (schema, request) {
      return {
        terminals: schema.deviceManagementLighthouseTerminals.where({
          id: request.params.terminalId
        }).models
      };
    });
    server.put('/device-management/lighthouse/terminal', function () {
      return {
        status: 200
      };
    });
  }
});