define("lh4/templates/authenticated/internet-payments/profile/account-preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qo38v2/F",
    "block": "{\"symbols\":[\"flash\",\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui account-preferences-page\"],[8],[0,\"\\n\\t\"],[7,\"h3\",true],[8],[0,\"Default Home Page\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"Choose your default home page\"],[9],[0,\"\\n\\n\\t\"],[5,\"internet-payments/ui/dropdown/dropdown\",[],[[\"@onChange\",\"@label\",\"@defaultText\"],[[23,0,[\"onHomePageSelect\"]],\"Home Page\",[23,0,[\"dropdownDefault\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"allowedDefaultHomePageOptions\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"item\"],[11,\"data-value\",[29,[[23,2,[\"id\"]]]]],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary button\",[28,\"if\",[[24,[\"isLoading\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"Save\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"flash-message\",null,[[\"flash\",\"class\"],[[23,1,[]],[23,1,[\"class\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/profile/account-preferences.hbs"
    }
  });

  _exports.default = _default;
});