define("lh4/routes/authenticated/loyalty/discounts", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/routes/array-qp-serialization-fix-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin, _lighthousePermissionsMixin, _arrayQpSerializationFixMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, _lighthousePermissionsMixin.default, _arrayQpSerializationFixMixin.default, {
    requiredPermissions: 'view loyalty',
    location: Ember.inject.service(),
    queryParams: {
      loyaltyProgramGuids: {
        refreshModel: true
      },
      isActive: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      searchName: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var queryParams = {
        order: 'name',
        sort: 'asc',
        limit: 'all'
      };
      return Ember.RSVP.hash({
        loyaltyProgramGuids: params.loyaltyProgramGuids,
        loyaltyDiscounts: this.store.query('loyalty/discount', params),
        programs: this.store.query('loyalty/program', queryParams)
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      if (!model.loyaltyProgramGuids.length) {
        var loyaltyProgramGuids = model.programs.map(function (program) {
          return program.guid;
        });
        controller.set('loyaltyProgramGuids', loyaltyProgramGuids);
        this.replaceWith({
          queryParams: {
            loyaltyProgramGuids: loyaltyProgramGuids
          }
        });
      }
    }
  });

  _exports.default = _default;
});