define("lh4/routes/authenticated/support/support-contacts", ["exports", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lighthousePermissionsMixin.default, {
    requiredPermissions: 'view info in support',
    location: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations
      });
    }
  });

  _exports.default = _default;
});