define("lh4/templates/components/ui/label/ticket-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YgjHx0m/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"ui \",[22,\"color\"],\" label\"]]],[8],[1,[22,\"status\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/label/ticket-status.hbs"
    }
  });

  _exports.default = _default;
});