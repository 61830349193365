define("lh4/controllers/authenticated/location-settings/edit", ["exports", "lh4/controllers/general-form-controller", "jquery"], function (_exports, _generalFormController, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    flashMessagesHelper: Ember.inject.service(),
    locationSettingsController: Ember.inject.controller('authenticated.location-settings'),
    handleServerError: function handleServerError(error) {
      var _this = this;

      var updateError = 'Something went wrong, location could not be updated.';
      var errorMessage = error.details && error.details[0] || error.message || updateError;
      this.set('serverError', errorMessage);
      this.set('serverErrorTimeoutId', Ember.run.later(this, function () {
        _this.set('serverError', null);
      }, this.serverErrorTimeoutDelay));
    },
    handleServerResponse: function handleServerResponse() {
      this.close();
    },
    close: function close() {
      this.transitionOnClose();
      this.setProperties({
        shouldCloseTray: false,
        isSaving: false
      });
      this.clearServerError();
    },
    isModelAndChangesetPristine: function isModelAndChangesetPristine() {
      var _this$model$locationM;

      return !this.model.managedMenus.isDirty && (this.model.locationMap ? true : !((_this$model$locationM = this.model.locationMap) !== null && _this$model$locationM !== void 0 && _this$model$locationM.isDirty)) && this.changeset.get('isPristine') && this.changeset.locationMap.get('isPristine');
    },
    actions: {
      update: function update() {
        var _this2 = this;

        var settingsChangeset = this.changeset;
        var locationMapChangeset = this.changeset.locationMap;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          showErrors: true,
          isSaving: true
        });
        this.clearServerError();
        Ember.RSVP.Promise.all([settingsChangeset.validate(), locationMapChangeset.validate()]).then(function () {
          if (settingsChangeset.get('isInvalid') || locationMapChangeset.get('isInvalid')) {
            return Ember.RSVP.reject(settingsChangeset.errors || locationMapChangeset.errors);
          }

          if (_this2.isModelAndChangesetPristine()) {
            _this2.closeTray();

            return Ember.RSVP.resolve();
          }

          return (0, _jquery.default)('#confirm-location-settings-modal').modal('show');
        }).catch(function (errors) {
          // We currently don't have a field component for checkboxes
          // Because of this there is no standard way of displaying
          // error messages for boolean fields
          // https://jira.shift4payments.com/browse/LH-8853
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this2.set('booleanError', booleanError.validation);

              _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                _this2.set('booleanError', null);
              }, _this2.serverErrorTimeoutDelay));
            }
          }
        }).finally(function () {
          return _this2.set('isSaving', false);
        });
      },
      modalDeny: function modalDeny() {
        (0, _jquery.default)('#confirm-location-settings-modal').modal('hide');
      },
      modalSave: function modalSave() {
        var _this3 = this;

        (0, _jquery.default)('#confirm-location-settings-modal').modal('hide');

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        var settingsChangeset = this.changeset;
        var locationMapChangeset = this.changeset.locationMap;
        var snapshot = this.changeset.snapshot();
        var requests = [];

        if (settingsChangeset.get('isDirty')) {
          requests.push(settingsChangeset.save());
        }

        if (locationMapChangeset.get('isDirty')) {
          var corporateGroupId = this.changeset.locationMap.get('corporateGroupId');
          var locationMapModel = this.model.locationMap;

          if (corporateGroupId) {
            requests.push(locationMapChangeset.save());
          } else if (locationMapModel) {
            requests.push(this.model.locationMap.destroyRecord());
          }
        }

        Ember.RSVP.Promise.all(requests).then(function () {
          _this3.locationSettingsController.set('shouldUpdate', true);

          _this3.closeTray();
        }).catch(function (_ref) {
          var errors = _ref.errors;

          _this3.changeset.restore(snapshot);

          _this3.changeset.data.rollbackAttributes();

          if (errors && errors[0]) {
            // TODO normalize how we handle these error messages
            var updateError = "Something went wrong, ".concat(_this3.instanceName, " could not be updated.");
            errors.forEach(function (error) {
              var errorMessage = error.details && error.details[0] || error.message || updateError;

              _this3.set('serverError', errorMessage);

              _this3.set('serverErrorTimeoutId', Ember.run.later(_this3, function () {
                _this3.set('serverError', null);
              }, _this3.serverErrorTimeoutDelay));
            });
          }
        }).finally(function () {
          return _this3.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});