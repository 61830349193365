define("lh4/services/reports/fiscal-report-api", ["exports", "lh4/utils/reports-handle-error", "lh4/config/environment"], function (_exports, _reportsHandleError, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    location: Ember.inject.service(),

    /**
     * Fetch report
     *
     * @param {string|null} terminalId
     * @param {Moment} startsAt
     * @param {Moment} endsAt
     * @returns {Promise<object>} - report results
     * @throws {header, details} - error ready for display
     */
    fetchReport: function fetchReport(locationId, terminalId, startsAt, endsAt) {
      var baseUrl = "".concat(_environment.default.host, "/api/v1/reports/fiscal-reports/").concat(locationId);
      var baseQuery = new URLSearchParams({
        token: this.session.get('data.authenticated').token
      });
      var query = new URLSearchParams(_objectSpread(_objectSpread(_objectSpread({}, baseQuery), terminalId ? {
        terminalId: terminalId
      } : {}), {}, {
        from: startsAt.toISOString().split('T')[0],
        to: endsAt.toISOString().split('T')[0]
      }));
      var url = "".concat(baseUrl, "?").concat(query);
      return this.ajax.request(url, {
        type: 'GET'
      }).then(function (response) {
        return _objectSpread(_objectSpread({}, response), {}, {
          baseUrl: baseUrl,
          baseQuery: baseQuery
        });
      }).catch(_reportsHandleError.default);
    }
  });

  _exports.default = _default;
});