define("lh4/abilities/business-setting", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin"], function (_exports, _emberCan, _abilityMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    location: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    canViewBusinessSettings: (0, _abilityMixin.computedAbility)(function getter() {
      var isAdmin = !this.get('tokenData.data.parentUserId');
      return isAdmin;
    }),
    canViewPosBusinessSettings: (0, _abilityMixin.computedAbility)(function getter() {
      var isAdmin = !this.get('tokenData.data.parentUserId');
      return isAdmin && this.hasProduct('echo-pro');
    })
  });

  _exports.default = _default;
});