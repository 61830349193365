define("lh4/templates/components/internet-payments/ui/check-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1X3yxTOZ",
    "block": "{\"symbols\":[\"item\",\"@itemName\",\"@maxItems\",\"@selected\"],\"statements\":[[4,\"if\",[[24,[\"items\",\"length\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"ui-checkbox\",null,[[\"class\",\"label\",\"checked\",\"onChange\",\"disabled\"],[\"checkbox select-items\",[28,\"concat\",[[28,\"concat\",[\"All \",[23,2,[]]],null],\"(\",[24,[\"items\",\"length\"]],\")\"],null],[28,\"or\",[[28,\"eq\",[[23,4,[\"length\"]],[24,[\"items\",\"length\"]]],null],[28,\"eq\",[[23,4,[\"length\"]],[23,3,[]]],null]],null],[28,\"action\",[[23,0,[]],\"selectAllItems\"],null],[28,\"gt\",[[24,[\"items\",\"length\"]],[23,3,[]]],null]]]],false],[0,\"\\n\"],[4,\"each\",[[28,\"take\",[[28,\"if\",[[23,0,[\"isShowMore\"]],[24,[\"items\",\"length\"]],[24,[\"visibleItems\"]]],null],[24,[\"items\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"ui-checkbox\",null,[[\"class\",\"checked\",\"label\",\"onChange\",\"disabled\"],[\"checklist__select\",[23,1,[\"isChecked\"]],[28,\"customer-display\",[[23,1,[]]],null],[28,\"action\",[[23,0,[]],[24,[\"selectItem\"]],[23,1,[\"id\"]]],null],[23,1,[\"isDisabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[7,\"span\",false],[12,\"class\",\"ui primary show-more\"],[3,\"on\",[\"click\",[23,0,[\"toggleShowMore\"]]]],[8],[0,\"Show \"],[1,[28,\"if\",[[23,0,[\"isShowMore\"]],\"Less\",\"All\"],null],false],[7,\"i\",true],[11,\"class\",[29,[[28,\"if\",[[23,0,[\"isShowMore\"]],\"up\",\"down\"],null],\" chevron icon\"]]],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"span\",true],[8],[0,\"No \"],[1,[23,2,[]],false],[0,\" Found\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/check-list.hbs"
    }
  });

  _exports.default = _default;
});