define("lh4/components/dashboard-widgets/dashboard-widget", ["exports", "ember-changeset", "lh4/components/dashboard-widgets/generic-widget"], function (_exports, _emberChangeset, _genericWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GROSS_SALES_WIDGET = _exports.DEFAULT_WIDGETS_SORT = _exports.DEFAULT_WIDGET_SETTINGS = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var CUSTOM_PERMISSIONS_ERROR = ['email-campaigns', 'online-reputation', 'social-media-exposure', 'skytab'];
  var DEFAULT_WIDGET_SETTINGS = Object.freeze({
    sales: {
      type: 'net-sales',
      totalsRange: 'today',
      view: 'detail'
    },
    'skytab-volume': {
      view: 'detail'
    },
    'daily-top-items': {
      type: 'by-sales'
    },
    'skytab-email': {
      period: 'today',
      view: 'simple'
    },
    'skytab-ratings': {
      period: 'today',
      view: 'simple'
    },
    deposits: {
      excludeFees: false
    }
  });
  _exports.DEFAULT_WIDGET_SETTINGS = DEFAULT_WIDGET_SETTINGS;
  var GROSS_SALES_WIDGET = Object.freeze({
    settings: {
      totalsRange: 'today',
      type: 'gross-sales'
    },
    slug: 'sales'
  });
  _exports.GROSS_SALES_WIDGET = GROSS_SALES_WIDGET;
  var DEFAULT_WIDGETS_SORT = ['skytab', 'sales', 'tickets', 'payment-methods', 'discounts', 'voids', 'guests', 'labor-statistics', 'clocked-in-employees', 'daily-top-items', 'batch-summary', 'deposit-summary', 'weather', 'online-reputation', 'email-campaigns', 'skytab-volume'];
  _exports.DEFAULT_WIDGETS_SORT = DEFAULT_WIDGETS_SORT;

  var _default = _genericWidget.default.extend({
    classNameBindings: ['flipped', 'expanded', 'readOnly'],
    didInsertElement: function didInsertElement() {
      var slug = this.widgetSettings.slug; // make a copy of the settings here so that one specific widget can save its settings
      // separately from the rest of the widgets (since the order is saved as one large object)

      var settingsChangeset = _objectSpread(_objectSpread({}, DEFAULT_WIDGET_SETTINGS[slug]), this.widgetSettings.settings);

      this.set('settingsChangeset', new _emberChangeset.default(settingsChangeset));
    },
    hasSettings: Ember.computed('widgetSettings.slug', function hasSettings() {
      return !this.readOnly && Ember.getOwner(this).lookup("component:dashboard-widgets/".concat(this.get('widgetSettings.slug'), "/settings"));
    }),
    hasCustomPermissionsError: Ember.computed('isUserPermitted', function hasCustomPermissionsError() {
      return CUSTOM_PERMISSIONS_ERROR.includes(this.get('widgetSettings.slug'));
    }),
    actions: {
      flip: function flip() {
        if (!this.media.isMobile && this.expanded) {
          this.send('toggleExpand');
        }

        this.toggleProperty('flipped');

        if (this.settingsChangeset.get('isDirty')) {
          this.settingsChangeset.rollback();
        }
      },
      toggleExpand: function toggleExpand() {
        this.toggleProperty('expanded');

        if (!this.get('media.isMobile') && this.expanded) {
          this.$().parent().css('z-index', this.numberOfWidgets - this.index + 40);
        } else {
          this.$().parent().css('z-index', '');
        }
      },
      remove: function remove() {
        this.sendAction('remove');
        this.sendAction('saveOrder');
      },
      saveSettings: function saveSettings() {
        if (this.settingsChangeset.get('isDirty')) {
          this.settingsChangeset.save();
          this.set('widgetSettings.settings', _objectSpread({}, this.settingsChangeset.get('data')));
          this.sendAction('saveOrder');
        }

        this.send('flip');
      }
    }
  });

  _exports.default = _default;
});