define("lh4/models/order", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdAt: (0, _attr.default)('string'),
    merchantId: (0, _attr.default)('string'),
    orderStatusId: (0, _attr.default)('string'),
    orderStatus: (0, _attr.default)('string'),
    orderStageId: (0, _attr.default)('string'),
    orderStage: (0, _attr.default)('string'),
    orderStagePercent: (0, _attr.default)('string'),
    orderType: (0, _attr.default)('string')
  });

  _exports.default = _default;
});