define("lh4/components/2fa/setup-verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui basic segment', 'setup-verify-2fa'],
    userCode: '',
    copySuccess: false,
    qrCodeText: Ember.computed('secretCode', function getter() {
      return "otpauth://totp/Lighthouse?secret=".concat(this.secretCode, "&issuer=Shift4");
    }),
    actions: {
      copySecretCodeSuccess: function copySecretCodeSuccess() {
        var _this = this;

        this.set('copySuccess', true);
        Ember.run.later(this, function () {
          _this.set('copySuccess', false);
        }, 2000);
      },
      verifyCode: function verifyCode() {
        this.onVerifyCode({
          userCode: this.userCode
        });
      }
    }
  });

  _exports.default = _default;
});