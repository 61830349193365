define("lh4/controllers/authenticated/loyalty/discounts/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    actions: {
      create: function create() {
        var _this = this;

        this._super(function () {
          _this.metrics.trackEvent('MixpanelCustom', {
            event: 'Loyalty Discounts Update Completed',
            'Entry Type': 'Edit',
            Status: _this.changeset.get('isActive'),
            locationId: _this.location.model.id,
            merchantId: _this.location.model.merchantId
          });
        });
      }
    }
  });

  _exports.default = _default;
});