define("lh4/templates/components/internet-payments/widgets/container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OrXx5UoZ",
    "block": "{\"symbols\":[\"@className\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"widget-container \",[23,1,[]]]]],[8],[0,\"\\n\\t\"],[14,2,[[28,\"hash\",null,[[\"Header\",\"Body\",\"Footer\"],[[28,\"component\",[\"internet-payments/widgets/container/header\"],null],[28,\"component\",[\"internet-payments/widgets/container/body\"],null],[28,\"component\",[\"internet-payments/widgets/container/footer\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/widgets/container.hbs"
    }
  });

  _exports.default = _default;
});