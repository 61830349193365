define("lh4/components/loyalty/loyalty-member-form", ["exports", "lh4/utils/states"], function (_exports, _states) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    transactionStatsHeaders: [{
      name: 'Balance',
      width: 'four'
    }, {
      name: 'Redeemed',
      width: 'four'
    }, {
      name: 'Lifetime Earned',
      width: 'four'
    }, {
      name: 'Visits',
      width: 'four'
    }],
    pointsHistoryHeaders: [{
      name: 'Date',
      width: 'five'
    }, {
      aligned: 'right',
      name: 'Point Change',
      width: 'four'
    }, {
      aligned: 'right',
      name: 'Balance',
      width: 'three'
    }, {
      name: 'Changed by',
      width: 'four'
    }],
    showErrors: false,
    store: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    region: Ember.inject.service('locale/region'),
    programOptions: Ember.computed.map('programs', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          isActive = _ref.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    states: _states.STATES.map(function (_ref2) {
      var state = _ref2.state,
          abbr = _ref2.abbr;
      return {
        name: state,
        value: abbr,
        abbr: abbr
      };
    }),
    transactionStats: Ember.computed('memberModel', function getter() {
      return [this.memberModel];
    }),
    currentBalance: Ember.computed('transactionStats.[]', function getter() {
      var currentBalanceUpd = this.transactionStats.length ? this.transactionStats[0].pointsBalance : 0;
      return currentBalanceUpd;
    }),
    displayTransactions: Ember.computed('transactions', 'member.addAdjustmentTransactionChangesets.[]', function getter() {
      var finalizedTransactions = this.transactions.map(function (_ref3) {
        var createdAt = _ref3.createdAt,
            pointChange = _ref3.pointChange,
            balanceAtPointInTime = _ref3.balanceAtPointInTime,
            employeeName = _ref3.employeeName;
        return {
          createdAt: createdAt,
          pointChange: pointChange,
          balanceAtPointInTime: balanceAtPointInTime,
          employeeName: employeeName
        };
      });
      var pendingTransactions = [];
      this.member.addAdjustmentTransactionChangesets.forEach(function (adjustment) {
        var _adjustment$change = adjustment.change,
            pointChange = _adjustment$change.pointChange,
            employeeName = _adjustment$change.employeeName;
        var currentBalance = 0;

        if (pendingTransactions.length) {
          currentBalance = pendingTransactions[0].balanceAtPointInTime;
        } else if (finalizedTransactions.length) {
          currentBalance = finalizedTransactions[0].balanceAtPointInTime;
        }

        var adjustTransaction = {
          employeeName: employeeName,
          pointChange: pointChange,
          balanceAtPointInTime: pointChange ? currentBalance + Number(pointChange) : null,
          pending: true,
          pendingText: 'ADJ PENDING'
        };
        pendingTransactions.unshift(adjustTransaction);
      });
      return pendingTransactions.concat(finalizedTransactions).slice(0, this.MAX_TRANSACTIONS_PER_TRAY);
    }),
    adjustPointsChangeset: Ember.computed('member', function getter() {
      var trans = this.store.createRecord('loyalty/transaction', {
        loyaltyMemberGuid: this.member.get('guid')
      });
      var adjustPointsSet = this.buildChildChangeset(trans);
      return adjustPointsSet;
    }),
    actions: {
      onSave: function onSave() {
        this.member.addAdjustmentTransactionChangesets.popObject(); // We only add to addAdjustmentTransactionChangesets if both fields are not empty

        if (this.adjustPointsChangeset.get('reason') || this.adjustPointsChangeset.get('pointChange')) {
          var _this$tokenData$data = this.tokenData.data,
              firstName = _this$tokenData$data.firstName,
              lastName = _this$tokenData$data.lastName;
          this.adjustPointsChangeset.set('employeeName', "".concat(firstName, " ").concat(lastName).trim());
          this.member.addAdjustmentTransactionChangesets.pushObject(this.adjustPointsChangeset);
        }
      },
      deletePending: function deletePending() {
        this.set('adjustPointsChangeset.pointChange', null);
        this.set('newBalance', null);
        this.set('adjustPointsChangeset.reason', null);
        this.member.addAdjustmentTransactionChangesets.popObject();
      }
    }
  });

  _exports.default = _default;
});