define("lh4/controllers/authenticated/internet-payments/customers/details/transaction", ["exports", "lh4/controllers/authenticated/internet-payments/transactions/master-transactions/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({
    actions: {
      onNextTransaction: function onNextTransaction() {
        var _this$nextTransaction;

        if ((_this$nextTransaction = this.nextTransaction) !== null && _this$nextTransaction !== void 0 && _this$nextTransaction.id) {
          this.transitionToRoute('authenticated.internet-payments.customers.details.transaction', this.nextTransaction.id);
        }
      },
      onPreviousTransaction: function onPreviousTransaction() {
        var _this$previousTransac;

        if ((_this$previousTransac = this.previousTransaction) !== null && _this$previousTransac !== void 0 && _this$previousTransac.id) {
          this.transitionToRoute('authenticated.internet-payments.customers.details.transaction', this.previousTransaction.id);
        }
      },
      onCloseTransaction: function onCloseTransaction() {
        this.transitionToRoute('authenticated.internet-payments.customers.details');
      },
      onOpenRiskBlockListTray: function onOpenRiskBlockListTray() {
        this.transitionToRoute('authenticated.internet-payments.customers.details.transaction.risk-block-list', this.model.transaction.id);
      }
    }
  });

  _exports.default = _default;
});