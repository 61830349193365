define("lh4/components/ui/tab-container", ["exports", "lh4/mixins/components/ui/tab-container-base", "lh4/utils/no-op"], function (_exports, _tabContainerBase, _noOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tabContainerBase.default, {
    classNames: ['segment', 'tab-container'],

    /**
     * @param {Array<string>} - tab names.
     */
    tabs: [],
    onTabChange: _noOp.default,
    showValues: false,
    didInsertElement: function didInsertElement() {
      var tabs = this.get('tabs');

      if (!tabs || !tabs.length) {
        this.logger.log('Error in tab-container: must provide "tabs"');
        return;
      }

      if (!this.get('activeTab')) {
        this.set('activeTab', tabs.objectAt(0));
      }
    },
    tabCountClass: Ember.computed('tabs', 'tabCount', function getter() {
      if (this.get('tabs.length')) {
        return this.get('getTabCountClass')(this, this.get('tabs.length'));
      }

      return '';
    }),
    actions: {
      toggleActiveTab: function toggleActiveTab(tabHeader) {
        var tabs = this.get('tabs');

        if (tabs.includes(tabHeader)) {
          this.set('activeTab', tabHeader);
          this.sendAction('onTabChange', tabHeader);
        } else if (tabs.length) {
          this.set('activeTab', tabs.objectAt(0));
          this.sendAction('onTabChange', tabs.objectAt(0));
        }
      }
    }
  });

  _exports.default = _default;
});