define("lh4/templates/authenticated/add-on-devices/wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wR2XOWNd",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"echo-pro/tableside/device-setup-wizard\",null,[[\"terminal\",\"printers\",\"defaultPrinter\",\"createTerminalHandler\",\"onCancel\",\"onFinish\"],[[24,[\"model\",\"newTerminal\"]],[24,[\"model\",\"printers\"]],[24,[\"defaultPrinter\"]],[24,[\"model\",\"createTerminal\"]],\"cancelWizard\",\"cancelWizard\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/add-on-devices/wizard.hbs"
    }
  });

  _exports.default = _default;
});