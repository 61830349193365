define("lh4/routes/authenticated/locations/location/hospitality/choices-and-choice-sets/set/edit-set/edit", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {});

  _exports.default = _default;
});