define("lh4/routes/authenticated/reporting", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    titleToken: 'Reporting',
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('view reports in general') && (this.can.cannot('manage virtual-gift-card in general') || this.can.cannot('access gift cards reporting in general'))) {
        this.replaceWith('/');
      }
    }
  });

  _exports.default = _default;
});