define("lh4/classes/components/tables/multiselect-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Row = Ember.ObjectProxy.extend({
    isSelected: false,
    content: null,
    isValid: true,
    init: function init(_ref) {
      var content = _ref.content,
          _ref$isSelected = _ref.isSelected,
          isSelected = _ref$isSelected === void 0 ? false : _ref$isSelected,
          _ref$isValid = _ref.isValid,
          isValid = _ref$isValid === void 0 ? true : _ref$isValid;
      this.set('content', content);
      this.set('isSelected', isSelected);
      this.set('isValid', isValid);
    }
  });
  var _default = Row;
  _exports.default = _default;
});