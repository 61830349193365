define("lh4/components/ui/dropdown/reservation-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reservation-date'],
    selectedDate: Ember.computed('startDate', function getter() {
      return (0, _moment.default)(this.get('startDate')).format('MM/DD/YYYY');
    }),
    actions: {
      focusCalendar: function focusCalendar() {
        this.$('.reservation-pikaday').click();
      },
      selectDateRange: function selectDateRange(value) {
        var date = (0, _moment.default)([value.getFullYear(), value.getMonth(), value.getDate()]);
        this.sendAction('selectDateRange', date.format('YYYY-MM-DD'));
      }
    }
  });

  _exports.default = _default;
});