define("lh4/initializers/input-paste-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * Initialize input paste event
  */
  function initialize() {
    Ember.TextField.reopen({
      paste: function paste() {
        var _this = this;

        Ember.run.next(function () {
          _this.sendAction('key-up', _this.get('value'));
        });
      }
    });
  }

  var _default = {
    name: 'input-paste-event',
    initialize: initialize
  };
  _exports.default = _default;
});