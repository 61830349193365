define("lh4/mirage/factories/loyalty-transaction", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    loyaltyProgramGuid: function loyaltyProgramGuid() {
      return _faker.default.datatype.uuid();
    },
    loyaltyMemberGuid: function loyaltyMemberGuid() {
      return _faker.default.datatype.uuid();
    },
    pointChange: function pointChange() {
      return 0;
    },
    reason: function reason() {
      return 'fake reason';
    },
    createdAt: function createdAt() {
      return new Date().toISOString();
    },
    balanceAtPointInTime: function balanceAtPointInTime() {
      return 0;
    },
    employeeName: function employeeName() {
      return "".concat(_faker.default.name.firstName(), " ").concat(_faker.default.name.lastName());
    }
  });

  _exports.default = _default;
});