define("lh4/templates/components/reputation/missing-permissions-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3tBgHuf5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui warning message missing-permissions-warning\"],[8],[0,\"\\n\\t\"],[7,\"p\",true],[8],[1,[24,[\"account\",\"warningMessage\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"canRelink\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"negative small ui button \",[28,\"if\",[[24,[\"isLoading\"]],\"loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"relinkAccount\"],[[\"bubbles\"],[false]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"facebook-page\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\tContinue to Facebook\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",null,[[\"bubbles\",\"style\",\"class\",\"tagName\",\"route\",\"model\"],[false,\"cursor: pointer\",\"negative small ui button\",\"button\",\"authenticated.reputation.accounts.edit\",[24,[\"account\",\"id\"]]]],{\"statements\":[[0,\"\\t\\t\\tEdit Account\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reputation/missing-permissions-warning.hbs"
    }
  });

  _exports.default = _default;
});