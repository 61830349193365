define("lh4/components/statements-download", ["exports", "moment", "lh4/mixins/components/data-testable-component"], function (_exports, _moment, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'statements-download',
    store: Ember.inject.service(),
    metrics: Ember.inject.service(),

    /* @params */
    statements: [],
    merchantId: null,
    handleNotification: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('statementMonth')) {
        var statementMonth = (0, _moment.default)(this.get('statements.lastObject'), 'YYYYMM');
        this.set('statementMonth', statementMonth);
      }
    },
    actions: {
      selectStatementMonth: function selectStatementMonth(date) {
        this.set('statementMonth', date);
      },
      downloadStatement: function downloadStatement() {
        var _this = this;

        var month = this.get('statementMonth').format('YYYYMM');
        var merchantId = this.get('merchantId');
        this.set('statementLoading', true);

        try {
          this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
            eventCategory: 'Outbound Link',
            eventAction: 'click',
            eventLabel: 'View Statement'
          });
        } catch (e) {
          return;
        }

        this.store.queryRecord('statement', {
          merchantId: merchantId,
          month: month
        }).then(function (statement) {
          window.open(statement.get('id'), '_blank');
        }).finally(function () {
          return _this.set('statementLoading', false);
        });
      },
      handleNotification: function handleNotification() {
        this.handleNotification();
      }
    }
  });

  _exports.default = _default;
});