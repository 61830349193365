define("lh4/components/ui/step-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    steps: [],
    activeStepIndex: 0,
    activeStep: Ember.computed('steps', 'activeStepIndex', function getter() {
      return this.steps[this.activeStepIndex];
    }),
    stepObjects: Ember.computed('step', 'activeStepIndex', function getter() {
      var activeStepIndex = this.activeStepIndex;
      return this.steps.map(function (name, index) {
        var className;

        if (activeStepIndex === index) {
          className = 'active';
        } else if (activeStepIndex > index) {
          className = 'completed';
        } else {
          className = 'disabled';
        }

        return {
          name: name,
          class: className
        };
      });
    })
  });

  _exports.default = _default;
});