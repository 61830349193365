define("lh4/utils/objects-array-group-by-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupObjectArrayByKey;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Modifies passed in array
   *
   * @param    {Array} arr - array to modify
   * @param    {Array} key - key to group by
   */
  function groupObjectArrayByKey(arrayToModify, key) {
    var arr = arrayToModify;
    var len = arr.length;
    var i = -1;

    while (i + 1 < len) {
      i += 1;
      var j = i + 1;

      for (; j < arr.length; j += 1) {
        if (arr[i][key] === arr[j][key]) {
          arr[i] = _objectSpread(_objectSpread({}, arr[i]), arr[j]);
          arr.splice(j -= 1, 1);
        }
      }
    }
  }
});