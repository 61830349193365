define("lh4/abilities/account-maintenance", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin"], function (_exports, _emberCan, _abilityMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    session: Ember.inject.service(),
    canManage: (0, _abilityMixin.computedAbility)('session.isSSO', 'location.model', function getter() {
      return !this.session.isSSO && this.isOwner() && this.location.model && !this.location.model.isCorpHq;
    })
  });

  _exports.default = _default;
});