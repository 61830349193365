define("lh4/mirage/factories/overview", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id: 1,
    '1099s': [],
    batches: [{
      AmtAmex: 208.88,
      AmtAmexReturns: 0,
      AmtDebit: 0,
      AmtDebitReturns: 0,
      AmtDiscover: 158.46,
      AmtDiscoverReturns: 0,
      AmtEBT: 0,
      AmtEBTReturns: 0,
      AmtMCReturns: 0,
      AmtMasterCard: 239.87,
      AmtPrivateLabel: 0,
      AmtReturns: 0,
      AmtVisa: 1223.66,
      AmtVisaReturns: 0,
      AmtVoyager: 0,
      AmtWrightExpress: 0,
      BatchID: '29630854         ',
      MerchantID: '0020800853',
      TotalAmt: 1830.87,
      TransDate: '2017-02-14T00:00:00.000Z'
    }],
    clockedIn: [{
      elapsed: 'Elapsed Time - 1:29 hours',
      name: 'Saurabh',
      punch: 'Last Punch at 02/16/2017 10:17AM',
      status: 'ClockedIn',
      statusText: 'Clocked In'
    }],
    labor: {
      laborPercent: '0.00%',
      netSales: '$49.05',
      pay: '$0.00',
      time: '2017-02-14T19:00:00.000Z'
    },
    statements: [201406, 201407, 201408, 201409, 201410, 201411, 201412, 201601, 201602, 201603, 201604, 201605, 201606, 201607, 201608, 201609, 201610, 201611, 201612],
    terminals: [{
      lastCalledAt: '2017-01-04T19:21:54.000Z',
      status: 'Offline',
      terminalName: null,
      terminalNumber: 1
    }],
    tickets: {
      closed: {
        count: '32',
        totalValue: '324.33'
      },
      open: {
        count: '3',
        totalValue: '10.30'
      },
      time: '2017-02-15T09:00:00.000Z'
    },
    notices: [],
    taxForms: []
  });

  _exports.default = _default;
});