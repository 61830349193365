define("lh4/templates/components/ui/form-fields/checkbox-single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ydGul1gc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"checkbox-single \",[22,\"fieldClasses\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[4,\"ui/form-fields/shared/label\",null,[[\"fullWidth\",\"required\"],[[24,[\"fullWidth\"]],[24,[\"required\"]]]],{\"statements\":[[1,[22,\"label\"],false]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\\n\\t\"],[7,\"div\",true],[10,\"class\",\"checkbox-cell\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"ui-checkbox\",null,[[\"checked\",\"onChange\",\"disabled\"],[[28,\"get\",[[24,[\"changeset\"]],[24,[\"property\"]]],null],[28,\"action\",[[23,0,[]],\"onClick\"],null],[28,\"if\",[[24,[\"disabled\"]],\"disabled\",null],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/checkbox-single.hbs"
    }
  });

  _exports.default = _default;
});