define("lh4/models/echo-pro/schedule", ["exports", "ember-data", "ember-data/relationships", "moment"], function (_exports, _emberData, _relationships, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    locationId: _emberData.default.attr('number'),
    notes: _emberData.default.attr('string'),
    color: _emberData.default.attr('string'),
    startsAt: _emberData.default.attr('number'),
    endsAt: _emberData.default.attr('number'),
    dayOfWeek: _emberData.default.attr('string'),
    unpaidBreakSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    scheduleTemplate: (0, _relationships.belongsTo)('echo-pro/schedule-template'),
    employee: (0, _relationships.belongsTo)('echo-pro/employee'),
    job: (0, _relationships.belongsTo)('echo-pro/job'),
    toShift: function toShift(dates) {
      var dayOfWeek = this.get('dayOfWeek');
      var day = dates.find(function (date) {
        return (0, _moment.default)(date).format('ddd') === dayOfWeek;
      });
      var startsAt = (0, _moment.default)(day).add(this.get('startsAt'), 'seconds').toDate();
      var endsAt = (0, _moment.default)(day).add(this.get('endsAt'), 'seconds').toDate();
      return this.store.createRecord('echo-pro/employee-shift', {
        locationId: this.get('locationId'),
        employee: this.get('employee'),
        job: this.get('job'),
        unpaidBreakSeconds: this.get('unpaidBreakSeconds') || 0,
        startsAt: startsAt,
        endsAt: endsAt,
        notes: this.get('notes'),
        color: this.get('color')
      });
    }
  });

  _exports.default = _default;
});