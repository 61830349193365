define("lh4/mixins/components/ui/dates/date-picker-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * General purpose Mixin for handling click outside of calentim date picker.
   * Provides picker closing action.
   */
  var _default = Ember.Mixin.create({
    onOutsideClick: function onOutsideClick(event) {
      if (this.picker.container[0].style.display !== 'none') {
        this.picker.hideDropdown();
        this.onBlur(event, this.picker);
      }
    },
    clickHandler: null,
    handleOutsideClick: function handleOutsideClick(event) {
      var _this$picker;

      var $element = this.$();
      var $target = (0, _jquery.default)(event.target);
      var $calender = (_this$picker = this.picker) === null || _this$picker === void 0 ? void 0 : _this$picker.container;

      if (!$target.closest($element).length && !$target.closest($calender).length) {
        this.onOutsideClick(event);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.clickHandler = this.get('handleOutsideClick').bind(this);
      return (0, _jquery.default)(document).on('click', this.clickHandler);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      return (0, _jquery.default)(document).off('click', this.clickHandler);
    }
  });

  _exports.default = _default;
});