define("lh4/models/reservations/block", ["exports", "ember-data", "ember-cp-validations", "moment"], function (_exports, _emberData, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    endsAt: {
      description: 'Block',
      validators: [(0, _emberCpValidations.validator)(function (endDateValue, options, model) {
        if (!model.isEnabled) {
          return true;
        }

        var timeFormat = 'hh:mmA';
        var startsAt = (0, _moment.default)(model.startsAt, timeFormat);
        var endsAt = (0, _moment.default)(endDateValue, timeFormat);

        if (endDateValue === (0, _moment.default)().startOf('day').format(timeFormat)) {
          endsAt = (0, _moment.default)(endDateValue, timeFormat).endOf('day');
        }

        if (startsAt.isAfter(endsAt)) {
          return "Start time ".concat(model.startsAt, " cannot be after end time ").concat(endDateValue);
        }

        if (startsAt.isSame(endsAt)) {
          return 'Start time can not be equal to end time';
        }

        return true;
      }, {
        dependentKeys: ['model.startsAt', 'model.isEnabled']
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    // guid: DS.attr('string'),
    locationId: _emberData.default.attr('number'),
    dayOfWeek: _emberData.default.attr('string'),
    partOfDay: _emberData.default.attr('string'),
    isEnabled: _emberData.default.attr('boolean'),
    tableAssignments: _emberData.default.attr(),
    startsAt: _emberData.default.attr('time'),
    endsAt: _emberData.default.attr('time')
  });

  _exports.default = _default;
});