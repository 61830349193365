define("lh4/controllers/authenticated/social-media/post-scheduler/account/edit-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    accountApi: Ember.inject.service('social-media/account-api'),
    facebook: Ember.inject.service('social-media/facebook'),
    twitter: Ember.inject.service('social-media/twitter'),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    ajax: Ember.inject.service(),
    isLoading: false,
    actions: {
      updateScheduledPost: function updateScheduledPost(post) {
        var _this = this;

        var scheduleOnce = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        this.set('isLoading', true);
        this.flashMessagesHelper.clearMessages();
        this.accountApi.updateScheduledPost(this.get('model.account.id'), post, scheduleOnce).then(function () {
          _this.flashMessagesHelper.pushMessage('Successfully scheduled post', 'success');

          Ember.run.later(_this, function () {
            _this.set('isLoading', false);

            _this.transitionToRoute('authenticated.social-media.post-scheduler.account', _this.get('model.account.id'));
          }, 1000);
        }).catch(function (err) {
          _this.set('isLoading', false);

          _this.errorHandler.handleResponseError(err);
        });
      },
      deletePost: function deletePost(post) {
        var _this2 = this;

        this.set('isLoading', true);
        this.flashMessagesHelper.clearMessages();
        this.accountApi.deleteScheduledPost(this.get('model.account.id'), post.id).then(function () {
          _this2.flashMessagesHelper.pushMessage('Successfully deleted post', 'success');

          Ember.run.later(_this2, function () {
            _this2.set('isLoading', false);

            _this2.transitionToRoute('authenticated.social-media.post-scheduler.account', _this2.get('model.account.id'));
          }, 1000);
        }).catch(function (err) {
          _this2.errorHandler.handleResponseError(err, 'Could not load account posts');

          _this2.set('isLoading', false);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('authenticated.social-media.post-scheduler.account', this.get('model.account.id'));
      }
    }
  });

  _exports.default = _default;
});