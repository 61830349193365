define("lh4/mirage/routes/integration/s4/api-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageIntegrationsApiKeysServer;

  function setMirageIntegrationsApiKeysServer(server) {
    server.loadFixtures('integration/s4/api-keys');
    server.get('/integration/s4/api-keys', function (schema) {
      return {
        apiKeys: schema['integration/s4/apiKeys'].all().models
      };
    });
  }
});