define("lh4/utils/is-positive-percentage-or-zero", ["exports", "lh4/utils/is-positive-percentage"], function (_exports, _isPositivePercentage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Validate if given value is numeric and between [0, 100]
   *
   * @param {string|number} value - value to validate
   * @param {number} precision - (Default value 2) value to calculate precision by
   * @param {boolean} isDisabled - (Default value false) if true, skip validation
   * @returns {boolean} - true if value valid, error message otherwise
   */
  var isPositivePercentageOrZero = function isPositivePercentageOrZero(value) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    var isDisabled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    return isDisabled || parseFloat(value) === 0 || (0, _isPositivePercentage.default)(value, precision);
  };

  var _default = isPositivePercentageOrZero;
  _exports.default = _default;
});