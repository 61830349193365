define("lh4/components/ui/buttons/reservation-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    status: Ember.computed('statusId', 'color', function getter() {
      switch (this.get('statusId')) {
        case 0:
          return {
            color: 'orange',
            message: 'Arrived'
          };

        case 1:
          return {
            color: 'green',
            message: 'Seated'
          };

        case 2:
          return {
            color: 'grey',
            message: 'Not Yet Here'
          };

        case 3:
          return {
            color: 'red',
            message: 'Cancelled'
          };

        case 4:
          return {
            color: 'grey',
            message: 'Closed'
          };

        default:
          return {
            color: 'red',
            message: 'Cancelled'
          };
      }
    })
  });

  _exports.default = _default;
});