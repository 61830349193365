define("lh4/templates/components/internet-payments/ui/button/pill-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tj4BNyL0",
    "block": "{\"symbols\":[\"@label\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"pill-button\"],[12,\"data-test\",\"pill-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"onPrimaryClickAction\"]]]],[8],[0,\"\\n\\t\"],[1,[23,1,[]],false],[0,\"\\n\\t\"],[7,\"i\",false],[12,\"class\",\"times icon\"],[3,\"on\",[\"click\",[23,0,[\"onIconClickAction\"]]]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/button/pill-button.hbs"
    }
  });

  _exports.default = _default;
});