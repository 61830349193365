define("lh4/controllers/authenticated/reputation/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      locationSelected: function locationSelected(location) {
        if (!location || !location.id) {
          return;
        }

        this.transitionToRoute('authenticated.reputation.reviews.locations', location.id);
      }
    }
  });

  _exports.default = _default;
});