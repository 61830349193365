define("lh4/services/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    status: null,
    getData: function getData(locationId) {
      var _this = this;

      return this.ajax.request("/api/v1/notifications/".concat(locationId)).then(function (notifications) {
        _this.set('status', notifications);

        return notifications;
      });
    },
    saveData: function saveData(key, type) {
      var _this2 = this;

      return this.ajax.request("/api/v1/notifications/".concat(this.location.model.id), {
        type: 'PUT',
        data: JSON.stringify({
          key: key,
          type: type
        })
      }).then(function () {
        return _this2.updateData(key, type);
      });
    },
    updateData: function updateData(key, type) {
      this.set("status.".concat(key).concat(type ? ".".concat(type) : ''), false);
      this.notifyPropertyChange('status');
    }
  });

  _exports.default = _default;
});