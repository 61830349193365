define("lh4/components/ui/dropdown-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.$('.item.active').length !== 0) {
        this.$('.title').attr('class', 'title active');
        this.$('.content').attr('class', 'content active');
      } else {
        this.$('.title').attr('class', 'title');
        this.$('.content').attr('class', 'content');
      }
    }
  });

  _exports.default = _default;
});