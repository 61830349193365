define("lh4/components/modal/action-confirmation", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    denyClassNames: 'negative',
    approveClassNames: 'positive',
    approveText: 'Ok',
    deniable: true,
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)("#".concat(this.get('id'))).modal('hide dimmer');
    }
  });

  _exports.default = _default;
});