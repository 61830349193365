define("lh4/controllers/authenticated/epro/workforce/terms-and-conditions", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    ajax: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    isSaving: false,
    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.epro.workforce.landing-page');
      },
      accept: function accept() {
        var _this = this;

        this.flashMessagesHelper.clearMessages();
        this.set('isSaving', true);
        this.ajax.post("/api/v1/s4-workforce/app/onboarding/".concat(this.location.model.id)).then(function () {
          _this.flashMessagesHelper.pushMessage('Onboarding has been initiated, this may take 10 minutes. Please do not navigate away or reload the page', 'success');
        }).catch(function () {
          _this.flashMessagesHelper.pushMessage('There was an unexpected error onboarding location', 'error');
        }).finally(function () {
          Ember.run.later(function () {
            _this.transitionToRoute('authenticated.epro.workforce.landing-page');

            _this.set('isSaving', false);
          }, 5000);
        });
      }
    }
  });

  _exports.default = _default;
});