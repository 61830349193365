define("lh4/mixins/routes/array-qp-serialization-fix-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var BASIC_TYPES = ['string', 'number'];
  /**
  * @param {*} array - value to check if it's an array
  * @returns {boolean} true if argument is array and contains only supported types
  */

  var isBasicArray = function isBasicArray(array) {
    return Ember.typeOf(array) === 'array' && array.every(function (value) {
      return BASIC_TYPES.includes(_typeof(value));
    });
  };

  var _default = Ember.Mixin.create({
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array' && isBasicArray(value)) {
        return value.slice();
      }

      return this._super(value, urlKey, defaultValueType);
    },
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (defaultValueType === 'array' && isBasicArray(value)) {
        return value.slice();
      }

      return this._super(value, urlKey, defaultValueType);
    }
  });

  _exports.default = _default;
});