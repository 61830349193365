define("lh4/routes/authenticated/customer-database/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [controller].concat(args));

      if (controller.step && !controller.isAttachmentValid) {
        // Redirect to import first step if user have no attachments in second step.
        controller.send('close', 'authenticated.customer-database.import');
      }
    }
  });

  _exports.default = _default;
});