define("lh4/templates/components/ui/icons/internet-payments/apm-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gLfT12B9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"-1 0 24 17\"],[10,\"fill\",\"currentColor\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.8043 0.979333 20 1.45 20 2V8H2V14H12V16H2ZM2 4H18V2H2V4ZM17 18V15H14V13H17V10H19V13H22V15H19V18H17ZM2 14V2V14Z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/apm-services.hbs"
    }
  });

  _exports.default = _default;
});