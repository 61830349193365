define("lh4/components/ui/fields/input-field", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'field', 'padded', 'stackable', 'grid'],
    fullWidth: true,
    type: 'text',
    inputClass: Ember.computed('fullWidth', function getter() {
      return "".concat(this.fullWidth ? 'twelve' : 'eight', " wide column");
    }),
    labelClass: Ember.computed('fullWidth', function getter() {
      return "".concat(this.fullWidth ? 'four' : 'eight', " wide column");
    }),
    didRender: function didRender() {
      this.$('.ember-text-field').focus(function (event) {
        (0, _jquery.default)(event.target).closest('.field').addClass('focused');
      }).blur(function (event) {
        (0, _jquery.default)(event.target).closest('.field').removeClass('focused');
      });
    }
  });

  _exports.default = _default;
});