define("lh4/routes/authenticated/settings/users/edit-user", ["exports", "lh4/mixins/authenticated-route-mixin", "ember-changeset", "ember-changeset-cp-validations"], function (_exports, _authenticatedRouteMixin, _emberChangeset, _emberChangesetCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(_ref) {
      var _this = this;

      var userId = _ref.userId;
      return Ember.RSVP.hash({
        user: this.store.peekRecord('settings/user', userId) || this.store.findRecord('settings/user', userId).catch(function (res) {
          var _res$errors, _res$errors$;

          if (((_res$errors = res.errors) === null || _res$errors === void 0 ? void 0 : (_res$errors$ = _res$errors[0]) === null || _res$errors$ === void 0 ? void 0 : _res$errors$.code) === 404) {
            return _this.transitionTo('authenticated.internet-payments.profile.users');
          }

          throw res;
        }),
        userPermissions: this.store.findRecord('settings/user-permissions', userId),
        permissions: this.store.findAll('accounts/permission'),
        locations: this.modelFor('authenticated').locations,
        permissionTemplates: this.store.findAll('settings/user-permission-template')
      });
    },
    setupController: function setupController() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var controller = args[0],
          user = args[1].user;

      this._super.apply(this, args);

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(user),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(user, validateFn, validationMap, {
        skipValidate: true
      });
      controller.set('changeset', changeset);
    },
    afterModel: function afterModel() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      this._super.apply(this, args);

      var model = args[0];
      this.store.push({
        data: {
          id: model.user.id,
          type: 'settings/user',
          attributes: _objectSpread(_objectSpread({}, model.user.toJSON()), model.userPermissions.toJSON())
        }
      });
      model.user.saveChanges();
    }
  });

  _exports.default = _default;
});