define("lh4/mirage/routes/solo-taxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageSoloServer;

  function setMirageSoloServer(server) {
    server.get('/solo/lighthouse/taxes', function (schema) {
      return schema.soloTaxes.all();
    });
    server.get('/solo/lighthouse/taxes/:taxId', function (schema) {
      return {
        taxes: schema.soloTaxes.all().models
      };
    });
  }
});