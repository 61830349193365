define("lh4/routes/authenticated/epro/payments/discounts/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'discount',
    model: function model() {
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        discount: this.store.createRecord('echo-pro/discount', {
          locationId: location
        }),
        orderTypes: this.store.query('echo-pro/order-type', {
          location: location
        }),
        items: this.store.query('echo-pro/item', {
          location: location
        })
      });
    }
  });

  _exports.default = _default;
});