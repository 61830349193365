define("lh4/templates/components/ui/navigation/vertical-navigation-categories/fundraising", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rriYXsN2",
    "block": "{\"symbols\":[\"@navOpenHover\",\"@navCloseHover\",\"@navOpenClick\",\"@isFundraisingActive\",\"@notificationList\",\"@navCloseClick\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"sidebar-content has-sub-nav\"],[12,\"data-test-nav-bar\",\"fundraising\"],[3,\"on\",[\"mouseenter\",[23,1,[]]]],[3,\"on\",[\"mouseleave\",[23,2,[]]]],[3,\"on\",[\"click\",[23,3,[]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"row \",[28,\"if\",[[23,4,[]],\"active\"],null]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[1,[22,\"ui/icons/sidebar/flare\"],false],[9],[0,\"\\n\\t\\t\"],[1,[28,\"ui/navigation/nav-item\",null,[[\"name\",\"notificationList\"],[\"Fundraising\",[23,5,[]]]]],false],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"chevron right icon\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"sub-nav\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sub-content\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"sub-title\"],[3,\"on\",[\"click\",[23,6,[]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"chevron left icon\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[8],[0,\"Fundraising\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sub-links\"],[8],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/navigation/vertical-navigation-categories/fundraising.hbs"
    }
  });

  _exports.default = _default;
});