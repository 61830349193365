define("lh4/services/browser", ["exports", "detect-browser"], function (_exports, _detectBrowser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend((0, _detectBrowser.detect)() || {}, {
    printPDFToFrame: function printPDFToFrame(frameSelector, blob) {
      switch (this.name) {
        case 'firefox':
          window.open(window.URL.createObjectURL(blob));
          break;

        case 'ie':
          window.navigator.msSaveOrOpenBlob(blob);
          break;

        default:
          {
            this.printPDFToFrameSelector(frameSelector, blob);
          }
      }
    },
    printPDFToFrameSelector: function printPDFToFrameSelector(frameSelector, blob) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var printFrame = document.querySelector(frameSelector);

      printFrame.onload = function onload() {
        if (options.scaleWidth) {
          var style = printFrame.contentWindow.document.createElement('style');
          style.textContent = 'img { max-width: 100%; height: auto; }';
          printFrame.contentWindow.document.head.appendChild(style);
        }

        printFrame.contentWindow.focus();
        printFrame.contentWindow.print();
      };

      printFrame.setAttribute('src', window.URL.createObjectURL(blob));
    }
  });

  _exports.default = _default;
});