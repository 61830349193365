define("lh4/adapters/s4-gift-cards/card", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return "".concat(this._super.apply(this, [query].concat(args)), "/").concat(query.locationId);
    }
  });

  _exports.default = _default;
});