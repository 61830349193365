define("lh4/routes/authenticated/device-management/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'terminal',
    model: function model(_ref) {
      var terminalId = _ref.terminalId;
      return Ember.RSVP.hash({
        terminal: this.store.findRecord('device-management/lighthouse/terminal', terminalId, {
          adapterOptions: {
            query: {
              locationId: this.location.model.id
            }
          }
        }),
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: this.location.model.id
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref2) {
            var guid = _ref2.guid,
                revenueCenterName = _ref2.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        })
      });
    }
  });

  _exports.default = _default;
});