define("lh4/components/social-media/reuse-fb-account", ["exports", "lh4/services/social-media/facebook"], function (_exports, _facebook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Namespaces = {
    SOCIAL_MEDIA: 'social-media',
    REPUTATION: 'reputation'
  };

  var _default = Ember.Component.extend({
    accountApi: Ember.inject.service('reputation/account-api'),
    facebook: Ember.inject.service('social-media/facebook'),
    store: Ember.inject.service(),
    namespace: Namespaces.SOCIAL_MEDIA,
    searchTerm: '',
    isLoading: false,
    errorMessages: [],
    results: [],
    selectedAccount: null,
    selectedReuse: false,
    limit: 5,
    isLinkedStatus: _facebook.FB_PAGE_STATUS_IS_LINKED,
    resultMessage: Ember.computed('totalResults', function getter() {
      if (this.get('totalResults') <= this.get('limit')) {
        return "Total matches: ".concat(this.get('totalResults'));
      }

      return "Total matches: ".concat(this.get('totalResults'), ", displaying ").concat(this.get('limit'), " most matching results only");
    }),
    label: Ember.computed('namespace', function getter() {
      return this.get('namespace') === Namespaces.SOCIAL_MEDIA ? 'Reputation' : 'Social Media';
    }),
    namespaceAccounts: Ember.computed('namespace', 'socialAccounts.[]', 'reputationAccounts.[]', function getter() {
      if (this.get('namespace') === Namespaces.SOCIAL_MEDIA) {
        return this.get('socialAccounts');
      }

      if (this.get('namespace') === Namespaces.REPUTATION) {
        return this.get('reputationAccounts');
      }

      return null;
    }),
    filterAccounts: function filterAccounts() {
      var searchTerm = this.get('searchTerm');

      if (!searchTerm || !searchTerm.trim()) {
        return this.get('namespaceAccounts');
      }

      return this.get('namespaceAccounts').filter(function (account) {
        return Ember.get(account, 'name').toLowerCase().includes(searchTerm.toLowerCase());
      });
    },
    resetSearchResults: function resetSearchResults() {
      this.set('totalResults', null);
      this.set('results', []);
    },
    setResults: function setResults(filteredAccounts) {
      this.set('totalResults', filteredAccounts.length);
      this.set('results', filteredAccounts.slice(0, this.get('limit')));
    },
    resetSelectedParameters: function resetSelectedParameters() {
      this.set('selectedAccount', null);
      this.sendAction('onSelect', null);
    },
    loadAccounts: function loadAccounts() {
      var _this = this;

      var filterAccounts = function filterAccounts(accounts) {
        return accounts.filter(function (account) {
          return _this.accountApi.isFbPage(Ember.get(account, 'type'));
        });
      };

      if (this.get('namespace') === Namespaces.SOCIAL_MEDIA) {
        return this.accountApi.findAccounts().then(function (accounts) {
          var filteredAccounts = filterAccounts(accounts);

          _this.set('socialAccounts', filteredAccounts);

          return filteredAccounts;
        });
      }

      if (this.get('namespace') === Namespaces.REPUTATION) {
        return this.store.findAll('social-media/account').then(function (accounts) {
          var filteredAccounts = filterAccounts(accounts);

          _this.set('reputationAccounts', filteredAccounts);

          return filteredAccounts;
        });
      }

      return Ember.RSVP.Promise.resolve([]);
    },
    actions: {
      search: function search() {
        var accounts = this.filterAccounts();

        if (accounts.length === 0) {
          this.set('errorMessages', ['Could not find any existing accounts with such name. Try a different name.']);

          if (!this.get('selectedAccount')) {
            this.resetSearchResults();
          }

          return;
        }

        this.set('errorMessages', []);
        this.setResults(accounts);
      },
      select: function select(item) {
        if (Ember.get(item, 'linkStatus') !== _facebook.FB_PAGE_STATUS_IS_LINKED) {
          return;
        }

        this.set('selectedAccount', item);
        this.sendAction('onSelect', item);
      },
      unSelect: function unSelect() {
        this.resetSelectedParameters();
      },
      selectFBAccount: function selectFBAccount(selected) {
        var _this2 = this;

        this.set('selectedReuse', selected);

        if (!selected) {
          this.set('searchTerm', '');
          this.set('errorMessages', []);
          this.resetSelectedParameters();
          this.resetSearchResults();
          return;
        }

        this.loadAccounts().then(function (filteredAccounts) {
          _this2.setResults(filteredAccounts);
        });
      }
    }
  });

  _exports.default = _default;
});