define("lh4/models/reports/subscription", ["exports", "ember-data", "ember-data/relationships", "moment", "ember-cp-validations", "lh4/mixins/model-cloneable"], function (_exports, _emberData, _relationships, _moment, _emberCpValidations, _modelCloneable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    day: (0, _emberCpValidations.validator)('presence', true),
    time: (0, _emberCpValidations.validator)('presence', true),
    frequency: (0, _emberCpValidations.validator)('presence', true),
    report: (0, _emberCpValidations.validator)('presence', true),
    product: (0, _emberCpValidations.validator)('presence', true),
    reportSubscriptionRecipientsString: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /^[\W]*([\w+\-.%!#$&'*/=?^`{|}~]+@[\w\-.]+\.[A-Za-z]+[\W]*,{1}[\W]*)*([\w+\-.%!#$&'*/=?^`{|}~]+@[\w\-.]+\.[A-Za-z]+)[\W]*$/,
        message: 'Enter valid email addresses separated by comma'
      }), (0, _emberCpValidations.validator)(function (value) {
        if (value.split(',').length > 100) {
          return 'No more than 100 email addresses allowed';
        }

        return true;
      })]
    },
    'options.locations': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'At least one location must be selected.'
    }),
    'options.format': (0, _emberCpValidations.validator)('presence', true),
    'options.subject': (0, _emberCpValidations.validator)('length', {
      max: 256
    }),
    'options.timezone': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _emberData.default.Model.extend(Validations, _modelCloneable.default, {
    day: _emberData.default.attr('number'),
    time: _emberData.default.attr('subscription-time'),
    frequency: _emberData.default.attr('string', {
      defaultValue: 'monthly'
    }),
    report: _emberData.default.attr('string'),
    product: _emberData.default.attr('string'),
    destination: _emberData.default.attr('string'),
    lastDeliveredAt: _emberData.default.attr('string'),
    options: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return {
          locations: [],
          timezone: _moment.default.tz.guess(),
          subject: ''
        };
      }
    }),
    reportSubscriptionRecipients: (0, _relationships.hasMany)('reports/subscription-recipient'),
    reportSubscriptionRecipientsString: _emberData.default.attr('string'),
    addRecipient: function addRecipient() {
      return this.store.createRecord('reports/subscription-recipient');
    }
  });

  _exports.default = _default;
});