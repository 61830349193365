define("lh4/controllers/authenticated/epro/menu/dynamic-pricing", ["exports", "lh4/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    order: 'isActive',
    sort: 'desc',
    headers: [{
      field: 'scheduleTitle',
      name: 'Group Name',
      width: 'four',
      sortable: true
    }, {
      field: 'revenueCenters',
      name: 'Revenue Centers',
      width: 'four',
      sortable: false
    }, {
      field: 'timeRanges',
      name: 'Time Ranges',
      width: 'six',
      sortable: false
    }, {
      field: 'isActive',
      name: 'Status',
      width: 'two',
      sortable: true,
      type: 'active'
    }],
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super((_this$location = this.location) === null || _this$location === void 0 ? void 0 : (_this$location$model = _this$location.model) === null || _this$location$model === void 0 ? void 0 : _this$location$model.id);
    }),
    dynamicPricesList: Ember.computed('model.dynamicPrices', 'model.revenueCenterOptions', 'models.intradayPeriodGroups', function getter() {
      var _this = this;

      var getRevenueCenterName = function getRevenueCenterName(guid) {
        var revCenter = _this.model.revenueCenterOptions.find(function (_ref) {
          var value = _ref.value;
          return value === guid;
        });

        return revCenter ? revCenter.name : 'Unknown';
      };

      return this.model.dynamicPrices.map(function (dynamicPrice) {
        var revenueCenterNameList = (dynamicPrice.revenueCenterGuids || []).length === 0 ? ['All Revenue Centers'] : dynamicPrice.revenueCenterGuids.map(getRevenueCenterName);
        var dayPartNames = dynamicPrice.intradayPeriodGroupGuids.map(function (guid) {
          var dayPart = _this.model.intradayPeriodGroups.find(function (_ref2) {
            var value = _ref2.value;
            return value === guid;
          });

          return dayPart ? dayPart.name : '';
        });
        var result = {
          id: dynamicPrice.guid,
          scheduleTitle: dynamicPrice.scheduleTitle,
          isActive: dynamicPrice.isActive,
          schedules: dynamicPrice.schedules.map(function (_ref3) {
            var scheduleName = _ref3.scheduleName,
                scheduleNumber = _ref3.scheduleNumber,
                beginTimeMilliseconds = _ref3.beginTimeMilliseconds,
                endTimeMilliseconds = _ref3.endTimeMilliseconds,
                isOnMonday = _ref3.isOnMonday,
                isOnTuesday = _ref3.isOnTuesday,
                isOnWednesday = _ref3.isOnWednesday,
                isOnThursday = _ref3.isOnThursday,
                isOnFriday = _ref3.isOnFriday,
                isOnSaturday = _ref3.isOnSaturday,
                isOnSunday = _ref3.isOnSunday;
            return {
              startsAtSeconds: beginTimeMilliseconds,
              endsAtSeconds: endTimeMilliseconds,
              name: scheduleName,
              scheduleNumber: scheduleNumber,
              isOnMonday: isOnMonday,
              isOnTuesday: isOnTuesday,
              isOnWednesday: isOnWednesday,
              isOnThursday: isOnThursday,
              isOnFriday: isOnFriday,
              isOnSaturday: isOnSaturday,
              isOnSunday: isOnSunday
            };
          }),
          timeOption: dynamicPrice.timeOption,
          dayPartNames: dayPartNames.length ? dayPartNames.join(', ') : 'All Day Parts',
          revenueCenterNames: revenueCenterNameList.join(', ')
        };
        result.hasOvernightPeriod = result.schedules.some(function (_ref4) {
          var endsAtSeconds = _ref4.endsAtSeconds;
          return endsAtSeconds >= 86400000;
        });
        return result;
      });
    })
  });

  _exports.default = _default;
});