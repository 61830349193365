define("lh4/serializers/echo-pro/setting/managed-menus", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    decamelize: function decamelize() {
      var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var keys = {};

      if (obj) {
        Object.keys(obj).forEach(function (attr) {
          keys[attr.replace(/[A-Z 0-9]/g, function (match, offset) {
            return (offset > 0 ? '_' : '') + match.toLowerCase();
          })] = obj[attr];
        });
      }

      return keys;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadData = payload;
      payloadData.managedMenus = payloadData.settings;
      delete payloadData.settings;
      return this._super(store, primaryModelClass, payloadData, id, requestType);
    },
    serialize: function serialize(snapshot) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var json = this._super.apply(this, [snapshot].concat(args));

      delete json.setting;
      var result = this.decamelize(json);
      Object.keys(result).forEach(function (attr) {
        result[attr] = result[attr] === 'true' ? '1' : '0';
      });
      return {
        settings: {
          locationId: snapshot.id,
          managed_menus: result
        }
      };
    }
  });

  _exports.default = _default;
});