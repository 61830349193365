define("lh4/components/customer-engagement/settings-links", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessagesHelper: Ember.inject.service(),
    permissions: Ember.inject.service(),
    hasOnlineOrdering: Ember.computed('settings', function getter() {
      var onlineOrdering = this.settings.onlineOrdering;
      return onlineOrdering ? onlineOrdering.isActive : false;
    }),
    hasReservations: Ember.computed('currentLocation', function getter() {
      return this.permissions.hasService('reservations', this.get('currentLocation'));
    }),
    fbAccounts: Ember.computed('social', 'currentLocation', function getter() {
      var currentLocation = this.get('currentLocation').get('id');
      var soc = this.get('social').filter(function (acc) {
        var socLoc = acc.get('locations').filter(function (loc) {
          if (parseInt(loc.get('id'), 10) === parseInt(currentLocation, 10) && acc.get('isConfiguredProperly') && acc.get('externalId') && acc.get('type') === 'facebook-page') {
            return loc;
          }

          return false;
        });

        if (socLoc.length) {
          return socLoc;
        }

        return false;
      }).sortBy('name');

      if (soc.length) {
        var customLink = Ember.Object.create({
          name: 'Add a Custom Link',
          pictureUri: _environment.default.socialMedia.defaultProfileImage,
          permalink: _environment.default.urls.facebook,
          id: '0'
        });
        soc.unshift(customLink);
        this.set('fbDisabled', true);
        return soc;
      }

      this.set('fbDisabled', false);
      return soc;
    }),
    twitterAccounts: Ember.computed('social', 'currentLocation', function getter() {
      var currentLocation = this.get('currentLocation').get('id');
      var soc = this.get('social').filter(function (acc) {
        var socLoc = acc.get('locations').filter(function (loc) {
          if (parseInt(loc.get('id'), 10) === parseInt(currentLocation, 10) && acc.get('isConfiguredProperly') && acc.get('type') === 'twitter') {
            return true;
          }

          return false;
        });

        if (socLoc.length) {
          return true;
        }

        return false;
      }).sortBy('name');

      if (soc.length) {
        var customLink = Ember.Object.create({
          name: 'Add a Custom Link',
          pictureUri: _environment.default.socialMedia.defaultProfileImage,
          permalink: _environment.default.urls.twitter,
          type: 'twitter',
          id: '0'
        });
        soc.unshift(customLink);
        this.set('twitterDisabled', true);
        return soc;
      }

      this.set('twitterDisabled', false);
      return soc;
    }),
    fbDisabled: false,
    twitterDisabled: false,
    actions: {
      selectFbAccount: function selectFbAccount(value) {
        if (parseInt(value, 10) === 0) {
          this.set('fbDisabled', false);
          var settings = this.get('settings');
          settings.set('facebookUrl', '');
          settings.set('socialMediaAccountFacebookId', null);
        }

        if (parseInt(value, 10)) {
          var selectedSmObj = this.get('social').findBy('id', value);

          var _settings = this.get('settings');

          _settings.set('facebookUrl', selectedSmObj.get('permalink'));

          _settings.set('socialMediaAccountFacebookId', selectedSmObj.get('id'));

          this.set('fbDisabled', true);
        } else {
          this.$('.ui.dropdown.fb-dropdown').dropdown('hide');
        }
      },
      selectTwitterAccount: function selectTwitterAccount(value) {
        if (parseInt(value, 10) === 0) {
          this.set('twitterDisabled', false);
          var settings = this.get('settings');
          settings.set('twitterUrl', '');
          settings.set('socialMediaAccountTwitterId', null);
        }

        if (parseInt(value, 10)) {
          var selectedSmObj = this.get('social').findBy('id', value);
          var _settings2 = this.settings;

          _settings2.set('twitterUrl', selectedSmObj.get('permalink'));

          _settings2.set('socialMediaAccountTwitterId', selectedSmObj.get('id'));

          this.set('twitterDisabled', true);
        } else {
          this.$('.ui.dropdown.twitter-dropdown').dropdown('hide');
        }
      },
      showSaveModal: function showSaveModal() {
        var _this = this;

        this.flashMessagesHelper.clearMessages();
        this.get('settings').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (!validations.get('isValid')) {
            return _this.flashMessagesHelper.pushMessage(validations.get('message'), 'error');
          }

          if (!_this.get('settings.facebookUrl') && _this.get('settings.isFacebookVisible')) {
            _this.flashMessagesHelper.pushMessage('Please enter the url for your facebook account', 'error');
          } else if (!_this.get('settings.twitterUrl') && _this.get('settings.isTwitterVisible')) {
            _this.flashMessagesHelper.pushMessage('Please enter the url for your Twitter account', 'error');
          } else {
            _this.set('settings.shouldUpdateActive', false);

            _this.$('.ui.modal.settings-links-modal').modal('show');
          }

          return false;
        });
      },
      discard: function discard() {
        this.get('settings').rollbackAttributes();
        this.sendAction('goBack');
      },
      cancel: function cancel() {
        this.$('.ui.modal.settings-links-modal').modal('hide');
      },
      changeLogo: function changeLogo(id) {
        this.sendAction('changeLogo', id);
      },
      save: function save() {
        var _this2 = this;

        this.settings.save().then(function () {
          _this2.flashMessagesHelper.pushMessage('Settings successfully updated', 'success');

          _this2.$('.ui.modal.settings-links-modal').modal('hide');
        });
      }
    }
  });

  _exports.default = _default;
});