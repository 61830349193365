define("lh4/utils/internet-payments/disputes/apply-dispute-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Returns an object which has updated offsetToken according to which child
   * route called this function. The token needs to be extracted from the data
   * and used as a startId or endId based on whether going to previous page or next.
   * This function is needed because of requirements for the endpoint we are calling.
   *
   * @method   applyDisputePagination
   *
   * @param    {Object} filter - Object that contains pagination values
   * @param    {Object} model - Object that contains data and limit
   * @returns  {Object} object that transforms pagination values to be specific to childRoute
   */
  var _default = function _default(filter, model) {
    var oldLimit = model.limit;
    var newOffset = filter.offset;
    var newLimit = filter.limit;

    var filterModified = _objectSpread({}, filter);

    var data = model.data.toArray();
    var oldOffset = model.offset;
    var limitChanged = oldLimit !== newLimit;
    filterModified.offset = newOffset;

    if (limitChanged) {
      filterModified.startId = undefined;
      filterModified.endId = undefined;
    } else if (oldOffset !== newOffset) {
      // Get ids
      var firstId = data[0].get('offsetToken');
      var lastId = data[data.length - 1].get('offsetToken');

      if (newOffset > oldOffset) {
        filterModified.startId = lastId;
      } else {
        filterModified.endId = firstId;
      }
    }

    return {
      filterModified: filterModified,
      limitChanged: limitChanged
    };
  };

  _exports.default = _default;
});