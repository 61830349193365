define("lh4/utils/weekdays", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getWeekdays;

  /**
   * Returns all weekdays from the passed day
   *
   * @method getWeekDays
   * @param {String} startOfWeek week starting day
   * @returns {Array} weekdays
   */
  function getWeekdays(startOfWeek) {
    var weekdays = [];

    for (var i = 0; i < 7; i += 1) {
      weekdays.push((0, _moment.default)().day(startOfWeek).add(i, 'days').format('dddd'));
    }

    return weekdays;
  }
});