define("lh4/services/update-log", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var flattenUpdate = function flattenUpdate(update) {
    return update.imageLinks.map(function (image, index) {
      return {
        image: image,
        description: update.description[index]
      };
    });
  };

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    walkthrough: Ember.inject.service(),
    locale: Ember.inject.service(),
    areSkipped: false,
    shouldShow: Ember.computed('updates.[]', 'areSkipped', function getter() {
      return !this.areSkipped && this.updates.length > 0;
    }),
    allUpdates: Ember.computed(function getter() {
      return _emberData.default.PromiseArray.create({
        promise: this.ajax.request('/api/v1/update-log').then(function (_ref) {
          var updates = _ref.updates;
          return updates;
        })
      });
    }),
    currentCountryCode: Ember.computed('locale.country', function getter() {
      return this.get('locale.country');
    }),
    updates: Ember.computed('allUpdates.[]', 'walkthrough.data.shownUpdates.[]', function getter() {
      var _this = this,
          _ref2;

      var shownUpdates = this.get('walkthrough.data.shownUpdates');
      var allUpdates = this.get('allUpdates');
      var source = Array.isArray(shownUpdates) ? allUpdates.filter(function (update) {
        return !shownUpdates.includes(update.id);
      }) : allUpdates;
      var filteredUpdates = source.filter(function (update) {
        if (!update.allowedCountries) {
          return true;
        }

        if (update.allowedCountries.includes(_this.currentCountryCode)) {
          return true;
        }

        return false;
      });
      return (_ref2 = []).concat.apply(_ref2, _toConsumableArray(filteredUpdates.map(flattenUpdate)));
    }),
    markAsShown: function markAsShown() {
      var shownUpdates = this.get('walkthrough.data.shownUpdates') || [];
      var current = this.get('allUpdates').map(function (u) {
        return u.id;
      });
      shownUpdates = shownUpdates.concat(current).uniq();
      this.set('walkthrough.data.shownUpdates', shownUpdates);
      return this.walkthrough.saveData();
    }
  });

  _exports.default = _default;
});