define("lh4/mixins/controllers/locations/location/echo-pro/manage-pos/customers/edit-controller-mixin", ["exports", "lh4/mixins/edit-controller-mixin"], function (_exports, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_editControllerMixin.default, {
    modelName: 'model.customer',
    actions: {
      deleteDialog: function deleteDialog() {
        this.set('isDeleteDialogOpen', true);
      },
      deleteDialogDecline: function deleteDialogDecline() {
        this.set('isDeleteDialogOpen', false);
      },
      delete: function _delete() {
        var _this = this;

        var customer = this.get('model.customer');
        this.set('isDeleteDialogOpen', false);
        this.set('isDeleting', true);
        customer.destroyRecord().then(function () {
          _this.transitionToRoute('authenticated.locations.location.echo-pro.manage-pos.customers');
        }).finally(function () {
          return _this.set('isDeleting', false);
        });
      },
      clear: function clear() {
        this.transitionToRoute('authenticated.locations.location.echo-pro.manage-pos.customers');
      },
      afterSaveHook: function afterSaveHook() {
        this.send('delayedTransition', 'authenticated.locations.location.echo-pro.manage-pos.customers', this.get('model.customer.locationId'));
      }
    }
  });

  _exports.default = _default;
});