define("lh4/controllers/authenticated/add-on-devices/customer-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('cacheBust', Date.now());
    },
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    showErrors: false,
    previewImageUrl: null,
    fullIdleScreenBlob: null,
    thumbIdleScreenBlob: null,
    fullOrderingScreenBlob: null,
    thumbOrderingScreenBlob: null,
    orderCropperOptions: {
      aspectRatio: 4 / 5,
      zoomable: false,
      scalable: false,
      viewMode: 1
    },
    idleCropperOptions: {
      aspectRatio: 16 / 10,
      zoomable: false,
      scalable: false,
      viewMode: 1
    },
    setIdleScreenImageBlobs: function setIdleScreenImageBlobs(_ref) {
      var fullIdleScreenBlob = _ref.fullIdleScreenBlob,
          thumbIdleScreenBlob = _ref.thumbIdleScreenBlob;

      if (!(fullIdleScreenBlob instanceof Blob) || !(thumbIdleScreenBlob instanceof Blob)) {
        throw new Error('fullBlob & thumbBlob must be of type Blob');
      }

      this.setProperties({
        fullIdleScreenBlob: fullIdleScreenBlob,
        thumbIdleScreenBlob: thumbIdleScreenBlob,
        shouldSaveIdleScreenImage: true
      });
    },
    setOrderingScreenImageBlobs: function setOrderingScreenImageBlobs(_ref2) {
      var fullOrderingScreenBlob = _ref2.fullOrderingScreenBlob,
          thumbOrderingScreenBlob = _ref2.thumbOrderingScreenBlob;

      if (!(fullOrderingScreenBlob instanceof Blob) || !(thumbOrderingScreenBlob instanceof Blob)) {
        throw new Error('fullBlob & thumbBlob must be of type Blob');
      }

      this.setProperties({
        fullOrderingScreenBlob: fullOrderingScreenBlob,
        thumbOrderingScreenBlob: thumbOrderingScreenBlob,
        shouldSaveOrderScreenImage: true
      });
    },
    deleteIdleImage: function deleteIdleImage() {
      var _this = this;

      var locationId = this.get('location.model.id');
      return this.ajax.delete("/api/v1/customer-display/settings/".concat(locationId, "/idle-screen-image")).then(function () {
        return _this.setProperties({
          fullIdleScreenBlob: null,
          thumbIdleScreenBlob: null
        });
      }).catch(function () {
        return Ember.RSVP.resolve('There was an unexpected error while removing your image');
      });
    },
    deleteOrderImage: function deleteOrderImage() {
      var _this2 = this;

      var locationId = this.get('location.model.id');
      return this.ajax.delete("/api/v1/customer-display/settings/".concat(locationId, "/order-screen-image")).then(function () {
        return _this2.setProperties({
          fullOrderingScreenBlob: null,
          thumbOrderingScreenBlob: null
        });
      }).catch(function () {
        return Ember.RSVP.resolve('There was an unexpected error while removing your image');
      });
    },
    saveIdleImages: function saveIdleImages() {
      var _this3 = this;

      var locationId = this.get('location.model.id');
      var formData = new FormData();
      formData.append('idleScreenFull', this.fullIdleScreenBlob, "".concat(locationId, "-idle-screen-full.png"));
      formData.append('idleScreenThumb', this.thumbIdleScreenBlob, "".concat(locationId, "-idle-screen-thumb.png"));
      return this.ajax.request("/api/v1/customer-display/settings/".concat(locationId, "/idle-screen-image"), {
        method: 'POST',
        data: formData,
        contentType: false,
        processData: false
      }).then(function (response) {
        return _this3.set('model.idleScreenLogoUrl', response.url);
      }).catch(function (error) {
        var errorMessage = 'Something went wrong, image could not be saved';

        if (error.payload && error.payload.error && error.payload.error.message) {
          errorMessage = "".concat(errorMessage, ": ").concat(error.payload.error.message);
        }

        return Ember.RSVP.reject(errorMessage);
      });
    },
    saveOrderImages: function saveOrderImages() {
      var _this4 = this;

      var locationId = this.get('location.model.id');
      var formData = new FormData();
      formData.append('orderScreenFull', this.fullOrderingScreenBlob, "".concat(locationId, "-order-full.png"));
      formData.append('orderScreenThumb', this.thumbOrderingScreenBlob, "".concat(locationId, "-order-thumb.png"));
      return this.ajax.request("/api/v1/customer-display/settings/".concat(locationId, "/order-screen-image"), {
        method: 'POST',
        data: formData,
        contentType: false,
        processData: false
      }).then(function (response) {
        return _this4.set('model.orderScreenLogoUrl', response.url);
      }).catch(function (error) {
        var errorMessage = 'Something went wrong, image could not be saved';

        if (error.payload && error.payload.error && error.payload.error.message) {
          errorMessage = "".concat(errorMessage, ": ").concat(error.payload.error.message);
        }

        return Ember.RSVP.reject(errorMessage);
      });
    },
    actions: {
      uploadIdleScreenImage: function uploadIdleScreenImage(_ref3) {
        var fullBlob = _ref3.fullBlob,
            thumbBlob = _ref3.thumbBlob;
        this.setIdleScreenImageBlobs({
          fullIdleScreenBlob: fullBlob,
          thumbIdleScreenBlob: thumbBlob
        });
        this.set('model.idleScreenLogoUrl', this.fullIdleScreenBlob);
      },
      uploadOrderingScreenImage: function uploadOrderingScreenImage(_ref4) {
        var fullBlob = _ref4.fullBlob,
            thumbBlob = _ref4.thumbBlob;
        this.setOrderingScreenImageBlobs({
          fullOrderingScreenBlob: fullBlob,
          thumbOrderingScreenBlob: thumbBlob
        });
        this.set('model.orderScreenLogoUrl', this.fullOrderingScreenBlob);
      },
      removeIdleScreenImage: function removeIdleScreenImage() {
        this.set('model.idleScreenLogoUrl', '');
        this.setProperties({
          shouldDeleteIdleImage: true
        });
      },
      removeOrderingScreenImage: function removeOrderingScreenImage() {
        this.set('model.orderScreenLogoUrl', '');
        this.setProperties({
          shouldDeleteOrderImage: true
        });
      },
      save: function save() {
        var _this5 = this;

        this.flashMessagesHelper.clearMessages();
        this.setProperties({
          isSaving: true
        });
        return this.model.validate().then(function () {
          var idleImagePromise;

          if (_this5.shouldSaveIdleScreenImage && !_this5.shouldDeleteIdleImage) {
            idleImagePromise = _this5.saveIdleImages();
          } else if (_this5.shouldDeleteIdleImage && !_this5.shouldSaveIdleScreenImage) {
            idleImagePromise = _this5.deleteIdleImage();
          } else if (_this5.shouldSaveIdleScreenImage && _this5.shouldDeleteIdleImage) {
            idleImagePromise = _this5.deleteIdleImage();
            idleImagePromise = _this5.saveIdleImages();
          } else {
            idleImagePromise = Ember.RSVP.resolve();
          }

          return idleImagePromise.then(function () {
            var orderImagePromise;

            if (_this5.shouldSaveOrderScreenImage && !_this5.shouldDeleteOrderImage) {
              orderImagePromise = _this5.saveOrderImages();
            } else if (_this5.shouldDeleteOrderImage && !_this5.shouldSaveOrderScreenImage) {
              orderImagePromise = _this5.deleteOrderImage();
            } else if (_this5.shouldDeleteOrderImage && _this5.shouldSaveOrderScreenImage) {
              orderImagePromise = _this5.deleteOrderImage();
              orderImagePromise = _this5.saveOrderImages();
            } else {
              orderImagePromise = Ember.RSVP.resolve();
            }

            return orderImagePromise.then(function () {
              return _this5.model.save();
            }).then(function () {
              _this5.flashMessagesHelper.pushMessage('Your Customer Display settings have been saved', 'success');

              return false;
            });
          });
        }).catch(function () {
          return _this5.flashMessagesHelper.pushMessage('There was an unexpected error when validating your Customer Display settings', 'error');
        }).finally(function () {
          return _this5.setProperties({
            isSaving: false,
            shouldSaveOrderScreenImage: null,
            shouldDeleteOrderImage: null,
            shouldSaveIdleScreenImage: null,
            shouldDeleteIdleImage: null
          });
        });
      }
    }
  });

  _exports.default = _default;
});