define("lh4/serializers/bank-account/bank", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'bank-account/bank';
    },
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload) {
      if (payload.status === 202) {
        this.store.push({
          data: {
            id: payload.id,
            type: 'bank-account/credential',
            attributes: {
              code: payload.code,
              context: payload.context,
              message: payload.message,
              rfiType: payload.rfiType,
              salt: payload.salt
            }
          }
        });
      }

      return this.normalize(primaryModelClass, payload);
    }
  });

  _exports.default = _default;
});