define("lh4/helpers/modify-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modifyTime = modifyTime;
  _exports.default = void 0;

  /**
   * Change input value to 12:00AM if value from model is 11:59PM.
   * @param 	{Array<string>} params - value from input field.
   * @returns {string} - value which should be visible to user in the input field.
   */
  function modifyTime(params
  /* , hash */
  ) {
    var value = '11:59PM';

    if (params[0] === value) {
      return '12:00AM';
    }

    return params[0];
  }

  var _default = Ember.Helper.helper(modifyTime);

  _exports.default = _default;
});