define("lh4/routes/authenticated/hospitality/customer-database", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Customer Database',
    requiredPermissions: 'manage hospitality/customer',
    queryParams: {
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      sortAsc: {
        refreshModel: true
      }
    },
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('hospitality/customer');
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        location: this.location.model,
        customers: this.store.query('hospitality/customer', {
          location: this.location.model.id,
          limit: params.limit,
          offset: params.offset,
          search: params.search,
          sort: params.sortAsc ? 'asc' : 'desc',
          order: params.sortBy
        })
      });
    }
  });

  _exports.default = _default;
});