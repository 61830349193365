define("lh4/routes/authenticated/locations/location/echo-pro/reservations/reservation", ["exports", "moment", "lh4/mixins/authenticated-route-mixin"], function (_exports, _moment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      startDate: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      // doing this to work around an ember bug
      // ref: https://github.com/emberjs/ember.js/issues/12473
      var controller = this.controllerFor("".concat(this.routeName, ".edit"));

      if (controller && controller.transition) {
        transition.abort();
      }
    },
    model: function model(params) {
      var _this = this;

      this.store.unloadAll('reservation');
      var today = (0, _moment.default)().format('YYYY-MM-DD');
      var loc = this.modelFor('authenticated.locations.location');
      var start = params.startDate ? (0, _moment.default)(params.startDate, 'YYYY-MM-DD').startOf('day') : (0, _moment.default)(today).startOf('day');
      var end = start.clone().add(1, 'day');
      return Ember.RSVP.hash({
        location: loc,
        reservation: this.store.query('reservation', {
          location: loc.id,
          'gte[partyTime]': start.toISOString(),
          'lt[partyTime]': end.toISOString()
        }).then(function () {
          return _this.store.peekAll('reservation');
        })
      });
    }
  });

  _exports.default = _default;
});