define("lh4/components/ui/form-fields/shared/label", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataTestableComponent.default, {
    tagName: 'label',
    classNameBindings: ['getClassName', 'labelClasses'],

    /**
     * @param {boolean} fullWidth - toggle between full/half of a containing grid
     */
    fullWidth: true,

    /**
     * @param {boolean} required - indicates whether a label is required
     */
    required: false,

    /* @param {boolean} hasFocus - Internal state for managing field styling */
    getClassName: Ember.computed('fullWidth', 'widthClass', function compute() {
      var suffix = ' wide column';

      if (this.widthClass) {
        return "".concat(this.widthClass).concat(suffix);
      }

      return "".concat(this.fullWidth ? 'four' : 'eight').concat(suffix);
    }),
    dataTest: 'form-field-label'
  });

  _exports.default = _default;
});