define("lh4/templates/components/ui/buttons/sub-account-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eFbHSi5o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"edit-col\"],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[\"authenticated.settings.users.edit-user\",[24,[\"user\",\"id\"]]]],{\"statements\":[[0,\"Edit\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"user\",\"isRedeemed\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"resendRegistration\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"resend-col\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"resendConfEmail\",[24,[\"user\",\"id\"]]]],[8],[0,\" Resend Registration\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"resend-col\"],[8],[0,\" Email Sent\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/buttons/sub-account-actions.hbs"
    }
  });

  _exports.default = _default;
});