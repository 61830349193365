define("lh4/utils/try-json-parse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Try to parse a string as JSON.
   *
   * @param {string} str - string to parse
   * @returns {Object|undefined} result of parsing or undefined
   */
  var _default = function _default(str) {
    try {
      return JSON.parse(str);
    } catch (_unused) {
      return undefined;
    }
  };

  _exports.default = _default;
});