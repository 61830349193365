define("lh4/components/image-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SUPPORTED_MIME_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif'];

  var _default = Ember.Component.extend({
    classNames: ['image-upload'],
    inputAccepts: SUPPORTED_MIME_TYPES.join(),
    flashMessagesHelper: Ember.inject.service(),

    /**
     * @param {function({fullBlob, thumbBlob}): Promise<any>} cropImage - Ember action fired when image was cropped
     */
    cropImage: null,

    /**
     * @param {width, height} full - full image dimensions
     */
    full: {
      width: 300,
      height: 225
    },

    /**
     * @param {width, height} [thumb] - thumbnail image dimensions (optional)
     */
    thumb: null,

    /**
     * @param {Boolean} inline - whether or not to display cropper inline or in a modal
    */
    inline: true,

    /**
     *  @param {Boolean} inline - whether or not to display confirmation modal
    */
    showModalConf: true,

    /**
     *   @param {Boolean} isCustomUploadButton - whether or not to show custom upload button
     */
    isCustomUploadButton: false,

    /**
     * @param {function} togglePreview - callback
     */
    togglePreview: function togglePreview() {},
    willDestroyElement: function willDestroyElement() {
      if (this.get('previewURL')) {
        URL.revokeObjectURL(this.get('previewURL'));
      }

      if (this.get('uploadedImageURL')) {
        URL.revokeObjectURL(this.get('uploadedImageURL'));
      }
    },
    resetInput: function resetInput() {
      this.$('input[type=file]').val('');
    },
    actions: {
      imageUpload: function imageUpload(e) {
        this.send('clearCrop', false);

        if (e.target.files.length) {
          var file = e.target.files[0];

          if (SUPPORTED_MIME_TYPES.includes(file.type)) {
            // hide previous image preview when cropper open
            this.send('togglePreview', false);
            this.set('uploadedImageURL', URL.createObjectURL(file));

            if (!this.get('inline')) {
              this.$('.ui.modal.upload-image').modal('show');
            }
          } else {
            this.flashMessagesHelper.pushMessage('Please choose a supported file type', 'warning');
            this.resetInput();
          }
        }
      },
      clearCrop: function clearCrop() {
        var resetInput = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        if (this.get('uploadedImageURL')) {
          URL.revokeObjectURL(this.get('uploadedImageURL'));
          this.set('uploadedImageURL', null);
        }

        if (!this.get('inline')) {
          this.$('.ui.modal.upload-image').modal('hide');
        }

        if (resetInput) {
          // unhide preview image when cropper is closed
          this.send('togglePreview', true);
          this.resetInput();
        }
      },
      cancelCrop: function cancelCrop() {
        var _this = this;

        Ember.run.later(this, function () {
          URL.revokeObjectURL(_this.get('previewURL'));

          _this.$('.ui.modal.crop-image').modal('hide');

          _this.set('previewURL', null);
        }, 300);

        if (!this.get('inline') && this.get('uploadedImageURL')) {
          this.$('.ui.modal.upload-image').modal('show');
        }
      },
      cropImageModal: function cropImageModal(cropper) {
        var _this2 = this;

        this.set('cropper', cropper);
        var canvas = cropper.getCroppedCanvas();
        canvas.toBlob(function (canvasBlob) {
          _this2.set('previewURL', URL.createObjectURL(canvasBlob));

          if (_this2.get('showModalConf')) {
            _this2.$('.ui.modal.crop-image').modal('show');
          } else {
            _this2.send('cropImage');
          }
        });
      },
      cropImage: function cropImage() {
        var _this3 = this;

        var cropper = this.get('cropper');
        var full = cropper.getCroppedCanvas(this.get('full'));
        var thumb = this.get('thumb') ? cropper.getCroppedCanvas(this.get('thumb')) : null;
        return new Ember.RSVP.Promise(function (resolve) {
          full.toBlob(function (fullBlob) {
            if (!thumb) {
              return resolve({
                fullBlob: fullBlob
              });
            }

            thumb.toBlob(function (thumbBlob) {
              resolve({
                fullBlob: fullBlob,
                thumbBlob: thumbBlob
              });
            });
            return false;
          });
        }).then(function (blobs) {
          return _this3.get('cropImage')(blobs);
        }).finally(function () {
          _this3.send('clearCrop');

          _this3.send('cancelCrop');
        });
      },
      togglePreview: function togglePreview(visible) {
        this.togglePreview(visible);
      }
    }
  });

  _exports.default = _default;
});