define("lh4/components/reputation/missing-permissions-warning", ["exports", "lh4/services/social-media/facebook"], function (_exports, _facebook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    facebook: Ember.inject.service('social-media/facebook'),
    account: null,
    type: Ember.computed('account', function getter() {
      return this.get('account.type');
    }),
    canRelink: Ember.computed('account', function getter() {
      var account = this.get('account');

      if (account.type === _facebook.ACCOUNT_TYPE_FB_PAGE) {
        return true;
      }

      return false;
    }),
    actions: {
      relinkAccount: function relinkAccount() {
        switch (this.get('type')) {
          case _facebook.ACCOUNT_TYPE_FB_PAGE:
            return this.facebook.redirectToAccountRelink(this.get('account'), null, true);

          default:
            return this.flashMessagesHelper.pushMessage('Could not determine account type', 'error');
        }
      }
    }
  });

  _exports.default = _default;
});