define("lh4/models/verify-owner/security", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships"], function (_exports, _emberData, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    changeTracker: {
      auto: false
    },
    questions: (0, _relationships.hasMany)('verify-owner/question', {
      async: false
    }),
    context: (0, _attr.default)('string'),
    salt: (0, _attr.default)('string')
  });

  _exports.default = _default;
});