define("lh4/mirage/fixtures/internet-payments/risk/risk-rule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RISK_RULE_FIXTURES = {
    'test-rule-without-proposed-changes': {
      id: '3a5dd435-046d-4548-bfe3-519c54b2b543',
      kountRuleId: 1,
      group: 'Consistency Rules',
      name: 'Billing Address Does Not Match Card Holder Address',
      description: 'DESCRIPTION: Billing Address Does Not Match Card Holder Address',
      score: 25,
      action: null,
      isActive: true,
      proposedChanges: [],
      isEditable: true,
      isEnabled: false,
      isKountSync: false,
      ruleNumber: 3,
      kountRule: {}
    },
    'test-rule-with-proposed-changes': {
      id: '3a5dd435-046d-4548-bfe3-519c54b2b543',
      kountRuleId: 1,
      group: 'Customer Rules',
      name: 'Customer authorized velocity exceeds 6 times within 1 day',
      description: 'DESCRIPTION: Customer authorized velocity exceeds 6 times within 1 day',
      score: 10,
      action: null,
      isActive: true,
      proposedChanges: [{
        createdAt: '2023-02-06T12:32:53.427Z',
        updatedAt: '2023-02-06T12:32:53.427Z',
        guid: 'e3fcc550-2c1a-45f1-872b-2c410f8a6496',
        riskRuleId: '3a5dd435-046d-4548-bfe3-519c54b2b543',
        name: 'Customer authorized velocity exceeds 10 times within 1 day',
        description: 'DESCRIPTION: Customer authorized velocity exceeds 10 times within 1 day',
        isEnabled: false,
        score: 15,
        status: 'pending'
      }],
      isEditable: true,
      isEnabled: true,
      isKountSync: false,
      ruleNumber: 3,
      kountRule: {}
    }
  };
  var _default = RISK_RULE_FIXTURES;
  _exports.default = _default;
});