define("lh4/components/epro/menu/pricing-table-form", ["exports", "lh4/utils/currency/converter"], function (_exports, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    defaultPriceText: Ember.computed('changeset.defaultPrice', function getter() {
      return (0, _converter.formatCurrency)(this.changeset.get('defaultPrice'), '');
    })
  });

  _exports.default = _default;
});