define("lh4/controllers/authenticated/updates/create", ["exports", "lh4/controllers/general-form-controller", "moment", "jquery"], function (_exports, _generalFormController, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    hasPendingUpdates: Ember.computed('model.location.merchantId', 'model.currentUpdates', function hasPendingUpdates() {
      function filterUpdatesByStatus(objects) {
        return objects.filter(function (obj) {
          return obj.status === 'pending';
        });
      }

      var filteredObjects = filterUpdatesByStatus(this.model.currentUpdates);
      return filteredObjects.length > 0;
    }),
    actions: {
      redirectToLocationSettings: function redirectToLocationSettings() {
        this.router.transitionTo('authenticated.location-settings');
      },
      applyImmediately: function applyImmediately(value) {
        this.changeset.set('instant', value);
        var element = (0, _jquery.default)('.date-picker-input');
        var picker = element.data('calentim');
        picker.elem.value = (0, _moment.default)().format('MM/DD/YYYY');
        this.changeset.set('scheduledFor', (0, _moment.default)().format('MM/DD/YYYY'));
      },
      create: function create(trackSaveEvent) {
        var _this = this;

        var element = (0, _jquery.default)('.date-picker-input');
        var picker = element.data('calentim');

        if (this.isSaving) {
          return;
        }

        this.changeset.set('scheduledForFormatted', (0, _moment.default)(picker.config.startDate).format('YYYY-MM-DD'));
        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError();
        this.changeset.validate().then(function () {
          if (_this.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this.changeset.get('errors'));
          }

          if (_this.isModelAndChangesetPristine()) {
            return _this.closeModal();
          }

          return _this.changeset.save().then(function () {
            if (trackSaveEvent) {
              trackSaveEvent();
            }

            return _this.closeModal();
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (errors && errors[0]) {
              var createError = "Something went wrong, ".concat(_this.instanceName, " could not be created.");
              errors.forEach(function (error) {
                var errorMessage = _this.getErrorMessage(error, createError);

                _this.set('serverError', errorMessage);

                _this.set('serverErrorTimeoutId', Ember.run.later(_this, function () {
                  _this.set('serverError', null);
                }, _this.serverErrorTimeoutDelay));
              });
            }
          });
        }).catch(function (errors) {
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this.set('booleanError', booleanError.validation);

              _this.set('serverErrorTimeoutId', Ember.run.later(_this, function () {
                _this.set('booleanError', null);
              }, _this.serverErrorTimeoutDelay));
            }
          }
        }).finally(function () {
          return _this.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});