define("lh4/controllers/authenticated/epro/menu/price-groups/create", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    trackUpdateCompleted: function trackUpdateCompleted() {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Completed',
        'Entry Type': 'New',
        'Entry ID': this.changeset.get('id'),
        'Number of Updates': Object.keys(this.changeset.change).length,
        'Entries Updated': Object.keys(this.changeset.change),
        'Menu Component': 'Price Group'
      });
    },
    actions: {
      create: function create(tackSaveEvent) {
        var _this = this;

        if (this.isSaving) {
          return;
        }

        if (this.changeset.get('isImported')) {
          this.changeset.set('groupToImport', null);
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError();
        this.changeset.validate().then(function () {
          if (_this.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this.changeset.get('errors'));
          }

          if (_this.isModelAndChangesetPristine()) {
            return _this.closeModal();
          }

          return _this.changeset.save().then(function () {
            if (tackSaveEvent) {
              tackSaveEvent();
            }

            return _this.closeModal();
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (errors && errors[0]) {
              var createError = "Something went wrong, ".concat(_this.instanceName, " could not be created.");
              errors.forEach(function (error) {
                var errorMessage = _this.getErrorMessage(error, createError);

                _this.set('serverError', errorMessage);

                _this.set('serverErrorTimeoutId', Ember.run.later(_this, function () {
                  _this.set('serverError', null);
                }, _this.serverErrorTimeoutDelay));
              });
            }
          });
        }).catch(function (errors) {
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this.set('booleanError', booleanError.validation);

              _this.set('serverErrorTimeoutId', Ember.run.later(_this, function () {
                _this.set('booleanError', null);
              }, _this.serverErrorTimeoutDelay));
            }
          }
        }).finally(function () {
          return _this.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});