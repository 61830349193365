define("lh4/templates/authenticated/locations/location/hospitality/customize-item-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A2O8Wzy/",
    "block": "{\"symbols\":[\"itemGroup\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide computer sixteen wide tablet sixteen wide mobile column menu-items\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui segments\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui clearing segment itemGroup-segment\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"b\",true],[8],[0,\"Item Group\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",false],[12,\"class\",\"plus large right aligned vertical icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"createItemGroup\"]],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment body-menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"itemGroups\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui button item-group-buttons \",[28,\"if\",[[28,\"eq\",[[24,[\"currentGroup\",\"rowguid\"]],[23,1,[\"rowguid\"]]],null],\"active\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"editItemGroup\",[23,1,[\"rowguid\"]]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[23,1,[\"description\"]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide computer sixteen wide tablet sixteen wide mobile column menu-form\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui segments body-menu\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/hospitality/customize-item-groups.hbs"
    }
  });

  _exports.default = _default;
});