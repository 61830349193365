define("lh4/components/table-layout/shared/element-styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htBlue = _exports.groupDefaults = _exports.labelDefaults = _exports.textDefaults = _exports.rectangleDefaults = _exports.circleDefaults = _exports.defaultShadow = void 0;
  var defaultShadow = '2px 2px 10px rgba(0,0,0,0.2)';
  _exports.defaultShadow = defaultShadow;
  var circleDefaults = {
    radius: 40,
    fill: '#F1F1F1',
    originX: 'center',
    originY: 'center',
    shadow: defaultShadow,
    lockScalingX: true,
    lockScalingY: true,
    selectable: false,
    hasControls: false,
    hasBorders: false
  };
  _exports.circleDefaults = circleDefaults;
  var rectangleDefaults = {
    width: 80,
    height: 80,
    fill: '#F1F1F1',
    originX: 'center',
    originY: 'center',
    shadow: defaultShadow,
    lockScalingX: true,
    lockScalingY: true,
    selectable: false,
    hasControls: false,
    hasBorders: false
  };
  _exports.rectangleDefaults = rectangleDefaults;
  var textDefaults = {
    fontSize: 14,
    fill: 'black',
    originX: 'center',
    originY: 'center',
    fontFamily: 'Arial',
    lockScalingX: true,
    lockScalingY: true,
    selectable: false,
    hasControls: false,
    hasBorders: false,
    fontWeight: 'bold'
  };
  _exports.textDefaults = textDefaults;
  var labelDefaults = {
    fontSize: 12,
    fontWeight: 'bold',
    fill: 'white',
    originX: 'center',
    originY: 'center',
    fontFamily: 'Arial',
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
    hasBorders: false
  };
  _exports.labelDefaults = labelDefaults;
  var groupDefaults = {
    originX: 'center',
    originY: 'center',
    left: 150,
    top: 100,
    angle: 0,
    hasBorders: false,
    hasControls: false
  };
  _exports.groupDefaults = groupDefaults;
  var htBlue = '#009BF4';
  _exports.htBlue = htBlue;
});