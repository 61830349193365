define("lh4/templates/components/ui/icons/sidebar/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ua0VIxLc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M3.00098 19C2.45098 19 1.98031 18.8043 1.58898 18.413C1.19698 18.021 1.00098 17.55 1.00098 17V7C1.00098 6.45 1.19698 5.97933 1.58898 5.588C1.98031 5.196 2.45098 5 3.00098 5H13.001C13.551 5 14.022 5.196 14.414 5.588C14.8053 5.97933 15.001 6.45 15.001 7V17C15.001 17.55 14.8053 18.021 14.414 18.413C14.022 18.8043 13.551 19 13.001 19H3.00098ZM3.00098 15.35C3.73431 14.9167 4.51764 14.5833 5.35098 14.35C6.18431 14.1167 7.06764 14 8.00098 14C8.93431 14 9.81764 14.1167 10.651 14.35C11.4843 14.5833 12.2676 14.9167 13.001 15.35V7H3.00098V15.35ZM8.00098 16C7.31764 16 6.65098 16.0833 6.00098 16.25C5.35098 16.4167 4.73431 16.6667 4.15098 17H11.851C11.2676 16.6667 10.651 16.4167 10.001 16.25C9.35098 16.0833 8.68431 16 8.00098 16ZM8.00098 13.25C7.25098 13.25 6.60498 12.9793 6.06298 12.438C5.52164 11.896 5.25098 11.25 5.25098 10.5C5.25098 9.75 5.52164 9.104 6.06298 8.562C6.60498 8.02067 7.25098 7.75 8.00098 7.75C8.75098 7.75 9.39698 8.02067 9.93898 8.562C10.4803 9.104 10.751 9.75 10.751 10.5C10.751 11.25 10.4803 11.896 9.93898 12.438C9.39698 12.9793 8.75098 13.25 8.00098 13.25ZM8.00098 11.4C8.25098 11.4 8.46331 11.3123 8.63798 11.137C8.81331 10.9623 8.90098 10.75 8.90098 10.5C8.90098 10.25 8.81331 10.0377 8.63798 9.863C8.46331 9.68767 8.25098 9.6 8.00098 9.6C7.75098 9.6 7.53864 9.68767 7.36398 9.863C7.18864 10.0377 7.10098 10.25 7.10098 10.5C7.10098 10.75 7.18864 10.9623 7.36398 11.137C7.53864 11.3123 7.75098 11.4 8.00098 11.4ZM17.001 19V5H19.001V19H17.001ZM21.001 19V5H23.001V19H21.001Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/customers.hbs"
    }
  });

  _exports.default = _default;
});