define("lh4/enums/color-code-strategy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COLOR_CODE_STRATEGY = void 0;
  var COLOR_CODE_STRATEGY = Object.freeze({
    NONE: 'none',
    DEFAULT: 'default',
    BY_NODE_TYPE: 'by-node-type'
  });
  _exports.COLOR_CODE_STRATEGY = COLOR_CODE_STRATEGY;
  var _default = COLOR_CODE_STRATEGY;
  _exports.default = _default;
});