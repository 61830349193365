define("lh4/components/loyalty/loyalty-discount-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    locale: Ember.inject.service(),
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.discountTypes = [{
        value: 'amount',
        name: "Amount (".concat(this.locale.currencySymbol, ")")
      }, {
        value: 'percent',
        name: 'Percentage (%)'
      }];
    },
    programOptions: Ember.computed.map('programs', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          isActive = _ref.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    actions: {
      onProgramChange: function onProgramChange(guid) {
        var program = this.store.peekRecord('loyalty/program', guid);
        this.discount.set('loyaltyProgramGuid', program);
      }
    }
  });

  _exports.default = _default;
});