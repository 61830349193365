define("lh4/templates/components/internet-payments/ui/fields/datetime-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vrTl3laD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"period\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"span\",false],[12,\"class\",\"period-text\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"onFocus\"]],[8],[1,[22,\"period\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"withoutDefaultDateTime\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"input\",true],[11,\"class\",[29,[\"datetime-range-picker-empty-input \",[28,\"if\",[[24,[\"placeholder\"]],\"datetime-input-with-placeholder\"],null]]]],[11,\"onfocus\",[28,\"action\",[[23,0,[]],\"onFocus\"],null]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"input\",true],[11,\"class\",[29,[\"datetime-range-picker-input \",[28,\"if\",[[24,[\"withoutDefaultDateTime\"]],\"hidden\"],null],\" \",[28,\"if\",[[24,[\"placeholder\"]],\"datetime-input-with-placeholder\"],null]]]],[11,\"onblur\",[22,\"onBlur\"]],[11,\"onfocus\",[28,\"action\",[[23,0,[]],\"onFocus\"],null]],[11,\"onkeydown\",[28,\"action\",[[23,0,[]],\"onKeyDown\"],null]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"placeholder\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"placeholder \",[28,\"if\",[[24,[\"withoutDefaultDateTime\"]],\"center\",\" \"],null]]]],[8],[1,[22,\"placeholder\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"i\",true],[10,\"class\",\"chevron down icon\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/fields/datetime-range-picker.hbs"
    }
  });

  _exports.default = _default;
});