define("lh4/templates/components/reports/solo/quick-menu-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ce6m+SuE",
    "block": "{\"symbols\":[\"employee\"],\"statements\":[[4,\"reports/table-wrapper\",null,[[\"generatedReport\",\"currentReport\",\"onToggleFavorite\",\"reportType\",\"generatedReportType\",\"enabledProd\",\"selectedLocations\",\"exportTypes\",\"startsAt\",\"endsAt\"],[[24,[\"generatedReport\"]],[24,[\"currentReport\"]],[24,[\"onToggleFavorite\"]],[24,[\"reportType\"]],[24,[\"generatedReportType\"]],[24,[\"enabledProd\"]],[24,[\"selectedLocations\"]],[24,[\"exportTypes\"]],[24,[\"startsAt\"]],[24,[\"endsAt\"]]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"bar-tables ui grid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide tablet sixteen wide mobile column\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"tables/quick-menu-table\",null,[[\"data\",\"show\",\"firstRowHeader\"],[[24,[\"currentReport\",\"totals\"]],\"5\",true]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"bar-tables ui grid\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"currentReport\",\"employees\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide computer sixteen wide tablet sixteen wide mobile column quick-menu-container\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"tables/quick-menu-table\",null,[[\"data\",\"show\",\"firstRowHeader\"],[[23,1,[]],\"5\",true]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reports/solo/quick-menu-summary.hbs"
    }
  });

  _exports.default = _default;
});