define("lh4/components/reputation/review-view-source-button", ["exports", "lh4/mixins/reputation/reputation-account-types"], function (_exports, _reputationAccountTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend((0, _reputationAccountTypes.default)('account'), {
    tagName: '',
    additionalClasses: '',

    /* @param */
    review: null,
    mainPost: null,
    isLinkSupported: Ember.computed('review.url', 'account.type', function getter() {
      return this.get('isFbPage') || this.get('isYelp');
    }),
    actions: {
      viewReviewSource: function viewReviewSource(review) {
        if (review.url) {
          window.open(review.url);
        }
      },
      selectPost: function selectPost() {
        this.sendAction('selectComment', this.get('mainPost'));
      }
    }
  });

  _exports.default = _default;
});