define("lh4/routes/authenticated/customer-engagement/settings", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'manage campaigns for customer-engagement',
    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor('authenticated.customer-engagement.settings.index');
        model.settings.rollbackAttributes();
      }
    }
  });

  _exports.default = _default;
});