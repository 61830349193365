define("lh4/mirage/factories/dispute", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    cardNumber: '************5678',
    cardType: 'MC',
    caseId: function caseId() {
      return _faker.default.datatype.uuid();
    },
    caseType: 'Dispute',
    disputeAmt: '40.00',
    fileDate: '2021-04-05T00:00:00.000Z',
    reasonCode: 'REASON_CODE',
    requestToken: function requestToken() {
      return _faker.default.datatype.uuid();
    },
    status: 'Queued',
    transAmt: '40.00',
    transDate: '2021-03-05T00:00:00.000Z',
    authNumber: function authNumber() {
      return _faker.default.datatype.uuid();
    }
  });

  _exports.default = _default;
});