define("lh4/components/ui/navigation/internet-payments-navigation", ["exports", "jquery", "lh4/config/environment", "lh4/enums/products"], function (_exports, _jquery, _environment, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['left-menu-container'],
    branding: _environment.default.branding,
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    permissions: Ember.inject.service(),
    isOpen: false,
    media: Ember.inject.service(),
    router: Ember.inject.service(),
    selectLocation: function selectLocation() {},
    ENV: _environment.default,
    count: 0,
    // this is used to limiting this.onToggleNav() only one time from toggleNav()
    currentMenu: null,
    can: Ember.inject.service(),
    canCreateOwnSubscriptions: Ember.computed('location.model.id', function getter() {
      return this.hasPermission('create own subscriptions in general');
    }),
    canViewReports: Ember.computed('location.model.id', function getter() {
      return this.hasPermission('view reports in general');
    }),
    canViewDocuments: Ember.computed('location.model.id', function getter() {
      return this.hasPermission('view documents in general');
    }),
    canViewSupportInfoForLocation: Ember.computed('location.model.id', function getter() {
      return this.can.can('view info for location in support');
    }),
    canViewTransactions: Ember.computed('location.model.id', function getter() {
      return this.can.can('view transactions in general');
    }),
    canViewCustomers: Ember.computed('location.model.id', function getter() {
      return this.can.can('view transactions in general') && !this.location.isTemporarilyHiddenForCA;
    }),
    canViewTransactionsCategory: Ember.computed('location.model.id', function getter() {
      return this.can.can('view transactions in general') || this.can.can('create transactions in transaction') || this.can.can('view disputes in internet-payment');
    }),
    getInsightRoute: Ember.computed('location.model.id', function getter() {
      if (this.can.can('view insights auth in internet-payments')) {
        return 'authenticated.internet-payments.insights.auth';
      }

      if (this.can.can('view insights risk in internet-payments')) {
        return 'authenticated.internet-payments.insights.risk';
      }

      if (this.can.can('view insights card verify in internet-payments')) {
        return 'authenticated.internet-payments.insights.card-verify';
      }

      if (this.can.can('view insights chargeback in internet-payments')) {
        return 'authenticated.internet-payments.insights.chargeback';
      }

      return '';
    }),
    openTransactionsAccordion: Ember.on('init', Ember.observer('router.currentRouteName', function callback() {
      var currentRouteName = this.router.currentRouteName;

      if (currentRouteName !== null && currentRouteName !== void 0 && currentRouteName.includes('internet-payments.transactions')) {
        var accordion = (0, _jquery.default)('#internet-payment-transactions-accordion');
        accordion.accordion('open', 0);
      }
    })),
    hasPermission: function hasPermission(permission) {
      var _this = this;

      return this.locations.filter(function (_ref) {
        var product = _ref.product,
            id = _ref.id;
        return product === _products.default.INTERNET_PAYMENTS && _this.can.can(permission, null, {
          locationId: id
        });
      }).length > 0;
    },
    actions: {
      subMenuOnOpen: function subMenuOnOpen(route, queryObject) {
        if (queryObject !== null && queryObject !== void 0 && queryObject.isQueryParams) {
          this.router.transitionTo(route, {
            queryParams: queryObject.values
          });
        } else {
          this.router.transitionTo(route);
        }
      },
      changeLocation: function changeLocation() {
        if (this.location.hasMultipleLocations) {
          this.set('isOpen', true);
        }
      },
      closeSidebar: function closeSidebar() {
        if (!this.isDestroyed) {
          this.set('isOpen', false);
        }
      },
      logout: function logout() {
        this.metrics.invoke('logout', 'datadogRum', {});
        this.session.invalidate();
      },
      toggleNav: function toggleNav(navEvent) {
        var menu = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        if (this.media.isDesktop || menu !== null) {
          return;
        }

        this.onToggleNav(navEvent);
      }
    }
  });

  _exports.default = _default;
});