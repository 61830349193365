define("lh4/controllers/authenticated/internet-payments/transactions/master-transactions/edit/risk-block-list", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    session: Ember.inject.service(),
    location: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    riskBlockListApi: Ember.inject.service('internet-payments/risk-block-list-api'),
    isSaving: false,
    errorMessage: null,
    requestArray: [],
    updateRiskBlockListStatus: function updateRiskBlockListStatus(parameter, value, status) {
      var newRequestArray = this.get('requestArray') || [];
      newRequestArray = newRequestArray.filter(function (r) {
        return r.parameter !== parameter;
      });
      newRequestArray.push({
        parameter: parameter,
        value: value,
        status: status
      });
      this.set('requestArray', newRequestArray);
    },
    actions: {
      closeBlockList: function closeBlockList() {
        this.set('errorMessage', null);
        this.transitionToRoute('authenticated.internet-payments.transactions.master-transactions.edit');
      },
      handleEmailBlockListStatusChanged: function handleEmailBlockListStatusChanged(status, email) {
        this.updateRiskBlockListStatus('email', email, status);
      },
      handleDeviceBlockListStatusChanged: function handleDeviceBlockListStatusChanged(status, deviceId) {
        this.updateRiskBlockListStatus('deviceId', deviceId, status);
      },
      handleTokenBlockListStatusChanged: function handleTokenBlockListStatusChanged(status, cardToken) {
        this.updateRiskBlockListStatus('token', cardToken, status);
      },
      handleEmailDomainBlockListStatusChanged: function handleEmailDomainBlockListStatusChanged(status, domain) {
        this.updateRiskBlockListStatus('emailDomain', domain, status);
      },
      handleCustomerNameBlockListStatusChanged: function handleCustomerNameBlockListStatusChanged(status, customerName) {
        this.updateRiskBlockListStatus('fullName', customerName, status);
      },
      saveBlockList: function saveBlockList() {
        var _this = this;

        var requestArray = this.get('requestArray');

        if (!requestArray || requestArray.length === 0) {
          this.send('closeBlockList');
        }

        this.set('isSaving', true);
        return this.riskBlockListApi.saveRiskBlockList(requestArray, this.model.transaction.locationId).then(function () {
          _this.send('riskBlockListChanged');

          _this.send('closeBlockList');
        }).catch(function (e) {
          var _e$payload, _e$payload$error, _e$payload2, _e$payload2$error;

          var message = ((_e$payload = e.payload) === null || _e$payload === void 0 ? void 0 : (_e$payload$error = _e$payload.error) === null || _e$payload$error === void 0 ? void 0 : _e$payload$error.friendlyError) || ((_e$payload2 = e.payload) === null || _e$payload2 === void 0 ? void 0 : (_e$payload2$error = _e$payload2.error) === null || _e$payload2$error === void 0 ? void 0 : _e$payload2$error.message) || e.message;

          _this.set('errorMessage', "An error occured while saving data: ".concat(message));
        }).finally(function () {
          _this.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});