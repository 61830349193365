define("lh4/serializers/echo-pro/ingredient", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = _application.default.extend({
    primaryKey: 'guid',
    attrs: {
      items: {
        embedded: 'always',
        serialize: 'id',
        deserialize: 'records'
      },
      itemsMap: {
        deserialize: 'records',
        serialize: 'records'
      },
      modifiers: {
        embedded: 'always',
        serialize: 'id',
        deserialize: 'records'
      },
      modifiersMap: {
        deserialize: 'records',
        serialize: 'records'
      }
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (jsonToNormalize.items) {
        json.itemsMap = jsonToNormalize.items.map(function (item) {
          return {
            guid: item.guid,
            ingredientGuid: json.guid,
            itemGuid: item.guid,
            name: item.name,
            quantity: item.quantity
          };
        });
      }

      if (jsonToNormalize.modifiers) {
        json.modifiersMap = jsonToNormalize.modifiers.map(function (modifier) {
          return {
            guid: modifier.guid,
            ingredientGuid: json.guid,
            modifierGuid: modifier.guid,
            name: modifier.name,
            quantity: modifier.quantity
          };
        });
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.items = json.itemsMap.map(function (i) {
        return i.itemGuid;
      });
      json.modifiers = json.modifiersMap.map(function (m) {
        return m.modifierGuid;
      });
      json.itemsMap = json.itemsMap.map(function (_ref) {
        var guid = _ref.guid,
            rest = _objectWithoutProperties(_ref, ["guid"]);

        return rest;
      });
      json.modifiersMap = json.modifiersMap.map(function (_ref2) {
        var guid = _ref2.guid,
            rest = _objectWithoutProperties(_ref2, ["guid"]);

        return rest;
      });
      return json;
    }
  });

  _exports.default = _default;
});