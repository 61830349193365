define("lh4/routes/authenticated/epro/menu/modifiers/edit", ["exports", "lh4/routes/general-edit-route", "lh4/utils/menu-handle-changeset-update"], function (_exports, _generalEditRoute, _menuHandleChangesetUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    modelName: 'modifier',
    model: function model(_ref) {
      var modifierId = _ref.modifierId;
      return Ember.RSVP.hash({
        modifier: this.store.findRecord('echo-pro/modifier', modifierId),
        modifierSets: this.modelFor('authenticated.epro.menu.modifiers').modifierSets,
        printers: this.modelFor('authenticated.epro.menu.modifiers').printers,
        stylesheets: this.isEproColorPickerFeatureFlagEnabled() ? this.store.findAll('echo-pro/stylesheet') : null
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'Edit',
        'Entry ID': model.modifier.guid,
        'Menu Component': 'Modifier'
      });
    },
    afterModel: function afterModel(model) {
      var attrs = [['printers', 'printerIds'], ['modifierSets', 'modifierSetIds'], ['nestedModifierSets', 'nestedModifierSetIds']];
      (0, _menuHandleChangesetUpdate.default)(attrs, model.modifier);
    }
  });

  _exports.default = _default;
});