define("lh4/templates/components/modal/action-confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Twa+/nu5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-modal\",null,[[\"id\",\"class\",\"onApprove\",\"onDeny\",\"centered\"],[[24,[\"id\"]],\"small\",[24,[\"onApprove\"]],[24,[\"onDeny\"]],false]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\",true],[11,\"class\",[29,[[22,\"icon\"],\" outline icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[1,[22,\"question\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[1,[22,\"description\"],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"deniable\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"ui \",[22,\"denyClassNames\"],\" button cancel\"]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"denyText\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\n\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"ui \",[22,\"approveClassNames\"],\" right labeled icon button ok\"]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"approveText\"],false],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"checkmark icon\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/modal/action-confirmation.hbs"
    }
  });

  _exports.default = _default;
});