define("lh4/components/shared-forms/subscription", ["exports", "lh4/utils/times-in-day", "moment", "lh4/enums/products", "lh4/enums/processing-report-type-slug", "lh4/helpers/product-display"], function (_exports, _timesInDay, _moment, _products, _processingReportTypeSlug, _productDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    locations: [],
    reportTypes: {},
    showErrors: false,
    region: Ember.inject.service('locale/region'),
    isSettlementReport: function isSettlementReport() {
      return this.subscription.get('product') === _products.default.PROCESSING && this.subscription.get('report') === _processingReportTypeSlug.default.SETTLEMENT;
    },
    getUnsupportedLocations: function getUnsupportedLocations(product, locationIds) {
      var unsupportedLocations = [];

      if (product) {
        if (product !== this.productForAllLocations) {
          var selectedLocations = this.locations.filter(function (_ref) {
            var id = _ref.id;
            return locationIds.includes(id);
          });
          unsupportedLocations = selectedLocations.filter(function (location) {
            switch (product) {
              case 'solo':
              case 'gpatt':
                return !location.terminalProducts.includes('gpatt');

              case 'loyalty':
                return !location.services.any(function (res) {
                  return res.name === 'lighthouse-loyalty';
                });

              default:
                return location.product !== product;
            }
          });
        }
      }

      return unsupportedLocations;
    },
    getUnauthorizedLocations: function getUnauthorizedLocations(locationIds) {
      var nonSkytabPOSLocations = [];
      var countryCode = this.region.location.model.countryCode;
      var preferredLocations = this.locations.filter(function (_ref2) {
        var id = _ref2.id;
        return locationIds.includes(id);
      });

      if (this.isSettlementReport()) {
        nonSkytabPOSLocations = preferredLocations.filter(function (_ref3) {
          var product = _ref3.product;
          return product !== _products.default.EW;
        });
      }

      return [].concat(_toConsumableArray(preferredLocations.filter(function (location) {
        return location.countryCode !== countryCode;
      })), _toConsumableArray(nonSkytabPOSLocations));
    },
    productOptions: Ember.computed('products', function getter() {
      return this.products.map(function (type) {
        return {
          name: (0, _productDisplay.productDisplay)([type]),
          value: type
        };
      });
    }),
    // "all" is not true anymore. Processing -> Settlement is available to ST POS only
    productForAllLocations: 'processing',
    timeOptions: (0, _timesInDay.default)(15),
    frequencyOptions: [{
      name: 'Monthly',
      value: 'monthly'
    }, {
      name: 'Weekly',
      value: 'weekly'
    }, {
      name: 'Daily',
      value: 'daily'
    }],
    dayLabel: Ember.computed('subscription.frequency', function getter() {
      var frequency = this.subscription.get('frequency');

      if (frequency === 'daily') {
        return 'Reporting Day';
      }

      var formattedFrequency = frequency.slice(0, frequency.indexOf('ly')).capitalize();
      return "Day of ".concat(formattedFrequency);
    }),
    dayOptions: Ember.computed('subscription.frequency', function getter() {
      switch (this.subscription.get('frequency')) {
        case 'daily':
          return [{
            name: 'Last Day',
            value: 0
          }, {
            name: 'Current Day',
            value: 1
          }];

        case 'monthly':
          return Array(31).fill().map(function (e, i) {
            return {
              name: i + 1,
              value: i + 1
            };
          });

        default:
          return _moment.default.weekdays().map(function (name, i) {
            return {
              name: name,
              value: i
            };
          });
      }
    }),
    isReportSelectorEnabled: Ember.computed('subscribableReportTypes', function getter() {
      return this.subscribableReportTypes && this.subscribableReportTypes.length > 0;
    }),
    subscribableReportTypes: Ember.computed('subscription.product', 'subscription.options.locations.@each', function getter() {
      var product = this.subscription.get('product');
      var productReportTypes = this.reportTypes[product];

      if (!productReportTypes) {
        return undefined;
      }

      var subscribableReportTypes = productReportTypes.filter(function (_ref4) {
        var isHidden = _ref4.isHidden,
            subscribable = _ref4.subscribable;
        return isHidden === false && subscribable;
      });
      var hasMenchieLocation = this.locations.some(function (location) {
        return location.name.includes('MENCHIE');
      });

      if (this.subscription.get('product') === 'echo' && !hasMenchieLocation) {
        // Exclude menchies specific reports if not menchies
        return subscribableReportTypes.filterBy('menchiesOnly', false);
      }

      return subscribableReportTypes;
    }),
    reportTypeOptions: Ember.computed('subscribableReportTypes', function getter() {
      if (!this.subscribableReportTypes) {
        return undefined;
      }

      return this.subscribableReportTypes.map(function (type) {
        var name = type.name;

        if (type.params.locations && type.params.locations.limit === 1) {
          name += '<span class="asterisk">*</span>';
        }

        if (type.isFavorite) {
          name += '<i class="ui heart small icon favorite-icon" />';
        }

        return {
          name: name,
          value: type.slug
        };
      });
    }),
    exportTypes: Ember.computed('subscription.report', 'subscribableReportTypes', function getter() {
      var subscribableReportTypes = this.subscribableReportTypes;

      if (subscribableReportTypes) {
        var report = subscribableReportTypes.findBy('slug', this.subscription.get('report'));
        return report ? report.exports : {};
      }

      return {};
    }),
    exportTypeOptions: Ember.computed('exportTypes', function getter() {
      var options = [];

      if (this.exportTypes.csv) {
        options.push({
          name: 'CSV',
          value: 'csv'
        });
      }

      if (this.exportTypes.pdf) {
        options.push({
          name: 'PDF',
          value: 'pdf'
        });
      }

      if (this.exportTypes.xls) {
        options.push({
          name: 'XLS',
          value: 'xls'
        });
      }

      return options.length ? options : null;
    }),
    actions: {
      selectLocations: function selectLocations(locationIds, property) {
        var product = this.subscription.get('product');
        var unsupportedLocations = this.getUnsupportedLocations(product, locationIds);
        var unauthorizedLocations = this.getUnauthorizedLocations(locationIds);
        var unsupportedUnauthorizedLocations = Array.from(new Set(unsupportedLocations.concat(unauthorizedLocations)));

        if (unsupportedUnauthorizedLocations.length) {
          var count = unsupportedUnauthorizedLocations.length;
          alert("There are ".concat(count, " locations that are not supported by the product or the country."));
        } else {
          this.subscription.set(property, locationIds);
        }
      },
      selectProduct: function selectProduct(product) {
        if (this.showErrors) {
          this.subscription.validate('report');
        }

        var locationIds = this.subscription.get('options.locations');
        var unsupportedLocations = this.getUnsupportedLocations(product, locationIds);
        var hasSupportedLocations = locationIds.length > unsupportedLocations.length;
        var shouldUpdateProduct = true;

        if (!hasSupportedLocations) {
          var message = "The selected product, \"".concat((0, _productDisplay.productDisplay)([product]), "\", is not supported by any location") + ' you have selected. Please choose a different product or update your selected locations.';
          alert(message);
          shouldUpdateProduct = false;
        } else if (unsupportedLocations.length) {
          var confirmation = "The selected product, \"".concat((0, _productDisplay.productDisplay)([product]), "\", is not supported by") + " ".concat(unsupportedLocations.length, " locations that you have selected.") + ' Do you still wish to continue with this product?';
          shouldUpdateProduct = window.confirm(confirmation);
        }

        if (shouldUpdateProduct) {
          this.subscription.set('product', product);
        } else {
          this.subscription.set('product', null);
        }

        this.subscription.set('report', null);
        this.subscription.validate('report');
      },
      selectReport: function selectReport(value) {
        this.subscription.set('report', value);
        var locationIds = this.subscription.get('options.locations');
        var locations = this.locations.filter(function (_ref5) {
          var id = _ref5.id;
          return locationIds.includes(id);
        });

        if (this.subscription.get('product') === _products.default.PROCESSING && this.subscription.get('report') === _processingReportTypeSlug.default.SETTLEMENT && locations.some(function (_ref6) {
          var product = _ref6.product;
          return product !== _products.default.EW;
        })) {
          this.subscription.set('report', null);
          alert('Settlement report can only be selected for ST POS locations');
        }
      },
      selectFrequency: function selectFrequency(value) {
        this.subscription.set('frequency', value);
        this.subscription.set('day', null);

        if (this.showErrors) {
          this.subscription.validate('day');
        }
      }
    }
  });

  _exports.default = _default;
});