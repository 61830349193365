define("lh4/components/ui/s4/editable-input-group/editable-input-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="editable-input-group {{@class}}" data-test-editable-group="{{@data-test-editable-group}}">
  	{{yield}}
  </div>
  */
  {
    id: "ytv9Z3SC",
    block: "{\"symbols\":[\"@class\",\"@data-test-editable-group\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"editable-input-group \",[23,1,[]]]]],[11,\"data-test-editable-group\",[29,[[23,2,[]]]]],[8],[0,\"\\n\\t\"],[14,3],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/editable-input-group/editable-input-group.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});