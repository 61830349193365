define("lh4/mirage/routes/internet-payments/location-groups", ["exports", "lh4/mirage/fixtures/internet-payments"], function (_exports, _internetPayments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsLocationGroupsServer;

  function setMirageInternetPaymentsLocationGroupsServer(server) {
    server.get('/internet-payments/transactions', function () {
      return _internetPayments.default;
    });
  }
});