define("lh4/routes/authenticated/customer-engagement/campaigns/overview/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      to: {
        refreshModel: true
      },
      from: {
        refreshModel: true
      }
    },
    ajax: Ember.inject.service(),
    campaignApi: Ember.inject.service('customer-engagement/campaign-api'),
    errorHandler: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var _this$paramsFor = this.paramsFor('authenticated.customer-engagement.campaigns.overview'),
          campaignId = _this$paramsFor.campaignId;

      return Ember.RSVP.hash({
        overview: this.campaignApi.loadOverview(campaignId, params).catch(function (err) {
          _this.errorHandler.handleResponseError(err, 'Could not load overview data');

          return {};
        }),
        campaign: this.store.peekRecord('customer-engagement/campaign', campaignId)
      });
    }
  });

  _exports.default = _default;
});