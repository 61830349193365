define("lh4/routes/authenticated/settings/account", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/enums/date-range-title"], function (_exports, _authenticatedRouteMixin, _dateRangeTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    can: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        selectedPeriod: (0, _dateRangeTitle.isCurrentBusinessDay)(model.preferences.currentOrLastDay) ? _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT : _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST
      });
    }
  });

  _exports.default = _default;
});