define("lh4/models/virtual-terminal/refund-opt-out", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    merchantId: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    streetAddress: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zip: (0, _attr.default)('string'),
    countryCode: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    reason: (0, _attr.default)('string'),
    signature: (0, _attr.default)('string'),
    title: (0, _attr.default)('string')
  });

  _exports.default = _default;
});