define("lh4/routes/authenticated/locations/location/echo-pro/reservations/settings", ["exports", "ember-onbeforeunload/mixins/confirmation", "lh4/mixins/authenticated-route-mixin"], function (_exports, _confirmation, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_confirmation.default, _authenticatedRouteMixin.default, {
    model: function model() {
      var _this = this;

      // unloading records so that if a user has multiple locations,
      // the primary keys won't collide
      this.store.unloadAll('reservations/setting');
      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        settings: this.store.query('reservations/setting', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('reservations/setting').sortBy('id');
        }),
        channel: location.get('channel')
      });
    }
  });

  _exports.default = _default;
});