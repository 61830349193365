define("lh4/mirage/fixtures/internet-payments/users/configurable-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CONFIGURABLE_COLUMNS_EW_FIXTURE = _exports.CONFIGURABLE_COLUMNS_OP_FIXTURE = void 0;
  var CONFIGURABLE_COLUMNS_OP_FIXTURE = [{
    createdAt: '2023-08-22T08:47:34.251Z',
    updatedAt: '2023-08-22T08:47:34.251Z',
    guid: '79f14bbd-0f48-44ac-9180-dba553257b25',
    viewName: 'Template',
    locationId: 1,
    userId: 1,
    isFavorite: false,
    resourceViewPerspective: 'TRANSACTION-MASTER-DEFAULT',
    product: 'internet-payments',
    lbmColumnOptions: [{
      sortable: true,
      optionId: 'DATE',
      visible: true,
      name: 'Date',
      field: 'date'
    }, {
      sortable: true,
      optionId: 'ORDER_STATUS',
      visible: true,
      name: 'Order Status',
      field: 'processingStatus'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_ID',
      visible: true,
      name: 'Transaction ID',
      field: 'transactionId'
    }, {
      sortable: true,
      optionId: 'CREDIT_CARD',
      visible: true,
      name: 'Credit Card',
      field: 'cardNumber'
    }, {
      sortable: true,
      optionId: 'CUSTOMER_NAME',
      visible: true,
      name: 'Customer',
      field: 'customerName'
    }, {
      sortable: false,
      optionId: 'CUSTOMER_EMAIL',
      visible: true,
      name: 'Email',
      field: 'customerEmail'
    }, {
      sortable: false,
      optionId: 'CUSTOMER_IP',
      visible: false,
      name: 'Customer IP Address',
      field: 'customerIpAddress'
    }, {
      sortable: true,
      optionId: 'AMOUNT',
      visible: true,
      name: 'Amount',
      field: 'amount'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_TYPE',
      visible: true,
      name: 'Type',
      field: 'transType'
    }, {
      sortable: false,
      optionId: 'RISK_STATUS',
      visible: false,
      name: 'Risk',
      field: 'riskStatus'
    }, {
      sortable: false,
      optionId: 'RISK_ID',
      visible: false,
      name: 'Risk ID',
      field: 'riskId'
    }, {
      sortable: true,
      optionId: 'ACQUIRER_RESPONSE',
      visible: false,
      name: 'Acquirer Response',
      field: 'acquirerResponse'
    }, {
      sortable: false,
      optionId: 'CARD_TOKEN',
      visible: false,
      name: 'Card Token',
      field: 'cardToken'
    }, {
      sortable: false,
      optionId: 'MERCHANT_REFERENCE',
      visible: false,
      name: 'Merchant Reference',
      field: 'merchantReference'
    }, {
      sortable: false,
      optionId: 'ISSUER_BIN',
      visible: true,
      name: 'Issuer BIN',
      field: 'issuerBIN'
    }, {
      sortable: false,
      optionId: 'RISK_SCORE',
      visible: false,
      name: 'Risk Score',
      field: 'weightedRiskTotal'
    }, {
      sortable: false,
      optionId: 'SECURE_STATUS',
      visible: false,
      name: 'Secure Status',
      field: 'secureStatus'
    }]
  }, {
    guid: 'default',
    viewName: 'Default',
    locationId: 1,
    userId: 1,
    isFavorite: true,
    resourceViewPerspective: 'TRANSACTION-MASTER-DEFAULT',
    product: 'internet-payments',
    lbmColumnOptions: [{
      sortable: true,
      optionId: 'DATE',
      visible: true,
      name: 'Date',
      field: 'date'
    }, {
      sortable: false,
      optionId: 'MERCHANT_REFERENCE',
      visible: true,
      name: 'Merchant Reference',
      field: 'merchantReference'
    }, {
      sortable: false,
      optionId: 'ISSUER_BIN',
      visible: true,
      name: 'Issuer BIN',
      field: 'issuerBIN'
    }, {
      sortable: false,
      optionId: 'RISK_STATUS',
      visible: true,
      name: 'Risk',
      field: 'riskStatus'
    }, {
      sortable: true,
      optionId: 'ACQUIRER_RESPONSE',
      visible: true,
      name: 'Acquirer Response',
      field: 'acquirerResponse'
    }, {
      sortable: true,
      optionId: 'CUSTOMER_NAME',
      visible: true,
      name: 'Customer',
      field: 'customerName'
    }, {
      sortable: false,
      optionId: 'CUSTOMER_EMAIL',
      visible: true,
      name: 'Email',
      field: 'customerEmail'
    }, {
      sortable: true,
      optionId: 'CREDIT_CARD',
      visible: true,
      name: 'Credit Card',
      field: 'cardNumber'
    }, {
      sortable: true,
      optionId: 'AMOUNT',
      visible: true,
      name: 'Amount',
      field: 'amount'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_TYPE',
      visible: true,
      name: 'Type',
      field: 'transType'
    }, {
      sortable: true,
      optionId: 'ORDER_STATUS',
      visible: true,
      name: 'Order Status',
      field: 'processingStatus'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_ID',
      visible: true,
      name: 'Transaction ID',
      field: 'transactionId'
    }, {
      sortable: false,
      optionId: 'CUSTOMER_IP',
      visible: false,
      name: 'Customer IP Address',
      field: 'customerIpAddress'
    }, {
      sortable: false,
      optionId: 'RISK_ID',
      visible: false,
      name: 'Risk ID',
      field: 'riskId'
    }, {
      sortable: false,
      optionId: 'CARD_TOKEN',
      visible: false,
      name: 'Card Token',
      field: 'cardToken'
    }, {
      sortable: false,
      optionId: 'RISK_SCORE',
      visible: true,
      name: 'Risk Score',
      field: 'weightedRiskTotal'
    }, {
      sortable: false,
      optionId: 'SECURE_STATUS',
      visible: false,
      name: 'Secure Status',
      field: 'secureStatus'
    }]
  }];
  _exports.CONFIGURABLE_COLUMNS_OP_FIXTURE = CONFIGURABLE_COLUMNS_OP_FIXTURE;
  var CONFIGURABLE_COLUMNS_EW_FIXTURE = [{
    guid: 'default',
    viewName: 'Default',
    locationId: 1,
    userId: 1,
    isFavorite: true,
    resourceViewPerspective: 'TRANSACTION-MASTER-EW',
    product: 'internet-payments',
    lbmColumnOptions: [{
      sortable: true,
      optionId: 'DATE',
      visible: true,
      name: 'Date',
      field: 'date',
      columnType: 'date'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_ID',
      visible: true,
      name: 'Transaction ID',
      field: 'invoice',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'left'
      }
    }, {
      sortable: false,
      optionId: 'AUTHORIZATION_CODE',
      visible: true,
      name: 'Authorization Code',
      field: 'authorizationCode',
      columnType: 'text'
    }, {
      sortable: true,
      optionId: 'AMOUNT',
      visible: true,
      name: 'Total Amount',
      field: 'amount',
      columnType: 'currency'
    }, {
      sortable: true,
      optionId: 'PRIMARY_AMOUNT',
      visible: true,
      name: 'Primary Amount',
      field: 'primaryAmount',
      columnType: 'currency'
    }, {
      sortable: true,
      optionId: 'SECONDARY_AMOUNT',
      visible: true,
      name: 'Tip Amount',
      field: 'secondaryAmount',
      columnType: 'currency'
    }, {
      sortable: false,
      optionId: 'CUSTOMER_EMAIL',
      visible: true,
      name: 'Email',
      field: 'customerEmail',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'right'
      }
    }, {
      sortable: true,
      optionId: 'CUSTOMER_NAME',
      visible: true,
      name: 'Customer',
      field: 'customerName',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false,
        position: 'right'
      }
    }, {
      sortable: true,
      optionId: 'CREDIT_CARD',
      visible: true,
      name: 'Payment Method',
      field: 'cardNumber',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false
      }
    }, {
      sortable: true,
      optionId: 'ORDER_STATUS',
      visible: true,
      name: 'Transaction Status',
      field: 'processingStatus',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'EVENT_STATUS',
      visible: true,
      name: 'Event Status',
      field: 'eventStatus',
      columnType: 'text'
    }, {
      sortable: true,
      optionId: 'TRANSACTION_TYPE',
      visible: true,
      name: 'Transaction Type',
      field: 'transType',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'ORDER_NUMBER',
      visible: true,
      name: 'Order',
      field: 'orderNumber',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'SERVER_NAME',
      visible: true,
      name: 'Server',
      field: 'serverName',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'ACQUIRER_RESPONSE',
      visible: true,
      name: 'Acquirer Response',
      field: 'acquirerResponse',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'CUSTOMER_IP',
      visible: false,
      name: 'Customer IP Address',
      field: 'customerIpAddress',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'CARD_TOKEN',
      visible: false,
      name: 'Card Token',
      field: 'cardToken',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: true,
      optionId: 'MERCHANT_ID',
      visible: false,
      name: 'MID',
      field: 'merchantId',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false
      }
    }, {
      sortable: false,
      optionId: 'TERMINAL_NUMBER',
      visible: false,
      name: 'Terminal Number',
      field: 'terminalNumber',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false
      }
    }, {
      sortable: false,
      optionId: 'MERCHANT_BATCH_DATE_TZ',
      visible: false,
      name: 'Batch Date',
      field: 'merchantBatchDateTz',
      columnType: 'date',
      hints: {
        isCopiable: false,
        isCapitalized: false
      }
    }, {
      sortable: false,
      optionId: 'MERCHANT_BATCH_NUMBER',
      visible: false,
      name: 'Batch Number',
      field: 'merchantBatchNumber',
      columnType: 'text',
      hints: {
        isCopiable: true,
        isCapitalized: false
      }
    }, {
      sortable: false,
      optionId: 'BATCH_GROSS_AMOUNT',
      visible: false,
      name: 'Batch Gross Amount',
      field: 'batchGrossAmount',
      columnType: 'currency',
      hints: {
        isCopiable: false,
        isCapitalized: false
      }
    }, {
      sortable: false,
      optionId: 'BATCH_NET_AMOUNT',
      visible: false,
      name: 'Batch Net Amount',
      field: 'batchNetAmount',
      columnType: 'currency',
      hints: {
        isCopiable: false,
        isCapitalized: false
      }
    }, {
      sortable: false,
      optionId: 'CARD_TYPE_SOURCE',
      visible: false,
      name: 'Card Type',
      field: 'cardTypeSource',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'REVENUE_CENTER',
      visible: false,
      name: 'Revenue Center',
      field: 'revenueCenter',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'DAY_PARTS',
      visible: false,
      name: 'Day Parts',
      field: 'dayParts',
      columnType: 'text',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'TICKET_OPEN',
      visible: false,
      name: 'Ticket Open',
      field: 'ticketOpen',
      columnType: 'date',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }, {
      sortable: false,
      optionId: 'TICKET_CLOSE',
      visible: false,
      name: 'Ticket Close',
      field: 'ticketClose',
      columnType: 'date',
      hints: {
        isCopiable: false,
        isCapitalized: true
      }
    }]
  }];
  _exports.CONFIGURABLE_COLUMNS_EW_FIXTURE = CONFIGURABLE_COLUMNS_EW_FIXTURE;
});