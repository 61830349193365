define("lh4/serializers/customer-engagement/campaign", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'guid',
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Object.assign(hash, this.serialize(record, options));
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'customer-engagement/campaign';
    },
    attrs: {
      manualCustomers: {
        serialize: 'records',
        deserielize: false
      }
    }
  });

  _exports.default = _default;
});