define("lh4/models/loyalty/transaction", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    reason: {
      description: 'Reason',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        on: 'pointChange',
        ignoreBlank: true
      })]
    },
    loyaltyMemberGuid: {
      description: 'Loyalty Member',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    },
    pointChange: {
      description: 'Adjusting Points',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        on: 'reason',
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        integer: true,
        gte: -10000,
        lte: 10000
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    loyaltyMemberGuid: (0, _attr.default)('string'),
    pointChange: (0, _attr.default)('number'),
    reason: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    balanceAtPointInTime: (0, _attr.default)('number'),
    employeeName: (0, _attr.default)('string')
  });

  _exports.default = _default;
});