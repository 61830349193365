define("lh4/models/echo-pro/time-clock-segment", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    start: {
      description: 'From',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.not('model.isBreak')
      }), (0, _emberCpValidations.validator)('date', {
        onOrAfter: Ember.computed.alias('model.timeClockShift.clockedInAt'),
        message: '{description} must be on or after Clock In',
        disabled: Ember.computed.not('model.isBreak')
      }), (0, _emberCpValidations.validator)('date', {
        onOrBefore: Ember.computed.alias('model.timeClockShift.clockedOutAt'),
        message: '{description} must be on or before Clock Out',
        disabled: Ember.computed('model.isBreak', 'model.timeClockShift.clockedOutAt', function compute() {
          return !this.model.get('isBreak') || !this.model.get('timeClockShift.clockedOutAt');
        })
      }), (0, _emberCpValidations.validator)('date', {
        onOrBefore: 'now',
        message: '{description} must be on or before now',
        disabled: Ember.computed.not('model.isBreak')
      })]
    },
    end: {
      description: 'To',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.isBreak', 'model.timeClockShift.clockedOutAt', function compute() {
          return !this.model.get('isBreak') || !this.model.get('timeClockShift.clockedOutAt');
        })
      }), (0, _emberCpValidations.validator)('date', {
        after: Ember.computed.alias('model.start'),
        message: '{description} must be after From',
        disabled: Ember.computed.not('model.isBreak')
      }), (0, _emberCpValidations.validator)('date', {
        onOrBefore: Ember.computed.alias('model.timeClockShift.clockedOutAt'),
        message: '{description} must be on or before Clock Out',
        disabled: Ember.computed('model.isBreak', 'model.timeClockShift.clockedOutAt', function compute() {
          return !this.model.get('isBreak') || !this.model.get('timeClockShift.clockedOutAt');
        })
      }), (0, _emberCpValidations.validator)('date', {
        onOrBefore: 'now',
        message: '{description} must be on or before now',
        disabled: Ember.computed.not('model.isBreak')
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    guid: _emberData.default.attr('string'),
    start: _emberData.default.attr('string'),
    end: _emberData.default.attr('string'),
    isBreak: _emberData.default.attr('boolean'),
    isPaid: _emberData.default.attr('boolean'),
    isVoided: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date') // timeClockShift - reference to parent time clock shift

  });

  _exports.default = _default;
});