define("lh4/routes/authenticated/add-on-devices/gpatt", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "ember-onbeforeunload/mixins/confirmation"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _confirmation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _confirmation.default, {
    requiredPermissions: 'manage gpatt for add-on-devices',
    location: Ember.inject.service(),
    model: function model() {
      return this.store.findRecord('gpatt/settings', this.location.model.id);
    },
    isPageDirty: function isPageDirty(model) {
      var _this = this;

      var recipients = model['ratings-threshold-recipients'];
      var dirtyRecipients = recipients.filter(function (recipient) {
        return !recipient.id || recipient.hasDirtyAttributes || recipient.isDeleted;
      });
      var isDirty = model.hasDirtyAttributes || dirtyRecipients.length;

      if (isDirty || model['ratings-threshold-recipients'].length !== this.controller.recipientsSnapshots.length) {
        return true;
      } // if lengths match, check if both previous and current recipients don't match


      var currentRecipients = model['ratings-threshold-recipients'].map(function (c) {
        return JSON.stringify(c.toJSON());
      });
      isDirty = currentRecipients.some(function (c) {
        return !_this.controller.recipientsSnapshots.includes(c);
      }); // Returning this because we are rolling back values inside of the transition hook,
      // we need to look at this private method.
      // This is due to the fact that its getting used in the ember-onbeforeunload lib. :(

      return isDirty || !!this._transitionConfirmed;
    },
    customConfirm: function customConfirm() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var shouldTransition = window.confirm('It looks like there are unsaved form changes, would you like to continue?');

        if (!shouldTransition) {
          return reject();
        }

        var modelRecipients = _this2.controller.model['ratings-threshold-recipients'];
        var unsaved = [];
        modelRecipients.forEach(function (recipient) {
          if (!recipient.id) {
            unsaved.push(recipient);
          }

          recipient.rollbackAttributes();
        });
        modelRecipients.removeObjects(unsaved);

        _this2.controller.model.rollbackAttributes();

        return resolve();
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      controller.setRecipientsSnapshots(model['ratings-threshold-recipients']);
      controller.set('ratingsThresholdRecipients', model['ratings-threshold-recipients'].filterBy('isDeleted', false));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting && controller.openRecipient) {
        controller.openRecipient.unloadRecord();
        controller.setProperties({
          openRecipient: null,
          recipientsSnapshots: []
        });
      }
    }
  });

  _exports.default = _default;
});