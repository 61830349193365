define("lh4/routes/authenticated/redirects/marketplace-qr-pay", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    metrics: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.location.model.product === 'terminal') {
        return this.replaceWith('authenticated.ad.qrpay');
      }

      this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
        eventCategory: 'Outbound Link',
        eventAction: 'redirect',
        eventLabel: "Redirect marketplace-qr-pay - location: ".concat(this.location.model.id)
      });
      window.location.replace("/marketplace#/locations/".concat(this.location.model.id, "/apps/qr-pay"));
      return false;
    }
  });

  _exports.default = _default;
});