define("lh4/components/ui/form-fields/field", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataTestableComponent.default, {
    intl: Ember.inject.service(),
    changeset: {},
    classNames: ['stretched', 'column'],
    // 2nd item is because classNameBindings cannot contain spaces
    // and we need wide to follow sixteen or eight
    classNameBindings: ['fullWidth:sixteen:eight', 'fullWidth:wide:wide', 'label::no-label'],

    /**
     * @param {boolean} disabled - disables input and adds appropriate styling
     */
    disabled: false,

    /**
     * @param {boolean} required - used to force a required asterisk on our label
     */
    required: false,

    /**
     * @param {boolean} fullWidth - toggle between full/half of a containing grid
     */
    fullWidth: true,

    /**
     * @param {boolean} hasFocus - internal state for managing field styling
     */
    hasFocus: false,

    /**
     * @param {string} label - label text, if not present element is not rendered
     */
    label: null,

    /**
     * @param {boolean} showErrors - only triggers field validation if true
     */
    showErrors: false,

    /**
     * @param {string} property - changeset's property that holds the field's value
     */
    property: null,

    /**
     * @param {string} propertyAlias - property alias that can be used for ember relationship properties
     */
    propertyAlias: null,

    /**
     * @param {string} labelWidth - width class of label
     */
    labelWidth: null,

    /**
     * @param {string} fieldWidth - width class of field
     */
    fieldWidth: null,

    /**
     * @param {string} dataTest - data test property used for testing
     * @see mixins/data-testable-component
     */
    dataTest: 'form-field-field',
    init: function init() {
      var _this$propertyAlias;

      this._super.apply(this, arguments);

      if (!this.changeset || !this.changeset.data || !this.changeset.data.validations) {
        return;
      }

      var propName = (_this$propertyAlias = this.propertyAlias) !== null && _this$propertyAlias !== void 0 ? _this$propertyAlias : this.property;

      var _ref = this.changeset.data.validations.attrs.get(propName) || {},
          _ref$options = _ref.options,
          options = _ref$options === void 0 ? {} : _ref$options;

      if (options.presence) {
        this.required = true;
      }
    },
    hasError: Ember.computed('showErrors', 'changeset.error', function getter() {
      var _this$propertyAlias2;

      if (!this.showErrors || !this.changeset || !this.property) {
        return false;
      }

      var propName = (_this$propertyAlias2 = this.propertyAlias) !== null && _this$propertyAlias2 !== void 0 ? _this$propertyAlias2 : this.property;
      return this.changeset.get("error.".concat(propName));
    }),
    showErrorTooltip: Ember.computed('hasError', 'changeset.error', function getter() {
      if (!this.hasError) {
        return false;
      }

      var fieldHasError = this.changeset.get("error.".concat(this.property));

      if (!fieldHasError || !fieldHasError.value) {
        return false;
      }

      return true;
    }),
    fieldClasses: Ember.computed('disabled', 'hasFocus', 'hasError', 'showErrorTooltip', function getter() {
      var stateClasses = ["".concat(this.disabled ? ' disabled' : ''), "".concat(this.hasFocus ? ' focused' : ''), "".concat(this.showErrorTooltip ? ' show-error-tooltip' : ''), "".concat(this.hasError ? ' error' : '')];
      return "ui field padded stackable grid".concat(stateClasses.join(''));
    }),
    actions: {
      blurField: function blurField() {
        var _this$propertyAlias3;

        var propName = (_this$propertyAlias3 = this.propertyAlias) !== null && _this$propertyAlias3 !== void 0 ? _this$propertyAlias3 : this.property;

        if (this.blurField) {
          this.blurField(propName);
        } else if (this.showErrors) {
          this.changeset.validate(propName);
        }

        this.set('hasFocus', false);
      },
      focusField: function focusField() {
        this.set('hasFocus', true);
      },
      onChange: function onChange(value) {
        if (this.onChange) {
          this.onChange(value, this.property);
        } else {
          this.changeset.set(this.property, value);
        }
      },
      onKeydown: function onKeydown() {} // available to be overridden

    }
  });

  _exports.default = _default;
});