define("lh4/components/table-layout/shared/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SHAPE_LABEL = _exports.TABLE_SHAPE_ROUND = _exports.TABLE_SHAPE_RECT = void 0;
  var TABLE_SHAPE_RECT = 'rect';
  _exports.TABLE_SHAPE_RECT = TABLE_SHAPE_RECT;
  var TABLE_SHAPE_ROUND = 'round';
  _exports.TABLE_SHAPE_ROUND = TABLE_SHAPE_ROUND;
  var SHAPE_LABEL = 'label';
  _exports.SHAPE_LABEL = SHAPE_LABEL;
});