define("lh4/components/ui/form-fields/state-select-field", ["exports", "lh4/utils/states", "lh4/templates/components/ui/form-fields/select-field", "lh4/components/ui/form-fields/select-field"], function (_exports, _states, _selectField, _selectField2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _selectField2.default.extend({
    options: Ember.computed(function () {
      return _states.STATES.map(function (_ref) {
        var state = _ref.state,
            abbr = _ref.abbr;
        return {
          name: state,
          value: abbr
        };
      });
    }),
    label: 'State',
    property: 'state',
    layout: _selectField.default,
    dataTest: 'form-field-state-select-field'
  });

  _exports.default = _default;
});