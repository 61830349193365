define("lh4/routes/authenticated/epro/workforce/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    permissions: Ember.inject.service(),
    router: Ember.inject.service(),
    locationInterval: null,
    redirect: function redirect() {
      if (this.can.can('manage employees and jobs for echo-pro/workforce', this.location.model)) {
        return this.transitionTo('authenticated.epro.manage-employees.employees');
      }

      if (this.can.can('manage timeclock for echo-pro/workforce', this.location.model)) {
        return this.transitionTo('authenticated.epro.timeclock.settings');
      }

      return this.transitionTo('authenticated.epro.workforce.landing-page');
    },
    beforeModel: function beforeModel() {
      var _this = this;

      this._super.apply(this, arguments); // Pulling fresh location model in case something has changed


      return this.store.findRecord('location', this.location.model.id).then(function () {
        return _this.location.notifyPropertyChange('model');
      });
    },
    afterModel: function afterModel() {
      var _socialSchedulesServi,
          _socialSchedulesServi2,
          _socialSchedulesServi3,
          _socialSchedulesServi4,
          _socialSchedulesServi5,
          _socialSchedulesServi6,
          _this2 = this;

      var socialSchedulesService = this.permissions.getService('social-schedules');
      socialSchedulesService = ((_socialSchedulesServi = socialSchedulesService) === null || _socialSchedulesServi === void 0 ? void 0 : _socialSchedulesServi.locationService) || null;

      if (((_socialSchedulesServi2 = socialSchedulesService) === null || _socialSchedulesServi2 === void 0 ? void 0 : (_socialSchedulesServi3 = _socialSchedulesServi2.configuration) === null || _socialSchedulesServi3 === void 0 ? void 0 : _socialSchedulesServi3.status) === 'start' && !((_socialSchedulesServi4 = socialSchedulesService) !== null && _socialSchedulesServi4 !== void 0 && (_socialSchedulesServi5 = _socialSchedulesServi4.configuration) !== null && _socialSchedulesServi5 !== void 0 && _socialSchedulesServi5.error) && !((_socialSchedulesServi6 = socialSchedulesService) !== null && _socialSchedulesServi6 !== void 0 && _socialSchedulesServi6.isActive)) {
        this.set('locationInterval', setInterval(function () {
          _this2.permissions.refresh(_this2.location.model.id).then(function () {
            var _locationService$conf, _locationService$conf4, _locationService$conf5;

            var _this2$permissions$ge = _this2.permissions.getService('social-schedules'),
                locationService = _this2$permissions$ge.locationService;

            if (locationService !== null && locationService !== void 0 && (_locationService$conf = locationService.configuration) !== null && _locationService$conf !== void 0 && _locationService$conf.error && !(locationService !== null && locationService !== void 0 && locationService.isActive)) {
              var _locationService$conf2, _locationService$conf3;

              if ((locationService === null || locationService === void 0 ? void 0 : (_locationService$conf2 = locationService.configuration) === null || _locationService$conf2 === void 0 ? void 0 : _locationService$conf2.status) === 'start') {
                _this2.controller.set('showActivateButton', true);

                _this2.controller.send('showErrorForReactivation');

                clearInterval(_this2.locationInterval);
              } else if ((locationService === null || locationService === void 0 ? void 0 : (_locationService$conf3 = locationService.configuration) === null || _locationService$conf3 === void 0 ? void 0 : _locationService$conf3.status) === 'onboarding.error') {
                _this2.controller.set('showActivateButton', false);

                _this2.controller.send('showErrorForOnboardingError');

                clearInterval(_this2.locationInterval);
              }
            } else if (!(locationService !== null && locationService !== void 0 && (_locationService$conf4 = locationService.configuration) !== null && _locationService$conf4 !== void 0 && _locationService$conf4.error) && (locationService === null || locationService === void 0 ? void 0 : (_locationService$conf5 = locationService.configuration) === null || _locationService$conf5 === void 0 ? void 0 : _locationService$conf5.status) === 'onboarding.complete' && locationService !== null && locationService !== void 0 && locationService.isActive) {
              _this2.controller.set('showActivateButton', false);

              _this2.controller.send('showNotificationForOnboardingSuccess');

              clearInterval(_this2.locationInterval);
            }
          });
        }, 60000));
      }
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      var socialSchedulesService = this.permissions.getService('social-schedules');

      var _ref = socialSchedulesService || {},
          locationService = _ref.locationService;

      if (!(locationService !== null && locationService !== void 0 && locationService.isActive)) {
        var _locationService$conf6, _locationService$conf7, _locationService$conf8, _locationService$conf9;

        if ((locationService === null || locationService === void 0 ? void 0 : (_locationService$conf6 = locationService.configuration) === null || _locationService$conf6 === void 0 ? void 0 : _locationService$conf6.status) === 'start' && locationService !== null && locationService !== void 0 && (_locationService$conf7 = locationService.configuration) !== null && _locationService$conf7 !== void 0 && _locationService$conf7.error) {
          controller.set('showActivateButton', false);
        } else if ((locationService === null || locationService === void 0 ? void 0 : (_locationService$conf8 = locationService.configuration) === null || _locationService$conf8 === void 0 ? void 0 : _locationService$conf8.status) === 'onboarding.error' && locationService !== null && locationService !== void 0 && (_locationService$conf9 = locationService.configuration) !== null && _locationService$conf9 !== void 0 && _locationService$conf9.error) {
          controller.set('showActivateButton', false);
          controller.send('showErrorForOnboardingError');
        }
      }
    },
    actions: {
      willTransition: function willTransition() {
        if (this.locationInterval) {
          clearInterval(this.locationInterval);
        }

        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});