define("lh4/components/2fa/recovery-codes-display", ["exports", "file-saver"], function (_exports, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    copySuccess: false,
    codes: [],
    codesDisplay: Ember.computed('codes.[]', function getter() {
      return this.codes && this.codes.join(', ');
    }),
    actions: {
      copyCodesSuccess: function copyCodesSuccess() {
        var _this = this;

        this.set('copySuccess', true);
        Ember.run.later(this, function () {
          _this.set('copySuccess', false);
        }, 2000);
      },
      downloadCodes: function downloadCodes() {
        var _this2 = this;

        try {
          var blob = new Blob(["".concat(this.codesDisplay, "\n")], {
            type: 'text/plain;charset=utf-8'
          });

          _fileSaver.default.saveAs(blob, 'codes.txt');
        } catch (err) {
          // Some browsers might not support this
          this.set('downloadFailure', true);
          Ember.run.later(this, function () {
            _this2.set('downloadFailure', false);
          }, 2000);
        }
      }
    }
  });

  _exports.default = _default;
});