define("lh4/mirage/routes/finaro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageFinaroServer;

  function setMirageFinaroServer(server) {
    server.get('/finaro/app/sso/:locationId', function () {
      return {
        url: 'www.finaro-test.com'
      };
    });
  }
});