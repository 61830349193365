define("lh4/models/verify-owner/question", ["exports", "ember-data", "ember-data/attr", "ember-cp-validations"], function (_exports, _emberData, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    answer: {
      description: 'Answer',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    changeTracker: {
      auto: false
    },
    guid: (0, _attr.default)('string'),
    answer: (0, _attr.default)('string'),
    answers: (0, _attr.default)('raw'),
    prompt: (0, _attr.default)('string')
  });

  _exports.default = _default;
});