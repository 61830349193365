define("lh4/templates/components/radio-color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e1pdplU9",
    "block": "{\"symbols\":[\"color\"],\"statements\":[[4,\"each\",[[24,[\"colors\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"radio-color-option\",null,[[\"useColorClass\",\"color\",\"selected\",\"onClick\",\"useUnsetOption\"],[[24,[\"useColorClass\"]],[23,1,[]],[28,\"eq\",[[23,1,[]],[24,[\"selected\"]]],null],[28,\"action\",[[23,0,[]],\"onClick\",[23,1,[]]],null],[24,[\"useUnsetOption\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/radio-color-picker.hbs"
    }
  });

  _exports.default = _default;
});