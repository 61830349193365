define("lh4/routes/authenticated/locations/location/hospitality/choices-and-choice-sets/set/edit-set/choice/edit-choice", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(param) {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        choiceSets: this.store.query('hospitality/choice-set', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('hospitality/choice-set');
        }),
        editChoices: this.store.findRecord('hospitality/choice', param.choiceId)
      });
    },
    afterModel: function afterModel(model) {
      this.controllerFor('authenticated.locations.location.hospitality.choices-and-choice-sets.set.edit-set.edit').set('selectedChoiceGuid', model.editChoices.get('rowguid'));
    }
  });

  _exports.default = _default;
});