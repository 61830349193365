define("lh4/components/dashboard/dashboard-chart", ["exports", "d3-scale", "d3-axis", "d3-selection", "d3-array", "d3-format"], function (_exports, _d3Scale, _d3Axis, _d3Selection, _d3Array, _d3Format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dashboard-chart'],
    init: function init() {
      this._super.apply(this, arguments);

      this.margin = {
        top: 40,
        right: 10,
        bottom: 30,
        left: 60
      };
      this.width = 875 - this.margin.left - this.margin.right;
      this.height = 400 - this.margin.top - this.margin.bottom;
      this.x = (0, _d3Scale.scaleBand)().range([0, this.width]).padding(0.45);
      this.y = (0, _d3Scale.scaleLinear)().range([this.height, 0]);
      this.updateAxes();
    },
    updateAxes: function updateAxes() {
      var makeMoney = (0, _d3Format.format)('$');
      this.xAxis = (0, _d3Axis.axisBottom)(this.x);
      this.yAxis = (0, _d3Axis.axisLeft)(this.y).ticks(4).tickFormat(makeMoney);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var chartId = "#".concat(this.get('chartId'));
      var svg = (0, _d3Selection.select)(chartId).attr('width', '100%').attr('height', '100%').attr('viewBox', '0 0 875 400').attr('preserveAspectRatio', 'xMinYMin').append('g').attr('transform', "translate(".concat(this.margin.left, ", ").concat(this.margin.top, ")"));
      svg.append('g').attr('class', 'x axis').attr('transform', "translate(0, ".concat(this.height, ")")).style('font-size', '18px').call(this.xAxis);
      svg.append('g').attr('class', 'y axis').style('font-size', '15px').call(this.yAxis);
      this.updateChart();
    },
    rerenderOnDataChange: Ember.observer('data.[]', function callback() {
      this.updateChart();
    }),
    updateChart: function updateChart() {
      var _this = this;

      var data = this.get('data').toArray();
      this.x.domain(data.map(function (d) {
        return d.get('location.name');
      }));
      this.y.domain([0, (0, _d3Array.max)(data, function (d) {
        return d.get('total');
      })]);
      this.updateAxes();
      var chartId = "#".concat(this.get('chartId'));
      var svg = (0, _d3Selection.select)(chartId).select('g');
      svg.select('.x.axis').call(this.xAxis);
      svg.select('.y.axis').call(this.yAxis);
      var bar = svg.selectAll('.bar').data(data, function (d) {
        return d.get('location.name');
      });
      bar.exit().remove(); // exit
      // only create a bar if the total is greater than 0

      bar.enter().filter(function (d) {
        return d.get('total') !== 0;
      }).append('rect') // enter
      .attr('class', 'bar').attr('x', function (d) {
        return _this.x(d.get('location.name'));
      }).attr('width', this.x.bandwidth()).attr('y', function (d) {
        return _this.y(d.get('total'));
      }).attr('height', function (d) {
        return _this.height - _this.y(d.get('total'));
      }).merge(bar) // update
      .attr('class', 'bar').attr('fill', this.get('barChartFill')).style('stroke-width', '1px').style('stroke', '#006EA6');
      bar.enter().append('text').attr('class', 'text').attr('x', function (d) {
        return _this.x(d.get('location.name')) + _this.x.bandwidth() / 2;
      }).attr('y', function (d) {
        return _this.y(d.get('total')) - 10;
      }).attr('fill', '#000000').style('text-anchor', 'middle').style('font-size', '20px').text(function (d) {
        return "$ ".concat((0, _d3Format.format)('.2f')(d.get('total')));
      });
    }
  });

  _exports.default = _default;
});