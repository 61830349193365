define("lh4/routes/authenticated/locations/location/echo-pro/employee-schedule/shift/create", ["exports", "lh4/mixins/routes/locations/location/echo-pro/employee-schedule/shift/edit-route-mixin"], function (_exports, _editRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_editRouteMixin.default, {});

  _exports.default = _default;
});