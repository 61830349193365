define("lh4/helpers/em-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emDash = emDash;
  _exports.default = void 0;

  /**
   * Returns centered dash
   *
   * @returns {string} - html string for centered dash
   */
  function emDash() {
    var div = document.createElement('div');
    div.className = 'centered';
    div.innerHTML = '&mdash;';
    return div;
  }

  var _default = Ember.Helper.helper(emDash);

  _exports.default = _default;
});