define("lh4/components/reports/echo-pro/till-summary-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    tagName: '',
    dataLabels: Ember.computed('data.labels', 'show', 'showMore', function getter() {
      var labels = _toConsumableArray(this.data.labels);

      if (!this.showMore && labels.length >= this.show) {
        labels = labels.slice(0, this.show);
      }

      return labels;
    }),
    dataRows: Ember.computed('data.rows', 'show', 'showMore', function getter() {
      var rows = _toConsumableArray(this.data.rows);

      if (rows.length > 0) {
        // rotate the 'rows' array -> make the rows become columns and the columns become rows
        rows = rows[0].map(function (_, colIndex) {
          return rows.map(function (row) {
            return row[colIndex];
          });
        });
      }

      if (!this.showMore && rows.length >= this.show) {
        rows = rows.slice(0, this.show);
      }

      return rows;
    }),
    showMore: false,
    showButton: Ember.computed('data.labels', 'show', function getter() {
      return this.show < this.data.labels.length - 1;
    }),
    actions: {
      toggleShowMore: function toggleShowMore() {
        this.toggleProperty('showMore');
      }
    }
  });

  _exports.default = _default;
});