define("lh4/serializers/echo-pro/time-clock-shift-v2", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'echo-pro/time-clock-shift-v2';
    },
    attrs: {
      timeClockSegments: {
        embedded: 'always'
      },
      timeClockBreaks: {
        embedded: 'always'
      },
      employee: {
        embedded: 'always'
      },
      job: {
        embedded: 'always'
      },
      payPeriod: {
        embedded: 'always'
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.employee;
      delete json.job;
      delete json.payPeriod;
      return json;
    }
  });

  _exports.default = _default;
});