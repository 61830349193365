define("lh4/components/charts/pie-chart", ["exports", "d3-shape", "d3-selection", "d3-scale"], function (_exports, _d3Shape, _d3Selection, _d3Scale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['summary-pie-chart'],
    data: {
      headers: ['Something Else', 'Gross Sales', 'Net Income'],
      name: 'Sales by Revenue Class',
      results: [['Food', 1.30, 30], ['Drink', 2.99, 10], ['Zeroed', 0.00, 20], ['dirt', 50, 10], ['old', 40, 20], ['Total', 220, 220]]
    },
    chartId: Ember.computed('index', 'column', function getter() {
      return "chart-".concat(this.get('column'));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var data = this.get('data.results');
      var column = this.get('column');
      var chartId = this.get('chartId');
      var filteredData = data.filter(function (x) {
        if (parseInt(x[column], 10) === 0 || x[0] === 'Total') {
          return false;
        }

        return true;
      });
      var width = 400;
      var height = 400;
      var radius = Math.min(width, height) / 2;
      var color = (0, _d3Scale.scaleOrdinal)().range(['#00C0F4', '#00647F', '#00C9FF', '#0098E5', '#00B5E5', '#00C98D', '#00A9FF']);
      var svg = (0, _d3Selection.select)("#".concat(chartId)).attr('width', width).attr('height', height).attr('viewBox', "0 0 ".concat(height, " ").concat(width)).append('g').attr('class', 'path').attr('transform', "translate(".concat(width / 2, " , ").concat(height / 2, ")"));
      var pieArc = (0, _d3Shape.arc)().innerRadius(0).outerRadius(radius);
      var makePie = (0, _d3Shape.pie)().value(function (x) {
        return x[column];
      }).sort(null);
      svg.selectAll('path').data(makePie(filteredData)).enter().append('path').attr('d', pieArc).attr('fill', function (d) {
        return color(d.data[0]);
      });
      (0, _d3Selection.select)('g').append('g').attr('class', 'lines').append('text');
    }
  });

  _exports.default = _default;
});