define("lh4/templates/components/ui/table-wrapper/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g0OhbnAs",
    "block": "{\"symbols\":[\"record\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"collection\",\"length\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"collection\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[14,2,[[28,\"hash\",null,[[\"record\"],[[23,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\"],[7,\"td\",true],[10,\"colspan\",\"100\"],[10,\"class\",\"no-records\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"noRecordsMessage\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/table-wrapper/row.hbs"
    }
  });

  _exports.default = _default;
});