define("lh4/mirage/factories/loyalty-promotions", ["exports", "ember-cli-mirage", "lh4/models/loyalty/promotion"], function (_exports, _emberCliMirage, _promotion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _emberCliMirage.faker.random.uuid();
    },
    isActive: true,
    loyaltyProgramGuid: function loyaltyProgramGuid() {
      return _emberCliMirage.faker.random.uuid();
    },
    type: _promotion.PROMOTION_TYPES.SIGN_UP,
    name: function name() {
      return _emberCliMirage.faker.commerce.productName();
    },
    value: 10
  });

  _exports.default = _default;
});