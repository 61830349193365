define("lh4/components/reputation/account-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui', 'header', 'item', 'sm-header', 'left', 'aligned'],
    flashMessagesHelper: Ember.inject.service(),
    showWarning: true,
    isWarningTooltip: true,
    imgWidth: '50',
    imgHeight: '50',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('name', this.get('account.name'));
    }
  });

  _exports.default = _default;
});