define("lh4/serializers/echo-pro/schedule", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.scheduleTemplateGuid = json.scheduleTemplate;
      json.employeeGuid = json.employee;
      json.jobGuid = json.job;
      delete json.scheduleTemplate;
      delete json.employee;
      delete json.job;
      return json;
    },
    attrs: {
      scheduleTemplate: {
        embedded: 'always',
        serialize: 'id',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});