define("lh4/models/echo-pro/item-tax-map", ["exports", "ember-data", "ember-cp-validations", "ember-data/relationships"], function (_exports, _emberData, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    isPriceQualifying: {
      description: 'Price Qualifying',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    },
    isQuantityQualifying: {
      description: 'Quantity Qualifying',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    guid: _emberData.default.attr('string'),
    taxGuid: (0, _relationships.belongsTo)('echo-pro/tax'),
    itemGuid: _emberData.default.attr('string'),
    isPriceQualifying: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isQuantityQualifying: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});