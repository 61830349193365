define("lh4/components/sign-in/maintenance-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['maintenance-warning', 'row'],
    year: 2019,
    month: 'November',
    day: '27th',
    from: '6:00 am EST',
    to: '7:00 am EST'
  });

  _exports.default = _default;
});