define("lh4/templates/components/ui/buttons/step-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dFxSj8Lo",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[4,\"each\",[[24,[\"steps\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"a\",false],[12,\"class\",[29,[\"ui \",[28,\"if\",[[28,\"eq\",[[24,[\"current\"]],[23,1,[]]],null],\"blue\"],null],\" empty circular label\"]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectStep\",[23,1,[]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/buttons/step-picker.hbs"
    }
  });

  _exports.default = _default;
});