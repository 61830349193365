define("lh4/templates/authenticated/locations/location/hospitality/customize-item-groups/edit-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gtOnYIyF",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segment header\"],[8],[0,\"\\n\\tEdit \"],[1,[24,[\"model\",\"singleGroup\",\"description\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"flash-message\",null,[[\"flash\",\"class\"],[[23,1,[]],[23,1,[\"class\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\t\"],[7,\"form\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"fields\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide field\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"label\",true],[8],[0,\"Item Group Name\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"model\",\"singleGroup\",\"description\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide field\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"label\",true],[8],[0,\"Price of Items in this Group\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"model\",\"singleGroup\",\"dollarAmount\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\"],[1,[28,\"ui/buttons/form-confirmation-buttons\",null,[[\"setLoading\",\"discard\",\"save\"],[[24,[\"setLoading\"]],[28,\"action\",[[23,0,[]],\"clear\"],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/hospitality/customize-item-groups/edit-group.hbs"
    }
  });

  _exports.default = _default;
});