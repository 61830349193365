define("lh4/components/ui/dropdown-simple-checklist", ["exports", "lh4/utils/no-op"], function (_exports, _noOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown-simple-checklist', 'ui', 'input'],
    collection: [],
    placeholder: 'Please Select',
    selectionAllItemName: 'All',
    selectionAllItemId: 'all',
    displayPlaceholderOnAll: false,
    selectionAllItemIsChecked: Ember.computed('collection.@each.isChecked', function getter() {
      return this.collection.every(function (item) {
        return item.isChecked;
      });
    }),
    onChange: _noOp.default,
    counterItemId: 'counter',
    counterItemName: Ember.computed('collection.@each.isChecked', function getter() {
      if (!this.selectedItems.length) {
        return this.placeholder;
      }

      if (this.selectedItems.length === 1) {
        return this.selectedItems[0].name;
      }

      if (this.isAllSelected) {
        return this.displayPlaceholderOnAll ? this.placeholder : this.selectionAllItemName;
      }

      return "Selected: ".concat(this.selectedItems.length);
    }),
    selectedItems: Ember.computed('collection.@each.isChecked', function getter() {
      return this.collection.filter(function (val) {
        return val.isChecked;
      });
    }),
    isAllSelected: Ember.computed('collection.@each.isChecked', function getter() {
      return this.collection.every(function (val) {
        return val.isChecked;
      });
    }),
    actions: {
      selectRecord: function selectRecord(_, id) {
        if (id === this.selectionAllItemId) {
          var isAllChecked = this.selectionAllItemIsChecked;
          this.collection.forEach(function (item) {
            return Ember.set(item, 'isChecked', !isAllChecked);
          });
        } else {
          var changedRecord = this.collection.find(function (val) {
            return val.id.toString() === id;
          });
          Ember.set(changedRecord, 'isChecked', !changedRecord.isChecked);
        }

        this.onChange(this.selectedItems.map(function (val) {
          return val.id;
        }));
      }
    }
  });

  _exports.default = _default;
});