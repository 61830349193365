define("lh4/components/dashboard-widgets/skytab-links/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['skytab-links-data', 'table-widget'],
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    reportDates: {
      startsAt: null,
      startsAtTime: null,
      endsAt: null,
      endsAtTime: null
    },
    reportLocations: Ember.computed('location.id', function getter() {
      return [this.location.id];
    })
  });

  _exports.default = _default;
});