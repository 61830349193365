define("lh4/templates/components/internet-payments/tray/menu-sliding-tray/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ui4LQXwW",
    "block": "{\"symbols\":[\"&default\",\"@namedBlocksInfo\"],\"statements\":[[4,\"if\",[[28,\"-has-block\",[[23,2,[]],\"default\",[25,1]],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"menu-header\"],[8],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/tray/menu-sliding-tray/header.hbs"
    }
  });

  _exports.default = _default;
});