define("lh4/enums/ip-dispute-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DISPUTE_STATUS_DISPLAY = _exports.DISPUTE_STATUS = void 0;
  var DISPUTE_STATUS = Object.freeze({
    NEED_RESPONSE: 'NEED_RESPONSE',
    AWAITING_INFO: 'AWAITING_INFO',
    WON: 'WON',
    LOST: 'LOST'
  });
  _exports.DISPUTE_STATUS = DISPUTE_STATUS;
  var DISPUTE_STATUS_DISPLAY = Object.freeze({
    NEED_RESPONSE: 'Need Response',
    IN_REVIEW: 'In Review',
    LOST: 'Lost',
    WON: 'Won'
  });
  _exports.DISPUTE_STATUS_DISPLAY = DISPUTE_STATUS_DISPLAY;
});