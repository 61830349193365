define("lh4/templates/components/ui/tree/report-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S+e9/WVJ",
    "block": "{\"symbols\":[\"root\"],\"statements\":[[4,\"each\",[[24,[\"tree\",\"roots\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"report-node \",[23,1,[\"nodeType\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"location\",\"hasInternetPaymentProduct\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"table\",true],[11,\"class\",[29,[\"ui celled unstackable table sticky-height-\",[23,1,[\"rows\",\"length\"]]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"ui/tree/report-node\",null,[[\"node\",\"onToggleIsExpanded\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"toggleIsExpanded\",[24,[\"node\"]]],null]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"table\",true],[11,\"class\",[29,[\"ui celled unstackable table sticky-height-\",[23,1,[\"children\",\"0\",\"rows\",\"length\"]]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"ui/tree/report-node\",null,[[\"node\",\"onToggleIsExpanded\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"toggleIsExpanded\",[24,[\"node\"]]],null]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/tree/report-tree.hbs"
    }
  });

  _exports.default = _default;
});