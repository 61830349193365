define("lh4/abilities/transaction", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    session: Ember.inject.service(),
    location: Ember.inject.service('location'),
    canViewTransactions: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.hasLocationPermission(_permissions.default.TRANSACTIONS.VIEW_TRANSACTIONS) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.LBM_TRANSACTIONS_VISIBILITY];
    }),
    canCreateTransactions: (0, _abilityMixin.computedAbility)('session.isSSO', function getter() {
      return !this.session.isSSO && this.hasLocationPermission(_permissions.default.TRANSACTIONS.CREATE_TRANSACTIONS) && !this.location.isTemporarilyHiddenForCA;
    }),
    canCreateStPosTransactions: (0, _abilityMixin.computedAbility)('session.isSSO', function getter() {
      return !this.session.isSSO && this.hasLocationPermission(_permissions.default.TRANSACTIONS.CREATE_TRANSACTIONS) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.LBM_TRANSACTIONS_VISIBILITY];
    }),
    canRefundTransactions: (0, _abilityMixin.computedAbility)('session.isSSO', function getter() {
      return !this.session.isSSO && this.hasPermission(_permissions.default.TRANSACTIONS.REFUND_TRANSACTIONS, this.locationId);
    })
  });

  _exports.default = _default;
});