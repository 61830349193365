define("lh4/templates/authenticated/virtual-gift-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "//JVPwYi",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"virtual-gift-cards\",null,[[\"giftCardIframeError\",\"giftCardIframeData\"],[[24,[\"giftCardIframeError\"]],[24,[\"giftCardIframeData\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/virtual-gift-cards.hbs"
    }
  });

  _exports.default = _default;
});