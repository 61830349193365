define("lh4/components/hospitality/dynamic-multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    property: 'name',
    relation: null,
    classNames: ['dynamic-multi-select'],
    collection: [],
    selected: [],
    item: null,
    collectionUnselected: Ember.computed('newOptionsObjects.[]', 'selected.[]', function getter() {
      if (!this.newOptionsObjects.length) {
        return [];
      }

      var _this$selected = this.selected,
          selected = _this$selected === void 0 ? [] : _this$selected,
          relation = this.relation;
      return this.newOptionsObjects.filter(function (record) {
        return !selected.find(function (selectedRecord) {
          return record[relation] === selectedRecord[relation];
        });
      });
    }),
    collectionSelected: Ember.computed('newOptionsObjects.[]', 'selected.[]', function getter() {
      if (!this.newOptionsObjects.length) {
        return [];
      }

      var _this$selected2 = this.selected,
          selected = _this$selected2 === void 0 ? [] : _this$selected2,
          relation = this.relation;
      return this.newOptionsObjects.filter(function (record) {
        return selected.find(function (selectedRecord) {
          return record[relation] === selectedRecord[relation];
        });
      });
    }),
    newOptionsObjects: Ember.computed('collection', function getter() {
      var collection = this.collection,
          relation = this.relation,
          item = this.item;
      return collection.map(function (x) {
        switch (relation) {
          case 'ccatId':
            // Choice Set
            return {
              ccatId: x.choiceCatId,
              name: x.choiceCatName,
              rowguid: x.rowguid
            };

          case 'choiceCategoryId':
            // Choice Set
            return {
              choiceCategoryId: x.choiceCatId,
              name: x.choiceCatName,
              menuItemId: item.menuItemId
            };

          case 'screenCategoryId':
            {
              // Menu Categories
              // Make sure that content source never returns nulls, or undefined
              if (!['predefined', 'Predefined', 'runtimemap', null].includes(x.contentSource)) {
                x.set('contentSource', null);
              }

              return {
                screenCategoryId: x.menuCatId,
                name: x.buttonName,
                contentSource: x.contentSource,
                menuItemId: item.menuItemId
              };
            }

          case 'menuItemId':
            // Menu Items
            return {
              menuItemId: x.menuItemId,
              name: x.longName,
              rowguid: x.rowguid
            };

          case 'securityId':
            // Permissions
            return {
              isAllowed: true,
              securityId: x.securityId,
              name: x.securityName,
              rowguid: x.rowguid
            };

          case 'jobId':
            // Jobs
            return {
              isAllowed: true,
              jobId: x.jobId,
              name: x.name,
              rowguid: x.rowguid
            };

          case 'choiceId':
            // Items
            return {
              choiceId: x.choiceId,
              name: x.name,
              rowguid: x.rowguid
            };

          case 'printerId':
            // Printers
            return {
              isActive: true,
              printerId: x.printerId,
              name: x.name,
              menuItemId: item.menuItemId
            };

          default:
            return false;
        }
      });
    }),
    actions: {
      selectAll: function selectAll() {
        this.set('selected', this.newOptionsObjects);
      },
      selectNone: function selectNone() {
        this.set('selected', []);
      },
      selectItem: function selectItem(item) {
        if (!this.selected) {
          this.set('selected', []);
        }

        this.selected.pushObject(item);
      },
      unselectItem: function unselectItem(item) {
        var selected = this.selected,
            relation = this.relation;
        var items = selected.filter(function (i) {
          return i[relation] === item[relation];
        });
        selected.removeObjects(items);
      }
    }
  });

  _exports.default = _default;
});