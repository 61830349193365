define("lh4/serializers/echo-pro/ingredient-item-map", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    normalize: function normalize(modelClass, resourceHashToNormalize) {
      var resourceHash = resourceHashToNormalize;
      resourceHash.guid = resourceHash.itemGuid;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, resourceHash].concat(args));
    }
  });

  _exports.default = _default;
});