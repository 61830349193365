define("lh4/components/settings-location-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['settings-location-table'],
    selectedMerchantId: null,
    selectedMerchantName: null,
    merchantIdConfirm: null,
    ssnLastFour: null,
    ajax: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    permissions: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    metrics: Ember.inject.service(),
    isAddLocationOpen: false,
    didInsertElement: function didInsertElement() {
      if (this.get('mid')) {
        this.set('isAddLocationOpen', true);
      }
    },
    actions: {
      toggleAddLocation: function toggleAddLocation() {
        this.toggleProperty('isAddLocationOpen');
      },
      removeLocation: function removeLocation(id, name) {
        this.set('selectedMerchantId', id);
        this.set('selectedMerchantName', name);
        this.$('.ui.modal').modal('show');
      },
      addLocation: function addLocation() {
        var _this = this;

        var ssnLastFour = this.get('ssnLastFour');
        var newMerchantId = this.get('mid');
        var merchReg = /\d{10}/;
        var ssnReg = /\d{4}/;

        if (merchReg.test(newMerchantId) && ssnReg.test(ssnLastFour)) {
          this.ajax.request("/api/v1/accounts/".concat(this.get('tokenData.data.sub'), "/link/").concat(newMerchantId), {
            method: 'POST',
            data: JSON.stringify({
              identifier: ssnLastFour
            })
          }).then(function () {
            _this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
              eventCategory: 'Settings',
              eventAction: 'New location added'
            });

            var message = 'The location has been added to your account.' + ' This change will take effect the next time you log in.';

            _this.flashMessages.success(message, {
              class: 'ui success message'
            });

            Ember.run.later(function () {
              _this.set('isAddLocationOpen', false);
            }, 5000);
          }).catch(function () {
            _this.flashMessages.danger('An error occurred when trying to save this location. Try again later.', {
              class: 'ui error message'
            });
          });
        } else {
          this.flashMessages.danger('Please make sure you are entering the correct Merchant ID and SSN.', {
            class: 'ui error message'
          });
        }
      },
      unlinkMerchant: function unlinkMerchant() {
        var _this2 = this;

        var merchantId = this.get('selectedMerchantId');
        var merchantIdConfirm = this.get('merchantIdConfirm');

        if (merchantId === merchantIdConfirm) {
          this.ajax.request("/api/v1/accounts/".concat(this.get('tokenData.data.sub'), "/unlink/").concat(merchantId), {
            method: 'POST',
            data: JSON.stringify({
              merchantId: merchantId
            })
          }).then(function () {
            var message = 'The location has been removed from you account.' + ' This change will take effect the next time you log in.';

            _this2.flashMessages.success(message, {
              class: 'ui success message'
            });

            setTimeout(function () {
              _this2.$('.ui.modal').modal('hide');
            }, 1500);
          }).catch(function () {
            _this2.flashMessages.danger('An error occurred when trying to unlink this location. Try again later.', {
              class: 'ui error message'
            });
          });
        } else {
          this.flashMessages.danger('Please make sure you are entering the correct merchant ID.', {
            class: 'ui error message'
          });
        }
      }
    }
  });

  _exports.default = _default;
});