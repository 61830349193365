define("lh4/controllers/authenticated/social-media/post-scheduler/account/schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    accountApi: Ember.inject.service('social-media/account-api'),
    facebook: Ember.inject.service('social-media/facebook'),
    twitter: Ember.inject.service('social-media/twitter'),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    ajax: Ember.inject.service(),
    metrics: Ember.inject.service(),
    isLoading: false,
    actions: {
      schedulePost: function schedulePost(post) {
        var _this = this;

        var scheduleOnce = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

        if (this.isLoading) {
          return;
        }

        this.set('isLoading', true);
        this.flashMessagesHelper.clearMessages();
        this.accountApi.schedulePost(this.get('model.account.id'), post, scheduleOnce).then(function () {
          _this.flashMessagesHelper.pushMessage('Successfully scheduled post', 'success');

          if (post.scheduleTime) {
            _this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
              eventCategory: 'Social Media',
              eventAction: 'Scheduled post created'
            });
          } else {
            _this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
              eventCategory: 'Social Media',
              eventAction: 'Instant post created'
            });
          }

          Ember.run.later(_this, function () {
            _this.setProperties({
              isLoading: false,
              isSubmitted: true
            });

            _this.transitionToRoute('authenticated.social-media.post-scheduler.account', _this.get('model.account.id'));
          }, 1000);
        }).catch(function (err) {
          _this.set('isLoading', false);

          _this.errorHandler.handleResponseError(err);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('authenticated.social-media.post-scheduler.account', this.get('model.account.id'));
      }
    }
  });

  _exports.default = _default;
});