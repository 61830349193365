define("lh4/templates/components/input-merchant-ids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WADwmTM/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[8],[0,\"Create New Merchant ID\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"three fields user-merchant-id-input\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"New Merchant ID\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"value\"],[[24,[\"newMerchId\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Last Four of SSN\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"type\",\"maxlength\",\"value\",\"autocomplete\"],[\"password\",\"4\",[24,[\"ssnLastFour\"]],\"new-password\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\" \"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui green button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"addMerchant\"]],[8],[0,\"Add Merchant ID\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/input-merchant-ids.hbs"
    }
  });

  _exports.default = _default;
});