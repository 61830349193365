define("lh4/templates/authenticated/support/contact/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ExVSe2VP",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"ui/modal/fullscreen-modal\",null,[[\"title\",\"errorMessage\",\"onClose\"],[\"Create Support Ticket\",[28,\"if\",[[24,[\"showErrors\"]],[28,\"if\",[[24,[\"changeset\",\"isInvalid\"]],\"Your form has errors and cannot be submitted\"],null]],null],[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `modal.content` to be a contextual component but found a string. Did you mean `(component modal.content)`? ('lh4/templates/authenticated/support/contact/create.hbs' @ L7:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"support/contact/support-ticket-form\",null,[[\"changeset\",\"locations\",\"groups\",\"showErrors\"],[[24,[\"changeset\"]],[24,[\"model\",\"locations\"]],[24,[\"model\",\"groups\"]],[24,[\"showErrors\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"actions\"]],\"expected `modal.actions` to be a contextual component but found a string. Did you mean `(component modal.actions)`? ('lh4/templates/authenticated/support/contact/create.hbs' @ L15:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"createTicket\"]],[8],[0,\"\\n\\t\\t\\tCreate Ticket\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary basic button left-action\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n\\t\\t\\tDiscard\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/support/contact/create.hbs"
    }
  });

  _exports.default = _default;
});