define("lh4/controllers/authenticated/locations/location/echo-pro/employee-schedule/template/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    columns: ['Name of template', 'Date'],
    reload: function reload() {
      var _this = this;

      this.store.query('echo-pro/schedule-template', {
        location: this.get('model.location.id'),
        order: 'name',
        sort: 'asc'
      }).then(function (templates) {
        _this.set('selected', []);

        _this.set('model.templates', templates);
      });
    },
    actions: {
      deleteSelected: function deleteSelected() {
        var _this2 = this;

        var selected = this.get('selected');

        if (Ember.isEmpty(selected)) {
          return;
        }

        this.set('isLoading', true);
        Ember.RSVP.Promise.all(selected.map(function (template) {
          return template.destroyRecord();
        })).then(function () {
          return _this2.reload();
        }).finally(function () {
          return _this2.set('isLoading', false);
        });
      },
      clear: function clear() {
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
      }
    }
  });

  _exports.default = _default;
});