define("lh4/helpers/usd-number-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.usdNumberFormat = usdNumberFormat;
  _exports.default = void 0;
  var DIVISION_RANGES = [{
    divider: 1e18,
    suffix: 'Qi'
  }, {
    divider: 1e15,
    suffix: 'Qa'
  }];
  /**
   * Returns an US currency representation of a number
   * @param {Number} params - [0] - number to be converted
   * @returns {String} - string with US currency representation of the given number
   */

  function usdNumberFormat(params) {
    var numberValue = Number(params[0]); // This flag is introduced due to Safari having weird
    // behavior with Intl.NumberFormat and compact notation.
    // For some reason it converts 29.99 to 30 if compact notation is used.

    var useStandardFormat = Boolean(params[1]);
    var isNegative = numberValue < 0;
    var absoluteValue = Math.abs(numberValue);
    var formattingOptions = params[2];

    for (var i = 0; i < DIVISION_RANGES.length; i += 1) {
      if (absoluteValue >= DIVISION_RANGES[i].divider) {
        return "".concat(isNegative ? '-' : '', "$").concat((absoluteValue / DIVISION_RANGES[i].divider).toFixed(2) + DIVISION_RANGES[i].suffix);
      }
    }

    var options = formattingOptions || {
      style: 'currency',
      currency: 'USD',
      notation: useStandardFormat ? 'standard' : 'compact',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    };
    return new Intl.NumberFormat('en-US', options).format(params[0]);
  }

  var _default = Ember.Helper.helper(usdNumberFormat);

  _exports.default = _default;
});