define("lh4/components/social-media/account-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var getTypeIcon = function getTypeIcon(type) {
    var typeIcon = 'facebook f';
    var typeBackground = 'facebook-background';

    switch (type) {
      case 'twitter':
        {
          typeIcon = 'twitter inverted';
          typeBackground = 'twitter-background';
          break;
        }

      case 'yelp':
        {
          typeIcon = 'yelp inverted';
          typeBackground = 'yelp-background';
          break;
        }

      case 'google-my-business':
        {
          typeIcon = 'google inverted';
          typeBackground = 'google-background';
          break;
        }

      default:
        {
          typeIcon = 'facebook f';
          typeBackground = 'facebook-background';
          break;
        }
    }

    return {
      typeIcon: typeIcon,
      typeBackground: typeBackground
    };
  };

  var _default = Ember.Component.extend({
    tagName: 'i',
    classNames: ['huge', 'icons', 'profile-picture'],
    typeBackground: null,
    typeIcon: null,
    width: '50',
    height: '50',
    src: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var iconTypeObject = getTypeIcon(this.get('account.type'));
      this.set('typeIcon', iconTypeObject.typeIcon);
      this.set('typeBackground', iconTypeObject.typeBackground);
    }
  });

  _exports.default = _default;
});