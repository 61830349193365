define("lh4/controllers/sign-in", ["exports", "jquery", "lh4/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    zendesk: Ember.inject.service(),
    ENV: _environment.default,
    // Store Credentials
    email: '',
    password: '',
    currentEmail: null,
    // Error Messages
    errMessage: null,
    successMessage: null,
    branding: _environment.default.branding,
    shouldDisplayMaintenanceWarning: _environment.default.displayMaintenanceWarning,
    requiresAdditionalInput: false,
    isLoading: false,
    queryParams: 'showRecoverySuccess',
    showRecoverySuccess: false,
    recoverySuccess: false,
    setTwoFactorState: function setTwoFactorState(_ref) {
      var requiresCode = _ref.requiresCode,
          requiresRecoveryCode = _ref.requiresRecoveryCode;
      this.setProperties({
        requiresCode: !!requiresCode,
        requiresRecoveryCode: !!requiresRecoveryCode
      });
    },
    actions: {
      updateEmail: function updateEmail(email) {
        this.set('email', email);
      },
      updatePassword: function updatePassword(password) {
        this.set('password', password);
      },
      updateCurrentEmail: function updateCurrentEmail(currentEmail) {
        this.set('currentEmail', currentEmail);
      },
      authenticate: function authenticate() {
        var _this = this;

        (0, _jquery.default)('.sign-in-form, .email-input-container, .password-input-container').removeClass('error');
        var email = this.email,
            password = this.password,
            currentEmail = this.currentEmail;

        if (!email || !password) {
          this.set('isLoading', false);

          if (!email && !password) {
            (0, _jquery.default)('.email-input-container, .password-input-container, .sign-in-form').addClass('error');
            this.set('errMessage', 'Please submit an email and password');
          } else if (!email) {
            (0, _jquery.default)('.email-input-container,.sign-in-form').addClass('error');
            this.set('errMessage', 'Please submit an email');
          } else if (!password) {
            (0, _jquery.default)('.password-input-container, .sign-in-form').addClass('error');
            this.set('errMessage', 'Please submit a password');
          }

          return;
        }

        this.set('isLoading', true);
        this.session.authenticate('authenticator:jwt-custom', {
          identification: email.trim(),
          password: password.trim(),
          currentEmail: currentEmail !== null ? currentEmail.trim() : null
        }).then(function () {
          return _this.zendesk.identifyUser();
        }).catch(function (e) {
          _this.set('isLoading', false);

          if (e.error && e.error.code === 449) {
            return _this.setTwoFactorState({
              requiresCode: true
            });
          }

          return _this.handleGenericAuthErrors(e);
        });
      },
      backToSignIn: function backToSignIn() {
        this.setTwoFactorState({});
        this.resetFailed2FAMessage();
      },
      toCodeRecovery: function toCodeRecovery() {
        this.setTwoFactorState({
          requiresRecoveryCode: true
        });
        this.resetFailed2FAMessage();
      },
      backToCode: function backToCode() {
        this.setTwoFactorState({
          requiresCode: true
        });
        this.resetFailed2FAMessage();
      },
      submitCode: function submitCode() {
        var _this2 = this;

        if (this.isLoading) {
          return;
        }

        var email = this.email,
            password = this.password,
            currentEmail = this.currentEmail,
            otpCode = this.code;
        this.set('isLoading', true);
        this.session.authenticate('authenticator:jwt-custom', {
          identification: email.trim(),
          password: password.trim(),
          currentEmail: currentEmail !== null ? currentEmail.trim() : null,
          otpCode: otpCode
        }).then(function () {
          return _this2.zendesk.identifyUser();
        }).catch(function (e) {
          _this2.handleGenericAuthErrors(e);

          _this2.set('isLoading', false);
        });
      },
      submitRecoveryCode: function submitRecoveryCode() {
        var _this3 = this;

        if (this.isLoading) {
          return;
        }

        var email = this.email,
            password = this.password,
            currentEmail = this.currentEmail,
            recoveryCode = this.recoveryCode;
        this.set('isLoading', true);
        this.session.authenticate('authenticator:jwt-custom', {
          identification: email.trim(),
          password: password.trim(),
          currentEmail: currentEmail !== null ? currentEmail.trim() : null,
          recoveryCode: recoveryCode
        }).then(function () {
          return _this3.zendesk.identifyUser();
        }).catch(function (e) {
          _this3.handleGenericAuthErrors(e);

          _this3.set('isLoading', false);
        });
      }
    },

    /**
     * Handle generic auth error responses
     *
     * @param {object} e - error
     */
    handleGenericAuthErrors: function handleGenericAuthErrors(e) {
      if (!e.error) {
        (0, _jquery.default)('.sign-in-form').addClass('error');
        return this.set('errMessage', 'Something went wrong');
      }

      if (e.error.code === 406) {
        return this.set('requiresAdditionalInput', true);
      }

      if (e.error.code === 201) {
        return this.set('successMessage', e.error.message.trim());
      }

      var errorString = e.error.code === 400 && e.error.message === 'requested code and secret do not match' ? 'You\'ve entered the wrong verification code, it has expired, or it has already been used.' : e.error.message;
      (0, _jquery.default)('.sign-in-form').addClass('error');
      return this.set('errMessage', errorString);
    },
    resetRecoverySuccess: function resetRecoverySuccess() {
      this.setProperties({
        showRecoverySuccess: false,
        successMessage: null
      });
    },
    resetFailed2FAMessage: function resetFailed2FAMessage() {
      this.set('errMessage', null);
    }
  });

  _exports.default = _default;
});