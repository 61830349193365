define("lh4/mirage/routes/echo-pro/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageEchoProCustomersServer;

  function setMirageEchoProCustomersServer(server) {
    server.get('/echo-pro/customers', function () {
      return {
        customers: [{
          createdAt: '2022-11-02T02:40:44.360Z',
          updatedAt: '2022-11-02T02:40:44.360Z',
          serverCreatedAt: '2022-11-02T02:40:44.360Z',
          serverUpdatedAt: '2022-11-02T02:40:44.360Z',
          guid: '5f6e2bcb-8963-4856-8cbc-25d03d4f4ac5',
          locationId: 1,
          firstName: 'Aaron',
          middleInitial: null,
          lastName: 'Killinger',
          notes: null,
          lookup: null,
          taxExempt: false,
          active: true,
          loyaltyMemberGuid: null,
          customerEmails: [{
            createdAt: '2022-11-02T02:32:58.012Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: 'd854e883-ed35-4c45-8735-1d74744006a2',
            locationId: 1,
            customerGuid: '5f6e2bcb-8963-4856-8cbc-25d03d4f4ac5',
            type: 0,
            email: 'AaronMKillinger@rhyta.com',
            isActive: true
          }],
          customerPhones: [{
            createdAt: '2022-11-02T02:32:58.117Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: 'd169d271-97ed-43c9-990f-c559f4873f69',
            customerGuid: '5f6e2bcb-8963-4856-8cbc-25d03d4f4ac5',
            locationId: 1,
            type: 1,
            number: '+16153840300',
            isActive: true
          }],
          customerAddresses: [{
            createdAt: '2022-11-02T02:32:58.216Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: '5019d759-0970-479a-9c45-18aa7e249703',
            locationId: 1,
            customerGuid: '5f6e2bcb-8963-4856-8cbc-25d03d4f4ac5',
            addressType: 0,
            streetAddress1: '1885 Wilkinson Street',
            streetAddress2: null,
            city: 'Springfield',
            state: 'TN',
            country: null,
            postalCode: '37172',
            isActive: true
          }],
          customerStatistic: null
        }, {
          createdAt: '2022-11-02T02:40:44.360Z',
          updatedAt: '2022-11-02T02:40:44.360Z',
          serverCreatedAt: '2022-11-02T02:40:44.360Z',
          serverUpdatedAt: '2022-11-02T02:40:44.360Z',
          guid: 'f984d5bd-7855-4610-a6af-11d41df8a6a6',
          locationId: 1,
          firstName: 'Alice',
          middleInitial: null,
          lastName: 'Sexton',
          notes: null,
          lookup: null,
          taxExempt: false,
          active: true,
          loyaltyMemberGuid: null,
          customerEmails: [{
            createdAt: '2022-11-02T02:32:58.064Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: 'a2524452-a8d0-464f-a552-650fa0a09c0b',
            locationId: 1,
            customerGuid: 'f984d5bd-7855-4610-a6af-11d41df8a6a6',
            type: 0,
            email: 'AliceKSexton@gustr.com',
            isActive: true
          }],
          customerPhones: [{
            createdAt: '2022-11-02T02:32:58.174Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: '0c99247f-754f-4e8d-a07a-29fc93df1042',
            customerGuid: 'f984d5bd-7855-4610-a6af-11d41df8a6a6',
            locationId: 1,
            type: 1,
            number: '646-708-7366',
            isActive: true
          }],
          customerAddresses: [{
            createdAt: '2022-11-02T02:32:58.257Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: '5c3d2c5b-5d01-457d-9261-e374e9d48264',
            locationId: 1,
            customerGuid: 'f984d5bd-7855-4610-a6af-11d41df8a6a6',
            addressType: 0,
            streetAddress1: '454 Cantebury Drive',
            streetAddress2: null,
            city: 'Garden City',
            state: 'NY',
            country: null,
            postalCode: '11530',
            isActive: true
          }],
          customerStatistic: null
        }, {
          createdAt: '2022-11-02T02:40:44.360Z',
          updatedAt: '2022-11-02T02:40:44.360Z',
          serverCreatedAt: '2022-11-02T02:40:44.360Z',
          serverUpdatedAt: '2022-11-02T02:40:44.360Z',
          guid: '827248c5-4f38-4d3f-8dd3-cb5c20ace8aa',
          locationId: 1,
          firstName: 'Andres',
          middleInitial: null,
          lastName: 'Ritchie',
          notes: null,
          lookup: null,
          taxExempt: false,
          active: true,
          loyaltyMemberGuid: null,
          customerEmails: [{
            createdAt: '2022-11-02T02:32:58.009Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: 'cf2825af-e7f3-4062-a321-75af5dd5f4cd',
            locationId: 1,
            customerGuid: '827248c5-4f38-4d3f-8dd3-cb5c20ace8aa',
            type: 0,
            email: 'AndresBRitchie@gustr.com',
            isActive: true
          }],
          customerPhones: [{
            createdAt: '2022-11-02T02:32:58.114Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: '04215a16-5ead-4efb-92d3-17686cd31a1f',
            customerGuid: '827248c5-4f38-4d3f-8dd3-cb5c20ace8aa',
            locationId: 1,
            type: 1,
            number: '123456',
            isActive: true
          }],
          customerAddresses: [{
            createdAt: '2022-11-02T02:32:58.214Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: '2b3f564a-46c9-4c42-ac95-9a30c0c6da97',
            locationId: 1,
            customerGuid: '827248c5-4f38-4d3f-8dd3-cb5c20ace8aa',
            addressType: 0,
            streetAddress1: '3403 Rebecca Street',
            streetAddress2: null,
            city: 'Elgin',
            state: 'IL',
            country: null,
            postalCode: '60120',
            isActive: true
          }],
          customerStatistic: null
        }, {
          createdAt: '2022-11-02T02:40:44.360Z',
          updatedAt: '2022-11-02T02:40:44.360Z',
          serverCreatedAt: '2022-11-02T02:40:44.360Z',
          serverUpdatedAt: '2022-11-02T02:40:44.360Z',
          guid: '09f41cb8-f9d0-4a00-aad5-7154aecb5323',
          locationId: 1,
          firstName: 'Angel',
          middleInitial: null,
          lastName: 'Gonzales',
          notes: null,
          lookup: null,
          taxExempt: false,
          active: true,
          loyaltyMemberGuid: null,
          customerEmails: [{
            createdAt: '2022-11-02T02:32:58.042Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: '706b876a-5f00-4753-8cb1-49784172ad44',
            locationId: 1,
            customerGuid: '09f41cb8-f9d0-4a00-aad5-7154aecb5323',
            type: 0,
            email: 'AngelSGonzales@dayrep.com',
            isActive: true
          }],
          customerPhones: [{
            createdAt: '2022-11-02T02:32:58.155Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: '8fd350de-f942-49fa-b17d-40cc075ad123',
            customerGuid: '09f41cb8-f9d0-4a00-aad5-7154aecb5323',
            locationId: 1,
            type: 1,
            number: '+37064499876',
            isActive: true
          }],
          customerAddresses: [{
            createdAt: '2022-11-02T02:32:58.237Z',
            updatedAt: '2022-11-02T02:40:44.360Z',
            serverCreatedAt: '2022-11-02T02:40:44.360Z',
            serverUpdatedAt: '2022-11-02T02:40:44.360Z',
            guid: '35f6b960-66e2-4212-b62e-1c0956b8f4b9',
            locationId: 1,
            customerGuid: '09f41cb8-f9d0-4a00-aad5-7154aecb5323',
            addressType: 0,
            streetAddress1: '3504 Del Dew Drive',
            streetAddress2: null,
            city: 'Washington',
            state: 'MD',
            country: null,
            postalCode: '20024',
            isActive: true
          }],
          customerStatistic: null
        }],
        meta: {
          count: 4
        }
      };
    });
  }
});