define("lh4/templates/components/internet-payments/ui/fields/password-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+P+ufs24",
    "block": "{\"symbols\":[],\"statements\":[[5,\"internet-payments/ui/fields/input\",[],[[\"@containerClass\",\"@inputClass\",\"@labelClass\",\"@label\",\"@type\",\"@placeholder\",\"@hasError\",\"@disabled\",\"@onInput\",\"@value\",\"@autocomplete\",\"@size\",\"@minlength\",\"@maxlength\",\"@pattern\",\"@readonly\",\"@required\",\"@subText\"],[[23,0,[\"args\",\"containerClass\"]],[23,0,[\"args\",\"inputClass\"]],[23,0,[\"args\",\"labelClass\"]],[23,0,[\"args\",\"label\"]],[23,0,[\"type\"]],[23,0,[\"args\",\"placeholder\"]],[23,0,[\"args\",\"error\"]],[23,0,[\"args\",\"disabled\"]],[23,0,[\"args\",\"onInput\"]],[23,0,[\"args\",\"value\"]],[23,0,[\"args\",\"autocomplete\"]],[23,0,[\"args\",\"size\"]],[23,0,[\"args\",\"minlength\"]],[23,0,[\"args\",\"maxlength\"]],[23,0,[\"args\",\"pattern\"]],[23,0,[\"args\",\"readonly\"]],[23,0,[\"args\",\"required\"]],[23,0,[\"args\",\"subText\"]]]],{\"statements\":[[0,\"\\n\\t\"],[7,\"span\",false],[12,\"class\",\"show-password\"],[12,\"data-test-fields\",\"password-input\"],[3,\"on\",[\"click\",[23,0,[\"togglePasswordFieldType\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,0,[\"type\"]],\"password\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"ui/icons/internet-payments/visibility\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"ui/icons/internet-payments/visibility-off\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/fields/password-input.hbs"
    }
  });

  _exports.default = _default;
});