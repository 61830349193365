define("lh4/services/walkthrough", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    data: null,
    // this function is supposed to be used inside of a model hook
    // because then the loading substate will trigger, allowing the request to load
    // before the intro component tries to read data
    getData: function getData() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (_this.get('data')) {
          return resolve(_this.get('data'));
        }

        return _this.ajax.request("/api/v1/accounts/".concat(_this.get('tokenData.data.sub'), "/walkthrough")).then(function (data) {
          var walkthrough = _this.extractWalkthrough(data);

          _this.set('data', walkthrough);

          return resolve(walkthrough);
        }).catch(reject);
      });
    },
    saveData: function saveData() {
      return this.ajax.request("/api/v1/accounts/".concat(this.get('tokenData.data.sub'), "/walkthrough"), {
        type: 'PUT',
        data: JSON.stringify(this.get('data'))
      }).then(function (data) {
        return data.walkthrough;
      });
    },
    extractWalkthrough: function extractWalkthrough(dataWithWalkthrough) {
      var data = dataWithWalkthrough;

      if (!data.walkthrough.managePos) {
        data.walkthrough.managePos = {
          echoPro: {},
          hospitality: {}
        };
      }

      if (!data.walkthrough.premiumWelcome) {
        data.walkthrough.premiumWelcome = {};
      }

      return data.walkthrough;
    }
  });

  _exports.default = _default;
});