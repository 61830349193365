define("lh4/controllers/authenticated/social-media", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    can: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    location: Ember.inject.service(),
    routeData: Ember.computed(function getter() {
      var routeData = [];

      if (this.can.can('manage posts for social-medium')) {
        routeData.push({
          name: 'authenticated.social-media.post-scheduler',
          title: 'Post Scheduler'
        });
        routeData.push({
          name: 'authenticated.social-media.accounts',
          title: 'Social Accounts'
        });
        routeData.push({
          name: 'authenticated.social-media.analytics',
          title: 'Analytics'
        });
      }

      return routeData;
    })
  });

  _exports.default = _default;
});