define("lh4/templates/components/modal/action-confirmation-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "49ylHvFh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segments\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\",true],[11,\"class\",[29,[[22,\"icon\"],\" outline icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[1,[22,\"question\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[1,[22,\"description\"],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui right aligned segment actions\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",\"ui negative button action-confirmation-deny-button\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"onDeny\"]]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"denyText\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",\"ui positive right labeled icon button action-confirmation-approve-button\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"onApprove\"]]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"approveText\"],false],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"checkmark icon\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/modal/action-confirmation-inline.hbs"
    }
  });

  _exports.default = _default;
});