define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/waitlist/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    partySizeOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    phoneNumberTypes: ['Cell', 'Home', 'Office'],
    tableReadyNotifications: ['Text', 'Call'],
    actions: {
      saveChanges: function saveChanges() {
        var _this = this;

        this.get('model.singleReservation').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.get('model.singleReservation').save();

            _this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.waitlist');
          }
        });
      },
      discardChanges: function discardChanges() {
        this.get('model.singleReservation').rollbackAttributes();
        this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.waitlist');
      }
    }
  });

  _exports.default = _default;
});