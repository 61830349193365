define("lh4/mixins/components/data-testable-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Mixin.create({
    /* @param {string} dataTest - should be provided in the following format:
    *  name=[value] where name is required & value is optional.
    *
    *  examples:
    *  "foo-and=bar" render into data-test-foo-and="bar"
    *  "only-foo" renders into data-test-only-foo
    */
    dataTest: null,

    /* @private */
    _attributeName: null,

    /* @private */
    _attributeValue: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.dataTest && !this.hasDataTestAttributeOverridden()) {
        this.setupDataTestAttributeBindings();
      }
    },
    hasDataTestAttributeOverridden: function hasDataTestAttributeOverridden() {
      return Object.keys(this.attrs).find(function (attr) {
        return /^data-test/.test(attr);
      });
    },
    setupDataTestAttributeBindings: function setupDataTestAttributeBindings() {
      var _this$parseDataTest = this.parseDataTest(),
          attributeName = _this$parseDataTest.attributeName,
          attributeValue = _this$parseDataTest.attributeValue;

      this.set('_attributeName', this.appendDataTestAttributeName(attributeName));
      this.set('_attributeValue', attributeValue);
      this.set('attributeBindings', ["_attributeValue:".concat(this._attributeName)]);
    },
    parseDataTest: function parseDataTest() {
      var attributeName = null; // empty string, not null, because we want Ember to render our attribute with no value
      // passing in null does not render it

      var attributeValue = '';

      if (!this.hasDefaultValue()) {
        attributeName = this.dataTest;
      } else {
        var _this$dataTest$split = this.dataTest.split('=');

        var _this$dataTest$split2 = _slicedToArray(_this$dataTest$split, 2);

        attributeName = _this$dataTest$split2[0];
        attributeValue = _this$dataTest$split2[1];
      }

      return {
        attributeName: attributeName,
        attributeValue: attributeValue
      };
    },
    appendDataTestAttributeName: function appendDataTestAttributeName(partialName) {
      return "data-test-".concat(partialName);
    },
    hasDefaultValue: function hasDefaultValue() {
      var _this$dataTest$split3 = this.dataTest.split('='),
          _this$dataTest$split4 = _slicedToArray(_this$dataTest$split3, 2),
          value = _this$dataTest$split4[1];

      return value;
    }
  });

  _exports.default = _default;
});