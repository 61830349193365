define("lh4/serializers/verify-owner/security", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'verify-owner/security';
    },
    attrs: {
      questions: {
        embedded: 'always'
      }
    },
    // Since this is an update the payload is expected to be normalized
    // But the payload is the owner token and since we're
    // remove this record on page transition we're just going to pass
    // the existing data to normalize with our id
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id) {
      var model = store.peekRecord('verify-owner/security', id);
      model.questions.forEach(function (question) {
        question.set('answer', undefined);
      });
      return this.normalize(primaryModelClass, _objectSpread(_objectSpread({}, model.data), {}, {
        id: id,
        context: null,
        salt: null
      }));
    }
  });

  _exports.default = _default;
});