define("lh4/adapters/solo/tax", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this.host, "/api/v1/solo/lighthouse/taxes");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return "".concat(this.host, "/api/v1/solo/lighthouse/taxes/").concat(id);
    },
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(this.host, "/api/v1/solo/lighthouse/taxes/").concat(id);
    },
    urlForQuery: function urlForQuery() {
      return "".concat(this.host, "/api/v1/solo/lighthouse/taxes?locationId=").concat(this.location.model.id);
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(this.host, "/api/v1/solo/lighthouse/taxes?locationId=").concat(this.location.model.id);
    },
    urlForQueryRecord: function urlForQueryRecord() {
      return "".concat(this.host, "/api/v1/solo/lighthouse/taxes?locationId=").concat(this.location.model.id);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id) {
      return "".concat(this.host, "/api/v1/solo/lighthouse/taxes/").concat(id);
    }
  });

  _exports.default = _default;
});