define("lh4/mirage/factories/epro-discount", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    isActive: true,
    isAllowedAnyDate: true,
    isAllowedAnyTime: false,
    isAllowedFriday: true,
    isAllowedMonday: true,
    isAllowedSaturday: true,
    isAllowedSunday: true,
    isAllowedThursday: false,
    isAllowedTuesday: true,
    isAllowedWednesday: true,
    isAppliedToItems: true,
    isAppliedToModifiers: true,
    isAppliedToTickets: false,
    isAutomatic: false,
    isCalculatedPostTax: false,
    isReasonNumeric: true,
    isReasonRequired: true,
    isRepeatedYearly: false,
    items: [{
      guid: function guid() {
        return _faker.default.datatype.uuid();
      },
      locationId: 1,
      name: 'Test Item 1',
      discountItemMap: {
        discountGuid: '1',
        itemGuid: '2'
      }
    }],
    locationId: 1,
    mode: 1,
    name: function name() {
      return _faker.default.commerce.productName();
    },
    orderTypeGuid: '777',
    ordinal: 3,
    endDate: null,
    endTime: null,
    startDate: null,
    startTime: null,
    updatedAt: '2017-06-15T15:54:44.531Z',
    createdAt: '2016-10-18T17:31:43.349Z',
    value: '1.00',
    isLoyaltyDiscount: false
  });

  _exports.default = _default;
});