define("lh4/routes/authenticated/epro/menu/pricing-table", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/utils/currency/converter"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'manage echo-pro/menu',
    location: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: {
      searchValue: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      departmentGuid: {
        refreshModel: true
      }
    },
    convertRecordToModel: function convertRecordToModel(record) {
      var _this = this;

      var corporateGroupItemOverrides = (record.corporateGroupItemOverrides || []).map(function (o) {
        return _this.store.createRecord('corporate-group-item-override', o);
      });
      return this.store.createRecord('echo-pro/menu/pricing-table', _objectSpread(_objectSpread({}, record), {}, {
        id: record.guid,
        itemGuid: record.guid,
        defaultPrice: record.price,
        corporateGroupItemOverrides: corporateGroupItemOverrides
      }));
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (!this.location.model.isCorpHq) {
        this.replaceWith('authenticated.epro.menu');
      }

      this.store.unloadAll('echo-pro/menu/pricing-table');
      this.store.unloadAll('corporate-group-item-override');
    },
    model: function model(params) {
      var _this2 = this;

      var location = this.location.model.id;
      var corporation = this.location.model.corpId;
      var queryParams = {
        limit: 'all',
        order: 'name',
        sort: 'asc'
      };
      return this.ajax.request("/api/v1/corporations/".concat(corporation, "/overrides/items/price"), {
        data: _objectSpread(_objectSpread({}, params), {}, {
          searchName: 'name'
        })
      }).then(function (_ref) {
        var records = _ref.records,
            meta = _ref.meta;
        var pricingTable = records.map(function (item) {
          return _this2.convertRecordToModel(item);
        });
        pricingTable.meta = meta;
        return Ember.RSVP.hash({
          pricingTable: pricingTable,
          departments: _this2.store.query('echo-pro/department', _objectSpread(_objectSpread({}, queryParams), {}, {
            location: location,
            fields: 'name,guid,isActive'
          })),
          corporateGroups: _this2.store.query('corporate-group', _objectSpread(_objectSpread({}, queryParams), {}, {
            corporation: corporation
          })),
          itemGuids: _this2.ajax.request('/api/v1/echo-pro/items', {
            data: {
              fields: 'guid',
              limit: 'all',
              location: location,
              searchName: params.searchName,
              departmentGuid: params.departmentGuid
            }
          }).then(function (_ref2) {
            var items = _ref2.items;
            return items.map(function (_ref3) {
              var guid = _ref3.guid;
              return guid;
            });
          })
        });
      });
    },
    afterModel: function afterModel(model) {
      model.pricingTable.forEach(function (item) {
        var priceGroups = model.corporateGroups.map(function (group) {
          var _item$corporateGroupI;

          var priceOverride = (_item$corporateGroupI = item.corporateGroupItemOverrides.find(function (o) {
            return o.property === 'price' && +o.corporateGroupId === +group.id;
          })) === null || _item$corporateGroupI === void 0 ? void 0 : _item$corporateGroupI.value;
          return {
            record: group,
            price: (0, _converter.formatCurrency)(priceOverride || item.price),
            isPriceOverriden: !!priceOverride
          };
        });
        item.set('priceGroups', priceGroups);
      });
    }
  });

  _exports.default = _default;
});