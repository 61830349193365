define("lh4/routes/sign-in", ["exports", "lh4/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    queryParams: {
      showRecoverySuccess: {
        refreshModel: true
      }
    },
    router: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    titleToken: 'Sign In',
    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);

      if (this.session.isAuthenticated) {
        return null;
      }

      return this.launchDarkly.setFlagsForAnonymousUser();
    },
    model: function model(_ref) {
      var showRecoverySuccess = _ref.showRecoverySuccess;
      var routeName = this.routeName;

      if (showRecoverySuccess) {
        this.controllerFor(routeName).set('successMessage', 'Your request was successfully sent.' + ' You will receive an email soon with instructions to complete your request.');
        this.controllerFor(routeName).set('recoverySuccess', true);
      }
    },
    afterModel: function afterModel(_, transition) {
      var _this = this;

      transition.then(function () {
        _this.replaceWith('/sign-in');
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.resetRecoverySuccess();
        controller.resetFailed2FAMessage();
        controller.set('isLoading', false);
      }
    }
  });

  _exports.default = _default;
});