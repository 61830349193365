define("lh4/templates/components/ui/client-pagination-buttons-prev-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TGKzNtbl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui pagination menu client-pagination-buttons-comp\"],[8],[0,\"\\n\\t\"],[7,\"a\",false],[12,\"class\",[29,[\"pagination-previous-btn icon relative item \",[28,\"if\",[[28,\"not\",[[24,[\"hasPrevPage\"]]],null],\"disabled\"],null]]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectPreviousPage\"]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"left chevron icon\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"a\",false],[12,\"class\",[29,[\"pagination-next-btn icon relative item \",[28,\"if\",[[28,\"not\",[[24,[\"hasNextPage\"]]],null],\"disabled\"],null]]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectNextPage\"]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"right chevron icon\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/client-pagination-buttons-prev-next.hbs"
    }
  });

  _exports.default = _default;
});