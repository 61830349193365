define("lh4/templates/components/ui/icons/s4/checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jyEUfAzu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"15\"],[10,\"height\",\"11\"],[10,\"viewBox\",\"0 0 15 11\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M5.5 11L0.5 6.00002L1.91 4.59002L5.5 8.17002L13.09 0.580017L14.5 2.00002L5.5 11Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/s4/checked.hbs"
    }
  });

  _exports.default = _default;
});