define("lh4/authenticators/oauth2-custom", ["exports", "lh4/config/environment", "lh4/authenticators/jwt-custom"], function (_exports, _environment, _jwtCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwtCustom.default.extend({
    init: function init() {
      this._super.apply(this, arguments); // overwrite the endpoint


      this.serverTokenEndpoint = _environment.default.oauth2.authTokenUri;
      this.tokenPropertyName = 'access_token';
    },
    getAuthenticateData: function getAuthenticateData(credentials) {
      return {
        client_id: _environment.default.oauth2.clientId,
        client_secret: '',
        grant_type: _environment.default.oauth2.grantType,
        code: credentials.code,
        redirect_uri: _environment.default.oauth2.redirectUri,
        code_verifier: credentials.codeVerifier
      };
    }
  });

  _exports.default = _default;
});