define("lh4/components/modal/workforce-notification", ["exports", "moment", "lh4/enums/date-time-format"], function (_exports, _moment, _dateTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    classNames: ['workforce-notification'],
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    modalsManager: Ember.inject.service('modals-manager'),

    /* @param {string} errorMessage - Message to display for form level error */
    errorMessage: null,
    locationId: Ember.computed('location.model', function getter() {
      return this.location.model.id;
    }),
    hideWfNotification: Ember.computed('location.model.id', {
      get: function get() {
        var hideWfNotification = JSON.parse(window.localStorage.getItem('hideWfNotification'));

        if (_typeof(hideWfNotification) !== 'object' || hideWfNotification === null) {
          hideWfNotification = {};
        }

        return hideWfNotification[this.location.model.id] || false;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    message: Ember.computed('location.model', function getter() {
      var _this$location$model$, _wfService$locationSe;

      var MIN_DIFF_IN_DAYS = 14;
      var wfService = (_this$location$model$ = this.location.model.services) === null || _this$location$model$ === void 0 ? void 0 : _this$location$model$.find(function (_ref) {
        var name = _ref.name;
        return name === 'social-schedules';
      });
      var wfServiceConfig = wfService ? wfService.locationService.configuration : null; // if it's an active subscription in pending cancellation status

      if (wfService !== null && wfService !== void 0 && (_wfService$locationSe = wfService.locationService) !== null && _wfService$locationSe !== void 0 && _wfService$locationSe.isActive && wfServiceConfig !== null && wfServiceConfig !== void 0 && wfServiceConfig.cancelDate && (0, _moment.default)(wfServiceConfig.cancelDate).diff((0, _moment.default)(), 'days') <= MIN_DIFF_IN_DAYS && (!wfServiceConfig.cancelOrderDate || (0, _moment.default)().diff((0, _moment.default)(wfServiceConfig.cancelOrderDate), 'days') >= 1)) {
        return "Your Workforce subscription will be cancelled on ".concat((0, _moment.default)(wfServiceConfig.cancelDate).format(_dateTimeFormat.default.LOCALIZED_DATE_ONLY), ".");
      } // if it's a trial


      if ((wfServiceConfig === null || wfServiceConfig === void 0 ? void 0 : wfServiceConfig.subscriptionStartDate) === null && wfServiceConfig !== null && wfServiceConfig !== void 0 && wfServiceConfig.trialEndDate && (0, _moment.default)(wfServiceConfig.trialEndDate).diff((0, _moment.default)(), 'days') <= MIN_DIFF_IN_DAYS) {
        return "Your Workforce subscription will be ending trial on ".concat((0, _moment.default)(wfServiceConfig.trialEndDate).format(_dateTimeFormat.default.LOCALIZED_DATE_ONLY), ".");
      }

      return '';
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.shouldShow && this.message.length && !this.hideWfNotification) {
        this.modalsManager.pushToList('workforce-notification', this.$('.ui.modal'));
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.shouldShow && this.message.length && !this.hideWfNotification) {
        this.modalsManager.pushToList('workforce-notification', this.$('.ui.modal'));
      }
    },
    actions: {
      close: function close() {
        this.modalsManager.deleteFromList('workforce-notification');
      },
      onHidden: function onHidden() {
        this.send('close');
      },
      setHideWfNotification: function setHideWfNotification() {
        this.set('hideWfNotification', !this.hideWfNotification);
        var hideWfNotification = JSON.parse(window.localStorage.getItem('hideWfNotification'));

        if (_typeof(hideWfNotification) !== 'object' || hideWfNotification === null) {
          hideWfNotification = {};
        }

        hideWfNotification[this.locationId] = this.hideWfNotification;
        window.localStorage.setItem('hideWfNotification', JSON.stringify(hideWfNotification));
      }
    }
  });

  _exports.default = _default;
});