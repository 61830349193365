define("lh4/serializers/loyalty/promotion", ["exports", "lh4/serializers/loyalty/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (json.startDate) {
        json.isAllowedAnyDate = false;
      }

      if (json.startTime) {
        json.isAllowedAnyTime = false;
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.isAllowedAnyDate) {
        json.startDate = null;
        json.endDate = null;
        json.isRepeatedYearly = false;
      }

      if (json.isAllowedAnyTime) {
        json.startTime = null;
        json.endTime = null;
      }

      return json;
    }
  });

  _exports.default = _default;
});