define("lh4/mirage/routes/hardware", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageHardwareItemsServer;

  function setMirageHardwareItemsServer(server) {
    server.get('hardware/items', function () {
      return {
        items: [{
          id: 'SKYTAB_GLASS',
          name: 'SkyTab Glass',
          type: 'Hardware',
          price: 29.99,
          priceSuffix: 'mo',
          maxQuantity: 99,
          paymentInterval: 'monthly',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/SkyTab_Glass.png',
          archosProductId: '2390',
          dependencies: [],
          displayEnabled: true,
          listItemDetails: {
            imageUrl: 'https://lh-banner.s3.amazonaws.com/images/skytab-glass-798x453.png',
            title: 'SkyTab Glass',
            description: 'POS on a tablet - use it like you would your POS Workstation, but on the go! Take your tablet with you and take orders table-side. Pay at the table with full payment capabilities, including tap to pay and EMV. Integrated with all SkyTab ecosystem accessories - printers, cash drawers, and more!',
            disclaimer: null
          },
          productPageEnabled: true,
          productPageDetails: {
            headingSectionEnabled: true,
            aboutSectionEnabled: true,
            detailsSectionEnabled: true,
            path: 'skytab-glass',
            name: 'SkyTab Glass',
            headingSection: {
              imageUrl: '/img/hardware/skytab-glass-heading.png',
              title: 'SkyTab Glass',
              badge: null,
              subTitle: 'Supercharge your servers with fully mobile POS',
              description: 'POS on a tablet - use it like you would your POS Workstation, but on the go! Take your tablet with you and take orders table-side. Pay at the table with full payment capabilities, including tap to pay and EMV. Integrated with all SkyTab ecosystem accessories - printers, cash drawers, and more!'
            },
            aboutSection: {
              title: 'Complete POS software',
              description: 'POS on a tablet - use it like you would your POS Workstation and take Chip, Swipe or NFC payments on the go!',
              imageUrl: '/img/hardware/pos-tablet.png',
              gridList: null
            },
            productDetailsSection: {
              details: [{
                type: 'Device Type',
                description: '22” Touchscreen Display'
              }, {
                type: 'Dimensions',
                description: '12.2” H x 20.2” W x 1.8” D inches'
              }, {
                type: 'Weight',
                description: '15.4lb'
              }, {
                type: 'Connectivity',
                description: 'Ethernet'
              }],
              imageUrl: '/img/hardware/skytab-kds-measurement.png',
              isImageVertical: false
            }
          },
          options: null
        }, {
          id: 'SKYTAB_POS',
          name: 'SkyTab POS',
          type: 'Hardware',
          price: 29.99,
          priceSuffix: 'mo',
          maxQuantity: 99,
          paymentInterval: 'monthly',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/SkyTab_POS.png',
          archosProductId: '2384',
          dependencies: [],
          displayEnabled: true,
          listItemDetails: {
            imageUrl: '/img/hardware/skytab-pos-list-item.png',
            title: 'SkyTab POS Bundle',
            description: 'Revolutionary POS system, combining flawless performance, modern sleek design, and an integrated customer display for a truly exceptional checkout experience!',
            disclaimer: null
          },
          productPageEnabled: true,
          productPageDetails: {
            headingSectionEnabled: true,
            aboutSectionEnabled: true,
            detailsSectionEnabled: true,
            path: 'skytab-pos-bundle',
            name: 'SkyTab POS Bundle',
            headingSection: {
              imageUrl: '/img/hardware/skytab-pos-heading.png',
              title: 'SkyTab POS',
              badge: 'BUNDLE',
              subTitle: 'Power your restaurant with next generation POS',
              description: 'Revolutionary POS system, combining flawless performance, modern sleek design, and an integrated customer display for a truly exceptional checkout experience! Say goodbye to technical glitches and slow transactions, as our system ensures flawless performance every time, allowing you to serve customers swiftly and efficiently. With its sleek and modern design, our POS system adds a touch of sophistication to your business, impressing both customers and staff alike.'
            },
            aboutSection: {
              title: 'All-in-One POS System',
              description: 'Upgrade your business with our all-inclusive POS bundle, including a cash drawer, receipt printer, and employee cards, for streamlined operations and enhanced point-of-sale efficiency.',
              imageUrl: '/img/hardware/skytab-pos.png',
              gridList: null
            },
            productDetailsSection: {
              details: [{
                type: 'Device Type',
                description: '14.0" Touchscreen Android Device '
              }, {
                type: 'Dimensions',
                description: '11.4” H x 17.7” W x 14.6” D inches'
              }, {
                type: 'Weight',
                description: '5.68lb'
              }, {
                type: 'Connectivity',
                description: 'Dual-Band Wi-Fi, Bluetooth 5.0, USB type-C (2) for Power & Hub connect'
              }],
              imageUrl: '/img/hardware/skytab-pos-measurement.png',
              isImageVertical: false
            }
          },
          options: {
            type: 'checkbox',
            title: 'Customise the bundle',
            items: [{
              name: '10 Employee cards',
              value: 'SKYTAB_EMPLOYEE_ID_CARDS'
            }, {
              name: 'Receipt Printer Epson TM-M30',
              value: 'RECEIPT_PRINTER_EPSON_TM_M30'
            }, {
              name: 'Cash Drawer',
              value: 'CASH_DRAWER'
            }]
          }
        }, {
          id: 'SKYTAB_EMPLOYEE_ID_CARDS',
          name: 'SkyTab Employee ID Cards',
          type: 'Supplies',
          price: 9.99,
          priceSuffix: 'pack',
          maxQuantity: 99,
          paymentInterval: 'oneTime',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/SkyTab_Employee_Cards.png',
          archosProductId: '1871',
          dependencies: [],
          displayEnabled: true,
          listItemDetails: {
            isOrderingDisabled: false,
            imageUrl: '/img/hardware/skytab-employee-id-cards.png',
            title: 'Employee ID Cards',
            description: 'SkyTab Employee ID cards allow your staff to sign in to the POS securely with a single swipe. Adding even this small efficiency adds up to savings.',
            disclaimer: null
          },
          productPageEnabled: false,
          productPageDetails: {},
          options: null
        }, {
          id: 'ARCHER_ROUTER',
          name: 'Wireless Router',
          type: 'Hardware',
          price: 0,
          priceSuffix: null,
          maxQuantity: 1,
          paymentInterval: 'oneTime',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/Archer_Router.png',
          archosProductId: '2183',
          dependencies: [{
            id: 'SKYTAB_GLASS',
            ratio: null
          }],
          displayEnabled: false,
          listItemDetails: {},
          productPageEnabled: false,
          productPageDetails: {},
          options: null
        }, {
          id: 'SKYTAB_MOBILE',
          name: 'SkyTab Mobile',
          type: 'Hardware',
          price: 20,
          priceSuffix: 'mo',
          maxQuantity: 99,
          paymentInterval: 'monthly',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/SkyTab_Mobile_2.png',
          archosProductId: '2476',
          dependencies: [],
          displayEnabled: true,
          listItemDetails: {
            imageUrl: '/img/hardware/skytab-mobile-list-item.png',
            title: 'SkyTab Mobile',
            description: 'A handheld terminal to take orders and accept payment from anywhere with Cellular capabilities.',
            disclaimer: null
          },
          productPageEnabled: true,
          productPageDetails: {
            headingSectionEnabled: true,
            aboutSectionEnabled: true,
            detailsSectionEnabled: true,
            path: 'skytab-mobile',
            name: 'SkyTab Mobile',
            headingSection: {
              imageUrl: '/img/hardware/skytab-mobile-heading.png',
              title: 'SkyTab Mobile',
              badge: null,
              subTitle: 'Point of Sale in the Palm of Your Hand',
              description: 'SkyTab Mobile lets you take orders and accept payment from anywhere with 4G and WiFi capabilities. Increase Operational Efficiency, Build a Marketing Database, and Improve Online Reputation.'
            },
            aboutSection: {
              title: 'Mobile Hardware to Supercharge Your Business',
              description: 'SkyTab Mobile is a game-changer for the hospitality and foodservice industries. It combines state-of-the-art hardware with simple and intuitive software to deliver a five-star experience for businesses and their guests.',
              imageUrl: '/img/hardware/skytab-mobile.png',
              gridList: null
            },
            productDetailsSection: {
              details: [{
                type: 'Device Type',
                description: '5.7" Touchscreen Android Device'
              }, {
                type: 'Dimensions',
                description: '7.4” H x 3.1” W x 2.6” D inches'
              }, {
                type: 'Weight',
                description: '1lb'
              }, {
                type: 'Connectivity',
                description: 'Wifi, Cellular 4G'
              }, {
                type: 'Payments',
                description: 'Chip, Swipe, NFC built-in'
              }],
              imageUrl: '/img/hardware/skytab-mobile-measurement.png',
              isImageVertical: true
            }
          }
        }, {
          id: 'SKYTAB_KDS',
          name: 'SkyTab KDS',
          type: 'Hardware',
          price: 29.99,
          priceSuffix: 'mo',
          maxQuantity: 99,
          paymentInterval: 'monthly',
          imageUrl: '/img/hardware/skytab-kds-heading.png',
          archosProductId: '2409',
          dependencies: [],
          displayEnabled: true,
          listItemDetails: {
            imageUrl: '/img/hardware/skytab-kds-heading.png',
            title: 'SkyTab KDS',
            description: 'Optimize operations by connecting Front of House to Back of House.',
            disclaimer: null
          },
          productPageEnabled: true,
          productPageDetails: {
            headingSectionEnabled: true,
            aboutSectionEnabled: true,
            detailsSectionEnabled: true,
            path: 'skytab-kds',
            name: 'SkyTab KDS',
            headingSection: {
              imageUrl: '/img/hardware/skytab-kds-heading.png',
              title: 'SkyTab KDS',
              badge: null,
              subTitle: 'Unleash the Power of Efficiency with our Kitchen Display System',
              description: 'Optimize operations by connecting Front of House to Back of House.'
            },
            aboutSection: {
              title: null,
              description: null,
              imageUrl: '/img/hardware/skytab-kds.png',
              gridList: [{
                title: 'Intuitive',
                items: ['Customizable and easy-to-use', 'Large screen for easy reading', 'Responsive touchscreen']
              }, {
                title: 'Reliable',
                items: ['Always on connectivity pushes orders to the KDS via local connection', 'Commercial-grade hardware for high-volume settings', 'Kitchen performance insight to track productivity']
              }, {
                title: 'Scalable',
                items: ['Routes orders to multiple prep stations with multi-station fulfillment', 'Displays item status from prep stations for cross-team collaboration', 'Routes all direct and integrated third-party orders from the POS']
              }]
            },
            productDetailsSection: {
              details: [{
                type: 'Device Type',
                description: '22" Touchscreen Display'
              }, {
                type: 'Dimensions',
                description: '12.2” H x 20.2” W x 1.8” D inches'
              }, {
                type: 'Weight',
                description: '15.4lb'
              }, {
                type: 'Connectivity',
                description: 'Ethernet'
              }],
              imageUrl: '/img/hardware/skytab-kds-measurement.png',
              isImageVertical: false
            }
          },
          options: {
            type: 'radio',
            title: 'Mounting options',
            items: [{
              name: 'Wall Mount',
              value: 'SKYTAB_KDS_WALL_MOUNT'
            }, {
              name: 'Stand',
              value: 'SKYTAB_KDS_STAND'
            }]
          }
        }, {
          id: 'SKYTAB_KDS_STAND',
          name: 'SkyTab KDS Stand, Dual-hinge',
          type: 'Hardware',
          price: 0,
          priceSuffix: null,
          maxQuantity: 99,
          paymentInterval: 'oneTime',
          imageUrl: null,
          archosProductId: '2442',
          dependencies: [{
            id: 'SKYTAB_KDS',
            ratio: null
          }],
          displayEnabled: false,
          listItemDetails: {},
          productPageEnabled: false,
          productPageDetails: {},
          options: null
        }, {
          id: 'SKYTAB_KDS_WALL_MOUNT',
          name: 'SkyTab KDS Wall-Mount Kit',
          type: 'Hardware',
          price: 0,
          priceSuffix: null,
          maxQuantity: 99,
          paymentInterval: 'oneTime',
          imageUrl: null,
          archosProductId: '2410',
          dependencies: [{
            id: 'SKYTAB_KDS',
            ratio: null
          }],
          displayEnabled: false,
          listItemDetails: {},
          productPageEnabled: false,
          productPageDetails: {},
          options: null
        }, {
          id: 'RECEIPT_PRINTER_EPSON_TM_M30',
          name: 'Receipt Printer Epson TM-M30',
          type: 'Hardware',
          price: 0,
          priceSuffix: null,
          maxQuantity: 99,
          paymentInterval: 'oneTime',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/Epson_TMM30_Printer.png',
          archosProductId: '2150',
          dependencies: [],
          displayEnabled: false,
          listItemDetails: {},
          productPageEnabled: false,
          productPageDetails: {},
          options: null
        }, {
          id: 'SKYTAB_CUSTOMER_FACING_DISPLAY',
          name: 'SkyTab Customer Facing Display',
          type: 'Hardware',
          price: 29.99,
          priceSuffix: 'mo',
          maxQuantity: 99,
          paymentInterval: 'monthly',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/Customer-Facing-Display-Icon.png',
          archosProductId: '2449',
          dependencies: [],
          displayEnabled: true,
          listItemDetails: {
            imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/Customer-Facing-Display-Icon.png',
            title: 'SkyTab Customer Facing Display',
            description: 'Upgrade your payment system to our Customer facing display and streamline your checkout process.',
            disclaimer: null
          },
          productPageEnabled: true,
          productPageDetails: {
            headingSectionEnabled: true,
            aboutSectionEnabled: true,
            detailsSectionEnabled: true,
            path: 'skytab-display',
            name: 'SkyTab Customer Facing Display',
            headingSection: {
              imageUrl: '/img/hardware/skytab-customer-facing-display.png',
              title: 'SkyTab Customer Facing Display',
              badge: null,
              subTitle: 'Elevate the Customer Experience',
              description: 'Add SkyTab’s interactive customer display to improve the guest experience. The simplified ordering and payments system enhances customer experience by providing transparency and accuracy in order processing. Customers can conveniently review their orders through a touchscreen display, ensuring precision and increased tipping opportunities'
            },
            aboutSection: {
              title: 'Customer Engagement',
              description: 'The system offers customization options, allowing businesses to upload custom images for branding and advertising on idle screens. Additionally, the system facilitates seamless tipping through configurable gratuity rates, making it easier for customers to leave tips. In terms of customer engagement, the integration of loyalty programs fosters customer retention and opens up marketing opportunities.',
              imageUrl: '/img/hardware/skytab-customer-facing-display-about.png',
              gridList: null
            },
            secondAboutSection: {
              title: 'Secure & Feature Rich',
              description: "The platform ensures security through Shift4, a leading provider of integrated payments in the foodservice and hospitality industries. Transactions are protected, offering peace of mind to both businesses and customers. Furthermore, the system's flexibility shines through in its diverse payment options, accommodating various preferences with contactless, mobile, EMV, and swipe transactions.",
              imageUrl: '/img/hardware/skytab-customer-facing-display-second-about.png'
            },
            productDetailsSection: {
              details: [{
                type: 'Device Type',
                description: '7.0" Touchscreen Display'
              }, {
                type: 'Dimensions',
                description: '7.4” W x 6.9” H x 1.9” D inches'
              }, {
                type: 'Weight',
                description: '1.73 Lb'
              }, {
                type: 'Connectivity',
                description: 'USB'
              }, {
                type: 'Payment',
                description: 'Chip, Swipe, NFC built-in'
              }],
              imageUrl: '/img/hardware/skytab-customer-facing-display-measurement.png',
              isImageVertical: true
            }
          }
        }, {
          id: 'REMOTE_DOT_MATRIX_KITCHEN_PRINTER',
          name: 'Remote Dot Matrix Kitchen Printer',
          type: 'Hardware',
          price: 9.99,
          priceSuffix: 'mo',
          maxQuantity: 99,
          paymentInterval: 'monthly',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/remote-dot-matrix-kitchen-printer-small.png',
          archosProductId: '1880',
          dependencies: [],
          displayEnabled: true,
          listItemDetails: {
            imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/remote-dot-matrix-kitchen-printer-small.png',
            title: 'Remote Dot Matrix Kitchen Printer',
            description: 'Ethernet Kitchen Printer that has the ability to print in two colors. Built to withstand heat and the fast pace environment in a kitchen. When printing has an audible noise to assist the kitchen staff to ensure no order has been missed.',
            disclaimer: null
          },
          productPageEnabled: false,
          productPageDetails: {},
          options: null
        }, {
          id: 'THERMAL_PRINTER',
          name: 'Thermal Printer',
          type: 'Hardware',
          price: 9.99,
          priceSuffix: 'mo',
          maxQuantity: 99,
          paymentInterval: 'monthly',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/thermal-printer.png',
          archosProductId: '2150',
          dependencies: [],
          displayEnabled: true,
          listItemDetails: {
            imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/thermal-printer.png',
            title: 'Thermal Printer',
            description: 'Multifunctional Thermal printer that can be used for receipts, ticket orders, kitchen printer via ethernet and USB communication.',
            disclaimer: null
          },
          productPageEnabled: false,
          productPageDetails: {},
          options: null
        }, {
          id: 'CASH_DRAWER',
          name: 'Cash Drawer',
          type: 'Hardware',
          price: 129.99,
          priceSuffix: null,
          maxQuantity: 99,
          paymentInterval: 'oneTime',
          imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/Cash_Drawer.png',
          archosProductId: '2010',
          dependencies: [],
          displayEnabled: true,
          listItemDetails: {
            imageUrl: 'https://s3.amazonaws.com/Harbortouch_Files/Images/Cash_Drawer.png',
            title: 'Cash Drawer',
            description: 'Our high-quality cash drawer is the perfect solution to keep your cash organized and secure. With its robust construction and user-friendly design, it ensures smooth cash transactions and safeguards your funds.',
            disclaimer: null
          },
          productPageEnabled: false,
          productPageDetails: {},
          options: null
        }]
      };
    });
    server.get('hardware/items/ordered', function () {
      return {
        SKYTAB_GLASS: false,
        SKYTAB_EMPLOYEE_ID_CARDS: false,
        ARCHER_ROUTER: true,
        SKYTAB_MOBILE: false
      };
    });
  }
});