define("lh4/controllers/authenticated/customer-database/edit", ["exports", "jquery", "lh4/utils/changeset"], function (_exports, _jquery, changesetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    shouldCloseTray: false,
    showErrors: null,
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    flashMessages: null,
    validateAndSaveChangeset: function validateAndSaveChangeset(changeset, key) {
      var _this = this;

      return changeset.map(function (record) {
        return record.validate().then(function () {
          if (record.isValid) {
            record.set('customer', _this.model.customer); // record.save was throwing because models don't have a save endpoint

            record.execute();
            record.rollback();

            _this.model.customer.get(key).pushObject(record.data);

            return true;
          }

          return Ember.RSVP.reject(record.get('errors'));
        }).catch(function (validations) {
          if (validations.length) {
            validations.forEach(function (v) {
              _this.flashMessagesHelper.pushMessage(v.validation, 'error');
            });
          }
        });
      });
    },
    isModelAndChangesetPristine: function isModelAndChangesetPristine() {
      return !this.model.customer.isDirty && this.changeset.get('isPristine');
    },
    areChildrenPristine: function areChildrenPristine(model, changeset) {
      return changesetUtils.areChildrenPristine(model, changeset);
    },
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (_ref) {
        var id = _ref.id;
        return !changeset.findBy('id', id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    closeTray: function closeTray() {
      this.set('showErrors', false);
      this.set('shouldCloseTray', true);
    },
    actions: {
      close: function close() {
        this.set('shouldCloseTray', false);
        this.transitionToRoute('authenticated.customer-database');
      },
      updateCustomer: function updateCustomer() {
        var _this2 = this;

        this.flashMessagesHelper.clearMessages();
        this.set('showErrors', true); // we have to check for pristine before we validate and save

        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.customer.phones, this.changeset.phoneChangesets) && this.areChildrenPristine(this.model.customer.emails, this.changeset.emailChangesets) && this.areChildrenPristine(this.model.customer.addresses, this.changeset.addressChangesets); // validating and saving at the same time for convenience.
        // If this causes a problem it should be moved into the same block for model.

        var phonePromises = this.validateAndSaveChangeset(this.changeset.phoneChangesets, 'phones');
        var emailPromises = this.validateAndSaveChangeset(this.changeset.emailChangesets, 'emails');
        var addressPromises = this.validateAndSaveChangeset(this.changeset.addressChangesets, 'addresses');
        Ember.RSVP.all([].concat(_toConsumableArray(phonePromises), _toConsumableArray(emailPromises), _toConsumableArray(addressPromises))).then(function (values) {
          var areChildrenValid = values.every(function (value) {
            return value;
          });

          _this2.changeset.validate().then(function () {
            if (!(_this2.changeset.get('isValid') && areChildrenValid)) {
              return Ember.RSVP.reject(_this2.changeset.get('errors'));
            }

            _this2.removeRecordsFromModel(_this2.model.customer.phones, _this2.changeset.phoneChangesets);

            _this2.removeRecordsFromModel(_this2.model.customer.emails, _this2.changeset.emailChangesets);

            _this2.removeRecordsFromModel(_this2.model.customer.addresses, _this2.changeset.addressChangesets);

            if (isPristine) {
              _this2.closeTray();

              return Ember.RSVP.resolve();
            }

            return _this2.changeset.save().then(function () {
              _this2.closeTray();

              return Ember.RSVP.resolve();
            });
          }).catch(function (errors) {
            if (errors.length) {
              errors.forEach(function (error) {
                _this2.flashMessagesHelper.pushMessage(error.validation, 'error');
              });
            }
          });
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      deleteCustomerModal: function deleteCustomerModal() {
        (0, _jquery.default)('#deleteModal').modal('show');
      },
      deleteCustomer: function deleteCustomer() {
        var _this3 = this;

        this.changeset.rollback();
        this.model.customer.destroyRecord().then(function () {
          _this3.set('shouldCloseTray', true);
        });
      }
    }
  });

  _exports.default = _default;
});