define("lh4/abilities/order", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions", "lh4/config/environment"], function (_exports, _emberCan, _abilityMixin, _permissions, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    canView: Ember.computed(function getter() {
      // Show all products except Slice
      return _environment.default.branding.name !== _environment.default.BrandNames.Slice && (this.get('canViewSupply') || this.get('canViewPos') || this.get('canPlaceSupply'));
    }),
    canPlaceSupply: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.ORDERS.PLACE_SUPPLY_ORDER);
    }),
    canViewSupply: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.ORDERS.VIEW_SUPPLY_ORDER);
    }),
    canViewPos: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.ORDERS.VIEW_POS_ORDER);
    })
  });

  _exports.default = _default;
});