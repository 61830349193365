define("lh4/templates/authenticated/internet-payments/reporting/subscriptions/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zKIEdVPW",
    "block": "{\"symbols\":[\"tray\"],\"statements\":[[5,\"internet-payments/tray/sliding-tray\",[],[[\"@className\",\"@onClose\",\"@errorMessage\",\"@shouldClose\",\"@title\"],[\"subscriptions-tray\",[23,0,[\"onCloseTray\"]],[23,0,[\"errorMessage\"]],false,\"Create Subscription\"]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"subscriptions-form padding-wrapper\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"internet-payments/reporting/subscriptions/subscription-form\",[],[[\"@locations\",\"@reportTypes\",\"@subscription\",\"@warningMessage\",\"@setWarningMessage\"],[[23,0,[\"model\",\"locations\"]],[23,0,[\"model\",\"reportTypes\"]],[23,0,[\"changeset\"]],[23,0,[\"warningMessage\"]],[23,0,[\"setWarningMessage\"]]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[6,[23,1,[\"actions\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"disabled\",[23,0,[\"isSubmitButtonDisabled\"]]],[12,\"class\",\"ui primary button full-width\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"create\"]]]],[8],[0,\"\\n\\t\\t\\tSave\\n\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/reporting/subscriptions/create.hbs"
    }
  });

  _exports.default = _default;
});