define("lh4/services/internet-payments/risk-block-list-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var emptyResponse = [];

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    getRiskBlockListFromCustomer: function getRiskBlockListFromCustomer(customer) {
      var requestArray = [];

      if (customer !== null && customer !== void 0 && customer.emailList) {
        customer.emailList.forEach(function (value) {
          return requestArray.push({
            parameter: 'email',
            value: value
          });
        });
      }

      if (customer !== null && customer !== void 0 && customer.id) {
        requestArray.push({
          parameter: 'token',
          value: customer.id
        });
      }

      return this.getRiskBlockList(requestArray);
    },
    getRiskBlockListFromTransaction: function getRiskBlockListFromTransaction(transaction) {
      var emailDomain = function emailDomain() {
        var email = transaction.customerEmail;

        if (!email) {
          return null;
        }

        var domainRegex = /@([^\s@]+)$/;
        var match = email.match(domainRegex);
        return match ? match[1] : null;
      };

      var customerName = transaction.customerName ? transaction.customerName : null;
      var requestArray = [];

      if (transaction !== null && transaction !== void 0 && transaction.customerEmail) {
        requestArray.push({
          parameter: 'email',
          value: transaction.customerEmail
        });
      }

      if (transaction !== null && transaction !== void 0 && transaction.deviceId) {
        requestArray.push({
          parameter: 'deviceId',
          value: transaction.deviceId
        });
      }

      if (transaction !== null && transaction !== void 0 && transaction.cardToken) {
        requestArray.push({
          parameter: 'token',
          value: transaction.cardToken
        });
      }

      if (emailDomain) {
        requestArray.push({
          parameter: 'emailDomain',
          value: emailDomain()
        });
      }

      if (customerName) {
        requestArray.push({
          parameter: 'fullName',
          value: customerName
        });
      }

      return this.getRiskBlockList(requestArray, transaction.locationId);
    },
    getRiskBlockList: function getRiskBlockList(requestArray, locationId) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        if (!requestArray || requestArray.length === 0) {
          return resolve(emptyResponse);
        }

        return _this.ajax.request("/api/v2/internet-payments/risk-block-list/".concat(locationId !== null && locationId !== void 0 ? locationId : _this.location.model.id, "/status"), {
          type: 'POST',
          data: JSON.stringify({
            riskRules: requestArray
          })
        }).then(function (response) {
          if (!response.riskRules || !response.riskRules.length) {
            return resolve(emptyResponse);
          }

          return resolve(response.riskRules);
        }).catch(function () {
          return resolve(emptyResponse);
        });
      });
    },
    saveRiskBlockList: function saveRiskBlockList(requestArray, locationId) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!requestArray || requestArray.length === 0) {
          return resolve();
        }

        return _this2.ajax.request("/api/v2/internet-payments/risk-block-list/".concat(locationId !== null && locationId !== void 0 ? locationId : _this2.location.model.id, "/update"), {
          method: 'POST',
          data: JSON.stringify({
            riskRules: requestArray
          })
        }).then(resolve).catch(reject);
      });
    }
  });

  _exports.default = _default;
});