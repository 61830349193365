define("lh4/controllers/authenticated/internet-payments/customers/details/transaction/refund-transaction", ["exports", "lh4/controllers/authenticated/internet-payments/customers/details/create-transaction"], function (_exports, _createTransaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _createTransaction.default.extend({});

  _exports.default = _default;
});