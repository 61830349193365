define("lh4/models/echo-pro/customer", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/utils/array-join", "moment", "lh4/models/echo-pro/customer/email", "lh4/models/echo-pro/customer/phone", "lh4/models/echo-pro/customer/address"], function (_exports, _emberData, _attr, _relationships, _emberCpValidations, _arrayJoin, _moment, _email, _phone, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "emailTypes", {
    enumerable: true,
    get: function get() {
      return _email.emailTypes;
    }
  });
  Object.defineProperty(_exports, "phoneTypes", {
    enumerable: true,
    get: function get() {
      return _phone.phoneTypes;
    }
  });
  Object.defineProperty(_exports, "addressTypes", {
    enumerable: true,
    get: function get() {
      return _address.addressTypes;
    }
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var typeComparator = function typeComparator(a, b, types) {
    var aOrderIndex = types.findIndex(function (type) {
      return type.name === a.get('type');
    });
    var bOrderIndex = types.findIndex(function (type) {
      return type.name === b.get('type');
    });
    return aOrderIndex - bOrderIndex;
  };

  var primaryFromArray = function primaryFromArray(array, collectionName) {
    return Ember.computed("".concat(collectionName, ".@each.type"), function getter() {
      return this.get(collectionName).toArray().sort(function (a, b) {
        return typeComparator(a, b, array);
      }).get('firstObject');
    });
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: {
      description: 'First Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    lastName: {
      description: 'Last Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    notes: {
      description: 'Comment',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    emails: {
      description: 'Email',
      validators: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('array-unique-field', {
        uniqueField: 'type',
        uniqueFieldFilter: function uniqueFieldFilter(_ref) {
          var type = _ref.type;
          return _email.emailTypes.some(function (_ref2) {
            var name = _ref2.name;
            return type === name;
          });
        },
        dependentKeys: ['emails.@each.type']
      })]
    },
    phones: {
      description: 'Phone',
      validators: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('array-unique-field', {
        uniqueField: 'type',
        uniqueFieldFilter: function uniqueFieldFilter(_ref3) {
          var type = _ref3.type;
          return _phone.phoneTypes.some(function (_ref4) {
            var name = _ref4.name;
            return type === name;
          });
        },
        dependentKeys: ['phones.@each.type']
      })]
    },
    addresses: {
      description: 'Address',
      validators: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('array-unique-field', {
        uniqueField: 'type',
        uniqueFieldFilter: function uniqueFieldFilter(_ref5) {
          var type = _ref5.type;
          return _address.addressTypes.some(function (_ref6) {
            var name = _ref6.name;
            return type === name;
          });
        },
        dependentKeys: ['addresses.@each.type']
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    notes: (0, _attr.default)('string'),
    customerStatistic: (0, _attr.default)(),
    emails: (0, _relationships.hasMany)('echo-pro/customer/email'),
    phones: (0, _relationships.hasMany)('echo-pro/customer/phone'),
    addresses: (0, _relationships.hasMany)('echo-pro/customer/address'),
    primaryEmail: primaryFromArray(_email.emailTypes, 'emails'),
    primaryPhone: primaryFromArray(_phone.phoneTypes, 'phones'),
    primaryAddress: primaryFromArray(_address.addressTypes, 'addresses'),
    fullName: Ember.computed('firstName', 'lastName', function getter() {
      return (0, _arrayJoin.default)(this, ['firstName', 'lastName'], ' ');
    }),
    avgSpend: Ember.computed('customerStatistic', function getter() {
      var total = this.get('totalSpend');
      var count = parseInt(this.get('customerStatistic.salesCount'), 10);
      return total && count ? total / count : 0;
    }),
    totalSpend: Ember.computed('customerStatistic', function getter() {
      return parseFloat(this.get('customerStatistic.salesTotal')) || 0;
    }),
    lastVisit: Ember.computed('customerStatistic', function getter() {
      var date = this.get('customerStatistic.lastVisitDate');
      return date ? (0, _moment.default)(date) : null;
    }),
    addEmail: function addEmail() {
      return this.addItem('emails', 'echo-pro/customer/email', {
        type: _email.emailTypes.get('lastObject').name
      });
    },
    addPhone: function addPhone() {
      return this.addItem('phones', 'echo-pro/customer/phone', {
        type: _phone.phoneTypes.get('lastObject').name
      });
    },
    addAddress: function addAddress() {
      return this.addItem('addresses', 'echo-pro/customer/address', {
        type: _address.addressTypes.get('lastObject').name
      });
    },
    addItem: function addItem(collectionName, modelName, options) {
      var data = {
        locationId: this.get('locationId'),
        createdAt: new Date()
      };
      return this.store.createRecord(modelName, Object.assign(data, options));
    },
    save: function save() {
      var _this = this;

      return this._super().then(function (customer) {
        _this.notifyPropertyChange('isSaved');

        return customer.cleanup();
      });
    },
    cleanup: function cleanup() {
      var _this2 = this;

      var cleanupRelation = function cleanupRelation(name) {
        _this2.get(name).removeObjects(_this2.get(name).filterBy('isNew'));
      };

      cleanupRelation('emails');
      cleanupRelation('phones');
      cleanupRelation('addresses');
      return this;
    },
    contains: function contains(keyword) {
      var term = keyword;

      if (!term) {
        return true;
      }

      term = term.toLowerCase();
      var relations = [].concat(_toConsumableArray(this.get('emails').toArray()), _toConsumableArray(this.get('phones').toArray()), _toConsumableArray(this.get('addresses').toArray()));
      return this.get('fullName').toLowerCase().includes(term) || relations.some(function (relation) {
        return relation.contains(term);
      });
    }
  });

  _exports.default = _default;
});