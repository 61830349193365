define("lh4/serializers/echo-pro/employee-shift", ["exports", "moment", "lh4/serializers/echo-pro/application"], function (_exports, _moment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.shift = [json.startsAt, json.endsAt];
      json.employeeGuid = json.employee;
      json.jobGuid = json.job;
      delete json.startsAt;
      delete json.endsAt;
      delete json.day;
      delete json.selectedDays;
      delete json.employee;
      delete json.job;
      return json;
    },
    normalize: function normalize(modelClass, json) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      var normalized = this._super.apply(this, [modelClass, json].concat(args));

      normalized.data.attributes.startsAt = (0, _moment.default)(json.shift[0]).toDate();
      normalized.data.attributes.endsAt = (0, _moment.default)(json.shift[1]).toDate();
      return normalized;
    },
    attrs: {
      job: {
        embedded: 'always',
        serialize: 'id',
        deserialize: 'records'
      },
      employee: {
        embedded: 'always',
        serialize: 'id',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});