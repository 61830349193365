define("lh4/components/dashboard-widgets/skytab-volume/data", ["exports", "moment", "lh4/mixins/dashboard-widget-mixin", "lh4/formats"], function (_exports, _moment, _dashboardWidgetMixin, _formats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TABLE_MAX_DAYS = 4;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    locale: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['skytab-volume-data'],
    classNameBindings: ['widgetRootClass'],
    skeletonData: {
      days: [{
        date: '2019-07-01',
        volume: '5'
      }, {
        date: '2019-07-02',
        volume: '10'
      }, {
        date: '2019-07-03',
        volume: '7'
      }, {
        date: '2019-07-04',
        volume: '8'
      }, {
        date: '2019-07-05',
        volume: '5'
      }, {
        date: '2019-07-06',
        volume: '2'
      }, {
        date: '2019-07-07',
        volume: '3'
      }, {
        date: '2019-07-08',
        volume: '4'
      }, {
        date: '2019-07-09',
        volume: '6'
      }, {
        date: '2019-07-10',
        volume: '1'
      }, {
        date: '2019-07-11',
        volume: '5'
      }, {
        date: '2019-07-12',
        volume: '10'
      }, {
        date: '2019-07-13',
        volume: '7'
      }, {
        date: '2019-07-14',
        volume: '8'
      }, {
        date: '2019-07-15',
        volume: '5'
      }, {
        date: '2019-07-16',
        volume: '2'
      }, {
        date: '2019-07-17',
        volume: '3'
      }, {
        date: '2019-07-18',
        volume: '4'
      }, {
        date: '2019-07-19',
        volume: '6'
      }, {
        date: '2019-07-20',
        volume: '1'
      }, {
        date: '2019-07-21',
        volume: '5'
      }, {
        date: '2019-07-22',
        volume: '10'
      }, {
        date: '2019-07-23',
        volume: '7'
      }, {
        date: '2019-07-24',
        volume: '8'
      }, {
        date: '2019-07-25',
        volume: '5'
      }, {
        date: '2019-07-26',
        volume: '2'
      }, {
        date: '2019-07-27',
        volume: '3'
      }, {
        date: '2019-07-28',
        volume: '4'
      }, {
        date: '2019-07-29',
        volume: '6'
      }, {
        date: '2019-07-30',
        volume: '1'
      }],
      total: 153
    },
    widgetRootClass: Ember.computed('settings.view', function getter() {
      return this.get('settings.view') === 'simple' ? 'single-value-widget' : 'table-widget';
    }),
    chartData: Ember.computed.map('data.days', function (_ref) {
      var date = _ref.date,
          volume = _ref.volume;
      return {
        date: (0, _moment.default)(date).format(_moment.default.HTML5_FMT.DATE),
        value: parseFloat(volume)
      };
    }),
    tableData: Ember.computed('data.days.[]', 'expanded', function getter() {
      var _this = this;

      var days = this.data.days.map(function (d) {
        return {
          volume: d.volume,
          // TODO  LH-27133 remove weird date hack, see ticket for more details
          date: (0, _moment.default)(d.date).tz(_this.intl.formats.date.SHORT.timeZone, true)
        };
      }).reverse();
      return {
        days: this.expanded ? days : days.slice(0, TABLE_MAX_DAYS)
      };
    }),
    chartOptions: Ember.computed('location', function getter() {
      var _this2 = this;

      var valueTemplate = this.locale.currencyTemplate('[[value]]');
      return {
        balloonText: valueTemplate,
        categoryField: 'date',
        dataDateFormat: _moment.default.HTML5_FMT.DATE,
        parseDates: true,
        valueAxesOptions: {
          precision: 2,
          labelFunction: function labelFunction(value) {
            return _this2.locale.currencyFormat(value);
          }
        },
        valueField: 'value',
        chartCursor: {
          categoryBalloonFunction: function categoryBalloonFunction(value) {
            return _this2.locale.formatDateForAmChart(value, _this2.useIntlDates, _formats.default.date.MEDIUM);
          }
        },
        categoryAxis: {
          labelFunction: function labelFunction(value) {
            return _this2.locale.formatDateForAmChart(value, _this2.useIntlDates, _formats.default.date.MEDIUM_NO_YEAR);
          }
        }
      };
    })
  });

  _exports.default = _default;
});