define("lh4/mirage/serializers/epro-modifier-sets", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    primaryKey: 'guid'
  });

  _exports.default = _default;
});