define("lh4/components/file-drop", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['file-drop'],
    hoverClass: 'hover',
    file: null,
    onChange: null,
    didInsertElement: function didInsertElement() {
      var handleDrag = Ember.run.bind(this, this.handleDrag);
      var handleDrop = Ember.run.bind(this, this.handleDrop);
      var handleChange = Ember.run.bind(this, this.handleChange);
      this.$().on({
        dragenter: handleDrag,
        dragover: handleDrag,
        dragleave: handleDrag,
        drop: handleDrop
      });
      this.$('input:file').on('change', handleChange);
      var accept = this.get('accept');

      if (accept) {
        this.$('input:file').attr('accept', accept);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var handleDrag = Ember.run.bind(this, this.handleDrag);
      var handleDrop = Ember.run.bind(this, this.handleDrop);
      var handleChange = Ember.run.bind(this, this.handleChange);
      this.$().off({
        dragenter: handleDrag,
        dragover: handleDrag,
        dragleave: handleDrag,
        drop: handleDrop
      });
      this.$('input:file').off('change', handleChange);
    },
    click: function click(event) {
      if (event.target.type !== 'file') {
        this.$('input:file').click();
      }
    },
    handleDrag: function handleDrag(event) {
      if (event.type === 'dragover' || event.type === 'dragenter') {
        this.startHover();
      } else if (!this.isChild(event.target)) {
        this.stopHover();
      }

      return false;
    },
    handleDrop: function handleDrop(event) {
      this.stopHover();
      this.handleFile(event.dataTransfer.files[0]);
      return false;
    },
    handleChange: function handleChange(event) {
      this.handleFile(event.target.files[0] || null);
    },
    handleFile: function handleFile(file) {
      this.setFile(file);
    },
    setFile: function setFile(file) {
      this.set('file', file);
      this.sendAction('onChange', file);
    },
    browse: function browse(event) {
      if (event.target.type !== 'file') {
        this.$('input:file').click();
      }
    },
    startHover: function startHover() {
      this.$().addClass(this.get('hoverClass'));
    },
    stopHover: function stopHover() {
      this.$().removeClass(this.get('hoverClass'));
    },
    isChild: function isChild(element) {
      var source = (0, _jquery.default)(this.get('element'));
      var parent = source.children(':nth-child(2)')[0];
      return _jquery.default.contains(parent, element);
    }
  });

  _exports.default = _default;
});