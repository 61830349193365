define("lh4/components/dashboard-widgets/ip-transactions/data", ["exports", "lh4/mixins/dashboard-widget-mixin", "ember-changeset", "moment", "lh4/enums/ip-date-ranges", "lh4/enums/ip-risk-statuses"], function (_exports, _dashboardWidgetMixin, _emberChangeset, _moment, _ipDateRanges, _ipRiskStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TABS = {
    NEED_REVIEW: 'Need Review',
    LATEST: 'Latest'
  };
  var REVIEW_TAB_STATUSES = [_ipRiskStatuses.default.REVIEW];
  var DATE_FORMAT = 'YYYY-MM-DD';

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['ip-transactions-data', 'table-widget'],
    tabs: [TABS.LATEST, TABS.NEED_REVIEW],
    widgetSlug: 'ip-transactions',
    noDataMessage: "There isn't any information available for the timeframe selected, please choose another timeframe.",
    sessionStorage: Ember.inject.service('session-storage'),
    media: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var sessionStorageData = this.sessionStorage.get(this.widgetSlug);
      this.selectedRange = sessionStorageData ? sessionStorageData === null || sessionStorageData === void 0 ? void 0 : sessionStorageData.range : _ipDateRanges.default.TODAY.VALUE;
      this.activeTab = sessionStorageData ? sessionStorageData === null || sessionStorageData === void 0 ? void 0 : sessionStorageData.activeTab : TABS.LATEST;
    },
    skeletonData: {
      type: 'Transactions',
      data: {
        reviewTransactions: [{
          date: '2022-05-05T10:00:00.000Z',
          amount: 89.00,
          cardType: 'AMEX',
          cardNumber: '5319'
        }, {
          date: '2022-05-05T09:55:00.000Z',
          amount: 189.00,
          cardType: 'VISA',
          cardNumber: '8888'
        }],
        latestTransactions: [{
          date: '2022-05-05T12:46:00.000Z',
          cardType: 'VISA',
          cardNumber: '5897',
          amount: 25.00,
          riskStatus: 'Approved'
        }, {
          date: '2022-05-05T12:42:00.000Z',
          cardType: 'MASTERCARD',
          cardNumber: '4897',
          amount: 25.00,
          riskStatus: 'Verified'
        }]
      }
    },
    rangeOptions: [_ipDateRanges.default.TODAY, _ipDateRanges.default.YESTERDAY],
    linkProperties: Ember.computed('activeTab', 'selectedRange', 'data', function linkProperties() {
      var riskStatus = this.activeTab === TABS.NEED_REVIEW ? REVIEW_TAB_STATUSES : [];
      var riskStatusQueryParams = JSON.stringify(riskStatus);

      var _this$get = this.get('data'),
          startDate = _this$get.startDate;

      return {
        riskStatusQueryParams: riskStatusQueryParams,
        dateQueryParams: {
          start: _moment.default.utc(startDate).format(DATE_FORMAT),
          end: _moment.default.utc(startDate).format(DATE_FORMAT)
        },
        path: 'authenticated.internet-payments.transactions'
      };
    }),
    actions: {
      onRangeSelect: function onRangeSelect(range) {
        this.setSettings(new _emberChangeset.default({
          range: range
        }));
        this.sessionStorage.update(this.widgetSlug, {
          range: range
        });
      },
      onTabChange: function onTabChange(tab) {
        this.sessionStorage.update(this.widgetSlug, {
          activeTab: tab
        });
      }
    }
  });

  _exports.default = _default;
});