define("lh4/controllers/authenticated/epro/workforce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    permissions: Ember.inject.service(),
    actions: {
      activateWorkforce: function activateWorkforce() {
        this.transitionToRoute('authenticated.epro.workforce.terms-and-conditions');
      }
    }
  });

  _exports.default = _default;
});