define("lh4/routes/authenticated/locations/location/echo-pro/manage-pos/customers/edit", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    redirect: function redirect(param) {
      this.replaceWith('authenticated.customer-database.edit', param.customerId);
    }
  });

  _exports.default = _default;
});