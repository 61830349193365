define("lh4/serializers/supply-orders/product", ["exports", "lh4/serializers/supply-orders/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      products: {
        serialize: 'id',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});