define("lh4/helpers/stringify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringify = stringify;
  _exports.default = void 0;

  /**
   * Wrapper around JSON's stringify function
   * @param {Array<Object>} params - function parameters
   * @returns {String} - first parameter converted to a JSON string
   */
  function stringify(params
  /* , hash */
  ) {
    return JSON.stringify(params[0]);
  }

  var _default = Ember.Helper.helper(stringify);

  _exports.default = _default;
});