define("lh4/components/datetime-picker-input", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: '',
    label: '',
    date: null,
    time: '12:00AM',
    onChange: null,
    placement: 'left',
    yearRange: '50',
    isError: false,
    minDate: null,
    mergeParts: function mergeParts(date, timePart) {
      var time = (0, _moment.default)(timePart, 'hh:mmA');
      var value = (0, _moment.default)(date).hour(time.hour()).minute(time.minute()).second(0);
      return value.toISOString();
    },
    updateValue: function updateValue() {
      var date = this.get('date');
      var time = this.get('time');

      if (!date || !time) {
        return;
      }

      var value = this.mergeParts(date, time);
      this.set('value', value);
      this.sendAction('onChange', value);
    },
    valueChanged: Ember.observer('value', function callback() {
      var value = this.get('value');

      if (!value) {
        return;
      }

      var momentValue = (0, _moment.default)(value);
      this.set('date', momentValue.toDate());
      this.set('time', momentValue.format('hh:mmA'));
    }).on('init'),
    actions: {
      dateSelected: function dateSelected(date) {
        this.send('partSelected', 'date', date);
      },
      timeSelected: function timeSelected(time) {
        this.send('partSelected', 'time', time);
      },
      partSelected: function partSelected(key, value) {
        if (!value) {
          return;
        }

        this.set(key, value);
        this.updateValue();
      }
    }
  });

  _exports.default = _default;
});