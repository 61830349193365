define("lh4/routes/authenticated/epro/menu/price-groups/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    modelName: 'corporateGroup',
    model: function model(_ref) {
      var priceGroupId = _ref.priceGroupId;
      return Ember.RSVP.hash({
        corporateGroup: this.store.findRecord('corporate-group', priceGroupId, {
          adapterOptions: {
            corpId: this.location.model.corpId
          }
        })
      });
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
      // that doesn't exist in the price groups
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'Edit',
        'Entry ID': model.corporateGroup.id,
        'Menu Component': 'Price Group'
      });
    }
  });

  _exports.default = _default;
});