define("lh4/templates/components/ui/popup/error-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wRMPmkwJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"i\",true],[10,\"class\",\"popup-trigger red exclamation triangle icon\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui error popup\"],[8],[0,\"\\n\\t\"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/popup/error-popup.hbs"
    }
  });

  _exports.default = _default;
});