define("lh4/components/support/contact/support-ticket-details", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid', 'support-ticket-details'],
    commentsSortedByCreatedAt: Ember.computed.sort('model.ticket.comments', function (a, b) {
      return (0, _moment.default)(b.get('createdAt'), 'hh:mmA').diff((0, _moment.default)(a.get('createdAt'), 'hh:mmA'));
    }).property('model.ticket.comments.@each.createdAt', 'model.ticket.comments.[]'),
    actions: {
      postComment: function postComment() {
        this.sendAction('postComment');
      }
    }
  });

  _exports.default = _default;
});