define("lh4/components/epro/menu/reorder-modifiers-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    azSorted: false,
    azModifiers: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // re-set this property on every render & re-render
      // because opening modal doesn't do anything after
      // initial render was performed and changeset was changed in route when 'Save & duplicate'
      // (cloning) is performed or another edit tray was opened


      this.set('azModifiers', this.modifiers);
    },
    willRender: function willRender() {
      // access computed property to trigger changes
      // this updates UI on initial render and re-render after changeset is changed in route when 'Save & duplicate'
      // (cloning) is performed or another edit tray was opened
      // eslint considers this oddity to be an unused expresssion and there doesn't seem to be an obvious
      // way to silence the problem without making this really weird. ignoring for now.

      /* eslint-disable no-unused-expressions */
      this.azModifiers;
    },
    actions: {
      modalDeny: function modalDeny() {
        this.modalDeny();
      },
      onSave: function onSave() {
        this.onModalSave(this.azModifiers);
      },
      headerToggle: function headerToggle(sortAToZ) {
        if (sortAToZ) {
          this.set('azSorted', true);
          this.set('azModifiers', this.modifiers.sortBy('name'));
        } else {
          this.set('azSorted', false);
        }
      },
      onDragStart: function onDragStart() {
        this.set('azSorted', false);
      }
    }
  });

  _exports.default = _default;
});