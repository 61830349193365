define("lh4/mirage/routes/auth", ["exports", "lh4/config/environment", "ember-cli-mirage"], function (_exports, _environment, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageAuthServer;

  function setMirageAuthServer(server) {
    server.post('/auth/authenticate', function (schema, request) {
      var login = JSON.parse(request.requestBody);

      if (login.email === 'tschnaars@harbortouch.com' && login.password === 'test1') {
        return _environment.default.authResponse;
      }

      if (login.email === '2fa@shift4.com' && login.password === 'test1') {
        if (login.otpCode) {
          return new _emberCliMirage.default.Response(400, {
            'Content-Type': 'application/json'
          }, {
            error: {
              code: 400,
              message: 'requested code and secret do not match'
            }
          });
        }

        if (login.recoveryCode) {
          return new _emberCliMirage.default.Response(400, {
            'Content-Type': 'application/json'
          }, {
            error: {
              code: 400,
              message: 'no valid recovery codes found'
            }
          });
        }

        return new _emberCliMirage.default.Response(422, {
          'Content-Type': 'application/json'
        }, {
          error: {
            code: 449,
            message: 'retry with two factor code'
          }
        });
      }

      return new _emberCliMirage.default.Response(401, {
        'Content-Type': 'application/json'
      }, {
        error: {
          code: 401,
          message: 'invalid credentials'
        }
      });
    });
    server.post('/auth/sso', function (schema, request) {
      var requestBody = JSON.parse(request.requestBody);

      if (requestBody.token === 'test-token-for-sso') {
        return new _emberCliMirage.default.Response(200, {
          'Content-Type': 'application/json'
        }, {
          aId: '23d22a88-5555-4444-98d6-decd395de750',
          email: 'tschnaars@harbortouch.com',
          firstName: 'Tom',
          is2fa: false,
          lastName: 'Schnaars',
          parentUserId: null,
          permissions: [],
          // eslint-disable-next-line max-len
          token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkZha2Vyb28gRmFrZXJpc2giLCJhZG1pbiI6dHJ1ZSwianRpIjoiOTM2YjgwNmYtODNjMi00Yzk2LTgyZTctNGQ3YzU3MzlhZmM2IiwiaWF0IjoxNjAyNzg1OTE0LCJleHAiOjE2MDI3ODk1ODl9.CyqinzRA4VgicF1wHSQ9gk2QyAAvzc6C0_IA2UdudE4'
        });
      }

      return new _emberCliMirage.default.Response(401, {
        'Content-Type': 'application/json'
      }, {
        error: {
          code: 401,
          message: 'invalid credentials'
        }
      });
    });
  }
});