define("lh4/abilities/echo-pro/workforce", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _permissions, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DELINQUENT_SETTING = 'isDelinquent';
  var SOCIAL_SCHEDULES = 'social-schedules';

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    session: Ember.inject.service(),
    location: Ember.inject.service(),
    permissions: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    canManageEmployeesAndJobs: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canManageEmployeesAndJobs() {
      return this.hasProduct('echo-pro') && this.hasService('social-schedules') && this.hasLocationPermission(_permissions.default.HBR.EMPLOYEES_AND_JOBS);
    }),
    canManageTimeclock: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canManageTimeclock() {
      return this.hasProduct('echo-pro') && this.hasService('social-schedules') && this.hasLocationPermission(_permissions.default.HBR.TIMECLOCK);
    }),
    canManageScheduler: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canManageScheduler() {
      return this.hasProduct('echo-pro') && this.hasService('social-schedules') && this.hasLocationPermission(_permissions.default.HBR.EMPLOYEE_SCHEDULING);
    }),
    canManageWorkforce: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canManageWorkforce() {
      var _this$permissions$get, _this$permissions$get2, _this$permissions$get3;

      var isDelinquent = (_this$permissions$get = this.permissions.getService(SOCIAL_SCHEDULES)) === null || _this$permissions$get === void 0 ? void 0 : (_this$permissions$get2 = _this$permissions$get.locationService) === null || _this$permissions$get2 === void 0 ? void 0 : (_this$permissions$get3 = _this$permissions$get2.configuration) === null || _this$permissions$get3 === void 0 ? void 0 : _this$permissions$get3[DELINQUENT_SETTING];
      return (this.canManageEmployeesAndJobs || this.canManageTimeclock || this.canManageScheduler) && !isDelinquent;
    }),
    canViewLighthouseReports: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewLighthouseReports() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.WORKFORCE_REPORT_VISIBILITY];
    }),
    canManageTipSharing: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canManageTipSharing() {
      return this.hasProduct('echo-pro') && this.hasLocationPermission(_permissions.default.HBR.EMPLOYEES_AND_JOBS) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.TIP_SHARING_VISIBILITY];
    })
  });

  _exports.default = _default;
});