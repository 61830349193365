define("lh4/enums/transaction-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TRANSACTION_TYPE_DISPLAY_VALUE = _exports.TRANSACTION_TYPE = void 0;
  var TRANSACTION_TYPE = Object.freeze({
    AUTH: 'AUTH',
    INFO: 'INFO',
    REFUND: 'REFUND',
    SALE: 'SALE',
    VOID: 'VOID'
  });
  _exports.TRANSACTION_TYPE = TRANSACTION_TYPE;
  var TRANSACTION_TYPE_DISPLAY_VALUE = Object.freeze({
    AUTH: 'Auth',
    INFO: 'Non-Sale/Info',
    REFUND: 'Refund',
    SALE: 'Sale',
    VOID: 'Void'
  });
  _exports.TRANSACTION_TYPE_DISPLAY_VALUE = TRANSACTION_TYPE_DISPLAY_VALUE;
});