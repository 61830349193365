define("lh4/templates/components/internet-payments/ui/search/slideout-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ld00xiUJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"id\",[23,0,[\"id\"]]],[11,\"class\",[29,[\"slideout-search \",[28,\"if\",[[23,0,[\"isSearchOpen\"]],\"open\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"span\",false],[12,\"class\",\"search-button circular-button secondary\"],[3,\"on\",[\"click\",[23,0,[\"onOpen\"]]]],[8],[0,\"\\n\\t\\t\"],[1,[22,\"ui/icons/internet-payments/search\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"list-search-container\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"internet-payments/ui/search/list-search\",null,[[\"class\",\"autocomplete\",\"searchTerm\",\"placeholder\",\"onUpdated\",\"onClose\",\"focusIn\",\"focusOut\"],[[23,0,[\"args\",\"class\"]],[23,0,[\"args\",\"autocomplete\"]],[23,0,[\"args\",\"searchTerm\"]],[23,0,[\"args\",\"placeholder\"]],[23,0,[\"args\",\"onUpdated\"]],[23,0,[\"onClose\"]],[23,0,[\"onFocus\"]],[23,0,[\"onBlur\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/search/slideout-search.hbs"
    }
  });

  _exports.default = _default;
});