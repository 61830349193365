define("lh4/templates/components/ui/client-pagination-full-prev-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4xe5q93Y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"topContent\"],[[28,\"component\",[\"ui/shared/yield-basic-no-tag\"],[[\"data\"],[[24,[\"collectionPaged\"]]]]]]]]]],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui basic clearing segment client-pagination-full-buttons\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"float-right\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"ui/client-pagination-buttons-prev-next\",null,[[\"hasPrevPage\",\"hasNextPage\",\"onSelectPreviousPage\",\"onSelectNextPage\"],[[24,[\"hasPrevPage\"]],[24,[\"hasNextPage\"]],[28,\"action\",[[23,0,[]],\"selectPreviousPage\"],null],[28,\"action\",[[23,0,[]],\"selectNextPage\"],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1,[[28,\"hash\",null,[[\"bottomContent\"],[[28,\"component\",[\"ui/shared/yield-basic-no-tag\"],[[\"data\"],[[24,[\"collectionPaged\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/client-pagination-full-prev-next.hbs"
    }
  });

  _exports.default = _default;
});