define("lh4/routes/authenticated/social-media/accounts/link", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable camelcase */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      state: {
        refreshModel: true
      },
      code: {
        refreshModel: true
      },
      oauth_token: {
        refreshModel: true
      },
      oauth_verifier: {
        refreshModel: true
      },
      error: {
        refreshModel: true
      },
      denied: {
        refreshModel: true
      },
      error_reason: {
        refreshModel: true
      },
      error_description: {
        refreshModel: true
      }
    },
    facebook: Ember.inject.service('social-media/facebook'),
    twitter: Ember.inject.service('social-media/twitter'),
    accountApi: Ember.inject.service('social-media/account-api'),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _transition$to;

      var _this$accountApi$chec = this.accountApi.checkRedirectErrors(transition === null || transition === void 0 ? void 0 : (_transition$to = transition.to) === null || _transition$to === void 0 ? void 0 : _transition$to.queryParams),
          errorMessage = _this$accountApi$chec.errorMessage,
          redirectOrigin = _this$accountApi$chec.redirectOrigin;

      if (errorMessage) {
        transition.abort();
        this.flashMessagesHelper.pushMessage(errorMessage, 'error');

        if (redirectOrigin) {
          return window.location.replace(redirectOrigin);
        }

        return this.transitionTo('authenticated.social-media.accounts');
      }

      var adjustedUrl = this.accountApi.adjustRedirectUrlHash(window.location);

      if (adjustedUrl) {
        transition.abort();
        window.location.href = adjustedUrl;
      }

      return false;
    },
    // Facebook returns 'code'. Twitter 'oauth_token' + 'oauth_verifier'
    model: function model(_ref) {
      var _this = this;

      var accountId = _ref.state,
          code = _ref.code,
          oauth_token = _ref.oauth_token,
          oauth_verifier = _ref.oauth_verifier;

      if (accountId && (code || oauth_token && oauth_verifier)) {
        var redirectOrigin = this.accountApi.getRedirectOrigin(accountId);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (code) {
            return resolve(_this.facebook.relinkAccount(accountId, code));
          }

          if (oauth_token && oauth_verifier) {
            return resolve(_this.twitter.relinkAccount(accountId, oauth_token, oauth_verifier));
          }

          return reject('Could not determine account type, please try relinking account again');
        }).then(function (res) {
          if (res.account.isConfiguredProperly === true) {
            _this.flashMessagesHelper.pushMessage('Successfully re-linked account', 'success');
          } else {
            _this.flashMessagesHelper.pushMessage('Failed to re-link account', 'error');
          } // refresh account state


          return _this.store.findRecord('social-media/account', accountId);
        }).then(function () {
          if (redirectOrigin) {
            return window.location.replace(redirectOrigin);
          }

          return _this.transitionTo('authenticated.social-media.accounts.edit', accountId);
        }).catch(function (err) {
          _this.errorHandler.handleResponseError(err);

          if (redirectOrigin) {
            return window.location.replace(redirectOrigin);
          }

          return _this.transitionTo('authenticated.social-media.accounts');
        });
      }

      return {
        accountType: window.localStorage.getItem('social-media-type'),
        locations: this.modelFor('authenticated.social-media').locations,
        account: {
          name: '',
          locations: []
        },
        accountId: accountId,
        code: code,
        oauth_token: oauth_token,
        oauth_verifier: oauth_verifier
      };
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // do not use sticky query params
        // https://guides.emberjs.com/v3.15.0/routing/query-params/#toc_sticky-query-param-values
        controller.setProperties({
          accountId: undefined,
          code: undefined,
          oauth_token: undefined,
          oauth_verifier: undefined,
          error: undefined,
          denied: undefined,
          error_reason: undefined,
          error_description: undefined
        });
      }
    }
  });
  /* eslint-enable camelcase */


  _exports.default = _default;
});