define("lh4/routes/authenticated/customer-engagement/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    location: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        settings: this.store.findRecord('customer-engagement/setting', this.location.model.id),
        social: this.store.findAll('social-media/account')
      });
    }
  });

  _exports.default = _default;
});