define("lh4/templates/authenticated/internet-payments/insights/chargeback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6VeBp05V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"insights-coming-soon\"],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[8],[0,\"Coming Soon.\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"Actionable data at your fingertips.\"],[9],[0,\"\\n\\t\"],[7,\"img\",true],[10,\"src\",\"/img/internet-payments/coming-soon-insights.png\"],[10,\"alt\",\"Coming Soon\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/insights/chargeback.hbs"
    }
  });

  _exports.default = _default;
});