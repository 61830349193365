define("lh4/serializers/launch-control/module", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'moduleId',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'launch-control/module';
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadData = {
        modules: payload.modules
      };
      return this._super(store, primaryModelClass, payloadData, id, requestType);
    }
  });

  _exports.default = _default;
});