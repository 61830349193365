define("lh4/adapters/launch-control/workflow", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      var locationId = snapshot.adapterOptions.locationId;
      return "".concat(this.host, "/").concat(this.namespace, "/launch-control/workflows/").concat(locationId);
    }
  });

  _exports.default = _default;
});