define("lh4/components/support/contact/support-ticket-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    groupOptions: Ember.computed.map('groups', function (_ref) {
      var name = _ref.name,
          value = _ref.id;
      return {
        name: name,
        value: value
      };
    }),
    locationOptions: Ember.computed.map('locations', function (_ref2) {
      var merchantId = _ref2.merchantId,
          name = _ref2.name,
          value = _ref2.id;
      return {
        name: "".concat(merchantId, " - ").concat(name),
        value: value
      };
    })
  });

  _exports.default = _default;
});