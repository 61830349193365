define("lh4/routes/authenticated/epro/timeclock/settings", ["exports", "ember-changeset", "lh4/utils/validations"], function (_exports, _emberChangeset, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TRANSITION_MESSAGE = 'You have unsaved changes. Are you sure you want to leave this page?';

  var _default = Ember.Route.extend({
    location: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var _buildChangesetDepend = (0, _validations.buildChangesetDependentValidations)(model.settings.accounting.content),
          validateFn = _buildChangesetDepend.validateFn,
          validationMap = _buildChangesetDepend.validationMap;

      var accountingChangeset = new _emberChangeset.default(model.settings.accounting.content, validateFn, validationMap, {
        skipValidate: true
      });
      var businessChangeset = new _emberChangeset.default(model.settings.business.content);
      controller.set('changeset', accountingChangeset);
      controller.set('businessChangeset', businessChangeset);
    },
    model: function model() {
      return Ember.RSVP.hash({
        settings: this.store.queryRecord('echo-pro/setting', {
          location: this.location.model.id,
          type: ['accounting', 'business']
        })
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        if ((this.controller.businessChangeset.isDirty || this.controller.changeset.isDirty) && !window.confirm(TRANSITION_MESSAGE)) {
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});