define("lh4/components/reservations/reservation-link", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    link: Ember.computed(function getter() {
      return "".concat(_environment.default.hosts.reservations).concat(this.get('channel'));
    })
  });

  _exports.default = _default;
});