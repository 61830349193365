define("lh4/mirage/routes/transactions/revenue-centers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMirageTransactionsServersServer;

  function getMirageTransactionsServersServer(server) {
    server.get('/transactions/revenue-centers', function () {
      return {
        revenueCenters: ['Takeout', 'Delivery', 'Bar Tab']
      };
    });
  }
});