define("lh4/components/customer-engagement/campaign-summary", ["exports", "moment", "lh4/utils/cron"], function (_exports, _moment, _cron) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line require-jsdoc
  function buildInviteDisplayName(campaign) {
    var count = campaign.get('customerVisitCount');
    var lastVisit = campaign.get('customerLastVisitWithinDays');
    var notVisited = campaign.get('customerNotVisitedWithinDays');
    return "Customer has visited the place at least ".concat(count, " times in ").concat(lastVisit / 30, " months, but have not") + " returned in ".concat(notVisited / 7, " weeks");
  }

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui', 'segments'],
    campaign: null,
    location: null,
    readOnly: false,
    media: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    statusIsActive: Ember.computed('campaign.status', function getter() {
      return this.get('campaign.status') !== 'Inactive';
    }),
    showActiveToggle: Ember.computed('campaign.isNew', 'campaign.totalSent', 'campaign.status', function getter() {
      return !this.get('campaign.isNew') && this.get('campaign.status') !== 'Complete' && this.get('campaign.totalSent');
    }),
    scheduledAt: Ember.computed('campaign.scheduleTime', 'campaign.scheduleRepeat', 'campaign.scheduleRepeatEnd', function getter() {
      var time = this.get('campaign.scheduleTime');
      var cronExpression = this.get('campaign.scheduleRepeat');
      var repeatEnd = this.get('campaign.scheduleRepeatEnd');

      if (time) {
        return (0, _moment.default)(time).format('MMMM DD, YYYY');
      }

      var _calculateFrequency = (0, _cron.calculateFrequency)(cronExpression),
          frequency = _calculateFrequency.frequency,
          days = _calculateFrequency.days;

      return (0, _cron.getFormattedFrequencyString)(frequency, repeatEnd, days);
    }),
    rule: Ember.computed('campaign', function getter() {
      switch (this.get('campaign.type')) {
        case 'Survey':
          if (this.get('campaign.surveyFrequencyDays') === 0) {
            return 'Within 24 hours of each visit';
          }

          return "No more than once per ".concat(this.get('campaign.surveyFrequencyDays'), " days");

        case 'Invite':
          return buildInviteDisplayName(this.get('campaign'));

        case 'Welcome':
          return 'Within 24 hours of the first customer visit (completed ticket)';

        default:
          return '';
      }
    }),
    sentDate: Ember.computed('campaign.lastSent', 'campaign.lastSent', function getter() {
      var _this$campaign, _this$campaign2;

      return ((_this$campaign = this.campaign) === null || _this$campaign === void 0 ? void 0 : _this$campaign.lastSent) || ((_this$campaign2 = this.campaign) === null || _this$campaign2 === void 0 ? void 0 : _this$campaign2.scheduleTime);
    }),
    minDate: Ember.computed(function () {
      return (0, _moment.default)().toDate();
    }),
    actions: {
      onAfterSelect: function onAfterSelect(instance, date) {
        this.set('campaign.scheduleTime', date);
      },
      onTimeChange: function onTimeChange(instance, date) {
        this.set('campaign.scheduleTime', date);
      },
      onMobileChange: function onMobileChange(date) {
        this.set('campaign.scheduleTime', date);
      },
      toggleActive: function toggleActive(checked) {
        var _this = this;

        this.set('statusIsActive', checked);

        if (!this.get('statusIsActive')) {
          this.set('campaign.status', 'Inactive');
          this.get('campaign').save();
          return;
        }

        if (this.get('campaign.type') === 'Promo') {
          if ((0, _moment.default)().isAfter((0, _moment.default)(this.get('campaign.scheduleTime')).format())) {
            this.flashMessagesHelper.pushMessage('Schedule time must be set in the future', 'error');
            Ember.run.later(function () {
              _this.set('statusIsActive', false);
            }, 500);
            return;
          }
        }

        this.set('campaign.status', 'Scheduled');
        this.get('campaign').save();
      }
    }
  });

  _exports.default = _default;
});