define("lh4/components/edit-merchant-ids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    merchantId: null,
    merchantIdConfirm: null,
    confirmDeletion: Ember.computed('merchantId', 'merchantIdConfirm', function getter() {
      if (this.get('merchantId') === this.get('merchantIdConfirm')) {
        return '';
      }

      return ' disabled';
    }),
    actions: {
      deleteMerchantId: function deleteMerchantId(id) {
        this.set('merchantId', id);
        this.$('.ui.modal').modal('show');
      }
    }
  });

  _exports.default = _default;
});