define("lh4/mirage/factories/support-ticket", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    subject: function subject() {
      return _faker.default.lorem.paragraph();
    },
    assignee: function assignee() {
      return _faker.default.company.companyName();
    },
    status: function status() {
      var statuses = ['Open', 'In Progress', 'Resolved', 'Waiting on Customer', 'Duplicate'];
      return statuses[Math.floor(Math.random() * statuses.length)];
    },
    dateUpdated: '2017-02-21T15:03:00.000Z',
    locationId: function locationId(i) {
      // Fix off by one issue.
      return i + 1;
    },
    createdAt: '2017-02-21T15:03:00.000Z',
    customerEmail: function customerEmail() {
      return _faker.default.internet.email();
    },
    customerId: '00000',
    description: function description() {
      return _faker.default.lorem.paragraph();
    }
  });

  _exports.default = _default;
});