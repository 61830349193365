define("lh4/mixins/routes/previous-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController: function setupController(controller, model, transition) {
      var _transition$from;

      this._super(controller, model, transition);

      if (transition !== null && transition !== void 0 && (_transition$from = transition.from) !== null && _transition$from !== void 0 && _transition$from.name) {
        controller.set('previousRoute', transition.from.name);
      }
    }
  });

  _exports.default = _default;
});