define("lh4/routes/authenticated/epro/invoices/sso", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    redirectUri: '/',
    pageTitle: 'Invoices',
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var stateToken = transition.to.queryParams.stateToken;
      this.ajax.post('/api/v1/s4-invoices/app/sso/', {
        data: {
          locationId: this.location.model.id,
          stateToken: stateToken
        }
      }).then(function (res) {
        return window.location.replace(res.url);
      }).catch(function () {
        var _transition$from;

        _this.flashMessagesHelper.pushMessage("Something went wrong. Failed to navigate to the ".concat(_this.pageTitle, " page."));

        var redirectUrl = transition === null || transition === void 0 ? void 0 : (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : _transition$from.name;

        _this.replaceWith(redirectUrl || 'authenticated.dashboard2');
      });
    }
  });

  _exports.default = _default;
});