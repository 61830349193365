define("lh4/routes/authenticated/locations/location/hospitality/customize-menu/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    redirect: function redirect(model) {
      var first = model.menuCategories.filter(function (x) {
        return x.get('locationId').toString() === model.location.get('id');
      }).get('firstObject');

      if (first) {
        this.transitionTo('authenticated.locations.location.hospitality.customize-menu.category.edit-category', model.location.get('id'), first.get('rowguid'));
      }
    }
  });

  _exports.default = _default;
});