define("lh4/controllers/authenticated/locations/location/hospitality/customize-menu/category/edit-category/item/create-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CUSTOMIZE_MENU_ROUTE = 'authenticated.locations.location.hospitality.customize-menu';

  var _default = Ember.Controller.extend({
    logger: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    categoryRowGuid: null,
    isLoading: false,
    weightDisabled: Ember.computed('model.newItem.isSoldByWeight', function getter() {
      return this.model.newItem.isSoldByWeight ? '' : 'disabled';
    }),
    setLoading: Ember.computed('isLoading', function getter() {
      return this.isLoading ? 'loading' : '';
    }),
    tabs: ['Item Details', 'Categories', 'Choice Sets', 'Printers', 'Options'],
    actions: {
      save: function save() {
        var _this = this;

        this.set('isLoading', true);
        this.flashMessages.clearMessages();
        this.model.newItem.set('categoryRowGuid', this.categoryRowGuid);
        this.model.newItem.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (!validations.get('isValid')) {
            return Ember.RSVP.reject(validations);
          }

          _this.model.newItem.save().then(function (item) {
            _this.flashMessages.success('Item Successfully Created!', {
              class: 'ui success message'
            });

            _this.transitionToRoute(CUSTOMIZE_MENU_ROUTE);

            _this.logger.log('Item Successfully Created!');

            _this.set('isLoading', false);

            _this.transitionToRoute("".concat(CUSTOMIZE_MENU_ROUTE, ".category.edit-category.item.edit-item"), item.categoryRowGuid, item.rowguid);
          }, function (err) {
            _this.logger.log("".concat(err, " error occurred when saving item"));

            _this.set('isLoading', false);

            _this.flashMessages.danger('Error connecting to terminal. Please make sure your unit is online.', {
              class: 'ui negative message'
            });
          });

          return false;
        }).catch(function (validations) {
          _this.set('isLoading', false);

          validations.forEach(function (_ref2) {
            var message = _ref2.message;

            if (message) {
              _this.flashMessages.danger("".concat(message, "."), {
                class: 'ui negative message'
              });
            }
          });
        });
      },
      clear: function clear() {
        this.transitionToRoute(CUSTOMIZE_MENU_ROUTE);
      }
    }
  });

  _exports.default = _default;
});