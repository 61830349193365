define("lh4/models/supply-orders/address", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    addressId: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    company: (0, _attr.default)('string'),
    street1: (0, _attr.default)('string'),
    street2: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zip: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    extendedZip: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    isCommercial: (0, _attr.default)('boolean'),
    isValid: (0, _attr.default)('boolean'),
    candidates: (0, _attr.default)()
  });

  _exports.default = _default;
});