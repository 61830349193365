define("lh4/routes/authenticated/updates/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    modelName: 'menuVersion',
    beforeModel: function beforeModel() {
      if (!this.location.model.isCorpHq) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
    },
    model: function model(_ref) {
      var updateId = _ref.updateId;
      var corpId = this.location.model.corpId;
      return Ember.RSVP.hash({
        menuVersionList: this.store.peekAll('echo-pro/menu-version', {
          corporation: corpId,
          status: 'pending'
        }),
        menuVersion: this.store.peekRecord('echo-pro/menu-version', updateId)
      });
    },
    afterModel: function afterModel(model) {
      if (model.menuVersion.menuLocationVersions.some(function (location) {
        return location.status === 'complete';
      })) {
        model.menuVersion.setProperties({
          hasCompleteUpdate: true
        });
      }

      if (model.menuVersion.menuLocationVersions.every(function (location) {
        return location.status === 'error';
      })) {
        model.menuVersion.setProperties({
          hasAllUpdatesFailed: true
        });
      }

      if (model.menuVersion.hasCompleteUpdate || model.menuVersion.hasAllUpdatesFailed) {
        model.menuVersion.setProperties({
          isUpdateReadOnly: true
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });

  _exports.default = _default;
});