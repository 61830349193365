define("lh4/transforms/time", ["exports", "ember-data/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DEFAULT_OPTIONS = {
    displayFormat: 'hh:mmA',
    sourceFormat: null
  };

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (serialized == null || serialized === undefined) {
        return serialized;
      }

      var _DEFAULT_OPTIONS$opti = _objectSpread(_objectSpread({}, DEFAULT_OPTIONS), options),
          sourceFormat = _DEFAULT_OPTIONS$opti.sourceFormat,
          displayFormat = _DEFAULT_OPTIONS$opti.displayFormat;

      var date = sourceFormat ? (0, _moment.default)(serialized, sourceFormat) : (0, _moment.default)(serialized);
      return date.format(displayFormat);
    },
    serialize: function serialize(deserialized) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (deserialized == null || deserialized === undefined) {
        return deserialized;
      }

      var _DEFAULT_OPTIONS$opti2 = _objectSpread(_objectSpread({}, DEFAULT_OPTIONS), options),
          sourceFormat = _DEFAULT_OPTIONS$opti2.sourceFormat,
          displayFormat = _DEFAULT_OPTIONS$opti2.displayFormat;

      var date = (0, _moment.default)(deserialized, displayFormat);
      return sourceFormat ? date.format(sourceFormat) : date.toISOString();
    }
  });

  _exports.default = _default;
});