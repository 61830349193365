define("lh4/models/internet-payments/transaction", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    acquirerResponse: (0, _attr.default)('string'),
    amount: (0, _attr.default)('number'),
    authorizationCode: (0, _attr.default)('string'),
    avsResponseCode: (0, _attr.default)('string'),
    avsDescription: (0, _attr.default)('string'),
    batchGrossAmount: (0, _attr.default)('number'),
    batchNetAmount: (0, _attr.default)('number'),
    browser: (0, _attr.default)('string'),
    cardNumber: (0, _attr.default)('string'),
    cardToken: (0, _attr.default)('string'),
    cardType: (0, _attr.default)('string'),
    cardTypeSource: (0, _attr.default)('string'),
    chargebackAmount: (0, _attr.default)('string'),
    chargebackCode: (0, _attr.default)('string'),
    chargebackDatePosted: (0, _attr.default)('string'),
    chargebackReason: (0, _attr.default)('string'),
    chargebackDescription: (0, _attr.default)('string'),
    customerEmail: (0, _attr.default)('string'),
    customerName: (0, _attr.default)('string'),
    customerReference: (0, _attr.default)('string'),
    customerIpAddress: (0, _attr.default)('string'),
    cvv2ResponseCode: (0, _attr.default)('string'),
    cvv2Description: (0, _attr.default)('string'),
    date: (0, _attr.default)('string'),
    dayParts: (0, _attr.default)('string'),
    deviceId: (0, _attr.default)('string'),
    displayV2Preferred: (0, _attr.default)('boolean'),
    eventHistory: (0, _attr.default)(),
    eventStatus: (0, _attr.default)(),
    fundingSource: (0, _attr.default)('string'),
    hostResponse: (0, _attr.default)('string'),
    invoice: (0, _attr.default)('string'),
    isMobileDevice: (0, _attr.default)('boolean'),
    isRecurringPayment: (0, _attr.default)('boolean'),
    issuerBIN: (0, _attr.default)('string'),
    issuerCountry: (0, _attr.default)('string'),
    merchantBatchNumber: (0, _attr.default)('string'),
    merchantBatchDateTz: (0, _attr.default)('string'),
    merchantId: (0, _attr.default)('string'),
    merchantName: (0, _attr.default)('string'),
    merchantReference: (0, _attr.default)('string'),
    networkReferenceNumber: (0, _attr.default)('string'),
    orderNumber: (0, _attr.default)('number'),
    osVersion: (0, _attr.default)('string'),
    personaScore: (0, _attr.default)('number'),
    primaryAmount: (0, _attr.default)('number'),
    processingStatus: (0, _attr.default)('string'),
    revenueCenter: (0, _attr.default)('string'),
    riskId: (0, _attr.default)('string'),
    riskRules: (0, _attr.default)(),
    riskScore: (0, _attr.default)('string'),
    riskStatus: (0, _attr.default)('string'),
    secondaryAmount: (0, _attr.default)('number'),
    serverName: (0, _attr.default)('string'),
    settlementAmount: (0, _attr.default)('string'),
    shift4Mid: (0, _attr.default)('string'),
    shift4Serial: (0, _attr.default)('string'),
    ticketOpen: (0, _attr.default)('string'),
    ticketClose: (0, _attr.default)('string'),
    transactionAmount: (0, _attr.default)('string'),
    transactionId: (0, _attr.default)('number'),
    transactionStatus: (0, _attr.default)('string'),
    transbaseId: (0, _attr.default)('string'),
    transType: (0, _attr.default)('string'),
    weightedRiskTotal: (0, _attr.default)('number'),
    terminalNumber: (0, _attr.default)('number'),
    ticketReference: (0, _attr.default)('string'),
    ticketPaymentReference: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number')
  });

  _exports.default = _default;
});