define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/waitlist", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentId: null,
    filtered: Ember.computed.filter('model.reservation', function filterCallback(res) {
      return res.get('type') === 'waitlist' && !res.get('isNew') && res.get('locationId') === this.get('model.location.id');
    }).property('model.reservation.[]', 'model.reservation.@each.isNew'),
    sortedModel: Ember.computed.sort('filtered', function (a, b) {
      return (0, _moment.default)(a.get('arrivedAt')).diff((0, _moment.default)(b.get('arrivedAt')));
    }),
    actions: {
      assignTable: function assignTable(resId, value) {
        var res = this.store.peekRecord('reservation', resId);
        res.set('tableLayoutGuid', this.store.peekRecord('reservations/table', value));
        res.set('sectionGuid', res.get('tableLayoutGuid.sectionGuid'));
        res.save();
      },
      delResSure: function delResSure(id) {
        this.set('currentId', id);
        (0, _jquery.default)('.ui.modal').modal('show');
      },
      delRes: function delRes(yn) {
        if (yn) {
          var res = this.store.peekRecord('reservation', this.currentId);
          res.destroyRecord();
        }

        this.set('currentId', null);
      }
    }
  });

  _exports.default = _default;
});