define("lh4/mixins/routes/edit-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    idPathParam: 'id',
    record: {
      modelField: null,
      model: null
    },
    // relationships - E.g. {modelField: 'taxes', model: 'echo-pro/tax', where: {location: location.id}}
    relationships: [],
    model: function model(params) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [params].concat(args));

      var idPathParam = this.get('idPathParam');
      var record = this.get('record');
      var location = this.modelFor('authenticated.locations.location');
      var loadData = {};
      this.get('relationships').forEach(function (r) {
        loadData[r.modelField] = _this.store.query(r.model, r.where || {
          location: location.id
        });
      });
      loadData[record.modelField] = this.store.findRecord(record.model, params[idPathParam]).catch(function (res) {
        if (res.errors && res.errors[0] && res.errors[0].code === 404) {
          return _this.transitionTo('page-not-found');
        }

        throw res;
      });
      return Ember.RSVP.hash(loadData);
    }
  });

  _exports.default = _default;
});