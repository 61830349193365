define("lh4/adapters/note", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'note';
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var _snapshot$record = snapshot.record,
          caseId = _snapshot$record.caseId,
          locationId = _snapshot$record.locationId,
          requestToken = _snapshot$record.requestToken;
      return "".concat(this.host, "/").concat(this.namespace, "/disputes/").concat(caseId, "/").concat(modelName, "?locationId=").concat(locationId, "&requestToken=").concat(requestToken);
    }
  });

  _exports.default = _default;
});