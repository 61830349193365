define("lh4/components/epro/menu/modifier-bulk-form", ["exports", "lh4/mixins/components/ui/color-picker/color-picker-mapping"], function (_exports, _colorPickerMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_colorPickerMapping.default, {
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    classNames: ['modifier-form', 'form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    printerOptions: Ember.computed.map('printers', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          isActive = _ref.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setColorsFromStylesheet();
    },
    actions: {
      handleFieldSelectorGroupChange: function handleFieldSelectorGroupChange(field, included) {
        this.onFieldSelectorGroupChange(field, included);
      },
      handleOptionsChange: function handleOptionsChange(selected) {
        this.changeset.set('options', selected);
      }
    }
  });

  _exports.default = _default;
});