define("lh4/controllers/authenticated/reputation/accounts/link", ["exports", "lh4/mixins/reputation/reputation-account-types"], function (_exports, _reputationAccountTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend((0, _reputationAccountTypes.default)(null, 'model.accountType'), {
    ajax: Ember.inject.service(),
    facebook: Ember.inject.service('social-media/facebook'),
    yelp: Ember.inject.service('reputation/yelp'),
    google: Ember.inject.service('reputation/google-my-business'),
    accountApi: Ember.inject.service('reputation/account-api'),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    editRecord: Ember.inject.service(),
    validationErrors: Ember.computed('model.account.{name,locationId}', function getter() {
      return this.accountApi.validateAccount(this.get('model.account'));
    }),
    actions: {
      linkAccount: function linkAccount() {
        var _this = this;

        this.flashMessagesHelper.clearMessages();
        var validationErrors = this.accountApi.validateAccount(this.get('model.account'));

        if (validationErrors.length > 0) {
          return false;
        }

        this.set('isLoading', true);

        if (this.get('isFbPage')) {
          if (!this.get('model.code')) {
            this.flashMessagesHelper.pushMessage('Could not validate authentication parameters sent by Social Media Provider. Please try again', 'error');
            return false;
          } // TODO: validate the record


          return this.facebook.linkFbAccountReputation(this.get('model.account'), this.get('model.code'), this.get('socialMediaAccountId')).then(function (_ref) {
            var response = _ref.response;

            _this.flashMessagesHelper.pushMessage('Successfully created account', 'success');

            if (_this.get('socialMediaAccountId')) {
              _this.transitionToRoute('authenticated.reputation.accounts');
            } else {
              _this.transitionToRoute('authenticated.reputation.accounts.edit', response.account.id, {
                queryParams: {
                  showFields: false
                }
              });
            }
          }).catch(function (err) {
            return _this.errorHandler.handleResponseError(err);
          }).finally(function () {
            _this.set('isLoading', false);
          });
        }

        if (this.get('isYelp')) {
          return this.yelp.linkYelpAccount(this.get('model.account'), this.get('yelpBusiness')).then(function () {
            _this.flashMessagesHelper.pushMessage('Successfully created account', 'success');

            _this.transitionToRoute('authenticated.reputation.accounts', {
              queryParams: {
                showFields: false
              }
            });
          }).catch(function (err) {
            return _this.errorHandler.handleResponseError(err);
          }).finally(function () {
            _this.set('isLoading', false);
          });
        }

        if (this.get('isGoogleBusiness')) {
          if (!this.get('model.code')) {
            this.flashMessagesHelper.pushMessage('Could not validate authentication parameters sent by Google My Business. Please try again', 'error');
            return false;
          }

          return this.google.linkGMBAccountReputation(this.get('model.account'), this.get('model.code'), this.get('socialMediaAccountId')).then(function (_ref2) {
            var response = _ref2.response;

            _this.flashMessagesHelper.pushMessage('Successfully created account', 'success');

            _this.transitionToRoute('authenticated.reputation.accounts.edit', response.account.id, {
              queryParams: {
                showFields: false
              }
            });
          }).catch(function (err) {
            return _this.errorHandler.handleResponseError(err);
          }).finally(function () {
            _this.set('isLoading', false);
          });
        }

        this.flashMessagesHelper.pushMessage('Incorrect navigation attempt', 'error');
        this.transitionTo('authenticated.reputation.accounts');
        return false;
      },
      onSelectYelpBusiness: function onSelectYelpBusiness(yelpBusiness) {
        this.set('yelpBusiness', yelpBusiness);
      },

      /**
       * Select SM account to reuse
       * @param {Object|null} socialMediaAccount - account object on select, null on unselect
       */
      onSelectSmAccount: function onSelectSmAccount(socialMediaAccount) {
        if (socialMediaAccount) {
          this.set('socialMediaAccountId', socialMediaAccount.get('id'));
        } else {
          this.set('socialMediaAccountId', null);
        }
      },
      backToAccounts: function backToAccounts() {
        if (!window.history.state) {
          return this.transitionToRoute('authenticated.reputation.accounts.add');
        }

        if (window.history.state.route) {
          return this.transitionToRoute(window.history.state.route);
        }

        return window.history.back();
      }
    }
  });

  _exports.default = _default;
});