define("lh4/templates/components/hospitality/manage-employees/employee/jobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q7McJCXX",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"hospitality/dynamic-multi-select\",null,[[\"collection\",\"selected\",\"relation\"],[[24,[\"collection\"]],[24,[\"selected\"]],\"jobId\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/hospitality/manage-employees/employee/jobs.hbs"
    }
  });

  _exports.default = _default;
});