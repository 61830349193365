define("lh4/components/ui/tab-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: 'nav',
    classNames: ['tab-elements'],
    media: Ember.inject.service(),

    /* @param Array<{name, title, param}> */
    routes: null,
    displayTitle: Ember.computed('router.currentRouteName', function getter() {
      if (this.mobileTitle) {
        return this.mobileTitle;
      }

      if (!this.routes || !this.routes.length === 0) {
        return '';
      }

      var currentRouteName = this.router.currentRouteName;

      if (currentRouteName.includes('loading')) {
        return 'Loading...';
      }

      var currentRouteData = this.routes.find(function (route) {
        return currentRouteName.includes(route.name);
      });
      return currentRouteData === null || currentRouteData === void 0 ? void 0 : currentRouteData.title;
    }),
    isTabletFull: Ember.computed('media.isTablet', function getter() {
      return this.media.isTablet && this.routes && this.routes.length > 5;
    })
  });

  _exports.default = _default;
});