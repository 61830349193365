define("lh4/templates/components/hospitality/manage-employees/employee/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UY0YhH8Q",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"hospitality/employee-multi-select\",null,[[\"employee\"],[[24,[\"employee\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/hospitality/manage-employees/employee/permissions.hbs"
    }
  });

  _exports.default = _default;
});