define("lh4/templates/components/supply-orders/select-equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hp7TtKZu",
    "block": "{\"symbols\":[\"equipment\"],\"statements\":[[4,\"if\",[[24,[\"hasEquipment\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui four column stackable grid\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"equipment\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"column\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectEquipment\",[23,1,[]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui top attached header segment\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"h3\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"externalName\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\"],[1,[23,1,[\"externalName\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t \\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui attached segment\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"img\",true],[11,\"src\",[29,[[23,1,[\"imageLink\"]]]]],[10,\"class\",\"ui centered image\"],[11,\"alt\",[29,[[23,1,[\"externalName\"]]]]],[10,\"HEIGHT\",\"100\"],[10,\"WIDTH\",\"100\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"h3\",true],[10,\"class\",\"ui header\"],[8],[0,\"No equipment was found for that location. Please choose a different location\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/supply-orders/select-equipment.hbs"
    }
  });

  _exports.default = _default;
});