define("lh4/models/dashboard/layout", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    slug: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    widgets: _emberData.default.attr(),
    requiredProducts: _emberData.default.attr({
      defaultValue: []
    }),
    deniedProducts: _emberData.default.attr({
      defaultValue: []
    })
  });

  _exports.default = _default;
});