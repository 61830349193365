define("lh4/components/ui/tab-container/tab-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['tab-container-header', 'item'],
    classNameBindings: ['isActive:active'],
    click: function click() {
      this.sendAction('onSelect', this.get('tabName'));
    },
    tabName: '',
    activeTab: null,
    isActive: Ember.computed('tabName', 'activeTab', function getter() {
      return this.get('tabName') === this.get('activeTab');
    })
  });

  _exports.default = _default;
});