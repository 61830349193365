define("lh4/models/supply-orders/order", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    billedAt: (0, _attr.default)('string'),
    cancelledAt: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('string'),
    discount: (0, _attr.default)('string'),
    fulfilledAt: (0, _attr.default)('string'),
    fulfilledBy: (0, _attr.default)('string'),
    isBilled: (0, _attr.default)('boolean'),
    isCancelled: (0, _attr.default)('boolean'),
    isFulfilled: (0, _attr.default)('boolean'),
    isGraphite: (0, _attr.default)('boolean'),
    mid: (0, _attr.default)('string'),
    shippingAddress1: (0, _attr.default)('string'),
    shippingAddress2: (0, _attr.default)('string'),
    shippingAttention: (0, _attr.default)('string'),
    shippingCity: (0, _attr.default)('string'),
    shippingPrice: (0, _attr.default)('number'),
    shippingState: (0, _attr.default)('string'),
    shippingTo: (0, _attr.default)('string'),
    shippingZip: (0, _attr.default)('string'),
    shippingZipExtended: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('string')
  });

  _exports.default = _default;
});