define("lh4/components/hospitality/choices-and-choice-sets/sets/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disableMinMax: Ember.computed('model.{quantityMin,quantityMax}', function getter() {
      return this.get('model.quantityMin') === 1 && this.get('model.quantityMax') === 1;
    }),
    actions: {
      selectAllocateQuantityChoice: function selectAllocateQuantityChoice(value) {
        this.set('disableMinMax', value);

        if (value) {
          this.setProperties({
            'model.quantityMin': 1,
            'model.quantityMax': 1
          });
        }
      },
      setQuantityBehavior: function setQuantityBehavior(value) {
        this.set('model.quantityBehavior', value);

        if (value === 0) {
          this.set('model.isOptional', true);
          this.set('model.isOptionalForBartender', false);
        } else if (value === 1) {
          this.set('model.isOptional', false);
          this.set('model.isTimid', false);
        } else {
          this.set('model.isOptionalForBartender', false);
          this.set('model.isTimid', false);
        }
      }
    }
  });

  _exports.default = _default;
});