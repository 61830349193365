define("lh4/components/ui/s4/icons/right-chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg id="right-chevron-icon" xmlns="http://www.w3.org/2000/svg" width="17" height="24" viewBox="0 0 17 24" fill="none">
  	<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z" fill="{{if @color @color '#FFFFFF'}}"/>
  </svg>
  */
  {
    id: "G+Uuf0ly",
    block: "{\"symbols\":[\"@color\"],\"statements\":[[7,\"svg\",true],[10,\"id\",\"right-chevron-icon\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"17\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 17 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M9.99997 6L8.58997 7.41L13.17 12L8.58997 16.59L9.99997 18L16 12L9.99997 6Z\"],[11,\"fill\",[29,[[28,\"if\",[[23,1,[]],[23,1,[]],\"#FFFFFF\"],null]]]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/right-chevron.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});