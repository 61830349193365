define("lh4/serializers/internet-payments/dispute-management/dispute", ["exports", "ember-uuid", "lh4/utils/currency/converter", "lh4/serializers/application"], function (_exports, _emberUuid, _converter, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    DISPUTE_BRAND_TO_IP_BRAND: {
      Visa: 'visa',
      'American Express': 'amex',
      MasterCard: 'mastercard',
      Discover: 'discover',
      JCB: 'jcb'
    },
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'internet-payments/dispute-management/dispute';
    },
    normalize: function normalize() {
      var normalized = this._super.apply(this, arguments);

      if (normalized.data.attributes.disputeAmount) {
        normalized.data.attributes.disputeAmount = (0, _converter.toDollars)(normalized.data.attributes.disputeAmount).toString();
      }

      if (normalized.data.attributes.transaction && normalized.data.attributes.transaction.amount) {
        normalized.data.attributes.transaction.amount = (0, _converter.toDollars)(normalized.data.attributes.transaction.amount).toString();
      } // id is actually a offsetToken, it is not unique


      normalized.data.attributes.offsetToken = normalized.data.id;
      normalized.data.id = (0, _emberUuid.v4)();
      var cardBrand = normalized.data.attributes.card.brand;

      if (this.DISPUTE_BRAND_TO_IP_BRAND[cardBrand]) {
        normalized.data.attributes.card.brand = this.DISPUTE_BRAND_TO_IP_BRAND[cardBrand];
      }

      return normalized;
    }
  });

  _exports.default = _default;
});