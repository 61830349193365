define("lh4/templates/components/ui/popup/phone-number-explanation-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3fZH3TNQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"phone-number-field\"],[8],[0,\"\\n \"],[7,\"a\",true],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"Why do we ask for a phone number?\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui popup phone-number-popup flowing card\"],[8],[0,\"\\n \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"right floated circular-button secondary\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"onCloseExplanation\"]],[8],[0,\"\\n   \"],[5,\"ui/icons/s4/close\",[],[[],[]]],[0,\"\\n  \"],[9],[0,\"\\n \"],[9],[0,\"\\n \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n  When you call us from this phone number, our systems can provide your business information to our support team before they even pick up the call, and we can start helping you faster!\\n \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/popup/phone-number-explanation-popup.hbs"
    }
  });

  _exports.default = _default;
});