define("lh4/routes/authenticated/epro/menu/dynamic-pricing/create", ["exports", "lh4/routes/general-create-route", "moment", "lh4/utils/time/time-array"], function (_exports, _generalCreateRoute, _moment, _timeArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var DEFAULT_START_OF_DAY = '04:00 AM';
  var TIME_OPTION_INTERVAL_MINUTES = 15;

  var _default = _generalCreateRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    intradayPeriod: Ember.inject.service('intraday-period'),
    locationSettings: Ember.inject.service('echo-pro/settings'),
    modelName: 'dynamicPrice',
    childChangesets: {
      schedules: 'scheduleChangesets'
    },
    model: function model() {
      var dynamicPrice = this.store.createRecord('echo-pro/availability-price/dynamic-price', {
        locationId: this.location.model.id,
        isActive: false,
        timeOption: 'Custom Schedule',
        revenueCenterGuids: [],
        intradayPeriodGroupGuids: [],
        schedules: [this.store.createRecord('echo-pro/availability-price/schedule', {
          guid: null,
          locationId: this.location.model.id,
          scheduleNumber: 1,
          scheduleName: 'Schedule 1'
        })],
        overrideDates: [],
        isDateOverrideEnabled: false
      });
      return Ember.RSVP.hash({
        dynamicPrice: dynamicPrice,
        startOfDay: this.locationSettings.getEoDSetting(this.location.model.id),
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: this.location.model.id
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref) {
            var guid = _ref.guid,
                revenueCenterName = _ref.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        intradayPeriodOptions: this.intradayPeriod.getDayPartsGroups(this.location.model.id).then(function (intradayPeriods) {
          return intradayPeriods.map(function (_ref2) {
            var guid = _ref2.guid,
                intradayPeriodGroupName = _ref2.intradayPeriodGroupName;
            return {
              value: guid,
              name: intradayPeriodGroupName
            };
          });
        })
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      var formatTime = function formatTime(milliseconds) {
        var time = _moment.default.utc(milliseconds).format('h:mm A');

        return milliseconds >= 86400000 ? "".concat(time, " (Overnight)") : time;
      };

      var timeOptions = (0, _timeArray.default)(model.startOfDay || DEFAULT_START_OF_DAY, TIME_OPTION_INTERVAL_MINUTES).map(function (ms) {
        return {
          name: formatTime(ms),
          value: ms
        };
      });

      var beginTimeOptions = _toConsumableArray(timeOptions);

      beginTimeOptions.pop();

      var endTimeOptions = _toConsumableArray(timeOptions);

      endTimeOptions.shift();
      controller.setProperties({
        beginTimeOptions: beginTimeOptions,
        endTimeOptions: endTimeOptions
      });
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'New',
        'Entry ID': model.dynamicPrice.guid,
        'Menu Component': 'Dynamic Pricing'
      });
    }
  });

  _exports.default = _default;
});