define("lh4/enums/ip-risk-rules-groups-hidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RISK_RULES_GROUPS_HIDDEN = Object.freeze(['hide', 'flag', 'hide rules', 'flag rules']);
  var _default = RISK_RULES_GROUPS_HIDDEN;
  _exports.default = _default;
});