define("lh4/templates/components/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z3TaZLPw",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[4,\"each\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"flash-messages-container \",[22,\"stickyClasses\"]]]],[8],[0,\"\\n\\t\\t\"],[1,[28,\"flash-message\",null,[[\"flash\",\"class\"],[[23,1,[]],[23,1,[\"class\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/flash-messages.hbs"
    }
  });

  _exports.default = _default;
});