define("lh4/routes/authenticated/epro/workforce/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    redirect: function redirect() {
      if (this.can.can('manage employees and jobs for echo-pro/workforce', this.location.model)) {
        return this.transitionTo('authenticated.epro.manage-employees.employees');
      }

      if (this.can.can('manage timeclock for echo-pro/workforce', this.location.model)) {
        return this.transitionTo('authenticated.epro.timeclock.settings');
      }

      if (this.can.can('manage scheduler for echo-pro/workforce', this.location.model)) {
        return this.transitionTo('authenticated.epro.workforce.links.scheduler');
      }

      return this.transitionTo('/');
    }
  });

  _exports.default = _default;
});