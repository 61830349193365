define("lh4/mirage/factories/solo-employee", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    name: 'Test Employee',
    pin: '****',
    isActive: true,
    createdAt: new Date().toISOString(),
    permissions: {
      isTransactionsAccessEnabled: true,
      isReportsAccessEnabled: true,
      isSettingsAccessEnabled: true,
      isEmployeeManagementAccessEnabled: true
    }
  });

  _exports.default = _default;
});