define("lh4/routes/authenticated/locations/location/echo-pro/reservations", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    requiredPermissions: 'manage echo-pro/reservation',
    titleToken: 'Reservations',
    model: function model() {
      var _this = this;

      var loc = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        location: loc,
        table: this.store.query('reservations/table', {
          location: loc.id
        }).then(function () {
          return _this.store.peekAll('reservations/table');
        }),
        section: this.store.query('reservations/section', {
          location: loc.id
        }).then(function () {
          return _this.store.peekAll('reservations/section');
        })
      });
    },
    actions: {
      showListForCreate: function showListForCreate() {
        if (window.matchMedia('(max-width: 767px)').matches) {
          this.controllerFor('authenticated.locations.location.echo-pro.reservations.reservation').toggleProperty('showReservation');
          this.controllerFor('authenticated.locations.location.echo-pro.reservations.reservation').toggleProperty('showList');
        }
      }
    }
  });

  _exports.default = _default;
});