define("lh4/models/echo-pro/accounting/tax-set", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/has-many-ids"], function (_exports, _emberData, _attr, _relationships, _emberCpValidations, _modelCloneable, _hasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Revenue class name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100,
        description: 'Revenue class name length'
      })]
    },
    grossPercentage: (0, _emberCpValidations.validator)('number', {
      description: 'Percentage of Gross',
      positive: true,
      allowBlank: true,
      allowString: true
    })
  });

  var _default = _emberData.default.Model.extend(Validations, _modelCloneable.default, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    isAllLiquor: (0, _attr.default)('boolean'),
    isIncludedInTipOut: (0, _attr.default)('boolean'),
    grossPercentage: (0, _attr.default)('number'),
    isIncludedInGratuity: (0, _attr.default)('boolean'),
    isIncludedInFavorites: (0, _attr.default)('boolean'),
    isNonSalesRevenue: (0, _attr.default)('boolean'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    taxes: (0, _relationships.hasMany)('echo-pro/tax'),
    taxIds: Ember.computed('taxes', (0, _hasManyIds.default)('taxes', 'echo-pro/tax'))
  });

  _exports.default = _default;
});