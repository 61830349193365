define("lh4/templates/components/internet-payments/download-label-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7BOVktfH",
    "block": "{\"symbols\":[\"fileType\",\"@url\",\"@fileTypes\",\"@value\",\"@showNew\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"download-label-button\"],[10,\"data-test-ip\",\"download-label-button\"],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"internet-payments/ui/dropdown/dropdown-file-selector\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,3,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"li\",false],[12,\"class\",\"item\"],[12,\"data-test-ip\",\"download-item\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"handleClick\"]],[23,1,[\"extention\"]]],null]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[23,1,[\"name\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[4,\"link-to-outbound\",[[23,2,[]]],[[\"eventLabel\",\"class\",\"data-test-ip\"],[\"View Document\",\"download\",\"download-link\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"ui/icons/internet-payments/download\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"download\"],[12,\"data-test-ip\",\"download-click\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"handleClick\"]],\"pdf\"],null]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"ui/icons/internet-payments/download\",[],[[],[]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[10,\"data-test-ip\",\"download-label\"],[8],[1,[28,\"capitalize-first\",[[23,4,[]]],null],false],[9],[0,\"\\n\\t\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"new\"],[10,\"data-test-ip\",\"new\"],[8],[0,\"New\"],[9]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/download-label-button.hbs"
    }
  });

  _exports.default = _default;
});