define("lh4/templates/authenticated/internet-payments/support/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t1Qwpq/8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"hr\",true],[10,\"class\",\"ui wide divider\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"support-wrap-container\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[1,[22,\"subMessage\"],false],[9],[0,\"\\n\\t\"],[5,\"internet-payments/support/support-list\",[],[[\"@supportInfo\"],[[22,\"supportInfo\"]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/support/index.hbs"
    }
  });

  _exports.default = _default;
});