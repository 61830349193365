define("lh4/mirage/fixtures/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DOCUMENTS_FIXTURE = {
    documents: {
      '1099s': [{
        url: 'test.com',
        year: '2018'
      }, {
        url: 'test.com',
        year: '2019'
      }],
      notices: [{
        month: 1,
        name: 'fileName',
        url: 'test.com',
        year: '2021'
      }, {
        month: 2,
        name: 'fileName',
        url: 'test.com',
        year: '2022'
      }],
      statements: [{
        month: 201812,
        formats: ['pdf', 'xlsx']
      }, {
        month: 201912,
        formats: ['pdf']
      }]
    }
  };
  var _default = DOCUMENTS_FIXTURE;
  _exports.default = _default;
});