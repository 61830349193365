define("lh4/components/ui/popup/error-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['error-popup'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.popup-trigger').popup({
        inline: false,
        on: 'click',
        popup: this.$('.error.popup'),
        position: 'bottom right'
      });
    }
  });

  _exports.default = _default;
});