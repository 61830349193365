define("lh4/mirage/factories/epro-dynamic-price", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    locationId: 1,
    scheduleTitle: 'Test Schedule',
    isActive: true,
    timeOption: 'Custom Schedule',
    revenueCenterGuids: [],
    intradayPeriodGroupGuids: [],
    schedules: [],
    overrideDates: [],
    isDateOverrideEnabled: false
  });

  _exports.default = _default;
});