define("lh4/models/echo-pro/employee-shift", ["exports", "ember-cp-validations", "ember-data", "ember-data/attr", "ember-data/model", "lh4/enums/duration", "moment"], function (_exports, _emberCpValidations, _emberData, _attr, _model, _duration, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    notes: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    unpaidBreakSecondsText: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /(^\s*\d+h\s*$)|(^\s*\d+h\s*\d+(m|min)\s*$)|(^\s*\d+(m|min)\s*$)/,
        message: 'Incorrect duration format'
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        var durationSeconds = model.get('durationSeconds');
        var breakSeconds = model.get('unpaidBreakSeconds');

        if (breakSeconds && durationSeconds <= breakSeconds) {
          return 'Unpaid break can not be longer than shift duration.';
        }

        return true;
      })]
    },
    startsAt: {
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        var startsAt = (0, _moment.default)(model.get('startsAt'));
        var endsAt = (0, _moment.default)(model.get('endsAt'));

        if (startsAt.isSameOrAfter(endsAt)) {
          return 'Shift start should be less or equal with shift end';
        }

        if (startsAt.diff(endsAt, 'days') > 1) {
          return 'Shift duration is longer than one day';
        }

        return true;
      }, {
        dependentKeys: ['model.endsAt']
      })]
    },
    job: {
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        if (!model.get('isTimeOff') && !model.get('job.id')) {
          return 'Job should be specified';
        }

        return true;
      })]
    }
  });
  var shiftHelper = {
    getTime: function getTime(name) {
      return (0, _moment.default)(this.get(name)).format('hh:mmA');
    },
    setTime: function setTime(name, value) {
      var date = (0, _moment.default)(this.get(name));

      if (value) {
        var time = (0, _moment.default)(value, 'hh:mmA');
        date.set({
          hour: time.format('HH'),
          minute: time.format('mm')
        });
      }

      this.set(name, date.toDate());
      var startsAt = (0, _moment.default)(this.get('startsAt'));
      var endsAt = (0, _moment.default)(this.get('endsAt'));
      var daysDiff = startsAt.diff(endsAt, 'days');

      if (startsAt.isSameOrAfter(endsAt)) {
        this.set('endsAt', endsAt.add(daysDiff + 1, 'days').toDate());
      } else {
        this.set('endsAt', endsAt.add(daysDiff, 'days').toDate());
      }

      return value;
    },
    getDate: function getDate() {
      return (0, _moment.default)(this.get('startsAt')).startOf('day').toDate();
    },
    setDate: function setDate(value) {
      var startsAtTime = this.get('startsAtTime');
      this.set('startsAt', value);
      this.set('startsAtTime', startsAtTime);
      return value;
    },
    setSeconds: function setSeconds(value, callback) {
      var hours = value.match(/(\d+)h/);
      var minutes = value.match(/(\d+)m/);
      var totalSeconds = 0;

      if (hours && hours.length > 1) {
        totalSeconds += hours[1] * _duration.default.HOUR.IN_SECONDS;
      }

      if (minutes && minutes.length > 1) {
        totalSeconds += minutes[1] * _duration.default.MINUTE.IN_SECONDS;
      }

      callback(totalSeconds);
    }
  };

  var _default = _model.default.extend(Validations, {
    locationId: _emberData.default.attr('number'),
    unpaidBreakSeconds: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    isTimeOff: (0, _attr.default)('boolean', false),
    notes: (0, _attr.default)('string'),
    color: (0, _attr.default)('string'),
    employee: _emberData.default.belongsTo('echo-pro/employee'),
    job: _emberData.default.belongsTo('echo-pro/job'),
    startsAt: (0, _attr.default)('date'),
    endsAt: (0, _attr.default)('date'),
    day: Ember.computed('startsAt', 'endsAt', {
      get: function get() {
        return shiftHelper.getDate.call(this);
      },
      set: function set(key, value) {
        return shiftHelper.setDate.call(this, value);
      }
    }),
    startsAtTime: Ember.computed('startsAt', {
      get: function get() {
        return shiftHelper.getTime.call(this, 'startsAt');
      },
      set: function set(key, value) {
        return shiftHelper.setTime.call(this, 'startsAt', value);
      }
    }),
    endsAtTime: Ember.computed('endsAt', {
      get: function get() {
        return shiftHelper.getTime.call(this, 'endsAt');
      },
      set: function set(key, value) {
        return shiftHelper.setTime.call(this, 'endsAt', value, true);
      }
    }),
    unpaidBreakSecondsText: Ember.computed('unpaidBreakSeconds', {
      get: function get() {
        var unpaidBreakSeconds = this.get('unpaidBreakSeconds') || 0;

        var duration = _moment.default.duration(unpaidBreakSeconds, 'seconds');

        var hours = duration.hours();
        var minutes = duration.minutes();
        return "".concat(hours > 0 ? "".concat(hours, "h ") : '').concat(minutes > 0 ? "".concat(minutes, "min") : '');
      },
      set: function set(key, value) {
        var _this = this;

        shiftHelper.setSeconds(value, function (seconds) {
          return _this.set('unpaidBreakSeconds', seconds);
        });
        return value;
      }
    }),
    durationSeconds: Ember.computed('startsAt', 'endsAt', function getter() {
      return (0, _moment.default)(this.get('endsAt')).diff(this.get('startsAt'), 'seconds');
    }),
    isOpenShift: Ember.computed('employee', function getter() {
      return !this.get('employee.id');
    }),
    canHaveConflict: Ember.computed('isOpenShift', 'isTimeOff', function getter() {
      return !this.get('isOpenShift') && !this.get('isTimeOff');
    }),
    cloneWithDate: function cloneWithDate(date) {
      var clone = this.store.createRecord('echo-pro/employee-shift', {
        startsAt: this.get('startsAt'),
        endsAt: this.get('endsAt'),
        employee: this.get('employee'),
        job: this.get('job'),
        unpaidBreakSeconds: this.get('unpaidBreakSeconds'),
        locationId: this.get('locationId'),
        color: this.get('color'),
        notes: this.get('notes'),
        isTimeOff: this.get('isTimeOff')
      });
      clone.set('day', date);
      return clone;
    },
    hasConflictWith: function hasConflictWith(shift) {
      if (this.get('id') === shift.get('id')) {
        return false;
      }

      if (!this.get('canHaveConflict') || !shift.get('canHaveConflict')) {
        return false;
      }

      if (this.get('employee.id') !== shift.get('employee.id')) {
        return false;
      }

      var leftStart = this.get('startsAt');
      var rightStart = shift.get('startsAt');
      var leftEnd = this.get('endsAt');
      var rightEnd = shift.get('endsAt');

      if (leftStart < rightEnd && rightStart < leftEnd) {
        return true;
      }

      return false;
    },
    normalizeRelations: function normalizeRelations(relations) {
      var job = this.store.peekRecord('echo-pro/job', relations.jobGuid);
      var employee = this.store.peekRecord('echo-pro/employee', relations.employeeGuid);
      this.set('job', job);
      this.set('employee', employee);
      return this;
    },
    toSchedule: function toSchedule() {
      var startsAtUtc = (0, _moment.default)(this.get('startsAt')).utc();
      var endsAtUtc = (0, _moment.default)(this.get('endsAt')).utc();

      var startsAtTime = _moment.default.duration(startsAtUtc.format('H:mm'));

      var endsAtTime = _moment.default.duration(endsAtUtc.format('H:mm'));

      if (startsAtTime >= endsAtTime) {
        endsAtTime.add(1, 'day');
      }

      return this.store.createRecord('echo-pro/schedule', {
        locationId: this.get('locationId'),
        employee: this.get('employee'),
        job: this.get('job'),
        unpaidBreakSeconds: this.get('unpaidBreakSeconds'),
        startsAt: startsAtTime.asSeconds(),
        endsAt: endsAtTime.asSeconds(),
        dayOfWeek: startsAtUtc.format('ddd'),
        notes: this.get('notes'),
        color: this.get('color')
      });
    }
  });

  _exports.default = _default;
});