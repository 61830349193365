define("lh4/templates/components/ui/checkbox-unescaped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IFQ2zFKn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[11,\"name\",[22,\"name\"]],[11,\"tabindex\",[22,\"tabindex\"]],[11,\"checked\",[28,\"unbound\",[[24,[\"checked\"]]],null]],[11,\"disabled\",[28,\"unbound\",[[24,[\"disabled\"]]],null]],[11,\"type\",[22,\"type\"]],[8],[9],[0,\"\\n\"],[7,\"label\",true],[10,\"class\",\"ui checkbox-unescaped label-container\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[8],[1,[22,\"label\"],true],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"nameSuffix\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"ui checkbox-unescaped suffix\"],[8],[1,[22,\"nameSuffix\"],true],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"nameDetail\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"ui checkbox-unescaped detail\"],[8],[1,[22,\"nameDetail\"],true],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[14,1,[[28,\"action\",[[23,0,[]],\"execute\"],null]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/checkbox-unescaped.hbs"
    }
  });

  _exports.default = _default;
});