define("lh4/templates/components/dashboard-widgets/ip-dashboard-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "POI2OBmt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"widget\",\"isPending\"]],[24,[\"widget\",\"isRejected\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui segments front\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"ui active inverted loading dimmer \",[24,[\"widgetSettings\",\"slug\"]]]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"reload\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"widget\",\"isPending\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui active inverted dimmer\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[5,\"internet-payments/spinners/loader-circle\",[],[[],[]]],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"widget\",\"isRejected\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"error\"],[8],[0,\"Error loading widget. Tap to retry.\"],[9],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"component\",[[28,\"concat\",[\"dashboard-widgets/\",[24,[\"widgetSettings\",\"slug\"]],\"/data\"],null]],[[\"class\",\"widget\",\"loading\"],[\"ui segment skeleton\",[24,[\"loadedWidget\"]],true]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[24,[\"isHidden\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui segments front\"],[8],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\\t\"],[1,[28,\"component\",[[28,\"concat\",[\"dashboard-widgets/\",[24,[\"widgetSettings\",\"slug\"]],\"/data\"],null]],[[\"class\",\"setSettings\",\"widget\",\"location\"],[\"ui segment\",[28,\"action\",[[23,0,[]],\"setSettings\"],null],[24,[\"widget\"]],[24,[\"location\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/ip-dashboard-widget.hbs"
    }
  });

  _exports.default = _default;
});