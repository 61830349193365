define("lh4/models/processing/customer/email", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _emberData, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.processingEmailTypes = _exports.default = void 0;
  var processingEmailTypes = [{
    name: 'Primary'
  }, {
    name: 'Alternate'
  }, {
    name: 'Work'
  }, {
    name: 'Other'
  }];
  _exports.processingEmailTypes = processingEmailTypes;
  var Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: 'Email',
      validators: [(0, _emberCpValidations.validator)('format', {
        type: 'email',
        allowBlank: false
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('customer.emails')) {
          var emails = model.get('customer.emails').map(function (e) {
            if (e.get('email')) {
              return e.get('email').toLowerCase();
            }

            return false;
          });
          var unique = emails.length === emails.uniq().length;
          return unique || 'Customer has duplicated email addresses. (Email addresses are not case sensitive)';
        }

        return true;
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    email: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    customer: (0, _relationships.belongsTo)('processing/customer'),
    createdAt: (0, _attr.default)('date'),
    emailTypes: Ember.computed(function () {
      return processingEmailTypes.map(function (_ref) {
        var name = _ref.name;
        return {
          name: name,
          value: name
        };
      });
    }),
    contains: function contains(term) {
      return this.get('email').toLowerCase().includes(term);
    }
  });

  _exports.default = _default;
});