define("lh4/models/echo-pro/deploy", ["exports", "ember-data/model", "ember-data/attr", "lh4/mixins/model-cloneable", "ember-cp-validations"], function (_exports, _model, _attr, _modelCloneable, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Update Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    },
    notes: {
      description: 'Notes',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255,
        description: 'Notes length'
      })]
    },
    scheduledFor: {
      description: 'Date',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      })]
    },
    selectedLocation: {
      description: 'Selected location',
      validators: [(0, _emberCpValidations.validator)('presence', {
        message: 'Location must be selected to proceed',
        presence: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, _modelCloneable.default, {
    guid: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    notes: (0, _attr.default)('string'),
    scheduledFor: (0, _attr.default)('string'),
    analysis: (0, _attr.default)(),
    selectedLocation: (0, _attr.default)('string'),
    instant: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});