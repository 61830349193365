define("lh4/adapters/hardware/checkout", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      if (status !== 201) {
        return this._super.apply(this, [status, headers, payload, requestData].concat(args));
      }

      var response = this._super.apply(this, [status, headers, payload, requestData].concat(args));

      response.id = this.location.model.id;
      return response;
    }
  });

  _exports.default = _default;
});