define("lh4/templates/components/ui/images/image-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4crO+c8R",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showRemove\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\",false],[12,\"class\",\"large times circle icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"onDelete\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"img\",true],[11,\"class\",[29,[\"ui bordered \",[22,\"size\"],\" image\"]]],[11,\"src\",[29,[[22,\"cacheBustedLink\"]]]],[10,\"alt\",\"Selected Image\"],[10,\"data-test-image-frame\",\"\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/images/image-frame.hbs"
    }
  });

  _exports.default = _default;
});