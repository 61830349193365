define("lh4/components/ui/modal/fullscreen-display-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fullscreen-display-modal'],
    previousBodyOverflow: undefined,
    onClose: function onClose() {},
    didInsertElement: function didInsertElement() {
      var previousBodyOverflow = document.body.style.overflow === 'hidden' ? '' : document.body.style.overflow;
      this.set('previousBodyOverflow', previousBodyOverflow);
      setTimeout(function () {
        document.body.style.overflow = 'hidden';
      });
    }
  });

  _exports.default = _default;
});