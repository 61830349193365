define("lh4/utils/dash-to-camel-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = dashToCamelCase;

  /**
   * @method dashToCamelCase
   * @param {string} input - input string with dashes
   * @returns {string} camelized string, i.e. my-foo-bar becomes myFooBar
   */
  function dashToCamelCase(input) {
    return input.replace(/-([a-z])/g, function (x) {
      return x[1].toUpperCase();
    });
  }
});