define("lh4/components/tables/pos-orders-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    statusLabel: Ember.computed('order.orderStatus', function getter() {
      var status = this.get('order.orderStatus');

      if (status === 'Completed') {
        return 'green';
      }

      if (status === 'Pending') {
        return 'yellow';
      }

      return 'red';
    }),
    stageLabel: Ember.computed('order.orderStage', function getter() {
      var status = this.get('order.orderStage');

      if (status === 'Complete') {
        return 'green';
      }

      if (status === 'New') {
        return 'yellow';
      }

      if (status === 'Order Not Continuing - Decline/Cancel/Hold/Invalid') {
        return 'red';
      }

      return '';
    })
  });

  _exports.default = _default;
});