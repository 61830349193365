define("lh4/mirage/fixtures/internet-payments/acquirer-response-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ACQUIRER_RESPONSE_CODES_FIXTURE = {
    acquirerResponseCodes: [{
      code: '00',
      description: 'Approval'
    }, {
      code: '01',
      description: 'Refer to card issuer'
    }, {
      code: '02',
      description: 'Refer to issuer-Special Condition'
    }, {
      code: '03',
      description: 'Invalid Merchant'
    }, {
      code: '04',
      description: 'Pick up card'
    }, {
      code: '05',
      description: 'Do not honor'
    }, {
      code: '06',
      description: 'General error '
    }, {
      code: '07',
      description: 'Pick up card,special condition'
    }, {
      code: '08',
      description: 'Honor Mastercard with ID'
    }, {
      code: '10',
      description: 'Partial approval for the authorized amount returned in Group III version 022'
    }, {
      code: '11',
      description: 'VIP approval'
    }, {
      code: '12',
      description: 'Invalid transaction '
    }, {
      code: '13',
      description: 'Invalid amount '
    }, {
      code: '14',
      description: 'Invalid account'
    }, {
      code: '15',
      description: 'No such issuer'
    }, {
      code: '19',
      description: 'Re-enter transaction '
    }, {
      code: '21',
      description: 'Unable to back out transaction'
    }, {
      code: '25',
      description: 'Unable to locate account number'
    }, {
      code: '28',
      description: 'File is temporarily unavailable'
    }, {
      code: '30',
      description: 'Transaction was improperly formatted'
    }, {
      code: '34',
      description: 'MasterCard use only, Transaction Canceled; Fraud Concern (Used in reversal requests only)'
    }, {
      code: '39',
      description: 'No credit account'
    }, {
      code: '41',
      description: 'Lost card'
    }, {
      code: '43',
      description: 'Stolen card'
    }, {
      code: '46',
      description: 'Closed Account'
    }, {
      code: '51',
      description: 'Not sufficient funds'
    }, {
      code: '52',
      description: 'No checking account'
    }, {
      code: '53',
      description: 'No savings account'
    }, {
      code: '54',
      description: 'Expired card'
    }, {
      code: '55',
      description: 'Incorrect PIN or missing'
    }, {
      code: '56',
      description: 'No card record'
    }, {
      code: '57',
      description: 'Transaction not permitted to cardholder'
    }, {
      code: '58',
      description: 'Transaction not permitted-Terminal'
    }, {
      code: '59',
      description: 'Suspected Fraud'
    }, {
      code: '61',
      description: 'Exceeds withdrawal limit'
    }, {
      code: '62',
      description: 'Invalid service code, restricted'
    }, {
      code: '63',
      description: 'Security violation '
    }, {
      code: '65',
      description: 'Exceeds withdrawal frequency limit'
    }, {
      code: '6P',
      description: 'Verification data failed'
    }, {
      code: '75',
      description: 'Allowable number of PIN-entry tried exceeded'
    }, {
      code: '76',
      description: 'Unable to locate, no match'
    }, {
      code: '77',
      description: 'Inconsistent, reverse, or repeat data'
    }, {
      code: '78',
      description: 'Blocked, first used'
    }, {
      code: '79',
      description: 'Already reversed at switch'
    }, {
      code: '80',
      description: 'No financial impact'
    }, {
      code: '81',
      description: 'Cryptographic error'
    }, {
      code: '82',
      description: 'Negative Online CAM, dCVV, iCVV, or CVV results'
    }, {
      code: '83',
      description: 'Cannot verify PIN'
    }, {
      code: '85',
      description: 'Card Ok'
    }, {
      code: '86',
      description: 'Cannot verify PIN'
    }, {
      code: '91',
      description: 'Issuer or switch is unavailable'
    }, {
      code: '92',
      description: 'Destination not found'
    }, {
      code: '93',
      description: 'Violation, cannot complete'
    }, {
      code: '94',
      description: 'Duplicate Transaction'
    }, {
      code: '96',
      description: 'System malfunction '
    }, {
      code: 'A1',
      description: 'POS device authentication successful'
    }, {
      code: 'A2',
      description: 'POS device authentication not successful'
    }, {
      code: 'A3',
      description: 'POS device deactivation successful'
    }, {
      code: 'B1',
      description: 'Surcharge amount not permitted on debit cards or EBT food stamps'
    }, {
      code: 'B2',
      description: 'Surcharge amount not supported by debit network issuer'
    }, {
      code: 'CV',
      description: 'Card Type Verification Error'
    }, {
      code: 'D3',
      description: 'Transaction failure due to missing or invalid 3D-Secure cryptogram'
    }, {
      code: 'E1',
      description: 'Encryption is not configured'
    }, {
      code: 'E2',
      description: 'Terminal is not authenticated'
    }, {
      code: 'E3',
      description: 'Data could not be decrypted'
    }, {
      code: 'EA',
      description: 'Verification error - account length'
    }, {
      code: 'EB',
      description: 'Verification Error - CHECK DIGIT ERR'
    }, {
      code: 'EC',
      description: 'Verification error - CID format'
    }, {
      code: 'H1',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'H2',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'H3',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'H4',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'H5',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'H6',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'H7',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'H8',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'H9',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'HV',
      description: 'Hierarchy Verification Error'
    }, {
      code: 'K0',
      description: 'Token request was processed'
    }, {
      code: 'K1',
      description: 'Tokenization is not configured'
    }, {
      code: 'K2',
      description: 'Terminal is not authenticated'
    }, {
      code: 'K3',
      description: 'Data could not be de-tokenized'
    }, {
      code: 'M0',
      description: 'Mastercard: Canada region-issued Domestic Debit Transaction not allowed'
    }, {
      code: 'N3',
      description: 'Cash back service not available'
    }, {
      code: 'N4',
      description: 'Exceeds issuer withdrawal limit'
    }, {
      code: 'N7',
      description: 'CVV2 value supplied is invalid'
    }, {
      code: 'P0',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'P1',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'P2',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'P3',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'P4',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'P5',
      description: 'Contact Merchant Services/Technical Support'
    }, {
      code: 'P6',
      description: 'Customer verification data failed'
    }, {
      code: 'P7',
      description: 'The terminal has not yet completed the boarding process. The Serial Number has not been set up.'
    }, {
      code: 'Q1',
      description: 'Card authentication failed'
    }, {
      code: 'R0',
      description: 'Customer requested stop of specific recurring payment'
    }, {
      code: 'R1',
      description: 'Stop recurring payments'
    }, {
      code: 'R3',
      description: 'Stop all recurring payments'
    }, {
      code: 'S0',
      description: 'The PAN used in the transaction is inactive'
    }, {
      code: 'S1',
      description: 'The Mod-10 check failed'
    }, {
      code: 'S5',
      description: 'Decline – no preauthorization found'
    }, {
      code: 'S9',
      description: 'Maximum working balance exceeded'
    }, {
      code: 'SA',
      description: 'The Authorization Server is shut down'
    }, {
      code: 'SB',
      description: 'Invalid card status – status is other than active'
    }, {
      code: 'SC',
      description: 'Unknown dealer/store code - special edit'
    }, {
      code: 'SD',
      description: 'Maximum number of recharges is exceeded'
    }, {
      code: 'SE',
      description: 'Card was already used'
    }, {
      code: 'SF',
      description: 'Manual transactions not allowed'
    }, {
      code: 'SH',
      description: 'Transaction type was unknown'
    }, {
      code: 'SJ',
      description: 'An invalid tender type was submitted'
    }, {
      code: 'SK',
      description: 'An invalid customer type was submitted'
    }, {
      code: 'SL',
      description: 'PIN was locked'
    }, {
      code: 'SM',
      description: 'The maximum number of redemptions was exceeded'
    }, {
      code: 'SP',
      description: 'The maximum number of PAN tries was exceeded'
    }, {
      code: 'SR',
      description: 'The card was already issued'
    }, {
      code: 'SS',
      description: 'The card was not issued'
    }, {
      code: 'T0',
      description: 'First check is okay and has been converted'
    }, {
      code: 'T1',
      description: 'The check is okay but cannot be converted. This is a declined transaction'
    }, {
      code: 'T2',
      description: 'Invalid ABA number, not an ACH participant'
    }, {
      code: 'T3',
      description: 'Amount greater than the limit'
    }, {
      code: 'V1',
      description: 'Daily threshold exceeded'
    }]
  };
  var _default = ACQUIRER_RESPONSE_CODES_FIXTURE;
  _exports.default = _default;
});