define("lh4/utils/day-to-week-range", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = weekRangeFromDate;

  /**
   * Returns object containing 'from' and 'to' dates based on a weekday
   *
   * @param    {Date} [date] - Date to get week range
   * @param    {Number} [weekStart] - Optional starting day of week to offset by
   * @returns  {Object} Object containing from and to date objects
   */
  function weekRangeFromDate(date) {
    var weekStart = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var start = (0, _moment.default)(date).startOf('day').day(weekStart);

    if (start.isAfter(date)) {
      start.add(-1, 'weeks');
    }

    return {
      from: start.clone(),
      to: start.add(1, 'weeks').add(-1, 'milliseconds')
    };
  }
});