define("lh4/models/notice", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    month: _emberData.default.attr('number'),
    year: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    date: Ember.computed('year', 'month', function getter() {
      return (0, _moment.default)([this.get('year'), this.get('month') - 1]);
    })
  });

  _exports.default = _default;
});