define("lh4/enums/ip-ach-service-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ACH_SERVICE_ACTIVE = Object.freeze({
    YES: 'Y',
    NO: 'N'
  });
  var _default = ACH_SERVICE_ACTIVE;
  _exports.default = _default;
});