define("lh4/templates/authenticated/launch-control/discounts/discount-list/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+vxK1OUb",
    "block": "{\"symbols\":[\"modal\",\"__arg0\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"s4-module-container\"],[8],[0,\"\\n\\t\"],[5,\"ui/launch-control/fullscreen-modal\",[],[[\"@title\",\"@onClose\",\"@isWhiteHeader\",\"@isBlackCloseHeader\",\"@isBlackCloseButton\",\"@errorMessage\",\"@isLaunchControl\",\"@showFlashMessage\",\"@isLoading\"],[\"title\",[23,0,[\"navigateToList\"]],true,true,true,[23,0,[\"errorMessage\"]],true,true,[23,0,[\"isLoading\"]]]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"step-container\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"epro/payments/s4/discount/discount-form\",[],[[\"@orderTypes\",\"@items\",\"@changeset\",\"@header\",\"@namedBlocksInfo\"],[[23,0,[\"model\",\"orderTypes\"]],[23,0,[\"model\",\"items\"]],[23,0,[\"changeset\"]],\"New Discount\",[28,\"hash\",null,[[\"actionButtons\"],[0]]]]],{\"statements\":[[4,\"if\",[[28,\"-is-named-block-invocation\",[[23,2,[]],\"actionButtons\"],null]],null,{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@data-test-button\",\"@type\",\"@text\",\"@onClick\"],[\"discount-form-skip\",\"rounded-outline\",\"CANCEL\",[23,0,[\"navigateToList\"]]]]],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@data-test-button\",\"@type\",\"@text\",\"@onClick\",\"@buttonType\"],[\"submit\",\"rounded-contained\",\"SAVE\",[23,0,[\"submit\"]],\"submit\"]]],[0,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]},null]],\"parameters\":[2]}],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/launch-control/discounts/discount-list/new.hbs"
    }
  });

  _exports.default = _default;
});