define("lh4/routes/authenticated/locations/location/echo-pro/reservations/waitlist/create", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var m = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        newRes: this.store.createRecord('reservation', {
          statusId: 0,
          type: 'waitlist',
          locationId: m.id
        }),
        location: m
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.get('currentModel.newRes').deleteRecord();
      }
    }
  });

  _exports.default = _default;
});