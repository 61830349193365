define("lh4/serializers/echo-pro/modifier-set", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      modifiers: {
        embedded: 'always',
        serialize: 'id'
      },
      nestedModifiers: {
        embedded: 'always',
        serialize: 'id'
      },
      modifiersMap: {
        embedded: 'always'
      },
      items: {
        embedded: 'always',
        serialize: 'id'
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.modifiers = [];
      json.modifiersMap.forEach(function (map) {
        json.modifiers.push(map.modifierGuid);
      });
      json.parentModifiers = json.modifiers;
      delete json.modifiers;

      if (json.minQuantityForIndividualModifier === null) {
        delete json.minQuantityForIndividualModifier;
      }

      if (json.maxQuantityForIndividualModifier === null) {
        delete json.maxQuantityForIndividualModifier;
      }

      if (json.minModifiersPerSet === null) {
        delete json.minModifiersPerSet;
      }

      if (json.maxModifiersPerSet === null) {
        delete json.maxModifiersPerSet;
      }

      json.value = Number.parseFloat(json.value).toFixed(2);

      if (!json.isFlatPrice) {
        json.value /= 100;
      }

      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payloadToNormalize) {
      var payload = payloadToNormalize;

      if (payload.modifierSet) {
        payload.modifierSet.modifiers = payload.modifierSet.parentModifiers;
        delete payload.modifierSet.parentModifiers;
      }

      for (var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        args[_key - 3] = arguments[_key];
      }

      return this._super.apply(this, [store, primaryModelClass, payload].concat(args));
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (!json.isFlatPrice) {
        json.value *= 100;
      }

      json.value = Number.parseFloat(json.value).toFixed(2);

      for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
        args[_key2 - 2] = arguments[_key2];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    }
  });

  _exports.default = _default;
});