define("lh4/metrics-adapters/user-guiding", ["exports", "ember-metrics/metrics-adapters/base", "lh4/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _base.default.extend({
    location: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    permissions: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    env: _environment.default,
    init: function init() {},
    identify: function identify(data) {
      if (data.email && window.userGuiding) {
        window.userGuiding.identify(data.email, _objectSpread(_objectSpread({}, data), {}, {
          app: 'LBM'
        }));
      }
    }
  });

  _exports.default = _default;
});