define("lh4/models/hospitality/customer/phone", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.phoneTypes = _exports.default = void 0;
  var phoneTypes = [{
    name: 'Primary',
    id: 0
  }, {
    name: 'Alternate',
    id: 1
  }, {
    name: 'Work',
    id: 2
  }, {
    name: 'Other',
    id: 3
  }];
  _exports.phoneTypes = phoneTypes;

  var _default = _model.default.extend({
    guid: (0, _attr.default)('string'),
    customerGuid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    type: (0, _attr.default)('string'),
    alternateType: (0, _attr.default)('string'),
    number: (0, _attr.default)('phone'),
    isActive: (0, _attr.default)('boolean'),
    customer: (0, _relationships.belongsTo)('hospitality/customer'),
    phoneTypes: phoneTypes.map(function (_ref) {
      var name = _ref.name;
      return {
        name: name,
        value: name
      };
    })
  });

  _exports.default = _default;
});