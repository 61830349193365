define("lh4/mirage/factories/epro-gratuity", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    locationId: 1,
    isActive: true,
    name: 'Test Gratuity 1',
    isFlatAmount: true,
    value: '0.0500',
    guestThreshold: 4,
    taxSetGutaxSetGuid: function taxSetGutaxSetGuid() {
      return _faker.default.datatype.uuid();
    },
    createdAt: function createdAt() {
      return new Date().toISOString();
    },
    updatedAt: function updatedAt() {
      return new Date().toISOString();
    }
  });

  _exports.default = _default;
});