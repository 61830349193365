define("lh4/templates/components/reports/report-table-drill-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zx3HWKVV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"drill-down-report-table-wrapper\"],[11,\"class\",[29,[\"drill-down-report-\",[22,\"generatedReportType\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"currentReport\",\"trees\"]],[24,[\"length\"]],1],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui clearing header report-table-header\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h3\",true],[8],[1,[24,[\"currentReport\",\"title\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[7,\"div\",true],[10,\"id\",\"report-table-data-wrapper\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"ui/tree/report-tree\",null,[[\"tree\",\"fetchFunction\"],[[24,[\"tree\"]],[24,[\"fetchFunction\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"iframe\",true],[10,\"class\",\"print-frame\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reports/report-table-drill-down.hbs"
    }
  });

  _exports.default = _default;
});