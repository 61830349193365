define("lh4/components/ui/dropdown/sort-helper", ["exports", "lh4/utils/compare"], function (_exports, _compare) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNames: ['ui', 'buttons'],
    _sortBy: null,
    _sortDesc: false,
    isVisible: Ember.computed('sortable.[]', 'sortByMap.[]', function getter() {
      if (!this.get('sortable.length') || !this.get('sortByMap.length')) {
        return false;
      }

      return true;
    }),
    sortByValues: Ember.computed('sortByMap.[]', function getter() {
      var sortByMap = this.get('sortByMap');

      if (sortByMap) {
        return sortByMap.map(function (value) {
          if (typeof value === 'string') {
            return _defineProperty({}, value.camelize(), value);
          }

          return value;
        });
      }

      return false;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('sortDesc')) {
        this.set('_sortDesc', this.get('sortDesc'));
      }

      if (this.get('sortBy')) {
        this.set('_sortBy', this.get('sortBy'));
      }
    },
    updateSortable: function updateSortable() {
      var sortBy = this.get('_sortBy');

      if (!sortBy) {
        return;
      }

      var preSort = this.get('sortable').toArray(); // Function to remove non-numeric characters and convert to number

      var toNumericValue = function toNumericValue(value) {
        if (value == null) {
          // Checks for both null and undefined using type coercion
          return value;
        }

        if (typeof value === 'string') {
          var numericString = value.replace(/[^0-9.-]/g, '');
          return !Number.isNaN(numericString) ? parseFloat(numericString) : value;
        }

        return value;
      };

      var sorted = preSort.sort(function (a, b) {
        var _a = a[sortBy];
        var _b = b[sortBy]; // Convert values to numeric if possible

        _a = toNumericValue(_a);
        _b = toNumericValue(_b); // Handling null and undefined values

        if (_a == null && _b == null) {
          return 0;
        }

        if (_a == null) {
          return 1;
        }

        if (_b == null) {
          return -1;
        } // Perform sorting based on type


        if (typeof _a === 'number' && typeof _b === 'number') {
          return _a - _b; // For numeric sorting
        }

        return (0, _compare.default)(String(_a), String(_b)); // For string sorting
      });

      if (this.get('_sortDesc')) {
        sorted.reverse();
      }

      this.set('sortable', sorted);
    },
    actions: {
      sortBy: function sortBy(selected) {
        if (this.get('_sortBy') === selected) {
          this.setSortOrder(this.toggleProperty('_sortDesc') ? 'DESC' : 'ASC');
        } else {
          this.set('_sortBy', selected);
          this.setSortBy(selected);
        }

        this.updateSortable();
      },
      sortOrder: function sortOrder() {
        this.setSortOrder(this.toggleProperty('_sortDesc') ? 'DESC' : 'ASC');
        this.updateSortable();
      }
    }
  });

  _exports.default = _default;
});