define("lh4/routes/authenticated/epro/manage-employees", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    titleToken: Ember.computed('location.model.merchantId', 'launchDarkly.featureFlags', function titleToken() {
      if (this.can.can('manage workforce for echo-pro/workforce', this.location.model)) {
        return 'Workforce';
      }

      return 'Manage Employees';
    })
  });

  _exports.default = _default;
});