define("lh4/templates/components/ui/icons/sidebar/skytab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iDI1j3dl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M22 12.001C22 17.5238 17.5228 22.001 12 22.001C6.47715 22.001 2 17.5238 2 12.001C2 6.47813 6.47715 2.00098 12 2.00098C17.5228 2.00098 22 6.47813 22 12.001ZM20 12.001C20 16.4193 16.4183 20.001 12 20.001C7.58172 20.001 4 16.4193 4 12.001C4 7.5827 7.58172 4.00098 12 4.00098C16.4183 4.00098 20 7.5827 20 12.001Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M12.0568 6.00098C12.0568 8.83556 9.82418 11.1334 7 11.1334C9.39876 11.1334 11.416 14.0481 12 18.001C12.584 14.0481 14.6012 11.1334 17 11.1334C14.1758 11.1334 12.0568 8.83556 12.0568 6.00098Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/skytab.hbs"
    }
  });

  _exports.default = _default;
});