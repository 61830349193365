define("lh4/routes/authenticated/locations/location/hospitality/customize-menu", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROUTE_PREFIX = 'authenticated.locations.location.hospitality.customize-menu.category';

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Customize Menu',
    requiredPermissions: 'manage hospitality/menu',
    model: function model() {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        menuCategories: this.store.query('hospitality/menu-category', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('hospitality/menu-category');
        }),
        location: location
      });
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('hospitality/menu-category');
    },
    actions: {
      loading: function loading(transition) {
        if (transition.targetName === "".concat(ROUTE_PREFIX, ".edit-category.edit")) {
          this.controllerFor(this.routeName).set('disableCreateItem', false);
        }
      },
      willTransition: function willTransition(transition) {
        if (transition.targetName === "".concat(ROUTE_PREFIX, ".category.create-category")) {
          this.controllerFor(this.routeName).set('disableCreateItem', true);
          this.controllerFor(this.routeName).set('selectedCategory', null);
        } else {
          this.controllerFor(this.routeName).set('disableCreateItem', false);
        }
      }
    }
  });

  _exports.default = _default;
});