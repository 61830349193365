define("lh4/templates/components/ui/search/list-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2LTTK+uM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"autocomplete\",\"placeholder\",\"value\",\"maxlength\",\"key-up\",\"insert-newline\"],[[24,[\"autocomplete\"]],[24,[\"placeholder\"]],[24,[\"proxy\"]],[24,[\"maxLength\"]],[28,\"action\",[[23,0,[]],\"searchTerm\",false],null],[28,\"action\",[[23,0,[]],\"searchTerm\",true],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"proxy\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\",false],[12,\"class\",\"times large circle outline link icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"clearTerm\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"i\",false],[12,\"class\",\"search large link icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"searchTerm\",true]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/search/list-search.hbs"
    }
  });

  _exports.default = _default;
});