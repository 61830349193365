define("lh4/utils/period-to-moment", ["exports", "lh4/enums/date-range-title", "moment"], function (_exports, _dateRangeTitle, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = periodToMoment;

  /**
   * Returns two moment objects based on a time period
   *
   * @method   periodToMoment
   *
   * @param    {string} period - String that states the time period
   * @param    {string} [eod] - Optional end of day time to offset by
   * @param    {Number} [weekStart] - Optional starting day of week to offset by
   * @returns  {Object} Object containing startsAt and endsAt moment objects
   */
  function periodToMoment(period, eod, weekStart) {
    var startsAt = (0, _moment.default)().startOf('day');
    var endsAt = (0, _moment.default)().endOf('day');

    switch (period) {
      case _dateRangeTitle.DATE_RANGE_TITLE.CUSTOM:
        startsAt = null;
        endsAt = null;
        break;

      case _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT:
        break;

      case _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST:
        startsAt.subtract(1, 'day');
        endsAt.subtract(1, 'day');
        break;

      case _dateRangeTitle.DATE_RANGE_TITLE.WEEK.TO_DATE:
        startsAt.startOf('week');
        break;

      case _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST:
        startsAt.subtract(1, 'week').startOf('week');
        endsAt.subtract(1, 'week').endOf('week');
        break;

      case _dateRangeTitle.DATE_RANGE_TITLE.MONTH.TO_DATE:
        startsAt.startOf('month');
        break;

      case _dateRangeTitle.DATE_RANGE_TITLE.MONTH.LAST:
        startsAt.subtract(1, 'month').startOf('month');
        endsAt.subtract(1, 'month').endOf('month');
        break;

      case _dateRangeTitle.DATE_RANGE_TITLE.YEAR.TO_DATE:
        startsAt.startOf('year');
        break;

      case _dateRangeTitle.DATE_RANGE_TITLE.YEAR.LAST:
        startsAt.subtract(1, 'year').startOf('year');
        endsAt.subtract(1, 'year').endOf('year');
        break;

      default:
        break;
    }

    if (startsAt && endsAt) {
      if (eod) {
        // There is a bug with moment-timezone when UTC day != timezone day, so this is a required workaround.
        // https://github.com/moment/moment-timezone/issues/119
        var eodTime = _moment.default.utc(eod, ['hh:mmA', 'HH:mm'], true);

        var todayEod = (0, _moment.default)().startOf('day').set({
          hours: eodTime.hours(),
          minutes: eodTime.minutes()
        });
        var now = (0, _moment.default)();

        if (now.isBefore(todayEod) && (period === _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT || period === _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST)) {
          startsAt.subtract(1, 'day');
          endsAt.subtract(1, 'day');
        }

        var todayEodHours = todayEod.hours();
        var todayEodMinutes = todayEod.minutes();
        startsAt.add(todayEodHours, 'hours').add(todayEodMinutes, 'minutes');
        endsAt.add(todayEodHours, 'hours').add(todayEodMinutes, 'minutes');
      }

      if (weekStart && (period === _dateRangeTitle.DATE_RANGE_TITLE.WEEK.TO_DATE || period === _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST)) {
        if ((0, _moment.default)().day() < weekStart) {
          startsAt.subtract(1, 'week');

          if (period === _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST) {
            endsAt.subtract(1, 'week');
          }
        }

        startsAt.add(weekStart, 'days');

        if (period === _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST) {
          endsAt.add(weekStart, 'days');
        }
      }
    }

    return {
      startsAt: startsAt,
      endsAt: endsAt
    };
  }
});