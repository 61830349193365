define("lh4/models/hospitality/menu-item", ["exports", "ember-data/model", "ember-data/attr", "moment", "ember-cp-validations"], function (_exports, _model, _attr, _moment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    longName: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Item name'
    }),
    revenueClass: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Revenue class'
    }),
    priceCategoryId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Item group'
    }),
    miDefaultQty: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 1,
      description: 'Default quantity'
    }),
    displayPrice: (0, _emberCpValidations.validator)('number', {
      disabled: Ember.computed('model.usesSetPrice', function getter() {
        return !this.model.usesSetPrice;
      }),
      allowString: true,
      allowNone: false,
      gte: 0,
      description: 'Unique price'
    })
  });

  var _default = _model.default.extend(Validations, {
    locationId: (0, _attr.default)('number'),
    canonicalItemId: (0, _attr.default)('string', {
      defaultValue: null
    }),
    cost: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    courseGroupId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    description: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    displayPrice: (0, _attr.default)('number'),
    doNotPrintItem: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    editedAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return _moment.default.utc();
      }
    }),
    enteredAt: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return _moment.default.utc();
      }
    }),
    freshSheetThreshold: (0, _attr.default)('number', {
      defaultValue: null
    }),
    genreId: (0, _attr.default)('number', {
      defaultValue: null
    }),
    hasSpecialRequest: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isOpenItem: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isSoldByWeight: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    longName: (0, _attr.default)('string'),
    menuDescription: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    miDefaultQty: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    miSku: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    overridesCourse: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    parCount: (0, _attr.default)('number', {
      defaultValue: null
    }),
    preferredCourseId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    price: (0, _attr.default)('number', {
      defaultValue: null
    }),
    priceCategoryId: (0, _attr.default)('number'),
    revenueClass: (0, _attr.default)('number'),
    rowguid: (0, _attr.default)('string'),
    tareWeight: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    usesSetPrice: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    // use price set on item, not on item group
    menuItemId: (0, _attr.default)(),
    menuItemsChoiceSets: (0, _attr.default)(),
    menuItemsCategories: (0, _attr.default)(),
    menuItemPrinters: (0, _attr.default)()
  });

  _exports.default = _default;
});