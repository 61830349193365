define("lh4/components/amcharts3/line-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var SEMANTIC_BLUE = '#009bf4';
  var BORDER_GREY_2 = '#E5E5E5';
  var DARK_GREY = '#333333';
  var CATEGORY_AXIS_DEFAULT = {
    axisColor: BORDER_GREY_2,
    fillAlpha: 1,
    fontSize: 12,
    gridAlpha: 1,
    gridColor: BORDER_GREY_2,
    gridCount: 0,
    gridPosition: 'start',
    labelFrequency: 3,
    minorGridAlpha: 1,
    tickLength: 1,
    titleFontSize: 12
  };
  var VALUE_AXIS_DEFAULT = {
    axisAlpha: 0,
    axisColor: BORDER_GREY_2,
    gridAlpha: 1,
    gridColor: BORDER_GREY_2,
    minHorizontalGap: 80,
    offset: 8,
    titleFontSize: 0,
    titleRotation: -2,
    zeroGridAlpha: 0
  };
  var CHART_CURSOR_DEFAULT = {
    enabled: true,
    cursorColor: SEMANTIC_BLUE
  };
  var GRAPH_DEFAULT = {
    bullet: 'round',
    type: 'line'
  };

  var _default = Ember.Component.extend({
    classNames: ['line-chart'],
    options: {},
    init: function init() {
      this._super.apply(this, arguments);

      this.chart = null;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _this$options = this.options,
          _this$options$balloon = _this$options.balloonText,
          balloonText = _this$options$balloon === void 0 ? '[[value]]' : _this$options$balloon,
          categoryField = _this$options.categoryField,
          categoryAxis = _this$options.categoryAxis,
          chartScrollbar = _this$options.chartScrollbar,
          dataDateFormat = _this$options.dataDateFormat,
          listeners = _this$options.listeners,
          parseDates = _this$options.parseDates,
          _this$options$valueAx = _this$options.valueAxesOptions,
          valueAxesOptions = _this$options$valueAx === void 0 ? {} : _this$options$valueAx,
          valueField = _this$options.valueField,
          _this$options$graphs = _this$options.graphs,
          graphs = _this$options$graphs === void 0 ? [{
        balloonText: balloonText,
        valueField: valueField,
        title: 'graph 1'
      }] : _this$options$graphs,
          _this$options$colors = _this$options.colors,
          colors = _this$options$colors === void 0 ? [SEMANTIC_BLUE] : _this$options$colors,
          chartCursor = _this$options.chartCursor,
          _this$options$plotAre = _this$options.plotAreaBorderAlpha,
          plotAreaBorderAlpha = _this$options$plotAre === void 0 ? 1 : _this$options$plotAre,
          _this$options$legend = _this$options.legend,
          legend = _this$options$legend === void 0 ? {
        backgroundAlpha: 1,
        borderAlpha: 1,
        borderColor: BORDER_GREY_2,
        color: DARK_GREY,
        enabled: false,
        rollOverColor: SEMANTIC_BLUE,
        tabIndex: 14,
        useGraphSettings: true,
        width: 4
      } : _this$options$legend; // Might max sense to revisit if we start having more than a singular axes...
      // or make a different component.

      var valueAxes = [_objectSpread(_objectSpread({}, VALUE_AXIS_DEFAULT), valueAxesOptions)];
      var chart = window.AmCharts.makeChart(this.element, {
        type: 'serial',
        path: '/amcharts',
        dataProvider: this.data,
        allLabels: [],
        backgroundAlpha: 0.8,
        borderColor: BORDER_GREY_2,
        balloon: {
          color: DARK_GREY,
          fillAlpha: 1,
          fillColor: '#fff',
          shadowAlpha: 0.06
        },
        categoryAxis: _objectSpread(_objectSpread({
          parseDates: parseDates
        }, CATEGORY_AXIS_DEFAULT), categoryAxis),
        categoryField: categoryField,
        chartCursor: _objectSpread(_objectSpread({}, CHART_CURSOR_DEFAULT), chartCursor),
        chartScrollbar: chartScrollbar,
        color: DARK_GREY,
        colors: colors,
        dataDateFormat: dataDateFormat,
        graphs: graphs.map(function (graph) {
          return _objectSpread(_objectSpread({}, GRAPH_DEFAULT), graph);
        }),
        guides: [],
        handDrawScatter: 1,
        hideCredits: true,
        legend: legend,
        listeners: listeners,
        plotAreaBorderAlpha: plotAreaBorderAlpha,
        plotAreaBorderColor: BORDER_GREY_2,
        plotAreaFillAlphas: 1,
        startEffect: 'easeInSine',
        touchClickDuration: -1,
        trendLines: [],
        valueAxes: valueAxes
      });
      this.chart = chart;
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.chart) {
        var _this$options2 = this.options,
            graphs = _this$options2.graphs,
            categoryAxis = _this$options2.categoryAxis,
            valueAxesOptions = _this$options2.valueAxesOptions;
        var valueAxes = [_objectSpread(_objectSpread({}, VALUE_AXIS_DEFAULT), valueAxesOptions)];
        this.chart.valueAxes = valueAxes;
        this.chart.categoryAxis = _objectSpread(_objectSpread({}, CATEGORY_AXIS_DEFAULT), categoryAxis);
        this.chart.graphs = (graphs || []).map(function (graph) {
          return _objectSpread(_objectSpread({}, GRAPH_DEFAULT), graph);
        });
        this.chart.dataProvider = this.data;
        this.chart.validateData();
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.chart) {
        this.chart.clear();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});