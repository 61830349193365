define("lh4/components/ui/s4/icons/checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg width="50" height="50" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00002 11.2L1.80002 7.00001L0.400024 8.40001L6.00002 14L18 2.00001L16.6 0.600006L6.00002 11.2Z" fill="#FFFFFF"/>
  </svg>
  
  */
  {
    id: "cg1xHHjJ",
    block: "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"50\"],[10,\"height\",\"50\"],[10,\"viewBox\",\"0 0 18 14\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n    \"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M6.00002 11.2L1.80002 7.00001L0.400024 8.40001L6.00002 14L18 2.00001L16.6 0.600006L6.00002 11.2Z\"],[10,\"fill\",\"#FFFFFF\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/checkmark.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});