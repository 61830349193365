define("lh4/templates/authenticated/social-media/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a3nMRiF5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui no-gutter grid\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide widescreen sixteen wide computer sixteen wide tablet sixteen wide mobile column full-height post-list\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/social-media/analytics.hbs"
    }
  });

  _exports.default = _default;
});