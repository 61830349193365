define("lh4/templates/components/ui/icons/internet-payments/risk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8T7EhmmK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"currentColor\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"data-test-icon\",\"risk\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M12 2L4 5V11.09C4 16.14 7.41 20.85 12 22C16.59 20.85 20 16.14 20 11.09V5L12 2ZM18 11.09C18 15.09 15.45 18.79 12 19.92C8.55 18.79 6 15.1 6 11.09V6.39L12 4.14L18 6.39V11.09Z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/risk.hbs"
    }
  });

  _exports.default = _default;
});