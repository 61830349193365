define("lh4/routes/authenticated/bank-account/verify-owner", ["exports", "ember-changeset", "ember-changeset-cp-validations", "lh4/mixins/changeset-transition-route-mixin"], function (_exports, _emberChangeset, _emberChangesetCpValidations, _changesetTransitionRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.session.ownerToken) {
        this.replaceWith('authenticated.bank-account.edit');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        owner: this.store.createRecord('verify-owner/owner')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model.owner),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(model.owner, validateFn, validationMap, {
        skipValidate: true
      });
      controller.set('changeset', changeset);
    }
  });

  _exports.default = _default;
});