define("lh4/models/gpatt/settings", ["exports", "ember-cp-validations", "ember-data", "ember-data/relationships"], function (_exports, _emberCpValidations, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'tip-1': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      message: 'Tip field should be a number.'
    }),
    'tip-2': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      message: 'Tip field should be a number.'
    }),
    'tip-3': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      message: 'Tip field should be a number.'
    }),
    'ratings-threshold-recipients': {
      description: 'Recipient name',
      validators: [(0, _emberCpValidations.validator)('array-unique-field', {
        uniqueField: 'name',
        uniqueFieldFilter: function uniqueFieldFilter(recipient) {
          return !!recipient.name;
        },
        dependentKeys: ['model.ratings-threshold-recipients.@each.name', 'model.ratings-threshold-recipients.[]']
      })]
    }
  });
  /**
   * Default values of this model should always be set and match default values from DB
   *
   * @see https://jira.shift4payments.com/browse/LH-14258
   * @see https://jira.shift4payments.com/browse/LH-17528
   */

  var _default = _emberData.default.Model.extend(Validations, {
    'tip-1': _emberData.default.attr('number', {
      defaultValue: 10
    }),
    'tip-2': _emberData.default.attr('number', {
      defaultValue: 15
    }),
    'tip-3': _emberData.default.attr('number', {
      defaultValue: 20
    }),
    'default-tip': _emberData.default.attr('string', {
      defaultValue: 'tip-2'
    }),
    'tip-calculation-method': _emberData.default.attr('string', {
      defaultValue: 'post-tax'
    }),
    'collect-more-info': _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    'collect-more-info-label': _emberData.default.attr('string', {
      defaultValue: 'Full name'
    }),
    'collect-more-info-message': _emberData.default.attr('string', {
      defaultValue: 'Please enter your name and email to receive special offers'
    }),
    'collect-ratings': _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    'ratings-threshold': _emberData.default.attr('number', {
      defaultValue: 5
    }),
    'ratings-threshold-recipients': (0, _relationships.hasMany)('gpatt/ratings-threshold-recipient'),
    'ratings-threshold-should-notify': _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    'allow-partial-payment': _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    'enable-tip-screen': _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    'enable-force-tip-selection': _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    'enable-signature-capture': _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    'allow-cash-payment': _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    'enable-order-at-the-table': _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    'enable-guests-in-ordering': _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    'show-brand-logo': _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    'brand-logo-uri': _emberData.default.attr('string', {
      defaultValue: ''
    }),
    addRecipient: function addRecipient() {
      return this.store.createRecord('gpatt/ratings-threshold-recipient', {
        settings: this
      });
    }
  });

  _exports.default = _default;
});