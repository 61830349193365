define("lh4/mirage/routes/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageAnalyticsServer;

  function setMirageAnalyticsServer(server) {
    server.get('/analytics/permissions', function () {
      return {
        enabled: true
      };
    });
    server.get('/analytics/sso', function () {
      return {
        url: 'http://localhost/analytics?token=test'
      };
    });
  }
});