define("lh4/utils/date/dates-overlap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * @param {Object} start1 - moment object
   * @param {Object} end1 - moment object
   * @param {Object} start2 - moment object
   * @param {Object} end2 - moment object
   * @returns {boolean} indicates if schedules overlap
   */
  var datesOverlap = function datesOverlap(start1, end1, start2, end2) {
    if (start1 > end1 || start2 > end2) {
      throw new Error('start date must be before end date');
    }

    return !(start1 < start2 && end1 <= start2 || start1 >= end2 && end1 > end2);
  };

  var _default = datesOverlap;
  _exports.default = _default;
});