define("lh4/components/charts/line/date-range-chart", ["exports", "jquery", "lh4/utils/objects-array-group-by-key", "moment", "d3-shape", "d3-scale", "d3-array", "d3-selection", "d3-time-format", "d3-format", "d3-axis", "d3-time"], function (_exports, _jquery, _objectsArrayGroupByKey, _moment, _d3Shape, _d3Scale, _d3Array, _d3Selection, _d3TimeFormat, _d3Format, _d3Axis, _d3Time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (!this.get('lineData')) {
        this.set('lineData', []);
        return;
      } // support dynamic media for yScaleVisibilityDynamic


      this.set('chart', this.initializeChart());
      var isYScaleVisible = this.get('isYScaleVisible');

      if (typeof isYScaleVisible !== 'boolean') {
        this.yScaleVisibilityDynamic();
        (0, _jquery.default)(window).on("resize.date-range-chart.#".concat(this.elementId), function () {
          return _this.yScaleVisibilityDynamic();
        });
      } else {
        this.set('_isYScaleVisible', isYScaleVisible);
      }

      (0, _jquery.default)(window).on("resize.date-range-chart.#".concat(this.elementId), function () {
        return _this._redraw();
      });

      this._redraw();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (!this.get('lineData')) {
        this.set('lineData', []);
        return;
      }

      this.set('chart', this.initializeChart());

      this._redraw();
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(window).off("resize.#".concat(this.elementId));

      this._super.apply(this, arguments);
    },
    options: {},

    /**
     * Data for graph lines
     * @prop {(Array.<Object>|Object.<Array.<Object>>)} - array of objects or object or arrays
     * @example Array of objects
     *   [ { time: '1514707200000', value: 10 } ]
     *
     * @example Object of arrays
     *   {
     *     likes: [ { time: '1514707200000', value: 1 } ],
     *     comments: [ { time: '1514707200000', value: 15 } ]
     *   }
     */
    lineData: [],
    colorOptions: [],
    tooltipClass: '',
    classNames: ['date-range-chart-wrapper'],
    classNameBindings: ['_isYScaleVisible::no-y-scale'],
    _isYScaleVisible: true,
    isYScaleVisible: true,
    yScaleVisibilityDynamic: function yScaleVisibilityDynamic() {
      var isYScaleVisible = this.get('isYScaleVisible');

      if (window.matchMedia("(".concat(isYScaleVisible, ")")).matches) {
        this.set('_isYScaleVisible', true);
      } else {
        this.set('_isYScaleVisible', false);
      }

      this._setChartDimensions();
    },

    /**
     * Minimum tick values on Y Scale
     */
    minYScale: 10,
    yAxisLabel: '',
    xAxisLabel: '',

    /**
     * Object key in lineData data objects that should be used for plotting X Axis
     */
    xKeyName: 'time',
    timeInterval: 'day',

    /**
     * Time Formatter function
     * It's consumed by d3 to format x axis label JS Date objects to strings
     */
    timeIntervalFormatter: Ember.computed('timeInterval', function getter() {
      var timeInterval = this.get('timeInterval');

      switch (timeInterval) {
        case 'day':
          return (0, _d3TimeFormat.utcFormat)('%b %d');

        default:
          return (0, _d3TimeFormat.utcFormat)('');
      }
    }),

    /**
     * Returns function that handles display of X Axis Label count
     *
     * .count(0, d) returns time interval count between dates. 0 is epoch beginning
     *   P.S. inside .count, not using modulus for comparison infinitely loops through time backwards
     *
     * @example timeInterval = day
     *   if (total ticks < (chart width / xAxisTickSpacing)) {	 *
     *     return utcDay formatter function. Displays a tick for each day
     *
     * 	 } else if (total ticks > (chart width / xAxisTickSpacing)) {
     *      return utcDay formatter with filter function
     *      filter function returns max fittable tix at even intervals
     *      .count is used to return dates at even intervals
     *
     *   } else if ((chart width / xAxisTickSpacing) <=2) {
     *      return utcDay formatter with filter function
     *      filter function returns first and last tick
     *   }
     */
    xAxisTimeIntervalTicks: Ember.computed('timeInterval', function getter() {
      var timeInterval = this.get('timeInterval');
      var chart = this.get('chart');
      var timeRange = chart.xScale.domain();

      switch (timeInterval) {
        default:
        case 'day':
          {
            var totalDays = _d3Time.utcDay.range(timeRange[0], timeRange[1]).length + 1;
            var fitsMaxTicks = Math.floor(chart.width / this.get('xAxisTickSpacing'));
            var ticks = _d3Time.utcDay;

            if (fitsMaxTicks <= 2) {
              ticks = _d3Time.utcDay.filter(function (d) {
                return _d3Time.utcDay.count((0, _d3Time.utcWeek)(d), d) % Math.floor(totalDays - 1) === 0;
              });
            } else if (fitsMaxTicks < totalDays) {
              ticks = _d3Time.utcDay.filter(function (d) {
                return _d3Time.utcDay.count(0, d) % Math.floor(totalDays / fitsMaxTicks) === 0;
              });
            }

            return ticks;
          }
      }
    }).volatile(),
    yAxisFormat: 'number',

    /**
     * Returns y Axis value formatting function, consumed by d3 internally
     */
    yAxisFormatter: Ember.computed('yAxisFormat', function getter() {
      var yAxisFormat = this.get('yAxisFormat');

      switch (yAxisFormat) {
        case 'number':
          return (0, _d3Format.format)('.0f');

        case 'decimal':
          return (0, _d3Format.format)('$.2f');

        case 'currency':
          return (0, _d3Format.format)('$.2f');

        case 'float':
        default:
          return function floatFormat(d) {
            if (d % 1 !== 0) {
              return (0, _d3Format.format)('.2f')(d);
            }

            return (0, _d3Format.format)('.0f')(d);
          };
      }
    }),
    xAxisTickSpacing: 60,
    yAxisTickSpacing: 30,
    chartWidth: 960,
    chartHeight: 500,
    isYSingleLine: Ember.computed('lineData.[]', function getter() {
      // if graph has single line, expects an array
      // if graph has multiple lines, expects object with key:array pairs
      return Array.isArray(this.get('lineData'));
    }),

    /**
     * Custom Name for Y axis Label
     */
    yName: '',

    /**
     * Custom mapping for y Axis axis label and color
     * @example changes totalViews label to Impressions
     * { 'Impressions': { key: 'totalViews'} }
     */
    yNamesMap: {},

    /**
     * Label display mapping function for chart with multiple lines
     * By default returns capitalized key, unless yNamesMap is provided for that key
     * @example
     *  yNamesMap: { 'Impressions': { key: 'totalViews' } }
     *  lineData: {
     *     likes: [ {...} ],
     *     totalViews: [ {...} ]
     *   }
     *
     * result = { Likes: { key: 'likes' }, Impressions: { key: 'totalViews' }}
     */
    _yNamesMap: Ember.computed('lineData.[],yNamesMap', function getter() {
      var dataKeys = Object.keys(this.get('lineData'));
      var yNamesMap = this.get('yNamesMap');
      return dataKeys.reduce(function (prev, curr) {
        var label = Object.keys(yNamesMap).find(function (newLabel) {
          return yNamesMap[newLabel].key === curr;
        });
        var newValue = label ? _defineProperty({}, label, yNamesMap[label]) : _defineProperty({}, curr.capitalize(), {
          key: curr
        });
        return Object.assign(prev, newValue);
      }, {});
    }),
    // format data from start of period to align xAxis labels
    _lineDataFormatted: Ember.computed('lineData.[],timeInterval', function getter() {
      var _ref5;

      // if Array, convert to object, so we could use the same line mapping functions
      var isYSingleLine = this.get('isYSingleLine');
      var lineData = this.get('lineData');
      var data = isYSingleLine ? _defineProperty({}, this.get('yName'), lineData) : lineData;
      var graphKeys = isYSingleLine ? [this.get('yName')] : Object.keys(lineData);
      var timeInterval = this.get('timeInterval');
      var formatted;

      switch (timeInterval) {
        case 'day':
        default:
          formatted = graphKeys.map(function (key) {
            return data[key].map(function (x) {
              return _defineProperty({
                time: new Date(parseInt(x.time, 10)).setUTCHours(0, 0, 0, 0)
              }, key, x.value);
            });
          });
      }

      var allData = (_ref5 = []).concat.apply(_ref5, _toConsumableArray(formatted));

      if (!isYSingleLine) {
        (0, _objectsArrayGroupByKey.default)(allData, this.get('xKeyName'));
      }

      return allData;
    }),
    // Takes either a list of colors, a function or an object with the mapping already in place
    _updateColorFunction: function _updateColorFunction() {
      var chart = this.get('chart');
      var colorOptions = this.get('colorOptions');

      if (typeof colorOptions === 'function') {
        return colorOptions;
      }

      if (Array.isArray(colorOptions)) {
        //  If an array is provided, map it to the domain
        var colorMap = {};
        var cColor = 0;

        if (chart.groupObjs) {
          Object.keys(chart.groupObjs).forEach(function (cName) {
            colorMap[cName] = colorOptions[cColor];
            cColor = (cColor + 1) % colorOptions.length;
          });
        }

        return function (group) {
          return colorMap[group];
        };
      }

      if (_typeof(colorOptions) === 'object') {
        // if an object is provided, assume it maps to  the colors
        return function (group) {
          return colorOptions[group];
        };
      }

      return [];
    },
    _mapDataToLineObjects: function _mapDataToLineObjects() {
      var chart = this.get('chart');
      var cY;
      chart.data = this.get('_lineDataFormatted');
      /**
       * Getter for x Axis value for data point
       *
       * @param {Object} d - chart.data data point
       * @returns {(string|number)} x Axis value for data point
       */

      chart.getXValue = function xAxisValue(d) {
        return d[chart.xName];
      };

      if (chart.yNames) {
        Object.keys(chart.yNames).forEach(function (yName) {
          chart.groupObjs[yName] = {
            yFunct: null,
            visible: null,
            objs: {}
          };
        });
      }
      /**
       * Getter for y Axis value for data point
       *
       * @param {Object} column - y Name
       * @returns {function} returns y Axis value for data point (there can be several y Axis values)
       */


      chart.getYValue = function yAxisValue(column) {
        return function datapoint(d) {
          return d[column];
        };
      }; // Transform array to object


      chart.yGetterFunctions = [];

      if (chart.yNames) {
        Object.keys(chart.yNames).forEach(function (yName) {
          cY = chart.groupObjs[yName];
          cY.visible = true;
          cY.yFunct = chart.getYValue(chart.yNames[yName].key);
        });
      }
    },
    _getYGetterFunctions: function _getYGetterFunctions() {
      var chart = this.get('chart');
      var yGetterFunctions = [];

      if (chart.groupObjs) {
        Object.keys(chart.groupObjs).forEach(function (yName) {
          var currentGroup = chart.groupObjs[yName];

          if (currentGroup.visible === true) {
            yGetterFunctions.push(currentGroup.yFunct);
          }
        });
      }

      return yGetterFunctions;
    },
    _getYScaleFunction: function _getYScaleFunction(yName) {
      var chart = this.get('chart');
      return function yScale(d) {
        return chart.yScale(chart.groupObjs[yName].yFunct(d));
      };
    },
    _getYMax: function _getYMax() {
      var chart = this.get('chart');
      return (0, _d3Array.max)([this.get('minYScale')].concat(_toConsumableArray(this._getYGetterFunctions().map(function (fn) {
        return (0, _d3Array.max)(chart.data, fn);
      }))));
    },

    /**
     * Toggles graph line
     *
     * @param {string} yName - line name
     */
    _toggleSeries: function _toggleSeries(yName) {
      var chart = this.get('chart');
      var cY = null;
      cY = chart.groupObjs[yName];
      cY.visible = !cY.visible;

      if (cY.visible === false) {
        cY.objs.legend.div.style('opacity', '0.3');
      } else {
        cY.objs.legend.div.style('opacity', '1');
      }

      this._redraw();
    },
    _drawAxes: function _drawAxes() {
      var chart = this.get('chart');
      chart.xAxisLabel = this.get('xAxisLabel');
      chart.yAxisLabel = this.get('yAxisLabel');
      var domainX = (0, _d3Array.extent)(chart.data, chart.getXValue); // extend x axes if there's only 1 data point

      if (domainX[0] === domainX[1]) {
        domainX = [domainX[0], (0, _moment.default)(domainX[1]).add(1, this.get('timeInterval'))];
      }

      chart.xScale = (0, _d3Scale.scaleUtc)().range([0, chart.width]).domain(domainX);
      chart.yScale = (0, _d3Scale.scaleLinear)().range([chart.height, 0]).domain([0, this._getYMax()]); // Create axis

      chart.objs.xAxis = (0, _d3Axis.axisBottom)().scale(chart.xScale).tickPadding(15).tickFormat(this.get('timeIntervalFormatter'));
      chart.objs.yAxis = (0, _d3Axis.axisLeft)().scale(chart.yScale).tickPadding(15).tickFormat(this.get('yAxisFormatter'));
      chart.objs.axes = {};
      chart.objs.axes.g = chart.objs.g.select('g.axes'); // Show axis

      chart.objs.axes.x = chart.objs.axes.g.select('g.x-axis').attr('transform', "translate(0,".concat(chart.height, ")")).call(chart.objs.xAxis).select('text.label').attr('x', chart.width / 2).attr('y', 30).style('text-anchor', 'middle').text(chart.xAxisLabel);
      chart.objs.axes.y = chart.objs.axes.g.select('g.y-axis').call(chart.objs.yAxis).select('text.label').attr('transform', 'rotate(-90)').attr('y', -42).attr('x', -chart.height / 2).attr('dy', '.71em').style('text-anchor', 'middle').text(chart.yAxisLabel);
    },
    initializeChart: function initializeChart() {
      var chart = this.set('chart', {});
      chart.groupObjs = {}; // The data organized by grouping and sorted as well as any metadata for the groups

      chart.objs = {
        mainDiv: null,
        chartDiv: null,
        g: null,
        xAxis: null,
        yAxis: null,
        tooltip: null,
        legend: null,
        scatterplot: null
      };
      chart.colorFunct = this._updateColorFunction();
      chart.xName = this.get('xKeyName');
      chart.yNames = this.get('isYSingleLine') ? _defineProperty({}, this.get('yName'), {
        key: [this.get('yName')]
      }) : this.get('_yNamesMap');
      chart.yFormatter = this.get('yAxisFormatter');
      var selector = "#".concat(this.elementId);
      chart.objs.mainDiv = (0, _d3Selection.select)(selector); // Capture the inner div for the chart (where the chart actually is)

      chart.selector = "".concat(selector, " .inner-box");
      chart.objs.chartDiv = (0, _d3Selection.select)(chart.selector);

      this._mapDataToLineObjects();

      this._setChartDimensions();

      this._drawAxes();

      this._drawData();

      this._drawTooltips();

      return chart;
    },
    _setChartDimensions: function _setChartDimensions() {
      var chart = this.get('chart');

      if (this.get('marginOptions')) {
        chart.margin = this.get('marginOptions');
      } else {
        chart.margin = {
          top: 15,
          right: 30,
          bottom: 30,
          left: 50
        };

        if (!this.get('_isYScaleVisible')) {
          chart.margin.left = 30;
          chart.margin.right = 30;
        }
      }

      chart.divWidth = this.get('chartWidth');
      chart.divHeight = this.get('chartHeight');
      chart.width = chart.divWidth - chart.margin.left - chart.margin.right;
      chart.height = chart.divHeight - chart.margin.top - chart.margin.bottom;
      chart.objs.chartDiv.select('.chart-area').attr('width', chart.width + (chart.margin.left + chart.margin.right)).attr('height', chart.height + (chart.margin.top + chart.margin.bottom));
      chart.objs.g = chart.objs.chartDiv.select('.chart-group').attr('transform', "translate(".concat(chart.margin.left, ",").concat(chart.margin.top, ")"));
    },
    _drawData: function _drawData() {
      var _this2 = this;

      var chart = this.get('chart');
      var cY = null;
      chart.objs.legend = chart.objs.mainDiv.select('.legend');

      if (this.get('isYSingleLine')) {
        chart.objs.legend.style('display', 'none');
      }

      chart.objs.g.select('.chart-lines').remove();
      chart.objs.g.append('g').attr('class', 'chart-lines');

      if (!chart.groupObjs) {
        return chart;
      } // draw lines, scatterplot, tooltips and append mouse events


      Object.keys(chart.groupObjs).forEach(function (yName) {
        cY = chart.groupObjs[yName]; // Define the line object

        cY.objs.line = {
          g: null,
          series: null
        };
        cY.objs.line.series = (0, _d3Shape.line)().x(function (d) {
          return chart.xScale(chart.getXValue(d));
        }).y(_this2._getYScaleFunction(yName)); // add g dom elements for each line

        cY.objs.g = chart.objs.g.select('.chart-lines').append('g'); // draw path from line data

        cY.objs.line.g = cY.objs.g.append('path').datum(chart.data).attr('class', 'line').attr('d', cY.objs.line.series).style('fill', 'none').style('stroke', chart.colorFunct(yName)).attr('data-series', yName); // Draw scatterplot

        cY.objs.scatterplot = {
          g: null
        };
        cY.objs.scatterplot.g = cY.objs.g.selectAll('dot').data(chart.data).enter().append('circle').attr('r', 3.5).attr('class', 'scatterdot').attr('cx', function (d) {
          return chart.xScale(chart.getXValue(d));
        }).attr('cy', function (d) {
          return chart.yScale(cY.yFunct(d));
        }); // Draw Legend

        cY.objs.legend = {};
        cY.objs.legend.div = chart.objs.legend.append('div').on('click', function () {
          return _this2._toggleSeries(yName);
        });
        cY.objs.legend.icon = cY.objs.legend.div.append('div').attr('class', 'series-marker').style('background-color', chart.colorFunct(yName));
        cY.objs.legend.text = cY.objs.legend.div.append('p').text(yName);
      });
      return chart;
    },
    _drawTooltips: function _drawTooltips() {
      var _this3 = this;

      var chart = this.get('chart'); // Create external div for tooltip (allows outside css customization)

      chart.objs.tooltip = chart.objs.chartDiv.select('.tooltips-container').classed(this.get('tooltipClass'), true).style('top', "".concat(chart.margin.top, "px")).style('left', "".concat(chart.margin.left, "px")).style('display', 'none');
      chart.objs.tooltipLine = chart.objs.tooltip.select('.tooltip-line');
      chart.objs.tooltipLegendWrapper = chart.objs.tooltip.select('.tooltip-legend-wrapper');
      chart.objs.tooltipLegendValue = chart.objs.tooltipLegendWrapper.select('.tooltip-legend');
      chart.objs.tooltip.selectAll('.tooltip-wrapper').remove();

      if (chart.groupObjs) {
        Object.keys(chart.groupObjs).forEach(function (yName) {
          var cY = chart.groupObjs[yName];
          var tooltip = chart.objs.tooltip.append('div').attr('class', 'tooltip-wrapper');
          cY.objs.tooltipInner = tooltip.append('div').attr('class', 'tooltip-inner');
          cY.objs.text = cY.objs.tooltipInner.append('span').attr('class', 'tooltip-value');
          cY.objs.tooltip = tooltip;
        });
      } // tooltip hover capture


      chart.objs.g.select('rect.overlay').attr('width', chart.width).attr('height', chart.height).on('mouseover', function () {
        chart.objs.tooltip.style('display', null);
      }).on('mouseout', function () {
        chart.objs.tooltip.style('display', 'none');
      }).on('mousemove', function () {
        _this3._tooltipHover(_d3Selection.event.target);
      });
    },
    _tooltipHover: function _tooltipHover(eventTarget) {
      var chart = this.get('chart');
      var minX = chart.xScale.invert((0, _d3Selection.mouse)(eventTarget)[0]);
      var target = (0, _d3Array.bisector)(chart.getXValue).left(chart.data, minX, 1);
      var tickLeft = chart.data[target - 1]; // fallback to tickLeft if theres only one tick

      var tickRight = chart.data[target] || chart.data[target - 1];
      var d;

      try {
        d = minX - chart.getXValue(tickLeft) > chart.getXValue(tickRight) - minX ? tickRight : tickLeft;
      } catch (e) {
        return;
      }

      var minY = chart.height;
      var leftPosition = chart.xScale(chart.getXValue(d));

      if (chart.groupObjs) {
        Object.keys(chart.groupObjs).forEach(function (yName) {
          var chartY = chart.groupObjs[yName];

          if (chartY.visible === false) {
            return;
          } // Move the tooltip


          chartY.objs.tooltip.style('left', "".concat(leftPosition, "px"));
          chartY.objs.tooltipInner.style('top', "".concat(chart.yScale(chartY.yFunct(d)), "px")); // Change the text

          chartY.objs.tooltip.select('.tooltip-value').text(chart.yFormatter(chartY.yFunct(d)));
          minY = Math.min(minY, chart.yScale(chartY.yFunct(d)));
        });
      } // Tooltip line to bottom of chart


      chart.objs.tooltipLegendWrapper.style('top', "".concat(chart.height, "px")).style('left', "".concat(leftPosition, "px"));
      chart.objs.tooltipLegendValue.text(this.get('timeIntervalFormatter')(new Date(d.time)));
      chart.objs.tooltipLine.style('top', "".concat(minY, "px")).style('left', "".concat(leftPosition, "px")).style('height', "".concat(chart.height - minY, "px"));
    },
    _redraw: function _redraw() {
      var chart = this.get('chart');
      chart.width = parseInt(chart.objs.chartDiv.style('width'), 10) - (chart.margin.left + chart.margin.right);
      chart.height = parseInt(chart.objs.chartDiv.style('height'), 10) - (chart.margin.top + chart.margin.bottom); // Update the range of the scale with new width/height

      chart.xScale.range([0, chart.width]).nice();
      chart.yScale.range([chart.height, 0]).domain([0, this._getYMax()]).nice();
      chart.objs.yAxis.tickSize(-chart.width, 0, 0);
      chart.objs.xAxis.ticks(this.get('xAxisTimeIntervalTicks')).tickSize(-chart.height, 0, 0);

      if (!chart.objs.g) {
        return false;
      }

      chart.objs.axes.g.select('.x-axis').attr('transform', "translate(0,".concat(chart.height, ")")).call(chart.objs.xAxis);
      chart.objs.axes.g.select('.x-axis .label').attr('x', chart.width / 2);
      chart.objs.axes.g.select('.y-axis').call(chart.objs.yAxis);
      chart.objs.axes.g.select('.y-axis .label').attr('x', -chart.height / 2); // Force D3 to recalculate and update the line

      if (chart.groupObjs) {
        Object.keys(chart.groupObjs).forEach(function (yName) {
          var cY = chart.groupObjs[yName];

          if (cY.visible === true) {
            cY.objs.line.g.attr('d', cY.objs.line.series).style('display', null);
            cY.objs.scatterplot.g.style('display', null);
            cY.objs.tooltip.style('display', null);
            cY.objs.scatterplot.g.attr('cx', function (d) {
              return chart.xScale(chart.getXValue(d));
            }).attr('cy', function (d) {
              return chart.yScale(cY.yFunct(d));
            });
          } else {
            cY.objs.line.g.style('display', 'none');
            cY.objs.scatterplot.g.style('display', 'none');
            cY.objs.tooltip.style('display', 'none');
          }
        });
      }

      chart.objs.chartDiv.select('svg').attr('width', chart.width + (chart.margin.left + chart.margin.right)).attr('height', chart.height + (chart.margin.top + chart.margin.bottom));
      chart.objs.g.select('.overlay').attr('width', chart.width).attr('height', chart.height);

      this._drawTooltips();

      return true;
    }
  });

  _exports.default = _default;
});