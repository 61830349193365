define("lh4/metrics-adapters/mixpanel-custom", ["exports", "ember-metrics/metrics-adapters/mixpanel"], function (_exports, _mixpanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixpanel.default.extend({
    toStringExtension: function toStringExtension() {
      return 'MixpanelCustom';
    },
    addGroup: function addGroup(_ref) {
      var group = _ref.group,
          id = _ref.id;

      if (group && id) {
        window.mixpanel.add_group(group, id);
      }
    },
    addToUserPropertyList: function addToUserPropertyList(_ref2) {
      var list = _ref2.list,
          value = _ref2.value;

      if (list && value) {
        window.mixpanel.people.union(list, value);
      }
    },
    reset: function reset() {
      window.mixpanel.reset();
    },
    addContext: function addContext(data) {
      window.mixpanel.register(data);
    }
  });

  _exports.default = _default;
});