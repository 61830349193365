define("lh4/components/epro/menu/department-form", ["exports", "lh4/mixins/components/ui/color-picker/color-picker-mapping"], function (_exports, _colorPickerMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_colorPickerMapping.default, {
    classNames: ['department-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedDynamicPriceGuid: Ember.computed('firstDynamicPriceGuid', {
      get: function get() {
        return this.get('currentDynamicPriceGuid') || this.get('firstDynamicPriceGuid');
      },
      set: function set(key, value) {
        return this.set('currentDynamicPriceGuid', value);
      }
    }),
    minimumPrice: Ember.computed('department', function getter() {
      var prices = (this.department.get('items') || []).map(function (item) {
        return item.get('price');
      });
      return prices.length === 0 ? 10000000 : Math.min.apply(Math, _toConsumableArray(prices));
    }),
    courseOptions: Ember.computed('courses', function getter() {
      return [{
        name: 'No Course',
        value: null
      }].concat(_toConsumableArray(this.courses.map(function (_ref) {
        var name = _ref.name,
            value = _ref.guid,
            isActive = _ref.isActive;
        return {
          name: name,
          value: value,
          isActive: isActive
        };
      })));
    }),
    timeOptions: Ember.computed('intradayPeriodOptions', function getter() {
      return this.intradayPeriodOptions.length > 0 ? ['All Day', 'Day Parts', 'Custom Schedule'] : ['All Day', 'Custom Schedule'];
    }),
    actions: {
      handleDynamicPriceGuidChange: function handleDynamicPriceGuidChange(guid) {
        this.set('selectedDynamicPriceGuid', guid);
      },
      handleAvailabilityTimeOptionChange: function handleAvailabilityTimeOptionChange(timeOption) {
        this.department.availabilityChangeset.set('timeOption', timeOption);

        if (timeOption === 'Custom Schedule') {
          if (this.department.availabilityChangeset.scheduleChangesets.filterBy('isVoided', false).length === 0) {
            var initialSchedule = this.store.createRecord('echo-pro/availability-price/schedule', {
              guid: null,
              locationId: this.location.model.id,
              scheduleNumber: 1,
              scheduleName: 'Schedule 1'
            });
            var changeset = this.buildChildChangeset(initialSchedule);
            this.department.availabilityChangeset.scheduleChangesets.pushObject(changeset);
          }
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setColorsFromStylesheet();
    }
  });

  _exports.default = _default;
});