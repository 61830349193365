define("lh4/models/hospitality/customer/email", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailTypes = _exports.default = void 0;
  var emailTypes = [{
    name: 'Primary',
    id: 0
  }, {
    name: 'Alternate',
    id: 1
  }, {
    name: 'Work',
    id: 2
  }, {
    name: 'Other',
    id: 3
  }];
  _exports.emailTypes = emailTypes;

  var _default = _model.default.extend({
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    customerGuid: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    alternateType: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('string'),
    externalId: (0, _attr.default)('string'),
    bounced: (0, _attr.default)('boolean'),
    customer: (0, _relationships.belongsTo)('hospitality/customer'),
    emailTypes: emailTypes.map(function (_ref) {
      var name = _ref.name;
      return {
        name: name,
        value: name
      };
    })
  });

  _exports.default = _default;
});