define("lh4/controllers/authenticated/add-on-devices/wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    defaultPrinter: Ember.computed('model.printers.@each.isDefault', function getter() {
      return this.get('model.printers').find(function (printer) {
        return printer.get('isDefault');
      });
    }),
    actions: {
      cancelWizard: function cancelWizard() {
        this.get('model.newTerminal').rollbackAttributes();
        this.transitionToRoute('authenticated.add-on-devices.tableside');
      }
    }
  });

  _exports.default = _default;
});