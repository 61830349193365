define("lh4/templates/components/ui/form-fields/checkbox-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eb7GEHjy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"fieldClasses\"]],[8],[0,\"\\n\\t\"],[7,\"input\",true],[10,\"class\",\"hidden\"],[11,\"disabled\",[28,\"if\",[[24,[\"disabled\"]],\"disabled\",null],null]],[11,\"checked\",[28,\"get\",[[24,[\"changeset\"]],[24,[\"property\"]]],null]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"onChange\"],null]],[11,\"name\",[22,\"name\"]],[11,\"type\",[22,\"type\"]],[8],[9],[0,\"\\n\\t\"],[7,\"label\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"label\"],false],[0,\"\\n\\t\\t\"],[14,1,[[28,\"hash\",null,[[\"rawLabel\"],[[28,\"component\",[\"ui/shared/yield-basic-no-tag\"],null]]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showErrorTooltip\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted red segment error-message\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"get\",[[28,\"get\",[[24,[\"changeset\",\"error\"]],[24,[\"property\"]]],null],\"validation\"],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/checkbox-field.hbs"
    }
  });

  _exports.default = _default;
});