define("lh4/components/epro/menu/availability-price/date-override-form", ["exports", "moment", "lh4/enums/date-time-format"], function (_exports, _moment, _dateTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var DATEPICKER_WIDTH = {
    MOBILE: 200,
    DESKTOP: 200
  };
  var DEFAULT_OVERRIDE_PERIOD_LENGTH_DAYS = 6;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    location: Ember.inject.service(),
    media: Ember.inject.service(),
    classNames: ['ui', 'column', 'sixteen', 'wide', 'stackable', 'date-override-form'],
    ranges: [],
    property: 'overrideDates',
    toggleProperty: 'isDateOverrideEnabled',
    toggleLabel: 'Enable Date Overrides',
    addLabel: 'Add Date',
    groupLabel: 'Date',
    oneCalendarWidth: Ember.computed('media.isMobile', function getter() {
      return this.media.isMobile ? DATEPICKER_WIDTH.MOBILE : DATEPICKER_WIDTH.DESKTOP;
    }),
    format: _dateTimeFormat.default.LOCALIZED_DATE_ONLY,
    baseFormat: 'YYYY-MM-DD',
    overrideDates: Ember.computed('changeset', 'property', function getter() {
      return (this.changeset.get(this.property) || []).sort(function (a, b) {
        return (0, _moment.default)(a.beginDate).diff((0, _moment.default)(b.beginDate));
      });
    }),
    hasError: Ember.computed('changeset.errors.[]', function getter() {
      var _this = this;

      return this.changeset.errors.some(function (_ref) {
        var key = _ref.key;
        return key === _this.property;
      });
    }),
    overlapErrorMessage: Ember.computed('changeset.errors.[]', function getter() {
      var overlaps = this.checkOverlap();

      if (!overlaps || overlaps.length === 0) {
        return null;
      }

      return "Date range \"".concat((0, _moment.default)(overlaps[0][0].beginDate).format(_dateTimeFormat.default.LOCALIZED_DATE_ONLY), " - ").concat((0, _moment.default)(overlaps[0][0].endDate).format(_dateTimeFormat.default.LOCALIZED_DATE_ONLY), "\" overlaps date range \"").concat((0, _moment.default)(overlaps[0][1].beginDate).format(_dateTimeFormat.default.LOCALIZED_DATE_ONLY), " - ").concat((0, _moment.default)(overlaps[0][1].endDate).format(_dateTimeFormat.default.LOCALIZED_DATE_ONLY), "\"");
    }),
    checkOverlap: function checkOverlap() {
      if (!Array.isArray(this.overrideDates) || this.overrideDates.length < 2) {
        return [];
      }

      var sortedPeriods = _toConsumableArray(this.overrideDates).sort(function (a, b) {
        return (0, _moment.default)(a.beginDate).diff((0, _moment.default)(b.beginDate));
      }).filter(function (period) {
        return period.beginDate && period.endDate;
      });

      var overlaps = sortedPeriods.reduce(function (acc, _ref2, index) {
        var beginDate = _ref2.beginDate,
            endDate = _ref2.endDate;

        if (index === 0) {
          return acc;
        }

        var prev = sortedPeriods[index - 1];

        if ((0, _moment.default)(beginDate).isSameOrBefore(prev.endDate)) {
          acc.push([prev, {
            beginDate: beginDate,
            endDate: endDate
          }]);
        }

        return acc;
      }, []);
      return overlaps;
    },
    addDateOverride: function addDateOverride() {
      var _this2 = this;

      if (!this.overrideDates) {
        this.changeset.set(this.property, []);
      }

      var from = Math.max.apply(Math, _toConsumableArray(this.overrideDates.map(function (_ref3) {
        var endDate = _ref3.endDate;
        return (0, _moment.default)(endDate, _this2.baseFormat);
      })).concat([(0, _moment.default)()]));
      var overridePeriodStart = (0, _moment.default)(from).add(1, 'days');
      var overridePeriodEnd = (0, _moment.default)(overridePeriodStart).add(DEFAULT_OVERRIDE_PERIOD_LENGTH_DAYS, 'days');
      this.overrideDates.pushObject({
        beginDate: overridePeriodStart.format(this.baseFormat),
        endDate: overridePeriodEnd.format(this.baseFormat)
      });
    },
    setDates: function setDates(id, beginDate, endDate) {
      this.changeset.set("".concat(this.property, ".").concat(id, ".beginDate"), beginDate.format(this.baseFormat));
      this.changeset.set("".concat(this.property, ".").concat(id, ".endDate"), endDate.format(this.baseFormat));
      return this.changeset.validate();
    },
    actions: {
      addDateOverride: function addDateOverride() {
        this.addDateOverride();
        return this.changeset.validate();
      },
      deleteDateOverride: function deleteDateOverride(record) {
        this.overrideDates.removeObject(record);

        if (this.overrideDates.length === 0) {
          // Automatically turn off the toggle when removing the last range
          this.changeset.set(this.toggleProperty, false);
        }

        return this.changeset.validate();
      },
      onAfterSelect: function onAfterSelect(record, event) {
        if (event.config && event.config.startDate && event.config.endDate) {
          var _event$config = event.config,
              beginDate = _event$config.startDate,
              endDate = _event$config.endDate;

          if (beginDate.isValid() && endDate.isValid() && beginDate.isSameOrBefore(endDate)) {
            var dates = this.changeset.get(this.property);
            var id = dates.findIndex(function (_ref4) {
              var bd = _ref4.beginDate,
                  ed = _ref4.endDate;
              return bd === record.beginDate && ed === record.endDate;
            });
            this.setDates(id, beginDate, endDate);
          }
        }

        return true;
      },
      onBlur: function onBlur(record, event) {
        if (event.target.value) {
          var _event$target$value$s = event.target.value.split(' - '),
              _event$target$value$s2 = _slicedToArray(_event$target$value$s, 2),
              from = _event$target$value$s2[0],
              to = _event$target$value$s2[1];

          var beginDate = (0, _moment.default)(from, this.format);
          var endDate = (0, _moment.default)(to, this.format);
          var id = this.overrideDates.findIndex(function (_ref5) {
            var bd = _ref5.beginDate,
                ed = _ref5.endDate;
            return bd === record.beginDate && ed === record.endDate;
          });

          if (beginDate.isValid() && endDate.isValid()) {
            if (beginDate.isAfter(endDate)) {
              var _ref6 = [endDate, beginDate];
              beginDate = _ref6[0];
              endDate = _ref6[1];
            }

            this.setDates(id, beginDate, endDate);
          } else {
            this.setDates(id, (0, _moment.default)(record.beginDate, this.format), (0, _moment.default)(record.endDate, this.format));
          }
        }

        return true;
      },
      toggleDateOverride: function toggleDateOverride() {
        if (!this.overrideDates || !this.overrideDates.length) {
          this.addDateOverride();
        }

        this.changeset.set(this.toggleProperty, !this.changeset.get(this.toggleProperty));
        return this.changeset.validate();
      }
    }
  });

  _exports.default = _default;
});