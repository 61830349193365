define("lh4/constants/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BLOCKED_ACCOUNT_STATUSES = _exports.APPROVED_ACCOUNT_STATUSES = _exports.PENDING_ACCOUNT_STATUSES = void 0;
  // https://shift4.atlassian.net/wiki/spaces/IS/pages/17373089/Merchant+Account+Statuses
  var PENDING_ACCOUNT_STATUSES = Object.freeze([100, 125, 375, 390, 400, 425, 500, 550]);
  _exports.PENDING_ACCOUNT_STATUSES = PENDING_ACCOUNT_STATUSES;
  var APPROVED_ACCOUNT_STATUSES = Object.freeze([330, 600, 650, 675, 700, 730, 785]);
  _exports.APPROVED_ACCOUNT_STATUSES = APPROVED_ACCOUNT_STATUSES;
  var BLOCKED_ACCOUNT_STATUSES = Object.freeze([200, 300, 325]);
  _exports.BLOCKED_ACCOUNT_STATUSES = BLOCKED_ACCOUNT_STATUSES;
});