define("lh4/routes/authenticated/internet-payments/risk/risk-rules/edit", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    store: Ember.inject.service(),
    modelName: 'riskRule',
    ruleId: null,
    model: function model(params) {
      var _this = this;

      this.store.unloadAll('internet-payments/risk-rule');
      return Ember.RSVP.hash({
        riskRule: this.ajax.request("/api/v1/internet-payments/risk-rules/".concat(this.location.model.id, "/").concat(params.ruleId)).then(function (data) {
          _this.ruleId = data.id;

          var normalized = _this.store.normalize('internet-payments/risk-rule', data);

          normalized.data.id = 0;
          return _this.store.push(normalized);
        })
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      controller.setProperties({
        errorMessage: null,
        validationErrors: {},
        ruleId: this.ruleId
      });
    }
  });

  _exports.default = _default;
});