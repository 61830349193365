define("lh4/routes/authenticated/locations/location/day-parts/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    corporation: Ember.inject.service(),
    router: Ember.inject.service(),
    modelName: 'intradayPeriodGroup',
    beforeModel: function beforeModel() {
      if (this.corporation.isMenuManagedByCorporate) {
        this.router.transitionTo('authenticated.locations.location.day-parts');
      }
    },
    model: function model() {
      var locationId = this.location.model.id;
      return Ember.RSVP.hash({
        intradayPeriodGroup: this.store.createRecord('echo-pro/intraday-period-group', {
          locationId: locationId
        })
      });
    }
  });

  _exports.default = _default;
});