define("lh4/components/reports/echo/financial-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showAll: false,
    actions: {
      beforePrint: function beforePrint() {
        this.toggleProperty('showAll');
      },
      afterPrint: function afterPrint() {
        this.toggleProperty('showAll');
      }
    }
  });

  _exports.default = _default;
});