define("lh4/abilities/general", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions", "lh4/enums/services", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _permissions, _services, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    canViewSalesInfo: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.GENERAL.SALES_INFO);
    }),
    canViewTicketsInfo: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.GENERAL.TICKET_INFO);
    }),
    canViewLocationTicketsInfo: (0, _abilityMixin.computedAbility)(function getter() {
      return (this.hasProduct('hospitality') || this.hasProduct('echo-pro')) && this.get('canViewTicketsInfo');
    }),
    canViewPaymentsInfo: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.GENERAL.PAYMENT_INFO);
    }),
    canViewProcessingDetails: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.GENERAL.PROCESSING_DETAIL);
    }),
    canViewDocuments: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.DOCUMENTS.MONTHLY_CC_STATEMENTS, this.locationId) || this.hasPermission(_permissions.default.DOCUMENTS.VIEW_TAX_DOCUMENTS, this.locationId) || this.hasPermission(_permissions.default.DOCUMENTS.ACCOUNT_NOTICES, this.locationId);
    }),
    canViewCreditCardStatements: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.DOCUMENTS.MONTHLY_CC_STATEMENTS, this.locationId);
    }),
    canViewTaxDocuments: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.DOCUMENTS.VIEW_TAX_DOCUMENTS, this.locationId);
    }),
    canViewAccountNotices: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.DOCUMENTS.ACCOUNT_NOTICES, this.locationId);
    }),
    canViewBatchHistory: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.GENERAL.BATCH_HISTORY);
    }),
    canViewLaborPercentage: (0, _abilityMixin.computedAbility)(function getter() {
      return (this.hasProduct('hospitality') || this.hasProduct('echo-pro')) && this.hasPermission(_permissions.default.GENERAL.LABOR_PERCENTAGE);
    }),
    canViewEmployeeTimeclock: (0, _abilityMixin.computedAbility)(function getter() {
      return (this.hasProduct('hospitality') || this.hasProduct('echo-pro')) && this.hasPermission(_permissions.default.GENERAL.EMPLOYEE_TIMECLOCK);
    }),
    canViewReports: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.REPORTING.REPORTS, this.locationId);
    }),
    canViewLocationReports: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.REPORTING.REPORTS);
    }),
    canManageReportSubscriptions: (0, _abilityMixin.computedAbility)(function getter() {
      return this.get('canViewReports') && this.get('permissions.reportLocations').length !== 0;
    }),
    canCreateOwnSubscriptions: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.REPORTING.CREATE_OWN_SUBSCRIPTIONS, this.locationId) && !this.location.isTemporarilyHiddenForCA;
    }),
    canManageUsers: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.USERS.MANAGE_USERS);
    }),
    canManageLocationUsers: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.USERS.MANAGE_USERS);
    }),
    canGrantManageUsers: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.USERS.GRANT_MANAGE_USERS);
    }),
    canViewTransactions: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.TRANSACTIONS.VIEW_TRANSACTIONS) && (this.canAccessForUs || this.location.isCA);
    }),
    canCreateTransactions: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.TRANSACTIONS.CREATE_TRANSACTIONS);
    }),
    canRefundTransactions: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.TRANSACTIONS.REFUND_TRANSACTIONS);
    }),
    canViewDashboard: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.DASHBOARD.VIEW_DASHBOARD);
    }),
    canManageDeveloperSettingsForLocation: (0, _abilityMixin.computedAbility)('session.isSSO', function getter() {
      return !this.session.isSSO && this.hasLocationPermission(_permissions.default.DEVELOPERS.MANAGE_DEVELOPER_SETTINGS) && !this.location.isTemporarilyHiddenForCA;
    }),
    canAccessFinaro: (0, _abilityMixin.computedAbility)('location.isUS', 'location.model.services', 'location.model.merchantId', function getter() {
      return this.hasPermission(_permissions.default.FINARO.FINARO_INSIGHT_ACCESS) && !this.location.isUS && this.location.model.services.some(function (activeService) {
        return activeService.name === _services.default.FINARO_INSIGHT;
      });
    }),
    canAccessForUs: (0, _abilityMixin.computedAbility)('location.isUS', function getter() {
      return this.location.isUS;
    }),
    canUseShift4Gateway: (0, _abilityMixin.computedAbility)('location.usesShift4PaymentProcessor', function getter() {
      return this.location.usesShift4PaymentProcessor;
    }),
    canManageVirtualGiftCard: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canManage() {
      return this.hasProduct('echo-pro') || this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.GIFT_CARD_VISIBILITY];
    }),
    canManageVirtualTerminal: (0, _abilityMixin.computedAbility)('location.model.usesShift4PaymentProcessor', function getter() {
      return this.location.model.usesShift4PaymentProcessor && this.canViewTransactions && this.canAccessForUs;
    }),
    canViewApmServices: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_APM_SERVICES_VISIBILITY] && !this.location.isTemporarilyHiddenForCA;
    }),
    canAccessGiftCards: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.GIFT_CARDS.ACCESS);
    }),
    canAccessGiftCardOrders: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.GIFT_CARDS.ORDERS_ACCESS);
    }),
    canAccessGiftCardsReporting: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasLocationPermission(_permissions.default.REPORTING.GIFT_CARDS);
    }),
    canSeeTimezone: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SHOW_TIMEZONE_NEXT_TO_LOCATION];
    }),
    canViewLocationGroups: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.MLM_LOCATION_GROUPS_PAGE_VISIBILITY];
    }),
    canViewCustomerImportV2: (0, _abilityMixin.computedAbility)(function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_CUSTOMER_DATABASE_IMPORT_V2];
    }),
    canViewPhoneNumber: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.PHONE_NUMBER_IDENTIFY_CALLER];
    })
  });

  _exports.default = _default;
});