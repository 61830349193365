define("lh4/components/hospitality/customize-menu/item/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didUpdateAttrs: function didUpdateAttrs() {
      this.oldUsesSetPrice = this.model.usesSetPrice;
    },
    isUseSetPriceDisabled: Ember.computed('model.priceCategoryId', function getter() {
      return this.model.priceCategoryId === -1;
    }),
    itemPriceLabelClass: Ember.computed('model.usesSetPrice', function getter() {
      return this.model.usesSetPrice ? 'required' : '';
    }),
    itemGroupPrice: Ember.computed('model.priceCategoryId', 'model.usesSetPrice', function getter() {
      var _this = this;

      var itemGroup = this.itemGroup.find(function (_ref) {
        var priceCategoryId = _ref.priceCategoryId;
        return priceCategoryId === _this.model.priceCategoryId;
      });
      return itemGroup === null || itemGroup === void 0 ? void 0 : itemGroup.dollarAmount;
    }),
    actions: {
      onItemGroupChange: function onItemGroupChange(id) {
        var priceCategoryId = parseInt(id, 10);
        this.model.setProperties({
          priceCategoryId: priceCategoryId,
          usesSetPrice: priceCategoryId === -1 ? true : this.oldUsesSetPrice
        });
      }
    }
  });

  _exports.default = _default;
});