define("lh4/models/virtual-terminal/refund-settings", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    allowPrepaid: (0, _attr.default)('boolean'),
    allowNonPrepaid: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});