define("lh4/templates/components/supply-orders/select-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IljcXr0/",
    "block": "{\"symbols\":[\"location\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui four column stackable grid\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"locations\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",false],[12,\"class\",\"column\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectLocation\",[23,1,[]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui header top attached segment\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h3\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui bottom attached segment\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"merchantId\"]],false],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"addressLine1\"]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"addressLine2\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[23,1,[\"addressLine2\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[7,\"p\",true],[8],[1,[23,1,[\"city\"]],false],[0,\", \"],[1,[23,1,[\"state\"]],false],[0,\" \"],[1,[23,1,[\"zip\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/supply-orders/select-location.hbs"
    }
  });

  _exports.default = _default;
});