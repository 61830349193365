define("lh4/controllers/authenticated/customer-engagement/campaigns/overview/survey", ["exports", "jquery", "lh4/config/environment", "lh4/enums/date-range-title", "moment"], function (_exports, _jquery, _environment, _dateRangeTitle, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Build table header
   *
   * @param {String} name header name
   * @param {String} field field to be displayed
   * @param {String} width semantic ui css table column width class
   * @returns {Object} result table header
   */
  function buildHeader(name, field, width) {
    return {
      name: name,
      field: field,
      width: width
    };
  }
  /**
   * Build computed attribute for report url
   * @param {String} format report format: pdf, csv, xls.
   * @returns {Object} result computed attribute
   */


  function reportUrl(format) {
    return Ember.computed('from', 'to', function getter() {
      var params = _jquery.default.param({
        from: this.from,
        to: this.to,
        'locations[]': [this.get('model.locationId')],
        campaignGuid: this.get('model.campaignId').campaignId,
        token: this.session.get('data.authenticated').token
      });

      return "".concat(_environment.default.host, "/api/v1/reports/lighthouse/").concat(format, "/customer-engagement-survey-reviews?").concat(params);
    });
  }

  var _default = Ember.Controller.extend({
    queryParams: ['offset', 'limit', 'to', 'from', 'rating', 'sortBy', 'sortAsc'],
    offset: 0,
    limit: 25,
    sortBy: 'fullName',
    sortAsc: true,
    session: Ember.inject.service(),
    from: (0, _moment.default)().startOf('week').format('YYYY-MM-DD'),
    to: (0, _moment.default)().endOf('week').format('YYYY-MM-DD'),
    media: Ember.inject.service(),
    maxDate: (0, _moment.default)(),
    rangeTitle: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.THIS,
    verticalRangeWidth: 160,
    campaignApi: Ember.inject.service('customer-engagement/campaign-api'),
    headers: [buildHeader('Date', 'time', 'two print-two'), buildHeader('Rating', 'rating', 'two print-two'), buildHeader('User', 'fullName', 'four print-four'), buildHeader('Review', 'review', 'eight print-eight')],
    rating: 'Any Rating',
    ratings: ['Any Rating', '1 Star', '2 Star', '3 Star', '4 Star', '5 Star'],
    barOptions: {
      categoryField: 'label',
      parseDates: false,
      valueField: 'value'
    },
    surveyRating: Ember.computed('model.survey.campaign', function getter() {
      var survey = this.get('model.survey.campaign'); // survey.ratingByScore is an object and not an array.

      return [{
        label: '1 Star',
        value: survey.ratingByScore['1']
      }, {
        label: '2 Stars',
        value: survey.ratingByScore['2']
      }, {
        label: '3 Stars',
        value: survey.ratingByScore['3']
      }, {
        label: '4 Stars',
        value: survey.ratingByScore['4']
      }, {
        label: '5 Stars',
        value: survey.ratingByScore['5']
      }];
    }),
    lineOptions: {
      categoryField: 'date',
      dataDateFormat: _moment.default.HTML5_FMT.DATE,
      parseDates: true,
      valueField: 'ratingAverage',
      valueAxesOptions: {
        minimum: 0,
        maximum: 5,
        integersOnly: true
      }
    },
    surveyAverage: Ember.computed('model.survey.campaign.graphData', function getter() {
      return this.get('model.survey.campaign.graphData').sortBy('time').map(function (_ref) {
        var time = _ref.time,
            ratingAverage = _ref.ratingAverage;
        return {
          date: (0, _moment.default)(time).format(_moment.default.HTML5_FMT.DATE),
          ratingAverage: ratingAverage
        };
      });
    }),
    pdfURL: reportUrl('pdf'),
    csvURL: reportUrl('csv'),
    xlsURL: reportUrl('xls'),
    actions: {
      filterChanged: function filterChanged(filter) {
        this.setProperties(filter);
      },
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        this.set('from', startDate.format('YYYY-MM-DD'));
        this.set('to', endDate.format('YYYY-MM-DD'));
        this.set('offset', 0);
      },
      onMobileChange: function onMobileChange(rangeTitle, startDate, endDate) {
        this.set('offset', 0);
        this.set('rangeTitle', rangeTitle);

        if (startDate) {
          this.set('from', startDate.format('YYYY-MM-DD'));
        }

        if (endDate) {
          this.set('to', endDate.format('YYYY-MM-DD'));
        }
      },
      onRangeSelect: function onRangeSelect(instance, range) {
        var startDate = range.startDate,
            endDate = range.endDate,
            title = range.title;
        instance.setStart(startDate.startOf('day'));
        instance.setEnd(endDate.endOf('day'));
        this.set('from', startDate.format('YYYY-MM-DD'));
        this.set('rangeTitle', title);
        this.set('to', endDate.format('YYYY-MM-DD'));
        this.set('offset', 0);
      }
    }
  });

  _exports.default = _default;
});