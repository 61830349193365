define("lh4/models/internet-payments/customer/details", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    billingAddress: (0, _attr.default)('string'),
    billingName: (0, _attr.default)('string'),
    billingZip: (0, _attr.default)('string'),
    cardFirst4: (0, _attr.default)('string'),
    cardLast4: (0, _attr.default)('string'),
    cardBrand: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('string'),
    emailCount: (0, _attr.default)('number'),
    emailList: (0, _attr.default)(),
    ipCount: (0, _attr.default)('number'),
    ipList: (0, _attr.default)(),
    last3dsStatus: (0, _attr.default)('string'),
    last3dsStatusDate: (0, _attr.default)('string'),
    lastRiskScore: (0, _attr.default)('string'),
    lastRiskScoreDate: (0, _attr.default)('string'),
    nameCount: (0, _attr.default)('number'),
    nameList: (0, _attr.default)(),
    phoneCount: (0, _attr.default)('number'),
    phoneList: (0, _attr.default)(),
    referenceCount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    referenceList: (0, _attr.default)(),
    chargebackCount: (0, _attr.default)('number'),
    chargebackList: (0, _attr.default)(),
    totalSpent: (0, _attr.default)('string')
  });

  _exports.default = _default;
});