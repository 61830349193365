define("lh4/mixins/components/ui/tab-container-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    logger: Ember.inject.service(),

    /**
     * @param {string} - (optional) preselected Tab Name
     */
    activeTab: null,

    /**
     * @param {string} - (optional) style name for tab component
     * Supported options: "links" (default), "buttons".
     */
    tabStyle: 'links',

    /**
     * @param {string} - (optional) additional class for top segment
     */
    topSegmentClass: '',
    styleOptions: {
      links: {
        rootSegmentClass: 'ui segments body-menu',
        topSegmentClass: 'ui segment',
        topSegmentFluid: true,
        contentSegmentClass: 'ui segment'
      },
      buttons: {
        rootSegmentClass: '',
        topSegmentClass: 'ui top attached pointing menu',
        topSegmentFluid: false,
        contentSegmentClass: 'ui bottom attached segment'
      }
    },
    tabCountClasses: ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'],
    getTabCountClass: function getTabCountClass(context, tabCount) {
      if (!tabCount || tabCount > 10 || tabCount < 0) {
        return '';
      }

      return context.get('tabCountClasses')[tabCount];
    },
    componentStyles: Ember.computed('tabStyle', 'topSegmentClass', function getter() {
      var tabStyle = this.get('tabStyle');
      var styleOptions = this.get('styleOptions');
      return Ember.Object.create({
        rootSegmentClass: styleOptions[tabStyle].rootSegmentClass,
        topSegmentClass: "".concat(styleOptions[tabStyle].topSegmentClass, " ").concat(this.get('topSegmentClass')),
        topSegmentFluid: styleOptions[tabStyle].topSegmentFluid,
        contentSegmentClass: styleOptions[tabStyle].contentSegmentClass
      });
    })
  });

  _exports.default = _default;
});