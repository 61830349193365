define("lh4/components/epro/accounting/surcharge-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    corporation: Ember.inject.service(),
    locale: Ember.inject.service(),
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid', 'surcharge-form'],
    showErrors: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.surchargeTypeOptions = [{
        value: true,
        name: "Flat (".concat(this.locale.currencySymbol, ")")
      }, {
        value: false,
        name: 'Percentage (%)'
      }];
    },
    // Backup nested changeset properties so they're not lost
    // if a user misclicks and doesn't want to start over
    backupOrderTypeId: null,
    backupTaxSetId: null,
    backupItemIds: [],
    taxSetOptions: Ember.computed.map('taxSets', function (_ref) {
      var name = _ref.name,
          value = _ref.id,
          isActive = _ref.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    orderTypeOptions: Ember.computed.map('orderTypes', function (_ref2) {
      var name = _ref2.name,
          value = _ref2.id,
          isActive = _ref2.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    actions: {
      handleAppliesToChange: function handleAppliesToChange(appliesTo) {
        var currentAppliesTo = this.surcharge.get('appliesTo');

        if (currentAppliesTo === 'order-type') {
          this.set('backupOrderTypeId', this.surcharge.get('orderTypeId'));
          this.set('backupTaxSetId', this.surcharge.get('taxSetId'));
          this.surcharge.set('orderTypeId', null);
          this.surcharge.set('taxSetId', null);
        } else if (currentAppliesTo === 'menu-item') {
          this.set('backupItemIds', this.surcharge.get('itemIds'));
          this.surcharge.set('itemIds', []);
        } // Checking at existing changeset will continue showing both options
        // until the next save for items that had an existing value previously saved


        if (appliesTo === 'order-type') {
          this.surcharge.set('orderTypeId', this.backupOrderTypeId || this.surcharge.get('orderTypeId'));
          this.surcharge.set('taxSetId', this.backupTaxSetId || this.surcharge.get('taxSetId'));
        } else if (appliesTo === 'menu-item') {
          this.surcharge.set('itemIds', this.backupItemIds || this.surcharge.get('itemIds'));
        }

        this.surcharge.set('appliesTo', appliesTo);
      }
    }
  });

  _exports.default = _default;
});