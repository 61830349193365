define("lh4/models/corporate-group-item-override", ["exports", "ember-data", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/model-validators"], function (_exports, _emberData, _emberCpValidations, _modelCloneable, _modelValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    value: {
      description: 'Price Group Value',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true,
        gte: 0
      }), (0, _modelValidators.numberFloatValidator)()]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, _modelCloneable.default, {
    itemGuid: _emberData.default.attr('string'),
    corpId: _emberData.default.attr('number'),
    corporateGroupId: _emberData.default.attr('number'),
    property: _emberData.default.attr('string'),
    value: _emberData.default.attr('string')
  });

  _exports.default = _default;
});