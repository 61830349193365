define("lh4/models/internet-payments/dispute-management/dispute", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    offsetToken: (0, _attr.default)('string'),
    date: (0, _attr.default)('string'),
    caseNumber: (0, _attr.default)('string'),
    daysToAct: (0, _attr.default)('number'),
    disputeAmount: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    reasonCode: (0, _attr.default)('string'),
    ipAddress: (0, _attr.default)('string'),
    dueBy: (0, _attr.default)('string'),
    caseType: (0, _attr.default)('string'),
    transaction: (0, _attr.default)({
      type: 'object',
      properties: {
        date: (0, _attr.default)('string'),
        id: (0, _attr.default)('string'),
        amount: (0, _attr.default)('number'),
        currency: (0, _attr.default)('string'),
        acquirerRefNumber: (0, _attr.default)('string'),
        merchantReference: (0, _attr.default)('string')
      }
    }),
    reason: (0, _attr.default)({
      type: 'object',
      properties: {
        description: (0, _attr.default)('string'),
        longDescription: (0, _attr.default)('string'),
        documents: (0, _attr.default)({
          type: 'array',
          items: {
            type: 'string'
          }
        })
      }
    }),
    acquirerRefNumber: (0, _attr.default)('string'),
    card: (0, _attr.default)({
      type: 'object',
      properties: {
        last4: (0, _attr.default)('string'),
        brand: (0, _attr.default)('string'),
        ipAddress: (0, _attr.default)('string')
      }
    }),
    evidence: (0, _attr.default)({
      type: 'object',
      properties: {
        productDescription: (0, _attr.default)('string'),
        customerName: (0, _attr.default)('string'),
        customerEmail: (0, _attr.default)('string'),
        customerPurchaseIp: (0, _attr.default)('string'),
        signatureId: (0, _attr.default)('string'),
        receiptId: (0, _attr.default)('string'),
        communicationId: (0, _attr.default)('string'),
        serviceDocId: (0, _attr.default)('string'),
        serviceDate: (0, _attr.default)('string'),
        additionalInfoId: (0, _attr.default)('string'),
        additionalInfoText: (0, _attr.default)('string')
      }
    }),
    acceptedAsLost: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});