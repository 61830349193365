define("lh4/components/virtual-terminal/refund-settings/steps/error/index", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#ui/step-container/form-content stepHeader=stepHeader}}
  	{{#ui/messages-list
  		class="visible error"
  		title="There was an error completing your request."
  		messages=errorMessages as |message|
  	}}
  		{{message}}
  	{{/ui/messages-list}}
  {{/ui/step-container/form-content}}
  
  */
  {
    id: "BWh6Q8dN",
    block: "{\"symbols\":[\"message\"],\"statements\":[[4,\"ui/step-container/form-content\",null,[[\"stepHeader\"],[[24,[\"stepHeader\"]]]],{\"statements\":[[4,\"ui/messages-list\",null,[[\"class\",\"title\",\"messages\"],[\"visible error\",\"There was an error completing your request.\",[24,[\"errorMessages\"]]]],{\"statements\":[[0,\"\\t\\t\"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/virtual-terminal/refund-settings/steps/error/index.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'virtual-terminal-refund-settings-steps-error',
    classNames: ['error-step']
  }));

  _exports.default = _default;
});