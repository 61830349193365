define("lh4/templates/components/epro/menu/pricing-table-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4kquNvDZ",
    "block": "{\"symbols\":[\"childChangeset\"],\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui header toggle-header\"],[8],[0,\"\\n\\tGeneral\\n\"],[9],[0,\"\\n\"],[4,\"ui/form-fields/static-field\",null,[[\"label\",\"labelClasses\"],[\"Item Name\",\"white\"]],{\"statements\":[[0,\"\\t\"],[1,[24,[\"changeset\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"ui/form-fields/static-field\",null,[[\"label\",\"labelClasses\"],[\"Department\",\"white\"]],{\"statements\":[[0,\"\\t\"],[1,[24,[\"changeset\",\"departmentName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"ui/form-fields/input-field\",null,[[\"changeset\",\"label\",\"property\",\"type\",\"min\",\"showErrors\",\"data-test-input\"],[[24,[\"changeset\"]],\"Default Price\",\"defaultPrice\",\"number\",\"0\",[24,[\"showErrors\"]],\"defaultPrice\"]]],false],[0,\"\\n\\n\"],[7,\"h2\",true],[10,\"class\",\"ui header toggle-header\"],[8],[0,\"\\n\\tPricing\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"changeset\",\"priceOverrideChangesets\",\"length\"]],0],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"data-test-message\",\"no-price-groups\"],[8],[0,\"\\n\\t\\tNo Price Groups To Display\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"changeset\",\"priceOverrideChangesets\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"changeset\",\"label\",\"property\",\"type\",\"min\",\"showErrors\",\"placeholder\",\"data-test-price-group-input\"],[[23,1,[]],[23,1,[\"corporateGroupName\"]],\"value\",\"number\",\"0\",[24,[\"showErrors\"]],[24,[\"defaultPriceText\"]],[23,1,[\"corporateGroupId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\"],[1,[22,\"ui/form-fields/required-fields\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/epro/menu/pricing-table-form.hbs"
    }
  });

  _exports.default = _default;
});