define("lh4/routes/authenticated/locations/location/hospitality/manage-employees/edit-employee", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      return this.store.findRecord('hospitality/employee', params.employeeId, {
        reload: true
      }).then(function (employee) {
        var employeePrivileges = employee.get('employeesPrivileges');
        var employeeSecId = Ember.A([]);
        var employeeIsAllowed = Ember.A([]); // Make into obj

        employeePrivileges.forEach(function (x) {
          employeeSecId.push(x.securityId);
          employeeIsAllowed.push(x.isAllowed);
        });
        var newEmployeePrivileges = Ember.A([]);
        return _this.store.query('hospitality/privilege', {
          location: location.id
        }, {
          reload: true
        }).then(function () {
          return _this.store.peekAll('hospitality/privilege');
        }).then(function (permSet) {
          // Sort by security name
          var perms = Ember.A(permSet.toArray().sort(function (a, b) {
            if (a.get('securityName').trim() < b.get('securityName').trim()) {
              return -1;
            }

            if (a.get('securityName').trim() > b.get('securityName').trim()) {
              return 1;
            }

            return 0;
          }));
          perms.forEach(function (x) {
            var props = Ember.Object.create({
              isAllowed: false,
              securityName: x.get('securityName'),
              rowguid: null,
              securityId: x.get('securityId'),
              employeeId: employee.employeeId,
              locationId: employee.locationId,
              usersecurityId: null
            });

            if (employeeSecId.includes(x.get('securityId')) && employeeIsAllowed[employeeSecId.indexOf(x.get('securityId'))] === true) {
              props.set('isAllowed', true);
            }

            var existingPrivilege = employeePrivileges.find(function (y) {
              return y.securityId === x.get('securityId');
            });

            if (existingPrivilege) {
              props.set('rowguid', existingPrivilege.rowguid);
              props.set('usersecurityId', existingPrivilege.usersecurityId);
            }

            newEmployeePrivileges.push(props);
          });
          return newEmployeePrivileges;
        }).then(function (perms) {
          employee.set('employeesPrivileges', perms);
          return employee;
        });
      }).then(function (employee) {
        var model = {
          employee: employee,
          job: _this.store.query('hospitality/job', {
            location: location.id
          }).then(function () {
            return _this.store.peekAll('hospitality/job');
          })
        };
        return Ember.RSVP.hash(model);
      });
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('hospitality/job');
    }
  });

  _exports.default = _default;
});