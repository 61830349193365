define("lh4/templates/components/dashboard-widgets/online-reputation/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s2JdooKg",
    "block": "{\"symbols\":[\"account\"],\"statements\":[[4,\"if\",[[24,[\"showSignup\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"img\",true],[10,\"src\",\"/img/dashboard/reputation-management-signup.png\"],[10,\"alt\",\"Comment Bubbles\"],[8],[9],[0,\"\\n\\t\"],[7,\"span\",true],[8],[0,\"Constantly monitor reviews from the major review websites\"],[9],[0,\"\\n\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"ui inverted button\",\"authenticated.premium.reputation\"]],{\"statements\":[[0,\"Monitor online reviews\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"data\",\"accounts\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"stars\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[28,\"to-fixed\",[[23,1,[\"stars\"]],1],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"content no-stars\"],[8],[0,\"N/A\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"large blue star icon\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/online-reputation/data.hbs"
    }
  });

  _exports.default = _default;
});