define("lh4/components/reputation/review-respond-form", ["exports", "moment", "lh4/services/social-media/facebook"], function (_exports, _moment, _facebook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    accountApi: Ember.inject.service('reputation/account-api'),
    flashMessagesHelper: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    label: '',
    responseText: '',
    respondButtonText: 'Post',
    respondTextMaxLength: _facebook.COMMENT_MAX_LENGTH,

    /* @params */
    account: null,
    selectedReview: null,
    isTextValid: Ember.computed('responseText', function getter() {
      var text = this.get('responseText');
      return !!(text && text.trim());
    }),
    actions: {
      sendResponse: function sendResponse() {
        var _this = this;

        if (!this.get('isTextValid')) {
          return;
        }

        this.set('isLoading', true);
        this.accountApi.respondToReview(this.get('account'), this.get('selectedReview'), this.get('responseText')).then(function () {
          _this.flashMessagesHelper.pushMessage('Successfully responded to review', 'success');

          _this.sendAction('onReplySuccess', {
            responseText: _this.get('responseText'),
            updatedAt: (0, _moment.default)().toDate()
          });
        }).catch(function (err) {
          _this.flashMessagesHelper.pushMessages(_this.responseMessages.getResponseErrors(err));
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      },
      discard: function discard() {
        this.sendAction('onDiscard');
      }
    }
  });

  _exports.default = _default;
});