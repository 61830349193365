define("lh4/templates/components/ui/step-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cztkLiYH",
    "block": "{\"symbols\":[\"step\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui fluid ordered steps\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"stepObjects\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[[23,1,[\"class\"]],\" step\"]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[14,2,[[28,\"hash\",null,[[\"content\"],[[28,\"component\",[\"ui/step-container/step-content\"],[[\"activeStep\"],[[24,[\"activeStep\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/step-container.hbs"
    }
  });

  _exports.default = _default;
});