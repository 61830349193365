define("lh4/routes/authenticated/epro/menu/dynamic-pricing/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _generalEditRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    dynamicPricing: Ember.inject.service('dynamic-pricing'),
    intradayPeriod: Ember.inject.service('intraday-period'),
    locationSettings: Ember.inject.service('echo-pro/settings'),
    router: Ember.inject.service(),
    modelName: 'dynamicPrice',
    childChangesets: {
      schedules: 'scheduleChangesets'
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      this.router.transitionTo('authenticated.epro.menu.dynamic-pricing');
    }),
    model: function model(_ref) {
      var scheduleTitleGuid = _ref.scheduleTitleGuid;
      var _this$location$model = this.location.model,
          location = _this$location$model.id,
          corpId = _this$location$model.corpId;
      return Ember.RSVP.hash({
        dynamicPrice: this.store.findRecord('echo-pro/availability-price/dynamic-price', scheduleTitleGuid),
        startOfDay: this.locationSettings.getEoDSetting(location),
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: location
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref2) {
            var guid = _ref2.guid,
                revenueCenterName = _ref2.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        intradayPeriodOptions: this.intradayPeriod.getDayPartsGroups(location).then(function (intradayPeriods) {
          return intradayPeriods.map(function (_ref3) {
            var guid = _ref3.guid,
                intradayPeriodGroupName = _ref3.intradayPeriodGroupName;
            return {
              value: guid,
              name: intradayPeriodGroupName
            };
          });
        }),
        isMenuManagedByCorporate: corpId && this.corporation.getMenuManagedStatus(location)
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      var dynamicPrice = model.dynamicPrice,
          startOfDay = model.startOfDay,
          isMenuManagedByCorporate = model.isMenuManagedByCorporate;
      var adjustedTimeOptions = this.dynamicPricing.validateCustomScheduleTimeOptions(dynamicPrice.schedules, startOfDay, isMenuManagedByCorporate, Ember.set);
      controller.setProperties(_objectSpread(_objectSpread({}, adjustedTimeOptions), {}, {
        isAnyScheduleOverlapping: false
      }));
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'Edit',
        'Entry ID': dynamicPrice.guid,
        'Menu Component': 'Dynamic Pricing'
      });
    }
  });

  _exports.default = _default;
});