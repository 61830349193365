define("lh4/templates/components/modal/action-confirmation-v2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IDlgAVwu",
    "block": "{\"symbols\":[\"modal\",\"@question\",\"@isOpen\",\"@description\",\"@onDeny\",\"@denyText\",\"@onApprove\",\"@approveText\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"modals\"]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[5,\"ui/modal/modal-dialog\",[],[[\"@title\",\"@isOpen\",\"@onClose\"],[[23,2,[]],[23,3,[]],[28,\"action\",[[23,0,[]],\"closeModalDialog\"],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[6,[23,1,[\"body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"p\",true],[8],[1,[23,4,[]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\\t\"],[6,[23,1,[\"actions\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@onClick\",\"@type\",\"@text\"],[[23,5,[]],\"outlined\",[23,6,[]]]]],[0,\"\\n\\t\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@onClick\",\"@type\",\"@text\"],[[23,7,[]],\"contained\",[23,8,[]]]]],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/modal/action-confirmation-v2.hbs"
    }
  });

  _exports.default = _default;
});