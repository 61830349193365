define("lh4/templates/components/ui/icons/internet-payments/exclamation-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3PDBi0LF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"14\"],[10,\"height\",\"14\"],[10,\"viewBox\",\"0 0 14 14\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M6.33337 8.99967H7.66671V10.333H6.33337V8.99967ZM6.33337 3.66634H7.66671V7.66634H6.33337V3.66634ZM6.99337 0.333008C3.31337 0.333008 0.333374 3.31967 0.333374 6.99967C0.333374 10.6797 3.31337 13.6663 6.99337 13.6663C10.68 13.6663 13.6667 10.6797 13.6667 6.99967C13.6667 3.31967 10.68 0.333008 6.99337 0.333008ZM7.00004 12.333C4.05337 12.333 1.66671 9.94634 1.66671 6.99967C1.66671 4.05301 4.05337 1.66634 7.00004 1.66634C9.94671 1.66634 12.3334 4.05301 12.3334 6.99967C12.3334 9.94634 9.94671 12.333 7.00004 12.333Z\"],[10,\"fill\",\"#FAFAFA\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/exclamation-mark.hbs"
    }
  });

  _exports.default = _default;
});