define("lh4/controllers/authenticated/reporting/subscriptions", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    headers: [{
      name: 'Product',
      width: 'three',
      sortable: false
    }, {
      name: 'Report Type',
      width: 'four',
      sortable: false
    }, {
      name: 'Frequency',
      width: 'two',
      sortable: false
    }, {
      name: 'Send Time',
      width: 'two',
      sortable: false
    }, {
      name: 'Send To',
      width: 'five',
      sortable: false
    }],
    sortedSubscriptions: Ember.computed.sort('model.subscriptions', function (a, b) {
      return (0, _moment.default)(a.get('time'), 'hh:mmA').diff((0, _moment.default)(b.get('time'), 'hh:mmA'));
    }).property('model.subscriptions.@each.time', 'model.subscriptions.[]'),
    actions: {
      editSubscription: function editSubscription(_ref) {
        var id = _ref.id;
        this.transitionToRoute('authenticated.reporting.subscriptions.edit-subscription', id);
      },
      createSubscription: function createSubscription() {
        this.transitionToRoute('authenticated.reporting.subscriptions.create-subscription');
      }
    }
  });

  _exports.default = _default;
});