define("lh4/templates/components/internet-payments/page-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "swUPoO2b",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segment center aligned page-not-found\"],[8],[0,\"\\n\\t\"],[7,\"h1\",true],[8],[0,\"\\n\\t\\t\"],[1,[22,\"message\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\"],[1,[22,\"subMessage\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"img\",true],[11,\"src\",[22,\"imageUri\"]],[11,\"alt\",[22,\"imageAltText\"]],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/page-not-found.hbs"
    }
  });

  _exports.default = _default;
});