define("lh4/serializers/echo-pro/price-schedule", ["exports", "moment", "lh4/serializers/echo-pro/application"], function (_exports, _moment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    normalize: function normalize(modelClass, json) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      var normalized = this._super.apply(this, [modelClass, json].concat(args));

      normalized.data.attributes.startsAt = _moment.default.utc(json.startsAtSeconds);
      normalized.data.attributes.endsAt = _moment.default.utc(json.endsAtSeconds);
      return normalized;
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var startOfDay = _moment.default.utc('1970-01-01 12:00 AM', 'YYYY-MM-DD LT'); // THIS IS ACTUALLY MILLISECONDS, NOT SECONDS!


      json.startsAtSeconds = json.startsAt.valueOf() - startOfDay.valueOf(); // THIS IS ACTUALLY MILLISECONDS, NOT SECONDS!

      json.endsAtSeconds = json.endsAt.valueOf() - startOfDay.valueOf();
      delete json.startsAt;
      delete json.endsAt;
      delete json.allDay;
      return json;
    }
  });

  _exports.default = _default;
});