define("lh4/components/echo-pro/tableside/device-setup-wizard/device-name-step", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    canProceed: Ember.computed.bool('terminal.terminalName'),
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('#wizard-device-name-input').focus();
    },
    actions: {
      proceed: function proceed() {
        if (!this.get('canProceed')) {
          this.sendAction('onError', 'Please enter serial number');
          return;
        }

        this.sendAction('onProceed');
      },
      back: function back() {
        this.sendAction('onBack');
      }
    }
  });

  _exports.default = _default;
});