define("lh4/mirage/fixtures/integration/s4/webhook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WEBHOOKS = [{
    id: '2c58a8b8-ebc6-45aa-b580-8567cb3a0a13',
    callCount: 10,
    description: 'This webhook is for the SALE and REFUND events.',
    environment: 'UAT',
    eventTypes: ['SALE', 'REFUND'],
    isActive: false,
    locationId: 1,
    name: 'Webhook 2',
    signingSecret: 'f7bf510f29d66a0727eb14bf2be1d375',
    url: 'https://example.com/webhooks/2'
  }, {
    id: 'ba45022e-37ca-4efa-b2db-d580d89eb42d',
    callCount: 100,
    description: 'This webhook is for the SALE event.',
    environment: 'Development',
    eventTypes: ['SALE'],
    isActive: true,
    locationId: 1,
    name: 'Webhook 1',
    signingSecret: 'be1d3759d66a0727ef7bf510f2b14bf2',
    url: 'https://example.com/webhooks/1'
  }, {
    id: '4cf172b9-f725-48c6-954d-c4bca2e5e3d0',
    callCount: 25,
    description: 'This webhook is for the DISPUTE event.',
    environment: 'Development',
    eventTypes: ['DISPUTE'],
    isActive: true,
    locationId: 1,
    name: 'Webhook 3',
    signingSecret: '9d66a0727ef7bf510f2b14bf2be1d375',
    url: 'https://example.com/webhooks/3'
  }];
  var _default = WEBHOOKS;
  _exports.default = _default;
});