define("lh4/templates/components/social-media/account-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+7r5dnxJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Account Name\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"input\",null,[[\"value\",\"type\",\"placeholder\",\"maxlength\"],[[24,[\"account\",\"name\"]],\"text\",\"Name\",\"100\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui dividing header anti-padded\"],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\"],[7,\"label\",true],[8],[0,\"Locations\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"useLocationsIds\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"dynamic-checklist\",null,[[\"checkboxClass\",\"dataType\",\"collection\",\"selectedIds\",\"idField\",\"property\"],[\"ui checkbox\",\"edit-mod\",[24,[\"locations\"]],[24,[\"account\",\"locations\"]],\"id\",\"name\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"dynamic-checklist\",null,[[\"checkboxClass\",\"dataType\",\"collection\",\"selected\",\"property\"],[\"ui checkbox\",\"edit-mod\",[24,[\"locations\"]],[24,[\"account\",\"locations\"]],\"name\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/social-media/account-edit.hbs"
    }
  });

  _exports.default = _default;
});