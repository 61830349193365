define("lh4/components/ui/modal/display-info-modal", ["exports", "semantic-ui-ember/components/ui-modal"], function (_exports, _uiModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ESCAPE_KEY_CODE = 27;

  var _default = _uiModal.default.extend({
    classNames: ['ui', 'modal', 'longer', 'display-info-modal'],
    init: function init() {
      this._super.apply(this, arguments);

      this.attrs.centered = this.attrs.centered || false;
    },
    keyDown: function keyDown(e) {
      if (e.keyCode === ESCAPE_KEY_CODE) {
        this.send('close');
      }
    },
    touchMove: function touchMove(e) {
      // there's a semantic-ui bug with scrolling modals with touch event
      // https://github.com/Semantic-Org/Semantic-UI/issues/6656
      e.stopImmediatePropagation();
    },
    actions: {
      close: function close() {
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});