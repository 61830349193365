define("lh4/controllers/authenticated/virtual-terminal/transactions", ["exports", "lh4/enums/date-range-title", "moment"], function (_exports, _dateRangeTitle, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_REPORT_PERIOD = 14;
  var RANGES = [{
    title: _dateRangeTitle.DATE_RANGE_TITLE.TODAY,
    startDate: (0, _moment.default)().startOf('day'),
    endDate: (0, _moment.default)().endOf('day')
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.YESTERDAY,
    startDate: (0, _moment.default)().subtract(1, 'days').startOf('day'),
    endDate: (0, _moment.default)().subtract(1, 'days').endOf('day')
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.THIS,
    startDate: (0, _moment.default)().startOf('week'),
    endDate: (0, _moment.default)().endOf('day')
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.WEEKS.TWO,
    startDate: (0, _moment.default)().subtract(MAX_REPORT_PERIOD, 'days').startOf('day'),
    endDate: (0, _moment.default)().endOf('day')
  }];

  var _default = Ember.Controller.extend({
    queryParams: ['start', 'end', 'searchTerm'],
    media: Ember.inject.service(),
    session: Ember.inject.service(),
    maxDays: MAX_REPORT_PERIOD,
    flashMessagesHelper: Ember.inject.service(),
    rangeTitle: null,
    ranges: RANGES,
    headers: Ember.computed(function () {
      return [{
        field: 'invoiceId',
        name: 'Invoice',
        width: 'two'
      }, {
        field: 'total',
        name: 'Total',
        width: 'two'
      }, {
        field: 'date',
        name: 'Date',
        width: 'two'
      }, {
        field: 'authNumber',
        name: 'Auth #',
        width: 'one'
      }, {
        field: 'tipAmount',
        name: 'Tip',
        width: 'one'
      }, {
        field: 'cardNumber',
        name: 'Card #',
        width: 'two'
      }, {
        field: 'customerName',
        name: 'Name',
        width: 'two'
      }, {
        field: 'transType',
        name: 'Type',
        width: 'two'
      }, {
        field: 'action',
        name: 'Action',
        width: 'two'
      }];
    }),
    from: Ember.computed('start', function getter() {
      if (this.start) {
        return (0, _moment.default)(this.start, 'YYYY-MM-DD').startOf('day');
      }

      return (0, _moment.default)().subtract(1, 'days').startOf('day');
    }),
    to: Ember.computed('end', function getter() {
      if (this.end) {
        return (0, _moment.default)(this.end, 'YYYY-MM-DD').endOf('day');
      }

      return (0, _moment.default)().endOf('day');
    }),
    buttonTitle: Ember.computed(function getter() {
      if (this.session.isSSO) {
        return 'Disabled for Single Sign On';
      }

      return '';
    }),
    actions: {
      onMobileRangeChange: function onMobileRangeChange(rangeTitle, initialStartDate, initialEndDate) {
        this.set('rangeTitle', rangeTitle);
        var startDate;
        var endDate;

        if (!initialStartDate) {
          startDate = (0, _moment.default)();
          endDate = (0, _moment.default)();
        } else {
          startDate = initialStartDate;
          endDate = initialEndDate;
        }

        this.set('start', startDate.format('YYYY-MM-DD'));
        this.set('end', endDate.format('YYYY-MM-DD'));
      },
      onFirstSelect: function onFirstSelect(instance, firstDate) {
        // Since we allow the user to select either the beginning or the end
        // of the date at the beginning of their selection we'll limit both
        // min and max date so they can get no more than 14 days in their selection
        var maxDate = _moment.default.min((0, _moment.default)(), firstDate.clone().add(MAX_REPORT_PERIOD, 'days'));

        var minDate = firstDate.clone().subtract(MAX_REPORT_PERIOD, 'days');
        instance.setMinDate(minDate.startOf('day'));
        instance.setMaxDate(maxDate.endOf('day'));
      },
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        // Reset minDate and maxDate so a new selection can be made
        instance.setMinDate(null);
        instance.setMaxDate((0, _moment.default)().endOf('day'));
        this.set('start', startDate.format('YYYY-MM-DD'));
        this.set('end', endDate.format('YYYY-MM-DD'));
      },
      onRangeSelect: function onRangeSelect(_, range) {
        var startDate = range.startDate,
            endDate = range.endDate;
        this.set('start', startDate.format('YYYY-MM-DD'));
        this.set('end', endDate.format('YYYY-MM-DD'));
      },
      // TODO: LH-21801, extract or remove this copy pasta
      onDesktopBlur: function onDesktopBlur(_ref) {
        var view = _ref.view,
            target = _ref.target;
        var instance = view.$(target).data('calentim');
        var parts = target.value.split('-');
        var inputStart = (0, _moment.default)(parts[0].trim(), instance.options.format);
        var inputEnd = (0, _moment.default)(parts[1].trim(), instance.options.format); // validate dates entered and fallback to previous values if they're invalid

        var start = inputStart.isValid() ? inputStart.startOf('day') : instance.config.startDate;
        var end = inputEnd.isValid() ? inputEnd.endOf('day') : instance.config.endDate; // nothing changed, exit early

        if (start.isSame(instance.config.startDate) && end.isSame(instance.config.endDate)) {
          return instance.updateInput();
        }

        var maxDate = (0, _moment.default)().endOf('day'); // if start or end is after maxDate, set to today

        if (start.isAfter(maxDate)) {
          start = maxDate.clone().startOf('day');
        }

        if (end.isAfter(maxDate)) {
          end = maxDate;
        } // if start is after end, set start to the same day


        if (start.isAfter(end)) {
          start = end.clone().startOf('day');
        } // validate max report range


        var maxEnd = start.clone().add(MAX_REPORT_PERIOD, 'days');

        if (maxEnd.isAfter(maxDate)) {
          maxEnd = maxDate;
        }

        if (end.isAfter(maxEnd)) {
          end = maxEnd;
        } // remember ranges to check later if active range changed and deselect it


        var rangesBefore = JSON.stringify(instance.config.ranges); // update active ranges

        instance.config.ranges.forEach(function (aRange) {
          var range = aRange;
          range.selected = range.startDate.isSame(start, 'day') && range.endDate.isSame(end, 'day');
        });
        instance.setStart(start);
        instance.setEnd(end);
        instance.updateInput();
        this.setProperties({
          start: start.format('YYYY-MM-DD'),
          end: end.format('YYYY-MM-DD')
        }); // checks if current range changed and deselects
        // later is used, because user can blur by clicking on new range button

        Ember.run.later(function () {
          // ranges changed, unselect previous active range
          if (JSON.stringify(instance.config.ranges) !== rangesBefore) {
            instance.reDrawCalendars();
          }
        }, 200);
        return false;
      }
    }
  });

  _exports.default = _default;
});