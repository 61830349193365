define("lh4/templates/authenticated/bank-account/security", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vy1fq4xY",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"ui/modal/fullscreen-modal\",null,[[\"title\",\"onClose\",\"errorMessage\"],[\"Change Bank Account\",[28,\"action\",[[23,0,[]],\"close\"],null],[24,[\"errorMessage\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `modal.content` to be a contextual component but found a string. Did you mean `(component modal.content)`? ('lh4/templates/authenticated/bank-account/security.hbs' @ L7:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bank-account\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"verify-owner/security-form\",null,[[\"changeset\",\"showErrors\"],[[24,[\"changeset\"]],[24,[\"showErrors\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"actions\"]],\"expected `modal.actions` to be a contextual component but found a string. Did you mean `(component modal.actions)`? ('lh4/templates/authenticated/bank-account/security.hbs' @ L15:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui button primary\",[28,\"if\",[[24,[\"isSaving\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"\\n\\t\\t\\tNext\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary basic button left-action\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n\\t\\t\\tCancel\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/bank-account/security.hbs"
    }
  });

  _exports.default = _default;
});