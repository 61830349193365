define("lh4/routes/authenticated/support", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: 'Support',
    location: Ember.inject.service(),
    router: Ember.inject.service(),
    setupController: function setupController(controller) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [controller].concat(args));

      controller.set('support', this.modelFor('authenticated.support'));
    },
    beforeModel: function beforeModel(transition) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      this._super.apply(this, [transition].concat(args));

      if (transition.to.name === 'authenticated.support' || transition.to.name.includes('support-links')) {
        this.transitionTo('authenticated.support.support-links');
      } else if (transition.to.name === 'authenticated.support.support-contacts') {
        this.transitionTo('authenticated.support.support-contacts');
      }
    }
  });

  _exports.default = _default;
});