define("lh4/templates/components/ui/logo/lbm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aVuS4Vz8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"lbm-logo-container\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"branding\",\"signInLogo\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"branding\",\"signInLogo\"]]]]],[10,\"class\",\"logo\"],[11,\"alt\",[29,[[24,[\"branding\",\"friendlyName\"]],\" Logo\"]]],[11,\"style\",[29,[\"width: \",[24,[\"branding\",\"signInSize\"]],\"px\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"can\",[\"view login interface updates in internet-payment\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"branding\",\"logoDark\"]]]]],[10,\"class\",\"logo\"],[11,\"alt\",[29,[[24,[\"branding\",\"friendlyName\"]],\" Logo\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"branding\",\"logoV2\"]]]]],[10,\"class\",\"logo\"],[11,\"alt\",[29,[[24,[\"branding\",\"friendlyName\"]],\" Logo\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"divider\"],[8],[9],[0,\"\\n\\t\"],[7,\"span\",true],[8],[0,\"Lighthouse Business Manager\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/logo/lbm.hbs"
    }
  });

  _exports.default = _default;
});