define("lh4/components/reservations/reservation-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reservations-list'],
    searchedList: Ember.computed('model.reservation.@each.isNew', 'model.reservation.@each.partyTime', 'search', 'startDate', function getter() {
      var _this = this;

      if (this.get('search')) {
        var search = this.get('search').toLowerCase();
        return this.get('model.reservation').filter(function (res) {
          if (res.get('customerFullName') && res.get('customerFullName').toLowerCase().search(search) !== -1 && !res.get('isNew') && (0, _moment.default)(res.get('partyTime')).format('YYYY-MM-DD') === _this.get('startDate')) {
            return res;
          }

          return false;
        }).sortBy('partyTime');
      }

      return this.get('model.reservation').filter(function (res) {
        return !res.get('isNew') && (0, _moment.default)(res.get('partyTime')).format('YYYY-MM-DD') === _this.get('startDate');
      }).sortBy('partyTime');
    }),
    actions: {
      displayMobileReservation: function displayMobileReservation() {
        if (window.matchMedia('(max-width: 767px)').matches) {
          this.sendAction('displayMobileReservation');
        }
      }
    }
  });

  _exports.default = _default;
});