define("lh4/components/dashboard-widgets/guests/data", ["exports", "lh4/mixins/dashboard-widget-mixin", "lh4/formats"], function (_exports, _dashboardWidgetMixin, _formats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    locale: Ember.inject.service(),
    classNames: ['table-widget', 'guests-data'],
    skeletonData: {
      salesPerGuest: 15.5
    },
    chartData: Ember.computed('data', function getter() {
      var data = this.get('data.guestsPerHour').map(function (g) {
        return {
          hour: g.hour,
          value: parseInt(g.guests, 10)
        };
      });
      return data;
    }),
    chartOptions: Ember.computed('data', function getter() {
      var _this = this;

      return {
        categoryField: 'hour',
        parseDates: false,
        valueField: 'value',
        chartCursor: {
          categoryBalloonFunction: function categoryBalloonFunction(value) {
            return _this.locale.formatTimeForAmChart(value, _this.useIntlDates, _formats.default.time.SHORT);
          }
        },
        categoryAxis: {
          labelFunction: function labelFunction(value) {
            return _this.locale.formatTimeForAmChart(value, _this.useIntlDates, _formats.default.time.SHORT);
          }
        }
      };
    })
  });

  _exports.default = _default;
});