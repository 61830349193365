define("lh4/templates/components/ui/form-fields/number-input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "abdjJTgT",
    "block": "{\"symbols\":[\"@class\",\"@value\",\"@min\",\"@max\"],\"statements\":[[5,\"input\",[[12,\"data-test-input-field\",\"number\"],[12,\"oninput\",[28,\"action\",[[23,0,[]],\"validateNumberInput\"],null]]],[[\"@class\",\"@value\",\"@min\",\"@max\",\"@type\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],\"text\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/number-input-field.hbs"
    }
  });

  _exports.default = _default;
});