define("lh4/components/dashboard-widgets/add-new-carousel", ["exports", "ember-gestures/mixins/recognizers", "lh4/components/dashboard-widgets/dashboard-widget"], function (_exports, _recognizers, _dashboardWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_recognizers.default, {
    recognizers: 'swipe',
    widgets: undefined,
    scrollIndex: 0,
    defaultSettings: _dashboardWidget.DEFAULT_WIDGET_SETTINGS,
    updateIndex: function updateIndex() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('scrollIndex');
      var widgetWidth = this.$('.dashboard-widget').outerWidth();
      this.$('.carousel-wrapper').stop().animate({
        scrollLeft: widgetWidth * index
      }, 300);
      this.set('scrollIndex', index);
    },
    didInsertElement: function didInsertElement() {
      this.$().parent().height(0);
      this.set('_onResize', Ember.run.bind(this, function resizer() {
        this.updateIndex();
      }));
      window.addEventListener('resize', this._onResize);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var $parent = this.$().parent();

      if (this.get('visible')) {
        var curHeight = $parent.outerHeight();
        var autoHeight = $parent.css('height', 'auto').outerHeight();
        $parent.height(curHeight); // Wrapping animation into next() prevents from modifying other properties twice in the single render
        // In our case this is happening when mobile orientation is being changed on large resolution mobile devices
        // An estimated guess is that jQuery animations are interfering with ember-responsive library
        // Issue reference: https://github.com/emberjs/ember.js/issues/13948

        Ember.run.next(function () {
          $parent.stop().animate({
            height: autoHeight
          }, 300, function () {
            // after animation finishes reset to height: auto
            $parent.css({
              height: 'auto'
            });
          });
        });
      } else {
        // Wrapping animation into next() prevents from modifying other properties twice in the single render
        // In our case this is happening when mobile orientation is being changed on large resolution mobile devices
        // An estimated guess is that jQuery animations are interfering with ember-responsive library
        // Issue reference: https://github.com/emberjs/ember.js/issues/13948
        Ember.run.next(function () {
          $parent.stop().animate({
            height: 0
          }, 300);
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('resize', this._onResize);
    },
    widgetsToScroll: Ember.computed('numberOfColumns', function getter() {
      switch (this.get('numberOfColumns')) {
        case 'one':
          return 1;

        case 'two':
          return 2;

        case 'three':
          return 3;

        default:
          return false;
      }
    }),
    actions: {
      next: function next() {
        if (this.get('scrollIndex') >= this.get('widgets.length') - this.get('widgetsToScroll')) {
          this.updateIndex(0);
        } else {
          this.updateIndex(this.get('scrollIndex') + this.get('widgetsToScroll'));
        }
      },
      previous: function previous() {
        if (this.get('scrollIndex') <= 0) {
          this.updateIndex(this.get('widgets.length') - this.get('widgetsToScroll'));
        } else {
          this.updateIndex(this.get('scrollIndex') - this.get('widgetsToScroll'));
        }
      },
      addWidget: function addWidget(_ref) {
        var slug = _ref.slug;
        var addedText = this.$(".".concat(slug, " .added-text")).addClass('visible').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
          addedText.removeClass('visible');
        });
        this.sendAction('addWidget', {
          settings: _dashboardWidget.DEFAULT_WIDGET_SETTINGS[slug] || {},
          slug: slug
        });
      }
    }
  });

  _exports.default = _default;
});