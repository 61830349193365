define("lh4/utils/has-required-products", ["exports", "lh4/enums/products"], function (_exports, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasRequiredProduct = hasRequiredProduct;
  _exports.default = hasRequiredProducts;

  /**
   * Returns whether or not a location has the required product
   *
   * @param {string} product product to check for
   * @param {Object} location location to be checked
   * @returns {Boolean} if location has required product
   */
  function hasRequiredProduct(productName, location) {
    var product = productName;

    if (product === _products.default.INTERNET_PAYMENTS) {
      return location.product === _products.default.INTERNET_PAYMENTS;
    }

    if (product === 'skytab') {
      product = 'gpatt';
    }

    var result = location.terminalProducts && location.terminalProducts.includes(product);
    return !!result;
  }
  /**
   * Checks if location has all required products
   * Used for dashboard layouts and widgets
   *
   * @param {Array} products list of products to check for
   * @param {Object} location location to be checked
   * @returns {Boolean} if location has required product
   */


  function hasRequiredProducts(products, location) {
    return products.every(function (product) {
      return hasRequiredProduct(product, location);
    });
  }
});