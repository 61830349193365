define("lh4/templates/authenticated/settings/users/edit-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2zYJyWFZ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"modal/user-modal\",null,[[\"user\",\"visible\",\"type\",\"permissionSets\",\"locations\",\"permissions\",\"loading\",\"modalDeny\",\"saveUser\"],[[24,[\"changeset\"]],true,\"edit\",[24,[\"permissionSets\"]],[24,[\"model\",\"locations\"]],[24,[\"model\",\"permissions\"]],[28,\"or\",[[24,[\"loading\"]],[24,[\"isLoading\"]]],null],[28,\"action\",[[23,0,[]],\"modalDeny\"],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/settings/users/edit-user.hbs"
    }
  });

  _exports.default = _default;
});