define("lh4/adapters/hardware/items/discount", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'api/v1',
    location: Ember.inject.service(),
    query: function query(store, type, _query) {
      var url = "".concat(this.host, "/").concat(this.namespace, "/hardware/items/discounts");
      return this.ajax(url, 'GET', {
        data: _query
      });
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      var response = this._super.apply(this, [status, headers, payload, requestData].concat(args));

      if (!response.discounts) {
        return response;
      }

      return {
        discounts: response.discounts
      };
    }
  });

  _exports.default = _default;
});