define("lh4/mirage/factories/hospitality-menu-category", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    buttonName: function buttonName() {
      return _faker.default.commerce.productName();
    },
    contentMap: null,
    contentSource: 'RuntimeMap',
    genreId: null,
    graphic: null,
    id: function id(i) {
      return i + 1;
    },
    isActive: function isActive() {
      return _faker.default.datatype.boolean();
    },
    locationId: 1,
    ordinal: 15,
    rowguid: function rowguid() {
      return _faker.default.datatype.uuid();
    },
    rvScreenCategory: {
      type: 'Buffer',
      data: [91, 111, 98, 106, 101, 99, 116, 32, 79, 98, 106, 101, 99, 116, 93]
    },
    viewAsList: false
  });

  _exports.default = _default;
});