define("lh4/components/ui/dates/date-picker", ["exports", "lh4/utils/no-op", "lh4/enums/key-code", "lh4/mixins/components/ui/dates/date-picker-mixin", "moment", "lh4/enums/date-time-format"], function (_exports, _noOp, _keyCode, _datePickerMixin, _moment, _dateTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_datePickerMixin.default, {
    classNames: ['date-picker', 'ui', 'dropdown', 'selection', 'floating'],
    date: null,
    disabled: false,
    arrowOn: 'left',
    maxDate: null,
    minDate: null,
    onafterselect: _noOp.default,
    onBlur: _noOp.default,
    onFocus: _noOp.default,
    picker: null,
    placeholder: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupPicker();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var date = this.date,
          picker = this.picker;

      if (!date && picker) {
        picker.clearInput();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.picker) {
        this.picker.destroy();
      }
    },
    getOptions: function getOptions() {
      var options = this.getProperties('arrowOn', 'maxDate', 'minDate', 'onafterselect');
      return _objectSpread(_objectSpread({}, options), {}, {
        autoCloseOnSelect: true,
        calendarCount: 1,
        enableKeyboard: false,
        format: _dateTimeFormat.default.LOCALIZED_DATE_ONLY,
        oneCalendarWidth: 300,
        showFooter: false,
        showHeader: false,
        showTimePickers: false,
        singleDate: true,
        startEmpty: !this.date
      });
    },
    setupPicker: function setupPicker() {
      this.$('.date-picker-input').calentim(this.getOptions());
      var instance = this.$('.date-picker-input').data('calentim');
      instance.setStart(this.date ? (0, _moment.default)(this.date, _moment.default.ISO_8601) : null);
      instance.setEnd(this.date ? (0, _moment.default)(this.date, _moment.default.ISO_8601) : null);
      this.set('picker', instance);
    },
    actions: {
      onFocus: function onFocus() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var event = args[0];
        this.picker.showDropdown(event);

        if (this.onFocus) {
          this.onFocus.apply(this, args);
        }
      },
      onKeyDown: function onKeyDown(event) {
        if (event.keyCode === _keyCode.default.TAB || event.keyCode === _keyCode.default.ESC) {
          this.picker.hideDropdown(event);
        }
      },
      onBlur: function onBlur(event) {
        var _this = this;

        Ember.run.later(function () {
          if (_this.onBlur) {
            _this.onBlur(event, _this.picker);
          }
        }, 100);
      }
    }
  });

  _exports.default = _default;
});