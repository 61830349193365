define("lh4/templates/components/internet-payments/ui/checkbox/toggle-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h4I6DJod",
    "block": "{\"symbols\":[\"@className\",\"@label\",\"@checked\",\"@disabled\",\"@data-test-toggle-switch\"],\"statements\":[[5,\"ui-checkbox\",[[12,\"class\",[29,[\"internet-payments-toggle toggle checkbox \",[23,1,[]]]]]],[[\"@label\",\"@checked\",\"@disabled\",\"@onChange\",\"@data-test-toggle-switch\"],[[23,2,[]],[23,3,[]],[23,4,[]],[28,\"action\",[[23,0,[]],[23,0,[\"onChange\"]]],null],[23,5,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/checkbox/toggle-switch.hbs"
    }
  });

  _exports.default = _default;
});