define("lh4/templates/components/ui/icons/nav-bar/bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C5AjwEmN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"40\"],[10,\"height\",\"40\"],[10,\"viewBox\",\"0 0 40 40\"],[10,\"fill\",\"none\"],[10,\"data-ember-extension\",\"1\"],[8],[0,\"\\n\\t\"],[7,\"g\",true],[10,\"clip-path\",\"url(#clip0_1356_1708)\"],[8],[0,\"\\n\\t\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M20 30C21.1 30 22 29.1 22 28H18C18 29.1 18.9 30 20 30ZM26 24V19C26 15.93 24.37 13.36 21.5 12.68V12C21.5 11.17 20.83 10.5 20 10.5C19.17 10.5 18.5 11.17 18.5 12V12.68C15.64 13.36 14 15.92 14 19V24L12 26V27H28V26L26 24ZM24 25H16V19C16 16.52 17.51 14.5 20 14.5C22.49 14.5 24 16.52 24 19V25Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"defs\",true],[8],[0,\"\\n\\t\\t\"],[7,\"clipPath\",true],[10,\"id\",\"clip0_1356_1708\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"rect\",true],[10,\"width\",\"40\"],[10,\"height\",\"40\"],[10,\"rx\",\"20\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/nav-bar/bell.hbs"
    }
  });

  _exports.default = _default;
});