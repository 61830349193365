define("lh4/routes/authenticated/supply-orders/location/equipment", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Equipment Order',
    requiredPermissions: 'place supply order',
    supplyCart: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.supplyCart.locationId === null) {
        this.transitionTo('authenticated.supply-orders.location');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        equipment: this.store.query('supply-orders/product', {
          locationId: this.get('supplyCart.locationId')
        })
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var equipmentId = this.supplyCart.equipmentId;

        if (equipmentId === null) {
          if (transition.targetName === 'supply-orders.location.supplies' || transition.targetName === 'supply-orders.location.order') {
            transition.abort();
          }
        }
      }
    }
  });

  _exports.default = _default;
});