define("lh4/routes/authenticated/epro/accounting/taxes/edit", ["exports", "lh4/routes/general-edit-route", "lh4/mixins/changeset-transition-route-mixin"], function (_exports, _generalEditRoute, _changesetTransitionRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend(_changesetTransitionRouteMixin.default, {
    modelName: 'tax',
    childChangesets: {
      itemTaxMap: 'itemTaxMapChangesets',
      taxSetTaxMap: 'taxSetTaxMapChangesets',
      taxRules: 'taxRuleChangesets'
    },
    model: function model(_ref) {
      var taxId = _ref.taxId;
      return Ember.RSVP.hash({
        tax: this.store.findRecord('echo-pro/tax', taxId),
        items: this.modelFor('authenticated.epro.accounting.taxes').items,
        orderTypes: this.modelFor('authenticated.epro.accounting.taxes').orderTypes,
        taxSets: this.modelFor('authenticated.epro.accounting.taxes').taxSets
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      controller.set('buildChildChangeset', function (childModel) {
        return _this.buildChildChangeset(childModel);
      });
    }
  });

  _exports.default = _default;
});