define("lh4/components/dashboard-widgets/deposit-summary/data", ["exports", "moment", "lh4/mixins/dashboard-widget-mixin", "lh4/utils/date-moment"], function (_exports, _moment, _dashboardWidgetMixin, _dateMoment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['deposit-summary-data', 'table-widget'],
    skeletonData: {
      deposits: [{
        ReferenceNumber: '8870431459135257',
        Amount: 48264.82,
        BatchDate: '10/18/2018'
      }, {
        ReferenceNumber: '5829618010584700',
        Amount: 231841.25,
        BatchDate: '10/17/2018'
      }, {
        ReferenceNumber: '1832221972489510',
        Amount: 171523.91,
        BatchDate: '10/16/2018'
      }, {
        ReferenceNumber: '8208442500448546',
        Amount: 582429.67,
        BatchDate: '10/15/2018'
      }]
    },
    store: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    deposits: Ember.computed('expanded', 'data', function getter() {
      var deposits = !this.expanded ? this.data.deposits.slice(0, 4) : this.data.deposits;
      return deposits.sort(function (x, y) {
        return (0, _moment.default)(y.BatchDate, 'L').diff((0, _moment.default)(x.BatchDate, 'L'));
      });
    }),
    reportDates: Ember.computed(function getter() {
      var _this$store$peekRecor = this.store.peekRecord('preferences', this.tokenData.data.sub),
          eod = _this$store$peekRecor.eod,
          weekStart = _this$store$peekRecor.weekStart;

      var _getWeekToDateRange = (0, _dateMoment.getWeekToDateRange)(eod, weekStart),
          startDate = _getWeekToDateRange.startDate,
          endDate = _getWeekToDateRange.endDate;

      return {
        startsAt: startDate.format(),
        startsAtTime: startDate.format('HH:mmA'),
        endsAt: endDate.format(),
        endsAtTime: endDate.format('HH:mmA')
      };
    })
  });

  _exports.default = _default;
});