define("lh4/mirage/factories/epro-tax-set", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    locationId: 1,
    name: function name() {
      return _faker.default.lorem.word();
    },
    isAllLiquor: false,
    isIncludedInTipOut: false,
    grossPercentage: 3,
    isIncludedInGratuity: false,
    isIncludedInFavorites: true,
    isNonSalesRevenue: false,
    isActive: true
  });

  _exports.default = _default;
});