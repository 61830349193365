define("lh4/models/echo-pro/availability-price/configuration", ["exports", "moment", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _moment, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    scheduleTitle: {
      description: 'Group Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    overrideDates: {
      description: 'Specific Dates',
      validators: [(0, _emberCpValidations.validator)('array-length', {
        min: 1,
        max: 12,
        dependentKeys: ['model.overrideDates.[]'],
        disabled: Ember.computed.not('model.isDateOverrideEnabled')
      }), (0, _emberCpValidations.validator)(function (periods) {
        if (!Array.isArray(periods) || periods.length < 2) {
          return [];
        }

        var sortedPeriods = _toConsumableArray(periods).sort(function (a, b) {
          return (0, _moment.default)(a.beginDate).diff((0, _moment.default)(b.beginDate));
        }).filter(function (period) {
          return period.beginDate && period.endDate;
        });

        var hasOverlaps = sortedPeriods.some(function (_ref, index) {
          var beginDate = _ref.beginDate;

          if (index === 0) {
            return false;
          }

          var prev = sortedPeriods[index - 1];

          if ((0, _moment.default)(beginDate).isSameOrBefore(prev.endDate)) {
            return true;
          }

          return false;
        });

        if (hasOverlaps) {
          return 'Date ranges for Specific Dates cannot contain any overlaps';
        }

        return true;
      }, {
        dependentKeys: ['model.overrideDates.[]'],
        disabled: Ember.computed.not('model.isDateOverrideEnabled')
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    scheduleTitle: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean'),
    revenueCenterGuids: (0, _attr.default)(),
    intradayPeriodGroupGuids: (0, _attr.default)(),
    overrideDates: (0, _attr.default)(),
    isDateOverrideEnabled: (0, _attr.default)('boolean'),
    schedules: (0, _relationships.hasMany)('echo-pro/availability-price/schedule'),
    timeOption: (0, _attr.default)('string')
  });

  _exports.default = _default;
});