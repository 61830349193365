define("lh4/mirage/routes/supply-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageSupplyOrdersServer;

  function setMirageSupplyOrdersServer(server) {
    server.get('/supply-orders/orders', function () {
      return {
        orders: [{
          id: 1,
          billedAt: null,
          cancelledAt: null,
          createdAt: '2016-12-22T20:26:01.890Z',
          discount: '0.00',
          fulfilledAt: null,
          fulfilledBy: null,
          isBilled: false,
          isCancelled: false,
          isFulfilled: false,
          isGraphite: false,
          mid: '0000000000',
          shippingAddress1: 'street 1',
          shippingAddress2: 'street 2',
          shippingAttention: '',
          shippingCity: 'city',
          shippingPrice: 14.24,
          shippingState: 'state',
          shippingTo: 'ship to',
          shippingZip: 'zip',
          shippingZipExtended: '9334',
          updatedAt: '2016-12-22T20:26:01.890Z'
        }, {
          id: 2,
          billedAt: null,
          cancelledAt: null,
          createdAt: '2016-12-22T20:26:01.890Z',
          discount: '0.00',
          fulfilledAt: null,
          fulfilledBy: null,
          isBilled: false,
          isCancelled: false,
          isFulfilled: false,
          isGraphite: false,
          mid: '1111111111',
          shippingAddress1: 'street 1',
          shippingAddress2: 'street 2',
          shippingAttention: '',
          shippingCity: 'city',
          shippingPrice: 14.24,
          shippingState: 'state',
          shippingTo: 'ship to',
          shippingZip: 'zip',
          shippingZipExtended: '9334',
          updatedAt: '2016-12-22T20:26:01.890Z'
        }]
      };
    });
    server.get('/orders', function () {
      return {
        orders: [{
          id: 1,
          createdAt: '2017-02-01T09:34:47.000Z',
          merchantId: '0000000000',
          orderStage: 'Order Not Continuing - Decline/Cancel/Hold/Invalid',
          orderStageId: 86,
          orderStagePercent: 12,
          orderStatus: 'Cancelled',
          orderStatusId: 200,
          orderType: 'Accessory Only Order'
        }, {
          id: 2,
          createdAt: '2017-02-01T09:34:47.000Z',
          merchantId: '1111111111',
          orderStage: 'Completed',
          orderStageId: 86,
          orderStagePercent: 100,
          orderStatus: 'Completed',
          orderStatusId: 200,
          orderType: 'Accessory Only Order'
        }]
      };
    }); // Model?

    server.post('/supply-orders/addresses', function () {
      return {
        address: [{
          addressId: 't3st',
          name: '',
          company: '',
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: '',
          email: '',
          extendedZip: '',
          phone: '',
          isCommercial: true,
          isValid: true,
          candidates: []
        }]
      };
    }); // arrow function result used to be wrapped in this at one point:
    // if (request.params.id === '0000000000') {

    server.get('/supply-orders/products/by-mid/:id', function () {
      return {
        products: [{
          displayName: 'Receipt Printer',
          externalName: 'Receipt Printer',
          id: '0-1983',
          imageLink: 'https://s3.amazonaws.com/ht-supply-system/devices/receipt-printer.png',
          products: [{
            description: 'single-ply',
            displayName: '3 X 150 Single Ply',
            id: 3,
            imageLink: 'https://s3.amazonaws.com/ht-supply-system/items/paper-rolls.png',
            model: 'single-ply',
            price: '15.17',
            weight: 21
          }]
        }]
      };
    });
    server.post('/supply-orders/shipping-orders', function () {
      return {
        order: {
          addressId: 'b834ff69-f140-426e-82b0-31b706de5a0b',
          createdAt: '2017-02-24T18:39:56.330Z',
          id: 'e243a95f-ce59-4944-9bf2-0e91c0946c28',
          isSaturdayDelivery: false,
          publishedRate: 17.09,
          rate: 12.23,
          serviceId: '3197bb2e-9ee6-4d93-a9c1-646f33dbc0e3',
          shipments: [{
            addressId: 'b834ff69-f140-426e-82b0-31b706de5a0b',
            createdAt: '2017-02-24T18:39:56.338Z',
            height: 11,
            id: 'd0af7668-dff6-4547-a26a-e149a22f37d4',
            isPurchased: false,
            isSaturdayDelivery: false,
            isVoid: false,
            labelHtmlImage: null,
            labelImage: null,
            labelImageFormat: null,
            length: 8,
            orderId: 'e243a95f-ce59-4944-9bf2-0e91c0946c28',
            publishedRate: 17.09,
            rate: 12.23,
            serviceId: '3197bb2e-9ee6-4d93-a9c1-646f33dbc0e3',
            trackingNumber: '1Z05073E4294178567',
            updatedAt: '2017-02-24T18:39:56.338Z',
            weight: 21,
            width: 2
          }],
          updatedAt: '2017-02-24T18:39:56.330Z'
        }
      };
    });
    server.get('/supply-orders/services', function () {
      return {
        services: [{
          displayName: 'Ground',
          id: '3197bb2e-9ee6-4d93-a9c1-646f33dbc0e3',
          isActive: true
        }, {
          displayName: 'Second Day',
          id: '07b00c24-b238-4a3b-a2e4-28fab0394531',
          isActive: true
        }, {
          displayName: 'Next Day',
          id: '2a8d5ce8-3729-475b-ac77-d7d6131087c2',
          isActive: true
        }]
      };
    });
  }
});