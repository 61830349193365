define("lh4/routes/authenticated/loyalty/programs", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'view loyalty',
    location: Ember.inject.service(),
    queryParams: {
      isActive: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      searchName: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        programs: this.store.query('loyalty/program', params)
      });
    }
  });

  _exports.default = _default;
});