define("lh4/templates/components/internet-payments/ui/relay-cursor-paginator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Iw85FMBg",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"nav\",true],[10,\"class\",\"pagination-footer\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[8],[0,\"Rows per page\"],[9],[0,\"\\n\\t\"],[5,\"ui-dropdown\",[],[[\"@class\",\"@selected\",\"@onChange\"],[\"selection\",[23,0,[\"itemsPerPage\"]],[28,\"fn\",[[23,0,[\"handleItemsPerPageChange\"]]],null]]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"chevron down icon\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"ol\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"itemsPerPageOptions\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"li\",true],[10,\"class\",\"item\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[5,\"ui/client-pagination-buttons\",[],[[\"@currentPage\",\"@totalPages\",\"@onPageChange\",\"@showPageLinks\"],[[23,0,[\"currentPage\"]],[23,0,[\"totalPages\"]],[23,0,[\"handlePageChange\"]],false]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/relay-cursor-paginator.hbs"
    }
  });

  _exports.default = _default;
});