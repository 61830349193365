define("lh4/controllers/authenticated/epro/menu/order-types", ["exports", "lh4/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    headers: [{
      field: 'name',
      name: 'Name',
      width: 'eight',
      sortable: true
    }, {
      field: 'isActive',
      name: 'Status',
      width: 'eight',
      sortable: true,
      type: 'active'
    }],
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super((_this$location = this.location) === null || _this$location === void 0 ? void 0 : (_this$location$model = _this$location.model) === null || _this$location$model === void 0 ? void 0 : _this$location$model.id);
    })
  });

  _exports.default = _default;
});