define("lh4/controllers/authenticated/settings/2fa/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    twoFactorAuth: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    isLoading: false,
    errorField: null,
    step: 0,
    steps: ['Two-Factor App', 'App configuration'],
    secretCode: null,
    userCode: null,
    recoveryCodes: [],
    actions: {
      backTo2fa: function backTo2fa() {
        if (this.step === 2) {
          this.session.invalidate();
          return;
        }

        this.router.transitionTo('authenticated.settings.2fa');
        this.set('step', 0);
      },
      nextStep: function nextStep(inputData) {
        if (this.isLoading) {
          return;
        }

        this.set('isLoading', true);

        if (this.step === 0) {
          this.start2faSetup();
        } else if (this.step === 1) {
          this.enable2fa(inputData && inputData.userCode);
        } else {
          this.session.invalidate();
        }
      }
    },
    start2faSetup: function start2faSetup() {
      var _this = this;

      this.twoFactorAuth.generateSecretKey().then(function (res) {
        _this.set('secretCode', res.secretCode);

        _this.incrementProperty('step');
      }).catch(function (e) {
        var err = JSON.parse(e);

        _this.errorHandler.handleResponseError(err);
      }).finally(function () {
        return _this.set('isLoading', false);
      });
    },
    enable2fa: function enable2fa(userCode) {
      var _this2 = this;

      if (!userCode || !userCode.trim() || userCode.length !== 6) {
        this.set('errorField', 'userCode');
        this.flashMessagesHelper.pushMessage('Verification code is not correct. Must be exactly 6 characters', 'error');
        this.set('isLoading', false);
        return;
      }

      this.twoFactorAuth.enable2fa(this.secretCode, userCode).then(function (result) {
        _this2.set('secretCode', null);

        _this2.set('recoveryCodes', result.codes);

        _this2.incrementProperty('step');

        _this2.flashMessagesHelper.pushMessage('Two-Factor Authentication Enabled', 'success');
      }).catch(function (err) {
        return _this2.errorHandler.handleResponseError(err);
      }).finally(function () {
        return _this2.set('isLoading', false);
      });
    }
  });

  _exports.default = _default;
});