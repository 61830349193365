define("lh4/mirage/factories/integration/s4/webhook", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id: function id() {
      return _faker.default.datatype.guid();
    },
    callCount: 0,
    description: function description() {
      return _faker.default.commerce.productDescription();
    },
    environment: 'Development',
    eventTypes: ['SALE', 'REFUND', 'DISPUTE'],
    isActive: true,
    locationId: 1,
    name: function name() {
      return _faker.default.commerce.productName();
    },
    signingSecret: function signingSecret() {
      return Buffer.from(_faker.default.datatype.string(16)).toString('hex');
    },
    url: function url() {
      return _faker.default.internet.url();
    }
  });

  _exports.default = _default;
});