define("lh4/templates/components/tables/supply-orders-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8YyUrNlX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"center aligned\"],[8],[1,[24,[\"order\",\"id\"]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[24,[\"order\",\"mid\"]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[28,\"moment-utc-format\",[[24,[\"order\",\"createdAt\"]],\"MM/DD/YYYY hh:mmA\"],null],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[24,[\"order\",\"shippingCity\"]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[24,[\"order\",\"shippingTo\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/tables/supply-orders-table-row.hbs"
    }
  });

  _exports.default = _default;
});