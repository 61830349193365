define("lh4/adapters/echo-pro/stylesheet", ["exports", "lh4/adapters/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindAll: function urlForFindAll() {
      return "".concat(this.host, "/").concat(this.namespace, "/echo-pro/stposUiStylesheets");
    }
  });

  _exports.default = _default;
});