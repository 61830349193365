define("lh4/routes/authenticated/reputation/accounts/link", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/services/social-media/facebook", "lh4/services/reputation/google-my-business", "lh4/services/reputation/yelp"], function (_exports, _authenticatedRouteMixin, _facebook, _googleMyBusiness, _yelp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable camelcase */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      accountType: {
        refreshModel: true
      },
      code: {
        refreshModel: true
      },
      state: {
        refreshModel: true
      },
      error: {
        refreshModel: true
      },
      error_reason: {
        refreshModel: true
      },
      error_description: {
        refreshModel: true
      }
    },
    facebook: Ember.inject.service('social-media/facebook'),
    accountApi: Ember.inject.service('reputation/account-api'),
    smAccountApi: Ember.inject.service('social-media/account-api'),
    google: Ember.inject.service('reputation/google-my-business'),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _transition$to;

      var _this$facebook$checkF = this.facebook.checkFbRedirectErrors(transition === null || transition === void 0 ? void 0 : (_transition$to = transition.to) === null || _transition$to === void 0 ? void 0 : _transition$to.queryParams),
          errorMessage = _this$facebook$checkF.errorMessage,
          redirectOrigin = _this$facebook$checkF.redirectOrigin;

      if (errorMessage) {
        transition.abort();
        this.flashMessagesHelper.pushMessage(errorMessage, 'error');

        if (redirectOrigin) {
          return window.location.replace(redirectOrigin);
        }

        return this.transitionTo('authenticated.social-media.accounts');
      }

      var adjustedUrl = this.get('smAccountApi').adjustRedirectUrlHash(window.location);

      if (adjustedUrl) {
        transition.abort();
        window.location.href = adjustedUrl;
      }

      return false;
    },
    model: function model(_ref) {
      var _this = this;

      var accountType = _ref.accountType,
          accountId = _ref.state,
          code = _ref.code;
      var typeInLocalStorage = window.localStorage.getItem('social-media-type');
      var locationId = window.localStorage.getItem('reputation-selected-location'); // facebook-page

      if (code && this.accountApi.isFbPage(typeInLocalStorage)) {
        if (code && accountId) {
          return this.facebook.relinkAccount(accountId, code, 'reputation').then(function () {
            _this.flashMessagesHelper.pushMessage('Successfully linked account', 'success');

            _this.transitionTo('authenticated.reputation.accounts.edit', accountId);
          }).catch(function (err) {
            _this.errorHandler.handleResponseError(err);

            _this.transitionTo('authenticated.reputation.accounts');
          });
        }

        return Ember.RSVP.hash({
          accountType: typeInLocalStorage,
          locations: this.modelFor('authenticated.reputation').locations,
          usedLocations: this.loadExcludedLocations(_facebook.ACCOUNT_TYPE_FB_PAGE),
          account: {
            name: '',
            locationId: locationId
          },
          code: code
        });
      } // Google My Business


      if (code && this.accountApi.isGoogleBusiness(typeInLocalStorage)) {
        return Ember.RSVP.hash({
          accountType: typeInLocalStorage,
          locations: this.modelFor('authenticated.reputation').locations,
          usedLocations: this.loadExcludedLocations(_googleMyBusiness.ACCOUNT_TYPE_GOOGLE_BUSINESS),
          account: {
            name: '',
            locationId: locationId
          },
          code: code
        });
      } // yelp


      if (this.accountApi.isYelp(accountType)) {
        return Ember.RSVP.hash({
          accountType: accountType,
          locations: this.modelFor('authenticated.reputation').locations,
          usedLocations: this.loadExcludedLocations(_yelp.ACCOUNT_TYPE_YELP),
          account: {
            name: '',
            locationId: locationId,
            yelpBusiness: null
          }
        });
      }

      this.flashMessagesHelper.pushMessage('Incorrect navigation attempt', 'error');
      this.transitionTo('authenticated.reputation.accounts');
      return false;
    },
    loadExcludedLocations: function loadExcludedLocations(accountType) {
      var _this2 = this;

      return this.accountApi.getExcludedLocations(accountType).then(function (results) {
        return results.locations;
      }).catch(function (err) {
        _this2.errorHandler.handleResponseError(err);

        return [];
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // do not use sticky query params
        // https://guides.emberjs.com/v3.15.0/routing/query-params/#toc_sticky-query-param-values
        controller.setProperties({
          accountType: undefined,
          code: undefined,
          state: undefined,
          error: undefined,
          error_reason: undefined,
          error_description: undefined
        });
      }
    }
  });
  /* eslint-enable camelcase */


  _exports.default = _default;
});