define("lh4/templates/components/ui/form-fields/numeric-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lRLuS25H",
    "block": "{\"symbols\":[\"@disabled\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"numeric-field \",[22,\"fieldClasses\"]]]],[10,\"data-test-form-field-numeric\",\"\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui \",[22,\"setLoading\"],\" button decrease\"]]],[12,\"disabled\",[28,\"or\",[[23,0,[\"isDecreaseDisabled\"]],[23,1,[]]],null]],[12,\"data-test-numeric-field-button\",\"decrease\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"decrease\"]],[8],[0,\"\\n\\t\\t-\\n\\t\"],[9],[0,\"\\n\\t\"],[5,\"ui/fields/input-field\",[[12,\"onkeydown\",[28,\"action\",[[23,0,[]],\"onKeydown\"],null]],[12,\"oninput\",[28,\"action\",[[23,0,[]],\"onInput\"],null]]],[[\"@inputClass\",\"@value\",\"@disabled\"],[\"numeric-input\",[23,0,[\"value\"]],[23,0,[\"isInputDisabled\"]]]]],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui \",[22,\"setLoading\"],\" button increase\"]]],[12,\"disabled\",[28,\"or\",[[23,0,[\"isIncreaseDisabled\"]],[23,1,[]]],null]],[12,\"data-test-numeric-field-button\",\"increase\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"increase\"]],[8],[0,\"\\n\\t\\t+\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/numeric-field.hbs"
    }
  });

  _exports.default = _default;
});