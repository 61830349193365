define("lh4/utils/sort-array-by-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortArrayByArray;

  /**
   * Sort an array according to the list on another array.
   *
   * @method sortArrayByArray
   *
   * @param {Object[]} arrayToSort - Array which we'll sort here.
   * @param {string[]} sortList - array of strings how we should sort the array.
   * @param {string[]} sortPropertyName - property name of the param in the array which we'll sort .
   * @returns {Object[]} Array of object containing params.
   */
  function sortArrayByArray(arrayToSort, sortList, sortPropertyName) {
    return arrayToSort.sort(function (a, b) {
      var ai = sortList.indexOf(a[sortPropertyName]);
      var bi = sortList.indexOf(b[sortPropertyName]);

      if (ai === -1) {
        return 1;
      }

      if (bi === -1) {
        return -1;
      }

      return ai >= bi ? 1 : -1;
    });
  }
});