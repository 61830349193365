define("lh4/adapters/support/group", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL() {
      return "".concat(this.host, "/api/v1/support/tickets/groups");
    }
  });

  _exports.default = _default;
});