define("lh4/routes/authenticated/epro/accounting/surcharges/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'surcharge',
    model: function model(_ref) {
      var surchargeId = _ref.surchargeId;

      var _this$modelFor = this.modelFor('authenticated.epro.accounting.surcharges'),
          orderTypes = _this$modelFor.orderTypes,
          items = _this$modelFor.items,
          taxSets = _this$modelFor.taxSets;

      var surcharge = this.store.findRecord('echo-pro/accounting/surcharge', surchargeId);
      return Ember.RSVP.hash({
        surcharge: surcharge,
        orderTypes: orderTypes,
        items: items,
        taxSets: taxSets
      });
    }
  });

  _exports.default = _default;
});