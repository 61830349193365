define("lh4/enums/transactions-search-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IP_TRANSACTIONS_SEARCH_FILTERS = _exports.FNB_TRANSACTIONS_SEARCH_FILTERS = void 0;
  var FNB_TRANSACTIONS_SEARCH_FILTERS = Object.freeze([{
    name: 'customerName',
    value: 'Customer'
  }, {
    name: 'customerEmail',
    value: 'Email'
  }, {
    name: 'cardNumber',
    value: 'Payment Method'
  }, {
    name: 'invoice',
    value: 'Transaction ID'
  }, {
    name: 'authorizationCode',
    value: 'Authorization Code'
  }, {
    name: 'customerIpAddress',
    value: 'Customer IP Address'
  }, {
    name: 'cardToken',
    value: 'Card Token'
  }, {
    name: 'orderNumber',
    value: 'Order'
  }, {
    name: 'serverName',
    value: 'Server'
  }]);
  _exports.FNB_TRANSACTIONS_SEARCH_FILTERS = FNB_TRANSACTIONS_SEARCH_FILTERS;
  var IP_TRANSACTIONS_SEARCH_FILTERS = Object.freeze([{
    name: 'merchantReference',
    value: 'Merchant Reference'
  }, {
    name: 'customerName',
    value: 'Customer'
  }, {
    name: 'issuerBIN',
    value: 'Issuer BIN'
  }, {
    name: 'customerEmail',
    value: 'Email'
  }, {
    name: 'cardNumber',
    value: 'Credit Card'
  }, {
    name: 'invoice',
    value: 'Transaction ID'
  }, {
    name: 'customerIpAddress',
    value: 'Customer IP Address'
  }, {
    name: 'cardToken',
    value: 'Card Token'
  }]);
  _exports.IP_TRANSACTIONS_SEARCH_FILTERS = IP_TRANSACTIONS_SEARCH_FILTERS;
});