define("lh4/components/ui/client-pagination-full-prev-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    collection: [],
    pageIndex: 0,
    pageSize: 10,
    collectionPaged: Ember.computed('collection.[]', 'pageIndex', 'pageSize', function getter() {
      if (!this.get('collection') || !this.get('collection').length) {
        return [];
      }

      return this.get('collection').slice(this.get('pageIndex'), this.get('pageIndex') + this.get('pageSize'));
    }),
    hasPrevPage: Ember.computed('pageIndex', function getter() {
      return this.get('pageIndex') > 0;
    }),
    hasNextPage: Ember.computed('collection.[]', 'pageIndex', function getter() {
      return this.get('pageIndex') + this.get('pageSize') < this.get('collection').length;
    }),
    actions: {
      selectPreviousPage: function selectPreviousPage() {
        var newIndex = this.get('pageIndex') - this.get('pageSize');
        this.set('pageIndex', newIndex >= 0 ? newIndex : 0);
      },
      selectNextPage: function selectNextPage() {
        var newIndex = this.get('pageIndex') + this.get('pageSize');
        var totalLength = this.get('collection').length;
        this.set('pageIndex', newIndex < totalLength ? newIndex : totalLength - this.get('pageSize'));
      }
    }
  });

  _exports.default = _default;
});