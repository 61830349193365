define("lh4/serializers/loyalty/discount", ["exports", "lh4/serializers/loyalty/application", "lh4/utils/currency/converter"], function (_exports, _application, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    normalize: function normalize() {
      var normalized = this._super.apply(this, arguments);

      if (normalized.data.attributes.type === 'amount') {
        normalized.data.attributes.value = (0, _converter.toDollars)(normalized.data.attributes.value).toString();
      }

      return normalized;
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.type === 'amount') {
        json.value = +(0, _converter.toCents)(json.value).toString();
      }

      delete json.loyaltyProgramName;
      return json;
    }
  });

  _exports.default = _default;
});