define("lh4/templates/components/social-media/post-preview/post-footer-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4n9fLxM/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row flex-row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"item post-date\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"content middle aligned\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui small header\"],[8],[1,[24,[\"post\",\"publishTimeDisplayTime\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[24,[\"post\",\"publishTimeDisplayDate\"]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"post\",\"status\"]],\"ERROR\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"ui-popup\",null,[[\"tagName\",\"content\"],[\"div\",\"Attempting to make a post resulted in error. Please try again.\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"popup-activator\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui big warning sign yellow icon\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/social-media/post-preview/post-footer-preview.hbs"
    }
  });

  _exports.default = _default;
});