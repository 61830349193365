define("lh4/adapters/echo-pro/setting", ["exports", "lh4/adapters/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'api/v2',
    buildURL: function buildURL() {
      return "".concat(this.host, "/api/v2/echo-pro/settings");
    }
  });

  _exports.default = _default;
});