define("lh4/utils/simple-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = simpleSort;

  function simpleSort(isAscending, a, b) {
    if (isAscending && a < b || !isAscending && a > b) {
      return -1;
    }

    return 1;
  }
});