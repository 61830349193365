define("lh4/models/echo-pro/time-clock-shift-v2", ["exports", "ember-cp-validations", "ember-data", "ember-data/relationships", "lh4/enums/date-time-format", "moment"], function (_exports, _emberCpValidations, _emberData, _relationships, _dateTimeFormat, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    employeeGuid: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Employee'
    }),
    jobGuid: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Job'
    }),
    clockedInAt: {
      description: 'Clock In',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('date', {
        onOrBefore: 'now',
        errorFormat: _dateTimeFormat.default.LOCALIZED_SHORT,
        message: function message(type) {
          if (type === 'onOrBefore') {
            return '{description} must be on or before now';
          }

          return undefined;
        },
        disabled: Ember.computed.equal('model.voided', true)
      })]
    },
    clockedOutAt: {
      description: 'Clock Out',
      validators: [(0, _emberCpValidations.validator)('date', {
        after: Ember.computed.alias('model.clockedInAt'),
        onOrBefore: 'now',
        errorFormat: _dateTimeFormat.default.LOCALIZED_SHORT,
        message: function message(type) {
          if (type === 'after') {
            return '{description} must be after Clock In';
          }

          if (type === 'onOrBefore') {
            return '{description} must be on or before now';
          }

          return undefined;
        },
        disabled: Ember.computed.equal('model.voided', true)
      })]
    },
    tips: {
      description: 'Cash Tips',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        gte: 0,
        lte: 9999999.99,
        positive: true
      })]
    },
    segmentChangesets: {
      description: 'Segments',
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        var isValid = true;
        return model.get('segmentChangesets').reduce(function (prom, changeset) {
          return prom.then(function () {
            return changeset.validate().then(function () {
              if (changeset.isInvalid) {
                isValid = false;
              }
            });
          });
        }, Ember.RSVP.resolve()).then(function () {
          return model.get('validateBreakOverlap')(model.get('segmentChangesets'));
        }).then(function (isBreaksValid) {
          return isValid && isBreaksValid;
        });
      }, {
        disabled: Ember.computed.not('model.segmentChangesets')
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    locationId: _emberData.default.attr('number'),
    guid: _emberData.default.attr('string'),
    voided: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    tips: _emberData.default.attr('string', {
      defaultValue: '0.00'
    }),
    notes: _emberData.default.attr('string'),
    registerId: _emberData.default.attr('number'),
    payRate: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    clockedInAt: _emberData.default.attr('string'),
    clockedOutAt: _emberData.default.attr('string'),
    breakTotalSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    overtimeCalculation: _emberData.default.attr('string'),
    overtimeThreshold: _emberData.default.attr('number'),
    payPeriodStartDay: _emberData.default.attr('string'),
    payPeriodStartedAt: _emberData.default.attr('string'),
    normalHourlyPayRate: _emberData.default.attr('number'),
    overtimeHourlyPayRate: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    grossReceipts: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    doubleOvertimeTotalSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    standardOvertimeTotalSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    standardWorkTimeTotalSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    clockedInSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    clockedOutSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    createdAt: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('string'),
    job: (0, _relationships.belongsTo)('echo-pro/job'),
    jobGuid: _emberData.default.attr('string'),
    employee: (0, _relationships.belongsTo)('echo-pro/employee'),
    employeeGuid: _emberData.default.attr('string'),
    payPeriod: (0, _relationships.belongsTo)('echo-pro/pay-period'),
    payPeriodGuid: _emberData.default.attr('string'),
    timeClockBreaks: (0, _relationships.hasMany)('echo-pro/time-clock-break'),
    timeClockSegments: (0, _relationships.hasMany)('echo-pro/time-clock-segment'),
    addSegment: function addSegment(options) {
      var data = {
        createdAt: new Date(),
        updatedAt: new Date()
      };
      return this.store.createRecord('echo-pro/time-clock-segment', Object.assign(data, options));
    },
    addItem: function addItem(options) {
      var data = {
        locationId: this.locationId,
        createdAt: new Date(),
        updatedAt: new Date()
      };
      return this.store.createRecord('echo-pro/time-clock-shift-v2', Object.assign(data, options));
    },
    validateBreakOverlap: function validateBreakOverlap(segmentChangesets) {
      var breaks = segmentChangesets.filter(function (record) {
        return record.get('isBreak');
      }).sort(function (a, b) {
        return (0, _moment.default)(a.get('start')).diff(b.get('start'));
      });
      var isValid = true;

      for (var i = 0; i < breaks.length - 1; i += 1) {
        var current = breaks[i];
        var next = breaks[i + 1];
        var now = (0, _moment.default)().toISOString();
        var currentStart = current.get('start');
        var currentEnd = current.get('end') || now;
        var nextStart = next.get('start');
        var nextEnd = next.get('end') || now;

        if ((0, _moment.default)(nextStart).isBetween(currentStart, currentEnd, undefined, '[)')) {
          next.addError('start', 'From overlaps with another break');
        }

        if ((0, _moment.default)(nextEnd).isBetween(currentStart, currentEnd, undefined, '[)')) {
          next.addError('end', 'To overlaps with another break');
        }

        if (next.isInvalid) {
          isValid = false;
        }
      }

      return isValid;
    }
  });

  _exports.default = _default;
});