define("lh4/controllers/authenticated/epro/menu", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    routeData: Ember.computed('location.model', function getter() {
      var routeData = [{
        name: 'authenticated.epro.menu.items',
        title: 'Items'
      }, {
        name: 'authenticated.epro.menu.departments',
        title: 'Departments'
      }, {
        name: 'authenticated.epro.menu.modifiers',
        title: this.intl.t('controllers:authenticated:epro:menu:route-data:modifiers')
      }, {
        name: 'authenticated.epro.menu.modifier-sets',
        title: 'Modifier Sets'
      }, {
        name: 'authenticated.epro.menu.order-types',
        title: 'Order Types'
      }];

      if (this.can.can('view dynamic pricing in echo-pro/dynamic-pricing', this.location.model)) {
        routeData.push({
          name: 'authenticated.epro.menu.dynamic-pricing',
          title: 'Dynamic Pricing'
        });
      } else {
        routeData.push({
          name: 'authenticated.epro.menu.price-schedule-groups',
          title: 'Scheduled Pricing'
        });
      }

      if (this.can.can('view menu coursing in echo-pro/courses', this.location.model)) {
        routeData.push({
          name: 'authenticated.epro.menu.courses',
          title: 'Coursing'
        });
      }

      if (this.location.model.isCorpHq) {
        routeData.push({
          name: 'authenticated.epro.menu.price-groups',
          title: 'Price Groups'
        });
        routeData.push({
          name: 'authenticated.epro.menu.pricing-table',
          title: 'Pricing Table'
        });
      }

      return routeData;
    })
  });

  _exports.default = _default;
});