define("lh4/controllers/authenticated/disputes/edit", ["exports", "lh4/controllers/general-form-controller", "lh4/config/environment"], function (_exports, _generalFormController, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    shouldCloseTray: false,
    exportFormats: [{
      name: 'PDF',
      format: 'pdf'
    }],
    exportDefaultFileName: 'dispute',
    exportUrl: "".concat(_environment.default.host, "/api/v1/disputes/"),
    actions: {
      exportError: function exportError() {
        this.set('serverError', 'Unable to export case.  Please try again.');
      },
      exportSuccess: function exportSuccess() {
        this.clearServerError();
      },
      submitEvidenceResponse: function submitEvidenceResponse(message) {
        var _this = this;

        var locationId = this.location.model.id;
        var requestToken = this.model.dispute.get('requestToken');
        this.store.createRecord('note', {
          caseId: this.model.dispute.caseId,
          locationId: locationId,
          requestToken: this.model.dispute.get('requestToken'),
          message: message
        }, {
          adapterOptions: {
            locationId: locationId,
            requestToken: requestToken
          }
        }).save().then(function () {
          _this.send('refreshPage');
        }).catch(function () {
          _this.set('serverError', 'Unable to submit evidence.  Please try again.');
        });
      },
      onSubmitError: function onSubmitError() {
        this.set('serverError', 'Error encountered submitting answers');
      },
      onSubmitSuccess: function onSubmitSuccess() {
        var _this2 = this;

        this.flashMessagesHelper.pushMessage('Questionnaire Submitted', 'success');
        setTimeout(function () {
          _this2.set('shouldCloseTray', true);
        }, 1000);
      }
    }
  });

  _exports.default = _default;
});