define("lh4/controllers/authenticated/deposit-breakdown", ["exports", "lh4/enums/date-range-title", "lh4/utils/date-moment", "moment"], function (_exports, _dateRangeTitle, _dateMoment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var MAX_DAYS = 31;
  var RangeOptions = [{
    title: _dateRangeTitle.DATE_RANGE_TITLE.TODAY,
    startDate: (0, _moment.default)(),
    endDate: (0, _moment.default)()
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.YESTERDAY,
    startDate: (0, _moment.default)().subtract(1, 'days'),
    endDate: (0, _moment.default)().subtract(1, 'days')
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.TO_DATE,
    startDate: (0, _moment.default)().startOf('week'),
    endDate: (0, _moment.default)()
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST,
    startDate: (0, _moment.default)().subtract(1, 'weeks').startOf('week'),
    endDate: (0, _moment.default)().subtract(1, 'weeks').endOf('week')
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.MONTH.TO_DATE,
    startDate: (0, _moment.default)().startOf('month'),
    endDate: (0, _moment.default)()
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.MONTH.LAST,
    startDate: (0, _moment.default)().subtract(1, 'months').startOf('month'),
    endDate: (0, _moment.default)().subtract(1, 'months').endOf('month')
  }];

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    reportsApi: Ember.inject.service('reports/report-api'),
    router: Ember.inject.service(),
    from: null,
    maxDate: (0, _moment.default)(),
    maxDays: MAX_DAYS,
    minuteSteps: 5,
    ranges: RangeOptions,
    rangeTitle: null,
    to: null,
    verticalRangeWidth: 160,
    isLoading: false,
    getExportUrl: function getExportUrl(extension) {
      if (!this.model.location) {
        return false;
      }

      var reportOptions = {
        locations: ["".concat(this.model.location.id)],
        product: this.model.location.product,
        startsAt: (0, _moment.default)(this.from).toDate(),
        endsAt: (0, _moment.default)(this.to).toDate()
      };
      return this.reportsApi.getReportExportUrl('processing', 'batch-reconciliation', reportOptions, extension);
    },
    exportCSVUrl: Ember.computed('from', 'to', function exportCSVUrl() {
      return this.getExportUrl('csv');
    }),
    exportXLSUrl: Ember.computed('from', 'to', function exportXLSUrl() {
      return this.getExportUrl('xls');
    }),
    actions: {
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        // Reset minDate and maxDate so a new selection can be made
        instance.setMinDate(null);
        instance.setMaxDate((0, _moment.default)());
        this.setProperties({
          from: startDate,
          to: endDate
        });
      },
      onDesktopBlur: function onDesktopBlur(event) {
        var _event$target$value$s = event.target.value.split(' - '),
            _event$target$value$s2 = _slicedToArray(_event$target$value$s, 2),
            from = _event$target$value$s2[0],
            to = _event$target$value$s2[1];

        var fromMoment = (0, _moment.default)(from);
        var toMoment = (0, _moment.default)(to);

        if (fromMoment.isValid() && toMoment.isValid()) {
          this.setProperties({
            from: fromMoment,
            to: toMoment
          });
        }
      },
      onFirstSelect: function onFirstSelect(instance, firstDate) {
        // Since we allow the user to select either the beginning or the end
        // of the date at the beginning of their selection we'll limit both
        // min and max date so they can get no more than 31 days in their selection
        var maxDate = _moment.default.min((0, _moment.default)(), firstDate.clone().add(MAX_DAYS, 'days'));

        var minDate = firstDate.clone().subtract(MAX_DAYS, 'days');
        instance.setMinDate(minDate);
        instance.setMaxDate(maxDate);
      },
      onMobileChange: function onMobileChange(rangeTitle, startDate, endDate) {
        this.set('rangeTitle', rangeTitle);
        return (0, _dateRangeTitle.isCustom)(rangeTitle) ? this.setProperties({
          from: startDate,
          to: endDate
        }) : this.setProperties({
          from: (0, _dateMoment.getStartOfBusinessDay)(startDate, this.preferences.eod),
          to: (0, _dateMoment.getEndOfBusinessDay)(endDate, this.preferences.eod)
        });
      },
      onRangeSelect: function onRangeSelect(instance, range) {
        var startDate = range.startDate,
            endDate = range.endDate,
            title = range.title;
        var adjustedStart = (0, _dateMoment.getStartOfBusinessDay)(startDate, this.preferences.eod);
        var adjustedEnd = (0, _dateMoment.getEndOfBusinessDay)(endDate, this.preferences.eod);
        instance.setStart(adjustedStart);
        instance.setEnd(adjustedEnd);
        instance.updateTimePickerDisplay();
        this.setProperties({
          from: startDate,
          to: endDate,
          rangeTitle: title
        });
      },
      onTimeChange: function onTimeChange(instance, startDate, endDate) {
        this.setProperties({
          from: startDate,
          to: endDate
        });
      },
      generateReport: function generateReport() {
        this.router.transitionTo('authenticated.deposit-breakdown.report', {
          queryParams: {
            from: this.from.toISOString(),
            to: this.to.toISOString()
          }
        });
      }
    }
  });

  _exports.default = _default;
});