define("lh4/serializers/virtual-terminal/transaction", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      var replace = payloadKey.replace("".concat(payloadKey), "virtual-terminal/".concat(payloadKey));
      return this._super(replace);
    }
  });

  _exports.default = _default;
});