define("lh4/models/device-management/lighthouse/terminal", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend((0, _emberCpValidations.buildValidations)(), {
    locationId: _emberData.default.attr('number'),
    number: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    product: _emberData.default.attr('string'),
    serialNumber: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    lastCalledAt: _emberData.default.attr('date'),
    revenueCenterGuid: _emberData.default.attr('string'),
    revenueCenterName: _emberData.default.attr('string')
  });

  _exports.default = _default;
});