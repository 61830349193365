define("lh4/templates/components/internet-payments/spinners/button-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JLKx0mfa",
    "block": "{\"symbols\":[\"@class\",\"@isIconLoader\"],\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\",true],[10,\"class\",\"icon-loader\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"button\",true],[11,\"class\",[29,[\"ui button button-loader \",[23,1,[]]]]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"spinner\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/spinners/button-spinner.hbs"
    }
  });

  _exports.default = _default;
});