define("lh4/routes/authenticated/gift-cards", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    location: Ember.inject.service(),
    titleToken: 'Shift4 Cares Gift Card',
    model: function model() {
      return Ember.RSVP.hash({
        cards: this.store.query('s4-gift-cards/card', {
          locationId: this.location.model.id
        })
      });
    },
    actions: {
      reloadRoute: function reloadRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});