define("lh4/controllers/authenticated/epro/manage-employees/employees/create", ["exports", "lh4/controllers/general-form-controller", "lh4/utils/employees"], function (_exports, _generalFormController, _employees) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (_ref) {
        var id = _ref.id;
        return !changeset.findBy('id', id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    actions: {
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      create: function create() {
        var _this = this;

        if (this.isSaving) {
          return;
        }

        this.set('showErrors', true);
        this.set('isSaving', true);
        this.clearServerError(); // we have to check for pristine before we validate and save

        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.employee.employeePhones, this.changeset.phoneChangesets) && this.areChildrenPristine(this.model.employee.employeeDates, this.changeset.dateChangesets) && this.areChildrenPristine(this.model.employee.payRates, this.changeset.payRateChangesets);
        (0, _employees.validateEmployeeChangeset)(this.model.employee, this.changeset).then(function (_ref2) {
          var areChildrenValid = _ref2.areChildrenValid;

          if (!areChildrenValid) {
            _this.set('areChildrenInvalid', true);
          }

          if (!(_this.changeset.isValid && areChildrenValid)) {
            return Ember.RSVP.reject(_this.changeset.get('errors'));
          }

          if (!_this.changeset.payRateChangesets.length) {
            return Ember.RSVP.reject([{
              message: 'Employee must be assigned a job'
            }]);
          }

          _this.removeRecordsFromModel(_this.model.employee.employeePhones, _this.changeset.phoneChangesets);

          _this.removeRecordsFromModel(_this.model.employee.employeeDates, _this.changeset.dateChangesets);

          _this.removeRecordsFromModel(_this.model.employee.payRates, _this.changeset.payRateChangesets);

          if (isPristine) {
            _this.clearTemporaryData();

            _this.closeModal();

            return Ember.RSVP.resolve();
          }

          return _this.changeset.save().then(function () {
            _this.clearTemporaryData();

            _this.closeModal();
          }).catch(function (_ref3) {
            var errors = _ref3.errors;

            if (!(errors && errors[0])) {
              return;
            }

            throw errors;
          });
        }).catch(function (errors) {
          var createError = "Something went wrong, ".concat(_this.instanceName, " could not be updated.");
          errors.forEach(function (error) {
            var errorMessage = error.details && error.details[0] || error.message || createError;
            return _this.set('serverError', errorMessage);
          });
        }).finally(function () {
          return _this.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});