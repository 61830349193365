define("lh4/components/reputation/google-select-business", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    errorHandler: Ember.inject.service(),
    accountApi: Ember.inject.service('reputation/account-api'),

    /* @param */
    reputationAccountId: null,

    /* @param */
    googleAccounts: [],
    selectedAccount: null,
    googleLocations: [],
    isLoading: false,
    loadAccountLocations: function loadAccountLocations() {
      var _this = this;

      if (!this.get('selectedAccount')) {
        return;
      }

      if (!this.get('reputationAccountId')) {
        throw new Error('reputationAccountId cannot be empty');
      }

      this.set('isLoading', true);
      this.set('googleLocations', []);
      this.accountApi.getGoogleAccountLocations(this.get('reputationAccountId'), this.get('selectedAccount').id).then(function (locations) {
        _this.set('googleLocations', locations);
      }).catch(function (err) {
        _this.errorHandler.handleResponseError(err, 'Could not load Google Business Locations');
      }).finally(function () {
        return _this.set('isLoading', false);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('googleAccounts').length > 0) {
        this.set('selectedAccount', this.get('googleAccounts')[0]);
        this.loadAccountLocations();
      }
    },
    actions: {
      selectAccount: function selectAccount() {
        this.loadAccountLocations();
      },
      selectLocation: function selectLocation(location) {
        this.sendAction('onSelectGoogleLocation', this.get('selectedAccount'), location);
      }
    }
  });

  _exports.default = _default;
});