define("lh4/mixins/list-status-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    statusOptions: Ember.computed(function getter() {
      return [{
        name: this.intl.t('mixins:list-status-options:status-options:all'),
        value: 'all'
      }, {
        name: this.intl.t('mixins:list-status-options:status-options:active'),
        value: 'true'
      }, {
        name: this.intl.t('mixins:list-status-options:status-options:not-active'),
        value: 'false'
      }];
    }),
    selectedStatus: Ember.computed('isActive', function getter() {
      if (this.isActive === 'true' || this.isActive === 'false') {
        return this.isActive;
      }

      return 'all';
    }),
    actions: {
      handleStatusChange: function handleStatusChange(status) {
        var isActive = null;

        if (status === 'true' || status === 'false') {
          isActive = status;
        }

        this.set('isActive', isActive);
      }
    }
  });

  _exports.default = _default;
});