define("lh4/enums/shipping-methods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SHIPPING_METHODS = Object.freeze({
    GROUND: 'ground',
    NEXT_DAY: 'nextDay',
    SECOND_DAY: 'secondDay'
  });
  var _default = SHIPPING_METHODS;
  _exports.default = _default;
});