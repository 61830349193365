define("lh4/models/gpatt/ratings-threshold-recipient", ["exports", "ember-data", "ember-data/relationships", "ember-cp-validations"], function (_exports, _emberData, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ONE_OF_THE_FIELDS_BLANK_MESSAGE = 'One of the fields (phone or email) can\'t be blank';
  var Validation = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.or('model.phone', 'model.email'),
      message: ONE_OF_THE_FIELDS_BLANK_MESSAGE
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'email',
      message: 'Email address is invalid.'
    })],
    name: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      message: 'Name should not be empty'
    }),
    phone: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.or('model.phone', 'model.email'),
      message: ONE_OF_THE_FIELDS_BLANK_MESSAGE
    }), (0, _emberCpValidations.validator)('phone-number', {
      allowBlank: true,
      message: 'Phone number is invalid'
    })]
  });

  var _default = _emberData.default.Model.extend(Validation, {
    email: _emberData.default.attr('string'),
    isEmailActive: _emberData.default.attr('boolean'),
    isPhoneActive: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('phone'),
    settings: (0, _relationships.belongsTo)('gpatt/settings')
  });

  _exports.default = _default;
});