define("lh4/controllers/authenticated/epro/timeclock/settings", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    messageTimeoutDelay: 5000,
    successMessage: null,
    isSaving: false,
    actions: {
      update: function update() {
        var _this = this;

        if (this.isSaving) {
          return null;
        }

        this.set('successMessage', null);
        this.set('isSaving', true);
        return this.changeset.validate().then(function () {
          if (_this.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this.changeset.get('errors'));
          }

          _this.changeset.set('minimumWage', Number.parseFloat(_this.changeset.get('minimumWage')).toFixed(2));

          _this.changeset.execute();

          _this.businessChangeset.execute();

          return _this.model.settings.save().then(function () {
            _this.changeset.rollback();

            _this.businessChangeset.rollback();

            _this.set('successMessage', 'Settings saved!');

            _this.set('successMessageTimeoutId', Ember.run.later(_this, function () {
              _this.set('successMessage', null);
            }, _this.messageTimeoutDelay));
          }).catch(function (err) {
            var errors = [err];
            throw errors;
          });
        }).catch(function (errors) {
          if (errors && errors[0]) {
            var updateError = "Something went wrong, ".concat(_this.instanceName, " could not be updated.");
            errors.forEach(function (error) {
              var errorMessage = _this.getErrorMessage(error, updateError);

              _this.set('errorMessage', errorMessage);

              _this.set('errorMessageTimeoutId', Ember.run.later(_this, function () {
                _this.set('errorMessage', null);
              }, _this.messageTimeoutDelay));
            });
          }
        }).finally(function () {
          return _this.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});