define("lh4/components/social-media/post-frequency", ["exports", "moment", "lh4/utils/cron"], function (_exports, _moment, _cron) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    repeatsEnd: false,
    media: Ember.inject.service(),
    location: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('post.scheduleRepeatEnd')) {
        this.set('repeatsEnd', true);
      } // Reset all days


      this.get('days').forEach(function (day) {
        Ember.set(day, 'isActive', false);
      });

      if (this.get('post.scheduleRepeat')) {
        var cronFreq = (0, _cron.calculateFrequency)(this.get('post.scheduleRepeat'));

        if (cronFreq) {
          this.set('selectedFrequency', cronFreq.frequency);
          this.days.forEach(function (day) {
            Ember.set(day, 'isActive', cronFreq.days.includes(day.id));
          });
        }
      } else {
        this.set('post.scheduleRepeat', this.getCronExpression());
      }
    },
    classNames: ['sm-post-frequency'],
    includeBiWeekly: false,
    minDate: Ember.computed(function () {
      return (0, _moment.default)().toDate();
    }),
    frequencyLength: Ember.computed('includeBiWeekly', function getter() {
      var defaultFrequencies = ['Daily', 'Weekly', 'Monthly', 'Yearly'];

      if (this.get('includeBiWeekly')) {
        defaultFrequencies.insertAt(2, _cron.CronFrequencies.BI_WEEKLY);
      }

      return defaultFrequencies;
    }),
    selectedFrequency: 'Daily',
    days: [Ember.Object.create({
      id: 7,
      name: 'Sunday',
      isActive: false
    }), Ember.Object.create({
      id: 1,
      name: 'Monday',
      isActive: false
    }), Ember.Object.create({
      id: 2,
      name: 'Tuesday',
      isActive: false
    }), Ember.Object.create({
      id: 3,
      name: 'Wednesday',
      isActive: false
    }), Ember.Object.create({
      id: 4,
      name: 'Thursday',
      isActive: false
    }), Ember.Object.create({
      id: 5,
      name: 'Friday',
      isActive: false
    }), Ember.Object.create({
      id: 6,
      name: 'Saturday',
      isActive: false
    })],
    cronObserver: Ember.observer('selectedFrequency', 'days.@each.isActive', 'post.scheduleTime', function callback() {
      this.set('post.scheduleRepeat', this.getCronExpression());
      this.onValidationResults(!this.hasPeriodErrors());
    }),
    summary: Ember.computed('post.scheduleTime', 'post.scheduleRepeat', 'post.scheduleRepeatStart', 'post.scheduleRepeatEnd', 'selectedFrequency', 'days.@each.isActive', 'repeatsEnd', function getter() {
      return (0, _cron.getFormattedFrequencyString)(this.get('selectedFrequency'), this.get('post.scheduleRepeatEnd'), this.get('days').filter(function (d) {
        return d.isActive;
      }).map(function (d) {
        return d.id;
      }));
    }),
    hasPeriodErrors: function hasPeriodErrors() {
      var frequency = this.get('selectedFrequency');
      var days = this.get('days').filterBy('isActive').mapBy('id');
      return (frequency === _cron.CronFrequencies.WEEKLY || frequency === _cron.CronFrequencies.BI_WEEKLY) && days.length === 0;
    },
    getCronExpression: function getCronExpression() {
      var frequency = this.get('selectedFrequency');
      var days = this.get('days').filterBy('isActive').mapBy('id');
      var currentTime;

      if (this.get('post.id')) {
        currentTime = this.get('post.scheduleTime') || this.get('post.scheduleRepeatStart');
      } else {
        currentTime = this.get('post.scheduleRepeatStart') || this.get('post.scheduleTime');
      }

      return (0, _cron.createCronExpression)((0, _moment.default)(currentTime).tz(this.location.getLocationTimezone()), frequency, days);
    },
    actions: {
      neverEnds: function neverEnds(value) {
        var repeatEnd = value ? (0, _moment.default)().add(7, 'days').format() : null;
        this.setProperties({
          repeatsEnd: value,
          'post.scheduleRepeatEnd': repeatEnd
        });
      },
      onAfterSelect: function onAfterSelect(instance, date) {
        this.set('post.scheduleRepeatEnd', date);
      },
      onTimeChange: function onTimeChange(instance, date) {
        this.set('post.scheduleRepeatEnd', date);
      },
      onMobileChange: function onMobileChange(date) {
        this.set('post.scheduleRepeatEnd', date);
      },
      setDayCron: function setDayCron(day, checked) {
        day.set('isActive', checked);
      }
    }
  });

  _exports.default = _default;
});