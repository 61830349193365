define("lh4/models/report", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    echo: (0, _attr.default)(),
    'echo-pro': (0, _attr.default)(),
    gpatt: (0, _attr.default)(),
    hospitality: (0, _attr.default)(),
    lighthouse: (0, _attr.default)(),
    loyalty: (0, _attr.default)(),
    mozart: (0, _attr.default)(),
    processing: (0, _attr.default)(),
    retail: (0, _attr.default)(),
    solo: (0, _attr.default)()
  });

  _exports.default = _default;
});