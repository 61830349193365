define("lh4/components/ui/records-side-menu/top-header-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'basic', 'attached', 'segment', 'records-side-menu-header']
  });

  _exports.default = _default;
});