define("lh4/models/dashboard/processing/batch-detail", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    batches: (0, _relationships.hasMany)('processing/batch'),
    transactions: (0, _relationships.hasMany)('processing/transaction'),
    deposits: (0, _relationships.hasMany)('processing/deposit')
  });

  _exports.default = _default;
});