define("lh4/services/social-media/facebook", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SCOPES = _exports.ANALYTICS_FIELDS = _exports.COMMENT_MAX_LENGTH = _exports.POST_MAX_LENGTH = _exports.FB_PAGE_STATUS_MISSING_ROLES_OR_PERMISSIONS = _exports.FB_PAGE_STATUS_PAGE_NOT_FOUND = _exports.FB_PAGE_STATUS_INVALIDATED_TOKEN = _exports.FB_PAGE_STATUS_PAGE_MISSING_PERMISSIONS = _exports.FB_PAGE_STATUS_USER_MISSING_PERMISSIONS = _exports.FB_PAGE_STATUS_MISSING_ROLES = _exports.FB_PAGE_STATUS_IS_LINKED = _exports.FB_PAGE_STATUS_CAN_LINK = _exports.ACCOUNT_TYPE_FB_PROFILE = _exports.ACCOUNT_TYPE_FB_GROUP = _exports.ACCOUNT_TYPE_FB_PAGE = _exports.FB_AUTH_URL = _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var FB_AUTH_BASE = 'https://www.facebook.com';
  var FB_AUTH_PATH = '/v7.0/dialog/oauth';
  var FB_AUTH_URL = "".concat(FB_AUTH_BASE).concat(FB_AUTH_PATH);
  _exports.FB_AUTH_URL = FB_AUTH_URL;
  var LOCAL_STORAGE_SOCIAL_MEDIA_TYPE = 'social-media-type';
  var ACCOUNT_TYPE_FB_PAGE = 'facebook-page';
  _exports.ACCOUNT_TYPE_FB_PAGE = ACCOUNT_TYPE_FB_PAGE;
  var ACCOUNT_TYPE_FB_GROUP = 'facebook-group';
  _exports.ACCOUNT_TYPE_FB_GROUP = ACCOUNT_TYPE_FB_GROUP;
  var ACCOUNT_TYPE_FB_PROFILE = 'facebook-profile';
  _exports.ACCOUNT_TYPE_FB_PROFILE = ACCOUNT_TYPE_FB_PROFILE;
  var FB_PAGE_STATUS_CAN_LINK = 'CAN_LINK';
  _exports.FB_PAGE_STATUS_CAN_LINK = FB_PAGE_STATUS_CAN_LINK;
  var FB_PAGE_STATUS_IS_LINKED = 'IS_LINKED';
  _exports.FB_PAGE_STATUS_IS_LINKED = FB_PAGE_STATUS_IS_LINKED;
  var FB_PAGE_STATUS_MISSING_ROLES = 'MISSING_ROLES';
  _exports.FB_PAGE_STATUS_MISSING_ROLES = FB_PAGE_STATUS_MISSING_ROLES;
  var FB_PAGE_STATUS_USER_MISSING_PERMISSIONS = 'USER_MISSING_PERMISSIONS';
  _exports.FB_PAGE_STATUS_USER_MISSING_PERMISSIONS = FB_PAGE_STATUS_USER_MISSING_PERMISSIONS;
  var FB_PAGE_STATUS_PAGE_MISSING_PERMISSIONS = 'PAGE_MISSING_PERMISSIONS';
  _exports.FB_PAGE_STATUS_PAGE_MISSING_PERMISSIONS = FB_PAGE_STATUS_PAGE_MISSING_PERMISSIONS;
  var FB_PAGE_STATUS_INVALIDATED_TOKEN = 'INVALIDATED_TOKEN';
  _exports.FB_PAGE_STATUS_INVALIDATED_TOKEN = FB_PAGE_STATUS_INVALIDATED_TOKEN;
  var FB_PAGE_STATUS_PAGE_NOT_FOUND = 'PAGE_NOT_FOUND';
  _exports.FB_PAGE_STATUS_PAGE_NOT_FOUND = FB_PAGE_STATUS_PAGE_NOT_FOUND;
  var FB_PAGE_STATUS_MISSING_ROLES_OR_PERMISSIONS = 'MISSING_ROLES_OR_PERMISSIONS';
  _exports.FB_PAGE_STATUS_MISSING_ROLES_OR_PERMISSIONS = FB_PAGE_STATUS_MISSING_ROLES_OR_PERMISSIONS;
  var POST_MAX_LENGTH = 63206;
  _exports.POST_MAX_LENGTH = POST_MAX_LENGTH;
  var COMMENT_MAX_LENGTH = 8000;
  _exports.COMMENT_MAX_LENGTH = COMMENT_MAX_LENGTH;
  var ANALYTICS_FIELDS = ['totalViews', 'rate', 'engagement', 'likes', 'shares', 'comments'];
  _exports.ANALYTICS_FIELDS = ANALYTICS_FIELDS;
  var SCOPES = new Map([[ACCOUNT_TYPE_FB_PAGE, ['pages_manage_ads', 'pages_manage_metadata', 'pages_read_engagement', 'pages_read_user_content', 'pages_manage_posts', 'pages_manage_engagement', 'pages_show_list', 'read_insights']], [ACCOUNT_TYPE_FB_PROFILE, []], [ACCOUNT_TYPE_FB_GROUP, []]]);
  _exports.SCOPES = SCOPES;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    accountApi: Ember.inject.service('social-media/account-api'),

    /**
     * Get Facebook request permission scope
     * @param {('facebook-page'|'facebook-group'|'facebook-profile')} accountType - facebook account type
     * @returns {string} facebook permission scope
     */
    getFacebookScopes: function getFacebookScopes(accountType) {
      if (!SCOPES.has(accountType)) {
        return '';
      }

      var scopes = SCOPES.get(accountType);

      if (!Array.isArray(scopes)) {
        return '';
      }

      return scopes.filter(function (s) {
        return typeof s === 'string' && s.trim().length;
      }).join(',');
    },

    /**
    * Constructs a valid URL used for redirects
    * @param {Object=} options={} - the url path
    * @param {string=} options.path - the url path
    * @param {Object=} options.query - the query params
    * @param {string=} options.base - base url segment
    * @returns {string} url for redirect (i.e window.location = url or window.location.replace(url))
    */
    buildRedirectUrl: function buildRedirectUrl() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var _options$path = options.path,
          path = _options$path === void 0 ? FB_AUTH_PATH : _options$path,
          _options$query = options.query,
          query = _options$query === void 0 ? {} : _options$query,
          _options$base = options.base,
          base = _options$base === void 0 ? FB_AUTH_BASE : _options$base;
      var qs = new URLSearchParams(query).toString();
      return new URL("".concat(path).concat(Ember.isEmpty(qs) ? '' : "?".concat(qs)), base).href;
    },

    /**
     * Redirect to Facebook login page which will redirect back
     * @param {('facebook-page'|'facebook-group'|'facebook-profile')} accountType - facebook account type
     * @param {string} redirectUri - facebook's or drm redirect uri
     */
    redirectToFbAuth: function redirectToFbAuth(accountType) {
      var redirectUri = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _environment.default.socialMedia.facebook.redirectUrl;
      window.localStorage.setItem(LOCAL_STORAGE_SOCIAL_MEDIA_TYPE, accountType);
      window.location = this.buildRedirectUrl({
        query: {
          client_id: _environment.default.socialMedia.facebook.appId,
          redirect_uri: redirectUri,
          scope: this.getFacebookScopes(accountType),
          response_type: 'code'
        }
      });
    },

    /**
     * Redirect to facebook authorization for account or page for rerequest or reauthentication
     * @param {Object} account - social media account
     * @param {Object} [page] - facebook page. Only for social media accounts without associated page
     * @param {boolean} isReputation - is consumer reputation or social-media
     */
    redirectToAccountRelink: function redirectToAccountRelink(account, page) {
      var _this = this;

      var isReputation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var redirectUri = isReputation ? _environment.default.reputation.facebook.redirectUrl : _environment.default.socialMedia.facebook.redirectUrl;
      var consumer = isReputation ? 'reputation' : 'social-media';
      window.localStorage.setItem(LOCAL_STORAGE_SOCIAL_MEDIA_TYPE, Ember.get(account, 'type'));
      this.accountApi.setRedirectOrigin(Ember.get(account, 'id'));
      var queryCommon = {
        client_id: _environment.default.socialMedia.facebook.appId,
        redirect_uri: redirectUri,
        state: Ember.get(account, 'id')
      };
      var missingPermissions = page ? page.missingPermissions.toString() : Ember.get(account, 'externalAccount.missingPermissions').toString();
      var linkStatus = page ? page.linkStatus : Ember.get(account, 'linkStatus');

      var queryParams = _objectSpread(_objectSpread({}, queryCommon), {}, {
        scope: missingPermissions
      });

      switch (linkStatus) {
        case FB_PAGE_STATUS_USER_MISSING_PERMISSIONS:
        case FB_PAGE_STATUS_PAGE_MISSING_PERMISSIONS:
        case FB_PAGE_STATUS_MISSING_ROLES_OR_PERMISSIONS:
          queryParams.auth_type = 'rerequest';
          break;

        default:
          queryParams.auth_type = 'reauthenticate';
          break;
      }

      if (linkStatus === FB_PAGE_STATUS_PAGE_MISSING_PERMISSIONS || linkStatus === FB_PAGE_STATUS_MISSING_ROLES_OR_PERMISSIONS) {
        this.ajax.request("/api/v1/".concat(consumer, "/accounts/").concat(Ember.get(account, 'id'), "/permissions"), {
          type: 'DELETE'
        }).then(function () {
          return window.location.replace(_this.buildRedirectUrl({
            query: queryParams
          }));
        });
      } else {
        window.location.replace(this.buildRedirectUrl({
          query: queryParams
        }));
      }
    },

    /**
     * Used before account is linked to page
     * user that granted partial permissions might give account wide perm, but leave out specific page
     * in that case account would appear without missing permissions
     * @param {Object} account - social media account
     * @param {boolean} isReputation - is consumer reputation or social-media
     */
    redirectToAccountRererequest: function redirectToAccountRererequest(account) {
      var _this2 = this;

      var isReputation = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var consumer = isReputation ? 'reputation' : 'social-media';
      var redirectUri = isReputation ? _environment.default.reputation.facebook.redirectUrl : _environment.default.socialMedia.facebook.redirectUrl;
      window.localStorage.setItem(LOCAL_STORAGE_SOCIAL_MEDIA_TYPE, Ember.get(account, 'type'));
      var queryParams = {
        client_id: _environment.default.socialMedia.facebook.appId,
        redirect_uri: redirectUri,
        state: Ember.get(account, 'id'),
        auth_type: 'rerequest',
        scope: this.getFacebookScopes(Ember.get(account, 'type'))
      };
      this.ajax.request("/api/v1/".concat(consumer, "/accounts/").concat(Ember.get(account, 'id'), "/permissions"), {
        type: 'DELETE'
      }).then(function () {
        return window.location.replace(_this2.buildRedirectUrl({
          query: queryParams
        }));
      });
    },

    /**
     * Link social media account with facebook account (not page)
     * @param {{name: string, locations: Array<object>}} account - account object
     * @param {string} code - facebook authentication code
     * @param {number} [drmAccountId] - pass if reusing existing DRM FB account
     * @returns {Promise<{response, accountType}>} result of HTTP request
     */
    linkFbAccountSocialMedia: function linkFbAccountSocialMedia(account, code, drmAccountId) {
      var accountType = window.localStorage.getItem(LOCAL_STORAGE_SOCIAL_MEDIA_TYPE);

      if (!accountType) {
        return Ember.RSVP.Promise.reject('Could not determine account type, please try adding account again');
      }

      return this.ajax.request("/api/v1/social-media/accounts/link/".concat(accountType), {
        headers: {
          'Content-Type': 'application/json'
        },
        type: 'POST',
        data: JSON.stringify({
          code: code,
          name: account.name,
          drmAccountId: drmAccountId,
          locations: account.locations.map(function (location) {
            return location.id;
          }),
          branding: _environment.default.branding.name
        })
      }).then(function (response) {
        return {
          response: response,
          accountType: accountType
        };
      });
    },
    checkFbRedirectErrors: function checkFbRedirectErrors() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var isSocialMediaRedirectDenied = queryParams.error;

      if (!isSocialMediaRedirectDenied) {
        return {};
      }

      var errorMessage = queryParams.error_reason === 'user_denied' ? 'Access Denied by User' : queryParams.error_description;
      var accountId = queryParams.state;
      var redirectOrigin = this.accountApi.getRedirectOrigin(accountId);

      if (redirectOrigin) {
        return {
          errorMessage: errorMessage,
          redirectOrigin: redirectOrigin
        };
      }

      return {
        errorMessage: errorMessage
      };
    },

    /**
     * Transform redirect URI in hash history environment
     *
     * actual redirect uri
     * https://lh.harbortouch.com/?code=a1cd_#_&state=162#/social-media/accounts/link
     * @param {object} location - window location
     * @returns {string} transformed redirect uri
     */
    adjustRedirectUrlHash: function adjustRedirectUrlHash(location) {
      if (_environment.default.locationType === 'hash') {
        return "".concat(location.origin).concat(location.hash).concat(location.search);
      }

      return null;
    },

    /**
     * Link reputation account with facebook account (not page)
     * @param {{ name: string, locationId: string }} account - account object
     * @param {string} code - facebook authentication code
     * @param {number} [socialMediaAccountId] - social media account id
     * @returns {Promise<{response, accountType}>} result of HTTP request
     */
    linkFbAccountReputation: function linkFbAccountReputation(account, code, socialMediaAccountId) {
      var accountType = window.localStorage.getItem(LOCAL_STORAGE_SOCIAL_MEDIA_TYPE);

      if (!accountType) {
        return Ember.RSVP.Promise.reject('Could not determine account type, please try adding account again');
      }

      return this.ajax.request("/api/v1/reputation/accounts/link/".concat(accountType), {
        headers: {
          'Content-Type': 'application/json'
        },
        type: 'POST',
        data: JSON.stringify({
          code: socialMediaAccountId ? undefined : code,
          socialMediaAccountId: socialMediaAccountId,
          name: account.name,
          locationId: account.locationId,
          branding: _environment.default.branding.name
        })
      }).then(function (response) {
        return {
          response: response,
          accountType: accountType
        };
      });
    },

    /**
     * Exchanges code to new facebook tokens
     * Used when users are redirected back to lighthouse from facebook after relinking/reauthorizing
     * @param {number} accountId - social media account id
     * @param {string} newCode - facebook authorization exchange code
     * @param {('social-media'|'reputation')} linkType - facebook integration consumer
     * @returns {Promise} - social media account
     */
    relinkAccount: function relinkAccount(accountId, newCode) {
      var linkType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'social-media';
      return this.ajax.request("/api/v1/".concat(linkType, "/accounts/").concat(accountId, "/relink"), {
        headers: {
          'Content-Type': 'application/json'
        },
        type: 'POST',
        data: JSON.stringify({
          code: newCode
        })
      });
    }
  });

  _exports.default = _default;
});