define("lh4/controllers/authenticated/locations/location/hospitality/customize-menu", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROUTE = 'authenticated.locations.location.hospitality.customize-menu.category.edit-category.item.create-item';

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    selectedItem: 'Please Select an Item',
    showInactiveItems: true,
    disableCreateItem: false,
    menuCategories: Ember.computed.filter('model.menuCategories', function filterCallback(category) {
      return category.get('locationId') === parseInt(this.get('model.location.id'), 10);
    }),
    selectedRevenueClass: 'Please select a class',
    selectedItemGroup: 'Please select an item group',
    selectedCategory: null,
    menuItems: Ember.computed('selectedCategory', function getter() {
      return this.store.peekAll('hospitality/menu-item');
    }),
    actions: {
      transitionModal: function transitionModal() {
        (0, _jquery.default)('#discard-category-changes').modal('show');
      },
      discardChanges: function discardChanges() {
        this.get('model.menuCategories').forEach(function (category) {
          category.rollbackAttributes();
        });
        this.set('disableCreateItem', false);
        this.get('previousTransition').retry();
      },
      createCategory: function createCategory() {
        this.transitionToRoute('authenticated.locations.location.hospitality.customize-menu.category.create-category');
      },
      createItem: function createItem() {
        if (!this.get('disableCreateItem')) {
          if (!this.get('selectedCategory')) {
            this.transitionToRoute(ROUTE, this.get('model.menuCategories').get('firstObject').id);
          } else {
            this.transitionToRoute(ROUTE, this.get('selectedCategory.id') || 'null');
          }
        }
      },
      selectRevenueClass: function selectRevenueClass(component, id, value) {
        this.set('selectedRevenueClass', value);
      },
      selectItemGroup: function selectItemGroup(component, id, value) {
        this.set('selectItemGroup', value);
      }
    }
  });

  _exports.default = _default;
});