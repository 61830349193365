define("lh4/routes/authenticated/locations/location/echo-pro/manage-pos/timeclock/create-shift", ["exports", "lh4/mixins/authenticated-route-mixin", "moment"], function (_exports, _authenticatedRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    router: Ember.inject.service(),
    calculatePayPeriod: function calculatePayPeriod(startOfWeek, endOfDayTime) {
      var payPeriodDate;

      if ((0, _moment.default)().day(startOfWeek).weekday() > (0, _moment.default)().weekday()) {
        payPeriodDate = (0, _moment.default)().add(-1, 'week').day(startOfWeek);
      } else {
        payPeriodDate = (0, _moment.default)().day(startOfWeek);
      }

      payPeriodDate.startOf('day').add(endOfDayTime, 'ms');
      return payPeriodDate.utc().toString();
    },
    model: function model() {
      var currentRoute = this.router.currentRoute;
      var selectedEmployeeId = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.queryParams.selectedEmployeeId;
      var location = this.modelFor('authenticated.locations.location');
      var selectedEmployee = this.store.peekRecord('echo-pro/employee', selectedEmployeeId);
      var defaultJob = selectedEmployee.get('jobs').sortBy('name').toArray()[0];
      var settings = this.store.peekRecord('echo-pro/setting', this.location.model.id);
      var _settings$business$co = settings.business.content,
          startOfWeek = _settings$business$co.startOfWeek,
          endOfDayTime = _settings$business$co.endOfDayTime;
      var newShift = this.store.createRecord('echo-pro/time-clock-shift', {
        voided: false,
        clockedInAt: (0, _moment.default)().toISOString(),
        locationId: parseInt(location.id, 10),
        employeeGuid: selectedEmployee,
        jobGuid: defaultJob,
        overtimeCalculation: settings.accounting.content.overtimeCalculation,
        payPeriodStartDay: startOfWeek,
        payPeriodStartedAt: this.calculatePayPeriod(startOfWeek, endOfDayTime),
        overtimeHourlyPayRate: defaultJob.overtimePayRate,
        normalHourlyPayRate: defaultJob.payRate,
        standardWorkTimeTotalSeconds: 0,
        standardOvertimeTotalSeconds: 0,
        doubleOvertimeTotalSeconds: 0,
        createdAt: (0, _moment.default)().toISOString(),
        updatedAt: (0, _moment.default)().toISOString()
      });
      return Ember.RSVP.hash({
        newShift: newShift
      });
    },
    beforeModel: function beforeModel() {},
    afterModel: function afterModel(model) {
      this.modelFor('authenticated.locations.location.echo-pro.manage-pos.timeclock').shifts._pushInternalModels([model.newShift._internalModel]);

      this.controllerFor('authenticated.locations.location.echo-pro.manage-pos.timeclock').set('selectedShift', model.newShift);
    }
  });

  _exports.default = _default;
});