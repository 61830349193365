define("lh4/serializers/preferences", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INTERNET_PAYMENT_WIDGETS = ['summary-data-card', 'summary-bar-chart-card', 'ip-transactions', 'ip-transactions-risk', 'ip-notifications'];

  var _default = _application.default.extend({
    primaryKey: 'id',
    location: Ember.inject.service(),
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'preferences';
    },
    orderIncludesPaymentWidgets: function orderIncludesPaymentWidgets(dashboardOrder) {
      return dashboardOrder ? dashboardOrder.some(function (item) {
        return INTERNET_PAYMENT_WIDGETS.includes(item.slug);
      }) : false;
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize; // TODO:: This check should be removed after LH-17337 finished.

      if (this.location.hasInternetPaymentProduct && json.oldDashboard === true) {
        json.oldDashboard = false;
      }

      if (!this.location.hasInternetPaymentProduct && this.orderIncludesPaymentWidgets(json.dashboardOrder)) {
        var newOrder = json.dashboardOrder.filter(function (item) {
          return !INTERNET_PAYMENT_WIDGETS.includes(item.slug);
        });
        json.dashboardOrder = newOrder.length > 0 ? newOrder : null;
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    }
  });

  _exports.default = _default;
});