define("lh4/controllers/authenticated/epro/accounting", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.routeData = [{
        name: 'authenticated.epro.accounting.taxes',
        title: this.intl.t('controllers:authenticated:epro:accounting:route-data:taxes')
      }, {
        name: 'authenticated.epro.accounting.surcharges',
        title: this.intl.t('controllers:authenticated:epro:accounting:route-data:surcharges')
      }, {
        name: 'authenticated.epro.accounting.revenue-classes',
        title: this.intl.t('controllers:authenticated:epro:accounting:route-data:revenue-classes')
      }, {
        name: 'authenticated.epro.accounting.gratuities',
        title: this.intl.t('controllers:authenticated:epro:accounting:route-data:gratuities')
      }];
    }
  });

  _exports.default = _default;
});