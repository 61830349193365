define("lh4/validators/array-unique-field", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line require-jsdoc
  function generateErrorMessage(options, duplicateValue) {
    return options.getMessage ? options.getMessage(options.description, duplicateValue) : "Can only have one ".concat(options.description || 'field', " of the same type \"").concat(duplicateValue, "\"");
  }

  var ArrayUniqueField = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      if (!options.uniqueField) {
        return 'No options.uniqueField provided';
      }

      var list = model.get(attribute);
      var groupedValues = {};

      var filter = options.uniqueFieldFilter || function () {
        return true;
      };

      list.filter(filter).forEach(function (obj) {
        var key = obj[options.uniqueField];
        groupedValues[key] = groupedValues[key] ? groupedValues[key] + 1 : 1;
      });
      var duplicateValue = Object.keys(groupedValues).find(function (key) {
        return groupedValues[key] > 1;
      });
      return duplicateValue ? generateErrorMessage(options, duplicateValue) : true;
    }
  });

  ArrayUniqueField.reopenClass({
    getDependentsFor: function getDependentsFor() {
      return [];
    }
  });
  var _default = ArrayUniqueField;
  _exports.default = _default;
});