define("lh4/adapters/hospitality/preference", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id, modelName) {
      return "".concat(this.buildURL(), "/").concat(modelName.pluralize(), "?location=").concat(id);
    }
  });

  _exports.default = _default;
});