define("lh4/components/dashboard-widgets/batch-summary/data", ["exports", "moment", "jquery", "lh4/mixins/dashboard-widget-mixin", "lh4/utils/date-moment"], function (_exports, _moment, _jquery, _dashboardWidgetMixin, _dateMoment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['batch-summary-data', 'table-widget'],
    skeletonData: {
      batches: [{
        TransDate: '2018-10-22T00:00:00.000Z',
        TotalAmt: 186215.4
      }, {
        TransDate: '2018-10-21T00:00:00.000Z',
        TotalAmt: 93816.68
      }, {
        TransDate: '2018-10-20T00:00:00.000Z',
        TotalAmt: 77353.53
      }, {
        TransDate: '2018-10-19T00:00:00.000Z',
        TotalAmt: 251038.36
      }, {
        TransDate: '2018-10-18T00:00:00.000Z',
        TotalAmt: 209603.34
      }]
    },
    media: Ember.inject.service(),
    store: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    batches: Ember.computed('expanded', 'data', function getter() {
      var batches = !this.expanded ? this.data.batches.slice(0, 5) : this.data.batches;
      return batches.sort(function (x, y) {
        return _moment.default.utc(y.TransDate).diff(_moment.default.utc(x.TransDate));
      });
    }),
    reportDates: Ember.computed(function getter() {
      var _this$store$peekRecor = this.store.peekRecord('preferences', this.tokenData.data.sub),
          eod = _this$store$peekRecor.eod,
          weekStart = _this$store$peekRecor.weekStart;

      var _getWeekToDateRange = (0, _dateMoment.getWeekToDateRange)(eod, weekStart),
          startDate = _getWeekToDateRange.startDate,
          endDate = _getWeekToDateRange.endDate;

      return {
        startsAt: startDate.format(),
        startsAtTime: startDate.format('HH:mmA'),
        endsAt: endDate.format(),
        endsAtTime: endDate.format('HH:mmA')
      };
    }),
    actions: {
      openModal: function openModal(id) {
        (0, _jquery.default)("#".concat(id)).modal('show');
      }
    }
  });

  _exports.default = _default;
});