define("lh4/services/modals-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    list: {},
    // Adds modal element to an array. If it is the first element pushed it will be displayed immediately
    pushToList: function pushToList(name, data) {
      this.list[name] = data;

      if (Object.keys(this.list).length === 1) {
        this.list[name].modal('show');
      }
    },
    // Hides modal element and removes it from the array.
    // If there are still modal elements left it will show the first one in the list (FIFO).
    deleteFromList: function deleteFromList(name) {
      if (this.list[name]) {
        this.list[name].modal('hide');
        delete this.list[name];

        if (Object.keys(this.list).length) {
          this.list[Object.keys(this.list)[0]].modal('show');
        }
      }
    }
  });

  _exports.default = _default;
});