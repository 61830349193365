define("lh4/mirage/routes/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageLocationsServer;

  function setMirageLocationsServer(server) {
    server.get('/api/v2/locations');
    server.get('/api/v2/locations/:locationId', function (_ref, request) {
      var locations = _ref.locations;
      var id = request.params.locationId;
      return locations.find(id);
    });
  }
});