define("lh4/mirage/routes/internet-payments/users/configurable-columns", ["exports", "lh4/mirage/fixtures/internet-payments/users/configurable-columns"], function (_exports, _configurableColumns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageTransactionsServer;

  function setMirageTransactionsServer(server) {
    server.delete('/internet-payments/users/configurable-columns/:id', {});
    server.get('/internet-payments/users/configurable-columns', function (schema, request) {
      var resourceViewPerspective = request.queryParams.resourceViewPerspective;

      if (resourceViewPerspective === 'TRANSACTION-MASTER-EW') {
        return {
          columnConfigurations: _configurableColumns.CONFIGURABLE_COLUMNS_EW_FIXTURE
        };
      }

      return {
        columnConfigurations: _configurableColumns.CONFIGURABLE_COLUMNS_OP_FIXTURE
      };
    });
    server.put('/internet-payments/users/configurable-columns', function (schema, request) {
      var requestBody = JSON.parse(request.requestBody);
      return {
        columnConfiguration: {
          createdAt: '2023-08-23T09:25:22.099Z',
          updatedAt: '2023-08-23T09:25:22.099Z',
          guid: 'c74b3a9b-7cc8-49fb-8934-5ee27f2cc71c',
          viewName: requestBody.viewName,
          resourceViewPerspective: requestBody.resourceViewPerspective,
          lbmColumnOptions: requestBody.columnOptions,
          product: 'internet-payments',
          locationId: requestBody.locationId,
          isFavorite: true
        }
      };
    });
    server.put('/internet-payments/users/configurable-columns/favorite/:uuid', function (schema, request) {
      var requestBody = JSON.parse(request.requestBody);
      return {
        columnConfiguration: {
          createdAt: '2023-08-23T09:25:22.099Z',
          updatedAt: '2023-08-23T09:25:22.099Z',
          guid: 'c74b3a9b-7cc8-49fb-8934-5ee27f2cc71c',
          viewName: 'Template',
          resourceViewPerspective: 'TRANSACTION-MASTER-DEFAULT',
          lbmColumnOptions: [{
            optionId: 'DATE',
            visible: true
          }, {
            optionId: 'ORDER_STATUS',
            visible: true
          }, {
            optionId: 'TRANSACTION_ID',
            visible: true
          }, {
            optionId: 'CREDIT_CARD',
            visible: true
          }, {
            optionId: 'CUSTOMER_NAME',
            visible: true
          }, {
            optionId: 'CUSTOMER_EMAIL',
            visible: true
          }, {
            optionId: 'CUSTOMER_IP',
            visible: false
          }, {
            optionId: 'AMOUNT',
            visible: true
          }, {
            optionId: 'TRANSACTION_TYPE',
            visible: true
          }, {
            optionId: 'RISK_STATUS',
            visible: true
          }, {
            optionId: 'RISK_ID',
            visible: false
          }, {
            optionId: 'ACQUIRER_RESPONSE',
            visible: true
          }, {
            optionId: 'CARD_TOKEN',
            visible: false
          }, {
            optionId: 'MERCHANT_REFERENCE',
            visible: true
          }, {
            optionId: 'RISK_SCORE',
            visible: true
          }, {
            optionId: 'SECURE_STATUS',
            visible: false
          }],
          product: 'internet-payments',
          locationId: 1698,
          userId: 5,
          isFavorite: requestBody.isFavorite
        }
      };
    });
  }
});