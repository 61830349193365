define("lh4/templates/components/ui/modal/fullscreen-display-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pf8xUNT5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"content\"],[[28,\"component\",[\"ui/shared/yield-basic\"],[[\"defaultClassNames\"],[\"content\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/modal/fullscreen-display-modal.hbs"
    }
  });

  _exports.default = _default;
});