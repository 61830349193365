define("lh4/abilities/fiscal-reporting", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions", "lh4/enums/launch-darkly-feature-flags", "lh4/enums/country-code"], function (_exports, _emberCan, _abilityMixin, _permissions, _launchDarklyFeatureFlags, _countryCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service(),
    store: Ember.inject.service(),
    canManage: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', 'location.model', function canManage() {
      var featureFlag = this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.FISCAL_REPORTS_VISIBILITY];

      if (!this.hasPermission(_permissions.default.GENERAL.REPORTS) || !featureFlag) {
        return false;
      }

      var currentLocation = this.location.model;

      if (!this.permissions.hasProduct('echo-pro', currentLocation)) {
        return false;
      } // fiscal reporting is only available outside US


      if (this.location.model.countryCode === _countryCode.default.US) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});