define("lh4/templates/authenticated/internet-payments/customers/details/create-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7KX8yYTX",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"modals\"]],{\"statements\":[[4,\"ui/modal/fullscreen-modal\",null,[[\"title\",\"onClose\",\"errorMessage\"],[\"Create Transaction\",[28,\"action\",[[23,0,[]],\"onClose\"],null],[24,[\"errorMessage\"]]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `modal.content` to be a contextual component but found a string. Did you mean `(component modal.content)`? ('lh4/templates/authenticated/internet-payments/customers/details/create-transaction.hbs' @ L8:C5) \"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"fullscreen-modal-iframe\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"hasLoaded\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[22,\"internet-payments/loader-circle\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[7,\"iframe\",true],[10,\"title\",\"Internet Payments Transaction Form\"],[11,\"src\",[24,[\"model\",\"url\"]]],[11,\"onload\",[28,\"action\",[[23,0,[]],\"handleIframeLoad\"],null]],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/customers/details/create-transaction.hbs"
    }
  });

  _exports.default = _default;
});