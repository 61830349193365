define("lh4/models/echo-pro/printer", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    guid: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    terminalHardwareId: _emberData.default.attr('string'),
    model: _emberData.default.attr('string'),
    usedFor: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    remote: _emberData.default.attr('boolean'),
    cutterEnabled: _emberData.default.attr('boolean'),
    textSize: _emberData.default.attr('string'),
    lineSpacing: _emberData.default.attr('string'),
    customTextBeforeServerName: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    shouldGuestCheckShowCustomerName: _emberData.default.attr('boolean'),
    shouldGuestCheckShowCustomTableName: _emberData.default.attr('boolean'),
    shouldGuestCheckShowModifierPrices: _emberData.default.attr('boolean'),
    shouldGuestCheckPrintBarcode: _emberData.default.attr('boolean'),
    shouldGuestCheckPrintItems: _emberData.default.attr('boolean'),
    shouldGuestCheckShowSpecialRequests: _emberData.default.attr('boolean'),
    shouldGuestCheckShowTipHints: _emberData.default.attr('boolean'),
    shouldNeverPrintReceipt: _emberData.default.attr('boolean'),
    shouldTransactionShowCustomerName: _emberData.default.attr('boolean'),
    shouldTransactionShowCustomTableName: _emberData.default.attr('boolean'),
    shouldTransactionShowModifierPrices: _emberData.default.attr('boolean'),
    shouldTransactionPrintBarcode: _emberData.default.attr('boolean'),
    shouldTransactionPrintItems: _emberData.default.attr('boolean'),
    shouldTransactionShowSpecialRequests: _emberData.default.attr('boolean'),
    shouldTransactionShowTipHints: _emberData.default.attr('boolean'),
    shouldAutoPrintWhenZeroTicketClosed: _emberData.default.attr('boolean'),
    shouldCreditSlipShowTipHints: _emberData.default.attr('boolean'),
    tipHint1: _emberData.default.attr('string'),
    tipHint2: _emberData.default.attr('string'),
    tipHint3: _emberData.default.attr('string'),
    customHeaderTextLine1: _emberData.default.attr('string'),
    customHeaderTextLine2: _emberData.default.attr('string'),
    customFooterTextLine1: _emberData.default.attr('string'),
    customFooterTextLine2: _emberData.default.attr('string'),
    groupBy: _emberData.default.attr('string'),
    separateWith: _emberData.default.attr('string'),
    shouldSortByDepartmentsWhenPrinting: _emberData.default.attr('boolean'),
    shouldShowSectionName: _emberData.default.attr('boolean'),
    shouldShowNumberOfGuests: _emberData.default.attr('boolean'),
    shouldShowHeader: _emberData.default.attr('boolean'),
    shouldShowFooter: _emberData.default.attr('boolean'),
    shouldPrintOtherPrinterNames: _emberData.default.attr('boolean'),
    shouldBackOfHousePrintBarcode: _emberData.default.attr('boolean'),
    shouldPrintVoidSlips: _emberData.default.attr('boolean'),
    shouldRollUpItemQuantities: _emberData.default.attr('boolean'),
    shouldRollUpModifiers: _emberData.default.attr('boolean'),
    shouldPrintLineItemsSeparately: _emberData.default.attr('boolean'),
    shouldCutPaperAfterEveryItem: _emberData.default.attr('boolean'),
    shouldShowCustomerDetails: _emberData.default.attr('boolean'),
    hasSecondDrawer: _emberData.default.attr('boolean'),
    isDefault: _emberData.default.attr('boolean'),
    employee: (0, _relationships.hasMany)('echo-pro/employee')
  });

  _exports.default = _default;
});