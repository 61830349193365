define("lh4/controllers/authenticated/customer-engagement/campaigns/overview/activity/customer", ["exports", "lh4/mixins/controllers/locations/location/echo-pro/manage-pos/customers/edit-controller-mixin"], function (_exports, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {
    actions: {
      clear: function clear() {
        this.get('model.customer').rollback();
        this.transitionToRoute('authenticated.customer-engagement.campaigns.overview.activity');
      },
      afterSaveHook: function afterSaveHook() {
        this.send('delayedTransition', 'authenticated.customer-engagement.campaigns.overview.activity');
      }
    }
  });

  _exports.default = _default;
});