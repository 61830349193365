define("lh4/controllers/authenticated/reputation/accounts/edit", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_PARAMS = {
    showFields: true
  };

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    accountApi: Ember.inject.service('reputation/account-api'),
    isLoading: false,
    showFields: DEFAULT_PARAMS.showFields,
    isFacebookPage: Ember.computed('model.account.type', function getter() {
      return this.accountApi.isFbPage(this.get('model.account.type'));
    }),
    isGoogleBusiness: Ember.computed('model.account.type', function getter() {
      return this.accountApi.isGoogleBusiness(this.get('model.account.type'));
    }),
    initialAccountName: Ember.computed('model.account', function getter() {
      // To display initial name, without changes!
      return this.get('model.account.name');
    }),
    initialExternalId: Ember.computed('model.account', function getter() {
      return this.get('model.account.externalId');
    }),
    queryParams: ['showFields'],
    reset: function reset() {
      this.setProperties(DEFAULT_PARAMS);
    },
    actions: {
      saveAccount: function saveAccount() {
        var _this = this;

        this.set('isLoading', true);
        var accountId = this.get('model.account.id');
        this.accountApi.updateAccount(accountId, this.get('model.account')).then(function () {
          _this.flashMessagesHelper.pushMessage('Account successfully updated', 'success');

          _this.transitionToRoute('authenticated.reputation.accounts');
        }).catch(function (errorMessages) {
          _this.flashMessagesHelper.pushMessages(_this.responseMessages.getResponseErrors(errorMessages));
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      },
      selectGoogleLocation: function selectGoogleLocation(account, location) {
        var reputationAccount = this.get('model.account');
        Ember.set(reputationAccount, 'externalId', location.id);
        Ember.set(reputationAccount, 'externalName', location.locationName);
        Ember.set(reputationAccount.externalAccount, 'externalUserId', account.id);
      },
      discardChanges: function discardChanges() {
        this.set('isLoading', true);
        this.transitionToRoute('authenticated.reputation.accounts');
      },
      showDeleteDialog: function showDeleteDialog() {
        if (this.get('isLoading')) {
          return;
        }

        (0, _jquery.default)('#delete-reputation-account-dialog').modal('show');
      },
      deleteAccount: function deleteAccount(account) {
        var _this2 = this;

        if (this.get('isLoading')) {
          return;
        }

        this.set('isLoading', true);
        this.accountApi.deleteAccount(account.id).then(function () {
          _this2.flashMessagesHelper.pushMessage('Successfully deleted account', 'success');

          _this2.transitionToRoute('authenticated.reputation.accounts');
        }).catch(function (e) {
          _this2.errorHandler.handleResponseError(e, 'Could not delete account');
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      },
      backToAccounts: function backToAccounts() {
        this.set('isLoading', true);
        this.transitionToRoute('authenticated.reputation.accounts');
      },
      selectEdit: function selectEdit(selected) {
        this.set('showFields', selected);
      }
    }
  });

  _exports.default = _default;
});