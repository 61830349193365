define("lh4/controllers/authenticated/support", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    can: Ember.inject.service(),
    features: Ember.inject.service(),
    location: Ember.inject.service(),
    router: Ember.inject.service(),
    currentRoute: Ember.computed('router.currentRouteName', function currentRoute() {
      return this.router.currentRouteName;
    }),
    routeData: Ember.computed(function getter() {
      var routeData = [];

      if (this.can.can('view docs in support')) {
        routeData.push({
          name: 'authenticated.support.support-links',
          title: 'Support Links'
        });
      }

      if (this.can.can('manage tickets in support')) {
        routeData.push({
          name: 'authenticated.support.contact',
          title: 'Contact Support'
        });
      }

      if (this.can.can('view info in support')) {
        routeData.push({
          name: 'authenticated.support.support-contacts',
          title: 'Support Contacts'
        });
      }

      return routeData;
    })
  });

  _exports.default = _default;
});