define("lh4/serializers/echo-pro/modifier", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var _default = _application.default.extend({
    attrs: {
      modifierSets: {
        embedded: 'always',
        serialize: 'id'
      },
      nestedModifierSets: {
        embedded: 'always',
        serialize: 'id'
      },
      modifierSetMap: {
        serialize: 'false',
        deserialize: 'records'
      },
      printers: {
        embedded: 'always',
        serialize: 'id'
      },
      stposUiStylesheetObjectLinks: {
        embedded: 'always'
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.kitchenName = json.kitchenName && json.kitchenName.length ? json.kitchenName : null;
      json.parentModifierSets = json.modifierSets;
      delete json.modifiers;
      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payloadToNormalize) {
      var payload = payloadToNormalize;

      if (payload.modifier) {
        payload.modifier.modifierSets = payload.modifier.parentModifierSets;
        delete payload.modifier.parentModifierSets;
      }

      if (payload.modifiers) {
        payload.modifiers = payload.modifiers.map(function (_ref) {
          var parentModifierSets = _ref.parentModifierSets,
              rest = _objectWithoutProperties(_ref, ["parentModifierSets"]);

          return _objectSpread(_objectSpread({}, rest), {}, {
            modifierSets: parentModifierSets
          });
        });
      }

      for (var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        args[_key - 3] = arguments[_key];
      }

      return this._super.apply(this, [store, primaryModelClass, payload].concat(args));
    }
  });

  _exports.default = _default;
});