define("lh4/components/ui/dates/datetime-picker", ["exports", "lh4/enums/date-time-format", "lh4/enums/key-code", "lh4/mixins/components/ui/dates/date-picker-mixin", "lh4/utils/no-op", "moment"], function (_exports, _dateTimeFormat, _keyCode, _datePickerMixin, _noOp, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_datePickerMixin.default, {
    classNames: ['datetime-picker', 'ui', 'input', 'icon'],
    oneCalendarWidth: 300,
    date: null,
    disabled: false,
    arrowOn: 'left',
    autoAlign: true,
    maxDate: null,
    minDate: null,
    onafterselect: _noOp.default,
    ontimechange: _noOp.default,
    onBlur: _noOp.default,
    onFocus: _noOp.default,
    picker: null,
    placeholder: null,
    defaultStartTime: null,
    defaultEndTime: null,
    showCalendars: true,
    autoCloseOnSelect: true,
    reverseTimepickerArrows: true,
    format: _dateTimeFormat.default.LOCALIZED_SHORT,
    showTimePickers: true,
    isBusinessDay: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupPicker();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var date = this.date,
          picker = this.picker,
          format = this.format,
          isBusinessDay = this.isBusinessDay,
          reportWithoutEndTime = this.reportWithoutEndTime;

      if (picker) {
        if (!date) {
          picker.clearInput();
          return;
        }

        var dateFormat = format || 'MM/DD/YYYY h:mm A';
        var formattedDate;

        if (reportWithoutEndTime) {
          formattedDate = _moment.default.utc(date).format(dateFormat);
        } else {
          formattedDate = isBusinessDay ? (0, _moment.default)(date).format(dateFormat) : _moment.default.utc(date).format(dateFormat);
        }

        if (formattedDate !== picker.elem.value) {
          picker.elem.value = formattedDate;
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.picker) {
        this.picker.destroy();
      }
    },
    getOptions: function getOptions() {
      var options = this.getProperties('arrowOn', 'autoAlign', 'maxDate', 'minDate', 'onafterselect', 'ontimechange', 'onbeforeshow', 'onbeforehide', 'showCalendars', 'autoCloseOnSelect', 'reverseTimepickerArrows', 'showTimePickers', 'format', 'oneCalendarWidth');
      return _objectSpread(_objectSpread({}, options), {}, {
        calendarCount: 1,
        enableKeyboard: false,
        showFooter: false,
        showHeader: false,
        singleDate: true,
        startEmpty: !this.date
      });
    },
    setupPicker: function setupPicker() {
      this.$('.datetime-picker-input').calentim(this.getOptions());
      var instance = this.$('.datetime-picker-input').data('calentim');
      var date = this.date,
          defaultEndTime = this.defaultEndTime,
          defaultStartTime = this.defaultStartTime,
          isBusinessDay = this.isBusinessDay;

      if (defaultStartTime) {
        instance.setStartTimeValue(parseInt(defaultStartTime.format('hh'), 10), parseInt(defaultStartTime.format('mm'), 10), defaultStartTime.format('a'));
      }

      if (defaultEndTime) {
        instance.setEndTimeValue(parseInt(defaultEndTime.format('hh'), 10), parseInt(defaultEndTime.format('mm'), 10), defaultEndTime.format('a'));
      }

      var singleDayPickerDate = null;

      if (date) {
        singleDayPickerDate = isBusinessDay ? (0, _moment.default)(date).format(this.format) : _moment.default.utc(date).format(this.format);
      }

      instance.setStart(singleDayPickerDate);
      instance.setEnd(singleDayPickerDate);
      this.set('picker', instance);
    },
    actions: {
      onFocus: function onFocus() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var event = args[0];
        this.picker.showDropdown(event);

        if (this.onFocus) {
          this.onFocus.apply(this, args);
        }
      },
      onKeyDown: function onKeyDown(event) {
        if (event.keyCode === _keyCode.default.TAB || event.keyCode === _keyCode.default.ESC) {
          this.picker.hideDropdown(event);
        }
      }
    }
  });

  _exports.default = _default;
});