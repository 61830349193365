define("lh4/serializers/virtual-terminal/refund-settings/initiate", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload) {
      // The response payload for the 'initiate' endpoint contains data for the 'sign' model.
      store.push({
        data: {
          id: payload.id,
          type: 'virtual-terminal/refund-settings/sign',
          attributes: {
            signingKey: payload.signingKey,
            documentPath: payload.url
          },
          relationships: {}
        }
      });
      return this.normalize(primaryModelClass, payload);
    }
  });

  _exports.default = _default;
});