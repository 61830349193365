define("lh4/validators/shift-dates", ["exports", "ember-cp-validations/validators/base", "moment"], function (_exports, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ShiftDates = _base.default.extend({
    validate: function validate(value, options, model) {
      var clockedInAt = (0, _moment.default)(model.get('clockedInAt'));
      var clockedOutAt = value;

      if (!clockedOutAt || clockedInAt.isBefore(clockedOutAt)) {
        return true;
      }

      return "Please select a date after ".concat(clockedInAt.format('MM/DD/YYYY hh:mmA'), " for Clock out field");
    }
  });

  ShiftDates.reopenClass({
    getDependentsFor: function getDependentsFor() {
      return ['model.clockedInAt'];
    }
  });
  var _default = ShiftDates;
  _exports.default = _default;
});