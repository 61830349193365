define("lh4/templates/components/ui/icons/internet-payments/kebab-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6ZfxA7pr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"40\"],[10,\"height\",\"41\"],[10,\"viewBox\",\"0 0 40 41\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"rect\",true],[10,\"y\",\"0.5\"],[10,\"width\",\"40\"],[10,\"height\",\"40\"],[10,\"rx\",\"20\"],[10,\"fill\",\"currentColor\"],[10,\"fill-opacity\",\"0.08\"],[8],[9],[0,\"\\t\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M20 16.5C21.1 16.5 22 15.6 22 14.5C22 13.4 21.1 12.5 20 12.5C18.9 12.5 18 13.4 18 14.5C18 15.6 18.9 16.5 20 16.5ZM20 18.5C18.9 18.5 18 19.4 18 20.5C18 21.6 18.9 22.5 20 22.5C21.1 22.5 22 21.6 22 20.5C22 19.4 21.1 18.5 20 18.5ZM20 24.5C18.9 24.5 18 25.4 18 26.5C18 27.6 18.9 28.5 20 28.5C21.1 28.5 22 27.6 22 26.5C22 25.4 21.1 24.5 20 24.5Z\"],[10,\"fill\",\"currentColor\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/kebab-menu.hbs"
    }
  });

  _exports.default = _default;
});