define("lh4/controllers/authenticated/epro/accounting/revenue-classes/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    corporation: Ember.inject.service(),
    // Overriding this because instanceName is "taxSet" (as per model class)
    // but we refer to it as "revenueClass" everywhere else
    isModelAndChangesetPristine: function isModelAndChangesetPristine() {
      return !this.model.revenueClass.isDirty && this.changeset.get('isPristine');
    }
  });

  _exports.default = _default;
});