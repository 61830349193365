define("lh4/helpers/increment-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.increment = increment;
  _exports.default = void 0;

  function increment(params) {
    return Number(params[0]) + 1;
  }

  var _default = Ember.Helper.helper(increment);

  _exports.default = _default;
});