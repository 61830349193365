define("lh4/routes/authenticated/epro/manage-employees/jobs/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'job',
    queryParams: {
      clone: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var clone = _ref.clone;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        job: this.createNewJob(clone),
        orderTypes: this.store.query('echo-pro/order-type', {
          location: location
        }),
        printers: this.store.query('echo-pro/printer', {
          location: location
        })
      });
    },
    createNewJob: function createNewJob(cloneId) {
      return cloneId ? this.store.findRecord('echo-pro/job', cloneId).then(function (job) {
        return job.cloneShallow({
          employee: [],
          guid: null,
          name: '',
          code: ''
        });
      }) : this.store.createRecord('echo-pro/job', {
        defaultLandingView: 0,
        locationId: this.location.model.id,
        loginType: 0
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // do not use sticky query params
        // https://guides.emberjs.com/v3.15.0/routing/query-params/#toc_sticky-query-param-values
        controller.set('clone', undefined);
      }
    }
  });

  _exports.default = _default;
});