define("lh4/controllers/authenticated/epro/timeclock/shifts", ["exports", "lh4/enums/date-range-title", "lh4/enums/date-time-format", "lh4/utils/date-moment", "moment"], function (_exports, _dateRangeTitle, _dateTimeFormat, _dateMoment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var DATE_RANGE_PRESETS = [_dateMoment.Period.TODAY, _dateMoment.Period.YESTERDAY, _dateMoment.Period.WEEK_TO_DATE, _dateMoment.Period.LAST_WEEK, _dateMoment.Period.MONTH_TO_DATE, _dateMoment.Period.LAST_MONTH, _dateMoment.Period.YEAR_TO_DATE, _dateMoment.Period.LAST_YEAR];
  var DEBOUNCE_THRESHOLD_MS = 500;

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    queryParams: ['location', 'all', 'selectedIds', 'from', 'to', 'offset', 'limit', 'order', 'sort'],
    location: null,
    all: true,
    selectedIds: [],
    from: null,
    to: null,
    offset: 0,
    limit: 10,
    order: 'clockedInAt',
    sort: 'asc',
    headers: [{
      name: 'Employee',
      field: 'employee.name',
      width: 'four'
    }, {
      name: 'Clock In',
      field: 'clockedInAt',
      width: 'three',
      sortable: true,
      type: 'date'
    }, {
      name: 'Clock Out',
      field: 'clockedOutAt',
      width: 'three',
      sortable: true,
      type: 'date'
    }, {
      name: 'Job',
      field: 'jobGuid.name',
      width: 'two'
    }, {
      name: 'Breaks',
      field: 'clockedOutSeconds',
      width: 'two'
    }, {
      name: 'On Clock',
      field: 'clockedInSeconds',
      width: 'two'
    }],
    selectionAllItemName: 'All Employees',
    rangeTitle: null,
    ranges: Ember.computed(function ranges() {
      return (0, _dateMoment.generateDateRanges)(DATE_RANGE_PRESETS, this.model.preferences);
    }),
    mobileFrom: null,
    mobileTo: null,
    displayFrom: Ember.computed('from', function getter() {
      return (0, _moment.default)(this.from).format(_dateTimeFormat.default.LOCALIZED_SHORT);
    }),
    displayTo: Ember.computed('to', function getter() {
      return (0, _moment.default)(this.to).format(_dateTimeFormat.default.LOCALIZED_SHORT);
    }),
    maxDate: Ember.computed(function () {
      return (0, _moment.default)();
    }),
    employeeOptions: Ember.computed('model.employees', 'all', function getter() {
      var _this = this;

      return this.model.employees.map(function (employee) {
        return {
          id: employee.guid,
          name: employee.name,
          isChecked: _this.all || _this.selectedIds.includes(employee.guid),
          isActive: employee.isActive
        };
      });
    }),
    shifts: [],
    shiftsCount: Ember.computed('shifts', function getter() {
      return this.shifts.meta ? this.shifts.meta.count : this.shifts.length;
    }),
    populateShifts: function populateShifts() {
      this.send('populateShifts', this);
    },
    setDates: function setDates(startDate, endDate) {
      Ember.run.debounce(this, this._setDates, startDate, endDate, DEBOUNCE_THRESHOLD_MS);
    },
    _setDates: function _setDates(startDate, endDate) {
      this.setProperties({
        from: startDate ? startDate.format() : null,
        to: endDate ? endDate.format() : null,
        offset: 0
      });
      this.populateShifts();
    },
    actions: {
      onRangeDesktopBlur: function onRangeDesktopBlur(event) {
        var _event$target$value$s = event.target.value.split(' - '),
            _event$target$value$s2 = _slicedToArray(_event$target$value$s, 2),
            from = _event$target$value$s2[0],
            to = _event$target$value$s2[1];

        var fromMoment = (0, _moment.default)(from || null, _dateTimeFormat.default.LOCALIZED_SHORT);
        var toMoment = (0, _moment.default)(to || null, _dateTimeFormat.default.LOCALIZED_SHORT);

        if (fromMoment.isValid() && toMoment.isValid()) {
          if (fromMoment > toMoment) {
            var _ref = [toMoment, fromMoment];
            fromMoment = _ref[0];
            toMoment = _ref[1];
          }

          this.setDates(fromMoment, toMoment);
        }
      },
      onSelectionChange: function onSelectionChange(selectedIds) {
        var isAllSelected = selectedIds.length === this.employeeOptions.length;
        this.setProperties({
          all: isAllSelected,
          selectedIds: !isAllSelected ? selectedIds : []
        });
        this.populateShifts();
      },
      filterChanged: function filterChanged(filter) {
        var order = filter.sortBy,
            sortAsc = filter.sortAsc,
            limit = filter.limit,
            offset = filter.offset;
        var newSort = sortAsc ? 'asc' : 'desc';
        this.setProperties({
          sort: newSort,
          order: order,
          limit: limit,
          offset: offset
        });
        this.populateShifts();
      },
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        this.setDates(startDate, endDate);
        this.setProperties({
          rangeTitle: _dateRangeTitle.DATE_RANGE_TITLE.CUSTOM,
          mobileFrom: startDate,
          mobileTo: endDate
        });
      },
      onRangeSelect: function onRangeSelect(instance, range) {
        var startDate = range.startDate,
            endDate = range.endDate,
            title = range.title;
        this.setDates(startDate, endDate);
        this.setProperties({
          rangeTitle: title,
          mobileFrom: startDate,
          mobileTo: endDate
        });
      },
      onMobileRangeChange: function onMobileRangeChange(rangeTitle, startDate, endDate) {
        if (this.rangeTitle !== rangeTitle) {
          this.set('rangeTitle', rangeTitle);
        }

        this.setDates(startDate, endDate);
      },
      onRowClick: function onRowClick(record) {
        this.transitionToRoute('authenticated.epro.timeclock.shifts.edit', record.id);
      },
      create: function create() {
        this.transitionToRoute('authenticated.epro.timeclock.shifts.create');
      }
    }
  });

  _exports.default = _default;
});