define("lh4/components/tables/employee-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ['employee-table'],
    selectedEmployee: null,
    jobSearchValue: Ember.computed('currentSearchTerm', function getter() {
      return this.get('currentSearchTerm') ? this.get('currentSearchTerm') : null;
    }),
    employeeSearchValue: Ember.computed('currentSearchTerm', function getter() {
      return this.get('currentSearchTerm') ? this.get('currentSearchTerm') : null;
    }),
    searchIcon: Ember.computed('jobSearchValue', 'employeeSearchValue', function getter() {
      if (this.get('jobSearchValue') || this.get('employeeSearchValue')) {
        return false;
      }

      return true;
    }),
    isActive: true,
    editEmployeeLink: Ember.computed('type', function getter() {
      if (this.get('type') === 'echo-pro') {
        return 'authenticated.locations.location.echo-pro.manage-pos.manage-employees.edit-employee';
      }

      return 'authenticated.locations.location.hospitality.manage-employees.edit-employee';
    }),
    createEmployeeLink: Ember.computed('type', function getter() {
      if (this.get('type') === 'echo-pro') {
        return 'authenticated.locations.location.echo-pro.manage-pos.manage-employees.create';
      }

      return 'authenticated.locations.location.hospitality.manage-employees.create';
    }),
    rootLink: Ember.computed('type', function getter() {
      if (this.get('type') === 'echo-pro') {
        return 'authenticated.locations.location.echo-pro.manage-pos.manage-employees';
      }

      return 'authenticated.locations.location.hospitality.manage-employees';
    }),
    // Pagination Dropdown. Adjust to any 3 ascending integers
    paginationSelection: [10, 25, 50],
    pages: Ember.computed('employees', 'jobs', 'jobsSelected', 'limit', function getter() {
      var recordsLengths = this.get('jobsSelected') ? this.get('jobs.content.length') : this.get('employees.content.length');
      var pageArray = [];
      var offset = 0;
      var numberOfPages = Math.ceil(recordsLengths / this.get('limit'));

      for (var i = 0; i < numberOfPages; i += 1) {
        var pageObj = {
          index: i,
          offset: offset
        };
        pageArray.push(pageObj);
        offset += this.get('limit');
      }

      return pageArray;
    }),
    currentPage: Ember.computed('pages', 'offset', function getter() {
      var _this = this;

      var page = this.pages.find(function (p) {
        return p.offset === _this.offset;
      });
      return page ? page.index + 1 : 1;
    }),
    // Search functions
    employeeSearch: function employeeSearch() {
      this.sendAction('employeeSearchTerm', this.get('employeeSearchValue'));
    },
    jobSearch: function jobSearch() {
      this.sendAction('jobSearchTerm', this.get('jobSearchValue'));
    },
    actions: {
      // Search Actions
      onJobSearch: function onJobSearch(forceSearch) {
        if (forceSearch) {
          return this.jobSearch();
        }

        var jobSearchValue = this.get('jobSearchValue');

        if (!jobSearchValue || jobSearchValue.length >= 1) {
          Ember.run.debounce(this, this.jobSearch, 1500);
        }

        return false;
      },
      onEmployeeSearch: function onEmployeeSearch(forceSearch) {
        if (forceSearch) {
          return this.employeeSearch();
        }

        var employeeSearchValue = this.get('employeeSearchValue');

        if (!employeeSearchValue || employeeSearchValue.length >= 1) {
          Ember.run.debounce(this, this.employeeSearch, 1500);
        }

        return false;
      },
      clearSearch: function clearSearch() {
        this.sendAction('clearSearch');
      },
      // Pagination
      updateLimit: function updateLimit(selected) {
        this.sendAction('updateLimit', parseInt(selected, 10));
      },
      decrementOffsetComp: function decrementOffsetComp() {
        this.sendAction('decrementOffset');
      },
      incrementOffsetComp: function incrementOffsetComp() {
        this.sendAction('incrementOffset');
      },
      handlePageChange: function handlePageChange(page) {
        var pageObj = this.pages[page - 1];
        this.router.transitionTo(this.rootLink, {
          queryParams: {
            offset: pageObj.offset
          }
        });
      }
    }
  });

  _exports.default = _default;
});