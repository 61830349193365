define("lh4/utils/is-positive-decimal-of-length", ["exports", "lh4/utils/is-positive-decimal"], function (_exports, _isPositiveDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Validate if value is float with 2 decimal positions and doesn't exceed given length
   *
   * @param {string|number} value - value to validate
   * @param {number} length - max length of value
   * @returns {boolean|string} - true if value valid, error message otherwise
   */
  var isPositiveDecimalOfLength = function isPositiveDecimalOfLength(value, length) {
    return (0, _isPositiveDecimal.default)(value) && String(value).length <= length ? true : 'Value must be a nonzero positive number, can only contain maximum two decimal numbers' + " and may only be ".concat(length, " characters long");
  };

  var _default = isPositiveDecimalOfLength;
  _exports.default = _default;
});