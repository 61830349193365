define("lh4/templates/components/ui/table-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zbNIeLgW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"head\",\"row\",\"pagination\"],[[28,\"component\",[\"ui/table-wrapper/head\"],null],[28,\"component\",[\"ui/table-wrapper/row\"],[[\"collection\",\"noRecordsMessage\"],[[24,[\"collectionPaged\"]],[24,[\"noRecordsMessage\"]]]]],[28,\"component\",[\"ui/table-wrapper/pagination\"],[[\"totalPages\",\"currentPage\",\"pageSize\",\"onPageChange\"],[[24,[\"totalPages\"]],[24,[\"currentPage\"]],[24,[\"pageSize\"]],[28,\"action\",[[23,0,[]],\"onPageChange\"],null]]]]]]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/table-wrapper.hbs"
    }
  });

  _exports.default = _default;
});