define("lh4/routes/authenticated/bank-account/credentials", ["exports", "ember-changeset", "ember-changeset-cp-validations", "lh4/mixins/changeset-transition-route-mixin"], function (_exports, _emberChangeset, _emberChangesetCpValidations, _changesetTransitionRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, {
    location: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        credentials: this.store.peekRecord('bank-account/credential', this.location.model.id)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model.credentials),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(model.credentials, validateFn, validationMap, {
        skipValidate: true
      });
      controller.set('changeset', changeset);
    },
    afterModel: function afterModel(model) {
      if (!model.credentials) {
        this.transitionTo('authenticated.bank-account.verify-owner');
      }
    },
    deactivate: function deactivate() {
      // When the user leaves this page we'll clear existing models
      // This allows us to go through the process again without existing
      // stored values/state. If the owner token was set the verify-owner
      // page will immediately redirect to the bank-account/edit page.
      this.store.unloadAll('bank-account/bank');
      this.store.unloadAll('bank-account/credential');
    }
  });

  _exports.default = _default;
});