define("lh4/adapters/supply-orders/product", ["exports", "ember-inflector", "lh4/adapters/application"], function (_exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    query: function query(store, type, _query) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.ajax.request("/api/v1/supply-orders/products/by-mid/".concat(_query.locationId), {
          type: 'GET'
        }).then(function (orderObj) {
          resolve(orderObj);
        }).catch(function () {
          reject('An error occurred when GET was made to products/by-mid/');
        });
      });
    },
    pathForType: function pathForType(model) {
      var modelName = (0, _emberInflector.pluralize)(model);
      modelName = "".concat(modelName, "/by-mid");
      return modelName;
    }
  });

  _exports.default = _default;
});