define("lh4/templates/components/ui/icons/sidebar/skytab-bi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6iutvzk2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M18.5 11L16.7812 7.21875L13 5.5L16.7812 3.78125L18.5 0L20.2188 3.78125L24 5.5L20.2188 7.21875L18.5 11ZM13 7H12.3H11V17H13V7.31818V7ZM10.1 5H5V19H19V13.9L21 9.5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H14.5L10.1 5ZM9 10H7V17H9V10ZM17 13H15V17H17V13Z\"],[10,\"fill\",\"#090304\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/skytab-bi.hbs"
    }
  });

  _exports.default = _default;
});