define("lh4/templates/authenticated/locations/location/echo-pro/employee-schedule/template/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bL8kqkEF",
    "block": "{\"symbols\":[\"template\"],\"statements\":[[4,\"modal/route-modal\",null,[[\"class\"],[\"small\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"ui/modal/dialog-header\",null,[[\"title\",\"close\"],[\"List of templates\",[28,\"action\",[[23,0,[]],\"clear\"],null]]]],false],[0,\"\\n\\n\"],[4,\"ui/modal/dialog-content\",null,null,{\"statements\":[[4,\"tables/multiselect-table\",null,[[\"selected\",\"columns\",\"items\"],[[24,[\"selected\"]],[24,[\"columns\"]],[24,[\"model\",\"templates\"]]]],{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"moment-format\",[[23,1,[\"createdAt\"]],\"DD/MM/YYYY\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"isLoading\"]],\"loading\",\"\"],null],\" button\"]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"deleteSelected\"]],[8],[0,\"Delete Selected\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/employee-schedule/template/list.hbs"
    }
  });

  _exports.default = _default;
});