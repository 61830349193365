define("lh4/templates/components/ui/form-fields/region-input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BY/qIVPS",
    "block": "{\"symbols\":[\"@changeset\",\"@label\",\"@onChange\",\"@property\",\"@showErrors\",\"@fullWidth\",\"@placeholder\",\"@isLoading\"],\"statements\":[[4,\"if\",[[23,0,[\"region\",\"showRegion\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"ui/form-fields/input-field\",[[12,\"data-test-form-field-input-field\",\"region\"]],[[\"@changeset\",\"@label\",\"@property\",\"@showErrors\",\"@onChange\",\"@fullWidth\",\"@type\",\"@placeholder\",\"@disabled\"],[[23,1,[]],[23,2,[]],[23,4,[]],[23,5,[]],[23,3,[]],[23,6,[]],\"text\",[23,7,[]],[23,8,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[5,\"ui/form-fields/state-select-field\",[],[[\"@changeset\",\"@label\",\"@onChange\",\"@property\",\"@showErrors\",\"@fullWidth\",\"@placeholder\",\"@disabled\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/region-input-field.hbs"
    }
  });

  _exports.default = _default;
});