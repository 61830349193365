define("lh4/models/echo-pro/availability-price/schedule", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "lh4/utils/boolean-property-array"], function (_exports, _model, _attr, _emberCpValidations, _booleanPropertyArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    scheduleName: {
      description: 'Schedule Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    daysOfWeek: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'At least one weekday must be selected'
    }),
    beginTimeMilliseconds: {
      description: 'From',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('invalidBeginTime')) {
          return 'Invalid Time';
        }

        return true;
      })]
    },
    endTimeMilliseconds: {
      description: 'To',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('invalidEndTime')) {
          return 'Invalid Time';
        }

        return true;
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (!value) {
          return true;
        }

        if (value <= model.get('beginTimeMilliseconds')) {
          return 'Must be later than \'From\' time';
        }

        return true;
      })]
    }
  });
  var DAY_OF_WEEK_OPTIONS = {
    isOnMonday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnTuesday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnWednesday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnThursday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnFriday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnSaturday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnSunday: (0, _attr.default)('boolean', {
      defaultValue: false
    })
  };

  var _default = _model.default.extend(Validations, DAY_OF_WEEK_OPTIONS, {
    guid: (0, _attr.default)('string'),
    scheduleTitleGuid: (0, _attr.default)('string'),
    scheduleName: (0, _attr.default)('string'),
    scheduleNumber: (0, _attr.default)('number'),
    endTimeMilliseconds: (0, _attr.default)('number'),
    beginTimeMilliseconds: (0, _attr.default)('number'),
    isVoided: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    daysOfWeek: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(DAY_OF_WEEK_OPTIONS)).concat([(0, _booleanPropertyArray.default)(Object.keys(DAY_OF_WEEK_OPTIONS))]))
  });

  _exports.default = _default;
});