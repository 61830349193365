define("lh4/components/table-layout/label-settings-edit", ["exports", "lh4/components/table-layout/shared/canvas-rotate-actions"], function (_exports, _canvasRotateActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: null,
    actions: {
      rotateMinus: function rotateMinus() {
        (0, _canvasRotateActions.rotateMinus)(this.get('label'));
        this.sendAction('redrawObject', this.get('label'));
        this.sendAction('updateModel', this.get('label.meta.guid'), {
          rotation: this.get('label.meta.angle')
        });
      },
      rotatePlus: function rotatePlus() {
        (0, _canvasRotateActions.rotatePlus)(this.get('label'));
        this.sendAction('redrawObject', this.get('label'));
        this.sendAction('updateModel', this.get('label.meta.guid'), {
          rotation: this.get('label.meta.angle')
        });
      }
    }
  });

  _exports.default = _default;
});