define("lh4/templates/components/charts/bar/overview-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "asg82YYv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[11,\"id\",[29,[\"overview-chart-1\",[22,\"chartId\"]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/charts/bar/overview-bar.hbs"
    }
  });

  _exports.default = _default;
});