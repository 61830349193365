define("lh4/mirage/fixtures/internet-payments/location-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LOCATION_GROUPS_FIXTURE = {
    locationGroups: [{
      id: '1',
      groupName: 'GroupA 1',
      createdBy: 'User 1',
      lastUpdated: '2024-07-30T23:03:49.000Z',
      updatedBy: 'User 2',
      locations: 4,
      status: 'Active'
    }, {
      id: '2',
      groupName: 'GroupB 1',
      createdBy: 'User 1',
      lastUpdated: '2024-07-30T23:03:49.000Z',
      updatedBy: 'User 2',
      locations: 12,
      status: 'Not Active'
    }]
  };
  var _default = LOCATION_GROUPS_FIXTURE;
  _exports.default = _default;
});