define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/reservation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    search: '',
    queryParams: ['startDate', 'search'],
    startDate: null,
    showList: true,
    showReservation: Ember.computed(function () {
      if (window.matchMedia('(max-width: 767px)').matches) {
        return false;
      }

      return true;
    }),
    actions: {
      displayMobileReservation: function displayMobileReservation() {
        if (window.matchMedia('(max-width: 767px)').matches) {
          this.toggleProperty('showList');
          this.toggleProperty('showReservation');
        }
      },
      search: function search(term) {
        this.set('search', term);
      },
      clear: function clear() {
        this.set('search', '');
      },
      dateRangeSelected: function dateRangeSelected(start) {
        if (this.get('model.reservation').get('firstObject') && this.get('model.reservation').get('firstObject').id) {
          this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.reservation.edit', this.get('model.reservation').get('firstObject').id, {
            queryParams: {
              startDate: start
            }
          });
        } else {
          this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.reservation', {
            queryParams: {
              startDate: start
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});