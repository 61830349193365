define("lh4/templates/components/ui/tray/sliding-tray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YBBCDTVo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"tray\"]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"sliding-tray \",[22,\"classNames\"],\" \",[28,\"if\",[[24,[\"isTrayOpen\"]],\"open\"],null]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"tray-header\",[28,\"if\",[[24,[\"errorMessage\"]],\" error\"],null]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h2\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"i\",false],[12,\"class\",\"big times icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted red segment error-message\"],[10,\"data-test-message\",\"error\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui icon exclamation triangle\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"errorMessage\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[14,1,[[28,\"hash\",null,[[\"content\",\"actions\",\"close\"],[[28,\"component\",[\"ui/shared/yield-basic\"],[[\"defaultClassNames\"],[\"content\"]]],[28,\"component\",[\"ui/tray/sliding-tray-actions\"],null],[28,\"action\",[[23,0,[]],\"close\"],null]]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/tray/sliding-tray.hbs"
    }
  });

  _exports.default = _default;
});