define("lh4/routes/authenticated/bank-account/edit", ["exports", "ember-changeset", "lh4/utils/validations", "lh4/mixins/changeset-transition-route-mixin"], function (_exports, _emberChangeset, _validations, _changesetTransitionRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.session.ownerToken) {
        this.replaceWith('authenticated.bank-account.verify-owner');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        bank: this.store.createRecord('bank-account/bank')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var _buildChangesetDepend = (0, _validations.buildChangesetDependentValidations)(model.bank),
          validateFn = _buildChangesetDepend.validateFn,
          validationMap = _buildChangesetDepend.validationMap;

      var changeset = new _emberChangeset.default(model.bank, validateFn, validationMap, {
        skipValidate: true
      });
      controller.set('changeset', changeset);
    }
  });

  _exports.default = _default;
});