define("lh4/models/solo/device", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    terminalName: {
      description: 'Terminal Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    isActive: {
      description: 'Active',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    terminalName: (0, _attr.default)('string'),
    hardwareId: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    isActive: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});