define("lh4/mirage/routes/transactions", ["exports", "lh4/mirage/fixtures/internet-payments/transactions"], function (_exports, _transactions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageTransactionsServer;

  function setMirageTransactionsServer(server) {
    server.get('/transactions', function () {
      return _transactions.default;
    });
  }
});