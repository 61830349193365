define("lh4/models/echo-pro/setting/accounting", ["exports", "ember-cp-validations", "ember-data/attr", "ember-data/model", "ember-data/relationships", "lh4/utils/is-positive-decimal"], function (_exports, _emberCpValidations, _attr, _model, _relationships, _isPositiveDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var lessThan = function lessThan(low, high) {
    return parseFloat(low) <= parseFloat(high);
  };

  var greaterThan = function greaterThan(low, high) {
    return parseFloat(low) >= parseFloat(high);
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    minimumWage: {
      description: 'Minimum wage',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        integer: false
      }), (0, _emberCpValidations.validator)(function (value) {
        if ((0, _isPositiveDecimal.default)(value)) {
          return true;
        }

        return 'Value must be a number, can only contain maximum two decimal numbers' + ' and may only be 10 characters long';
      })]
    },
    // Daily overtime threshold
    firstDailyOvertimeThreshold: {
      description: 'First Daily Overtime Threshold',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        integer: false,
        lte: 24
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('location').model.features.newTimeClockManagement) {
          if (model.get('overtimeCalculation') === 'California' && parseInt(value, 10) < 8) {
            return 'First Daily Overtime Threshold must be higher than or equal to 8';
          }
        } else if (parseInt(value, 10) < 1) {
          return 'First Daily Overtime Threshold must be higher than or equal to 1';
        }

        if (model.get('secondDailyOvertimeThreshold') && greaterThan(value, model.get('secondDailyOvertimeThreshold'))) {
          return 'First Daily Overtime Threshold must be lower than the Second Daily Overtime Threshold';
        }

        return true;
      }, {
        dependentKeys: ['model.secondDailyOvertimeThreshold']
      })]
    },
    secondDailyOvertimeThreshold: {
      description: 'Second Daily Overtime Threshold',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true,
        integer: false,
        lte: 24
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('location').model.features.newTimeClockManagement) {
          if (model.get('overtimeCalculation') === 'California' && parseInt(value, 10) < 12) {
            return 'Second Daily Overtime Threshold must be higher than or equal to 12';
          }
        }

        if (lessThan(value, model.get('firstDailyOvertimeThreshold'))) {
          return 'First Daily Overtime Threshold must be lower than the Second Daily Overtime Threshold';
        }

        return true;
      }, {
        dependentKeys: ['model.firstDailyOvertimeThreshold']
      })]
    },
    // Weekly overtime threshold
    firstWeeklyOvertimeThreshold: {
      description: 'First Weekly Overtime Threshold',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        integer: false,
        lte: 40
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('secondWeeklyOvertimeThreshold') && greaterThan(value, model.get('secondWeeklyOvertimeThreshold'))) {
          return 'First Weekly Overtime Threshold must be lower than the Second Weekly Overtime Threshold';
        }

        return true;
      }, {
        dependentKeys: ['model.secondWeeklyOvertimeThreshold']
      })]
    },
    secondWeeklyOvertimeThreshold: {
      description: 'Second Weekly Overtime Threshold',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true,
        integer: false,
        lte: 168
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (lessThan(value, model.get('firstWeeklyOvertimeThreshold'))) {
          return 'First Weekly Overtime Threshold must be lower than the Second Weekly Overtime Threshold';
        }

        return true;
      }, {
        dependentKeys: ['model.firstWeeklyOvertimeThreshold']
      })]
    },
    // Overtime multipliers
    firstOvertimeMultiplier: {
      description: 'First Overtime Multiplier',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        integer: false,
        gte: 1.5
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('secondOvertimeMultiplier') && greaterThan(value, model.get('secondOvertimeMultiplier'))) {
          return 'First Overtime Multiplier must be lower than the Second Overtime Multiplier';
        }

        return true;
      }, {
        dependentKeys: ['model.secondOvertimeMultiplier']
      })]
    },
    secondOvertimeMultiplier: {
      description: 'Second Overtime Multiplier',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true,
        integer: false,
        gt: Ember.computed.alias('model.firstOvertimeMultiplier'),
        dependentKeys: ['model.firstOvertimeMultiplier']
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        var overtimeRule = model.get('overtimeCalculation');

        if (overtimeRule !== 'Weekly' && Ember.isEmpty(value)) {
          return 'Second Overtime Multiplier must be a number';
        }

        return true;
      }, {
        dependentKeys: ['model.overtimeCalculation']
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    autoClockOut: (0, _attr.default)('string'),
    breaksPaid: (0, _attr.default)('string'),
    firstDailyOvertimeThreshold: (0, _attr.default)('string', {
      defaultValue: '8.0'
    }),
    firstOvertimeMultiplier: (0, _attr.default)('string', {
      defaultValue: '1.5'
    }),
    firstWeeklyOvertimeThreshold: (0, _attr.default)('string', {
      defaultValue: '40.00'
    }),
    minimumWage: (0, _attr.default)('string', {
      defaultValue: '7.25'
    }),
    monthlyPayPeriods: (0, _attr.default)('string'),
    overtimeCalculation: (0, _attr.default)('string', {
      defaultValue: 'Weekly'
    }),
    overtimeThreshold: (0, _attr.default)('string'),
    payPeriodType: (0, _attr.default)('string'),
    secondDailyOvertimeThreshold: (0, _attr.default)('string'),
    secondOvertimeMultiplier: (0, _attr.default)('string'),
    secondWeeklyOvertimeThreshold: (0, _attr.default)('string'),
    setting: (0, _relationships.belongsTo)('echo-pro/setting'),
    location: Ember.inject.service()
  });

  _exports.default = _default;
});