define("lh4/enums/stpos-ui-stylesheet-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STPOS_UI_STYLESHEET_COLOR = void 0;
  var STPOS_UI_STYLESHEET_COLOR = Object.freeze({
    OLIVE: 'olive',
    LATTE: 'latte',
    ORANGE: 'orange',
    RED: 'red',
    SALMON: 'salmon',
    VIOLET: 'violet',
    PURPLE: 'purple',
    IRIS: 'iris',
    TEAL: 'teal',
    GREEN: 'green',
    MAGENTA: 'magenta'
  });
  _exports.STPOS_UI_STYLESHEET_COLOR = STPOS_UI_STYLESHEET_COLOR;
  var _default = STPOS_UI_STYLESHEET_COLOR;
  _exports.default = _default;
});