define("lh4/helpers/singular-or-plural-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.format = format;
  _exports.default = void 0;

  /**
   * Choose string by number value.
   *
   * @param {Array} arr - not-named arguments
   * @param {Object} hash - named arguments
   * @returns {String} singular or plural string
   */
  function format(arr, hash) {
    var number = hash.number,
        singular = hash.singular,
        plural = hash.plural;
    return number === 1 ? singular : plural;
  }

  var _default = Ember.Helper.helper(format);

  _exports.default = _default;
});