define("lh4/templates/components/dashboard-widgets/skytab-volume/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KRRwtVpH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"ui definition table\"],[8],[0,\"\\n\\t\"],[7,\"tbody\",true],[8],[0,\"\\n\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"View\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"ui-dropdown\",null,[[\"selected\",\"onChange\"],[[28,\"or\",[[24,[\"settings\",\"view\"]],\"detail\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"settings\",\"view\"]]],null]],null]]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"item\"],[10,\"data-value\",\"detail\"],[8],[0,\"Detail\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"item\"],[10,\"data-value\",\"table\"],[8],[0,\"Table\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"item\"],[10,\"data-value\",\"simple\"],[8],[0,\"Simple\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/skytab-volume/settings.hbs"
    }
  });

  _exports.default = _default;
});