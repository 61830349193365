define("lh4/components/ui/form-fields/radio-list", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    dataTest: 'form-field-radio-list',
    radioItems: Ember.computed.map('items', function (item) {
      return Ember.typeOf(item) === 'object' ? item : {
        label: item,
        value: item
      };
    })
  });

  _exports.default = _default;
});