define("lh4/components/dashboard/processing-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'segments', 'dashboard', 'processing-detail'],
    selectedBatch: null,
    actions: {
      showTransactions: function showTransactions(batchId) {
        this.set('selectedBatch', null);

        if (this.$(".".concat(batchId)).is(':visible')) {
          this.$(".".concat(batchId)).hide();
        } else {
          this.$('.transactions:visible').hide();
          this.$(".".concat(batchId)).toggle();
          this.set('selectedBatch', batchId);
        }
      },
      selectPeriod: function selectPeriod(component, value) {
        this.sendAction('selectReportPeriod', value);
      }
    }
  });

  _exports.default = _default;
});