define("lh4/components/ui/table-wrapper/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      onPageSizeChange: function onPageSizeChange(pageNo) {
        this.set('pageSize', parseInt(pageNo, 10));
        this.sendAction('onPageChange', 1);
      },
      onPageChange: function onPageChange(pageNo) {
        this.sendAction('onPageChange', pageNo);
      }
    }
  });

  _exports.default = _default;
});