define("lh4/templates/authenticated/ad/qrpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YuUN67J3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui fluid container qrpay-ad\"],[8],[0,\"\\n\\t\"],[7,\"img\",true],[10,\"class\",\"ui image\"],[10,\"src\",\"/img/qrpay-ad.png\"],[10,\"alt\",\"Advertisement for QR Pay\"],[8],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"Please contact your POS software provider or reseller to learn more about accepting QR code payments at your business.\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"video-wrap\"],[8],[0,\"\\n\\t\\t\"],[7,\"script\",true],[10,\"src\",\"https://player.vimeo.com/api/player.js\"],[8],[9],[0,\"\\n\"],[0,\"\\t\\t\"],[7,\"div\",true],[10,\"style\",\"padding:56.25% 0 0 0;position:relative;\"],[8],[7,\"iframe\",true],[10,\"src\",\"https://player.vimeo.com/video/429319845?title=0&byline=0&portrait=0\"],[10,\"style\",\"position:absolute;top:0;left:0;width:100%;height:100%;\"],[10,\"frameborder\",\"0\"],[10,\"allow\",\"autoplay; fullscreen\"],[10,\"allowfullscreen\",\"\"],[8],[9],[9],[0,\"\\n\"],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/ad/qrpay.hbs"
    }
  });

  _exports.default = _default;
});