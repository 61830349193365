define("lh4/services/customer-engagement/campaign-api", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var format = 'YYYY-MM-DD';
  /**
   * Extract date range params from query
   *
   * @param {Object} param query params
   * @returns {Object} object with date range
   */

  function extractDateRange(_ref) {
    var from = _ref.from,
        to = _ref.to;
    return {
      from: (0, _moment.default)(from, format).format(format),
      to: (0, _moment.default)(to, format).add(1, 'day').format(format)
    };
  }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    loadOverview: function loadOverview(campaignId, params) {
      return this.ajax.request("/api/v1/customer-engagement/campaigns/".concat(campaignId, "/overview"), {
        data: extractDateRange(params)
      });
    },
    loadActivity: function loadActivity(campaignId, params) {
      var sortOrder = params.sortAsc ? 'ASC' : 'DESC';
      return this.ajax.request("/api/v1/customer-engagement/customers/".concat(campaignId, "/activity"), {
        data: _objectSpread({
          limit: params.limit,
          offset: params.offset,
          status: params.status,
          sortBy: params.sortBy,
          sortOrder: sortOrder
        }, extractDateRange(params))
      });
    },
    loadSurvey: function loadSurvey(campaignId, params) {
      var sortOrder = params.sortAsc ? 'ASC' : 'DESC';
      return this.ajax.request("/api/v1/customer-engagement/campaigns/".concat(campaignId, "/survey"), {
        data: _objectSpread(_objectSpread({
          limit: params.limit,
          offset: params.offset,
          sortBy: params.sortBy,
          sortOrder: sortOrder
        }, parseInt(params.rating, 10) ? {
          rating: parseInt(params.rating, 10)
        } : null), extractDateRange(params))
      });
    },
    deleteCampaign: function deleteCampaign(campaignId) {
      return this.ajax.delete("/api/v1/customer-engagement/campaigns/".concat(campaignId));
    }
  });

  _exports.default = _default;
});