define("lh4/serializers/social-media/account", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Object.assign(hash, this.serialize(record, options));
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'social-media/account';
    },
    attrs: {
      locations: {
        serialize: 'ids',
        deserialize: 'ids'
      }
    }
  });

  _exports.default = _default;
});