define("lh4/components/internet-payments/transactions-controls", ["exports", "lh4/enums/transactions-search-filters", "lh4/helpers/usd-format"], function (_exports, _transactionsSearchFilters, _usdFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var generatePreviewList = function generatePreviewList(optionSet, baseSet) {
    var maxSingles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    var supportAll = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

    if (!optionSet || optionSet.length === 0) {
      return [];
    }

    if (optionSet.length <= maxSingles) {
      return baseSet.filter(function (b) {
        return optionSet.includes(b.id);
      }).map(function (o) {
        return o.name;
      });
    }

    if (supportAll === true && baseSet.length === optionSet.length) {
      return ['All'];
    }

    return ["".concat(optionSet.length, " Selected")];
  };

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    can: Ember.inject.service(),
    searchCriteria: _transactionsSearchFilters.IP_TRANSACTIONS_SEARCH_FILTERS,

    get acquirerResponseOptionsPreviews() {
      return generatePreviewList(this.acquirerResponseQueryParams, this.acquirerResponseOptions, 0).join(', ');
    },

    get amountOptionsPreview() {
      var min = this.minQueryParam;
      var max = this.maxQueryParam;
      var displayMin = min || min === 0 ? (0, _usdFormat.usdFormat)([min]) : null;
      var displayMax = max || max === 0 ? (0, _usdFormat.usdFormat)([max]) : null;

      if (!displayMin && !displayMax) {
        return '';
      } // only min exists


      if (displayMin && !displayMax) {
        return "From ".concat(displayMin);
      } // only max exists


      if (displayMax && !displayMin) {
        return "To ".concat(displayMax);
      }

      return "".concat(displayMin, " - ").concat(displayMax);
    },

    get eventStatusOptionsPreviews() {
      // this.eventStatusOptions should already handle feature flag alterations
      return generatePreviewList(this.eventStatusQueryParams, this.eventStatusOptions, 0).join(', ');
    },

    get riskRuleOptionsPreviews() {
      return generatePreviewList(this.riskRuleQueryParams, this.riskRuleOptions, 0).join(', ');
    },

    get riskStatusOptionsPreviews() {
      return generatePreviewList(this.riskQueryParams, this.riskStatusOptions).join(', ');
    },

    get transactionStatusOptionsPreviews() {
      return generatePreviewList(this.transactionStatusQueryParams, this.transactionStatusOptions).join(', ');
    },

    get transactionTypeOptionsPreviews() {
      return generatePreviewList(this.transactionTypeQueryParams, this.transactionTypesOptions).join(', ');
    },

    get searchBy() {
      var _this$matchPartial;

      return ((_this$matchPartial = this.matchPartial) === null || _this$matchPartial === void 0 ? void 0 : _this$matchPartial.length) > 0 ? this.matchPartial[0] : null;
    },

    // We only know how many have been selected and not necessarily how many are possible
    // so we're going to short circuit generatePreviewList to just return the number of batch
    // numbers selected by matching it against itself and turning off the "All" option
    // This should always give us "X Selected" unless it's 0.
    get merchantBatchNumbersOptionsPreviews() {
      return generatePreviewList(this.merchantBatchNumberParams, this.merchantBatchNumberParams, 0, false).join(', ');
    },

    actions: {
      onFilter: function onFilter() {
        this.onFilter();
      },
      onNewTransaction: function onNewTransaction() {
        this.onNewTransaction();
      },
      onExport: function onExport(fileType) {
        this.downloadTransactions(fileType);
      }
    }
  });

  _exports.default = _default;
});