define("lh4/models/reservation", ["exports", "ember-data", "ember-data/relationships", "ember-cp-validations", "moment"], function (_exports, _emberData, _relationships, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    customerFullName: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        message: 'Name should not be empty'
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    partySize: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Party size should not be empty'
    }),
    partyTime: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Time should not be empty'
    }),
    customerEmail: (0, _emberCpValidations.validator)('email', {
      allowBlank: true,
      type: 'email',
      allowNonTld: true,
      message: 'Email format incorrect.'
    }),
    customerPhone: (0, _emberCpValidations.validator)('phone-number', {
      presence: true,
      message: 'Number is incorrect or empty'
    }),
    notes: (0, _emberCpValidations.validator)('length', {
      max: 200,
      message: 'Note cannot be longer than 200 characters'
    })
  });

  var _default = _emberData.default.Model.extend(Validations, {
    locationId: _emberData.default.attr('number'),
    statusId: _emberData.default.attr('number', {
      defaultValue: 2
    }),
    sectionGuid: (0, _relationships.belongsTo)('reservations/section'),
    tableLayoutGuid: (0, _relationships.belongsTo)('reservations/table'),
    type: _emberData.default.attr('string', {
      defaultValue: 'reservation'
    }),
    customerGuid: _emberData.default.attr('string'),
    customerFullName: _emberData.default.attr('string'),
    customerPhone: _emberData.default.attr('phone'),
    customerPhoneType: _emberData.default.attr('string', {
      defaultValue: 'Cell'
    }),
    customerEmail: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    partyTime: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return (0, _moment.default)((0, _moment.default)().toISOString(), 'YYYY-MM-DD').toISOString();
      }
    }),
    partySize: _emberData.default.attr('number'),
    arrivedAt: _emberData.default.attr('string'),
    notificationType: _emberData.default.attr('string', {
      defaultValue: 'None'
    })
  });

  _exports.default = _default;
});