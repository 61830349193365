define("lh4/controllers/authenticated/locations/location/revenue-centers/create", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    actions: {
      handleInput: function handleInput(value) {
        this.changeset.set('revenueCenterName', value);
      }
    }
  });

  _exports.default = _default;
});