define("lh4/components/epro/menu/dynamic-pricing-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid', 'dynamic-pricing-form'],
    showErrors: false,
    actions: {
      handleTimeOptionChange: function handleTimeOptionChange(timeOption) {
        this.changeset.set('timeOption', timeOption);

        if (timeOption === 'Custom Schedule') {
          if (this.changeset.scheduleChangesets.filterBy('isVoided', false).length === 0) {
            var initialSchedule = this.store.createRecord('echo-pro/availability-price/schedule', {
              guid: null,
              locationId: this.location.model.id,
              scheduleNumber: 1,
              scheduleName: 'Schedule 1'
            });
            var changeset = this.buildChildChangeset(initialSchedule);
            this.changeset.scheduleChangesets.pushObject(changeset);
          }
        }
      }
    }
  });

  _exports.default = _default;
});