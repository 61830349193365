define("lh4/mirage/fixtures/users/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    merchantId: '0000000001',
    name: 'Super Location 1',
    legalName: 'Internet Payments 1',
    shift4Serial: '1',
    hasAccess: true
  }, {
    id: 2,
    merchantId: '0000000002',
    name: 'Location 2',
    legalName: 'Internet Payments 1',
    shift4Serial: '1',
    hasAccess: true
  }, {
    id: 3,
    merchantId: '0000000003',
    name: 'Location 3',
    legalName: 'Super Online Payments 1',
    shift4Serial: '2',
    hasAccess: true
  }, {
    id: 4,
    merchantId: '0000000004',
    name: 'Location 4',
    legalName: 'Super Online Payments 1',
    shift4Serial: '2',
    hasAccess: false
  }, {
    id: 5,
    merchantId: '0000000005',
    name: 'Location 5',
    legalName: 'Online Payments 2',
    shift4Serial: '3',
    hasAccess: false
  }];
  _exports.default = _default;
});