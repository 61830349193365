define("lh4/enums/ip-apm-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APM_STATUS = Object.freeze({
    DISABLED: 'disabled',
    DISABLED_PENDING: 'disabled_pending',
    DISABLED_BLOCKED: 'disabled_blocked',
    ENABLED: 'enabled',
    NO_STATUS: 'no_status'
  });
  var _default = APM_STATUS;
  _exports.default = _default;
});