define("lh4/components/table-layout/section-settings-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this$section;

      this._super.apply(this, arguments);

      this.originalName = (_this$section = this.section) === null || _this$section === void 0 ? void 0 : _this$section.name;
    },
    cacheOriginalName: Ember.observer('section', function observe() {
      var _this$section2;

      this.originalName = (_this$section2 = this.section) === null || _this$section2 === void 0 ? void 0 : _this$section2.name;
    }),
    actions: {
      onChange: function onChange() {
        var _this$section3, _this$section3$name;

        if (!((_this$section3 = this.section) !== null && _this$section3 !== void 0 && (_this$section3$name = _this$section3.name) !== null && _this$section3$name !== void 0 && _this$section3$name.trim())) {
          this.section.name = this.originalName;
        }
      }
    }
  });

  _exports.default = _default;
});