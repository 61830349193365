define("lh4/mixins/controllers/discard-changes-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TRANSITION_TIMEOUT_TO_FINISH_ANIMATION = 200;

  var _default = Ember.Mixin.create({
    modelNameToTrack: 'model',
    actions: {
      discardChanges: function discardChanges() {
        var _this = this;

        var modelName = this.get('modelNameToTrack');
        var model = this.get(modelName);

        if (model) {
          if (model.get('isNew')) {
            model.destroyRecord();
          } else {
            model.rollback();
          }

          Ember.run.later(function () {
            return _this.get('transition').retry();
          }, TRANSITION_TIMEOUT_TO_FINISH_ANIMATION);
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});