define("lh4/mixins/dashboard-widget-mixin", ["exports", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // defaults
    location: {},
    toggleExpand: function toggleExpand() {},
    launchDarkly: Ember.inject.service('launch-darkly'),
    data: Ember.computed('loading', 'widget', 'widget.data', function getter() {
      // A widget can be loaded, but not accessible
      // get widget() in generic-widget.js returns false if the product is denied or the user is not permitted.
      // The computed properties will still fire and are expecting data, so defaulting to the skeleton data.
      if (this.get('loading') || this.get('widget') === false) {
        return this.get('skeletonData');
      }

      return this.get('widget.data');
    }),
    useIntlDates: Ember.computed('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_I18N_DASHBOARD_DATE_FORMATS];
    })
  });

  _exports.default = _default;
});