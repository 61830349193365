define("lh4/routes/authenticated/locations/location/echo-pro/employee-schedule/template/create", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var location = this.modelFor('authenticated.locations.location');

      var _this$modelFor = this.modelFor('authenticated.locations.location.echo-pro.employee-schedule'),
          range = _this$modelFor.range,
          shifts = _this$modelFor.shifts;

      return Ember.RSVP.hash({
        range: range,
        template: this.store.createRecord('echo-pro/schedule-template', {
          name: '',
          locationId: location.id,
          createdAt: new Date(),
          schedules: shifts.map(function (shift) {
            return shift.toSchedule();
          })
        })
      });
    }
  });

  _exports.default = _default;
});