define("lh4/routes/authenticated/reporting/subscriptions/create-subscription", ["exports", "ember-changeset", "ember-changeset-cp-validations", "lh4/mixins/changeset-transition-route-mixin"], function (_exports, _emberChangeset, _emberChangesetCpValidations, _changesetTransitionRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, {
    modelName: 'subscription',
    childChangesets: {
      reportSubscriptionRecipients: 'reportSubscriptionRecipientsChangesets'
    },
    queryParams: {
      clone: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var clone = _ref.clone;
      return Ember.RSVP.hash({
        subscription: this.createNewSubscription(clone),
        locations: this.modelFor('authenticated').locations,
        products: this.modelFor('authenticated.reporting.subscriptions').products,
        reportTypes: this.modelFor('authenticated.reporting.subscriptions').reportTypes
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model.subscription),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(model.subscription, validateFn, validationMap, {
        skipValidate: true
      });
      changeset.reportSubscriptionRecipientsChangesets = model.subscription.reportSubscriptionRecipients.map(this.buildChildChangeset);
      var modelName = this.modelName,
          childChangesets = this.childChangesets; // clears main model created in create routes in case model was not saved

      var clearTemporaryMainModel = function clearTemporaryMainModel() {
        if (model[modelName].isNew) {
          model[modelName].unloadRecord();
        }
      };

      if (!childChangesets) {
        return;
      } // set loaded data on controller to check if anything changed when exiting


      var childChangesetsSnapshotMap = {}; // build child changesets

      Object.keys(childChangesets).forEach(function (childModelName) {
        var childChangesetName = childChangesets[childModelName];
        changeset[childChangesetName] = model[modelName][childModelName].map(_this.buildChildChangeset);
        childChangesetsSnapshotMap[childChangesetName] = changeset[childChangesetName].map(function (c) {
          return JSON.stringify(c.data.toJSON());
        });
      });

      var clearTemporaryData = function clearTemporaryData() {
        Object.keys(childChangesets).forEach(function (childModelName) {
          var childChangesetName = childChangesets[childModelName]; // unload new records that were created when creating new childChangesets

          model[modelName][childModelName].filter(function (m) {
            return m.isNew;
          }).forEach(function (m) {
            return m.unloadRecord();
          }); // update childChangesetsSnapshots to match childChangesets
          // this prevents false positives when detecting unsaved changes

          childChangesetsSnapshotMap[childChangesetName] = changeset[childChangesetName].map(function (c) {
            return JSON.stringify(c.data.toJSON());
          });
        }); // used after clearing child records as unloading main model leaves child models in !isNew state

        clearTemporaryMainModel();
      };

      controller.setProperties({
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        },
        childChangesetsSnapshotMap: childChangesetsSnapshotMap,
        clearTemporaryData: clearTemporaryData,
        changeset: changeset
      });
    },
    createNewSubscription: function createNewSubscription(clone) {
      if (clone) {
        var originalSubscription = this.store.peekRecord('reports/subscription', clone);
        return originalSubscription.cloneShallow({
          guid: null,
          name: ''
        });
      }

      return Ember.RSVP.resolve(this.store.createRecord('reports/subscription'));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // do not use sticky query params
        // https://guides.emberjs.com/v3.15.0/routing/query-params/#toc_sticky-query-param-values
        controller.set('clone', undefined);
      }
    },
    buildChildChangeset: function buildChildChangeset(model) {
      var _buildChangeset2 = (0, _emberChangesetCpValidations.buildChangeset)(model),
          validateFn = _buildChangeset2.validateFn,
          validationMap = _buildChangeset2.validationMap;

      return new _emberChangeset.default(model, validateFn, validationMap, {
        skipValidate: true
      });
    }
  });

  _exports.default = _default;
});