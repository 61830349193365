define("lh4/utils/launch-control/google-map-api", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function init() {
    return new Promise(function (resolve, reject) {
      window.initMap = function () {
        return resolve();
      };

      var script = document.createElement('script');
      script.src = "https://maps.googleapis.com/maps/api/js?key=".concat(_environment.default['ember-google-maps'].key, "&callback=initMap");
      script.async = true;
      script.defer = true;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  var _default = {
    init: init
  };
  _exports.default = _default;
});