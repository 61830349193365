define("lh4/routes/authenticated/locations/location/hospitality/customize-menu/category/edit-category", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(param) {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        menuCategory: param.categoryId === 'null' ? 'null' : this.store.findRecord('hospitality/menu-category', param.categoryId),
        menuItem: this.store.query('hospitality/menu-item', {
          location: location.id,
          category: param.categoryId
        }).then(function () {
          return _this.store.peekAll('hospitality/menu-item');
        })
      });
    },
    afterModel: function afterModel(model) {
      var customizeMenuController = 'authenticated.locations.location.hospitality.customize-menu';

      if (model.menuCategory !== 'null') {
        this.controllerFor("".concat(customizeMenuController, ".category.edit-category.item.create-item")).set('categoryRowGuid', model.menuCategory.get('rowguid'));
      }

      this.controllerFor(customizeMenuController).set('selectedCategory', model.menuCategory);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('hospitality/menu-item');
    }
  });

  _exports.default = _default;
});