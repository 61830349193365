define("lh4/routes/authenticated/reputation/reviews/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    location: Ember.inject.service(),
    redirect: function redirect() {
      this.transitionTo('authenticated.reputation.reviews.locations', this.get('location.model.id'));
    }
  });

  _exports.default = _default;
});