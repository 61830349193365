define("lh4/serializers/dashboard/financial-overview/application", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'locationId',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return "dashboard/financial-overview/".concat(payloadKey);
    }
  });

  _exports.default = _default;
});