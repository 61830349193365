define("lh4/enums/ip-home-page-defaults", ["exports", "lh4/utils/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INTERNET_PAYMENTS_HOME_PAGE_DEFAULTS = Object.freeze({
    dashboard: Object.freeze({
      id: 'dashboard',
      name: 'Dashboard',
      route: 'authenticated.internet-payments.dashboard',
      permission: _permissions.default.DASHBOARD.VIEW_DASHBOARD
    }),
    transactions: Object.freeze({
      id: 'transactions',
      name: 'Transactions',
      route: 'authenticated.internet-payments.transactions',
      permission: _permissions.default.TRANSACTIONS.VIEW_TRANSACTIONS
    }),
    customers: Object.freeze({
      id: 'customers',
      name: 'Customers',
      route: 'authenticated.internet-payments.customers',
      permission: null
    })
  });
  var _default = INTERNET_PAYMENTS_HOME_PAGE_DEFAULTS;
  _exports.default = _default;
});