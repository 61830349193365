define("lh4/components/customer-engagement/campaign-edit-wizard/campaign-audience", ["exports", "lh4/models/customer-engagement/campaign"], function (_exports, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SCHEDULE_TYPE_AND_AUDIENCE_PAIRS = Object.freeze({
    RULE_ALL: "".concat(_campaign.CAMPAIGN_SCHEDULE_TYPES.RULE, "_").concat(_campaign.CAMPAIGN_AUDIENCE_TYPES.ALL),
    TIME_ALL: "".concat(_campaign.CAMPAIGN_SCHEDULE_TYPES.TIME, "_").concat(_campaign.CAMPAIGN_AUDIENCE_TYPES.ALL),
    TIME_GROUPS: "".concat(_campaign.CAMPAIGN_SCHEDULE_TYPES.TIME, "_").concat(_campaign.CAMPAIGN_AUDIENCE_TYPES.GROUPS)
  });

  var _default = Ember.Component.extend({
    flashMessagesHelper: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['ui', 'segment', 'campaign', 'audience'],
    selectedCustomers: Ember.computed('campaign.manualCustomers', function getter() {
      if (this.get('campaign.manualCustomers.length')) {
        return this.get('campaign.manualCustomers').toArray();
      }

      return [];
    }),
    isLoading: false,
    isRuleAvailable: Ember.computed('location.product', 'campaign.type', function getter() {
      return this.get('scheduleTypesAvailable').includes(_campaign.CAMPAIGN_SCHEDULE_TYPES.RULE);
    }),
    isTimeAvailable: Ember.computed('location.product', 'campaign.type', function getter() {
      return this.get('scheduleTypesAvailable').includes(_campaign.CAMPAIGN_SCHEDULE_TYPES.TIME);
    }),
    currentValidation: Ember.computed.alias('campaign.validations.errors.firstObject.attribute'),
    inviteRuleCombined: Ember.computed('campaign.customerVisitCount', 'campaign.customerLastVisitWithinDays', 'campaign.customerNotVisitedWithinDays', function getter() {
      var count = this.get('campaign.customerVisitCount');
      var lastVisit = this.get('campaign.customerLastVisitWithinDays');
      var notVisited = this.get('campaign.customerNotVisitedWithinDays');
      return "".concat(count, " ").concat(lastVisit, " ").concat(notVisited);
    }),
    campaignScheduleTypeAndAudience: Ember.computed('campaign.scheduleType', 'campaign.audience', function getter() {
      return "".concat(this.campaign.scheduleType, "_").concat(this.campaign.audience);
    }),
    CAMPAIGN_SCHEDULE_TYPES: _campaign.CAMPAIGN_SCHEDULE_TYPES,
    CAMPAIGN_AUDIENCE_TYPES: _campaign.CAMPAIGN_AUDIENCE_TYPES,
    CAMPAIGN_TYPES: _campaign.CAMPAIGN_TYPES,
    SCHEDULE_TYPE_AND_AUDIENCE_PAIRS: SCHEDULE_TYPE_AND_AUDIENCE_PAIRS,
    actions: {
      openSelectCustomersModal: function openSelectCustomersModal() {
        var _this = this;

        var locationId = this.get('campaign.locationId');
        this.get('selectedCustomers');
        this.set('isLoading', true);
        this.store.query('customer-engagement/customer', {
          locationId: locationId,
          limit: null
        }).then(function (customers) {
          var customersWithEmail = customers.filter(function (customer) {
            return customer.get('primaryEmail');
          });

          _this.set('customers', customersWithEmail);
        }).catch(function () {
          _this.flashMessagesHelper.pushMessage('Something went wrong while trying to retrieve customers list', 'error');
        }).finally(function () {
          _this.set('isLoading', false);

          _this.$('#campaign-manual-customers-modal').modal('show');
        });
      },
      setManualCustomers: function setManualCustomers(selectedCustomers) {
        this.set('campaign.manualCustomers', selectedCustomers);
      },
      setInviteRule: function setInviteRule(visitCount, lastVisitWithinDays, notVisitedWithinDays) {
        this.set('campaign.customerVisitCount', visitCount);
        this.set('campaign.customerLastVisitWithinDays', lastVisitWithinDays);
        this.set('campaign.customerNotVisitedWithinDays', notVisitedWithinDays);
      }
    }
  });

  _exports.default = _default;
});