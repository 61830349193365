define("lh4/templates/authenticated/locations/location/echo-pro/reservations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3kCl1ffA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"reservation-nav\"],[8],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"tab-selection\",\"authenticated.locations.location.echo-pro.reservations.reservation\"]],{\"statements\":[[0,\"Reservations\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"tab-selection\",\"authenticated.locations.location.echo-pro.reservations.calendar\"]],{\"statements\":[[0,\"Calendar\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"tab-selection\",\"authenticated.locations.location.echo-pro.reservations.blocks\"]],{\"statements\":[[0,\"Reservation Blocks\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"tab-selection\",\"authenticated.locations.location.echo-pro.reservations.settings\"]],{\"statements\":[[0,\"Settings\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",\"create-res\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"showListForCreate\"]],[8],[0,\"\\n\\t\\t\\t\"],[4,\"link-to\",null,[[\"route\"],[\"authenticated.locations.location.echo-pro.reservations.reservation.create\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"plus icon\"],[8],[9],[0,\"Create Reservation\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/reservations.hbs"
    }
  });

  _exports.default = _default;
});