define("lh4/routes/authenticated/disputes/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    location: Ember.inject.service(),
    modelName: 'dispute',
    model: function model(_ref) {
      var disputeId = _ref.disputeId;
      var locationId = this.location.model.id;

      var _this$store$peekRecor = this.store.peekRecord('dispute', disputeId),
          requestToken = _this$store$peekRecor.requestToken;

      return Ember.RSVP.hash({
        dispute: this.store.findRecord('dispute', disputeId, {
          adapterOptions: {
            locationId: locationId,
            requestToken: requestToken
          }
        }),
        evidenceText: ''
      });
    },
    actions: {
      refreshPage: function refreshPage() {
        this.refresh();
      },
      error: function error(_ref2, transition) {
        var errors = _ref2.errors;
        // If a 403 error is detected from the server it's possible that an
        // id for an existing location's asset is still in the url. If this 403
        // has a from object, we'll attempt to replace the transition with the
        // parent similar to the redirect function above.
        return errors && errors[0].code === 403 && transition.from ? this.replaceWith(transition.from.parent.name) : true; // let the error bubble to the next handler
      }
    }
  });

  _exports.default = _default;
});