define("lh4/components/tables/responsive-table/desktop-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cellClass: null,
    classNameBindings: ['cellClass'],
    tagName: 'td'
  });

  _exports.default = _default;
});