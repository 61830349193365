define("lh4/controllers/authenticated/settings", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    can: Ember.inject.service(),
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    location: Ember.inject.service(),
    routeData: Ember.computed(function getter() {
      var routeData = [{
        name: 'authenticated.settings.account',
        title: 'Account'
      }, {
        name: 'authenticated.settings.locations',
        title: 'Locations'
      }, {
        name: 'authenticated.settings.reporting-settings',
        title: 'Settings'
      }];

      if (this.can.can('manage users in general')) {
        routeData.splice(2, 0, {
          name: 'authenticated.settings.users',
          title: 'Users'
        });
      }

      routeData.push({
        name: 'authenticated.settings.2fa',
        title: 'Two-Factor Authentication'
      });
      return routeData;
    })
  });

  _exports.default = _default;
});