define("lh4/models/processing/customer/phone", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _emberData, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.processingPhoneTypes = _exports.default = void 0;
  var processingPhoneTypes = [{
    name: 'Primary'
  }, {
    name: 'Alternate'
  }, {
    name: 'Work'
  }, {
    name: 'Other'
  }, {
    name: 'Unknown'
  }];
  _exports.processingPhoneTypes = processingPhoneTypes;

  var trimPunctuation = function trimPunctuation(value) {
    return value.replace(/[^a-zA-Z0-9]/, '');
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    number: {
      description: 'Phone number',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('phone-number', {
        message: 'Phone number has incorrect format'
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    number: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    customer: (0, _relationships.belongsTo)('processing/customer'),
    createdAt: (0, _attr.default)('date'),
    phoneTypes: Ember.computed(function () {
      return processingPhoneTypes.map(function (_ref) {
        var name = _ref.name;
        return {
          name: name,
          value: name
        };
      });
    }),
    contains: function contains(term) {
      var trimmed = trimPunctuation(term);

      if (!trimmed && term) {
        return false;
      }

      var rawNumber = trimPunctuation(this.get('number') || '');
      return rawNumber.includes(trimmed);
    }
  });

  _exports.default = _default;
});