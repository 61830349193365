define("lh4/services/edit-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Service.extend({
    logger: Ember.inject.service(),
    responseMessages: Ember.inject.service('response-messages'),

    /**
     * Validate record
     * @param {Object} record - Ember model to validate
     * @returns {Promise<Object>} - validated record
     * @throws {Array<string>} - error messages from validation
     */
    validate: function validate(record) {
      return record.validate().then(function (_ref) {
        var validations = _ref.validations;

        if (!validations.get('isValid')) {
          return Ember.RSVP.Promise.reject(validations);
        }

        return record;
      }).catch(function (validations) {
        throw validations.map(function (v) {
          return Ember.get(v, 'errors') ? Ember.get(v, 'errors').map(function (e) {
            return Ember.get(e, 'message');
          }) : [Ember.get(v, 'message')];
        }).reduce(function (all, messages) {
          return [].concat(_toConsumableArray(all), _toConsumableArray(messages));
        });
      });
    },

    /**
     * Validate records array
     * @param {Array<Object>} records - Ember models array to validate
     * @returns {Promise<Array<Object>>} - validated records array
     * @throws {Array<string>} - error messages from first failed record validation
     */
    validateArray: function validateArray(records) {
      var _this = this;

      return Ember.RSVP.Promise.all(records.map(function (record) {
        return _this.validate(record);
      }));
    },

    /**
     * Save record changes
     * @param {Object} record - Ember model to save
     * @returns {Promise<Object>} - saved record
     * @throws {Array<string>} - error messages from HTTP response
     */
    save: function save(record) {
      var _this2 = this;

      return record.save().then(function (savedModel) {
        _this2.logger.log("".concat(record.constructor.modelName, " saved successfully!"));

        return savedModel;
      }).catch(function (err) {
        _this2.logger.log("".concat(JSON.stringify(err), " error occurred when saving ").concat(record.constructor.modelName));

        throw _this2.responseMessages.getResponseErrors(err);
      });
    },

    /**
     *
     * @param {Array<Object>} records - Ember models to save
     * @returns {Promise<Array<Object>>} - saved records array
     * @throws {Array<string>} - error messages from HTTP response
     */
    saveRecords: function saveRecords(records) {
      var _this3 = this;

      if (!records.length) {
        return Ember.RSVP.resolve([]);
      }

      return Ember.RSVP.Promise.all(records.map(function (record) {
        return _this3.save(record);
      }));
    },

    /**
     *
     * @param {Array<Object>} records - Ember models to save
     * @returns {Promise<Array<Object>>} - saved records array
     * @throws {Array<string>} - error messages from HTTP response
     */
    validateAndSaveRecords: function validateAndSaveRecords(records) {
      var _this4 = this;

      if (!records.length) {
        return Ember.RSVP.resolve([]);
      }

      return Ember.RSVP.Promise.all(records.map(function (record) {
        return _this4.validateAndSave(record);
      }));
    },

    /**
     * Validate and Save record
     * @param {Object} record - Ember model
     * @returns {Promise<Object>} - saved record
     * @throws {Array<string>} - validation or save error messages
     */
    validateAndSave: function validateAndSave(record) {
      var _this5 = this;

      return this.validate(record).then(function () {
        return _this5.save(record);
      });
    }
  });

  _exports.default = _default;
});