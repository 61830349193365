define("lh4/controllers/authenticated/internet-payments/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    router: Ember.inject.service(),
    permissions: Ember.inject.service(),
    can: Ember.inject.service(),
    currentTitle: Ember.computed('media.isMobile', 'router.currentRouteName', function getter() {
      var _this = this;

      var currentName;

      var getCurrentRouteName = function getCurrentRouteName() {
        var route = _this.router.currentRouteName;
        var routeName;

        if (route.includes('account-preferences')) {
          routeName = 'Account Preferences';
        } else if (route.includes('account')) {
          routeName = 'Profile';
        } else if (route.includes('locations')) {
          routeName = 'Locations';
        } else if (route.includes('location-groups')) {
          routeName = 'Location Groups';
        } else if (route.includes('two-factor')) {
          routeName = 'Two-Factor Authentication';
        } else if (route.includes('users')) {
          routeName = 'Users';
        } else if (route.includes('documents')) {
          routeName = 'Documents';
        } else {
          routeName = 'Profile';
        }

        return routeName;
      };

      if (this.media.isMobile) {
        currentName = getCurrentRouteName();
      } else {
        currentName = 'Profile';
      }

      return currentName;
    }),
    routeData: Ember.computed(function computeRouteData() {
      var routeData = [];
      routeData.push({
        name: 'authenticated.internet-payments.profile.account',
        title: 'Account'
      });

      if (this.can.can('manage location users in general')) {
        routeData.push({
          name: 'authenticated.internet-payments.profile.users',
          title: 'Users'
        });
      }

      if (this.can.can('view mlm locations page in feature-flag')) {
        routeData.push({
          name: 'authenticated.internet-payments.profile.locations',
          title: 'Locations'
        });
      }

      if (this.can.can('view location groups in general')) {
        routeData.push({
          name: 'authenticated.internet-payments.profile.location-groups',
          title: 'Location Groups'
        });
      }

      routeData.push({
        name: 'authenticated.internet-payments.profile.two-factor',
        title: 'Two-Factor Authentication'
      });
      routeData.push({
        name: 'authenticated.internet-payments.profile.account-preferences',
        title: 'Account Preferences'
      });
      return routeData;
    })
  });

  _exports.default = _default;
});