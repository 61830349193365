define("lh4/routes/authenticated/internet-payments/customers/details/transaction", ["exports", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lighthousePermissionsMixin.default, {
    location: Ember.inject.service(),
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),
    riskBlockListApi: Ember.inject.service('internet-payments/risk-block-list-api'),
    requiredPermissions: ['view transactions in general'],
    model: function model(_ref) {
      var transactionId = _ref.transactionId;
      var transaction = this.store.peekRecord('internet-payments/transaction', transactionId);

      if (!transaction) {
        this.transitionTo('authenticated.internet-payments.customers.details');
      }

      var _this$modelFor = this.modelFor('authenticated.internet-payments.customers.details'),
          customer = _this$modelFor.customer,
          transactions = _this$modelFor.transactions;

      var riskBlockList = this.riskBlockListApi.getRiskBlockListFromTransaction(transaction);
      return Ember.RSVP.hash({
        transaction: transaction,
        transactionsArray: transactions.toArray(),
        customer: customer,
        riskBlockList: riskBlockList
      });
    },
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('authenticated.internet-payments.customers.details');
        controller.set('isLoading', true);
        transition.promise.finally(function () {
          controller.set('isLoading', false);
        });
      },
      riskBlockListChanged: function riskBlockListChanged() {
        this.refresh();
        this.send('generateNewSearch');
      }
    }
  });

  _exports.default = _default;
});