define("lh4/mirage/factories/hospitality-employee", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: function name() {
      return "".concat(_faker.default.name.firstName(), " ").concat(_faker.default.name.lastName());
    },
    firstName: function firstName() {
      return _faker.default.name.firstName();
    },
    lastName: function lastName() {
      return _faker.default.name.lastName();
    },
    middleName: function middleName() {
      return _faker.default.name.firstName();
    },
    login: 1111,
    payrollId: '1111111',
    bornAt: '',
    hiredAt: '',
    isActive: function isActive() {
      return _faker.default.datatype.boolean();
    },
    email: function email() {
      return _faker.default.internet.email();
    },
    state: function state() {
      return _faker.default.address.state();
    },
    city: function city() {
      return _faker.default.address.city();
    },
    liquorCardExpiresAt: null,
    healthCardExpiresAt: null,
    street: function street() {
      return _faker.default.address.streetName();
    },
    street2: function street2() {
      return _faker.default.address.streetName();
    },
    cardswipe: null,
    clockInAt: null,
    currentTimesheetId: null,
    emergencyPhone: function emergencyPhone() {
      return _faker.default.phone.phoneNumber();
    },
    employeeNumber: null,
    expectedClockOutAt: null,
    isAdmin: false,
    isClockedIn: function isClockedIn() {
      return _faker.default.datatype.boolean();
    },
    isLoggedIn: function isLoggedIn() {
      return _faker.default.datatype.boolean();
    },
    locationId: 1,
    lockedOutAt: null,
    ordinal: 11,
    phone1: function phone1() {
      return _faker.default.phone.phoneNumber();
    },
    phone2: function phone2() {
      return _faker.default.phone.phoneNumber();
    },
    postal: '18018',
    reviewedAt: null,
    rowguid: function rowguid() {
      return _faker.default.datatype.uuid();
    },
    securityTemplateId: null,
    ssn: '000-00-0000',
    employeesPrivileges: [],
    employeesJobs: [],
    terminatedAt: null,
    ticketListSchema: null
  });

  _exports.default = _default;
});