define("lh4/components/single-box-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui segments'],
    report: Ember.computed('data', 'name', function getter() {
      var _this = this;

      var report = this.get('data').reports.filter(function (x) {
        return x.name === _this.get('name');
      });
      var reportArray = {
        title: report[0].name,
        array: []
      };
      var headers = report[0].headers;
      var results = report[0].results;
      headers.forEach(function (x, index) {
        var obj = {
          header: x,
          value: results[index]
        };
        reportArray.array.push(obj);
      });
      return reportArray;
    })
  });

  _exports.default = _default;
});