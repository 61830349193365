define("lh4/components/reputation/review-respond-form-owner-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /* @params */
    account: null,
    selectedReview: null,
    showUpdateOwnerReply: false,
    actions: {
      toggleUpdateOwnerReply: function toggleUpdateOwnerReply() {
        this.set('showUpdateOwnerReply', !this.get('showUpdateOwnerReply'));
      },
      onUpdateSuccess: function onUpdateSuccess(replyObj) {
        this.sendAction('onUpdateSuccess', replyObj);
      }
    }
  });

  _exports.default = _default;
});