define("lh4/components/epro/menu/availability-price/custom-scheduling", ["exports", "lh4/utils/date/dates-overlap"], function (_exports, _datesOverlap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    location: Ember.inject.service(),
    initialScheduleCreated: false,
    classNames: ['custom-scheduling-container', 'stretched', 'column', 'sixteen', 'wide'],
    dayOfWeekOptions: [{
      id: 'isOnMonday',
      name: 'Monday'
    }, {
      id: 'isOnTuesday',
      name: 'Tuesday'
    }, {
      id: 'isOnWednesday',
      name: 'Wednesday'
    }, {
      id: 'isOnThursday',
      name: 'Thursday'
    }, {
      id: 'isOnFriday',
      name: 'Friday'
    }, {
      id: 'isOnSaturday',
      name: 'Saturday'
    }, {
      id: 'isOnSunday',
      name: 'Sunday'
    }],
    activePriceSchedules: Ember.computed('schedules', 'schedules.@each.isVoided', function getter() {
      return this.schedules.filterBy('isVoided', false).sortBy('scheduleNumber');
    }),
    validateSchedules: function validateSchedules() {
      var _this = this;

      this.setScheduleValidation(false);
      this.set('noScheduleProvidedError', false);
      var schedules = this.activePriceSchedules;

      if (!schedules.length) {
        this.set('noScheduleProvidedError', true);
        this.setScheduleValidation(true);
        return;
      } // We just assume that the first schedule is the source of truth in case of overlaps


      var correctSchedule = schedules[0]; // Reset temporary messages

      schedules.forEach(function (s) {
        return s.set('overlapErrorMessage', null);
      });

      for (var i = 0; i < schedules.length; i += 1) {
        var _loop = function _loop(j) {
          var schedule1 = schedules[i];
          var schedule2 = schedules[j]; // Do not validate against correct schedule & itself

          if (schedule2 !== correctSchedule && schedule1 !== schedule2) {
            // normally an if(this.dayOfWeekOptions) should be here, however,
            // this.dayOfWeekOptions is hard coded at the top and is not changed.
            // though the complexity is small, not adding it now.
            _this.dayOfWeekOptions.forEach(function (day) {
              if (!(schedule1.get('daysOfWeek').includes(day.id) && schedule2.get('daysOfWeek').includes(day.id))) {
                return;
              }

              try {
                if ((0, _datesOverlap.default)(schedule1.get('beginTimeMilliseconds'), schedule1.get('endTimeMilliseconds'), schedule2.get('beginTimeMilliseconds'), schedule2.get('endTimeMilliseconds'))) {
                  schedule2.set('overlapErrorMessage', "Schedule \"".concat(schedule2.get('scheduleName'), "\" overlaps schedule \"").concat(schedule1.get('scheduleName'), "\""));

                  _this.setScheduleValidation(true);
                }
              } catch (error) {// Do nothing, start date must be before end date - validation fails
                // Helper throws because it can't determine the correct result
                // And ember validator will handle this
              }
            });
          }
        };

        // We only want to compare schedules once between each other, so we reduce potential checks here
        for (var j = i + 1; j < schedules.length; j += 1) {
          _loop(j);
        }
      }
    },
    actions: {
      onDayOfWeekChange: function onDayOfWeekChange(record, value) {
        record.set('daysOfWeek', value);
        this.validateSchedules();
      },
      onTimePickerChange: function onTimePickerChange(record, value, property) {
        // do nothing if values are the same as this makes changeset dirty even on initial load
        // also for performance reasons, as validateSchedules gets called even if there were no changes
        if (record.get(property) === value) {
          return;
        }

        if (property === 'beginTimeMilliseconds' && record.get('invalidBeginTime')) {
          record.set('invalidBeginTime', false);
        }

        if (property === 'endTimeMilliseconds' && record.get('invalidEndTime')) {
          record.set('invalidEndTime', false);
        }

        record.set(property, value);
        this.validateSchedules();
      },
      addSchedule: function addSchedule() {
        var activeScheduleNames = [];
        var nextScheduleNumber = 1;
        this.schedules.forEach(function (changeset) {
          var scheduleNumber = changeset.get('scheduleNumber');

          if (scheduleNumber >= nextScheduleNumber) {
            nextScheduleNumber = scheduleNumber + 1;
          }

          if (!changeset.get('isVoided')) {
            activeScheduleNames.push(changeset.get('scheduleName'));
          }
        });
        var nextScheduleName = '';

        for (var i = activeScheduleNames.length + 1; i > 0; i -= 1) {
          if (!activeScheduleNames.includes("Schedule ".concat(i))) {
            nextScheduleName = "Schedule ".concat(i);
          }
        }

        var newSchedule = this.store.createRecord('echo-pro/availability-price/schedule', {
          guid: null,
          locationId: this.location.model.id,
          scheduleNumber: nextScheduleNumber,
          scheduleName: nextScheduleName
        });
        var changeset = this.buildChildChangeset(newSchedule);
        this.schedules.pushObject(changeset);
        this.validateSchedules();
      },
      deleteSchedule: function deleteSchedule(changeset) {
        // If record was added and deleted before hitting 'Save' - remove it altogether
        if (changeset.data.isNew) {
          this.schedules.removeObject(changeset);
        } else {
          changeset.set('isVoided', true);
        }

        this.validateSchedules();
      }
    }
  });

  _exports.default = _default;
});