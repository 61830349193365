define("lh4/validators/break-duration", ["exports", "ember-cp-validations/validators/base", "moment"], function (_exports, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var BreakDuration = _base.default.extend({
    validate: function validate(value, options, model) {
      var clockedIn = model.get('clockedInAt');
      var clockedOut = model.get('clockedOutAt');
      var shiftClockedOut = clockedOut && (0, _moment.default)(clockedIn).isBefore(clockedOut);

      if (shiftClockedOut ? _moment.default.duration((0, _moment.default)(clockedOut).diff(clockedIn)).asSeconds() < value : _moment.default.duration((0, _moment.default)().diff(clockedIn)).asSeconds() < value) {
        return 'Break duration can\'t be longer than shift duration';
      }

      return true;
    }
  });

  BreakDuration.reopenClass({
    getDependentsFor: function getDependentsFor() {
      return ['model.clockedInAt', 'model.clockedOutAt'];
    }
  });
  var _default = BreakDuration;
  _exports.default = _default;
});