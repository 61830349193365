define("lh4/templates/authenticated/launch-control/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "srD2sT4t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"launch-control\"],[10,\"data-dd-action-name\",\"Launch Control\"],[8],[0,\"\\n\\t\"],[5,\"launch-control/order-tracker/order-tracker\",[],[[\"@orderStatus\",\"@goLiveDate\",\"@launchControlModules\",\"@setFilter\",\"@selectedFilter\",\"@filters\",\"@cards\",\"@csmInfo\"],[[24,[\"model\",\"orderStatus\"]],[24,[\"model\",\"goLiveDate\"]],[24,[\"model\",\"launchControlModules\"]],[23,0,[\"setFilter\"]],[23,0,[\"selectedFilter\"]],[24,[\"model\",\"filters\"]],[24,[\"model\",\"cards\"]],[24,[\"model\",\"csmInfo\"]]]]],[0,\"\\n\\t\"],[5,\"launch-control/carousel\",[],[[\"@selectedFilter\",\"@cards\",\"@goLiveDate\"],[[23,0,[\"selectedFilter\"]],[24,[\"model\",\"cards\"]],[24,[\"model\",\"goLiveDate\"]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/launch-control/index.hbs"
    }
  });

  _exports.default = _default;
});