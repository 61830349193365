define("lh4/metrics-adapters/google-analytics-with-custom-dimensions", ["exports", "ember-metrics/metrics-adapters/google-analytics"], function (_exports, _googleAnalytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _googleAnalytics.default.extend({
    toStringExtension: function toStringExtension() {
      return 'GoogleAnalyticsWithCustomDimensions';
    },
    dimensions: function dimensions() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (window.ga) {
        Object.keys(options).forEach(function (key) {
          if (options[key]) {
            window.ga('set', key, options[key]);
          }
        });
      }
    }
  });

  _exports.default = _default;
});