define("lh4/components/ui/shared/yield-basic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['defaultClassNames'],
    defaultClassNames: ''
  });

  _exports.default = _default;
});