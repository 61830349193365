define("lh4/adapters/corporate-group", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var corpId = snapshot.adapterOptions.corpId;
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(corpId, "/groups/").concat(id);
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var corpId = snapshot.record.corpId;
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(corpId, "/groups");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var corpId = snapshot.record.corpId;
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(corpId, "/groups/").concat(id);
    },
    urlForQuery: function urlForQuery(query) {
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(query.corporation, "/groups");
    }
  });

  _exports.default = _default;
});