define("lh4/templates/components/ui/icons/internet-payments/finance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BKF17lE8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"currentColor\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"data-test-icon\",\"finance\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M21 7.28V5C21 3.9 20.1 3 19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V16.72C21.59 16.37 22 15.74 22 15V9C22 8.26 21.59 7.63 21 7.28ZM20 9V15H13V9H20ZM5 19V5H19V7H13C11.9 7 11 7.9 11 9V15C11 16.1 11.9 17 13 17H19V19H5Z\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M16 13.5C16.8284 13.5 17.5 12.8284 17.5 12C17.5 11.1716 16.8284 10.5 16 10.5C15.1716 10.5 14.5 11.1716 14.5 12C14.5 12.8284 15.1716 13.5 16 13.5Z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/finance.hbs"
    }
  });

  _exports.default = _default;
});