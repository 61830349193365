define("lh4/templates/forgot-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GJ4C4W71",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"recover-account-form\",null,[[\"title\",\"errorMessage\",\"onCompleted\"],[\"Recover Account\",\"An error occurred while recovering your account\",[28,\"action\",[[23,0,[]],\"redirectToLogin\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/forgot-password.hbs"
    }
  });

  _exports.default = _default;
});