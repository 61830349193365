define("lh4/serializers/reports/subscription-recipient", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return this._super("reports/".concat(payloadKey));
    },
    keyForRelationship: function keyForRelationship() {
      return 'reportSubscriptionId';
    },
    attrs: {
      reportSubscription: {
        serialize: 'id',
        deserialize: 'id'
      }
    }
  });

  _exports.default = _default;
});