define("lh4/mixins/controllers/customer-engagement/edit-controller-mixin", ["exports", "ember-data", "jquery", "lh4/mixins/edit-controller-mixin", "lh4/mixins/controllers/discard-changes-mixin", "lh4/config/environment"], function (_exports, _emberData, _jquery, _editControllerMixin, _discardChangesMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_editControllerMixin.default, _discardChangesMixin.default, {
    flashMessagesHelper: Ember.inject.service(),
    ajax: Ember.inject.service(),
    modelName: 'model.campaign',
    modelNameToTrack: 'model.campaign',
    step: 0,
    saveLocationSettings: false,
    selectedSettings: null,
    canGoForward: Ember.computed('model.campaign.validations.errors.[]', 'model.campaign.manualCustomers', 'step', function getter() {
      switch (this.get('step')) {
        case 0:
          return this.get('model.campaign.validations.attrs.type.isValid');

        case 1:
          return this.get('model.campaign.validations.attrs.name.isValid') && this.get('model.campaign.validations.attrs.subject.isValid') && this.get('model.campaign.validations.attrs.aliasName.isValid') && this.get('model.campaign.validations.attrs.aliasEmail.isValid') && this.get('model.campaign.validations.attrs.type.isValid') && this.get('model.campaign.validations.attrs.status.isValid') && this.get('model.campaign.validations.attrs.locationId.isValid');

        case 2:
          return this.get('model.campaign.validations.attrs.scheduleType.isValid') && (this.get('model.campaign.audience') === 'GROUPS' ? this.get('model.campaign.manualCustomers.length') > 0 : true);

        default:
          return true;
      }
    }),
    preview: Ember.computed('step', 'model.campaign', function getter() {
      var _this = this;

      if ([3, 4].every(function (step) {
        return step !== _this.get('step');
      })) {
        return '';
      }

      var url = "".concat(_environment.default.host, "/api/v1/customer-engagement/campaign-templates/preview");

      var _ref = this.model.campaign || {},
          backgroundColor = _ref.backgroundColor,
          bodyText = _ref.bodyText,
          facebookUrl = _ref.facebookUrl,
          headerText = _ref.headerText,
          locationId = _ref.locationId,
          twitterUrl = _ref.twitterUrl,
          imageUrl = _ref.imageUrl,
          type = _ref.type;

      var qs = _jquery.default.param({
        backgroundColor: backgroundColor,
        bodyText: bodyText,
        facebookUrl: facebookUrl,
        headerText: headerText,
        locationId: locationId,
        token: this.get('tokenData.data.token'),
        twitterUrl: twitterUrl,
        imageUrl: imageUrl,
        type: type
      });

      return _emberData.default.PromiseObject.create({
        promise: this.ajax.request("".concat(url, "?").concat(qs), {
          dataType: 'text'
        })
      });
    }),
    actions: {
      goForward: function goForward() {
        if (!this.get('canGoForward')) {
          return;
        }

        this.incrementProperty('step');
      },
      goBackward: function goBackward() {
        if (this.get('step') === 0) {
          return this.transitionToRoute('authenticated.customer-engagement');
        }

        this.decrementProperty('step');
        return false;
      },
      saveAs: function saveAs(status) {
        var campaign = this.get('model.campaign');
        campaign.set('status', status);
        this.send('beforeSave');
      },
      beforeSave: function beforeSave() {
        var _this2 = this;

        if (this.get('model.campaign.useAliasEmail') && this.get('saveLocationSettings')) {
          var settings = this.get('selectedSettings');
          settings.set('aliasName', this.get('model.campaign.aliasName'));
          settings.set('aliasEmail', this.get('model.campaign.aliasEmail'));
          settings.save().then(function () {
            _this2.send('save');
          });
        } else {
          this.send('save');
        }
      },
      afterSaveHook: function afterSaveHook() {
        this.transitionToRoute('authenticated.customer-engagement');
      },
      beforeDelete: function beforeDelete() {
        (0, _jquery.default)('#delete-campaign').modal('show');
      },
      delete: function _delete() {
        var _this3 = this;

        this.set('isLoading', true);
        this.get('model.campaign').destroyRecord().then(function () {
          _this3.transitionToRoute('authenticated.customer-engagement');
        }).finally(function () {
          _this3.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});