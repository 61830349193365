define("lh4/templates/components/social-media/account-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vuplNg7i",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"account\",\"isConfiguredProperly\"]],[24,[\"src\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"img\",true],[10,\"class\",\"ui rounded image\"],[11,\"src\",[29,[[22,\"src\"]]]],[11,\"width\",[29,[[22,\"width\"]]]],[11,\"height\",[29,[[22,\"height\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"img\",true],[10,\"class\",\"ui rounded image\"],[11,\"width\",[29,[[22,\"width\"]]]],[11,\"height\",[29,[[22,\"height\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[7,\"i\",true],[10,\"class\",\"tiny icons sm-icons\"],[8],[0,\"\\n\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"circle icon \",[22,\"typeBackground\"]]]],[8],[9],[0,\"\\n\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"fitted tiny icon \",[22,\"typeIcon\"]]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/social-media/account-image.hbs"
    }
  });

  _exports.default = _default;
});