define("lh4/templates/components/internet-payments/ui/filter-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KCLYh0B6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"args\",\"isMobile\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"button\",false],[12,\"class\",\"circular-button secondary\"],[12,\"tabindex\",\"0\"],[12,\"data-test-ip\",\"filter-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"handleClick\"]]]],[8],[0,\"\\n\\t\\t\"],[1,[22,\"ui/icons/internet-payments/filter\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"button\",false],[12,\"class\",\"ui button blue filter-button no-border flex\"],[12,\"tabindex\",\"0\"],[12,\"data-test-ip\",\"filter-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"handleClick\"]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"filter-button-wrapper\"],[10,\"data-test-ip\",\"filter-button-wrapper\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"ui/icons/internet-payments/filter\"],false],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"filter-text\"],[8],[0,\"FILTERS\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/filter-button.hbs"
    }
  });

  _exports.default = _default;
});