define("lh4/controllers/authenticated/customer-engagement/campaigns/create", ["exports", "lh4/mixins/controllers/customer-engagement/edit-controller-mixin"], function (_exports, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {
    metrics: Ember.inject.service(),
    actions: {
      afterSaveHook: function afterSaveHook(record) {
        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'Customer Engagement',
          eventAction: 'Campaign created',
          eventLabel: record.type
        });
        this.transitionToRoute('authenticated.customer-engagement');
      }
    }
  });

  _exports.default = _default;
});