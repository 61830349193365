define("lh4/utils/get-max-item-modifier-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMaxItemModifierError;

  /**
   * Return the HTML for the error message when an item has
   * more than the max number of modifiers selected.
   *
   * @param {Array} errorItems - array of items that have more than the max number of modifiers selected
   * @param {number} maxValue - the max number of modifiers allowed
   * @returns {string} HTML for the error message
   */
  function getMaxItemModifierError(errorItems, maxValue) {
    var generateItemUrl = function generateItemUrl(_ref) {
      var guid = _ref.guid;
      return "/epro/menu/items/".concat(guid);
    };

    var pluralizedModifier = maxValue === 1 ? 'modifier' : 'modifiers';
    return "\n\t\t<div class=\"modifier-set-max-validation\">\n\t\t\t<p>Some items already have more than ".concat(maxValue, " ").concat(pluralizedModifier, " selected as default:</p>\n\t\t\t<ul>\n\t\t\t\t").concat(errorItems.map(function (item) {
      return "\n\t\t\t\t\t<li>\n\t\t\t\t\t\t<a href=\"".concat(generateItemUrl(item), "\" target=\"_blank\" rel=\"noopener\">").concat(item.name, "</a>\n\t\t\t\t\t</li>\n\t\t\t\t");
    }).join(''), "\n\t\t\t</ul>\n\t\t</div>\n\t");
  }
});