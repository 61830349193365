define("lh4/components/hospitality/customize-menu/items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    items: Ember.computed('showInactiveItems', 'menuCategory.menuItemsCategories', 'menuItems', 'menuCategory', function getter() {
      if (this.get('menuCategory') === 'null') {
        var menuItems = this.get('menuItems');
        return !this.get('showInactiveItems') ? menuItems.filterBy('isActive', true) : menuItems.filterBy('isLoaded', true);
      }

      if (!this.get('menuCategory.menuItemsCategories')) {
        return [];
      }

      var itemsAfterCats = this.get('menuCategory.menuItemsCategories').map(function (item) {
        return item.menuItemId;
      });
      var filteredArray = this.get('menuItems').filter(function (x) {
        return itemsAfterCats.includes(x.get('menuItemId'));
      }).sortBy('name');
      return !this.get('showInactiveItems') ? filteredArray.filterBy('isActive', true) : filteredArray;
    })
  });

  _exports.default = _default;
});