define("lh4/controllers/authenticated/bank-account/credentials", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_FILE_SIZE = 1024 * 1024 * 2; // 2MB

  var _default = _generalFormController.default.extend({
    location: Ember.inject.service(),
    ajax: Ember.inject.service(),
    bankAccount: Ember.inject.service('bank-account'),
    file: null,
    transitionToError: function transitionToError(message) {
      this.changeset.rollback();
      this.bankAccount.set('errorMessage', message);
      this.transitionToRoute('authenticated.bank-account.error');
    },
    uploadFileError: function uploadFileError(message) {
      var _this = this;

      this.set('serverError', message);
      this.set('isSaving', false);
      this.set('serverErrorTimeoutId', Ember.run.later(this, function () {
        _this.set('serverError', null);
      }, this.serverErrorTimeoutDelay));
    },
    actions: {
      uploadFile: function uploadFile(file) {
        this.set('file', file);
      },
      close: function close() {
        this.transitionToRoute('authenticated.locations.location.overview', this.location.model.id);
      },
      submit: function submit() {
        var _this2 = this;

        this.set('showErrors', true);
        this.set('isSaving', true);

        if (this.changeset.get('rfiType') !== 'contact-info') {
          if (!this.file) {
            return this.uploadFileError('Please select a file to upload.');
          }

          if (this.file.size > MAX_FILE_SIZE) {
            this.set('file', null);
            return this.uploadFileError('File size too large.  Please select a file smaller than 2MB.');
          }
        }

        this.changeset.validate().then(function () {
          if (_this2.changeset.isInvalid) {
            return false;
          }

          var data = new FormData();
          data.append('name', _this2.changeset.get('name'));
          data.append('phone', _this2.changeset.get('phone'));
          data.append('title', _this2.changeset.get('title'));
          data.append('file', _this2.file);
          data.append('context', _this2.changeset.get('context'));
          data.append('salt', _this2.changeset.get('salt'));

          _this2.ajax.request("/api/v1/locations/".concat(_this2.location.model.id, "/bank-account/upload"), {
            method: 'POST',
            contentType: false,
            processData: false,
            data: data
          }).then(function () {
            _this2.set('file', null);

            _this2.changeset.rollback();

            _this2.transitionToRoute('authenticated.bank-account.success');

            return Ember.RSVP.resolve();
          }).catch(function (error) {
            _this2.transitionToError(error.payload.error.message);
          });

          return false;
        }).finally(function () {
          _this2.set('isSaving', false);
        });
        return false;
      }
    }
  });

  _exports.default = _default;
});