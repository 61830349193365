define("lh4/templates/components/reputation/review-respond-form-owner-reply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pTIidTZ8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showUpdateOwnerReply\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui basic segment\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"reputation/review-respond-form\",null,[[\"label\",\"respondButtonText\",\"responseText\",\"account\",\"selectedReview\",\"onReplySuccess\",\"onDiscard\"],[\"Edit your comment\",\"Update\",[28,\"readonly\",[[24,[\"selectedReview\",\"ownerReply\",\"comment\"]]],null],[24,[\"account\"]],[24,[\"selectedReview\"]],[28,\"action\",[[23,0,[]],\"onUpdateSuccess\"],null],[28,\"action\",[[23,0,[]],\"toggleUpdateOwnerReply\"],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui basic segment owner-response-header\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[8],[0,\"Owner response\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[4,\"reputation/post-preview\",null,[[\"userName\",\"date\",\"comment\"],[\"Owner\",[24,[\"selectedReview\",\"ownerReply\",\"createdAt\"]],[24,[\"selectedReview\",\"ownerReply\",\"comment\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui basic segment owner-response-buttons\"],[8],[0,\"\\n\\t\\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"toggleUpdateOwnerReply\"]],[8],[0,\"Edit\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reputation/review-respond-form-owner-reply.hbs"
    }
  });

  _exports.default = _default;
});