define("lh4/controllers/authenticated/internet-payments/customers/details/create-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    actions: {
      handleIframeLoad: function handleIframeLoad() {
        this.set('hasLoaded', true);
      },
      onClose: function onClose() {
        this.transitionToRoute(this.router.currentRoute.parent.name);
      }
    }
  });

  _exports.default = _default;
});