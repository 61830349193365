define("lh4/templates/authenticated/reputation/accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5QC3IBhx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segment controls\"],[8],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary\",[28,\"if\",[[24,[\"loading\"]],\" loading\"],null],\" button\"]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"addSocialNetworkDialog\",[24,[\"selectedLocation\"]]]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"plus icon\"],[8],[9],[0,\"\\n\\t\\tNew Account\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"reputation-tabs\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"reputation/accounts-table\",null,[[\"limit\",\"offset\",\"location\",\"accounts\",\"filterChanged\",\"selectAccount\",\"deleteAccount\"],[[24,[\"limit\"]],[24,[\"offset\"]],[24,[\"selectedLocation\"]],[24,[\"model\",\"accounts\"]],[28,\"action\",[[23,0,[]],\"filterChanged\"],null],[28,\"action\",[[23,0,[]],\"selectAccount\"],null],[28,\"action\",[[23,0,[]],\"deleteAccount\"],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/reputation/accounts/index.hbs"
    }
  });

  _exports.default = _default;
});