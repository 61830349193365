define("lh4/models/dashboard/financial-overview/index", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    total: _emberData.default.attr('number'),
    location: Ember.computed(function getter() {
      return this.store.peekRecord('location', this.get('id'));
    })
  });

  _exports.default = _default;
});