define("lh4/services/user-idle", ["exports", "ember-user-activity/services/user-idle"], function (_exports, _userIdle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userIdle.default.extend({
    IDLE_TIMEOUT: 1800000 // 30 minutes

  });

  _exports.default = _default;
});