define("lh4/components/updates/location-deploy-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['location-overview-table'],
    locationOverviewHeaders: [{
      name: null,
      width: 'one'
    }, {
      name: 'Location',
      width: 'six'
    }, {
      name: 'Location Status',
      width: 'three'
    }],
    router: Ember.inject.service()
  });

  _exports.default = _default;
});