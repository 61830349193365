define("lh4/templates/components/ui/header-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ftHsyAW5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"headerText\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"tablet center-aligned header item left-header\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"headerText\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[14,1,[[28,\"hash\",null,[[\"headerItem\"],[[28,\"component\",[\"ui/header-menu/header-item\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]}],[7,\"div\",true],[11,\"class\",[29,[\"ui \",[22,\"secondaryHeaderClass\"],\" secondary menu\"]]],[8],[0,\"\\n\\t\"],[14,1,[[28,\"hash\",null,[[\"secondaryItem\"],[[28,\"component\",[\"ui/header-menu/secondary-item\"],null]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/header-menu.hbs"
    }
  });

  _exports.default = _default;
});