define("lh4/constants/launch-control/modules/installation-details", ["exports", "lh4/utils/permissions", "lh4/utils/states"], function (_exports, _permissions, _states) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_ERROR_MESSAGES = _exports.DATE_FORMAT = _exports.CONTACTS_ERRORS = _exports.ADMIN_PERMISSIONS = _exports.MANAGER_PERMISSIONS = _exports.VALID_STATES = _exports.STREET_ADDRESS_DELIMITER = _exports.USER_ROLE = void 0;
  var USER_ROLE = Object.freeze({
    MANAGER: 'Manager',
    // owner
    ADMIN: 'Admin' // sub-user

  });
  _exports.USER_ROLE = USER_ROLE;
  var STREET_ADDRESS_DELIMITER = ', ';
  _exports.STREET_ADDRESS_DELIMITER = STREET_ADDRESS_DELIMITER;

  var VALID_STATES = _states.STATES.map(function (state) {
    return state.abbr;
  }); // Permissions sets are defined here -> https://docs.google.com/spreadsheets/d/1-seOUVzrL1paJb4zgiAytOlNxkXbzKYzvVKpDoTxqt4/edit#gid=0


  _exports.VALID_STATES = VALID_STATES;
  var MANAGER_PERMISSIONS = [// Loyalty
  _permissions.default.LOYALTY.MANAGE_PROMOTIONS, _permissions.default.LOYALTY.MANAGE_DISCOUNTS, // Reporting
  _permissions.default.REPORTING.REPORTS, // Transactions
  _permissions.default.TRANSACTIONS.VIEW_TRANSACTIONS, _permissions.default.TRANSACTIONS.CREATE_TRANSACTIONS, _permissions.default.TRANSACTIONS.REFUND_TRANSACTIONS, // Dashboard
  _permissions.default.DASHBOARD.VIEW_DASHBOARD, // Old Dashboard
  _permissions.default.OLD_DASHBOARD.PAYMENT_INFO, _permissions.default.OLD_DASHBOARD.PROCESSING_DETAIL, _permissions.default.OLD_DASHBOARD.SALES_INFO, _permissions.default.OLD_DASHBOARD.TICKET_INFO, // Location Overview
  _permissions.default.LOCATION_OVERVIEW.BATCH_HISTORY, _permissions.default.LOCATION_OVERVIEW.LABOR_PERCENTAGE, _permissions.default.LOCATION_OVERVIEW.EMPLOYEE_TIMECLOCK, // Marketplace
  _permissions.default.MARKETPLACE.ACCESS, // Disputes
  _permissions.default.DISPUTES.SUBMIT_DISPUTE_INFO, _permissions.default.DISPUTES.VIEW_DISPUTE, _permissions.default.DISPUTES.VIEW_SUBMITTED_INFO, // InCharge App
  _permissions.default.IN_CHARGE_APP.ACCESS_LIGHTHOUSE_MOBILE_APP, // Hospitality
  _permissions.default.HOSP.RESERVATIONS, _permissions.default.HOSP.EMPLOYEES, _permissions.default.HOSP.ONLINE_ORDERING, // SkyTab POS
  _permissions.default.HBR.EMPLOYEES_AND_JOBS, _permissions.default.HBR.TABLE_LAYOUT, _permissions.default.HBR.ONLINE_ORDERING, _permissions.default.HBR.EMPLOYEE_SCHEDULING, _permissions.default.HBR.RESERVATIONS, _permissions.default.HBR.PAYMENTS, _permissions.default.HBR.CUSTOMERS, _permissions.default.HBR.TABLESIDE, // Echo Pro
  _permissions.default.ECHO.POS, // Salon
  _permissions.default.SALON.POS, // SkyTab Mobile
  _permissions.default.SKYTAB.ACCESS, // Orders
  _permissions.default.ORDERS.VIEW_POS_ORDER, _permissions.default.ORDERS.VIEW_SUPPLY_ORDER, // configuration
  _permissions.default.CONFIGURATION.MANAGE_SETTINGS, // Users
  _permissions.default.USERS.MANAGE_USERS];
  _exports.MANAGER_PERMISSIONS = MANAGER_PERMISSIONS;
  var ADMIN_PERMISSIONS = [// Loyalty
  _permissions.default.LOYALTY.MANAGE_PROMOTIONS, _permissions.default.LOYALTY.MANAGE_DISCOUNTS, _permissions.default.LOYALTY.MANAGE_PROGRAMS, _permissions.default.LOYALTY.MANAGE_MEMBERS, // Reporting
  _permissions.default.REPORTING.CREATE_OWN_SUBSCRIPTIONS, _permissions.default.REPORTING.MANAGE_USERS_SUBSCRIPTIONS, _permissions.default.REPORTING.REPORTS, // Transactions
  _permissions.default.TRANSACTIONS.VIEW_TRANSACTIONS, _permissions.default.TRANSACTIONS.CREATE_TRANSACTIONS, _permissions.default.TRANSACTIONS.REFUND_TRANSACTIONS, // Insights
  _permissions.default.INSIGHTS.VIEW_AUTH, _permissions.default.INSIGHTS.VIEW_CARD_VERIFY, _permissions.default.INSIGHTS.VIEW_CHARGEBACK, _permissions.default.INSIGHTS.VIEW_RISK, // Dashboard
  _permissions.default.DASHBOARD.VIEW_DASHBOARD, // Risk
  _permissions.default.RISK.MANAGE_BLOCK_LISTS, _permissions.default.RISK.MANAGE_RISK_RULES, _permissions.default.RISK.VIEW_RISK_RULES, // Developers
  _permissions.default.DEVELOPERS.MANAGE_DEVELOPER_SETTINGS, // Internet Payment disputes
  _permissions.default.INTERNET_PAYMENTS_DISPUTES.IP_SUBMIT_DISPUTE_EVIDENCE, _permissions.default.INTERNET_PAYMENTS_DISPUTES.IP_VIEW_DISPUTES, // Documents
  _permissions.default.DOCUMENTS.ACCOUNT_NOTICES, _permissions.default.DOCUMENTS.MONTHLY_CC_STATEMENTS, _permissions.default.DOCUMENTS.VIEW_TAX_DOCUMENTS, // Old Dashboard
  _permissions.default.OLD_DASHBOARD.PAYMENT_INFO, _permissions.default.OLD_DASHBOARD.PROCESSING_DETAIL, _permissions.default.OLD_DASHBOARD.SALES_INFO, _permissions.default.OLD_DASHBOARD.TICKET_INFO, // Location Overview
  _permissions.default.LOCATION_OVERVIEW.BATCH_HISTORY, _permissions.default.LOCATION_OVERVIEW.LABOR_PERCENTAGE, _permissions.default.LOCATION_OVERVIEW.EMPLOYEE_TIMECLOCK, // Market Place
  _permissions.default.MARKETPLACE.ACCESS, // Customer Database
  _permissions.default.PROCESSING.CUSTOMERS, // Dispute
  _permissions.default.DISPUTES.SUBMIT_DISPUTE_INFO, _permissions.default.DISPUTES.VIEW_DISPUTE, _permissions.default.DISPUTES.VIEW_SUBMITTED_INFO, // Business Settings
  _permissions.default.BUSINESS_SETTINGS.MANAGE_DAY_PARTS_AND_REVENUE_CENTERS, // InCharge
  _permissions.default.IN_CHARGE_APP.ACCESS_LIGHTHOUSE_MOBILE_APP, // Hospitality
  _permissions.default.HOSP.RESERVATIONS, _permissions.default.HOSP.EMPLOYEES, _permissions.default.HOSP.ONLINE_ORDERING, _permissions.default.HOSP.CHOICES_AND_CHOICE_SETS, _permissions.default.HOSP.ITEM_GROUPS, _permissions.default.HOSP.MENU, // SkyTab Pos
  _permissions.default.HBR.EMPLOYEES_AND_JOBS, _permissions.default.HBR.TABLE_LAYOUT, _permissions.default.HBR.ONLINE_ORDERING, _permissions.default.HBR.EMPLOYEE_SCHEDULING, _permissions.default.HBR.RESERVATIONS, _permissions.default.HBR.PAYMENTS, _permissions.default.HBR.CUSTOMERS, _permissions.default.HBR.TABLESIDE, _permissions.default.HBR.ACCOUNTING, _permissions.default.HBR.MENU, // Echo Pro POS
  _permissions.default.ECHO.POS, // Salon
  _permissions.default.SALON.POS, // SkyTab Mobile
  _permissions.default.SKYTAB.ACCESS, // Support
  _permissions.default.SUPPORT.SEND_SUPPORT_MESSAGES, _permissions.default.SUPPORT.SEND_TICKETS, _permissions.default.SUPPORT.VIEW_CONTACT_INFO, _permissions.default.SUPPORT.VIEW_DOCS, // Orders
  _permissions.default.ORDERS.VIEW_POS_ORDER, _permissions.default.ORDERS.VIEW_SUPPLY_ORDER, _permissions.default.ORDERS.PLACE_SUPPLY_ORDER, // Social Media
  _permissions.default.SOCIAL_MEDIA.MANAGE_ACCOUNTS, _permissions.default.SOCIAL_MEDIA.MANAGE_POSTS, // Reputation Management
  _permissions.default.REPUTATION_MANAGEMENT.MANAGE_ACCOUNTS, _permissions.default.REPUTATION_MANAGEMENT.MANAGE_REVIEWS, // Customer Engagement
  _permissions.default.CUSTOMER_ENGAGEMENT.ACCESS, // Configuration
  _permissions.default.CONFIGURATION.MANAGE_SETTINGS, // Users
  _permissions.default.USERS.MANAGE_USERS, _permissions.default.USERS.GRANT_MANAGE_USERS];
  _exports.ADMIN_PERMISSIONS = ADMIN_PERMISSIONS;
  var CONTACTS_ERRORS = Object.freeze({
    INVALID_PHONE: 'Please enter a valid, working phone number',
    INVALID_NAME: 'Please enter full name',
    INVALID_EMAIL: 'Please enter valid email address'
  });
  _exports.CONTACTS_ERRORS = CONTACTS_ERRORS;
  var DATE_FORMAT = 'MM/DD/YYYY';
  _exports.DATE_FORMAT = DATE_FORMAT;
  var DEFAULT_ERROR_MESSAGES = {
    contacts: 'There was an unexpected error saving contacts. Please try again later.',
    'shipping-address': 'There was an unexpected error saving shipping address. Please try again later.',
    'setup-date': 'There was an unexpected error saving setup date. Please try again later.',
    'module-completion': 'There was an unexpected completing the module. Please try again later.',
    fixHighlighted: 'Fix the highlighted errors to continue.'
  };
  _exports.DEFAULT_ERROR_MESSAGES = DEFAULT_ERROR_MESSAGES;
});