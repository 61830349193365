define("lh4/controllers/authenticated/internet-payments/account-settings", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    location: Ember.inject.service(),
    isAccountSettingsActive: _environment.default.internetPayments.accountSettings,
    decisionsIframeURL: Ember.computed('model.location.merchantId', 'model.token', function getter() {
      // the order of queryParams matters.....
      var queryParams = new URLSearchParams({
        FlowAlias: 'LHWelcomeMenu',
        sessionid: 'NS-Lighthouse',
        ForceFormat: true,
        MerchantID: this.model.location.merchantId,
        // this is returned as encoded, we must not encode this again
        Token: this.model.token,
        Location: 'TopLeft',
        Chrome: 'Off'
      });
      return "".concat(_environment.default.urls.decisions, "?").concat(queryParams.toString());
    })
  });

  _exports.default = _default;
});