define("lh4/components/echo-pro/tableside/device-setup-wizard/serial-number-step", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    validationError: Ember.computed('terminal.hardwareId', function getter() {
      var terminal = this.get('terminal');

      if (!terminal) {
        return false;
      }

      var _terminal$validate = terminal.validate({
        on: 'hardwareId'
      }, false),
          validations = _terminal$validate.validations;

      return validations.get('isValid') ? false : validations.get('message');
    }),
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('#wizard-serial-number-input').focus();
    },
    actions: {
      proceed: function proceed() {
        var validationError = this.get('validationError');

        if (validationError) {
          this.sendAction('onError', validationError);
          return;
        }

        this.sendAction('onProceed');
      },
      back: function back() {
        this.sendAction('onBack');
      }
    }
  });

  _exports.default = _default;
});