define("lh4/components/reports/echo-pro/ticket-detail-open", ["exports", "jquery", "lh4/utils/string-type"], function (_exports, _jquery, _stringType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @param {integer} type - from format type
   * @returns {string} - row name
   */
  function rowName(type) {
    switch (type) {
      case _stringType.STRING_TYPE.NUMBERS:
        {
          return 'orderNumber';
        }

      case _stringType.STRING_TYPE.LETTERS:
        {
          return 'employee';
        }

      case _stringType.STRING_TYPE.DATE_TIME:
        {
          return 'opened';
        }

      default:
        {
          return 'orderNumber';
        }
    }
  }

  var _default = Ember.Component.extend({
    details: null,
    header: null,
    isLoading: null,
    sortByMap: [{
      orderNumber: 'Ticket #'
    }, 'Order Type', 'Customer Name', 'Subtotal', {
      discountTotal: 'Discounts'
    }, {
      hiddenTaxTotal: 'Hidden Tax'
    }, {
      taxTotal: 'Tax'
    }, 'Grand Total', 'Employee', 'Opened'],
    currentPage: 1,
    ticketsPerPage: 10,
    displayedRows: Ember.computed('currentReport.rows.[]', function getter() {
      return this.get('currentReport.rows');
    }),
    pages: Ember.computed('currentReport.rows.[]', 'displayedRows', function getter() {
      var pages = [];
      var rows = this.get('displayedRows');
      var size = this.get('ticketsPerPage');
      var count = 0;

      while (rows.length > count) {
        pages.push(rows.slice(count, size + count));
        count += size;
      }

      return pages;
    }),
    tickets: Ember.computed('pages.[]', 'currentPage', function getter() {
      return this.get('pages')[this.get('currentPage') - 1];
    }),
    actions: {
      deployDetails: function deployDetails(item, header) {
        this.set('details', item[header]);
        this.set('header', header);
        (0, _jquery.default)('#itemModal').modal('show');
      },
      searchReport: function searchReport(searchText) {
        this.set('currentPage', 1);
        this.set('searchError', false);

        if (!searchText) {
          this.set('isLoading', false);
          return this.set('displayedRows', this.get('currentReport.rows'));
        }

        var formatType = (0, _stringType.default)(searchText);

        if (formatType === _stringType.STRING_TYPE.NOT_FOUND) {
          this.set('isLoading', false);
          this.set('displayedRows', []);
          this.set('searchError', true);
          return false;
        }

        var searchLower = searchText.toLowerCase();

        var orderNumberFilter = function orderNumberFilter(row) {
          return row[rowName(formatType)].toString().toLowerCase().includes(searchLower);
        };

        var paymentRefsFilter = function paymentRefsFilter(row) {
          var _row$ticketPayments;

          return row === null || row === void 0 ? void 0 : (_row$ticketPayments = row.ticketPayments) === null || _row$ticketPayments === void 0 ? void 0 : _row$ticketPayments.some( // payment[6] should be "reference text" column
          function (payment) {
            return payment[6].toLowerCase().includes(searchLower);
          });
        };

        var defaultFilter = function defaultFilter(row) {
          return orderNumberFilter(row) || paymentRefsFilter(row);
        };

        var filterFn = defaultFilter;

        if (formatType === _stringType.STRING_TYPE.NUMBERS) {
          // payment[4] should be "card last 4" column
          var paymentFilter = function paymentFilter(payment) {
            var _payment$;

            return ((_payment$ = payment[4]) === null || _payment$ === void 0 ? void 0 : _payment$.toString()) === searchLower;
          }; // Default filter covers the order number, payment filter covers last 4


          filterFn = function filterFn(row) {
            var _row$ticketPayments2;

            return defaultFilter(row) || (row === null || row === void 0 ? void 0 : (_row$ticketPayments2 = row.ticketPayments) === null || _row$ticketPayments2 === void 0 ? void 0 : _row$ticketPayments2.some(paymentFilter));
          };
        } // formatType = 1 is string -> Add cardholder name search


        if (formatType === _stringType.STRING_TYPE.LETTERS) {
          // payment[3] should be "card holder name" column
          var cardNameFilter = function cardNameFilter(payment) {
            var _payment$2;

            return (_payment$2 = payment[3]) === null || _payment$2 === void 0 ? void 0 : _payment$2.toString().toLowerCase().includes(searchLower);
          };

          filterFn = function filterFn(row) {
            var _row$ticketPayments3;

            return defaultFilter(row) || (row === null || row === void 0 ? void 0 : (_row$ticketPayments3 = row.ticketPayments) === null || _row$ticketPayments3 === void 0 ? void 0 : _row$ticketPayments3.some(cardNameFilter));
          };
        }

        this.set('displayedRows', this.get('currentReport.rows').filter(filterFn));
        this.set('isLoading', false);
        return false;
      }
    }
  });

  _exports.default = _default;
});