define("lh4/templates/authenticated/add-on-devices/printers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V96rYLvk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"printers\"],[8],[0,\"\\n\\t\"],[1,[28,\"flash-messages\",null,[[\"flashMessages\"],[[24,[\"flashMessages\"]]]]],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui yellow small message\"],[8],[0,\"\\n\\t\\tFor best results use an image that is PNG format, 1 bit (black and white), and maximum size of 600px wide and 600px tall\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui form padded stackable grid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide stretched column\"],[10,\"data-test-field\",\"receipt-printer-logo-label\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"ui/form-fields/image-upload-field\",null,[[\"label\",\"buttonText\",\"buttonId\",\"uploadImage\",\"removeImage\",\"isPreviewVisible\",\"full\",\"cropperOptions\",\"imageUrl\",\"data-test-receipt-printer-logo-screen-upload\"],[\"Logo Image\",\"Attach Photo\",\"receipt-printer-logo-image-upload-button\",[28,\"action\",[[23,0,[]],\"uploadReceiptPrinterLogo\"],null],[28,\"action\",[[23,0,[]],\"removeReceiptPrinterLogo\"],null],true,[28,\"hash\",null,[[\"width\",\"height\"],[600,600]]],[24,[\"cropperOptions\"]],[24,[\"printerLogoUrl\"]],true]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary button \",[28,\"if\",[[24,[\"isSaving\"]],\"loading\"],null]]]],[12,\"data-test-save-button\",\"\"],[12,\"disabled\",[28,\"not\",[[23,0,[\"isFormDirty\"]]],null]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"\\n\\t\\tSave Changes\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/add-on-devices/printers.hbs"
    }
  });

  _exports.default = _default;
});