define("lh4/mirage/factories/epro-menu-version", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    corpId: 1,
    userId: 1,
    name: function name() {
      return _faker.default.lorem.sentence(4);
    },
    scheduledFor: function scheduledFor() {
      return _faker.default.date.future();
    },
    createdAt: function createdAt() {
      return _faker.default.date.past();
    },
    updatedAt: function updatedAt() {
      return _faker.default.date.past();
    },
    notes: function notes() {
      return _faker.default.lorem.sentence(50);
    },
    menuLocationVersions: [{
      status: 'pending',
      location: {
        id: 1,
        merchantId: '0000000279',
        name: ' Test Location 2'
      }
    }, {
      status: 'pending',
      location: {
        id: 2,
        merchantId: '0000050011',
        name: 'Test Location'
      }
    }],
    isActive: true,
    status: 'complete'
  });

  _exports.default = _default;
});