define("lh4/mixins/components/ui/color-picker/color-picker-mapping", ["exports", "lh4/components/epro/menu/ui-helpers/color-picker-unset"], function (_exports, _colorPickerUnset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create({
    can: Ember.inject.service(),
    isColorPickerEnabled: Ember.computed(function f() {
      return this.can.can('view menu color picker in feature-flag');
    }),

    /**
     * Transforms the stylesheets prop into a colors array to be used by a color-picker component.
     * Prepends the unset option to the colors array.
     *
     * The following are implicit dependencies required for this Mixin Function
     * - this - An Ember Component
     * - stylesheets - A prop that is an array of epro.StposUiStylesheet objects
     */
    setColorsFromStylesheet: function setColorsFromStylesheet() {
      if (!this.isColorPickerEnabled) {
        return;
      }

      var stylesheets = this.stylesheets;

      if (stylesheets) {
        var colors = stylesheets.map(function (ss) {
          return ss.stylesheet.primary;
        });
        var colorsWithUnset = [_colorPickerUnset.default.color].concat(_toConsumableArray(colors));
        this.set('colors', colorsWithUnset);
      } else {
        this.set('colors', []);
      }
    }
  });

  _exports.default = _default;
});