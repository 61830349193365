define("lh4/helpers/space-to-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.spaceToDash = spaceToDash;
  _exports.default = void 0;

  /**
   * Replaces chars not belonging to the set of lower, upper and numeric letters with dashes
   * @param {String} value - the string to perform the replacement on
   * @returns {String} - new string with chars replaced
   */
  function spaceToDash(value) {
    return value.toString().replace(/[^a-zA-Z0-9]+/g, '-');
  }

  var _default = Ember.Helper.helper(spaceToDash);

  _exports.default = _default;
});