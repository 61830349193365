define("lh4/models/settings/user", ["exports", "ember-data", "ember-cp-validations", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberData, _emberCpValidations, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: {
      description: 'First Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    lastName: {
      description: 'Last Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    emailAddress: (0, _emberCpValidations.validator)('format', {
      allowBlank: false,
      type: 'email',
      description: 'Email'
    }),
    phoneNumber: (0, _emberCpValidations.validator)('phone-number', {
      allowBlank: false,
      type: 'phone',
      message: 'Please enter a valid phone number',
      description: 'Phone Number',
      disabled: Ember.computed('model.launchDarkly', function getter() {
        var _this$model$location;

        var hasInternetPaymentProduct = (_this$model$location = this.model.location) === null || _this$model$location === void 0 ? void 0 : _this$model$location.hasInternetPaymentProduct;
        var featureFlag = this.model.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.PHONE_NUMBER_IDENTIFY_CALLER];
        return !featureFlag || hasInternetPaymentProduct;
      })
    })
  });

  var _default = _emberData.default.Model.extend(Validations, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service('location'),
    createdAt: _emberData.default.attr('string'),
    emailAddress: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isLocked: _emberData.default.attr('boolean'),
    isRedeemed: _emberData.default.attr('boolean'),
    lastLoggedInAt: _emberData.default.attr('string'),
    lastLoginAttempt: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    lockedAt: _emberData.default.attr('string'),
    loginAttempts: _emberData.default.attr('number'),
    metadata: _emberData.default.attr(),
    middleName: _emberData.default.attr('string'),
    parentUserId: _emberData.default.attr('number'),
    phoneNumber: _emberData.default.attr('string'),
    preferences: _emberData.default.attr(),
    updatedAt: _emberData.default.attr('string'),
    walkthrough: _emberData.default.attr(),
    userPermissionMaps: _emberData.default.attr(),
    // Added these 2 attributes to match endpoints
    permissionSets: _emberData.default.attr(),
    email: _emberData.default.attr('string')
  });

  _exports.default = _default;
});