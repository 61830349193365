define("lh4/mirage/fixtures/integration/s4/event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EVENTS = [{
    id: '16963312-48eb-41a9-b4a3-690df09e70b7',
    date: '2023-01-20T00:00:00.000Z',
    environment: 'UAT',
    errorMessage: null,
    locationId: 1,
    name: 'SALE',
    requestBody: JSON.stringify({
      id: '16963312-48eb-41a9-b4a3-690df09e70b7',
      data: {
        amount: 1.00,
        card: {
          last4: '0923',
          expirationYear: 2025,
          expirationMonth: 6
        }
      }
    }),
    status: 'SUCCESS',
    statusCode: 200,
    webhookId: '2c58a8b8-ebc6-45aa-b580-8567cb3a0a13'
  }, {
    id: '1345e868-697b-4017-b313-5c09ef1d7284',
    date: '2023-01-21T00:00:00.000Z',
    environment: 'UAT',
    errorMessage: null,
    locationId: 1,
    name: 'REFUND',
    requestBody: JSON.stringify({
      id: '1345e868-697b-4017-b313-5c09ef1d7284',
      data: {
        amount: -10.00,
        card: {
          last4: '6323',
          expirationYear: 2025,
          expirationMonth: 2
        }
      }
    }),
    status: 'SUCCESS',
    statusCode: 200,
    webhookId: '2c58a8b8-ebc6-45aa-b580-8567cb3a0a13'
  }, {
    id: '5e16add1-6e83-4761-a273-0e71e3b3a92b',
    date: '2023-01-22T00:00:00.000Z',
    environment: 'UAT',
    errorMessage: JSON.stringify({
      id: '5e16add1-6e83-4761-a273-0e71e3b3a92b',
      error: 'Gateway timeout'
    }),
    locationId: 1,
    name: 'SALE',
    requestBody: JSON.stringify({
      id: '5e16add1-6e83-4761-a273-0e71e3b3a92b',
      data: {
        amount: 10.00,
        card: {
          last4: '3253',
          expirationYear: 2023,
          expirationMonth: 6
        }
      }
    }),
    status: 'FAIL',
    statusCode: 500,
    webhookId: '2c58a8b8-ebc6-45aa-b580-8567cb3a0a13'
  }];
  var _default = EVENTS;
  _exports.default = _default;
});