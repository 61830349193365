define("lh4/controllers/authenticated/disputes", ["exports", "lh4/controllers/general-list-controller", "moment", "lh4/config/environment", "lh4/utils/date-moment"], function (_exports, _generalListController, _moment, _environment, _dateMoment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember;

  var MAX_REPORT_PERIOD = 13;
  var DATE_FORMAT = 'YYYY-MM-DD';
  var DATE_RANGE_PRESETS = [_dateMoment.Period.TODAY, _dateMoment.Period.YESTERDAY, _dateMoment.Period.WEEK_TO_DATE, _dateMoment.Period.TWO_WEEKS_TO_DATE];
  var QUERY_PARAMS = ['cardNumber', 'cardType', 'caseType', 'datePrefix', 'disputeAmt', 'end', 'order', 'transAmt', 'start', 'status', 'caseId'];

  var _default = _generalListController.default.extend({
    headers: [{
      field: 'fileDate',
      name: 'File Date',
      sortable: true,
      type: 'date'
    }, {
      field: 'dueDate',
      name: 'Due Date',
      sortable: true,
      type: 'date'
    }, {
      field: 'status',
      name: 'Status',
      sortable: true
    }, {
      field: 'cardType',
      name: 'Card Brand',
      sortable: true
    }, {
      field: 'caseType',
      name: 'Case Type',
      sortable: true
    }, {
      field: 'caseId',
      name: 'Case #',
      sortable: true,
      type: 'number'
    }, {
      field: 'reasonCode',
      name: 'Reason',
      sortable: true
    }, {
      field: 'transDate',
      name: 'Transaction Date',
      sortable: true,
      type: 'date'
    }, {
      field: 'cardNumber',
      name: 'Card #',
      sortable: true,
      type: 'number'
    }, {
      field: 'disputeAmt',
      name: 'Dispute Amt',
      sortable: true,
      type: 'number'
    }, {
      field: 'transAmt',
      name: 'Transaction Amt',
      sortable: true,
      type: 'number'
    }],
    queryParams: QUERY_PARAMS,
    exportFormats: [{
      name: 'CSV',
      format: 'csv'
    }, {
      name: 'Excel',
      format: 'xlsx'
    }, {
      name: 'PDF',
      format: 'pdf'
    }],
    exportDefaultFileName: 'disputes',
    media: Ember.inject.service(),
    location: Ember.inject.service(),
    maxDays: MAX_REPORT_PERIOD,
    datePrefix: 'file',
    rangeTitle: null,
    ranges: Ember.computed('model.preferences.weekStart', function ranges() {
      return (0, _dateMoment.generateDateRanges)(DATE_RANGE_PRESETS, {
        weekStart: this.model.preferences.weekStart
      });
    }),
    filteredField: 'status',
    status: 'Awaiting Merchant Response',
    start: undefined,
    end: undefined,
    order: 'dueDate ASC',
    datePrefixOptions: [{
      name: 'File Date',
      value: 'file'
    }, {
      name: 'Transaction Date',
      value: 'trans'
    }],
    sortBy: Ember.computed('order', function getter() {
      return this.order.split(' ')[0];
    }),
    sort: Ember.computed('order', function getter() {
      return this.order.split(' ')[1].toLowerCase();
    }),
    sortAsc: Ember.computed('order', function getter() {
      return this.order.split(' ')[1].toLowerCase() === 'asc';
    }),
    from: Ember.computed('start', function getter() {
      if (this.start) {
        return (0, _moment.default)(this.start, DATE_FORMAT).startOf('day');
      }

      return (0, _moment.default)().startOf('day');
    }),
    to: Ember.computed('end', function getter() {
      if (this.end) {
        return (0, _moment.default)(this.end, DATE_FORMAT).endOf('day');
      }

      return (0, _moment.default)().endOf('day');
    }),
    exportUrl: (_Ember = Ember).computed.apply(_Ember, QUERY_PARAMS.concat([function getter() {
      var filter = this[this.filteredField] ? "&".concat(this.filteredField, "=").concat(this[this.filteredField]) : '';
      var startFilter = "".concat(this.datePrefix, "Start=").concat(this.start);
      var endFilter = "".concat(this.datePrefix, "End=").concat(this.end);
      var locationFilter = "location=".concat(this.location.model.id);
      return "".concat(_environment.default.host, "/api/v1/disputes?").concat(startFilter, "&").concat(endFilter, "&").concat(locationFilter, "&order=").concat(this.order).concat(filter);
    }])),
    setDates: function setDates(startMoment, endMoment) {
      this.setProperties({
        start: startMoment.format(DATE_FORMAT),
        end: endMoment.format(DATE_FORMAT)
      });
    },
    actions: {
      handleDatePrefixChange: function handleDatePrefixChange(datePrefix) {
        var _this = this;

        Ember.run.later(function () {
          // Give time for animation to close to prevent semantic ui error
          _this.set('datePrefix', datePrefix);
        }, 200);
      },
      onMobileRangeChange: function onMobileRangeChange(rangeTitle, start, end) {
        var startDate = start;
        var endDate = end;
        this.set('rangeTitle', rangeTitle);

        if (!startDate) {
          startDate = (0, _moment.default)();
          endDate = (0, _moment.default)();
        }

        this.setDates(startDate, endDate);
      },
      onFirstSelect: function onFirstSelect(instance, firstDate) {
        // Since we allow the user to select either the beginning or the end
        // of the date at the beginning of their selection we'll limit both
        // min and max date so they can get no more than 14 days in their selection
        var maxDate = _moment.default.min((0, _moment.default)(), firstDate.clone().add(MAX_REPORT_PERIOD, 'days'));

        var minDate = firstDate.clone().subtract(MAX_REPORT_PERIOD, 'days');
        instance.setMinDate(minDate.startOf('day'));
        instance.setMaxDate(maxDate.endOf('day'));
      },
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        // Reset minDate and maxDate so a new selection can be made
        instance.setMinDate(null);
        instance.setMaxDate((0, _moment.default)().endOf('day'));
        instance.hideDropdown();
        this.setDates(startDate, endDate);
      },
      onRangeSelect: function onRangeSelect(instance, range) {
        var startDate = range.startDate,
            endDate = range.endDate;
        instance.hideDropdown();
        this.setDates(startDate, endDate);
      },
      // TODO: LH-21801, extract or remove this copy pasta
      onDesktopBlur: function onDesktopBlur(_ref) {
        var view = _ref.view,
            target = _ref.target;
        var instance = view.$(target).data('calentim');
        var parts = target.value.split('-');
        var inputStart = (0, _moment.default)(parts[0].trim(), instance.options.format);
        var inputEnd = (0, _moment.default)(parts[1].trim(), instance.options.format); // validate dates entered and fallback to previous values if they're invalid

        var start = inputStart.isValid() ? inputStart.startOf('day') : instance.config.startDate;
        var end = inputEnd.isValid() ? inputEnd.endOf('day') : instance.config.endDate; // nothing changed, exit early

        if (start.isSame(instance.config.startDate) && end.isSame(instance.config.endDate)) {
          return instance.updateInput();
        }

        var maxDate = (0, _moment.default)().endOf('day'); // if start or end is after maxDate, set to today

        if (start.isAfter(maxDate)) {
          start = maxDate.clone().startOf('day');
        }

        if (end.isAfter(maxDate)) {
          end = maxDate;
        } // if start is after end, set start to the same day


        if (start.isAfter(end)) {
          start = end.clone().startOf('day');
        } // validate max report range


        var maxEnd = start.clone().add(MAX_REPORT_PERIOD, 'days');

        if (maxEnd.isAfter(maxDate)) {
          maxEnd = maxDate;
        }

        if (end.isAfter(maxEnd)) {
          end = maxEnd;
        } // remember ranges to check later if active range changed and deselect it


        var rangesBefore = JSON.stringify(instance.config.ranges); // update active ranges

        instance.config.ranges.forEach(function (aRange) {
          var range = aRange;
          range.selected = range.startDate.isSame(start, 'day') && range.endDate.isSame(end, 'day');
        });
        instance.setStart(start);
        instance.setEnd(end);
        instance.updateInput();
        this.setDates(start, end); // checks if current range changed and deselects
        // later is used, because user can blur by clicking on new range button

        Ember.run.later(function () {
          // ranges changed, unselect previous active range
          if (JSON.stringify(instance.config.ranges) !== rangesBefore) {
            instance.reDrawCalendars();
          }
        }, 200);
        return false;
      },
      handleFilteredFieldChange: function handleFilteredFieldChange(value) {
        this.set('filteredField', value);
      },
      searchFilterChanged: function searchFilterChanged(filters) {
        this.setProperties(filters);
      },
      filterChanged: function filterChanged(filter) {
        // Handled a bit differently than our echo pro handler since
        // these values are sent to the server as one property `order`
        var sortAsc = filter.sortAsc,
            sortBy = filter.sortBy;
        var sort = sortAsc ? 'asc' : 'desc';
        this.set('order', "".concat(sortBy, " ").concat(sort.toUpperCase()));
      },
      onExportError: function onExportError() {
        this.set('serverError', 'Unable to export case list.  Please try again.');
      },
      onExportSuccess: function onExportSuccess() {
        this.set('serverError', null);
      }
    }
  });

  _exports.default = _default;
});