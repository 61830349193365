define("lh4/mixins/routes/json-query-param-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    serializeQueryParam: function serializeQueryParam(value, key, defaultType) {
      var _this$queryParams, _this$queryParams$key;

      if (((_this$queryParams = this.queryParams) === null || _this$queryParams === void 0 ? void 0 : (_this$queryParams$key = _this$queryParams[key]) === null || _this$queryParams$key === void 0 ? void 0 : _this$queryParams$key.type) === 'json') {
        if (typeof value === 'string') {
          try {
            JSON.parse(value);
            return value; // already a json string, return as is
          } catch (e) {// do nothing
          }
        }

        return JSON.stringify(value);
      }

      return this._super(value, key, defaultType);
    },
    deserializeQueryParam: function deserializeQueryParam(value, key, defaultType) {
      var _this$queryParams2, _this$queryParams2$ke;

      if (((_this$queryParams2 = this.queryParams) === null || _this$queryParams2 === void 0 ? void 0 : (_this$queryParams2$ke = _this$queryParams2[key]) === null || _this$queryParams2$ke === void 0 ? void 0 : _this$queryParams2$ke.type) === 'json') {
        try {
          return JSON.parse(value);
        } catch (e) {
          return undefined;
        }
      }

      return this._super(value, key, defaultType);
    }
  });

  _exports.default = _default;
});