define("lh4/mirage/fixtures/processing-finaro-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PROCESSING_FINARO_REPORT_LIST = {
    options: [{
      subscribable: true,
      custom: false,
      isHidden: false,
      isPaginated: true,
      menchiesOnly: false,
      product: 'processing-finaro',
      slug: 'batch-details',
      name: 'Mock Batch Transaction Detail',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: false
      },
      params: {
        isSingleLocationReport: false,
        datePickerOptions: {
          hasMinutesDisabled: false,
          isDateTimeBusinessDay: true,
          isMaxDurationLimitingStart: true,
          singleDate: false,
          rangesEnabled: []
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        start: {
          type: 'date',
          date: true,
          time: true
        }
      },
      description: 'Batch details'
    }]
  };
  var _default = PROCESSING_FINARO_REPORT_LIST;
  _exports.default = _default;
});