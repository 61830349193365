define("lh4/templates/components/dashboard-widgets/ip-notifications/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5k91kB/r",
    "block": "{\"symbols\":[\"note\"],\"statements\":[[4,\"unless\",[[24,[\"loading\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"data\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"id\",\"ip-notifications-widget\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"data\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"component\",[[28,\"concat\",[\"dashboard-widgets/ip-notifications/types/\",[23,1,[\"type\"]]],null]],[[\"data\"],[[23,1,[\"data\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/ip-notifications/data.hbs"
    }
  });

  _exports.default = _default;
});