define("lh4/components/ui/search/list-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'right', 'icon', 'input', 'search'],
    previousTerm: null,
    maxLength: 255,
    searchTerm: null,
    proxy: null,
    termDebounce: null,
    autocomplete: null,
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.searchTerm !== this.previousTerm) {
        this.set('proxy', this.searchTerm);
      }

      this.set('previousTerm', this.searchTerm);
    },
    updateTerm: function updateTerm() {
      this.onUpdated(this.proxy);
      this.set('termDebounce', null);
    },
    actions: {
      searchTerm: function searchTerm(forceSearch) {
        if (forceSearch) {
          Ember.run.cancel(this.termDebounce);
          this.updateTerm();
        } else {
          this.set('termDebounce', Ember.run.debounce(this, this.updateTerm, 500));
        }
      },
      clearTerm: function clearTerm() {
        this.set('proxy', null);
        this.updateTerm();
      }
    }
  });

  _exports.default = _default;
});