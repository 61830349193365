define("lh4/components/echo-pro/tableside/terminals-table-row", ["exports", "lh4/models/echo-pro/terminal"], function (_exports, _terminal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_FOCUS_COLUMN = '.row-terminal-name-input';

  var rollbackEmptyAttributes = function rollbackEmptyAttributes(terminal) {
    var terminalNameValue = terminal.get('terminalName');
    var hardwareIdValue = terminal.get('hardwareId');

    if (!hardwareIdValue || !terminalNameValue) {
      // Since rollbackAttribute() is only available in canary build - so rollback all and set non-empty
      terminal.rollbackAttributes();

      if (terminalNameValue) {
        terminal.set('terminalName', terminalNameValue);
      }

      if (hardwareIdValue) {
        terminal.set('hardwareId', hardwareIdValue);
      }
    }
  };

  var _default = Ember.Component.extend({
    tagName: 'tr',
    terminal: null,
    selectedTerminal: null,
    isMobile: false,
    selectedFocusColumn: DEFAULT_FOCUS_COLUMN,
    isRowInEditMode: Ember.computed('selectedTerminal', function getter() {
      var _this = this;

      if (this.get('terminal') === this.get('selectedTerminal')) {
        Ember.run.schedule('afterRender', this, function () {
          return _this.$(_this.get('selectedFocusColumn')).focus();
        });
        return true;
      }

      return false;
    }),
    click: function click() {
      return false;
    },
    actions: {
      selectTerminal: function selectTerminal(columnName) {
        if (columnName && this.get('terminal.statusDisplay') === _terminal.STATUS_NEVER_SYNCED) {
          this.set('selectedFocusColumn', columnName);
        } else {
          this.set('selectedFocusColumn', DEFAULT_FOCUS_COLUMN);
        }

        this.sendAction('onSelect', this.get('terminal'));
      },
      deleteTerminal: function deleteTerminal() {
        this.sendAction('onDelete', this.get('terminal'));
      },
      saveTerminal: function saveTerminal() {
        var terminal = this.get('terminal');
        rollbackEmptyAttributes(terminal);
        this.sendAction('onSave', terminal);
      },
      discardChanges: function discardChanges() {
        this.sendAction('onDiscardChanges', this.get('terminal'));
      }
    }
  });

  _exports.default = _default;
});