define("lh4/controllers/authenticated/virtual-terminal/transactions/sale/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['businessDate', 'cardToken', 'tipAmount', 'total'],
    businessDate: null,
    cardToken: null,
    tipAmount: null,
    total: null,
    actions: {
      handleIframeLoad: function handleIframeLoad() {
        this.set('hasLoaded', true);
      },
      close: function close() {
        this.transitionToRoute('authenticated.virtual-terminal.transactions');
      }
    }
  });

  _exports.default = _default;
});