define("lh4/templates/authenticated/supply-orders/location/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E9U2hQ7p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\"],[1,[28,\"supply-orders/select-order\",null,[[\"model\",\"selectShipping\",\"submitOrder\",\"productTransition\",\"loadingShipping\",\"submitOrderLoading\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"selectShipping\"],null],[28,\"action\",[[23,0,[]],\"submitOrder\"],null],[28,\"action\",[[23,0,[]],\"productTransition\"],null],[24,[\"loadingShipping\"]],[24,[\"submitOrderLoading\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/supply-orders/location/order.hbs"
    }
  });

  _exports.default = _default;
});