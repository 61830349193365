define("lh4/routes/authenticated/support/contact", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    queryParams: {
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },
    requiredPermissions: ['view info in support', 'manage tickets in support'],
    titleToken: 'Support',
    can: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        tickets: this.store.query('support/ticket', {
          locations: this.modelFor('authenticated').locations.mapBy('id'),
          limit: params.limit,
          offset: params.offset,
          searchTerm: params.search
        })
      });
    }
  });

  _exports.default = _default;
});