define("lh4/routes/authenticated/locations/location/business-settings", ["exports", "moment-timezone", "lh4/mixins/authenticated-route-mixin", "lh4/utils/weekdays"], function (_exports, _momentTimezone, _authenticatedRouteMixin, _weekdays) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    store: Ember.inject.service(),
    settings: Ember.inject.service('echo-pro/settings'),
    businessSettingsService: Ember.inject.service('business-settings'),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var locationController = this.controllerFor('authenticated.locations.location');
      locationController.set('showLocationTabs', true);
      var skyTabPosPayPeriod = model.skyTabPosPayPeriod,
          _model$businessSettin = model.businessSettings,
          timezone = _model$businessSettin.timezone,
          gatewayEodTime = _model$businessSettin.gatewayEodTime,
          depositFundingTime = _model$businessSettin.depositFundingTime,
          skyTabPosEodTime = _model$businessSettin.skyTabPosEodTime,
          finalizeCreditCardPaymentsImmediatelyWithNoTip = _model$businessSettin.finalizeCreditCardPaymentsImmediatelyWithNoTip;
      controller.setProperties({
        timezoneAbbreviation: _momentTimezone.default.tz(timezone).format('z'),
        skyTabPosPayPeriod: skyTabPosPayPeriod,
        gatewayEodTime: gatewayEodTime,
        depositFundingTime: depositFundingTime,
        skyTabPosEodTime: skyTabPosEodTime,
        finalizeCreditCardPaymentsImmediatelyWithNoTip: finalizeCreditCardPaymentsImmediatelyWithNoTip
      });
    },
    beforeModel: function beforeModel() {
      if (this.can.cannot('view business-settings in business-setting')) {
        this.transitionTo('/');
      }
    },
    model: function model() {
      var locationId = this.location.model.id;
      return Ember.RSVP.hash({
        businessSettings: this.businessSettingsService.getBusinessSettings(locationId),
        skyTabPosPayPeriod: this.store.queryRecord('echo-pro/setting', {
          location: locationId,
          type: ['business']
        }).then(function (record) {
          var weekdays = (0, _weekdays.default)(record.business.get('startOfWeek'));
          return "".concat(weekdays[0], " through ").concat(weekdays[weekdays.length - 1]);
        })
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var locationController = this.controllerFor('authenticated.locations.location');
        locationController.set('showLocationTabs', false);
      }
    }
  });

  _exports.default = _default;
});