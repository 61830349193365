define("lh4/components/ui/lightbox-single", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['id'],
    classNames: ['lightbox-single'],
    active: false,
    willDestroyElement: function willDestroyElement() {
      if (this.get('active')) {
        (0, _jquery.default)(window).off('scroll.lightbox-single');
      }

      this._super.apply(this, arguments);
    },
    actions: {
      expand: function expand() {
        var _this = this;

        var offset = this.$('.lightbox-dimmer').offset();
        this.$('.lightbox-dimmer').css({
          top: "".concat(-1 * offset.top + this.$(window).scrollTop(), "px"),
          left: "".concat(-1 * offset.left, "px")
        });
        this.set('active', true);
        (0, _jquery.default)(window).on('scroll.lightbox-single', function () {
          _this.set('active', false);

          (0, _jquery.default)(window).off('scroll.lightbox-single');
        });
      },
      close: function close() {
        this.set('active', false);
      }
    }
  });

  _exports.default = _default;
});