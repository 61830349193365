define("lh4/components/tables/reservation-block-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    selectedTableAmount: Ember.computed('currentBlock.tableAssignments', function getter() {
      return this.get('currentBlock').get('tableAssignments')["".concat(this.table.value, "-top")] || 0;
    }),
    actions: {
      addTable: function addTable() {
        if (this.get('selectedTableAmount') < this.get('table').count) {
          // the way rollbackAttributes works requires this
          var copy = JSON.parse(JSON.stringify(this.get('currentBlock').get('tableAssignments')));
          copy["".concat(this.table.value, "-top")] = this.get('selectedTableAmount') + 1;
          this.get('currentBlock').set('tableAssignments', copy);
        }
      },
      removeTable: function removeTable() {
        if (this.get('selectedTableAmount') > 0) {
          var copy = JSON.parse(JSON.stringify(this.get('currentBlock').get('tableAssignments')));
          copy["".concat(this.table.value, "-top")] = this.get('selectedTableAmount') - 1;

          if (copy["".concat(this.table.value, "-top")] === 0) {
            delete copy["".concat(this.table.value, "-top")];
          }

          this.get('currentBlock').set('tableAssignments', copy);
        }
      }
    }
  });

  _exports.default = _default;
});