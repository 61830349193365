define("lh4/templates/components/sign-in/alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0aNyhKet",
    "block": "{\"symbols\":[\"alert\"],\"statements\":[[4,\"each\",[[24,[\"request\",\"alerts\"]]],null,{\"statements\":[[4,\"if\",[[28,\"alert-is-valid\",[[23,1,[]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"ui message \",[23,1,[\"type\"]]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"view login interface updates in internet-payment\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[5,\"ui/icons/internet-payments/warning\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[5,\"ui/icons/internet-payments/important-notification\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"p\",true],[8],[7,\"b\",true],[8],[1,[23,1,[\"header\"]],false],[9],[0,\" \"],[1,[23,1,[\"message\"]],true],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/sign-in/alerts.hbs"
    }
  });

  _exports.default = _default;
});