define("lh4/routes/authenticated/premium/social-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    titleToken: 'Social Media',
    redirect: function redirect() {
      if (this.can.can('manage posts for social-medium')) {
        this.transitionTo('authenticated.social-media.post-scheduler');
      } else if (this.can.can('manage accounts for social-medium')) {
        this.transitionTo('authenticated.social-media.accounts');
      }
    }
  });

  _exports.default = _default;
});