define("lh4/abilities/add-on-device", ["exports", "ember-can", "lh4/config/environment", "lh4/enums/launch-darkly-feature-flags", "lh4/mixins/abilities/ability-mixin", "lh4/utils/gpatt", "lh4/utils/permissions"], function (_exports, _emberCan, _environment, _launchDarklyFeatureFlags, _abilityMixin, _gpatt, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    features: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    canView: Ember.computed('location.model', function getter() {
      return this.canViewGpatt || this.canViewTableside || this.canViewCustomerDisplay;
    }),
    canViewGpatt: (0, _abilityMixin.computedAbility)('location.model', function getter() {
      return (0, _gpatt.default)(this.location.model) && this.hasPermission(_permissions.default.SKYTAB.ACCESS);
    }),
    canManageGpatt: (0, _abilityMixin.computedAbility)('location.model', function getter() {
      return (0, _gpatt.default)(this.location.model) && this.hasPermission(_permissions.default.SKYTAB.ACCESS);
    }),
    canViewTableside: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('echo-pro') && this.hasLocationPermission(_permissions.default.HBR.TABLESIDE) && (_environment.default.branding.name === _environment.default.BrandNames.RestaurantManager || _environment.default.branding.name === _environment.default.BrandNames.POSitouch) && !this.get('canViewGpatt');
    }),
    canManageTableside: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('echo-pro') && this.hasLocationPermission(_permissions.default.HBR.TABLESIDE);
    }),
    canViewCustomerDisplay: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('echo-pro');
    }),
    canManageCustomerDisplay: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('echo-pro');
    }),
    canViewPrinters: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('echo-pro');
    }),
    canManagePrinters: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('echo-pro');
    }),
    canViewKds: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.hasProduct('echo-pro') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.KDS_PREP_ITEM];
    }),
    canManageKds: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.hasProduct('echo-pro') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.KDS_PREP_ITEM];
    }),
    canDeleteIngredients: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.hasProduct('echo-pro') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.KDS_INGREDIENT_DELETE];
    })
  });

  _exports.default = _default;
});