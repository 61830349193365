define("lh4/templates/components/dashboard-widgets/labor-statistics/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h/mS8Ipz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"Labor Hours\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[28,\"or\",[[28,\"to-fixed\",[[24,[\"data\",\"hours\"]],2],null],0],null],false],[0,\"h\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"Labor Percentage\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[24,[\"data\",\"laborPct\"]],false],[0,\"%\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"Labor Cost Per Guest\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[28,\"currency-format\",[[24,[\"data\",\"costPerGuest\"]]],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"Labor Cost\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[28,\"currency-format\",[[24,[\"data\",\"cost\"]]],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/labor-statistics/data.hbs"
    }
  });

  _exports.default = _default;
});