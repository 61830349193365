define("lh4/mixins/routes/customer-engagement/edit-route-mixin", ["exports", "lh4/mixins/routes/discard-changes-mixin"], function (_exports, _discardChangesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Make campaign draft if allowed.
   * @param {Object} campaign -campaign to save as draft if need
   * @returns {Object} campaign
   */
  function makeDraft(campaign) {
    if (!campaign.get('isEditable') || campaign.get('status') !== 'Scheduled') {
      return {
        campaign: campaign
      };
    }

    campaign.set('status', 'Draft');
    return campaign.save().then(function () {
      return {
        campaign: campaign,
        statusChanged: true
      };
    });
  }

  var _default = Ember.Mixin.create(_discardChangesMixin.default, {
    modalName: 'discard-campaign-changes',
    modelNameToTrack: 'model.campaign',
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('customer-engagement/campaign', params.campaignId).then(makeDraft).then(function (_ref) {
        var campaign = _ref.campaign,
            statusChanged = _ref.statusChanged;
        return Ember.RSVP.hash({
          campaign: campaign,
          statusChanged: statusChanged,
          templates: _this.store.findAll('customer-engagement/campaign-template'),
          locations: _this.modelFor('authenticated').locations
        });
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      controller.set('step', model.campaign.get('isNew') ? 0 : 4);
      controller.set('saveLocationSettings', false);

      if (model.statusChanged) {
        controller.send('notifyAboutStatusChange');
      }
    },
    actions: {
      willTransition: function willTransition() {
        var model = this.controller.get('model.campaign');
        var isNew = model.get('isNew');
        var changedAttributes = model.changedAttributes();
        var onlyTypeHasChanged = this.controller.get('step') === 0 && Object.keys(changedAttributes).length === 1 && changedAttributes.type;

        if (isNew && onlyTypeHasChanged) {
          return true;
        }

        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});