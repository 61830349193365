define("lh4/utils/scroll-to-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollToError;

  /**
   * Scrolls to form field that has error.
   */
  function scrollToError() {
    Ember.run.later(function () {
      var _document$querySelect;

      return (_document$querySelect = document.querySelector('.error:not(.s4-alert)')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.scrollIntoView({
        behavior: 'smooth'
      });
    });
  }
});