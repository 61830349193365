define("lh4/components/ui/search/social-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchText: '',
    placeholder: 'Search...',
    actions: {
      onClear: function onClear() {
        this.set('searchText', '');
        this.sendAction('onSearch', this.searchText);
      },
      onSearchChanged: function onSearchChanged() {
        if (!this.searchText || this.searchText.length >= 1) {
          Ember.run.debounce(this, this._onSearchActual, 700);
        }
      }
    },
    _onSearchActual: function _onSearchActual() {
      this.sendAction('onSearch', this.searchText);
    }
  });

  _exports.default = _default;
});