define("lh4/components/launch-control/order-tracker/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="progress-bar {{@class}}">
  	<div class="percentage-badge">
  		<div class="completion-text">{{@value}}%</div>
  	</div>
  	<div class="progress-bar-label">Launch Progress</div>
  	<div class="progress-bar-slider">
  		<Ui::S4::Inputs::Slider
  				@value="{{@value}}"
  		/>
  	</div>
  </div>
  
  */
  {
    id: "FYdM3vCs",
    block: "{\"symbols\":[\"@class\",\"@value\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"progress-bar \",[23,1,[]]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"percentage-badge\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"completion-text\"],[8],[1,[23,2,[]],false],[0,\"%\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"progress-bar-label\"],[8],[0,\"Launch Progress\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"progress-bar-slider\"],[8],[0,\"\\n\\t\\t\"],[5,\"ui/s4/inputs/slider\",[],[[\"@value\"],[[29,[[23,2,[]]]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/launch-control/order-tracker/progress-bar.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});