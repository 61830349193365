define("lh4/enums/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DURATION = Object.freeze({
    MINUTE: Object.freeze({
      IN_SECONDS: 60,
      IN_MILLISECONDS: 60000 // 60 * 1000

    }),
    HOUR: Object.freeze({
      IN_SECONDS: 3600,
      IN_MINUTES: 60
    }),
    DAY: Object.freeze({
      IN_MILLISECONDS: 86400000 // 24 * 60 * 60 * 1000,

    })
  });
  var _default = DURATION;
  _exports.default = _default;
});