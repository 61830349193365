define("lh4/components/ui/messages-list", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'ui-messages-list',
    tagName: 'div',
    classNames: ['ui', 'message'],
    isVisible: Ember.computed.notEmpty('messages')
  });

  _exports.default = _default;
});