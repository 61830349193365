define("lh4/components/ui/dates/datetime-range-picker-for-slider", ["exports", "lh4/mixins/components/ui/dates/date-picker-mixin", "lh4/components/ui/dates/datetime-range-picker"], function (_exports, _datePickerMixin, _datetimeRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Adding `datePickerMixin` to the `datetime-range-picker` component breaks too many tests.
  // However, without that mixin, datepicker ignores clicks on a tray and can't fire blur event.
  var _default = _datetimeRangePicker.default.extend(_datePickerMixin.default, {
    layoutName: 'components/ui/dates/datetime-range-picker'
  });

  _exports.default = _default;
});