define("lh4/components/dashboard-widgets/sales/data", ["exports", "moment", "lh4/mixins/dashboard-widget-mixin", "lh4/formats"], function (_exports, _moment, _dashboardWidgetMixin, _formats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TABLE_MAX_SALES = 4;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    locale: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['sales-data'],
    classNameBindings: ['widgetRootClass'],
    skeletonData: {
      total: 840
    },
    widgetRootClass: Ember.computed('settings.view', function getter() {
      return this.get('settings.view') === 'simple' ? 'single-value-widget' : 'table-widget';
    }),
    chartData: Ember.computed('data', 'settings.view', function getter() {
      return this.get('data.sales').map(function (_ref) {
        var date = _ref.date,
            sales = _ref.sales;
        return {
          date: (0, _moment.default)(date).format(_moment.default.HTML5_FMT.DATE),
          value: parseFloat(sales)
        };
      });
    }),
    tableData: Ember.computed('data.sales.[]', 'settings.view', 'expanded', function getter() {
      var _this = this;

      if (this.get('settings.view') !== 'table') {
        return null;
      }

      var sales = this.get('data.sales').map(function (d) {
        return {
          sales: d.sales,
          // TODO  LH-27133 remove weird date hack, see ticket for more details
          date: (0, _moment.default)(d.date).tz(_this.intl.formats.date.SHORT.timeZone, true)
        };
      }).reverse();
      var hasMore = sales.length > TABLE_MAX_SALES;
      return {
        hasMore: hasMore,
        sales: this.expanded || !hasMore ? sales : sales.slice(0, TABLE_MAX_SALES)
      };
    }),
    chartOptions: Ember.computed('location', function getter() {
      var _this2 = this;

      var valueTemplate = this.locale.currencyTemplate('[[value]]');
      return {
        language: 'en',
        balloonText: valueTemplate,
        categoryField: 'date',
        dataDateFormat: _moment.default.HTML5_FMT.DATE,
        parseDates: true,
        valueAxesOptions: {
          precision: 2,
          labelFunction: function labelFunction(value) {
            return _this2.locale.currencyFormat(value);
          }
        },
        valueField: 'value',
        chartCursor: {
          categoryBalloonFunction: function categoryBalloonFunction(value) {
            return _this2.locale.formatDateForAmChart(value, _this2.useIntlDates, _formats.default.date.MEDIUM, 'SALES-CURSOR');
          }
        },
        categoryAxis: {
          labelFunction: function labelFunction(value, data) {
            return _this2.locale.formatDateForAmChart(value, _this2.useIntlDates, _formats.default.date.MEDIUM_NO_YEAR, data, 'SALES-AXIS');
          }
        }
      };
    })
  });

  _exports.default = _default;
});