define("lh4/routes/authenticated/epro/workforce/links/overtime", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/routes/authenticated/epro/workforce/links/sso"], function (_exports, _authenticatedRouteMixin, _sso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sso.default.extend(_authenticatedRouteMixin.default, {
    redirectUri: '/overtime_settings',
    pageTitle: 'Overtime'
  });

  _exports.default = _default;
});