define("lh4/models/supply-orders/product", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    displayName: (0, _attr.default)('string'),
    externalName: (0, _attr.default)('string'),
    model: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    cost: (0, _attr.default)('number'),
    price: (0, _attr.default)('number'),
    active: (0, _attr.default)('boolean'),
    weight: (0, _attr.default)('number'),
    isFreeShipping: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    products: (0, _relationships.hasMany)('supply-orders/product'),
    imageLink: (0, _attr.default)('string')
  });

  _exports.default = _default;
});