define("lh4/templates/components/tables/pos-orders-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d371WCSM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"center aligned\"],[8],[1,[24,[\"order\",\"id\"]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[24,[\"order\",\"merchantId\"]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[28,\"moment-utc-format\",[[24,[\"order\",\"createdAt\"]],\"MM/DD/YYYY hh:mmA\"],null],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[1,[24,[\"order\",\"orderType\"]],false],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"ui \",[22,\"statusLabel\"],\" label\"]]],[8],[1,[24,[\"order\",\"orderStatus\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"ui \",[22,\"stageLabel\"],\" label\"]]],[8],[1,[24,[\"order\",\"orderStage\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"ui-progress\",null,[[\"percent\",\"class\"],[[24,[\"order\",\"orderStagePercent\"]],\"teal tiny indicating\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"bar\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/tables/pos-orders-table-row.hbs"
    }
  });

  _exports.default = _default;
});