define("lh4/templates/components/ui/search/general-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "utxulWvR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"ui left icon input \",[28,\"if\",[[24,[\"searchError\"]],\"error\"],null],\" \",[28,\"if\",[[24,[\"searchText\"]],\"active\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"searchText\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"persistSearchIcon\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"search link icon active\"],[10,\"role\",\"button\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[7,\"i\",false],[12,\"class\",\"times circle link icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"onClear\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",false],[12,\"class\",\"search link icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"onSearchChanged\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[1,[28,\"input\",null,[[\"value\",\"type\",\"placeholder\",\"key-up\",\"maxlength\"],[[24,[\"searchText\"]],\"text\",[24,[\"placeholder\"]],[28,\"action\",[[23,0,[]],\"onSearchChanged\"],null],\"32\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/search/general-search.hbs"
    }
  });

  _exports.default = _default;
});