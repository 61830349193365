define("lh4/mirage/routes/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageServicesServer;

  function setMirageServicesServer(server) {
    server.get('/services/location/:locationId', function (_ref) {
      var services = _ref.services;
      return {
        services: services.all().models
      };
    });
    server.post('/services/location/:locationId', function (_ref2) {
      var status = _ref2.status;
      return {
        status: status
      };
    });
  }
});