define("lh4/templates/components/dashboard-widgets/clearbanc/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "clHJRMsw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"logo\"],[8],[7,\"img\",true],[10,\"src\",\"/img/dashboard/clearbanc.svg\"],[10,\"alt\",\"Clearbanc Logo\"],[8],[9],[9],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"data\",\"status\"]],\"APPROVED\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[8],[0,\"Get capital to grow your business with a flat fee as low as 12%.\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"data\",\"status\"]],\"advance_pending_error\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[8],[0,\"You’ve already accepted an advance offer for this location within the last 24 hours.\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[8],[0,\"You may be eligible for capital to help grow your business.\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[7,\"a\",true],[11,\"href\",[29,[\"/marketplace/#/clearbanc/\",[24,[\"location\",\"model\",\"id\"]]]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[10,\"class\",\"ui fluid inverted button\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"Click Here\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/clearbanc/data.hbs"
    }
  });

  _exports.default = _default;
});