define("lh4/controllers/authenticated/internet-payments/customers/details/transaction/risk-block-list", ["exports", "lh4/controllers/authenticated/internet-payments/transactions/master-transactions/edit/risk-block-list"], function (_exports, _riskBlockList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _riskBlockList.default.extend({
    actions: {
      closeBlockList: function closeBlockList() {
        this.set('errorMessage', null);
        this.transitionToRoute('authenticated.internet-payments.customers.details.transaction');
      }
    }
  });

  _exports.default = _default;
});