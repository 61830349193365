define("lh4/components/modal/campaign-manual-customers-modal", ["exports", "lh4/classes/components/tables/multiselect-table-row"], function (_exports, _multiselectTableRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchTerm: null,
    searchStrings: Ember.computed('customers', 'customers.@each', function getter() {
      var _this$customers = this.customers,
          customers = _this$customers === void 0 ? [] : _this$customers;
      return customers.map(function (customer) {
        var _customer$firstName = customer.firstName,
            firstName = _customer$firstName === void 0 ? '' : _customer$firstName,
            _customer$lastName = customer.lastName,
            lastName = _customer$lastName === void 0 ? '' : _customer$lastName,
            _customer$primaryEmai = customer.primaryEmail.email,
            email = _customer$primaryEmai === void 0 ? '' : _customer$primaryEmai;
        return "".concat(firstName, " ").concat(lastName, " ").concat(email, "}").toUpperCase();
      });
    }),
    rows: Ember.computed('customers', 'searchTerm', 'customers.@each', 'searchStrings', function getter() {
      var _this$selected = this.selected,
          selected = _this$selected === void 0 ? [] : _this$selected,
          searchStrings = this.searchStrings;
      var _this$customers2 = this.customers,
          customers = _this$customers2 === void 0 ? [] : _this$customers2,
          searchTerm = this.searchTerm;

      if (searchTerm) {
        searchTerm = searchTerm.toUpperCase();
        customers = customers.filter(function (_, index) {
          return searchStrings[index].indexOf(searchTerm) !== -1;
        });
      }

      return customers.map(function (i) {
        return _multiselectTableRow.default.create({
          content: i,
          isSelected: selected.includes(i)
        });
      });
    }),
    allSelected: Ember.computed('rows.[]', 'selected.[]', 'rows.@each.isSelected', function getter() {
      var rowsLength = this.get('rows.length');
      return rowsLength && rowsLength !== 0 && rowsLength === this.get('rows').filterBy('isSelected', true).length;
    }),
    actions: {
      toggleSelection: function toggleSelection(row) {
        if (this.get('validateSelection') && !row.get('validations.isValid')) {
          return;
        }

        row.toggleProperty('isSelected');
      },
      toggleFullSelection: function toggleFullSelection(checked) {
        this.get('rows').setEach('isSelected', checked);
      },
      confirm: function confirm() {
        this.set('showErrors', false);
        var selected = this.rows.filterBy('isSelected', true).map(function (s) {
          return s.content;
        });

        if (selected.length < 1) {
          this.set('errorMessage', 'You must select at least one customer.');
          this.set('showErrors', true);
          return;
        }

        this.$('#campaign-manual-customers-modal').modal('hide');
        this.sendAction('setManualCustomers', selected);
      },
      cancel: function cancel() {
        this.set('showErrors', false);
        this.$('#campaign-manual-customers-modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});