define("lh4/templates/components/ui/messages-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UVXQIj/I",
    "block": "{\"symbols\":[\"message\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"h4\",true],[10,\"data-test-title\",\"\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"messages\",\"length\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"ul\",true],[10,\"data-test-list\",\"\"],[10,\"class\",\"ui list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"li\",true],[10,\"data-test-item\",\"\"],[8],[14,2,[[23,1,[]]]],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/messages-list.hbs"
    }
  });

  _exports.default = _default;
});