define("lh4/routes/create-account", ["exports", "lh4/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    titleToken: 'Create Account',
    launchDarkly: Ember.inject.service(),
    beforeModel: function beforeModel(transition, queryParams) {
      this._super(transition, queryParams);

      return this.launchDarkly.setFlagsForAnonymousUser();
    }
  });

  _exports.default = _default;
});