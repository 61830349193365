define("lh4/adapters/echo-pro/intraday-period-group", ["exports", "lh4/adapters/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    buildURL: function buildURL(modelName, id) {
      var location = this.get('location');
      var url = "".concat(this.host, "/").concat(this.namespace, "/echo-pro/").concat(location.model.id, "/day-parts/groups");
      return id ? "".concat(url, "/").concat(id) : url;
    }
  });

  _exports.default = _default;
});