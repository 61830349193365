define("lh4/mirage/config", ["exports", "lh4/mirage/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageConfig;

  function setMirageConfig() {
    this.passthrough('/write-coverage');

    _routes.default.timeZonesList(this);

    _routes.default.alerts(this);

    _routes.default.dashboard2(this);

    _routes.default.soloSettings(this);

    _routes.default.locations(this);

    this.namespace = '/api/v1';

    _routes.default.auth(this);

    _routes.default.corporateGroups(this);

    _routes.default.corporateGroupItemOverrides(this);

    _routes.default.corporateGroupLocationMaps(this);

    _routes.default.corporateMenuUpdates(this);

    _routes.default.corporateLocationOverviews(this);

    _routes.default.locationOverviews(this);

    _routes.default.settings(this);

    _routes.default.reports(this);

    _routes.default.support(this);

    _routes.default.dashboard(this);

    _routes.default.hospitality(this);

    _routes.default.epro(this);

    _routes.default.supplyOrders(this);

    _routes.default.reservations(this);

    _routes.default.accounts(this);

    _routes.default.updateLog(this);

    _routes.default.services(this);

    _routes.default.customerEngagement(this);

    _routes.default.socialMedia(this);

    _routes.default.gpatt(this);

    _routes.default.virtualTerminals(this);

    _routes.default.loyalty(this);

    _routes.default.disputes(this);

    _routes.default.finaro(this);

    _routes.default.soloTaxes(this);

    _routes.default.solo(this);

    _routes.default.giftCard(this);

    _routes.default.documents(this);

    _routes.default.integrationS4ApiKeys(this);

    _routes.default.integrationS4Events(this);

    _routes.default.integrationS4Webhooks(this);

    _routes.default.internetPaymentsCustomers(this);

    _routes.default.internetPaymentsCustomersDetails(this);

    _routes.default.internetPaymentsReports(this);

    _routes.default.internetPaymentsTransactions(this);

    _routes.default.internetPaymentsUsersConfigurableColumns(this);

    _routes.default.internetPaymentsDisputes(this);

    _routes.default.internetPaymentsRiskBlockList(this);

    _routes.default.internetPaymentsRiskRules(this);

    _routes.default.internetPaymentsInsightsCards(this);

    _routes.default.internetPaymentsAcquirerResponseCodes(this);

    _routes.default.servers(this);

    _routes.default.hardware(this);

    _routes.default.analytics(this);

    _routes.default.notifications(this);

    _routes.default.echoProCustomers(this);

    _routes.default.usersTransactionsFilterTemplates(this);

    _routes.default.businessSettings(this);

    _routes.default.echoProAdvantageSettings(this);

    _routes.default.deviceManagement(this);

    _routes.default.launchControl(this);

    _routes.default.deployAnalysis(this);

    _routes.default.deployAnalysis(this);

    _routes.default.transactionDayParts(this);

    _routes.default.transactionRevenueCenters(this);

    _routes.default.echoProDayParts(this);

    _routes.default.echoProExternalOrders(this);

    _routes.default.locationGroups(this);

    _routes.default.usersLocation(this);
  }
});