define("lh4/controllers/authenticated/virtual-terminal/transactions-ip", ["exports", "lh4/controllers/authenticated/virtual-terminal/transactions"], function (_exports, _transactions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transactions.default.extend({
    headers: Ember.computed(function () {
      return [{
        field: 'invoiceId',
        name: 'Invoice',
        width: 'two'
      }, {
        field: 'total',
        name: 'Total',
        width: 'two'
      }, {
        field: 'date',
        name: 'Date',
        width: 'two'
      }, {
        field: 'authNumber',
        name: 'Auth #',
        width: 'two'
      }, {
        field: 'tipAmount',
        name: 'Tip',
        width: 'two'
      }, {
        field: 'cardNumber',
        name: 'Card #',
        width: 'two'
      }, {
        field: 'customerName',
        name: 'Name',
        width: 'two'
      }, {
        field: 'transType',
        name: 'Type',
        width: 'two'
      }, {
        field: 'action',
        name: 'Action',
        width: 'two'
      }];
    })
  });

  _exports.default = _default;
});