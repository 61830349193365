define("lh4/models/hospitality/revenue-center", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    rowguid: (0, _attr.default)('string')
  });

  _exports.default = _default;
});