define("lh4/components/ui/form-fields/static-field", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    fullWidth: true,
    dataTest: 'form-field-static-field',
    inputClass: Ember.computed('fullWidth', 'fieldWidth', function getter() {
      var widthClass = this.fieldWidth || !this.label && 'sixteen' || this.fullWidth && 'twelve' || 'eight';
      return "".concat(widthClass, " wide column ui white");
    })
  });

  _exports.default = _default;
});