define("lh4/routes/authenticated/customer-database/create", ["exports", "ember-changeset", "ember-changeset-cp-validations", "lh4/mixins/changeset-transition-route-mixin"], function (_exports, _emberChangeset, _emberChangesetCpValidations, _changesetTransitionRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    model: function model() {
      var location = this.location.model;
      var modelName;

      if (location.product === 'echo-pro') {
        modelName = 'echo-pro/customer';
      } else if (this.can.can('manage processing/customer')) {
        modelName = 'processing/customer';
      }

      return Ember.RSVP.hash({
        customer: this.store.createRecord(modelName, {
          locationId: this.location.model.id
        })
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model.customer),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(model.customer, validateFn, validationMap, {
        skipValidate: true
      });
      changeset.phoneChangesets = model.customer.phones.map(this.buildChildChangeset);
      changeset.emailChangesets = model.customer.emails.map(this.buildChildChangeset);
      changeset.addressChangesets = model.customer.addresses.map(this.buildChildChangeset);
      controller.set('changeset', changeset);
      controller.setProperties({
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        }
      });
    },
    buildChildChangeset: function buildChildChangeset(model) {
      var _buildChangeset2 = (0, _emberChangesetCpValidations.buildChangeset)(model),
          validateFn = _buildChangeset2.validateFn,
          validationMap = _buildChangeset2.validationMap;

      return new _emberChangeset.default(model, validateFn, validationMap, {
        skipValidate: true
      });
    }
  });

  _exports.default = _default;
});