define("lh4/templates/components/ui/icons/sidebar/support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EKvCKCH3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M12.001 2C6.48098 2 2.00098 6.48 2.00098 12C2.00098 17.52 6.48098 22 12.001 22C17.521 22 22.001 17.52 22.001 12C22.001 6.48 17.521 2 12.001 2ZM19.461 9.12L16.681 10.27C16.171 8.91 15.101 7.83 13.731 7.33L14.881 4.55C16.981 5.35 18.651 7.02 19.461 9.12ZM12.001 15C10.341 15 9.00098 13.66 9.00098 12C9.00098 10.34 10.341 9 12.001 9C13.661 9 15.001 10.34 15.001 12C15.001 13.66 13.661 15 12.001 15ZM9.13098 4.54L10.301 7.32C8.92098 7.82 7.83098 8.91 7.32098 10.29L4.54098 9.13C5.35098 7.02 7.02098 5.35 9.13098 4.54ZM4.54098 14.87L7.32098 13.72C7.83098 15.1 8.91098 16.18 10.291 16.68L9.12098 19.46C7.02098 18.65 5.35098 16.98 4.54098 14.87ZM14.881 19.46L13.731 16.68C15.101 16.17 16.181 15.09 16.681 13.71L19.461 14.88C18.651 16.98 16.981 18.65 14.881 19.46Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/support.hbs"
    }
  });

  _exports.default = _default;
});