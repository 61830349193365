define("lh4/controllers/authenticated/epro/timeclock/shifts/general", ["exports", "lh4/controllers/general-form-controller", "lh4/enums/date-time-format", "moment"], function (_exports, _generalFormController, _dateTimeFormat, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    canSave: true,
    warningMessage: null,
    instanceName: 'timeClockShift',
    isTray: false,
    serverErrorTimeoutDelay: 6000,
    createWorkSegments: function createWorkSegments() {
      var _this = this;

      var shiftStart = this.changeset.get('clockedInAt');
      var shiftEnd = this.changeset.get('clockedOutAt') || null;
      var breaks = this.changeset.segmentChangesets.filter(function (record) {
        return record.get('isBreak');
      }).sort(function (a, b) {
        return (0, _moment.default)(a.get('start')).diff(b.get('start'));
      });
      var segmentStart = shiftStart;
      breaks.forEach(function (b) {
        var start = b.get('start');

        if (segmentStart !== start) {
          // create a work segment before all breaks or in between two breaks
          var newSegment = _this.changeset.data.addSegment({
            start: segmentStart,
            end: start,
            isBreak: false,
            isPaid: true,
            timeClockShift: _this.changeset
          });

          _this.changeset.segmentChangesets.pushObject(_this.buildChildChangeset(newSegment));
        }

        segmentStart = b.get('end') || null;
      });

      if (segmentStart !== shiftEnd) {
        // create a work segment after all breaks
        var newSegment = this.changeset.data.addSegment({
          start: segmentStart,
          end: shiftEnd,
          isBreak: false,
          isPaid: true,
          timeClockShift: this.changeset
        });
        this.changeset.segmentChangesets.pushObject(this.buildChildChangeset(newSegment));
      }
    },
    removeWorkSegments: function removeWorkSegments() {
      var _this2 = this;

      this.changeset.segmentChangesets.filter(function (record) {
        return !record.get('isBreak');
      }).forEach(function (record) {
        return _this2.changeset.segmentChangesets.removeObject(record);
      });
    },
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (record) {
        return !changeset.findBy('id', record.id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    applyChildChangesets: function applyChildChangesets(changesets, key) {
      var _this3 = this;

      return changesets.forEach(function (record) {
        record.execute();
        record.rollback();

        _this3.model.timeClockShift.get(key).pushObject(record.data);
      });
    },
    close: function close() {
      this.clearTemporaryData();
      this.clearServerError();

      if (this.isTray) {
        this.transitionToRoute('authenticated.epro.timeclock.shifts');
      } else {
        this.closeModal();
      }
    },
    actions: {
      close: function close() {
        this.close();
      },
      save: function save() {
        var _this4 = this;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError();
        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.timeClockShift.timeClockSegments, this.changeset.segmentChangesets);
        this.changeset.validate().then(function () {
          if (!_this4.changeset.isValid) {
            return undefined;
          }

          if (isPristine) {
            _this4.close();

            return undefined;
          }

          var snapshot = _this4.changeset.snapshot(); // Remove old work segments


          _this4.removeWorkSegments();

          _this4.removeRecordsFromModel(_this4.model.timeClockShift.timeClockSegments, _this4.changeset.segmentChangesets);

          if (!_this4.changeset.get('voided')) {
            // Generate new work segments
            _this4.createWorkSegments();

            _this4.applyChildChangesets(_this4.changeset.segmentChangesets, 'timeClockSegments');
          }

          return _this4.changeset.save().then(function () {
            return _this4.close();
          }).catch(function (err) {
            var _errors$0$details$;

            var errors = err.errors;

            _this4.changeset.restore(snapshot);

            if (_this4.isTray) {
              _this4.changeset.data.rollbackAttributes();
            }

            if (!(errors && errors[0])) {
              return;
            }

            if (errors[0].details && (_errors$0$details$ = errors[0].details[0]) !== null && _errors$0$details$ !== void 0 && _errors$0$details$.match(/Shift overlaps another shift with period: /)) {
              var dates = errors[0].details[0].split('period: ')[1].split(' - ');
              errors[0].details[0] = 'Shift overlaps another shift with period: ' + "".concat((0, _moment.default)(dates[0]).format(_dateTimeFormat.default.LOCALIZED_SHORT), " to ") + "".concat(dates[1] === 'now' ? 'now' : (0, _moment.default)(dates[1]).format(_dateTimeFormat.default.LOCALIZED_SHORT), ".");
            } // TODO normalize how we handle these error messages: LH-8461


            var saveError = "Something went wrong, ".concat(_this4.instanceName, " could not be saved.");
            errors.forEach(function (error) {
              var errorMessage = error.details && error.details[0] || error.message || saveError;

              _this4.set('serverError', errorMessage);

              _this4.set('serverErrorTimeoutId', Ember.run.later(_this4, function () {
                _this4.set('serverError', null);
              }, _this4.serverErrorTimeoutDelay));
            });
          });
        }).finally(function () {
          return _this4.set('isSaving', false);
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      }
    }
  });

  _exports.default = _default;
});