define("lh4/templates/authenticated/account-maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WpZDalMQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\",true],[11,\"src\",[22,\"decisionsIframeURL\"]],[8],[9],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"modals\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"modal/walkthrough-videos\",null,[[\"key\",\"videos\",\"title\",\"shouldShow\"],[\"accountMaintenance\",[24,[\"tutorialVideos\"]],\"Maintain your account\",[24,[\"shouldShowTutorials\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/account-maintenance.hbs"
    }
  });

  _exports.default = _default;
});