define("lh4/components/epro/accounting/revenue-class-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    corporation: Ember.inject.service(),
    classNames: ['revenue-class-form'],
    taxOptions: Ember.computed.map('taxes', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          isActive = _ref.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    })
  });

  _exports.default = _default;
});