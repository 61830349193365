define("lh4/templates/components/social-media/scheduled-post-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LpnvWt4s",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"social-media/post-preview/post-content-preview\",null,[[\"text\",\"picture1Uri\",\"scheduleRepeat\"],[[24,[\"post\",\"text\"]],[24,[\"post\",\"picture1Uri\"]],[24,[\"post\",\"scheduleRepeat\"]]]]],false],[0,\"\\n\\n\"],[4,\"social-media/post-preview/post-footer-preview\",null,[[\"post\"],[[24,[\"post\"]]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"ui/buttons/button-confirm-modal\",null,[[\"onApprove\",\"buttonText\",\"buttonClass\",\"confirmationQuestion\",\"confirmationDescription\",\"confirmationApproveText\",\"confirmationDenyText\",\"icon\"],[[28,\"action\",[[23,0,[]],[24,[\"onDelete\"]]],null],\"Delete\",\"small ui button scheduled-post-button\",\"Delete Post?\",\"Are you sure you want to delete scheduled post?\",\"Delete\",\"Cancel\",\"trash alternate\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"small ui button scheduled-post-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"onEdit\"]]]],[8],[0,\"Edit\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/social-media/scheduled-post-preview.hbs"
    }
  });

  _exports.default = _default;
});