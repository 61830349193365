define("lh4/routes/authenticated/customer-engagement/campaigns/index", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var engagementRoute = 'authenticated.customer-engagement';

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    requiredPermissions: 'manage campaigns for customer-engagement',
    location: Ember.inject.service(),
    queryParams: {
      offset: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      name: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      sortAsc: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        campaigns: this.store.query('customer-engagement/campaign', {
          limit: params.limit,
          offset: params.offset,
          name: params.name,
          locationId: this.location.model.id,
          status: params.status,
          sortBy: params.sortBy,
          sortAsc: params.sortAsc
        })
      });
    },
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('authenticated.customer-engagement.campaigns.index');
        controller.set('loading', true);
        transition.promise.finally(function () {
          controller.set('loading', false);
        });
      },
      willTransition: function willTransition(transition) {
        var accessingChildRoute = transition.to.name.includes(engagementRoute);

        if (!accessingChildRoute) {
          var controller = this.controllerFor("".concat(engagementRoute, ".campaigns.index"));
          controller.setProperties({
            name: null,
            status: 'all'
          });
        }
      }
    }
  });

  _exports.default = _default;
});