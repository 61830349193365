define("lh4/components/modal/updates-modal", ["exports", "ember-gestures/mixins/recognizers"], function (_exports, _recognizers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_recognizers.default, {
    recognizers: 'swipe',
    walkthrough: Ember.inject.service(),
    updateLog: Ember.inject.service(),
    session: Ember.inject.service(),
    modalsManager: Ember.inject.service('modals-manager'),

    /**
     * Refresh modal after image is loaded to fix it's positioning after content change
     */
    refreshModalChanges: function refreshModalChanges() {
      var _this = this;

      Ember.run.next(this, function () {
        var _this$$;

        // make sure a valid element exists
        // some weird bug can happen where this is run after element is destroyed already - LH-9852
        if (_this !== null && _this !== void 0 && (_this$$ = _this.$('#updates-modal')) !== null && _this$$ !== void 0 && _this$$.length) {
          _this.$('#updates-modal img').on('load', function () {
            _this.$('#updates-modal').modal('refresh');
          });
        }
      });
    },
    // makes sure that dimmer is always hidden when navigating away
    willDestroyElement: function willDestroyElement() {
      this.send('close');
    },
    currentUpdateIndex: 0,
    currentUpdate: Ember.computed('updateLog.updates.[]', 'currentUpdateIndex', function getter() {
      return this.get('updateLog.updates')[this.get('currentUpdateIndex')];
    }),
    canGoForward: Ember.computed('updateLog.updates', 'currentUpdateIndex', function getter() {
      return this.get('updateLog.updates').length > this.get('currentUpdateIndex') + 1;
    }),
    canGoBackward: Ember.computed('updateLog.updates', 'currentUpdateIndex', function getter() {
      return this.get('currentUpdateIndex') > 0;
    }),
    canSkip: Ember.computed('currentUpdateIndex', function getter() {
      return this.get('currentUpdateIndex') === 0;
    }),
    canShowUpdatesChanged: Ember.observer('updateLog.shouldShow', function callback() {
      if (this.updateLog.shouldShow) {
        this.send('show');
      }
    }).on('didInsertElement'),
    actions: {
      skip: function skip() {
        if (!this.get('canSkip')) {
          return;
        }

        this.set('updateLog.areSkipped', true);
        this.send('close');
      },
      finish: function finish() {
        if (this.get('canGoForward')) {
          return;
        }

        this.updateLog.markAsShown();
        this.send('close');
      },
      goForward: function goForward() {
        if (!this.get('canGoForward')) {
          return;
        }

        this.incrementProperty('currentUpdateIndex');
        this.refreshModalChanges();
      },
      goBackward: function goBackward() {
        if (!this.get('canGoBackward')) {
          return;
        }

        this.decrementProperty('currentUpdateIndex');
        this.refreshModalChanges();
      },
      show: function show() {
        this.modalsManager.pushToList('updates-modal', this.$('#updates-modal'));
        this.refreshModalChanges();
      },
      close: function close() {
        // Cleanup when modal is closed - otherwise video does not stop (if it's playing already)
        var iframe = this.element.querySelector('iframe');

        if (iframe) {
          this.$(iframe).remove();
        }

        this.modalsManager.deleteFromList('updates-modal');
      },
      onClose: function onClose() {
        if (this.updateLog.updates.length === 1) {
          this.updateLog.markAsShown();
        } else {
          this.set('updateLog.areSkipped', true);
        }

        this.send('close');
      },
      onHidden: function onHidden() {
        this.set('updateLog.areSkipped', true);
        this.send('close');
      },
      onStepSelect: function onStepSelect() {
        this.refreshModalChanges();
      }
    }
  });

  _exports.default = _default;
});