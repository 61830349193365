define("lh4/routes/authenticated/locations/location/hospitality/choices-and-choice-sets", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Choices and Choice Sets',
    requiredPermissions: 'manage hospitality/choice',
    model: function model() {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        choiceSets: this.store.query('hospitality/choice-set', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('hospitality/choice-set');
        }),
        location: location
      });
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments); // Clear all dependant computed props - to prevent usage of unloaded records


      this.controllerFor('authenticated.locations.location.hospitality.choices-and-choice-sets').set('selectedChoiceSetsGuid', null);
      this.store.unloadAll('hospitality/choice-set');
    }
  });

  _exports.default = _default;
});