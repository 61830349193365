define("lh4/services/token-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    metrics: Ember.inject.service(),
    data: Ember.computed('session.isAuthenticated', function getter() {
      // We check for session.data.authenticated.token in case session gets into invalid state & session data
      // is not fully cleared (from localStorage) but no token exists anymore
      if (!(this.get('session.isAuthenticated') && this.get('session.data.authenticated.token'))) {
        return {
          permissions: []
        };
      } // merge token data with auth response


      var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
      var data = this.get('session.data.authenticated');
      return Object.assign(data, authenticator.getTokenData(this.get('session.data.authenticated.token')));
    })
  });

  _exports.default = _default;
});