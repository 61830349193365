define("lh4/templates/components/ui/icons/login/accepted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FdaL09SS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"64\"],[10,\"height\",\"64\"],[10,\"viewBox\",\"0 0 64 64\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M32 0C14.3584 0 0 14.3486 0 32C0 49.6514 14.3486 64 32 64C49.6514 64 64 49.6514 64 32C64 14.3486 49.6514 0 32 0ZM32 63.0012C14.9023 63.0012 0.988875 49.0877 0.988875 31.9901C0.988875 14.8924 14.9023 0.978976 32 0.978976C49.0976 0.978976 63.0111 14.8924 63.0111 31.9901C63.0111 49.0877 49.0976 63.0012 32 63.0012Z\"],[10,\"fill\",\"#090304\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M31.9999 6.32886C17.8392 6.32886 6.32874 17.8492 6.32874 32C6.32874 46.1508 17.8491 57.6712 31.9999 57.6712C46.1507 57.6712 57.6711 46.1508 57.6711 32C57.6711 17.8492 46.1507 6.32886 31.9999 6.32886ZM31.9999 56.6823C18.393 56.6823 7.31761 45.6069 7.31761 32C7.31761 30.1607 7.52528 28.361 7.91094 26.6304C11.9752 17.3548 21.2212 10.8678 31.9999 10.8678C42.7786 10.8678 52.0246 17.3548 56.0889 26.6304C56.4746 28.361 56.6822 30.1508 56.6822 32C56.6822 45.6069 45.6068 56.6823 31.9999 56.6823Z\"],[10,\"fill\",\"#090304\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M29.597 38.8332L22.4178 31.654L21.7157 32.3462L29.597 40.2275L45.1817 24.6527L44.4796 23.9507L29.597 38.8332Z\"],[10,\"fill\",\"#090304\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/login/accepted.hbs"
    }
  });

  _exports.default = _default;
});