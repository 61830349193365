define("lh4/templates/authenticated/support/support-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9GOcBqVz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui fluid container support-wrap\"],[8],[0,\"\\n\"],[4,\"link-to-outbound\",[\"https://www.skytab.com/resource-center\"],[[\"class\"],[\"ui link\"]],{\"statements\":[[0,\"\\t\\tSkyTab Resource Center\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/support/support-links.hbs"
    }
  });

  _exports.default = _default;
});