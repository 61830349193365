define("lh4/components/modal/checkout-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="header">
  	<h2>Checkout</h2>
  
  	<div class="close" role="button" {{action "close" }}>
  		<img src="/img/checkout/close.svg" alt="Close">
  	</div>
  </div>
  {{#if errorMessage}}
  	<div class="ui inverted red segment error-message" data-test-message="error">
  		<i class="ui icon exclamation triangle"></i>
  		{{errorMessage}}
  	</div>
  {{/if}}
  {{#if successMessage}}
  	<div class="ui inverted green segment success-message" data-test-message="success">
  		<i class="ui check icon"></i>
  		{{successMessage}}
  	</div>
  {{/if}}
  {{yield (hash
  content=(component 'ui/shared/yield-basic' defaultClassNames='content')
  )}}
  */
  {
    id: "DSuD+gk+",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[8],[0,\"Checkout\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",false],[12,\"class\",\"close\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/img/checkout/close.svg\"],[10,\"alt\",\"Close\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted red segment error-message\"],[10,\"data-test-message\",\"error\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui icon exclamation triangle\"],[8],[9],[0,\"\\n\\t\\t\"],[1,[22,\"errorMessage\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"successMessage\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted green segment success-message\"],[10,\"data-test-message\",\"success\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui check icon\"],[8],[9],[0,\"\\n\\t\\t\"],[1,[22,\"successMessage\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1,[[28,\"hash\",null,[[\"content\"],[[28,\"component\",[\"ui/shared/yield-basic\"],[[\"defaultClassNames\"],[\"content\"]]]]]]]]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/modal/checkout-modal.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['checkout-modal'],

    /* @param {string} errorMessage - Message to display for form level error */
    errorMessage: null,
    previousBodyOverflow: undefined,
    onClose: function onClose() {},
    didInsertElement: function didInsertElement() {
      // In the situation that we're moving from one fullscreen modal to another
      // it's possible that hidden has yet to be removed. For now we'll still
      // attempt to set back to a previous body overflow value unless that
      // value was hidden. If this causes future issues we may need to rethink
      // the setTimeout pattern we have currently.
      var previousBodyOverflow = document.body.style.overflow === 'hidden' ? '' : document.body.style.overflow;
      this.set('previousBodyOverflow', previousBodyOverflow); // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
      // the responsiveness for some reason. Setting within a setTimeout fixes this.

      setTimeout(function () {
        document.body.style.overflow = 'hidden';
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      setTimeout(function () {
        document.body.style.overflow = _this.previousBodyOverflow;
      });
    },
    actions: {
      close: function close() {
        this.onClose();
      }
    }
  }));

  _exports.default = _default;
});