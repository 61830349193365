define("lh4/mirage/factories/epro-modifier", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    id: function id() {
      return _faker.default.datatype.uuid();
    },
    active: true,
    cost: '1.00',
    kitchenName: function kitchenName() {
      return _faker.default.commerce.productName();
    },
    locationId: '1',
    name: function name() {
      return _faker.default.commerce.productName();
    },
    ordinal: 'number',
    shouldPrintDistinctly: true,
    upc: 'string',
    onlineActive: true,
    onlineName: 'string',
    onlineDescription: 'string',
    shouldPromptSpecialRequest: false
  });

  _exports.default = _default;
});