define("lh4/helpers/render-pricing-schedules", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.renderPricingSchedules = renderPricingSchedules;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var AMOUNT_OF_SCHEDULES_TO_SHOW = 2;
  var MAX_SCHEDULE_NAME_LENGTH = 30;
  /**
   * Returns a formatted hour string from milliseconds.
   *
   * @param {number} milliseconds value
   * @returns {string} formatted hours
   */

  function getHoursString(milliseconds) {
    return _moment.default.utc(milliseconds).format('hh:mm A');
  }
  /**
   * Returns a string listing the ranges of schedule's active days of week.
   *
   * Under the hood:
   * The function iterates through a collection of all days and records labels of
   * all active consecutive and singular days in arrays as ranges.
   * During the output the ranges are joined into single strings and concatenated.
   *
   * Example 1:
   * - Active days: Mon, Tue, Wed, Fri, Sun
   * - Expected output: "Mon - Wed, Fri, Sun"
   *
   * Example 2:
   * - Active days: Tue, Wed, Fri, Sat
   * - Expected output: "Tue - Wed, Fri - Sat"
   *
   * Example 3:
   * - Active days: Tue, Sat
   * - Expected output: "Tue, Sat"
   *
   * Example 4:
   * - Active days: Mon, Tue, Wed, Thu, Fri, Sat, Sun
   * - Expected output: "Every Day"
   *
   * @param {object} schedule pricing schedule object
   * @returns {string} string of active day ranges
   */


  function getDaysOfWeekRangeString(schedule) {
    var weekdayOptions = [{
      key: 'isOnMonday',
      label: 'Mon'
    }, {
      key: 'isOnTuesday',
      label: 'Tue'
    }, {
      key: 'isOnWednesday',
      label: 'Wed'
    }, {
      key: 'isOnThursday',
      label: 'Thu'
    }, {
      key: 'isOnFriday',
      label: 'Fri'
    }, {
      key: 'isOnSaturday',
      label: 'Sat'
    }, {
      key: 'isOnSunday',
      label: 'Sun'
    }];
    var ranges = weekdayOptions.reduce(function (accumulator, currentDay, index) {
      var isCurrentDayActive = schedule[currentDay.key];
      var previousDay = weekdayOptions[index - 1];
      var isPreviousDayActive = previousDay ? schedule[previousDay.key] : false;

      if (!isCurrentDayActive) {
        return accumulator;
      }

      if (isPreviousDayActive) {
        accumulator[accumulator.length - 1].push(currentDay);
      } else {
        accumulator.push([currentDay]);
      }

      return accumulator;
    }, []).map(function (range) {
      if (!range.length) {
        return '';
      }

      if (range.length === 7) {
        return 'Every Day';
      }

      var firstDayLabel = range[0].label;

      if (range.length > 1) {
        var lastDayLabel = range[range.length - 1].label;
        return "".concat(firstDayLabel, " - ").concat(lastDayLabel);
      }

      return firstDayLabel;
    });
    return ranges.join(', ');
  }
  /**
   * Returns a list of up to 2 pricing schedules in a following format:
   * "[[Schedule Name]]: [[Active Days Range]] [[Time Range]]"
   *
   * Example output: "Schedule 1: Mon - Fri, Sun 10:00 AM - 12:00 PM"
   *
   * @param {Array} params helper params
   * @param {String}  includeScheduleName - whether to include schedule name into output string
   * @returns {SafeString} formatted schedule list string
   */


  function renderPricingSchedules(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        schedules = _ref2[0],
        includeScheduleNameParam = _ref2[1],
        _ref2$ = _ref2[2],
        sortBy = _ref2$ === void 0 ? 'createdAt' : _ref2$;

    if (!schedules || !schedules.length) {
      return '';
    }

    var pricingSchedules = schedules.sortBy(sortBy).toArray();
    var includeScheduleName = includeScheduleNameParam !== null && includeScheduleNameParam !== void 0 ? includeScheduleNameParam : true;
    return Ember.String.htmlSafe(pricingSchedules.slice(0, AMOUNT_OF_SCHEDULES_TO_SHOW).map(function (schedule, index) {
      var scheduleName = '';

      if (includeScheduleName && schedule.name) {
        scheduleName = schedule.name.length > MAX_SCHEDULE_NAME_LENGTH ? "".concat(schedule.name.slice(0, MAX_SCHEDULE_NAME_LENGTH), "...: ") : "".concat(schedule.name, ": ");
      } // Prepare a time range for render


      var daysString = getDaysOfWeekRangeString(schedule);
      var hoursString = "".concat(getHoursString(schedule.startsAtSeconds), " - ").concat(getHoursString(schedule.endsAtSeconds)); // Indicate concatenation when there are more than 2 (by default) pricing schedules

      var ellipsisString = pricingSchedules.length > AMOUNT_OF_SCHEDULES_TO_SHOW && index === AMOUNT_OF_SCHEDULES_TO_SHOW - 1 ? '...' : '';
      return Ember.Handlebars.Utils.escapeExpression("".concat(scheduleName).concat(daysString, " ").concat(hoursString).concat(ellipsisString));
    }).join('<br/>'));
  }

  var _default = Ember.Helper.helper(renderPricingSchedules);

  _exports.default = _default;
});