define("lh4/serializers/internet-payments/customer", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      var replace = payloadKey.replace("".concat(payloadKey), "internet-payments/".concat(payloadKey));
      return this._super(replace);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var response = this.normalize(primaryModelClass, payload.customer, payload.customer.cardToken, requestType);
      response.data.id = payload.customer.cardToken;
      return response;
    }
  });

  _exports.default = _default;
});