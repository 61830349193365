define("lh4/helpers/lowercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lowerCase = lowerCase;
  _exports.default = void 0;

  /**
   * Converts string to lower case
   * @param {String} value - string to be converted
   * @returns {String} - new string representing the calling string converted to lower case
   */
  function lowerCase(value) {
    return value.toString().toLowerCase();
  }

  var _default = Ember.Helper.helper(lowerCase);

  _exports.default = _default;
});