define("lh4/models/reservations/setting", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var validations = (0, _emberCpValidations.buildValidations)({
    value: [(0, _emberCpValidations.validator)('email', {
      type: 'email',
      message: 'Email address is incorrect or empty',
      disabled: Ember.computed('model.id', function getter() {
        return this.model.id !== 'notification-email';
      })
    }), (0, _emberCpValidations.validator)('phone-number', {
      message: 'Phone number is incorrect or empty',
      disabled: Ember.computed('model.id', function getter() {
        return this.model.id !== 'notification-phone-number';
      })
    }), (0, _emberCpValidations.validator)(function (value, options, model) {
      // Turnaround Time & Rules
      if (model.id !== 'notification-email' && model.id !== 'notification-phone-number' && model.id !== 'notification-email-enabled' && model.id !== 'notification-phone-enabled') {
        var number = Number(value);

        if (Number.isInteger(number) && number > 0 && number < Number.MAX_SAFE_INTEGER) {
          return true;
        }

        var isMaxPartySize = model.id === 'max-party-size';
        var fieldLabel = isMaxPartySize ? 'Size' : 'Turnaround Time (min)';
        var fieldPartyCount = isMaxPartySize ? 'Maximum Party Size' : "Party of ".concat(model.get('id').split('-')[1]);
        return "".concat(fieldLabel, " in ").concat(fieldPartyCount, " cannot be empty, zero, negative or decimal");
      }

      return true;
    })]
  });

  var _default = _emberData.default.Model.extend(validations, {
    /* id: string */
    locationId: _emberData.default.attr('number'),
    value: _emberData.default.attr('string'),
    notificationEmailEnabled: Ember.computed(function getter() {
      return this.store.peekRecord('reservations/setting', 'notification-email-enabled');
    }),
    notificationPhoneEnabled: Ember.computed(function getter() {
      return this.store.peekRecord('reservations/setting', 'notification-phone-enabled');
    })
  });

  _exports.default = _default;
});