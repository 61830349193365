define("lh4/serializers/loyalty/program", ["exports", "lh4/serializers/loyalty/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    normalize: function normalize() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var
      /* modelClass */
      json = args[1];

      if (json.user) {
        json.ownerUserName = "".concat(json.user.firstName, " ").concat(json.user.lastName);
        delete json.user;
      }

      return this._super.apply(this, args);
    },
    serialize: function serialize() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      var locationIds = args[0].record.locationIds;

      var json = this._super.apply(this, args);

      json.locations = locationIds.map(function (x) {
        return Number(x);
      });
      return json;
    }
  });

  _exports.default = _default;
});