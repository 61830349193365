define("lh4/models/internet-payments/customer", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    nameList: (0, _attr.default)(),
    emailList: (0, _attr.default)(),
    cardNumber: (0, _attr.default)('string'),
    cardNetwork: (0, _attr.default)('string'),
    createDate: (0, _attr.default)('string'),
    cardBrand: (0, _attr.default)('string'),
    cardFirst4: (0, _attr.default)('string'),
    cardLast4: (0, _attr.default)('string'),
    cardToken: (0, _attr.default)('string'),
    customer: (0, _attr.default)(),
    deviceIdToIpAddress: (0, _attr.default)(),
    emailCount: (0, _attr.default)('number'),
    ipCount: (0, _attr.default)('number'),
    ipList: (0, _attr.default)(),
    lastRiskScore: (0, _attr.default)('string'),
    lastRiskScoreDate: (0, _attr.default)('string'),
    merchantId: (0, _attr.default)('string'),
    nameCount: (0, _attr.default)('number'),
    phoneCount: (0, _attr.default)('number'),
    phoneList: (0, _attr.default)(),
    totalSpent: (0, _attr.default)('string')
  });

  _exports.default = _default;
});