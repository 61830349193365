define("lh4/routes/authenticated/epro/workforce/links/sso", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    redirectUri: '/',
    pageTitle: '',
    beforeModel: function beforeModel(transition) {
      var _this = this;

      this.ajax.request("/api/v1/s4-workforce/app/sso/".concat(this.location.model.id, "?uri=").concat(encodeURIComponent(this.redirectUri))).then(function (res) {
        return window.location.replace(res.url);
      }).catch(function () {
        var _transition$from, _transition$from2;

        _this.flashMessagesHelper.pushMessage("Something went wrong. Failed to navigate to the ".concat(_this.pageTitle, " page."));

        var redirectUrl = (transition === null || transition === void 0 ? void 0 : (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : _transition$from.name) === 'authenticated.locations.location.echo-pro.loading' ? 'authenticated.epro.workforce' : transition === null || transition === void 0 ? void 0 : (_transition$from2 = transition.from) === null || _transition$from2 === void 0 ? void 0 : _transition$from2.name;

        _this.replaceWith(redirectUrl || 'authenticated.epro.workforce');
      });
    }
  });

  _exports.default = _default;
});