define("lh4/utils/validations", ["exports", "ember-changeset-cp-validations"], function (_exports, _emberChangesetCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildChangesetDependentValidations = buildChangesetDependentValidations;
  _exports.ValidationModel = void 0;

  /**
   * ValidationModel creates a read-only proxy for the model and superimposes the changes
   */
  var ValidationModel = Ember.Object.extend({
    // from ember docs: "if you try to call get on a property whose value is undefined,
    // the unknownProperty() method will be called on the object"
    // only "changes" and "content" are defined in this object so all other get calls will be sent here
    unknownProperty: function unknownProperty(key) {
      var _this$changes, _this$content;

      if (key === 'changes') {
        return Object.prototype.hasOwnProperty.call(this, 'changes') ? this.changes : undefined;
      }

      if (key === 'content') {
        return Object.prototype.hasOwnProperty.call(this, 'content') ? this.content : undefined;
      } // if we have a changed version of the key, return that


      if ((_this$changes = this.changes) !== null && _this$changes !== void 0 && _this$changes.hasOwnProperty(key)) {
        return this.changes[key];
      } // otherwise return the value directly from the model


      return (_this$content = this.content) === null || _this$content === void 0 ? void 0 : _this$content.get(key);
    }
  });
  /**
   * Mimic of the buildChangeset function that uses a proxy to accurately deal with dependent validations
   * Based on some code posted in this issue: https://github.com/offirgolan/ember-changeset-cp-validations/issues/25
   *
   * @param {Object} model instance of the model
   * @returns {Object} validationMap and validationFn required for changeset creation
   */

  _exports.ValidationModel = ValidationModel;

  function buildChangesetDependentValidations(model) {
    var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model),
        validationMap = _buildChangeset.validationMap;

    var validateFn = function validate(_ref) {
      var _proxy$get, _proxy$get$get, _proxy$get$get$key;

      var key = _ref.key,
          newValue = _ref.newValue,
          changes = _ref.changes,
          content = _ref.content;
      // make a proxy that will combine the model and the changes
      var validationModel = ValidationModel.create({
        content: content,
        changes: changes
      });
      var proxy = Ember.ObjectProxy.create(Ember.getOwner(model).ownerInjection(), {
        content: validationModel
      }); // update relavent validator options to use the proxy instead of a cached version of the model,
      // this allows any computed properties within buildValidations to use the changes
      // ex. disabled: not(...), lt: alias(...), gt: computed(...)
      // the way this is constructed makes eslint think it is an unused expression

      /* eslint-disable no-unused-expressions */

      (_proxy$get = proxy.get('validations')) === null || _proxy$get === void 0 ? void 0 : (_proxy$get$get = _proxy$get.get('_validators')) === null || _proxy$get$get === void 0 ? void 0 : (_proxy$get$get$key = _proxy$get$get[key]) === null || _proxy$get$get$key === void 0 ? void 0 : _proxy$get$get$key.map(function (validator) {
        var _validator$get;

        (_validator$get = validator.get('options')) === null || _validator$get === void 0 ? void 0 : _validator$get.set('model', proxy);
      }); // update the ember-cp-validations library to use the proxy instead of the current model,
      // this allows custom validators within buildValidations to use the changes
      // ex. validator((value, options, model) => { ... }
      // WARNING: this is private to ember-cp-validations and WILL break when we upgrade it

      var validationsClass = proxy.get('__VALIDATIONS_CLASS__').create({
        model: proxy
      });
      return validationsClass.validateAttribute(key, newValue).then(function (_ref2) {
        var validations = _ref2.validations;
        return validations.get('isValid') ? true : validations.get('message');
      });
    };

    return {
      validationMap: validationMap,
      validateFn: validateFn
    };
  }
});