define("lh4/models/echo-pro/pay-rate", ["exports", "ember-data", "lh4/mixins/model-cloneable", "ember-cp-validations", "ember-data/relationships", "lh4/utils/is-positive-decimal-or-zero"], function (_exports, _emberData, _modelCloneable, _emberCpValidations, _relationships, _isPositiveDecimalOrZero) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    jobGuid: (0, _emberCpValidations.validator)('presence', {
      presence: true
    }),
    payRate: {
      description: 'Pay Rate',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        gte: 0,
        description: 'Pay Rate',
        allowString: true,
        positive: true,
        message: 'Pay Rate must be a number greater than or equal to 0',
        allowNone: false
      }), (0, _emberCpValidations.validator)(function (value) {
        if (!(0, _isPositiveDecimalOrZero.default)(value.trim(), 2)) {
          return 'Pay Rate can consist of a maximum of 2 decimal digits';
        }

        return true;
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        positive: true,
        message: 'Pay Rate exceeds the maximum allowed value',
        lte: 10000000
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (Number(value) < Number(model.get('jobValue'))) {
          return "Pay Rate must be higher than job value of ".concat(model.get('jobValue'));
        }

        return true;
      })]
    }
  });

  var _default = _emberData.default.Model.extend(_modelCloneable.default, Validations, {
    location: Ember.inject.service(),
    locationId: _emberData.default.attr('number'),
    employeeGuid: _emberData.default.attr('string'),
    jobGuid: _emberData.default.attr('string'),
    payRate: _emberData.default.attr('string'),
    isVoided: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    employee: (0, _relationships.belongsTo)('echo-pro/employee'),
    name: Ember.computed('jobGuid', function getter() {
      return this.store.peekRecord('echo-pro/job', this.jobGuid).name;
    }),
    jobValue: Ember.computed('payRate', function getter() {
      if (this.jobGuid) {
        var job = this.store.peekRecord('echo-pro/job', this.jobGuid);
        return job ? job.payRate : null;
      }

      return null;
    })
  });

  _exports.default = _default;
});