define("lh4/helpers/bytes-to-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bytesToSize = bytesToSize;
  _exports.default = void 0;

  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
      return 'n/a';
    }

    var fileSize = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

    if (fileSize === 0) {
      return "".concat(bytes, " ").concat(sizes[fileSize]);
    }

    var exactFileSize = Math.round((bytes / Math.pow(1024, fileSize)).toFixed(1));
    return "".concat(exactFileSize, " ").concat(sizes[fileSize]);
  }

  var _default = Ember.Helper.helper(bytesToSize);

  _exports.default = _default;
});