define("lh4/templates/components/ui/radio-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q4/yHXbp",
    "block": "{\"symbols\":[\"checkBox\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"checkBoxes\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"ui-checkbox\",null,[[\"class\",\"label\",\"checked\",\"onChange\"],[\"radio-accordion-checkbox\",[23,1,[\"text\"]],[28,\"eq\",[[28,\"concat\",[[23,1,[\"value\"]],\"\"],null],[28,\"concat\",[[24,[\"selectedValue\"]],\"\"],null]],null],[28,\"action\",[[23,0,[]],\"checkBoxChanged\",[23,1,[\"value\"]]],null]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"radio-accordion-content\"],[8],[0,\"\\n\\t\\t\\t\"],[14,2,[[28,\"hash\",null,[[\"content\"],[[28,\"component\",[\"ui/radio-accordion/radio-accordion-content\"],[[\"current\",\"selectedValue\"],[[23,1,[\"value\"]],[24,[\"selectedValue\"]]]]]]]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/radio-accordion.hbs"
    }
  });

  _exports.default = _default;
});