define("lh4/components/ui/form-fields/textarea-field", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    // textarea attributes
    maxLength: null,
    placeholder: null,
    dataTest: 'form-field-textarea-field',
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.changeset || !this.changeset.data || !this.changeset.data.validations) {
        return;
      }

      var _ref = this.changeset.data.validations.attrs[this.property] || {},
          _ref$options = _ref.options,
          options = _ref$options === void 0 ? {} : _ref$options;

      if (options.length && options.length.max) {
        this.setProperties({
          maxLength: options.length.max
        });
      }
    },
    textareaClass: Ember.computed('fullWidth', function getter() {
      if (!this.label) {
        return 'sixteen wide column';
      }

      return "".concat(this.fullWidth ? 'twelve' : 'eight', " wide column");
    })
  });

  _exports.default = _default;
});