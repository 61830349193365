define("lh4/components/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isSticky: false,
    flashMessages: Ember.inject.service(),
    stickyClasses: Ember.computed('isSticky', function getter() {
      if (this.get('isSticky')) {
        return 'ui sticky fixed top full-width';
      }

      return '';
    })
  });

  _exports.default = _default;
});