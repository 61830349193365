define("lh4/components/dashboard-widgets/payment-methods/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    locale: Ember.inject.service(),
    classNames: ['payment-methods-data'],
    chartData: Ember.computed('data', function getter() {
      return this.get('data').sortBy('name').map(function (_ref) {
        var name = _ref.name,
            amount = _ref.amount;
        return {
          name: name,
          amount: amount
        };
      });
    }),
    skeletonData: {},
    chartOptions: Ember.computed('location', function getter() {
      var valueTemplate = this.locale.currencyTemplate('[[value]]');
      return {
        balloonText: '[[title]]<br><span style=\'font-size:14px\'><b>{{value}}</b> ([[percents]]%)</span>'.replace('{{value}}', valueTemplate),
        legendValueText: valueTemplate,
        percentPrecision: 0,
        precision: 2,
        titleField: 'name',
        valueField: 'amount'
      };
    }),
    noData: Ember.computed('data', function getter() {
      return Object.keys(this.get('data')).length === 0;
    })
  });

  _exports.default = _default;
});