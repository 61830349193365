define("lh4/enums/language-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LANGUAGE_CODE = Object.freeze({
    EN: 'en',
    LT: 'lt'
  });
  var _default = LANGUAGE_CODE;
  _exports.default = _default;
});