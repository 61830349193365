define("lh4/components/amcharts3/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pie-chart'],
    options: {},
    init: function init() {
      this._super.apply(this, arguments);

      this.chart = null;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _this$options = this.options,
          _this$options$balloon = _this$options.balloonText,
          balloonText = _this$options$balloon === void 0 ? '[[title]]<br><span style=\'font-size:14px\'><b>[[value]]</b> ([[percents]]%)</span>' : _this$options$balloon,
          percentPrecision = _this$options.percentPrecision,
          precision = _this$options.precision,
          _this$options$legendV = _this$options.legendValueText,
          legendValueText = _this$options$legendV === void 0 ? '[[value]]' : _this$options$legendV,
          titleField = _this$options.titleField,
          valueField = _this$options.valueField;
      var chart = window.AmCharts.makeChart(this.element, {
        type: 'pie',
        path: '/amcharts',
        allLabels: [],
        balloon: {
          borderColor: '#E5E5E5',
          color: '#333333',
          fillAlpha: 1
        },
        balloonText: balloonText,
        color: '#333333',
        colors: ['#009BF4', '#F6511D', '#FFB400', '#7FB800', '#0D2C54', '#7c579a'],
        hideCredits: true,
        hideLabelsPercent: 101,
        hoverAlpha: 0.77,
        innerRadius: '60%',
        labelColorField: '#333333',
        labelRadius: 4,
        legend: {
          enabled: true,
          valueText: legendValueText
        },
        maxLabelWidth: 75,
        outlineThickness: 0,
        percentPrecision: percentPrecision,
        precision: precision,
        radius: '40%',
        titleField: titleField,
        titles: [],
        valueField: valueField,
        dataProvider: this.data
      });
      this.chart = chart;
    },
    willDestroyElement: function willDestroyElement() {
      if (this.chart) {
        this.chart.clear();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});