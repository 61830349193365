define("lh4/components/dashboard-widgets/clearbanc/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['clearbanc-data'],
    skeletonData: {
      status: 'APPROVED',
      amount: 7500
    },
    location: Ember.inject.service()
  });

  _exports.default = _default;
});