define("lh4/mirage/fixtures/drill-down-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GC_TRANSACTION_REPORT_FIXTURE = _exports.GC_BALANCE_FIXTURE = _exports.GC_TRANSACTION_DETAIL_FIXTURE = void 0;
  var GC_TRANSACTION_DETAIL_FIXTURE = {
    trees: [{
      nodeType: 'transaction-detail',
      parentGuid: null,
      level: 0,
      rows: [{
        cols: [{
          colSpan: 8,
          rowSpan: 1,
          value: 'Transaction Detail Report',
          dataType: 'header'
        }],
        type: 'title'
      }],
      children: [{
        nodeType: 'group',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Date',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Type',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Waitperson',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Gift Card #',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Amount',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Balance After',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Void',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Void ID',
            dataType: 'header'
          }],
          type: 'header'
        }],
        children: [],
        isExpandable: false,
        guid: 'c69ae03b-e0e0-5c4a-8e79-df2c01f8436e'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 04:42:55 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Redeem',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x9837',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$75.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$25.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '52899b31-067f-54df-91a9-fb48eecb905f'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 04:43:14 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Redeem',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x3961',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$63.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$37.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '64a41c6c-00ea-5028-98c1-77ae64933156'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 04:43:29 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Redeem',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x8711',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$14.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$86.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: 'bc234d9d-6f51-546e-937d-f7a06f0b7bbc'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 04:43:42 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Redeem',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x8711',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$50.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$36.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '7176a237-dd5f-5acb-ba7d-0cc2e091260b'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 04:43:54 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Redeem',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x9057',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$100.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$0.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '6a37b197-2105-5153-b219-f1fc5731d5f1'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 04:44:10 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Reload',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x9057',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$200.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$200.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '5b3c72b3-49da-523e-8a04-2f7af8d9f3e7'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 04:44:34 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Redeem',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x0902',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$53.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$46.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '9cd6264c-95e7-5705-8adb-bbc281e0955f'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 04:50:41 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Reload',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x5885',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$50.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$150.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: 'd5d72ac8-3706-5dba-917b-ef4ef5b15cc8'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 05:13:25 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Sale',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x4706',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$200.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$200.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: 'd34b6972-d3b4-5257-acdd-2915b5b2eb99'
      }, {
        nodeType: 'data',
        parentGuid: '14332127-0ce8-568b-accc-179eb53a7c9c',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '10/29/20 05:13:44 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Redeem',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Michael Johnson',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'x4706',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$75.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$125.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '45330ca4-a96a-5c78-8fe9-a24f7471bd3b'
      }],
      isExpandable: true,
      guid: '14332127-0ce8-568b-accc-179eb53a7c9c'
    }],
    title: 'Transaction Detail Report',
    start: '2021-10-01T00:00:00.000Z',
    end: '2021-10-31T06:59:59.999Z'
  };
  _exports.GC_TRANSACTION_DETAIL_FIXTURE = GC_TRANSACTION_DETAIL_FIXTURE;
  var GC_BALANCE_FIXTURE = {
    trees: [{
      nodeType: 'balances',
      parentGuid: null,
      level: 0,
      rows: [{
        cols: [{
          colSpan: 7,
          rowSpan: 1,
          value: 'Balances Report',
          dataType: 'header'
        }],
        type: 'title'
      }],
      children: [{
        nodeType: 'balances',
        parentGuid: 'fd87e588-5b97-526a-a505-78af87a4e8d6',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 2,
            value: '',
            dataType: 'empty'
          }, {
            colSpan: 2,
            rowSpan: 1,
            value: 'Gift Card',
            dataType: 'header'
          }, {
            colSpan: 4,
            rowSpan: 1,
            value: 'Last Transaction',
            dataType: 'header'
          }],
          type: 'header'
        }, {
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Status',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Balance',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Date',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Transaction ID',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Type',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Amount',
            dataType: 'header'
          }],
          type: 'header'
        }],
        children: [{
          nodeType: 'group',
          parentGuid: 'f27f2303-eee6-5ccc-ad63-f7278502451a',
          level: 2,
          rows: [{
            cols: [{
              colSpan: 1,
              rowSpan: 1,
              value: 'Location 3',
              dataType: 'header'
            }, {
              colSpan: 1,
              rowSpan: 1,
              value: '',
              dataType: 'empty'
            }, {
              colSpan: 1,
              rowSpan: 1,
              value: '$862.00',
              dataType: 'money'
            }, {
              colSpan: 1,
              rowSpan: 1,
              value: '',
              dataType: 'empty'
            }, {
              colSpan: 1,
              rowSpan: 1,
              value: '',
              dataType: 'empty'
            }, {
              colSpan: 1,
              rowSpan: 1,
              value: '',
              dataType: 'empty'
            }, {
              colSpan: 1,
              rowSpan: 1,
              value: '',
              dataType: 'empty'
            }],
            type: 'group'
          }],
          children: [{
            nodeType: 'data',
            parentGuid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe',
            level: 3,
            rows: [{
              cols: [{
                colSpan: 1,
                rowSpan: 1,
                value: 'x1046',
                dataType: 'header'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'Active',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$24.00',
                dataType: 'money'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '05/27/20 12:02:35 AM',
                dataType: 'date-time'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '222913',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'SVUse',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$24.00',
                dataType: 'money'
              }],
              type: 'data'
            }],
            children: [],
            isExpandable: false,
            guid: 'b1928817-003a-578d-a1ac-2817feafc7f5'
          }, {
            nodeType: 'data',
            parentGuid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe',
            level: 3,
            rows: [{
              cols: [{
                colSpan: 1,
                rowSpan: 1,
                value: 'x8198',
                dataType: 'header'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'Active',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$150.00',
                dataType: 'money'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '05/27/20 12:00:20 AM',
                dataType: 'date-time'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '222905',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'SVRecharge',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$150.00',
                dataType: 'money'
              }],
              type: 'data'
            }],
            children: [],
            isExpandable: false,
            guid: '56cce114-63ef-56eb-aa2e-ac7b4fb7714a'
          }, {
            nodeType: 'data',
            parentGuid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe',
            level: 3,
            rows: [{
              cols: [{
                colSpan: 1,
                rowSpan: 1,
                value: 'x6903',
                dataType: 'header'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'Active',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$96.00',
                dataType: 'money'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '05/27/20 12:02:54 AM',
                dataType: 'date-time'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '222914',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'SVUse',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$96.00',
                dataType: 'money'
              }],
              type: 'data'
            }],
            children: [],
            isExpandable: false,
            guid: 'd9ac9db1-dd1d-5a25-a993-a6b94e38d8a1'
          }, {
            nodeType: 'data',
            parentGuid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe',
            level: 3,
            rows: [{
              cols: [{
                colSpan: 1,
                rowSpan: 1,
                value: 'x8999',
                dataType: 'header'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'Active',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$57.00',
                dataType: 'money'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '05/27/20 12:01:16 AM',
                dataType: 'date-time'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '222909',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'SVUse',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$57.00',
                dataType: 'money'
              }],
              type: 'data'
            }],
            children: [],
            isExpandable: false,
            guid: '7264f5be-b3d5-5bd7-8c64-988bf99a2e01'
          }, {
            nodeType: 'data',
            parentGuid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe',
            level: 3,
            rows: [{
              cols: [{
                colSpan: 1,
                rowSpan: 1,
                value: 'x7747',
                dataType: 'header'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'Active',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$95.00',
                dataType: 'money'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '05/27/20 12:02:56 AM',
                dataType: 'date-time'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '222916',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'SVUse',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$95.00',
                dataType: 'money'
              }],
              type: 'data'
            }],
            children: [],
            isExpandable: false,
            guid: '3104f2a5-b022-50ee-9404-c23c086b4231'
          }, {
            nodeType: 'data',
            parentGuid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe',
            level: 3,
            rows: [{
              cols: [{
                colSpan: 1,
                rowSpan: 1,
                value: 'x5655',
                dataType: 'header'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'Active',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$140.00',
                dataType: 'money'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '05/27/20 12:03:44 AM',
                dataType: 'date-time'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '222927',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'SVRecharge',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$140.00',
                dataType: 'money'
              }],
              type: 'data'
            }],
            children: [],
            isExpandable: false,
            guid: 'df753945-ad90-5b3b-8f3c-d81a273ad91d'
          }, {
            nodeType: 'data',
            parentGuid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe',
            level: 3,
            rows: [{
              cols: [{
                colSpan: 1,
                rowSpan: 1,
                value: 'x6490',
                dataType: 'header'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'Active',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$100.00',
                dataType: 'money'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '05/26/20 11:46:07 PM',
                dataType: 'date-time'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '222898',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'SVSale',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$100.00',
                dataType: 'money'
              }],
              type: 'data'
            }],
            children: [],
            isExpandable: false,
            guid: '2e854a17-f0e6-5a30-8d95-80d14cbe7aa3'
          }, {
            nodeType: 'data',
            parentGuid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe',
            level: 3,
            rows: [{
              cols: [{
                colSpan: 1,
                rowSpan: 1,
                value: 'x1323',
                dataType: 'header'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'Active',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$100.00',
                dataType: 'money'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '05/26/20 11:46:08 PM',
                dataType: 'date-time'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '222899',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'SVSale',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$100.00',
                dataType: 'money'
              }],
              type: 'data'
            }],
            children: [],
            isExpandable: false,
            guid: '788a8036-4d49-5cde-80ff-c016b3eb8adc'
          }, {
            nodeType: 'data',
            parentGuid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe',
            level: 3,
            rows: [{
              cols: [{
                colSpan: 1,
                rowSpan: 1,
                value: 'x8860',
                dataType: 'header'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'Active',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$100.00',
                dataType: 'money'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '05/26/20 11:46:11 PM',
                dataType: 'date-time'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '222902',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: 'SVSale',
                dataType: 'string'
              }, {
                colSpan: 1,
                rowSpan: 1,
                value: '$100.00',
                dataType: 'money'
              }],
              type: 'data'
            }],
            children: [],
            isExpandable: false,
            guid: '6e3186b9-0163-5be5-be87-a0d13d723a61'
          }],
          isExpandable: true,
          guid: '9a725252-f74a-5b92-ae52-d90c03d9b9fe'
        }],
        isExpandable: true,
        guid: 'f27f2303-eee6-5ccc-ad63-f7278502451a'
      }],
      isExpandable: true,
      guid: 'fd87e588-5b97-526a-a505-78af87a4e8d6'
    }],
    title: 'Balances Report',
    start: '2021-10-01T00:00:00.000Z',
    end: '2021-10-31T06:59:59.999Z'
  };
  _exports.GC_BALANCE_FIXTURE = GC_BALANCE_FIXTURE;
  var GC_TRANSACTION_REPORT_FIXTURE = {
    trees: [{
      nodeType: 'transaction-summary',
      parentGuid: null,
      level: 0,
      rows: [{
        cols: [{
          dataType: 'header',
          colSpan: 5,
          rowSpan: 1,
          value: 'Transaction Summary Report'
        }],
        type: 'title'
      }],
      children: [{
        nodeType: 'group',
        parentGuid: '88efff34-38c5-5efb-ae9c-5615117898dd',
        level: 1,
        rows: [{
          cols: [{
            dataType: 'empty',
            colSpan: 2,
            rowSpan: 2,
            value: ''
          }, {
            dataType: 'header',
            colSpan: 2,
            rowSpan: 1,
            value: 'Sold'
          }, {
            dataType: 'header',
            colSpan: 2,
            rowSpan: 1,
            value: 'Redeemed'
          }, {
            dataType: 'header',
            colSpan: 2,
            rowSpan: 1,
            value: 'Expired'
          }, {
            dataType: 'header',
            colSpan: 2,
            rowSpan: 1,
            value: 'Total'
          }],
          type: 'header'
        }, {
          cols: [{
            dataType: 'header',
            colSpan: 1,
            rowSpan: 1,
            value: 'Count'
          }, {
            dataType: 'header',
            colSpan: 1,
            rowSpan: 1,
            value: 'Amount'
          }, {
            dataType: 'header',
            colSpan: 1,
            rowSpan: 1,
            value: 'Count'
          }, {
            dataType: 'header',
            colSpan: 1,
            rowSpan: 1,
            value: 'Amount'
          }, {
            dataType: 'header',
            colSpan: 1,
            rowSpan: 1,
            value: 'Count'
          }, {
            dataType: 'header',
            colSpan: 1,
            rowSpan: 1,
            value: 'Amount'
          }, {
            dataType: 'header',
            colSpan: 1,
            rowSpan: 1,
            value: 'Count'
          }, {
            dataType: 'header',
            colSpan: 1,
            rowSpan: 1,
            value: 'Amount'
          }],
          type: 'header'
        }],
        children: [],
        isExpandable: false,
        guid: '95a78fb7-81cf-5642-a086-15a7935e1477'
      }, {
        nodeType: 'transaction-summary',
        parentGuid: '88efff34-38c5-5efb-ae9c-5615117898dd',
        level: 1,
        rows: [{
          cols: [{
            dataType: 'header',
            colSpan: 2,
            rowSpan: 1,
            value: 'Total'
          }, {
            dataType: 'integer',
            colSpan: 1,
            rowSpan: 1,
            value: '3'
          }, {
            dataType: 'money',
            colSpan: 1,
            rowSpan: 1,
            value: '$4.50'
          }, {
            dataType: 'integer',
            colSpan: 1,
            rowSpan: 1,
            value: '7'
          }, {
            dataType: 'money',
            colSpan: 1,
            rowSpan: 1,
            value: '-$4.30'
          }, {
            dataType: 'integer',
            colSpan: 1,
            rowSpan: 1,
            value: '0'
          }, {
            dataType: 'money',
            colSpan: 1,
            rowSpan: 1,
            value: '$0.00'
          }, {
            dataType: 'integer',
            colSpan: 1,
            rowSpan: 1,
            value: '10'
          }, {
            dataType: 'money',
            colSpan: 1,
            rowSpan: 1,
            value: '$0.19'
          }],
          type: 'total'
        }],
        children: [{
          nodeType: 'transaction-summary',
          parentGuid: '1de93777-ebdc-59b7-8872-287a335e3e2c',
          level: 2,
          rows: [{
            cols: [{
              dataType: 'header',
              colSpan: 2,
              rowSpan: 1,
              value: '0000000002 - Location 1'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '0'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '$0.00'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '3'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '-$2.13'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '0'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '$0.00'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '3'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '-$2.13'
            }],
            type: 'data'
          }],
          children: [],
          isExpandable: false,
          guid: '87a3b65c-765c-59fe-b630-9971e47f3f40'
        }, {
          nodeType: 'transaction-summary',
          parentGuid: '1de93777-ebdc-59b7-8872-287a335e3e2c',
          level: 2,
          rows: [{
            cols: [{
              dataType: 'header',
              colSpan: 2,
              rowSpan: 1,
              value: '0000000003 - Location 2'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '1'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '$0.50'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '1'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '-$0.14'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '0'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '$0.00'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '2'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '$0.36'
            }],
            type: 'data'
          }],
          children: [],
          isExpandable: false,
          guid: '69edd60a-473c-5278-86bd-1a485300349d'
        }, {
          nodeType: 'transaction-summary',
          parentGuid: '1de93777-ebdc-59b7-8872-287a335e3e2c',
          level: 2,
          rows: [{
            cols: [{
              dataType: 'header',
              colSpan: 2,
              rowSpan: 1,
              value: '0000000004 - Location 3'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '2'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '$4.00'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '3'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '-$2.03'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '0'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '$0.00'
            }, {
              dataType: 'integer',
              colSpan: 1,
              rowSpan: 1,
              value: '5'
            }, {
              dataType: 'money',
              colSpan: 1,
              rowSpan: 1,
              value: '$1.96'
            }],
            type: 'data'
          }],
          children: [],
          isExpandable: false,
          guid: 'e8d2cb12-4cd8-5caa-ac2e-9175948bf6b4'
        }],
        isExpandable: true,
        guid: '1de93777-ebdc-59b7-8872-287a335e3e2c'
      }],
      isExpandable: true,
      guid: '88efff34-38c5-5efb-ae9c-5615117898dd'
    }],
    title: 'Transaction Summary Report',
    start: '2021-10-01T00:00:00-04:00',
    end: '2021-10-31T23:59:00-04:00'
  };
  _exports.GC_TRANSACTION_REPORT_FIXTURE = GC_TRANSACTION_REPORT_FIXTURE;
});