define("lh4/templates/components/day-part-category-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GlZR20J9",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ui/form-fields/input-field\",null,[[\"changeset\",\"label\",\"property\",\"showErrors\",\"data-test-day-part-category-form-name\"],[[24,[\"intradayPeriodGroup\"]],\"Name\",\"intradayPeriodGroupName\",[24,[\"showErrors\"]],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/day-part-category-form.hbs"
    }
  });

  _exports.default = _default;
});