define("lh4/mirage/routes/integration/s4/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageIntegrationsEventsServer;

  function setMirageIntegrationsEventsServer(server) {
    server.loadFixtures('integration/s4/events');
    server.get('/integration/s4/events', function (schema) {
      return {
        events: schema['integration/s4/events'].all().models
      };
    });
  }
});