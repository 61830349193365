define("lh4/templates/authenticated/internet-payments/developers/webhooks/details/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bOEAMh+i",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"changeset\",\"errors\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui developer-webhooks-settings-error-message\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui icon exclamation circle\"],[8],[9],[0,\"\\n\\t\\t\"],[1,[23,0,[\"changeset\",\"errors\",\"0\",\"validation\"]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"developer-webhooks-settings\"],[8],[0,\"\\n\\t\"],[5,\"internet-payments/developers/webhooks/general-settings-form\",[],[[\"@changeset\",\"@onUpdateForm\"],[[23,0,[\"changeset\"]],[23,0,[\"onUpdateForm\"]]]]],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"developer-settings-actions\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ip-button\",[28,\"if\",[[23,0,[\"isSaving\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"save\"]]]],[8],[0,\"\\n\\t\\t\\tSave Changes\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ip-button inverted\",[28,\"if\",[[23,0,[\"isDeleting\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"delete\"]]]],[8],[0,\"\\n\\t\\t\\tDelete\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/developers/webhooks/details/settings.hbs"
    }
  });

  _exports.default = _default;
});