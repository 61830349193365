define("lh4/utils/combine-dates", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = combineDates;

  /**
   * Combine date string and time string into single moment object
   *
   * @param {string|Date} day Date containing day/month/year to combine with the time
   * @param {string} time String in hh:mmA format
   * @returns {moment} Combined moment object
   */
  function combineDates(day, time) {
    var isBusinessDay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    if (!day) {
      return false;
    }

    if (!time) {
      return (0, _moment.default)(day);
    }

    if (!isBusinessDay) {
      var _timeMoment = (0, _moment.default)(time, 'hh:mmA').utc(true);

      return _moment.default.utc(day).hour(_timeMoment.hour()).minute(_timeMoment.minute());
    }

    var timeMoment = (0, _moment.default)(time, 'hh:mmA');
    return (0, _moment.default)(day).hour(timeMoment.hour()).minute(timeMoment.minute());
  }
});