define("lh4/routes/authenticated/customer-engagement/campaigns/overview/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    campaignApi: Ember.inject.service('customer-engagement/campaign-api'),
    model: function model() {
      var _this$paramsFor = this.paramsFor('authenticated.customer-engagement.campaigns.overview'),
          campaignId = _this$paramsFor.campaignId;

      var campaign = this.store.peekRecord('customer-engagement/campaign', campaignId);
      return Ember.RSVP.hash({
        campaign: campaign,
        location: this.store.findRecord('location', campaign.get('locationId'))
      });
    }
  });

  _exports.default = _default;
});