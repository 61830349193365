define("lh4/models/hospitality/item-group", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    description: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    locationId: (0, _attr.default)('number'),
    priceCategoryId: (0, _attr.default)('number'),
    description: (0, _attr.default)('string'),
    dollarAmount: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    priceCategoryOrdinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    courseGroupId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    preferredCourseId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    exportId: (0, _attr.default)('string', {
      defaultValue: null
    }),
    rvPriceCategory: (0, _attr.default)(),
    rowguid: (0, _attr.default)('string'),
    menuItems: (0, _attr.default)()
  });

  _exports.default = _default;
});