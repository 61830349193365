define("lh4/controllers/authenticated/epro/timeclock/shifts/create", ["exports", "lh4/controllers/authenticated/epro/timeclock/shifts/general"], function (_exports, _general) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _general.default.extend({});

  _exports.default = _default;
});