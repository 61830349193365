define("lh4/templates/components/ui/navigation/vertical-navigation-categories/skytab-bi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eT0YvxV3",
    "block": "{\"symbols\":[\"@notificationList\",\"@skytabBi\",\"@navOpenHover\",\"@navCloseHover\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"sidebar-content\"],[12,\"data-test-nav-bar\",[23,2,[]]],[3,\"on\",[\"mouseenter\",[23,3,[]]]],[3,\"on\",[\"mouseleave\",[23,4,[]]]],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"row\",\"authenticated.analytics\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[1,[22,\"ui/icons/sidebar/skytab-bi\"],false],[9],[0,\"\\n\\t\\t\"],[1,[28,\"ui/navigation/nav-item\",null,[[\"name\",\"notificationList\"],[\"SkyTab BI\",[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/navigation/vertical-navigation-categories/skytab-bi.hbs"
    }
  });

  _exports.default = _default;
});