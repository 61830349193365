define("lh4/templates/components/ui/navigation/vertical-navigation-categories/updates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3oTSCHXT",
    "block": "{\"symbols\":[\"@navOpenHover\",\"@navCloseHover\",\"@isUpdatesActive\",\"@notificationList\"],\"statements\":[[4,\"link-to\",null,[[\"class\",\"data-test-nav\",\"route\"],[\"vertical\",\"updates\",\"authenticated.updates\"]],{\"statements\":[[0,\"\\t\"],[7,\"div\",false],[12,\"class\",\"sidebar-content has-sub-nav\"],[3,\"on\",[\"mouseenter\",[23,1,[]]]],[3,\"on\",[\"mouseleave\",[23,2,[]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"row \",[28,\"if\",[[23,3,[]],\"active\"],null]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[1,[22,\"ui/icons/sidebar/update\"],false],[9],[0,\"\\n\\t\\t\\t\"],[1,[28,\"ui/navigation/nav-item\",null,[[\"name\",\"notificationList\"],[\"Updates\",[23,4,[]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/navigation/vertical-navigation-categories/updates.hbs"
    }
  });

  _exports.default = _default;
});