define("lh4/controllers/authenticated/epro/manage-employees", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    routeData: Ember.computed('location.model.merchantId', 'launchDarkly.featureFlags', function routeData() {
      if (this.can.can('manage workforce for echo-pro/workforce', this.location.model)) {
        var availableRoutes = [];

        if (this.can.can('manage employees and jobs for echo-pro/workforce', this.location.model)) {
          availableRoutes.push({
            name: 'authenticated.epro.manage-employees.employees',
            title: 'Employees'
          }, {
            name: 'authenticated.epro.manage-employees.jobs',
            title: 'Jobs'
          });

          if (this.can.can('manage tip sharing for echo-pro/workforce', this.location.model)) {
            availableRoutes.push({
              name: 'authenticated.epro.manage-employees.tip-sharing',
              title: 'Tip Sharing'
            });
          }
        }

        if (this.can.can('manage timeclock for echo-pro/workforce', this.location.model)) {
          availableRoutes.push({
            name: 'authenticated.epro.timeclock.settings',
            title: 'Labor Settings'
          }, {
            name: 'authenticated.epro.workforce.links.timecards',
            title: 'Timecards'
          });
        }

        if (this.can.can('manage scheduler for echo-pro/workforce', this.location.model)) {
          availableRoutes.push({
            name: 'authenticated.epro.workforce.links.scheduler',
            title: 'Scheduler'
          });
        }

        return availableRoutes;
      }

      var defaultRoutes = [{
        name: 'authenticated.epro.manage-employees.employees',
        title: 'Employees'
      }, {
        name: 'authenticated.epro.manage-employees.jobs',
        title: 'Jobs'
      }];

      if (this.can.can('manage tip sharing for echo-pro/workforce', this.location.model)) {
        defaultRoutes.push({
          name: 'authenticated.epro.manage-employees.tip-sharing',
          title: 'Tip Sharing'
        });
      }

      return defaultRoutes;
    })
  });

  _exports.default = _default;
});