define("lh4/controllers/authenticated/epro/payments/tenders", ["exports", "lh4/controllers/general-list-controller", "lh4/mixins/list-status-options"], function (_exports, _generalListController, _listStatusOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend(_listStatusOptions.default, {
    corporation: Ember.inject.service(),
    intl: Ember.inject.service(),
    location: Ember.inject.service(),
    tenders: Ember.computed.filter('model.tenders', function filterCallback(tender) {
      switch (tender.name) {
        case 'House Account':
          return this.model.settings.customer.get('allowHouseAccounts') === '1';

        case 'Virtual':
          return this.model.settings.mobileWallet.get('vnEnabled') === '1';

        case 'Debit':
        case 'Cash Rounding':
          return false;

        default:
          return true;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super((_this$location = this.location) === null || _this$location === void 0 ? void 0 : (_this$location$model = _this$location.model) === null || _this$location$model === void 0 ? void 0 : _this$location$model.id);
    })
  });

  _exports.default = _default;
});