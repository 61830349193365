define("lh4/controllers/authenticated/epro/accounting/surcharges/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    corporation: Ember.inject.service()
  });

  _exports.default = _default;
});