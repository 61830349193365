define("lh4/components/charts/bar/overview-bar", ["exports", "d3-scale", "d3-axis", "d3-selection", "d3-array", "d3-format"], function (_exports, _d3Scale, _d3Axis, _d3Selection, _d3Array, _d3Format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['overview-bar'],
    // data: {
    // 	headers: ['Something Else', 'Gross Sales', 'Net Income'],
    // 	name: 'Sales by Revenue Class',
    // 	results: [['Food', 30, 30], ['Drink', 10, 10], ['Misc', 20, 20], ['Total', 220, 220]]
    // },
    init: function init() {
      this._super.apply(this, arguments);

      this.margin = {
        top: 60,
        right: 10,
        bottom: 30,
        left: 60
      };
      this.width = 875 - this.margin.left - this.margin.right;
      this.height = 530 - this.margin.top - this.margin.bottom;
      this.x = (0, _d3Scale.scaleBand)().range([0, this.width]).padding(0.45);
      this.y = (0, _d3Scale.scaleLinear)().range([this.height, 0]);
      this.updateAxes();
    },
    updateAxes: function updateAxes() {
      var makeMoney = (0, _d3Format.format)('$');
      this.xAxis = (0, _d3Axis.axisBottom)(this.x);
      this.yAxis = (0, _d3Axis.axisLeft)(this.y).ticks(4).tickFormat(makeMoney);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var chartId = "#overview-chart-1".concat(this.get('chartId'));
      var svg = (0, _d3Selection.select)(chartId).attr('width', '100%').attr('height', '100%').attr('viewBox', '0 0 875 530').attr('preserveAspectRatio', 'xMinYMin').append('g').attr('transform', "translate(".concat(this.margin.left, ", ").concat(this.margin.top, ")"));
      svg.append('g').attr('class', 'x axis').attr('transform', "translate(0, ".concat(this.height, ")")).style('font-size', '18px').call(this.xAxis);
      svg.append('g').attr('class', 'y axis').style('font-size', '15px').call(this.yAxis);
      this.updateChart();
    },
    rerenderOnDataChange: Ember.observer('data.results.[]', function callback() {
      this.updateChart();
    }),
    updateChart: function updateChart() {
      var _this = this;

      var data = this.get('chartData');
      var column = this.get('column');
      var noTotalData = Ember.copy(data.results);
      noTotalData.forEach(function (x) {
        if (x[0] === 'Total') {
          noTotalData.pop();
        }
      });
      this.x.domain(noTotalData.map(function (d) {
        return d[0];
      }));
      this.y.domain([0, (0, _d3Array.max)(noTotalData, function (d) {
        return d[column];
      })]);
      this.updateAxes();
      var chartId = "#overview-chart-1".concat(this.get('chartId'));
      var svg = (0, _d3Selection.select)(chartId).select('g');
      svg.select('.x.axis').call(this.xAxis);
      svg.select('.y.axis').call(this.yAxis);
      var bar = svg.selectAll('.bar').data(noTotalData, function (d) {
        return d[column];
      });
      bar.exit().remove(); // exit
      // only create a bar if the total is greater than 0

      bar.enter().append('rect') // enter
      .attr('class', 'bar').attr('x', function (d) {
        return _this.x(d[0]);
      }).attr('width', this.x.bandwidth()).attr('y', function (d) {
        return _this.y(d[column]);
      }).attr('height', function (d) {
        return _this.height - _this.y(d[column]);
      }).merge(bar) // update
      .attr('class', 'bar').attr('fill', this.get('barChartFill')).style('stroke-width', '1px').style('stroke', '#006EA6');
      bar.enter().append('text').attr('class', 'text').attr('x', function (d) {
        return _this.x(d[0]) + _this.x.bandwidth() / 2;
      }).attr('y', function (d) {
        return _this.y(d[column]) - 10;
      }).attr('fill', '#000000').style('text-anchor', 'middle').style('font-size', '20px').text(function (d) {
        if (_this.get('currency')) {
          return "$ ".concat((0, _d3Format.format)('.2f')(d[column]));
        }

        return "".concat(d[column]);
      });
    }
  });

  _exports.default = _default;
});