define("lh4/mirage/routes/internet-payments/acquirer-response-codes", ["exports", "lh4/mirage/fixtures/internet-payments/acquirer-response-codes"], function (_exports, _acquirerResponseCodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsAcquirerServer;

  function setMirageInternetPaymentsAcquirerServer(server) {
    server.get('/internet-payments/acquirer-response-codes', function () {
      return _acquirerResponseCodes.default;
    });
  }
});