define("lh4/components/reputation/accounts-table", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /* Params */
    accounts: [],
    filterChanged: null,
    selectAccount: null,
    deleteAccount: null,
    location: null,

    /* Component */
    limit: 25,
    offset: 0,
    headers: [{
      name: 'Name',
      field: 'name',
      width: 'five',
      sortable: false
    }, {
      name: 'Avg. Rating',
      field: 'value',
      width: 'three',
      sortable: false
    }, {
      name: 'Votes',
      field: 'name',
      width: 'three',
      sortable: false
    }, {
      name: 'Reviews',
      field: 'isActive',
      width: 'three',
      sortable: false
    }, {
      name: '',
      field: 'actionDelete',
      width: 'two',
      sortable: false
    }],
    accountsDisplay: Ember.computed('accounts.[]', 'offset', 'limit', 'location', function getter() {
      var accounts = this.get('accounts');
      var location = this.get('location');
      var offset = this.get('offset');
      var limit = this.get('limit');

      if (!accounts || accounts.get('length') === 0) {
        return [];
      }

      if (location === null || location.id === null) {
        return accounts.get('length') <= limit ? accounts : accounts.slice(offset, offset + limit);
      }

      var accountsByLocation = accounts.filter(function (acc) {
        return acc.locationId === Number(location.id);
      });
      return accountsByLocation.length <= limit ? accountsByLocation : accountsByLocation.splice(offset, limit);
    }),
    actions: {
      filterChanged: function filterChanged(value) {
        this.sendAction('filterChanged', value);
      },
      selectAccount: function selectAccount(account) {
        this.sendAction('selectAccount', account);
      },
      showDeleteDialog: function showDeleteDialog(account) {
        this.set('accountToDelete', account);
        (0, _jquery.default)('#delete-reputation-account-dialog').modal('show');
      },
      deleteAccount: function deleteAccount() {
        this.sendAction('deleteAccount', this.get('accountToDelete'));
      }
    }
  });

  _exports.default = _default;
});