define("lh4/utils/apply-changeset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(changeset) {
    return changeset.validate().then(function (result) {
      if (changeset.isInvalid) {
        var error = new Error('invalid changeset');
        error.isChangesetError = true;
        error.details = result;
        throw error;
      }

      changeset.execute(); // Flush changes to backing object

      changeset.rollback(); // Reset changeset to clean state
    });
  };

  _exports.default = _default;
});