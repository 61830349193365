define("lh4/models/processing/transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    ImportDate: _emberData.default.attr('date'),
    MerchantNo: _emberData.default.attr('string'),
    VendorName: _emberData.default.attr('string'),
    CreditCardNo: _emberData.default.attr('string'),
    CVVCode: _emberData.default.attr('string'),
    BatchTotal: _emberData.default.attr('number'),
    CountryCode: null,
    ForeignFlag: null,
    CardType: null,
    TerminalType: null,
    TransactionType: _emberData.default.attr('string'),
    TransactionDate: _emberData.default.attr('string'),
    CardExpDate: _emberData.default.attr('string'),
    SequenceNo: null,
    TransactionCode: _emberData.default.attr('string'),
    TransactionAmount: _emberData.default.attr('number'),
    OtherAmount: _emberData.default.attr('number'),
    TotalBatchTransactions: _emberData.default.attr('number'),
    ReferenceNo: _emberData.default.attr('string'),
    SalesRejectCode: null,
    SystemTime: null,
    ApprovalCode: _emberData.default.attr('string'),
    VendorResponseCode: null,
    AddressVerificationFlag: null,
    OriginalCurrencyCode: _emberData.default.attr('string'),
    POSEntryMode: _emberData.default.attr('string'),
    SettleFlag: _emberData.default.attr('string'),
    ECommerceInd: _emberData.default.attr('string'),
    BatchNo: _emberData.default.attr('number'),
    TerminalNo: _emberData.default.attr('string'),
    IsSwiped: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});