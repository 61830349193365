define("lh4/utils/gpatt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isGpattSupported;
  var PRODUCT_GPATT = 'gpatt';
  /**
   * Check if GPATT is supported for current location
   * @param {object} location - location model
   * @returns {boolean} true if supports
   */

  function isGpattSupported(location) {
    var result = location.terminalProducts && location.terminalProducts.length && location.terminalProducts.includes(PRODUCT_GPATT);
    return !!result;
  }
});