define("lh4/routes/authenticated/settings/2fa/setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.session.data.authenticated.is2fa) {
        this.transitionTo('authenticated.settings.2fa');
      }
    }
  });

  _exports.default = _default;
});