define("lh4/controllers/authenticated/locations/location/overview", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NOTIFICATIONS_KEY = 'locationOverview';
  var NOTIFICATIONS_TYPE = {
    statement: 'statements',
    tax: '1099s',
    notice: 'notices'
  };

  var _default = Ember.Controller.extend({
    permissions: Ember.inject.service(),
    notifications: Ember.inject.service(),
    can: Ember.inject.service(),
    showAllTerminals: false,
    terminals: Ember.computed('model.overview', 'showAllTerminals', function getter() {
      if (this.get('model.overview.terminals')) {
        if (this.get('showAllTerminals')) {
          return this.get('model.overview.terminals').sortBy('lastCalledAt').reverse();
        }

        return this.get('model.overview.terminals').sortBy('lastCalledAt').reverse().splice(0, 2);
      }

      return false;
    }),
    showAllEmployees: false,
    clockedIn: Ember.computed('model.overview', 'showAllEmployees', function getter() {
      if (this.get('model.overview.clockedIn')) {
        if (this.get('showAllEmployees')) {
          return this.get('model.overview.clockedIn');
        }

        return this.get('model.overview.clockedIn').sortBy('status').splice(0, 4);
      }

      return false;
    }),
    chartData: Ember.computed('model.overview.batches', function getter() {
      return this.get('model.overview.batches').map(function (_ref) {
        var TransDate = _ref.TransDate,
            value = _ref.TotalAmt;
        return {
          date: _moment.default.utc(TransDate).format(_moment.default.HTML5_FMT.DATE),
          value: value
        };
      }).sortBy('date');
    }),
    chartOptions: {
      balloonText: '$[[value]]',
      categoryField: 'date',
      dataDateFormat: _moment.default.HTML5_FMT.DATE,
      parseDates: true,
      valueAxesOptions: {
        precision: 2,
        unit: '$',
        unitPosition: 'left'
      },
      valueField: 'value'
    },
    noticeDates: Ember.computed('model.overview.notices', function getter() {
      return this.get('model.overview.notices').map(function (notice) {
        return notice.get('date').format('YYYYMM');
      });
    }),
    selectedNotices: Ember.computed('selectedNoticesDate', function getter() {
      var date = this.get('selectedNoticesDate');
      return this.get('model.overview.notices').filter(function (notice) {
        return date.isSame(notice.get('date'), 'month');
      });
    }),
    actions: {
      addBankAccount: function addBankAccount() {
        this.transitionToRoute('authenticated.bank-account.verify-owner');
      },
      terminalToggle: function terminalToggle() {
        this.toggleProperty('showAllTerminals');
      },
      employeesToggle: function employeesToggle() {
        this.toggleProperty('showAllEmployees');
      },
      select1099: function select1099(value, id) {
        this.set('selected1099', {
          url: value,
          year: id
        });
      },
      selectNoticesDate: function selectNoticesDate(date) {
        this.set('selectedNoticesDate', date);
      },
      handleStatementClick: function handleStatementClick() {
        if (this.notifications.status.locationOverview.statements) {
          this.notifications.saveData(NOTIFICATIONS_KEY, NOTIFICATIONS_TYPE.statement);
        }
      },
      handleNoticeClick: function handleNoticeClick() {
        if (this.notifications.status.locationOverview.notices) {
          this.notifications.saveData(NOTIFICATIONS_KEY, NOTIFICATIONS_TYPE.notice);
        }
      },
      handleTaxClick: function handleTaxClick() {
        if (this.notifications.status.locationOverview['1099s']) {
          this.notifications.saveData(NOTIFICATIONS_KEY, NOTIFICATIONS_TYPE.tax);
        }
      }
    }
  });

  _exports.default = _default;
});