define("lh4/controllers/authenticated/updates/edit", ["exports", "lh4/controllers/general-form-controller", "jquery", "moment"], function (_exports, _generalFormController, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    actions: {
      delete: function _delete() {
        var _this = this;

        if (this.isDeleting) {
          return;
        }

        this.setProperties({
          isDeleting: true,
          showErrors: true
        });
        var updateGuid = this.changeset.get('guid');
        this.ajax.delete("/api/v1/corporations/".concat(this.location.model.corpId, "/menu-updates/").concat(updateGuid)).then(function () {
          _this.closeModal();
        }).finally(function () {
          return _this.set('isDeleting', false);
        });
      },
      update: function update(trackUpdateEvent) {
        var _this2 = this;

        var element = (0, _jquery.default)('.date-picker-input');
        var picker = element.data('calentim');

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError();
        var snapshot = this.changeset.snapshot();
        this.changeset.set('scheduledForFormatted', (0, _moment.default)(picker.config.startDate).format('YYYY-MM-DD'));
        this.changeset.validate().then(function () {
          if (_this2.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this2.changeset.get('errors'));
          }

          if (_this2.isModelAndChangesetPristine()) {
            _this2.closeTray();

            return Ember.RSVP.resolve();
          }

          return _this2.changeset.save().then(function () {
            if (trackUpdateEvent) {
              trackUpdateEvent();
            }

            _this2.closeTray();
          }).catch(function (_ref) {
            var errors = _ref.errors;

            _this2.changeset.restore(snapshot);

            _this2.changeset.data.rollbackAttributes();

            if (errors && errors[0]) {
              var updateError = "Something went wrong, ".concat(_this2.instanceName, " could not be updated.");
              errors.forEach(function (error) {
                var errorMessage = _this2.getErrorMessage(error, updateError);

                _this2.set('serverError', errorMessage);

                _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                  _this2.set('serverError', null);
                }, _this2.serverErrorTimeoutDelay));
              });
            }
          });
        }).catch(function (errors) {
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this2.set('booleanError', booleanError.validation);

              _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                _this2.set('booleanError', null);
              }, _this2.serverErrorTimeoutDelay));
            }
          }
        }).finally(function () {
          return _this2.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});