define("lh4/templates/components/supply-orders/order-wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TEYiRwNM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segments\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[8],[0,\"Order Supplies\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/supply-orders/order-wizard.hbs"
    }
  });

  _exports.default = _default;
});