define("lh4/components/tables/supply-orders-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['supply-orders-table'],
    location: Ember.inject.service(),
    store: Ember.inject.service(),
    can: Ember.inject.service(),
    showAll: false,
    supplyOrders: Ember.computed('location.model.merchantId', function getter() {
      return this.can.can('view supply in order') ? this.store.query('supply-orders/order', {
        mid: [this.get('location.model.merchantId')],
        limit: 100,
        order: 'createdAt',
        direction: 'desc'
      }) : null;
    }),
    orderRoll: Ember.computed('showAll', 'supplyOrders.[]', function getter() {
      if (this.get('supplyOrders')) {
        return this.get('showAll') ? this.get('supplyOrders') : this.get('supplyOrders').slice(0, 5);
      }

      return false;
    }),
    actions: {
      toggleOrders: function toggleOrders() {
        this.toggleProperty('showAll');
      }
    }
  });

  _exports.default = _default;
});