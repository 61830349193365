define("lh4/routes/authenticated/locations/location/overview", ["exports", "moment", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _moment, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OVERVIEW_PERMISSIONS = ['view ticket info in general', 'view payments info in general', 'view credit card statements in general', 'view tax documents in general', 'view account notices in general', 'view batch history in general', 'view labor percentage in general', 'view employee timeclock in general'];

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    notifications: Ember.inject.service(),
    redirect: function redirect() {
      if (this.can.cannot('access for us in general')) {
        this.transitionTo('/');
      }

      if (this.location.model.isCorpHq) {
        this.transitionTo('authenticated.location-settings');
      }
    },
    model: function model() {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      var hasOverviewAccess = OVERVIEW_PERMISSIONS.some(function (permission) {
        return _this.can.can(permission);
      });
      var filter = {
        adapterOptions: {
          merchantId: location.get('merchantId'),
          zone: this.location.getLocationTimezone()
        },
        reload: true
      };
      return Ember.RSVP.hash({
        location: location,
        overview: hasOverviewAccess ? this.store.findRecord('overview', location.get('id'), filter) : Ember.Object.create({}),
        notifications: this.notifications.getData(location.get('id'))
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      if (!Ember.isEmpty(model.overview.get('1099s'))) {
        var preselected1099 = model.overview.get('1099s.lastObject');
        controller.send('select1099', preselected1099.url, preselected1099.year);
      }

      if (!Ember.isEmpty(model.overview.get('notices'))) {
        var currentMonth = (0, _moment.default)().startOf('month');
        controller.set('selectedNoticesDate', currentMonth);
      }

      var locationController = this.controllerFor('authenticated.locations.location');
      locationController.set('showLocationTabs', true);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var locationController = this.controllerFor('authenticated.locations.location');
        locationController.set('showLocationTabs', false);
      }
    }
  });

  _exports.default = _default;
});