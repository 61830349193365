define("lh4/serializers/hospitality/customer", ["exports", "ember-data", "lh4/serializers/hospitality/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'guid',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'hospitality/customer';
    },
    keyForRelationship: function keyForRelationship() {
      return "customer".concat(this._super.apply(this, arguments).capitalize());
    },
    attrs: {
      emails: {
        embedded: 'always'
      },
      addresses: {
        embedded: 'always'
      },
      phones: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});