define("lh4/mirage/factories/hospitality-menu-item", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    canonicalItemId: null,
    cost: '0.0000',
    courseGroupId: 0,
    description: '',
    displayPrice: '100.0000',
    doNotPrintItem: false,
    editedAt: null,
    enteredAt: null,
    freshSheetThreshold: null,
    genreId: null,
    hasSpecialRequest: false,
    isActive: true,
    isOpenItem: false,
    isSoldByWeight: false,
    locationId: 1,
    longName: function longName() {
      return _faker.default.commerce.productName();
    },
    menuDescription: '',
    miDefaultQty: '1.0000',
    miSku: '',
    overridesCourse: false,
    parCount: null,
    preferredCourseId: 0,
    price: null,
    priceCategoryId: 1,
    revenueClass: 1,
    rowguid: function rowguid() {
      return _faker.default.datatype.uuid();
    },
    rvMenuItem: {
      type: 'Buffer',
      data: [91, 111, 98, 106, 101, 99, 116, 32, 79, 98, 106, 101, 99, 116, 93]
    },
    tareWeight: 0,
    usesSetPrice: true
  });

  _exports.default = _default;
});