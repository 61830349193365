define("lh4/serializers/echo-pro/modifier-set-map", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'guid',
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Object.assign(hash, this.serialize(record, options));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (!json.isFlatAmount && json.value !== null) {
        json.value /= 100;
      }

      if (!json.isFlatAmountOnline && json.onlineValue !== null) {
        json.onlineValue /= 100;
      }

      return json;
    },
    normalize: function normalize(modelClass, resourceHashToNormalize) {
      var resourceHash = resourceHashToNormalize;

      if (resourceHash.value !== null) {
        if (!resourceHash.isFlatAmount) {
          resourceHash.value *= 100;
        }

        resourceHash.value = Number.parseFloat(resourceHash.value).toFixed(2);
      }

      if (resourceHash.onlineValue !== null) {
        if (!resourceHash.isFlatAmountOnline) {
          resourceHash.onlineValue *= 100;
        }

        resourceHash.onlineValue = Number.parseFloat(resourceHash.onlineValue).toFixed(2);
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, resourceHash].concat(args));
    }
  });

  _exports.default = _default;
});