define("lh4/mirage/routes/reports", ["exports", "lh4/mirage/fixtures/reports", "lh4/mirage/fixtures/drill-down-report-list", "lh4/mirage/fixtures/drill-down-report", "lh4/mirage/fixtures/drill-down-ticket-details", "lh4/mirage/fixtures/ticket-details-download", "lh4/mirage/fixtures/fiscal-report", "lh4/mirage/fixtures/processing-finaro-list", "lh4/mirage/fixtures/processing-finaro-report", "lh4/mirage/fixtures/processing-report", "lh4/mirage/fixtures/ew-pos-reports/ticket-transaction-status"], function (_exports, _reports, _drillDownReportList, _drillDownReport, _drillDownTicketDetails, _ticketDetailsDownload, _fiscalReport, _processingFinaroList, _processingFinaroReport, _processingReport, _ticketTransactionStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageReportsServer;

  function setMirageReportsServer(server) {
    server.get('/reports', function () {
      return {
        reports: _reports.default
      };
    });
    server.get('/reports/subscriptions', function (_ref) {
      var subscriptions = _ref.subscriptions;
      return {
        subscriptions: subscriptions.all().models
      };
    });
    server.delete('/reports/subscriptions/:id', {});
    server.get('/gift-card-reports', function () {
      return _drillDownReportList.default;
    });
    server.get('/reports/fiscal-reports/:locationId', function () {
      return _fiscalReport.default;
    });
    server.post('/:reportUrl/:exportType/:reportType', function () {
      return {
        guid: 'guid',
        url: 'url'
      };
    });
    server.post('/gift-card-reports/transaction-summary', function () {
      return _drillDownReport.GC_TRANSACTION_REPORT_FIXTURE;
    });
    server.post('/gift-card-reports/transaction-detail', function () {
      return _drillDownReport.GC_TRANSACTION_DETAIL_FIXTURE;
    });
    server.post('/gift-card-reports/balances', function () {
      return _drillDownReport.GC_BALANCE_FIXTURE;
    });
    server.get('/tickets/:123/details', function () {
      return _drillDownTicketDetails.default;
    });
    server.get('/tickets/:123/details/pdf', function () {
      return _ticketDetailsDownload.default;
    });
    server.post('/reports/solo/quick-menu-summary', function () {
      return {
        custom: true,
        name: 'Quick Menu Summary',
        totals: {
          name: 'Sales summary',
          sales: '$2000',
          tax: '$10',
          total: '$2010',
          results: [['Count', 'Item', 'Total Price', 'Total Tax', 'Total Amount'], ['9', 'Waffle Fries', '$18', '$1', '$19'], ['16', 'Cheeseburger', '$10', '$3', '$13'], ['40', 'Pizza', '$100', '$2', '$102'], ['55', 'Steak', '$10122', '$2', '$10124'], ['32', 'Fries', '$130', '$1', '$131']]
        },
        employees: [{
          name: 'Emp 1',
          tax: '$8.4',
          sales: '$23',
          total: '$31.4',
          results: [['Count', 'Item', 'Total Price', 'Total Tax', 'Total Amount'], ['9', 'Waffle Fries', '$18', '$2', '$20'], ['16', 'Cheeseburger', '$10', '$1', '$11']]
        }, {
          name: 'Emp 2',
          tax: '$10.4',
          sales: '$2',
          total: '$12.4',
          results: [['Count', 'Item', 'Total Price', 'Total Tax', 'Total Amount'], ['13', 'Waffle Fries', '$36', '$5', '$41'], ['100', 'Coors Light', '$180', '$15', '$195']]
        }]
      };
    });
    server.post('/reports/solo/transaction-summary', function () {
      return {
        custom: true,
        name: 'Transaction Summary',
        total: {
          balance: '$55',
          voids: '$18',
          tax: '$3',
          surcharges: '$7',
          tips: '$10',
          total: '$75',
          totalCash: '$5',
          totalCredit: '$35',
          totalDebit: '$30',
          totalGiftCard: '$40'
        },
        employees: [{
          balance: '$30',
          voids: '$6',
          tips: '$7',
          name: 'Emp 1',
          totalCash: '$5',
          totalCredit: '$10',
          totalDebit: '$20',
          totalGiftCard: '$20',
          transactions: [{
            type: 'sale',
            processedAt: '01/18/2020 16:18PM',
            balance: '$5',
            tips: '$1',
            terminal: 'TERMINAL 1',
            cardBrand: 'Visa',
            tenderType: 'debit'
          }, {
            type: 'void',
            processedAt: '01/19/2020 16:19PM',
            balance: '$5',
            tips: '$1',
            terminal: 'TERMINAL 2',
            cardBrand: 'Mastercard',
            tenderType: 'debit'
          }, {
            type: 'sale',
            processedAt: '01/19/2020 18:18PM',
            balance: '$20',
            tips: '$4',
            terminal: 'TERMINAL 1',
            cardBrand: 'Visa',
            tenderType: 'credit'
          }, {
            type: 'sale',
            processedAt: '01/19/2020 18:21PM',
            balance: '$5',
            tips: '$3',
            terminal: 'TERMINAL 1',
            cardBrand: 'Visa',
            tenderType: 'cash'
          }, {
            type: 'sale',
            processedAt: '01/19/2020 18:24PM',
            balance: '$20',
            tips: '$0',
            terminal: 'TERMINAL 1',
            cardBrand: '',
            tenderType: 'gift-card'
          }]
        }, {
          balance: '$15',
          voids: '$12',
          tips: '$3',
          name: 'Emp 2',
          totalCash: '$0',
          totalCredit: '$10',
          totalDebit: '$25',
          totalGiftCard: '$20',
          transactions: [{
            type: 'sale',
            processedAt: '01/18/2020 16:18PM',
            balance: '$25',
            tips: '$5',
            terminal: 'TERMINAL 3',
            cardBrand: 'My Card',
            tenderType: 'debit'
          }, {
            type: 'void',
            processedAt: '01/18/2020 19:18PM',
            balance: '$10',
            tips: '$2',
            terminal: 'TERMINAL 3',
            cardBrand: 'Maestro',
            tenderType: 'credit'
          }, {
            type: 'sale',
            processedAt: '01/19/2020 20:24PM',
            balance: '$20',
            tips: '$0',
            terminal: 'TERMINAL 3',
            cardBrand: '',
            tenderType: 'gift-card'
          }]
        }]
      };
    });
    server.post('/reports/solo/sales-summary', function () {
      return {
        name: 'Sales Summary',
        custom: true,
        totals: {
          name: 'Sales summary',
          count: 25,
          totalPrice: '$226.25',
          totalTips: '$0.00',
          totalTax: '$59.25',
          totalAmount: '$285.50',
          totalPlusTips: '$285.50',
          employeeNames: 'Jack Jackson, Emily James',
          deviceSerialNos: '0002555',
          cardBrandResults: [['Tender Type', 'Card Brand', 'Tender Total', 'Tip Amount', 'Tender Ttl-Tip'], ['credit', 'AMEX', '$94.20', '$0.00', '$94.20'], ['credit', 'VISA', '$76.64', '$0.00', '$76.64'], ['credit', 'MASTERCARD', '$55.41', '$0.00', '$55.41'], ['gift-card', '', '$40.00', '$0.00', '$40.00']],
          results: [['Count', 'Item', 'Unit Price', 'Unit Tax', 'Inclusive Unit Price', 'Total Price', 'Total Tax', 'Total Amount'], ['10', 'Liquor', '$10.16', '$2.37', '$12.53', '$101.60', '$23.70', '$125.30'], ['15', 'Wine', '$8.31', '$2.37', '$10.68', '$124.65', '$35.55', '$160.20']]
        },
        devices: [{
          name: '0002555',
          tax: '$59.25',
          tips: '$0.00',
          count: 25,
          totalPrice: '$226.25',
          totalTips: '$0.00',
          totalAmount: '$285.50',
          totalPlusTips: '$285.50',
          employeeNames: 'Jack Jackson, Emily James',
          results: [['Count', 'Item', 'Unit Price', 'Unit Tax', 'Inclusive Unit Price', 'Total Price', 'Total Tax', 'Total Amount'], ['10', 'Liquor', '$10.16', '$2.37', '$12.53', '$101.60', '$23.70', '$125.30'], ['15', 'Wine', '$8.31', '$2.37', '$10.68', '$124.65', '$35.55', '$160.20']],
          cardBrandResults: [['Tender Type', 'Card Brand', 'Tender Total', 'Tip Amount', 'Tender Ttl-Tip'], ['credit', 'AMEX', '$94.20', '$0.00', '$94.20'], ['credit', 'VISA', '$76.64', '$0.00', '$76.64'], ['credit', 'MASTERCARD', '$55.41', '$0.00', '$55.41'], ['gift-card', '', '$40.00', '$0.00', '$40.00']]
        }]
      };
    });
    server.post('/reports/echo-pro/cash-transactions-by-employee', function () {
      return {
        name: 'Cash Transactions by Employee',
        headers: ['Employee Name', 'Cash Received', 'Change Made', 'Cash Payment Total'],
        rows: [['Agne Kasiuleviciute', '$199.47', '$0.00', '$199.47'], ['Arturas Cernodiedas', '$7.01', '$0.00', '$7.01'], ['Blixa Bargeld', '$391.87', '$106.06', '$285.81'], ['Danielius Jonusas', '$1220.31', '$0.00', '$1220.31'], ['Darijus Aleknavicius', '$2713.62', '$0.00', '$2713.62']]
      };
    });
    server.post('/reports/echo-pro/activity-summary', function () {
      return {
        name: 'Activity Summary',
        custom: true,
        buckets: [{
          guid: null,
          name: 'Totals',
          reports: [{
            name: 'Business Trends',
            rows: [['Headcount', '0'], ['Avg. Head', '$0.00'], ['', ''], ['Open Tickets', '5'], ['Closed Tickets', '0'], ['Avg. Ticket', '$0.00'], ['', '']]
          }]
        }],
        warningText: 'This is a warning message'
      };
    });
    server.get('/reports/products/location/:id', function () {
      return {
        reportProducts: {
          'echo-pro': true,
          echo: false,
          retail: false,
          hospitality: false,
          harborpay: false,
          processing: true,
          solo: true
        }
      };
    });
    server.get('/reports/products/gpatt', function () {
      return {
        reportProducts: {
          gpatt: false
        }
      };
    });
    server.post('/reports/products/gpatt', function () {
      return {
        reportProducts: {
          gpatt: true
        }
      };
    });
    server.post('/reports/products/loyalty', function () {
      return {
        reportProducts: {
          loyalty: true
        }
      };
    });
    server.post('/reports/products/solo', function () {
      return {
        reportProducts: {
          solo: true
        }
      };
    });
    server.get('/processing-reports/options', function () {
      return _processingFinaroList.default;
    });
    server.post('/processing-reports/fetch', function (schema, request) {
      var _JSON$parse = JSON.parse(request.requestBody),
          reportType = _JSON$parse.reportType,
          locationIds = _JSON$parse.locationIds;

      if (reportType === 'ticket-transaction-status') {
        if ([1, 2].every(function (locationId) {
          return locationIds.includes(locationId);
        })) {
          return _ticketTransactionStatus.TICKET_TRANSACTION_STATUS_REPORT_MULTIPLE_LOCATIONS;
        }

        return _ticketTransactionStatus.TICKET_TRANSACTION_STATUS_REPORT;
      }

      return reportType === 'settlement' ? _processingReport.default : _processingFinaroReport.default;
    });
  }
});