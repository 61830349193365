define("lh4/templates/authenticated/skytab-orders/hardware", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zFTpG2fb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"hardware \",[28,\"if\",[[24,[\"media\",\"isMobile\"]],\"mobile\"],null]]]],[8],[0,\"\\n\\t\"],[5,\"dashboard/ad-banner\",[],[[\"@showRedirect\",\"@target\",\"@adBannerUrl\"],[\"true\",\"_self\",[23,0,[\"adBannerUrl\"]]]]],[0,\"\\n\\n\"],[4,\"if\",[[28,\"can\",[\"view product list in hardware-ordering\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"hardware/product-list\",[],[[\"@products\"],[[23,0,[\"productsToDisplay\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/skytab-orders/hardware.hbs"
    }
  });

  _exports.default = _default;
});