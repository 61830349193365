define("lh4/routes/authenticated/internet-payments/support/index", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: ['view info in support']
  });

  _exports.default = _default;
});