define("lh4/templates/components/tables/customer-import-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Ri6VWYI",
    "block": "{\"symbols\":[\"parsedCustomer\",\"parsedCustomerIndex\",\"column\",\"@columns\",\"@parsedCustomers\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"ui celled table\"],[8],[0,\"\\n\\t\"],[7,\"thead\",true],[8],[0,\"\\n\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"ui/checkbox\",null,[[\"checked\",\"onChange\"],[[23,0,[\"areAllSelected\"]],[28,\"action\",[[23,0,[]],\"toggleFullSelection\"],null]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"],[4,\"each\",[[23,4,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"th\",true],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,5,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"tables/customer-import-table/row\",[],[[\"@dataTestId\",\"@parsedCustomer\",\"@toggleSelection\",\"@isSelected\"],[[28,\"concat\",[\"row-\",[23,2,[]]],null],[23,1,[]],[28,\"action\",[[23,0,[]],\"toggleSelection\",[23,1,[]]],null],[28,\"get\",[[23,0,[\"parsedCustomersSelectedMap\"]],[23,2,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/tables/customer-import-table.hbs"
    }
  });

  _exports.default = _default;
});