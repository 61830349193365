define("lh4/components/ui/form-fields/radio-color-picker", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    dataTest: 'form-field-radio-color-picker',
    useUnsetOption: false,
    pickerClass: Ember.computed('fullWidth', function getter() {
      if (!this.label) {
        return 'sixteen wide column';
      }

      return "".concat(this.fullWidth ? 'twelve' : 'eight', " wide column");
    })
  });

  _exports.default = _default;
});