define("lh4/routes/authenticated/virtual-terminal/transactions/sale/edit", ["exports", "lh4/config/environment", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _environment, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var url = "".concat(_environment.default.host, "/api/v1/virtual-terminal/url");

  var _default = Ember.Route.extend(_lighthousePermissionsMixin.default, {
    requiredPermissions: ['manage virtual-terminal'],
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    queryParams: {
      businessDate: {
        refreshModel: true
      },
      cardToken: {
        refreshModel: true
      },
      tipAmount: {
        refreshModel: true
      },
      total: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('create transactions in transaction')) {
        this.transitionTo('authenticated.virtual-terminal.transactions');
      }
    },
    model: function model(_ref) {
      var invoice = _ref.invoiceId,
          businessDate = _ref.businessDate,
          cardToken = _ref.cardToken,
          tipAmount = _ref.tipAmount,
          total = _ref.total;
      var qs = new URLSearchParams({
        token: this.tokenData.data.token,
        locationId: this.location.model.id,
        type: 'sale',
        invoice: invoice,
        businessDate: businessDate,
        cardToken: cardToken,
        tipAmount: tipAmount,
        total: total
      });
      return Ember.RSVP.hash({
        origin: this.modelFor('authenticated.virtual-terminal.transactions').origin,
        url: "".concat(url, "?").concat(qs.toString())
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (model.origin) {
        window.addEventListener('message', function (event) {
          if (event.origin === model.origin) {
            // Make a call for the parent route to refresh since we know
            // the child iframe has gotten to a point where data has been changed
            _this.send('generateNewSearch');

            _this.transitionTo('authenticated.virtual-terminal.transactions');
          }
        }, false);
      }
    },
    setupController: function setupController(controller) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [controller].concat(args));

      controller.set('hasLoaded', false);
    }
  });

  _exports.default = _default;
});