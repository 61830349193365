define("lh4/serializers/customer-engagement/settings", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'location',
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Object.assign(hash, this.serialize(record, options));
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'customer-engagement/settings';
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.locationId = parseInt(json.locationId, 10);
      return json;
    },
    attrs: {
      location: {
        key: 'locationId',
        embedded: 'always',
        serialize: 'id',
        deserialize: 'record'
      }
    }
  });

  _exports.default = _default;
});