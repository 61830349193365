define("lh4/enums/payment-intervals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PAYMENT_INTERVALS = Object.freeze({
    ONE_TIME: 'oneTime',
    MONTHLY: 'monthly'
  });
  var _default = PAYMENT_INTERVALS;
  _exports.default = _default;
});