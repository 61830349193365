define("lh4/components/dashboard-widgets/online-reputation/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['online-reputation-data', 'table-widget'],
    classNameBindings: ['showSignup'],
    skeletonData: {
      accounts: [{
        name: 'Google',
        stars: 3.5
      }, {
        name: 'Facebook',
        stars: 4.1
      }, {
        name: 'Yelp',
        stars: 4.1
      }, {
        name: 'TripAdvisor',
        stars: 3.6
      }]
    },
    showSignup: Ember.computed('permissionError', 'data.accounts.[]', function getter() {
      return this.get('permissionError') || this.get('data.accounts.length') === 0;
    })
  });

  _exports.default = _default;
});