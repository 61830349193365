define("lh4/utils/currency/converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = _exports.toDollars = _exports.toCents = void 0;

  /**
   * Given a number in dollars (ex. 31.54) returns anumber in cents (ex. 3154)
   *
   * @param {number} dollars - amount in dollars, i.e. 1.23
   * @returns {number} dollars amount in cents, i.e. 123
   */
  var toCents = function toCents(dollars) {
    return Math.round(dollars * 100);
  };
  /**
   * Given a number in cents (ex. 3154) returns anumber in dollars (ex. 31.54)
   *
   * @param {number} cents - amount in cents, i.e. 123
   * @returns {number} cents amount in dollars with two decimal precision, i.e. 1.23
   */


  _exports.toCents = toCents;

  var toDollars = function toDollars(cents) {
    var dollars = cents / 100;
    return Math.round(dollars * 100) / 100;
  };
  /**
   * Converts a value to a formatted string
   *
   * @param {number|string} value - the number to format
   * @param {string} defaultString - the value to return if the input is an invalid number
   * @returns {number} a formatted string with two decimal precision, i.e. 1.23
   */


  _exports.toDollars = toDollars;

  var formatCurrency = function formatCurrency(value) {
    var defaultString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0.00';

    if (Number.isNaN(Number.parseFloat(value))) {
      return defaultString;
    }

    return parseFloat(value).toFixed(2);
  };

  _exports.formatCurrency = formatCurrency;
});