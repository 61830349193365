define("lh4/routes/authenticated/social-media/post-scheduler/account/edit-post", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    accountApi: Ember.inject.service('social-media/account-api'),
    errorHandler: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    model: function model(_ref) {
      var _this = this;

      var postId = _ref.postId;
      var parentModel = this.modelFor('authenticated.social-media.post-scheduler.account');
      return Ember.RSVP.hash({
        account: parentModel.account,
        post: this.accountApi.loadScheduledPost(parentModel.account.get('id'), postId).then(function (response) {
          return response.post;
        }).catch(function (err) {
          _this.errorHandler.handleResponseError(err, 'Could not load account posts');

          return null;
        })
      });
    }
  });

  _exports.default = _default;
});