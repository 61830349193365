define("lh4/utils/save-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = saveEmberModelArray;

  /**
   * Saves the array of Ember models one after another
   *
   * @param    {Array} [models] - Models to save
   * @returns  {Promise} Result of save
   */
  function saveEmberModelArray(models) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var changedModels = models.filterBy('hasDirtyAttributes', true);
      /**
       * Saves the model
       * @returns {boolean} returns save result: false is no models were saved
       */

      function saveModel() {
        if (!changedModels.length) {
          return resolve(true);
        }

        var model = changedModels.shift();
        model.save().then(saveModel).catch(reject);
        return false;
      }

      if (!changedModels.length) {
        return resolve(false);
      }

      saveModel();
      return false;
    });
  }
});