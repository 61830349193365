define("lh4/routes/authenticated/kitchen-display-system/order-ready-screen", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "ember-changeset-cp-validations", "ember-changeset", "lh4/mixins/components/ui/color-picker/color-picker-mapping"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin, _emberChangesetCpValidations, _emberChangeset, _colorPickerMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, _colorPickerMapping.default, {
    location: Ember.inject.service(),
    modelName: 'orderReadyScreen',
    requiredPermissions: 'view order ready screen for kitchen-display-system',
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.setColorsFromStylesheet();
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
    },
    model: function model() {
      return Ember.RSVP.hash({
        settings: this.store.queryRecord('echo-pro/setting', {
          location: this.location.model.id,
          type: 'order_ready_screen'
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model.settings),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(model.settings.orderReadyScreen.content, validateFn, validationMap, {
        skipValidate: true
      });
      controller.set('changeset', changeset);
    }
  });

  _exports.default = _default;
});