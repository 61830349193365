define("lh4/components/ui/search/general-search", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['general-search'],
    searchText: '',
    onSearch: null,
    isLoading: null,
    debounceWait: 700,
    init: function init() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super.apply(this, args);

      var inputs = args[0];
      this.placeholder = inputs.placeholder || this.intl.t('components:ui:search:general-search:input:placeholder:search');
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _jquery.default)('input').on('paste', function () {
        Ember.run.next(_this, function () {
          _this.send('onSearchChanged');
        });
      });
    },
    actions: {
      onSearchChanged: function onSearchChanged() {
        this.set('isLoading', true);

        if (!this.searchText || this.searchText.length >= 1) {
          Ember.run.debounce(this, this._onSearchActual, this.debounceWait);
        }
      },
      onClear: function onClear() {
        this.set('searchText', '');
        this.set('isLoading', false);
        this.sendAction('onSearch', this.searchText);
      }
    },
    _onSearchActual: function _onSearchActual() {
      this.sendAction('onSearch', this.searchText);
    }
  });

  _exports.default = _default;
});