define("lh4/templates/components/updates/deploy-analysis-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CtGr8zfM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"deploy-analysis-item\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[4,\"if\",[[23,0,[\"parsedChanges\",\"name\",\"previousValue\"]]],null,{\"statements\":[[1,[23,0,[\"parsedChanges\",\"name\",\"previousValue\"]],false],[0,\" → \"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[23,0,[\"parsedChanges\",\"name\",\"previousValue\"]],\" updated-entity\"],null]]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[23,0,[\"parsedChanges\",\"name\",\"displayValue\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"parsedChanges\",\"price\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\\t\"],[4,\"if\",[[23,0,[\"parsedChanges\",\"price\",\"previousValue\"]]],null,{\"statements\":[[1,[28,\"currency-format\",[[23,0,[\"parsedChanges\",\"price\",\"previousValue\"]]],null],false],[0,\" → \"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[23,0,[\"parsedChanges\",\"price\",\"previousValue\"]],\" updated-entity\"],null]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"currency-format\",[[23,0,[\"parsedChanges\",\"price\",\"displayValue\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/updates/deploy-analysis-item.hbs"
    }
  });

  _exports.default = _default;
});