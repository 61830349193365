define("lh4/serializers/echo-pro/tip-share-rule", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    attrs: {
      tipSharePayor: {
        embedded: 'always',
        serialize: 'record'
      },
      tipSharePayee: {
        embedded: 'always',
        serialize: 'record'
      }
    }
  });

  _exports.default = _default;
});