define("lh4/enums/currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CURRENCY = Object.freeze({
    USD: 'USD',
    GBP: 'GBP',
    EUR: 'EUR'
  });
  var _default = CURRENCY;
  _exports.default = _default;
});