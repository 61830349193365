define("lh4/components/dashboard-widgets/skytab/data", ["exports", "lh4/mixins/dashboard-widget-mixin", "lh4/utils/date-moment"], function (_exports, _dashboardWidgetMixin, _dateMoment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['skytab-data'],
    classNameBindings: ['showCarousel:carousel:show-signup'],
    skeletonData: {
      hasSkytab: false
    },
    store: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.showCarousel) {
        var index = 0;
        var max = this.$('.item').length;
        var carouselSpin = setInterval(function () {
          var width = _this.$('.item').outerWidth();

          _this.$().animate({
            scrollLeft: width * index
          }, 300);

          index += 1;

          if (index === max) {
            index = 0;
          }
        }, 5000);
        this.set('carouselSpin', carouselSpin);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.carouselSpin) {
        clearInterval(this.carouselSpin);
      }
    },
    showSignup: Ember.computed('data.hasSkytab', 'permissionError', function getter() {
      return this.permissionError || !this.data.hasSkytab;
    }),
    showCarousel: Ember.computed.alias('data.hasData'),
    reportDates: Ember.computed(function getter() {
      var _this$store$peekRecor = this.store.peekRecord('preferences', this.tokenData.data.sub),
          eod = _this$store$peekRecor.eod,
          weekStart = _this$store$peekRecor.weekStart;

      var _getWeekToDateRange = (0, _dateMoment.getWeekToDateRange)(eod, weekStart),
          startDate = _getWeekToDateRange.startDate,
          endDate = _getWeekToDateRange.endDate;

      return {
        startsAt: startDate.format(),
        startsAtTime: startDate.format('HH:mmA'),
        endsAt: endDate.format(),
        endsAtTime: endDate.format('HH:mmA')
      };
    })
  });

  _exports.default = _default;
});