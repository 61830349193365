define("lh4/controllers/authenticated/account-maintenance", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tutorialVideos: [{
      title: 'How to change your banking information',
      id: '462736920'
    }],
    walkthrough: Ember.inject.service(),
    location: Ember.inject.service(),
    shouldShowTutorials: Ember.computed('walkthrough.data.accountMaintenance', function getter() {
      return !this.walkthrough.data.accountMaintenance;
    }),
    decisionsIframeURL: Ember.computed('model.location.merchantId', 'model.token', function getter() {
      // the order of queryParams matters.....
      var queryParams = {
        FlowAlias: 'LHWelcomeMenu',
        sessionid: 'NS-Lighthouse',
        ForceFormat: true,
        MerchantID: this.model.location.merchantId,
        // this is returned as encoded, we must not encode this again
        Token: this.model.token,
        Location: 'TopLeft',
        Chrome: 'Off'
      };
      var queryString = Object.entries(queryParams).map(function (i) {
        return "".concat(i[0], "=").concat(i[1]);
      }).join('&');
      return "".concat(_environment.default.urls.decisions, "?").concat(queryString);
    })
  });

  _exports.default = _default;
});