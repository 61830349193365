define("lh4/components/modal/reservation-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Open modal by default if visible is set to true


      if (this.get('visible')) {
        this.$('.ui.modal').modal('show');
      }
    },
    willDestroyElement: function willDestroyElement() {
      // hide modal on transition
      this.$('.ui.modal').modal('hide');

      this._super.apply(this, arguments);
    },
    sms: false,
    call: false,
    email: false,
    comment: 'Your reservation has been cancelled.',
    actions: {
      cancel: function cancel() {
        var _this = this;

        var reservationId = this.get('model.reservation.id');
        var customerPhone = this.get('model.reservation.customerPhone');
        var comment = this.get('comment');

        if (this.get('email')) {
          if (Ember.isBlank(comment)) {
            this.flashMessages.danger('Comment to customer is required', {
              class: 'ui error message'
            });
            return;
          }

          this.ajax.request('/api/v1/notifications/reservations/cancel-email', {
            method: 'POST',
            data: JSON.stringify({
              reservationId: this.get('model.reservation.id'),
              comment: comment
            })
          }).then(function () {
            _this.flashMessages.success('Cancellation email sent to customer', {
              class: 'ui success message'
            });
          }).catch(function (err) {
            _this.flashMessages.danger("An error occured when trying to send message: ".concat(err), {
              class: 'ui error message'
            });
          });
        }

        if (this.get('sms')) {
          if (Ember.isBlank(comment)) {
            this.flashMessages.danger('Comment to customer is required', {
              class: 'ui error message'
            });
            return;
          }

          this.ajax.request('/api/v1/notifications/sms', {
            method: 'POST',
            data: JSON.stringify({
              to: customerPhone,
              body: comment,
              countryCode: this.location.model.countryCode
            })
          }).then(function () {
            _this.flashMessages.success('SMS text sent to customer', {
              class: 'ui success message'
            });
          }).catch(function (err) {
            _this.flashMessages.danger("An error occured when trying to send message: ".concat(err), {
              class: 'ui error message'
            });
          });
        }

        if (this.get('call')) {
          if (Ember.isBlank(comment)) {
            this.flashMessages.danger('Comment to customer is required', {
              class: 'ui error message'
            });
            return;
          }

          this.ajax.request('/api/v1/notifications/call?cancellation=true', {
            method: 'POST',
            data: JSON.stringify({
              to: customerPhone,
              body: this.get('model.location.channel'),
              cancellation: true,
              countryCode: this.location.model.countryCode,
              comment: comment
            })
          }).then(function () {
            _this.flashMessages.success('Call placed to customer', {
              class: 'ui success message'
            });
          }).catch(function (err) {
            _this.flashMessages.danger("An error occured when trying to send message: ".concat(err), {
              class: 'ui error message'
            });
          });
        }

        this.ajax.request("/api/v1/reservations/cancel/".concat(reservationId), {
          method: 'PUT'
        }).then(function () {
          _this.flashMessages.success('Reservation Cancelled', {
            class: 'ui success message'
          });

          setTimeout(function () {
            _this.sendAction('modalSuccess', _this.get('model'));
          }, 3000);
        }).catch(function (err) {
          _this.flashMessages.danger("An error occured when trying to send message: ".concat(err), {
            class: 'ui error message'
          });
        });
      },
      modalDeny: function modalDeny(res) {
        this.sendAction('modalDeny', res);
      }
    }
  });

  _exports.default = _default;
});