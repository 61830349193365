define("lh4/templates/components/internet-payments/ui/form-wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mLMjjqKU",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"internet-payments form-wizard\"],[8],[0,\"\\n\\t\"],[7,\"ol\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"steps\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"li\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"completed\"]],\"completed\"],null],[28,\"if\",[[23,1,[\"active\"]],\"active\"],null]]]],[8],[7,\"span\",true],[8],[1,[23,1,[\"label\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/form-wizard.hbs"
    }
  });

  _exports.default = _default;
});