define("lh4/controllers/authenticated/bank-account/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    bankAccount: Ember.inject.service(),
    location: Ember.inject.service(),
    typeOptions: [{
      name: 'Checking',
      value: 'Checking'
    }, {
      name: 'Savings',
      value: 'Savings'
    }, {
      name: 'General Ledger',
      value: 'General Ledger'
    }],
    transitionOnClose: function transitionOnClose() {
      this.transitionToRoute('authenticated.locations.location.overview', this.location.model.id);
    },
    transitionToError: function transitionToError(error) {
      this.changeset.rollback();
      this.bankAccount.set('errorMessage', error.friendlyError);
      this.transitionToRoute('authenticated.bank-account.error');
    },
    actions: {
      save: function save() {
        var _this = this;

        if (this.isSaving) {
          return;
        }

        this.set('showErrors', true);
        this.set('isSaving', true);
        this.clearServerError();
        this.changeset.validate().then(function () {
          if (_this.changeset.get('isInvalid')) {
            return false;
          }

          return _this.changeset.save().then(function (response) {
            _this.set('showErrors', false);

            if (response.status === 200) {
              _this.transitionToRoute('authenticated.bank-account.success');
            } else {
              _this.transitionToRoute('authenticated.bank-account.credentials');
            }
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (errors && errors[0]) {
              _this.transitionToError(errors[0]);
            }
          });
        }).finally(function () {
          return _this.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});