define("lh4/routes/authenticated/locations/location/hospitality/customize-menu/category/edit-category/item/create-item", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var _this$modelFor = this.modelFor('authenticated.locations.location'),
          location = _this$modelFor.id;

      var category = this.modelFor('authenticated.locations.location.hospitality.customize-menu.category.edit-category');
      var menuItemsCategories = category.menuCategory.id ? [{
        locationId: location,
        menuItemId: null,
        rowguid: category.menuCategory.id,
        screenCategoryId: null
      }] : [];

      if (category.menuCategory.id) {
        this.store.find('hospitality/menu-category', category.menuCategory.id).then(function (menuCat) {
          menuItemsCategories[0].screenCategoryId = menuCat.menuCatId;
        });
      }

      return Ember.RSVP.hash({
        choiceSets: this.store.query('hospitality/choice-set', {
          location: location
        }),
        newItem: this.store.createRecord('hospitality/menu-item', {
          locationId: location,
          ordinal: 0,
          menuItemsCategories: menuItemsCategories
        }),
        category: this.store.query('hospitality/menu-category', {
          location: location
        }),
        revClass: this.store.query('hospitality/revenue-class', {
          location: location
        }),
        itemGroup: this.store.query('hospitality/item-group', {
          location: location
        }),
        printers: this.store.query('hospitality/printer', {
          location: location
        })
      });
    }
  });

  _exports.default = _default;
});