define("lh4/helpers/transaction-type", ["exports", "lh4/enums/transaction-types", "lh4/helpers/capitalize-first", "lh4/helpers/lowercase"], function (_exports, _transactionTypes, _capitalizeFirst, _lowercase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transactionType = transactionType;
  _exports.default = void 0;

  /**
   * Get display value for a transaction type.
   *
   * @param {string} value - transaction type value
   * @returns {string} the display value for the transaction type or empty
   */
  function transactionType(value) {
    if (value) {
      return _transactionTypes.TRANSACTION_TYPE_DISPLAY_VALUE[value] || (0, _capitalizeFirst.capitalizeFirst)([(0, _lowercase.lowerCase)(value)]);
    }

    return '';
  }

  var _default = Ember.Helper.helper(transactionType);

  _exports.default = _default;
});