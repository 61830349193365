define("lh4/components/modal/reservation-notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var notifySuccess = function notifySuccess(context, successMessage) {
    context.flashMessagesHelper.pushMessage(successMessage, 'success');
    Ember.run.later(function () {
      context.set('disable', false);
      context.sendAction('modalDeny');
    }, 2000);
  };

  var notifyError = function notifyError(context, err) {
    context.set('disable', false);
    context.flashMessagesHelper.pushMessage("An error occurred when trying to send message: ".concat(err), 'error');
  };

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    location: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // open modal by default if visible is set to true


      if (this.get('visible')) {
        this.flashMessagesHelper.clearMessages();
        this.$('.ui.modal').modal('show');
      }
    },
    willDestroyElement: function willDestroyElement() {
      // hide modal on transition
      this.$('.ui.modal').modal('hide');

      this._super.apply(this, arguments);
    },
    notificationType: Ember.computed.oneWay('model.reservation.notificationType'),
    disable: false,
    actions: {
      modalDeny: function modalDeny() {
        this.sendAction('modalDeny');
      },
      notifyCustomer: function notifyCustomer() {
        var _this = this;

        this.flashMessagesHelper.clearMessages();

        if (!this.get('model.reservation.customerPhone') || !this.get('model.reservation.customerPhone').trim()) {
          this.set('disable', false);
          this.flashMessagesHelper.pushMessage('Phone cannot be empty', 'error');
          return false;
        }

        this.set('disable', true);

        if (this.get('notificationType').toLowerCase() === 'text') {
          var name = this.get('model.reservation.customerFullName').replace(/\?/g, '').trim();
          var size = this.get('model.reservation.partySize');
          var body = "".concat(name, ", your table for ").concat(size, " is ready at ").concat(this.get('model.location.name'));
          return this.ajax.request('/api/v1/notifications/sms', {
            method: 'POST',
            data: JSON.stringify({
              to: this.get('model.reservation.customerPhone'),
              body: body,
              countryCode: this.location.model.countryCode
            })
          }).then(function () {
            return notifySuccess(_this, 'Text message sent to customer');
          }).catch(function (err) {
            return notifyError(_this, err);
          });
        }

        if (this.get('notificationType').toLowerCase() === 'call') {
          return this.ajax.request('/api/v1/notifications/call', {
            method: 'POST',
            data: JSON.stringify({
              to: this.get('model.reservation.customerPhone'),
              body: this.get('model.location.channel'),
              countryCode: this.location.model.countryCode
            })
          }).then(function () {
            return notifySuccess(_this, 'Call placed to customer');
          }).catch(function (err) {
            return notifyError(_this, err);
          });
        }

        this.set('disable', false);
        return false;
      }
    }
  });

  _exports.default = _default;
});