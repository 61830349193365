define("lh4/mirage/routes/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageNotificationsServer;

  function setMirageNotificationsServer(server) {
    server.get('/notifications/:locationId', function (schema, request) {
      var id = request.params.locationId;

      if (id.toString() === '2') {
        return {
          locationOverview: {}
        };
      }

      return {
        locationOverview: {
          statements: true,
          notices: true,
          '1099s': true
        }
      };
    });
    server.put('/notifications/:locationId', function () {
      return {
        status: 200
      };
    });
  }
});