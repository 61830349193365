define("lh4/models/echo-pro/revenue-center-type", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _emberData.default.Model.extend(Validations, {
    guid: _emberData.default.attr('string'),
    revenueCenterTypeName: _emberData.default.attr('string'),
    revenueCenterTypeCode: _emberData.default.attr('string'),
    revenueCenterTypeNote: _emberData.default.attr('string')
  });

  _exports.default = _default;
});