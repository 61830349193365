define("lh4/serializers/echo-pro/tax", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;
      var decimals = json.isFlatAmount ? 2 : 3;

      if (!json.isFlatAmount) {
        json.value *= 100;
      }

      json.value = Number.parseFloat(json.value).toFixed(decimals);
      json.itemTaxMap = (jsonToNormalize.items || []).map(function (item) {
        return {
          guid: item.itemTaxMap.guid,
          isPriceQualifying: item.itemTaxMap.isPriceQualifying,
          isQuantityQualifying: item.itemTaxMap.isQuantityQualifying,
          taxGuid: json.guid,
          itemGuid: item.guid
        };
      });
      json.taxSetTaxMap = (jsonToNormalize.taxSets || []).map(function (taxSet) {
        return {
          guid: taxSet.taxSetTaxMap.guid,
          isPriceQualifying: taxSet.taxSetTaxMap.isPriceQualifying,
          isQuantityQualifying: taxSet.taxSetTaxMap.isQuantityQualifying,
          taxGuid: json.guid,
          taxSetGuid: taxSet.guid
        };
      });

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var decimals = json.isFlatAmount ? 2 : 3;
      json.value = Number.parseFloat(json.value).toFixed(decimals);

      if (!json.isFlatAmount) {
        json.value /= 100;
      }

      json.items = json.itemTaxMap.map(function (i) {
        return i.itemGuid;
      });
      json.taxSets = json.taxSetTaxMap.map(function (i) {
        return i.taxSetGuid;
      });
      return json;
    },
    attrs: {
      items: {
        embedded: 'always',
        serialize: 'id'
      },
      itemTaxMap: {
        embedded: 'always',
        deserialize: 'records',
        serialize: 'records'
      },
      taxSets: {
        embedded: 'always',
        serialize: 'id'
      },
      taxSetTaxMap: {
        embedded: 'always',
        deserialize: 'records',
        serialize: 'records'
      },
      orderTypes: {
        embedded: 'always',
        serialize: 'id'
      },
      taxRules: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});