define("lh4/models/reservations/table", ["exports", "ember-uuid", "ember-data", "ember-data/relationships", "ember-cp-validations"], function (_exports, _emberUuid, _emberData, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Table or label name',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 60
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    locationId: _emberData.default.attr('number'),
    sectionGuid: (0, _relationships.belongsTo)('reservations/section'),
    sectionId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    posX: _emberData.default.attr('number'),
    posY: _emberData.default.attr('number'),
    maxOccupants: _emberData.default.attr('number'),
    shape: _emberData.default.attr('string'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    clean: _emberData.default.attr('boolean'),
    rotation: _emberData.default.attr('number'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    occupantCount: _emberData.default.attr('number'),
    guid: Ember.computed('id', function () {
      return (0, _emberUuid.v4)();
    })
  });

  _exports.default = _default;
});