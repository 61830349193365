define("lh4/mirage/fixtures/drill-down-ticket-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DRILL_DOWN_TICKET_DETAILS = {
    trees: [{
      nodeType: 'ticket-details',
      parentGuid: null,
      level: 0,
      children: [{
        nodeType: 'ticket-details',
        parentGuid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
        level: 1,
        ordinal: 0,
        children: [],
        guid: '3df25ebb-8b1e-4ee9-9eef-752945e0ee30',
        isExpandable: false,
        rows: [{
          cols: [{
            dataType: '',
            value: 'Opened'
          }, {
            dataType: '',
            value: 'Completed'
          }, {
            dataType: '',
            value: 'Customer Name'
          }, {
            dataType: '',
            value: 'OrderType'
          }, {
            dataType: '',
            value: 'Ticket Owner'
          }, {
            dataType: '',
            value: 'Status'
          }],
          type: 'header'
        }]
      }, {
        nodeType: 'ticket-details',
        parentGuid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
        level: 1,
        ordinal: 1,
        children: [],
        guid: '0bf48985-3bb3-4f05-b4b6-529167571da8',
        isExpandable: false,
        rows: [{
          cols: [{
            dataType: 'date-time',
            value: '11/17/2021 05:31 PM'
          }, {
            dataType: 'date-time',
            value: '11/17/2021 05:51 PM'
          }, {
            dataType: '',
            value: 'Jack Jackson'
          }, {
            dataType: '',
            value: 'Dine in'
          }, {
            dataType: '',
            value: 'Support'
          }, {
            dataType: '',
            value: 'Voided'
          }],
          type: 'ticket details'
        }]
      }],
      isExpandable: true,
      guid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
      rows: [{
        cols: [{
          dataType: '',
          value: 'Ticket #8001'
        }, {
          dataType: 'startTime',
          value: '11/17/2021 05:31 PM'
        }, {
          dataType: 'endTime',
          value: '11/17/2021 05:51PM'
        }],
        type: 'title'
      }]
    }, {
      nodeType: 'ticket-details-totals',
      parentGuid: null,
      level: 0,
      children: [{
        nodeType: 'ticket-details',
        parentGuid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
        level: 1,
        ordinal: 0,
        children: [],
        guid: '3df25ebb-8b1e-4ee9-9eef-752945e0ee30',
        isExpandable: false,
        rows: [{
          cols: [{
            dataType: '',
            value: ''
          }, {
            dataType: '',
            value: 'Qty'
          }, {
            dataType: '',
            value: 'Subtotal'
          }, {
            dataType: '',
            value: 'Discount'
          }, {
            dataType: '',
            value: 'Modifiers'
          }, {
            dataType: '',
            value: 'Surcharges'
          }, {
            dataType: '',
            value: 'Hidden Taxes'
          }, {
            dataType: '',
            value: 'Taxes'
          }, {
            dataType: '',
            value: 'Item Total'
          }, {
            dataType: '',
            value: 'Added'
          }, {
            dataType: '',
            value: 'Status'
          }],
          type: 'header'
        }]
      }, {
        nodeType: 'ticket-details',
        parentGuid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
        level: 1,
        ordinal: 1,
        children: [{
          nodeType: 'ticket-details',
          parentGuid: 'edce17e2-eae4-4edd-8740-6661acb5adf6',
          guid: '6a6ee3e1-c17c-44b3-a5c4-8384b728de81',
          isExpandable: false,
          level: 2,
          children: [],
          rows: [{
            cols: [{
              dataType: '',
              value: 'Hot Dog'
            }, {
              dataType: 'float',
              value: '1'
            }, {
              dataType: 'money',
              value: '$1.99'
            }, {
              dataType: 'money',
              value: '$0.99'
            }, {
              dataType: 'money',
              value: '$1.00'
            }, {
              dataType: 'money',
              value: '$0.00'
            }, {
              dataType: 'money',
              value: '$0.00'
            }, {
              dataType: 'money',
              value: '$0.99'
            }, {
              dataType: 'money',
              value: '2.99'
            }, {
              dataType: 'date-time',
              value: '11/17/2021 05:31 PM'
            }, {
              dataType: 'status',
              value: 'Sold'
            }],
            type: 'item'
          }]
        }, {
          nodeType: 'ticket-details',
          parentGuid: 'edce17e2-eae4-4edd-8740-6661acb5adf6',
          guid: 'fa05da70-92ba-4673-8018-3a10aea5518f',
          isExpandable: false,
          level: 2,
          children: [],
          rows: [{
            cols: [{
              dataType: '',
              value: 'Coca Cola'
            }, {
              dataType: 'float',
              value: '1'
            }, {
              dataType: 'money',
              value: '$1.00'
            }, {
              dataType: 'money',
              value: '$0.00'
            }, {
              dataType: 'money',
              value: '$0.00'
            }, {
              dataType: 'money',
              value: '$0.00'
            }, {
              dataType: 'money',
              value: '$0.00'
            }, {
              dataType: 'money',
              value: '$0.00'
            }, {
              dataType: 'money',
              value: '1.00'
            }, {
              dataType: 'date-time',
              value: '11/17/2021 05:31 PM'
            }, {
              dataType: 'status',
              value: 'Sold'
            }],
            type: 'item'
          }]
        }],
        guid: 'edce17e2-eae4-4edd-8740-6661acb5adf6',
        isExpandable: true,
        rows: [{
          cols: [{
            dataType: '',
            value: 'Total'
          }, {
            dataType: 'float',
            value: '2'
          }, {
            dataType: 'money',
            value: '$2.99'
          }, {
            dataType: 'money',
            value: '$0.99'
          }, {
            dataType: 'money',
            value: '$1.00'
          }, {
            dataType: 'money',
            value: '$0.00'
          }, {
            dataType: 'money',
            value: '$0.00'
          }, {
            dataType: 'money',
            value: '$0.99'
          }, {
            dataType: 'money',
            value: '3.99'
          }, {
            dataType: 'empty',
            value: ''
          }, {
            dataType: 'empty',
            value: ''
          }],
          type: 'total'
        }]
      }],
      isExpandable: true,
      guid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
      rows: [{
        cols: [{
          dataType: '',
          value: 'Ticket #8001'
        }, {
          dataType: 'startTime',
          value: '11/17/2021 05:31 PM'
        }, {
          dataType: 'endTime',
          value: '11/17/2021 05:51PM'
        }],
        type: 'title'
      }]
    }, {
      nodeType: 'ticket-details-deductions',
      parentGuid: null,
      level: 0,
      children: [{
        nodeType: 'ticket-details',
        parentGuid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
        level: 1,
        ordinal: 0,
        children: [],
        guid: '0bf48985-3bb3-4f05-b4b6-529167571da8',
        isExpandable: false,
        rows: [{
          cols: [{
            dataType: '',
            value: ''
          }, {
            dataType: '',
            value: 'Amount'
          }],
          type: 'header'
        }]
      }, {
        nodeType: 'ticket-details',
        parentGuid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
        level: 1,
        ordinal: 1,
        children: [{
          nodeType: 'ticket-details',
          parentGuid: 'edce17e2-eae4-4edd-8740-6661acb5adf6',
          guid: '6a6ee3e1-c17c-44b3-a5c4-8384b758472',
          isExpandable: false,
          level: 2,
          children: [],
          rows: [{
            cols: [{
              dataType: '',
              value: 'Advantage Program'
            }, {
              dataType: 'money',
              value: '$0.13'
            }],
            type: 'fee type'
          }]
        }],
        guid: 'edce17e2-eae4-4edd-8740-6661acb5adf6',
        isExpandable: true,
        rows: [{
          cols: [{
            dataType: '',
            value: 'Fees'
          }, {
            dataType: 'money',
            value: '$0.13'
          }],
          type: 'fee'
        }]
      }, {
        nodeType: 'ticket-details',
        parentGuid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
        level: 1,
        ordinal: 2,
        children: [{
          nodeType: 'ticket-details',
          parentGuid: 'edce17e2-eae4-4edd-8740-6661a1524f6',
          guid: '6a6ee3e1-c17c-44b3-a5c4-8384b758472',
          isExpandable: false,
          level: 2,
          children: [],
          rows: [{
            cols: [{
              dataType: '',
              value: 'Taxes'
            }, {
              dataType: 'money',
              value: '$0.13'
            }],
            type: 'tax'
          }]
        }],
        guid: 'edce17e2-eae4-4edd-8740-6661a1524f6',
        isExpandable: true,
        rows: [{
          cols: [{
            dataType: '',
            value: 'Taxes'
          }, {
            dataType: 'money',
            value: '$0.13'
          }],
          type: 'taxes'
        }]
      }],
      isExpandable: true,
      guid: '388b0d87-d699-4ff9-b76f-d59cac2a14da',
      rows: [{
        cols: [{
          dataType: '',
          value: 'Ticket #8001'
        }, {
          dataType: 'startTime',
          value: '11/17/2021 05:31 PM'
        }, {
          dataType: 'endTime',
          value: '11/17/2021 05:51PM'
        }],
        type: 'title'
      }]
    }],
    title: 'Ticket #8001'
  };
  var _default = DRILL_DOWN_TICKET_DETAILS;
  _exports.default = _default;
});