define("lh4/components/ui/popup/phone-number-explanation-popup", ["exports", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    position: null,
    isPhoneNumberIdentifyCallerEnabled: Ember.computed('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.PHONE_NUMBER_IDENTIFY_CALLER];
    }),
    classNames: ['phone-number-popup'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.isPhoneNumberIdentifyCallerEnabled) {
        Ember.run.scheduleOnce('afterRender', this, function getter() {
          var popupOptions = {
            inline: true,
            on: 'click'
          };

          if (this.position) {
            popupOptions.position = this.position;
          }

          this.$('.phone-number-field').popup(popupOptions);
        });
      }
    },
    actions: {
      onCloseExplanation: function onCloseExplanation() {
        this.$('.phone-number-field').popup('hide');
      }
    }
  });

  _exports.default = _default;
});