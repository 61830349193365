define("lh4/routes/authenticated/internet-payments/apm-services", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.can.cannot('view apm services in general')) {
        this.transitionTo('authenticated.internet-payments.dashboard');
      }
    },
    model: function model() {
      var locationId = this.location.model.id;
      return Ember.RSVP.hash({
        ach: this.ajax.request("/api/v1/apm/".concat(locationId, "/status/ach"))
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});