define("lh4/adapters/accounts/permission", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    tokenData: Ember.inject.service(),
    pathForType: function pathForType() {
      return 'accounts/permission';
    },
    urlForFindAll: function urlForFindAll() {
      // This is a hack but we have to do it because the endpoints are strange.
      return "".concat(this._super.apply(this, arguments), "s");
    }
  });

  _exports.default = _default;
});