define("lh4/mirage/routes/internet-payments/risk/risk-block-list", ["exports", "lh4/mirage/fixtures/internet-payments/risk/risk-block-list"], function (_exports, _riskBlockList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsRiskBlockListServer;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function setMirageInternetPaymentsRiskBlockListServer(serverObject) {
    var server = serverObject;

    var riskRulesMap = function riskRulesMap(riskRules) {
      return riskRules.map(function (_ref) {
        var parameter = _ref.parameter,
            value = _ref.value;

        switch (parameter) {
          case 'email':
            if (!_riskBlockList.default.emails[value]) {
              return _objectSpread(_objectSpread({}, _riskBlockList.default.emails.NOTFOUND), {}, {
                value: value
              });
            }

            return _riskBlockList.default.emails[value];

          case 'deviceId':
            if (!_riskBlockList.default.devices[value]) {
              return _objectSpread(_objectSpread({}, _riskBlockList.default.devices.NOTFOUND), {}, {
                value: value
              });
            }

            return _riskBlockList.default.devices[value];

          case 'token':
            if (!_riskBlockList.default.tokens[value]) {
              return _objectSpread(_objectSpread({}, _riskBlockList.default.tokens.NOTFOUND), {}, {
                value: value
              });
            }

            return _riskBlockList.default.tokens[value];

          default:
            return {};
        }
      });
    };

    server.post('/internet-payments/risk-block-list/:locationId/status', function (db, request) {
      var _JSON$parse = JSON.parse(request.requestBody),
          riskRules = _JSON$parse.riskRules;

      var result = riskRulesMap(riskRules);
      return {
        riskRules: result
      };
    });
    server.namespace = '/api/v2';
    server.post('/internet-payments/risk-block-list/:locationId/status', function (db, request) {
      var _JSON$parse2 = JSON.parse(request.requestBody),
          riskRules = _JSON$parse2.riskRules;

      var result = riskRulesMap(riskRules);
      return {
        riskRules: result
      };
    });
    server.namespace = '/api/v1';
  }
});