define("lh4/components/reputation/account-header-ratings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    accountApi: Ember.inject.service('reputation/account-api'),
    router: Ember.inject.service(),
    account: null,
    isActive: Ember.computed('router.currentRoute', function getter() {
      return this.router.isActive('authenticated.reputation.reviews.locations.account', this.account.id);
    }),
    selectItemClass: Ember.computed('account.type', 'isActive', function getter() {
      var activeClass = this.get('isActive') ? 'active' : '';
      var comingSoonClass = this.get('account') && this.get('account').isComingSoon ? 'coming-soon' : '';
      return "account-review-select-item ".concat(activeClass, " ").concat(comingSoonClass);
    }),
    actions: {
      setActiveAccount: function setActiveAccount() {
        if (!this.account || !this.account.type) {
          return false;
        }

        if (this.account.isComingSoon) {
          return false;
        }

        if (this.account.isEmpty) {
          return this.accountApi.redirectToCreateAccount(this.account.type, this.router.currentRouteName);
        }

        this.router.transitionTo('authenticated.reputation.reviews.locations.account', this.account.id);
        return false;
      }
    }
  });

  _exports.default = _default;
});