define("lh4/models/social-media/account", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Account name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    locations: {
      description: 'Locations',
      validators: [(0, _emberCpValidations.validator)(function (locations) {
        return Ember.isEmpty(locations) ? 'At least one location must be selected' : true;
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    externalId: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    pictureUri: _emberData.default.attr('string'),
    externalAccount: _emberData.default.attr(),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    locations: _emberData.default.hasMany('location'),
    linkStatus: _emberData.default.attr('string'),
    warningMessage: _emberData.default.attr('string'),
    externalName: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isConfiguredProperly: _emberData.default.attr('boolean'),
    permalink: _emberData.default.attr('string')
  });

  _exports.default = _default;
});