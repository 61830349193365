define("lh4/mirage/factories/epro-pay-rate", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    locationId: 1,
    employeeGuid: null,
    jobGuid: null,
    payRate: 0.00,
    isVoided: false
  });

  _exports.default = _default;
});