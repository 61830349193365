define("lh4/adapters/virtual-terminal/transaction", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    handleResponse: function handleResponse() {
      var response = this._super.apply(this, arguments);

      if (!response.transactions) {
        return response;
      }

      response.transactions.forEach(function (aTransaction) {
        var transaction = aTransaction;
        transaction.id = "".concat(transaction.invoiceId, "-").concat(transaction.cardNumber, "-").concat(transaction.batchId);
      });
      return response;
    }
  });

  _exports.default = _default;
});