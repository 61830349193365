define("lh4/routes/authenticated/deposit-breakdown/report", ["exports", "moment", "lh4/mixins/authenticated-route-mixin"], function (_exports, _moment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    reportApi: Ember.inject.service('reports/report-api'),
    flashMessagesHelper: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: {
      from: {
        refreshModel: true
      },
      to: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      var currentRoute = this.router.currentRoute;
      var queryParams = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.queryParams;

      if (!(queryParams !== null && queryParams !== void 0 && queryParams.from) || !(queryParams !== null && queryParams !== void 0 && queryParams.to)) {
        this.transitionTo('authenticated.deposit-breakdown');
      }
    },
    model: function model() {
      var _this = this;

      var currentRoute = this.router.currentRoute;
      var queryParams = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.queryParams;
      var startsAt = (0, _moment.default)(queryParams === null || queryParams === void 0 ? void 0 : queryParams.from).toDate();
      var endsAt = (0, _moment.default)(queryParams === null || queryParams === void 0 ? void 0 : queryParams.to).toDate();

      var _this$modelFor = this.modelFor('authenticated.deposit-breakdown'),
          location = _this$modelFor.location;

      var reportOptions = {
        startsAt: startsAt,
        endsAt: endsAt,
        locations: ["".concat(location.get('id'))],
        product: location.get('product')
      };
      return Ember.RSVP.hash({
        location: location,
        startsAt: startsAt,
        endsAt: endsAt,
        report: this.reportApi.fetchReport('processing', 'batch-reconciliation', 'Batch Reconciliation', reportOptions).catch(function (err) {
          var _JSON$parse = JSON.parse(err.message),
              header = _JSON$parse.header,
              details = _JSON$parse.details;

          var messages = [header];

          if (details.code && details.message) {
            messages.push("".concat(details.code, " - ").concat(details.message));
          }

          _this.flashMessagesHelper.pushMessages(messages, 'negative');
        })
      });
    }
  });

  _exports.default = _default;
});