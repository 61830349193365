define("lh4/components/dashboard-widgets/weather/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['weather-data'],
    classNameBindings: ['night', 'notSupported'],
    skeletonData: {
      weather: {
        timezone: 'America/New_York',
        currently: {
          summary: 'Clear',
          icon: 'clear-day',
          temperature: 40.21,
          humidity: 55,
          windSpeed: 2.33
        },
        daily: {
          data: [{
            day: 'Mon',
            summary: 'Mostly cloudy starting in the afternoon.',
            icon: 'partly-cloudy-night',
            temperatureHigh: 46.49,
            temperatureLow: 39.27
          }, {
            day: 'Tue',
            summary: 'Rain until afternoon.',
            icon: 'rain',
            temperatureHigh: 52.49,
            temperatureLow: 30.48
          }, {
            day: 'Wed',
            summary: 'Windy in the morning and partly cloudy throughout the day.',
            icon: 'wind',
            temperatureHigh: 37.79,
            temperatureLow: 24.75
          }, {
            day: 'Thu',
            summary: 'Mixed precipitation in the evening and overnight.',
            icon: 'rain',
            temperatureHigh: 37.72,
            temperatureLow: 33.89
          }, {
            day: 'Fri',
            summary: 'Rain until afternoon.',
            icon: 'rain',
            temperatureHigh: 51.11,
            temperatureLow: 34.64
          }, {
            day: 'Sat',
            summary: 'Partly cloudy starting in the afternoon, continuing until evening.',
            icon: 'partly-cloudy-night',
            temperatureHigh: 47.62,
            temperatureLow: 31.66
          }]
        }
      }
    },
    night: Ember.computed('data.weather.currently.icon', function getter() {
      if (this.data.weather && this.data.weather.currently) {
        return this.data.weather.currently.icon.includes('night');
      }

      return false;
    }),
    days: Ember.computed('data.weather.daily.data', function getter() {
      if (this.data.weather && this.data.weather.daily) {
        return this.data.weather.daily.data.slice(0, 6);
      }

      return false;
    }),
    notSupported: Ember.computed('data.weather', function getter() {
      return !this.data.weather;
    })
  });

  _exports.default = _default;
});