define("lh4/enums/credit-card-brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CREDIT_CARD_BRAND = Object.freeze({
    AMEX: 'amex',
    AX: 'amex',
    DISC: 'discover',
    DISCOVER: 'discover',
    NS: 'discover',
    JCB: 'jcb',
    JC: 'jcb',
    MASTERCARD: 'mastercard',
    MSTR: 'mastercard',
    MC: 'mastercard',
    VISA: 'visa',
    VS: 'visa'
  });
  var _default = CREDIT_CARD_BRAND;
  _exports.default = _default;
});