define("lh4/templates/components/ui/buttons/reservation-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rSHlTJ9+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"ui \",[24,[\"status\",\"color\"]],\" horizontal label\"]]],[8],[1,[24,[\"status\",\"message\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/buttons/reservation-status.hbs"
    }
  });

  _exports.default = _default;
});