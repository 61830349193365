define("lh4/routes/authenticated/locations/location/hospitality/customize-item-groups/create-group", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        newGroup: this.store.createRecord('hospitality/item-group', {
          locationId: location.id,
          ordinal: 0
        })
      });
    },
    afterModel: function afterModel() {
      this.controllerFor('authenticated.locations.location.hospitality.customize-item-groups').set('currentGroup', null);
    }
  });

  _exports.default = _default;
});