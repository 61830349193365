define("lh4/controllers/authenticated/internet-payments/reporting/coming-soon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    imageUri: '/img/internet-payments/coming-soon-page.jpg'
  });

  _exports.default = _default;
});