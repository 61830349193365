define("lh4/routes/authenticated/locations/location/hospitality", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        preferences: this.store.findRecord('hospitality/preference', this.location.model.id)
      });
    }
  });

  _exports.default = _default;
});