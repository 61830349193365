define("lh4/validators/messages", ["exports", "ember-cp-validations/validators/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _messages.default.extend({
    arrayTooLong: 'Too many {description} (maximum is {max})',
    arrayTooShort: 'Not enough {description} (minimum is {min})',
    arrayWrongLength: 'Incorrect number of {description} (required amount is {is})'
  });

  _exports.default = _default;
});