define("lh4/templates/components/internet-payments/ui/dropdown/dropdown-file-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Av4dQFM2",
    "block": "{\"symbols\":[\"@isDownloading\",\"&default\"],\"statements\":[[4,\"ui-dropdown\",null,[[\"class\"],[[28,\"concat\",[\"dropdown-file-selector\",[28,\"if\",[[23,1,[]],\" disabled\"],null]],null]]],{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"internet-payments/spinners/button-spinner\",[],[[\"@isIconLoader\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[5,\"ui/icons/internet-payments/download\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[7,\"ul\",true],[11,\"class\",[29,[\"menu transition hidden \",[28,\"if\",[[23,1,[]],\"hidden\"],null]]]],[8],[0,\"\\n\"],[0,\"\\t\\t\"],[14,2],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/dropdown/dropdown-file-selector.hbs"
    }
  });

  _exports.default = _default;
});