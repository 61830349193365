define("lh4/mirage/routes/update-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageUpdateLogServer;

  function setMirageUpdateLogServer(server) {
    server.get('/update-log', function () {
      return {
        updates: []
      };
    });
  }
});