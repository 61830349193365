define("lh4/templates/components/overview-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/uLeP6g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"id\",\"bar-chart\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/overview-chart.hbs"
    }
  });

  _exports.default = _default;
});