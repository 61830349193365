define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/reservation/edit/notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      modalDeny: function modalDeny() {
        this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.reservation.edit', this.get('model.location.id'), this.get('model.reservation.id'));
      }
    }
  });

  _exports.default = _default;
});