define("lh4/components/ui/form-fields/select-field-time", ["exports", "lh4/utils/times-in-day", "lh4/templates/components/ui/form-fields/select-field", "lh4/components/ui/form-fields/select-field"], function (_exports, _timesInDay, _selectField, _selectField2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _selectField2.default.extend({
    options: (0, _timesInDay.default)(15),
    dataTest: 'form-field-select-field-time',
    defaultText: Ember.computed(function getter() {
      return this.changeset && this.changeset.get(this.property) || this.placeholder;
    }),
    layout: _selectField.default
  });

  _exports.default = _default;
});