define("lh4/components/ui/search/reservations-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reservations-search'],
    actions: {
      search: function search(term) {
        if (term) {
          this.sendAction('search', term);
        }
      },
      clear: function clear() {
        this.sendAction('search', '');
      }
    }
  });

  _exports.default = _default;
});