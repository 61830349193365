define("lh4/components/epro/menu/ui-helpers/color-picker-unset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UNSET_OPTION = Object.freeze({
    color: 'white'
  });
  var _default = UNSET_OPTION;
  _exports.default = _default;
});