define("lh4/components/epro/menu/modifier-form", ["exports", "lh4/mixins/components/ui/color-picker/color-picker-mapping"], function (_exports, _colorPickerMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_colorPickerMapping.default, {
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setColorsFromStylesheet();
    },
    printerOptions: Ember.computed.map('printers', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          isActive = _ref.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    })
  });

  _exports.default = _default;
});