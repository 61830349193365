define("lh4/templates/components/supply-orders/step-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PYoD/8MR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui three steps\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[[24,[\"disableEquipment\"]],\"authenticated.supply-orders.location.equipment\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"desktop icon\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"Select Equipment\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"Choose the equipment\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\"],[[24,[\"disableSupplies\"]],\"authenticated.supply-orders.location.supplies\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"sticky note outline icon\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"Select Supplies\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"Choose your supplies\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\"],[[24,[\"disableOrder\"]],\"authenticated.supply-orders.location.order\"]],{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"pointing right icon\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"Confirm Order\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"Verify order details\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/supply-orders/step-list.hbs"
    }
  });

  _exports.default = _default;
});