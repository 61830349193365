define("lh4/components/social-media/account-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui', 'header', 'item', 'sm-header', 'left', 'aligned'],
    flashMessagesHelper: Ember.inject.service(),
    isLinked: Ember.computed('account', function getter() {
      var account = this.get('account'); // 'account-facebook-page-edit' passes object

      return !!Ember.get(account, 'externalId');
    }),
    showWarning: true,
    isWarningTooltip: true
  });

  _exports.default = _default;
});