define("lh4/components/dashboard/dashboard-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dashboard-table'],
    total: Ember.computed('data', function getter() {
      var data = this.get('data');

      if (data === null || data === undefined) {
        return 0;
      }

      return data.reduce(function (prev, x) {
        return prev + x.total;
      }, 0).toFixed(2);
    })
  });

  _exports.default = _default;
});