define("lh4/templates/components/modal/route-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mQh8UTR3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"asking\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"ui/modal/dialog-header\",null,[[\"title\"],[[24,[\"questionTitle\"]]]]],false],[0,\"\\n\"],[4,\"ui/modal/dialog-content\",null,null,{\"statements\":[[0,\"\\t\\t\"],[7,\"p\",true],[8],[1,[22,\"questionText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",\"ui negative button\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"questionDecline\"]],[8],[0,\"No\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",false],[12,\"class\",\"ui right labeled icon positive button ok\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"questionConfirm\"]],[8],[0,\"\\n\\t\\t\\tYes\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"checkmark icon\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/modal/route-modal.hbs"
    }
  });

  _exports.default = _default;
});