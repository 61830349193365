define("lh4/models/virtual-terminal/transaction", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    invoiceId: (0, _attr.default)('string'),
    cardToken: (0, _attr.default)('string'),
    date: (0, _attr.default)('string'),
    total: (0, _attr.default)('string'),
    tipAmount: (0, _attr.default)('string'),
    cardNumber: (0, _attr.default)('string'),
    authNumber: (0, _attr.default)('string'),
    customerName: (0, _attr.default)('string'),
    batchId: (0, _attr.default)('number'),
    transType: (0, _attr.default)('string'),
    processingStatus: (0, _attr.default)('string'),
    transactionId: (0, _attr.default)('string'),
    customerEmail: (0, _attr.default)('string'),
    cardType: (0, _attr.default)('string'),
    secureStatus: (0, _attr.default)('string'),
    riskStatus: (0, _attr.default)('string'),
    riskId: (0, _attr.default)('number'),
    // Used as a passthrough
    fraudDetails: (0, _attr.default)()
  });

  _exports.default = _default;
});