define("lh4/components/internet-payments/page-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    imageUri: '/img/internet-payments/page-not-found.png',
    message: 'Sorry.',
    subMessage: "The page you are looking for doesn't exist or an other error occurred.",
    imageAltText: 'Page Not Found'
  });

  _exports.default = _default;
});