define("lh4/components/supply-orders/select-equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    supplyCart: Ember.inject.service(),
    classNames: ['supply-orders'],
    hasEquipment: Ember.computed('model.equipment', function getter() {
      var equipment = this.get('model.equipment');
      return equipment.content.length;
    }),
    actions: {
      selectEquipment: function selectEquipment(equipmentObj) {
        this.supplyCart.selectEquipment(equipmentObj);
        this.sendAction('selectedEquipment');
      }
    }
  });

  _exports.default = _default;
});