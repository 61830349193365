define("lh4/models/customer-engagement/customer/email", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailTypes = _exports.default = void 0;
  var emailTypes = [{
    name: 'Primary'
  }, {
    name: 'Alternate'
  }, {
    name: 'Work'
  }, {
    name: 'Other'
  }];
  _exports.emailTypes = emailTypes;

  var _default = _model.default.extend({
    guid: (0, _attr.default)('string'),
    customerGuid: (0, _attr.default)('string'),
    externalId: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    email: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    customer: (0, _relationships.belongsTo)('customer-engagement/customer'),
    createdAt: (0, _attr.default)('date')
  });

  _exports.default = _default;
});