define("lh4/controllers/authenticated/social-media/post-scheduler/account/index", ["exports", "jquery", "lh4/enums/date-range-title", "moment"], function (_exports, _jquery, _dateRangeTitle, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var RANGES = [{
    title: _dateRangeTitle.DATE_RANGE_TITLE.TODAY,
    startDate: (0, _moment.default)().startOf('day'),
    endDate: (0, _moment.default)().endOf('day')
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.THIS,
    startDate: (0, _moment.default)().startOf('day'),
    endDate: (0, _moment.default)().endOf('week')
  }, {
    title: _dateRangeTitle.DATE_RANGE_TITLE.WEEKS.TWO,
    startDate: (0, _moment.default)().startOf('day'),
    endDate: (0, _moment.default)().add(7, 'days').endOf('week')
  }];
  var DATE_FORMAT = 'YYYY-MM-DD';

  var _default = Ember.Controller.extend({
    queryParams: ['from', 'to'],
    accountApi: Ember.inject.service('social-media/account-api'),
    facebook: Ember.inject.service('social-media/facebook'),
    media: Ember.inject.service(),
    dateFilteredPosts: Ember.computed('model.postData.posts.[]', function getter() {
      var posts = this.get('model.postData.posts');
      return posts.reduce(function (postList, post) {
        var list = postList;
        var date = post.publishTimeDisplayDate;
        list[date] = list[date] ? [].concat(_toConsumableArray(list[date]), [post]) : [post];
        return list;
      }, {});
    }),
    errorHandler: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    isLoading: false,
    minDate: (0, _moment.default)().toDate(),
    from: (0, _moment.default)().startOf('week').format(DATE_FORMAT),
    to: (0, _moment.default)().endOf('week').format(DATE_FORMAT),
    ranges: RANGES,
    setDates: function setDates(startDate, endDate) {
      this.setProperties({
        from: startDate ? startDate.format(DATE_FORMAT) : null,
        to: endDate ? endDate.format(DATE_FORMAT) : null
      });
      this.send('loadPosts');
    },
    actions: {
      selectAccount: function selectAccount(selectedHTML, value) {
        if (this.get('model.account.id') !== parseInt(value, 10)) {
          this.transitionToRoute('authenticated.social-media.post-scheduler.account', value);
        } else {
          (0, _jquery.default)('.ui.dropdown.accounts-dropdown').dropdown('hide');
        }
      },
      // Range picker action handlers
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        this.setDates(startDate, endDate);
      },
      onMobileRangeChange: function onMobileRangeChange(rangeTitle, startDate, endDate) {
        if (this.rangeTitle !== rangeTitle) {
          this.set('rangeTitle', rangeTitle);
        }

        this.setDates(startDate, endDate);
      },
      onRangeSelect: function onRangeSelect(rangeTitle, date) {
        var startDate = date.startDate,
            endDate = date.endDate;
        this.setDates(startDate, endDate);
      },
      onMobileSelect: function onMobileSelect(rangeTitle, date) {
        var startDate = date.startDate,
            endDate = date.endDate;
        this.setDates(startDate, endDate);
      },
      relinkAccount: function relinkAccount() {
        this.facebook.redirectToAccountRelink(this.get('model.account'));
      },
      loadPosts: function loadPosts() {
        var _this = this;

        var dateRange = {
          from: this.get('from'),
          to: this.get('to')
        };

        if (!this.get('isLoading')) {
          this.set('isLoading', true);
          this.accountApi.loadScheduledPosts(this.get('model.account.id'), dateRange).then(function (_ref) {
            var posts = _ref.posts;

            _this.set('model.postData.posts', posts);
          }).catch(function (err) {
            _this.errorHandler.handleResponseError(err, 'Could not load account posts');
          }).finally(function () {
            _this.set('isLoading', false);
          });
        }
      },
      deleteScheduledPost: function deleteScheduledPost(post) {
        var _this2 = this;

        this.set('isLoading', true);
        this.flashMessagesHelper.clearMessages();
        this.accountApi.deleteScheduledPost(this.get('model.account.id'), post.id).then(function () {
          _this2.get('model.postData.posts').removeObject(post);

          _this2.flashMessagesHelper.pushMessage('Successfully deleted post', 'success');
        }).catch(function (err) {
          _this2.errorHandler.handleResponseError(err, 'Could not load account posts');
        }).finally(function () {
          _this2.set('isLoading', false);
        });
      },
      editScheduledPost: function editScheduledPost(post) {
        this.transitionToRoute('authenticated.social-media.post-scheduler.account.edit-post', this.get('model.account.id'), post.id);
      }
    }
  });

  _exports.default = _default;
});