define("lh4/routes/authenticated/updates/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'menuVersion',
    ajax: Ember.inject.service(),
    adjustNaming: function adjustNaming(name) {
      switch (name) {
        case 'allow_price_overrides':
          return 'Pricing';

        case 'allow_active_toggle_overrides':
          return 'Active Status';

        case 'allow_departments_active_toggle_overrides':
          return 'Departments';

        case 'allow_discounts_active_toggle_overrides':
          return 'Discounts';

        case 'allow_gratuities_active_toggle_overrides':
          return 'Gratuities';

        case 'allow_items_active_toggle_overrides':
          return 'Items';

        case 'allow_modifiers_active_toggle_overrides':
          return 'Modifiers';

        case 'allow_modifier_sets_active_toggle_overrides':
          return 'Modifier Sets';

        case 'allow_order_types_active_toggle_overrides':
          return 'Order Types';

        case 'allow_price_schedules_active_toggle_overrides':
          return 'Scheduled Pricing';

        case 'allow_surcharges_active_toggle_overrides':
          return 'Surcharges';

        case 'allow_courses_active_toggle_overrides':
          return 'Courses';

        case 'allow_taxes_active_toggle_overrides':
          return 'Taxes';

        case 'allow_tax_sets_active_toggle_overrides':
          return 'Revenue Classes';

        case 'allow_tenders_active_toggle_overrides':
          return 'Tenders';

        default:
          return name;
      }
    },
    filterOutSettings: function filterOutSettings(location) {
      var filteredSettings = [];

      if (location.hasStatusOverrides) {
        location.locationSettings.forEach(function (setting) {
          if (setting.name !== 'menu_managed') {
            if (setting.name === 'allow_price_overrides') {
              filteredSettings.push(setting);
            } else if (setting.value === '1') {
              filteredSettings.push(setting);
            }
          }
        });
        filteredSettings.forEach(function (setting, index) {
          if (setting.name === 'allow_price_overrides') {
            filteredSettings.splice(index, 1);
            filteredSettings.unshift(setting);
          }
        });
        return filteredSettings;
      }

      var pricingSetting = location.locationSettings.filter(function (setting) {
        return setting.name === 'allow_price_overrides';
      });
      filteredSettings.push(pricingSetting[0], {
        name: 'allow_active_toggle_overrides',
        value: '0'
      });
      return filteredSettings;
    },
    restructureIntoHtml: function restructureIntoHtml(location) {
      var _this = this;

      var filteredSettings = this.filterOutSettings(location);
      var renamedSettings = ['<div class="tooltip-title"><h3>Allow Editing</h3></div>'];
      filteredSettings.forEach(function (setting) {
        if (setting !== null && setting !== void 0 && setting.name) {
          if (setting.name === 'allow_active_toggle_overrides' || setting.name === 'allow_price_overrides') {
            var img = setting.value === '1' ? 'check' : 'cross';
            renamedSettings.push("<div class=\"tooltip-content bold\"><img class=\"tooltip-icon ".concat(img, "\" src=\"/img/multi-location-management/").concat(img, ".png\"> ").concat(_this.adjustNaming(setting.name), "</div>"));
          } else {
            renamedSettings.push("<div class=\"tooltip-content padded\">".concat(_this.adjustNaming(setting.name), "</div>"));
          }
        }
      });
      return renamedSettings.join('');
    },
    model: function model() {
      var _this2 = this;

      var locationOverview = this.ajax.request("/api/v1/corporations/".concat(this.location.model.corpId, "/location-overview")).then(function (locations) {
        var filteredLocationOverviews = [];
        locations.forEach(function (loc) {
          filteredLocationOverviews.push({
            name: loc.name,
            id: loc.id,
            isUpToDate: loc.isUpToDate,
            isLocationManaged: loc.isLocationManaged,
            priceGroupName: loc.priceGroupName,
            settings: loc.locationSettings,
            locationSettingsTemplate: _this2.restructureIntoHtml(loc)
          });
        });
        return Ember.RSVP.hash({
          filteredLocationOverviews: filteredLocationOverviews
        });
      });
      var corporation = this.location.model.corpId;
      var currentUpdates = this.store.query('echo-pro/menu-version', {
        corporation: corporation
      });
      return Ember.RSVP.hash({
        formattedCorpLocationsOverview: locationOverview,
        menuVersion: this.store.createRecord('echo-pro/menu-version'),
        currentUpdates: currentUpdates
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });

  _exports.default = _default;
});