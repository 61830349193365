define("lh4/services/sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    media: Ember.inject.service(),
    router: Ember.inject.service(),
    isOpen: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('isOpen', this.media.isDesktop);
      this.router.on('routeWillChange', function () {
        if (_this.media.isMobile || _this.media.isTablet) {
          _this.set('isOpen', false);
        }

        if (document.body.scrollTop) {
          document.body.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      });
    }
  });

  _exports.default = _default;
});