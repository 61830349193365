define("lh4/templates/authenticated/hardware/skytab-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J4Bbso71",
    "block": "{\"symbols\":[],\"statements\":[[5,\"hardware/product-page\",[],[[\"@productName\",\"@price\",\"@heading\",\"@about\",\"@productDetails\",\"@productDetailsImageSrc\",\"@addToOrderOptions\",\"@selectedAddToOrderOption\",\"@itemsToAdd\"],[[23,0,[\"productName\"]],[23,0,[\"price\"]],[23,0,[\"heading\"]],[23,0,[\"about\"]],[23,0,[\"productDetails\"]],[23,0,[\"productDetailsImageSrc\"]],[23,0,[\"addToOrderOptions\"]],[23,0,[\"selectedAddToOrderOption\"]],[23,0,[\"itemsToAdd\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/hardware/skytab-glass.hbs"
    }
  });

  _exports.default = _default;
});