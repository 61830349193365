define("lh4/models/virtual-terminal/refund-settings/initiate", ["exports", "ember-data", "ember-data/attr", "ember-cp-validations"], function (_exports, _emberData, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    reason: {
      description: 'Reason for Change',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 256
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    reason: (0, _attr.default)('string'),
    allowPrepaid: (0, _attr.default)('boolean'),
    allowNonPrepaid: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});