define("lh4/routes/authenticated/locations/location/echo-pro/reservations/waitlist/edit", ["exports", "jquery", "lh4/mixins/authenticated-route-mixin"], function (_exports, _jquery, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return Ember.RSVP.hash({
        singleReservation: this.store.findRecord('reservation', params.reservationId)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.run.schedule('afterRender', this, function () {
        (0, _jquery.default)('html, body').animate({
          scrollTop: (0, _jquery.default)('.res-form').offset().top - (0, _jquery.default)('.reservation-headers').height()
        }, 300);
      });
    }
  });

  _exports.default = _default;
});