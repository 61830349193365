define("lh4/mirage/routes/time-zones-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageTimeZoneListServer;

  function setMirageTimeZoneListServer(server) {
    server.get('/time-zones-list.json', function () {
      return [{
        countryCode: 'MX',
        locales: ['America/Cancun', 'America/Hermosillo', 'America/Mazatlan', 'America/Mexico_City', 'America/Tijuana'],
        name: 'Mexico'
      }, {
        countryCode: 'US',
        locales: ['Pacific/Honolulu', 'America/Anchorage', 'America/Los_Angeles', 'America/Denver', 'America/Phoenix', 'America/Chicago', 'America/New_York'],
        name: 'United States'
      }];
    });
  }
});