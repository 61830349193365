define("lh4/templates/components/internet-payments/ui/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2vIEjaPj",
    "block": "{\"symbols\":[\"option\",\"@isViewFirstPageVisible\",\"@isViewLastPageVisible\"],\"statements\":[[7,\"nav\",true],[10,\"class\",\"pagination-footer\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[8],[0,\"Rows per page\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[23,0,[\"rowOptions\",\"length\"]],1],null]],null,{\"statements\":[[4,\"ui-dropdown\",null,[[\"class\",\"selected\",\"onChange\"],[\"selection\",[23,0,[\"itemsPerPage\"]],[28,\"action\",[[23,0,[]],\"handleLimitChange\"],null]]],{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text\"],[10,\"data-test-internet-payments-pagination\",\"selected-page-size\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"chevron down icon\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"ol\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"rowOptions\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"li\",true],[10,\"class\",\"item\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"selection ui dropdown\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"text\"],[10,\"data-test-internet-payments-pagination\",\"selected-page-size\"],[8],[1,[23,0,[\"itemsPerPage\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[7,\"span\",true],[10,\"class\",\"page-info\"],[8],[1,[23,0,[\"currentPage\"]],false],[0,\" of \"],[1,[23,0,[\"pageCount\"]],false],[9],[0,\"\\n\\t\"],[5,\"internet-payments/ui/client-pagination-buttons\",[],[[\"@currentPage\",\"@maxPagesShown\",\"@totalPages\",\"@onPageChange\",\"@isViewFirstPageVisible\",\"@isViewLastPageVisible\"],[[23,0,[\"currentPage\"]],[23,0,[\"maxPagesShown\"]],[23,0,[\"pageCount\"]],[23,0,[\"handlePageChange\"]],[23,2,[]],[23,3,[]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/pagination.hbs"
    }
  });

  _exports.default = _default;
});