define("lh4/templates/components/internet-payments/reporting/reports/report-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YyW6GKGh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"report-card\"],[12,\"data-test\",\"ip-report-card\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"onGenerateReport\"]],\"fromcard\"],null]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"card-header\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[10,\"data-test\",\"ip-report-card-title\"],[8],[1,[23,0,[\"args\",\"title\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"args\",\"icon\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"img\",true],[11,\"src\",[29,[\"/img/internet-payments/reports/\",[23,0,[\"args\",\"icon\"]],\".svg\"]]],[10,\"alt\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"data-test\",\"ip-report-card-description\"],[8],[1,[23,0,[\"args\",\"description\"]],false],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/reporting/reports/report-card.hbs"
    }
  });

  _exports.default = _default;
});