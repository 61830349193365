define("lh4/controllers/authenticated/epro/menu/items", ["exports", "lh4/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _generalListController.default.extend({
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    queryParams: ['departmentGuid', 'searchName', 'limit', 'order', 'offset', 'isActive'],
    isActive: 'true',
    // Makes ember to treat this as a string
    headers: Ember.computed('location.model', function getter() {
      var headers = [{
        field: 'name',
        name: 'Name',
        width: 'five',
        sortable: true
      }, {
        field: 'departments.name',
        name: 'Department',
        width: this.can.can('view menu availability in echo-pro/menu-availability') ? 'three' : 'four',
        sortable: true
      }, {
        field: 'qtyOnHand',
        name: 'In stock',
        width: 'two',
        sortable: true,
        type: 'number'
      }, {
        field: 'price',
        name: 'Price',
        width: 'two',
        sortable: true,
        type: 'number'
      }, {
        field: 'isActive',
        name: 'Status',
        width: this.can.can('view menu availability in echo-pro/menu-availability') ? 'two' : 'three',
        type: 'active',
        sortable: true
      }];

      if (this.can.can('view menu availability in echo-pro/menu-availability')) {
        headers = [].concat(_toConsumableArray(headers.slice(0, 3)), [{
          field: 'defaultAvailability',
          name: 'Availability',
          width: 'two',
          sortable: true
        }], _toConsumableArray(headers.slice(3)));
      }

      return headers;
    }),
    includeList: [],
    selectionCount: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super(); // reset all bulk selections when location is changed


      if ((_this$location = this.location) !== null && _this$location !== void 0 && (_this$location$model = _this$location.model) !== null && _this$location$model !== void 0 && _this$location$model.id && this.location.model.id !== this.bulkSelectLocationId) {
        this.setProperties({
          includeList: [],
          selectionCount: 0,
          bulkSelectLocationId: this.location.model.id,
          departmentGuid: undefined
        });
      }
    }),
    isMultipleItemsSelected: Ember.computed('selectionCount', function getter() {
      return this.selectionCount > 1;
    }),
    departmentOptions: Ember.computed('model.departments', 'departmentGuid', function getter() {
      var _this = this;

      var isAllSelected = typeof this.departmentGuid === 'undefined' || this.departmentGuid === null;
      return (this.model.departments || []).map(function (department) {
        var _this$departmentGuid;

        return {
          id: department.guid,
          name: department.name,
          isChecked: isAllSelected || ((_this$departmentGuid = _this.departmentGuid) === null || _this$departmentGuid === void 0 ? void 0 : _this$departmentGuid.includes(department.guid)),
          isActive: department.isActive
        };
      });
    }),
    inactiveItemsWarning: Ember.computed('isActive', 'model.inactiveItems', function getter() {
      return this.isActive === 'true' && this.model.inactiveItems.meta.count > 0;
    }),
    actions: {
      handleTermUpdated: function handleTermUpdated(searchName) {
        this.setProperties({
          searchName: searchName,
          offset: 0
        });
      },
      handleDepartmentChange: function handleDepartmentChange(selectedIds) {
        var isAllSelected = selectedIds.length === this.departmentOptions.length;
        this.setProperties({
          offset: 0,
          departmentGuid: !isAllSelected ? selectedIds : undefined
        });
      },
      handleBulkSelectionChanged: function handleBulkSelectionChanged(includeList, selectionCount) {
        this.setProperties({
          includeList: includeList,
          selectionCount: selectionCount
        });
      },
      handleBulkEditClicked: function handleBulkEditClicked() {
        if (this.isMultipleItemsSelected && this.can.can('bulk-edit in echo-pro/menu')) {
          this.transitionToRoute('authenticated.epro.menu.items.bulk-edit');
        }
      },
      handleHideInactiveChange: function handleHideInactiveChange(hideInactive) {
        this.setProperties({
          offset: 0,
          isActive: hideInactive ? 'true' : 'null'
        });
      }
    }
  });

  _exports.default = _default;
});