define("lh4/templates/components/hospitality/choices-and-choice-sets/choices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dLdVGcU1",
    "block": "{\"symbols\":[\"choice\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"menu-items-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"choices\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"tagName\",\"class\",\"activeClass\",\"route\",\"model\"],[\"button\",[28,\"concat\",[\"ui button item-buttons\",[28,\"unless\",[[23,1,[\"isActive\"]],\" isInactive\"],null]],null],\"selected-child\",\"authenticated.locations.location.hospitality.choices-and-choice-sets.set.edit-set.choice.edit-choice\",[23,1,[\"rowguid\"]]]],{\"statements\":[[0,\"\\t\\t\\t\"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/hospitality/choices-and-choice-sets/choices.hbs"
    }
  });

  _exports.default = _default;
});