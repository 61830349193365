define("lh4/enums/tip-share-basis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TOTAL_TIPS_BASIS = _exports.GROSS_SALES_BASIS = _exports.NET_SALES_BASIS = void 0;

  var _Object$freeze;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var NET_SALES_BASIS = '00000000-0000-0000-0000-000000000001';
  _exports.NET_SALES_BASIS = NET_SALES_BASIS;
  var GROSS_SALES_BASIS = '00000000-0000-0000-0000-000000000002';
  _exports.GROSS_SALES_BASIS = GROSS_SALES_BASIS;
  var TOTAL_TIPS_BASIS = '00000000-0000-0000-0000-000000000003';
  _exports.TOTAL_TIPS_BASIS = TOTAL_TIPS_BASIS;
  var TIP_SHARE_BASIS = Object.freeze((_Object$freeze = {}, _defineProperty(_Object$freeze, NET_SALES_BASIS, 'Net Sales'), _defineProperty(_Object$freeze, GROSS_SALES_BASIS, 'Gross Sales'), _defineProperty(_Object$freeze, TOTAL_TIPS_BASIS, 'Total Tips'), _Object$freeze));
  var _default = TIP_SHARE_BASIS;
  _exports.default = _default;
});