define("lh4/templates/components/supply-orders/select-supply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZrdccPja",
    "block": "{\"symbols\":[\"supply\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui four column stackable grid\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"supplies\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",false],[12,\"class\",\"column\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectSupply\",[23,1,[]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui top attached header segment\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h3\",true],[8],[1,[23,1,[\"displayName\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui attached segment\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"class\",\"ui centered image\"],[11,\"src\",[29,[[23,1,[\"imageLink\"]]]]],[11,\"alt\",[29,[[23,1,[\"displayName\"]]]]],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/supply-orders/select-supply.hbs"
    }
  });

  _exports.default = _default;
});