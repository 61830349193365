define("lh4/templates/components/ui/icons/login/invalid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jUuCoF1a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"10\"],[10,\"height\",\"10\"],[10,\"viewBox\",\"0 0 10 10\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"g\",true],[10,\"id\",\"Misc/times\"],[10,\"clip-path\",\"url(#clip0_6555_482)\"],[8],[0,\"\\n\\t\\t\"],[7,\"path\",true],[10,\"id\",\"times\"],[10,\"d\",\"M8.34024 9.05927L8.98188 8.41763C9.14157 8.25791 9.14157 7.99876 8.98188 7.8388L6.22024 5.07768L8.98163 2.31632C9.14132 2.1566 9.14132 1.89745 8.98163 1.73749L8.33999 1.09584C8.18027 0.936151 7.92112 0.936151 7.76115 1.09584L5.00004 3.85748L2.23868 1.09609C2.07896 0.936402 1.81981 0.936402 1.65984 1.09609L1.01845 1.73749C0.858759 1.89718 0.858759 2.15635 1.01845 2.31632L3.77984 5.07768L1.01845 7.83905C0.858759 7.99876 0.858759 8.25791 1.01845 8.41788L1.66009 9.05952C1.81981 9.21922 2.07896 9.21922 2.23893 9.05952L5.00004 6.29789L7.7614 9.05927C7.92112 9.21897 8.18027 9.21897 8.34024 9.05927Z\"],[10,\"fill\",\"#999999\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"defs\",true],[8],[0,\"\\n\\t\\t\"],[7,\"clipPath\",true],[10,\"id\",\"clip0_6555_482\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"rect\",true],[10,\"width\",\"10\"],[10,\"height\",\"10\"],[10,\"fill\",\"white\"],[10,\"transform\",\"translate(0 -0.000488281)\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/login/invalid.hbs"
    }
  });

  _exports.default = _default;
});