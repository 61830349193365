define("lh4/mirage/fixtures/ew-pos-reports/ticket-transaction-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TICKET_TRANSACTION_STATUS_REPORT_MULTIPLE_LOCATIONS = _exports.TICKET_TRANSACTION_STATUS_REPORT = void 0;
  var TICKET_TRANSACTION_STATUS_REPORT = {
    trees: [{
      isExpandable: true,
      level: 0,
      nodeType: 'data',
      parentGuid: null,
      children: [{
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: '41fb559e-a50b-5c33-99e4-0d5a3b50e37e',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '18504464 Credit',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 18,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 20,
            dataType: 'integer',
            hyperLink: {
              target: '_self',
              slug: 'master-transactions',
              params: {
                customDateRange: true,
                end: '2024-01-01T03:59:00+02:00',
                start: '2022-01-01T04:00:00+02:00',
                merchantBatchNumber: '18504464',
                min: 0,
                locationId: 1
              }
            }
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$673.45',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$673.45',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '08/23/2023',
            dataType: 'date'
          }],
          type: 'data'
        }],
        guid: '03cdf43b-8062-5612-8d4f-a8391fde64a5'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: '41fb559e-a50b-5c33-99e4-0d5a3b50e37e',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '185044464 Debit',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 0,
            dataType: 'integer',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 0,
            dataType: 'integer',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0',
            dataType: 'money',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '-$13.62',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '08/23/2023',
            dataType: 'date'
          }],
          type: 'data'
        }],
        guid: '1dd8c15f-6f9a-52aa-9bdd-0c42788ac305'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: '41fb559e-a50b-5c33-99e4-0d5a3b50e37e',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Deposit Total',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 18,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 20,
            dataType: 'integer',
            hyperLink: {
              target: '_self',
              slug: 'master-transactions',
              params: {
                customDateRange: true,
                end: '2024-01-01T03:59:00+02:00',
                start: '2022-01-01T04:00:00+02:00',
                status: '["SETTLED"]',
                locationId: 1
              }
            }
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$659.83',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$659.83',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'date',
            columnType: 'empty'
          }],
          type: 'data'
        }],
        guid: '5149114e-75dc-581b-8425-9c3eeec963c2'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: '41fb559e-a50b-5c33-99e4-0d5a3b50e37e',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Un-Deposited Tickets',
            dataType: 'string',
            tooltip: 'Total of Tickets that have not been finalized for batching, including current day tickets.'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 1,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 1,
            dataType: 'integer',
            hyperLink: {
              target: '_self',
              slug: 'master-transactions',
              params: {
                customDateRange: true,
                end: '2024-01-01T03:59:00+02:00',
                start: '2022-01-01T04:00:00+02:00',
                status: '["UNSETTLED"]',
                locationId: 1
              }
            }
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$18.50',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'money',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'date',
            columnType: 'empty'
          }],
          type: 'data'
        }],
        guid: 'f8add5d3-eb6b-5cc4-9cdb-e24ebaa3bfc6'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: '41fb559e-a50b-5c33-99e4-0d5a3b50e37e',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Ticket Total',
            dataType: 'string',
            columnType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 19,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 21,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$691.95',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string'
          }],
          type: 'bottom'
        }],
        guid: '3930fc68-18a4-56b9-aea9-6a00a4dd9b11'
      }],
      rows: [{
        cols: [{
          colSpan: 1,
          rowSpan: 1,
          value: 'Batch Number',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Tickets',
          dataType: 'integer',
          columnType: 'header',
          tooltip: 'Total of Tickets that were closed and finalized in the batch.'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Transactions',
          dataType: 'integer',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Transaction Amount',
          dataType: 'float',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Deposit Amount',
          dataType: 'float',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Deposit Date',
          dataType: 'date',
          columnType: 'header'
        }],
        type: 'header'
      }],
      guid: '41fb559e-a50b-5c33-99e4-0d5a3b50e37e'
    }],
    title: 'Ticket Transaction Status',
    start: '2022-01-01T04:00:00+02:00',
    end: '2024-01-01T03:59:00+02:00'
  };
  _exports.TICKET_TRANSACTION_STATUS_REPORT = TICKET_TRANSACTION_STATUS_REPORT;
  var TICKET_TRANSACTION_STATUS_REPORT_MULTIPLE_LOCATIONS = {
    trees: [{
      isExpandable: true,
      level: 0,
      nodeType: 'data',
      parentGuid: null,
      children: [{
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '1005 Credit',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 58,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 58,
            dataType: 'integer',
            hyperLink: {
              target: '_self',
              slug: 'master-transactions',
              params: {
                customDateRange: true,
                end: '2024-03-05T01:59:59.999Z',
                start: '2024-03-01T02:00:00.000Z',
                merchantBatchNumber: '1005',
                min: 0,
                locationId: 2
              }
            }
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$2,319.50',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$5,912.98',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '02/29/2024',
            dataType: 'date'
          }],
          type: 'data'
        }],
        guid: '3267bee0-b7fb-5d35-af83-a2999f1cabec'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '1006 Credit',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 109,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 109,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$6,207.18',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$6,207.18',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '03/01/2024',
            dataType: 'date'
          }],
          type: 'data'
        }],
        guid: '5e723c6c-51f8-53a3-bb67-020ab4a0f072'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '1007 Credit',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 96,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 96,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$5,782.13',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$5,782.13',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '03/02/2024',
            dataType: 'date'
          }],
          type: 'data'
        }],
        guid: 'c55bdae9-9de9-5bb5-a60f-8096285cfc5c'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '1008 Credit',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 74,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 74,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$5,178.33',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$5,178.33',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '03/03/2024',
            dataType: 'date'
          }],
          type: 'data'
        }],
        guid: '7eca1dfb-b0e1-556b-93cf-c6d635557dc7'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '1009 Credit',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 70,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 71,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$3,705.73',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$3,705.73',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '03/04/2024',
            dataType: 'date'
          }],
          type: 'data'
        }],
        guid: '61951531-0c41-5f4a-b2b7-f85804ce5d95'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '1010 Credit',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 34,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 34,
            dataType: 'integer',
            hyperLink: {
              target: '_self',
              slug: 'master-transactions',
              params: {
                customDateRange: true,
                end: '2024-03-05T01:59:59.999Z',
                start: '2024-03-01T02:00:00.000Z',
                merchantBatchNumber: '1010',
                min: 0,
                locationId: 1
              }
            }
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$1,633.28',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$1,633.28',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '03/05/2024',
            dataType: 'date'
          }],
          type: 'data'
        }],
        guid: '725eb825-364e-589e-a84b-5b34953447a6'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Deposit Total',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 441,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 442,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'money',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$28,419.63',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }],
          type: 'data'
        }],
        guid: 'f960dcd6-4f45-56c1-ae74-9953136799a6'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Un-Deposited Tickets',
            dataType: 'string',
            tooltip: 'Total of Tickets that have not been finalized for batching, including current day tickets.'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 73,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 77,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$3,964.23',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }],
          type: 'data'
        }],
        guid: '9d602c09-536f-57c6-92b9-159794c20a95'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'data',
        parentGuid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Ticket Total',
            dataType: 'string',
            columnType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 514,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 519,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$28,790.38',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string'
          }],
          type: 'bottom'
        }],
        guid: '2ef0bb6f-9ef5-571b-a180-30ff233c1a99'
      }],
      rows: [{
        cols: [{
          colSpan: 1,
          rowSpan: 1,
          value: 'Batch Number',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Tickets',
          dataType: 'integer',
          columnType: 'header',
          tooltip: 'Total of Tickets that were closed and finalized in the batch.'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Transactions',
          dataType: 'integer',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Transaction Amount',
          dataType: 'float',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Deposit Amount',
          dataType: 'float',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Deposit Date',
          dataType: 'date',
          columnType: 'header'
        }],
        type: 'header'
      }],
      guid: 'cf3d4766-74f9-5277-b6b1-25e882b09a1f'
    }],
    title: 'Ticket Transaction Status',
    start: '2024-03-01T02:00:00.000Z',
    end: '2024-03-05T01:59:59.999Z'
  };
  _exports.TICKET_TRANSACTION_STATUS_REPORT_MULTIPLE_LOCATIONS = TICKET_TRANSACTION_STATUS_REPORT_MULTIPLE_LOCATIONS;
  var _default = TICKET_TRANSACTION_STATUS_REPORT;
  _exports.default = _default;
});