define("lh4/templates/components/internet-payments/ui/message/info-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a1iiI6ln",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui info message transition fade\"],[8],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\"],[1,[23,0,[\"args\",\"messageText\"]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/message/info-message.hbs"
    }
  });

  _exports.default = _default;
});