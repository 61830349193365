define("lh4/routes/authenticated/epro/manage-employees/employees/edit", ["exports", "lh4/routes/general-edit-route", "lh4/utils/employees"], function (_exports, _generalEditRoute, _employees) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'employee',
    childChangesets: {
      employeeDates: 'dateChangesets',
      employeePhones: 'phoneChangesets',
      payRates: 'payRateChangesets'
    },
    model: function model(_ref) {
      var employeeId = _ref.employeeId;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        employee: this.store.findRecord('echo-pro/employee', employeeId, {
          reload: true
        }),
        permissions: this.store.query('echo-pro/permission', {
          location: location
        }),
        jobs: this.store.query('echo-pro/job', {
          location: location
        }),
        printers: this.store.query('echo-pro/printer', {
          location: location
        })
      });
    },
    afterModel: function afterModel(model) {
      (0, _employees.addMissingPayrates)(this.store, model.employee);
    }
  });

  _exports.default = _default;
});