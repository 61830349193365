define("lh4/controllers/authenticated/bank-account/security", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    bankAccount: Ember.inject.service(),
    location: Ember.inject.service(),
    validateAndSaveChangesets: function validateAndSaveChangesets(changesets) {
      var _this = this;

      return changesets.map(function (record) {
        return record.validate().then(function () {
          if (record.isInvalid) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.execute();
          record.rollback();
          return true;
        }).catch(function () {
          _this.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    transitionOnClose: function transitionOnClose() {
      this.transitionToRoute('authenticated.locations.location.overview', this.location.model.id);
    },
    transitionToError: function transitionToError(error) {
      this.changeset.rollback();
      this.bankAccount.set('errorMessage', error.friendlyError);
      this.transitionToRoute('authenticated.bank-account.error');
    },
    actions: {
      save: function save() {
        var _this2 = this;

        if (this.isSaving) {
          return;
        }

        this.set('showErrors', true);
        this.set('isSaving', true);
        this.clearServerError();
        var questionPromises = this.validateAndSaveChangesets(this.changeset.questionChangesets);
        Ember.RSVP.all(questionPromises).then(function (values) {
          var areChildrenValid = values.every(function (value) {
            return value;
          });

          if (!areChildrenValid) {
            // Fields aren't valid
            // early exit so user can fix fields
            return Ember.RSVP.resolve();
          } // There is no validation for security as the only fields
          // are questions so we skip to right to saving.


          return _this2.changeset.save().then(function () {
            _this2.set('showErrors', false);

            _this2.transitionToRoute('authenticated.bank-account.edit');
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (errors && errors[0]) {
              _this2.transitionToError(errors[0]);
            }
          });
        }).finally(function () {
          return _this2.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});