define("lh4/components/reports/echo-pro/weekly-financial-overview", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    weekOfSelectedDay: Ember.computed('startsAt', 'test', function getter() {
      var startOfWeekSetting = this.get('startOfWeekSetting');
      var date = (0, _moment.default)(this.get('startsAt'));
      var diffDays = date.day() - (0, _moment.default)().day(startOfWeekSetting).day();
      var startOfWeek = date.clone().subtract(diffDays >= 0 ? diffDays : 7 + diffDays, 'days');
      var endOfWeek = startOfWeek.clone().add(6, 'days');
      return "".concat(startOfWeek.utc().format('MM/DD/YYYY'), " 4:00 AM - ").concat(endOfWeek.utc().format('MM/DD/YYYY'), " 3:59AM UTC");
    })
  });

  _exports.default = _default;
});