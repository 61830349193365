define("lh4/routes/authenticated/epro/payments/tenders/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'tender',
    registerSettings: Ember.inject.service('echo-pro/register-settings'),
    model: function model() {
      return Ember.RSVP.hash({
        tender: this.store.createRecord('echo-pro/tender', {
          locationId: this.location.model.id
        }),
        jobs: this.modelFor('authenticated.epro.payments.tenders').jobs,
        terminalHasOnboardReader: this.registerSettings.getRegisterSettings(this.location.model.id, 'hardware', 'payment_device_model').then(function (response) {
          return response.some(function (item) {
            return item.value === 'ONBOARD_READER';
          });
        }),
        locationSettings: this.store.queryRecord('echo-pro/setting', {
          location: this.location.model.id,
          type: ['accounting', 'business', 'processing']
        })
      });
    }
  });

  _exports.default = _default;
});