define("lh4/mixins/reputation/reputation-account-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createReputationAccountTypesMixin;

  /**
   * Create Mixin
   *
   * @param {string} accountField - account field name used by children
   * @param {string} accountFieldValue - direct value field name
   * @returns {Ember.Mixin} mixin
   */
  function createReputationAccountTypesMixin(accountField, accountFieldValue) {
    var typeField = accountFieldValue || "".concat(accountField, ".type");
    return Ember.Mixin.create({
      accountApi: Ember.inject.service('reputation/account-api'),

      /* @param required */
      accountSelected: null,
      checkTypeSafe: function checkTypeSafe(checkingCallback) {
        if (!this.get(typeField)) {
          return false;
        }

        return checkingCallback(this.get(typeField));
      },
      isFbPage: Ember.computed(typeField, function getter() {
        var _this = this;

        return this.checkTypeSafe(function (type) {
          return _this.accountApi.isFbPage(type);
        });
      }),
      isGoogleBusiness: Ember.computed(typeField, function getter() {
        var _this2 = this;

        return this.checkTypeSafe(function (type) {
          return _this2.accountApi.isGoogleBusiness(type);
        });
      }),
      isYelp: Ember.computed(typeField, function getter() {
        var _this3 = this;

        return this.checkTypeSafe(function (type) {
          return _this3.accountApi.isYelp(type);
        });
      })
    });
  }
});