define("lh4/components/ui/search/report-search", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['report-helper'],
    searchText: '',
    onSearch: null,
    isLoading: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _jquery.default)('input').on('paste', function () {
        Ember.run.next(_this, function () {
          _this.send('onSearchChanged');
        });
      });
    },
    actions: {
      onSearchChanged: function onSearchChanged() {
        this.set('isLoading', true);

        if (!this.searchText || this.searchText.length >= 1) {
          Ember.run.debounce(this, this._onSearchActual, 700);
        }
      },
      onClear: function onClear() {
        this.set('searchText', null);
        this.set('isLoading', false);
        this.sendAction('onSearch', this.searchText);
      }
    },
    _onSearchActual: function _onSearchActual() {
      this.sendAction('onSearch', this.searchText);
    }
  });

  _exports.default = _default;
});