define("lh4/mirage/fixtures/reservations/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RESERVATIONS_SETTINGS = void 0;
  var RESERVATIONS_SETTINGS = {
    settings: [{
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-10-03T07:04:54.595Z',
      locationId: 3796,
      name: 'max-party-size',
      value: '10'
    }, {
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-10-03T07:04:54.595Z',
      locationId: 3796,
      name: 'notification-email',
      value: ''
    }, {
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-10-03T07:04:54.595Z',
      locationId: 3796,
      name: 'notification-email-enabled',
      value: ''
    }, {
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-12-27T14:50:20.033Z',
      locationId: 3796,
      name: 'notification-phone-enabled',
      value: ''
    }, {
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-12-27T14:50:36.877Z',
      locationId: 3796,
      name: 'notification-phone-number',
      value: ''
    }, {
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-10-03T07:04:54.595Z',
      locationId: 3796,
      name: 'turnaround-10',
      value: '60'
    }, {
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-10-03T07:04:54.595Z',
      locationId: 3796,
      name: 'turnaround-2',
      value: '60'
    }, {
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-10-03T07:04:54.595Z',
      locationId: 3796,
      name: 'turnaround-4',
      value: '60'
    }, {
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-10-03T07:04:54.595Z',
      locationId: 3796,
      name: 'turnaround-6',
      value: '60'
    }, {
      createdAt: '2023-10-03T07:04:54.595Z',
      updatedAt: '2023-10-03T07:04:54.595Z',
      locationId: 3796,
      name: 'turnaround-8',
      value: '60'
    }],
    meta: {
      count: 1
    }
  };
  _exports.RESERVATIONS_SETTINGS = RESERVATIONS_SETTINGS;
  var _default = RESERVATIONS_SETTINGS;
  _exports.default = _default;
});