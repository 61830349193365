define("lh4/components/ui/fields/dropdown-input", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'field', 'padded', 'stackable', 'grid'],
    didRender: function didRender() {
      this.$('.ui.dropdown').focus(function (event) {
        (0, _jquery.default)(event.target).closest('.field').addClass('focused');
      }).blur(function (event) {
        (0, _jquery.default)(event.target).closest('.field').removeClass('focused');
      });
    }
  });

  _exports.default = _default;
});