define("lh4/components/reports/financial-overview-tables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    classNames: ['report-summary-tables'],
    showCountAmount: true,
    showTable: false,
    media: Ember.inject.service(),
    showCountAmountToggle: Ember.computed('table', function getter() {
      var data = this.get('table');
      var headers = data.headers;
      return headers.includes('Count');
    }),
    formatChartData: Ember.computed('table', 'showCountAmount', 'showTable', 'showCountAmountToggle', function getter() {
      var _this = this;

      var data = this.get('table');
      var results = data.results;
      var formattedData = [];
      var showCountAmount = this.get('showCountAmount');
      results.forEach(function (x) {
        if (data.name === 'Discounts') {
          if (x[0] !== 'Total') {
            var obj = {};

            if (!showCountAmount) {
              obj.value = parseInt(x[2], 10);
            } else {
              obj.value = parseInt(x[3], 10);
            }

            var _x = _slicedToArray(x, 1);

            obj.label = _x[0];
            formattedData.push(obj);
          }
        } else if (x[0] !== 'Total' && x[0] !== 'Subtotal') {
          var _obj = {};

          if (!_this.get('showCountAmountToggle')) {
            _obj.value = parseInt(x[1], 10);
          } else if (!showCountAmount) {
            _obj.value = parseInt(x[1], 10);
          } else {
            _obj.value = parseInt(x[2], 10);
          }

          var _x2 = _slicedToArray(x, 1);

          _obj.label = _x2[0];
          formattedData.push(_obj);
        }
      });
      return formattedData;
    }),
    categoryAxis: Ember.computed('media.isMobile', 'formatChartData', function getter() {
      if (!this.media.isMobile && this.formatChartData.length < 6) {
        return {};
      }

      return {
        labelRotation: this.formatChartData.length > 1 ? 90 : 0,
        autoGridCount: false,
        gridCount: this.formatChartData.length
      };
    }),
    amountChartOptions: Ember.computed('categoryAxis', function getter() {
      return {
        balloonText: '$[[value]]',
        categoryField: 'label',
        valueAxesOptions: {
          precision: 2,
          unit: '$',
          unitPosition: 'left'
        },
        valueField: 'value',
        categoryAxis: this.categoryAxis
      };
    }),
    countChartOptions: Ember.computed('categoryAxis', function getter() {
      return {
        categoryField: 'label',
        valueField: 'value',
        categoryAxis: this.categoryAxis
      };
    }),
    formatTableData: Ember.computed('table', function getter() {
      var data = this.get('table');
      var resultsArrays = data.results;
      var headers = data.headers;
      var currencyTitles = ['Gross Sales', 'Net Income', 'Amount', 'Net Amount'];
      var titleIndexes = [];
      currencyTitles.forEach(function (title) {
        if (headers.indexOf(title) !== -1) {
          titleIndexes.push(headers.indexOf(title));
        }
      });
      var formattedResults = [];
      resultsArrays.forEach(function (resultArray) {
        var formattedResult = [];
        resultArray.forEach(function (result, index) {
          if (titleIndexes.includes(index)) {
            formattedResult.push("$".concat(result));
          } else {
            formattedResult.push(result);
          }
        });
        formattedResults.push(formattedResult);
      });
      return formattedResults;
    }),
    actions: {
      chartSelection: function chartSelection() {
        this.toggleProperty('showTable');
      },
      toggleCountAmount: function toggleCountAmount(bool) {
        this.set('showCountAmount', bool);
      }
    }
  });

  _exports.default = _default;
});