define("lh4/controllers/authenticated/customer-engagement/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessagesHelper: Ember.inject.service(),
    location: Ember.inject.service(),
    actions: {
      goBack: function goBack() {
        this.transitionToRoute('authenticated.customer-engagement.campaigns');
      },
      changeLogo: function changeLogo(id) {
        this.transitionToRoute('authenticated.locations.location.overview', id);
      }
    }
  });

  _exports.default = _default;
});