define("lh4/controllers/authenticated/locations/location/echo-pro/employee-schedule/template/create", ["exports", "lh4/mixins/edit-controller-mixin"], function (_exports, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {
    modelName: 'model.template',
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    actions: {
      afterSaveHook: function afterSaveHook() {
        this.metrics.trackEvent('MixpanelCustom', {
          event: 'Employee Schedule Update Completed',
          'Update Name': 'Create Template ',
          'Schedule Start Date': this.model.range.from.format('DD/MM/YYYY'),
          'Missing Recipients': true,
          locationId: this.location.model.id,
          merchantId: this.location.model.merchantId
        });
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
      },
      clear: function clear() {
        this.get('model.template').rollbackAttributes();
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule');
      }
    }
  });

  _exports.default = _default;
});