define("lh4/components/reputation/post-preview", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    /* @params */
    userName: null,
    date: null,
    comment: null,
    rating: null,
    maxRating: 5,
    dateDiff: Ember.computed('date', function getter() {
      if (!this.get('date')) {
        return null;
      }

      return "".concat((0, _moment.default)().diff((0, _moment.default)(this.get('date')), 'days'), " d");
    })
  });

  _exports.default = _default;
});