define("lh4/components/ui/form-fields/select-field", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _field.default.extend({
    /* @param Array<{name, value}> options - Options available to select */
    options: [],
    placeholder: null,
    defaultText: Ember.computed.alias('placeholder'),
    nullValueOptionName: null,

    /**
     * @param {Boolean} allowUnsafeHtmlRendering - decides whether to use {{ }} or {{{ }}} for label rendering.
     * DANGEROUS! If you're not sure if your html is safe or not, DO NOT CHANGE THIS TO 'TRUE'
     * or it may become susceptible to XSS attack: https://gist.github.com/jamesarosen/478db5faef370eac43fb
     */
    allowUnsafeHtmlRendering: false,
    dataTest: 'form-field-select-field',

    /**
     * @param {Boolean} includeInactiveStyle - decides whether to render items where isActive == false with
     * a different style. NOTE: could be removed someday if the inactive style is used in all pages of the application
     */
    includeInactiveStyle: false,
    dropdownClass: Ember.computed('label', 'fullWidth', 'fieldWidth', 'allowSearch', 'allowMultiple', function compute() {
      var widthString;

      if (this.fieldWidth) {
        widthString = this.fieldWidth;
      } else if (!this.label) {
        widthString = 'sixteen';
      } else {
        widthString = this.fullWidth ? 'twelve' : 'eight';
      }

      var search = this.allowSearch ? ' search' : '';
      var multiple = this.allowMultiple ? ' multiple' : '';
      return "ui ".concat(widthString, " wide column").concat(search).concat(multiple, " selection floating");
    }),
    optionItems: Ember.computed('options', function getter() {
      var _this$options, _this$options2;

      var nullValueOption = {
        name: this.nullValueOptionName,
        value: null,
        isActive: true
      };

      if (!((_this$options = this.options) !== null && _this$options !== void 0 && _this$options.length) && !this.nullValueOptionName) {
        return [];
      }

      if (!((_this$options2 = this.options) !== null && _this$options2 !== void 0 && _this$options2.length) && !!this.nullValueOptionName) {
        return [nullValueOption];
      }

      var options = Array.from(this.options);

      if (options.every(function (o) {
        return typeof o === 'string';
      })) {
        return options.map(function (str) {
          return {
            name: str,
            value: str
          };
        });
      }

      var mappedOptions = options.map(function (option) {
        return _objectSpread(_objectSpread({}, option), {}, {
          value: typeof option.value !== 'undefined' ? option.value : option.name
        });
      });

      if (this.nullValueOptionName) {
        return [nullValueOption].concat(_toConsumableArray(mappedOptions));
      }

      return mappedOptions;
    }),
    updateDropdownOptions: function updateDropdownOptions(dropdown) {
      // Get all selected labels within this dropdown
      var selectedLabels = dropdown.querySelectorAll('.ui.label'); // Get all dropdown items within this dropdown

      var dropdownItems = dropdown.querySelectorAll('.ui.vertical.menu .item');
      var selectedValues = new Set();
      selectedLabels.forEach(function (label) {
        var dataValue = label.getAttribute('data-value');
        selectedValues.add(dataValue);
      }); // Update classes for dropdown items

      dropdownItems.forEach(function (item) {
        var dataValue = item.getAttribute('data-value');

        if (selectedValues.has(dataValue)) {
          item.classList.add('selected', 'active', 'filtered');
        } else {
          item.classList.remove('selected', 'active', 'filtered');
        }
      });
    },
    willRender: function willRender() {
      var _this = this;

      var nullSelection;

      if (this.nullValueOptionName && !this.changeset.get(this.property)) {
        nullSelection = this.optionItems.find(function (option) {
          return option.name === _this.nullValueOptionName;
        });
      }

      if (nullSelection) {
        this.defaultText = nullSelection.name;
      }
    },
    didRender: function didRender() {
      // Select only dropdowns with the multiple selection class
      if (this.allowMultiple) {
        this.updateDropdownOptions(this.$('.ui.dropdown').get(0));
      }

      this.$('.ui.dropdown').focus(this.actions.focusField.bind(this)).blur(this.actions.blurField.bind(this));

      if (this.allowSearch) {
        this.$('.ui.dropdown input.search').focus(this.actions.focusField.bind(this)).blur(this.actions.blurField.bind(this));
      }
    }
  });

  _exports.default = _default;
});