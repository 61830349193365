define("lh4/controllers/authenticated/internet-payments/customers/details", ["exports", "lh4/config/environment", "lh4/controllers/internet-payments/general-list-controller", "lh4/enums/sorting-direction", "lh4/utils/try-json-parse"], function (_exports, _environment, _generalListController, _sortingDirection, _tryJsonParse2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var HEADERS = [{
    field: 'date',
    name: 'Date',
    sortable: true
  }, {
    field: 'amount',
    name: 'Amount',
    sortable: true
  }, {
    field: 'orderStatus',
    name: 'Order Status'
  }, {
    field: 'transactionId',
    name: 'Transaction ID'
  }, {
    field: 'transType',
    name: 'Type'
  }, {
    field: 'acquirerResponse',
    name: 'Acquirer Response'
  }, {
    field: 'riskStatus',
    name: 'Risk'
  }];

  var _default = _generalListController.default.extend({
    router: Ember.inject.service(),
    ajaxDownload: Ember.inject.service(),
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    headers: HEADERS,
    errorModel: null,
    isLoading: false,
    matchPartial: ['status', 'riskStatus', 'type', 'date', 'amount', 'invoice'],
    queryParams: ['detailsLimit', 'detailsOffset', 'detailsSearchTerm', 'detailsSortAsc', 'detailsSortBy', 'detailsSortDir'],
    // TODO: revert back to 10 with LH-19351
    detailsLimit: 100,
    detailsOffset: 0,
    detailsSearchTerm: null,
    detailsSortAsc: false,
    detailsSortBy: 'date',
    detailsSortDir: _sortingDirection.default.DESC,
    isDownloading: false,
    getIndex: function getIndex(id) {
      return this.model.navList.findIndex(function (x) {
        return x.id === id;
      });
    },
    previousCustomer: Ember.computed('model', function getter() {
      var index = this.getIndex(this.model.customer.id);

      if (index > 0) {
        return this.model.navList[index - 1];
      }

      return null;
    }),
    previousCustomerName: Ember.computed('previousCustomer', function getter() {
      var _this$previousCustome;

      return (_this$previousCustome = this.previousCustomer) === null || _this$previousCustome === void 0 ? void 0 : _this$previousCustome.name;
    }),
    nextCustomer: Ember.computed('model', function getter() {
      var index = this.getIndex(this.model.customer.id);

      if (index < this.model.navList.length - 1) {
        return this.model.navList[index + 1];
      }

      return null;
    }),
    nextCustomerName: Ember.computed('nextCustomer', function getter() {
      var _this$nextCustomer;

      return (_this$nextCustomer = this.nextCustomer) === null || _this$nextCustomer === void 0 ? void 0 : _this$nextCustomer.name;
    }),
    latestTransaction: Ember.computed('model', function getter() {
      var transactions = this.model.transactions;

      if (transactions.length && transactions.find(function (t) {
        return t.hostResponse;
      })) {
        var _transactions$filter$ = transactions.filter(function (transaction) {
          return transaction.hostResponse !== null;
        }).sortBy('date').get('lastObject'),
            displayV2Preferred = _transactions$filter$.displayV2Preferred,
            weightedRiskTotal = _transactions$filter$.weightedRiskTotal,
            date = _transactions$filter$.date;

        return {
          displayV2Preferred: displayV2Preferred,
          weightedRiskTotal: weightedRiskTotal,
          date: date
        };
      }

      return {};
    }),
    customerTransactionAggregate: Ember.computed('model.transactions', function getter() {
      var transactions = this.model.transactions;
      var customerReferences = [];
      transactions.forEach(function (transaction) {
        if (transaction.customerReference && !customerReferences.includes(transaction.customerReference)) {
          customerReferences.push(transaction.customerReference);
        }
      });
      return {
        customerReference: customerReferences.join(', '),
        customerReferenceCount: customerReferences.length
      };
    }),
    actions: {
      onRowClick: function onRowClick(record) {
        this.transitionToRoute('authenticated.internet-payments.transactions.master-transactions.edit', record.transbaseId);
      },
      onSearchInput: function onSearchInput(searchTerm) {
        this.setProperties({
          detailsSearchTerm: searchTerm,
          detailsOffset: 0
        });
      },
      onNewTransaction: function onNewTransaction() {
        this.transitionToRoute('authenticated.internet-payments.customers.details.create-transaction');
      },
      goBack: function goBack() {
        var _params$get, _params$get2, _params$get3, _params$get4;

        var params = new URL(window.location.href).searchParams;
        var start = (_params$get = params.get('start')) !== null && _params$get !== void 0 ? _params$get : undefined;
        var end = (_params$get2 = params.get('end')) !== null && _params$get2 !== void 0 ? _params$get2 : undefined;
        var max = (_params$get3 = params.get('max')) !== null && _params$get3 !== void 0 ? _params$get3 : undefined;
        var min = (_params$get4 = params.get('min')) !== null && _params$get4 !== void 0 ? _params$get4 : undefined;
        this.router.transitionTo('authenticated.internet-payments.customers.index', {
          queryParams: {
            start: start,
            end: end,
            min: min,
            max: max
          }
        });
      },
      nextCustomer: function nextCustomer() {
        var nextId = this.nextCustomer.id;
        this.transitionToRoute('authenticated.internet-payments.customers.details', nextId);
      },
      previousCustomer: function previousCustomer() {
        var prevId = this.previousCustomer.id;
        this.transitionToRoute('authenticated.internet-payments.customers.details', prevId);
      },
      onFilterChanged: function onFilterChanged(filter) {
        var _filter$sortAsc = filter.sortAsc,
            sortAsc = _filter$sortAsc === void 0 ? this.detailsSortAsc : _filter$sortAsc,
            _filter$sortBy = filter.sortBy,
            sortBy = _filter$sortBy === void 0 ? this.detailsSortBy : _filter$sortBy,
            _filter$limit = filter.limit,
            limit = _filter$limit === void 0 ? this.detailsLimit : _filter$limit,
            _filter$offset = filter.offset,
            offset = _filter$offset === void 0 ? this.detailsOffset : _filter$offset;
        var sortDir = sortAsc ? 'ASC' : 'DESC';
        this.setProperties({
          detailsSortAsc: sortAsc,
          detailsSortDir: sortDir,
          detailsSortBy: sortBy,
          detailsLimit: limit,
          detailsOffset: offset
        });
      },
      downloadTransactions: function downloadTransactions(fileType) {
        var _this = this;

        var _this$model$transacti = this.model.transactionParams,
            locationId = _this$model$transacti.locationId,
            start = _this$model$transacti.start,
            end = _this$model$transacti.end,
            searchTerm = _this$model$transacti.searchTerm,
            cardTokens = _this$model$transacti.cardTokens; // remove anything that's falsy

        var params = Object.entries({
          locationId: locationId,
          start: start,
          end: end,
          searchTerm: searchTerm
        }).filter(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              value = _ref2[1];

          return value;
        });
        cardTokens.forEach(function (cardToken) {
          return params.push(['cardTokens[]', cardToken]);
        });
        this.matchPartial.forEach(function (item) {
          return params.push(['matchPartial[]', item]);
        });
        var queryString = new URLSearchParams(params).toString();
        var fileExt = fileType.toLowerCase();
        this.set('isDownloading', true);
        this.ajaxDownload.request("".concat(_environment.default.host, "/api/v1/internet-payments/transactions/").concat(fileExt, "?").concat(queryString), "transactions-".concat(start, "-").concat(end, ".").concat(fileExt), function (xhr) {
          var _tryJsonParse, _tryJsonParse$error;

          _this.set('errorModel', {
            errors: [{
              code: xhr.status,
              message: 'There was an error exporting transactions'
            }],
            message: (_tryJsonParse = (0, _tryJsonParse2.default)(xhr.responseText)) === null || _tryJsonParse === void 0 ? void 0 : (_tryJsonParse$error = _tryJsonParse.error) === null || _tryJsonParse$error === void 0 ? void 0 : _tryJsonParse$error.friendlyError
          });
        }, null, false, 'blob', function () {
          return _this.set('isDownloading', false);
        });
      }
    }
  });

  _exports.default = _default;
});