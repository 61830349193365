define("lh4/components/echo-pro/employee-schedule/calendar-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    onClick: ''
  });

  _exports.default = _default;
});