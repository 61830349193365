define("lh4/routes/authenticated/reporting/gift-card-reporting", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/utils/set-reports-default-location"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin, _setReportsDefaultLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    queryParams: {
      reportType: {
        replace: true
      },
      selectedLocations: {
        replace: true
      },
      enabledProduct: {
        replace: true
      },
      reportPeriodSelected: {
        replace: true
      },
      generate: {
        replace: true
      },
      'dates.startsAt': {
        replace: true
      },
      'dates.startsAtTime': {
        replace: true
      },
      'dates.endsAt': {
        replace: true
      },
      'dates.endsAtTime': {
        replace: true
      }
    },
    requiredPermissions: ['manage virtual-gift-card in general', 'access gift cards reporting in general'],
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    ajax: Ember.inject.service(),
    reportApi: Ember.inject.service('reports/drill-down-report-api'),
    beforeModel: function beforeModel() {
      if (this.can.cannot('manage virtual-gift-card in general') || this.can.cannot('access gift cards reporting in general')) {
        this.transitionTo('authenticated.dashboard2');
      }

      this.reportApi.setRequestUrl('gift-card-reports');
    },
    model: function model() {
      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations,
        newSubscription: this.store.createRecord('reports/subscription'),
        preferences: this.store.findRecord('preferences', this.get('tokenData.data.sub')),
        reportTypes: this.reportApi.fetchReportList(this.location.model.id)
      });
    },
    setupController: function setupController(controller) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [controller].concat(args));

      (0, _setReportsDefaultLocation.default)(controller, this.location.model.id);
      controller.set('showTimeZoneWarning', false);
      controller.set('showGiftCardPeriodDisclaimer', true);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('currentReport', null);
      }
    }
  });

  _exports.default = _default;
});