define("lh4/routes/authenticated/internet-payments/account-settings/coming-soon", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default);

  _exports.default = _default;
});