define("lh4/mixins/model-cloneable", ["exports", "ember-data-copyable"], function (_exports, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin to clone Ember Models
   * Purpose of this is to have external dependencies and configuration in one place to prevent duplication and, in case
   * we need, easier migration to other libraries.
   */
  var _default = Ember.Mixin.create(_emberDataCopyable.default, {
    /**
     * Make shallow clone (relations are copied, not cloned)
     * @param {object} overrideValues - adjust model fields
     * @returns {Promise<Ember.Model>} cloned model
     */
    cloneShallow: function cloneShallow() {
      var _this = this;

      var overrideValues = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var originalRelations = {};
      this.eachRelationship(function (name, descriptor) {
        if (descriptor.isRelationship) {
          originalRelations[descriptor.key] = _this.get(descriptor.key);
        }
      });
      return this.copy(false, {
        overwrite: Object.assign(originalRelations, overrideValues)
      });
    },
    duplicateModel: function duplicateModel() {
      var _this2 = this;

      var overrideValues = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var originalAttributes = {};
      var originalRelations = {};
      this.eachRelationship(function (name, descriptor) {
        if (descriptor.isRelationship) {
          originalRelations[descriptor.key] = _this2.get(descriptor.key);
        }
      });
      this.eachAttribute(function (name, descriptor) {
        if (descriptor.options && descriptor.options.isCopyable) {
          originalAttributes[descriptor.name] = _this2.get(descriptor.name);
        } else if (descriptor.type) {
          originalAttributes[descriptor.name] = descriptor.options.defaultValue !== undefined ? descriptor.options.defaultValue : null;
        } else {
          originalAttributes[descriptor.name] = undefined;
        }
      });
      return this.copy(false, {
        overwrite: Object.assign(originalAttributes, originalRelations, overrideValues)
      });
    },
    // Use when the relation is a mapping one. Re-create to renew internal guids.
    recreateMappingRelation: function recreateMappingRelation(mappingName, fieldName) {
      var _this3 = this;

      var mappings = this[mappingName].map(function (mapping) {
        return _this3.store.createRecord(fieldName, mapping.toJSON());
      });
      this[mappingName].clear();
      this[mappingName].addObjects(mappings);
      return this;
    }
  });

  _exports.default = _default;
});