define("lh4/models/customer-engagement/campaign-template", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    guid: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    headerText: _emberData.default.attr('string'),
    bodyText: _emberData.default.attr('string'),
    imageUrl: _emberData.default.attr('string'),
    backgroundColor: _emberData.default.attr('string')
  });

  _exports.default = _default;
});