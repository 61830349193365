define("lh4/routes/authenticated/locations/location/revenue-centers/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    router: Ember.inject.service(),
    modelName: 'revenueCenter',
    beforeModel: function beforeModel() {
      if (this.corporation.isMenuManagedByCorporate) {
        this.router.transitionTo('authenticated.locations.location.revenue-centers');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        revenueCenter: this.store.createRecord('echo-pro/revenue-center', {
          locationId: this.location.model.id
        })
      });
    }
  });

  _exports.default = _default;
});