define("lh4/mirage/factories/loyalty-discount", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    isActive: false,
    loyaltyProgramGuid: function loyaltyProgramGuid() {
      return _faker.default.datatype.uuid();
    },
    type: 'amount',
    name: function name() {
      return _faker.default.commerce.productName();
    },
    value: 10,
    pointCost: 1
  });

  _exports.default = _default;
});