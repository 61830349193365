define("lh4/controllers/authenticated/locations/location/revenue-centers/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    isModelAndChangesetPristine: function isModelAndChangesetPristine() {
      return this.changeset.get('isPristine');
    }
  });

  _exports.default = _default;
});