define("lh4/templates/components/ui/dropdown-static/dropdown-static-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DLamv4cY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"item ui-dropdown-select-option\"],[11,\"data-value\",[22,\"value\"]],[8],[0,\"\\n\\t\"],[1,[22,\"text\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/dropdown-static/dropdown-static-option.hbs"
    }
  });

  _exports.default = _default;
});