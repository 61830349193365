define("lh4/templates/components/ui/icons/sidebar/flare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BAyJVU41",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M1.00098 13V11H7.00098V13H1.00098ZM7.75098 9.15L5.65098 7.05L7.05098 5.65L9.15098 7.75L7.75098 9.15ZM11.001 7V1H13.001V7H11.001ZM16.251 9.15L14.851 7.75L16.951 5.65L18.351 7.05L16.251 9.15ZM17.001 13V11H23.001V13H17.001ZM12.001 15C11.1676 15 10.4593 14.7083 9.87598 14.125C9.29264 13.5417 9.00098 12.8333 9.00098 12C9.00098 11.1667 9.29264 10.4583 9.87598 9.875C10.4593 9.29167 11.1676 9 12.001 9C12.8343 9 13.5426 9.29167 14.126 9.875C14.7093 10.4583 15.001 11.1667 15.001 12C15.001 12.8333 14.7093 13.5417 14.126 14.125C13.5426 14.7083 12.8343 15 12.001 15ZM16.951 18.35L14.851 16.25L16.251 14.85L18.351 16.95L16.951 18.35ZM7.05098 18.35L5.65098 16.95L7.75098 14.85L9.15098 16.25L7.05098 18.35ZM11.001 23V17H13.001V23H11.001Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/flare.hbs"
    }
  });

  _exports.default = _default;
});