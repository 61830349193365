define("lh4/mirage/fixtures/integration/s4/api-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API_KEYS = [{
    key: '6925717460233927694d2f747d532324',
    environment: 'UAT',
    expireDate: '2023-02-13T19:02:47.153+02:00',
    locationId: 1,
    notificationEmail: 'john.smith@example.com'
  }, {
    key: '276839454c2152235a646e6736385b3b',
    environment: 'Development',
    expireDate: '2023-11-01T19:02:47.153+02:00',
    locationId: 1,
    notificationEmail: 'joe.jones@example.com'
  }, {
    key: '746925775c2f2278792a575d3b505550',
    environment: 'UAT',
    expireDate: '2023-11-01T19:02:47.153+02:00',
    locationId: 1,
    notificationEmail: 'john.smith@example.com'
  }];
  var _default = API_KEYS;
  _exports.default = _default;
});