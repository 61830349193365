define("lh4/abilities/hospitality/online-ordering", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    canView: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('hospitality') && this.hasLocationPermission(_permissions.default.HOSP.ONLINE_ORDERING);
    }),
    canManage: (0, _abilityMixin.computedAbility)(function getter() {
      var hasLocationKey = this.get('location.model.locationKey');

      if (!hasLocationKey) {
        return false;
      }

      return this.hasService('online-ordering') && this.get('canView');
    }),
    canRegister: (0, _abilityMixin.computedAbility)(function getter() {
      return !this.hasService('online-ordering') && this.get('canView');
    })
  });

  _exports.default = _default;
});