define("lh4/adapters/echo-pro/menu-version", ["exports", "lh4/adapters/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var corpId = snapshot.adapterOptions.corpId;
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(corpId, "/menu-updates/").concat(id);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var corpId = snapshot.record.corpId;
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(corpId, "/menu-updates/").concat(id);
    },
    urlForQuery: function urlForQuery(query) {
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(query.corporation, "/menu-updates");
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(this.location.model.corpId, "/menu-updates");
    }
  });

  _exports.default = _default;
});