define("lh4/models/echo-pro/price-schedule", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/utils/boolean-property-array", "moment"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _booleanPropertyArray, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var getDefaultStartsAt = function getDefaultStartsAt() {
    return _moment.default.utc('1970-01-01 12:00:00.000 AM', 'YYYY-MM-DD LT');
  };

  var getDefaultEndsAt = function getDefaultEndsAt() {
    return _moment.default.utc('1970-01-01 11:59:59.999 PM');
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Price Schedule Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255,
        description: 'Price Schedule Name'
      })]
    },
    startsAt: (0, _emberCpValidations.validator)('date', {
      before: Ember.computed.alias('model.endsAt'),
      message: 'End Time cannot be before the Start Time'
    }),
    daysOfWeek: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'At least one weekday must be selected'
    })
  });
  var DAY_OF_WEEK_OPTIONS = {
    isOnMonday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnTuesday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnWednesday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnThursday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnFriday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnSaturday: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isOnSunday: (0, _attr.default)('boolean', {
      defaultValue: false
    })
  };

  var _default = _model.default.extend(Validations, DAY_OF_WEEK_OPTIONS, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    daysOfWeek: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(DAY_OF_WEEK_OPTIONS)).concat([(0, _booleanPropertyArray.default)(Object.keys(DAY_OF_WEEK_OPTIONS))])),
    // IMPORTANT - this is actually millisecond, not seconds
    startsAtSeconds: (0, _attr.default)('number'),
    startsAt: (0, _attr.default)({
      defaultValue: getDefaultStartsAt
    }),
    // IMPORTANT - this is actually millisecond, not seconds
    endsAtSeconds: (0, _attr.default)('number'),
    endsAt: (0, _attr.default)({
      defaultValue: getDefaultEndsAt
    }),
    isVoided: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    priceScheduleMap: (0, _relationships.hasMany)('echo-pro/item-price-schedule-map'),
    priceScheduleGroup: (0, _relationships.belongsTo)('echo-pro/price-schedule-group'),
    createdAt: (0, _attr.default)('date'),
    allDay: Ember.computed('startsAt', 'endsAt', function rangeChanged() {
      return this.startsAt.isSame(getDefaultStartsAt()) && this.endsAt.isSame(getDefaultEndsAt());
    })
  });

  _exports.default = _default;
});