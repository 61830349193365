define("lh4/mirage/factories/epro-tender", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    locationId: 1,
    name: 'Test',
    allowsChange: false,
    isActive: true,
    isLocked: false,
    isCustomerRequired: true,
    isTipAllowed: false,
    opensDrawer: false,
    promptForTipOnDevice: false,
    referenceCode: 1,
    referenceText: 'Test Text'
  });

  _exports.default = _default;
});