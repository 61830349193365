define("lh4/components/tables/responsive-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var REVERSED_SORT_TYPES = ['active'];

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    media: Ember.inject.service(),
    location: Ember.inject.service(),
    classNames: ['responsive-table'],
    classNameBindings: ['isScrollable:scrollable-table'],

    /**
     * @param {Array} - Array of objects of { name: {string}, field: {string}, width: {string} }.
     */
    headers: [],

    /**
     * @param {Array} - Array of any type to display.
     */
    rows: [],

    /**
     * @param {Boolean} - Excludes ember data rows where isNew === true
     */
    excludeNew: false,
    fixed: false,
    onFilterChange: null,
    onRowClick: null,
    limit: 50,
    maxPagesShown: 5,
    offset: 0,
    isScrollable: false,
    totalCount: 0,
    sortAsc: true,
    sortBy: 'product',
    currentPage: Ember.computed('offset', 'limit', function getter() {
      // Offset / Limit + 1
      // Math.floor is likely unneeded, but it helps read that this should
      // be an integer.
      return Math.floor(this.offset / this.limit) + 1;
    }),
    totalPageCount: Ember.computed('totalCount', 'limit', function getter() {
      // numberOfRows divided by limit with a minimum of 1
      return Math.max(1, Math.ceil(this.totalCount / this.limit));
    }),
    desktopHeaderOptions: Ember.computed('headers', 'sortBy', 'sortAsc', function getter() {
      var _this = this;

      return this.headers.map(function (header) {
        if (!header.sortable) {
          return header;
        }

        var ascClass = REVERSED_SORT_TYPES.includes(header.type) ? 'down' : 'up';
        var descClass = REVERSED_SORT_TYPES.includes(header.type) ? 'up' : 'down';
        var isSortActive = _this.sortBy === header.field;
        var sortDir = 'up';

        if (isSortActive) {
          sortDir = _this.sortAsc ? ascClass : descClass;
        }

        return _objectSpread(_objectSpread({}, header), {}, {
          isSortActive: isSortActive,
          sortDir: sortDir
        });
      });
    }),
    mobileSortOptions: Ember.computed('headers', function getter() {
      var _this2 = this;

      var sortableHeaders = this.headers.filter(function (header) {
        return header.sortable;
      });
      var options = [];
      sortableHeaders.forEach(function (header) {
        switch (header.type) {
          case 'active':
            options.push({
              value: "".concat(header.field, "-desc"),
              label: _this2.intl.t('components:tables:responsive-table:mobile-sort-options:active-to-not-active')
            });
            options.push({
              value: "".concat(header.field, "-asc"),
              label: _this2.intl.t('components:tables:responsive-table:mobile-sort-options:not-active-to-active')
            });
            break;

          case 'date':
            options.push({
              value: "".concat(header.field, "-desc"),
              label: "".concat(header.name, ": New to Old")
            });
            options.push({
              value: "".concat(header.field, "-asc"),
              label: "".concat(header.name, ": Old to New")
            });
            break;

          case 'number':
            options.push({
              value: "".concat(header.field, "-desc"),
              label: "".concat(header.name, ": ").concat(_this2.intl.t('components:tables:responsive-table:mobile-sort-options:default:high-to-low'))
            });
            options.push({
              value: "".concat(header.field, "-asc"),
              label: "".concat(header.name, ": ").concat(_this2.intl.t('components:tables:responsive-table:mobile-sort-options:default:low-to-high'))
            });
            break;

          default:
            options.push({
              value: "".concat(header.field, "-asc"),
              label: "".concat(header.name, ": ").concat(_this2.intl.t('components:tables:responsive-table:mobile-sort-options:default:a-to-z'))
            });
            options.push({
              value: "".concat(header.field, "-desc"),
              label: "".concat(header.name, ": ").concat(_this2.intl.t('components:tables:responsive-table:mobile-sort-options:default:z-to-a'))
            });
            break;
        }
      });
      return options;
    }),
    selectedSortOption: Ember.computed('sortAsc', 'sortBy', function getter() {
      return "".concat(this.sortBy, "-").concat(this.sortAsc ? 'asc' : 'desc');
    }),
    hasSortOptions: Ember.computed('mobileSortOptions', function getter() {
      return this.mobileSortOptions.length > 0;
    }),
    rowClickFunction: Ember.computed('onRowClick', 'disabled', function getter() {
      if (this.onRowClick && !this.disabled) {
        return this.onRowClick;
      }

      return null;
    }),
    changeFilters: function changeFilters(changes) {
      if (!this.onFilterChange) {
        return;
      }

      var limit = this.limit,
          sortAsc = this.sortAsc,
          sortBy = this.sortBy;

      var filters = _objectSpread({
        limit: limit,
        offset: 0,
        sortAsc: sortAsc,
        sortBy: sortBy
      }, changes);

      this.onFilterChange(filters);
    },
    actions: {
      handleLimitChange: function handleLimitChange(limitStr) {
        var _this3 = this;

        Ember.run.next(function () {
          var limit = parseInt(limitStr, 10);

          _this3.changeFilters({
            limit: limit
          });
        });
      },
      handleRowClick: function handleRowClick(record) {
        if (this.onRowClick) {
          this.onRowClick(record);
        }
      },
      handlePageChange: function handlePageChange(pageNumber) {
        var offset = (pageNumber - 1) * this.limit;
        this.changeFilters({
          offset: offset
        });
      },
      handleSortChange: function handleSortChange(header) {
        var sortBy = header.field;
        var sortAsc = sortBy === this.sortBy ? !this.sortAsc : !REVERSED_SORT_TYPES.includes(header.type);
        this.changeFilters({
          sortAsc: sortAsc,
          sortBy: sortBy
        });
      },
      handleMobileSortChange: function handleMobileSortChange(value) {
        var _value$split = value.split('-'),
            _value$split2 = _slicedToArray(_value$split, 2),
            sortBy = _value$split2[0],
            direction = _value$split2[1];

        var sortAsc = direction === 'asc';
        this.changeFilters({
          sortAsc: sortAsc,
          sortBy: sortBy
        });
      }
    }
  });

  _exports.default = _default;
});