define("lh4/serializers/hospitality/customer/address", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var addressTypes = [{
    name: 'Primary',
    id: 0
  }, {
    name: 'Alternate',
    id: 1
  }, {
    name: 'Work',
    id: 2
  }, {
    name: 'Others',
    id: 3
  }];

  var _default = _application.default.extend({
    keyForAttribute: function keyForAttribute(key) {
      if (key === 'type') {
        return 'addressType';
      }

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return this._super.apply(this, [key].concat(args));
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (json.addressType != null) {
        var validType = addressTypes.find(function (addressType) {
          return addressType.id === json.addressType;
        });
        json.addressType = validType ? validType.name : addressTypes[3].name;
      }

      for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
        args[_key2 - 2] = arguments[_key2];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.addressType != null) {
        var validType = addressTypes.find(function (adressType) {
          return adressType.name === json.addressType;
        });
        json.addressType = validType ? validType.id : 3;
      }

      return json;
    }
  });

  _exports.default = _default;
});