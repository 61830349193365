define("lh4/components/echo-pro/tableside/terminals-table", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    editRecord: Ember.inject.service('edit-record'),
    flashMessagesHelper: Ember.inject.service('flash-messages-helper'),
    terminals: [],
    pageSize: 20,
    currentPage: 1,
    selectedTerminal: null,
    selectedTerminalForDelete: null,
    totalPages: Ember.computed('terminals.[]', 'pageSize', function getter() {
      var total = this.get('terminals.length');
      return Math.ceil(total / this.get('pageSize'));
    }),
    terminalsOnPage: Ember.computed('terminals.[]', 'terminals.@each.isNew', 'currentPage', 'pageSize', function getter() {
      var terminals = this.get('terminals').toArray().filter(function (t) {
        return !t.get('isNew');
      }).sort(function (t1, t2) {
        return t1.get('createdAt') > t2.get('createdAt') ? -1 : 1;
      });
      var from = (this.get('currentPage') - 1) * this.get('pageSize');
      return terminals.splice(from, this.get('pageSize'));
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(window).on('click.tableside-table', function () {
        if (_this.get('selectedTerminal')) {
          _this.send('discardChanges', _this.get('selectedTerminal'));
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(window).off('click.tableside-table');

      this._super.apply(this, arguments);
    },
    actions: {
      onPageChange: function onPageChange(pageNo) {
        this.set('currentPage', pageNo);
      },
      onPageSizeChange: function onPageSizeChange(pageNo) {
        this.set('pageSize', parseInt(pageNo, 10));
        this.set('currentPage', 1);
      },
      selectTerminal: function selectTerminal(terminal) {
        if (this.get('selectedTerminal') && this.get('selectedTerminal') !== terminal) {
          this.send('discardChanges', this.get('selectedTerminal'));
        }

        if (!this.get('selectedTerminal')) {
          this.set('selectedTerminal', terminal);
        }
      },
      deleteTerminal: function deleteTerminal(terminal) {
        this.set('selectedTerminalForDelete', terminal);

        if (!terminal) {
          return false;
        }

        this.$('#delete-terminal-dialog').modal('show');
        return true;
      },
      deleteTerminalConfirmed: function deleteTerminalConfirmed() {
        var _this2 = this;

        var terminal = this.get('selectedTerminalForDelete');
        this.set('selectedTerminalForDelete', null);

        if (!terminal) {
          return;
        }

        terminal.destroyRecord().then(function () {
          _this2.get('terminals').removeObject(terminal);

          _this2.flashMessagesHelper.pushMessage('Unregistered successfully', 'success');
        }).catch(function (messages) {
          return _this2.flashMessagesHelper.pushMessages(messages, 'negative');
        });
      },
      saveTerminal: function saveTerminal(terminal) {
        var _this3 = this;

        this.flashMessagesHelper.clearMessages();

        if (this.get('selectedTerminal') !== terminal || !terminal.get('hasDirtyAttributes')) {
          this.set('selectedTerminal', null);
          return;
        }

        this.editRecord.validateAndSave(terminal).then(function () {
          _this3.set('selectedTerminal', null);

          _this3.flashMessagesHelper.pushMessage('Saved successfully', 'success');
        }).catch(function (messages) {
          return _this3.flashMessagesHelper.pushMessages(messages, 'negative');
        });
      },
      discardChanges: function discardChanges(terminal) {
        if (this.get('selectedTerminal') !== terminal) {
          return;
        }

        terminal.rollbackAttributes();
        this.set('selectedTerminal', null);
      }
    }
  });

  _exports.default = _default;
});