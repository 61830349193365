define("lh4/components/hospitality/choices-and-choice-sets/sets/options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var defaultChoice = this.get('model.defaultChoiceId');
      this.set('initialDefaultChoiceId', defaultChoice); // if defaultChoiceId is === 0 then there is no default

      if (defaultChoice) {
        this.set('useDefaultChoice', true);
      }
    },
    initialDefaultChoiceId: 0,
    useDefaultChoice: false,
    actions: {
      toggleDefaultChoice: function toggleDefaultChoice(checked) {
        var defaultChoice = this.get('model.defaultChoiceId');

        if (checked) {
          this.set('useDefaultChoice', true);

          if (this.initialDefaultChoiceId) {
            // if checked load initial default choice if there was one
            this.set('model.defaultChoiceId', this.initialDefaultChoiceId);
          } else if (!this.initialDefaultChoiceId) {
            // fallback if something went wrong, for no default choice it has to be 0
            this.set('model.defaultChoiceId', 0);
          }
        } else {
          if (defaultChoice !== 0) {
            // when unchecking save choice set if it was set
            this.set('initialDefaultChoiceId', this.get('model.defaultChoiceId'));
          }

          this.set('useDefaultChoice', false); // hospitality interprets 0 as no default

          this.set('model.defaultChoiceId', 0);
        }
      }
    }
  });

  _exports.default = _default;
});