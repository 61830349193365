define("lh4/routes/authenticated/locations/location/echo-pro/employee-schedule/shift/edit", ["exports", "lh4/mixins/routes/locations/location/echo-pro/employee-schedule/shift/edit-route-mixin"], function (_exports, _editRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_editRouteMixin.default, {
    modelInternal: function modelInternal(_ref) {
      var _this = this;

      var shiftId = _ref.shiftId;
      return this.store.findRecord('echo-pro/employee-shift', shiftId).catch(function (res) {
        if (res.errors && res.errors[0] && res.errors[0].code === 404) {
          return _this.transitionTo('page-not-found');
        }

        throw res;
      });
    }
  });

  _exports.default = _default;
});