define("lh4/services/reports/utils", ["exports", "lh4/utils/date-moment"], function (_exports, _dateMoment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPostBody = _exports.getLocationIds = void 0;

  /**
   * Get normalized location ids
   *
   * @param {Array<string|number>} [locationIds] - locationIds to normalize
   * @returns {Array} - array of normalized location ids
   */
  var getLocationIds = function getLocationIds() {
    var locationIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return locationIds.map(function (id) {
      return Number.parseInt(id, 10);
    });
  };
  /**
   * Get POST request body
   *
   * @param {{
   * startsAt: string,
   * endsAt: string,
   * locations: number[],
   * colorCodeStrategy: string,
   * offset: number,
   * limit: number }} options - request
   * allPages: boolean,
   * @returns {Object} - request body
   */


  _exports.getLocationIds = getLocationIds;

  var getPostBody = function getPostBody(_ref) {
    var startsAt = _ref.startsAt,
        endsAt = _ref.endsAt,
        locations = _ref.locations,
        colorCodeStrategy = _ref.colorCodeStrategy,
        offset = _ref.offset,
        limit = _ref.limit,
        allPages = _ref.allPages;
    return {
      start: (0, _dateMoment.getNormalizedDateStart)(startsAt),
      end: (0, _dateMoment.getNormalizedDateEnd)(endsAt),
      locations: getLocationIds(locations),
      colorCodeStrategy: colorCodeStrategy,
      offset: offset,
      limit: limit,
      allPages: allPages
    };
  };

  _exports.getPostBody = getPostBody;
});