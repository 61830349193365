define("lh4/routes/authenticated/social-media", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/utils/permissions"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    errorHandler: Ember.inject.service(),
    features: Ember.inject.service(),
    permissions: Ember.inject.service(),
    requiredPermissions: ['manage posts for social-medium', 'manage accounts for social-medium'],
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('social-media/account');
    },
    permissionCheckFailed: function permissionCheckFailed() {
      return this.replaceWith('authenticated.premium.customer-engagement');
    },
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations.filter(function (location) {
          return _this.permissions.hasService('lighthouse-premium', location);
        }).filter(function (location) {
          return _this.permissions.hasPermission(_permissions.default.SOCIAL_MEDIA.MANAGE_ACCOUNTS, location.id);
        }),
        accounts: this.store.findAll('social-media/account', {
          reload: true
        }).catch(function (err) {
          return _this.errorHandler.handleErrorCustomMessage(err, 'Could not load social media accounts');
        }),
        state: {
          selectedAccountId: null
        }
      });
    },
    redirect: function redirect(model, transition) {
      // Default sub-page
      if (transition.targetName === 'social-media' || transition.targetName === 'social-media.index') {
        return this.transitionTo('authenticated.social-media.post-scheduler');
      }

      return false;
    }
  });

  _exports.default = _default;
});