define("lh4/components/reports/solo/sales-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    itemTotalResults: Ember.computed('currentReport', function getter() {
      var _this$currentReport$t = this.currentReport.totalSummary,
          results = _this$currentReport$t.results,
          totalPrice = _this$currentReport$t.totalPrice,
          totalTips = _this$currentReport$t.totalTips,
          totalTax = _this$currentReport$t.totalTax,
          totalAmount = _this$currentReport$t.totalAmount;
      return {
        results: results.itemTotalResults,
        totals: {
          totalPrice: totalPrice,
          totalTips: totalTips,
          totalTax: totalTax,
          totalAmount: totalAmount
        }
      };
    }),
    cardBrandResults: Ember.computed('currentReport', function getter() {
      var _this$currentReport$t2 = this.currentReport.totalSummary,
          results = _this$currentReport$t2.results,
          totalTips = _this$currentReport$t2.totalTips,
          totalAmount = _this$currentReport$t2.totalAmount,
          tenderTotalMinusTips = _this$currentReport$t2.tenderTotalMinusTips;
      return {
        results: results.cardBrandResults,
        totals: {
          totalAmount: totalAmount,
          totalTips: totalTips,
          tenderTotalMinusTips: tenderTotalMinusTips
        }
      };
    })
  });

  _exports.default = _default;
});