define("lh4/routes/authenticated/epro/menu/modifiers/create", ["exports", "lh4/routes/general-create-route", "lh4/utils/increment-name"], function (_exports, _generalCreateRoute, _incrementName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    modelName: 'modifier',
    queryParams: {
      clone: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var clone = _ref.clone;
      return Ember.RSVP.hash({
        modifier: this.createNewModifier(clone),
        modifierSets: this.modelFor('authenticated.epro.menu.modifiers').modifierSets,
        printers: this.modelFor('authenticated.epro.menu.modifiers').printers,
        stylesheets: this.isEproColorPickerFeatureFlagEnabled() ? this.store.findAll('echo-pro/stylesheet') : null
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'New',
        'Entry ID': model.modifier.guid,
        'Menu Component': 'Modifier'
      });

      if (controller.clone) {
        // changing of changeset properties instead of model properties ensures thant changeset becomes dirty
        // and and confirm dialog is fired when trying to close modal after cloning even if nothing was changed
        var _this$store$peekRecor = this.store.peekRecord('echo-pro/modifier', controller.clone),
            name = _this$store$peekRecor.name,
            onlineName = _this$store$peekRecor.onlineName;

        controller.changeset.set('name', (0, _incrementName.default)(name));
        controller.changeset.set('onlineName', onlineName ? (0, _incrementName.default)(onlineName) : null);
      }

      controller.set('isSaving', false);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('clone', null);
      }
    },
    createNewModifier: function createNewModifier(cloneId) {
      return cloneId ? this.store.findRecord('echo-pro/modifier', cloneId).then(function (original) {
        return original.duplicateModel();
      }) : this.store.createRecord('echo-pro/modifier', {
        locationId: this.location.model.id
      });
    }
  });

  _exports.default = _default;
});