define("lh4/templates/components/ui/buttons/form-confirmation-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mgaA77HZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide computer sixteen wide mobile column left aligned\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"id\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",[29,[\"ui icon duplicate \",[22,\"setLoading\"],\" button\"]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"clone\"]],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"clone icon\"],[8],[9],[0,\"\\n\\t\\t\\t\\tDuplicate\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide computer sixteen wide mobile column right aligned\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"menu-button-set\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui discard \",[22,\"setLoading\"],\" button\"]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"discard\"]],[8],[0,\"\\n\\t\\t\\t\\tDiscard\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary \",[22,\"setLoading\"],\" button\"]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"save\"]],[8],[0,\"\\n\\t\\t\\t\\tSave\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/buttons/form-confirmation-buttons.hbs"
    }
  });

  _exports.default = _default;
});