define("lh4/controllers/authenticated/social-media/accounts/edit", ["exports", "jquery", "lh4/services/social-media/facebook"], function (_exports, _jquery, _facebook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editRecord: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    facebook: Ember.inject.service('social-media/facebook'),
    isFacebookPage: Ember.computed('model.account', function getter() {
      return this.get('model.account.type') === _facebook.ACCOUNT_TYPE_FB_PAGE;
    }),
    isLoading: false,
    initialAccountName: Ember.computed('model.account', function getter() {
      // To display initial name, without changes!
      return this.get('model.account.name');
    }),
    actions: {
      saveAccount: function saveAccount() {
        var _this = this;

        this.set('isLoading', true);
        this.editRecord.validateAndSave(this.get('model.account')).then(function () {
          _this.flashMessagesHelper.pushMessage('Account successfully updated', 'success');

          _this.transitionToRoute('authenticated.social-media.accounts');
        }).catch(function (errorMessages) {
          return _this.flashMessagesHelper.pushMessages(errorMessages);
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      },
      discardChanges: function discardChanges() {
        this.transitionToRoute('authenticated.social-media.accounts');
      },
      showDeleteDialog: function showDeleteDialog() {
        (0, _jquery.default)('#delete-social-media-account-dialog').modal('show');
      },
      deleteAccount: function deleteAccount() {
        var _this2 = this;

        if (this.get('loading')) {
          return;
        }

        this.set('loading', true);
        var account = this.get('model.account');
        var id = account.get('id');
        account.destroyRecord().then(function () {
          _this2.flashMessagesHelper.pushMessage('Successfully deleted account', 'success');

          _this2.transitionToRoute('authenticated.social-media.accounts');
        }).catch(function (e) {
          _this2.errorHandler.handleResponseError(e, 'Could not delete account');

          account.unloadRecord();

          _this2.store.findRecord('social-media/account', id, {
            reload: true
          });
        }).finally(function () {
          _this2.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});