define("lh4/enums/sorting-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SORTING_DIRECTION = void 0;
  var SORTING_DIRECTION = Object.freeze({
    ASC: 'ASC',
    DESC: 'DESC'
  });
  _exports.SORTING_DIRECTION = SORTING_DIRECTION;
  var _default = SORTING_DIRECTION;
  _exports.default = _default;
});