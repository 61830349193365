define("lh4/routes/authenticated/locations/location/hospitality/choices-and-choice-sets/set/create-set", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        menuItems: this.store.query('hospitality/menu-item', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('hospitality/menu-item');
        }),
        choices: this.store.query('hospitality/choice', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('hospitality/choice');
        }),
        menuCategory: this.store.query('hospitality/menu-category', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('hospitality/menu-category');
        }),
        newChoiceSets: this.store.createRecord('hospitality/choice-set', {
          locationId: location.id,
          ordinal: 0
        })
      });
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('hospitality/menu-item');
      this.store.unloadAll('hospitality/choice');
      this.store.unloadAll('hospitality/menu-category');
    }
  });

  _exports.default = _default;
});