define("lh4/components/radio-color-option", ["exports", "lh4/components/epro/menu/ui-helpers/color-picker-unset"], function (_exports, _colorPickerUnset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'i',
    classNames: ['ui', 'circular', 'icon', 'button', 'radio-color-option'],
    classNameBindings: ['colorClass', 'selected'],
    attributeBindings: ['style', 'color:data-test-radio-color-option'],
    useUnsetOption: false,
    useColorClass: false,
    color: null,
    selected: false,
    onClick: null,
    colorClass: Ember.computed('color', 'useColorClass', function f() {
      if (this.useUnsetOption && this.color === _colorPickerUnset.default.color) {
        return this.color;
      }

      return this.useColorClass ? this.color : null;
    }),
    style: Ember.computed('color', 'useColorClass', function f() {
      return this.useColorClass ? null : "background-color: ".concat(this.color, ";");
    }),
    click: function click() {
      if (this.onClick) {
        this.onClick();
      }
    }
  });

  _exports.default = _default;
});