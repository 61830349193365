define("lh4/routes/authenticated/location-settings/edit", ["exports", "ember-changeset", "ember-changeset-cp-validations", "lh4/routes/general-edit-route"], function (_exports, _emberChangeset, _emberChangesetCpValidations, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    dynamicPricing: Ember.inject.service(),
    modelName: 'managedMenus',
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.location.model.isCorpHq) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
    },
    // overriding hasDirtyChangesets function set by ChangesetTransitionRouteMixin
    // for custom isDirty check before closing modal
    // because we have unusual situation with two separate changesets (not child changeset)
    hasDirtyChangesets: function hasDirtyChangesets() {
      var changeset = this.controller.changeset;

      if ( // checking if "main" changeset is dirty. Basically it holds values for allowing active toggle overrides
      // and allow price editing override
      changeset.isDirty // checking if second changeset is dirty and is not deleted
      // we check isDeleted because ember leaves isDirty = true if record is marked as deleted
      // this one holds value for price group.
      || changeset.locationMap.isDirty && !changeset.locationMap.data.isDeleted) {
        return true;
      }

      return false;
    },
    model: function model(_ref) {
      var locationId = _ref.locationId;
      var isDynamicPricingEnabled = this.dynamicPricing.isDynamicPricingEnabled(locationId);
      return Ember.RSVP.hash({
        managedMenus: this.store.queryRecord('echo-pro/setting', {
          location: locationId,
          type: 'managed_menus'
        }).then(function (data) {
          return data.managedMenus;
        }),
        location: this.store.peekRecord('location', locationId),
        allowActiveToggleOverrides: [{
          id: 'allowItemsActiveToggleOverrides',
          name: 'Items'
        }, {
          id: 'allowDepartmentsActiveToggleOverrides',
          name: 'Departments'
        }, {
          id: 'allowModifiersActiveToggleOverrides',
          name: 'Modifiers'
        }, {
          id: 'allowModifierSetsActiveToggleOverrides',
          name: 'Modifier Sets'
        }, {
          id: 'allowOrderTypesActiveToggleOverrides',
          name: 'Order Types'
        }].concat(_toConsumableArray(isDynamicPricingEnabled ? [{
          id: 'allowDynamicPricingActiveToggleOverrides',
          name: 'Dynamic Pricing'
        }] : [{
          id: 'allowPriceSchedulesActiveToggleOverrides',
          name: 'Scheduled Pricing'
        }]), [{
          id: 'allowCoursesActiveToggleOverrides',
          name: 'Courses'
        }, {
          id: 'allowTendersActiveToggleOverrides',
          name: 'Tenders'
        }, {
          id: 'allowDiscountsActiveToggleOverrides',
          name: 'Discounts'
        }, {
          id: 'allowTaxesActiveToggleOverrides',
          name: 'Taxes'
        }, {
          id: 'allowSurchargesActiveToggleOverrides',
          name: 'Surcharges'
        }, {
          id: 'allowTaxSetsActiveToggleOverrides',
          name: 'Revenue Classes'
        }, {
          id: 'allowGratuitiesActiveToggleOverrides',
          name: 'Gratuities'
        }]),
        corporateGroupOptions: this.store.query('corporate-group', {
          corporation: this.location.model.corpId
        }).then(function (data) {
          return [// adding default option to allow removing the mapping
          {
            value: 0,
            name: 'Default'
          }].concat(_toConsumableArray(data.map(function (item) {
            return {
              value: +item.id,
              name: item.name
            };
          })));
        }),
        locationMap: this.store.queryRecord('corporate-group-location-map', {
          location: locationId,
          corporation: this.location.model.corpId
        }),
        locationOverview: this.ajax.request("/api/v1/corporations/".concat(this.location.model.corpId, "/location-overview")).then(function (locations) {
          var locationOverview = locations.filter(function (location) {
            return location.id.toString() === locationId;
          });
          return locationOverview[0];
        })
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var managedMenus = model.managedMenus,
          _model$location = model.location,
          locationId = _model$location.id,
          corpId = _model$location.corpId;
      var locationMap = model.locationMap;

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(managedMenus),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(managedMenus, validateFn, validationMap, {
        skipValidate: true
      });

      if (!locationMap) {
        locationMap = this.store.createRecord('corporate-group-location-map', {
          corporateGroupId: 0,
          locationId: locationId,
          corporateGroup: {
            corpId: corpId
          }
        });
      }

      var _buildChangeset2 = (0, _emberChangesetCpValidations.buildChangeset)(locationMap),
          v = _buildChangeset2.validateFn,
          vm = _buildChangeset2.validationMap;

      changeset.locationMap = new _emberChangeset.default(locationMap, v, vm, {
        skipValidate: true
      });
      controller.setProperties({
        changeset: changeset,
        // overriding some controller values set by ChangesetTransitionRouteMixin
        // for custom isDirty check before closing modal
        showConfirmation: false,
        showErrors: false,
        onBeforeClose: function onBeforeClose() {
          if (!_this.hasDirtyChangesets()) {
            return true;
          }

          if (window.confirm(_this.confirmMessage)) {
            _this.rollbackChanges();

            return true;
          }

          return false;
        }
      });
    }
  });

  _exports.default = _default;
});