define("lh4/routes/authenticated/support/contact/details", ["exports", "lh4/mixins/changeset-transition-route-mixin"], function (_exports, _changesetTransitionRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, {
    model: function model(params) {
      return Ember.RSVP.hash({
        ticket: this.store.findRecord('support/ticket', params.ticketId)
      });
    },
    setupController: function setupController(controller) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [controller].concat(args));

      controller.createNewComment();
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.changeset.get('data').unloadRecord();
      }
    }
  });

  _exports.default = _default;
});