define("lh4/templates/authenticated/internet-payments/disputes/case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GimXD3+L",
    "block": "{\"symbols\":[],\"statements\":[[5,\"internet-payments/disputes/case-panel\",[],[[\"@dispute\",\"@onReloadAllTabs\",\"@onBeforeShow\",\"@onBeforeHide\"],[[23,0,[\"model\",\"dispute\"]],[28,\"action\",[[23,0,[]],\"onReloadAllTabs\"],null],[28,\"action\",[[23,0,[]],\"onBeforeShow\"],null],[28,\"action\",[[23,0,[]],\"onBeforeHide\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/disputes/case.hbs"
    }
  });

  _exports.default = _default;
});