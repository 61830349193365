define("lh4/components/social-media/missing-permissions-warning", ["exports", "lh4/services/social-media/facebook", "lh4/services/social-media/twitter", "lh4/config/environment"], function (_exports, _facebook, _twitter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ENV: _environment.default,
    facebook: Ember.inject.service('social-media/facebook'),
    twitter: Ember.inject.service('social-media/twitter'),
    account: null,
    relinkAccount: null,
    warningMessage: null,
    type: Ember.computed('account', function getter() {
      return this.get('account.type');
    }),
    canRelink: Ember.computed('account', function getter() {
      var account = this.get('account');

      if (account.type === _twitter.ACCOUNT_TYPE_TWITTER && account.linkStatus === _twitter.TWITTER_STATUS_SUSPENDED_ACCOUNT) {
        return false;
      }

      return true;
    }),
    actions: {
      relinkAccount: function relinkAccount() {
        if (this.get('relinkAccount')) {
          return this.sendAction('relinkAccount', this.get('account'));
        }

        switch (this.get('type')) {
          case _facebook.ACCOUNT_TYPE_FB_PAGE:
            return this.facebook.redirectToAccountRelink(this.get('account'));

          case _twitter.ACCOUNT_TYPE_TWITTER:
            return this.twitter.redirectToAuth(this.get('account'));

          default:
            return this.flashMessagesHelper.pushMessage('Could not determine account type', 'error');
        }
      }
    }
  });

  _exports.default = _default;
});