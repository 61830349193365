define("lh4/constants/launch-control/modules/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MENU_SUPPORTED_FILE_TYPES = _exports.MAX_MENU_FILE_SIZE = void 0;
  var MAX_MENU_FILE_SIZE = '20 MB';
  _exports.MAX_MENU_FILE_SIZE = MAX_MENU_FILE_SIZE;
  var MENU_SUPPORTED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  _exports.MENU_SUPPORTED_FILE_TYPES = MENU_SUPPORTED_FILE_TYPES;
});