define("lh4/mixins/abilities/ability-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computedAbility = _exports.default = void 0;

  var computedAbility = function computedAbility() {
    var _Ember;

    var observe = ['location.model', 'tokenData.data', 'location.model.services.[]'];

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var getter = args.pop();
    observe.push.apply(observe, args);
    (true && !(typeof getter === 'function') && Ember.assert('last argument must be a function', typeof getter === 'function'));
    return (_Ember = Ember).computed.apply(_Ember, observe.concat([getter]));
  };

  _exports.computedAbility = computedAbility;

  var parsePermission = function parsePermission(permission) {
    return Array.isArray(permission) ? permission : [permission];
  };

  var _default = Ember.Mixin.create({
    permissions: Ember.inject.service(),
    location: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    hasService: function hasService(name) {
      return this.permissions.hasService(name);
    },
    hasPermission: function hasPermission(permissionSet) {
      var locationId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var permission = parsePermission(permissionSet);
      var permissions = this.permissions;
      return permission.some(function (p) {
        return locationId ? permissions.hasPermission(p, locationId) : permissions.hasPermission(p, null);
      });
    },
    hasLocationPermission: function hasLocationPermission(permissionSet) {
      var permission = parsePermission(permissionSet);
      var permissions = this.permissions;
      return permission.some(function (p) {
        return permissions.hasPermission(p);
      });
    },
    hasProduct: function hasProduct() {
      var _this = this;

      for (var _len2 = arguments.length, products = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        products[_key2] = arguments[_key2];
      }

      return products.some(function (product) {
        return _this.permissions.hasProduct(product);
      });
    },
    isOwner: function isOwner() {
      return this.permissions.isOwner;
    }
  });

  _exports.default = _default;
});