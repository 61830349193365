define("lh4/constants/launch-control/modules/employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_EMPLOYEES = _exports.DEFAULT_JOBS = _exports.JOB_LOGIN_TYPE = void 0;
  var JOB_LOGIN_TYPE = Object.freeze({
    COUNTER_SERVICE: 0,
    TABLE_SERVICE: 1,
    HOST_MODE: 2,
    TIME_CLOCK_MODE: 3,
    DISPATCH: 4
  });
  _exports.JOB_LOGIN_TYPE = JOB_LOGIN_TYPE;
  var DEFAULT_JOBS = Object.freeze([{
    name: 'Bartender',
    payRate: 1.0,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.TABLE_SERVICE,
    ticketPaymentOperationType: 1,
    isCompleteAllTicketsEnabled: true,
    isGridViewEnabled: true,
    isDeclareTipsAtClockoutEnabled: true,
    isHoldItemsEnabled: true,
    usePersonalTill: true,
    isDisplayShiftReportEnabled: true,
    isHostModeEnabled: true,
    isBlind: false,
    operatesAs: 'Restricted',
    isActive: true,
    defaultMenuType: 'Departments'
  }, {
    name: 'Busser',
    payRate: 1.0,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.TIME_CLOCK_MODE,
    ticketPaymentOperationType: 2,
    isDeclareTipsAtClockoutEnabled: true,
    isFastPayEnabled: false,
    isGridViewEnabled: false,
    isHostModeEnabled: false,
    isHoldItemsEnabled: false,
    isIgnoreRequiredModifiersEnabled: false,
    isActive: true,
    defaultMenuType: 'Departments'
  }, {
    name: 'Cashier',
    payRate: 1.0,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.COUNTER_SERVICE,
    ticketPaymentOperationType: 0,
    canAccessRecentlyClosedTicket: true,
    shouldAutoPrintDeliveryInformationOnTransfer: true,
    isHostModeEnabled: false,
    isHoldItemsEnabled: false,
    isIgnoreRequiredModifiersEnabled: false,
    isActive: true,
    defaultMenuType: 'Departments'
  }, {
    name: 'Cook',
    payRate: 1.0,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.TIME_CLOCK_MODE,
    ticketPaymentOperationType: 2,
    isBlind: true,
    isFastPayEnabled: false,
    isGridViewEnabled: false,
    isHostModeEnabled: false,
    isHoldItemsEnabled: false,
    isIgnoreRequiredModifiersEnabled: false,
    isActive: true,
    defaultMenuType: 'Departments'
  }, {
    name: 'Delivery',
    payRate: 1.0,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.TABLE_SERVICE,
    ticketPaymentOperationType: 0,
    isAccessAllTicketsEnabled: true,
    canAccessRecentlyClosedTicket: true,
    isDeclareTipsAtClockoutEnabled: true,
    isTransferTicketOnScanEnabled: true,
    shouldAutoPrintDeliveryInformationOnTransfer: true,
    usePersonalTill: true,
    isFastPayEnabled: false,
    isHostModeEnabled: false,
    isHoldItemsEnabled: false,
    isIgnoreRequiredModifiersEnabled: false,
    isActive: true,
    defaultMenuType: 'Departments'
  }, {
    name: 'Host',
    payRate: 1.0,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.TABLE_SERVICE,
    ticketPaymentOperationType: 0,
    isCompleteAllTicketsEnabled: true,
    isGridViewEnabled: true,
    isDeclareTipsAtClockoutEnabled: true,
    isHoldItemsEnabled: true,
    usePersonalTill: true,
    isDisplayShiftReportEnabled: true,
    isHostModeEnabled: true,
    isBlind: false,
    operatesAs: 'Restricted',
    isActive: true,
    defaultMenuType: 'Departments'
  }, {
    name: 'Manager',
    payRate: 1.0,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.TABLE_SERVICE,
    ticketPaymentOperationType: 1,
    isAccessAllTicketsEnabled: true,
    canAccessRecentlyClosedTicket: true,
    isFastPayEnabled: false,
    isIgnoreRequiredModifiersEnabled: false,
    isActive: true,
    defaultMenuType: 'Departments'
  }, {
    name: 'Owner',
    payRate: 1.00,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.TABLE_SERVICE,
    ticketPaymentOperationType: 1,
    isAccessAllTicketsEnabled: true,
    canAccessRecentlyClosedTicket: true,
    canSetHouseAccountThreshold: true,
    isFastPayEnabled: false,
    isIgnoreRequiredModifiersEnabled: false,
    isActive: true,
    defaultMenuType: 'Departments'
  }, {
    name: 'Server',
    payRate: 1.0,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.TABLE_SERVICE,
    ticketPaymentOperationType: 2,
    isCompleteAllTicketsEnabled: true,
    isGridViewEnabled: true,
    isDeclareTipsAtClockoutEnabled: true,
    isHoldItemsEnabled: true,
    usePersonalTill: true,
    isDisplayShiftReportEnabled: true,
    isHostModeEnabled: true,
    isBlind: false,
    operatesAs: 'Restricted',
    isActive: true,
    defaultMenuType: 'Departments'
  }, {
    name: 'Back of House',
    payRate: 1.0,
    overtimePayRate: 1.5,
    loginType: JOB_LOGIN_TYPE.TIME_CLOCK_MODE,
    ticketPaymentOperationType: 2,
    isDeclareTipsAtClockoutEnabled: true,
    isDisplayShiftReportEnabled: true,
    operatesAs: 'Restricted',
    isActive: true,
    defaultMenuType: 'Departments'
  }]);
  _exports.DEFAULT_JOBS = DEFAULT_JOBS;
  var DEFAULT_EMPLOYEES = Object.freeze([{
    name: 'Owner',
    loginPin: '1111',
    isActive: true,
    _job: 'Owner'
  }, {
    name: 'Example Cashier',
    loginPin: '2222',
    isActive: true,
    _job: 'Cashier'
  }, {
    name: 'Example Server',
    loginPin: '3333',
    isActive: true,
    _job: 'Server'
  }, {
    name: 'Example Bartender',
    loginPin: '4444',
    isActive: true,
    _job: 'Bartender'
  }]);
  _exports.DEFAULT_EMPLOYEES = DEFAULT_EMPLOYEES;
});