define("lh4/components/internet-payments/ui/dates/datetime-range-picker", ["exports", "lh4/components/ui/dates/datetime-range-picker", "lh4/enums/date-time-format", "lh4/enums/key-code", "lh4/utils/no-op", "moment"], function (_exports, _datetimeRangePicker, _dateTimeFormat, _keyCode, _noOp, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * @typedef {Object} Range
   * @prop {Moment} startDate
   * @prop {Moment} endDate
   */
  var INPUT_DEBOUNCE_MS = 500;
  var TOGGLE_THROTTLE_MS = 100;
  var PICKER_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

  var _default = _datetimeRangePicker.default.extend({
    tagName: 'span',
    autoApply: Ember.computed.not('showButtons'),
    hasDateRangeChanges: false,
    isActive: false,
    isDateOnly: Ember.computed.not('showTimePickers'),
    label: 'Period',
    startDate: undefined,
    endDate: undefined,
    onDateRangeChanged: _noOp.default,
    // Properties also used by the picker component that was extended
    autoAlign: false,
    format: _dateTimeFormat.default.LOCALIZED_DATE_ONLY,
    minDate: undefined,
    maxDate: undefined,
    ranges: undefined,
    showButtons: true,
    showTimePickers: false,
    startOnMonday: true,
    start: Ember.computed.alias('startDate'),
    end: Ember.computed.alias('endDate'),
    init: function init() {
      this._super.apply(this, arguments);

      this.onbeforeshow = this.onPickerBeforeShow.bind(this);
      this.onbeforehide = this.onPickerBeforeHide.bind(this);
      this.onfirstselect = this.onPickerFirstSelect.bind(this);
      this.onbeforeselect = this.onPickerBeforeSelect.bind(this);
      this.onafterselect = this.onPickerAfterSelect.bind(this);
      this.onrangeselect = this._onPickerRangeSelect.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.inputElement = this.element.querySelector('input.datetime-range-picker-input');

      if (!this.inline) {
        this.picker.container.addClass('desktop-calendar');
      }

      if (this.picker.config.startDate && this.picker.config.endDate) {
        // Make sure the initial picker instance date range is valid based on the component configuration
        // Sync new values with component and run date range changed callback if changes were made
        this.normalizePickerDateRange();
        this.setOwnDateRange(this.picker.config.startDate, this.picker.config.endDate);
        this.callbackOnDateRangeChanged();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var picker = this.picker,
          startDate = this.startDate,
          endDate = this.endDate;

      if (startDate && endDate) {
        this.setPickerDateRange(startDate, endDate);
      }

      var preset = this.getCurrentRangePreset(startDate, endDate);

      if (preset) {
        preset.selected = true;
      }

      picker.reDrawCalendars();
    },

    /**
     * Returns range preset based on moment start and end date
     */
    getCurrentRangePreset: function getCurrentRangePreset(startDate, endDate) {
      var todaysDate = (0, _moment.default)();
      return this.picker.config.ranges.find(function (range) {
        // checking picker range endDate so it wont go passed todaysDate
        // this was done to auto detect correct range presets and align with ui
        var rangeEndDate = range.endDate.isAfter(todaysDate) ? todaysDate : range.endDate;
        return range.startDate.isSame(startDate, 'day') && rangeEndDate.isSame(endDate, 'day');
      });
    },

    /**
     * Run date range changed callback if component date range changed
     */
    callbackOnDateRangeChanged: function callbackOnDateRangeChanged() {
      if (this.hasDateRangeChanges) {
        this.hasDateRangeChanges = false;
        this.onDateRangeChanged(this.startDate.clone(), this.endDate.clone());
      }
    },

    /**
     * Cancel any picker instance date range select operation that is in progress
     */
    cancelPickerDateRangeSelect: function cancelPickerDateRangeSelect() {
      this.picker.globals.startSelected = false;
      this.picker.globals.endSelected = true;
    },

    /**
     * Clamp the picker instance date range so that it is between the given min/max dates
     * @param {Moment} minDate
     * @param {Moment} maxDate
     */
    clampPickerDateRange: function clampPickerDateRange(minDate, maxDate) {
      var _this$picker$config = this.picker.config,
          startDate = _this$picker$config.startDate,
          endDate = _this$picker$config.endDate;

      if (minDate) {
        if (startDate.isBefore(minDate)) {
          startDate = minDate.clone();
        }

        if (endDate.isBefore(minDate)) {
          endDate = minDate.clone();
        }
      }

      if (maxDate) {
        if (startDate.isAfter(maxDate)) {
          startDate = maxDate.clone();
        }

        if (endDate.isAfter(maxDate)) {
          endDate = maxDate.clone();
        }
      }

      this.setPickerDateRange(startDate, endDate);
    },

    /**
     * Get a normalized date range depending on whether the component is date only or not
     * The resulting date range is meant for operations non-native to the picker instance
     * @param {Moment} startDate
     * @param {Moment} endDate
     * @returns {Range} normalized date range
     */
    getNormalizedDateRange: function getNormalizedDateRange(startDate, endDate) {
      var normalStartDate = startDate.clone();
      var normalEndDate = endDate.clone();

      if (this.isDateOnly) {
        return {
          startDate: normalStartDate.startOf('day'),
          endDate: normalEndDate.endOf('day')
        };
      }

      return {
        startDate: normalStartDate,
        endDate: normalEndDate
      };
    },

    /**
     * Get the selectable date range for a given date based on configured limits
     * @returns {Range} selectable date range
     */
    getSelectableDateRangeForDate: function getSelectableDateRangeForDate() {
      var _this$minDate, _this$maxDate;

      return {
        startDate: ((_this$minDate = this.minDate) === null || _this$minDate === void 0 ? void 0 : _this$minDate.clone()) || null,
        endDate: ((_this$maxDate = this.maxDate) === null || _this$maxDate === void 0 ? void 0 : _this$maxDate.clone()) || null
      };
    },

    /**
     * Check to see if the component input element value is a valid date range string
     * @returns {boolean}
     */
    isInputValid: function isInputValid() {
      var _this$inputElement$va = this.inputElement.value.split(this.picker.config.dateSeparator),
          _this$inputElement$va2 = _slicedToArray(_this$inputElement$va, 2),
          startDate = _this$inputElement$va2[0],
          endDate = _this$inputElement$va2[1];

      return (0, _moment.default)(startDate, this.format, true).isValid() && (0, _moment.default)(endDate, this.format, true).isValid();
    },

    /**
     * Limit the selectable date range for a given date based on configured limits
     */
    limitSelectableDateRangeForDate: function limitSelectableDateRangeForDate() {
      var _this$getSelectableDa = this.getSelectableDateRangeForDate(),
          startDate = _this$getSelectableDa.startDate,
          endDate = _this$getSelectableDa.endDate;

      this.picker.config.minDate = startDate;
      this.picker.config.maxDate = endDate;
    },

    /**
     * Normalize picker instance date range so that it is valid based on component configuration
     */
    normalizePickerDateRange: function normalizePickerDateRange() {
      if (!this.picker.config.startDate || !this.picker.config.endDate) {
        return;
      }

      var _this$getSelectableDa2 = this.getSelectableDateRangeForDate(),
          startDate = _this$getSelectableDa2.startDate,
          endDate = _this$getSelectableDa2.endDate;

      this.clampPickerDateRange(startDate, endDate);
      this.picker.refreshValues();
      this.picker.setDisplayDate(this.picker.config.endDate);
    },

    /**
     * Reset the selectable date range to the initial range limits
     */
    resetSelectableDateRange: function resetSelectableDateRange() {
      this.picker.config.minDate = this.minDate;
      this.picker.config.maxDate = this.maxDate;
    },

    /**
     * Select a range preset in the picker instance if the preset matches the given dates
     * @param {Moment} startDate
     * @param {Moment} endDate
     */
    selectRangePresetByDateRange: function selectRangePresetByDateRange(startDate, endDate) {
      if (!startDate || !endDate) {
        return;
      }

      this.picker.clearRangeSelection();

      var _this$getNormalizedDa = this.getNormalizedDateRange(startDate, endDate),
          normalStartDate = _this$getNormalizedDa.startDate,
          newEndDate = _this$getNormalizedDa.endDate;

      var preset = this.getCurrentRangePreset(normalStartDate, newEndDate);

      if (preset) {
        preset.selected = true;
        this.picker.reDrawCalendars();
      }
    },

    /**
     * Set the component date range and track if date range changed
     * @param {Moment} startDate
     * @param {Moment} endDate
     */
    setOwnDateRange: function setOwnDateRange(startDate, endDate) {
      if (!startDate || !endDate) {
        return;
      }

      var _this$getNormalizedDa2 = this.getNormalizedDateRange(startDate, endDate),
          normalStartDate = _this$getNormalizedDa2.startDate,
          normalEndDate = _this$getNormalizedDa2.endDate;

      if (!normalStartDate.isSame(this.startDate) || !normalEndDate.isSame(this.endDate)) {
        this.setProperties({
          startDate: normalStartDate,
          endDate: normalEndDate,
          hasDateRangeChanges: true
        });
      }
    },

    /**
     * Set the picker instance date range
     * @param {Moment|String} startDateInput
     * @param {Moment|String} endDateInput
     */
    setPickerDateRange: function setPickerDateRange(startDateInput, endDateInput) {
      var startDate = typeof startDateInput === 'string' ? (0, _moment.default)(startDateInput, PICKER_DATE_FORMAT) : startDateInput;
      var endDate = typeof endDateInput === 'string' ? (0, _moment.default)(endDateInput, PICKER_DATE_FORMAT) : endDateInput;

      if (!startDate || !endDate) {
        this.picker.config.startDate = null;
        this.picker.config.endDate = null;
        this.picker.refreshValues();
        return;
      }

      if (!startDate.isSame(this.picker.config.startDate) || !endDate.isSame(this.picker.config.endDate)) {
        this.picker.config.startDate = startDate.clone();
        this.picker.config.endDate = endDate.clone();
        this.picker.refreshValues();
      }
    },

    /**
     * Callback for picker instance before picker is shown
     */
    onPickerBeforeShow: function onPickerBeforeShow() {
      this.normalizePickerDateRange();
      this.selectRangePresetByDateRange(this.picker.config.startDate, this.picker.config.endDate);
      this.set('isActive', true);
    },

    /**
     * Callback for picker instance before picker is hidden
     */
    onPickerBeforeHide: function onPickerBeforeHide() {
      this.inputElement.blur();
      this.set('isActive', false);
      this.cancelPickerDateRangeSelect();
      this.resetSelectableDateRange(); // Sync picker instance date range with component date range
      // This will effectively revert to the previous values if the component date range was not changed

      this.setPickerDateRange(this.startDate, this.endDate);
      this.picker.refreshValues();
      this.picker.clearRangeSelection();
      this.callbackOnDateRangeChanged();
    },

    /**
     * Callback for picker instance when a date range select operation was started by selecting the first date
     * @param {Moment} date
     */
    onPickerFirstSelect: function onPickerFirstSelect(_, date) {
      this.picker.clearRangeSelection();
      this.limitSelectableDateRangeForDate(date);
    },

    /**
     * Callback for picker instance before accepting the second selected date in a date range select operation
     * @returns {boolean} whether or not the selection should be accepted
     */
    onPickerBeforeSelect: function onPickerBeforeSelect() {
      var _this = this;

      // The before select callback is meant as a validation step that continues in Calentim code
      // To avoid conflicts with picker state, the callback is allowed to finish and our changes are made afterwards
      Ember.run.next(this, function () {
        _this.normalizePickerDateRange();

        _this.selectRangePresetByDateRange(_this.picker.config.startDate, _this.picker.config.endDate);
      });
      return true;
    },

    /**
     * Callback for picker instance after a date range select operation is completed
     */
    onPickerAfterSelect: function onPickerAfterSelect() {
      this.setOwnDateRange(this.picker.config.startDate, this.picker.config.endDate);
    },

    /**
     * Callback for picker instance when a date range preset is selected
     */
    _onPickerRangeSelect: function _onPickerRangeSelect(element, data) {
      this.cancelPickerDateRangeSelect();
      this.normalizePickerDateRange();

      if (this.onPickerRangeSelect) {
        this.onPickerRangeSelect(element, data);
      }

      if (this.autoApply) {
        this.setOwnDateRange(this.picker.config.startDate, this.picker.config.endDate);
      }
    },

    /**
     * Callback for component input element input events. Debounced.
     */
    onInput: function onInput() {
      Ember.run.debounce(this, this._onInput, this.autoApply, INPUT_DEBOUNCE_MS);
    },

    /**
     * Validate component input element value and update picker instance
     * @param {boolean} setsOwnDates - set component date range if input is valid
     */
    _onInput: function _onInput(setsOwnDates) {
      if (this.isInputValid()) {
        this.picker.fetchInputs();
        this.normalizePickerDateRange();
        var _this$picker$config2 = this.picker.config,
            startDate = _this$picker$config2.startDate,
            endDate = _this$picker$config2.endDate;
        this.selectRangePresetByDateRange(startDate, endDate);

        if (setsOwnDates) {
          this.setOwnDateRange(startDate, endDate);
        }

        return;
      } // Revert input to previous valid picker instance date range


      this.picker.refreshValues();
    },

    /**
     * Show or hide picker. Throttled.
     * @param {boolean} shouldShow - whether to show picker or not
     */
    showPicker: function showPicker(shouldShow) {
      Ember.run.throttle(this, this._showPicker, shouldShow, TOGGLE_THROTTLE_MS);
    },

    /**
     * Show or hide picker
     * @param {boolean} shouldShow - whether to show picker or not
     */
    _showPicker: function _showPicker(shouldShow) {
      if (shouldShow) {
        this.picker.showDropdown();
        return;
      }

      this.picker.hideDropdown({});
    },
    actions: {
      handleInputFocus: function handleInputFocus() {
        this.showPicker(true);
      },
      handleInput: function handleInput() {
        this.onInput();
      },
      handleInputKeyDown: function handleInputKeyDown(event) {
        if (event.keyCode === _keyCode.default.TAB || event.keyCode === _keyCode.default.ESC) {
          this.showPicker(false);
          return;
        }

        if (event.keyCode === _keyCode.default.ENTER) {
          this._onInput(true);

          this.showPicker(false);
          return;
        } // Treat other key down events as input to keep input debounce active


        this.onInput();
      },
      togglePicker: function togglePicker(event) {
        this.showPicker(!this.isActive || event.target === this.inputElement);
      }
    }
  });

  _exports.default = _default;
});