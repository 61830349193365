define("lh4/utils/get-array-difference-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getArrayDifferenceCount;

  /**
   * Count how many items initialArray has which comparisonArray does not
   * @param {Object[]} initialArray - Array which values we want to check
   * @param {Object[]} comparisonArray - Array which we want to use for comparement
   * @returns {number} number of items which comparisonArray does not have
   */
  function getArrayDifferenceCount(initialArray, comparisonArray) {
    var count = 0;
    initialArray.forEach(function (item) {
      if (!comparisonArray.includes(item)) {
        count += 1;
      }
    });
    return count;
  }
});