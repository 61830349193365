define("lh4/routes/authenticated/epro/workforce/terms-and-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    termsAndConditionsUrl: Ember.computed(function termsAndConditionsUrl() {
      var _this = this;

      return this.ajax.request('/api/v1/s4-workforce/app/onboarding/terms-and-conditions', {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        return _this.set('termsAndConditionsUrl', res.termsAndConditionsUrl);
      }).catch(function (err) {
        _this.flashMessagesHelper.pushMessages(_this.responseMessages.getResponseErrors(err));
      });
    }),
    model: function model() {
      return Ember.RSVP.hash({
        termsAndConditionsUrl: this.termsAndConditionsUrl
      });
    }
  });

  _exports.default = _default;
});