define("lh4/components/updates/location-overview-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['location-overview-table'],
    locationOverviewHeaders: [{
      name: 'Location',
      width: 'six'
    }, {
      name: 'Price Group',
      width: 'three'
    }, {
      name: 'Menu Status',
      width: 'three'
    }, {
      name: 'Location Status',
      width: 'three'
    }, {
      name: null,
      width: 'one'
    }],
    router: Ember.inject.service(),
    actions: {
      redirectToLocationSettings: function redirectToLocationSettings() {
        this.router.transitionTo('authenticated.location-settings');
      }
    }
  });

  _exports.default = _default;
});