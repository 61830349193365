define("lh4/validators/password-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validatePassword = _exports.errors = void 0;
  var errors = {
    minLength: 0,
    mixedCasing: 1,
    specialCharacter: 2,
    numeric: 3
  };
  _exports.errors = errors;
  var minimumPasswordLength = 8;
  var mixedCasingExpression = /^(?=.*[A-Z].*)(?=.*[a-z].*).*$/;
  var numericExpression = /^(?=.*[0-9].*).*$/;
  var specialCharExpression = /^(?=.*[~`!@#$%^&*()\-_+={}[\]|;:"<>,./?].*).*$/;

  var validatePassword = function validatePassword(password) {
    var errorSet = [];

    if (password.length < minimumPasswordLength) {
      errorSet.push(errors.minLength);
    }

    if (!mixedCasingExpression.test(password)) {
      errorSet.push(errors.mixedCasing);
    }

    if (!numericExpression.test(password)) {
      errorSet.push(errors.numeric);
    }

    if (!specialCharExpression.test(password)) {
      errorSet.push(errors.specialCharacter);
    }

    return errorSet.sort();
  };

  _exports.validatePassword = validatePassword;
});