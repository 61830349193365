define("lh4/models/echo-pro/intraday-period-group", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    intradayPeriodGroupName: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        description: 'Name'
      }), (0, _emberCpValidations.validator)('length', {
        max: 60,
        description: 'Name'
      })]
    }
  });
  _exports.Validations = Validations;

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    intradayPeriodGroupName: (0, _attr.default)('string')
  });

  _exports.default = _default;
});