define("lh4/utils/email-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Checks whether the provided email is valid or not
   *
   * @param {string} email - an email
   * @returns {boolean} - validation response for the provided email
   */
  var validateEmail = function validateEmail(email) {
    var emailRegex = /^([a-zA-Z0-9._%-+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return emailRegex.test(email);
  };

  var _default = validateEmail;
  _exports.default = _default;
});