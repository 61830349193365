define("lh4/templates/components/ui/dropdown-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bdj68hOg",
    "block": "{\"symbols\":[\"record\"],\"statements\":[[7,\"style\",true],[8],[0,\"\\n\\t.dropdown-simple-overflow {\\n\\t\\toverflow: hidden;\\n\\t\\ttext-overflow: ellipsis;\\n\\t\\tmax-width:  maxWidth;\\n\\t}\\n\"],[9],[0,\"\\n\\n\"],[4,\"ui-dropdown\",null,[[\"id\",\"class\",\"classNames\",\"selected\",\"onChange\"],[[24,[\"dropdownId\"]],[24,[\"classes\"]],[28,\"if\",[[24,[\"isDisabled\"]],\"disabled\"],null],[28,\"get\",[[24,[\"selected\"]],[24,[\"idField\"]]],null],[28,\"action\",[[23,0,[]],\"selectRecord\"],null]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"text dropdown-simple-selected dropdown-simple-overflow\"],[8],[9],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"collection\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"dropdown-simple-item item\"],[11,\"data-value\",[29,[[28,\"get\",[[23,1,[]],[24,[\"idField\"]]],null]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"get\",[[23,1,[]],[24,[\"displayField\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/dropdown-simple.hbs"
    }
  });

  _exports.default = _default;
});