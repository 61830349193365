define("lh4/components/ui/radio-accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    checkBoxes: [],
    selectedValue: null,
    isRadio: false,
    actions: {
      checkBoxChanged: function checkBoxChanged(value, checked, source) {
        if (this.get('isRadio') && !checked) {
          Ember.set(source, 'checked', true);
          return;
        }

        if (checked) {
          this.set('selectedValue', value);
        } else {
          this.set('selectedValue', null);
        }

        this.sendAction('onChange', this.get('selectedValue'));
      }
    }
  });

  _exports.default = _default;
});