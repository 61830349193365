define("lh4/components/epro/menu/modifier-modal", ["exports", "ember-changeset"], function (_exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    locale: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments); // Since we want to add a null option, but don't want to pass
      // it to our changeset we create a modal changeset to manage
      // this local state. If a value isn't present we'll default
      // the dropdown to null (this won't be saved, but is used)
      // to show the defaultModifier price that will be used
      // if a value isn't entered.


      var modalChangeset = new _emberChangeset.default({
        isFlatAmount: null
      });
      this.set('modalChangeset', modalChangeset);
      this.modifierPriceOptions = [{
        value: null,
        name: 'Default'
      }, {
        value: true,
        name: "Flat (".concat(this.locale.currencySymbol, ")")
      }, {
        value: false,
        name: 'Percentage (%)'
      }];
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.changeset) {
        return;
      }

      var modalChangeset = this.modalChangeset;

      if (this.changeset.get('value') !== null) {
        modalChangeset.set('isFlatAmount', this.changeset.get('isFlatAmount'));
      } else {
        modalChangeset.set('isFlatAmount', null);
      }
    },
    modifierOptions: Ember.computed.map('modifiers', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          onlineActive = _ref.onlineActive,
          isActive = _ref.active;
      var classNames = onlineActive ? null : ' subdued';
      return {
        classNames: classNames,
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    isOnlineActive: Ember.computed('modifiers', 'changeset.modifierGuid', function getter() {
      if (!this.changeset) {
        return false;
      }

      var selectedGuid = this.changeset.get('modifierGuid');
      var selectedModifier = this.modifiers.find(function (modifier) {
        return selectedGuid === modifier.guid;
      });
      return selectedModifier ? selectedModifier.onlineActive : false;
    }),
    actions: {
      modalDeny: function modalDeny() {
        this.set('errorMessage', null);
        this.modalDeny();
      },
      selectPriceOption: function selectPriceOption(value, property) {
        if (value !== null) {
          this.changeset.set(property, value);
        } else {
          this.changeset.set('value', null);
        }

        this.modalChangeset.set('isFlatAmount', value);
      },
      saveModifier: function saveModifier() {
        var _this = this;

        this.changeset.validate().then(function () {
          if (_this.changeset.isValid) {
            _this.onModalSave();

            _this.set('errorMessage', null);

            return Ember.RSVP.resolve();
          }

          return Ember.RSVP.reject(_this.changeset.errors);
        }).catch(function (errors) {
          if (errors.length) {
            errors.forEach(function (error) {
              _this.set('errorMessage', error.validation);
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});