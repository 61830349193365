define("lh4/services/session", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    // ownerToken isn't part of ember simple auth. We are simply
    // adding this here since similar to our session token, we
    // are adding an additional header if present.
    //
    // TODO find out if owner token needs to persist with refresh
    // If so we might want to hook into the same AdapterStore
    // our access token is stored in instead of in memory.
    ownerToken: null,
    // Shorthand check to determine if user was logged in via normal authentication or SSO
    isSSO: Ember.computed('session.authenticator', function getter() {
      return this.session.authenticator === 'authenticator:sso';
    }),
    handleInvalidation: function handleInvalidation() {
      // Clear ownerToken if invalidate has been called so it doesn't
      // persist through on our session.
      this.set('ownerToken', null);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});