define("lh4/routes/authenticated/locations/location/echo-pro/manage-pos/timeclock", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    requiredPermissions: 'manage echo-pro/time-clock',
    titleToken: 'Timeclock Management',
    queryParams: {
      selectedEmployeeId: {
        refreshModel: true
      },
      fromFilter: {
        refreshModel: true
      },
      toFilter: {
        refreshModel: true
      }
    },
    redirect: function redirect() {
      var location = this.modelFor('authenticated.locations.location');

      if (this.can.can('manage workforce for echo-pro/workforce', location)) {
        this.replaceWith('authenticated.epro.workforce.links.timecards');
      }
    },
    model: function model(param) {
      var location = this.modelFor('authenticated.locations.location');
      var filter = {
        locationId: {
          $eq: location.id
        },
        $and: [{
          voided: {
            $eq: false
          }
        }, {
          $or: [{
            $and: [{
              clockedInAt: {
                $gte: param.fromFilter
              }
            }, {
              clockedInAt: {
                $lte: param.toFilter
              }
            }]
          }, {
            $and: [{
              clockedOutAt: {
                $gte: param.fromFilter
              }
            }, {
              clockedOutAt: {
                $lte: param.toFilter
              }
            }]
          }]
        }]
      };

      if (param.selectedEmployeeId) {
        filter.$and.push({
          employeeGuid: {
            $eq: param.selectedEmployeeId
          }
        });
      }

      this.store.unloadAll('echo-pro/time-clock-shift');
      return Ember.RSVP.hash({
        employees: this.store.query('echo-pro/employee', {
          location: location.id
        }),
        shifts: this.store.query('echo-pro/time-clock-shift', {
          order: 'clockedInAt',
          filter: JSON.stringify(filter)
        }),
        breaks: this.store.query('echo-pro/time-clock-break', {
          location: location.id,
          filter: {
            voided: false
          }
        }),
        settings: this.store.queryRecord('echo-pro/setting', {
          location: location.id,
          type: ['accounting', 'business']
        }),
        location: location
      });
    },
    beforeModel: function beforeModel() {},
    afterModel: function afterModel() {
      this.controllerFor('authenticated.locations.location.echo-pro.manage-pos.timeclock').set('selectedShift', null);
    },
    actions: {
      refreshPage: function refreshPage(callback) {
        this.refresh().then(function () {
          return callback && callback();
        });
      }
    }
  });

  _exports.default = _default;
});