define("lh4/routes/authenticated/internet-payments/transactions/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    redirect: function redirect() {
      if (this.can.can('view transactions in general')) {
        return this.transitionTo('authenticated.internet-payments.transactions.master-transactions');
      }

      if (this.location.hasInternetPaymentProduct) {
        return this.transitionTo('authenticated.internet-payments.dashboard');
      }

      return this.transitionTo('/');
    }
  });

  _exports.default = _default;
});