define("lh4/mixins/lighthouse-permissions-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This mixin should be included in any route that needs to be protected.
   * Add a `requiredPermissions` attribute on that route which can be either a string or array of permissions.
   * The mixin will check if the user has those permissions, and if they do not, will redirect them.
   * This mixin only needs to be included in the parent-most route that needs to be protected.
   * e.g. Including it in `settings.subscriptions` will also protect all child routes such as:
   * `settings.subscriptions.edit-subscription` and `settings.subscriptions.create-subscription`.
   *
   * This differs from the AuthenticatedRouteMixin, which should be mixed-into any route that requires
   * the user to be authenticated (which is almost all of them, except for things like login).
   */
  var _default = Ember.Mixin.create({
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      // this call is important for when also using the AuthenticatedRouteMixin
      this._super.apply(this, arguments);

      var permissions = this.requiredPermissions;

      if (!permissions) {
        return false;
      }

      permissions = Array.isArray(permissions) ? permissions : [permissions];

      if (permissions.every(function (p) {
        return _this.can.cannot(p);
      })) {
        return this.permissionCheckFailed();
      }

      return false;
    },

    /**
     * Callback ran when the check for required permissions fails
     * Useful when you want to do a custom redirect instead of the standard one to dashboard
     *
     * @returns {any | Promise<any>} Can return the same things as beforeModel
     */
    permissionCheckFailed: function permissionCheckFailed() {
      return this.replaceWith(this.location.hasInternetPaymentProduct ? '/internet-payments/dashboard' : '/');
    }
  });

  _exports.default = _default;
});