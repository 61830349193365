define("lh4/enums/ip-pagination-limit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_PAGE = Object.freeze({
    LIMIT: 100
  });
  var _default = DEFAULT_PAGE;
  _exports.default = _default;
});