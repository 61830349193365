define("lh4/templates/components/social-media/no-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DyK76c0V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui message\"],[8],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"You do not have any accounts at the moment\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"tagName\",\"class\",\"route\"],[\"button\",\"ui primary button\",[28,\"concat\",[[24,[\"type\"]],\".accounts.add\"],null]]],{\"statements\":[[0,\"\\t\\tAdd Account\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/social-media/no-accounts.hbs"
    }
  });

  _exports.default = _default;
});