define("lh4/components/sign-in/two-factor-recover-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      submit: function submit() {
        if (!this.recoveryCode || !this.recoveryCode.length) {
          this.set('recoveryCodeError', true);
          return;
        }

        this.onSubmit();
      }
    }
  });

  _exports.default = _default;
});