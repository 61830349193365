define("lh4/components/ui/s4/icons/visible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  	<path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.00098C7 5.00098 2.73 8.11098 1 12.501C2.73 16.891 7 20.001 12 20.001C17 20.001 21.27 16.891 23 12.501C21.27 8.11098 17 5.00098 12 5.00098ZM12 17.501C9.24 17.501 7 15.261 7 12.501C7 9.74098 9.24 7.50098 12 7.50098C14.76 7.50098 17 9.74098 17 12.501C17 15.261 14.76 17.501 12 17.501ZM12 9.50098C10.34 9.50098 9 10.841 9 12.501C9 14.161 10.34 15.501 12 15.501C13.66 15.501 15 14.161 15 12.501C15 10.841 13.66 9.50098 12 9.50098Z" fill="black" fill-opacity="0.6"/>
  </svg>
  */
  {
    id: "QmB/iray",
    block: "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M12 5.00098C7 5.00098 2.73 8.11098 1 12.501C2.73 16.891 7 20.001 12 20.001C17 20.001 21.27 16.891 23 12.501C21.27 8.11098 17 5.00098 12 5.00098ZM12 17.501C9.24 17.501 7 15.261 7 12.501C7 9.74098 9.24 7.50098 12 7.50098C14.76 7.50098 17 9.74098 17 12.501C17 15.261 14.76 17.501 12 17.501ZM12 9.50098C10.34 9.50098 9 10.841 9 12.501C9 14.161 10.34 15.501 12 15.501C13.66 15.501 15 14.161 15 12.501C15 10.841 13.66 9.50098 12 9.50098Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/visible.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});