define("lh4/controllers/authenticated/support/contact/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessagesHelper: Ember.inject.service(),
    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.support.contact');
      },
      createTicket: function createTicket() {
        var _this = this;

        this.flashMessagesHelper.clearMessages();
        this.set('showErrors', true);
        this.changeset.validate().then(function () {
          if (_this.changeset.get('isValid')) {
            return _this.changeset.save().then(function () {
              Ember.run.later(function () {
                _this.transitionToRoute('authenticated.support.contact');
              }, 1500);

              _this.set('showErrors', false);

              return Ember.RSVP.resolve();
            });
          }

          return Ember.RSVP.reject(_this.changeset.get('errors'));
        }).catch(function (errors) {
          if (errors.length) {
            errors.forEach(function (error) {
              _this.flashMessagesHelper.pushMessage(error.validation, 'error');
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});