define("lh4/components/cropper-js", ["exports", "cropperjs"], function (_exports, _cropperjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['cropper-js', 'ui', 'clearing', 'segments'],
    didInsertElement: function didInsertElement() {
      var image = this.$('img')[0];
      this.set('cropper', new _cropperjs.default(image, this.options));
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.cropper.replace(this.image);
    },
    actions: {
      cancel: function cancel() {
        this.onCancel();
      },
      cropImage: function cropImage() {
        this.onFinish(this.cropper);
      },
      rotate: function rotate(amount) {
        // this is done on the cropperjs example site to make sure
        // the cropped image doesn't move around weirdly when rotating
        var cropped = this.cropper.cropped;

        if (cropped && this.options.viewMode > 0) {
          this.cropper.clear();
        }

        this.cropper.rotate(amount);

        if (cropped && this.options.viewMode > 0) {
          this.cropper.crop();
        }
      },
      zoom: function zoom(amount) {
        this.cropper.zoom(amount);
      }
    }
  });

  _exports.default = _default;
});