define("lh4/routes/authenticated/reports", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    redirect: function redirect() {
      var adjustedReportsUrl = "".concat(window.location.origin, "/reporting/reports").concat(window.location.search);
      window.location.replace(adjustedReportsUrl);
    }
  });

  _exports.default = _default;
});