define("lh4/mirage/routes/loyalty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageLoyaltyServer;

  function comparatorLogic(a, b, sort, order) {
    if (typeof a.attrs[order] === 'number' || typeof a.attrs[order] === 'boolean') {
      return sort === 'asc' ? a.attrs[order] - b.attrs[order] : b.attrs[order] - a.attrs[order];
    }

    return sort === 'asc' ? a.attrs[order].localeCompare(b.attrs[order]) : b.attrs[order].localeCompare(a.attrs[order]);
  }

  function setMirageLoyaltyServer(server) {
    server.get('/loyalty/lighthouse/discounts', function (schema, _ref) {
      var queryParams = _ref.queryParams;
      var sort = queryParams.sort,
          order = queryParams.order,
          isActive = queryParams.isActive,
          searchName = queryParams.searchName,
          loyaltyProgramGuids = queryParams.loyaltyProgramGuids;
      var discounts = schema.loyaltyDiscounts.all().models.slice();
      var sortedDiscounts = discounts.sort(function (a, b) {
        return comparatorLogic(a, b, sort, order);
      });
      var programs = schema.loyaltyPrograms.all().models.slice();
      var filteredDiscounts = [];

      switch (isActive) {
        case undefined:
          filteredDiscounts = sortedDiscounts;
          break;

        case 'true':
          filteredDiscounts = sortedDiscounts.filter(function (d) {
            return d.isActive;
          });
          break;

        case 'false':
          filteredDiscounts = sortedDiscounts.filter(function (d) {
            return !d.isActive;
          });
          break;

        default:
          filteredDiscounts = sortedDiscounts;
      }

      if (loyaltyProgramGuids && loyaltyProgramGuids[0] !== 'all') {
        filteredDiscounts = filteredDiscounts.filter(function (m) {
          return loyaltyProgramGuids.includes(m.loyaltyProgramGuid);
        });
      } else {
        filteredDiscounts = filteredDiscounts.filter(function (m) {
          return programs.indexOf(m.loyaltyProgramGuid);
        });
      }

      var searchedDiscounts = searchName ? filteredDiscounts.filter(function (d) {
        return d.name.toUpperCase().indexOf(searchName.toUpperCase()) > -1;
      }) : filteredDiscounts;
      return {
        discounts: searchedDiscounts
      };
    });
    server.get('/loyalty/lighthouse/discounts/:id', function (schema) {
      return {
        discount: schema.loyaltyDiscounts.all().models[0]
      };
    });
    server.post('/loyalty/lighthouse/discounts', function (schema, _ref2) {
      var requestBody = _ref2.requestBody;

      var _JSON$parse = JSON.parse(requestBody),
          name = _JSON$parse.name,
          value = _JSON$parse.value,
          pointCost = _JSON$parse.pointCost,
          isActive = _JSON$parse.isActive,
          loyaltyProgramGuid = _JSON$parse.loyaltyProgramGuid,
          type = _JSON$parse.type;

      var id = "".concat(name, "-1111-2222");
      var discount = {
        name: name,
        value: value,
        isActive: isActive,
        pointCost: pointCost,
        id: id,
        guid: id,
        loyaltyProgramGuid: loyaltyProgramGuid,
        type: type
      };
      schema.loyaltyDiscounts.create(discount);
      return {
        discount: schema.loyaltyDiscounts.where({
          name: name
        }).models[0]
      };
    });
    server.get('/loyalty/lighthouse/programs', function (schema, _ref3) {
      var queryParams = _ref3.queryParams;
      var programs = schema.loyaltyPrograms.all().models.slice();

      if (!Object.keys(queryParams).length) {
        return {
          programs: programs
        };
      }

      var sort = queryParams.sort,
          order = queryParams.order,
          isActive = queryParams.isActive,
          searchName = queryParams.searchName,
          limit = queryParams.limit;

      if (limit === 'all') {
        return {
          programs: programs
        };
      }

      var sortedPrograms = programs.sort(function (a, b) {
        return comparatorLogic(a, b, sort, order);
      });
      var filteredPrograms = [];

      switch (isActive) {
        case undefined:
          filteredPrograms = sortedPrograms;
          break;

        case 'true':
          filteredPrograms = sortedPrograms.filter(function (d) {
            return d.isActive;
          });
          break;

        case 'false':
          filteredPrograms = sortedPrograms.filter(function (d) {
            return !d.isActive;
          });
          break;

        default:
          filteredPrograms = sortedPrograms;
      }

      var searchPrograms = searchName ? filteredPrograms.filter(function (d) {
        return d.name.toUpperCase().indexOf(searchName.toUpperCase()) > -1;
      }) : filteredPrograms;
      return {
        programs: searchPrograms
      };
    });
    server.get('/loyalty/lighthouse/programs/:id', function (schema, _ref4) {
      var id = _ref4.params.id;
      return {
        program: schema.loyaltyPrograms.where({
          id: id
        }).models[0]
      };
    });
    server.post('/loyalty/lighthouse/programs', function (schema, _ref5) {
      var requestBody = _ref5.requestBody;

      var _JSON$parse2 = JSON.parse(requestBody),
          name = _JSON$parse2.name,
          pointName = _JSON$parse2.pointName,
          locations = _JSON$parse2.locations;

      var id = "".concat(name, "-1111-2222");
      var ownerUserId = 1;
      var ownerUserName = 'Tom Schnaars';
      var program = {
        id: id,
        guid: id,
        ownerUserId: ownerUserId,
        ownerUserName: ownerUserName,
        name: name,
        pointName: pointName,
        locations: locations
      };
      schema.loyaltyPrograms.create(program);
      return {
        program: schema.loyaltyPrograms.where({
          name: name
        }).models[0]
      };
    });
    server.put('/loyalty/lighthouse/programs/:guid', function (schema, request) {
      var guid = request.params.guid;
      var program = schema.loyaltyPrograms.findBy({
        guid: guid
      });

      var _JSON$parse3 = JSON.parse(request.requestBody),
          name = _JSON$parse3.name,
          pointName = _JSON$parse3.pointName,
          locations = _JSON$parse3.locations;

      return program.update({
        name: name,
        pointName: pointName,
        locations: locations
      });
    });
    server.get('/loyalty/lighthouse/members', function (schema, _ref6) {
      var queryParams = _ref6.queryParams;
      var sort = queryParams.sort,
          order = queryParams.order,
          isActive = queryParams.isActive,
          searchName = queryParams.searchName,
          loyaltyProgramGuids = queryParams.loyaltyProgramGuids;
      var members = schema.loyaltyMembers.all().models.slice();
      var programs = schema.loyaltyPrograms.all().models.slice();
      var sortedMembers = [];
      sortedMembers = members.sort(function (a, b) {
        return comparatorLogic(a, b, sort, order);
      });
      var filteredMembers = [];

      switch (isActive) {
        case 'true':
          filteredMembers = sortedMembers.filter(function (d) {
            return d.isActive;
          });
          break;

        case 'false':
          filteredMembers = sortedMembers.filter(function (d) {
            return !d.isActive;
          });
          break;

        case undefined:
        default:
          filteredMembers = sortedMembers;
      }

      if (loyaltyProgramGuids && loyaltyProgramGuids[0] !== 'all') {
        filteredMembers = sortedMembers.filter(function (m) {
          return loyaltyProgramGuids.includes(m.loyaltyProgramGuid);
        });
      } else {
        filteredMembers = sortedMembers.filter(function (m) {
          return programs.indexOf(m.loyaltyProgramGuid);
        });
      }

      var searchMembers = searchName ? filteredMembers.filter(function (m) {
        return m.name.toUpperCase().indexOf(searchName.toUpperCase()) > -1;
      }) : filteredMembers;
      return {
        members: searchMembers
      };
    });
    server.get('/loyalty/lighthouse/members/:id', function (schema, _ref7) {
      var guid = _ref7.params.id;
      return {
        member: schema.loyaltyMembers.where({
          guid: guid
        }).models
      };
    });
    server.post('/loyalty/lighthouse/members', function (schema, _ref8) {
      var requestBody = _ref8.requestBody;
      var reqBody = JSON.parse(requestBody);
      reqBody.guid = "".concat(reqBody.firstName, "-1111-2222");
      schema.loyaltyMembers.create(reqBody);
      return {
        member: schema.loyaltyMembers.where({
          guid: reqBody.guid
        }).models[0]
      };
    });
    server.put('/loyalty/lighthouse/members/:guid', function (schema, request) {
      var guid = request.params.guid;

      var _JSON$parse4 = JSON.parse(request.requestBody),
          firstName = _JSON$parse4.firstName,
          lastName = _JSON$parse4.lastName,
          phoneNumber = _JSON$parse4.phoneNumber,
          emailAddress = _JSON$parse4.emailAddress,
          streetAddress1 = _JSON$parse4.streetAddress1,
          streetAddress2 = _JSON$parse4.streetAddress2,
          city = _JSON$parse4.city,
          state = _JSON$parse4.state,
          zip = _JSON$parse4.zip,
          loyaltyProgramGuid = _JSON$parse4.loyaltyProgramGuid,
          isActive = _JSON$parse4.isActive;

      var member = schema.loyaltyMembers.findBy({
        guid: guid
      });
      return member.update({
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        emailAddress: emailAddress,
        streetAddress1: streetAddress1,
        streetAddress2: streetAddress2,
        city: city,
        state: state,
        zip: zip,
        loyaltyProgramGuid: loyaltyProgramGuid,
        isActive: isActive
      });
    });
    server.post('/loyalty/lighthouse/transactions', function (schema, _ref9) {
      var requestBody = _ref9.requestBody;

      var _JSON$parse5 = JSON.parse(requestBody),
          pointChange = _JSON$parse5.pointChange,
          loyaltyMemberGuid = _JSON$parse5.loyaltyMemberGuid,
          reason = _JSON$parse5.reason;

      var id = "".concat(reason, "-1111-2222");
      var transaction = {
        id: id,
        guid: id,
        loyaltyMemberGuid: loyaltyMemberGuid,
        pointChange: pointChange,
        reason: reason,
        type: 'adjust'
      };
      schema.loyaltyTransactions.create(transaction);
      return {
        transaction: schema.loyaltyTransactions.where({
          id: id
        }).models[0]
      };
    });
    server.get('/loyalty/lighthouse/transactions', function (schema, _ref10) {
      var _ref10$queryParams = _ref10.queryParams,
          limit = _ref10$queryParams.limit,
          order = _ref10$queryParams.order,
          sort = _ref10$queryParams.sort,
          loyaltyMemberGuid = _ref10$queryParams.loyaltyMemberGuid;
      var transactions = schema.loyaltyTransactions.all().models.slice();
      var filteredTransactions = [];

      if (loyaltyMemberGuid) {
        filteredTransactions = transactions.filter(function (t) {
          return t.loyaltyMemberGuid === loyaltyMemberGuid;
        });
      } else {
        filteredTransactions = transactions;
      }

      var sortedTransactions = filteredTransactions.sort(function (a, b) {
        return comparatorLogic(a, b, sort, order);
      });
      var limitedTransactions;

      if (limit) {
        limitedTransactions = sortedTransactions.slice(0, limit);
      } else {
        limitedTransactions = sortedTransactions;
      }

      return {
        transactions: limitedTransactions
      };
    });
    server.get('/loyalty/lighthouse/promotions', function (schema, _ref11) {
      var queryParams = _ref11.queryParams;
      var sort = queryParams.sort,
          order = queryParams.order,
          isActive = queryParams.isActive,
          searchName = queryParams.searchName,
          loyaltyProgramGuid = queryParams.loyaltyProgramGuid;
      var promotions = schema.loyaltyPromotions.all().models.slice();
      var sortedPromotions = promotions.sort(function (a, b) {
        return comparatorLogic(a, b, sort, order);
      });
      var programs = schema.loyaltyPrograms.all().models.slice();
      var filteredPromotions = [];

      switch (isActive) {
        case undefined:
          filteredPromotions = sortedPromotions;
          break;

        case 'true':
          filteredPromotions = sortedPromotions.filter(function (d) {
            return d.isActive;
          });
          break;

        case 'false':
          filteredPromotions = sortedPromotions.filter(function (d) {
            return !d.isActive;
          });
          break;

        default:
          filteredPromotions = sortedPromotions;
      }

      if (loyaltyProgramGuid && loyaltyProgramGuid[0] !== 'all') {
        filteredPromotions = sortedPromotions.filter(function (m) {
          return loyaltyProgramGuid.includes(m.loyaltyProgramGuid);
        });
      } else {
        filteredPromotions = sortedPromotions.filter(function (m) {
          return programs.indexOf(m.loyaltyProgramGuid);
        });
      }

      var searchedPromotions = searchName ? filteredPromotions.filter(function (d) {
        return d.name.toUpperCase().indexOf(searchName.toUpperCase()) > -1;
      }) : filteredPromotions;
      return {
        promotions: searchedPromotions
      };
    });
    server.get('/loyalty/lighthouse/promotions/:guid', function (schema, _ref12) {
      var guid = _ref12.params.guid;
      return {
        promotion: schema.loyaltyPromotions.where({
          guid: guid
        }).models[0]
      };
    });
    server.post('/loyalty/lighthouse/promotions', function (schema, _ref13) {
      var requestBody = _ref13.requestBody;

      var _JSON$parse6 = JSON.parse(requestBody),
          name = _JSON$parse6.name,
          type = _JSON$parse6.type,
          loyaltyProgramGuid = _JSON$parse6.loyaltyProgramGuid,
          value = _JSON$parse6.value;

      var promotion = {
        id: '1',
        guid: '1',
        type: type,
        loyaltyProgramGuid: loyaltyProgramGuid,
        name: name,
        value: value
      };
      schema.loyaltyPromotions.create(promotion);
      return {
        promotion: schema.loyaltyPromotions.where({
          name: name
        }).models[0]
      };
    });
    server.put('/loyalty/lighthouse/promotions/:guid', function (schema, request) {
      var guid = request.params.guid;
      var _request$requestBody = request.requestBody,
          isActive = _request$requestBody.isActive,
          name = _request$requestBody.name,
          type = _request$requestBody.type,
          value = _request$requestBody.value,
          weekdays = _request$requestBody.weekdays,
          startDate = _request$requestBody.startDate,
          endDate = _request$requestBody.endDate,
          startTime = _request$requestBody.startTime,
          endTime = _request$requestBody.endTime;
      var promotion = schema.loyaltyPromotions.findBy({
        guid: guid
      });
      return promotion.update({
        isActive: isActive,
        name: name,
        type: type,
        value: value,
        weekdays: weekdays,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime
      });
    });
  }
});