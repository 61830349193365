define("lh4/enums/timezone-long", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIMEZONE_LONG = {
    EST: 'Eastern Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
    AST: 'Atlantic Standard Time',
    ADT: 'Atlantid Daylight Time',
    AKST: 'Alaska Standard Time',
    AKDT: 'Alaska Daylight Time',
    HST: 'Hawaii–Aleutian Standard Time',
    HDT: 'Hawaii–Aleutian Daylight Time',
    SST: 'Samoa Standard Time',
    GMT: 'Greenwich Mean Time',
    BST: 'British Summer Time',
    IST: 'Irish Standard Time',
    CET: 'Central European Time',
    CEST: 'Central European Summer Time',
    EET: 'Eastern European Time',
    EEST: 'Eastern European Summer Time',
    UTC: 'Coordinated Universal Time'
  };
  var _default = TIMEZONE_LONG;
  _exports.default = _default;
});