define("lh4/services/two-factor-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),

    /**
     * Generate Secret Key
     *
     * @returns {Promise<{secretCode, qrCode}>} secret key info
     */
    generateSecretKey: function generateSecretKey() {
      return this.ajax.request('/api/v1/auth/otp/generate').then(function (res) {
        if (!res || !res.secretCode) {
          throw new Error(JSON.stringify({
            errors: {
              message: 'could not generate secret code'
            }
          }));
        }

        return {
          secretCode: res.secretCode,
          qrCode: res.qrCode
        };
      });
    },

    /**
     * Enable 2FA for user
     *
     * @param {string} secretKey - secret key previously generated by API
     * @param {string} code - 6 digit user code generated using secretKey
     * @param {boolean} recoveryCodes - generate recovery codes
     * @returns {Promise<object>} api call result
     */
    enable2fa: function enable2fa(secretKey, code) {
      var recoveryCodes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      return this.ajax.request('/api/v1/auth/otp', {
        type: 'POST',
        data: JSON.stringify({
          secretKey: secretKey,
          code: code,
          recoveryCodes: recoveryCodes
        })
      });
    },

    /**
     * Generate new set of Recovery Codes
     *
     * @returns {Promise<Array<string>>} recovery codes
     */
    generateRecoveryKeys: function generateRecoveryKeys() {
      return this.ajax.request('/api/v1/auth/otp/recovery', {
        type: 'POST'
      }).then(function (response) {
        return response.codes;
      });
    },

    /**
     * Disaable 2FA for user
     *
     * @returns {Promise<object>}api call result
     */
    disable2fa: function disable2fa() {
      return this.ajax.request('/api/v1/auth/otp', {
        type: 'DELETE'
      });
    }
  });

  _exports.default = _default;
});