define("lh4/controllers/authenticated/kitchen-display-system/order-ready-screen", ["exports", "lh4/controllers/general-form-controller", "lh4/models/echo-pro/setting/order-ready-screen", "lh4/config/environment"], function (_exports, _generalFormController, _orderReadyScreen, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_DISPLAY_DURATION = 86400;

  var _default = _generalFormController.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    showErrors: false,
    colors: Object.values(_orderReadyScreen.COLORS),
    orderReadyScreenUrl: Ember.computed('location.model', function getter() {
      return "".concat(_environment.default.orsBaseUrl, "/").concat(this.location.model.channel);
    }),
    isVisible: false,
    actions: {
      copyUrl: function copyUrl() {
        var _this = this;

        navigator.clipboard.writeText(this.orderReadyScreenUrl);
        this.set('isVisible', true);
        setTimeout(function () {
          _this.set('isVisible', false);
        }, 500);
      },
      update: function update() {
        var _this2 = this;

        if (this.isSaving) {
          return;
        }

        var orderReadyScreen = this.changeset;

        if (orderReadyScreen.get('title').length > 255) {
          this.flashMessagesHelper.pushMessage('Title cannot be greater than 255 characters', 'error');
        }

        var autoDisplayDuration = Number(orderReadyScreen.get('autoDisplayDuration'));
        var manualDisplayDuration = Number(orderReadyScreen.get('manualDisplayDuration'));

        if (autoDisplayDuration > MAX_DISPLAY_DURATION || manualDisplayDuration > MAX_DISPLAY_DURATION) {
          this.flashMessagesHelper.pushMessage("Display Duration cannot be greater than 24hr(".concat(MAX_DISPLAY_DURATION, " seconds)"), 'error');
        } else if (autoDisplayDuration < 0 || manualDisplayDuration < 0) {
          this.flashMessagesHelper.pushMessage('Display Mode Duration cannot be less than zero', 'error');
        } else {
          this.set('showErrors', true);
          this.set('isSaving', true);
          this.clearServerError();
          this.changeset.validate().then(function () {
            if (!_this2.changeset.isValid) {
              return Ember.RSVP.reject(_this2.changeset.get('errors'));
            }

            return _this2.changeset.save().then(function () {
              _this2.flashMessagesHelper.pushMessage('Your Order Ready Screen settings have been saved', 'success');
            }).catch(function (_ref) {
              var errors = _ref.errors;

              if (!(errors && errors[0])) {
                return;
              }

              throw errors;
            });
          }).catch(function (errors) {
            var createError = "Something went wrong, ".concat(_this2.instanceName, " could not be updated.");
            errors.forEach(function (error) {
              var errorMessage = error.details && error.details[0] || error.message || createError;
              return _this2.set('serverError', errorMessage);
            });
          }).finally(function () {
            return _this2.set('isSaving', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});