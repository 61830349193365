define("lh4/templates/components/internet-payments/risk/risk-status-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ccle9R3j",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"risk-status-icon\"],[10,\"data-test-ip\",\"risk-status-icon\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"containsDecline\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[4,\"internet-payments/risk/risk-block-list-icon-tooltip\",null,[[\"dataName\",\"dataStatus\",\"tooltipClass\"],[[24,[\"args\",\"dataName\"]],[24,[\"statuses\",\"DECLINE\"]],[24,[\"args\",\"tooltipClass\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"containsApprove\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[4,\"internet-payments/risk/risk-block-list-icon-tooltip\",null,[[\"dataName\",\"dataStatus\",\"tooltipClass\"],[[24,[\"args\",\"dataName\"]],[24,[\"statuses\",\"APPROVE\"]],[24,[\"args\",\"tooltipClass\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/risk/risk-status-icon.hbs"
    }
  });

  _exports.default = _default;
});