define("lh4/components/ui/dates/mobile-date-picker", ["exports", "lh4/utils/no-op", "moment"], function (_exports, _noOp, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_FORMAT = 'YYYY-MM-DD';

  var formatDate = function formatDate(date) {
    var isBusinessDay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var dateFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DATE_FORMAT;
    return isBusinessDay ? (0, _moment.default)(date).format(dateFormat) : _moment.default.utc(date).format(dateFormat);
  };

  var _default = Ember.Component.extend({
    classNames: ['mobile-date-picker-wrapper'],
    date: (0, _moment.default)(),
    disabled: false,
    maxDate: null,
    minDate: null,
    onBlur: _noOp.default,
    onChange: _noOp.default,
    onFocus: _noOp.default,
    placeholder: null,
    isBusinessDay: true,
    hasEndDate: false,
    formattedDate: Ember.computed('date', function getter() {
      if (this.date) {
        return formatDate(this.date, this.isBusinessDay);
      }

      return '';
    }),
    formattedMaxDate: Ember.computed('maxDate', function getter() {
      if (this.maxDate) {
        return formatDate(this.maxDate);
      }

      return '';
    }),
    formattedMinDate: Ember.computed('minDate', function getter() {
      if (this.minDate) {
        return formatDate(this.minDate);
      }

      return '';
    }),
    actions: {
      onChange: function onChange() {
        var date = this.isBusinessDay ? (0, _moment.default)(window.event.target.value, DATE_FORMAT) : _moment.default.utc(window.event.target.value, DATE_FORMAT);
        var endDate = this.hasEndDate ? date.endOf('day') : undefined;
        this.onChange(date, endDate);
      }
    }
  });

  _exports.default = _default;
});