define("lh4/routes/authenticated/epro/manage-employees/employees/create", ["exports", "lh4/routes/general-create-route", "lh4/utils/employees"], function (_exports, _generalCreateRoute, _employees) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    queryParams: {
      clone: {
        refreshModel: true
      }
    },
    modelName: 'employee',
    childChangesets: {
      employeeDates: 'dateChangesets',
      employeePhones: 'phoneChangesets',
      payRates: 'payRateChangesets'
    },
    model: function model(_ref) {
      var clone = _ref.clone;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        employee: this.createNewEmployee(clone),
        permissions: this.store.query('echo-pro/permission', {
          location: location
        }),
        jobs: this.store.query('echo-pro/job', {
          location: location
        }),
        printers: this.store.query('echo-pro/printer', {
          location: location
        })
      });
    },
    createNewEmployee: function createNewEmployee(cloneId) {
      if (cloneId) {
        return (0, _employees.cloneEmployee)(this.store, cloneId);
      }

      return this.store.createRecord('echo-pro/employee', {
        locationId: this.location.model.id,
        isActive: true
      });
    },
    afterModel: function afterModel(model) {
      (0, _employees.addMissingPayrates)(this.store, model.employee);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // do not use sticky query params
        // https://guides.emberjs.com/v3.15.0/routing/query-params/#toc_sticky-query-param-values
        controller.set('clone', undefined);
      }
    }
  });

  _exports.default = _default;
});