define("lh4/models/echo-pro/gratuity", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/utils/is-positive-decimal-of-length", "lh4/utils/is-positive-percentage", "lh4/utils/has-many-ids"], function (_exports, _emberData, _attr, _relationships, _emberCpValidations, _isPositiveDecimalOfLength, _isPositivePercentage, _hasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        message: 'Gratuity name cannot be longer than 255 symbols',
        max: 255
      })]
    },
    isFlatAmount: (0, _emberCpValidations.validator)('presence', true),
    taxSetGuid: {
      description: 'Revenue Class',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    value: {
      description: 'Value',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        allowBlank: false
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        return model.get('isFlatAmount') ? (0, _isPositiveDecimalOfLength.default)(value, 9) : (0, _isPositivePercentage.default)(value);
      })]
    },
    guestThreshold: {
      description: 'Guest Count',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        lte: 50,
        allowString: true,
        positive: true,
        allowBlank: false,
        integer: true
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    name: (0, _attr.default)('string'),
    isFlatAmount: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    value: (0, _attr.default)('string'),
    guestThreshold: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    taxSetGuid: (0, _attr.default)('string'),
    orderTypes: (0, _relationships.hasMany)('echo-pro/order-type'),
    orderTypeIds: Ember.computed('orderTypes', (0, _hasManyIds.default)('orderTypes', 'echo-pro/order-type')),
    createdAt: (0, _attr.default)('time'),
    updatedAt: (0, _attr.default)('time')
  });

  _exports.default = _default;
});