define("lh4/components/echo-pro/employee-schedule/shift/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    findInCollection: function findInCollection(collection, selectedId) {
      return collection.find(function (_ref) {
        var id = _ref.id;
        return id === selectedId;
      });
    },
    availableJobs: Ember.computed('model.employee', function getter() {
      var employee = this.get('model.employee');
      var jobs = employee && employee.get('id') ? employee.get('jobs') : this.get('jobs');
      return (jobs ? jobs.toArray() : []).sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    }),
    actions: {
      selectEmployee: function selectEmployee(value) {
        var _this = this;

        var employee = this.findInCollection(this.employees, value); // set employee

        this.set('model.employee', employee);

        if (value !== 'open shift') {
          // check to see if the employee has current job
          var job = this.findInCollection(this.availableJobs, this.get('model.job.id')); // if there is no job then set to first item

          if (!job) {
            job = this.availableJobs.firstObject;
          } // wait for it to be a valid dropdown option before changing the value


          Ember.run.next(this, function () {
            _this.set('model.job', job);
          });
        }
      },
      selectJob: function selectJob(value) {
        this.set('model.job', this.findInCollection(this.availableJobs, value));
      }
    }
  });

  _exports.default = _default;
});