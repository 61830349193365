define("lh4/templates/authenticated/locations/location/echo-pro/employee-schedule/shift/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OkB2pP4K",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal/route-modal\",null,[[\"class\"],[\"small\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"ui/modal/dialog-header\",null,[[\"title\",\"close\"],[\"Edit Shift\",[28,\"action\",[[23,0,[]],\"clear\"],null]]]],false],[0,\"\\n\\n\"],[4,\"ui/modal/dialog-content\",null,null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"echo-pro/employee-schedule/shift/edit\",null,[[\"weekDays\",\"colors\",\"model\",\"jobs\",\"selectedDays\",\"employees\"],[[24,[\"weekDays\"]],[24,[\"colors\"]],[24,[\"model\",\"shift\"]],[24,[\"model\",\"jobs\"]],[24,[\"selectedDays\"]],[24,[\"model\",\"employees\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[1,[28,\"ui/modal/dialog-buttons\",null,[[\"acceptText\",\"accept\",\"decline\",\"otherwise\",\"isLoading\",\"isOtherwiseLoading\"],[\"Save Shift\",[28,\"action\",[[23,0,[]],\"validateAndSave\"],null],[28,\"action\",[[23,0,[]],\"clear\"],null],[28,\"action\",[[23,0,[]],\"delete\"],null],[24,[\"isLoading\"]],[24,[\"isDeleting\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/employee-schedule/shift/edit.hbs"
    }
  });

  _exports.default = _default;
});