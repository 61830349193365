define("lh4/controllers/authenticated/bank-account/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    location: Ember.inject.service(),
    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.locations.location.overview', this.location.model.id);
      }
    }
  });

  _exports.default = _default;
});