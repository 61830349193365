define("lh4/routes/authenticated/social-media/accounts/edit", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    editRecord: Ember.inject.service(),
    model: function model(_ref) {
      var _this = this;

      var accountId = _ref.accountId;

      var _this$modelFor = this.modelFor('authenticated.social-media'),
          locations = _this$modelFor.locations;

      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/options")).catch(function (err) {
        _this.errorHandler.handleResponseError(err, 'Could not load social media account options');

        return {};
      }).then(function (options) {
        return Ember.RSVP.hash({
          locations: locations,
          options: options,
          account: _this.store.findRecord('social-media/account', accountId, {
            reload: true
          }).catch(function (err) {
            _this.errorHandler.handleErrorCustomMessage(err, 'Could not load social media account');

            _this.transitionTo('authenticated.social-media.accounts');
          })
        });
      });
    },
    actions: {
      willTransition: function willTransition() {
        if (!this.get('controller.model.account.isDeleted')) {
          this.get('controller.model.account').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});