define("lh4/controllers/authenticated/settings/2fa", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    twoFactorAuth: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    isLoading: false,
    is2faEnabledRequest: null,
    recoveryCodes: [],
    settingsMessage: 'Two-Factor authentication adds and extra layer of protection to your account. ' + 'Whenever you sign in, you’ll need to enter both your password and a security code.',
    is2faEnabled: Ember.computed('session.data.authenticated.is2fa', 'is2faEnabledRequest', function getter() {
      if (this.is2faEnabledRequest !== null) {
        return this.is2faEnabledRequest;
      }

      return this.get('session.data.authenticated.is2fa');
    }).readOnly(),
    actions: {
      enable2fa: function enable2fa() {
        if (this.isLoading) {
          return;
        }

        this.router.transitionTo('authenticated.settings.2fa.setup');
      },
      toggle2faEnabled: function toggle2faEnabled(enable) {
        var _this = this;

        if (this.isLoading) {
          return;
        }

        if (enable) {
          return;
        }

        this.set('isLoading', true);
        this.set('is2faEnabledRequest', enable);
        this.twoFactorAuth.disable2fa().then(function () {
          _this.flashMessagesHelper.pushMessage('Two Factor Authentication successfully disabled', 'success');

          Ember.run.later(_this, function () {
            _this.set('isLoading', false);

            _this.session.invalidate();
          }, 2000);
        }).catch(function (err) {
          _this.set('is2faEnabledRequest', null);

          _this.errorHandler.handleResponseError(err, 'There was an error while disabling Two Factor Authentication');

          _this.set('isLoading', false);
        });
      },
      generateRecoveryKeys: function generateRecoveryKeys() {
        var _this2 = this;

        if (this.isLoading) {
          return;
        }

        this.set('isLoading', true);
        this.twoFactorAuth.generateRecoveryKeys().then(function (codes) {
          _this2.set('recoveryCodes', codes);

          (0, _jquery.default)('#recovery-codes-modal').modal({
            closable: false,
            detachable: false
          }).modal('show');
        }).catch(function (err) {
          _this2.errorHandler.handleResponseError(err, 'There was an error while disabling Two Factor Authentication');
        }).finally(function () {
          return _this2.set('isLoading', false);
        });
      },
      closeRecoveryModal: function closeRecoveryModal() {
        this.set('recoveryCodes', []);
        (0, _jquery.default)('#recovery-codes-modal').modal('hide');
      }
    }
  });

  _exports.default = _default;
});