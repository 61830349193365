define("lh4/mirage/serializers/hospitality-choices", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.RestSerializer.extend({
    primaryKey: 'rowguid'
  });

  _exports.default = _default;
});