define("lh4/routes/authenticated/add-on-devices", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    requiredPermissions: ['manage gpatt for add-on-devices', 'manage tableside for add-on-devices', 'manage customer display for add-on-devices', 'manage printers for add-on-devices'],
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    titleToken: 'Devices',
    redirect: function redirect(model, transition) {
      if (transition.targetName === 'authenticated.add-on-devices.gpatt' && this.can.cannot('manage gpatt for add-on-devices', this.location.model)) {
        this.transitionTo('authenticated.dashboard2');
      }

      if (transition.targetName === 'authenticated.add-on-devices.tableside' && this.can.cannot('manage tableside for add-on-devices')) {
        this.transitionTo('authenticated.dashboard2');
      }

      if (transition.targetName === 'authenticated.add-on-devices.customer-display' && this.can.cannot('manage customer display for add-on-devices')) {
        this.transitionTo('authenticated.dashboard2');
      }

      if (transition.targetName === 'authenticated.add-on-devices.printers' && this.can.cannot('manage printers for add-on-devices')) {
        this.transitionTo('authenticated.dashboard2');
      }
    }
  });

  _exports.default = _default;
});