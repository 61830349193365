define("lh4/templates/components/hospitality/customize-menu/item/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jnb2XP9j",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"hospitality/dynamic-multi-select\",null,[[\"collection\",\"selected\",\"item\",\"relation\"],[[24,[\"collection\"]],[24,[\"item\",\"menuItemsCategories\"]],[24,[\"item\"]],\"screenCategoryId\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/hospitality/customize-menu/item/categories.hbs"
    }
  });

  _exports.default = _default;
});