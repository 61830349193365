define("lh4/serializers/echo-pro/customer/phone", ["exports", "lh4/serializers/echo-pro/customer/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var phoneTypes = [{
    name: 'Home',
    id: 0
  }, {
    name: 'Mobile',
    id: 1
  }, {
    name: 'Work',
    id: 2
  }, {
    name: 'Emergency',
    id: 3
  }, {
    name: 'Unknown',
    id: 4
  }];

  var _default = _application.default.extend({
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (json.type != null) {
        var validType = phoneTypes.find(function (phoneType) {
          return phoneType.id === json.type;
        });
        json.type = validType ? validType.name : phoneTypes[4].name;
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.type != null) {
        var validType = phoneTypes.find(function (phoneType) {
          return phoneType.name === json.type;
        });
        json.type = validType ? validType.id : 4;
      }

      return json;
    }
  });

  _exports.default = _default;
});