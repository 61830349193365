define("lh4/templates/components/ui/dates/mobile-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7dhiWxop",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"class\",[28,\"concat\",[\"mobile-date-picker \",[24,[\"className\"]]],null]],[11,\"disabled\",[22,\"disabled\"]],[11,\"min\",[22,\"formattedMinDate\"]],[11,\"max\",[22,\"formattedMaxDate\"]],[11,\"onblur\",[22,\"onBlur\"]],[11,\"onfocus\",[22,\"onFocus\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"onChange\"],null]],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"value\",[22,\"formattedDate\"]],[10,\"type\",\"date\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/dates/mobile-date-picker.hbs"
    }
  });

  _exports.default = _default;
});