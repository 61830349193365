define("lh4/templates/authenticated/supply-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4cABqEAe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"can\",[\"place supply in order\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[22,\"supply-orders/order-wizard\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[1,[28,\"supply-orders/order-tracker\",null,[[\"model\",\"canViewPosOrders\",\"canViewSupplyOrders\"],[[24,[\"model\"]],[28,\"can\",[\"view pos in order\"],null],[28,\"can\",[\"view supply in order\"],null]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/supply-orders.hbs"
    }
  });

  _exports.default = _default;
});