define("lh4/abilities/echo-pro/item", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _permissions, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    canManage: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('echo-pro') && this.hasLocationPermission(_permissions.default.HBR.MENU);
    }),
    canViewDefaultModifiers: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.DEFAULT_MODIFIERS_ENABLED];
    }),
    canViewDefaultModifiersColumn: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.DEFAULT_MODIFIERS_ENABLED] && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.DEFAULT_MODIFIERS_COLUMN_VISIBILITY];
    }),
    canViewPrepTimesColumn: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.DEFAULT_MODIFIERS_ENABLED] && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.PREP_TIMES_COLUMN_VISIBILITY];
    })
  });

  _exports.default = _default;
});