define("lh4/controllers/authenticated/internet-payments/reporting/subscriptions", ["exports", "lh4/controllers/general-list-controller", "lh4/enums/ip-pagination-limit"], function (_exports, _generalListController, _ipPaginationLimit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    field: 'report',
    name: 'Report'
  }, {
    field: 'frequency',
    name: 'Frequency'
  }, {
    field: 'sendTime',
    name: 'Send Time'
  }, {
    field: 'lastSent',
    name: 'Last Sent'
  }, {
    field: 'email',
    name: 'Email'
  }];

  var _default = _generalListController.default.extend({
    headers: HEADERS,
    isFilterOpen: false,
    media: Ember.inject.service(),
    location: Ember.inject.service(),
    queryParams: ['filterLocationId', 'offset'],
    limit: _ipPaginationLimit.default.LIMIT,
    actions: {
      onLocationSelect: function onLocationSelect(value) {
        this.setProperties({
          filterLocationId: value,
          offset: 0
        });
      }
    }
  });

  _exports.default = _default;
});