define("lh4/routes/authenticated/locations/location/echo-pro/manage-pos/customize-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.replaceWith('authenticated.epro.menu');
    }
  });

  _exports.default = _default;
});