define("lh4/models/echo-pro/time-clock-break", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    guid: _emberData.default.attr('string'),
    timeClockShiftGuid: _emberData.default.attr('string'),
    startedAt: _emberData.default.attr('string'),
    endedAt: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    isPaid: _emberData.default.attr('boolean'),
    voided: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('string')
  });

  _exports.default = _default;
});