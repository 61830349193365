define("lh4/components/wootric-survey", ["exports", "jquery", "lh4/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WOOTRIC_SCRIPT_URL = 'https://disutgh7q0ncc.cloudfront.net/beacon.js';
  /* eslint-disable camelcase */

  var _default = Ember.Component.extend({
    tokenData: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      if (_environment.default.environment === 'test') {
        return;
      }

      window.wootricSettings = {
        account_token: _environment.default.wootric.nps,
        email: this.get('tokenData.data.email'),
        created_at: this.get('tokenData.data.userSince')
      };

      _jquery.default.getScript(WOOTRIC_SCRIPT_URL, function () {
        window.WootricSurvey.run(window.wootricSettings);
      });
    }
  });

  _exports.default = _default;
});