define("lh4/routes/authenticated/epro/accounting", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    intl: Ember.inject.service(),
    requiredPermissions: 'manage echo-pro/accounting',
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.titleToken = this.intl.t('routes:authenticated:epro:accounting:title-token');
      controller.setProperties({
        routeData: [{
          name: 'authenticated.epro.accounting.taxes',
          title: this.intl.t('controllers:authenticated:epro:accounting:route-data:taxes')
        }, {
          name: 'authenticated.epro.accounting.surcharges',
          title: this.intl.t('controllers:authenticated:epro:accounting:route-data:surcharges')
        }, {
          name: 'authenticated.epro.accounting.revenue-classes',
          title: this.intl.t('controllers:authenticated:epro:accounting:route-data:revenue-classes')
        }, {
          name: 'authenticated.epro.accounting.gratuities',
          title: this.intl.t('controllers:authenticated:epro:accounting:route-data:gratuities')
        }]
      });
    }
  });

  _exports.default = _default;
});