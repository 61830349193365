define("lh4/templates/components/internet-payments/info-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E2U4N2sJ",
    "block": "{\"symbols\":[\"@disabled\",\"@text\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"info-pill\",[28,\"if\",[[23,1,[]],\" disabled\"],null]]]],[8],[0,\"\\n\\t\"],[1,[23,2,[]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/info-pill.hbs"
    }
  });

  _exports.default = _default;
});