define("lh4/templates/components/reports/echo-pro/ticket-transaction-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mayzSKCo",
    "block": "{\"symbols\":[\"@currentReport\",\"@reportType\",\"@generatedReportType\",\"@enabledProd\",\"@selectedLocations\",\"@exportTypes\",\"@generatedReport\",\"@onToggleFavorite\",\"@startsAt\",\"@endsAt\",\"@selectedReport\",\"@showCustomNodeTypes\"],\"statements\":[[5,\"modal/change-location-modal\",[],[[\"@location\",\"@col\",\"@onClose\",\"@onSwitch\"],[[24,[\"changeLocationAttributes\",\"location\"]],[24,[\"changeLocationAttributes\",\"col\"]],[28,\"action\",[[23,0,[]],[23,0,[\"closeChangeLocationModal\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"switchLocation\"]]],null]]]],[0,\"\\n\\n\"],[5,\"reports/report-table-tree\",[],[[\"@currentReport\",\"@reportType\",\"@generatedReportType\",\"@enabledProd\",\"@selectedLocations\",\"@exportTypes\",\"@generatedReport\",\"@onToggleFavorite\",\"@startsAt\",\"@endsAt\",\"@selectedReport\",\"@showCustomNodeTypes\",\"@onLinkClick\",\"@tableTreeWrapperClasses\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[28,\"action\",[[23,0,[]],[23,0,[\"handleLinkClick\"]]],null],\"table-hover\"]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reports/echo-pro/ticket-transaction-status.hbs"
    }
  });

  _exports.default = _default;
});