define("lh4/controllers/authenticated/reputation/accounts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['offset', 'limit'],
    limit: 25,
    offset: 0,
    errorHandler: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    location: Ember.inject.service(),
    accountApi: Ember.inject.service('reputation/account-api'),
    selectedLocation: Ember.computed('location', 'location.model.id', function getter() {
      this.set('offset', 0);
      return this.get('location.model');
    }),
    actions: {
      deleteAccount: function deleteAccount(account) {
        var _this = this;

        if (this.get('loading')) {
          return;
        }

        this.set('loading', true);
        this.accountApi.deleteAccount(account.id).then(function () {
          _this.flashMessagesHelper.pushMessage('Successfully deleted account', 'success');

          _this.transitionToRoute('authenticated.reputation.accounts');

          _this.get('model.accounts').removeObject(account);
        }).catch(function (e) {
          _this.errorHandler.handleResponseError(e, 'Could not delete account');
        }).finally(function () {
          _this.set('loading', false);
        });
      },
      filterChanged: function filterChanged(_ref) {
        var offset = _ref.offset,
            limit = _ref.limit;
        this.transitionToRoute('authenticated.reputation.accounts', {
          queryParams: {
            offset: offset,
            limit: limit
          }
        });
      },
      selectAccount: function selectAccount(account) {
        this.transitionToRoute('authenticated.reputation.accounts.edit', account.id);
      }
    }
  });

  _exports.default = _default;
});