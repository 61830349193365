define("lh4/utils/belongs-to-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getBelongsToId;

  /**
   * Returns an object with a getter/setter used to return an id
   * representing a record associated with a model. (Used to work around
   * bugs related to changesets directly altering relationships)
   * When calling the setter the id will be mapped to the appropriate record
   *
   * @method getBelongsToId
   * @param {string} dependentKey belongsTo property we're looking at
   * @param {string} modelName name of the belongsTo property model
   * @returns {Object} getBelongsToId
   */
  function getBelongsToId(dependentKey, modelName) {
    return {
      get: function get() {
        if (this[dependentKey]) {
          return this[dependentKey].get('id');
        }

        return null;
      },
      set: function set(key, id) {
        if (id) {
          var record = this.store.peekRecord(modelName, id);
          this.set(dependentKey, record);
        } else {
          this.set(dependentKey, id);
        }

        return id;
      }
    };
  }
});