define("lh4/routes/authenticated/reputation/accounts/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    accountApi: Ember.inject.service('reputation/account-api'),
    errorHandler: Ember.inject.service(),
    model: function model() {
      var _this = this;

      this.store.unloadAll('social-media/account');
      var accountApi = this.accountApi;
      return Ember.RSVP.hash({
        accounts: accountApi.findAccounts().catch(function (err) {
          _this.errorHandler.handleErrorCustomMessage(err, 'Could not load accounts');
        }),
        locations: this.store.peekAll('location')
      });
    },
    actions: {
      addSocialNetworkDialog: function addSocialNetworkDialog(location) {
        this.transitionTo('authenticated.reputation.accounts.add', {
          queryParams: {
            location: location && location.id
          }
        });
      }
    }
  });

  _exports.default = _default;
});