define("lh4/routes/authenticated/locations/location/revenue-centers", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    corporation: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // Fetch location menu settings to evaluate isMenuManagedByCorporate before components are loaded
      if (this.location.model.corpId) {
        this.corporation.loadLocationMenuSettings(this.location.model.id);
      }
    },
    queryParams: {
      searchName: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        revenueCenters: this.store.query('echo-pro/revenue-center', _objectSpread(_objectSpread({}, params), {}, {
          location: location
        }))
      });
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);

      var locationController = this.controllerFor('authenticated.locations.location');
      locationController.set('showLocationTabs', true);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var locationController = this.controllerFor('authenticated.locations.location');
        locationController.set('showLocationTabs', false);
      }
    },
    redirect: function redirect() {
      if (this.can.cannot('view revenue centers in revenue-center', this.location.model)) {
        this.transitionTo('authenticated.dashboard2');
      }
    }
  });

  _exports.default = _default;
});