define("lh4/mixins/mobile-scroll-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    media: Ember.inject.service(),
    activate: function activate() {
      this._super.apply(this, arguments);

      if (this.media.isMobile) {
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});