define("lh4/components/ui/form-fields/required-fields", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'form-field-required-fields',
    classNames: ['required-fields'],
    intl: Ember.inject.service()
  });

  _exports.default = _default;
});