define("lh4/components/ui/dropdown-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @param {string} - max-width style option
     */
    maxWidth: '15rem',

    /**
     * @param {Array<object>} - options to select from
     */
    collection: [],

    /**
     * @param {string} - object ID field name
     */
    idField: 'id',

    /**
     * @param {string} - object field to display
     */
    displayField: 'name',

    /**
     * @param {object} - selected Object, use only when `selectId=false` (default)
     */
    selected: null,

    /**
     * @param {boolean} - dropdown selects ID field value instead of whole object
     */
    selectId: false,

    /**
     * @param {string|number} - selected ID, use only with `selectId=true`, otherwise use `selected`.
     */
    selectedById: null,

    /**
     * @param {string} - dropdown ID
     */
    dropdownId: '',
    additionalClasses: '',
    tagName: '',
    selectedId: Ember.computed('selected', function getter() {
      return this.get("selected.".concat(this.get('idField')));
    }),
    classes: Ember.computed('additionalClasses', function getter() {
      return "selection ".concat(this.get('additionalClasses'));
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var selectedById = this.get('selectedById');

      if (selectedById && this.get('selectId')) {
        var selected = this.get('collection').find(function (rec) {
          var field = Ember.get(rec, _this.get('idField'));

          if (typeof selectedById === 'number' && typeof field !== 'number') {
            return Number(field) === selectedById;
          }

          if (typeof selectedById === 'string' && typeof field !== 'string') {
            return String(field) === selectedById;
          }

          return field === selectedById;
        });
        this.set('selected', selected);
      }
    },
    actions: {
      selectRecord: function selectRecord(selectedId) {
        var _this2 = this;

        var selected = this.get('collection').find(function (record) {
          return Ember.get(record, _this2.get('idField')) === selectedId;
        });
        this.set('selected', selected);

        if (this.get('selectId')) {
          this.set('selectedById', selectedId);
        }

        this.sendAction('onChange', selected);
      }
    }
  });

  _exports.default = _default;
});