define("lh4/components/internet-payments/ui/fields/mobile-datetime-range-picker", ["exports", "lh4/components/ui/dates/mobile-datetime-range-picker"], function (_exports, _mobileDatetimeRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mobileDatetimeRangePicker.default.extend({
    actions: {
      onRangeSelect: function onRangeSelect() {
        this.set('withoutDefaultDateTime', false);

        this._super.apply(this, arguments);
      },
      customRangeSelect: function customRangeSelect() {
        this.set('withoutDefaultDateTime', false);

        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});