define("lh4/components/ui/dropdown/reservation-status", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reservation-status'],
    statuses: ['Arrived', 'Seated', 'Not Here Yet', 'Cancelled', 'Closed'],
    actions: {
      changeStatus: function changeStatus(value) {
        var res = this.get('model');
        res.set('statusId', parseInt(value, 10));

        if (value === '1') {
          res.set('arrivedAt', (0, _moment.default)().toISOString());
        } else if (value === '3') {
          this.sendAction('cancelReservation', res.get('locationId'), res.id);
        } else {
          res.set('arrivedAt', null);
        }
      }
    }
  });

  _exports.default = _default;
});