define("lh4/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    time: {
      // use SHORT format for time if no special formatting is specified in the design
      // EX. {{format-time dateValue format="SHORT"}}
      SHORT: {
        hour: 'numeric',
        minute: 'numeric'
      },
      MEDIUM: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      },
      LONG: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
      },
      FULL: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'long'
      }
    },
    // use SHORT format for date if no special formatting is specified in the design
    // EX. {{format-date dateValue format="SHORT"}}
    date: {
      SHORT: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      MEDIUM: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      LONG: {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      },
      FULL: {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      },
      SHORT_NO_YEAR: {
        month: '2-digit',
        day: '2-digit'
      },
      MEDIUM_NO_YEAR: {
        month: 'short',
        day: 'numeric'
      },
      MEDIUM_WEEKDAY_NO_YEAR: {
        weekday: 'short',
        month: 'short',
        day: 'numeric'
      },
      LONG_NO_DAY: {
        year: 'numeric',
        month: 'long'
      }
    },
    // dateTime: {
    //     This is intentionally commented out because there are limited defined
    //     formats for dates and times used in internationalization.   If there
    //     is a need for a date and time please use the LBM implemented
    //     format-date-time helper which takes a format from the date section
    //     and a format from the time section
    //     EX. {{format-date-time dateValue dateFormat="SHORT" timeFormat="SHORT"}}
    //
    //     If it is ever determined that there is a need for dateTime formats,
    //     please include them in the LocaleService as the date and time format
    //     are set in the intl service.
    // },
    number: {
      compact: {
        notation: 'compact'
      },
      EUR: {
        style: 'currency',
        currency: 'EUR'
      },
      USD: {
        style: 'currency',
        currency: 'USD'
      }
    }
  };
  _exports.default = _default;
});