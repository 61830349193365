define("lh4/abilities/hospitality", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var permissionsArray = [_permissions.default.HOSP.EMPLOYEES, _permissions.default.HOSP.MENU, _permissions.default.HOSP.ITEM_GROUPS, _permissions.default.HOSP.CHOICES_AND_CHOICE_SETS];

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    canView: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('hospitality');
    }),
    canManagePos: (0, _abilityMixin.computedAbility)(function getter() {
      var _this = this;

      return this.get('canView') && permissionsArray.some(function (permission) {
        return _this.hasPermission(permission);
      });
    })
  });

  _exports.default = _default;
});