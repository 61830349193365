define("lh4/routes/authenticated/epro/menu/price-schedule-groups/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    modelName: 'priceScheduleGroup',
    childChangesets: {
      priceSchedules: 'priceScheduleChangesets'
    },
    model: function model() {
      var locationId = this.location.model.id;
      var priceScheduleGroup = this.store.createRecord('echo-pro/price-schedule-group', {
        locationId: locationId
      });
      var priceSchedule = this.store.createRecord('echo-pro/price-schedule', {
        locationId: locationId,
        name: 'Schedule 1',
        createdAt: new Date()
      });
      priceScheduleGroup.priceSchedules.addObject(priceSchedule);
      return Ember.RSVP.hash({
        priceScheduleGroup: priceScheduleGroup
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'New',
        'Entry ID': model.priceScheduleGroup.guid,
        'Menu Component': 'Price Schedule Group'
      });
    }
  });

  _exports.default = _default;
});