define("lh4/mirage/routes/internet-payments/reports", ["exports", "lh4/mirage/fixtures/internet-payments/report-list", "lh4/mirage/fixtures/internet-payments/reports"], function (_exports, _reportList, _reports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsReportsServer;

  function setMirageInternetPaymentsReportsServer(server) {
    server.get('/internet-payments/reports', function () {
      return _reportList.default;
    });
    server.post('/internet-payments/reports/transaction', function () {
      return _reports.TRANSACTION_RESPONSE_FIXTURE;
    });
    server.post('/internet-payments/reports/funds-in-transit', function () {
      return _reports.FUNDS_IN_TRANSIT;
    });
  }
});