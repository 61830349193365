define("lh4/models/hardware/checkout", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    personalLocationId: {
      description: 'Merchant',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    personalFullName: {
      description: 'Name & Surname',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    personalEmail: {
      description: 'Email',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })]
    },
    personalPhoneNumber: {
      description: 'Phone',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('phone-number', {
        message: 'Phone number has incorrect format',
        requireParentheses: true
      })]
    },
    deliveryLocationId: {
      description: 'Shipping',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    deliveryStreetAddress1: {
      description: 'Street Address 1',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    deliveryStreetAddress2: {
      description: 'Street Address 2',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    deliveryCity: {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    deliveryState: {
      description: 'Address',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    deliveryZip: {
      description: 'Street Address 1',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('zip-code', {
        description: 'ZIP'
      })]
    },
    shippingMethod: {
      description: 'Shipping Method',
      validators: []
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    personalLocationId: _emberData.default.attr('string'),
    personalFullName: _emberData.default.attr('string'),
    personalEmail: _emberData.default.attr('string'),
    personalPhoneNumber: _emberData.default.attr('phone'),
    deliveryLocationId: _emberData.default.attr('string'),
    deliveryStreetAddress1: _emberData.default.attr('string'),
    deliveryStreetAddress2: _emberData.default.attr('string'),
    deliveryCity: _emberData.default.attr('string'),
    deliveryState: _emberData.default.attr('string'),
    deliveryZip: _emberData.default.attr('string'),
    shippingMethod: _emberData.default.attr('string'),
    agreeTermsAndConditions: _emberData.default.attr('boolean'),
    items: _emberData.default.attr({
      defaultValue: function defaultValue() {
        return [];
      }
    })
  });

  _exports.default = _default;
});