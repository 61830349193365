define("lh4/templates/components/reputation/google-select-business", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cOU/LMgP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui basic segment\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui center aligned header\"],[8],[0,\"\\n\\t\\tSelect Business Location\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui fluid blue label\"],[8],[0,\"Google Account\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[28,\"ui/dropdown-simple\",null,[[\"collection\",\"selected\",\"selectId\",\"idField\",\"displayField\",\"onChange\",\"additionalClasses\"],[[24,[\"googleAccounts\"]],[24,[\"selectedAccount\"]],false,\"name\",\"accountName\",[28,\"action\",[[23,0,[]],\"selectAccount\"],null],\"fluid\"]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide column\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui active center aligned inline loader\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui fluid blue label\"],[8],[0,\"Business Location\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"googleLocations\",\"length\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[28,\"ui/dropdown-simple\",null,[[\"collection\",\"selected\",\"selectId\",\"idField\",\"displayField\",\"onChange\",\"additionalClasses\"],[[24,[\"googleLocations\"]],[24,[\"selectedLocation\"]],false,\"name\",\"locationName\",[28,\"action\",[[23,0,[]],\"selectLocation\"],null],\"fluid\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui basic yellow message warn no-locations\"],[8],[0,\"No Locations available for this Google account\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reputation/google-select-business.hbs"
    }
  });

  _exports.default = _default;
});