define("lh4/routes/authenticated/internet-payments/transactions/master-transactions/edit/risk-block-list", ["exports", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lighthousePermissionsMixin.default, {
    location: Ember.inject.service(),
    ajax: Ember.inject.service(),
    requiredPermissions: ['manage risk block list in internet-payment'],
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('manage risk block list in internet-payment')) {
        this.transitionTo('authenticated.internet-payments.dashboard');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        transaction: this.modelFor('authenticated.internet-payments.transactions.master-transactions.edit').transaction,
        riskBlockList: this.modelFor('authenticated.internet-payments.transactions.master-transactions.edit').riskBlockList
      });
    }
  });

  _exports.default = _default;
});