define("lh4/mirage/routes/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageDashboardV1Server;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function setMirageDashboardV1Server(server) {
    server.get('/dashboard/financial-overview/:name', function (schema, request) {
      if (request.queryParams.start === '2016-01-01T03:00:00.000Z' && request.queryParams.end === '2017-01-01T02:59:59.999Z') {
        return _defineProperty({}, request.params.name, [{
          locationId: 1,
          total: '1.00'
        }, {
          locationId: 2,
          total: '1.00'
        }]);
      }

      return _defineProperty({}, request.params.name, [{
        locationId: 1,
        total: '0.00'
      }, {
        locationId: 2,
        total: '0.00'
      }]);
    });
    server.get('/dashboard/processing/batch-detail', function (schema, request) {
      if (request.queryParams.merchantId === '0000000001' || request.queryParams.start === '2017-02-01T00:00:00.000Z' && request.queryParams.end === '2017-02-28T23:59:59.999Z') {
        return {
          batches: [{
            MerchantID: '0018544891',
            BatchID: '29547984',
            TransDate: '2017-02-11T00:00:00.000Z',
            TotalAmt: 0.2,
            AmtVisa: 0,
            AmtMasterCard: 0.2,
            AmtAmex: 0,
            AmtDiscover: 0,
            AmtDebit: 0,
            AmtEBT: 0,
            AmtPrivateLabel: 0,
            AmtReturns: 0,
            AmtWrightExpress: 0,
            AmtVoyager: 0,
            AmtVisaReturns: 0,
            AmtMCReturns: 0,
            AmtAmexReturns: 0,
            AmtDiscoverReturns: 0,
            AmtDebitReturns: 0,
            AmtEBTReturns: 0
          }],
          transactions: [{
            ImportDate: '2017-02-11T00:00:00.000Z',
            TimeCounter: 75507626,
            MerchantNo: '0018544891',
            VendorName: 'TSYS-EDC',
            CreditCardNo: '',
            CVVCode: null,
            BatchTotal: 0.2,
            CountryCode: null,
            ForeignFlag: null,
            CardType: null,
            TerminalType: null,
            TransactionType: '04',
            TransactionDate: '20170211',
            CardExpDate: null,
            SequenceNo: null,
            TransactionCode: '63',
            TransactionAmount: 0.2,
            OtherAmount: 0,
            TotalBatchTransactions: 1,
            ReferenceNo: '',
            SalesRejectCode: null,
            SystemTime: null,
            ApprovalCode: '042590',
            VendorResponseCode: null,
            AddressVerificationFlag: null,
            OriginalCurrencyCode: '840',
            POSEntryMode: '01',
            SettleFlag: '',
            ECommerceInd: '',
            BatchNo: '29547984',
            TerminalNo: '76467743',
            IsSwiped: false
          }],
          deposits: [{
            ReferenceNumber: '0000032380309702',
            AggAmount: 0.2,
            RoutingNumber: '021210057',
            AccountNum: '******4634',
            BatchDate: '02/12/2017',
            DepositDate: '02/13/2017',
            ID: 0.7540724545251578,
            DebitCredit: 'Credit'
          }]
        };
      }

      return {
        batches: [],
        deposits: [],
        transactions: []
      };
    });
  }
});