define("lh4/components/table-layout/shared/canvas-rotate-actions", ["exports", "lh4/components/table-layout/shared/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rotateMinus = rotateMinus;
  _exports.rotatePlus = rotatePlus;

  /**
   * Rotate counter clock-wise
   * @param {object} canvasObject - canvas object
   */
  function rotateMinus(canvasObject) {
    if (canvasObject.meta.angle > 0) {
      Ember.set(canvasObject, 'angle', canvasObject.angle - 5);
      Ember.set(canvasObject, 'meta.angle', canvasObject.meta.angle - 5);
    } else if (canvasObject.meta.angle === 0 && canvasObject.get('meta.type') === _constants.SHAPE_LABEL) {
      Ember.set(canvasObject, 'angle', 355);
      Ember.set(canvasObject, 'meta.angle', 355);
    }
  }
  /**
   * Rotate clock-wise
   * @param {object} canvasObject - canvas object
   */


  function rotatePlus(canvasObject) {
    if (canvasObject.meta.angle < 180 && canvasObject.meta.type === 'table') {
      Ember.set(canvasObject, 'angle', canvasObject.angle + 5);
      Ember.set(canvasObject, 'meta.angle', canvasObject.meta.angle + 5);
    } else if (canvasObject.meta.angle === 355) {
      Ember.set(canvasObject, 'angle', 0);
      Ember.set(canvasObject, 'meta.angle', 0);
    } else if (canvasObject.meta.angle < 360 && canvasObject.meta.type === _constants.SHAPE_LABEL) {
      Ember.set(canvasObject, 'angle', canvasObject.angle + 5);
      Ember.set(canvasObject, 'meta.angle', canvasObject.meta.angle + 5);
    }
  }
});