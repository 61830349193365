define("lh4/routes/authenticated/account-maintenance", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/config/environment"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin, _lighthousePermissionsMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var redirectUrl = _environment.default.internetPayments.accountSettings ? 'authenticated.internet-payments.account-maintenance' : 'authenticated.internet-payments.account-maintenance.coming-soon';

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, _lighthousePermissionsMixin.default, {
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    session: Ember.inject.service(),
    titleToken: 'Account Maintenance',
    requiredPermissions: ['manage account-maintenance'],
    redirect: function redirect() {
      if (this.location.hasInternetPaymentProduct) {
        this.transitionTo(redirectUrl);
      }
    },
    model: function model() {
      var location = this.location.model;
      var userId = this.get('session.data.authenticated.sub');
      return Ember.RSVP.hash({
        location: location,
        token: this.ajax.request("/api/v1/accounts/".concat(userId, "/integration-token")).then(function (res) {
          return res.token;
        })
      });
    }
  });

  _exports.default = _default;
});