define("lh4/serializers/echo-pro/department", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      items: {
        embedded: 'always',
        serialize: 'record'
      },
      stposUiStylesheetObjectLinks: {
        embedded: 'always'
      },
      dynamicPricing: {
        embedded: 'always'
      },
      availability: {
        embedded: 'always'
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.availability && json.availability.schedules) {
        json.availability.schedules = json.availability.schedules.filter(function (schedule) {
          return !schedule.isVoided;
        });
      }

      return json;
    }
  });

  _exports.default = _default;
});