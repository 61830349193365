define("lh4/controllers/authenticated/customer-engagement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    location: Ember.inject.service(),
    walkthrough: Ember.inject.service(),
    tutorialVideos: [{
      id: '344427852'
    }]
  });

  _exports.default = _default;
});