define("lh4/components/ui/s4/cards/notification/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="launch-control-card {{@class}} {{if @isSelected 'selected' 'default'}}"
    data-test-card={{@data-test-card}}
  >
    <div class="notification-header">
      <img src="/img/launch-control/arrow_forward.svg" />
    </div>
    <div class="notification-content">{{@title}}</div>
  
    <div class="card-content">
      <div class="card-title">
        <div class="card-title-extra">
          {{#if @required}}
            <div class="required-part">Required</div>
          {{/if}}
          {{#if @stepCount}}
            <div class="steps-part">{{@stepCount}} steps</div>
          {{/if}}
        </div>
      </div>
  
      <p class="card-subtitle">{{@subtitle}}</p>
      {{yield}}
    </div>
  </div>
  */
  {
    id: "70YfQUoX",
    block: "{\"symbols\":[\"@stepCount\",\"@isSelected\",\"@class\",\"@data-test-card\",\"@title\",\"@required\",\"@subtitle\",\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"launch-control-card \",[23,3,[]],\" \",[28,\"if\",[[23,2,[]],\"selected\",\"default\"],null]]]],[11,\"data-test-card\",[23,4,[]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"notification-header\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/img/launch-control/arrow_forward.svg\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"notification-content\"],[8],[1,[23,5,[]],false],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"card-content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-title\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card-title-extra\"],[8],[0,\"\\n\"],[4,\"if\",[[23,6,[]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"required-part\"],[8],[0,\"Required\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"steps-part\"],[8],[1,[23,1,[]],false],[0,\" steps\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"card-subtitle\"],[8],[1,[23,7,[]],false],[9],[0,\"\\n    \"],[14,8],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/cards/notification/notification.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});