define("lh4/templates/components/reputation/star-or-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RtF0XOqL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"rating\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\",true],[10,\"class\",\"icon star blue\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"strongText\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"strong\",true],[8],[1,[22,\"ratingText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[22,\"ratingText\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isRecommendation\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\",true],[11,\"class\",[29,[\"icon thumbs \",[28,\"if\",[[24,[\"isRecommended\"]],\"up\",\"down\"],null],\" blue\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reputation/star-or-like.hbs"
    }
  });

  _exports.default = _default;
});