define("lh4/mirage/fixtures/ticket-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    guid: 'df41b8e5-52de-4525-bed0-1b8cb4307fee',
    orderNumber: 2004,
    orderType: 'Dine In',
    orderDate: '2024-02-28T03:12:07.307Z',
    serverName: 'ERICA BENNETT',
    subtotal: 26.99,
    gratuityTotal: 0,
    surchargeTotal: 0,
    taxTotal: 2.16,
    discountTotal: 0,
    grandTotal: 29.15,
    items: [{
      guid: '9563c4e4-04f0-40cd-970d-761f997c3bb6',
      name: 'Yuengling',
      quantity: 1,
      unitPrice: 4,
      isWeighted: false,
      weight: null,
      total: 4,
      modifiers: [],
      taxes: [{
        guid: 'c9dbd78d-5f3f-4a57-8bfe-e4f13bc89134',
        name: 'Sales Tax',
        percent: 8,
        total: 0.32,
        isHidden: false
      }],
      surcharges: [],
      discounts: [],
      grandTotal: 4.32,
      taxTotal: 0.32
    }, {
      guid: '9bd2c6fe-e90c-40d3-8eeb-67afc5cbde7d',
      name: 'Open Food',
      quantity: 1,
      unitPrice: 10.99,
      isWeighted: false,
      weight: null,
      total: 10.99,
      modifiers: [],
      taxes: [{
        guid: '760238b6-311d-46c7-b35c-d96309c269b3',
        name: 'Sales Tax',
        percent: 8,
        total: 0.88,
        isHidden: false
      }],
      surcharges: [],
      discounts: [],
      grandTotal: 11.87,
      taxTotal: 0.88
    }, {
      guid: '55cd05b4-08a2-439a-88a7-04e11fa967d9',
      name: 'Yuengling',
      quantity: 1,
      unitPrice: 4,
      isWeighted: false,
      weight: null,
      total: 4,
      modifiers: [],
      taxes: [{
        guid: '6100edfd-97fa-48ab-8ecc-57bfe5c08e31',
        name: 'Sales Tax',
        percent: 8,
        total: 0.32,
        isHidden: false
      }],
      surcharges: [],
      discounts: [],
      grandTotal: 4.32,
      taxTotal: 0.32
    }, {
      guid: '02526811-9540-45ea-8b32-dd85d459ae5c',
      name: 'Yuengling',
      quantity: 1,
      unitPrice: 4,
      isWeighted: false,
      weight: null,
      total: 4,
      modifiers: [],
      taxes: [{
        guid: '8d33c54b-31fc-4239-a36d-8ee1819b6823',
        name: 'Sales Tax',
        percent: 8,
        total: 0.32,
        isHidden: false
      }],
      surcharges: [],
      discounts: [],
      grandTotal: 4.32,
      taxTotal: 0.32
    }, {
      guid: '05589544-b40b-40e7-9a74-4f62b361896f',
      name: 'Yuengling',
      quantity: 1,
      unitPrice: 4,
      isWeighted: false,
      weight: null,
      total: 4,
      modifiers: [],
      taxes: [{
        guid: '7fcd24ac-3dad-4e74-9489-6926cdbc1fae',
        name: 'Sales Tax',
        percent: 8,
        total: 0.32,
        isHidden: false
      }],
      surcharges: [],
      discounts: [],
      grandTotal: 4.32,
      taxTotal: 0.32
    }],
    taxes: [],
    surcharges: [],
    discounts: [],
    gratuities: [],
    payments: [{
      guid: 'b34d75d3-075d-4472-8c0d-9b778fbab025',
      paymentType: 'Credit',
      cardType: 'VISA',
      cardLast4: '7380',
      paymentAmount: 29.15,
      tipAmount: 6,
      total: 35.15,
      adjustablePaymentAmount: 29.15,
      adjustableTipAmount: 6,
      adjustableTotal: 35.15,
      cardToken: '2596bbd27d66f42f90875f5f04af3185',
      tipAdjustmentAmount: 0,
      paymentAdjustmentAmount: 0
    }],
    paymentTotal: 35.15,
    adjustablePaymentTotal: 35.15,
    totalAdjustmentAmount: 0
  };
  _exports.default = _default;
});