define("lh4/mirage/routes/internet-payments/disputes", ["exports", "lh4/mirage/fixtures/internet-payments/disputes", "lh4/enums/ip-dispute-statuses"], function (_exports, _disputes, _ipDisputeStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsDisputesServer;

  function setMirageInternetPaymentsDisputesServer(serverObject) {
    var server = serverObject;
    server.get('/internet-payments/dispute-management/:locationId/count', function (schema, request) {
      var status = request.queryParams.status;

      switch (status) {
        case _ipDisputeStatuses.DISPUTE_STATUS.NEED_RESPONSE:
          return {
            count: _disputes.DISPUTE_COUNT_FIXTURE.countNeedResponse
          };

        case _ipDisputeStatuses.DISPUTE_STATUS.AWAITING_INFO:
          return {
            count: _disputes.DISPUTE_COUNT_FIXTURE.countInReview
          };

        case _ipDisputeStatuses.DISPUTE_STATUS.WON:
          return {
            count: _disputes.DISPUTE_COUNT_FIXTURE.countWon
          };

        case _ipDisputeStatuses.DISPUTE_STATUS.LOST:
          return {
            count: _disputes.DISPUTE_COUNT_FIXTURE.countLost
          };

        default:
          return {
            count: _disputes.DISPUTE_COUNT_FIXTURE.countAll
          };
      }
    });
    server.namespace = '/api/v2';
    server.get('/internet-payments/dispute-management/:locationId/disputes', function (schema, request) {
      var status = request.queryParams.status;
      var fixture;

      switch (status) {
        case _ipDisputeStatuses.DISPUTE_STATUS.NEED_RESPONSE:
          fixture = _disputes.NEED_RESPONSE_FIXTURE;
          break;

        case _ipDisputeStatuses.DISPUTE_STATUS.AWAITING_INFO:
          fixture = _disputes.AWAITING_INFO_FIXTURE;
          break;

        case _ipDisputeStatuses.DISPUTE_STATUS.WON:
          fixture = _disputes.WON_FIXTURE;
          break;

        case _ipDisputeStatuses.DISPUTE_STATUS.LOST:
          fixture = _disputes.LOST_FIXTURE;
          break;

        default:
          fixture = _disputes.ALL_FIXTURE;
      }

      return {
        disputes: fixture.disputes
      };
    });
    server.namespace = '/api/v1';
  }
});