define("lh4/components/reservations/reservation-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    phoneNumberService: Ember.inject.service('phone-number'),

    /**
     * Used in `sortedNotificationSettings` computed property to sort notification settings by id.
     */
    sortOrdinal: {
      0: 'notification-email',
      1: 'notification-phone-number',
      2: 'notification-email-enabled',
      3: 'notification-phone-enabled'
    },
    sortedNotificationSettings: Ember.computed('notificationSettings.[]', 'sortOrdinal', function getter() {
      var notificationSettings = this.get('notificationSettings');
      var sortOrdinal = this.get('sortOrdinal');
      var sortedNotificationSettings = [];
      Object.keys(sortOrdinal).forEach(function (index) {
        var setting = notificationSettings.find(function (s) {
          return s.get('id') === sortOrdinal[index];
        });
        sortedNotificationSettings[index] = setting;
      });
      return sortedNotificationSettings;
    }),
    actions: {
      handlePhoneChange: function handlePhoneChange() {
        var notificationSettings = this.get('notificationSettings');
        var phoneNumber = notificationSettings.find(function (setting) {
          return setting.get('id') === 'notification-phone-number';
        });
        phoneNumber.set('value', this.phoneNumberService.formatPhoneNumber(phoneNumber.value));
      }
    }
  });

  _exports.default = _default;
});