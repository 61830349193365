define("lh4/models/hospitality/menu-category", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    buttonName: {
      description: 'Category Name',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    contentSource: (0, _emberCpValidations.validator)('inclusion', {
      in: ['Predefined', 'RuntimeMap']
    })
  });

  var _default = _model.default.extend(Validations, {
    locationId: (0, _attr.default)('number'),
    menuCatId: (0, _attr.default)('number'),
    buttonName: (0, _attr.default)('string'),
    contentMap: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    contentSource: (0, _attr.default)('string', {
      defaultValue: 'Predefined'
    }),
    genreId: (0, _attr.default)('number', {
      defaultValue: null
    }),
    graphic: (0, _attr.default)('string', {
      defaultValue: null
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    rowguid: (0, _attr.default)('string'),
    rvScreenCategory: (0, _attr.default)(),
    viewAsList: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    menuItemsCategories: (0, _attr.default)()
  });

  _exports.default = _default;
});