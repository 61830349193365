define("lh4/helpers/moment-utc-format", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.momentUtcFormat = momentUtcFormat;
  _exports.default = void 0;

  /**
   * Parses a moment in UTC and formats it
   * @param {Array<Object>} params - [0] - moment, [1] - format
   * @param {Object} hash - hash, if it contains format then that format will be used of params' one
   * @returns {String} - string representing the given moment in UTC and formatted with the given format
   */
  function momentUtcFormat(params, hash) {
    if (!params[0]) {
      return '';
    }

    if (hash && hash.format) {
      return _moment.default.utc(params[0], hash.format).format(params[1]);
    }

    return _moment.default.utc(params[0]).format(params[1]);
  }

  var _default = Ember.Helper.helper(momentUtcFormat);

  _exports.default = _default;
});