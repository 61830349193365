define("lh4/helpers/round-decimal-no-zeros", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundDecimalNoZeros = roundDecimalNoZeros;
  _exports.default = void 0;

  /**
   * Rounds number to max 2 decimals with no trailing zeros
   * @param {number} params - number
   * @returns {string} max 2 decimal places without trailing zeros
   */
  function roundDecimalNoZeros(value) {
    return Number((Number(value).toFixed(2) * 1).toString());
  }

  var _default = Ember.Helper.helper(roundDecimalNoZeros);

  _exports.default = _default;
});