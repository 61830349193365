define("lh4/components/customer-engagement/campaign-edit-wizard", ["exports", "jquery", "ember-uuid", "lh4/models/customer-engagement/campaign"], function (_exports, _jquery, _emberUuid, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _TYPE_DISPLAY_MESSAGE;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TYPE_DISPLAY_MESSAGE_MAPPING = (_TYPE_DISPLAY_MESSAGE = {}, _defineProperty(_TYPE_DISPLAY_MESSAGE, _campaign.CAMPAIGN_TYPES.INVITE, 'Invite to visit again'), _defineProperty(_TYPE_DISPLAY_MESSAGE, _campaign.CAMPAIGN_TYPES.WELCOME, 'Welcome new customer'), _defineProperty(_TYPE_DISPLAY_MESSAGE, _campaign.CAMPAIGN_TYPES.PROMO, 'Holidays and promotions'), _defineProperty(_TYPE_DISPLAY_MESSAGE, _campaign.CAMPAIGN_TYPES.SURVEY, 'Survey Email'), _TYPE_DISPLAY_MESSAGE);
  var TYPE_WITH_THEME = [_campaign.CAMPAIGN_TYPES.PROMO];
  var TEMPLATES_AS_THEME = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  var PRODUCT_ALLOWED_SCHEDULE_TYPE = {
    'echo-pro': [_campaign.CAMPAIGN_SCHEDULE_TYPES.RULE, _campaign.CAMPAIGN_SCHEDULE_TYPES.TIME],
    echo: [_campaign.CAMPAIGN_SCHEDULE_TYPES.RULE, _campaign.CAMPAIGN_SCHEDULE_TYPES.TIME],
    hospitality: [_campaign.CAMPAIGN_SCHEDULE_TYPES.RULE, _campaign.CAMPAIGN_SCHEDULE_TYPES.TIME],
    retail: [_campaign.CAMPAIGN_SCHEDULE_TYPES.RULE, _campaign.CAMPAIGN_SCHEDULE_TYPES.TIME],
    terminal: [_campaign.CAMPAIGN_SCHEDULE_TYPES.TIME]
  }; // eslint-disable-next-line require-jsdoc

  function selectTemplate(type, templates) {
    var TYPE_NAME_MAP = {
      Promo: 'Autumn',
      Welcome: 'Welcome New Customer',
      Invite: 'Special Offer'
    };
    return templates.findBy('name', TYPE_NAME_MAP[type] || type);
  }

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: '',
    steps: ['General', 'Settings', 'Audience', 'Design', 'Review'],
    themes: Ember.computed('templates', function getter() {
      return this.get('templates').filter(function (t) {
        return TEMPLATES_AS_THEME.includes(parseInt(t.get('id'), 10));
      });
    }),
    step: 0,
    campaign: null,
    locations: [],
    templates: [],
    isRepeatable: false,
    saveLocationSettings: false,
    selectedSettings: null,
    selectedTemplate: null,
    initialType: null,
    areThemesAvailable: Ember.computed('campaign.type', function getter() {
      var campaignType = this.get('campaign.type');
      return TYPE_WITH_THEME.some(function (type) {
        return type === campaignType;
      });
    }),
    settingsTitle: Ember.computed('campaign.type', function getter() {
      var type = this.get('campaign.type');
      return TYPE_DISPLAY_MESSAGE_MAPPING[type] || type;
    }),
    selectedLocation: Ember.computed('campaign.locationId', function getter() {
      var _this = this;

      return this.get('locations').find(function (loc) {
        return parseInt(loc.get('id'), 10) === _this.get('campaign.locationId');
      });
    }),
    scheduleTypesAvailable: Ember.computed('selectedLocation.product', function getter() {
      var product = this.get('selectedLocation.product');
      return PRODUCT_ALLOWED_SCHEDULE_TYPE[product] || PRODUCT_ALLOWED_SCHEDULE_TYPE.terminal;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.send('storeInitialType');
    },
    stepChanged: Ember.observer('step', function callback() {
      this.send('storeInitialType');
    }),
    actions: {
      themeChanged: function themeChanged(index) {
        var theme = this.get('themes').objectAt(index);
        this.send('applyTemplate', theme);
      },
      onImageSelected: function onImageSelected(blob) {
        var _this2 = this;

        var formData = new FormData();
        formData.append('photo', blob, "".concat((0, _emberUuid.v4)(), ".png"));
        this.set('isUploadingImage', true);
        this.ajax.request('/api/v1/customer-engagement/campaigns/image', {
          method: 'POST',
          data: formData,
          contentType: false,
          processData: false
        }).then(function (_ref) {
          var url = _ref.url;

          _this2.set('campaign.imageUrl', url);
        }).finally(function () {
          _this2.set('isUploadingImage', false);
        });
      },
      selectCampaignType: function selectCampaignType(type) {
        this.set('campaign.type', type);
      },
      selectCampaignScheduleTypeAndAudience: function selectCampaignScheduleTypeAndAudience(scheduleType, audience) {
        this.set('campaign.scheduleType', scheduleType);
        this.set('campaign.audience', audience);
      },
      setIsRepeatable: function setIsRepeatable(checked) {
        this.set('isRepeatable', checked);
      },
      saveAs: function saveAs(status) {
        this.sendAction('saveAs', status);
      },
      goBackward: function goBackward() {
        this.sendAction('goBackward');
      },
      goForward: function goForward() {
        if (this.get('step') === 0) {
          var type = this.get('initialType');
          var campaign = this.get('campaign');

          if (type && type !== campaign.get('type') && campaign.hasChangedSettings()) {
            return this.send('askForSettingsReset');
          }

          this.send('applyTemplate');
        }

        this.sendAction('goForward');
        return false;
      },
      discardChanges: function discardChanges() {
        this.sendAction('discardChanges');
      },
      delete: function _delete() {
        this.sendAction('delete');
      },
      resetEmailTemplate: function resetEmailTemplate() {
        this.send('applyTemplate', this.get('selectedTemplate'), true);
      },
      beforeDelete: function beforeDelete() {
        this.sendAction('beforeDelete');
      },
      askForSettingsReset: function askForSettingsReset() {
        var _this3 = this;

        (0, _jquery.default)('#clear-campaign-settings').modal({
          onApprove: function onApprove() {
            _this3.get('campaign').resetSettings();

            _this3.send('applyTemplate');

            _this3.sendAction('goForward');
          }
        }).modal('show');
      },
      applyTemplate: function applyTemplate(inTemplate) {
        var reset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var template = inTemplate;

        if (!inTemplate) {
          template = selectTemplate(this.get('campaign.type'), this.get('templates'));
        }

        if (!template) {
          return;
        }

        if (reset) {
          var _template = template,
              bodyText = _template.bodyText,
              headerText = _template.headerText,
              imageUrl = _template.imageUrl,
              backgroundColor = _template.backgroundColor;
          this.campaign.setProperties({
            bodyText: bodyText,
            headerText: headerText,
            imageUrl: imageUrl,
            backgroundColor: backgroundColor
          });
        } else {
          var campaign = this.get('campaign');
          /**
           * We check if campaign has it's own (user entered) parameter and if it's not default.
           * If it's default parameter - return template parameter.
           * Else, if it's user entered parameter - return campaign parameter.
           */

          campaign.set('imageUrl', campaign.imageUrl && !this.templates.findBy('imageUrl', campaign.imageUrl) ? campaign.imageUrl : template.imageUrl);
          campaign.set('headerText', campaign.headerText && !this.templates.findBy('headerText', campaign.headerText) ? campaign.headerText : template.headerText);
          campaign.set('bodyText', campaign.bodyText ? campaign.bodyText : template.bodyText);
          campaign.set('backgroundColor', template.get('backgroundColor'));
        }

        this.set('selectedTemplate', template);
      },
      storeInitialType: function storeInitialType() {
        this.set('initialType', this.get('campaign.type'));
      }
    }
  });

  _exports.default = _default;
});