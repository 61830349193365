define("lh4/components/disputes/search-filter-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var debounceTimeout = 500;
  var KEYCODE_BACKSPACE = 8;

  var _default = Ember.Component.extend({
    classNames: ['search-filter-controls'],
    didReceiveAttrs: function didReceiveAttrs() {
      var properties = {
        previousCardNumber: this.cardNumber,
        previousDisputeAmt: this.disputeAmt,
        previousTransAmt: this.transAmt,
        previousStatus: this.status,
        previousCaseId: this.caseId,
        cardNumberProxy: null,
        statusProxy: null,
        disputeAmtProxy: null,
        transAmtProxy: null,
        searchDebounce: null,
        cardNumberInput: null,
        caseIdProxy: null // caseId

      };

      if (this.cardNumber !== this.previousCardNumber) {
        properties.cardNumberProxy = this.cardNumber;
      }

      if (this.disputeAmt !== this.previousDisputeAmt) {
        properties.disputeAmtProxy = this.disputeAmt;
      }

      if (this.transAmt !== this.previousTransAmt) {
        properties.transAmtProxy = this.transAmt;
      }

      if (this.status !== this.previousStatus) {
        properties.statusProxy = this.status;
      }

      if (this.caseId !== this.previousCaseId) {
        properties.caseIdProxy = this.caseId;
      }

      this.setProperties(properties);
    },
    filteredFieldOptions: [{
      name: 'Status',
      value: 'status'
    }, {
      name: 'Card Brand',
      value: 'cardType'
    }, {
      name: 'Card Last 4',
      value: 'cardNumber'
    }, {
      name: 'Dispute Amt',
      value: 'disputeAmt'
    }, {
      name: 'Transaction Amt',
      value: 'transAmt'
    }, {
      name: 'Case Number',
      value: 'caseId'
    }],
    cardTypeOptions: [{
      name: 'All cards',
      value: 'all'
    }, {
      name: 'American Express',
      value: 'AMEX'
    }, {
      name: 'Discover',
      value: 'DISC'
    }, {
      name: 'MasterCard',
      value: 'MC'
    }, {
      name: 'VISA',
      value: 'VISA'
    }],
    selectedCardType: Ember.computed('cardType', function getter() {
      return this.cardType || 'all';
    }),
    updateFilters: function updateFilters(filters) {
      var _filters$cardNumber = filters.cardNumber,
          cardNumber = _filters$cardNumber === void 0 ? undefined : _filters$cardNumber,
          _filters$cardType = filters.cardType,
          cardType = _filters$cardType === void 0 ? undefined : _filters$cardType,
          _filters$disputeAmt = filters.disputeAmt,
          disputeAmt = _filters$disputeAmt === void 0 ? undefined : _filters$disputeAmt,
          _filters$status = filters.status,
          status = _filters$status === void 0 ? undefined : _filters$status,
          _filters$transAmt = filters.transAmt,
          transAmt = _filters$transAmt === void 0 ? undefined : _filters$transAmt,
          _filters$caseId = filters.caseId,
          caseId = _filters$caseId === void 0 ? undefined : _filters$caseId;
      this.onChangeSearchFilter({
        cardNumber: cardNumber,
        cardType: cardType,
        disputeAmt: disputeAmt,
        status: status,
        transAmt: transAmt,
        caseId: caseId
      });
    },
    updateStatus: function updateStatus() {
      this.updateFilters({
        status: this.statusProxy || undefined
      });
      this.set('searchDebounce', null);
    },
    updateCardNumber: function updateCardNumber() {
      this.cardNumberInput.setCustomValidity('');

      if (!this.cardNumberProxy || this.cardNumberInput && this.cardNumberInput.checkValidity()) {
        this.updateFilters({
          cardNumber: this.cardNumberProxy || undefined
        });
      } else {
        this.cardNumberInput.setCustomValidity('Please enter the last 4 card numbers');
        this.cardNumberInput.reportValidity();
      }

      this.set('searchDebounce', null);
    },
    updateDisputeAmt: function updateDisputeAmt() {
      this.updateFilters({
        disputeAmt: this.disputeAmtProxy || undefined
      });
      this.set('searchDebounce', null);
    },
    updateTransAmt: function updateTransAmt() {
      this.updateFilters({
        transAmt: this.transAmtProxy || undefined
      });
      this.set('searchDebounce', null);
    },
    updateCaseId: function updateCaseId() {
      this.updateFilters({
        caseId: this.caseIdProxy || undefined
      });
      this.set('searchDebounce', null);
    },
    actions: {
      handleChangeFilteredField: function handleChangeFilteredField(value) {
        this.onChangeFilteredField(value);
        this.set('cardNumberInput', null);
        this.updateFilters({});
      },
      handleCardTypeChange: function handleCardTypeChange(cardType) {
        var value = cardType === 'all' ? undefined : cardType;
        this.updateFilters({
          cardType: value
        });
      },
      handleStatusChange: function handleStatusChange(forceUpdate) {
        if (forceUpdate) {
          Ember.run.cancel(this.searchDebounce);
          this.updateStatus();
        } else {
          this.set('searchDebounce', Ember.run.debounce(this, this.updateStatus, debounceTimeout));
        }
      },
      handleDisputeAmtChange: function handleDisputeAmtChange(forceUpdate) {
        if (forceUpdate) {
          Ember.run.cancel(this.searchDebounce);
          this.updateDisputeAmt();
        } else {
          this.set('searchDebounce', Ember.run.debounce(this, this.updateDisputeAmt, debounceTimeout));
        }
      },
      handleTransAmtChange: function handleTransAmtChange(forceUpdate) {
        if (forceUpdate) {
          Ember.run.cancel(this.searchDebounce);
          this.updateTransAmt();
        } else {
          this.set('searchDebounce', Ember.run.debounce(this, this.updateTransAmt, debounceTimeout));
        }
      },
      handleCardNumberChange: function handleCardNumberChange(forceUpdate, value, event) {
        this.set('cardNumberInput', event.target);

        if (forceUpdate) {
          Ember.run.cancel(this.searchDebounce);
          this.updateCardNumber();
        } else {
          this.set('searchDebounce', Ember.run.debounce(this, this.updateCardNumber, debounceTimeout));
        }
      },
      handleCardNumberKeyDown: function handleCardNumberKeyDown(currentValue, event) {
        var charValue = String.fromCharCode(event.keyCode); // Check for number or backspace

        if (Number.isNaN(charValue) && event.keyCode !== KEYCODE_BACKSPACE) {
          event.preventDefault();
        }

        return true;
      },
      handleCaseIdChange: function handleCaseIdChange(forceUpdate) {
        if (forceUpdate) {
          Ember.run.cancel(this.searchDebounce);
          this.updateCaseId();
        } else {
          this.set('searchDebounce', Ember.run.debounce(this, this.updateCaseId, debounceTimeout));
        }
      },
      handleCaseIdKeyDown: function handleCaseIdKeyDown(currentValue, event) {
        var charValue = String.fromCharCode(event.keyCode); // Check for number or backspace

        if (Number.isNaN(charValue) && event.keyCode !== KEYCODE_BACKSPACE) {
          event.preventDefault();
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});