define("lh4/controllers/authenticated/location-settings", ["exports", "lh4/controllers/general-list-controller", "lh4/mixins/list-status-options"], function (_exports, _generalListController, _listStatusOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var HEADERS = [{
    field: 'name',
    name: 'Location',
    width: 'four'
  }, {
    field: 'priceGroup',
    name: 'Pricing',
    width: 'four'
  }, {
    field: 'menuStatus',
    name: 'Menu Status',
    width: 'four'
  }, {
    field: 'status',
    name: 'Status',
    tooltip: true,
    tooltipHtmlContent: '<p>The locations <strong>Status</strong> has to be set to <strong>Managed</strong> to receive the newest menu updates. If <strong>Status</strong> is set to <strong>Not Managed</strong> the location is left to work independently from master location and is allowed to edit all sections of their Lighthouse Business Manager portal.<p>',
    width: 'four'
  }];

  var _default = _generalListController.default.extend(_listStatusOptions.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    queryParams: ['statusFilter', 'searchName'],
    headers: HEADERS,
    includeList: [],
    selectionCount: 0,
    locationIds: Ember.computed('model.locations.[]', function getter() {
      return this.get('model.locations').map(function (_ref) {
        var id = _ref.id;
        return +id;
      });
    }),
    statusFilterOptions: [{
      id: 0,
      name: 'Unmanaged'
    }, {
      id: 1,
      name: 'Managed'
    }],
    locationOptions: Ember.computed('statusFilter', function getter() {
      var _this = this;

      var isAllSelected = typeof this.statusFilter === 'undefined' || this.statusFilter === null;
      return (this.statusFilterOptions || []).map(function (option) {
        return _objectSpread(_objectSpread({}, option), {}, {
          isChecked: isAllSelected || (_this === null || _this === void 0 ? void 0 : _this.statusFilter.includes(option.id)),
          isActive: true
        });
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      // reset all bulk selections when location is changed
      if ((_this$location = this.location) !== null && _this$location !== void 0 && (_this$location$model = _this$location.model) !== null && _this$location$model !== void 0 && _this$location$model.id && this.location.model.id !== this.bulkSelectLocationId) {
        this.setProperties({
          includeList: [],
          selectionCount: 0,
          bulkSelectLocationId: this.location.model.id
        });
      }
    }),
    isMultipleItemsSelected: Ember.computed('selectionCount', function getter() {
      return this.selectionCount > 1;
    }),
    routeData: Ember.computed('location.model.terminalProducts.[]', 'launchDarkly.featureFlags', function getter() {
      var routeData = [];

      if (this.can.can('view day parts in day-part', this.location.model)) {
        routeData.push({
          name: 'authenticated.location-settings',
          title: 'Location Settings'
        });
      }

      if (this.can.can('view day parts in day-part', this.location.model)) {
        routeData.push({
          name: 'authenticated.locations.location.day-parts',
          title: 'Day Parts',
          param: this.location.model.id
        });
      }

      if (this.can.can('view revenue-centers in revenue-center', this.location.model)) {
        routeData.push({
          name: 'authenticated.locations.location.revenue-centers',
          title: 'Revenue Centers',
          param: this.location.model.id
        });
      }

      return routeData;
    }),
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('searchName', search);
        this.set('shouldUpdate', true);
      },
      onRowClick: function onRowClick(record) {
        this.transitionToRoute('authenticated.location-settings.edit', record.id);
      },
      handleTermUpdated: function handleTermUpdated(searchName) {
        this.setProperties({
          offset: 0,
          searchName: searchName
        });
      },
      handleBulkSelectionChanged: function handleBulkSelectionChanged(includeList, selectionCount) {
        this.setProperties({
          includeList: includeList,
          selectionCount: selectionCount
        });
      },
      handleBulkEditClicked: function handleBulkEditClicked() {
        if (this.isMultipleItemsSelected) {
          this.transitionToRoute('authenticated.location-settings.bulk-edit');
        }
      },
      handleStatusFilterChange: function handleStatusFilterChange(selectedIds) {
        var isAllSelected = selectedIds.length > 1;
        this.setProperties({
          offset: 0,
          statusFilter: !isAllSelected ? selectedIds : undefined
        });
        this.set('shouldUpdate', true);
      }
    }
  });

  _exports.default = _default;
});