define("lh4/models/hospitality/privilege", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: make sure this is needed. If not, kill.
  var Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(Validations, {
    allowNoPin: (0, _attr.default)('boolean'),
    isSecurityDefault: (0, _attr.default)('boolean'),
    isSecurityManagement: (0, _attr.default)('boolean'),
    rowguid: (0, _attr.default)('string'),
    securityId: (0, _attr.default)(),
    securityDescription: (0, _attr.default)('string'),
    securityName: (0, _attr.default)('string'),
    securityOrdinal: (0, _attr.default)('number'),
    securityXmlName: (0, _attr.default)('string')
  });

  _exports.default = _default;
});