define("lh4/components/ui/dropdown/export", ["exports", "lh4/utils/no-op"], function (_exports, _noOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajaxDownload: Ember.inject.service(),
    formats: [],
    exportUrl: '',
    exportDefaultFileName: '',
    enabled: true,
    hasError: false,
    errorMessage: null,
    onError: _noOp.default,
    onSuccess: _noOp.default,
    actions: {
      download: function download(format) {
        var _this = this;

        var url = this.exportUrl.indexOf('?') >= 0 ? "".concat(this.exportUrl, "&format=").concat(format) : "".concat(this.exportUrl, "?format=").concat(format);
        var defaultFileName = "".concat(this.exportDefaultFileName, ".").concat(format);
        this.ajaxDownload.request(url, defaultFileName, function (e) {
          if (_this.onError) {
            _this.onError(e);
          }

          _this.set('hasError', true);
        }, function () {
          if (_this.onSuccess) {
            _this.onSuccess();
          }

          _this.set('hasError', false);
        });
      }
    }
  });

  _exports.default = _default;
});