define("lh4/abilities/skytab-order", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _permissions, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    features: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    canViewOrders: (0, _abilityMixin.computedAbility)('location.model', 'launchDarkly.featureFlags', function canViewOrders() {
      return this.hasProduct('echo-pro') && this.hasPermission(_permissions.default.SKYTAB.ACCESS) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SKYTAB_ORDERS_VISIBILITY];
    }),
    canViewKdsOrder: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewKdsOrder() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SKYTAB_KDS_ORDER_VISIBILITY];
    }),
    canViewMobileOrder: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewMobileOrder() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SKYTAB_MOBILE_ORDER_VISIBILITY];
    }),
    canViewPosBundleOrder: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewPosBundleOrder() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SKYTAB_POS_BUNDLE_ORDER_VISIBILITY];
    }),
    canViewCustomerDisplayOrder: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewCustomerDisplayOrder() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SKYTAB_CUSTOMER_FACING_DISPLAY_ORDER_VISIBILITY];
    }),
    canViewKitchenDisplayOrder: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewKitchenDisplayOrder() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.REMOTE_DOT_MATRIX_KITCHEN_PRINTER_ORDER_VISIBILITY];
    }),
    canViewThermalPrinter: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewThermalPrinter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.THERMAL_PRINTER_ORDER_VISIBILITY];
    }),
    canViewCashDrawer: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewThermalPrinter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.CASH_DRAWER_ORDER_VISIBILITY];
    })
  });

  _exports.default = _default;
});