define("lh4/components/customer-database/customer-form", ["exports", "lh4/utils/states", "lh4/utils/validate-type", "lh4/models/hospitality/customer/address", "lh4/models/hospitality/customer/email", "lh4/models/hospitality/customer/phone"], function (_exports, _states, _validateType, _address, _email, _phone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['customer-form'],
    store: Ember.inject.service(),
    locale: Ember.inject.service(),
    region: Ember.inject.service('locale/region'),
    location: Ember.inject.service(),
    launchDarkly: Ember.inject.service(),
    states: _states.STATES.map(function (_ref) {
      var state = _ref.state,
          abbr = _ref.abbr;
      return {
        name: state,
        value: abbr,
        abbr: abbr
      };
    }),
    showErrors: false,
    email: Ember.computed('customer.emails', function emailChangesetsGetter() {
      return this.customer.emailChangesets;
    }),
    phone: Ember.computed('customer.phones', function phoneChangesetsGetter() {
      return this.customer.phoneChangesets;
    }),
    address: Ember.computed('customer.address', function addressChangesetsGetter() {
      return this.customer.addressChangesets;
    }),
    validatePhoneType: function validatePhoneType() {
      (0, _validateType.default)(this.phone, _phone.phoneTypes, 'phone');
    },
    validateEmailType: function validateEmailType() {
      (0, _validateType.default)(this.email, _email.emailTypes, 'email');
    },
    validateAddressType: function validateAddressType() {
      (0, _validateType.default)(this.address, _address.addressTypes, 'address');
    },
    actions: {
      changeProperty: function changeProperty(record, value, property) {
        record.set(property, value);
        this.validateEmailType();
        this.validatePhoneType();
        this.validateAddressType();
      },
      // Phones
      addNumber: function addNumber() {
        var newPhoneRecord = this.customer.data.addPhone(this.customer.data);
        var changeset = this.buildChildChangeset(newPhoneRecord);
        this.customer.phoneChangesets.pushObject(changeset);
      },
      deleteNumber: function deleteNumber(record) {
        this.customer.phoneChangesets.removeObject(record);
        this.validatePhoneType();
      },
      // Emails
      addEmail: function addEmail() {
        var newEmailRecord = this.customer.data.addEmail(this.customer.data);
        var changeset = this.buildChildChangeset(newEmailRecord);
        this.customer.emailChangesets.pushObject(changeset);
      },
      deleteEmail: function deleteEmail(record) {
        this.customer.emailChangesets.removeObject(record);
        this.validateEmailType();
      },
      // Address
      addAddress: function addAddress() {
        var newAddressRecord = this.customer.data.addAddress(this.customer.data);
        var changeset = this.buildChildChangeset(newAddressRecord);
        this.customer.addressChangesets.pushObject(changeset);
      },
      deleteAddress: function deleteAddress(record) {
        this.customer.addressChangesets.removeObject(record);
        this.validateAddressType();
      }
    }
  });

  _exports.default = _default;
});