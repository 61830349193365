define("lh4/routes/authenticated/premium/customer-engagement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    titleToken: 'Customer Engagement',
    redirect: function redirect() {
      if (this.can.can('manage campaigns for customer-engagement')) {
        this.transitionTo('authenticated.customer-engagement.campaigns');
      }
    }
  });

  _exports.default = _default;
});