define("lh4/routes/authenticated/customer-engagement", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/utils/permissions"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    titleToken: 'Customer Engagement',
    requiredPermissions: 'manage campaigns for customer-engagement',
    permissions: Ember.inject.service(),
    permissionCheckFailed: function permissionCheckFailed() {
      return this.replaceWith('authenticated.premium.customer-engagement');
    },
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations.filter(function (location) {
          return _this.permissions.hasService('lighthouse-premium', location);
        }).filter(function (location) {
          return _this.permissions.hasPermission(_permissions.default.CUSTOMER_ENGAGEMENT.ACCESS, location.id);
        })
      });
    }
  });

  _exports.default = _default;
});