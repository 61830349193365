define("lh4/components/dashboard-widgets/summary-bar-chart-card/data", ["exports", "moment", "lh4/mixins/dashboard-widget-mixin", "ember-changeset", "lh4/enums/ip-date-ranges"], function (_exports, _moment, _dashboardWidgetMixin, _emberChangeset, _ipDateRanges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var COMING_IN_COLOR = '#0E5BF3';
  var RESERVE_COLOR = '#88B7FA';
  var GOING_OUT_COLOR = '#FF5625';
  var WEEKLY_RANGES = [_ipDateRanges.default.THIS_WEEK.VALUE, _ipDateRanges.default.LAST_WEEK.VALUE];

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    locale: Ember.inject.service(),
    router: Ember.inject.service(),
    media: Ember.inject.service(),
    sessionStorage: Ember.inject.service(),
    widgetSlug: 'summary-bar-chart-card',
    classNames: ['summary-bar-chart-card', 'table-widget'],
    isShowBatchTray: false,
    chartData: Ember.computed('data.series', 'isWeeklyRangeSelected', function chartData() {
      var _this = this;

      return this.get('data.series').map(function (_ref) {
        var periodName = _ref.periodName,
            comingIn = _ref.comingIn,
            reserve = _ref.reserve,
            goingOut = _ref.goingOut;
        return {
          periodName: _this.isWeeklyRangeSelected ? (0, _moment.default)(periodName).format(_moment.default.HTML5_FMT.DATE) : periodName,
          comingIn: parseFloat(comingIn),
          reserve: parseFloat(reserve),
          goingOut: parseFloat(goingOut)
        };
      });
    }),
    selectedRange: Ember.computed(function selectedRange() {
      var sessionStorageData = this.sessionStorage.get(this.widgetSlug);
      return sessionStorageData ? sessionStorageData === null || sessionStorageData === void 0 ? void 0 : sessionStorageData.range : _ipDateRanges.default.THIS_WEEK.VALUE;
    }),
    isWeeklyRangeSelected: Ember.computed('selectedRange', function isWeeklyRangeSelected() {
      return WEEKLY_RANGES.includes(this.selectedRange);
    }),
    categoryAxisOptions: {
      centerLabelOnFullPeriod: true,
      gridColor: '#FFFFFF',
      fillAlpha: 1,
      gridAlpha: 1,
      minorGridAlpha: 1,
      tickLength: 1
    },
    categoryAxis: Ember.computed('isWeeklyRangeSelected', function categoryAxis() {
      if (this.isWeeklyRangeSelected) {
        return _objectSpread(_objectSpread({}, this.categoryAxisOptions), {}, {
          equalSpacing: false,
          parseDates: true,
          minPeriod: 'DD',
          autoGridCount: false,
          gridCount: 7,
          showFirstLabel: false,
          dateFormats: [{
            period: 'DD',
            format: 'DD'
          }, {
            period: 'MM',
            format: 'MMM'
          }]
        });
      }

      return this.categoryAxisOptions;
    }),
    rangeOptions: [_ipDateRanges.default.THIS_WEEK, _ipDateRanges.default.LAST_WEEK, _ipDateRanges.default.THIS_MONTH, _ipDateRanges.default.LAST_MONTH],
    chartOptions: Ember.computed('location', function chartOptions() {
      var valueTemplate = this.locale.currencyTemplate('[[value]]');
      return {
        categoryField: 'periodName',
        dataDateFormat: this.isWeeklyRangeSelected ? _moment.default.HTML5_FMT.DATE : null,
        parseDates: !!this.isWeeklyRangeSelected,
        valueAxesOptions: {
          labelFrequency: 2,
          usePrefixes: true,
          minorGridAlpha: 0,
          position: 'right'
        },
        categoryAxis: this.categoryAxis,
        colors: [COMING_IN_COLOR, RESERVE_COLOR, GOING_OUT_COLOR],
        graphs: [{
          id: 'g1',
          balloonText: valueTemplate,
          title: 'First',
          valueField: 'comingIn',
          type: 'column',
          fillAlphas: 1
        }, {
          id: 'g2',
          balloonText: valueTemplate,
          title: 'Second',
          valueField: 'reserve',
          type: 'column',
          fillAlphas: 1
        }, {
          id: 'g3',
          balloonText: valueTemplate,
          title: 'Third',
          valueField: 'goingOut',
          type: 'column',
          fillAlphas: 1
        }]
      };
    }),
    actions: {
      onRangeSelect: function onRangeSelect(range) {
        this.setSettings(new _emberChangeset.default({
          range: range
        }));
        this.sessionStorage.update(this.widgetSlug, {
          range: range
        });
      },
      onOpenBatchTray: function onOpenBatchTray() {
        this.set('isShowBatchTray', true);
      },
      onCloseBatchTray: function onCloseBatchTray() {
        this.set('isShowBatchTray', false);
      }
    }
  });

  _exports.default = _default;
});