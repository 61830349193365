define("lh4/components/ui/form-fields/checkbox-list", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _field.default.extend({
    dataTest: 'form-field-checkbox-list',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var changeset = this.changeset,
          property = this.property;
      var propertyString = "changeset.".concat(property, ".[]"); // redefine this property on every render & re-render, as clicking on checkbox doesn't do anything after
      // initial render was performed and changeset was changed in route when 'Save & duplicate'
      // (cloning) is performed or another edit tray was opened

      Ember.defineProperty(this, 'selected', Ember.computed(propertyString, function () {
        return _toConsumableArray(changeset.get(property));
      }));
    },
    // It's possible in the future we might want to add an additional check
    // here. But currently the way we're using this field with our models
    // is mainly grouping properties for user convenience.
    hasChildError: Ember.computed('showErrors', 'changeset.error', function getter() {
      var _this = this;

      if (!this.showErrors || this.changeset && this.changeset.get('errors') && this.changeset.get('errors').length === 0) {
        return false;
      }

      if (this.items.find(function (item) {
        return _this.changeset.get("error.".concat(item.id));
      })) {
        return true;
      }

      return false;
    }),
    hasError: Ember.computed('hasChildError', 'changeset.error', function getter() {
      if (this.hasChildError) {
        return true;
      }

      return this._super.apply(this, arguments);
    }),
    showErrorTooltip: Ember.computed('hasError', 'hasChildError', 'changeset.error', function getter() {
      if (this.hasChildError) {
        return true;
      }

      return this._super.apply(this, arguments);
    }),
    childErrorMessages: Ember.computed('hasChildError', 'changeset.error', function getter() {
      var _this2 = this;

      if (!this.hasChildError) {
        return [];
      }

      return this.changeset.get('errors').filter(function (_ref) {
        var key = _ref.key;
        return _this2.items.find(function (_ref2) {
          var id = _ref2.id;
          return id === key;
        });
      }).map(function (_ref3) {
        var validation = _ref3.validation;
        return validation;
      });
    }),
    errorMessageContent: Ember.computed('showErrorTooltip', 'childErrorMessages', 'changeset.error', function getter() {
      // If we're showing an error message for the collection property
      // or a single child error we'll just add the text.
      // If there are multiple child error messages the template will loop
      // over them.
      if (!this.showErrorTooltip) {
        return null;
      }

      if (this.childErrorMessages && this.childErrorMessages.length === 1) {
        return this.childErrorMessages[0];
      }

      if (this.childErrorMessages && this.childErrorMessages.length === 0) {
        return this.changeset.get("error.".concat(this.property, ".validation"));
      }

      return null;
    }),
    checkboxItems: Ember.computed('items.[]', 'selected.[]', function getter() {
      var _this3 = this;

      return this.items.map(function (_ref4) {
        var disabled = _ref4.disabled,
            id = _ref4.id,
            name = _ref4.name;
        return {
          disabled: disabled,
          id: id,
          name: name,
          isChecked: _this3.selected.includes(id)
        };
      });
    }),
    actions: {
      handleCheck: function handleCheck(id, checked) {
        var selected = _toConsumableArray(this.selected);

        if (checked) {
          selected.pushObject(id);
        } else {
          selected.removeObject(id);
        }

        if (this.onChange) {
          this.onChange(selected);
        } else {
          this.changeset.set(this.property, selected);
        }
      },
      selectAllItems: function selectAllItems() {
        var selected = _toConsumableArray(this.selected);

        if (selected.length === this.items.length) {
          selected.clear();
          this.changeset.set(this.property, selected);
        } else {
          this.items.map(function (item) {
            if (!selected.includes(item.id)) {
              selected.push(item.id);
            }

            return selected;
          });
          this.changeset.set(this.property, selected);
        }
      }
    }
  });

  _exports.default = _default;
});