define("lh4/routes/authenticated/locations/location/hospitality/manage-employees/create", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      return this.store.query('hospitality/privilege', {
        location: location.id
      }).then(function () {
        return _this.store.peekAll('hospitality/privilege');
      }).then(function (permSet) {
        var permissions = Ember.A([]);
        var perms = Ember.A(permSet.toArray().sort(function (a, b) {
          if (a.get('securityName').trim() < b.get('securityName').trim()) {
            return -1;
          }

          if (a.get('securityName').trim() > b.get('securityName').trim()) {
            return 1;
          }

          return 0;
        }));
        perms.forEach(function (x) {
          permissions.push(Ember.Object.create({
            isAllowed: false,
            securityId: x.get('securityId'),
            securityName: x.get('securityName')
          }));
        });
        return _this.store.createRecord('hospitality/employee', {
          locationId: location.id,
          isActive: true,
          employeesPrivileges: permissions
        });
      }).then(function (employee) {
        var model = {
          employee: employee,
          job: _this.store.query('hospitality/job', {
            location: location.id
          }).then(function () {
            return _this.store.peekAll('hospitality/job');
          })
        };
        return Ember.RSVP.hash(model);
      });
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('hospitality/job');
    }
  });

  _exports.default = _default;
});