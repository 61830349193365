define("lh4/utils/is-positive-decimal-or-zero", ["exports", "lh4/utils/is-positive-decimal"], function (_exports, _isPositiveDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Validate if given value is positive decimal with precision of parameter passed in or equal to 0
   *
   * @param {string|number} value - value to validate
   * @param {number} precision - (Default value 2) value to calculate precision by
   * @returns {boolean} - true if value is decimal
   */
  var isPositiveDecimalOrZero = function isPositiveDecimalOrZero(value) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return parseFloat(value) === 0 || (0, _isPositiveDecimal.default)(value, precision);
  };

  var _default = isPositiveDecimalOrZero;
  _exports.default = _default;
});