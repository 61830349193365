define("lh4/components/ui/risk-block-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#internet-payments/risk/risk-block-list-icon-tooltip
  	dataStatus=@status
  	dataName=@name
  }}
  	{{#if (can 'manage risk block list in internet-payment')}}
  		<button class="ui button blue filter-button no-border" {{on 'click' @onBlockListClick}}>
  			Manage Blocked Data
  		</button>
  	{{/if}}
  {{/internet-payments/risk/risk-block-list-icon-tooltip}}
  
  */
  {
    id: "4rdVF4ez",
    block: "{\"symbols\":[\"@onBlockListClick\",\"@name\",\"@status\"],\"statements\":[[4,\"internet-payments/risk/risk-block-list-icon-tooltip\",null,[[\"dataStatus\",\"dataName\"],[[23,3,[]],[23,2,[]]]],{\"statements\":[[4,\"if\",[[28,\"can\",[\"manage risk block list in internet-payment\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui button blue filter-button no-border\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,1,[]]]],[8],[0,\"\\n\\t\\t\\tManage Blocked Data\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/risk-block-tooltip.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});