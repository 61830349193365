define("lh4/services/social-media/twitter", ["exports", "jquery", "lh4/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TWITTER_STATUS_SUSPENDED_ACCOUNT = _exports.TWITTER_STATUS_INVALIDATED_TOKEN = _exports.TWITTER_STATUS_IS_LINKED = _exports.ANALYTICS_FIELDS = _exports.ACCOUNT_TYPE_TWITTER = _exports.POST_MAX_LENGTH = _exports.default = void 0;
  var TWITTER_AUTH_URL = 'https://api.twitter.com/oauth/authorize';
  var LOCAL_STORAGE_SOCIAL_MEDIA_TYPE = 'social-media-type';
  var ACCOUNT_TYPE_TWITTER = 'twitter';
  _exports.ACCOUNT_TYPE_TWITTER = ACCOUNT_TYPE_TWITTER;
  var POST_MAX_LENGTH = 280;
  _exports.POST_MAX_LENGTH = POST_MAX_LENGTH;
  var ANALYTICS_FIELDS = ['likes', 'shares'];
  _exports.ANALYTICS_FIELDS = ANALYTICS_FIELDS;
  var TWITTER_STATUS_IS_LINKED = 'IS_LINKED';
  _exports.TWITTER_STATUS_IS_LINKED = TWITTER_STATUS_IS_LINKED;
  var TWITTER_STATUS_INVALIDATED_TOKEN = 'INVALIDATED_TOKEN';
  _exports.TWITTER_STATUS_INVALIDATED_TOKEN = TWITTER_STATUS_INVALIDATED_TOKEN;
  var TWITTER_STATUS_SUSPENDED_ACCOUNT = 'SUSPENDED_ACCOUNT';
  /* eslint-disable camelcase */

  _exports.TWITTER_STATUS_SUSPENDED_ACCOUNT = TWITTER_STATUS_SUSPENDED_ACCOUNT;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    accountApi: Ember.inject.service('social-media/account-api'),

    /**
     * Redirect to twitter to get oauth_token that will be exchanged for access token
     *
     * @param {object} [account] - use if relinking an existing twitter account
     * @returns {Promise} redirects to twitter on fulfill
     */
    redirectToAuth: function redirectToAuth(account) {
      window.localStorage.setItem(LOCAL_STORAGE_SOCIAL_MEDIA_TYPE, ACCOUNT_TYPE_TWITTER);

      if (account) {
        this.accountApi.setRedirectOrigin(account.get('id'));
      }

      return this.ajax.request("api/v1/social-media/accounts/".concat(ACCOUNT_TYPE_TWITTER, "/token"), {
        type: 'GET',
        data: {
          branding: _environment.default.branding.name,
          accountId: account ? account.get('id') : null
        }
      }).then(function (response) {
        var qs = _jquery.default.param({
          oauth_token: response.oauth_token
        });

        window.location.replace("".concat(TWITTER_AUTH_URL, "?").concat(qs));
      });
    },

    /**
     * Link account with back-end
     * @param {{name: string, locations: Array<object>}} account - account object
     * @param {string} oauth_token - twitter oauthToken
     * @param {string} oauth_verifier - twitter oauth token verifier
     * @returns {Promise<{response, accountType}>} result of HTTP request
     */
    linkAccount: function linkAccount(account, oauth_token, oauth_verifier) {
      var accountType = window.localStorage.getItem(LOCAL_STORAGE_SOCIAL_MEDIA_TYPE);

      if (!accountType) {
        return Ember.RSVP.Promise.reject('Could not determine account type, please try adding account again');
      }

      return this.ajax.request("/api/v1/social-media/accounts/link/".concat(accountType), {
        headers: {
          'Content-Type': 'application/json'
        },
        type: 'POST',
        data: JSON.stringify({
          oauth_token: oauth_token,
          oauth_verifier: oauth_verifier,
          name: account.name,
          locations: account.locations.map(function (location) {
            return location.id;
          }),
          branding: _environment.default.branding.name
        })
      }).then(function (response) {
        return {
          response: response,
          accountType: accountType
        };
      });
    },

    /**
     * Relink existing twitter account with back-end
     * @param {number} accountId - sm account id
     * @param {string} oauth_token - twitter oauthToken
     * @param {string} oauth_verifier - twitter oauth token verifier
     * @returns {Promise} - social media account
     */
    relinkAccount: function relinkAccount(accountId, oauth_token, oauth_verifier) {
      return this.ajax.request("/api/v1/social-media/accounts/".concat(accountId, "/relink"), {
        type: 'POST',
        data: JSON.stringify({
          oauth_token: oauth_token,
          oauth_verifier: oauth_verifier
        })
      });
    },

    /**
     * Transform redirect URI in hash history environment
     *
     * actual redirect uri
     * https://lh.harbortouch.com/?oauth_token=a1cd&oauth_verifier=a2cd#/social-media/accounts/link?state=203
     * 'state' is only present when relinking an account
     * notice how twitter returns two '?', resulting in conflicting query params
     * @param {object} location - window location
     * @returns {string} transformed redirect uri
     */
    adjustRedirectUrlHash: function adjustRedirectUrlHash(location) {
      if (_environment.default.locationType === 'hash') {
        if (location.hash.includes('?state=')) {
          // get ?state=203
          var accId = location.hash.match(/\?state=\d+/g)[0]; // remove state from rest of hash #/social-media/accounts/link

          var hashWithoutState = location.hash.replace(accId, ''); // ?state=203 > &state=203

          var accIdQP = accId.replace('?', '&');
          return "".concat(location.origin).concat(hashWithoutState).concat(location.search).concat(accIdQP);
        }

        return "".concat(location.origin).concat(location.hash).concat(location.search);
      }

      return false;
    }
  });
  /* eslint-enable camelcase */


  _exports.default = _default;
});