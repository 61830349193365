define("lh4/mirage/factories/social-media-account", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: function name() {
      return "".concat(_faker.default.name.firstName(), " ").concat(_faker.default.name.lastName());
    },
    externalId: function externalId() {
      return _faker.default.datatype.uuid();
    },
    type: 'twitter',
    pictureUri: 'http://fakeurl.com/',
    externalAccount: 'werasdfasfd',
    locations: [],
    linkStatus: 'test',
    warningMessage: 'test',
    externalName: 'indeed',
    isActive: function isActive() {
      return _faker.default.datatype.boolean();
    },
    isConfiguredProperly: function isConfiguredProperly() {
      return _faker.default.datatype.boolean();
    }
  });

  _exports.default = _default;
});