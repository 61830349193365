define("lh4/templates/components/2fa/setup-intro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LMhbeBuF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"Download Two-Factor Application\"],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n\\tPlease download and install Two-Factor application. We recommend \"],[7,\"strong\",true],[8],[0,\"Google Authenticator\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-links\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"app-link android\"],[8],[0,\"\\n\\t\\t\"],[7,\"a\",true],[10,\"href\",\"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/img/store/google-play.png\"],[10,\"width\",\"180px\"],[10,\"alt\",\"Google Play icon\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"app-link ios\"],[8],[0,\"\\n\\t\\t\"],[7,\"a\",true],[10,\"href\",\"https://itunes.apple.com/us/app/google-authenticator/id388497605\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/img/store/app-store.svg\"],[10,\"width\",\"150px\"],[10,\"alt\",\"Apple Store icon\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/2fa/setup-intro.hbs"
    }
  });

  _exports.default = _default;
});