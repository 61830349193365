define("lh4/templates/components/customer-engagement/overview-stat-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cReOPl+b",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui center aligned segment\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui mini statistic\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"value \",[23,1,[\"className\"]]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"value\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"sub-stats\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"percentage\",[[23,1,[\"value\"]],[24,[\"sent\"]]],null],false],[0,\"%\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/customer-engagement/overview-stat-bar.hbs"
    }
  });

  _exports.default = _default;
});