define("lh4/models/echo-pro/terminal", ["exports", "ember-data", "ember-data/relationships", "ember-cp-validations", "moment"], function (_exports, _emberData, _relationships, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STATUS_OFFLINE = _exports.STATUS_NEVER_SYNCED = _exports.STATUS_ONLINE = _exports.default = void 0;
  var STATUS_ONLINE = 'Online';
  _exports.STATUS_ONLINE = STATUS_ONLINE;
  var STATUS_NEVER_SYNCED = 'Never Synced';
  _exports.STATUS_NEVER_SYNCED = STATUS_NEVER_SYNCED;
  var STATUS_OFFLINE = 'Offline';
  _exports.STATUS_OFFLINE = STATUS_OFFLINE;
  var OFFLINE_TIME_LIMIT_IN_MINUTES = 180;
  var blacklistedHardwareIds = ['demo', 'unknown'];
  var Validations = (0, _emberCpValidations.buildValidations)({
    hardwareId: {
      description: 'Hardware ID',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Please enter serial number'
      }), (0, _emberCpValidations.validator)(function (value) {
        if (blacklistedHardwareIds.some(function (id) {
          return value.toLowerCase().includes(id);
        })) {
          return 'Unable to create device, please contact support';
        }

        return true;
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    locationId: _emberData.default.attr('number'),
    terminalNumber: _emberData.default.attr('number'),
    terminalName: _emberData.default.attr('string'),
    isVirtualTerminal: _emberData.default.attr('boolean'),
    isBrain: _emberData.default.attr('boolean'),
    product: _emberData.default.attr('string'),
    softwareVersion: _emberData.default.attr('string'),
    hardwareId: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isRegistered: _emberData.default.attr('boolean'),
    timezone: _emberData.default.attr('string'),
    lastCalledAt: _emberData.default.attr('date'),
    isMobile: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    printers: (0, _relationships.hasMany)('echo-pro/printer'),
    statusDisplay: Ember.computed('lastCalledAt', function getter() {
      var lastCalledAt = this.get('lastCalledAt');

      if (!lastCalledAt || !this.get('isRegistered')) {
        return STATUS_NEVER_SYNCED;
      }

      if ((0, _moment.default)().diff((0, _moment.default)(lastCalledAt), 'minutes') > OFFLINE_TIME_LIMIT_IN_MINUTES) {
        return STATUS_OFFLINE;
      }

      return STATUS_ONLINE;
    })
  });

  _exports.default = _default;
});