define("lh4/components/ui/form-fields/checkbox-single", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    dataTest: 'form-field-checkbox-single',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var changeset = this.changeset,
          property = this.property;
      var propertyString = "changeset.".concat(property); // redefine this property on every render & re-render, as clicking on checkbox doesn't do anything after
      // initial render was performed and changeset was changed in route when 'Save & duplicate'
      // (cloning) is performed or another edit tray was opened

      Ember.defineProperty(this, 'isChecked', Ember.computed(propertyString, function () {
        return changeset.get(property);
      }));
    },
    actions: {
      onClick: function onClick(event) {
        if (this.disabled) {
          event.stopPropagation();
          return;
        }

        if ((event === null || event === void 0 ? void 0 : event.target) instanceof HTMLAnchorElement) {
          event.stopPropagation();
          return;
        }

        if (this.onChange) {
          this.onChange(event);
        } else {
          this.changeset.set(this.property, !this.isChecked);
        }
      }
    }
  });

  _exports.default = _default;
});