define("lh4/services/ajax", ["exports", "ember-ajax/services/ajax", "lh4/config/environment", "jquery"], function (_exports, _ajax, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ignoreUnauthorized = function ignoreUnauthorized(url) {
    return url.endsWith('/change-password');
  };

  var _default = _ajax.default.extend({
    host: _environment.default.host,
    session: Ember.inject.service(),
    contentType: 'application/json',
    headers: Ember.computed('session.data', 'session.isAuthenticated', 'session.ownerToken', {
      get: function get() {
        var headers = {};

        if (this.session.isAuthenticated) {
          headers['X-Access-Token'] = this.session.data.authenticated.token;
        }

        if (this.session.ownerToken) {
          headers['x-verified-owner-token'] = this.session.ownerToken;
        }

        return headers;
      }
    }),
    handleResponse: function handleResponse(status, meta, error, request) {
      // if we get back a 401, then we should invalidate the session
      if (status === 401 && this.get('session.isAuthenticated') && !ignoreUnauthorized(request.url)) {
        this.session.invalidate();
      }

      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      return this._super.apply(this, [status, meta, error, request].concat(args));
    },
    getBlob: function getBlob(url) {
      // @see https://github.com/ember-cli/ember-ajax/issues/321
      return _jquery.default.ajax(url, {
        type: 'GET',
        dataType: 'blob',
        xhr: function xhr() {
          var myXhr = _jquery.default.ajaxSettings.xhr();

          myXhr.responseType = 'blob';
          return myXhr;
        }
      });
    }
  });

  _exports.default = _default;
});