define("lh4/serializers/dashboard/processing/batch-detail", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return this._super("processing/".concat(payloadKey));
    },
    attrs: {
      batches: {
        embedded: 'always'
      },
      transactions: {
        embedded: 'always'
      },
      deposits: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});