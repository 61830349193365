define("lh4/components/reports/report-table-drill-down", ["exports", "lh4/classes/tree/report-tree"], function (_exports, _reportTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tree: Ember.computed('currentReport', function tree() {
      return new _reportTree.default(this.currentReport.trees);
    }),
    actions: {
      trackReportDownload: function trackReportDownload(type) {
        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'reports',
          eventAction: 'download',
          eventLabel: "".concat(this.get('enabledProd'), " - ").concat(this.get('reportType'), " - ").concat(type)
        });
      }
    }
  });

  _exports.default = _default;
});