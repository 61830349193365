define("lh4/metrics-adapters/datadog-rum", ["exports", "ember-metrics/metrics-adapters/base", "@datadog/browser-rum", "lh4/config/environment", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _base, _browserRum, _environment, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var SHIFT4_EMAIL_DOMAINS = ['futurepos.com', 'harbortouch.com', 'rmpos.com', 'unitedbankcard.com', 'venuenext.com', 'custcon.com', 'shift4.com', '3dcart.com', 'positouch.com', 'microsnyc.com', 'duetpos.com', 'futurepos.com', 'lighthousenetwork.com', 'merchantlink.com', 'venuenext.com', 'custcon.com', 'postec.com', 'revelsystems.com'];

  var _default = _base.default.extend({
    location: Ember.inject.service(),
    session: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    env: _environment.default,

    get isDatadogEnabled() {
      var ddEnabled = this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.LBM_TRACK_USAGE_WITH_DATADOG];
      return ddEnabled;
    },

    toStringExtension: function toStringExtension() {
      return 'datadog-rum';
    },
    getBaseApiUrl: function getBaseApiUrl() {
      if (_environment.default.host) {
        return "".concat(_environment.default.host, "/api");
      }

      return "".concat(window.location.protocol, "//").concat(window.location.host, "/api");
    },
    init: function init() {
      var _this = this;

      var _this$env = this.env,
          environment = _this$env.environment,
          datadogParams = _this$env.datadogParams;

      if (['staging', 'production'].includes(environment)) {
        _browserRum.datadogRum.init(_objectSpread(_objectSpread({}, datadogParams), {}, {
          allowedTracingUrls: [function (url) {
            var baseApiUrl = _this.getBaseApiUrl();

            return url.startsWith(baseApiUrl);
          }],
          beforeSend: function beforeSend() {
            if (!_this.isDatadogEnabled) {
              return false;
            }

            return true;
          }
        }));
      }
    },
    identify: function identify() {
      var _this$session$data;

      var user = (_this$session$data = this.session.data) === null || _this$session$data === void 0 ? void 0 : _this$session$data.authenticated;

      if (user !== null && user !== void 0 && user.aId) {
        var isShift4Email = SHIFT4_EMAIL_DOMAINS.some(function (domain) {
          return user.email.toLowerCase().endsWith("@".concat(domain));
        });

        _browserRum.datadogRum.setUser({
          id: user.aId,
          email: user.email,
          name: "".concat(user.firstName, " ").concat(user.lastName),
          admin: !user.parentUserId,
          createdAt: new Date(user.userSince * 1000).toISOString(),
          internal: this.session.isSSO || isShift4Email
        });
      }
    },
    logout: function logout() {
      if (this.isDatadogEnabled) {
        _browserRum.datadogRum.stopSession();
      }
    },
    addContext: function addContext(data) {
      _browserRum.datadogRum.setGlobalContextProperty('device', data);
    },
    trackEvent: function trackEvent(_ref) {
      var event = _ref.event,
          data = _objectWithoutProperties(_ref, ["event"]);

      // in case the refresh/relogin loses user session
      var currentUser = _browserRum.datadogRum.getUser();

      if (!currentUser.id) {
        this.identify();
      }

      if (this.isDatadogEnabled) {
        _browserRum.datadogRum.addAction(event, data);
      }
    },
    trackPage: function trackPage(pageData) {
      var currentUser = _browserRum.datadogRum.getUser();

      if (!currentUser.id) {
        this.identify();
      }

      if (this.isDatadogEnabled) {
        // this manually starts a page view
        _browserRum.datadogRum.startView({
          name: pageData.title.replace('authenticated.', '').replace('.index', '')
        });
      }
    },
    alias: function alias() {},
    willDestroy: function willDestroy() {}
  });

  _exports.default = _default;
});