define("lh4/controllers/register", ["exports", "jquery", "lh4/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    queryParams: ['token'],
    actions: {
      register: function register() {
        var _this = this;

        if (this.get('firstName') && this.get('lastName') && this.get('email') && this.get('password') && this.get('confirmPassword')) {
          if (this.get('confirmPassword') === this.get('password')) {
            this.set('registerLoading', true);

            _jquery.default.ajax("".concat(_environment.default.host, "/api/v1/auth/register"), {
              method: 'POST',
              data: JSON.stringify({
                token: this.get('token'),
                emailAddress: this.get('email'),
                password: this.get('password'),
                firstName: this.get('firstName'),
                middleName: !this.get('middleName') ? null : this.get('middleName'),
                lastName: this.get('lastName'),
                phoneNumber: !this.get('phoneNumber') ? null : this.get('phoneNumber')
              }),
              headers: {
                'Content-Type': 'application/json'
              },
              success: function success() {
                _this.set('registerLoading', false);

                _this.flashMessages.success('Registration succeeded. You will now be redirected to sign in.', {
                  class: 'ui success message'
                });

                Ember.run.later(function () {
                  _this.transitionToRoute('sign-in');
                }, 1500);
              },
              error: function error(e) {
                _this.set('registerLoading', false);

                _this.flashMessages.danger("Error: ".concat(e.responseJSON.error.message), {
                  class: 'ui error message'
                });
              }
            });
          } else {
            this.flashMessages.danger('Error: Passwords do not match!', {
              class: 'ui error message'
            });
          }
        } else {
          this.set('showErrors', true);
        }
      }
    }
  });

  _exports.default = _default;
});