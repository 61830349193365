define("lh4/controllers/authenticated/locations/location/hospitality/choices-and-choice-sets/set/edit-set/choice/create-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROUTE_CHOICE_SETS = 'authenticated.locations.location.hospitality.choices-and-choice-sets';

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    logger: Ember.inject.service(),
    modifierSetGuid: null,
    isLoading: false,
    setLoading: Ember.computed('isLoading', function getter() {
      return this.get('isLoading') ? 'loading' : '';
    }),
    tabs: ['Choice Details', 'Choice Sets'],
    actions: {
      save: function save() {
        var _this = this;

        this.flashMessages.clearMessages();
        this.set('isLoading', true);
        this.get('model.newChoice').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.get('model.newChoice').save().then(function (choice) {
              _this.flashMessages.success('Choice saved successfully!', {
                class: 'ui success message'
              });

              _this.logger.log('Choice saved successfully!');

              _this.set('isLoading', false);

              _this.transitionToRoute("".concat(ROUTE_CHOICE_SETS, ".set.edit-set.choice.edit-choice"), choice.get('rowguid'));
            }, function (err) {
              _this.set('isLoading', false);

              _this.logger.log("".concat(err, " error occurred when saving item"));

              _this.flashMessages.danger('Error connecting to terminal. Please make sure your unit is online.', {
                class: 'ui error message'
              });
            });
          } else {
            return Ember.RSVP.Promise.reject(validations);
          }

          return false;
        }).catch(function (validations) {
          _this.set('isLoading', false);

          validations.forEach(function (v) {
            if (v.get('message')) {
              _this.flashMessages.danger("".concat(v.get('message'), "."), {
                class: 'ui negative message'
              });
            }
          });
        });
      },
      clear: function clear() {
        this.get('model.newChoice').destroyRecord();
        this.transitionToRoute(ROUTE_CHOICE_SETS);
      }
    }
  });

  _exports.default = _default;
});