define("lh4/models/hospitality/job", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    basePay: (0, _emberCpValidations.validator)('presence', true),
    overtimePayRate: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    basePay: (0, _attr.default)('number'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isBlind: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    ordinal: (0, _attr.default)('number', {
      defaultValue: null
    }),
    areaSetId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    screenCategorySetId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    closesCashAutomatically: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    allowsFastPay: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    overrideAutoLogout: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isDishwasher: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    overtimePayRate: (0, _attr.default)('number'),
    discountSetId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    groupId: (0, _attr.default)('number', {
      defaultValue: null
    }),
    lastClosedMode: (0, _attr.default)('string', {
      defaultValue: 'open'
    }),
    allTicketsBehavior: (0, _attr.default)('string', {
      defaultValue: 'cashier'
    }),
    hidePreauths: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    payrollDeptId: (0, _attr.default)('string', {
      defaultValue: null
    }),
    role: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    isBartender: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    canClockout: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    allowDelivery: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    canTransferByBarcode: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    printsPacketOnTransfer: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    restrictsAllTicketsSection: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    defaultSection: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    revenueCenterId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    doesNotDeclareTips: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    seeAllTickets: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    allowHold: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    allowDiscountedHold: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    rvJobs: (0, _attr.default)(),
    rowguid: (0, _attr.default)('string'),
    isPaidHourly: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    useStatus: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    useHost: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    jobCode1: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    jobCode2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    departmentCode: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    jobId: (0, _attr.default)('number')
  });

  _exports.default = _default;
});