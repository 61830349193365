define("lh4/serializers/hardware/checkout", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'hardware/checkout';
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var map = {
        personal: {
          personalLocationId: 'locationId',
          personalFullName: 'fullName',
          personalEmail: 'email',
          personalPhoneNumber: 'phoneNumber'
        },
        delivery: {
          deliveryLocationId: 'locationId',
          deliveryStreetAddress1: 'streetAddress1',
          deliveryStreetAddress2: 'streetAddress2',
          deliveryCity: 'city',
          deliveryState: 'state',
          deliveryZip: 'zip'
        }
      };
      Object.keys(map).forEach(function (target) {
        var targetMap = map[target];
        json[target] = {};
        Object.keys(targetMap).forEach(function (key) {
          if (json[key] === undefined) {
            return;
          }

          var mapKey = targetMap[key];
          json[target][mapKey] = json[key];
          delete json[key];
        });
      });
      return json;
    }
  });

  _exports.default = _default;
});