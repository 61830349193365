define("lh4/enums/hardware-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HARDWARE_ITEMS = Object.freeze({
    SKYTAB_GLASS: 'SKYTAB_GLASS',
    SKYTAB_EMPLOYEE_ID_CARDS: 'SKYTAB_EMPLOYEE_ID_CARDS',
    ARCHER_ROUTER: 'ARCHER_ROUTER',
    SKYTAB_MOBILE: 'SKYTAB_MOBILE',
    SKYTAB_KDS: 'SKYTAB_KDS',
    SKYTAB_KDS_WALL_MOUNT: 'SKYTAB_KDS_WALL_MOUNT',
    SKYTAB_KDS_STAND: 'SKYTAB_KDS_STAND',
    SKYTAB_POS: 'SKYTAB_POS',
    RECEIPT_PRINTER_EPSON_TM_M30: 'RECEIPT_PRINTER_EPSON_TM_M30',
    CASH_DRAWER: 'CASH_DRAWER',
    SKYTAB_CUSTOMER_FACING_DISPLAY: 'SKYTAB_CUSTOMER_FACING_DISPLAY',
    REMOTE_DOT_MATRIX_KITCHEN_PRINTER: 'REMOTE_DOT_MATRIX_KITCHEN_PRINTER',
    THERMAL_PRINTER: 'THERMAL_PRINTER'
  });
  var _default = HARDWARE_ITEMS;
  _exports.default = _default;
});