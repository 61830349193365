define("lh4/routes/authenticated/reputation/reviews/locations/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    location: Ember.inject.service(),
    model: function model(_ref) {
      var accountId = _ref.accountId;
      var parentModel = this.modelFor('authenticated.reputation.reviews.locations');
      var account = parentModel.accounts.find(function (acc) {
        return "".concat(acc.id) === "".concat(accountId);
      });

      if (!account) {
        return this.transitionTo('authenticated.reputation.reviews.locations', this.get('location.model.id'));
      }

      return Ember.RSVP.hash({
        activeAccount: account,
        reviewData: this.controllerFor('authenticated.reputation.reviews.locations.account').loadReviews(account)
      });
    }
  });

  _exports.default = _default;
});