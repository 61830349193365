define("lh4/components/ui/s4/logos/doordash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  */
  {
    id: "PGyYoPwo",
    block: "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/logos/doordash.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});