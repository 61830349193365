define("lh4/templates/authenticated/locations/location/revenue-centers/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ww/OfuR6",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"ui/modal/fullscreen-modal\",null,[[\"title\",\"onClose\",\"errorMessage\",\"classNames\"],[\"New Revenue Center\",[28,\"action\",[[23,0,[]],\"close\"],null],[24,[\"errorMessage\"]],\"revenue-centers internet-payments-wrapper\"]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `modal.content` to be a contextual component but found a string. Did you mean `(component modal.content)`? ('lh4/templates/authenticated/locations/location/revenue-centers/create.hbs' @ L8:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"epro/revenue-centers/revenue-center-form\",null,[[\"changeset\",\"showErrors\",\"showTerminalsTable\"],[[24,[\"changeset\"]],[24,[\"showErrors\"]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"actions\"]],\"expected `modal.actions` to be a contextual component but found a string. Did you mean `(component modal.actions)`? ('lh4/templates/authenticated/locations/location/revenue-centers/create.hbs' @ L15:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui button primary\",[28,\"if\",[[24,[\"isSaving\"]],\" loading\"],null]]]],[12,\"data-test-button\",\"save\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"create\"]],[8],[0,\"\\n\\t\\t\\tSave\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui primary basic button left-action\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n\\t\\t\\tDiscard\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/revenue-centers/create.hbs"
    }
  });

  _exports.default = _default;
});