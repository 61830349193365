define("lh4/controllers/authenticated/reputation/reviews/locations/account", ["exports", "moment", "lh4/services/reputation/google-my-business"], function (_exports, _moment, _googleMyBusiness) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var PAGE_SIZE = 10;

  var _default = Ember.Controller.extend({
    errorHandler: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    accountApi: Ember.inject.service('reputation/account-api'),
    googlePageTracker: new _googleMyBusiness.GooglePageTracker(),
    activeAccountIsPageable: Ember.computed('model.activeAccount.type', function getter() {
      if (!this.get('model.activeAccount')) {
        return false;
      }

      return this.accountApi.isFbPage(this.get('model.activeAccount').type) || this.accountApi.isGoogleBusiness(this.get('model.activeAccount').type);
    }),
    loadReviews: function loadReviews(account) {
      var _this = this;

      var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('isLoading', true);

      var qs = _objectSpread({
        limit: PAGE_SIZE
      }, query);

      return this.accountApi.getReviews(account.id, qs).then(function (result) {
        var results = result;
        results.reviews.forEach(function (review) {
          var r = review;
          r.createdAt = (0, _moment.default)(r.createdAt).toDate();
        });

        if (_this.accountApi.isGoogleBusiness(account.type) && results.meta && results.meta.pagination) {
          results.meta.pagination.before = _this.get('googlePageTracker').getPrevious();
        }

        return results;
      }).catch(function (err) {
        _this.errorHandler.handleResponseError(err);

        return {};
      }).finally(function () {
        _this.set('isLoading', false);
      });
    },
    actions: {
      selectPreviousPage: function selectPreviousPage() {
        var _this2 = this;

        if (!this.get('activeAccountIsPageable')) {
          return;
        }

        var before = this.get('model.reviewData').meta.pagination.before;
        var qs = {};

        if (this.accountApi.isGoogleBusiness(this.get('model.activeAccount').type)) {
          qs.page = this.get('googlePageTracker').onPrevious(before);
        }

        if (this.accountApi.isFbPage(this.get('model.activeAccount').type)) {
          qs.before = before;
        }

        this.loadReviews(this.get('model.activeAccount'), qs).then(function (reviewData) {
          _this2.set('model.reviewData', reviewData);
        });
      },
      selectNextPage: function selectNextPage() {
        var _this3 = this;

        if (!this.get('activeAccountIsPageable')) {
          return;
        }

        var after = this.get('model.reviewData').meta.pagination.after;
        var qs = {};

        if (this.accountApi.isGoogleBusiness(this.get('model.activeAccount').type)) {
          qs.page = after;
          this.get('googlePageTracker').onNext(after);
        }

        if (this.accountApi.isFbPage(this.get('model.activeAccount').type)) {
          qs.after = after;
        }

        this.loadReviews(this.get('model.activeAccount'), qs).then(function (reviewData) {
          _this3.set('model.reviewData', reviewData);
        });
      }
    }
  });

  _exports.default = _default;
});