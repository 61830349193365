define("lh4/controllers/authenticated/locations/location/hospitality/manage-employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    // QueryParams
    queryParams: ['location', 'searchName', 'isActive', 'limit'],
    // Pages
    location: null,
    searchName: null,
    limit: 10,
    isActive: true,
    offset: 0,
    // Replace after API update
    employeeCount: null,
    actions: {
      employeeNameSearch: function employeeNameSearch(searchName) {
        var location = this.get('location');
        var isActive = this.get('isActive');
        var limit = this.get('limit');
        var offset = 0;
        this.transitionToRoute('authenticated.locations.location.hospitality.manage-employees', {
          queryParams: {
            location: location,
            searchName: searchName,
            isActive: isActive,
            limit: limit,
            offset: offset
          }
        });
      },
      clearSearch: function clearSearch() {
        var location = this.get('location');
        var isActive = this.get('isActive');
        var limit = this.get('limit');
        var offset = 0;
        this.transitionToRoute('authenticated.locations.location.hospitality.manage-employees', {
          queryParams: {
            location: location,
            searchName: null,
            isActive: isActive,
            limit: limit,
            offset: offset
          }
        });
      },
      updateLimit: function updateLimit(limit) {
        var location = this.get('location');
        var isActive = this.get('isActive');
        var offset = 0;
        this.set('limit', limit);
        this.transitionToRoute('authenticated.locations.location.hospitality.manage-employees', {
          queryParams: {
            location: location,
            isActive: isActive,
            limit: limit,
            offset: offset
          }
        });
      }
    }
  });

  _exports.default = _default;
});