define("lh4/mirage/factories/hospitality-privilege", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    allowNoPin: false,
    isSecurityDefault: false,
    isSecurityManagement: false,
    rowguid: '564DC4BB-D5D7-43CB-9E89-8DD5B1471587',
    securityId: 2,
    securityDescription: null,
    securityName: 'Test Privileges',
    securityOrdinal: 2,
    securityXmlName: 'Test XML Name'
  });

  _exports.default = _default;
});