define("lh4/components/customer-database/import-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedUniqueAttributes: Ember.computed('columns.@each.attribute', function getter() {
      return this.get('columns').filterBy('attribute.isUnique').mapBy('attribute');
    }),
    actions: {
      attributeChanged: function attributeChanged(column, displayName) {
        column.set('attribute', this.get('customerAttributes').find(function (a) {
          return a.displayName === displayName;
        }));
      },
      setStatus: function setStatus(column, status) {
        column.set('status', status);
      }
    }
  });

  _exports.default = _default;
});