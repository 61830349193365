define("lh4/mirage/factories/epro-modifier-set", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    name: function name() {
      return _faker.default.commerce.productName();
    },
    kitchenPrintName: function kitchenPrintName() {
      return _faker.default.commerce.productName();
    },
    isActive: true,
    isFlatPrice: true,
    isRequired: true,
    areModifierQuantitiesEnabled: true,
    areOptionsAutoDisplayed: true,
    oneOnly: false,
    locationId: 1,
    ordinal: 0,
    value: '1',
    createdAt: function createdAt() {
      return _faker.default.date.past();
    },
    onlineActive: true,
    onlineName: function onlineName() {
      return _faker.default.commerce.productName();
    },
    onlineDescription: function onlineDescription() {
      return _faker.default.commerce.productAdjective();
    },
    minQuantityForIndividualModifier: '1',
    maxQuantityForIndividualModifier: '5',
    minModifiersPerSet: '1',
    maxModifiersPerSet: '20' // modifiers: hasMany('echo-pro/modifier'),
    // modifiersMap: hasMany('echo-pro/modifier-set-map'),
    // nestedModifiers: hasMany('echo-pro/modifier', { inverse: 'nestedModifierSets' }),
    // items: hasMany('echo-pro/item'),
    // options: computed(
    // 	...Object.keys(OPTIONS),
    // 	getBooleanPropertyArray(Object.keys(OPTIONS))
    // ),

  });

  _exports.default = _default;
});