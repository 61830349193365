define("lh4/serializers/reports/subscription", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return this._super("reports/".concat(payloadKey));
    },
    attrs: {
      reportSubscriptionRecipients: {
        serialize: 'reportSubscriptionId',
        deserialize: 'reportSubscriptionId',
        embedded: 'always'
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.reportSubscriptionRecipientsString;
      return json;
    },
    normalize: function normalize() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var json = args[1];
      var recipientsArray = json.reportSubscriptionRecipients ? json.reportSubscriptionRecipients.map(function (r) {
        return r.destination;
      }).toString() : [];
      json.reportSubscriptionRecipientsString = recipientsArray.length ? recipientsArray.replaceAll(',', ', ') : '';
      return this._super.apply(this, args);
    }
  });

  _exports.default = _default;
});