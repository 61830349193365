define("lh4/models/loyalty/program", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "ember-data/relationships", "lh4/utils/has-many-ids"], function (_exports, _model, _attr, _emberCpValidations, _relationships, _hasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Loyalty Program Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    pointName: {
      description: 'Loyalty Point Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 10
      })]
    },
    discounts: {
      validators: [(0, _emberCpValidations.validator)('has-many')]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    pointName: (0, _attr.default)('string'),
    ownerUserId: (0, _attr.default)('number'),
    ownerUserName: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    discounts: (0, _relationships.hasMany)('loyalty/discount'),
    members: (0, _relationships.hasMany)('loyalty/member'),
    locations: (0, _relationships.hasMany)('location'),
    locationIds: Ember.computed('locations', (0, _hasManyIds.default)('locations', 'location'))
  });

  _exports.default = _default;
});