define("lh4/components/tables/generic-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: [],

    /**
     * @param {Array} - Array of objects of { name: {string}, field: {string}, width: {string} }.
     */
    headers: [],

    /**
     * @param {Array} - Array of any type to display.
     */
    records: [],
    striped: true,
    limit: 25,
    offset: 0,
    sortBy: null,
    sortAsc: true,
    enablePagination: true,
    itemsPerPage: [10, 25, 50],
    totalCount: 0,
    maxPagesShown: 6,
    allowRecordSelection: true,
    displayPagination: true,
    pages: Ember.computed('totalCount', 'records.[]', function getter() {
      var limit = this.get('limit');
      var totalCount = this.get('totalCount');
      var pagesCount = Math.ceil(totalCount / limit);
      return Array.from({
        length: pagesCount
      }, function (e, index) {
        return {
          index: index,
          offset: index * limit
        };
      });
    }),
    currentPage: Ember.computed('pages.[]', 'offset', function getter() {
      var _this = this;

      return this.get('pages').findIndex(function (p) {
        return p.offset === _this.get('offset');
      }) + 1;
    }),
    actions: {
      recordSelected: function recordSelected(record) {
        if (this.get('allowRecordSelection')) {
          this.sendAction('recordSelected', record);
        }
      },
      limitChanged: function limitChanged(limit) {
        this.send('filterChanged', {
          limit: parseInt(limit, 10),
          offset: 0
        });
      },
      sortChanged: function sortChanged(field) {
        var sortAsc = this.get('sortAsc');

        if (field === this.get('sortBy')) {
          sortAsc = !sortAsc;
        }

        this.send('filterChanged', {
          sortBy: field,
          sortAsc: sortAsc
        });
      },
      pageChanged: function pageChanged(index) {
        var page = this.get('pages')[index - 1];
        this.send('filterChanged', {
          offset: page.offset
        });
      },
      incrementOffset: function incrementOffset() {
        if (this.get('currentPage') === this.get('pages.lastObject')) {
          return;
        }

        this.send('filterChanged', {
          offset: this.get('offset') + this.get('limit')
        });
      },
      decrementOffset: function decrementOffset() {
        if (this.get('currentPage') === this.get('pages.firstObject')) {
          return;
        }

        this.send('filterChanged', {
          offset: this.get('offset') - this.get('limit')
        });
      },
      filterChanged: function filterChanged(updated) {
        var current = {
          limit: this.get('limit'),
          offset: this.get('offset'),
          sortBy: this.get('sortBy'),
          sortAsc: this.get('sortAsc')
        };
        this.sendAction('filterChanged', Object.assign(current, updated));
      }
    }
  });

  _exports.default = _default;
});