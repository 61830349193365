define("lh4/components/ui/records-side-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @param {Array<object>} - records to render
     */
    records: [],
    classNames: ['records-side-menu'],
    filterBy: 'isNew',
    filterInclusive: false,
    sortBy: null,
    sortDesc: false,
    recordsComputed: Ember.computed('records.[]', 'records.@each.isNew', function getter() {
      var _this = this;

      var records = this.get('records');
      var filterBy = this.get('filterBy');

      if (filterBy) {
        records = records.filter(function (rec) {
          return _this.get('filterInclusive') ? rec.get(filterBy) : !rec.get(filterBy);
        });
      }

      if (this.get('sortBy')) {
        records = records.sortBy(this.get('sortBy'));

        if (this.get('sortDesc')) {
          records = records.reverse();
        }
      }

      return records;
    })
  });

  _exports.default = _default;
});