define("lh4/components/ui/client-pagination-buttons-prev-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    hasPrevPage: false,
    hasNextPage: false,
    actions: {
      selectPreviousPage: function selectPreviousPage() {
        if (!this.get('hasPrevPage')) {
          return;
        }

        this.sendAction('onSelectPreviousPage');
      },
      selectNextPage: function selectNextPage() {
        if (!this.get('hasNextPage')) {
          return;
        }

        this.sendAction('onSelectNextPage');
      }
    }
  });

  _exports.default = _default;
});