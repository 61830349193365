define("lh4/components/internet-payments/ui/fields/datetime-range-picker", ["exports", "lh4/components/ui/dates/datetime-range-picker"], function (_exports, _datetimeRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _datetimeRangePicker.default.extend({
    classNames: ['datetime-range-picker'],
    actions: {
      onFocus: function onFocus() {
        this.set('withoutDefaultDateTime', false);

        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});