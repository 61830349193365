define("lh4/adapters/support/comment", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var _snapshot$record = snapshot.record,
          locationId = _snapshot$record.locationId,
          ticketId = _snapshot$record.ticketId;
      return "".concat(this.host, "/api/v1/support/tickets/ticket/").concat(locationId, "/").concat(ticketId, "/comment");
    }
  });

  _exports.default = _default;
});