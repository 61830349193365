define("lh4/templates/components/disable-firefox-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F3f1J5t3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"style\",\"display:none\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"],[7,\"input\",true],[10,\"style\",\"display:none\"],[10,\"type\",\"password\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/disable-firefox-autocomplete.hbs"
    }
  });

  _exports.default = _default;
});