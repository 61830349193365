define("lh4/controllers/authenticated/social-media/accounts/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    facebook: Ember.inject.service('social-media/facebook'),
    twitter: Ember.inject.service('social-media/twitter'),
    store: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    editRecord: Ember.inject.service(),
    actions: {
      linkAccount: function linkAccount() {
        var _this = this;

        if (!this.get('model.code') && !this.get('model.oauth_token') && !this.get('model.oauth_verifier')) {
          var message = 'Could not validate authentication parameters sent by Social Media Provider.' + ' Please try again';
          this.flashMessagesHelper.pushMessage(message, 'error');
          return;
        }

        this.set('isLoading', true);
        this.flashMessagesHelper.clearMessages();
        var account = this.store.createRecord('social-media/account', this.get('model.account'), this.get('drmAccountId'));
        this.editRecord.validate(account).then(function () {
          if (_this.get('model.code')) {
            return _this.facebook.linkFbAccountSocialMedia(_this.get('model.account'), _this.get('model.code')).then(function (_ref) {
              var response = _ref.response;

              _this.flashMessagesHelper.pushMessage('Successfully created account', 'success');

              _this.transitionToRoute('authenticated.social-media.accounts.edit', response.account.id);
            }).catch(function (err) {
              return _this.errorHandler.handleResponseError(err);
            });
          }

          if (_this.get('model.oauth_token')) {
            return _this.twitter.linkAccount(_this.get('model.account'), _this.get('model.oauth_token'), _this.get('model.oauth_verifier')).then(function () {
              _this.flashMessagesHelper.pushMessage('Successfully created account', 'success');

              _this.transitionToRoute('authenticated.social-media.accounts');
            }).catch(function (err) {
              return _this.errorHandler.handleResponseError(err);
            });
          }

          return false;
        }).catch(function (errorMessages) {
          return _this.flashMessagesHelper.pushMessages(errorMessages);
        }).finally(function () {
          account.unloadRecord();

          _this.set('isLoading', false);
        });
      },

      /**
       * Select DRM account to reuse
       * @param {Object|null} drmAccount - account object on select, null on unselect
       */
      onSelectDRMAccount: function onSelectDRMAccount(drmAccount) {
        if (drmAccount) {
          this.set('drmAccountId', drmAccount.id);
          var location = this.store.peekRecord('location', drmAccount.locationId);
          this.set('model.account.locations', [location]);
        } else {
          this.set('drmAccountId', null);
          this.set('model.account.locations', []);
        }
      },
      backToAccounts: function backToAccounts() {
        this.transitionToRoute('authenticated.social-media.accounts');
      }
    }
  });

  _exports.default = _default;
});