define("lh4/models/hospitality/choice", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    alternateKPrint: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    choiceSku: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    cost: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    courseId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    genreId: (0, _attr.default)('number', {
      defaultValue: null
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    name: (0, _attr.default)('string'),
    overridesCategoryPrice: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    percentageModifier: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    priceMod: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    printsRed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    rowguid: (0, _attr.default)('string'),
    rvChoices: (0, _attr.default)(),
    usesPercentage: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    choiceId: (0, _attr.default)('number'),
    choiceChoiceSets: (0, _attr.default)(),
    locationId: (0, _attr.default)('number')
  });

  _exports.default = _default;
});