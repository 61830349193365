define("lh4/components/ui/header-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui', 'secondary', 'menu', 'header-menu'],
    headerText: null,
    secondaryHeaderClass: 'right'
  });

  _exports.default = _default;
});