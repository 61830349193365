define("lh4/templates/components/dashboard-widgets/daily-top-items/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IlwUdWXw",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"loading\"]],\"skeleton-placeholder\"],null]]]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[28,\"if\",[[28,\"eq\",[[24,[\"settings\",\"type\"]],\"by-sales\"],null],[28,\"currency-format\",[[23,1,[\"total\"]]],null],[23,1,[\"total\"]]],null],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"no-data\"],[8],[7,\"div\",true],[10,\"class\",\"message\"],[8],[0,\"No daily top items to display.\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"thereIsMore\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",false],[12,\"class\",\"row action-target\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"toggleExpand\"]]]],[8],[0,\"Show \"],[1,[28,\"if\",[[24,[\"expanded\"]],\"less\",\"more\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/daily-top-items/data.hbs"
    }
  });

  _exports.default = _default;
});