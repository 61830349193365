define("lh4/serializers/echo-pro/intraday-period-group", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.name = json.intradayPeriodGroupName.trim();
      delete json.intradayPeriodGroupName;
      return json;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payloadToNormalize) {
      var payload = {
        intradayPeriodGroups: payloadToNormalize
      };

      for (var _len = arguments.length, args = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        args[_key - 3] = arguments[_key];
      }

      return this._super.apply(this, [store, primaryModelClass, payload].concat(args));
    }
  });

  _exports.default = _default;
});