define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/reservation/edit/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      modalSuccess: function modalSuccess(res) {
        var reservation = res.reservation;

        if (reservation) {
          this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.reservation.edit', reservation.get('locationId'), reservation.id);
        }
      },
      modalDeny: function modalDeny(res) {
        var reservation = res.reservation;

        if (reservation) {
          reservation.rollbackAttributes();
          this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.reservation.edit', reservation.get('locationId'), reservation.id);
        }
      }
    }
  });

  _exports.default = _default;
});