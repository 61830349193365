define("lh4/models/verify-owner/owner", ["exports", "ember-data", "ember-data/attr", "ember-cp-validations"], function (_exports, _emberData, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    accountProof: {
      description: 'Current Bank Account Last 4 Digits',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 4,
        max: 4
      })]
    },
    birthdate: {
      description: 'Date of Birth',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('date', {
        format: 'MM/DD/YYYY',
        onOrBefore: 'now'
      }), (0, _emberCpValidations.validator)('length', {
        min: 10,
        max: 10 // Length validator used to add maxlength property

      })]
    },
    ssnProof: {
      description: 'SSN Last 4 Digits',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 4,
        max: 4
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    accountProof: (0, _attr.default)('string'),
    birthdate: (0, _attr.default)('string'),
    ssnProof: (0, _attr.default)('string')
  });

  _exports.default = _default;
});