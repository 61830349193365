define("lh4/templates/components/internet-payments/ui/informative-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aH6Nxjuk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",[29,[\"ui right labeled icon button informative-button \",[22,\"classNames\"]]]],[12,\"data-test-ip\",\"informative-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"handleClick\"]]]],[8],[0,\"\\n\\t\"],[7,\"i\",true],[11,\"class\",[29,[[22,\"icon\"],\" icon\"]]],[10,\"data-test-ip\",\"informative-button-icon\"],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"informative-button-wrapper\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"informative-button-label\"],[10,\"data-test-ip\",\"informative-button-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"error\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"informative-button-error\"],[10,\"data-test-ip\",\"informative-button-error\"],[8],[1,[22,\"error\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"informative-button-name\"],[10,\"data-test-ip\",\"informative-button-name\"],[8],[1,[22,\"buttonName\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/informative-button.hbs"
    }
  });

  _exports.default = _default;
});