define("lh4/mixins/routes/locations/location/echo-pro/customers/edit-route-mixin", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    flashMessagesHelper: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.flashMessagesHelper.clearMessages();
    }
  });

  _exports.default = _default;
});