define("lh4/models/echo-pro/menu/pricing-table-bulk", ["exports", "ember-data", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/model-validators"], function (_exports, _emberData, _emberCpValidations, _modelCloneable, _modelValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    priceChangeValue: {
      description: 'Price change value',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        gte: 0
      }), (0, _modelValidators.numberFloatValidator)()]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, _modelCloneable.default, {
    corporateGroupId: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    priceChangeOptionId: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    priceChangeValue: _emberData.default.attr('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});