define("lh4/routes/authenticated/analytics/index", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/routes/authenticated/analytics/sso"], function (_exports, _authenticatedRouteMixin, _sso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sso.default.extend(_authenticatedRouteMixin.default, {});

  _exports.default = _default;
});