define("lh4/components/ui/s4/icons/minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
  	<path d="M20 11H4V13H20V11Z" fill="black" fill-opacity="0.6"/>
  </svg>
  
  */
  {
    id: "gLsBblA7",
    block: "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"currentColor\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M20 11H4V13H20V11Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/minus.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});