define("lh4/components/tables/shifts-table", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    itemsPerPage: [10, 25, 50],
    rootLink: 'authenticated.locations.location.echo-pro.manage-pos.timeclock',
    selectShift: '',
    addShift: '',
    deleteShift: '',
    clockOut: '',
    closeShift: '',
    save: '',
    limit: 10,
    offset: 0,
    totalPages: Ember.computed('shifts.[]', 'limit', function getter() {
      return Math.ceil(this.shifts.length / this.limit) || 1;
    }),
    currentPage: Ember.computed('offset', 'limit', function getter() {
      return Math.floor(this.offset / this.limit) + 1;
    }),
    shiftsPaged: Ember.computed('shifts.[]', 'currentPage', 'limit', function getter() {
      var from = (this.currentPage - 1) * this.limit;
      return this.shifts.toArray().splice(from, this.limit);
    }),
    fromFilter: Ember.computed('fromFilterValue', {
      get: function get() {
        return (0, _moment.default)(this.fromFilterValue);
      },
      set: function set(key, value) {
        this.set('fromFilterValue', value.toISOString());
        return value;
      }
    }),
    toFilter: Ember.computed('toFilterValue', {
      get: function get() {
        return (0, _moment.default)(this.toFilterValue);
      },
      set: function set(key, value) {
        this.set('toFilterValue', value.toISOString());
        return value;
      }
    }),
    fromFilterDate: Ember.computed('fromFilter', function getter() {
      return this.get('fromFilter').toISOString();
    }),
    toFilterDate: Ember.computed('toFilter', function getter() {
      return this.get('toFilter').toISOString();
    }),
    fromFilterTime: Ember.computed('fromFilter', {
      get: function get() {
        return this.get('fromFilter').format('hh:mmA');
      },
      set: function set(key, value, oldValue) {
        return this.setFilterTime('fromFilter', value, oldValue);
      }
    }),
    toFilterTime: Ember.computed('toFilter', {
      get: function get() {
        return this.get('toFilter').format('hh:mmA');
      },
      set: function set(key, value, oldValue) {
        return this.setFilterTime('toFilter', value, oldValue);
      }
    }),
    setFilterTime: function setFilterTime(filter, value, oldValue) {
      var _this = this;

      var time = (0, _moment.default)(value, 'hh:mmA');
      var newDate = this.get(filter);
      newDate.set({
        hours: time.format('HH'),
        minutes: time.format('mm')
      });

      if (this.validateFilters(filter, newDate)) {
        this.set(filter, newDate);
        return value;
      }

      setTimeout(function () {
        _this.revertFilter(filter);

        return oldValue;
      }, 0);
      return false;
    },
    validateFilters: function validateFilters(filter, editedFilter) {
      var fromFilter;
      var toFilter;

      if (filter === 'fromFilter') {
        fromFilter = editedFilter;
        toFilter = this.get('toFilter');
      } else {
        fromFilter = this.get('fromFilter');
        toFilter = editedFilter;
      }

      var validationResult = fromFilter.isBefore(toFilter);

      if (!validationResult) {
        this.flashMessages.clearMessages();
        this.flashMessages.danger('The To filter date must not fall before From filter date!', {
          class: 'ui negative message'
        });
      }

      return validationResult;
    },
    revertFilter: function revertFilter(filter) {
      var prevValue = this.get(filter);
      this.set(filter, prevValue);
    },
    actions: {
      filterDateSelect: function filterDateSelect(filter, value) {
        var newDate = (0, _moment.default)(value);
        newDate.set({
          hour: this.get(filter).format('HH'),
          minutes: this.get(filter).format('mm')
        });

        if (this.validateFilters(filter, newDate)) {
          this.set(filter, newDate);
          this.set('offset', 0);
        } else {
          this.revertFilter(filter);
        }
      },
      openConfirmationDialog: function openConfirmationDialog() {
        this.$('#delete-shift').modal('show');
        return true;
      },
      updateLimit: function updateLimit(limit) {
        var _this2 = this;

        Ember.run.next(function () {
          _this2.setProperties({
            limit: parseInt(limit, 10),
            offset: 0
          });
        });
      },
      handlePageChange: function handlePageChange(page) {
        var offset = (page - 1) * this.limit;
        this.set('offset', offset);
      }
    }
  });

  _exports.default = _default;
});