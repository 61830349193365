define("lh4/helpers/file-extension-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fileExtensionFormat = fileExtensionFormat;
  _exports.default = void 0;

  /**
   * Converts file extension to more recognizable string
   * @param {String} extension - file extension
   * @returns {String} - recognizable string
   */
  function fileExtensionFormat(extension) {
    var fileFormat = {
      pdf: 'PDF',
      xlsx: 'Excel',
      csv: 'CSV'
    };
    return fileFormat[extension];
  }

  var _default = Ember.Helper.helper(fileExtensionFormat);

  _exports.default = _default;
});