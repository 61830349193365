define("lh4/routes/authenticated/locations/location/echo-pro/reservations/reservation/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    redirect: function redirect() {
      this.transitionTo('authenticated.locations.location.echo-pro.reservations.reservation.create');
    }
  });

  _exports.default = _default;
});