define("lh4/mirage/fixtures/internet-payments/disputes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SINGLE_DISPUTE_FIXTURE_WITH_UPLOADED_FILES = _exports.SINGLE_DISPUTE_FIXTURE = _exports.DISPUTE_COUNT_FIXTURE = _exports.LOST_FIXTURE = _exports.WON_FIXTURE = _exports.ALL_FIXTURE = _exports.AWAITING_INFO_FIXTURE = _exports.NEED_RESPONSE_FIXTURE = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var NEED_RESPONSE_FIXTURE = {
    disputes: [{
      acceptedAsLost: false,
      id: '1',
      date: '2023-01-01 11:22:12',
      caseNumber: '2384927429',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 10,
      disputeAmount: 2100,
      status: 'NEED_RESPONSE',
      reasonCode: '3344',
      dueBy: '2022-12-30 05:22:12',
      transaction: {
        date: '2022-12-30 05:22:12',
        amount: 2100,
        id: '89',
        acquirerRefNumber: '789798',
        merchantReference: '09890'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '3423',
        brand: 'Visa',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }, {
      acceptedAsLost: false,
      id: '2',
      date: '2023-01-06 15:25:10',
      caseType: 'RETRIEVAL_REQUEST',
      caseNumber: '3243243244',
      daysToAct: 1,
      disputeAmount: 24300,
      status: 'NEED_RESPONSE',
      reasonCode: '3344',
      dueBy: '2022-12-30 05:22:12',
      transaction: {
        date: '2022-12-11 15:23:12',
        amount: 3300,
        id: '23423',
        acquirerRefNumber: '23412',
        merchantReference: '42343243'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '2433',
        brand: 'MasterCard',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }]
  };
  _exports.NEED_RESPONSE_FIXTURE = NEED_RESPONSE_FIXTURE;
  var AWAITING_INFO_FIXTURE = {
    disputes: [{
      id: '3',
      acceptedAsLost: false,
      date: '2023-01-01 11:22:12',
      caseNumber: '2384927429',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 10,
      disputeAmount: 2100,
      status: 'AWAITING_INFO',
      reasonCode: '3344',
      transaction: {
        date: '2022-12-30 05:22:12',
        amount: 2100,
        id: '89',
        acquirerRefNumber: '789798',
        merchantReference: '09890'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '3423',
        brand: 'Visa',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }]
  };
  _exports.AWAITING_INFO_FIXTURE = AWAITING_INFO_FIXTURE;
  var ALL_FIXTURE = {
    disputes: [{
      id: '4',
      acceptedAsLost: false,
      date: '2023-01-01 11:22:12',
      caseNumber: '2384927429',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 10,
      disputeAmount: 2100,
      status: 'NEED_RESPONSE',
      reasonCode: '3344',
      transaction: {
        date: '2022-12-30 05:22:12',
        amount: 2100,
        id: '89',
        acquirerRefNumber: '789798',
        merchantReference: '09890'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '3423',
        brand: 'Visa',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }, {
      id: '5',
      acceptedAsLost: false,
      date: '2023-01-06 15:25:10',
      caseNumber: '3243243244',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 1,
      disputeAmount: 24300,
      status: 'NEED_RESPONSE',
      reasonCode: '3344',
      transaction: {
        date: '2022-12-11 15:23:12',
        amount: 3300,
        id: '23423',
        acquirerRefNumber: '23412',
        merchantReference: '42343243'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '2433',
        brand: 'MasterCard',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }, {
      id: '6',
      acceptedAsLost: false,
      date: '2023-01-01 11:22:12',
      caseNumber: '2384927429',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 10,
      disputeAmount: 2100,
      status: 'AWAITING_INFO',
      reasonCode: '3344',
      transaction: {
        date: '2022-12-30 05:22:12',
        amount: 3300,
        id: '23423',
        acquirerRefNumber: '23412',
        merchantReference: '42343243'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '3423',
        brand: 'Visa',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }, {
      id: '7',
      acceptedAsLost: false,
      date: '2023-01-01 11:22:12',
      caseNumber: '2384927429',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 10,
      disputeAmount: 2100,
      status: 'WON',
      reasonCode: '3344',
      transaction: {
        date: '2022-12-30 05:22:12',
        amount: 2100,
        id: '89',
        acquirerRefNumber: '789798',
        merchantReference: '09890'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '3423',
        brand: 'Visa',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }, {
      id: '8',
      acceptedAsLost: false,
      date: '2023-01-01 11:22:12',
      caseNumber: '2384927429',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 10,
      disputeAmount: 2100,
      status: 'LOST',
      reasonCode: '3344',
      transaction: {
        date: '2022-12-30 05:22:12',
        amount: 2100,
        id: '89',
        acquirerRefNumber: '789798',
        merchantReference: '09890'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '3423',
        brand: 'Visa',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }]
  };
  _exports.ALL_FIXTURE = ALL_FIXTURE;
  var WON_FIXTURE = {
    disputes: [{
      id: '9',
      acceptedAsLost: false,
      date: '2023-01-01 11:22:12',
      caseNumber: '2384927429',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 10,
      disputeAmount: 2100,
      status: 'WON',
      reasonCode: '3344',
      transaction: {
        date: '2022-12-30 05:22:12',
        amount: 2100,
        id: '89',
        acquirerRefNumber: '789798',
        merchantReference: '09890'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '3423',
        brand: 'Visa',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }]
  };
  _exports.WON_FIXTURE = WON_FIXTURE;
  var LOST_FIXTURE = {
    disputes: [{
      id: '10',
      acceptedAsLost: false,
      date: '2023-01-01 11:22:12',
      caseNumber: '2384927429',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 10,
      disputeAmount: 2100,
      status: 'LOST',
      reasonCode: '3344',
      transaction: {
        date: '2022-12-30 05:22:12',
        amount: 2100,
        id: '89',
        acquirerRefNumber: '789798',
        merchantReference: '09890'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '3423',
        brand: 'Visa',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }, {
      id: '11',
      acceptedAsLost: true,
      date: '2023-01-01 12:22:12',
      caseNumber: '2384927430',
      caseType: 'RETRIEVAL_REQUEST',
      daysToAct: 10,
      disputeAmount: 2299,
      status: 'LOST',
      reasonCode: '3344',
      transaction: {
        date: '2022-12-30 06:22:12',
        amount: 2100,
        id: '89',
        acquirerRefNumber: '789799',
        merchantReference: '09891'
      },
      reason: {
        description: 'test-description',
        longDescription: 'test-long-description',
        documents: ['document-1', 'document-2']
      },
      card: {
        last4: '3423',
        brand: 'Visa',
        ipAddress: '123.3432.342'
      },
      evidence: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }]
  };
  _exports.LOST_FIXTURE = LOST_FIXTURE;
  var DISPUTE_COUNT_FIXTURE = {
    countNeedResponse: 2,
    countInReview: 1,
    countAll: 5,
    countWon: 1,
    countLost: 2
  };
  _exports.DISPUTE_COUNT_FIXTURE = DISPUTE_COUNT_FIXTURE;
  var SINGLE_DISPUTE_FIXTURE = {
    id: '10',
    date: '2023-04-26 11:09:43',
    caseNumber: '2384927429',
    disputeAmount: 21,
    disputeCurrency: 'USD',
    status: 'NEED_RESPONSE',
    reasonCode: '13.9',
    acceptedAsLost: false,
    caseType: 'CHARGEBACK',
    reason: {
      description: 'test-description',
      longDescription: 'test-long-description',
      documents: ['document-1', 'document-2']
    },
    transaction: {
      date: '2022-08-28 21:28:55',
      id: '89',
      amount: '68989',
      currency: 'USD',
      acquirerRefNumber: '24000972241706902529602',
      merchantReference: '09890',
      country: 'CH'
    },
    eventHistory: [{
      code: 'DISPUTE_UPDATED',
      message: 'A dispute for $125.00 was updated.',
      date: '2023-05-12 10:03:10',
      disputeState: {
        id: 'disp_keOPPoqFXIEzgyttOh9uDuS9',
        date: '2023-04-26 11:09:43',
        caseNumber: '10000000000002',
        disputeAmount: 12500,
        disputeCurrency: 'USD',
        status: 'LOST',
        reasonCode: '13.9',
        acceptedAsLost: true,
        caseType: 'CHARGEBACK',
        evidence: {
          productDescription: 'product 1',
          additionalInfoText: 'info text'
        }
      }
    }, {
      code: 'DISPUTE_UPDATED',
      message: 'A dispute for $125.00 was updated.',
      date: '',
      disputeState: {
        id: 'disp_keOPPoqFXIEzgyttOh9uDuS9',
        date: '2023-04-26 11:09:43',
        caseNumber: '10000000000002',
        disputeAmount: 12500,
        disputeCurrency: 'USD',
        status: 'LOST',
        reasonCode: '13.9',
        acceptedAsLost: true,
        caseType: 'CHARGEBACK',
        evidence: {
          productDescription: 'product 1',
          additionalInfoText: 'info text'
        }
      }
    }, {
      code: 'DISPUTE_UPDATED',
      message: '',
      date: '2023-04-26 11:12:32',
      disputeState: {
        id: 'disp_keOPPoqFXIEzgyttOh9uDuS9',
        date: '2023-04-26 11:09:43',
        caseNumber: '10000000000002',
        disputeAmount: 12500,
        disputeCurrency: 'USD',
        status: 'LOST',
        reasonCode: '13.9',
        acceptedAsLost: true,
        caseType: 'CHARGEBACK',
        evidence: {
          productDescription: 'product 1',
          additionalInfoText: 'info text'
        }
      }
    }, {
      code: 'DISPUTE_UPDATED',
      message: 'A dispute for $125.00 was updated.',
      date: '2023-04-26 11:10:55',
      disputeState: {
        id: 'disp_keOPPoqFXIEzgyttOh9uDuS9',
        date: '2023-04-26 11:09:43',
        caseNumber: '10000000000002',
        disputeAmount: 12500,
        disputeCurrency: 'USD',
        status: 'LOST',
        reasonCode: '13.9',
        acceptedAsLost: true,
        caseType: 'CHARGEBACK',
        evidence: {}
      }
    }, {
      code: 'FUNDS_WITHDRAWN',
      message: '$125.00 in dispute funds have been withdrawn from your account.',
      date: '2023-04-26 11:09:45',
      disputeState: {
        id: 'disp_keOPPoqFXIEzgyttOh9uDuS9',
        date: '2023-04-26 11:09:43',
        caseNumber: '10000000000002',
        disputeAmount: 12500,
        disputeCurrency: 'USD',
        status: 'NEED_RESPONSE',
        reasonCode: '13.9',
        acceptedAsLost: false,
        caseType: 'CHARGEBACK',
        evidence: {},
        daysToAct: 0,
        dueBy: '2023-05-06 11:09:43'
      }
    }, {
      code: 'DISPUTE_CREATED',
      message: 'Your customer has disputed a charge for $125.00.',
      date: '2023-04-26 11:09:45',
      disputeState: {
        id: 'disp_keOPPoqFXIEzgyttOh9uDuS9',
        date: '2023-04-26 11:09:43',
        caseNumber: '10000000000002',
        disputeAmount: 12500,
        disputeCurrency: 'USD',
        status: 'NEED_RESPONSE',
        reasonCode: '13.9',
        acceptedAsLost: false,
        caseType: 'CHARGEBACK',
        evidence: {},
        daysToAct: 0,
        dueBy: '2023-05-06 11:09:43'
      }
    }, {
      code: 'TRANSACTION_CREATED',
      message: '$689.89 transaction was made.',
      date: '2022-08-28 21:28:55'
    }],
    evidence: {
      productDescription: 'product 1',
      additionalInfoText: 'info text'
    },
    acquirerRefNumber: '24000972241706902529602',
    card: {
      last4: '3423',
      brand: 'visa'
    }
  };
  _exports.SINGLE_DISPUTE_FIXTURE = SINGLE_DISPUTE_FIXTURE;

  var SINGLE_DISPUTE_FIXTURE_WITH_UPLOADED_FILES = _objectSpread(_objectSpread({}, SINGLE_DISPUTE_FIXTURE), {}, {
    evidence: {
      signatureId: 'signature-file-id',
      receiptId: 'receipt-file-id',
      communicationId: 'communication-file-id',
      serviceDocId: 'serviceDoc-file-id',
      additionalInfoId: 'additionalInfo-file-id'
    },
    evidenceFileDetails: {
      signature: {
        id: 'signature-file-id',
        size: 120270,
        url: '/signature-file-download-path',
        type: 'png'
      },
      receipt: {
        id: 'receipt-file-id',
        size: 2520270,
        url: '/receipt-file-download-path',
        type: 'png'
      },
      communication: {
        id: 'communication-file-id',
        size: 2500000,
        url: '/communication-file-download-path',
        type: 'png'
      },
      serviceDoc: {
        id: 'serviceDoc-file-id',
        size: 2000000,
        url: '/serviceDoc-file-download-path',
        type: 'png'
      },
      additionalInfo: {
        id: 'additionalInfo-file-id',
        size: 15,
        url: '/additionalInfo-file-download-path',
        type: 'png'
      }
    }
  });

  _exports.SINGLE_DISPUTE_FIXTURE_WITH_UPLOADED_FILES = SINGLE_DISPUTE_FIXTURE_WITH_UPLOADED_FILES;
});