define("lh4/controllers/authenticated/device-management", ["exports", "lh4/controllers/general-list-controller", "lh4/enums/products"], function (_exports, _generalListController, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    location: Ember.inject.service(),
    logger: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    permissions: Ember.inject.service(),
    searchName: null,
    queryParams: ['searchName'],
    class: 'device-management',
    headers: [{
      name: 'Station Number',
      field: 'number',
      width: 'three',
      sortable: false
    }, {
      name: 'Device Name',
      field: 'name',
      width: 'three',
      sortable: false
    }, {
      name: 'Serial No.',
      field: 'serialNumber',
      width: 'three',
      sortable: false
    }, {
      name: 'Revenue Center',
      field: 'revenueCenterGuid',
      width: 'three',
      sortable: false
    }, {
      name: 'Device Status',
      field: 'isActive',
      width: 'three',
      sortable: false
    }],
    order: 'number',
    posTerminals: Ember.computed('model.terminals', function getter() {
      return this.model.terminals.filter(function (terminal) {
        return terminal.product === _products.default.EW;
      });
    }),
    mobileTerminals: Ember.computed('model.terminals', function getter() {
      return this.model.terminals.filter(function (terminal) {
        return terminal.product === _products.default.GPATT;
      });
    }),
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('searchName', search);
        this.set('offset', 0);
      }
    }
  });

  _exports.default = _default;
});