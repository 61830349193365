define("lh4/routes/authenticated/customer-engagement/campaigns/overview", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'manage campaigns for customer-engagement',
    model: function model(params) {
      return Ember.RSVP.hash({
        campaign: this.store.findRecord('customer-engagement/campaign', params.campaignId)
      });
    },
    redirect: function redirect(model) {
      if (model.campaign.get('isEditable')) {
        this.transitionTo('authenticated.customer-engagement.campaigns.edit', model.campaign.get('guid'));
      }
    }
  });

  _exports.default = _default;
});