define("lh4/components/epro/kds/modifier-prep-times-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="modifier-prep-times-table">
  	<div class="row header">
  		<div>Name</div>
  		<div>Prep Time</div>
  	</div>
  
  	{{#each @changeset.modifierSetsWithDefaults as |set|}}
  		<div class="row content title">
  			<div>{{set.name}}</div>
  		</div>
  		{{#each set.modifiers as |modifier modifierIndex|}}
  			<div class="row content item">
  				<div class="name">{{modifier.name}}</div>
  				<div class="input{{if (get set (concat "modifiers." modifierIndex ".error")) ' error' ''}}">
  					<Ui::FormFields::InputField
  						@changeset={{set}}
  						@property={{concat "modifiers." modifierIndex ".prepTimeText"}}
  						@placeholder="0:00"
  						@onChange={{fn @updatePrepTime set.guid modifier.guid}}
  						@showErrors={{@showErrors}}
  					/>
  				</div>
  			</div>
  		{{/each}}
  	{{/each}}
  </div>
  
  */
  {
    id: "Xexa7oWL",
    block: "{\"symbols\":[\"set\",\"modifier\",\"modifierIndex\",\"@updatePrepTime\",\"@showErrors\",\"@changeset\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modifier-prep-times-table\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row header\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[8],[0,\"Name\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[8],[0,\"Prep Time\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,6,[\"modifierSetsWithDefaults\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row content title\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"],[4,\"each\",[[23,1,[\"modifiers\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row content item\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"name\"],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"input\",[28,\"if\",[[28,\"get\",[[23,1,[]],[28,\"concat\",[\"modifiers.\",[23,3,[]],\".error\"],null]],null],\" error\",\"\"],null]]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[5,\"ui/form-fields/input-field\",[],[[\"@changeset\",\"@property\",\"@placeholder\",\"@onChange\",\"@showErrors\"],[[23,1,[]],[28,\"concat\",[\"modifiers.\",[23,3,[]],\".prepTimeText\"],null],\"0:00\",[28,\"fn\",[[23,4,[]],[23,1,[\"guid\"]],[23,2,[\"guid\"]]],null],[23,5,[]]]]],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/epro/kds/modifier-prep-times-table.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});