define("lh4/models/echo-pro/employee", ["exports", "ember-cp-validations", "ember-data", "ember-data/relationships", "lh4/mixins/model-cloneable", "lh4/utils/has-many-ids", "moment", "lh4/models/echo-pro/employee-date", "lh4/models/echo-pro/employee-phone"], function (_exports, _emberCpValidations, _emberData, _relationships, _modelCloneable, _hasManyIds, _moment, _employeeDate, _employeePhone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Employee Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100,
        min: 1
      })]
    },
    loginPin: {
      description: 'Login Pin',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        is: 4
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^\*{4}|\d{4}$/
      })]
    },
    email: (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'email',
      description: 'Email',
      message: 'Please enter a valid email address'
    }),
    zipCode: {
      description: 'ZIP',
      validators: [(0, _emberCpValidations.validator)('zip-code', {
        allowBlank: true
      })]
    },
    payrollCode: {
      description: 'Payroll Code',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    addressLine1: {
      description: 'Street Address 1',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    addressLine2: {
      description: 'Street Address 2',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    city: {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    dateOfBirth: {
      description: 'Date of Birth',
      allowBlank: true,
      validators: [(0, _emberCpValidations.validator)('date', {
        before: 'now',
        after: (0, _moment.default)().subtract(150, 'Years'),
        errorFormat: 'MM/DD/YYYY'
      })]
    },
    healthCardExpDate: {
      description: 'Health Card Expiration Date',
      allowBlank: true,
      validators: [(0, _emberCpValidations.validator)('date', {
        errorFormat: 'MM/DD/YYYY'
      })]
    },
    liquorCardExpDate: {
      description: 'Liquor Card Expiration Date',
      allowBlank: true,
      validators: [(0, _emberCpValidations.validator)('date', {
        errorFormat: 'MM/DD/YYYY'
      })]
    },
    lookup: {
      description: 'Employee ID',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    firstName: {
      description: 'First Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    lastName: {
      description: 'Last Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    }
  });

  var _default = _emberData.default.Model.extend(_modelCloneable.default, Validations, {
    guid: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    dateOfBirth: _emberData.default.attr('string'),
    loginPin: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    addressLine1: _emberData.default.attr('string'),
    addressLine2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string'),
    payrollCode: _emberData.default.attr('string'),
    lookup: _emberData.default.attr('string'),
    isLaborTrackingEnabled: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isRecordingBreaks: _emberData.default.attr('boolean'),
    healthCardExpDate: _emberData.default.attr('string'),
    liquorCardExpDate: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isPaidHourly: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isCardOnlyAccess: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    defaultPrinter: _emberData.default.attr('string'),
    payRates: (0, _relationships.hasMany)('echo-pro/pay-rate'),
    employeeDates: (0, _relationships.hasMany)('echo-pro/employee-date'),
    employeePhones: (0, _relationships.hasMany)('echo-pro/employee-phone'),
    permissions: (0, _relationships.hasMany)('echo-pro/permission'),
    permissionIds: Ember.computed('permissions', (0, _hasManyIds.default)('permissions', 'echo-pro/permission')),
    jobs: (0, _relationships.hasMany)('echo-pro/job'),
    jobIds: Ember.computed('jobs', (0, _hasManyIds.default)('jobs', 'echo-pro/job')),
    shifts: (0, _relationships.hasMany)('echo-pro/employee-shift'),
    location: Ember.inject.service(),
    addDate: function addDate() {
      var data = {
        employee: this,
        locationId: this.locationId,
        type: _employeeDate.dateTypes[0].value
      };
      return this.store.createRecord('echo-pro/employee-date', data);
    },
    addPhone: function addPhone() {
      var data = {
        employee: this,
        locationId: this.locationId,
        type: _employeePhone.phoneTypes[0].value
      };
      return this.store.createRecord('echo-pro/employee-phone', data);
    },
    addPayRate: function addPayRate(payRateChangeset) {
      var _this = this;

      var jobs = this.store.peekAll('echo-pro/job').filter(function (job) {
        return !payRateChangeset.findBy('jobGuid', job.id) && Number(job.locationId) === Number(_this.locationId);
      }).sort(function (job1, job2) {
        return job1.name.localeCompare(job2.name);
      });
      return this.store.createRecord('echo-pro/pay-rate', {
        jobGuid: jobs.firstObject.guid,
        locationId: this.locationId,
        payRate: jobs.firstObject.payRate
      });
    }
  });

  _exports.default = _default;
});