define("lh4/routes/authenticated/locations/location/echo-pro/reservations/reservation/create", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var location = this.modelFor('authenticated.locations.location');
      var reservations = this.modelFor('authenticated.locations.location.echo-pro.reservations.reservation').reservation;
      return Ember.RSVP.hash({
        locationId: location.id,
        reservations: reservations,
        reservation: this.store.createRecord('reservation'),
        settings: this.store.query('reservations/setting', {
          location: location.id
        })
      });
    }
  });

  _exports.default = _default;
});