define("lh4/models/echo-pro/modifier-set-map", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    value: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        description: 'Item Price',
        allowString: true,
        positive: true,
        // This is help mimic what is happening on the terminal
        lte: 10000000
      }), (0, _emberCpValidations.validator)('length', {
        max: 10
      })]
    },
    onlineValue: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        description: 'Online Price',
        allowString: true,
        positive: true,
        // This is help mimic what is happening on the terminal
        lte: 10000000
      }), (0, _emberCpValidations.validator)('length', {
        max: 10
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    modifierGuid: (0, _attr.default)('string'),
    modifierSetGuid: (0, _attr.default)('string'),
    ordinal: (0, _attr.default)('string', {
      defaultValue: 0
    }),
    value: (0, _attr.default)('number'),
    isFlatAmount: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    onlineValue: (0, _attr.default)('number'),
    isFlatAmountOnline: (0, _attr.default)('boolean', {
      defaultValue: true
    })
  });

  _exports.default = _default;
});