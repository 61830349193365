define("lh4/templates/components/ui/form-fields/file-upload-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pyiX19db",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"fieldClasses\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[4,\"ui/form-fields/shared/label\",null,[[\"fullWidth\",\"required\"],[[24,[\"fullWidth\"]],[24,[\"required\"]]]],{\"statements\":[[1,[22,\"label\"],false]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"twelve wide column\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"upload-message\"],[8],[0,\"\\n\\t\\t\\t\"],[14,1],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"input\",true],[10,\"id\",\"file\"],[10,\"name\",\"file\"],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"onFileUpload\"],null]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/file-upload-field.hbs"
    }
  });

  _exports.default = _default;
});