define("lh4/routes/authenticated/disputes", ["exports", "lh4/enums/date-range-title", "lh4/enums/products", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/utils/date-moment", "moment"], function (_exports, _dateRangeTitle, _products, _authenticatedRouteMixin, _lighthousePermissionsMixin, _mobileScrollRouteMixin, _dateMoment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  var DATE_FORMAT = 'YYYY-MM-DD';

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    location: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    requiredPermissions: ['view dispute in dispute'],
    titleToken: 'Disputes',
    queryParams: {
      cardNumber: {
        refreshModel: true
      },
      cardType: {
        refreshModel: true
      },
      caseType: {
        refreshModel: true
      },
      datePrefix: {
        refreshModel: true
      },
      disputeAmt: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      transAmt: {
        refreshModel: true
      },
      start: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      caseId: {
        refreshModel: true
      }
    },
    redirect: function redirect(model, transition) {
      if (!(this.location.isUS && transition.to.name.includes('disputes')) || this.location.model.product === _products.default.HOSPITALITY) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    model: function model(_ref) {
      var _objectSpread2;

      var start = _ref.start,
          end = _ref.end,
          datePrefix = _ref.datePrefix,
          params = _objectWithoutProperties(_ref, ["start", "end", "datePrefix"]);

      var location = this.location.model.id;

      var rangeTitle = this.controllerFor('authenticated/disputes').rangeTitle || _dateRangeTitle.DATE_RANGE_TITLE.CUSTOM;

      var filteredField;

      switch (true) {
        case !!params.cardNumber:
          filteredField = 'cardNumber';
          break;

        case !!params.cardType:
          filteredField = 'cardType';
          break;

        case !!params.caseType:
          filteredField = 'caseType';
          break;

        case !!params.disputeAmt:
          filteredField = 'disputeAmt';
          break;

        case !!params.transAmt:
          filteredField = 'transAmt';
          break;

        case !!params.caseId:
          filteredField = 'caseId';
          break;

        case !!params.status:
          // status should be last since we default the status field
          filteredField = 'status';
          break;

        default:
          break;
      }

      return Ember.RSVP.hash({
        disputes: this.store.query('dispute', _objectSpread((_objectSpread2 = {
          location: location
        }, _defineProperty(_objectSpread2, "".concat(datePrefix, "Start"), start || (0, _moment.default)().format(DATE_FORMAT)), _defineProperty(_objectSpread2, "".concat(datePrefix, "End"), end || (0, _moment.default)().format(DATE_FORMAT)), _objectSpread2), params)),
        datePrefix: datePrefix,
        filteredField: filteredField,
        rangeTitle: rangeTitle,
        preferences: this.store.findRecord('preferences', this.get('tokenData.data.sub'))
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args)); // There are times when we'll clear a field value, but don't
      // want to change the filteredField dropdown. Commonly in input
      // fields where a user is clearing a previous selection.


      if (model.filteredField) {
        controller.set('filteredField', model.filteredField);
      }

      controller.setProperties({
        datePrefix: model.datePrefix,
        rangeTitle: model.rangeTitle
      });

      if (controller.start === undefined) {
        var dates = (0, _dateMoment.generateDateRanges)([_dateMoment.Period.TWO_WEEKS_TO_DATE], {
          weekStart: model.preferences.weekStart
        })[0];
        controller.setProperties({
          start: dates.startDate.format(DATE_FORMAT),
          end: dates.endDate.format(DATE_FORMAT)
        });
      }
    },
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('authenticated/disputes');
        controller.set('currentlyLoading', true);
        transition.finally(function () {
          controller.set('currentlyLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});