define("lh4/routes/authenticated/epro/accounting/revenue-classes/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'revenueClass',
    model: function model(_ref) {
      var revenueId = _ref.revenueId;
      return Ember.RSVP.hash({
        revenueClass: this.store.findRecord('echo-pro/accounting/tax-set', revenueId),
        taxes: this.modelFor('authenticated.epro.accounting.revenue-classes').taxes
      });
    }
  });

  _exports.default = _default;
});