define("lh4/components/ui/dates/weekpicker-input", ["exports", "moment", "lh4/utils/day-to-week-range"], function (_exports, _moment, _dayToWeekRange2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _construct(Parent, args, Class) { if (_isNativeReflectConstruct()) { _construct = Reflect.construct; } else { _construct = function _construct(Parent, args, Class) { var a = [null]; a.push.apply(a, args); var Constructor = Function.bind.apply(Parent, a); var instance = new Constructor(); if (Class) _setPrototypeOf(instance, Class.prototype); return instance; }; } return _construct.apply(null, arguments); }

  function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

  function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    classNames: ['ui', 'basic', 'buttons', 'white', 'week-picker'],
    init: function init() {
      this._super.apply(this, arguments);

      this.set('from', this.from || (0, _moment.default)());
    },
    isLoading: false,
    onChange: function onChange() {},
    firstDay: 0,
    from: undefined,
    yearRange: null,
    fromForPickaday: Ember.computed('from', function getter() {
      return _construct(Date, _toConsumableArray((0, _moment.default)(this.from).toArray()));
    }),
    to: Ember.computed('from', function getter() {
      return (0, _dayToWeekRange2.default)(this.from, this.firstDay).to;
    }),
    weekPickerTrigger: Ember.computed(function () {
      return document.getElementsByClassName('week-picker-button')[0];
    }),
    canStepBack: Ember.computed('from', 'minDate', function getter() {
      if (this.minDate) {
        var prevWeekLastDay = (0, _moment.default)(this.from).startOf('week').subtract(1, 'days').endOf('week');
        return prevWeekLastDay.isAfter(this.minDate);
      }

      return true;
    }),
    canStepForward: Ember.computed('to', 'maxDate', function getter() {
      if (this.maxDate) {
        var nextWeekFirstDay = (0, _moment.default)(this.to).endOf('week').add(1, 'days').startOf('week');
        return nextWeekFirstDay.isBefore(this.maxDate);
      }

      return true;
    }),
    actions: {
      moveBack: function moveBack() {
        this.send('onWeekSelection', (0, _moment.default)(this.from).subtract(1, 'week').toDate());
      },
      moveForward: function moveForward() {
        this.send('onWeekSelection', (0, _moment.default)(this.from).add(1, 'week').toDate());
      },
      onWeekSelection: function onWeekSelection(fromValue) {
        var _this = this;

        if (this.isLoading) {
          return;
        }

        Ember.run.debounce(this, function () {
          var _dayToWeekRange = (0, _dayToWeekRange2.default)(fromValue, _this.firstDay),
              from = _dayToWeekRange.from,
              to = _dayToWeekRange.to;

          _this.onChange({
            from: from,
            to: to
          });
        }, 200);
      }
    }
  });

  _exports.default = _default;
});