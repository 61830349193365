define("lh4/components/amcharts3/column-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var VALUE_AXIS_DEFAULT = {
    axisAlpha: 0,
    axisColor: '#E5E5E5',
    gridAlpha: 1,
    gridColor: '#E5E5E5'
  };
  var GRAPH_DEFAULT = {
    fillAlphas: 1,
    type: 'column'
  };
  var CATEGORY_AXIS_DEFAULT = {
    axisColor: '#E5E5E5',
    fontSize: 11,
    gridAlpha: 1,
    gridColor: '#E5E5E5',
    gridPosition: 'start',
    titleFontSize: 11
  };

  var _default = Ember.Component.extend({
    classNames: ['column-chart'],
    options: {},
    init: function init() {
      this._super.apply(this, arguments);

      this.chart = null;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _this$options = this.options,
          _this$options$balloon = _this$options.balloonText,
          balloonText = _this$options$balloon === void 0 ? '[[value]]' : _this$options$balloon,
          dataDateFormat = _this$options.dataDateFormat,
          categoryField = _this$options.categoryField,
          parseDates = _this$options.parseDates,
          _this$options$valueAx = _this$options.valueAxesOptions,
          valueAxesOptions = _this$options$valueAx === void 0 ? {} : _this$options$valueAx,
          _this$options$valueAx2 = _this$options.valueAxes,
          valueAxes = _this$options$valueAx2 === void 0 ? [valueAxesOptions] : _this$options$valueAx2,
          valueField = _this$options.valueField,
          listeners = _this$options.listeners,
          _this$options$graphs = _this$options.graphs,
          graphs = _this$options$graphs === void 0 ? [{
        balloonText: balloonText,
        valueField: valueField
      }] : _this$options$graphs,
          _this$options$color = _this$options.color,
          color = _this$options$color === void 0 ? '#009BF4' : _this$options$color,
          _this$options$colors = _this$options.colors,
          colors = _this$options$colors === void 0 ? [color] : _this$options$colors,
          _this$options$categor = _this$options.categoryAxis,
          categoryAxis = _this$options$categor === void 0 ? {} : _this$options$categor,
          chartCursor = _this$options.chartCursor;
      var chart = window.AmCharts.makeChart(this.element, {
        type: 'serial',
        path: '/amcharts',
        dataProvider: this.data,
        allLabels: [],
        backgroundAlpha: 0.8,
        balloon: {
          color: '#333333',
          fillAlpha: 1,
          shadowAlpha: 0.05
        },
        categoryAxis: _objectSpread(_objectSpread({
          parseDates: parseDates
        }, CATEGORY_AXIS_DEFAULT), categoryAxis),
        categoryField: categoryField,
        chartCursor: _objectSpread({
          cursorColor: color,
          enabled: true
        }, chartCursor),
        colors: colors,
        listeners: listeners,
        dataDateFormat: dataDateFormat,
        graphs: graphs.map(function (graph) {
          return _objectSpread(_objectSpread({}, GRAPH_DEFAULT), graph);
        }),
        guides: [],
        hideCredits: true,
        startDuration: 1,
        titles: [],
        trendLines: [],
        valueAxes: valueAxes.map(function (axis) {
          return _objectSpread(_objectSpread({}, VALUE_AXIS_DEFAULT), axis);
        })
      });
      this.chart = chart;
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.chart) {
        var _this$options2 = this.options,
            graphs = _this$options2.graphs,
            categoryAxis = _this$options2.categoryAxis;
        this.chart.categoryAxis = _objectSpread(_objectSpread({}, CATEGORY_AXIS_DEFAULT), categoryAxis);
        this.chart.graphs = (graphs || []).map(function (graph) {
          return _objectSpread(_objectSpread({}, GRAPH_DEFAULT), graph);
        });
        this.chart.dataProvider = this.data;
        this.chart.validateData();
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.chart) {
        this.chart.clear();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});