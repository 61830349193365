define("lh4/utils/stylesheet-to-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getStylesheetToColor;

  /**
   * Compute Function.
   *
   * Returns an object with a getter that computes the correct color to set for Lighthouse radio-color-picker component.
   * Derived from the nested StposUiStylesheet Model.
   *
   * For any unset object without a stylesheet set, will default to white which represents the unset option.
   *
   * @method getStylesheetToColor
   * @param {string} stylesheetObjectLinksKey - Alias to whatever the model defines as the stylesheetObjectLinks key
   * @returns {Object} - Compute Object with a getter defined.
   */
  function getStylesheetToColor(dependentKey) {
    return {
      get: function get() {
        try {
          var stylesheet = this[dependentKey].mapBy('stposUiStylesheet').mapBy('stylesheet');

          if (stylesheet) {
            var rawStyle = JSON.parse(stylesheet);
            return rawStyle.primary;
          }

          return 'white';
        } catch (e) {
          return 'white';
        }
      }
    };
  }
});