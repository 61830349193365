define("lh4/models/customer-engagement/setting", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    facebookUrl: {
      description: 'facebook URL',
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /^https:\/\/(www\.)?facebook\.com/,
        message: 'Please format URL to match facebook domain. Example: https://facebook.com/account-id'
      })]
    },
    twitterUrl: {
      description: 'Twitter URL',
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        regex: /^https:\/\/(www\.)?twitter\.com/,
        message: 'Please format URL to match twitter domain. Example: https://twitter.com/account-id'
      })]
    },
    aliasEmail: {
      validators: [(0, _emberCpValidations.validator)('format', {
        allowBlank: true,
        type: 'email',
        allowNonTld: true,
        message: 'Email format incorrect.'
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    aliasEmail: _emberData.default.attr('string'),
    aliasName: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('string'),
    facebookUrl: _emberData.default.attr('string'),
    guid: _emberData.default.attr('string'),
    isFacebookVisible: _emberData.default.attr('boolean'),
    isOloVisible: _emberData.default.attr('boolean'),
    onlineOrdering: _emberData.default.attr(),
    isOnlineReservationsVisible: _emberData.default.attr('boolean'),
    isTwitterVisible: _emberData.default.attr('boolean'),
    locationId: _emberData.default.attr('number'),
    onlineReservationUrl: _emberData.default.attr('string'),
    socialMediaAccountFacebookId: _emberData.default.attr('number'),
    socialMediaAccountTwitterId: _emberData.default.attr('number'),
    twitterUrl: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('string'),
    shouldUpdateActive: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});