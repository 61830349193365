define("lh4/routes/authenticated/customer-database", ["exports", "jquery", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _jquery, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    titleToken: 'Customer Database',
    requiredPermissions: ['manage echo-pro/customer', 'manage processing/customer'],
    queryParams: {
      search: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('echo-pro/customer');
      this.store.unloadAll('processing/customer');
    },
    permissionCheckFailed: function permissionCheckFailed() {
      if (this.location.model.product === 'hospitality') {
        return this.replaceWith('authenticated.hospitality.customer-database');
      }

      return this._super.apply(this, arguments);
    },
    model: function model(params) {
      var location = this.location.model;
      var modelName;

      if (location.product === 'echo-pro') {
        modelName = 'echo-pro/customer';
      } else if (this.can.can('manage processing/customer')) {
        modelName = 'processing/customer';
      }

      var queryParams = _objectSpread(_objectSpread({}, params), {}, {
        location: location.id
      });

      return Ember.RSVP.hash({
        location: location,
        customers: this.store.query(modelName, queryParams)
      });
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('body').addClass('echo-pro-customers-route');
    },
    deactivate: function deactivate() {
      (0, _jquery.default)('body').removeClass('echo-pro-customers-route');
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});