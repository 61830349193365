define("lh4/controllers/authenticated/locations/location/echo-pro/manage-pos/timeclock", ["exports", "lh4/enums/date-time-format", "lh4/enums/duration", "moment"], function (_exports, _dateTimeFormat, _duration, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    logger: Ember.inject.service(),
    editRecord: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    location: Ember.inject.service(),
    isLoading: false,
    selectedShift: null,
    workTimeSnapshot: null,
    activeBreak: Ember.computed('selectedShift', function getter() {
      var selectedShift = this.selectedShift;

      if (selectedShift) {
        var foundBreak = this.store.peekAll('echo-pro/time-clock-break').filterBy('timeClockShiftGuid', selectedShift.id).filterBy('voided', false)[0];

        if (foundBreak) {
          this.set('workTimeSnapshot.breakIsPaid', foundBreak.isPaid);
          this.set('isPaid', foundBreak.isPaid);
        }

        return foundBreak;
      }

      return null;
    }),
    isPaid: true,
    isPaidObserver: Ember.observer('isPaid', 'selectedShift.breakHours', 'selectedShift.breakMinutes', function callback() {
      if (this.selectedShift) {
        this.set('selectedShift.breakIsPaid', this.isPaid);
      }
    }),
    isPaidComputed: Ember.computed('activeBreak', {
      get: function get() {
        var activeBreak = this.activeBreak;

        if (activeBreak) {
          return activeBreak.isPaid;
        }

        return true;
      },
      set: function set(key, value) {
        var activeBreak = this.activeBreak;

        if (activeBreak) {
          activeBreak.set('isPaid', value);
        }

        this.set('isPaid', value);
        return value;
      }
    }),
    limit: 10,
    offset: 0,
    selectedEmployeeId: null,
    searchName: '',
    searchNameValue: '',
    searchCompleted: false,
    searchObserver: Ember.observer('searchName', function callback() {
      this.set('searchCompleted', true);
      this.set('searchNameValue', this.searchName);
    }),
    fromFilter: (0, _moment.default)().subtract(6, 'days').startOf('day').toISOString(),
    toFilter: (0, _moment.default)().endOf('day').toISOString(),
    queryParams: ['selectedEmployeeId', 'fromFilter', 'toFilter'],
    employeeSortOptions: ['isActive:desc', 'name: asc'],
    sortedEmployees: Ember.computed.sort('model.employees', 'employeeSortOptions'),
    employees: Ember.computed('sortedEmployees', 'searchName', function getter() {
      var employees = this.sortedEmployees;
      var searchName = this.searchName.toLowerCase();
      return employees.filter(function (employee) {
        return employee.name.toLowerCase().indexOf(searchName) !== -1;
      });
    }),
    filteredShifts: Ember.computed('model.shifts.@each.voided', function getter() {
      return this.model.shifts.filterBy('voided', false);
    }),
    updateBreaks: function updateBreaks(shifts) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var allBreaks = [];
        shifts.map(function (shift) {
          var shiftBreaks = _this.store.peekAll('echo-pro/time-clock-break').filterBy('timeClockShiftGuid', shift.id).filterBy('voided', false);

          shiftBreaks.forEach(function (shiftBreak) {
            shiftBreak.setProperties({
              voided: true,
              updatedAt: (0, _moment.default)().toISOString()
            });
          });

          var newBreak = _this.store.createRecord('echo-pro/time-clock-break', {
            voided: false,
            locationId: _this.model.location.id,
            timeClockShiftGuid: shift.id,
            isPaid: _this.isPaid,
            startedAt: shift.clockedInAt,
            endedAt: (0, _moment.default)(shift.clockedInAt).add(shift.breakTotalSeconds, 'seconds').toISOString(),
            createdAt: (0, _moment.default)().toISOString(),
            updatedAt: (0, _moment.default)().toISOString()
          });

          shiftBreaks.pushObject(newBreak);
          allBreaks = allBreaks.concat(shiftBreaks);
          return null;
        });
        Ember.RSVP.Promise.all(allBreaks.map(function (shiftBreak) {
          return shiftBreak.save();
        })).then(function () {
          _this.logger.log('Breaks updated successfully');

          resolve();
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    adjustShiftEnd: function adjustShiftEnd(time) {
      return (0, _moment.default)(time).subtract(1, 'minutes');
    },
    destroyNewShift: function destroyNewShift() {
      var selectedShift = this.selectedShift;

      if (selectedShift && selectedShift.isNew) {
        selectedShift.set('voided', true);
        selectedShift.unloadRecord();
      }
    },
    getTotalWorkTime: function getTotalWorkTime(shift, breakIsPaid, breakDuration) {
      var clockedIn = (0, _moment.default)(shift.clockedInAt).set({
        seconds: 0,
        milliseconds: 0
      });
      var clockedOut = (0, _moment.default)(shift.clockedOutAt).set({
        seconds: 0,
        milliseconds: 0
      });

      var total = _moment.default.duration();

      if (shift.clockedOutAt) {
        total = _moment.default.duration(clockedOut.diff(clockedIn));

        if (!breakIsPaid) {
          total = total.subtract(breakDuration, 'seconds');
        }
      }

      return total.asSeconds();
    },
    getPartOfTotalWork: function getPartOfTotalWork(currentShift, primaryShift) {
      var currentShiftTotal = this.getTotalWorkTime(currentShift, true);
      var primaryShiftTotal = this.getTotalWorkTime(primaryShift, true);
      return currentShiftTotal / primaryShiftTotal;
    },
    getAdjustedShiftProperties: function getAdjustedShiftProperties(currentShift, primaryShift) {
      var standardOvertimeTotalSeconds = primaryShift.standardOvertimeTotalSeconds,
          doubleOvertimeTotalSeconds = primaryShift.doubleOvertimeTotalSeconds,
          breakIsPaid = primaryShift.breakIsPaid,
          breakTotalSeconds = primaryShift.breakTotalSeconds;
      var partOfTotalWork = this.getPartOfTotalWork(currentShift, primaryShift);

      var adjustedStdOTSeconds = Math.round(standardOvertimeTotalSeconds * partOfTotalWork / _duration.default.MINUTE.IN_SECONDS) * _duration.default.MINUTE.IN_SECONDS;

      var adjustedDblOTSeconds = Math.round(doubleOvertimeTotalSeconds * partOfTotalWork / _duration.default.MINUTE.IN_SECONDS) * _duration.default.MINUTE.IN_SECONDS;

      var adjustedBreakTotalSeconds = Math.round(breakTotalSeconds * partOfTotalWork / _duration.default.MINUTE.IN_SECONDS) * _duration.default.MINUTE.IN_SECONDS;

      var totalWorkTime = this.getTotalWorkTime(currentShift, breakIsPaid, adjustedBreakTotalSeconds);
      return {
        adjustedStdOTSeconds: adjustedStdOTSeconds,
        adjustedDblOTSeconds: adjustedDblOTSeconds,
        adjustedBreakTotalSeconds: adjustedBreakTotalSeconds,
        totalWorkTime: totalWorkTime
      };
    },
    createSplitShift: function createSplitShift(baseShift, clockedInAt, clockedOutAt, primaryShift) {
      var breakIsPaid = primaryShift.breakIsPaid;
      var splitShift = this.store.createRecord('echo-pro/time-clock-shift', baseShift.toJSON());
      splitShift.setProperties({
        breakIsPaid: breakIsPaid,
        clockedInAt: clockedInAt.toISOString(),
        clockedOutAt: clockedOutAt.toISOString(),
        tips: 0,
        notes: '',
        jobGuid: baseShift.jobGuid,
        employeeGuid: baseShift.employeeGuid,
        standardWorkTimeTotalSeconds: _moment.default.duration(clockedOutAt.diff(clockedInAt)).asSeconds()
      });

      var _this$getAdjustedShif = this.getAdjustedShiftProperties(splitShift, primaryShift),
          adjustedStdOTSeconds = _this$getAdjustedShif.adjustedStdOTSeconds,
          adjustedDblOTSeconds = _this$getAdjustedShif.adjustedDblOTSeconds,
          adjustedBreakTotalSeconds = _this$getAdjustedShif.adjustedBreakTotalSeconds,
          totalWorkTime = _this$getAdjustedShif.totalWorkTime;

      splitShift.set('standardWorkTimeTotalSeconds', totalWorkTime - adjustedStdOTSeconds - adjustedDblOTSeconds);
      splitShift.set('standardOvertimeTotalSeconds', adjustedStdOTSeconds);
      splitShift.set('doubleOvertimeTotalSeconds', adjustedDblOTSeconds);
      splitShift.set('breakTotalSeconds', adjustedBreakTotalSeconds);
      return splitShift;
    },
    splitShiftByEndOfDay: function splitShiftByEndOfDay(shift, endOfDayTime) {
      var businessDay = (0, _moment.default)(shift.clockedInAt).startOf('day').add(endOfDayTime, 'ms');
      var clockedIn = (0, _moment.default)(shift.clockedInAt);
      var clockedOut = (0, _moment.default)(shift.clockedOutAt);

      var primaryShift = _objectSpread({}, shift.toJSON());

      primaryShift.breakIsPaid = shift.breakIsPaid;
      var shifts = [];

      if (clockedIn.isSameOrAfter(businessDay)) {
        businessDay.add(1, 'days');
      }

      if (clockedOut.isAfter(businessDay)) {
        shift.set('clockedOutAt', this.adjustShiftEnd(businessDay).toISOString());

        var _this$getAdjustedShif2 = this.getAdjustedShiftProperties(shift, primaryShift),
            adjustedStdOTSeconds = _this$getAdjustedShif2.adjustedStdOTSeconds,
            adjustedDblOTSeconds = _this$getAdjustedShif2.adjustedDblOTSeconds,
            adjustedBreakTotalSeconds = _this$getAdjustedShif2.adjustedBreakTotalSeconds,
            totalWorkTime = _this$getAdjustedShif2.totalWorkTime;

        shift.set('standardWorkTimeTotalSeconds', totalWorkTime - adjustedStdOTSeconds - adjustedDblOTSeconds);
        shift.set('standardOvertimeTotalSeconds', adjustedStdOTSeconds);
        shift.set('doubleOvertimeTotalSeconds', adjustedDblOTSeconds);
        shift.set('breakTotalSeconds', adjustedBreakTotalSeconds);
        shifts.push(shift);

        if (_moment.default.duration(clockedOut.diff(businessDay)).asHours() < 24) {
          shifts.push(this.createSplitShift(shift, businessDay, clockedOut, primaryShift));
        } else {
          while (_moment.default.duration((0, _moment.default)(clockedOut).diff(businessDay)).asHours() > 24) {
            var splitShift = this.createSplitShift(shift, (0, _moment.default)(businessDay), this.adjustShiftEnd(businessDay.add(1, 'days')), primaryShift);
            shifts.push(splitShift);
          }

          shifts.push(this.createSplitShift(shift, businessDay, clockedOut, primaryShift));
        }
      } else {
        shifts.push(shift);
      }

      return shifts;
    },

    /**
     * @param {String} error - error message
     * @returns {String} flashMessage
     */
    errorMessageToFlashMessage: function errorMessageToFlashMessage(error) {
      /**
       * The only special case (yet) is the shifts overlap error message.
       * Such an error message requires timestamps reformatting: iso861 format => L LT format
       * */
      if (error.match(/Shift overlaps another shift with period: /)) {
        var dates = error.split('period: ')[1].split(' - ');
        return 'Shift overlaps another shift with period: ' + "".concat((0, _moment.default)(dates[0]).format(_dateTimeFormat.default.LOCALIZED_SHORT), " to ") + "".concat(dates[1] === 'now' ? 'now' : (0, _moment.default)(dates[1]).format(_dateTimeFormat.default.LOCALIZED_SHORT), ".");
      }

      return error;
    },
    handleSaveErrors: function handleSaveErrors(model, err) {
      var _this2 = this;

      this.set('isLoading', false);
      this.logger.log("".concat(err, " error occurred when saving ").concat(model, " changes"));
      this.responseMessages.getResponseErrors(err).forEach(function (details) {
        _this2.flashMessages.danger(_this2.errorMessageToFlashMessage(details), {
          class: 'ui error message'
        });
      });
    },
    handleSaveSuccess: function handleSaveSuccess() {
      var _this3 = this;

      this.actions.selectShift.call(this, this.selectedShift);
      this.set('isLoading', false); // Adding an action on our route is not the ideal fix here,
      // but since we are planning on restructuring this page shortly, it was
      // recommended to use the same behavior that was previously working.

      var refreshCallback = function refreshCallback() {
        _this3.flashMessages.success('Changes saved successfully!', {
          class: 'ui success message'
        });

        _this3.logger.log('Shift changes saved successfully!');
      };

      this.send('refreshPage', refreshCallback);
    },
    actions: {
      selectEmployee: function selectEmployee(employee) {
        if (this.selectedEmployeeId === employee.id) {
          this.set('selectedEmployeeId', null);
        } else {
          this.set('selectedEmployeeId', employee.id);
        }

        this.destroyNewShift();
        this.set('selectedShift', null);
        this.set('workTimeSnapshot', null);
        this.set('offset', 0);
        this.transitionToRoute('authenticated.locations.location.echo-pro.manage-pos.timeclock');
      },
      selectShift: function selectShift(shift) {
        var selectedShift = this.selectedShift;

        if (selectedShift) {
          this.destroyNewShift();
          this.set('selectedShift', null);
          this.set('workTimeSnapshot', null);

          if (selectedShift.id === shift.id) {
            this.transitionToRoute('authenticated.locations.location.echo-pro.manage-pos.timeclock');
          } else {
            this.transitionToRoute('authenticated.locations.location.echo-pro.manage-pos.timeclock.edit-shift', shift.id);
          }
        } else {
          this.transitionToRoute('authenticated.locations.location.echo-pro.manage-pos.timeclock.edit-shift', shift.id);
        }
      },
      addShift: function addShift() {
        if (!this.selectedEmployeeId) {
          this.flashMessages.clearMessages().danger('An Employee must be selected', {
            class: 'ui negative message'
          });
          return;
        }

        this.transitionToRoute('authenticated.locations.location.echo-pro.manage-pos.timeclock.create-shift');
      },
      deleteShift: function deleteShift() {
        var selectedShift = this.selectedShift;
        selectedShift.set('voided', true);
        this.actions.save.call(this);
      },
      clockOut: function clockOut(shift) {
        var breakTotal = 0;
        var shiftBreaks = this.store.peekAll('echo-pro/time-clock-break').filterBy('timeClockShiftGuid', shift.id);
        shiftBreaks.forEach(function (shiftBreak) {
          var breakStart = shiftBreak.startedAt;
          var breakEnd = shiftBreak.endedAt;

          if (!breakEnd) {
            shiftBreak.set('endedAt', (0, _moment.default)().toISOString());
          }

          breakTotal += _moment.default.duration((0, _moment.default)(breakEnd).diff(breakStart)).asSeconds();
        });

        if (breakTotal) {
          shift.set('breakTotalSeconds', Math.floor(breakTotal));
        }

        shift.set('clockedOutAt', (0, _moment.default)().toISOString());
      },
      closeShift: function closeShift(shift) {
        var _this$workTimeSnapsho;

        this.destroyNewShift();
        shift.rollbackAttributes(); // after setting values in observer rollbackAttributes() does not roll them back so doing it manually

        shift.setProperties(this.workTimeSnapshot);
        this.set('isPaidComputed', ((_this$workTimeSnapsho = this.workTimeSnapshot) === null || _this$workTimeSnapsho === void 0 ? void 0 : _this$workTimeSnapsho.breakIsPaid) !== undefined ? this.workTimeSnapshot.breakIsPaid : true);
        this.actions.selectShift.call(this, shift);
      },
      save: function save() {
        var _this4 = this;

        var shift = this.selectedShift;
        var editRecord = this.editRecord;
        var activeBreak = this.activeBreak;
        var breakValueChanged = !!shift.changedAttributes().breakTotalSeconds;

        var _this$store$peekRecor = this.store.peekRecord('echo-pro/setting/business', this.location.model.id),
            endOfDayTime = _this$store$peekRecor.endOfDayTime;

        this.flashMessages.clearMessages();
        this.set('isLoading', true);

        if (shift.voided) {
          return shift.save().then(this.handleSaveSuccess.bind(this)).catch(this.handleSaveErrors.bind(this, 'shift'));
        }

        editRecord.validate(shift).then(function () {
          var shifts = _this4.splitShiftByEndOfDay(shift, endOfDayTime);

          editRecord.saveRecords(shifts).then(function () {
            if (breakValueChanged) {
              _this4.updateBreaks(shifts).then(_this4.handleSaveSuccess.bind(_this4)).catch(_this4.handleSaveErrors.bind(_this4, 'break'));
            } else if (activeBreak && activeBreak.get('hasDirtyAttributes')) {
              activeBreak.save().then(_this4.handleSaveSuccess.bind(_this4)).catch(_this4.handleSaveErrors.bind(_this4, 'current break'));
            } else {
              _this4.handleSaveSuccess.call(_this4);
            }
          }, _this4.handleSaveErrors.bind(_this4, 'shift'));
        }).catch(function (validations) {
          _this4.set('isLoading', false);

          validations.forEach(function (message) {
            _this4.flashMessages.danger("".concat(message, "."), {
              class: 'ui negative message'
            });
          });
        });
        return false;
      },
      clearSearch: function clearSearch() {
        this.set('searchName', '');
      }
    }
  });

  _exports.default = _default;
});