define("lh4/controllers/authenticated/transactions/master-transactions/edit/refund", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    actions: {
      handleIframeLoad: function handleIframeLoad() {
        this.set('hasLoaded', true);
      },
      onClose: function onClose() {
        this.transitionToRoute(this.closeRoute);
      }
    }
  });

  _exports.default = _default;
});