define("lh4/templates/components/ui/icons/sidebar/reporting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UsP3WJqD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M19.001 3H5.00098C3.90098 3 3.00098 3.9 3.00098 5V19C3.00098 20.1 3.90098 21 5.00098 21H19.001C20.101 21 21.001 20.1 21.001 19V5C21.001 3.9 20.101 3 19.001 3ZM19.001 19H5.00098V5H19.001V19ZM7.00098 10H9.00098V17H7.00098V10ZM11.001 7H13.001V17H11.001V7ZM15.001 13H17.001V17H15.001V13Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/reporting.hbs"
    }
  });

  _exports.default = _default;
});