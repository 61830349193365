define("lh4/templates/components/ui/endless-scroll-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eFgP7ikJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"style\",true],[8],[0,\"\\n\\t.endless-scroll-simple {\\n\\t\\tmax-height: height;\\n\\t\\toverflow-y: auto;\\n\\t}\\n\"],[9],[0,\"\\n\"],[4,\"infinite-scroller\",null,[[\"class\",\"trigger-at\",\"scroll-debounce\",\"on-load-more\"],[\"endless-scroll-simple\",\"90%\",50,[28,\"action\",[[23,0,[]],\"loadMore\"],null]]],{\"statements\":[[0,\"\\t\"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[22,\"ui/loading-segment\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/endless-scroll-simple.hbs"
    }
  });

  _exports.default = _default;
});