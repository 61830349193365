define("lh4/components/jobs-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: added pagination for jobs
  var _default = Ember.Component.extend({
    paginationSelection: [5, 15, 30],
    actions: {
      previousPage: function previousPage() {
        this.sendAction('previousPage');
      },
      nextPage: function nextPage() {
        this.sendAction('nextPage');
      },
      // Pagination
      updatePagination: function updatePagination(id) {
        this.sendAction('updatePagination', id);
      },
      // Employee Record Controlls
      editJob: function editJob(guid) {
        this.sendAction('editJob', guid);
      }
    }
  });

  _exports.default = _default;
});