define("lh4/templates/authenticated/epro/external-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wNSOMRX5",
    "block": "{\"symbols\":[\"table\",\"record\",\"@model\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n \"],[7,\"div\",true],[10,\"class\",\"row-direction-list-controls\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"filter-controls\"],[8],[0,\"\\n   \"],[5,\"ui/search/list-search\",[],[[\"@searchTerm\",\"@placeholder\",\"@onUpdated\",\"@data-test-id\"],[[22,\"searchName\"],\"Search External Orders\",[28,\"action\",[[23,0,[]],\"onTermUpdated\"],null],\"search-control\"]]],[0,\"\\n  \"],[9],[0,\"\\n \"],[9],[0,\"\\n\\n \"],[5,\"tables/responsive-table\",[],[[\"@excludeNew\",\"@fixed\",\"@headers\",\"@limit\",\"@offset\",\"@rows\",\"@sortAsc\",\"@sortBy\",\"@totalCount\",\"@data-test-id\"],[true,true,[22,\"headers\"],[22,\"limit\"],[22,\"offset\"],[23,3,[\"externalOrders\"]],[22,\"sortAsc\"],[22,\"order\"],[23,3,[\"externalOrders\",\"meta\",\"count\"]],\"table\"]],{\"statements\":[[0,\"\\n  \"],[6,[23,1,[\"cell\"]],[[12,\"headerName\",\"headers.1.name\"],[12,\"data-test-id\",\"header-name\"]],[[],[]],{\"statements\":[[0,\"\\n   \"],[7,\"div\",true],[10,\"class\",\"ellipsis-container-multiple-items\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"logoUrl\"]]],null,{\"statements\":[[0,\"     \"],[7,\"img\",true],[11,\"src\",[23,2,[\"logoUrl\"]]],[10,\"alt\",\"Service Logo\"],[10,\"class\",\"logo-icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[23,2,[\"name\"]],false],[0,\"\\n   \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[6,[23,1,[\"cell\"]],[[12,\"headerName\",\"headers.2.name\"],[12,\"data-test-id\",\"header-revCenter\"]],[[],[]],{\"statements\":[[0,\"\\n   \"],[7,\"div\",true],[10,\"class\",\"ellipsis-container\"],[8],[0,\"\\n    \"],[1,[23,2,[\"revenueCenterName\"]],false],[0,\"\\n   \"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n \"]],\"parameters\":[1,2]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/epro/external-orders.hbs"
    }
  });

  _exports.default = _default;
});