define("lh4/controllers/authenticated/internet-payments/risk/risk-rules", ["exports", "lh4/controllers/internet-payments/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS_WITH_SCORE = [{
    field: 'sourceKountRuleId',
    name: 'Rule ID'
  }, {
    field: 'name',
    name: 'Rule'
  }, {
    field: 'score',
    name: 'Score Value'
  }, {
    field: 'isEnabled',
    name: 'Status'
  }];
  var HEADERS_WITH_ACTION = [{
    field: 'sourceKountRuleId',
    name: 'Rule ID'
  }, {
    field: 'name',
    name: 'Rule'
  }, {
    field: 'action',
    name: 'Action'
  }, {
    field: 'isEnabled',
    name: 'Status'
  }];

  var _default = _generalListController.default.extend({
    router: Ember.inject.service(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    headersWithScore: HEADERS_WITH_SCORE,
    headersWithAction: HEADERS_WITH_ACTION,
    actions: {
      onRowClick: function onRowClick(record) {
        if (this.can.can('manage risk rules in internet-payment')) {
          this.transitionToRoute('authenticated.internet-payments.risk.risk-rules.edit', record.id);
        }
      }
    }
  });

  _exports.default = _default;
});