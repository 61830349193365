define("lh4/models/echo-pro/price-schedule-group", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Price Schedule Group Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 255,
        description: 'Price Schedule Group Name'
      })]
    },
    priceSchedules: {
      description: 'Price Schedule Name',
      validators: [(0, _emberCpValidations.validator)('array-unique-field', {
        uniqueField: 'name',
        uniqueFieldFilter: function uniqueFieldFilter(priceSchedule) {
          return !!priceSchedule.name && !priceSchedule.isVoided;
        },
        dependentKeys: ['model.priceSchedules.@each.name', 'model.priceSchedules.[]'],
        getMessage: function getMessage() {
          return 'Price Schedule names must be unique within Price Schedule Group';
        }
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isVoided: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    priceSchedules: (0, _relationships.hasMany)('echo-pro/price-schedule'),
    addSchedule: function addSchedule(schedulesCount) {
      var schedule = {
        guid: null,
        locationId: this.locationId,
        name: "Schedule ".concat(schedulesCount ? schedulesCount + 1 : '1'),
        createdAt: new Date()
      };
      return this.store.createRecord('echo-pro/price-schedule', schedule);
    }
  });

  _exports.default = _default;
});