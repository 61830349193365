define("lh4/mirage/routes/gift-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageShift4GiftCardsServer;

  function setMirageShift4GiftCardsServer(server) {
    // Gift Card
    server.get('/s4-virtual-gift-cards/merchant/:locationId', function () {
      return {
        merchant: {
          businessLegalName: '',
          businessDba: '',
          firstName: 'Tom',
          lastName: 'Johnson',
          address: '2202 N Irving Street',
          address2: null,
          country: 'US',
          city: 'Allentown',
          state: 'PA',
          zip: '18109',
          phone: '8002010461',
          email: 'tschnaars@harbortouch.com',
          mid: '0000000215',
          fileId: '',
          logo: null
        },
        accessToken: 's4s_access_token'
      };
    });
  }
});