define("lh4/routes/authenticated/social-media/analytics/account/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      from: {
        replace: true
      },
      to: {
        replace: true
      }
    },
    ajax: Ember.inject.service(),
    accountApi: Ember.inject.service('social-media/account-api'),
    errorHandler: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    model: function model(_ref) {
      var _this = this;

      var from = _ref.from,
          to = _ref.to;
      this.flashMessagesHelper.clearMessages();

      var _this$modelFor = this.modelFor('authenticated.social-media.analytics.account'),
          account = _this$modelFor.account;

      var _this$modelFor2 = this.modelFor('authenticated.social-media'),
          accounts = _this$modelFor2.accounts;

      return Ember.RSVP.hash({
        account: account,
        accounts: accounts.filterBy('externalId').sortBy('createdAt').reverse(),
        analytics: this.accountApi.loadAnalyticsHelper(account, {
          from: from,
          to: to
        }).catch(function (err) {
          _this.errorHandler.handleResponseError(err, 'Could not load account analytics');

          return account.reload().then(function () {
            return {
              postData: {
                posts: [],
                meta: {}
              },
              graphData: {
                data: [],
                meta: {}
              }
            };
          });
        })
      });
    }
  });

  _exports.default = _default;
});