define("lh4/templates/authenticated/hardware/skytab-pos-bundle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t7bQ/R/e",
    "block": "{\"symbols\":[],\"statements\":[[5,\"hardware/product-page\",[],[[\"@productName\",\"@price\",\"@heading\",\"@about\",\"@productDetails\",\"@productDetailsImageSrc\",\"@options\",\"@selectedAddToOrderOptions\",\"@itemsToAdd\"],[[23,0,[\"productName\"]],[23,0,[\"price\"]],[23,0,[\"heading\"]],[23,0,[\"about\"]],[23,0,[\"productDetails\"]],[23,0,[\"productDetailsImageSrc\"]],[23,0,[\"options\"]],[23,0,[\"selectedAddToOrderOptions\"]],[23,0,[\"itemsToAdd\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/hardware/skytab-pos-bundle.hbs"
    }
  });

  _exports.default = _default;
});