define("lh4/components/epro/revenue-centers/revenue-center-form", ["exports", "lh4/enums/products"], function (_exports, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var POS_GROUP_NAME = 'SkyTab POS';
  var MOBILE_GROUP_NAME = 'SkyTab Mobile';

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    showTerminalsTable: false,
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    headers: [{
      name: 'Name',
      field: 'name',
      width: 'twelve',
      sortable: false
    }, {
      name: 'Status',
      field: 'isActive',
      width: 'four',
      sortable: false
    }],
    terminalsSorted: Ember.computed('terminals.@each.isMobile', function getter() {
      var terminals = this.terminals ? _toConsumableArray(this.terminals) : [];
      var posList = [{
        groupName: POS_GROUP_NAME,
        isGroup: true
      }].concat(_toConsumableArray(terminals.filter(function (_ref) {
        var product = _ref.product;
        return product === _products.default.EW;
      })));
      var mobileList = [{
        groupName: MOBILE_GROUP_NAME,
        isGroup: true
      }].concat(_toConsumableArray(terminals.filter(function (_ref2) {
        var product = _ref2.product;
        return product === _products.default.GPATT;
      })));
      var result = [];

      if (posList.length > 1) {
        result = result.concat(posList);
      }

      if (mobileList.length > 1) {
        result = result.concat(mobileList);
      }

      return result;
    }),
    actions: {
      handleInput: function handleInput(value) {
        this.changeset.set('revenueCenterName', value);
      }
    }
  });

  _exports.default = _default;
});