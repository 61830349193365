define("lh4/templates/components/ui/dates/date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S5H4MvHq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"ui icon \",[28,\"if\",[[24,[\"isLoading\"]],\"disabled\"],null],\" button\"]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"navigate\",\"backward\"]],[8],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"left chevron icon\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"button\",true],[11,\"class\",[29,[\"ui toggle \",[28,\"if\",[[24,[\"isLoading\"]],\"loading\"],null],\" button date-range-picker-button\"]]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\"],[1,[28,\"moment-format\",[[24,[\"start\"]],[24,[\"format\"]],[24,[\"sourceFormat\"]]],null],false],[1,[22,\"separator\"],false],[1,[28,\"moment-format\",[[24,[\"end\"]],[24,[\"format\"]],[24,[\"sourceFormat\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",[29,[\"ui icon \",[28,\"if\",[[24,[\"isLoading\"]],\"disabled\"],null],\" button\"]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"navigate\",\"forward\"]],[8],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"right chevron icon\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/dates/date-range-picker.hbs"
    }
  });

  _exports.default = _default;
});