define("lh4/utils/model-validators", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numberFloatValidator = numberFloatValidator;
  _exports.numberIntValidator = numberIntValidator;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Builds `ember-cp-validations` validator for Float numbers
   * @param {number} from - min value
   * @param {number} to - max value
   * @param {Object} optsExtra - additional `validator('number', {...})` options
   * @returns {Object} validator('number')
   */
  function numberFloatValidator() {
    var from = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var to = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Number.MAX_SAFE_INTEGER;
    var optsExtra = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return (0, _emberCpValidations.validator)('number', _objectSpread({
      allowBlank: true,
      allowString: true,
      integer: false,
      gte: from,
      lte: to
    }, optsExtra));
  }
  /**
   * Builds `ember-cp-validations` validator for Integer numbers
   * @param {number} from - min value
   * @param {number} to - max value
   * @param {Object} optsExtra - additional `validator('number', {...})` options
   * @returns {Object} validator('number')
   */


  function numberIntValidator() {
    var from = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var to = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Number.MAX_SAFE_INTEGER;
    var optsExtra = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return (0, _emberCpValidations.validator)('number', _objectSpread({
      allowBlank: true,
      allowString: true,
      integer: true,
      gte: from,
      lte: to
    }, optsExtra));
  }
});