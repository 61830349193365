define("lh4/components/epro/accounting/gratuity-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    corporation: Ember.inject.service(),
    locale: Ember.inject.service(),
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.gratuityTypeOptions = [{
        value: true,
        name: "Flat (".concat(this.locale.currencySymbol, ")")
      }, {
        value: false,
        name: 'Percentage (%)'
      }];
    },
    taxSetOptions: Ember.computed.map('taxSets', function (_ref) {
      var name = _ref.name,
          value = _ref.id,
          isActive = _ref.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    })
  });

  _exports.default = _default;
});