define("lh4/components/reputation/star-or-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    ratingText: Ember.computed('rating', 'maxRating', function getter() {
      return this.maxRating ? "".concat(this.rating, " / ").concat(this.maxRating) : this.rating;
    }),
    isRecommendation: Ember.computed('isRecommended', function getter() {
      return typeof this.isRecommended === 'boolean';
    })
  });

  _exports.default = _default;
});