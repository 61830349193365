define("lh4/routes/authenticated/locations/location/revenue-centers/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'revenueCenter',
    model: function model(_ref) {
      var revenueCenterGuid = _ref.revenueCenterGuid;
      return Ember.RSVP.hash({
        revenueCenter: this.store.findRecord('echo-pro/revenue-center', revenueCenterGuid)
      });
    }
  });

  _exports.default = _default;
});