define("lh4/mixins/unauthenticated-route-mixin", ["exports", "jquery", "lh4/config/environment", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ember-uuid", "cryptojs"], function (_exports, _jquery, _environment, _unauthenticatedRouteMixin, _emberUuid, _cryptojs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CODE_CHALLENGE_STORAGE = 'ember-last-challenge';
  /**
   * Convert a base64 encoded string to a base64url encoded string.
   * Stolen from base64url
   * https://github.com/brianloveswords/base64url/blob/5a65f0642d0166a61f0fada8d556152a14ae19b3/src/base64url.ts#L23
   *
   * @param {string} base64 base64 encoded string
   * @returns {string} base64url encoded string
   */

  function fromBase64(base64) {
    return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }
  /**
   * Check if uri contains encoded values
   * i.e. ?,=,&,/ etc
   * @param {string} uri - current uri
   * @returns {bool} true of yes false if no
   */


  function containsEncodedComponents(uri) {
    return decodeURI(uri) !== decodeURIComponent(uri);
  }

  var _default = Ember.Mixin.create(_unauthenticatedRouteMixin.default, {
    routeIfAlreadyAuthenticated: 'authenticated.dashboard2',
    router: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      /**
       * Check if any of `currentRoute?.queryParams` are decoded correctly
       * containsEncodedComponents is used to check if query params have encoded parts
       * loop on `currentRoute?.queryParams` object keys to check
       * if there's any that contain `=` i.e. `token=abc: true`
       *
       * Some security services format links from emails to prevent tracking pixels, etc.
       * and double encode query params
       * @example
       * formatted by security service:
       *   https://clicktime.symantec.com/a/1/ouside_token%3D&u=https%3A%2F%2Flh.harbortouch.com
       *     %2F%23%2Freset-password%3Ftoken%253Dc59f2fbd-7897-4f34-86bb-968b057e5590
       * decoded by the said security service
       *   https://lh.harbortouch.com/#/reset-password?token%3Dc59f2fbd-7897-4f34-86bb-968b057e5590
       *
       * it needs to be decoded again to produce result that currentRoute?.queryParams can read correctly
       * no encoding - =
       * encode once - %3D
       * encode twice - %253D
       */
      var currentRoute = this.router.currentRoute;
      var code = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.queryParams.code;

      if (containsEncodedComponents(window.location.search) && Object.keys((currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.queryParams) || {}).some(function (qp) {
        return qp.match(/=/);
      })) {
        window.location.replace(decodeURIComponent(window.location.href));
        transition.abort();
      }

      if (this.get('session.isAuthenticated') || !_environment.default.oauth2 || !_environment.default.oauth2.enabled) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        this._super.apply(this, [transition].concat(args));

        return;
      }

      if (code) {
        var codeVerifier = window.localStorage.getItem(CODE_CHALLENGE_STORAGE);
        this.session.authenticate('authenticator:oauth2-custom', {
          code: code,
          codeVerifier: codeVerifier
        }).catch(function (e) {
          var errorString = e || e.error ? e.error.message : e;

          _this.set('isLoading', false);

          (0, _jquery.default)('.sign-in-form').addClass('error');

          _this.set('errMessage', errorString);
        });
        transition.abort();
        return;
      }

      var challengeVerifier = (0, _emberUuid.v4)();
      var challenge = fromBase64(_cryptojs.default.enc.Base64.stringify(_cryptojs.default.SHA256(challengeVerifier)));
      window.localStorage.setItem(CODE_CHALLENGE_STORAGE, challengeVerifier);
      window.location.replace("".concat(_environment.default.oauth2.authBaseUri, "?response_type=").concat(_environment.default.oauth2.responseType) + "&client_id=".concat(_environment.default.oauth2.clientId, "&redirect_uri=").concat(_environment.default.oauth2.redirectUri) + "&code_challenge=".concat(challenge, "&code_challenge_method=S256"));
      transition.abort();
    }
  });

  _exports.default = _default;
});