define("lh4/enums/route-slug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ROUTE_SLUG = void 0;
  var ROUTE_SLUG = Object.freeze({
    'master-transactions': 'authenticated.transactions.master-transactions'
  });
  _exports.ROUTE_SLUG = ROUTE_SLUG;
  var _default = ROUTE_SLUG;
  _exports.default = _default;
});