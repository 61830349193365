define("lh4/routes/authenticated/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(mid) {
      return Ember.RSVP.hash({
        orders: this.store.query('supply-orders/order', {
          mid: mid.merchantId,
          limit: 100
        })
      });
    }
  });

  _exports.default = _default;
});