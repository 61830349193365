define("lh4/mirage/factories/epro-settings-customer", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    allowHouseAccounts: '1',
    allowLoyalty: '0',
    allowScanBarcodesAndCards: '0',
    defaultCity: 'Bethlehem',
    defaultState: 'PA',
    defaultZip: '18018',
    houseThresholdAmount: '0.00',
    houseTimeLimit: '90',
    numOrdersToRecall: '30',
    requireAddress: '1',
    requireEmail: '0',
    requirePhoneNumber: '0',
    requireZip: '0'
  });

  _exports.default = _default;
});