define("lh4/templates/components/ui/dropdown/reservation-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3JkNTHtC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"reservation-header\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"cal-label\"],[8],[0,\"\\n\\t\\tReservations for\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"cal-dropdown\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"pikaday-input\",null,[[\"class\",\"value\",\"format\",\"readonly\",\"onSelection\"],[\"reservation-pikaday\",[24,[\"selectedDate\"]],\"MM/DD/YYYY\",true,[28,\"action\",[[23,0,[]],\"selectDateRange\"],null]]]],false],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"dropdown icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"focusCalendar\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/dropdown/reservation-date.hbs"
    }
  });

  _exports.default = _default;
});