define("lh4/routes/authenticated/loyalty/promotions/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'promotion',
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    model: function model(_ref) {
      var promotionId = _ref.promotionId;
      return Ember.RSVP.hash({
        promotion: this.store.findRecord('loyalty/promotion', promotionId),
        programs: this.modelFor('authenticated.loyalty.promotions').programs
      });
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
      // that doesn't exist in the loyalty promotion context because they are not location dependent
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Loyalty Promotions Update Started',
        'Entry Type': 'Edit'
      });
    }
  });

  _exports.default = _default;
});