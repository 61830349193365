define("lh4/components/ui/chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="component chip">
  	<span>{{@value}}</span>
  	<button {{on "click" @onRemove}}><i class="icon times"></i></button>
  </div>
  
  */
  {
    id: "mA6DE+EG",
    block: "{\"symbols\":[\"@value\",\"@onRemove\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"component chip\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,2,[]]]],[8],[7,\"i\",true],[10,\"class\",\"icon times\"],[8],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/chip.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});