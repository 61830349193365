define("lh4/components/customer-engagement/campaign-edit-wizard/campaign-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessagesHelper: Ember.inject.service(),
    store: Ember.inject.service(),
    location: Ember.inject.service(),
    classNames: ['ui', 'segment', 'campaign', 'settings'],
    locations: [],
    campaign: null,
    title: null,
    isLoading: false,
    saveLocationSettings: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var locations = this.get('locations');
      var campaign = this.get('campaign');

      if (campaign.get('isNew')) {
        var defaultLocationId = this.get('location.model.id') || locations.get('firstObject.id');
        Ember.run.next(function () {
          return _this.send('selectLocation', defaultLocationId);
        });
      }
    },
    currentValidation: Ember.computed.alias('campaign.validations.errors.firstObject.attribute'),
    inviteRuleCombined: Ember.computed('campaign.customerVisitCount', 'campaign.customerLastVisitWithinDays', 'campaign.customerNotVisitedWithinDays', function getter() {
      var count = this.get('campaign.customerVisitCount');
      var lastVisit = this.get('campaign.customerLastVisitWithinDays');
      var notVisited = this.get('campaign.customerNotVisitedWithinDays');
      return "".concat(count, " ").concat(lastVisit, " ").concat(notVisited);
    }),
    actions: {
      setUseAliasEmail: function setUseAliasEmail(checked) {
        this.set('campaign.useAliasEmail', !checked);
      },
      selectLocation: function selectLocation(value) {
        if (this.get('campaign.locationId') === value) {
          return;
        }

        var locationId = parseInt(value, 10);
        this.set('campaign.locationId', locationId);
        this.send('applyLocationSettings', locationId);
      },
      applyLocationSettings: function applyLocationSettings(locationId) {
        var _this2 = this;

        if (!locationId) {
          return;
        }

        var campaign = this.get('campaign');
        this.set('isLoading', true);
        this.store.findRecord('customer-engagement/setting', campaign.get('locationId')).then(function (settings) {
          _this2.set('selectedSettings', settings);

          campaign.set('aliasName', settings.get('aliasName'));
          campaign.set('aliasEmail', settings.get('aliasEmail'));
        }).finally(function () {
          return _this2.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});