define("lh4/components/reports/report-table", ["exports", "lh4/utils/string-type"], function (_exports, _stringType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isLoading: false,
    classNames: ['report-table'],
    displayedRows: Ember.computed('currentReport.rows.[]', function getter() {
      return this.get('currentReport.rows');
    }),
    actions: {
      searchReport: function searchReport(searchText) {
        this.set('searchError', false);

        if (!searchText) {
          this.set('isLoading', false);
          return this.set('displayedRows', this.get('currentReport.rows'));
        }

        var formatType = (0, _stringType.default)(searchText); // Column indexes in which the search will be performed

        var filterType = [1, 3, 4, 6, 7, 9];

        if (formatType !== _stringType.STRING_TYPE.NUMBERS) {
          this.set('isLoading', false);
          this.set('displayedRows', []);
          this.set('searchError', true);
          return false;
        }

        var filteredRows = this.get('currentReport.rows').filter(function (row) {
          return filterType.some(function (typeKey) {
            return row[typeKey].toString().toLowerCase().includes(searchText);
          });
        });
        this.set('displayedRows', filteredRows);
        this.set('isLoading', false);
        return false;
      }
    }
  });

  _exports.default = _default;
});