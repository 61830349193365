define("lh4/instance-initializers/custom-intl", ["exports", "lh4/utils/intl/missing-message"], function (_exports, _missingMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var intlService = application.lookup('service:intl');
    (0, _missingMessage.setIntlService)(intlService);
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});