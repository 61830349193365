define("lh4/templates/components/ui/dropdown/export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lWIyDZ5m",
    "block": "{\"symbols\":[\"format\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"item export\"],[11,\"data-tooltip\",[22,\"errorMessage\"]],[10,\"data-inverted\",\"\"],[10,\"data-position\",\"top right\"],[8],[0,\"\\n\"],[4,\"ui-dropdown\",null,[[\"class\",\"action\"],[[28,\"concat\",[\"primary basic button pointing \",[28,\"unless\",[[24,[\"enabled\"]],\" disabled\"],null],[28,\"if\",[[24,[\"hasError\"]],\" negative\"],null]],null],\"hide\"]],{\"statements\":[[0,\"\\t\\tExport\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"formats\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"a\",true],[10,\"class\",\"item\"],[10,\"href\",\"#\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"download\",[23,1,[\"format\"]]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[7,\"i\",true],[10,\"class\",\"file text outline icon\"],[8],[9],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/dropdown/export.hbs"
    }
  });

  _exports.default = _default;
});