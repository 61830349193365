define("lh4/helpers/title-to-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.titleToClass = titleToClass;
  _exports.default = void 0;

  /**
   * Returns title's class
   * @param {String} value - title
   * @returns {String} - title's class
   */
  function titleToClass(value) {
    if (value[0]) {
      return value[0].split(' ').join('-').toLowerCase();
    }

    return '';
  }

  var _default = Ember.Helper.helper(titleToClass);

  _exports.default = _default;
});