define("lh4/components/ui/dates/mobile-datetime-range-picker", ["exports", "lh4/enums/date-range-title", "lh4/enums/duration", "lh4/utils/date-moment", "lh4/utils/no-op", "moment", "jquery", "lh4/components/ui/dates/default-custom-options"], function (_exports, _dateRangeTitle, _duration, _dateMoment, _noOp, _moment, _jquery, _defaultCustomOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATETIME_LOCAL_FORMAT = 'YYYY-MM-DDTHH:mm';
  var DATE_FORMAT = 'YYYY-MM-DD';
  var DATETIME_LOCAL_FORMAT_START_MINUTES = 'YYYY-MM-DDTHH:00';
  var DATETIME_LOCAL_FORMAT_END_MINUTES = 'YYYY-MM-DDTHH:59';
  var FRONTEND_DEFAULT_DATE_FORMAT = 'MM/DD/YYYY'; // To show the date on frontend view

  var getDate = function getDate(date, dateFormat) {
    var isBusinessDay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return isBusinessDay ? (0, _moment.default)(date, dateFormat) : _moment.default.utc(date, dateFormat);
  };

  var _default = Ember.Component.extend({
    classNames: ['mobile-datetime-range-picker'],
    end: null,
    maxDate: null,
    maxDays: null,
    maxToDate: null,
    start: null,
    ranges: Ember.computed(function () {
      return (0, _dateMoment.getDefaultDateRanges)();
    }),
    rangeTitle: null,
    type: 'datetime-local',
    customOptions: _defaultCustomOptions.default,
    onChange: _noOp.default,
    isBusinessDay: true,
    isOpen: false,
    customClass: null,
    iconNameInitial: 'right',
    iconNameToggle: 'left',
    iconName: 'right',
    hasMinutesDisabled: Ember.computed('customOptions', function hasMinutesDisabled() {
      var _this$customOptions = this.customOptions,
          customOptions = _this$customOptions === void 0 ? _defaultCustomOptions.default : _this$customOptions;
      return customOptions.hasMinutesDisabled;
    }),
    defaultStart: Ember.computed('start', function defaultStart() {
      var startDate = typeof this.start === 'string' ? (0, _moment.default)(this.start, 'MM/DD/YYYY hh:mm A') : this.start;
      return (0, _moment.default)(startDate).format(FRONTEND_DEFAULT_DATE_FORMAT);
    }),
    defaultEnd: Ember.computed('end', function defaultEnd() {
      var endDate = typeof this.end === 'string' ? (0, _moment.default)(this.end, 'MM/DD/YYYY hh:mm A') : this.end;
      return (0, _moment.default)(endDate).format(FRONTEND_DEFAULT_DATE_FORMAT);
    }),
    startFormat: Ember.computed('type', 'hasMinutesDisabled', function startFormat() {
      var format = this.type === 'date' ? DATE_FORMAT : DATETIME_LOCAL_FORMAT;
      return this.hasMinutesDisabled ? DATETIME_LOCAL_FORMAT_START_MINUTES : format;
    }),
    endFormat: Ember.computed('type', 'hasMinutesDisabled', function endFormat() {
      var format = this.type === 'date' ? DATE_FORMAT : DATETIME_LOCAL_FORMAT;
      return this.hasMinutesDisabled ? DATETIME_LOCAL_FORMAT_END_MINUTES : format;
    }),
    formattedEnd: Ember.computed('end', 'endFormat', function formattedEnd() {
      return this.end ? (0, _moment.default)(this.end).format(this.endFormat) : '';
    }),
    formattedMaxFromDate: Ember.computed('maxDate', 'startFormat', function formattedMaxFromDate() {
      return this.maxDate ? this.maxDate.format(this.startFormat) : '';
    }),
    formattedMaxToDate: Ember.computed('maxDate', 'maxToDate', 'endFormat', function formattedMaxToDate() {
      if (this.maxToDate) {
        return this.maxToDate.format(this.endFormat);
      }

      if (this.maxDate) {
        return this.maxDate.format(this.endFormat);
      }

      return '';
    }),
    formattedStart: Ember.computed('start', 'startFormat', function formattedStart() {
      return this.start ? (0, _moment.default)(this.start).format(this.startFormat) : '';
    }),
    isCustom: Ember.computed('rangeTitle', function isCustomRange() {
      return (0, _dateRangeTitle.isCustom)(this.rangeTitle);
    }),
    step: Ember.computed('hasMinutesDisabled', function step() {
      // When step is equal/over 3600 sec. the minute select becomes greyed out
      // @see https://git.shift4payments.com/lighthouse/frontend/-/merge_requests/1997#note_426103
      return this.hasMinutesDisabled ? _duration.default.HOUR.IN_SECONDS : _duration.default.MINUTE.IN_SECONDS;
    }),
    getStartNormalized: function getStartNormalized(start) {
      if (!start) {
        return null;
      }

      var normalized = start.clone();

      if (this.hasMinutesDisabled) {
        normalized.set({
          minute: 0,
          second: 0,
          millisecond: 0
        });
      }

      return normalized;
    },
    getEndNormalized: function getEndNormalized(end) {
      if (!end) {
        return null;
      }

      var normalized = end.clone();

      if (this.hasMinutesDisabled) {
        normalized.set({
          minute: 59,
          second: 59,
          millisecond: 999
        });
      }

      return normalized;
    },
    getMaxToDateByStartDate: function getMaxToDateByStartDate(start) {
      var maxDays = this.maxDays,
          maxDate = this.maxDate,
          _this$customOptions2 = this.customOptions,
          customOptions = _this$customOptions2 === void 0 ? _defaultCustomOptions.default : _this$customOptions2;
      var maxDurationIso8601 = customOptions.maxDurationIso8601;
      var _this$maxToDate = this.maxToDate,
          maxToDate = _this$maxToDate === void 0 ? maxDate : _this$maxToDate;

      if (maxDays && start) {
        maxToDate = start.clone().add(maxDays, 'days');
      }

      if (maxDurationIso8601 && start) {
        var duration = _moment.default.duration(maxDurationIso8601);

        if (duration.isValid()) {
          maxToDate = start.clone().add(duration.asMilliseconds());
        }
      }

      if (maxDate && maxToDate) {
        maxToDate = _moment.default.min(maxDate, maxToDate);
      }

      return maxToDate ? (0, _moment.default)(maxToDate.clone(), this.endFormat) : null;
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (!this.start || !this.hasMinutesDisabled) {
        return;
      }

      var start = this.getStartNormalized(this.start);
      var maxToDate = this.getMaxToDateByStartDate(start);

      if (maxToDate) {
        this.set('maxToDate', maxToDate);
      }
    },
    actions: {
      openTray: function openTray() {
        this.set('customClass', 'active');
        this.set('iconName', this.iconNameToggle);
        (0, _jquery.default)('.datetime-range-picker-input').blur();
        this.set('isOpen', true);
      },
      closeTray: function closeTray() {
        this.set('customClass', null);
        this.set('iconName', this.iconNameInitial);
        this.set('isOpen', false);
      },
      onChangeFrom: function onChangeFrom(_ref) {
        var target = _ref.target;
        var value = target.value ? getDate(target.value, this.startFormat, this.isBusinessDay) : null;
        var start = this.getStartNormalized(value);
        var maxToDate = this.getMaxToDateByStartDate(start);
        var end = null;

        if (this.end) {
          end = (0, _moment.default)(this.end);

          if (end.isAfter(start) && maxToDate && end.isAfter(maxToDate)) {
            end = maxToDate.clone();
          }
        }

        this.setProperties({
          start: start,
          end: end,
          maxToDate: maxToDate
        });
        this.onChange(this.rangeTitle, start, end);
      },
      onChangeTo: function onChangeTo(_ref2) {
        var target = _ref2.target;
        var value = target.value ? getDate(target.value, this.endFormat, this.isBusinessDay) : null;
        var end = this.getEndNormalized(value);
        var start = this.start ? (0, _moment.default)(this.start) : null;
        this.set('end', end);
        this.onChange(this.rangeTitle, start, end);
      },
      customRangeSelect: function customRangeSelect(rangeTitle, initialDate, finalDate) {
        var start = initialDate ? getDate(initialDate, undefined, this.isBusinessDay) : null;
        var end = finalDate ? getDate(finalDate, undefined, this.isBusinessDay) : null;
        this.setProperties({
          start: start,
          end: end
        });
        return this.onChange(rangeTitle, start, end);
      },
      onRangeSelect: function onRangeSelect(key, rangeTitle) {
        if ((0, _dateRangeTitle.isCustom)(rangeTitle)) {
          var start = this.start ? getDate(this.start, undefined, this.isBusinessDay) : null;
          var end = this.end ? getDate(this.end, undefined, this.isBusinessDay) : null;
          return this.onChange(rangeTitle, start, end);
        }

        var _this$ranges$find = this.ranges.find(function (_ref3) {
          var title = _ref3.title;
          return title === rangeTitle;
        }),
            startDate = _this$ranges$find.startDate,
            endDate = _this$ranges$find.endDate;

        this.setProperties({
          start: startDate,
          end: endDate
        });
        return this.onChange(rangeTitle, startDate, endDate);
      }
    }
  });

  _exports.default = _default;
});