define("lh4/templates/components/internet-payments/status-code-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HhOmUpb7",
    "block": "{\"symbols\":[\"@status\",\"&attrs\",\"@statusCode\"],\"statements\":[[7,\"div\",false],[13,2],[12,\"class\",[29,[\"status-code-chip \",[28,\"lowercase\",[[23,1,[]]],null]]]],[12,\"data-test-status-code-chip\",\"\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[28,\"lowercase\",[[23,1,[]]],null],\"success\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"icon check\"],[10,\"data-test-status-code-chip\",\"success-icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[28,\"lowercase\",[[23,1,[]]],null],\"failed\"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"icon ban\"],[10,\"data-test-status-code-chip\",\"fail-icon\"],[8],[9],[0,\"\\n\\t\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\t\"],[1,[23,3,[]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/status-code-chip.hbs"
    }
  });

  _exports.default = _default;
});