define("lh4/routes/authenticated/locations/location/echo-pro/employee-schedule", ["exports", "moment", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/utils/day-to-week-range"], function (_exports, _moment, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin, _dayToWeekRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var dateFormat = 'YYYY-MM-DD';
  /**
   * Get date range
   * @param {Object} params - query params
   * @param {Number} weekStart - day number
   * @returns {Object} result date range
   */

  function getRange(params, weekStart) {
    if (params.from && params.to) {
      var range = {
        from: (0, _moment.default)(params.from, dateFormat),
        to: (0, _moment.default)(params.to, dateFormat).add(1, 'days').add(-1, 'milliseconds')
      };
      var correctWeekStartDay = (0, _moment.default)(range.from).day() === weekStart;
      return correctWeekStartDay ? range : (0, _dayToWeekRange.default)(range.from, weekStart); // fix broken range
    }

    return (0, _dayToWeekRange.default)((0, _moment.default)(), weekStart);
  }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    requiredPermissions: 'manage echo-pro/employee-scheduling',
    titleToken: 'Employee Schedule',
    tokenData: Ember.inject.service(),
    session: Ember.inject.service(),
    employeeSchedule: Ember.inject.service(),
    queryParams: {
      from: {
        refreshModel: true
      },
      to: {
        refreshModel: true
      }
    },
    redirect: function redirect() {
      var location = this.modelFor('authenticated.locations.location');

      if (this.can.can('manage workforce for echo-pro/workforce', location)) {
        this.replaceWith('authenticated.epro.workforce.links.scheduler');
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('preferences', this.get('tokenData.data.sub')).then(function (preferences) {
        var location = _this.modelFor('authenticated.locations.location');

        var range = getRange(params, preferences.get('weekStart'));
        var employeeSchedule = _this.employeeSchedule;
        return Ember.RSVP.hash({
          location: location,
          preferences: preferences,
          dateFormat: dateFormat,
          range: range,
          shifts: _this.store.query('echo-pro/employee-shift', {
            location: location.id,
            from: range.from,
            to: range.to
          }).then(function (shifts) {
            return employeeSchedule.wrapShifts(shifts);
          }),
          employees: _this.store.query('echo-pro/employee', {
            isActive: true,
            location: location.id
          })
        });
      });
    },
    actions: {}
  });

  _exports.default = _default;
});