define("lh4/components/radio-color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    useColorClass: false,
    colors: [],
    selected: null,
    useUnsetOption: false,
    actions: {
      onClick: function onClick(color) {
        this.set('selected', color);
      }
    }
  });

  _exports.default = _default;
});