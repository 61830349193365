define("lh4/templates/authenticated/deposit-breakdown/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZtO4dLaU",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"reports/deposit-breakdown-table\",null,[[\"report\",\"isLoading\",\"exportCSVUrl\",\"exportXLSUrl\"],[[24,[\"model\",\"report\"]],[24,[\"model\",\"isLoading\"]],[24,[\"model\",\"exportCSVUrl\"]],[24,[\"model\",\"exportXLSUrl\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/deposit-breakdown/report.hbs"
    }
  });

  _exports.default = _default;
});