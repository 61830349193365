define("lh4/mirage/routes/solo-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageSoloSettingsServer;

  function setMirageSoloSettingsServer(server) {
    server.get('/api/v2/solo/settings', function (schema, request) {
      return {
        settings: schema.soloSettings.where({
          locationId: request.queryParams.locationId
        }).models
      };
    });
    server.patch('/api/v2/solo/settings', function (schema, request) {
      var body = JSON.parse(request.requestBody);
      schema.soloSettings.create(body);
      return {
        settings: body
      };
    });
  }
});