define("lh4/routes/authenticated/locations/location/echo-pro/reservations/reservation/edit", ["exports", "jquery", "lh4/mixins/authenticated-route-mixin"], function (_exports, _jquery, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var loc = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        reservation: this.store.findRecord('reservation', params.reservationId),
        location: this.store.peekRecord('location', loc.id),

        /* eslint-disable-next-line no-restricted-globals */
        settings: this.store.query('reservations/setting', {
          location: location.id
        })
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var model = this.controller.model;

        if (model.reservation.hasDirtyAttributes && !transition.to.name.includes(this.routeName)) {
          var changed = model.reservation.changedAttributes();

          if (!Object.keys(changed).includes('statusId') || changed.statusId[1] !== 3) {
            transition.abort();
            this.controller.set('transition', transition);
            (0, _jquery.default)('#reservation-changed').modal('show');
          }
        }
      }
    }
  });

  _exports.default = _default;
});