define("lh4/components/reports/echo/ticket-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortByMap: [{
      orderNumber: 'Ticket #'
    }, 'Order Type', 'Completed At', 'Customer Name', {
      subTotal: 'Subtotal'
    }, {
      discountTotal: 'Discount'
    }, {
      taxTotal: 'Tax'
    }, 'Grand Total', 'Employee Close Name']
  });

  _exports.default = _default;
});