define("lh4/adapters/hospitality/customer", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    urlForQuery: function urlForQuery() {
      return "".concat(this.buildURL(), "/customer-database/customers");
    }
  });

  _exports.default = _default;
});