define("lh4/components/reports/deposit-breakdown-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['transactions-table'],

    /* @params */
    report: null,
    displayedRows: Ember.computed('report.rows.[]', function getter() {
      return this.get('report.rows');
    })
  });

  _exports.default = _default;
});