define("lh4/components/ui/time-picker/time-picker", ["exports", "lh4/utils/time-validators", "moment", "lh4/utils/time-converter"], function (_exports, _timeValidators, _moment, _timeConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @param {boolean} isManualInputAllowed - (dis)allows manual input of hours & minutes
     */
    isManualInputAllowed: true,

    /**
     * @param {Array} classNames - - array of CSS classes to be applied to the root element
     */
    classNames: [],

    /**
     * @param {number} currentHours - used for inner tracking of hours state
     */
    currentHours: 12,

    /**
     * @param {number} currentMinutes - used for inner tracking of minutes state
     */
    currentMinutes: 0,

    /**
     * @param {string} meridiemIndicator - displays either 'am' or 'pm'
     */
    meridiemIndicator: 'am',

    /**
     * @param {boolean} isPickerVisible - determines if picker is visible under the dropdown
     */
    isPickerVisible: false,

    /**
     * @param {number} minuteSteps - minute increment size
     */
    minuteSteps: 1,

    /**
     * @param {object} - moment object
     */
    value: null,

    /**
     * @callback onChange - fires when hours, minutes or meridiem indicator changes
     */
    onChange: null,
    media: Ember.inject.service(),
    currentHoursDisplay: Ember.computed('currentHours', {
      get: function get() {
        return this.currentHours < 10 ? "0".concat(this.currentHours) : this.currentHours;
      },
      set: function set(key, newDisplayValue) {
        if (!(0, _timeValidators.isValidHour)(newDisplayValue)) {
          // Default value of 'currentHours'
          return 1;
        }

        return newDisplayValue;
      }
    }),
    currentMinutesDisplay: Ember.computed('currentMinutes', {
      get: function get() {
        return this.currentMinutes < 10 ? "0".concat(this.currentMinutes) : this.currentMinutes;
      },
      set: function set(key, newDisplayValue) {
        if (!(0, _timeValidators.isValidMinute)(newDisplayValue)) {
          // Default value of 'currentMinutes'
          return 0;
        }

        return newDisplayValue;
      }
    }),
    combinedDisplayValue: Ember.computed('currentHoursDisplay', 'currentMinutesDisplay', 'meridiemIndicator', function getter() {
      return "".concat(this.currentHoursDisplay, ":").concat(this.currentMinutesDisplay, " ").concat(this.meridiemIndicator.toUpperCase());
    }),
    isReadonly: Ember.computed('isManualInputAllowed', function getter() {
      return !this.isManualInputAllowed;
    }),
    mobileTimeValue: Ember.computed('currentHours', 'currentMinutes', function getter() {
      var currentHoursDisplay = this.currentHoursDisplay,
          currentMinutesDisplay = this.currentMinutesDisplay,
          meridiemIndicator = this.meridiemIndicator;
      return (0, _moment.default)("1970-01-01 ".concat(currentHoursDisplay, ":").concat(currentMinutesDisplay, " ").concat(meridiemIndicator.toUpperCase()), 'YYYY-MM-DD LT').format('HH:mm');
    }),
    valueObserver: Ember.observer('currentHoursDisplay', 'currentMinutesDisplay', 'meridiemIndicator', function callback() {
      var currentHoursDisplay = this.currentHoursDisplay,
          currentMinutesDisplay = this.currentMinutesDisplay,
          meridiemIndicator = this.meridiemIndicator;

      var value = _moment.default.utc("1970-01-01 ".concat(currentHoursDisplay, ":").concat(currentMinutesDisplay, " ").concat(meridiemIndicator.toUpperCase()), 'YYYY-MM-DD LT');

      this.set('value', value);

      if (this.onChange) {
        this.onChange(this.value);
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this$value;

      var currentHoursDisplay = this.currentHoursDisplay,
          currentMinutesDisplay = this.currentMinutesDisplay;
      this.value = (_this$value = this.value) !== null && _this$value !== void 0 ? _this$value : _moment.default.utc("1970-01-01 ".concat(currentHoursDisplay, ":").concat(currentMinutesDisplay, " ").concat(this.meridiemIndicator.toUpperCase()), 'YYYY-MM-DD LT');

      var _convertFrom24To12For = (0, _timeConverter.default)(this.value.format('HH:mm')),
          hours = _convertFrom24To12For.hours,
          minutes = _convertFrom24To12For.minutes,
          meridiemIndicator = _convertFrom24To12For.meridiemIndicator;

      this.setProperties({
        currentHours: hours,
        currentMinutes: minutes,
        meridiemIndicator: meridiemIndicator.toLowerCase()
      }); // This is necessary to store in order to successfully tear it down in 'willDestroyElement'
      // because .bind returns new function and we need a proper reference

      this._onClickHandler = this._onClick.bind(this);
      window.addEventListener('click', this._onClickHandler);
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('click', this._onClickHandler);
    },
    _onClickHandler: null,
    _onClick: function _onClick(e) {
      // this.element refers to a wrapper div and we want to reference
      // div with a '.time-picker-wrapper' class but in order to support multiple
      // pickers on the same page we can't reference it directly via class name thus
      // the usage of this.element.firstChild
      if (!this.element.firstChild.contains(e.target)) {
        this.focusedOut();
      }
    },
    focusedOut: function focusedOut() {
      this.set('isPickerVisible', false);
    },
    adjustMinutes: function adjustMinutes() {
      if (this.currentMinutes > 59) {
        this.set('currentMinutes', 0);
        this.adjustHours(1);
        this.adjustMeridiemIndicator(true);
      }

      if (this.currentMinutes < 0) {
        this.set('currentMinutes', 59);
        this.adjustHours(-1);
        this.adjustMeridiemIndicator(false);
      }
    },
    adjustHours: function adjustHours(offset) {
      if (!Number.isNaN(Number(offset))) {
        // offset can be either 1 or -1
        this.set('currentHours', this.currentHours + offset);
      }

      if (this.currentHours > 12) {
        this.set('currentHours', 1);
        this.adjustMeridiemIndicator(true);
      }

      if (this.currentHours < 1) {
        this.set('currentHours', 12);
        this.adjustMeridiemIndicator(false);
      }
    },
    adjustMeridiemIndicator: function adjustMeridiemIndicator(isIncrement) {
      if (this.currentHours === 12 && isIncrement === true) {
        this.invertMeridiemIndicator();
      }

      if (this.currentHours === 11 && isIncrement === false) {
        this.invertMeridiemIndicator();
      }
    },
    invertMeridiemIndicator: function invertMeridiemIndicator() {
      if (this.meridiemIndicator === 'am') {
        this.set('meridiemIndicator', 'pm');
      } else if (this.meridiemIndicator === 'pm') {
        this.set('meridiemIndicator', 'am');
      }
    },
    actions: {
      togglePicker: function togglePicker() {
        this.set('isPickerVisible', !this.isPickerVisible);
      },
      incrementHours: function incrementHours() {
        this.incrementProperty('currentHours');
        this.adjustHours();
        this.adjustMeridiemIndicator(true);
      },
      decrementHours: function decrementHours() {
        this.decrementProperty('currentHours');
        this.adjustHours();
        this.adjustMeridiemIndicator(false);
      },
      incrementMinutes: function incrementMinutes() {
        this.set('currentMinutes', this.currentMinutes + this.minuteSteps);
        this.adjustMinutes();
      },
      decrementMinutes: function decrementMinutes() {
        this.set('currentMinutes', this.currentMinutes - this.minuteSteps);
        this.adjustMinutes();
      },
      setMeridiemIndicator: function setMeridiemIndicator(value) {
        this.set('meridiemIndicator', value);
      },
      onHoursChanged: function onHoursChanged() {
        this.set('currentHours', this.currentHoursDisplay);
      },
      onMinutesChanged: function onMinutesChanged() {
        this.set('currentMinutes', this.currentMinutesDisplay);
      },
      onMobileTimeValueChanged: function onMobileTimeValueChanged() {
        var _convertFrom24To12For2 = (0, _timeConverter.default)(this.mobileTimeValue),
            hours = _convertFrom24To12For2.hours,
            minutes = _convertFrom24To12For2.minutes,
            meridiemIndicator = _convertFrom24To12For2.meridiemIndicator;

        this.setProperties({
          currentHours: hours,
          currentMinutes: minutes,
          meridiemIndicator: meridiemIndicator
        });
      }
    }
  });

  _exports.default = _default;
});