define("lh4/routes/authenticated/virtual-terminal/refund-settings", ["exports", "ember-changeset-cp-validations", "ember-changeset", "lh4/mixins/routes/previous-route-mixin"], function (_exports, _emberChangesetCpValidations, _emberChangeset, _previousRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_previousRouteMixin.default, {
    titleToken: 'Virtual Terminal',
    location: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        origin: this.modelFor('authenticated.virtual-terminal').origin,
        refundSettings: this.modelFor('authenticated.virtual-terminal').refundSettings
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      var id = this.location.model.id;
      controller.setProperties({
        buildRecordChangeset: this.buildRecordChangeset,
        internalVerifyChangeset: this.buildRecordChangeset('verify-owner/owner', {
          id: id
        }),
        questionsVerifyChangeset: this.buildRecordChangeset('verify-owner/security', {
          id: id
        }),
        initiateChangeset: this.buildRecordChangeset('virtual-terminal/refund-settings/initiate', _objectSpread(_objectSpread({}, model.refundSettings.toJSON()), {}, {
          id: id
        })),
        signChangeset: this.buildRecordChangeset('virtual-terminal/refund-settings/sign', {
          id: id
        })
      });
    },
    buildRecordChangeset: function buildRecordChangeset(modelName, model) {
      var record = (model === null || model === void 0 ? void 0 : model.id) && this.store.peekRecord(modelName, model.id) || this.store.createRecord(modelName, model);

      if (!record.validations) {
        return new _emberChangeset.default(record);
      }

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(record),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      return new _emberChangeset.default(record, validateFn, validationMap, {
        skipValidate: true
      });
    },
    deactivate: function deactivate() {
      // Remove all of these because they should only exist for the duration of refund settings flow.
      this.store.unloadAll('virtual-terminal/refund-settings/sign');
      this.store.unloadAll('virtual-terminal/refund-settings/initiate');
      this.store.unloadAll('verify-owner/question');
      this.store.unloadAll('verify-owner/security');
      this.store.unloadAll('verify-owner/owner');
    }
  });

  _exports.default = _default;
});