define("lh4/controllers/authenticated/loyalty/programs/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    isEditing: true,
    tokenData: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    isUserLoyaltyProgramOwner: Ember.computed('model.program', function getter() {
      return this.model.program.ownerUserId === this.tokenData.data.sub;
    }),
    actions: {
      create: function create() {
        var _this = this;

        this._super(function () {
          _this.metrics.trackEvent('MixpanelCustom', {
            event: 'Loyalty Programs Update Completed',
            'Entry Type': 'Edit',
            Status: _this.changeset.get('isActive'),
            locationId: _this.location.model.id,
            merchantId: _this.location.model.merchantId
          });
        });
      }
    }
  });

  _exports.default = _default;
});