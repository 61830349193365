define("lh4/adapters/echo-pro/deploy", ["exports", "lh4/adapters/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var selectedLocation = snapshot.record.selectedLocation;
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(this.location.model.corpId, "/deployment/").concat(selectedLocation);
    }
  });

  _exports.default = _default;
});