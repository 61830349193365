define("lh4/components/customer-engagement/overview-stat-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var build = function build(title, value, className) {
    return {
      title: title,
      value: value,
      className: className
    };
  };

  var TYPES_WITH_RETURNED_CUSTOMERS = ['Invite', 'Welcome'];
  var TYPES_WITH_ANSWERED_SURVEYS = ['Survey'];

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    tagName: 'div',
    classNames: ['ui', 'segments'],
    classNameBindings: ['isMobile::horizontal'],
    isMobile: Ember.computed.alias('media.isMobile'),
    items: Ember.computed('type', 'sent', 'opened', 'clicked', 'unsubscribed', 'bounced', 'rated', function getter() {
      var type = this.get('type');
      var items = [build('Sent', this.get('sent')), build('Opened', this.get('opened'), 'opened'), build('Clicked', this.get('clicked'), 'clicked'), build('Unsubscribed', this.get('unsubscribed'), 'unsubscribed'), build('Bounced', this.get('bounced'), 'bounced')];

      if (TYPES_WITH_RETURNED_CUSTOMERS.includes(type)) {// TODO: un-comment this when we start supporting this KPI parameter
        // items.push(build('Returned customers', this.get('rated'), 'bounced'));
      } else if (TYPES_WITH_ANSWERED_SURVEYS.includes(type)) {
        items.push(build('Answered surveys', this.get('rated'), 'bounced'));
      }

      return items;
    })
  });

  _exports.default = _default;
});