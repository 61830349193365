define("lh4/utils/internet-payments/risk/domain-from-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = domainFromEmail;

  /**
   * @method domainFromEmail
   * @param {string} email - full email address, for example tom@shift4.com
   * @returns {string | null} returns domain (ex. shift4.com) only if valid email provided or null
   */
  function domainFromEmail(email) {
    if (!email) return null;
    var atIndex = email.lastIndexOf('@');

    if (atIndex !== -1) {
      return email.slice(atIndex + 1);
    }

    return null;
  }
});