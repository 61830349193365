define("lh4/components/reputation/location-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /* Params */
    allLocations: null,
    location: null,
    nothingSelectedText: 'All locations',
    allowSelectAll: true,

    /* Component */
    locationObjects: Ember.computed('allLocations.[]', function getter() {
      var locations = this.allLocations.map(function (_ref) {
        var name = _ref.name,
            id = _ref.id;
        return {
          name: name,
          id: id
        };
      });

      if (this.allowSelectAll) {
        locations.unshift({
          name: 'All locations',
          id: null
        });
      }

      return locations;
    }),
    actions: {
      locationSelected: function locationSelected(location) {
        this.set('location', location);
        this.sendAction('onLocationSelected', location);
      }
    }
  });

  _exports.default = _default;
});