define("lh4/controllers/authenticated/social-media/accounts/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    facebook: Ember.inject.service('social-media/facebook'),
    twitter: Ember.inject.service('social-media/twitter'),
    metrics: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    actions: {
      selectAccountType: function selectAccountType(type) {
        var _this = this;

        if (type === 'facebook-page') {
          this.facebook.redirectToFbAuth(type);
        } else if (type === 'twitter') {
          this.twitter.redirectToAuth().catch(function (err) {
            _this.flashMessagesHelper.clearMessages();

            _this.flashMessagesHelper.pushMessages(_this.responseMessages.getResponseErrors(err));
          });
        } // I am hesitant to put this here because it will still
        // produce a metric regardless if the account registration
        // errors


        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'Social Media',
          eventAction: "".concat(type, " account created")
        });
      },
      backToAccounts: function backToAccounts() {
        this.transitionToRoute('authenticated.social-media.accounts');
      }
    }
  });

  _exports.default = _default;
});