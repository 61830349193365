define("lh4/components/ui/dates/default-custom-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_CUSTOM_OPTIONS = Object.freeze({
    hasMinutesDisabled: false,
    maxDurationIso8601: null // @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals

  });
  var _default = DEFAULT_CUSTOM_OPTIONS;
  _exports.default = _default;
});