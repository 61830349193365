define("lh4/templates/authenticated/locations/location/echo-pro/manage-pos/customize-menu/department/edit-department", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pnqpGaGO",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/manage-pos/customize-menu/department/edit-department.hbs"
    }
  });

  _exports.default = _default;
});