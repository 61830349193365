define("lh4/routes/authenticated/supply-orders", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    //
    // Waiting on decision on how to move forward on the supply ordering feature
    // ref: https://shift4.atlassian.net/browse/LH-18716
    //
    // redirect(model, transition) {
    // 	if (
    // 		transition.targetName === 'authenticated.supply-orders'
    // 		&& this.can.can('view orders in skytab-order', this.location.model)
    // 	) {
    // 		this.transitionTo('authenticated.skytab-orders');
    // 	}
    // 	if (
    // 		transition.targetName === 'authenticated.supply-orders.location'
    // 		&& this.can.can('view orders in skytab-order', this.location.model)
    // 	) {
    // 		this.transitionTo('authenticated.skytab-orders');
    // 	}
    // 	if (
    // 		transition.targetName === 'authenticated.supply-orders.location.equipment'
    // 		&& this.can.can('view orders in skytab-order', this.location.model)
    // 	) {
    // 		this.transitionTo('authenticated.skytab-orders');
    // 	}
    // 	if (
    // 		transition.targetName === 'authenticated.supply-orders.location.supplies'
    // 		&& this.can.can('view orders in skytab-order', this.location.model)
    // 	) {
    // 		this.transitionTo('authenticated.skytab-orders');
    // 	}
    // 	if (
    // 		transition.targetName === 'authenticated.supply-orders.location.order'
    // 		&& this.can.can('view orders in skytab-order', this.location.model)
    // 	) {
    // 		this.transitionTo('authenticated.skytab-orders');
    // 	}
    // },
    beforeModel: function beforeModel() {
      if (this.can.cannot('access for us in general')) {
        this.transitionTo('/');
      }
    }
  });

  _exports.default = _default;
});