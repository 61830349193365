define("lh4/mirage/fixtures/transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    eventHistory: [{
      message: 'Settled',
      eventStatus: 'Settled',
      type: 'Sale',
      code: 'SETTLED',
      date: ''
    }, {
      message: 'Sent for Settlement',
      eventStatus: 'Sent for Settlement',
      type: 'Sale',
      code: 'SENT_FOR_SETTLEMENT',
      date: '2024-03-05T00:00:00.000Z'
    }, {
      message: 'Captured',
      eventStatus: 'Captured',
      type: 'Sale',
      code: 'CAPTURED',
      date: '2024-03-04T23:07:08.000Z'
    }, {
      message: 'Auth Approved',
      eventStatus: 'Auth Approved',
      type: 'Auth',
      code: 'AUTH_APPROVED',
      date: '2024-03-04T17:06:58.000Z'
    }],
    date: '2024-03-04 18:07:08',
    processingStatus: 'SETTLED',
    transactionId: 33333333,
    cardType: 'visa',
    cardNumber: '9999',
    customerName: '793ad8f364c5633',
    customerEmail: '',
    customerIpAddress: '',
    customerReference: '',
    issuerBIN: '99999',
    amount: '108.00',
    secondaryAmount: 0,
    primaryAmount: 0,
    type: 'SALE',
    riskStatus: null,
    riskId: '',
    acquirerResponse: '00 - Approval',
    invoice: '123456789987',
    cardToken: '12345678',
    merchantReference: '',
    merchantBatchNumber: '2000',
    fundingSource: '',
    hostResponse: null,
    shift4Mid: '8888888',
    shift4Serial: '999999',
    riskScore: null,
    secureStatus: '',
    personaScore: 0,
    deviceId: null,
    browser: null,
    isMobileDevice: null,
    osVersion: null,
    riskRules: [],
    weightedRiskTotal: 0,
    displayV2Preferred: false,
    chargebackAmount: '0.00',
    chargebackCode: '',
    chargebackDatePosted: null,
    chargebackReason: '',
    chargebackDescription: '',
    networkReferenceNumber: '',
    avsResponseCode: '',
    avsDescription: 'Unknown AVS response code',
    cvv2ResponseCode: '',
    cvv2Description: 'Unknown CVV2 response code',
    isRecurringPayment: false,
    transbaseId: 'TRANcde8b63bb9b4e82c700261cac76116f3',
    riskEvent: false,
    cardVerifyEvent: false,
    authEvent: true,
    authDateTz: '2024-03-04T17:06:58.000Z',
    captureEvent: true,
    captureDateTz: '2024-03-04T23:07:08.000Z',
    processedEvent: true,
    merchantBatchEvent: true,
    merchantBatchDateTz: '2024-03-05T00:00:00.000Z',
    eventStatus: 'SETTLED',
    transactionStatus: 'SETTLED',
    orderType: 'SALE',
    orderNumber: 2001,
    authorizationCode: '011090',
    serverName: 'Erica',
    ticketReference: '70552e8f-0d1b-44be-8e21-f1d2a476a94d',
    ticketPaymentReference: 'b34d75d3-075d-4472-8c0d-9b778fbab025',
    customerPhone: '',
    transType: 'SALE',
    rules: []
  };
  _exports.default = _default;
});