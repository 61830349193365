define("lh4/models/echo-pro/menu/modifier-bulk", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "lh4/mixins/model-cloneable", "lh4/utils/has-many-ids", "lh4/utils/stylesheet-to-color", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _modelCloneable, _hasManyIds, _stylesheetToColor, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({});
  _exports.Validations = Validations;
  var FIELD_SELECTION_GROUPS = {
    // General
    includeGeneralActive: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralPrintBold: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralSpecialRequests: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Modifier Sets
    includeModifierSets: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Nested Modifier Sets
    includeNestedModifierSets: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Printers
    includePrinters: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    })
  };

  var _default = _model.default.extend(Validations, FIELD_SELECTION_GROUPS, _modelCloneable.default, {
    // General
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    shouldPrintDistinctly: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    shouldPromptSpecialRequest: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    stposUiStylesheetObjectLinks: (0, _relationships.hasMany)('echo-pro/stylesheet-object-link'),
    color: Ember.computed('stposUiStylesheetObjectLinks.@each.stposUiStylesheetGuid', (0, _stylesheetToColor.default)('stposUiStylesheetObjectLinks')),
    // Modifier Sets
    modifierSets: (0, _relationships.hasMany)('echo-pro/modifier-set'),
    modifierSetIds: Ember.computed('modifierSets', (0, _hasManyIds.default)('modifierSets', 'echo-pro/modifier-set')),
    // Nested Modifier Sets
    nestedModifierSets: (0, _relationships.hasMany)('echo-pro/modifier-set', {
      inverse: 'nestedModifiers'
    }),
    nestedModifierSetIds: Ember.computed('nestedModifierSetIds', (0, _hasManyIds.default)('nestedModifierSets', 'echo-pro/modifier-set')),
    // Printers
    printers: (0, _relationships.hasMany)('echo-pro/printer'),
    printerIds: Ember.computed('printers', (0, _hasManyIds.default)('printers', 'echo-pro/printer')),
    // Online Ordering
    onlineActive: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    })
  });

  _exports.default = _default;
});