define("lh4/mirage/factories/epro-item", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    isActive: true,
    locationId: 1,
    color: function color() {
      return _faker.default.commerce.color();
    },
    courseGuid: function courseGuid() {
      return _faker.default.datatype.uuid();
    },
    glyph: null,
    name: 'Test Item 1',
    kitchenName: 'Test Kitchen 1',
    itemUpc: '1234567890',
    price: 10,
    cost: 5,
    isDepartmentOpenKey: true,
    isDisplayedOnItemTracking: true,
    isEbtItem: false,
    isFavorite: true,
    isItemPrinted: false,
    isOpenItem: true,
    isWeighted: false,
    notes: null,
    canBuyWithGiftCard: true,
    inheritedTaxes: null,
    defaultQty: 1,
    discountSets: null,
    minQty: 1,
    ordinal: 0,
    qtyOnHand: 6,
    shouldNotPrintOnCustomerReceipt: false,
    showQtyAlert: true,
    showSpecialRequest: true,
    tareWeight: null,
    taxSetGuid: function taxSetGuid() {
      return _faker.default.datatype.uuid();
    },
    onlineActive: false,
    onlineName: function onlineName() {
      return _faker.default.commerce.product();
    },
    onlinePrice: function onlinePrice() {
      return _faker.default.commerce.price();
    },
    onlineDescription: function onlineDescription() {
      return _faker.default.company.catchPhraseDescriptor();
    },
    defaultAvailability: false,
    availability: null
  });

  _exports.default = _default;
});