define("lh4/routes/authenticated/epro/payments/discounts/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    modelName: 'discount',
    model: function model(_ref) {
      var discountId = _ref.discountId;
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        discount: this.store.findRecord('echo-pro/discount', discountId),
        orderTypes: this.store.query('echo-pro/order-type', {
          location: location
        }),
        items: this.store.query('echo-pro/item', {
          location: location
        })
      });
    }
  });

  _exports.default = _default;
});