define("lh4/templates/components/reservations/reservation-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QP0wmA9B",
    "block": "{\"symbols\":[\"res\"],\"statements\":[[4,\"each\",[[24,[\"searchedList\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",false],[12,\"class\",\"reservation-link\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"displayMobileReservation\"]],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"models\"],[\"authenticated.locations.location.echo-pro.reservations.reservation.edit\",[28,\"array\",[[23,1,[\"locationId\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"res-list-info\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"res-time\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"time-format\",[[23,1,[\"partyTime\"]]],null],false],[0,\"\\t\\n\\t\\t\\t\\t\"],[9],[0,\" \"],[7,\"b\",true],[8],[1,[23,1,[\"customerFullName\"]],false],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"res-list-status\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"ui/buttons/reservation-status\",null,[[\"statusId\"],[[23,1,[\"statusId\"]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"user-info\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"user icon\"],[8],[9],[1,[23,1,[\"partySize\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"no-res-container\"],[8],[0,\"\\n\\t\\t\"],[7,\"b\",true],[8],[0,\"No reservations today!\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reservations/reservation-list.hbs"
    }
  });

  _exports.default = _default;
});