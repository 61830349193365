define("lh4/models/echo-pro/menu/pricing-table", ["exports", "ember-data", "ember-cp-validations", "lh4/mixins/model-cloneable", "ember-data/relationships", "lh4/utils/model-validators"], function (_exports, _emberData, _emberCpValidations, _modelCloneable, _relationships, _modelValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    defaultPrice: {
      description: 'Default Price',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      }), (0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        gte: 0
      }), (0, _modelValidators.numberFloatValidator)()]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, _modelCloneable.default, {
    itemGuid: _emberData.default.attr('string'),
    defaultPrice: _emberData.default.attr('string', {
      isCopyable: true
    }),
    departmentGuid: _emberData.default.attr('string', {
      isCopyable: true
    }),
    departmentName: _emberData.default.attr('string', {
      isCopyable: true
    }),
    corporateGroupItemOverrides: (0, _relationships.hasMany)('corporate-group-item-override')
  });

  _exports.default = _default;
});