define("lh4/templates/components/supply-orders/order-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j0MxW/QU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"canViewSupplyOrders\"]],[24,[\"canViewPosOrders\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segments\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui top attached segment\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h3\",true],[8],[0,\"POS Systems and Supply Orders\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui attached segment\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"canViewSupplyOrders\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[22,\"tables/supply-orders-table\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"canViewPosOrders\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[22,\"tables/pos-orders-table\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/supply-orders/order-tracker.hbs"
    }
  });

  _exports.default = _default;
});