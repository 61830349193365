define("lh4/serializers/hospitality/menu-category", ["exports", "lh4/serializers/hospitality/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DS from 'ember-data';
  var _default = _application.default.extend({
    normalize: function normalize(model, hashToNormalize) {
      var hash = hashToNormalize; // contentSource on terminals is creating/saving these not with lowercase
      // we were creating them with lowercase by default.

      if (hash.contentSource === 'predefined' || !hash.contentSource) {
        hash.contentSource = 'Predefined';
      } else if (hash.contentSource === 'runtimemap') {
        hash.contentSource = 'RuntimeMap';
      }

      hash.menuCatId = hash.id;

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [model, hash].concat(args));
    },
    primaryKey: 'rowguid'
  });

  _exports.default = _default;
});