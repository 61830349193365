define("lh4/templates/components/ui/icons/nav-bar/logo-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U5c4VAHc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"32\"],[10,\"height\",\"32\"],[10,\"viewBox\",\"0 0 32 32\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M17.8733 6.84818L9.02882 18.211C8.99811 18.2724 8.96741 18.3031 8.96741 18.3645V19.6543C8.96741 19.8079 9.09025 19.9307 9.2438 19.9307H17.167C17.3206 19.9307 17.4434 20.0536 17.4434 20.2071V24.8136C17.4434 24.9672 17.5662 25.09 17.7198 25.09H19.4089C19.5624 25.09 19.6852 24.9672 19.6852 24.8136V7.00173C19.6852 6.84818 19.5624 6.72534 19.4089 6.72534H18.119C18.0269 6.75605 17.9348 6.78676 17.8733 6.84818ZM11.977 17.4739L16.952 11.1169C17.1056 10.9019 17.4434 11.0248 17.4434 11.2705V17.6275C17.4434 17.781 17.3206 17.9039 17.167 17.9039H12.192C11.9463 17.9346 11.8234 17.6582 11.977 17.4739Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M16 0C7.18618 0 0 7.18619 0 16C0 24.8138 7.18618 32 16 32C24.8138 32 32 24.8138 32 16C32 7.15548 24.8138 0 16 0ZM29.7274 16C29.7274 23.5547 23.5854 29.7275 16 29.7275C8.41459 29.7275 2.27255 23.5547 2.27255 16C2.27255 8.44531 8.41459 2.27255 16 2.27255C23.5854 2.27255 29.7274 8.41459 29.7274 16Z\"],[10,\"fill\",\"#136EF6\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/nav-bar/logo-mobile.hbs"
    }
  });

  _exports.default = _default;
});