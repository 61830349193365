define("lh4/components/supply-orders/select-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    supplyCart: Ember.inject.service(),
    classNames: ['supply-orders'],
    selectedItems: Ember.computed('supplyCart', function getter() {
      return this.get('supplyCart.orderDetails');
    }),
    rate: Ember.computed('supplyCart.rate', 'supplyCart.orderDetails', function getter() {
      var rate = this.get('supplyCart.rate');
      var products = this.get('supplyCart.orderDetails');
      var cost = products.reduce(function (prev, curr) {
        return prev + parseFloat(curr.price) * parseInt(curr.quantity, 10);
      }, rate);
      return !rate ? '$0.00' : "$".concat(cost.toFixed(2));
    }),
    actions: {
      removeItem: function removeItem(item) {
        this.supplyCart.remove(item);

        if (this.get('supplyCart.orderDetails').length === 0) {
          this.sendAction('productTransition');
        }

        if (this.get('supplyCart.serviceId')) {
          this.sendAction('selectShipping');
        }
      },
      selectShippingDropdown: function selectShippingDropdown(value) {
        this.set('supplyCart.serviceId', value);
        this.sendAction('selectShipping');
      },
      selectQty: function selectQty(product, value) {
        Ember.set(product, 'quantity', value);
        this.set('supplyCart.rate', null); // Set in condition to prevent sending empty service id

        if (this.get('supplyCart.serviceId')) {
          this.sendAction('selectShipping');
        }
      },
      submitOrder: function submitOrder() {
        this.sendAction('submitOrder');
      }
    }
  });

  _exports.default = _default;
});