define("lh4/controllers/authenticated/premium/customer-engagement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    walkthrough: Ember.inject.service(),
    logger: Ember.inject.service(),
    media: Ember.inject.service(),
    can: Ember.inject.service(),
    permissions: Ember.inject.service(),
    buttonText: Ember.computed('media.isDesktop', function getter() {
      return this.get('media.isDesktop') ? 'Automate your email marketing' : 'Get started';
    }),
    actions: {
      leave: function leave() {
        var _this = this;

        this.set('walkthrough.data.premiumWelcome.customerEngagement', true);
        this.walkthrough.saveData().catch(function (e) {
          return _this.logger.log(e);
        });
        this.set('loading', true);
        this.permissions.activateService('lighthouse-premium').then(function () {
          if (_this.can.can('manage campaigns for customer-engagement')) {
            _this.transitionToRoute('authenticated.customer-engagement');
          } else {
            _this.set('noPermissionWarning', true);
          }
        }).catch(function () {
          _this.set('error', 'We\'re sorry, there was an error during the signup process, please try again later.');
        }).finally(function () {
          _this.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});