define("lh4/controllers/authenticated/epro/menu/pricing-table/edit", ["exports", "lh4/controllers/general-form-controller", "lh4/utils/currency/converter"], function (_exports, _generalFormController, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    ajax: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    countChildChanges: function countChildChanges() {
      return this.changeset.priceOverrideChangesets.filter(function (childChangeset) {
        return childChangeset.isDirty;
      }).length;
    },
    validateAndRetrieveChildChanges: function validateAndRetrieveChildChanges() {
      return this.changeset.priceOverrideChangesets.map(function (childChangeset) {
        return childChangeset.validate().then(function () {
          if (childChangeset.isInvalid) {
            return Ember.RSVP.reject(childChangeset.errors);
          }

          return {
            corporateGroupId: childChangeset.get('corporateGroupId'),
            value: (0, _converter.formatCurrency)(childChangeset.get('value'), null)
          };
        });
      });
    },
    handleServerError: function handleServerError(error) {
      var _this = this;

      var updateError = 'Something went wrong, prices could not be updated.';
      var errorMessage = error.details && error.details[0] || error.message || updateError;
      this.set('serverError', errorMessage);
      this.set('serverErrorTimeoutId', Ember.run.later(this, function () {
        _this.set('serverError', null);
      }, this.serverErrorTimeoutDelay));
    },
    handleServerResponse: function handleServerResponse() {
      this.send('close');
    },
    trackUpdateCompleted: function trackUpdateCompleted() {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Completed',
        'Entry Type': 'Edit',
        'Entry ID': this.changeset.get('guid'),
        'Number of Updates': Object.keys(this.changeset.change).length,
        'Entries Updated': Object.keys(this.changeset.change),
        'Menu Component': 'Pricing Table'
      });
    },
    actions: {
      update: function update() {
        var _this2 = this;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError();
        var validateChildChanges = this.validateAndRetrieveChildChanges();
        Ember.RSVP.all(validateChildChanges).then(function (childChanges) {
          if (_this2.changeset.isPristine && _this2.countChildChanges() === 0) {
            // no changes have been made
            _this2.set('isSaving', false);

            _this2.closeTray();

            return Ember.RSVP.resolve();
          }

          return _this2.changeset.validate().then(function () {
            if (_this2.changeset.isInvalid) {
              _this2.set('isSaving', false);

              return false;
            }

            _this2.trackUpdateCompleted();

            var id = _this2.model.pricingTable.itemGuid;
            var requestUrl = "/api/v1/corporations/".concat(_this2.location.model.corpId, "/overrides/items/").concat(id, "/price");
            var requestBody = {
              defaultValue: _this2.changeset.get('defaultPrice'),
              values: childChanges
            };
            return _this2.ajax.request(requestUrl, {
              method: 'PUT',
              data: requestBody
            }).then(function () {
              return _this2.handleServerResponse();
            }).catch(function (e) {
              return _this2.handleServerError(e);
            }).finally(function () {
              return _this2.set('isSaving', false);
            });
          });
        }).catch(function () {
          // one or more child changesets failed validation, so will also
          // validate main changeset here so that all errors can show at once
          _this2.changeset.validate().then(function () {
            _this2.set('isSaving', false);
          });
        });
      }
    }
  });

  _exports.default = _default;
});