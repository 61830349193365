define("lh4/models/echo-pro/item-price-schedule-map", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    priceScheduleGuid: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Price Schedule'
    }),
    priceType: (0, _emberCpValidations.validator)('presence', {
      description: 'Price Type',
      presence: true
    }),
    priceValue: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      description: 'Price Value',
      positive: true,
      allowString: true,
      lte: Ember.computed('model.priceType', function getter() {
        if (this.model.get('priceType') === 'Dollars') {
          // This is to help mimic what is happening on the terminal
          return 10000000;
        } // 100%


        return 100;
      })
    })
  });

  var _default = _model.default.extend(Validations, {
    itemGuid: (0, _attr.default)('string'),
    priceScheduleGuid: (0, _attr.default)('string'),
    // Dollars, Discount, Surcharge
    priceType: (0, _attr.default)('string', {
      defaultValue: 'Dollars'
    }),
    priceValue: (0, _attr.default)('string')
  });

  _exports.default = _default;
});