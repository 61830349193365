define("lh4/controllers/authenticated/gift-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    ajax: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    order: 'purchaserName',
    sort: 'asc',
    sortAsc: Ember.computed('sort', function getter() {
      return this.sort === 'asc';
    }),
    successMessage: '',
    errorMessage: '',
    search: null,
    statusOptions: [{
      name: 'All status',
      value: 'all'
    }, {
      name: 'Active',
      value: 'active'
    }, {
      name: 'Redeemed',
      value: 'redeemed'
    }],
    selectedStatus: 'all',
    headers: [{
      name: 'Card Number',
      field: 'cardNumber',
      width: 'three',
      sortable: true
    }, {
      name: 'Status',
      field: 'isRedeemed',
      width: 'two',
      sortable: false
    }, {
      name: 'Amount',
      field: 'amount',
      width: 'two',
      sortable: true,
      aligned: 'right'
    }, {
      name: 'Name',
      field: 'purchaserName',
      width: 'two',
      sortable: true
    }, {
      name: 'Email',
      field: 'purchaserEmail',
      width: 'three',
      sortable: true
    }, {
      name: 'Purchased',
      field: 'purchaseDate',
      width: 'two',
      sortable: true
    }, {
      name: '',
      field: '',
      width: 'two',
      sortable: false
    }],
    redeemCard: function redeemCard(card) {
      var _this = this;

      return this.ajax.put("/api/v1/s4-gift-cards/cards/redeem/".concat(card.cardId)).then(function () {
        _this.set('successMessage', 'Card was successfully redeemed');

        Ember.run.later(_this, function () {
          _this.set('successMessage', '');
        }, 10000); // Since we only get 'ok' from backend, we have to force reload here

        _this.send('reloadRoute');
      }).catch(function () {
        _this.set('errorMessage', 'There was an unexpected error redeeming the card');

        Ember.run.later(_this, function () {
          _this.set('errorMessage', '');
        }, 10000);
        return Ember.RSVP.reject();
      });
    },
    unredeemCard: function unredeemCard(card) {
      var _this2 = this;

      return this.ajax.put("/api/v1/s4-gift-cards/cards/unredeem/".concat(card.cardId)).then(function () {
        Ember.run.later(_this2, function () {
          _this2.set('successMessage', '');
        }, 10000); // Since we only get 'ok' from backend, we have to force reload here

        _this2.send('reloadRoute');
      }).catch(function () {
        _this2.set('errorMessage', 'There was an unexpected error unredeeming the card');

        Ember.run.later(_this2, function () {
          _this2.set('errorMessage', '');
        }, 10000);
        return Ember.RSVP.reject();
      });
    },
    sortFn: function sortFn(a, b) {
      var value1 = a[this.order];
      var value2 = b[this.order]; // date

      if (value1 instanceof Date) {
        if (this.sortAsc) {
          return value1.getTime() - value2.getTime();
        }

        return value2.getTime() - value1.getTime();
      } // number


      if (Number.isFinite(value1)) {
        if (this.sortAsc) {
          return value1 - value2;
        }

        return value2 - value1;
      } // string


      if (this.sortAsc) {
        return value1.localeCompare(value2);
      }

      return value2.localeCompare(value1);
    },
    applySorting: function applySorting(items) {
      var itemsArray = items.toArray();
      return itemsArray.sort(this.sortFn.bind(this));
    },
    applySearchTerm: function applySearchTerm() {
      if (!this.search) {
        return this.model.cards;
      }

      var searchLowerCase = this.search.toLowerCase();
      return this.model.cards.filter(function (c) {
        return c.cardNumber.toLowerCase().indexOf(searchLowerCase) > -1 || c.purchaserName.toLowerCase().indexOf(searchLowerCase) > -1 || c.purchaserEmail.toLowerCase().indexOf(searchLowerCase) > -1;
      });
    },
    items: Ember.computed('model.cards.[]', 'selectedStatus', 'search', 'sort', 'order', function getter() {
      var status = this.selectedStatus.toLowerCase();
      var records = this.applySearchTerm();

      if (status === 'all') {
        return this.applySorting(records);
      }

      return this.applySorting(records.filter(function (c) {
        return c.status.toLowerCase() === status;
      }));
    }),
    actions: {
      handleStatusChange: function handleStatusChange(status) {
        this.set('selectedStatus', status);
      },
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      },
      filterChanged: function filterChanged(filter) {
        var sortAsc = filter.sortAsc,
            order = filter.sortBy;
        var sort = sortAsc ? 'asc' : 'desc';
        this.setProperties({
          sort: sort,
          order: order
        });
      },
      toggleRedeem: function toggleRedeem(cardId) {
        var card = this.model.cards.find(function (c) {
          return c.id === cardId;
        });

        if (card.isRedeemed) {
          this.unredeemCard(card);
        } else {
          this.redeemCard(card);
        }
      }
    }
  });

  _exports.default = _default;
});