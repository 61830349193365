define("lh4/mirage/fixtures/internet-payments/risk/risk-rules-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RISK_RULES_LIST_FIXTURES = [{
    id: '3a5dd435-046d-4548-bfe3-519c54b2b543',
    kountRule: {
      sourceKountRuleId: 1
    },
    group: 'Consistency Rules',
    name: 'Billing Address Does Not Match Card Holder Address',
    description: 'DESCRIPTION: Billing Address Does Not Match Card Holder Address',
    score: 25,
    action: null,
    isEnabled: true,
    proposedChanges: [{
      createdAt: '2023-02-06T12:32:53.427Z',
      updatedAt: '2023-02-06T12:32:53.427Z',
      guid: 'e3fcc550-2c1a-45f1-872b-2c410f8a6496',
      riskRuleId: '3a5dd435-046d-4548-bfe3-519c54b2b543',
      userId: 608,
      name: '1: Billing Address Does Not Match Card Holder Address',
      description: 'DESCRIPTION: Billing Address Does Not Match Card Holder Address',
      score: 25,
      status: 'pending'
    }]
  }, {
    id: 'dae3d34f-76c2-492a-a513-c17bb764a499',
    kountRule: {
      sourceKountRuleId: 2
    },
    group: 'Consistency Rules',
    name: 'Billing Address and Customer Device are More Than 500 Miles Apart',
    description: 'DESCRIPTION: Billing Address and Customer Device are More Than 500 Miles Apart',
    score: 10,
    action: null,
    isEnabled: true,
    proposedChanges: []
  }, {
    id: '40214dcc-8d56-4639-a315-3c81782a67cd',
    kountRule: {
      sourceKountRuleId: 6
    },
    group: 'Velocity Rules',
    name: 'Card used more than 3 times in last 7 days',
    description: 'DESCRIPTION: Card used more than 3 times in last 7 days',
    score: 15,
    action: null,
    isEnabled: true,
    proposedChanges: []
  }, {
    id: '18d96d20-bf92-40ae-b825-40ba56e6b917',
    kountRule: {
      sourceKountRuleId: 7
    },
    group: 'Velocity Rules',
    name: 'Customer has used more than 4 unique devices in past 30 days',
    description: 'DESCRIPTION: Customer has used more than 4 unique devices in past 30 days',
    score: 25,
    action: null,
    isEnabled: true,
    proposedChanges: []
  }, {
    id: '14e17c62-92df-4546-96e2-b7c8bf22d42d',
    kountRule: {
      sourceKountRuleId: 20
    },
    group: 'Approve & Decline Rules',
    name: 'Customer Score is greater than 80',
    description: 'DESCRIPTION: Customer Score is greater than 80',
    score: null,
    action: 'decline',
    isEnabled: true,
    proposedChanges: []
  }, {
    id: 'd687fa46-3cb0-4c75-bd7a-82b6442cbb00',
    kountRule: {
      sourceKountRuleId: 21
    },
    group: 'Approve & Decline Rules',
    name: 'Customer Score is greater than 70 and less than 80',
    description: 'DESCRIPTION: Customer Score is greater than 70 and less than 80',
    score: null,
    action: 'approve',
    isEnabled: false,
    proposedChanges: []
  }, {
    id: 'c9de50b7-7bba-4c33-8c4a-c6f7fd45810e',
    kountRule: {
      sourceKountRuleId: 23
    },
    group: 'Block and Trust Rules',
    name: 'Trusted IP address',
    description: 'DESCRIPTION: Trusted IP address',
    score: null,
    action: 'approve',
    isEnabled: true,
    proposedChanges: []
  }, {
    id: '4ba09f3b-edde-4bf8-832a-ee32c2935348',
    kountRule: {
      sourceKountRuleId: 24
    },
    group: 'Block and Trust Rules',
    name: 'Block IP address',
    description: 'DESCRIPTION: Block IP address',
    score: null,
    action: 'approve',
    isEnabled: true,
    proposedChanges: [{
      createdAt: '2023-02-06T12:32:53.427Z',
      updatedAt: '2023-02-06T12:32:53.427Z',
      guid: 'e3fcc550-2c1a-45f1-872b-2c410f8a6496',
      riskRuleId: '4ba09f3b-edde-4bf8-832a-ee32c2935348',
      userId: 608,
      name: '1: Block IP address',
      description: 'DESCRIPTION: Block IP address',
      action: 'approve',
      status: 'pending'
    }]
  }];
  var _default = RISK_RULES_LIST_FIXTURES;
  _exports.default = _default;
});