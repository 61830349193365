define("lh4/components/internet-payments/risk/risk-block-list-icon-tooltip", ["exports", "lh4/mixins/components/outside-event-mixin", "lh4/enums/ip-api-risk-statuses"], function (_exports, _outsideEventMixin, _ipApiRiskStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TOOLTIP_DATANAMES = void 0;
  var TOOLTIP_DATANAMES = Object.freeze({
    CARD_TOKEN: 'cardToken',
    DEVICE_IP_ADDRESS: 'deviceIpAddress',
    CUSTOMER_EMAIL: 'customerEmail',
    CREDIT_CARD: 'creditCard'
  });
  _exports.TOOLTIP_DATANAMES = TOOLTIP_DATANAMES;

  var _default = Ember.Component.extend(_outsideEventMixin.default, {
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['risk-block-list-tooltip-container'],
    dataName: 'Value',
    statuses: (0, _ipApiRiskStatuses.default)(),
    dataStatus: Ember.computed('statuses', function getter() {
      var statuses = this.get('statuses');
      return statuses.DELETE;
    }),
    iconClass: Ember.computed('dataStatus', 'statuses', function getter() {
      var statuses = this.get('statuses');

      switch (this.get('dataStatus')) {
        case statuses.APPROVE:
          return 'check circle';

        case statuses.DECLINE:
          return 'exclamation circle';

        default:
          return null;
      }
    }),
    tooltipHeaderText: Ember.computed('dataStatus', 'dataName', 'statuses', function getter() {
      var statuses = this.get('statuses');
      var dataName = this.get('dataName');

      switch (this.get('dataStatus')) {
        case statuses.APPROVE:
          return this.intl.t("tooltip:header:status:approve:".concat(dataName));

        case statuses.DECLINE:
          return this.intl.t("tooltip:header:status:decline:".concat(dataName));

        default:
          return '';
      }
    }),
    tooltipBodyText: Ember.computed('dataStatus', 'statuses', function getter() {
      var statuses = this.get('statuses');

      switch (this.get('dataStatus')) {
        case statuses.APPROVE:
          return this.intl.t('tooltip:body:status:approve');

        case statuses.DECLINE:
          return this.intl.t('tooltip:body:status:decline');

        default:
          return '';
      }
    }),
    actions: {
      toogleMobileTooltip: function toogleMobileTooltip() {
        this.$().toggleClass('mobile-tooltip-active');
      },
      handleOutsideEvent: function handleOutsideEvent() {
        this.$().removeClass('mobile-tooltip-active');
      }
    }
  });

  _exports.default = _default;
});