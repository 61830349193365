define("lh4/services/reputation/yelp", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ACCOUNT_TYPE_YELP = _exports.default = void 0;
  var ACCOUNT_TYPE_YELP = 'yelp';
  _exports.ACCOUNT_TYPE_YELP = ACCOUNT_TYPE_YELP;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    findYelpBusinesses: function findYelpBusinesses(_ref) {
      var term = _ref.term,
          location = _ref.location,
          limit = _ref.limit;

      var qs = _jquery.default.param({
        term: term,
        location: location,
        limit: limit
      });

      return this.ajax.request("/api/v1/reputation/accounts/link/search/yelp?".concat(qs), {
        headers: {
          'Content-Type': 'application/json'
        },
        type: 'GET'
      });
    },
    linkYelpAccount: function linkYelpAccount(_ref2, yelpBusiness) {
      var name = _ref2.name,
          locationId = _ref2.locationId;

      if (!yelpBusiness) {
        return Ember.RSVP.reject('Please select Yelp account');
      }

      return this.ajax.request('/api/v1/reputation/accounts/link/yelp', {
        headers: {
          'Content-Type': 'application/json'
        },
        type: 'POST',
        data: JSON.stringify({
          yelpBusinessId: yelpBusiness.id,
          externalName: yelpBusiness.name,
          name: name,
          locationId: locationId
        })
      }).then(function (response) {
        return {
          response: response,
          accountType: 'yelp'
        };
      });
    }
  });

  _exports.default = _default;
});