define("lh4/services/supply-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // Location Params
    locationId: null,
    locationName: null,
    locationStreet1: null,
    locationStreet2: null,
    locationCity: null,
    locationState: null,
    locationZip: null,
    locationPhone: null,
    locationEmail: null,
    // Validation from shipping API
    addressId: null,
    // Shipping service selected
    serviceId: null,
    serviceName: null,
    shippingOrderId: null,
    rate: null,
    comment: '',
    // Equipment Params
    equipmentId: null,
    equipmentName: null,
    supplies: null,
    // Supplies array
    orderDetails: null,
    qty: 1,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('orderDetails', []);
    },
    // Obeserves to see if locationId chaged, if so, empty array of supplies
    locationIdChanged: Ember.observer('locationId', function callback() {
      this.set('rate', null);
      this.set('equipmentId', null);
      this.set('equipmentName', null);
      this.set('orderDetails', []);
    }),
    // Obeserves to see if equipmentId chaged, if so, empty array of supplies
    equipmentIdChanged: Ember.observer('equipmentId', function callback() {
      this.set('rate', null);
    }),

    /*
     * Breaks apart location obj and places them into the above params
     * @param {object} locationObj - Location object
     */
    selectLocation: function selectLocation(locationObj) {
      this.setProperties({
        locationId: locationObj.get('merchantId'),
        locationName: locationObj.get('name'),
        locationStreet1: locationObj.get('addressLine1'),
        locationStreet2: locationObj.get('addressLine2'),
        locationCity: locationObj.get('city'),
        locationState: locationObj.get('state'),
        locationZip: locationObj.get('zip'),
        locationCompany: locationObj.get('company'),
        locationPhone: locationObj.get('phone'),
        locationEmail: locationObj.get('email')
      });
    },

    /*
     * Removes location from property
     */
    removeLocation: function removeLocation() {
      this.set('locationId', null);
    },

    /*
     * Breaks apart location obj and places them into the above params
     * @param {object} equipmentObj - Equipment object
     */
    selectEquipment: function selectEquipment(equipmentObj) {
      this.set('supplies', equipmentObj.get('products'));
      this.set('equipmentId', equipmentObj.get('id'));
      this.set('equipmentName', equipmentObj.get('externalName'));
    },

    /*
     * removes NOTHING!!!
     */
    removeEquipment: function removeEquipment() {
      this.set('equipmentId', null);
    },
    // Supplies
    add: function add(item) {
      var orderDetails = this.orderDetails,
          equipmentId = this.equipmentId,
          equipmentName = this.equipmentName;
      var productId = item.id,
          displayName = item.displayName,
          price = item.price;

      if (orderDetails.isAny('productId', productId)) {
        return;
      }

      orderDetails.pushObject({
        equipmentId: equipmentId,
        equipmentName: equipmentName,
        productId: productId,
        displayName: displayName,
        price: price,
        quantity: 1
      });
    },
    remove: function remove(item) {
      this.get('orderDetails').removeObject(item);
    },
    empty: function empty() {
      this.get('orderDetails').clear();
    }
  });

  _exports.default = _default;
});