define("lh4/templates/authenticated/internet-payments/developers/webhooks/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iXRmWbth",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[5,\"internet-payments/modals/fullscreen-modal\",[[12,\"data-test-ip-webooks-create\",\"full-screen-modal\"]],[[\"@title\",\"@errorMessage\",\"@onClose\"],[\"New Webhook\",[28,\"or\",[[23,0,[\"changeset\",\"errors\",\"0\",\"validation\"]],[23,0,[\"error\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"close\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `modal.content` to be a contextual component but found a string. Did you mean `(component modal.content)`? ('lh4/templates/authenticated/internet-payments/developers/webhooks/create.hbs' @ L8:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"internet-payments/developers/webhooks/general-settings-form\",[],[[\"@changeset\",\"@onUpdateForm\",\"@centerForm\",\"@newRecord\"],[[23,0,[\"changeset\"]],[23,0,[\"onUpdateForm\"]],true,true]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"actions\"]],\"expected `modal.actions` to be a contextual component but found a string. Did you mean `(component modal.actions)`? ('lh4/templates/authenticated/internet-payments/developers/webhooks/create.hbs' @ L16:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ip-button standard-width\",[28,\"if\",[[23,0,[\"isCreating\"]],\" loading\"],null]]]],[12,\"data-test-ip-webooks-create\",\"create-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"create\"]]]],[8],[0,\"\\n\\t\\t\\tCreate\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/developers/webhooks/create.hbs"
    }
  });

  _exports.default = _default;
});