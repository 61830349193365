define("lh4/components/social-media/link-configuration-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['link-configuration-warning'],
    account: null,
    isLinked: Ember.computed('account', 'account.externalId', function getter() {
      var account = this.get('account'); // 'account-facebook-page-edit' passes object

      return !!Ember.get(account, 'externalId');
    }),
    isWarningTooltip: true,
    namespace: 'social-media',
    editLink: 'authenticated.social-media.accounts.edit',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.get('isWarningTooltip')) {
        return;
      }

      this.$('.popup-activator').popup({
        inline: true,
        hoverable: true,
        prefer: 'opposite',
        position: 'top left',
        lastResort: 'bottom center',
        boundary: '.site-content',
        offset: -7
      });
    }
  });

  _exports.default = _default;
});