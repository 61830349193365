define("lh4/controllers/authenticated/locations/location/hospitality/choices-and-choice-sets/set/edit-set/choice/edit-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    logger: Ember.inject.service(),
    isLoading: false,
    setLoading: Ember.computed('isLoading', function getter() {
      return this.get('isLoading') ? 'loading' : '';
    }),
    tabs: ['Choice Details', 'Choice Sets'],
    actions: {
      save: function save() {
        var _this = this;

        this.flashMessages.clearMessages();
        this.set('isLoading', true);
        this.get('model.editChoices').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.get('model.editChoices').save().then(function () {
              _this.flashMessages.success('Choice saved successfully!', {
                class: 'ui success message'
              });

              _this.logger.log('Choice saved successfully!');

              _this.set('isLoading', false);
            }, function (err) {
              _this.set('isLoading', false);

              _this.logger.log("".concat(err, " error occurred when saving editChoices"));

              _this.flashMessages.danger('Error connecting to terminal. Please make sure your unit is online.', {
                class: 'ui error message'
              });
            });
          } else {
            return Ember.RSVP.Promise.reject(validations);
          }

          return false;
        }).catch(function (validations) {
          _this.set('isLoading', false);

          validations.forEach(function (v) {
            if (v.get('message')) {
              _this.flashMessages.danger("".concat(v.get('message'), "."), {
                class: 'ui negative message'
              });
            }
          });
        });
      },
      clear: function clear() {
        this.get('model.editChoices').rollbackAttributes();
        this.transitionToRoute('authenticated.locations.location.hospitality.choices-and-choice-sets.set.edit-set.choice.edit-choice');
      }
    }
  });

  _exports.default = _default;
});