define("lh4/routes/authenticated/locations/location/hospitality/customize-item-groups/edit-group", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(param) {
      return Ember.RSVP.hash({
        singleGroup: this.store.findRecord('hospitality/item-group', param.groupId)
      });
    },
    afterModel: function afterModel(model) {
      this.controllerFor('authenticated.locations.location.hospitality.customize-item-groups').set('currentGroup', model.singleGroup);
    }
  });

  _exports.default = _default;
});