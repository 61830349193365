define("lh4/components/recover-account-form", ["exports", "jquery", "lh4/config/environment", "lh4/utils/support-phone-numbers", "lh4/utils/phone-number"], function (_exports, _jquery, _environment, _supportPhoneNumbers, phoneNumberUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ENV: _environment.default,
    tagName: '',
    session: Ember.inject.service(),
    email: '',
    errorMessage: 'An error occurred',
    isLoading: false,
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    locationGuest: Ember.inject.service(),
    phoneNumber: Ember.inject.service(),
    branding: _environment.default.branding,
    isInformationModalVisible: false,
    supportPhoneNumber: Ember.computed('locationGuest.countryCode', function getter() {
      var countryCode = this.locationGuest.countryCode;
      var phoneNumber = (0, _supportPhoneNumbers.getSupportPhoneNumber)(countryCode).general;
      return this.phoneNumber.validationsAreActive ? phoneNumberUtils.formatPhoneNumber(phoneNumber, countryCode) : phoneNumber;
    }),
    actions: {
      updateEmail: function updateEmail(email) {
        this.set('email', email);
      },
      toggleInformationModal: function toggleInformationModal() {
        this.set('isInformationModalVisible', !this.isInformationModalVisible);
      },
      authenticate: function authenticate() {
        var _this = this;

        (0, _jquery.default)('.sign-in-form, .email-input').removeClass('error');
        (0, _jquery.default)('.email-input-container').removeClass('error');
        this.set('isLoading', true);

        if (!this.email) {
          (0, _jquery.default)('.sign-in-form, .email-input').addClass('error');
          (0, _jquery.default)('.email-input-container').addClass('error');
          this.set('isLoading', false);
          this.flashMessages.danger('Please enter your email', {
            class: 'ui error message'
          });
          return;
        }

        if (!this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          (0, _jquery.default)('.sign-in-form, .email-input').addClass('error');
          (0, _jquery.default)('.email-input-container').addClass('error');
          this.set('isLoading', false);
          this.flashMessages.danger('Not a valid email', {
            class: 'ui error message'
          });
          return;
        }

        this.ajax.request('/api/v1/auth/forgot-password', {
          type: 'POST',
          data: JSON.stringify({
            emailAddress: this.email,
            countryCode: this.locationGuest.countryCode
          })
        }).then(function () {
          _this.set('isLoading', false);

          _this.onCompleted();
        }).catch(function () {
          _this.set('isLoading', false);

          _this.flashMessages.danger(_this.errorMessage, {
            class: 'ui error visible message'
          });
        });
      }
    }
  });

  _exports.default = _default;
});