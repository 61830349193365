define("lh4/enums/ip-api-risk-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IP_API_RISK_STATUSES_V2 = Object.freeze({
    APPROVE: 'A',
    DECLINE: 'D',
    DELETE: 'N'
  });
  /**
   * Return correct API RISK STATUS object based on feature flag
   *
   * @returns {object} - IP_API_RISK_STATUSES or IP_API_RISK_STATUSES_V2 based on ff status
   */

  var getInternetPaymentsAPIRiskStatuses = function getInternetPaymentsAPIRiskStatuses() {
    return IP_API_RISK_STATUSES_V2;
  };

  var _default = getInternetPaymentsAPIRiskStatuses;
  _exports.default = _default;
});