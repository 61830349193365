define("lh4/abilities/solo", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions", "lh4/utils/gpatt", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _permissions, _gpatt, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service(),
    canViewDevices: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', 'location.model', function canViewDevices() {
      var _this$location$model$;

      return Boolean((_this$location$model$ = this.location.model.terminalProducts) === null || _this$location$model$ === void 0 ? void 0 : _this$location$model$.length) && this.hasPermission(_permissions.default.SKYTAB.ACCESS) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_QUICK_MENU];
    }),
    canManage: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', 'location.model', function canManage() {
      return (0, _gpatt.default)(this.location.model) && this.hasPermission(_permissions.default.SKYTAB.ACCESS) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_QUICK_MENU];
    }),
    canViewTaxes: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', 'location.model', function canManage() {
      return (0, _gpatt.default)(this.location.model) && this.hasPermission(_permissions.default.SKYTAB.ACCESS) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_QUICK_MENU] && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_TAXES_VISIBILITY];
    }),
    canViewOffline: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewOffline() {
      return this.get('canManage') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_OFFLINE_PAYMENT_VISIBILITY];
    }),
    canViewEnableCreditCard: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewEnableCreditCard() {
      return this.get('canManage') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_ENABLE_CREDIT_CARD_VISIBILITY];
    }),
    canViewEnablePinDebit: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewEnablePinDebit() {
      return this.get('canManage') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_ENABLE_PIN_DEBIT_VISIBILITY];
    }),
    canViewNonCashCharge: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewNonCashCharge() {
      return this.get('canManage') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_NON_CASH_CHARGE_VISIBILITY];
    }),
    canViewNewAdvantageProgram: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewNewAdvantageProgram() {
      return this.get('canManage') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_SETTINGS_NEW_ADVANTAGE_PROGRAM_VISIBILITY];
    }),
    canViewSoloSettingSyncTips: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewSoloSettingSyncTips() {
      return this.get('canManage') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_SETTINGS_SYNC_TIPS];
    }),
    canViewSoloReceiptSettings: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewSoloReceiptSettings() {
      return this.get('canManage') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_PRINT_RECEIPT_SETTINGS_VISIBILITY];
    }),
    canViewDualPricing: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewDualPricing() {
      return this.get('canManage') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_SETTINGS_DUAL_PRICING_VISIBILITY];
    }),
    canViewDeviceManagement: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.get('canViewDevices') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_DEVICE_MANAGEMENT_VISIBILITY];
    }),
    canViewEnableKioskMode: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function canViewEnableKioskMode() {
      return this.get('canManage') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SOLO_KIOSK_MODE_VISIBILITY];
    })
  });

  _exports.default = _default;
});