define("lh4/constants/controllers/support/index", ["exports", "lh4/enums/country-code"], function (_exports, _countryCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SUPPORT_PHONE_NUMBERS_OBJECT = void 0;
  var SUPPORT_PHONE_NUMBERS = [{
    countryCode: _countryCode.default.US,
    value: {
      general: '+18882762108',
      internetPayments: '+18002010461'
    }
  }, {
    countryCode: _countryCode.default.GB,
    value: {
      general: '+447549499381',
      internetPayments: '+447549499381'
    }
  }];
  var SUPPORT_PHONE_NUMBERS_OBJECT = Object.freeze(SUPPORT_PHONE_NUMBERS.reduce(function (acc, _ref) {
    var countryCode = _ref.countryCode,
        value = _ref.value;
    acc[countryCode] = value;
    return acc;
  }, {}));
  _exports.SUPPORT_PHONE_NUMBERS_OBJECT = SUPPORT_PHONE_NUMBERS_OBJECT;
  var _default = SUPPORT_PHONE_NUMBERS;
  _exports.default = _default;
});