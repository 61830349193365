define("lh4/components/internet-payments/page-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    imageUri: '',
    message: 'Coming Soon.',
    subMessage: 'Actionable data at your fingertips.',
    imageAltText: 'Coming Soon Image'
  });

  _exports.default = _default;
});