define("lh4/serializers/echo-pro/menu-version", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    primaryKey: 'guid',
    location: Ember.inject.service(),
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadData = {
        menuVersion: _objectSpread({}, payload)
      };
      return this._super(store, primaryModelClass, payloadData, id, requestType);
    },
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload) {
      return this.normalize(primaryModelClass, payload);
    },
    serialize: function serialize(snapshot) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var json = this._super.apply(this, [snapshot].concat(args));

      var name = json.name,
          scheduledForFormatted = json.scheduledForFormatted,
          notes = json.notes,
          instant = json.instant;
      return {
        applicationDate: instant ? null : scheduledForFormatted,
        name: name,
        notes: notes,
        instant: instant
      };
    }
  });

  _exports.default = _default;
});