define("lh4/components/ui/form-fields/datetime-field", ["exports", "lh4/enums/date-time-format", "moment", "lh4/components/ui/form-fields/field"], function (_exports, _dateTimeFormat, _moment, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    media: Ember.inject.service(),
    // calentim attributes
    arrowOn: 'left',
    maxDate: null,
    minDate: null,
    autoAlign: true,
    saveAsISOString: false,
    onChange: null,
    date: '',
    format: _dateTimeFormat.default.LOCALIZED_SHORT,
    dataTest: 'form-field-datetime-field',
    datePickerClass: Ember.computed('fullWidth', 'fieldWidth', function compute() {
      var suffix = ' wide column';

      if (this.fieldWidth) {
        return "".concat(this.fieldWidth).concat(suffix);
      }

      if (!this.label) {
        return "sixteen".concat(suffix);
      }

      return "".concat(this.fullWidth ? 'twelve' : 'eight').concat(suffix);
    }),
    updateChangeset: function updateChangeset(momentValue) {
      if (this.onChange) {
        this.onChange(this.property, momentValue);
        return;
      } // because running integration tests willDestroyElement() method is not always called
      // we might get some scenarios where outside click handler is still bind to 'this'
      // and tries to update changeset. To be safe the following check is needed
      // @see https://git.shift4payments.com/lighthouse/frontend/-/merge_requests/1509#note_263038


      if (this.changeset.set) {
        if (this.areMomentsDifferent(momentValue)) {
          var momentValueISO = this.saveAsISOString ? momentValue.toISOString() : momentValue;
          this.changeset.set(this.property, momentValueISO);
        }
      }
    },
    areMomentsDifferent: function areMomentsDifferent(momentValue) {
      var changesetValue = this.changeset.get(this.property);
      var changesetMoment = this.saveAsISOString ? (0, _moment.default)(changesetValue, _moment.default.ISO_8601) : changesetValue;
      return !momentValue.isSame(changesetMoment);
    },
    actions: {
      onInputBlur: function onInputBlur(event) {
        var _event$target$value;

        var eventValue = (_event$target$value = event.target.value) === null || _event$target$value === void 0 ? void 0 : _event$target$value.trim();

        if (eventValue === '') {
          this.updateChangeset(null);
          return;
        }

        var inputMoment = (0, _moment.default)(eventValue, this.format);

        if (inputMoment.isValid()) {
          this.updateChangeset(inputMoment);
        }
      },
      onDesktopBlur: function onDesktopBlur(event, picker) {
        if (!picker) {
          this.send('onInputBlur', event);
          return;
        }

        var momentValue = picker.elem.value ? (0, _moment.default)(picker.elem.value, this.format) : null;

        if (momentValue && momentValue.isValid()) {
          this.updateChangeset(momentValue);
        }

        if (!this.isDestroyed) {
          this.send('blurField');
        }
      },
      onMobileChange: function onMobileChange(momentDate) {
        this.updateChangeset(momentDate);
      },
      onTimeChange: function onTimeChange(instance) {
        var momentDate = (0, _moment.default)(instance.elem.value, this.format);
        this.updateChangeset(momentDate);
      }
    }
  });

  _exports.default = _default;
});