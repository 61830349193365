define("lh4/routes/authenticated/epro/timeclock/shifts/edit", ["exports", "lh4/routes/general-edit-route", "moment"], function (_exports, _generalEditRoute, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NO_PAYPERIOD_MESSAGE = 'This is a legacy shift and cannot be updated.';

  var _default = _generalEditRoute.default.extend({
    tokenData: Ember.inject.service(),
    modelName: 'timeClockShift',
    childChangesets: {
      timeClockSegments: 'segmentChangesets'
    },
    model: function model(_ref) {
      var timeClockShiftId = _ref.timeClockShiftId;
      var settings = this.store.peekRecord('echo-pro/setting', this.location.model.id);
      return Ember.RSVP.hash({
        settings: settings,
        preferences: this.store.findRecord('preferences', this.tokenData.get('data.sub')),
        timeClockShift: this.store.findRecord('echo-pro/time-clock-shift-v2', timeClockShiftId),
        employees: this.modelFor('authenticated.epro.timeclock.shifts').employees
      });
    },
    afterModel: function afterModel(model) {
      return (model.timeClockShift.payPeriodGuid ? this.store.queryRecord('echo-pro/pay-period', {
        filter: {
          guid: {
            $eq: model.timeClockShift.payPeriodGuid
          }
        }
      }) : Ember.RSVP.resolve()).then(function (payPeriod) {
        if (payPeriod) {
          model.timeClockShift.setProperties({
            payPeriodGuid: payPeriod.guid,
            payPeriod: payPeriod,
            payPeriodStartDay: _moment.default.weekdays(payPeriod.dayOfWeek),
            payPeriodStartedAt: payPeriod.start
          });
        }

        if (!model.timeClockShift.payPeriod.content && !model.timeClockShift.timeClockSegments.length) {
          model.timeClockShift.timeClockBreaks.filter(function (b) {
            return !b.get('voided');
          }).forEach(function (b) {
            var segment = model.timeClockShift.addSegment({
              isBreak: true,
              isPaid: b.get('isPaid'),
              start: b.get('startedAt'),
              end: b.get('endedAt')
            });
            model.timeClockShift.timeClockSegments.pushObject(segment);
          });
        }
      });
    },
    setupController: function setupController() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var controller = args[0],
          model = args[1];

      this._super.apply(this, args);

      model.timeClockShift.segmentChangesets = controller.changeset.segmentChangesets;
      model.timeClockShift.timeClockSegments.forEach(function (seg) {
        seg.set('timeClockShift', controller.changeset);
      });
      model.timeClockShift.saveChanges();
      var hasPayPeriod = !!(model.timeClockShift.payPeriodGuid && model.timeClockShift.payPeriod.content);
      controller.setProperties({
        canSave: hasPayPeriod,
        warningMessage: hasPayPeriod ? null : NO_PAYPERIOD_MESSAGE
      });
    }
  });

  _exports.default = _default;
});