define("lh4/adapters/verify-owner/security", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    session: Ember.inject.service(),
    buildURL: function buildURL() {
      return "".concat(this.host, "/api/v1/locations/").concat(this.location.model.id, "/verify-owner/continue");
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 200) {
        this.session.set('ownerToken', payload.token);
      }

      var id = requestData.url.match(/locations\/(\d*)\/verify-owner\/continue/)[1];

      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      var response = this._super.apply(this, [status, headers, payload, requestData].concat(args));

      response.id = id;
      response.locationId = id;
      return response;
    },
    // Main change for updateRecord here is to switch from PUT => POST
    updateRecord: function updateRecord(store, type, snapshot) {
      var id = snapshot.id;
      var data = this.serialize(snapshot, {
        includeId: true
      });
      var urlForQueryRecord = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(urlForQueryRecord, 'POST', {
        data: data
      });
    }
  });

  _exports.default = _default;
});