define("lh4/enums/ip-dispute-card-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INTERNET_PAYMENTS_DISPUTE_CARD_TYPES = Object.freeze({
    VISA: 'visa',
    AMEX: 'amex',
    MASTERCARD: 'mastercard',
    DISCOVER: 'discover',
    JCB: 'jcb',
    DINERS_CLUB: 'Diners Club',
    UNKNOWN: 'Unknown'
  });
  var _default = INTERNET_PAYMENTS_DISPUTE_CARD_TYPES;
  _exports.default = _default;
});