define("lh4/abilities/echo", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    canView: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('echo');
    }),
    canManagePos: (0, _abilityMixin.computedAbility)(function getter() {
      var hasManagementUrl = this.get('location.model.productConfiguration.managementUrl');
      var hasProductWithPermission = this.hasProduct('echo') && this.hasLocationPermission(_permissions.default.ECHO.POS);
      return hasProductWithPermission && hasManagementUrl;
    }),
    canManageTimeclock: (0, _abilityMixin.computedAbility)(function getter() {
      var hasManagementUrl = this.get('model.productConfiguration.timeclockUrl');
      var hasProductWithPermission = this.hasProduct('echo') && this.hasLocationPermission(_permissions.default.ECHO.POS);
      return hasProductWithPermission && hasManagementUrl;
    })
  });

  _exports.default = _default;
});