define("lh4/mixins/controllers/authenticated/epro/menu/stylesheet-saving", ["exports", "lh4/components/epro/menu/ui-helpers/color-picker-unset"], function (_exports, _colorPickerUnset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Mixin.create({
    can: Ember.inject.service(),
    isStylesheetObjectLinkSavingEnabled: function isStylesheetObjectLinkSavingEnabled() {
      return this.can.can('view menu color picker in feature-flag');
    },

    /**
            * This method is used for setting stylesheet object link on a changeset. e.g.: modifier, item, department.
            * Keep in mind that this requires some dependencies of changeset to be present in
            *  controller where this mixing is being used:
            * - color
            * - model.stylesheets
            * - stposUiStylesheetObjectLinks in the model of used controller
            * @param {Object} params - function parameters
            * @param {number} params.locationId - locationId
            * @param {number} params.locationId - locationId
            * @param {string} params.modifierGuid - Guid of modifier
            * @param {string} params.departmentGuid - Guid of department
            * @param {boolean} params.isActive - Guid of department
            */
    createStposUiStylesheetObjectLink: function createStposUiStylesheetObjectLink(objectLinkRecord) {
      if (!this.isStylesheetObjectLinkSavingEnabled()) {
        return;
      }

      var selectedColor = this.changeset.get('color');

      if (selectedColor === _colorPickerUnset.default.color) {
        this.changeset.set('stposUiStylesheetObjectLinks', []);
      } else {
        var _this$changeset$get, _this$changeset$get$f;

        var selectedStylesheet = this.model.stylesheets.find(function (item) {
          return item.stylesheet.primary === selectedColor;
        });
        var currentStylesheetObjectLinkGuid = (_this$changeset$get = this.changeset.get('stposUiStylesheetObjectLinks')) === null || _this$changeset$get === void 0 ? void 0 : (_this$changeset$get$f = _this$changeset$get.firstObject) === null || _this$changeset$get$f === void 0 ? void 0 : _this$changeset$get$f.guid;

        if (selectedStylesheet) {
          var allObjectLinks = this.store.peekAll('echo-pro/stylesheet-object-link');
          var existingStyleSheetLinkObject = currentStylesheetObjectLinkGuid && allObjectLinks.find(function (link) {
            return link.guid === currentStylesheetObjectLinkGuid;
          });
          var modelInstance;

          if (existingStyleSheetLinkObject) {
            existingStyleSheetLinkObject.set('stposUiStylesheetGuid', selectedStylesheet.guid);
            modelInstance = existingStyleSheetLinkObject;
          } else {
            modelInstance = this.store.createRecord('echo-pro/stylesheet-object-link', _objectSpread({
              stposUiStylesheetGuid: selectedStylesheet.guid
            }, objectLinkRecord));
          }

          this.changeset.set('stposUiStylesheetObjectLinks', [modelInstance]);
        }
      }
    }
  });

  _exports.default = _default;
});