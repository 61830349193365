define("lh4/routes/authenticated/locations", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    titleToken: 'Location',
    model: function model() {
      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations
      });
    }
  });

  _exports.default = _default;
});