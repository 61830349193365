define("lh4/serializers/s4-gift-cards/card", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'cardId',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'cards') {
        return 's4-gift-cards/card';
      }

      return payloadKey;
    }
  });

  _exports.default = _default;
});