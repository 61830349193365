define("lh4/models/echo-pro/department", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/utils/has-many-ids", "lh4/utils/stylesheet-to-color"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _hasManyIds, _stylesheetToColor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Department Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    onlineName: {
      description: 'Online Ordering Display Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    glyph: (0, _attr.default)('string'),
    guid: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isEbt: (0, _attr.default)('boolean'),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    onlineName: (0, _attr.default)('string'),
    onlineActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    onlineDescription: (0, _attr.default)('string'),
    isFastQuantityEnabled: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    courseGuid: (0, _attr.default)('string', {
      isCopyable: true
    }),
    items: (0, _relationships.hasMany)('echo-pro/item'),
    itemIds: Ember.computed('items', (0, _hasManyIds.default)('items', 'echo-pro/item')),
    stposUiStylesheetObjectLinks: (0, _relationships.hasMany)('echo-pro/stylesheet-object-link'),
    color: Ember.computed('stposUiStylesheetObjectLinks.@each.stposUiStylesheetGuid', (0, _stylesheetToColor.default)('stposUiStylesheetObjectLinks')),
    dynamicPricing: (0, _relationships.hasMany)('echo-pro/availability-price/price-setting'),
    availability: (0, _relationships.belongsTo)('echo-pro/availability-price/availability'),
    defaultAvailability: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    defaultAvailabilityString: Ember.computed('defaultAvailability', {
      get: function get() {
        return this.get('defaultAvailability') ? 'true' : 'false';
      },
      set: function set(key, value) {
        if (typeof value === 'string') {
          this.set('defaultAvailability', value === 'true');
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});