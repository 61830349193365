define("lh4/components/dashboard-widgets/daily-top-items/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['daily-top-items-data', 'table-widget'],
    skeletonData: {
      items: [{
        name: 'Kebab',
        total: 715.5
      }, {
        name: 'Hot Dog',
        total: 500.1
      }, {
        name: 'Pizza',
        total: 400.1
      }, {
        name: 'Burger',
        total: 200
      }, {
        name: 'Fries',
        total: 150
      }]
    },
    items: Ember.computed('expanded', 'data', function getter() {
      if (this.get('expanded')) {
        return this.get('data.items');
      }

      return this.get('data.items').slice(0, 5);
    }),
    thereIsMore: Ember.computed('data', function getter() {
      return this.get('loading') || this.get('data.items.length') > 5;
    })
  });

  _exports.default = _default;
});