define("lh4/components/dashboard-widgets/voids/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['voids-data', 'table-widget'],
    skeletonData: {
      count: 10,
      value: 210.27
    }
  });

  _exports.default = _default;
});