define("lh4/components/virtual-gift-cards", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isLoading: false,
    giftCardIframeError: null,
    giftCardIframeData: {},
    shift4ShopEnv: _environment.default.s4shopEnvironment,
    setIsLoading: function setIsLoading(isLoading) {
      if (!this.isDestroyed) {
        this.set('isLoading', isLoading);
      }
    },
    actions: {
      initializeIframe: function initializeIframe() {
        var _this = this;

        if (!this.giftCardIframeData) {
          return;
        }

        this.setIsLoading(true);
        var _this$giftCardIframeD = this.giftCardIframeData,
            _this$giftCardIframeD2 = _this$giftCardIframeD.merchant,
            merchant = _this$giftCardIframeD2 === void 0 ? undefined : _this$giftCardIframeD2,
            _this$giftCardIframeD3 = _this$giftCardIframeD.accessToken,
            accessToken = _this$giftCardIframeD3 === void 0 ? '' : _this$giftCardIframeD3;

        if (window.TSModule && merchant && accessToken) {
          window.TSModule.Initialize(merchant, accessToken, this.shift4ShopEnv).then(function () {
            var iframe = _this.get('element').querySelector('iframe.s4sportal');

            iframe.onload = function () {
              _this.setIsLoading(false);
            };
          }).catch(function () {
            _this.setIsLoading(false);
          });
        } else {
          this.setIsLoading(false);
        }
      }
    }
  });

  _exports.default = _default;
});