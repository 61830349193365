define("lh4/templates/components/ui/fields/input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DbqRir07",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[11,\"class\",[29,[[22,\"labelClass\"]]]],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"input\",null,[[\"value\",\"type\",\"min\",\"max\",\"name\",\"class\",\"placeholder\",\"maxlength\",\"disabled\"],[[24,[\"value\"]],[24,[\"type\"]],[24,[\"min\"]],[24,[\"max\"]],[24,[\"name\"]],[24,[\"inputClass\"]],[24,[\"placeholder\"]],[24,[\"maxlength\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/fields/input-field.hbs"
    }
  });

  _exports.default = _default;
});