define("lh4/templates/components/ui/search/social-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t2kKAqk7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui input\"],[8],[0,\"\\n\\t\"],[1,[28,\"input\",null,[[\"value\",\"type\",\"placeholder\",\"maxlength\",\"key-up\"],[[24,[\"searchText\"]],\"text\",[24,[\"placeholder\"]],\"32\",[28,\"action\",[[23,0,[]],\"onSearchChanged\"],null]]]],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"search-social\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"searchText\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\",false],[12,\"class\",\"times circle icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"onClear\"]],[8],[9],[0,\"\\t\\t\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"search icon\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/search/social-search.hbs"
    }
  });

  _exports.default = _default;
});