define("lh4/components/ui/step-container/form-content", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'ui-step-container-form-content',
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid']
  });

  _exports.default = _default;
});