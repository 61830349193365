define("lh4/components/ui/s4/icons/kiosk-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
    <path d="M50 93.5697C25.98 93.5697 6.42999 74.0297 6.42999 49.9997C6.42999 25.9697 25.98 6.42969 50 6.42969C74.02 6.42969 93.57 25.9697 93.57 49.9997C93.57 74.0297 74.03 93.5697 50 93.5697ZM50 8.42969C27.08 8.42969 8.42999 27.0797 8.42999 49.9997C8.42999 72.9197 27.08 91.5697 50 91.5697C72.92 91.5697 91.57 72.9197 91.57 49.9997C91.57 27.0797 72.92 8.42969 50 8.42969Z" fill="black"/>
    <path d="M50 83.9099C31.3 83.9099 16.09 68.6998 16.09 49.9998C16.09 31.2998 31.3 16.0898 50 16.0898C68.7 16.0898 83.91 31.2998 83.91 49.9998C83.91 68.6998 68.7 83.9099 50 83.9099ZM50 18.0898C32.4 18.0898 18.09 32.4098 18.09 49.9998C18.09 67.5898 32.41 81.9099 50 81.9099C67.59 81.9099 81.91 67.5898 81.91 49.9998C81.91 32.4098 67.59 18.0898 50 18.0898Z" fill="black"/>
    <path d="M43.71 65.1103L32.77 54.1603L34.18 52.7503L43.71 62.2803L68.16 37.8203L69.58 39.2403L43.71 65.1103Z" fill="black"/>
  </svg>
  
  */
  {
    id: "bj4gp89j",
    block: "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"100\"],[10,\"height\",\"100\"],[10,\"viewBox\",\"0 0 100 100\"],[10,\"fill\",\"none\"],[8],[0,\"\\n  \"],[7,\"path\",true],[10,\"d\",\"M50 93.5697C25.98 93.5697 6.42999 74.0297 6.42999 49.9997C6.42999 25.9697 25.98 6.42969 50 6.42969C74.02 6.42969 93.57 25.9697 93.57 49.9997C93.57 74.0297 74.03 93.5697 50 93.5697ZM50 8.42969C27.08 8.42969 8.42999 27.0797 8.42999 49.9997C8.42999 72.9197 27.08 91.5697 50 91.5697C72.92 91.5697 91.57 72.9197 91.57 49.9997C91.57 27.0797 72.92 8.42969 50 8.42969Z\"],[10,\"fill\",\"black\"],[8],[9],[0,\"\\n  \"],[7,\"path\",true],[10,\"d\",\"M50 83.9099C31.3 83.9099 16.09 68.6998 16.09 49.9998C16.09 31.2998 31.3 16.0898 50 16.0898C68.7 16.0898 83.91 31.2998 83.91 49.9998C83.91 68.6998 68.7 83.9099 50 83.9099ZM50 18.0898C32.4 18.0898 18.09 32.4098 18.09 49.9998C18.09 67.5898 32.41 81.9099 50 81.9099C67.59 81.9099 81.91 67.5898 81.91 49.9998C81.91 32.4098 67.59 18.0898 50 18.0898Z\"],[10,\"fill\",\"black\"],[8],[9],[0,\"\\n  \"],[7,\"path\",true],[10,\"d\",\"M43.71 65.1103L32.77 54.1603L34.18 52.7503L43.71 62.2803L68.16 37.8203L69.58 39.2403L43.71 65.1103Z\"],[10,\"fill\",\"black\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/kiosk-check.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});