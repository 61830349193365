define("lh4/routes/authenticated/locations/location", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/utils/route-rephrase"], function (_exports, _authenticatedRouteMixin, _routeRephrase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    permissions: Ember.inject.service(),
    location: Ember.inject.service(),
    model: function model(params) {
      if (params.locationId !== this.location.model.id) {
        return this.location.loadLocation(params.locationId);
      }

      return this.location.loadLocation(this.location.model.id);
    },
    setupController: function setupController(controller) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [controller].concat(args));

      controller.set('locations', this.modelFor('authenticated.locations').locations);
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      this._super.apply(this, [transition].concat(args));

      var newTransition = transition.to.queryParams['authenticated.locations.location'];

      if (!newTransition) {
        return;
      }

      var currentRouteId = newTransition.locationId;
      var previousRouteId = this.get('location.id');

      if (currentRouteId === previousRouteId || !previousRouteId) {
        return;
      }

      var previousRouteName = (0, _routeRephrase.default)(this.controllerFor('application').currentRouteName);

      if (!previousRouteName || previousRouteName === 'authenticated.locations.location.overview' || !/^locations.location/.test(previousRouteName)) {
        return;
      }

      Ember.RSVP.Promise.all([this.store.findRecord('location', currentRouteId), this.store.peekRecord('location', previousRouteId)]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            currentRoute = _ref2[0],
            previousRoute = _ref2[1];

        if (currentRoute && previousRoute) {
          if (currentRoute.get('product') !== previousRoute.get('product')) {
            return false;
          }

          var hasReservations = currentRoute.get('services').find(function (s) {
            return s.name === 'reservations';
          });

          if (!previousRouteName.includes('reservations') || hasReservations) {
            return _this.transitionTo(previousRouteName);
          }
        }

        return _this.transitionTo('authenticated.locations.location.overview');
      });
    }
  });

  _exports.default = _default;
});