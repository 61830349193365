define("lh4/components/epro/menu/price-schedules-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    locale: Ember.inject.service(),
    showErrors: false,
    validatePriceValue: function validatePriceValue(record) {
      var _this = this;

      this.set('errorMessage', null);
      this.$('.price-schedule-dropdown').removeClass('disabled');
      record.validate('priceValue').then(function (data) {
        if (record.get('isInvalid')) {
          _this.$('.price-schedule-dropdown').addClass('disabled');

          _this.set('errorMessage', data.validation);
        }
      });
    },
    actions: {
      setPriceType: function setPriceType(record, priceType) {
        record.set('priceType', priceType);
        this.validatePriceValue(record);
      },
      handlePriceValueChange: function handlePriceValueChange(record, priceValue) {
        record.set('priceValue', priceValue);
        this.validatePriceValue(record);
      }
    }
  });

  _exports.default = _default;
});