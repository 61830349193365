define("lh4/components/virtual-terminal/refund-settings/steps/update-settings/index", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#ui/step-container/form-content stepHeader=stepHeader}}
  	{{ui/form-fields/checkbox-field
  		dataTest="allow-prepaid-field"
  		class="checkbox-field"
  		label="Allow Prepaid Cards"
  		changeset=changeset
  		property="allowPrepaid"
  		showErrors=showErrors
  	}}
  	{{ui/form-fields/checkbox-field
  		dataTest="allow-non-prepaid-field"
  		class="checkbox-field"
  		label="Allow Non-prepaid Cards"
  		changeset=changeset
  		property="allowNonPrepaid"
  		showErrors=showErrors
  	}}
  	{{ui/form-fields/textarea-field
  		dataTest="reason-field"
  		label="Reason for Change"
  		changeset=changeset
  		property="reason"
  		showErrors=showErrors
  	}}
  	{{ui/form-fields/required-fields dataTest="required-indicator"}}
  {{/ui/step-container/form-content}}
  
  */
  {
    id: "RX5d/67Q",
    block: "{\"symbols\":[],\"statements\":[[4,\"ui/step-container/form-content\",null,[[\"stepHeader\"],[[24,[\"stepHeader\"]]]],{\"statements\":[[0,\"\\t\"],[1,[28,\"ui/form-fields/checkbox-field\",null,[[\"dataTest\",\"class\",\"label\",\"changeset\",\"property\",\"showErrors\"],[\"allow-prepaid-field\",\"checkbox-field\",\"Allow Prepaid Cards\",[24,[\"changeset\"]],\"allowPrepaid\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\\t\"],[1,[28,\"ui/form-fields/checkbox-field\",null,[[\"dataTest\",\"class\",\"label\",\"changeset\",\"property\",\"showErrors\"],[\"allow-non-prepaid-field\",\"checkbox-field\",\"Allow Non-prepaid Cards\",[24,[\"changeset\"]],\"allowNonPrepaid\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\\t\"],[1,[28,\"ui/form-fields/textarea-field\",null,[[\"dataTest\",\"label\",\"changeset\",\"property\",\"showErrors\"],[\"reason-field\",\"Reason for Change\",[24,[\"changeset\"]],\"reason\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\\t\"],[1,[28,\"ui/form-fields/required-fields\",null,[[\"dataTest\"],[\"required-indicator\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/virtual-terminal/refund-settings/steps/update-settings/index.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'virtual-terminal-refund-settings-steps-update-settings',
    classNames: ['update-settings-step']
  }));

  _exports.default = _default;
});