define("lh4/components/table-layout/table-settings-edit", ["exports", "lh4/components/table-layout/shared/canvas-rotate-actions", "lh4/components/table-layout/shared/constants"], function (_exports, _canvasRotateActions, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MIN_TABLE_SEATS = 1;
  var MAX_TABLE_SEATS = 10;

  var _default = Ember.Component.extend({
    maxTableSeats: 10,
    table: null,
    sections: [],
    currentSection: null,
    tableSection: Ember.computed('currentSection', function getter() {
      // To prevent setting currentSection
      return this.get('currentSection');
    }),
    selectedTableSeats: Ember.computed('table.meta.maxOccupants', function getter() {
      return this.get('table.meta.maxOccupants');
    }),
    tableShape: Ember.computed('table', function getter() {
      if (!this.get('table')) {
        return '';
      }

      return this.get('table').getObjects().filter(function (shape) {
        return !shape.isType('text');
      }).pop().isType(_constants.TABLE_SHAPE_RECT) ? _constants.TABLE_SHAPE_RECT : _constants.TABLE_SHAPE_ROUND;
    }),
    tableIsRect: Ember.computed('table', function getter() {
      if (!this.get('table')) {
        return false;
      }

      return this.get('tableShape') === _constants.TABLE_SHAPE_RECT;
    }),
    updateDropdownText: Ember.observer('currentSection.name', function observe() {
      var dropdownTextElement = this.element.querySelector('.ui.dropdown .text');

      if (dropdownTextElement) {
        dropdownTextElement.innerText = this.currentSection.name;
      }
    }),
    actions: {
      changeTableSection: function changeTableSection(selectedSection) {
        if (this.get('table')) {
          this.sendAction('onSectionChanged', this.get('table.meta.guid'), selectedSection);
        }
      },
      removeSeat: function removeSeat() {
        var currentSeats = parseInt(this.get('table.meta.maxOccupants'), 10);

        if (currentSeats > MIN_TABLE_SEATS) {
          this.set('table.meta.maxOccupants', currentSeats - 1);
        }

        this.sendAction('updateModel', this.get('table.meta.guid'), {
          maxOccupants: this.get('table.meta.maxOccupants')
        });
      },
      addSeat: function addSeat() {
        var currentSeats = parseInt(this.get('table.meta.maxOccupants'), 10);

        if (currentSeats < MAX_TABLE_SEATS) {
          this.set('table.meta.maxOccupants', currentSeats + 1);
        }

        this.sendAction('updateModel', this.get('table.meta.guid'), {
          maxOccupants: this.get('table.meta.maxOccupants')
        });
      },
      setShape: function setShape(value) {
        if (!this.get('table')) {
          return;
        }

        var id = this.get('table.meta.guid');

        if (this.get('tableIsRect') && value === _constants.TABLE_SHAPE_ROUND) {
          this.sendAction('updateModel', id, {
            width: this.get('table.width'),
            height: this.get('table.height'),
            shape: value
          });
        } else if (!this.get('tableIsRect') && value === _constants.TABLE_SHAPE_RECT) {
          this.sendAction('updateModel', id, {
            width: this.get('table.radius') * 2,
            height: this.get('table.radius') * 2,
            shape: value
          });
        }

        this.notifyPropertyChange('table');
        this.sendAction('onShapeChanged', this.get('table'));
      },
      shrinkX: function shrinkX() {
        var _this = this;

        this.get('table').getObjects().filter(function (shape) {
          return !shape.isType('text') && shape.get('width') > 40;
        }).forEach(function (shape) {
          shape.set('width', shape.get('width') - 5);

          _this.set('table.width', shape.get('width'));

          _this.sendAction('updateModel', _this.get('table.meta.guid'), {
            width: shape.get('width')
          });
        });
        this.sendAction('redrawObject', this.get('table'));
      },
      enlargeX: function enlargeX() {
        var _this2 = this;

        this.get('table').getObjects().filter(function (shape) {
          return !shape.isType('text');
        }).forEach(function (shape) {
          shape.set('width', shape.get('width') + 5);

          _this2.set('table.width', shape.get('width'));

          _this2.sendAction('updateModel', _this2.get('table.meta.guid'), {
            width: shape.get('width')
          });
        });
        this.sendAction('redrawObject', this.get('table'));
      },
      shrinkY: function shrinkY() {
        var _this3 = this;

        this.get('table').getObjects().filter(function (shape) {
          return !shape.isType('text') && shape.get('height') > 40;
        }).forEach(function (shape) {
          shape.set('height', shape.get('height') - 5);

          _this3.set('table.height', shape.get('height'));

          _this3.sendAction('updateModel', _this3.get('table.meta.guid'), {
            height: shape.get('height')
          });
        });
        this.sendAction('redrawObject', this.get('table'));
      },
      enlargeY: function enlargeY() {
        var _this4 = this;

        this.get('table').getObjects().filter(function (shape) {
          return !shape.isType('text');
        }).forEach(function (shape) {
          shape.set('height', shape.get('height') + 5);

          _this4.set('table.height', shape.get('height'));

          _this4.sendAction('updateModel', _this4.get('table.meta.guid'), {
            height: shape.get('height')
          });
        });
        this.sendAction('redrawObject', this.get('table'));
      },
      rotateMinus: function rotateMinus() {
        (0, _canvasRotateActions.rotateMinus)(this.get('table'));
        this.sendAction('redrawObject', this.get('table'));
        this.sendAction('updateModel', this.get('table.meta.guid'), {
          rotation: this.get('table.meta.angle')
        });
      },
      rotatePlus: function rotatePlus() {
        (0, _canvasRotateActions.rotatePlus)(this.get('table'));
        this.sendAction('redrawObject', this.get('table'));
        this.sendAction('updateModel', this.get('table.meta.guid'), {
          rotation: this.get('table.meta.angle')
        });
      },
      shrinkRadius: function shrinkRadius() {
        var _this5 = this;

        this.get('table').getObjects().filter(function (shape) {
          return !shape.isType('text') && shape.get('radius') > 20;
        }).forEach(function (shape) {
          shape.set('radius', shape.get('radius') - 2.5);

          _this5.set('table.radius', shape.get('radius'));

          _this5.sendAction('updateModel', _this5.get('table.meta.guid'), {
            width: shape.get('radius') * 2,
            height: shape.get('radius') * 2
          });
        });
        this.sendAction('redrawObject', this.get('table'));
      },
      enlargeRadius: function enlargeRadius() {
        var _this6 = this;

        this.get('table').getObjects().filter(function (shape) {
          return !shape.isType('text');
        }).forEach(function (shape) {
          shape.set('radius', shape.get('radius') + 2.5);

          _this6.set('table.radius', shape.get('radius'));

          _this6.sendAction('updateModel', _this6.get('table.meta.guid'), {
            width: shape.get('radius') * 2,
            height: shape.get('radius') * 2
          });
        });
        this.sendAction('redrawObject', this.get('table'));
      }
    }
  });

  _exports.default = _default;
});