define("lh4/templates/components/verify-owner/owner-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2luEXc7s",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"dataTest\",\"changeset\",\"inputmode\",\"label\",\"pattern\",\"placeholder\",\"property\",\"showErrors\"],[\"ssn-field\",[24,[\"changeset\"]],\"numeric\",\"SSN Last 4 Digits\",\"[0-9]{4}\",\"Enter last 4 digits of your SSN\",\"ssnProof\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"dataTest\",\"changeset\",\"label\",\"placeholder\",\"property\",\"showErrors\"],[\"dob-field\",[24,[\"changeset\"]],\"Date of Birth\",\"MM/DD/YYYY\",\"birthdate\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"dataTest\",\"changeset\",\"inputmode\",\"label\",\"pattern\",\"placeholder\",\"property\",\"showErrors\"],[\"bank-field\",[24,[\"changeset\"]],\"numeric\",\"Current Bank Account Last 4 Digits\",\"[0-9]{4}\",\"Enter last 4 digits of bank account on file\",\"accountProof\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\"],[1,[28,\"ui/form-fields/required-fields\",null,[[\"dataTest\"],[\"required-indicator\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/verify-owner/owner-form.hbs"
    }
  });

  _exports.default = _default;
});