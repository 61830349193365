define("lh4/mirage/factories/hospitality-choice-set", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    locationId: 1,
    alternateKPrint: '',
    // A null value hasnt been tested with the print functions.  This is the usual default
    canSelectMultiple: false,
    choiceCatId: null,
    choiceCatName: function choiceCatName(i) {
      return "Choice Cat Name - ".concat(i);
    },
    choiceCategoryGenreId: 0,
    defaultChoiceId: 0,
    inheritsPricing: false,
    isActive: true,
    isOptional: true,
    isOptionalForBartender: false,
    isTimid: false,
    ordinal: 0,
    percentageModifier: 0.00,
    prependCatName: false,
    priceModifier: 0.00,
    printsRed: false,
    quantityBehavior: 0,
    quantityMax: 1,
    quantityMin: 1,
    rowguid: function rowguid() {
      return _faker.default.datatype.uuid();
    },
    rvChoiceCategory: [],
    useLargeUI: false,
    usesPercentage: false,
    choiceChoiceSets: [],
    menuItemsChoiceSets: []
  });

  _exports.default = _default;
});