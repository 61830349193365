define("lh4/utils/boolean-property-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getBooleanPropertyArray;

  /**
   * Returns an object with a getter/setter used to return an array of
   * strings representing properties (on an EmberObject) with a value of
   * true when given a list of keys to iterate over.
   * When calling the setter the properties in the array will be set to true and
   * any omitted values to false
   *
   * @method getBooleanPropertyArray
   * @param {Array} propertyKeys An array of keys on the EmberObject
   * @returns {Object} booleanPropertyArray
   */
  function getBooleanPropertyArray() {
    var propertyKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return {
      get: function get() {
        var _this = this;

        return propertyKeys.filter(function (key) {
          return _this[key];
        });
      },
      set: function set(key, values) {
        var properties = {};
        propertyKeys.forEach(function (option) {
          properties[option] = values.includes(option);
        });
        this.setProperties(properties);
        return values;
      }
    };
  }
});