define("lh4/abilities/loyalty", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/config/environment", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _environment, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    store: Ember.inject.service(),
    canView: (0, _abilityMixin.computedAbility)(function getter() {
      var anyLocationHasFeatureFlag = true;

      if (_environment.default.respectLocationFeatureFlags) {
        anyLocationHasFeatureFlag = this.store.peekAll('location').any(function (l) {
          var _l$features;

          return ((_l$features = l.features) === null || _l$features === void 0 ? void 0 : _l$features.loyaltyv1) === true;
        });
      }

      return this.hasProduct('echo-pro') && anyLocationHasFeatureFlag;
    }),
    canManagePrograms: (0, _abilityMixin.computedAbility)(function getter() {
      return this.canView && this.hasPermission(_permissions.default.LOYALTY.MANAGE_PROGRAMS);
    }),
    canManageDiscounts: (0, _abilityMixin.computedAbility)(function getter() {
      return this.canView && this.hasPermission(_permissions.default.LOYALTY.MANAGE_DISCOUNTS);
    }),
    canManageMembers: (0, _abilityMixin.computedAbility)(function getter() {
      return this.canView && this.hasPermission(_permissions.default.LOYALTY.MANAGE_MEMBERS);
    }),
    canViewPromotions: (0, _abilityMixin.computedAbility)(function getter() {
      return this.canView;
    }),
    canManagePromotions: (0, _abilityMixin.computedAbility)(function getter() {
      return this.canView && this.hasPermission(_permissions.default.LOYALTY.MANAGE_PROMOTIONS);
    })
  });

  _exports.default = _default;
});