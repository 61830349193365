define("lh4/mirage/routes/support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageSupportServer;

  function setMirageSupportServer(server) {
    server.get('/support/tickets/tickets', function (schema, request) {
      return {
        ticket: schema.supportTickets.where({
          locationId: request.queryParams.locations[0]
        }).models
      };
    });
    server.get('/support/tickets/tickets/:id', function (schema, request) {
      return {
        ticket: schema.supportTickets.where({
          ticketId: request.params.id
        }).models
      };
    });
    server.post('/support/tickets/tickets', function (schema, request) {
      var body = JSON.parse(request.requestBody);
      schema.supportTickets.create(body);
      return {
        ticket: body
      };
    });
    server.get('/support/tickets/groups', function () {
      return {
        groups: [{
          id: 1,
          name: 'Customer Support'
        }, {
          id: 2,
          name: 'Harbortouch Default LH Group #1'
        }]
      };
    });
  }
});