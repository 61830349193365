define("lh4/models/settings/user-permission-template", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships"], function (_exports, _emberData, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string'),
    parentUserId: (0, _relationships.belongsTo)('settings/user'),
    createdBy: (0, _relationships.belongsTo)('settings/user'),
    permissions: (0, _attr.default)(),
    createdAt: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('string')
  });

  _exports.default = _default;
});