define("lh4/templates/components/internet-payments/ui/search/list-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+B+ZyTiI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"list-search ui input search\",[28,\"if\",[[24,[\"error\"]],\" error\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"span\",false],[12,\"class\",\"search-icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"searchTerm\",true]],[8],[0,\"\\n\\t\\t\"],[1,[22,\"ui/icons/internet-payments/search\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[1,[28,\"input\",null,[[\"class\",\"type\",\"autocomplete\",\"placeholder\",\"value\",\"key-up\",\"insert-newline\",\"maxlength\"],[[24,[\"class\"]],\"text\",[24,[\"autocomplete\"]],[24,[\"placeholder\"]],[24,[\"proxy\"]],[28,\"action\",[[23,0,[]],\"searchTerm\",false],null],[28,\"action\",[[23,0,[]],\"searchTerm\",true],null],[24,[\"maxSearch\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"proxy\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",false],[12,\"class\",\"close-icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"clearTerm\"]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"ui/icons/internet-payments/plus-circle\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/search/list-search.hbs"
    }
  });

  _exports.default = _default;
});