define("lh4/abilities/echo-pro/external-order", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service(),
    canViewExternalOrders: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.hasProduct('echo-pro') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_EXTERNAL_ORDERS];
    })
  });

  _exports.default = _default;
});