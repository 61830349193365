define("lh4/utils/array-join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = joinNotBlank;

  /**
   * Returns concatenated string
   *
   * @method   joinNotBlank
   *
   * @param    {Object} source - Object to extract values
   * @param    {Array} keys - Keys to get from the source to join
   * @param    {String} separator - String to insert between values
   * @returns  {String} Concatenated string
   */
  function joinNotBlank(source, keys, separator) {
    return keys.map(function (key) {
      return Ember.get(source, key);
    }).filter(function (value) {
      return !Ember.isBlank(value);
    }).join(separator);
  }
});