define("lh4/templates/components/ui/buttons/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Q5u1vZe",
    "block": "{\"symbols\":[\"@variant\",\"@data-test-id\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"ui button v2 \",[23,1,[]]]]],[12,\"data-test-id\",[23,2,[]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[23,0,[\"handleClick\"]]]],[8],[0,\"\\n\\t\"],[14,3],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/buttons/button.hbs"
    }
  });

  _exports.default = _default;
});