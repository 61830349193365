define("lh4/serializers/echo-pro/availability-price/availability", ["exports", "lh4/serializers/echo-pro/availability-price/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _configuration.default.extend({});

  _exports.default = _default;
});