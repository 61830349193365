define("lh4/mirage/fixtures/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REPORTS_FIXTURE = {
    echo: [{
      name: '4070 Report',
      slug: '4070',
      product: 'echo',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }, {
      name: 'Cash Drawer',
      slug: 'cash-drawer',
      product: 'echo',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }],
    'echo-pro': [{
      name: 'Activity Summary',
      slug: 'activity-summary',
      product: 'echo-pro',
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      exports: {
        csv: false,
        pdf: true,
        xls: false,
        html: true
      },
      subscribable: true,
      custom: true,
      isHidden: false,
      showOnPos: false,
      menchiesOnly: false,
      featureFlag: null,
      isFavorite: false,
      enabledProd: true,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Cash Out Details by Employee',
      slug: 'cash-out-details',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Cash Transactions by Employee',
      slug: 'cash-transactions-by-employee',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Cash Money by Employee',
      slug: 'cash-money-by-employee',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: false
        },
        locations: {
          type: 'array',
          limit: 1
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Customer Directory',
      slug: 'customers-detail',
      product: 'hospitality',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: true,
      menchiesOnly: false,
      featureFlag: null
    }, {
      custom: false,
      subscribable: false,
      isHidden: false,
      isPaginated: false,
      menchiesOnly: false,
      product: 'echo-pro',
      slug: 'ticket-transaction-status',
      name: 'Ticket Transaction Status',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: false
      },
      params: {
        isSingleLocationReport: false,
        datePickerOptions: {
          hasMinutesDisabled: false,
          isDateTimeBusinessDay: true,
          isMaxDurationLimitingStart: true,
          singleDate: false,
          rangesEnabled: []
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        start: {
          type: 'date',
          date: true,
          time: true
        }
      },
      isFavorite: false
    }, {
      name: 'Tax Summary by Revenue Class',
      slug: 'tax-summary-by-revenue-class',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: 'reports-view-tax-summary-by-revenue-class'
    }, {
      name: 'Labors by Employee Weekly',
      slug: 'labor-by-employee-weekly',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Employee Shift Detail',
      slug: 'employee-shift-detail',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Employee Shift Detail - New',
      slug: 'employee-shift-detail-new',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: 'reports-view-employee-shift-detail-new'
    }, {
      name: 'Empoloyee Labor Detail',
      slug: 'employee-labor-detail',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Payroll by Employee',
      slug: 'payroll-by-employee',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Payroll by Employee by Job',
      slug: 'payroll-by-employee-by-job',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Sales Labor Summary Hourly',
      slug: 'sales-labor-summary-hourly',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Sales Labor by Day by Job',
      slug: 'sales-labor-by-day-by-job',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Payroll Export',
      slug: 'payroll-export',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Ticket Summary',
      slug: 'ticket-summary',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Sales Summary by Department',
      slug: 'sales-summary-by-department',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Sales Summary by Employee',
      slug: 'sales-summary-by-employee',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Sales Summary by Item',
      slug: 'sales-summary-by-item',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Sales Summary by Order Type',
      slug: 'sales-summary-by-order-type',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Sales Summary by Revenue Class',
      slug: 'sales-summary-by-revenue-class',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Sales Summary by Revenue Class by Order Type',
      slug: 'sales-summary-by-revenue-class-by-order-type',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Financial Overview',
      slug: 'financial-overview',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Weekly Financial Overview',
      slug: 'weekly-financial-overview',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Sales Summary by Modifier',
      slug: 'sales-summary-by-modifier',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null,
      isDayPartCompatible: true,
      isRevenueCenterCompatible: true
    }, {
      name: 'Employee Tip Totals - New',
      slug: 'employee-tip-totals-new',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: 'reports-view-employee-tip-totals-new'
    }],
    harborpay: [{
      name: 'Sales Summary',
      slug: 'sales-summary',
      product: 'harborpay',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
          /* limit: 1 */

        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }],
    hospitality: [{
      name: 'Basic Product Mix',
      slug: 'basic-product-mix',
      product: 'hospitality',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }, {
      name: 'Cash Drawer',
      slug: 'cash-drawer',
      product: 'hospitality',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }, {
      name: 'Restaurant Financial Overview',
      slug: 'financial-overview',
      product: 'hospitality',
      exports: {
        csv: false,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: true,
      isHidden: false,
      menchiesOnly: false
    }],
    lighthouse: [{
      name: 'Campaign Customer Activity',
      slug: 'customer-engagement-campaign-customer-activity',
      product: 'lighthouse',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: true,
      menchiesOnly: false
    }, {
      name: 'Customer Reviews',
      slug: 'customer-engagement-survey-reviews',
      product: 'lighthouse',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: true,
      menchiesOnly: false
    }],
    processing: [{
      name: 'Batches',
      slug: 'batches',
      product: 'processing',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }, {
      name: 'Settlement',
      slug: 'settlement',
      product: 'processing',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }, {
      name: 'Transactions',
      slug: 'transactions',
      product: 'processing',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }],
    retail: [{
      name: 'Payment Summary',
      slug: 'payment-summary',
      product: 'retail',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }, {
      name: 'Sales Summary',
      slug: 'sales-summary',
      product: 'retail',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }],
    solo: [{
      name: 'Quick Menu Summary',
      slug: 'quick-menu-summary',
      product: 'solo',
      exports: {
        csv: false,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: true,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Transaction Summary',
      slug: 'transaction-summary',
      product: 'solo',
      exports: {
        csv: false,
        pdf: true,
        xls: false,
        html: false
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: true,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }, {
      name: 'Sales Summary',
      slug: 'sales-summary',
      product: 'solo',
      exports: {
        csv: false,
        pdf: true,
        xls: true,
        html: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        }
      },
      subscribable: true,
      custom: true,
      isHidden: false,
      menchiesOnly: false,
      featureFlag: null
    }]
  };
  var _default = REPORTS_FIXTURE;
  _exports.default = _default;
});