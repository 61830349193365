define("lh4/mirage/routes/servers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMirageTransactionsServersServer;

  function getMirageTransactionsServersServer(server) {
    server.get('/transactions/servers', function () {
      return {
        servers: ['Mark Farner', 'Mike Reno']
      };
    });
  }
});