define("lh4/controllers/authenticated/supply-orders/location/supplies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      selectedSupply: function selectedSupply() {
        this.transitionToRoute('authenticated.supply-orders.location.order');
      }
    }
  });

  _exports.default = _default;
});