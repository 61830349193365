define("lh4/components/employee-jobs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    moreLink: false,
    limitedJobs: Ember.computed('jobs.[]', function getter() {
      if (this.get('jobs.length') >= 3) {
        this.set('moreLink', true);
        return this.get('jobs').slice(0, 3);
      }

      this.set('moreLink', false);
      return this.get('jobs');
    })
  });

  _exports.default = _default;
});