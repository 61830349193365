define("lh4/services/reputation/account-api", ["exports", "jquery", "lh4/config/environment", "lh4/services/reputation/yelp", "lh4/services/social-media/facebook", "lh4/services/reputation/google-my-business"], function (_exports, _jquery, _environment, _yelp, _facebook, _googleMyBusiness) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var SUPPORTED_ACCOUNTS = [_facebook.ACCOUNT_TYPE_FB_PAGE, _googleMyBusiness.ACCOUNT_TYPE_GOOGLE_BUSINESS, _yelp.ACCOUNT_TYPE_YELP];
  var comingSoonAccounts = [];

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    facebook: Ember.inject.service('social-media/facebook'),
    google: Ember.inject.service('reputation/google-my-business'),
    metrics: Ember.inject.service(),
    isValidAccountType: function isValidAccountType(accountType) {
      return accountType === _facebook.ACCOUNT_TYPE_FB_PAGE || accountType === _yelp.ACCOUNT_TYPE_YELP;
    },
    isFbPage: function isFbPage(accountType) {
      return accountType === _facebook.ACCOUNT_TYPE_FB_PAGE;
    },
    isYelp: function isYelp(accountType) {
      return accountType === _yelp.ACCOUNT_TYPE_YELP;
    },
    isGoogleBusiness: function isGoogleBusiness(accountType) {
      return accountType === _googleMyBusiness.ACCOUNT_TYPE_GOOGLE_BUSINESS;
    },
    supportedAccounts: SUPPORTED_ACCOUNTS,
    comingSoonAccounts: comingSoonAccounts,
    redirectToCreateAccount: function redirectToCreateAccount(accountType, routeName, location) {
      window.history.pushState({
        route: routeName
      }, 'account');
      window.localStorage.setItem('reputation-selected-location', location);

      if (this.isFbPage(accountType)) {
        this.facebook.redirectToFbAuth(accountType, _environment.default.reputation.facebook.redirectUrl);
      } else if (this.isYelp(accountType)) {
        return Ember.getOwner(this).lookup('router:main').replaceWith('authenticated.reputation.accounts.link', {
          queryParams: {
            accountType: accountType
          }
        });
      } else if (this.isGoogleBusiness(accountType)) {
        this.google.redirectToGoogleAuth(accountType);
      }

      return false;
    },
    updateAccount: function updateAccount(accountId, account) {
      return this.ajax.request("/api/v1/reputation/accounts/".concat(accountId), {
        type: 'PUT',
        data: JSON.stringify(_objectSpread({}, account))
      });
    },
    findAccount: function findAccount(accountId) {
      return this.ajax.request("/api/v1/reputation/accounts/".concat(accountId));
    },
    findAccounts: function findAccounts() {
      return this.ajax.request('/api/v1/reputation/accounts').then(function (_ref) {
        var accounts = _ref.accounts;
        return accounts;
      });
    },
    getAccountPages: function getAccountPages(accountId) {
      return this.ajax.request("/api/v1/reputation/accounts/".concat(accountId, "/link/search/facebook-page"));
    },
    getGoogleAccounts: function getGoogleAccounts(accountId) {
      return this.ajax.request("/api/v1/reputation/accounts/".concat(accountId, "/link/search/google/accounts")).then(function (_ref2) {
        var accounts = _ref2.accounts;
        return accounts || [];
      });
    },
    getGoogleAccountLocations: function getGoogleAccountLocations(accountId, externalId) {
      return this.ajax.request("/api/v1/reputation/accounts/".concat(accountId, "/link/search/google/accounts/").concat(externalId, "/locations")).then(function (_ref3) {
        var locations = _ref3.locations;
        return locations || [];
      });
    },
    getReviews: function getReviews(accountId, query) {
      var qs = _jquery.default.param(query);

      return this.ajax.request("/api/v1/reputation/accounts/".concat(accountId, "/reviews?").concat(qs));
    },
    getReviewComments: function getReviewComments(accountId, reviewId, query) {
      var qs = _jquery.default.param(query);

      return this.ajax.request("/api/v1/reputation/accounts/".concat(accountId, "/reviews/").concat(reviewId, "/comments?").concat(qs));
    },
    updateReviewStatus: function updateReviewStatus(accountId, reviewId, status) {
      return this.ajax.request("/api/v1/reputation/accounts/".concat(accountId, "/reviews/").concat(reviewId, "/status"), {
        headers: {
          'Content-Type': 'application/json'
        },
        type: 'PUT',
        data: JSON.stringify({
          status: status
        })
      });
    },
    respondToReview: function respondToReview(account, review, responseText) {
      var _this = this;

      return this.ajax.request("/api/v1/reputation/accounts/".concat(account.id, "/reviews/").concat(review.id, "/respond"), {
        headers: {
          'Content-Type': 'application/json'
        },
        type: 'POST',
        data: JSON.stringify({
          message: responseText
        })
      }).then(function (result) {
        _this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'Reputation Management',
          eventAction: 'Responded to Review'
        });

        return result;
      });
    },

    /**
     * Load rating historical data
     *
     * @param {string|number} externalId - account external ID
     * @param {{from, to}} query - query options
     * @returns {Promise<Array<object>>} historical ratings
     */
    getRatingHistory: function getRatingHistory(externalId, query) {
      var qs = _jquery.default.param(query);

      return this.ajax.request("/api/v1/reputation/accounts/history/".concat(externalId, "/ratings?").concat(qs));
    },
    deleteAccount: function deleteAccount(accountId) {
      return this.ajax.request("/api/v1/reputation/accounts/".concat(accountId), {
        type: 'DELETE'
      });
    },
    validateAccount: function validateAccount(account) {
      var errs = [];

      if (!account.name || !account.name.trim()) {
        errs.push('name');
      }

      if (!account.locationId) {
        errs.push('locationId');
      }

      return errs;
    },
    getExcludedLocations: function getExcludedLocations(only) {
      return this.ajax.request("/api/v1/reputation/locations?skip=".concat(only));
    }
  });

  _exports.default = _default;
});