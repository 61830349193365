define("lh4/components/new-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'strong',
    classNames: ['new-icon'],
    text: 'New'
  });

  _exports.default = _default;
});