define("lh4/mirage/factories/hospitality-revenue-center", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    locationId: function locationId(i) {
      return i;
    },
    name: function name() {
      return _faker.default.commerce.productName();
    },
    ordinal: 0,
    isActive: true,
    rowguid: function rowguid() {
      return _faker.default.datatype.uuid();
    }
  });

  _exports.default = _default;
});