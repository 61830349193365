define("lh4/controllers/authenticated/customer-database/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['step'],
    customersImport: Ember.inject.service(),
    ajax: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    location: Ember.inject.service(),
    attachment: null,
    isAttachmentValid: false,
    step: 0,
    steps: ['Upload Contacts', 'Map Data', 'Import Data'],
    customerColumns: [],
    columns: [],
    parsedColumns: [],
    parsedCustomers: [],
    selectedCustomers: [],
    canGoForward: Ember.computed('columnValidations.[]', 'step', 'isAttachmentValid', function getter() {
      if (!this.get('isAttachmentValid')) {
        return false;
      }

      if (this.get('step') !== 1) {
        return true;
      }

      return this.get('columnValidations.length') === 0;
    }),
    columnValidations: Ember.computed('columns.@each.status', function getter() {
      return this.customersImport.validateColumns(this.get('columns'));
    }),
    importValidations: Ember.computed('parsedCustomers.[]', function getter() {
      return this.customersImport.validateCustomers(this.get('parsedCustomers'));
    }),
    importProductType: Ember.computed('location.model', function getter() {
      var path = this.location.model.product === 'echo-pro' ? 'echo-pro' : 'processing';
      var templateName = this.location.isUS ? 'customers-import-template.xlsx' : 'customers-import-template-for-non-us.xlsx';
      return {
        link: "/api/v1/".concat(path, "/customers/import"),
        modelPath: "".concat(path, "/customer"),
        templatePath: "/resources/".concat(path, "/").concat(templateName)
      };
    }),
    reset: function reset() {
      this.set('attachment', null);
      this.set('isAttachmentValid', false);
      this.set('columns', []);
      this.set('parsedCustomers', []);
      this.set('selectedCustomers', []);
      this.set('step', 0);
    },
    generateCustomers: function generateCustomers() {
      var columns = this.get('columns').filter(function (column) {
        return column.get('status') === 'defined';
      });
      var wrappers = this.customersImport.toCustomers(columns, this.get('model.location'));
      this.set('parsedColumns', columns.map(function (column) {
        return column.get('attribute.displayName');
      }));
      this.set('parsedCustomers', wrappers);
      this.set('selectedCustomers', wrappers.filter(function (wrapper) {
        return wrapper.customer.get('validations.isValid');
      }));
    },
    actions: {
      goForward: function goForward() {
        if (!this.get('canGoForward')) {
          return;
        }

        this.incrementProperty('step');

        if (this.get('step') === 2) {
          this.generateCustomers();
        }
      },
      goBackward: function goBackward() {
        if (this.get('step') < 1) {
          return;
        }

        this.decrementProperty('step');
      },
      parseCustomersFile: function parseCustomersFile(file) {
        var _this = this;

        this.set('isAttachmentValid', false);
        this.customersImport.parse(file).then(function (columns) {
          _this.set('columns', columns);

          _this.set('isAttachmentValid', true);
        }).catch(function (error) {
          _this.set('attachment', null);

          _this.flashMessagesHelper.pushMessage(error.message);
        });
      },
      import: function _import() {
        var _this2 = this;

        if (this.get('isImporting') || !this.get('selectedCustomers.length')) {
          return;
        }

        var serialized = this.get('selectedCustomers').map(function (wrapper) {
          return wrapper.customer.serialize();
        });
        var data = JSON.stringify({
          customers: serialized
        });
        this.set('isImporting', true);
        this.ajax.post(this.importProductType.link, {
          data: data
        }).then(function (response) {
          _this2.store.pushPayload(_this2.importProductType.modelPath, response);

          _this2.flashMessagesHelper.pushMessage('Customers were imported', 'success');

          Ember.run.later(function () {
            _this2.reset();

            _this2.send('refreshModel');

            _this2.transitionToRoute('authenticated.customer-database');
          }, 2000);
        }).catch(function () {
          _this2.flashMessagesHelper.pushMessage('There was an unexpected error importing customers', 'error');
        }).finally(function () {
          _this2.set('isImporting', false);
        });
      },
      close: function close() {
        var route = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'authenticated.customer-database';
        this.reset();
        this.transitionToRoute(route);
      }
    }
  });

  _exports.default = _default;
});