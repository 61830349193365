define("lh4/models/internet-payments/risk-rule", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    action: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    group: (0, _attr.default)('string'),
    isEditable: (0, _attr.default)('boolean'),
    isEnabled: (0, _attr.default)('boolean'),
    isKountSync: (0, _attr.default)('boolean'),
    kountRule: (0, _attr.default)(),
    name: (0, _attr.default)('string'),
    proposedChanges: (0, _attr.default)(),
    ruleNumber: (0, _attr.default)('number'),
    score: (0, _attr.default)('number')
  });

  _exports.default = _default;
});