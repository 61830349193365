define("lh4/templates/components/clockpicker-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8/7pYyDO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\"],[1,[28,\"input\",null,[[\"readonly\",\"value\",\"type\",\"placeholder\",\"disabled\",\"name\"],[true,[28,\"if\",[[24,[\"modifyValue\"]],[28,\"modify-time\",[[24,[\"value\"]]],null],[24,[\"value\"]]],null],\"text\",[24,[\"placeholder\"]],[24,[\"disabled\"]],[24,[\"name\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/clockpicker-input.hbs"
    }
  });

  _exports.default = _default;
});