define("lh4/routes/authenticated/reputation/accounts/add", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    actions: {
      willTransition: function willTransition() {
        var _this = this;

        Ember.run.later(this, function () {
          _this.controllerFor(_this.routeName).set('wants', null);
        }, 1000);
      }
    },
    setupController: function setupController(controller) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [controller].concat(args));

      controller.set('isLoading', false);
    }
  });

  _exports.default = _default;
});