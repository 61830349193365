define("lh4/components/reputation/yelp-business-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    yelp: Ember.inject.service('reputation/yelp'),
    media: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    searchLocation: '',
    searchTerm: '',
    isLoading: false,
    errorMessages: [],
    errorFields: {},
    results: [],
    selectedBusiness: null,
    limit: 50,
    pageSize: 5,
    actions: {
      search: function search() {
        var _this = this;

        this.set('isLoading', true);
        this.set('errorFields', {});
        this.yelp.findYelpBusinesses({
          term: this.get('searchTerm'),
          location: this.get('searchLocation'),
          limit: this.get('limit')
        }).then(function (res) {
          _this.set('results', res);

          _this.set('errorMessages', []);
        }).catch(function (err) {
          if (_this.get('searchLocation') && !_this.get('selectedBusiness')) {
            _this.set('results', []);
          }

          var responseErrors = _this.responseMessages.getResponseErrors(err);

          _this.set('errorMessages', responseErrors);

          if (responseErrors.some(function (errMsg) {
            return errMsg.includes('location');
          })) {
            _this.set('errorFields', {
              searchLocation: true
            });
          }
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      select: function select(business) {
        this.set('selectedBusiness', business);
        this.sendAction('onSelectBusiness', business);
      },
      unSelect: function unSelect() {
        this.set('selectedBusiness', null);
        this.sendAction('onSelectBusiness', null);
      }
    }
  });

  _exports.default = _default;
});