define("lh4/constants/launch-control/modules/online-ordering", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ONLINE_ORDERING_DELIVERY_RANGE_TYPE = void 0;
  // eslint-disable-next-line import/prefer-default-export
  var ONLINE_ORDERING_DELIVERY_RANGE_TYPE = {
    distance: 'distance',
    area: 'area'
  };
  _exports.ONLINE_ORDERING_DELIVERY_RANGE_TYPE = ONLINE_ORDERING_DELIVERY_RANGE_TYPE;
});