define("lh4/models/hospitality/choice-set", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    choiceCatName: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    locationId: (0, _attr.default)('number'),
    // A null value hasn't been tested with the print functions. This is the usual default
    alternateKPrint: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    canSelectMultiple: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    choiceCatId: (0, _attr.default)('number', {
      defaultValue: null
    }),
    choiceCatName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    choiceCategoryGenreId: (0, _attr.default)('number', {
      defaultValue: null
    }),
    defaultChoiceId: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    inheritsPricing: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isOptional: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isOptionalForBartender: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isTimid: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    percentageModifier: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    prependCatName: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    priceModifier: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    printsRed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    quantityBehavior: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    quantityMax: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    quantityMin: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    rowguid: (0, _attr.default)('string'),
    rvChoiceCategory: (0, _attr.default)(),
    useLargeUI: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    usesPercentage: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    choiceChoiceSets: (0, _attr.default)(),
    menuItemsChoiceSets: (0, _attr.default)()
  });

  _exports.default = _default;
});