define("lh4/models/echo-pro/order-type", ["exports", "ember-data", "ember-cp-validations", "lh4/utils/model-validators", "lh4/mixins/model-cloneable", "lh4/utils/boolean-property-array"], function (_exports, _emberData, _emberCpValidations, _modelValidators, _modelCloneable, _booleanPropertyArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_COLOR = _exports.COLORS = void 0;

  var _Ember, _Ember2;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var COLORS = {
    ORANGE: '#FF9D43',
    TEAL: '#00B2BF',
    FUSCIA: '#AD5AFB',
    LIME: '#32FEAD',
    PURPLE: '#8095FF',
    RED: '#FF6B6B',
    PINK: '#F571FC',
    GREEN: '#5DCA07',
    YELLOW: '#FFE100',
    CYAN: '#00C8FF'
  };
  _exports.COLORS = COLORS;
  var DEFAULT_COLOR = COLORS.CYAN;
  _exports.DEFAULT_COLOR = DEFAULT_COLOR;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Order Type Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100,
        description: 'Order type name length'
      })]
    },
    onlineName: {
      description: 'Online Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 65,
        description: 'Online Name length'
      })]
    },
    minDeferredTime: {
      description: 'Min Deferred Days',
      disabled: Ember.computed.not('model.isDeferred'),
      dependentKeys: ['isDeferred'],
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        dependentKeys: ['isDeferred']
      }), (0, _modelValidators.numberIntValidator)(0, 30, {
        allowBlank: false
      })]
    },
    maxDeferredTime: {
      description: 'Max Deferred Days',
      disabled: Ember.computed.not('model.isDeferred'),
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        dependentKeys: ['isDeferred']
      }), (0, _emberCpValidations.validator)('number', {
        message: "Max Deferred Days can't be less than Min Deferred Days",
        allowString: true,
        integer: true,
        gte: Ember.computed.alias('model.minDeferredTime'),
        lte: 30,
        dependentKeys: ['isDeferred']
      })]
    },
    minOrderAmount: {
      description: 'Minimum Order Amount',
      validators: [(0, _modelValidators.numberFloatValidator)()]
    },
    maxOrderAmount: {
      description: 'Maximum Order Amount',
      validators: [(0, _emberCpValidations.validator)('number', {
        message: "Maximum Order Amount can't be less than Minimum Order Amount",
        allowString: true,
        allowBlank: true,
        integer: false,
        gte: Ember.computed.alias('model.minOrderAmount')
      })]
    },
    deliveryDistance: {
      description: 'Maximum Delivery Distance (miles)',
      validators: [(0, _modelValidators.numberFloatValidator)()]
    },
    prepTimeMinutes: {
      description: 'Order Lead Time',
      validators: [(0, _modelValidators.numberIntValidator)(0, 480, {
        allowBlank: false
      })]
    },
    email: {
      type: 'email',
      description: 'Notification Email',
      disabled: Ember.computed.not('model.shouldSendEmail'),
      validators: [(0, _emberCpValidations.validator)('format', {
        type: 'email',
        allowBlank: true
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        dependentKeys: ['shouldSendEmail']
      })]
    },
    smsPhoneNumber: (0, _emberCpValidations.validator)('phone-number', {
      description: 'SMS Phone Number',
      disabled: Ember.computed.not('model.shouldSendSMS'),
      dependentKeys: ['shouldSendSMS']
    }),
    printerGuid: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        description: 'Notification Slips Printer',
        presence: true,
        disabled: Ember.computed.not('model.shouldPrintNotificationSlip'),
        dependentKeys: ['shouldPrintNotificationSlip']
      })]
    }
  });
  var OPTION_ATTRIBUTES = {
    isPrintedDistinctly: _emberData.default.attr('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    isCustomNameRequired: _emberData.default.attr('boolean', {
      defaultValue: false,
      isCopyable: true
    })
  };
  var RULE_ATTRIBUTES = {
    isCustomerRequired: _emberData.default.attr('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    requiresAddress: _emberData.default.attr('boolean', {
      isCopyable: true
    })
  };

  var _default = _emberData.default.Model.extend(Validations, OPTION_ATTRIBUTES, RULE_ATTRIBUTES, _modelCloneable.default, {
    allowCustomTicketNames: _emberData.default.attr('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    autoGenerateNewName: _emberData.default.attr('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    defaultSectionGuid: _emberData.default.attr('string', {
      isCopyable: true
    }),
    hasExpectedPrepTime: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isCustomerSelectionAllowed: _emberData.default.attr('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    isDeliveryInfoPrinted: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    isLocked: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    locationId: _emberData.default.attr('number', {
      isCopyable: true
    }),
    name: _emberData.default.attr('string'),
    ordinal: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    prepTimeMinutes: _emberData.default.attr('number', {
      defaultValue: 0,
      isCopyable: true
    }),
    promptRenameOrCombineTickets: _emberData.default.attr('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    revenueCenterGuid: _emberData.default.attr('string'),
    isDeferred: _emberData.default.attr('boolean', {
      isCopyable: true
    }),
    // In days
    minDeferredTime: _emberData.default.attr('number', {
      defaultValue: 0,
      isCopyable: true
    }),
    // In days
    maxDeferredTime: _emberData.default.attr('number', {
      defaultValue: 30,
      isCopyable: true
    }),
    onlineName: _emberData.default.attr('string'),
    onlineDescription: _emberData.default.attr('string'),
    onlineActive: _emberData.default.attr('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    minOrderAmount: _emberData.default.attr('number', {
      defaultValue: 0,
      isCopyable: true
    }),
    maxOrderAmount: _emberData.default.attr('number', {
      isCopyable: true
    }),
    deliveryDistance: _emberData.default.attr('number', {
      defaultValue: 0,
      isCopyable: true
    }),
    shouldPrintNotificationSlip: _emberData.default.attr('boolean', {
      isCopyable: true
    }),
    printNotificationSlipsTo: _emberData.default.attr('string'),
    printerGuid: _emberData.default.attr('string', {
      isCopyable: true
    }),
    shouldSendEmail: _emberData.default.attr('boolean', {
      isCopyable: true
    }),
    email: _emberData.default.attr('string', {
      isCopyable: true
    }),
    shouldSendSMS: _emberData.default.attr('boolean', {
      isCopyable: true
    }),
    smsPhoneNumber: _emberData.default.attr('phone', {
      isCopyable: true,
      defaultValue: null
    }),
    color: _emberData.default.attr('string', {
      defaultValue: DEFAULT_COLOR,
      isCopyable: true
    }),
    tax: _emberData.default.hasMany('echo-pro/tax'),
    customOptions: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(OPTION_ATTRIBUTES)).concat([(0, _booleanPropertyArray.default)(Object.keys(OPTION_ATTRIBUTES))])),
    orderTypeRules: (_Ember2 = Ember).computed.apply(_Ember2, _toConsumableArray(Object.keys(RULE_ATTRIBUTES)).concat([(0, _booleanPropertyArray.default)(Object.keys(RULE_ATTRIBUTES))])),
    customTicketNameOption: Ember.computed('autoGenerateNewName', 'promptRenameOrCombineTickets', {
      get: function get() {
        if (this.get('autoGenerateNewName')) {
          return 'autoGenerateNewName';
        }

        if (this.get('promptRenameOrCombineTickets')) {
          return 'promptRenameOrCombineTickets';
        }

        return 'none';
      },
      set: function set(key, value) {
        if (typeof value === 'string') {
          if (value === 'autoGenerateNewName') {
            this.set('autoGenerateNewName', true);
            this.set('promptRenameOrCombineTickets', false);
          } else if (value === 'promptRenameOrCombineTickets') {
            this.set('autoGenerateNewName', false);
            this.set('promptRenameOrCombineTickets', true);
          } else {
            this.set('autoGenerateNewName', false);
            this.set('promptRenameOrCombineTickets', false);
          }
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});