define("lh4/utils/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Resolves object's path and returns value
   *
   * @param {string} path - object path to get value from
   * @param {Object} object - object to look for a path
   * @returns {string|object} - value for a given path
   */
  var resolveObjectPath = function resolveObjectPath(path, object) {
    return path.split('.').reduce(function (prev, current) {
      return prev ? prev[current] : null;
    }, object);
  };

  var _default = resolveObjectPath;
  _exports.default = _default;
});