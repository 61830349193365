define("lh4/templates/components/internet-payments/payment-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ktQw9dnT",
    "block": "{\"symbols\":[\"@name\"],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"apm-services \",[22,\"paymentClass\"]]]],[8],[0,\"\\n\\t\"],[7,\"span\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/payment-method.hbs"
    }
  });

  _exports.default = _default;
});