define("lh4/components/table-layout/layout-top-menu", ["exports", "lh4/components/table-layout/shared/element-styles", "lh4/components/table-layout/shared/constants"], function (_exports, _elementStyles, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessagesHelper: Ember.inject.service(),
    newSection: null,
    currentSection: null,
    sections: [],
    nextTableNumber: 0,
    addSectionDisplay: false,
    updateDropdownText: Ember.observer('currentSection.name', function observe() {
      var dropdownTextElement = this.element.querySelector('.ui.dropdown .text');

      if (dropdownTextElement) {
        dropdownTextElement.innerText = this.currentSection.name;
      }
    }),
    actions: {
      selectSection: function selectSection(selectionId) {
        var selection = this.get('sections').findBy('id', selectionId);

        if (selection) {
          this.sendAction('selectSection', selection);
        }
      },
      addSectionDisplayToggle: function addSectionDisplayToggle() {
        var _this = this;

        this.toggleProperty('addSectionDisplay');
        Ember.run.schedule('afterRender', this, function () {
          return _this.$('#add-new-section-input').focus();
        });
      },
      addSection: function addSection() {
        var _this2 = this;

        if (Ember.isBlank(this.get('newSection'))) {
          this.flashMessagesHelper.pushMessage('Section cannot be empty', 'error');
          return;
        }

        if (this.get('newSection.length') > 100) {
          this.flashMessagesHelper.pushMessage('Section name cannot exceed 100 characterslong', 'error');
          return;
        }

        var isDuplicate = this.get('sections').toArray().some(function (section) {
          return section.get('name').toLowerCase() === _this2.get('newSection').toLowerCase();
        });

        if (isDuplicate) {
          this.flashMessagesHelper.pushMessage('Section already exists', 'error');
          return;
        }

        this.sendAction('createRecord', 'section', {
          name: this.get('newSection'),
          isActive: true
        });
        this.set('newSection', null);
        this.set('addSectionDisplay', false);
      },
      addCircleTable: function addCircleTable() {
        this.sendAction('createRecord', 'table', {
          sectionId: this.get('currentSection.id'),
          maxOccupants: 4,
          occupantCount: 0,
          name: this.get('nextTableNumber').toString(),
          posX: 150,
          posY: 100,
          width: _elementStyles.circleDefaults.radius * 2,
          height: _elementStyles.circleDefaults.radius * 2,
          rotation: 0,
          shape: _constants.TABLE_SHAPE_ROUND,
          isActive: true
        });
      },
      addSquareTable: function addSquareTable() {
        this.sendAction('createRecord', 'table', {
          sectionId: this.get('currentSection.id'),
          maxOccupants: 4,
          occupantCount: 0,
          name: this.get('nextTableNumber').toString(),
          posX: 150,
          posY: 100,
          width: _elementStyles.rectangleDefaults.width,
          height: _elementStyles.rectangleDefaults.height,
          rotation: 0,
          shape: _constants.TABLE_SHAPE_RECT,
          isActive: true
        });
      },
      addLabel: function addLabel() {
        var text = 'New Label';
        var label = new fabric.Text(text, _elementStyles.labelDefaults);
        this.sendAction('createRecord', 'table', {
          sectionId: this.get('currentSection.id'),
          maxOccupants: 0,
          occupantCount: 0,
          name: text,
          posX: 150,
          posY: 100,
          width: label.get('width') + 30,
          height: label.get('height') + 10,
          rotation: 0,
          shape: _constants.SHAPE_LABEL,
          isActive: true
        });
      },
      print: function print() {
        this.sendAction('print');
      }
    }
  });

  _exports.default = _default;
});