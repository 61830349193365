define("lh4/mirage/routes/virtual-terminal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PROFILES = [{
    allowPrepaid: false,
    allowNonPrepaid: false
  }, {
    allowPrepaid: true,
    allowNonPrepaid: false
  }, {
    allowPrepaid: false,
    allowNonPrepaid: true
  }, {
    allowPrepaid: true,
    allowNonPrepaid: true
  }];

  var _default = function _default(server) {
    server.get('/virtual-terminal/transactions', function (schema) {
      return {
        transactions: schema.virtualTerminalTransactions.all().models
      };
    });
    server.get('/virtual-terminal/origin', function () {
      return {
        origin: 'http://example.com'
      };
    });
    server.get('/virtual-terminal/transactions/refund/:transactionId', function (schema) {
      return {
        transaction: schema.virtualTerminalTransactions.all().models[0]
      };
    });
    server.get('/virtual-terminal/settings/unverified-refund', function (schema, request) {
      return {
        allowPrepaid: PROFILES[request.queryParams.locationId % PROFILES.length].allowPrepaid,
        allowNonPrepaid: PROFILES[request.queryParams.locationId % PROFILES.length].allowNonPrepaid
      };
    });
  };

  _exports.default = _default;
});