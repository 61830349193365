define("lh4/controllers/authenticated/epro/manage-employees/jobs/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    actions: {
      clone: function clone() {
        var id = this.changeset.get('id');
        this.transitionToRoute('authenticated.epro.manage-employees.jobs.create', {
          queryParams: {
            clone: id
          }
        });
      }
    }
  });

  _exports.default = _default;
});