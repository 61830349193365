define("lh4/controllers/authenticated/customer-engagement/campaigns/index", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FILTER_DEFAULTS = {
    offset: 0,
    limit: 25,
    sortAsc: true,
    sortBy: 'status',
    name: null,
    status: 'all'
  };

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    location: Ember.inject.service(),
    headers: [{
      field: 'name',
      name: 'Campaign name',
      width: 'four',
      sortable: true
    }, {
      field: 'status',
      name: 'Status',
      width: 'two',
      type: 'active',
      sortable: true
    }, {
      field: 'totalSent',
      name: 'Sent',
      width: 'two',
      type: 'number',
      sortable: true
    }, {
      field: 'totalOpens',
      name: 'Opens',
      width: 'three',
      type: 'number',
      sortable: true
    }, {
      field: 'totalClicks',
      name: 'Clicks',
      width: 'two',
      type: 'number',
      sortable: true
    }, {
      field: 'totalUnsubscribes',
      name: 'Unsubscribe',
      width: 'three',
      type: 'number',
      sortable: true
    }],
    queryParams: ['offset', 'limit', 'sortAsc', 'sortBy', 'name', 'status'],
    limit: FILTER_DEFAULTS.limit,
    offset: FILTER_DEFAULTS.offset,
    sortAsc: FILTER_DEFAULTS.sortAsc,
    sortBy: FILTER_DEFAULTS.sortBy,
    name: FILTER_DEFAULTS.name,
    status: FILTER_DEFAULTS.status,
    statusOptions: [{
      name: 'All Status Types',
      value: 'all'
    }, {
      name: 'Draft',
      value: 'Draft'
    }, {
      name: 'Active',
      value: 'Scheduled'
    }, {
      name: 'Complete',
      value: 'Complete'
    }, {
      name: 'Inactive',
      value: 'Inactive'
    }],
    routeData: [{
      name: 'authenticated.customer-engagement.campaigns',
      title: 'Campaigns'
    }, {
      name: 'authenticated.customer-engagement.settings',
      title: 'Settings'
    }],
    actions: {
      onTermUpdated: function onTermUpdated(name) {
        this.setProperties({
          name: name,
          offset: 0
        });
      },
      changeFilter: function changeFilter(_ref) {
        var sortAsc = _ref.sortAsc,
            sortBy = _ref.sortBy,
            limit = _ref.limit,
            offset = _ref.offset;
        this.setProperties({
          sortAsc: sortAsc,
          sortBy: sortBy,
          limit: limit,
          offset: offset
        });
      },
      filterStatus: function filterStatus(selected) {
        this.set('status', selected);
      },
      selectCampaign: function selectCampaign(value) {
        if (value.get('isEditable')) {
          this.transitionToRoute('authenticated.customer-engagement.campaigns.edit', value.id);
        } else {
          this.transitionToRoute('authenticated.customer-engagement.campaigns.overview.details', value.id);
        }
      }
    }
  });

  _exports.default = _default;
});