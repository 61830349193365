define("lh4/controllers/create-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      redirectToLogin: function redirectToLogin() {
        var _this = this;

        Ember.run.later(function () {
          return _this.transitionToRoute('sign-in', {
            queryParams: {
              showRecoverySuccess: true
            }
          });
        }, 1500);
      }
    }
  });

  _exports.default = _default;
});