define("lh4/controllers/authenticated/epro/timeclock/shifts/edit", ["exports", "moment", "lh4/controllers/authenticated/epro/timeclock/shifts/general"], function (_exports, _moment, _general) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _general.default.extend({
    isTray: true,
    shouldCloseTray: false,
    CLOCK_OUT_PROMPT: 'Are you sure you want to clock out this shift?',
    DELETE_PROMPT: 'Are you sure you want to clock out and delete this shift?',
    actions: {
      clockOut: function clockOut() {
        if (this.isSaving || this.changeset.get('clockedOutAt')) {
          return;
        }

        if (window.confirm(this.CLOCK_OUT_PROMPT)) {
          var now = (0, _moment.default)().toISOString();
          this.changeset.set('clockedOutAt', now);
          this.changeset.segmentChangesets.forEach(function (seg) {
            if (!seg.get('end')) {
              seg.set('end', now);
            }
          });
          this.send('save');
        }
      },
      delete: function _delete() {
        if (this.isSaving) {
          return;
        }

        if (window.confirm(this.DELETE_PROMPT)) {
          if (!this.changeset.get('clockedOutAt')) {
            this.changeset.set('clockedOutAt', (0, _moment.default)().toISOString());
          }

          this.changeset.set('voided', true);
          this.changeset.segmentChangesets.clear();
          this.send('save');
        }
      }
    }
  });

  _exports.default = _default;
});