define("lh4/controllers/authenticated/website-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    websiteBuilderIframeData: Ember.computed('model.websiteBuilderIframe', function websiteBuilderIframeData() {
      var locationId = this.model.locationId;
      var _this$model$websiteBu = this.model.websiteBuilderIframe.data,
          merchant = _this$model$websiteBu.merchant,
          accessToken = _this$model$websiteBu.accessToken;
      return {
        merchant: _objectSpread(_objectSpread({}, merchant), {}, {
          locationId: locationId
        }),
        accessToken: accessToken
      };
    }),
    websiteBuilderIframeError: Ember.computed('model.websiteBuilderIframe', function websiteBuilderIframeError() {
      return this.model.websiteBuilderIframe.error;
    })
  });

  _exports.default = _default;
});