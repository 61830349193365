define("lh4/routes/general-edit-route", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    redirect: function redirect(model, transition) {
      if (parseInt(model[this.modelName].locationId, 10) !== parseInt(this.location.model.id, 10)) {
        this.replaceWith(transition.from.parent.name);
      }
    },
    actions: {
      error: function error(_error, transition) {
        // If a 404 error is detected from the server it's possible that an
        // id for an existing location's asset is still in the url. If this 404
        // has a from object, we'll attempt to replace the transition with the
        // parent similar to the redirect function above.
        if (_error.errors && _error.errors[0].code === 404 && transition.from) {
          this.replaceWith(transition.from.parent.name);
        } else {
          return true;
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});