define("lh4/utils/support-phone-numbers", ["exports", "lh4/constants/controllers/support/index", "lh4/enums/country-code"], function (_exports, _index, _countryCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSupportPhoneNumber = getSupportPhoneNumber;
  _exports.default = void 0;
  var FALLBACK_LOCALE = _countryCode.default.US;

  function getSupportPhoneNumber(countryCode) {
    return _index.SUPPORT_PHONE_NUMBERS_OBJECT[countryCode] || _index.SUPPORT_PHONE_NUMBERS_OBJECT[FALLBACK_LOCALE];
  }

  var _default = getSupportPhoneNumber;
  _exports.default = _default;
});