define("lh4/templates/components/file-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bCj35eFT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[10,\"style\",\"display: none;\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/file-drop.hbs"
    }
  });

  _exports.default = _default;
});