define("lh4/enums/date-time-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_TIME_FORMAT = Object.freeze({
    ISO_8601_MS: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    LOCALIZED_DATE_ONLY: 'L',
    LOCALIZED_SHORT: 'L LT'
  });
  var _default = DATE_TIME_FORMAT;
  _exports.default = _default;
});