define("lh4/controllers/authenticated/locations/location/hospitality/customize-item-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    itemGroups: Ember.computed('model.location', 'model.itemGroups', function getter() {
      var location = this.get('model.location');
      var itemGroups = this.get('model.itemGroups').filterBy('locationId', parseInt(location.id, 10));
      return itemGroups;
    }),
    actions: {
      editItemGroup: function editItemGroup(rowGuid) {
        this.transitionToRoute('authenticated.locations.location.hospitality.customize-item-groups.edit-group', rowGuid);
      },
      createItemGroup: function createItemGroup() {
        this.transitionToRoute('authenticated.locations.location.hospitality.customize-item-groups.create-group');
      }
    }
  });

  _exports.default = _default;
});