define("lh4/controllers/authenticated/locations/location/hospitality/customize-item-groups/create-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    logger: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    isLoading: false,
    setLoading: Ember.computed('isLoading', function getter() {
      return this.get('isLoading') ? 'loading' : '';
    }),
    actions: {
      save: function save() {
        var _this = this;

        var newGroup = this.get('model.newGroup');
        this.set('isLoading', true);
        newGroup.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            newGroup.save().then(function (itemGroup) {
              _this.set('isLoading', false);

              _this.transitionToRoute('authenticated.locations.location.hospitality.customize-item-groups.edit-group', itemGroup.get('rowguid'));

              _this.logger.log('Item Group created');
            }).catch(function (err) {
              _this.set('isLoading', false);

              _this.logger.log("Error: ".concat(err));
            });
          } else {
            return Ember.RSVP.Promise.reject(validations);
          }

          return false;
        }).catch(function (validations) {
          _this.set('isLoading', false);

          validations.forEach(function (v) {
            if (v.get('message')) {
              _this.flashMessages.danger("".concat(v.get('message'), "."), {
                class: 'ui negative message'
              });
            }
          });
        });
      },
      clear: function clear() {
        this.transitionToRoute('authenticated.locations.location.hospitality.customize-item-groups');
      }
    }
  });

  _exports.default = _default;
});