define("lh4/serializers/hospitality/preference", ["exports", "lh4/serializers/hospitality/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    normalize: function normalize(typeClass, hashToNormalize) {
      var hash = hashToNormalize;
      Object.keys(hash).forEach(function (attr) {
        hash[attr.camelize()] = hash[attr];
        delete hash[attr];
      });

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      var normalized = this._super.apply(this, [typeClass, hash].concat(args));

      normalized.data.id = this.location.model.id;
      return normalized;
    }
  });

  _exports.default = _default;
});