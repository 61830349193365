define("lh4/enums/processing-report-service", ["exports", "lh4/enums/processing-report-type-slug", "lh4/enums/products"], function (_exports, _processingReportTypeSlug, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PROCESSING_SERVICE_REPORTS = ["".concat(_products.default.PROCESSING, "-").concat(_processingReportTypeSlug.default.SETTLEMENT), "".concat(_products.default.EW, "-").concat(_processingReportTypeSlug.default.TICKET_TRANSACTION_STATUS)];
  var _default = PROCESSING_SERVICE_REPORTS;
  _exports.default = _default;
});