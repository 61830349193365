define("lh4/components/loyalty/adjust-points", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ADJUST_POINT_LIMIT = 10000;

  var _default = Ember.Component.extend({
    tagName: '',
    adjustPointsChangeset: null,
    currentBalance: 0,
    newBalance: null,
    minAdjustPointLimit: -1 * ADJUST_POINT_LIMIT,
    maxAdjustPointLimit: ADJUST_POINT_LIMIT,
    showErrors: false,
    actions: {
      pointChangeChanged: function pointChangeChanged(record, value) {
        this.set('adjustPointsChangeset.pointChange', value);
        this.set('newBalance', this.currentBalance + Number(value));
        this.onSave();
      },
      savePointsChange: function savePointsChange(record, value) {
        this.set('adjustPointsChangeset.reason', value);
        this.onSave();
      }
    }
  });

  _exports.default = _default;
});