define("lh4/controllers/authenticated/social-media/accounts/index", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    errorHandler: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    sortOptions: ['createdAt:desc'],
    currentPage: 1,
    _filteredAccounts: Ember.computed('model.accounts.[]', 'searchText', function getter() {
      var filterType = ['name', 'externalName'];
      var searchText = (this.get('searchText') || '').toLowerCase();
      return this.get('model.accounts').filter(function (account) {
        return filterType.some(function (thisKey) {
          return account.get(thisKey) && account.get(thisKey).toString().toLowerCase().includes(searchText);
        });
      });
    }),
    accountsSorted: Ember.computed.sort('_filteredAccounts', 'sortOptions'),
    pageSize: 10,
    accountToDelete: null,
    searchText: '',
    actions: {
      showDeleteDialog: function showDeleteDialog(account) {
        this.set('accountToDelete', account);
        (0, _jquery.default)('#delete-social-media-account-dialog').modal('show');
      },
      deleteAccount: function deleteAccount() {
        var _this = this;

        if (this.get('loading')) {
          return;
        }

        this.set('loading', true);
        var account = this.get('accountToDelete');
        var id = account.get('id');
        account.destroyRecord().then(function () {
          _this.flashMessagesHelper.pushMessage('Successfully deleted account', 'success');

          _this.transitionToRoute('authenticated.social-media.accounts');
        }).catch(function (e) {
          _this.errorHandler.handleResponseError(e, 'Could not delete account');

          account.unloadRecord();

          _this.store.findRecord('social-media/account', id, {
            reload: true
          });
        }).finally(function () {
          _this.set('loading', false);
        });
      },
      onSearch: function onSearch(searchText) {
        this.set('searchText', searchText);
        this.set('currentPage', 1);
      }
    }
  });

  _exports.default = _default;
});