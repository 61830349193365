define("lh4/routes/authenticated/add-on-devices/wizard", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'manage tableside for add-on-devices',
    editRecord: Ember.inject.service('edit-record'),
    location: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var createTerminal = function createTerminal(terminal) {
        return _this.editRecord.validateAndSave(terminal);
      };

      createTerminal.bind(this);
      var location = this.location.model;
      return {
        newTerminal: this.store.createRecord('echo-pro/terminal', {
          locationId: location.id,
          hardwareId: null,
          terminalName: null,
          isMobile: true
        }),
        createTerminal: createTerminal,
        printers: this.store.query('echo-pro/printer', {
          location: location.id
        })
      };
    }
  });

  _exports.default = _default;
});