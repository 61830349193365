define("lh4/routes/authenticated/hardware/checkout", ["exports", "lh4/mixins/changeset-transition-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "ember-changeset", "ember-changeset-cp-validations", "lh4/enums/shipping-methods"], function (_exports, _changesetTransitionRouteMixin, _lighthousePermissionsMixin, _emberChangeset, _emberChangesetCpValidations, _shippingMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, _lighthousePermissionsMixin.default, {
    titleToken: 'Checkout',
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    cart: Ember.inject.service(),
    hardware: Ember.inject.service(),
    model: function model() {
      var location = this.location.model;
      var items = this.cart.items;
      return Ember.RSVP.hash({
        checkout: this.store.createRecord('hardware/checkout', {
          personalLocationId: location.id,
          deliveryLocationId: location.id,
          shippingMethod: _shippingMethods.default.GROUND
        }),
        locations: this.modelFor('authenticated').locations,
        items: this.hardware.items,
        discounts: items.length ? this.store.query('hardware/items/discount', {
          locationId: location.id,
          items: items
        }) : []
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model.checkout),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(model.checkout, validateFn, validationMap, {
        skipValidate: true
      }); // Pre-fill 'Name & Surname' and 'Email' fields from user's
      // token data only if the cart is not empty

      if (this.cart.items.length) {
        changeset.setProperties({
          personalFullName: "".concat(this.tokenData.data.firstName, " ").concat(this.tokenData.data.lastName),
          personalEmail: this.tokenData.data.email
        });
      }

      controller.setProperties({
        changeset: changeset,
        buildChildChangeset: function buildChildChangeset(childModel) {
          return _this.buildChildChangeset(childModel);
        },
        isOrdered: false,
        isLoading: false
      });
    },
    // eslint-disable-next-line class-methods-use-this
    buildChildChangeset: function buildChildChangeset(model) {
      var _buildChangeset2 = (0, _emberChangesetCpValidations.buildChangeset)(model),
          validateFn = _buildChangeset2.validateFn,
          validationMap = _buildChangeset2.validationMap;

      return new _emberChangeset.default(model, validateFn, validationMap, {
        skipValidate: true
      });
    }
  });

  _exports.default = _default;
});