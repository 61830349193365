define("lh4/routes/authenticated/epro/accounting/revenue-classes/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'revenueClass',
    model: function model() {
      var locationId = this.location.model.id;
      return Ember.RSVP.hash({
        revenueClass: this.store.createRecord('echo-pro/accounting/tax-set', {
          locationId: locationId
        }),
        taxes: this.modelFor('authenticated.epro.accounting.revenue-classes').taxes
      });
    }
  });

  _exports.default = _default;
});