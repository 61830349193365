define("lh4/adapters/preferences", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType) {
      if (requestType === 'findRecord' || requestType === 'updateRecord') {
        return "".concat(this.get('host'), "/api/v1/accounts/").concat(id, "/preferences");
      }

      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      return this._super.apply(this, [modelName, id, snapshot, requestType].concat(args));
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      // super hacky
      var id = requestData.url.match(/accounts\/(\d*)\/preferences/)[1];

      for (var _len2 = arguments.length, args = new Array(_len2 > 4 ? _len2 - 4 : 0), _key2 = 4; _key2 < _len2; _key2++) {
        args[_key2 - 4] = arguments[_key2];
      }

      var response = this._super.apply(this, [status, headers, payload, requestData].concat(args));

      response.preferences.id = id;
      return response;
    }
  });

  _exports.default = _default;
});