define("lh4/templates/components/ui/icons/s4/undo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y603onlS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"currentColor\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M14.1 19H7V17H14.1C15.15 17 16.0623 16.6667 16.837 16C17.6123 15.3333 18 14.5 18 13.5C18 12.5 17.6123 11.6667 16.837 11C16.0623 10.3333 15.15 10 14.1 10H7.8L10.4 12.6L9 14L4 9L9 4L10.4 5.4L7.8 8H14.1C15.7167 8 17.1043 8.525 18.263 9.575C19.421 10.625 20 11.9333 20 13.5C20 15.0667 19.421 16.375 18.263 17.425C17.1043 18.475 15.7167 19 14.1 19Z\"],[10,\"fill\",\"currentColor\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/s4/undo.hbs"
    }
  });

  _exports.default = _default;
});