define("lh4/components/tables/pos-orders-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pos-orders-table'],
    location: Ember.inject.service(),
    store: Ember.inject.service(),
    can: Ember.inject.service(),
    showAll: false,
    posOrders: Ember.computed('location.model.merchantId', function getter() {
      return this.can.can('view pos order') ? this.store.query('order', {
        mid: [this.get('location.model.merchantId')]
      }) : null;
    }),
    orderRoll: Ember.computed('showAll', 'posOrders.[]', function getter() {
      if (this.get('posOrders')) {
        return this.get('showAll') ? this.get('posOrders') : this.get('posOrders').slice(0, 5);
      }

      return false;
    }),
    actions: {
      toggleOrders: function toggleOrders() {
        this.toggleProperty('showAll');
      }
    }
  });

  _exports.default = _default;
});