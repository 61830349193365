define("lh4/components/transactions/transactions-controls", ["exports", "lh4/enums/transactions-search-filters", "lh4/components/internet-payments/transactions-controls"], function (_exports, _transactionsSearchFilters, _transactionsControls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var generatePreviewList = function generatePreviewList(optionSet, baseSet) {
    var maxSingles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    var supportAll = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    var workingOptionSet = optionSet; // If the option set includes both '' and 'null', we want to remove the 'null' option

    if (optionSet !== null && optionSet !== void 0 && optionSet.includes('') && optionSet !== null && optionSet !== void 0 && optionSet.includes('null')) {
      workingOptionSet = optionSet.filter(function (o) {
        return o !== 'null';
      });
    }

    if (!workingOptionSet || workingOptionSet.length === 0) {
      return [];
    }

    if (workingOptionSet.length <= maxSingles) {
      return baseSet.filter(function (b) {
        return workingOptionSet.includes(b.id);
      }).map(function (o) {
        return o.name;
      });
    }

    if (supportAll === true && baseSet.length === workingOptionSet.length) {
      return ['All'];
    }

    return ["".concat(workingOptionSet.length, " Selected")];
  };

  var _default = _transactionsControls.default.extend({
    searchCriteria: _transactionsSearchFilters.FNB_TRANSACTIONS_SEARCH_FILTERS,

    get cardTypesOptionsPreviews() {
      return generatePreviewList(this.cardTypesQueryParams, this.cardTypesOptions, 0).join(', ');
    },

    get dayPartsOptionsPreviews() {
      return generatePreviewList(this.dayPartsQueryParams, this.dayPartsOptions, 0).join(', ');
    },

    // We only know how many have been selected and not necessarily how many are possible
    // so we're going to short circuit generatePreviewList to just return the number of batch
    // numbers selected by matching it against itself and turning off the "All" option
    // This should always give us "X Selected" unless it's 0.
    get merchantBatchNumbersOptionsPreviews() {
      return generatePreviewList(this.merchantBatchNumberParams, this.merchantBatchNumberParams, 0, false).join(', ');
    },

    get revenueCentersOptionsPreviews() {
      return generatePreviewList(this.revenueCentersQueryParams, this.revenueCentersOptions, 0).join(', ');
    },

    get serverNameOptionsPreviews() {
      return generatePreviewList(this.serverNameQueryParams, this.serverNameOptions, 0).join(', ');
    }

  });

  _exports.default = _default;
});