define("lh4/components/state-selector", ["exports", "lh4/utils/states"], function (_exports, _states) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    states: _states.STATES.slice()
  });

  _exports.default = _default;
});