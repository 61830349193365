define("lh4/components/ui/header-menu/header-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'item',
    classNameBindings: ['defaultStyle:tablet', 'defaultStyle:center-aligned', 'defaultStyle:header', 'defaultStyle:item', 'defaultStyle:left-header'],
    defaultStyle: false
  });

  _exports.default = _default;
});