define("lh4/controllers/authenticated/reputation/accounts/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['location'],
    google: Ember.inject.service('reputation/google-my-business'),
    facebook: Ember.inject.service('social-media/facebook'),
    accountApi: Ember.inject.service('reputation/account-api'),
    appController: Ember.inject.controller('application'),
    location: null,
    actions: {
      selectAccountType: function selectAccountType(accountType) {
        if (this.get('isLoading')) {
          return;
        }

        var _this$get = this.get('appController'),
            currentRouteName = _this$get.currentRouteName;

        this.accountApi.redirectToCreateAccount(accountType, currentRouteName, this.get('location'));
      },
      backToAccounts: function backToAccounts() {
        this.set('isLoading', true);
        this.transitionToRoute('authenticated.reputation.accounts');
      }
    }
  });

  _exports.default = _default;
});