define("lh4/templates/components/customer-engagement/survey-stat-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I+0bXK38",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui horizontal segments\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui center aligned segment\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui mini statistic\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"value\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"average\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[0,\"\\n\\t\\t\\t\\tAverage\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui center aligned segment\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui mini statistic\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"value opened\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"responses\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"label\"],[8],[0,\"\\n\\t\\t\\t\\tResponses\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/customer-engagement/survey-stat-bar.hbs"
    }
  });

  _exports.default = _default;
});