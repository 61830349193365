define("lh4/serializers/echo-pro/item-price-schedule-map", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'guid',
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Object.assign(hash, this.serialize(record, options));
    }
  });

  _exports.default = _default;
});