define("lh4/routes/authenticated/social-media/post-scheduler/account/schedule", ["exports", "moment", "lh4/mixins/authenticated-route-mixin"], function (_exports, _moment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var scheduleTime = (0, _moment.default)().add(1, 'hour').format();
      var post = Ember.Object.create({
        text: '',
        scheduleTime: scheduleTime,
        originalScheduleTime: scheduleTime,
        isActive: true
      });
      return Ember.RSVP.hash({
        account: this.modelFor('authenticated.social-media.post-scheduler.account').account,
        post: post
      });
    }
  });

  _exports.default = _default;
});