define("lh4/templates/components/dashboard-widgets/guests/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZCl2ZyXy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"data\",\"guestsPerHour\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row chart-row\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"amcharts3/column-chart\",null,[[\"data\",\"options\"],[[24,[\"chartData\"]],[24,[\"chartOptions\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"no-data row\"],[8],[7,\"div\",true],[10,\"class\",\"message\"],[8],[0,\"No data to display.\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[7,\"div\",true],[10,\"class\",\"row collapsing\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"\\n\\t\\tSales Per Guest\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[28,\"currency-format\",[[24,[\"data\",\"salesPerGuest\"]]],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/guests/data.hbs"
    }
  });

  _exports.default = _default;
});