define("lh4/components/ui/field-selector-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-selector-group'],
    disabled: false,
    actions: {
      handleChange: function handleChange(value) {
        if (this.onChange) {
          this.onChange(this.groupName, value);
        }
      }
    }
  });

  _exports.default = _default;
});