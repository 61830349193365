define("lh4/controllers/authenticated/epro/menu/price-groups", ["exports", "lh4/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    headers: [{
      field: 'name',
      name: 'Name',
      width: 'eight',
      sortable: true
    }]
  });

  _exports.default = _default;
});