define("lh4/enums/account-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ACCOUNT_STATUS_CODES = _exports.ACCOUNT_STATUS = void 0;
  var ACCOUNT_STATUS = Object.freeze({
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    BLOCKED: 'BLOCKED',
    UNKNOWN: 'UNKNOWN'
  });
  _exports.ACCOUNT_STATUS = ACCOUNT_STATUS;
  var ACCOUNT_STATUS_CODES = Object.freeze({
    PRE_APPLICATION_RECEIVED: 100,
    RESUBMITTED_APPLICATION: 125,
    DECLINED: 200,
    CLOSED_MERCHANT_CANCELLED: 300,
    CLOSED_TERMINATION: 325,
    SEASONAL_BUSINESS: 330,
    GATEWAY_OR_ACCESSORY_SERVICE_ONLY: 350,
    AWAITING_ISO_ACTIVATION_OF_UNDERWRITING: 375,
    ORIG_APP_RECEIVED_IN_UNDERWRITING: 390,
    IN_UNDERWRITING: 400,
    ON_PRIMARY_HOLD_NEED_ADDITIONAL_INFORMATION: 425,
    APPROVED_IN_DATA_ENTRY_FOR_MID_BUILD: 500,
    APPROVED_AWAITING_FAXED_DOCUMENTATION: 550,
    APPROVED: 600,
    APPROVED_INSTALLED: 650,
    MERCHANT_REQUESTING_TO_CANCEL: 675,
    APPROVED_PROCESSING: 700,
    APPROVED_SEASONAL_HOLD: 730,
    LEGAL_REVIEW: 785
  });
  _exports.ACCOUNT_STATUS_CODES = ACCOUNT_STATUS_CODES;
  var _default = ACCOUNT_STATUS;
  _exports.default = _default;
});