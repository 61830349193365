define("lh4/templates/components/custom-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hU79qpI9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[8],[0,\"\\n\\t\"],[7,\"input\",false],[12,\"name\",[22,\"name\"]],[12,\"checked\",[22,\"checked\"]],[12,\"data-id\",[22,\"data-id\"]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[12,\"type\",[22,\"type\"]],[3,\"action\",[[23,0,[]],\"update\"],[[\"preventDefault\"],[false]]],[8],[9],[0,\"\\n\\t\"],[1,[22,\"label\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/custom-radio.hbs"
    }
  });

  _exports.default = _default;
});