define("lh4/mirage/factories/virtual-terminal-transaction", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    invoiceId: function invoiceId() {
      return _faker.default.datatype.uuid();
    },
    businessDate: '2020-02-21',
    date: function date() {
      return '2020-02-21T15:03:00.000Z';
    },
    total: '10',
    tipAmount: '1.5',
    cardNumber: '1111-2222-3333-4444',
    authNumber: function authNumber() {
      return _faker.default.datatype.uuid();
    }
  });

  _exports.default = _default;
});