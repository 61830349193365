define("lh4/components/reputation/review-view-modal", ["exports", "lh4/mixins/reputation/reputation-account-types"], function (_exports, _reputationAccountTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend((0, _reputationAccountTypes.default)('account'), {
    accountApi: Ember.inject.service('reputation/account-api'),
    errorHandler: Ember.inject.service(),

    /* @param */
    account: null,

    /* @param */
    selectedReview: null,
    selectedReply: null,
    canRespond: Ember.computed('account', 'selectedReview', function getter() {
      if (!this.get('account') || !this.get('selectedReview')) {
        return false;
      }

      return this.accountApi.isFbPage(this.get('account').type) || this.accountApi.isGoogleBusiness(this.get('account').type);
    }),
    isCommentsLoading: false,
    actions: {
      closeModal: function closeModal() {
        this.set('responseText', '');
        this.sendAction('onClose');
      },
      loadMoreComments: function loadMoreComments() {
        var _this = this;

        this.set('isCommentsLoading', true);
        var accountId = this.get('account').id;
        var review = this.get('selectedReview');
        var reviewId = review.id;
        var originalComments = review.replies.comments;
        var after = this.get('selectedReview').replies.meta.pagination.after;

        if (!after) {
          this.set('isCommentsLoading', false);
          return;
        }

        this.accountApi.getReviewComments(accountId, reviewId, {
          after: after,
          limit: 10
        }).then(function (_ref) {
          var comments = _ref.comments,
              meta = _ref.meta;
          var newComments = comments.filter(function (comm) {
            return !originalComments.find(function (origComm) {
              return comm.id === origComm.id;
            });
          });
          Ember.set(review, 'replies.comments', [].concat(_toConsumableArray(originalComments), _toConsumableArray(newComments)));
          Ember.set(review, 'replies.meta.pagination.after', meta.pagination.after);
        }).catch(function (err) {
          _this.errorHandler.handleResponseError(err);
        }).finally(function () {
          return _this.set('isCommentsLoading', false);
        });
      },
      ownerReplyUpdateSuccess: function ownerReplyUpdateSuccess(_ref2) {
        var responseText = _ref2.responseText,
            updatedAt = _ref2.updatedAt;
        var review = this.get('selectedReview');

        if (!review || !review.ownerReply) {
          return this.send('closeModal');
        } // update selectedReview.ownerReply values


        Ember.set(review.ownerReply, 'comment', responseText);
        Ember.set(review.ownerReply, 'updatedAt', updatedAt);
        Ember.set(review.ownerReply, 'createdAt', updatedAt);
        this.send('closeModal');
        return false;
      },
      replySuccess: function replySuccess(_ref3) {
        var responseText = _ref3.responseText,
            updatedAt = _ref3.updatedAt;
        var review = this.get('selectedReview');

        if (!review || !review.replies || !review.replies.comments) {
          return this.send('closeModal');
        }

        var userName = this.get('account') && this.get('account').externalName ? this.get('account').externalName : 'Your response';
        review.replies.comments.push({
          text: responseText,
          createdAt: updatedAt,
          updatedAt: updatedAt,
          user: {
            name: userName
          }
        });
        return this.send('closeModal');
      },
      selectComment: function selectComment(comment) {
        if (this.get('selectedReply') === comment.id) {
          this.set('selectedReply', null);
        } else {
          this.set('selectedReply', comment.id);
        }
      },
      onDiscard: function onDiscard() {
        this.set('selectedReply', null);
        this.set('responseText', '');
      }
    }
  });

  _exports.default = _default;
});