define("lh4/controllers/authenticated/locations/location/hospitality/customize-menu/category/edit-category/item/edit-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    logger: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    isLoading: false,
    weightDisabled: Ember.computed('model.editItem.isSoldByWeight', function getter() {
      return this.model.editItem.isSoldByWeight ? '' : 'disabled';
    }),
    setLoading: Ember.computed('isLoading', function getter() {
      return this.isLoading ? 'loading' : '';
    }),
    tabs: ['Item Details', 'Categories', 'Choice Sets', 'Printers', 'Options'],
    actions: {
      save: function save() {
        var _this = this;

        this.flashMessages.clearMessages();
        this.set('isLoading', true);
        this.model.editItem.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (!validations.isValid) {
            return Ember.RSVP.reject(validations);
          }

          _this.model.editItem.save().then(function () {
            _this.flashMessages.success('Item edited successfully!', {
              class: 'ui success message'
            });

            _this.logger.log('Item edited successfully!');

            _this.set('isLoading', false);
          }, function (err) {
            _this.logger.log("".concat(err, " error occurred when saving item"));

            _this.set('isLoading', false);

            _this.flashMessages.danger('Error connecting to terminal. Please make sure your unit is online.', {
              class: 'ui error message'
            });
          });

          return false;
        }).catch(function (validations) {
          _this.set('isLoading', false);

          validations.forEach(function (_ref2) {
            var message = _ref2.message;

            if (message) {
              _this.flashMessages.danger("".concat(message, "."), {
                class: 'ui negative message'
              });
            }
          });
        });
      },
      clear: function clear() {
        this.model.editItem.rollbackAttributes();
        this.transitionToRoute('authenticated.locations.location.hospitality.customize-menu.category.edit-category.item.edit-item');
      }
    }
  });

  _exports.default = _default;
});