define("lh4/components/ui/dropdown-static/dropdown-static-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    value: null,
    text: '',
    didInsertElement: function didInsertElement() {
      this.sendAction('onOptionInsert', this);
    },
    willDestroyElement: function willDestroyElement() {
      this.sendAction('onOptionDestroy', this);
    }
  });

  _exports.default = _default;
});