define("lh4/models/echo-pro/tax", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/has-many-ids", "lh4/utils/is-positive-decimal-or-zero", "lh4/utils/is-positive-decimal", "lh4/utils/is-positive-percentage-or-zero", "lh4/models/echo-pro/tax-rule"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _modelCloneable, _hasManyIds, _isPositiveDecimalOrZero, _isPositiveDecimal, _isPositivePercentageOrZero, _taxRule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Tax name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100,
        description: 'Tax name length'
      })]
    },
    value: {
      description: 'Value',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (parseFloat(value) < 0) {
          return 'Value cannot be less than zero';
        }

        if (parseFloat(value) > 0 && model.get('isFlatAmount') && !(0, _isPositiveDecimal.default)(value)) {
          return 'Value can only contain two decimal numbers';
        }

        if (parseFloat(value) > 0 && !(0, _isPositiveDecimal.default)(value, 3)) {
          return 'Value can only contain three decimal numbers';
        }

        return model.get('isFlatAmount') ? (0, _isPositiveDecimalOrZero.default)(value) : (0, _isPositivePercentageOrZero.default)(value, 3);
      }, {
        dependentKeys: ['isFlatAmount']
      })]
    },
    taxRules: {
      description: 'Tax Rules',
      validators: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('array-unique-field', {
        uniqueField: 'ruleType',
        uniqueFieldFilter: function uniqueFieldFilter(_ref) {
          var type = _ref.type;
          return _taxRule.ruleTypes.some(function (_ref2) {
            var name = _ref2.name;
            return type === name;
          });
        },
        dependentKeys: ['taxRules.@each.ruleType']
      })]
    }
  });

  var _default = _model.default.extend(Validations, _modelCloneable.default, {
    corporation: Ember.inject.service(),
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    value: (0, _attr.default)('string'),
    defaultTax: (0, _attr.default)('boolean'),
    isFlatAmount: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isNotAppliedIfAllLiquor: (0, _attr.default)('boolean'),
    isHiddenIfAllLiquor: (0, _attr.default)('boolean'),
    hidden: (0, _attr.default)('boolean'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    items: (0, _relationships.hasMany)('echo-pro/item'),
    itemIds: Ember.computed('items', (0, _hasManyIds.default)('items', 'echo-pro/item')),
    itemTaxMap: (0, _relationships.hasMany)('echo-pro/item-tax-map'),
    taxSets: (0, _relationships.hasMany)('echo-pro/accounting/tax-set'),
    taxSetIds: Ember.computed('taxSets', (0, _hasManyIds.default)('taxSets', 'echo-pro/accounting/tax-set')),
    taxSetTaxMap: (0, _relationships.hasMany)('echo-pro/tax-set-tax-map'),
    orderTypes: (0, _relationships.hasMany)('echo-pro/order-type'),
    orderTypeIds: Ember.computed('orderTypes', (0, _hasManyIds.default)('orderTypes', 'echo-pro/order-type')),
    printSeparate: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    taxRules: (0, _relationships.hasMany)('echo-pro/tax-rule'),
    addTaxRule: function addTaxRule(taxRule) {
      return this.store.createRecord('echo-pro/tax-rule', _objectSpread({
        locationId: this.get('locationId'),
        ruleType: _taxRule.ruleTypes.get('lastObject').name,
        operator: _taxRule.operators.get('lastObject').name
      }, taxRule));
    },
    addItemTaxMap: function addItemTaxMap(item) {
      return this.store.createRecord('echo-pro/item-tax-map', _objectSpread({}, item));
    },
    addTaxSetTaxMap: function addTaxSetTaxMap(taxSet) {
      return this.store.createRecord('echo-pro/tax-set-tax-map', _objectSpread({}, taxSet));
    }
  });

  _exports.default = _default;
});