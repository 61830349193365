define("lh4/models/echo-pro/pay-period", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _emberData.default.Model.extend(Validations, {
    guid: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    start: _emberData.default.attr(),
    dayOfWeek: _emberData.default.attr('number'),
    weekOfYear: _emberData.default.attr('number'),
    year: _emberData.default.attr('number'),
    periodType: _emberData.default.attr('string'),
    dailyOtThreshold1: _emberData.default.attr('number'),
    weeklyOtThreshold1: _emberData.default.attr('number'),
    dailyOtThreshold2: _emberData.default.attr('number'),
    weeklyOtThreshold2: _emberData.default.attr('number'),
    payMultiplier1: _emberData.default.attr('number'),
    payMultiplier2: _emberData.default.attr('number'),
    minimumWage: _emberData.default.attr('number')
  });

  _exports.default = _default;
});