define("lh4/mirage/fixtures/fiscal-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FISCAL_REPORT = {
    fiscalReports: [{
      guid: '6850c049-d2c4-408f-9c7a-bc23bbcb0422',
      locationId: 1,
      terminalId: 1,
      fileName: 'EKJ 20230609122711',
      typeName: 'txt',
      bucketName: 'electronic-journal',
      fileDate: '2023-06-09T09:27:11.000Z'
    }, {
      guid: '50cc7eff-6b2e-4f65-a9cf-011250d11020',
      locationId: 1,
      terminalId: 2,
      fileName: 'EKJ 20230609122711',
      typeName: 'txt',
      bucketName: 'electronic-journal',
      fileDate: '2023-06-09T09:27:11.000Z'
    }]
  };
  var _default = FISCAL_REPORT;
  _exports.default = _default;
});