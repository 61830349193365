define("lh4/routes/authenticated/customer-engagement/campaigns/overview/survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      to: {
        refreshModel: true
      },
      from: {
        refreshModel: true
      },
      rating: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      sortAsc: {
        refreshModel: true
      }
    },
    campaignApi: Ember.inject.service('customer-engagement/campaign-api'),
    errorHandler: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var overviewCampaignId = this.paramsFor('authenticated.customer-engagement.campaigns.overview');
      return Ember.RSVP.hash({
        survey: this.campaignApi.loadSurvey(overviewCampaignId.campaignId, params).catch(function (err) {
          _this.errorHandler.handleResponseError(err, 'Could not load customer engagement survey');

          return {};
        }),
        campaignId: overviewCampaignId,
        locationId: this.modelFor('authenticated.customer-engagement.campaigns.overview').campaign.get('locationId')
      });
    }
  });

  _exports.default = _default;
});