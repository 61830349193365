define("lh4/components/ui/s4/loaders/s4-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="s4-loader-container">
  	<div class="loader-text">
  		<Ui::Icons::S4::FourIcon />
  	</div>
  	<div class="main-container">
  		<Ui::Icons::S4::Spinner />
  	</div>
  </div>
  
  */
  {
    id: "FVPbTHOp",
    block: "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"s4-loader-container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"loader-text\"],[8],[0,\"\\n\\t\\t\"],[5,\"ui/icons/s4/four-icon\",[],[[],[]]],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"main-container\"],[8],[0,\"\\n\\t\\t\"],[5,\"ui/icons/s4/spinner\",[],[[],[]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/loaders/s4-loader.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});