define("lh4/components/launch-control/order-tracker/csm-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="csm-info-container">
      <div class="csm-info">
          <div class="csm-info-content">
              {{#if @csmInfo.csm}}
                  <div class="csm-info-row" data-test-id="csm-info-name">
                      <div class="csm-info-label">Name:</div>
                      <div class="csm-info-value"> {{@csmInfo.csm.FullName}}</div>
                  </div>
                  <div class="csm-info-row" data-test-id="csm-info-phone">
                      <div class="csm-info-label">Phone Number:</div>
                      <div class="csm-info-value">{{@csmInfo.csm.Phone}}</div>
                  </div>
                  <div class="csm-info-row" data-test-id="csm-info-email">
                      <div class="csm-info-label">Email:</div>
                      <div class="csm-info-value"> {{@csmInfo.csm.Email}}</div>
                  </div>
  
              {{else}}
                  <div data-test-id="csm-info-unavailable" class="csm-info-subtitle">Manager Unavailable</div>
                  <div>Your Client Success Manager is still being assigned. Please check back soon.</div>
              {{/if}}
          </div>
      </div>
  </div>
  
  */
  {
    id: "925cXt0+",
    block: "{\"symbols\":[\"@csmInfo\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"csm-info-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"csm-info\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"csm-info-content\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"csm\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"csm-info-row\"],[10,\"data-test-id\",\"csm-info-name\"],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"csm-info-label\"],[8],[0,\"Name:\"],[9],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"csm-info-value\"],[8],[0,\" \"],[1,[23,1,[\"csm\",\"FullName\"]],false],[9],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"csm-info-row\"],[10,\"data-test-id\",\"csm-info-phone\"],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"csm-info-label\"],[8],[0,\"Phone Number:\"],[9],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"csm-info-value\"],[8],[1,[23,1,[\"csm\",\"Phone\"]],false],[9],[0,\"\\n                \"],[9],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"csm-info-row\"],[10,\"data-test-id\",\"csm-info-email\"],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"csm-info-label\"],[8],[0,\"Email:\"],[9],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"csm-info-value\"],[8],[0,\" \"],[1,[23,1,[\"csm\",\"Email\"]],false],[9],[0,\"\\n                \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"data-test-id\",\"csm-info-unavailable\"],[10,\"class\",\"csm-info-subtitle\"],[8],[0,\"Manager Unavailable\"],[9],[0,\"\\n                \"],[7,\"div\",true],[8],[0,\"Your Client Success Manager is still being assigned. Please check back soon.\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/launch-control/order-tracker/csm-info.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});