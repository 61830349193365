define("lh4/templates/authenticated/support/contact/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sbOeKXGZ",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"ui/modal/fullscreen-modal\",null,[[\"title\",\"onClose\"],[[24,[\"title\"]],[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `modal.content` to be a contextual component but found a string. Did you mean `(component modal.content)`? ('lh4/templates/authenticated/support/contact/details.hbs' @ L6:C4) \"],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"support/contact/support-ticket-details\",null,[[\"model\",\"changeset\",\"postComment\"],[[24,[\"model\"]],[24,[\"changeset\"]],[28,\"action\",[[23,0,[]],\"postComment\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/support/contact/details.hbs"
    }
  });

  _exports.default = _default;
});