define("lh4/templates/components/ui/dropdown/reservation-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YKpdWo2b",
    "block": "{\"symbols\":[\"status\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui label reservation-buttons\"],[8],[0,\"\\n\\tStatus:\\n\"],[4,\"ui-dropdown\",null,[[\"selected\",\"onChange\"],[[24,[\"model\",\"statusId\"]],[28,\"action\",[[23,0,[]],\"changeStatus\"],null]]],{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"text\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"statuses\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"item \",[28,\"if\",[[28,\"and\",[[28,\"eq\",[[23,2,[]],3],null],[28,\"eq\",[[24,[\"type\"]],\"create\"],null]],null],\"disabled\"],null]]]],[11,\"data-value\",[29,[[23,2,[]]]]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/dropdown/reservation-status.hbs"
    }
  });

  _exports.default = _default;
});