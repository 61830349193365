define("lh4/utils/widget-is-usable", ["exports", "lh4/utils/has-required-products"], function (_exports, _hasRequiredProducts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = widgetIsUsable;

  /**
   * Check if a widget is usable on any location
   *
   * @param {Object} widget widget definition
   * @param {Array} locations array of locations to check
   * @returns {Boolean} whether or not widget is usable
   */
  function widgetIsUsable(widget, locations) {
    var productTypes = locations.map(function (location) {
      return location.product;
    }).uniq(); // is there any location that isn't on the denied products list

    var anyProductAllowed = productTypes.some(function (product) {
      return !widget.deniedProducts.includes(product);
    }); // is there any location that has the required products

    var anyLocationHasRequired = locations.some(function (location) {
      return (0, _hasRequiredProducts.default)(widget.requiredProducts, location);
    });
    return anyProductAllowed && anyLocationHasRequired;
  }
});