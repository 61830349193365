define("lh4/mirage/factories/epro-setting", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    locationId: 4,
    type: 'business',
    name: 'start_of_week',
    value: 'Sunday'
  });

  _exports.default = _default;
});