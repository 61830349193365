define("lh4/components/website-builder", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    websiteBuilderIframeError: null,
    websiteBuilderIframeData: {},
    websiteBuilderInstalled: false,
    shift4ShopEnv: _environment.default.s4shopEnvironment,
    actions: {
      initializeIframe: function initializeIframe() {
        if (!this.websiteBuilderIframeData) {
          return;
        }

        var _this$websiteBuilderI = this.websiteBuilderIframeData,
            _this$websiteBuilderI2 = _this$websiteBuilderI.merchant,
            merchant = _this$websiteBuilderI2 === void 0 ? undefined : _this$websiteBuilderI2,
            _this$websiteBuilderI3 = _this$websiteBuilderI.accessToken,
            accessToken = _this$websiteBuilderI3 === void 0 ? '' : _this$websiteBuilderI3;

        if (window.TSModule && merchant && accessToken) {
          window.TSModule.Initialize(merchant, accessToken, this.shift4ShopEnv, 'skytabwebsite');
        }
      }
    }
  });

  _exports.default = _default;
});