define("lh4/components/epro/payments/discount-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    media: Ember.inject.service(),
    corporation: Ember.inject.service(),
    locale: Ember.inject.service(),
    showErrors: false,
    weekdayOptions: [{
      id: 'isAllowedMonday',
      name: 'Monday'
    }, {
      id: 'isAllowedTuesday',
      name: 'Tuesday'
    }, {
      id: 'isAllowedWednesday',
      name: 'Wednesday'
    }, {
      id: 'isAllowedThursday',
      name: 'Thursday'
    }, {
      id: 'isAllowedFriday',
      name: 'Friday'
    }, {
      id: 'isAllowedSaturday',
      name: 'Saturday'
    }, {
      id: 'isAllowedSunday',
      name: 'Sunday'
    }],
    isAppliedToOptions: [{
      id: 'isAppliedToTickets',
      name: 'Tickets'
    }, {
      id: 'isAppliedToItems',
      name: 'Items'
    }],
    reasonTypes: [{
      value: true,
      name: 'Number'
    }, {
      value: false,
      name: 'Text'
    }],
    isAppliedToTicketsDisabled: Ember.computed('discount.isAutomatic', 'discount.mode', function getter() {
      return this.discount.get('isAutomatic') || this.discount.get('mode') === 2;
    }),
    containsIsAppliedToTickets: Ember.computed('discount.isAppliedTo.[]', function getter() {
      return this.discount.get('isAppliedTo').includes('isAppliedToTickets');
    }),
    isAppliedToTypes: Ember.computed('isAppliedToTicketsDisabled', function getter() {
      var _this = this;

      return this.isAppliedToOptions.map(function (option) {
        if (option.id === 'isAppliedToTickets') {
          return _objectSpread(_objectSpread({}, option), {}, {
            disabled: _this.isAppliedToTicketsDisabled
          });
        }

        return option;
      });
    }),
    discountTypes: Ember.computed('containsIsAppliedToTickets', function getter() {
      return [{
        value: 0,
        name: "Amount Off (".concat(this.locale.currencySymbol, ")")
      }, {
        value: 1,
        name: 'Percentage (%)'
      }, {
        value: 2,
        name: "Force Price (".concat(this.locale.currencySymbol, ")"),
        disabled: this.containsIsAppliedToTickets
      }];
    }),
    orderTypeOptions: Ember.computed('orderTypes', function getter() {
      return [{
        name: 'No Order Type',
        value: null
      }].concat(_toConsumableArray(this.orderTypes.map(function (_ref) {
        var value = _ref.id,
            name = _ref.name,
            isActive = _ref.isActive;
        return {
          name: name,
          value: value,
          isActive: isActive
        };
      })));
    }),
    isAllDaysSelected: Ember.computed('discount.weekdays.[]', function getter() {
      return this.discount.get('weekdays').length === 7;
    }),
    isCouponWarningMessageVisible: Ember.computed('discount.isAutomatic', 'discount.isCouponCodeEnabled', function getter() {
      return this.discount.get('isAutomatic') && this.discount.get('isCouponCodeEnabled');
    }),
    actions: {
      capitalizeCouponCode: function capitalizeCouponCode(code) {
        this.discount.set('couponCode', code.toUpperCase().trim());
      },
      onModeChange: function onModeChange(value) {
        this.discount.set('mode', value);
      },
      toggleAllDays: function toggleAllDays(value) {
        var options = this.weekdayOptions;
        var weekdays = value ? options.map(function (_ref2) {
          var id = _ref2.id;
          return id;
        }) : [];
        this.discount.set('weekdays', weekdays);
      }
    }
  });

  _exports.default = _default;
});