define("lh4/components/epro/menu/modifier-set-bulk-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    corporation: Ember.inject.service(),
    classNames: ['modifier-set-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    errorMessage: null,
    actions: {
      handleFieldSelectorGroupChange: function handleFieldSelectorGroupChange(field, included) {
        this.onFieldSelectorGroupChange(field, included);
      },
      handleOptionsChange: function handleOptionsChange(selected) {
        this.changeset.set('options', selected);
      }
    }
  });

  _exports.default = _default;
});