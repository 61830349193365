define("lh4/components/reservations/reservation-details", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['reservation-details'],
    partySizeOptions: Ember.computed('settings.@each.value', function getter() {
      if (!this.get('settings') || this.get('settings').length === 0) {
        return [];
      }

      var maxPartySize = this.get('settings').find(function (setting) {
        return setting.get('id') === 'max-party-size';
      });

      if (!maxPartySize) {
        return [];
      }

      return Array.from({
        length: maxPartySize.get('value')
      }, function (v, i) {
        return i + 1;
      });
    }),
    phoneNumberTypes: ['Cell', 'Home', 'Office'],
    tableReadyNotifications: ['Text', 'Call', 'None'],
    isLoading: false,
    isSubmitted: false,
    reservationDate: Ember.computed('model.partyTime', function getter() {
      return (0, _moment.default)(this.get('model.partyTime')).format('MM/DD/YYYY');
    }),
    reservationTime: Ember.computed('model.partyTime', {
      get: function get() {
        return (0, _moment.default)(this.get('model.partyTime')).format('hh:mm A');
      },
      set: function set(key, value) {
        var date = (0, _moment.default)(this.get('model.partyTime')).format('YYYY-MM-DD');
        this.set('model.partyTime', (0, _moment.default)("".concat(date, " ").concat(value), 'YYYY-MM-DD hh:mm A').toISOString());
        return (0, _moment.default)(this.get('model.partyTime')).format('hh:mm A');
      }
    }),
    actions: {
      notifyCustomer: function notifyCustomer(location, res) {
        if (this.get('model.notificationType') === null) {
          return;
        }

        this.sendAction('notifyCustomer', location, res);
      },
      save: function save() {
        this.set('isSubmitted', true);

        if (this.get('isLoading') === true) {
          return;
        }

        this.set('model.partyTime', (0, _moment.default)("".concat(this.get('reservationDate'), " ").concat(this.get('reservationTime')), 'MM/DD/YYYY hh:mm A'));
        this.set('model.locationId', this.get('settings.query.location'));

        if (this.get('model.notificationType') === 'none') {
          this.set('model.notificationType', null);
        }

        this.sendAction('save');
      },
      clear: function clear() {
        if (this.get('model.isDirty')) {
          this.sendAction('clear');
        }
      },
      partyDateSelect: function partyDateSelect(date) {
        var formattedDate = (0, _moment.default)(date).local().format('YYYY-MM-DD');
        var formattedTime = (0, _moment.default)(this.model.partyTime).format('hh:mm A');
        var partyDate = (0, _moment.default)("".concat(formattedDate, " ").concat(formattedTime), 'YYYY-MM-DD hh:mm A');
        this.set('model.partyTime', partyDate);
      },
      cancelReservation: function cancelReservation(id, loc) {
        this.sendAction('cancelReservation', id, loc);
      }
    }
  });

  _exports.default = _default;
});