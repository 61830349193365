define("lh4/components/customer-database/customer-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    limit: 10,
    offset: 0,
    sortBy: 'firstName,lastName',
    can: Ember.inject.service(),
    sortAsc: true,
    search: null,
    terminalHeaders: [{
      name: 'Name',
      field: 'firstName,lastName',
      width: 'four',
      sortable: true
    }, {
      name: 'Email',
      field: 'primaryEmail.email',
      width: 'four',
      sortable: false
    }, {
      name: 'Phone',
      field: 'primaryPhone.number',
      width: 'four',
      sortable: false
    }, {
      name: 'Address',
      field: 'primaryAddress.full',
      width: 'four',
      sortable: false
    }],
    hbrHeaders: [{
      name: 'Name',
      field: 'firstName,lastName',
      width: 'two',
      sortable: true
    }, {
      aligned: 'right',
      name: 'Avg. Spent',
      field: 'avgSpend',
      width: 'two',
      sortable: false,
      type: 'number'
    }, {
      aligned: 'right',
      name: 'Total Spent',
      field: 'totalSpend',
      width: 'two',
      sortable: false,
      type: 'number'
    }, {
      name: 'Last Visit',
      field: 'lastVisit',
      width: 'three',
      sortable: false,
      type: 'date'
    }, {
      name: 'Email',
      field: 'primaryEmail.email',
      width: 'three',
      sortable: false
    }, {
      name: 'Phone',
      field: 'primaryPhone.number',
      width: 'two',
      sortable: false
    }, {
      name: 'Address',
      field: 'primaryAddress.full',
      width: 'two',
      sortable: false
    }],
    headers: Ember.computed('location', function getter() {
      return this.can.can('manage processing/customer') ? this.terminalHeaders : this.hbrHeaders;
    }),
    actions: {
      handleRowClick: function handleRowClick(customer) {
        this.selectCustomer(customer);
      },
      filterChanged: function filterChanged(filter) {
        this.filterChanged(filter);
      }
    }
  });

  _exports.default = _default;
});