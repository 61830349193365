define("lh4/templates/components/reservations/reservation-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VS2DkI/p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segments\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui header segment\"],[8],[0,\"\\n\\t\\tOnline Reservation Link\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment center aligned\"],[8],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"This is the URL your customers will access in order to place an online reservation:\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui compact info message\"],[8],[0,\"\\n\"],[4,\"link-to-outbound\",[[24,[\"link\"]]],[[\"eventLabel\"],[\"Online Reservation Link Preview\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[22,\"link\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reservations/reservation-link.hbs"
    }
  });

  _exports.default = _default;
});