define("lh4/routes/authenticated/social-media/post-scheduler/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return Ember.RSVP.hash({
        accounts: this.modelFor('authenticated.social-media').accounts.filter(function (account) {
          return account.get('externalId');
        })
      });
    },
    redirect: function redirect(model) {
      if (!model.accounts || model.accounts.get('length') === 0) {
        return false;
      }

      var parentModel = this.modelFor('authenticated.social-media');
      var selectedAccountId = parentModel.state.selectedAccountId;

      if (selectedAccountId) {
        return this.transitionTo('authenticated.social-media.post-scheduler.account', selectedAccountId);
      }

      var firstAccount = model.accounts.sort(function (acc1, acc2) {
        return acc1.get('createdAt') >= acc2.get('createdAt') ? -1 : 1;
      }).objectAt(0);
      return this.transitionTo('authenticated.social-media.post-scheduler.account', firstAccount.get('id'));
    }
  });

  _exports.default = _default;
});