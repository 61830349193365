define("lh4/components/echo-pro/tableside/device-setup-wizard/pricing-step", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var branding = _environment.default.branding,
      BrandNames = _environment.default.BrandNames;

  var _default = Ember.Component.extend({
    tagName: '',
    branding: branding,
    BrandNames: BrandNames,
    actions: {
      proceed: function proceed() {
        this.sendAction('onProceed');
      }
    }
  });

  _exports.default = _default;
});