define("lh4/routes/authenticated/supply-orders/location/order", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'place supply order',
    supplyCart: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.supplyCart.locationId === null || this.supplyCart.equipmentId === null || this.supplyCart.orderDetails.length === 0) {
        this.transitionTo('authenticated.supply-orders.location');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        service: this.store.findAll('supply-orders/service')
      });
    }
  });

  _exports.default = _default;
});