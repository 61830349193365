define("lh4/templates/authenticated/locations/location/echo-pro/reservations/reservation/edit/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HrdB1Ozn",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"modal/reservation-cancel\",null,[[\"visible\",\"model\",\"modalSuccess\",\"modalDeny\"],[true,[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"modalSuccess\"],null],[28,\"action\",[[23,0,[]],\"modalDeny\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/reservations/reservation/edit/cancel.hbs"
    }
  });

  _exports.default = _default;
});