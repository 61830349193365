define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/reservation/edit", ["exports", "lh4/mixins/edit-controller-mixin", "jquery"], function (_exports, _editControllerMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {
    modelName: 'model.reservation',
    isLoading: false,
    actions: {
      notifyCustomer: function notifyCustomer(location, res) {
        this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.reservation.edit.notify', location, res);
      },
      cancelReservation: function cancelReservation(location, res) {
        this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.reservation.edit.cancel', location, res);
      },
      discardChanges: function discardChanges() {
        this.model.reservation.rollbackAttributes(); // have to empty out this transition before retrying to not trigger bug workaround
        // ref: https://github.com/emberjs/ember.js/issues/12473

        var transition = this.transition;
        this.set('transition', undefined);
        transition.retry();
        (0, _jquery.default)('#reservation-changed').modal('hide dimmer');
      }
    }
  });

  _exports.default = _default;
});