define("lh4/mirage/factories/supply", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    billedAt: null,
    cancelledAt: null,
    createdAt: '2016-12-22T20:26:01.890Z',
    discount: '0.00',
    fulfilledAt: null,
    fulfilledBy: null,
    isBilled: false,
    isCancelled: false,
    isFulfilled: false,
    isGraphite: false,
    mid: '0000000000',
    shippingAddress1: function shippingAddress1() {
      return _faker.default.address.streetAddress();
    },
    shippingAddress2: function shippingAddress2() {
      return _faker.default.address.streetAddress();
    },
    shippingAttention: '',
    shippingCity: function shippingCity() {
      return _faker.default.address.city();
    },
    shippingPrice: 14.24,
    shippingState: function shippingState() {
      return _faker.default.address.state();
    },
    shippingTo: function shippingTo() {
      return _faker.default.company.companyName();
    },
    shippingZip: function shippingZip() {
      return _faker.default.address.zipCode();
    },
    shippingZipExtended: '9334',
    updatedAt: '2016-12-22T20:26:01.890Z'
  });

  _exports.default = _default;
});