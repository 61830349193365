define("lh4/models/accounts/permission", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    parentCategory: (0, _attr.default)('string'),
    category: (0, _attr.default)('string'),
    isOnByDefault: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});