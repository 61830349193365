define("lh4/components/virtual-terminal/refund-settings/steps/internal-verify/index", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#verify-owner/owner-form
  	changeset=changeset
  	showErrors=showErrors
  }}
  	<h2 data-test-header class="ui header">{{stepHeader}}</h2>
  	<div data-test-security-notice class="ui warning message notice">
  		<i class="ui info circle icon"></i>
  		<div>
  			For security reasons, the action selected requires verification that you are the owner of the location:
  			<h4 data-test-location>{{locationName}} - {{merchantId}}</h4>
  		</div>
  	</div>
  {{/verify-owner/owner-form}}
  
  */
  {
    id: "PBrId2OP",
    block: "{\"symbols\":[],\"statements\":[[4,\"verify-owner/owner-form\",null,[[\"changeset\",\"showErrors\"],[[24,[\"changeset\"]],[24,[\"showErrors\"]]]],{\"statements\":[[0,\"\\t\"],[7,\"h2\",true],[10,\"data-test-header\",\"\"],[10,\"class\",\"ui header\"],[8],[1,[22,\"stepHeader\"],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"data-test-security-notice\",\"\"],[10,\"class\",\"ui warning message notice\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui info circle icon\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\\tFor security reasons, the action selected requires verification that you are the owner of the location:\\n\\t\\t\\t\"],[7,\"h4\",true],[10,\"data-test-location\",\"\"],[8],[1,[22,\"locationName\"],false],[0,\" - \"],[1,[22,\"merchantId\"],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/virtual-terminal/refund-settings/steps/internal-verify/index.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'virtual-terminal-refund-settings-steps-internal-verify',
    classNames: ['internal-verify-step']
  }));

  _exports.default = _default;
});