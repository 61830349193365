define("lh4/routes/authenticated/customer-engagement/campaigns/overview/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    campaignApi: Ember.inject.service('customer-engagement/campaign-api'),
    errorHandler: Ember.inject.service(),
    queryParams: {
      offset: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      to: {
        refreshModel: true
      },
      from: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      sortAsc: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      var _this$paramsFor = this.paramsFor('authenticated.customer-engagement.campaigns.overview'),
          campaignId = _this$paramsFor.campaignId;

      return this.campaignApi.loadActivity(campaignId, params).catch(function (err) {
        _this.errorHandler.handleResponseError(err, 'Could not load customer engagement activity');

        return {};
      }).then(function (activity) {
        return Ember.RSVP.hash({
          activity: activity,
          campaignId: campaignId,
          locationId: _this.modelFor('authenticated.customer-engagement.campaigns.overview').campaign.get('locationId')
        });
      });
    }
  });

  _exports.default = _default;
});