define("lh4/adapters/settings/user", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _application.default.extend({
    tokenData: Ember.inject.service(),
    pathForType: function pathForType() {
      var _this$tokenData$data = this.tokenData.data,
          userId = _this$tokenData$data.sub,
          parentUserId = _this$tokenData$data.parentUserId;
      return "accounts/".concat(parentUserId || userId, "/sub-account");
    },
    urlForFindAll: function urlForFindAll() {
      this.namespace = 'api/v2'; // This is a hack but we have to do it because the endpoints are strange.

      return "".concat(this._super.apply(this, arguments), "s");
    },
    urlForQuery: function urlForQuery() {
      this.namespace = 'api/v2';
      return "".concat(this._super.apply(this, arguments), "s");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var _snapshot$adapterOpti;

      var _ref = (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 ? _snapshot$adapterOpti : {},
          normalizedPermissionSets = _ref.normalizedPermissionSets;

      this.namespace = normalizedPermissionSets ? 'api/v2' : 'api/v1';
      return "".concat(this._super(id, modelName, snapshot));
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var _snapshot$adapterOpti2;

      var _ref2 = (_snapshot$adapterOpti2 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti2 !== void 0 ? _snapshot$adapterOpti2 : {},
          normalizedPermissionSets = _ref2.normalizedPermissionSets;

      this.namespace = normalizedPermissionSets ? 'api/v2' : 'api/v1';
      return "".concat(this._super(modelName, snapshot));
    },
    urlForFindRecord: function urlForFindRecord() {
      this.namespace = 'api/v2';
      return "".concat(this._super.apply(this, arguments));
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      var response = this._super.apply(this, [status, headers, payload, requestData].concat(args));

      if (!response.subAccounts) {
        return response;
      }

      var subAccounts = response.subAccounts,
          count = response.count;

      if (subAccounts[0] && !subAccounts[0].permissionSets) {
        subAccounts.forEach(function (account) {
          var locationPermissionsMap = (account.userPermissionMaps || []).reduce(function (acc, _ref3) {
            var locationId = _ref3.locationId,
                userPermissionId = _ref3.userPermissionId;

            if (!acc[locationId]) {
              acc[locationId] = [];
            }

            acc[locationId].push(userPermissionId);
            return acc;
          }, {}); // eslint-disable-next-line no-param-reassign

          account.permissionSets = Object.entries(locationPermissionsMap).map(function (_ref4) {
            var _ref5 = _slicedToArray(_ref4, 2),
                locationId = _ref5[0],
                permissionIds = _ref5[1];

            return {
              locationId: parseInt(locationId, 10),
              permissions: permissionIds
            };
          });
        });
      }

      return {
        subAccounts: subAccounts,
        meta: {
          count: subAccounts.length ? count : 0
        }
      };
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _snapshot$adapterOpti3;

      var _ref6 = (_snapshot$adapterOpti3 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti3 !== void 0 ? _snapshot$adapterOpti3 : {},
          normalizedPermissionSets = _ref6.normalizedPermissionSets;

      var data = this.serialize(snapshot, {
        includeId: true
      });
      var urlForUpdateRecord = this.urlForUpdateRecord(snapshot.id, type.modelName, snapshot);

      if (normalizedPermissionSets) {
        data.permissionSets = normalizedPermissionSets;
      }

      return this.ajax(urlForUpdateRecord, 'PUT', {
        data: data
      });
    },
    createRecord: function createRecord(store, type, snapshot) {
      var _snapshot$adapterOpti4;

      var _ref7 = (_snapshot$adapterOpti4 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti4 !== void 0 ? _snapshot$adapterOpti4 : {},
          normalizedPermissionSets = _ref7.normalizedPermissionSets;

      var data = this.serialize(snapshot, {
        includeId: true
      });
      var urlForCreateRecord = this.urlForCreateRecord(type.modelName, snapshot);

      if (normalizedPermissionSets) {
        data.permissionSets = normalizedPermissionSets;
      }

      return this.ajax(urlForCreateRecord, 'POST', {
        data: data
      });
    }
  });

  _exports.default = _default;
});