define("lh4/routes/sso", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Sign In',
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _transition$to$queryP,
          _this = this;

      var promise;

      if (this.get('session.isAuthenticated')) {
        this.set('session.skipRedirectOnInvalidation', true);
        promise = this.session.invalidate();
      } else {
        promise = Ember.RSVP.Promise.resolve();
      }

      var token = (_transition$to$queryP = transition.to.queryParams) === null || _transition$to$queryP === void 0 ? void 0 : _transition$to$queryP.token;
      promise.then(function () {
        return _this.session.authenticate('authenticator:sso', {
          token: token
        });
      }).then(function () {
        // this does pretty much what sessionInvalidated on the application mixin does
        // but since we override that to make sure the page doesn't reload
        // we have to do it here to make sure there's no data leftover from previous sessions
        window.location.replace(_environment.default.rootURL);
      }).catch(function () {
        _this.transitionTo('sign-in');
      });
    }
  });

  _exports.default = _default;
});