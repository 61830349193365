define("lh4/models/echo-pro/ingredient-item-map", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    quantity: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        integer: true,
        gte: 1,
        lte: 100
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    ingredientGuid: _emberData.default.attr('string'),
    itemGuid: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number', {
      defaultValue: 1
    })
  });

  _exports.default = _default;
});