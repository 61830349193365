define("lh4/templates/components/statements-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8kSpYiLr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"statements\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"statements-picker\",null,[[\"onChange\",\"month\",\"enabledDates\",\"data-test-picker-selection\"],[[28,\"action\",[[23,0,[]],\"selectStatementMonth\"],null],[28,\"moment-format\",[[24,[\"statementMonth\"]],\"MMMM, YYYY\",\"YYYYMM\"],null],[24,[\"statements\"]],\"statements\"]]],false],[0,\"\\n\\t\"],[7,\"button\",false],[12,\"data-test-statements-download-view-button\",\"\"],[12,\"class\",[29,[\"statement-button ui right floated green compact mini \",[28,\"if\",[[24,[\"statementLoading\"]],\"loading\"],null],\" button\"]]],[12,\"onClick\",[28,\"action\",[[23,0,[]],\"handleNotification\"],null]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"downloadStatement\"]],[8],[0,\"\\n\\t\\tView Statement\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"span\",true],[10,\"class\",\"no-statements\"],[8],[0,\"No Statements Available\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/statements-download.hbs"
    }
  });

  _exports.default = _default;
});