define("lh4/enums/ip-dispute-evidence-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DISPUTE_DATE_FIELD = _exports.DISPUTE_TEXT_FIELD = _exports.DISPUTE_EVIDENCE_DOCUMENT_FIELD = void 0;
  var DISPUTE_EVIDENCE_DOCUMENT_FIELD = Object.freeze({
    signatureId: 'signatureId',
    receiptId: 'receiptId',
    communicationId: 'communicationId',
    serviceDocId: 'serviceDocId',
    additionalInfoId: 'additionalInfoId'
  });
  _exports.DISPUTE_EVIDENCE_DOCUMENT_FIELD = DISPUTE_EVIDENCE_DOCUMENT_FIELD;
  var DISPUTE_TEXT_FIELD = Object.freeze({
    productDescription: 'productDescription',
    customerName: 'customerName',
    customerEmail: 'customerEmail',
    customerPurchaseIp: 'customerPurchaseIp',
    additionalInfoText: 'additionalInfoText'
  });
  _exports.DISPUTE_TEXT_FIELD = DISPUTE_TEXT_FIELD;
  var DISPUTE_DATE_FIELD = Object.freeze({
    serviceDate: 'serviceDate'
  });
  _exports.DISPUTE_DATE_FIELD = DISPUTE_DATE_FIELD;
});