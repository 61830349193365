define("lh4/mirage/routes/hospitality", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageHospitalityServer;

  function setMirageHospitalityServer(server) {
    server.get('/hospitality/employees', function (_ref, request) {
      var hospitalityEmployees = _ref.hospitalityEmployees;
      return {
        employees: hospitalityEmployees.where({
          isActive: request.queryParams.isActive
        }).models
      };
    });
    server.get('/hospitality/employees/:employeeId', function (_ref2, request) {
      var hospitalityEmployees = _ref2.hospitalityEmployees;
      return {
        employees: hospitalityEmployees.where({
          rowguid: request.params.employeeId
        }).models
      };
    });
    server.get('/hospitality/privileges', function (_ref3) {
      var hospitalityPrivileges = _ref3.hospitalityPrivileges;
      return {
        privileges: hospitalityPrivileges.all().models
      };
    });
    server.get('/hospitality/jobs', function (schema) {
      return {
        jobs: schema.hospitalityJobs.all().models
      };
    }); // Hosp Customize Menu

    server.get('/hospitality/menu-categories', function (schema) {
      return {
        menuCategory: schema.hospitalityMenuCategories.all().models
      };
    });
    server.get('/hospitality/menu-categories/:menuCatId', function (schema, request) {
      return {
        menuCategory: schema.hospitalityMenuCategories.where({
          rowguid: request.params.menuCatId
        }).models
      };
    });
    server.get('/hospitality/menu-items/', function (schema) {
      return {
        menuItems: schema.hospitalityMenuItems.all().models
      };
    });
    server.get('/hospitality/menu-items/:menuGuid', function (schema, request) {
      return {
        menuItems: schema.hospitalityMenuItems.where({
          rowguid: request.params.menuGuid
        }).models
      };
    });
    server.get('/hospitality/choice-sets', function (schema) {
      return {
        choiceSets: schema.hospitalityChoiceSets.all()
      };
    });
    server.get('/hospitality/revenue-classes', function (schema) {
      return {
        revenuClasses: schema.hospitalityRevenueClasses.all()
      };
    });
    server.get('/hospitality/item-groups', function (schema) {
      return {
        itemGroups: schema.hospitalityItemGroups.all()
      };
    }); // Hospitality Preferences

    server.get('/hospitality/preferences', function (schema) {
      var preferences = JSON.parse(JSON.stringify(schema.hospitalityPreferences.first()));
      delete preferences.id;
      return {
        preferences: preferences
      };
    });
  }
});