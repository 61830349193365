define("lh4/templates/authenticated/locations/location/echo-pro/manage-pos/table-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PWXi7QeK",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segments table-wrap\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide computer column\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"flash-message\",null,[[\"flash\",\"class\"],[[23,1,[]],[23,1,[\"class\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"mobile-warning\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h1\",true],[8],[0,\"Table layout is not optimized for usage on touch screen.  Please open this page on a computer.\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[1,[28,\"table-layout\",null,[[\"class\",\"tables\",\"sections\",\"isLoading\",\"addedRecord\",\"addedSection\",\"updateTableProps\",\"createRecord\",\"elementSectionChanged\",\"saveChanges\",\"sectionLoading\"],[\"res-table\",[24,[\"tables\"]],[24,[\"sections\"]],[24,[\"isLoading\"]],[24,[\"addedRecord\"]],[24,[\"addedSection\"]],[28,\"action\",[[23,0,[]],\"updateTableProps\"],null],[28,\"action\",[[23,0,[]],\"createRecord\"],null],[28,\"action\",[[23,0,[]],\"elementSectionChanged\"],null],[28,\"action\",[[23,0,[]],\"saveChanges\"],null],[24,[\"sectionLoading\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"modal/action-confirmation\",null,[[\"onApprove\",\"question\",\"description\",\"approveText\",\"denyText\",\"id\"],[[28,\"action\",[[23,0,[]],\"discardChanges\"],null],\"Are you sure you want to navigate away?\",\"You will lose unsaved changes.\",\"Yes\",\"No\",\"discard-table-changes\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/manage-pos/table-layout.hbs"
    }
  });

  _exports.default = _default;
});