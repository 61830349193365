define("lh4/controllers/authenticated/add-on-devices/tableside", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    defaultPrinter: Ember.computed('model.printers.@each.isDefault', function getter() {
      return this.get('model.printers').find(function (printer) {
        return printer.get('isDefault');
      });
    }),
    dropdownId: 'tableside-dropdown',
    ajax: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service('flash-messages-helper'),
    responseMessages: Ember.inject.service('response-messages'),
    actions: {
      changePrinter: function changePrinter(printer) {
        var _this = this;

        (0, _jquery.default)("#".concat(this.get('dropdownId'))).addClass('loading disabled');
        this.ajax.post('/api/v1/echo-pro/printers/default/tableside', {
          data: JSON.stringify({
            locationId: printer.get('locationId'),
            guid: printer.get('id')
          })
        }).then(function () {
          return _this.get('model.printers').forEach(function (p) {
            return p.set('isDefault', p === printer);
          });
        }).catch(function (err) {
          _this.flashMessagesHelper.pushMessages(_this.responseMessages.getResponseErrors(err));
        }).finally(function () {
          (0, _jquery.default)("#".concat(_this.get('dropdownId'))).removeClass('loading disabled');
        });
      }
    }
  });

  _exports.default = _default;
});