define("lh4/templates/authenticated/epro/menu/departments/reorder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GjKA/4I0",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ui/reorder-modal/reorder-modal\",[],[[\"@entities\",\"@title\",\"@onClose\",\"@reorderUrl\"],[[23,0,[\"model\",\"entities\"]],\"Edit Department Order\",[23,0,[\"onClose\"]],\"/api/v1/echo-pro/departments/reorder\"]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/epro/menu/departments/reorder.hbs"
    }
  });

  _exports.default = _default;
});