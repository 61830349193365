define("lh4/templates/components/ui/records-side-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N3sKGIHO",
    "block": "{\"symbols\":[\"record\",\"&default\"],\"statements\":[[14,2,[[28,\"hash\",null,[[\"header\"],[[28,\"component\",[\"ui/records-side-menu/top-header-menu\"],null]]]]]],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui vertical attached segment records-side-menu-list\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"column records-side-menu-record\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"recordsComputed\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[14,2,[[28,\"hash\",null,[[\"record\"],[[28,\"component\",[\"ui/shared/record-yield-basic\"],[[\"record\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[14,2,[[28,\"hash\",null,[[\"noRecords\"],[[28,\"component\",[\"ui/shared/yield-basic\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/records-side-menu.hbs"
    }
  });

  _exports.default = _default;
});