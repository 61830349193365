define("lh4/controllers/authenticated/customer-engagement/campaigns/overview/overview", ["exports", "lh4/enums/date-range-title", "moment"], function (_exports, _dateRangeTitle, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    rangeTitle: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.THIS,
    verticalRangeWidth: 160,
    queryParams: ['to', 'from'],
    from: (0, _moment.default)().startOf('week').format('YYYY-MM-DD'),
    to: (0, _moment.default)().endOf('week').format('YYYY-MM-DD'),
    maxDate: (0, _moment.default)(),
    chartOptions: {
      categoryField: 'date',
      dataDateFormat: _moment.default.HTML5_FMT.DATE,
      parseDates: true,
      colors: ['#009BF4', '#F6A735'],
      graphs: [{
        balloonText: 'Opened: [[value]]',
        valueField: 'opened'
      }, {
        balloonText: 'Clicked: [[value]]',
        valueField: 'clicked'
      }],
      valueAxes: [{
        integersOnly: true
      }, {
        integersOnly: true
      }]
    },
    overviewAverage: Ember.computed('model.overview.campaign.graphData', function getter() {
      return this.get('model.overview.campaign.graphData').sortBy('time').map(function (_ref) {
        var time = _ref.time,
            opened = _ref.opened,
            clicked = _ref.clicked;
        return {
          date: (0, _moment.default)(time).format(_moment.default.HTML5_FMT.DATE),
          opened: opened,
          clicked: clicked
        };
      });
    }),
    actions: {
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        this.set('from', startDate.format('YYYY-MM-DD'));
        this.set('to', endDate.format('YYYY-MM-DD'));
      },
      onMobileChange: function onMobileChange(rangeTitle, startDate, endDate) {
        this.set('rangeTitle', rangeTitle);

        if (startDate) {
          this.set('from', startDate.format('YYYY-MM-DD'));
        }

        if (endDate) {
          this.set('to', endDate.format('YYYY-MM-DD'));
        }
      },
      onRangeSelect: function onRangeSelect(instance, range) {
        var startDate = range.startDate,
            endDate = range.endDate,
            title = range.title;
        instance.setStart(startDate.startOf('day'));
        instance.setEnd(endDate.endOf('day'));
        this.set('from', startDate.format('YYYY-MM-DD'));
        this.set('rangeTitle', title);
        this.set('to', endDate.format('YYYY-MM-DD'));
      }
    }
  });

  _exports.default = _default;
});