define("lh4/models/s4-gift-cards/card", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    cardId: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('string'),
    cardNumber: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    amount: (0, _attr.default)('number'),
    purchaserName: (0, _attr.default)('string'),
    purchaserEmail: (0, _attr.default)('string'),
    purchaseDate: (0, _attr.default)('date'),
    isRedeemed: Ember.computed('status', function getter() {
      return this.status === 'Redeemed';
    })
  });

  _exports.default = _default;
});