define("lh4/components/internet-payments/ui/search/list-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    previousTerm: null,
    searchTerm: null,
    proxy: null,
    termDebounce: null,
    autocomplete: null,
    maxSearch: null,
    debounceWait: 1500,
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.searchTerm !== this.previousTerm) {
        this.set('proxy', this.searchTerm);
      }

      this.set('previousTerm', this.searchTerm);
    },
    updateTerm: function updateTerm() {
      this.onUpdated(this.proxy);
      this.set('termDebounce', null);
    },
    actions: {
      searchTerm: function searchTerm(forceSearch) {
        if (forceSearch) {
          Ember.run.cancel(this.termDebounce);
          this.updateTerm();
        } else {
          this.set('termDebounce', Ember.run.debounce(this, this.updateTerm, this.debounceWait));
        }
      },
      clearTerm: function clearTerm() {
        this.set('proxy', null);
        this.updateTerm();

        if (this.onClose) {
          this.onClose();
        }
      }
    }
  });

  _exports.default = _default;
});