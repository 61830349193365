define("lh4/abilities/feature-flag", ["exports", "ember-can", "lh4/enums/launch-darkly-feature-flags", "lh4/mixins/abilities/ability-mixin"], function (_exports, _emberCan, _launchDarklyFeatureFlags, _abilityMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service(),
    canViewIpApiKeys: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_DEVELOPER_API_KEYS_VISIBILITY];
    }),
    canViewIpDeveloperSettings: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.get('canViewIpApiKeys') || this.get('canViewIpWebhooks');
    }),
    canViewIpWebhooks: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_DEVELOPER_WEBHOOKS_VISIBILITY];
    }),
    canViewIpWebhookDetails: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.get('canViewIpWebhooks');
    }),
    canViewWebhooksPayoutProcessingEvents: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.WEBHOOKS_PAYOUT_PROCESSING_EVENTS_VISIBILITY];
    }),
    canViewIpTransactionsInsightsRisk: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_TRANSACTIONS_INSIGHTS_RISK_VISIBILITY];
    }),
    canViewOpTransactionsAdjustmentButton: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.OP_TRANSACTION_ADJUSTMENT_BUTTON];
    }),
    canViewOpAdjustmentTypeCustomAmount: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.OP_TRANSACTION_ADJUSTMENT_TYPE_CUSTOM_AMOUNT];
    }),
    canViewMenuColorPicker: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.EPRO_COLOR_PICKER];
    }),
    canViewTransactionsFilterTrayRedesign: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.TRANSACTIONS_FILTER_TRAY_REDESIGN];
    }),
    canViewTransactionFilterTemplates: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_TRANSACTION_FILTER_TEMPLATES];
    }),
    canViewTransactionDataAdditions: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_TRANSACTION_DATA_ADDITIONS];
    }),
    canViewGiftCardEventStatuses: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_GIFT_CARD_EVENT_STATUSES];
    }),
    canViewIpWebhookEventsResentUpdates: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_WEBHOOK_EVENTS_RESENT_UPDATES];
    }),
    canViewTransactionsAdvancedSearch: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.VIEW_TRANSACTION_ADVANCED_SEARCH];
    }),
    canViewTransactionAdjustments: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_TRANSACTION_ADJUSTMENTS];
    }),
    // TODO LH-28403: remove this when the feature flag is removed
    canViewAutoSettledEventStatus: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_TRANSACTION_AUTO_SETTLEMENT];
    }),
    canViewMlmTransactionDetailsFields: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.MLM_TRANSACTION_DETAILS_FIELDS_VISIBILITY];
    }),
    canViewMlmTransactionTableColumns: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.MLM_TRANSACTION_TABLE_COLUMNS_VISIBILITY];
    }),
    canViewRiskRuleFilter: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_TRANSACTIONS_RISK_RULE_FILTER];
    }),
    canViewTransactionBatchFilter: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.MLM_TRANSACTIION_BATCH_FILTER_FOR_ONLINE_PAYMENTS];
    }),
    canViewMlmLocationsSidebar: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.MLM_LOCATIONS_SIDEBAR_VISIBILITY];
    }),
    canChangeTransactionManagerDefaultLoadTime: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.CHANGE_TRANSACTION_MANGER_DEFAULT_LOAD_TIME];
    }),
    canViewMlmTransactionsLocationsFilter: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.MLM_TRANSACTIONS_LOCATIONS_FILTER_VISIBILITY];
    }),
    canViewMlmLocationsPage: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.MLM_LOCATIONS_PAGE_VISIBILITY];
    })
  });

  _exports.default = _default;
});