define("lh4/templates/components/ui/icons/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SlVWav0N",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"20\"],[10,\"height\",\"20\"],[10,\"viewBox\",\"0 0 20 20\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M9.99996 1.66666C5.39996 1.66666 1.66663 5.4 1.66663 10C1.66663 14.6 5.39996 18.3333 9.99996 18.3333C14.6 18.3333 18.3333 14.6 18.3333 10C18.3333 5.4 14.6 1.66666 9.99996 1.66666ZM10.8333 14.1667H9.16663V9.16666H10.8333V14.1667ZM10.8333 7.5H9.16663V5.83333H10.8333V7.5Z\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/info.hbs"
    }
  });

  _exports.default = _default;
});