define("lh4/templates/authenticated/internet-payments/profile/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ciFTYJgx",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"locations\"],[10,\"data-test\",\"locations\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"locations-controls\"],[10,\"data-test\",\"locations-controls\"],[8],[0,\"\\n\\t\\t\"],[5,\"internet-payments/ui/search/list-search\",[],[[\"@placeholder\",\"@onUpdated\",\"@debounceWait\"],[\"Search\",[23,0,[\"onFilterTermInput\"]],250]]],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[5,\"internet-payments/tables/scroll-table\",[],[[\"@headers\",\"@rows\"],[[23,0,[\"headers\"]],[23,0,[\"rows\"]]]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"td\",true],[11,\"class\",[29,[\"row-level-\",[23,1,[\"level\"]]]]],[8],[1,[23,1,[\"name\"]],true],[9],[0,\"\\n\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"parentId\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"internet-payments/color-dot-label\",[],[[\"@label\",\"@value\"],[[28,\"if\",[[23,1,[\"data\",\"hasAccess\"]],\"Access Granted\",\"No Access\"],null],[28,\"if\",[[23,1,[\"data\",\"hasAccess\"]],\"green\",\"red\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/profile/locations.hbs"
    }
  });

  _exports.default = _default;
});