define("lh4/mirage/routes/internet-payments/customers/details", ["exports", "lh4/mirage/fixtures/internet-payments/customers", "lh4/mirage/fixtures/internet-payments/transactions"], function (_exports, _customers, _transactions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsCustomersDetailsServer;

  function setMirageInternetPaymentsCustomersDetailsServer(server) {
    server.get('/internet-payments/customers/:customerId', function (db, request) {
      var customerId = request.params.customerId;
      return {
        customer: _customers.default.customers.find(function (customer) {
          return customer.id === customerId;
        })
      };
    });
    server.get('/internet-payments/transactions', function () {
      return {
        transactions: _transactions.default.transactions
      };
    });
  }
});