define("lh4/enums/ip-transaction-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INTERNET_PAYMENT_TRANSACTION_STATUS = {
    SETTLED: 'settled'
  };
  var _default = INTERNET_PAYMENT_TRANSACTION_STATUS;
  _exports.default = _default;
});