define("lh4/adapters/dispute", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'disputes';
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var _snapshot$adapterOpti = snapshot.adapterOptions,
          locationId = _snapshot$adapterOpti.locationId,
          requestToken = _snapshot$adapterOpti.requestToken;
      return "".concat(this._super(id, modelName, snapshot), "?location=").concat(locationId, "&requestToken=").concat(requestToken);
    }
  });

  _exports.default = _default;
});