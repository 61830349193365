define("lh4/routes/authenticated/virtual-terminal/transactions/sale/create", ["exports", "lh4/config/environment", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _environment, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var url = "".concat(_environment.default.host, "/api/v1/virtual-terminal/url");

  var _default = Ember.Route.extend(_lighthousePermissionsMixin.default, {
    requiredPermissions: ['manage virtual-terminal'],
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('create transactions in transaction')) {
        this.transitionTo('authenticated.virtual-terminal.transactions');
      }
    },
    model: function model() {
      var qs = new URLSearchParams({
        token: this.tokenData.data.token,
        locationId: this.location.model.id,
        type: 'sale'
      });
      return Ember.RSVP.hash({
        origin: this.modelFor('authenticated.virtual-terminal.transactions').origin,
        url: "".concat(url, "?").concat(qs.toString())
      });
    },
    setupController: function setupController(controller, model, transition) {
      var _this = this;

      this._super(controller, model, transition);

      var closeRoute = transition.from ? transition.from.name : 'authenticated.virtual-terminal.transactions';
      controller.set('closeRoute', closeRoute);
      controller.set('hasLoaded', false);

      this.onIframeClose = function (event) {
        if (event.origin === model.origin) {
          // Make a call for the parent route to refresh since we know
          // the child iframe has gotten to a point where data has been changed
          _this.send('generateNewSearch');

          _this.transitionTo(closeRoute);
        }
      };

      window.addEventListener('message', this.onIframeClose, false);
    },
    deactivate: function deactivate() {
      window.removeEventListener('message', this.onIframeClose);
    }
  });

  _exports.default = _default;
});