define("lh4/components/echo-pro/tableside/download-tableside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['ui', 'primary', 'basic', 'button'],
    classNameBindings: ['isLoading:loading'],
    attributeBindings: ['isLoading:disabled'],
    isLoading: false,
    locationId: null,
    ajax: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service('flash-messages-helper'),
    responseMessages: Ember.inject.service('response-messages'),
    click: function click() {
      var _this = this;

      this.flashMessagesHelper.clearMessages();
      this.set('isLoading', true);
      this.ajax.request("/api/v1/echo-pro/terminals/tableside-download?location=".concat(this.get('locationId')), {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        window.location.href = res.apkUrl;
      }).catch(function (err) {
        _this.flashMessagesHelper.pushMessages(_this.responseMessages.getResponseErrors(err));
      }).finally(function () {
        return _this.set('isLoading', false);
      });
    }
  });

  _exports.default = _default;
});