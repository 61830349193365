define("lh4/models/echo-pro/setting/customer", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    allowHouseAccounts: (0, _attr.default)('string'),
    allowLoyalty: (0, _attr.default)('string'),
    allowScanBarcodesAndCards: (0, _attr.default)('string'),
    defaultCity: (0, _attr.default)('string'),
    defaultState: (0, _attr.default)('string'),
    defaultZip: (0, _attr.default)('string'),
    houseThresholdAmount: (0, _attr.default)('string'),
    houseTimeLimit: (0, _attr.default)('string'),
    numOrdersToRecall: (0, _attr.default)('string'),
    requireAddress: (0, _attr.default)('string'),
    requireEmail: (0, _attr.default)('string'),
    requirePhoneNumber: (0, _attr.default)('string'),
    requireZip: (0, _attr.default)('string'),
    setting: (0, _relationships.belongsTo)('echo-pro/setting')
  });

  _exports.default = _default;
});