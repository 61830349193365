define("lh4/components/epro/menu/item-bulk-form", ["exports", "lh4/mixins/components/ui/color-picker/color-picker-mapping"], function (_exports, _colorPickerMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_colorPickerMapping.default, {
    store: Ember.inject.service(),
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    errorMessage: null,
    departmentOptions: Ember.computed.map('departments', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          isActive = _ref.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    revenueClassOptions: Ember.computed.map('revenueClasses', function (_ref2) {
      var name = _ref2.name,
          value = _ref2.guid,
          isActive = _ref2.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    itemTagOptions: Ember.computed.map('itemTags', function (_ref3) {
      var name = _ref3.name,
          value = _ref3.guid,
          isActive = _ref3.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    taxOptions: Ember.computed.map('taxes', function (_ref4) {
      var name = _ref4.name,
          value = _ref4.guid,
          isActive = _ref4.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    printerOptions: Ember.computed.map('printers', function (_ref5) {
      var name = _ref5.name,
          value = _ref5.guid,
          isActive = _ref5.active;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    courseOptions: Ember.computed('courses', function getter() {
      return [{
        name: 'No Course',
        value: null
      }].concat(_toConsumableArray((this.courses || []).map(function (_ref6) {
        var name = _ref6.name,
            value = _ref6.guid,
            isActive = _ref6.isActive;
        return {
          name: name,
          value: value,
          isActive: isActive
        };
      })));
    }),
    quantityDecimalPlacesOptions: [{
      name: 0
    }, {
      name: 1
    }, {
      name: 2
    }, {
      name: 3
    }],
    defaultUnitOptions: ['LB', 'OZ'],
    timeOptions: Ember.computed('intradayPeriodOptions', function getter() {
      return this.intradayPeriodOptions.length > 0 ? ['All Day', 'Day Parts', 'Custom Schedule'] : ['All Day', 'Custom Schedule'];
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setColorsFromStylesheet();
    },
    actions: {
      handleFieldSelectorGroupChange: function handleFieldSelectorGroupChange(field, included) {
        this.onFieldSelectorGroupChange(field, included);
      },
      handleAdvancedChange: function handleAdvancedChange(selected) {
        this.changeset.set('advanced', selected);
        this.changeset.validate('isOpenItem');
      },
      toggleWeighted: function toggleWeighted() {
        this.changeset.set('isWeighted', !this.changeset.get('isWeighted'));
        this.changeset.validate('tareWeight');
      },
      toggleManualQuantity: function toggleManualQuantity() {
        this.changeset.set('isManualQuantity', !this.changeset.get('isManualQuantity'));
      },
      toggleTrackItems: function toggleTrackItems() {
        this.changeset.set('isDisplayedOnItemTracking', !this.changeset.get('isDisplayedOnItemTracking'));
        this.changeset.validate('qtyOnHand');
      },
      toggleLowStockIndicator: function toggleLowStockIndicator() {
        this.changeset.set('showQtyAlert', !this.changeset.get('showQtyAlert'));
        this.changeset.validate('minQty');
      },
      handleAvailabilityTimeOptionChange: function handleAvailabilityTimeOptionChange(timeOption) {
        this.changeset.availabilityChangeset.set('timeOption', timeOption);

        if (timeOption === 'Custom Schedule') {
          if (this.changeset.availabilityChangeset.scheduleChangesets.filterBy('isVoided', false).length === 0) {
            var initialSchedule = this.store.createRecord('echo-pro/availability-price/schedule', {
              guid: null,
              locationId: this.location.model.id,
              scheduleNumber: 1,
              scheduleName: 'Schedule 1'
            });
            var changeset = this.buildChildChangeset(initialSchedule);
            this.changeset.availabilityChangeset.scheduleChangesets.pushObject(changeset);
          }
        }
      }
    }
  });

  _exports.default = _default;
});