define("lh4/models/solo/tax", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.APPLIED_MENU_SALES_TYPES = _exports.TAX_TYPES = void 0;
  var TAX_TYPES = {
    PERCENT: 'PERCENT'
  };
  _exports.TAX_TYPES = TAX_TYPES;
  var APPLIED_MENU_SALES_TYPES = {
    QUICK_MENU_NON_QUICK_MENU: 'quickMenuNonQuickMenu',
    QUICK_MENU_SALES: 'quickMenuSales',
    NON_QUICK_MENU_SALES: 'nonQuickMenuSales'
  };
  _exports.APPLIED_MENU_SALES_TYPES = APPLIED_MENU_SALES_TYPES;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Tax Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    value: {
      description: 'Amount Value',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        positive: true,
        allowString: true,
        allowBlank: false,
        gt: 0,
        lte: 100
      })]
    },
    applyTaxTo: {
      description: 'The value of the tax to apply',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    guid: (0, _attr.default)('string'),
    value: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    isInclusive: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isAppliedToQuickMenuSales: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAppliedToNonQuickMenuSales: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    amountType: (0, _attr.default)('string', {
      defaultValue: TAX_TYPES.PERCENT
    }),
    applyTaxTo: Ember.computed('isAppliedToQuickMenuSales', 'isAppliedToNonQuickMenuSales', {
      get: function get() {
        if (this.isAppliedToQuickMenuSales && this.isAppliedToNonQuickMenuSales) {
          return APPLIED_MENU_SALES_TYPES.QUICK_MENU_NON_QUICK_MENU;
        }

        if (this.isAppliedToQuickMenuSales) {
          return APPLIED_MENU_SALES_TYPES.QUICK_MENU_SALES;
        }

        if (this.isAppliedToNonQuickMenuSales) {
          return APPLIED_MENU_SALES_TYPES.NON_QUICK_MENU_SALES;
        }

        return '';
      },
      set: function set(key, value) {
        switch (value) {
          case APPLIED_MENU_SALES_TYPES.QUICK_MENU_NON_QUICK_MENU:
            this.setProperties({
              isAppliedToQuickMenuSales: true,
              isAppliedToNonQuickMenuSales: true
            });
            return value;

          case APPLIED_MENU_SALES_TYPES.QUICK_MENU_SALES:
            this.setProperties({
              isAppliedToQuickMenuSales: true,
              isAppliedToNonQuickMenuSales: false
            });
            return value;

          case APPLIED_MENU_SALES_TYPES.NON_QUICK_MENU_SALES:
            this.setProperties({
              isAppliedToQuickMenuSales: false,
              isAppliedToNonQuickMenuSales: true
            });
            return value;

          default:
            return value;
        }
      }
    })
  });

  _exports.default = _default;
});