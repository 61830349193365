define("lh4/models/echo-pro/setting/business", ["exports", "ember-data/model", "ember-data/relationships", "ember-data/attr"], function (_exports, _model, _relationships, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    acceptTips: (0, _attr.default)('string'),
    addUpGratuitiesWithTips: (0, _attr.default)('string'),
    allowCustomStartingCash: (0, _attr.default)('string'),
    allowOpenDiscounts: (0, _attr.default)('string'),
    allowOpenDiscountsForItems: (0, _attr.default)('string'),
    allowOpenDiscountsForTickets: (0, _attr.default)('string'),
    autoCloseChangeDialog: (0, _attr.default)('string'),
    autoCloseChangeDialogTime: (0, _attr.default)('string'),
    creditCardSignatureLineThreshold: (0, _attr.default)('string'),
    creditCardThresholdAmount: (0, _attr.default)('string'),
    drawerStartingCash: (0, _attr.default)('string'),
    emailMerchantAddress: (0, _attr.default)('string'),
    enableQuickTableSelection: (0, _attr.default)('string'),
    endOfDayTime: (0, _attr.default)('string'),
    finalizeCreditCardPaymentsImmediatelyWithNoTip: (0, _attr.default)('string'),
    includeInclusiveTaxInSales: (0, _attr.default)('string'),
    lockStationAttempts: (0, _attr.default)('string'),
    lockStationTime: (0, _attr.default)('string'),
    messageOfTheDay: (0, _attr.default)('string'),
    preAuthAmount: (0, _attr.default)('string'),
    preAuthEnabled: (0, _attr.default)('string'),
    predefinedOpenDiscountAmount1: (0, _attr.default)('string'),
    predefinedOpenDiscountAmount2: (0, _attr.default)('string'),
    predefinedOpenDiscountAmount3: (0, _attr.default)('string'),
    predefinedOpenDiscountAmount4: (0, _attr.default)('string'),
    predefinedOpenDiscountPercent1: (0, _attr.default)('string'),
    predefinedOpenDiscountPercent2: (0, _attr.default)('string'),
    predefinedOpenDiscountPercent3: (0, _attr.default)('string'),
    predefinedOpenDiscountPercent4: (0, _attr.default)('string'),
    printCreditCardSignatureLine: (0, _attr.default)('string'),
    printPaymentSlips: (0, _attr.default)('string'),
    printPaymentSlipsStrategy: (0, _attr.default)('string'),
    reportsStartOfWeek: (0, _attr.default)('string', {
      defaultValue: '0'
    }),
    showDrawerEquations: (0, _attr.default)('string'),
    startOfWeek: (0, _attr.default)('string', {
      defaultValue: 'Monday'
    }),
    timeoutTime: (0, _attr.default)('string'),
    tipReductionEnabled: (0, _attr.default)('string'),
    tipReductionPercent: (0, _attr.default)('string'),
    tipsFromCashDrawer: (0, _attr.default)('string'),
    userStartingCash: (0, _attr.default)('string'),
    setting: (0, _relationships.belongsTo)('echo-pro/setting')
  });

  _exports.default = _default;
});