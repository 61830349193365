define("lh4/models/reports/subscription-recipient", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _modelCloneable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    destination: [(0, _emberCpValidations.validator)('presence', {
      message: 'Enter an email address',
      presence: true
    }), (0, _emberCpValidations.validator)('format', {
      message: 'Enter an email address',
      type: 'email'
    })]
  });

  var _default = _model.default.extend(Validations, _modelCloneable.default, {
    reportSubscriptionId: (0, _attr.default)('number'),
    destination: (0, _attr.default)('string'),
    subscription: (0, _relationships.belongsTo)('reports/subscription')
  });

  _exports.default = _default;
});