define("lh4/utils/validation-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validationCheck;

  /**
   * Returns based on whether or not validations are valid
   *
   * @method    validationCheck
   * @param     {Object} validations ember-cp-validations object
   * @returns   {Object|RSVP.Promise} The validations if they're valid, otherwise a rejected Promise
   */
  function validationCheck() {
    var _Ember$RSVP$Promise;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var validations = args[0].validations;

    if (validations.get('isValid')) {
      return validations;
    }

    return (_Ember$RSVP$Promise = Ember.RSVP.Promise).reject.apply(_Ember$RSVP$Promise, args);
  }
});