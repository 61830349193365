define("lh4/templates/components/support/contact/support-ticket-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aJN5/a8W",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui form padded stackable grid\"],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[8],[0,\"Ticket Details\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"locations\",\"length\"]],1],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"ui/form-fields/select-field\",null,[[\"changeset\",\"label\",\"options\",\"placeholder\",\"property\",\"showErrors\"],[[24,[\"changeset\"]],\"Merchant ID\",[24,[\"locationOptions\"]],\"Select Merchant\",\"locationId\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[1,[28,\"ui/form-fields/select-field\",null,[[\"changeset\",\"label\",\"options\",\"placeholder\",\"property\",\"showErrors\"],[[24,[\"changeset\"]],\"Department\",[24,[\"groupOptions\"]],\"Select Department\",\"groupId\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\\t\"],[1,[28,\"ui/form-fields/input-field\",null,[[\"changeset\",\"label\",\"type\",\"property\",\"showErrors\"],[[24,[\"changeset\"]],\"Subject\",\"text\",\"subject\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\\t\"],[1,[28,\"ui/form-fields/textarea-field\",null,[[\"changeset\",\"label\",\"type\",\"property\",\"showErrors\"],[[24,[\"changeset\"]],\"Description\",\"text\",\"description\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\\n\\t\"],[1,[22,\"ui/form-fields/required-fields\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/support/contact/support-ticket-form.hbs"
    }
  });

  _exports.default = _default;
});