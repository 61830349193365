define("lh4/templates/components/ui/icons/internet-payments/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cYXCXhoQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"width\",\"24px\"],[10,\"height\",\"24px\"],[10,\"fill\",\"currentColor\"],[10,\"data-test-icon\",\"filter\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/filter.hbs"
    }
  });

  _exports.default = _default;
});