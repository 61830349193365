define("lh4/controllers/authenticated/support/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // QueryParams
    queryParams: ['search', 'limit', 'offset'],
    headers: [{
      name: 'Ticket #',
      width: 'two',
      sortable: false
    }, {
      name: 'Status',
      width: 'two',
      sortable: false
    }, {
      name: 'Location',
      width: 'six',
      sortable: false
    }, {
      name: 'Subject',
      width: 'four',
      sortable: false
    }, {
      name: 'Created',
      width: 'two',
      sortable: false
    }],
    editRecord: Ember.inject.service(),
    session: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    limit: 10,
    offset: 0,
    flashMessagesHelper: Ember.inject.service(),
    search: null,
    doSearch: function doSearch(value) {
      var filter = {
        search: value,
        offset: 0
      };
      this.transitionToRoute('authenticated.support.contact', this.mergeQueryParams(filter));
    },
    mergeQueryParams: function mergeQueryParams(updated) {
      var filter = {
        search: this.get('search'),
        offset: updated.offset,
        limit: this.get('limit')
      };
      return {
        queryParams: Object.assign(filter, updated)
      };
    },
    actions: {
      changeFilter: function changeFilter(filter) {
        this.transitionToRoute('authenticated.support.contact', this.mergeQueryParams(filter));
      },
      clearSearch: function clearSearch() {
        this.doSearch(null);
      },
      createTicket: function createTicket() {
        this.transitionToRoute('authenticated.support.contact.create');
      },
      searchFieldChanged: function searchFieldChanged(value) {
        Ember.run.debounce(this, this.doSearch, value, 400);
      },
      ticketDetails: function ticketDetails(ticketId) {
        this.transitionToRoute('authenticated.support.contact.details', ticketId.id);
      }
    }
  });

  _exports.default = _default;
});