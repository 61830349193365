define("lh4/adapters/verify-owner/owner", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    buildURL: function buildURL() {
      return "".concat(this.host, "/api/v1/locations/").concat(this.location.model.id, "/verify-owner");
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var id = requestData.url.match(/locations\/(\d*)\/verify-owner/)[1];

      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      var response = this._super.apply(this, [status, headers, payload, requestData].concat(args));

      response.id = id;
      response.locationId = id;
      return response;
    }
  });

  _exports.default = _default;
});