define("lh4/templates/components/ui/icons/sidebar/devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tYxdVY9R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M17.001 21C16.7176 21 16.4803 20.904 16.289 20.712C16.097 20.5207 16.001 20.2833 16.001 20V12C16.001 11.7167 16.097 11.479 16.289 11.287C16.4803 11.0957 16.7176 11 17.001 11H21.001C21.2843 11 21.5216 11.0957 21.713 11.287C21.905 11.479 22.001 11.7167 22.001 12V20C22.001 20.2833 21.905 20.5207 21.713 20.712C21.5216 20.904 21.2843 21 21.001 21H17.001ZM17.001 19H21.001V13H17.001V19ZM8.00098 21V19H10.001V17H4.00098C3.45098 17 2.98031 16.8043 2.58898 16.413C2.19698 16.021 2.00098 15.55 2.00098 15V5C2.00098 4.45 2.19698 3.979 2.58898 3.587C2.98031 3.19567 3.45098 3 4.00098 3H18.001C18.551 3 19.022 3.19567 19.414 3.587C19.8053 3.979 20.001 4.45 20.001 5V9H18.001V5H4.00098V15H14.001V17H12.001V19H14.001V21H8.00098ZM8.70098 13.75L11.001 12L13.301 13.75L12.451 10.9L14.751 9.05H11.901L11.001 6.25L10.101 9.05H7.25098L9.55098 10.9L8.70098 13.75Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/devices.hbs"
    }
  });

  _exports.default = _default;
});