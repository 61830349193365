define("lh4/enums/transactions-filters-tray-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IP_TRAY_FILTERS = _exports.FNB_TRAY_FILTERS = void 0;
  var IP_TRAY_FILTERS = Object.freeze([{
    name: 'Transaction Status',
    unsavedState: ['selectedTransactionStatuses'],
    savedState: ['status'],
    isClearable: true
  }, {
    name: 'Risk',
    unsavedState: ['selectedRiskStatuses'],
    savedState: ['riskStatus'],
    isClearable: true
  }, {
    name: 'Order Type',
    unsavedState: ['selectedTransactionTypes'],
    savedState: ['type'],
    isClearable: true
  }, {
    name: 'Total Amount',
    errorState: ['amountError'],
    unsavedState: ['transactionAmount.min', 'transactionAmount.max'],
    savedState: ['min', 'max'],
    isClearable: true
  }, {
    name: 'Risk Rules',
    unsavedState: ['selectedRiskRules'],
    savedState: ['riskRules'],
    isClearable: true
  }, {
    name: 'Event Status',
    unsavedState: ['selectedEventStatuses'],
    savedState: ['eventStatus'],
    isClearable: true
  }, {
    name: 'Acquirer Response',
    unsavedState: ['selectedAcquirerResponses'],
    savedState: ['acquirerResponses'],
    isClearable: true
  }]);
  _exports.IP_TRAY_FILTERS = IP_TRAY_FILTERS;
  var FNB_TRAY_FILTERS = Object.freeze([{
    name: 'Transaction Status',
    unsavedState: ['selectedTransactionStatuses'],
    savedState: ['status'],
    isClearable: true
  }, {
    name: 'Transaction Type',
    unsavedState: ['selectedTransactionTypes'],
    savedState: ['type'],
    isClearable: true
  }, {
    name: 'Total Amount',
    errorState: ['amountError'],
    unsavedState: ['transactionAmount.min', 'transactionAmount.max'],
    savedState: ['min', 'max'],
    isClearable: true
  }, {
    name: 'Event Status',
    unsavedState: ['selectedEventStatuses'],
    savedState: ['eventStatus'],
    isClearable: true
  }, {
    name: 'Acquirer Response',
    unsavedState: ['selectedAcquirerResponses'],
    savedState: ['acquirerResponses'],
    isClearable: true
  }, {
    name: 'Server',
    unsavedState: ['selectedServerNames'],
    savedState: ['serverNames'],
    isClearable: true
  }, {
    name: 'Card Type',
    unsavedState: ['selectedCardTypes'],
    savedState: ['cardTypes'],
    isClearable: true
  }, {
    name: 'Revenue Center',
    unsavedState: ['selectedRevenueCenters'],
    savedState: ['revenueCenters'],
    isClearable: true
  }, {
    name: 'Day Parts',
    unsavedState: ['selectedDayParts'],
    savedState: ['dayParts'],
    isClearable: true
  }]);
  _exports.FNB_TRAY_FILTERS = FNB_TRAY_FILTERS;
});