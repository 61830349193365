define("lh4/serializers/support/application", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return "support/".concat(this._super(payloadKey));
    }
  });

  _exports.default = _default;
});