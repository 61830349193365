define("lh4/mirage/factories/epro-order-type", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    allowCustomTicketNames: true,
    autoGenerateNewName: '',
    defaultSectionGuid: '70231e00-be61-4646-ae30-9a761cc6f020',
    isActive: true,
    isCustomNameRequired: true,
    isCustomerRequired: true,
    isCustomerSelectionAllowed: true,
    isDeliveryInfoPrinted: true,
    isLocked: true,
    isPrintedDistinctly: true,
    locationId: 1,
    name: function name() {
      return _faker.default.commerce.product();
    },
    ordinal: 0,
    promptRenameOrCombineTick: true,
    revenueCenterGuid: '',
    isDeferred: true,
    minDeferredTime: 10,
    maxDeferredTime: 20,
    onlineName: 'test name',
    onlineDescription: '',
    minOrderAmount: '0.00',
    maxOrderAmount: '10.00',
    orderLeadTime: '',
    deliveryDistance: 3.00,
    shouldPrintNotificationSlip: true,
    printNotificationSlipsTo: '',
    shouldSendEmail: true,
    email: function email() {
      return _faker.default.internet.email();
    },
    shouldSendSMS: true,
    smsPhoneNumber: '100-222-0330'
  });

  _exports.default = _default;
});