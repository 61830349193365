define("lh4/helpers/belongs-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.belongsTo = belongsTo;
  _exports.default = void 0;

  /**
   * Checks if an array contains an object
   * @param {Array} selected - array of objects
   * @param {Object} item - object to search for
   * @returns {Boolean} - true if item is contained by selected, false otherwise
   */
  function belongsTo(params, _ref) {
    var selected = _ref.selected,
        item = _ref.item;

    if (selected) {
      return selected.includes(item);
    }

    return false;
  }

  var _default = Ember.Helper.helper(belongsTo);

  _exports.default = _default;
});