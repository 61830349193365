define("lh4/enums/ip-api-risk-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IP_API_RISK_RESULTS = Object.freeze({
    SUCCESS: 'S',
    FAILED: 'F',
    ERROR: 'E',
    NOT_FOUND: 'N'
  });
  var _default = IP_API_RISK_RESULTS;
  _exports.default = _default;
});