define("lh4/components/virtual-terminal/refund-settings/steps/complete/index", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#ui/step-container/form-content stepHeader=stepHeader}}
  	<div data-test-complete-message>
  		<p>Your request to change the unverified refund settings has been submitted.</p>
  		<p>Click the download button to download the signed document for your records.</p>
  		<p>Please note that the changes may not take effect immediately. Thank you for your patience.</p>
  	</div>
  {{/ui/step-container/form-content}}
  
  */
  {
    id: "SpbL3+0d",
    block: "{\"symbols\":[],\"statements\":[[4,\"ui/step-container/form-content\",null,[[\"stepHeader\"],[[24,[\"stepHeader\"]]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"data-test-complete-message\",\"\"],[8],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"Your request to change the unverified refund settings has been submitted.\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"Click the download button to download the signed document for your records.\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\",true],[8],[0,\"Please note that the changes may not take effect immediately. Thank you for your patience.\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/virtual-terminal/refund-settings/steps/complete/index.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'virtual-terminal-refund-settings-steps-complete',
    classNames: ['complete-step']
  }));

  _exports.default = _default;
});