define("lh4/components/customer-engagement/status-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'label', 'status-box'],
    classNameBindings: ['labelColor'],
    labelColor: Ember.computed('status', function getter() {
      switch (this.get('status').toLowerCase()) {
        case 'complete':
          return 'grey';

        case 'scheduled':
          return 'green';

        case 'draft':
          return 'orange';

        case 'inactive':
          return 'red';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});