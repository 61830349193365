define("lh4/templates/authenticated/social-media/post-scheduler/account/schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RfbLQpf7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal/route-modal\",null,[[\"offset\",\"class\"],[1000,\"longer\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"flash-messages\",null,[[\"flashMessages\"],[[24,[\"flashMessages\"]]]]],false],[0,\"\\n\\t\"],[1,[28,\"social-media/post-schedule\",null,[[\"account\",\"post\",\"isLoading\",\"onCancel\",\"onSchedulePost\",\"isNew\"],[[24,[\"model\",\"account\"]],[24,[\"model\",\"post\"]],[24,[\"isLoading\"]],[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"schedulePost\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/social-media/post-scheduler/account/schedule.hbs"
    }
  });

  _exports.default = _default;
});