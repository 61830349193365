define("lh4/routes/authenticated/social-media/analytics/account", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(_ref) {
      var accountId = _ref.accountId;
      var parentModel = this.modelFor('authenticated.social-media');
      Ember.set(parentModel, 'state.selectedAccountId', accountId);
      var account = this.store.peekRecord('social-media/account', accountId);

      if (!account) {
        return this.transitionTo('authenticated.social-media.analytics');
      }

      return {
        account: account
      };
    },
    redirect: function redirect(model) {
      if (!model.account.get('externalId')) {
        return this.transitionTo('authenticated.social-media.analytics');
      }

      return false;
    }
  });

  _exports.default = _default;
});