define("lh4/templates/components/social-media/link-configuration-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DvN4st60",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"popup-activator\"],[8],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"ui warning sign yellow icon\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"isWarningTooltip\"]],\"ui compact account-header inline popup\",\"content sub header account-header\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isLinked\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"component\",[[28,\"concat\",[[24,[\"namespace\"]],\"/missing-permissions-warning\"],null]],[[\"account\"],[[24,[\"account\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui warning message\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Account is not configured\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"editLink\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"bubbles\",\"style\",\"class\",\"tagName\",\"route\",\"model\"],[false,\"cursor: pointer\",\"negative small ui button\",\"button\",[24,[\"editLink\"]],[24,[\"account\",\"id\"]]]],{\"statements\":[[0,\"\\t\\t\\t\\t\\tEdit Account\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/social-media/link-configuration-warning.hbs"
    }
  });

  _exports.default = _default;
});