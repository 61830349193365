define("lh4/components/clockpicker-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input-group', 'clockpicker'],
    didInsertElement: function didInsertElement() {
      var that = this;
      this.$().clockpicker({
        donetext: 'Done',
        // will use locale service to determine if locale supports 12 or 24 hour format
        twelvehour: true,
        placement: this.get('data-placement'),
        minutestep: this.get('minutestep'),
        afterDone: this.get('afterDone')
      }).find('input').change(function callback() {
        that.set('value', this.value);
        that.sendAction('onSelection', this.value);
      });
    }
  });

  _exports.default = _default;
});