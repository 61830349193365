define("lh4/templates/components/hardware/added-to-cart-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vl4a35yp",
    "block": "{\"symbols\":[\"@item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"added-to-cart-card\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"imageUrl\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"img\",true],[11,\"src\",[23,1,[\"imageUrl\"]]],[11,\"alt\",[23,1,[\"name\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[5,\"hardware/no-image\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\n\\t\"],[7,\"div\",true],[10,\"class\",\"added-to-cart-item-container\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"product-description\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"product-name\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"product-category\"],[8],[1,[23,1,[\"type\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"product-price\"],[8],[1,[28,\"usd-format\",[[23,0,[\"discountedPrice\"]]],null],false],[4,\"if\",[[23,1,[\"priceSuffix\"]]],null,{\"statements\":[[0,\"/\"],[1,[28,\"full-price-suffix\",[[23,1,[\"priceSuffix\"]]],null],false]],\"parameters\":[]},null],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/hardware/added-to-cart-card.hbs"
    }
  });

  _exports.default = _default;
});