define("lh4/components/ui/form-fields/draggable-items-list", ["exports", "lh4/components/ui/form-fields/items-list"], function (_exports, _itemsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _itemsList.default.extend({
    listItems: Ember.computed('selected.[]', 'items.[]', function getter() {
      var _this = this;

      if (!this.selected.length) {
        return [];
      }

      return this.items.filter(function (_ref) {
        var id = _ref.id;
        return _this.selected.includes(id);
      }).sort(function (a, b) {
        return _this.selected.indexOf(a.id) - _this.selected.indexOf(b.id);
      });
    })
  });

  _exports.default = _default;
});