define("lh4/templates/components/ui/modal/display-info-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JrCXst1j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"onClose\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\",true],[10,\"class\",\"close icon\"],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"close\"],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1,[[28,\"hash\",null,[[\"header\"],[[28,\"component\",[\"ui/shared/yield-basic\"],[[\"defaultClassNames\"],[\"header\"]]]]]]]],[0,\"\\n\"],[14,1,[[28,\"hash\",null,[[\"content\"],[[28,\"component\",[\"ui/shared/yield-basic\"],[[\"defaultClassNames\"],[\"content\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/modal/display-info-modal.hbs"
    }
  });

  _exports.default = _default;
});