define("lh4/utils/intl/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setIntlService = setIntlService;
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var intlService = null;
  /**
   * Handles missing translations by providing a fallback to a default en-US locale.
   * @param {string} key - translation key
   * @param {string|string[]} locales - The locales to check for the translation.
   * @returns {string} - translated string or string error message
   */

  function missingMessage(key, locales) {
    var translationOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var defaultFallbackLocale = 'en-US'; // Show console warning about missing translation for a locale, but default to en-US translation
    // eslint-disable-next-line no-console

    console.warn("Missing translation for key: \"".concat(key, "\" in locale: \"").concat(locales, "\""));

    if (translationOptions.htmlSafe) {
      // NEEDS further investigation to understand why do we need to remove htmlSafe property from the options object.
      // If we wouldn't do it will break UI where given locale doesn't have translations AND
      // value pased to the translation e.g: this.intl.t('translation:key',{ value: value}) )
      // OR htmlSafe=true passed to enable rendering HTML within translations
      // e.g this.intl.t('translation:key',{ htmlSafe: true}) )
      // eslint-disable-next-line no-param-reassign
      delete translationOptions.htmlSafe;
    }

    return intlService.exists(key, defaultFallbackLocale) ? intlService.t(key, _objectSpread(_objectSpread({}, translationOptions), {}, {
      locale: defaultFallbackLocale
    })) : "Missing translation for ".concat(key, " for defaultLocale ").concat(defaultFallbackLocale);
  }

  function setIntlService(service) {
    intlService = service;
  }

  var _default = missingMessage;
  _exports.default = _default;
});