define("lh4/models/echo-pro/external-order", ["exports", "ember-data/attr", "ember-data/model"], function (_exports, _attr, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    origin: (0, _attr.default)('string'),
    logoUrl: (0, _attr.default)('string'),
    revenueCenterName: (0, _attr.default)('string')
  });

  _exports.default = _default;
});