define("lh4/routes/authenticated/virtual-terminal/transactions/sale", ["exports", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lighthousePermissionsMixin.default, {
    requiredPermissions: ['manage virtual-terminal'],
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('create transactions in transaction')) {
        this.transitionTo('authenticated.virtual-terminal.transactions');
      }
    }
  });

  _exports.default = _default;
});