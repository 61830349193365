define("lh4/mirage/routes/solo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageSoloServer;

  function setMirageSoloServer(server) {
    server.get('/solo/lighthouse/items/:itemId', function (schema) {
      return {
        items: schema.soloItems.all().models
      };
    });
    server.get('/solo/lighthouse/items/:merchantId/:itemId', function (schema) {
      return {
        items: schema.soloItems.all().models
      };
    });
  }
});