define("lh4/routes/authenticated/reputation", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/utils/permissions"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    errorHandler: Ember.inject.service(),
    features: Ember.inject.service(),
    permissions: Ember.inject.service(),
    requiredPermissions: ['manage reviews for reputation', 'manage accounts for reputation'],
    permissionCheckFailed: function permissionCheckFailed() {
      return this.replaceWith('authenticated.premium.reputation');
    },
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        locations: this.modelFor('authenticated').locations.filter(function (location) {
          return _this.permissions.hasService('lighthouse-premium', location);
        }).filter(function (location) {
          var manageReputationAccountPermission = _permissions.default.REPUTATION_MANAGEMENT.MANAGE_ACCOUNTS;
          return _this.permissions.hasPermission(manageReputationAccountPermission, location.id);
        })
      });
    },
    redirect: function redirect(model, transition) {
      if (transition.targetName === 'reputation' || transition.targetName === 'reputation.index') {
        return this.transitionTo('authenticated.reputation.reviews');
      }

      return false;
    }
  });

  _exports.default = _default;
});