define("lh4/templates/components/ui/label/validation-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KufZr6uh",
    "block": "{\"symbols\":[\"@message\",\"@isValid\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"validation-status\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"ui/icons/login/valid\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"valid\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[22,\"ui/icons/login/invalid\"],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"invalid\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/label/validation-status.hbs"
    }
  });

  _exports.default = _default;
});