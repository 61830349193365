define("lh4/controllers/authenticated/locations/location/echo-pro/employee-schedule/shift/edit", ["exports", "lh4/mixins/controllers/locations/location/echo-pro/employee-schedule/shift/edit-controller-mixin"], function (_exports, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {});

  _exports.default = _default;
});