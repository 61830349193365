define("lh4/controllers/authenticated/locations/location/revenue-centers", ["exports", "lh4/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BASIC_HEADERS = [{
    name: 'Name',
    field: 'revenueCenterName',
    width: 'sixteen',
    sortable: false
  }];
  var ALL_HEADERS = [{
    name: 'Name',
    field: 'revenueCenterName',
    width: 'twelve',
    sortable: false
  }, {
    name: 'Devices Assigned',
    field: 'terminals',
    width: 'four',
    sortable: false
  }];

  var _default = _generalListController.default.extend({
    location: Ember.inject.service(),
    logger: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    permissions: Ember.inject.service(),
    corporation: Ember.inject.service(),
    searchName: null,
    queryParams: ['searchName'],
    headers: Ember.computed('location.model', function getter() {
      return this.location.model.isCorpHq ? BASIC_HEADERS : ALL_HEADERS;
    }),
    revenueCenterList: Ember.computed('model.revenueCenters', function getter() {
      return this.model.revenueCenters || [];
    }),
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('searchName', search);
        this.set('offset', 0);
      }
    }
  });

  _exports.default = _default;
});