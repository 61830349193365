define("lh4/models/corporate-group", ["exports", "ember-data", "ember-cp-validations", "lh4/mixins/model-cloneable"], function (_exports, _emberData, _emberCpValidations, _modelCloneable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Price Group Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100,
        description: 'Price group name length'
      })]
    },
    groupToImport: {
      description: 'Group to Import',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.isImported')
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, _modelCloneable.default, {
    isImported: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    corpId: _emberData.default.attr('number', {
      isCopyable: true
    }),
    name: _emberData.default.attr('string'),
    groupToImport: _emberData.default.attr('number')
  });

  _exports.default = _default;
});