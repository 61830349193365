define("lh4/controllers/authenticated/bank-account/verify-owner", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { reject } from 'rsvp';
  var _default = _generalFormController.default.extend({
    location: Ember.inject.service(),
    bankAccount: Ember.inject.service('bank-account'),
    transitionOnClose: function transitionOnClose() {
      this.transitionToRoute('authenticated.locations.location.overview', this.location.model.id);
    },
    transitionToError: function transitionToError(error) {
      this.changeset.rollback();
      this.bankAccount.set('errorMessage', error.friendlyError);
      this.transitionToRoute('authenticated.bank-account.error');
    },
    actions: {
      save: function save() {
        var _this = this;

        if (this.isSaving) {
          return;
        }

        this.set('showErrors', true);
        this.set('isSaving', true);
        this.clearServerError();
        this.changeset.validate().then(function () {
          if (_this.changeset.get('isInvalid')) {
            // return reject(this.changeset.get('errors'));
            // If we have an invalid changeset stop now
            // and let the user fix. Previously we were throwing
            // an explicit error here and then catching it,
            // but there really is no reason to throw this since we
            // know what's wrong and we're letting the user know.
            // The only other consideration would be setting
            // showErrors true in this block instead of always setting.
            // to true on save. If we are having an uncaught
            // error here the resolution is to put the reject and
            // catch back in to troubleshoot, but ideally errors for
            // client side validation are already being displayed to
            // the user.
            return false;
          }

          return _this.changeset.save().then(function () {
            _this.set('showErrors', false);

            _this.transitionToRoute('authenticated.bank-account.security');
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (errors && errors[0]) {
              _this.transitionToError(errors[0]);
            }
          });
        }).finally(function () {
          return _this.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});