define("lh4/serializers/reservations/setting", ["exports", "lh4/serializers/reservations/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'name',
    phoneNumberService: Ember.inject.service('phone-number'),
    normalize: function normalize(modelClass, json) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      var normalized = this._super.apply(this, [modelClass, json].concat(args));

      if (json.name.indexOf('turnaround') === -1 && json.name.indexOf('max') === -1) {
        if (json.value === '1' || json.value === 'true') {
          normalized.data.attributes.value = true;
        } else if (json.value === '0' || json.value === 'false') {
          normalized.data.attributes.value = false;
        }
      }

      if (json.name === 'notification-phone-number') {
        normalized.data.attributes.value = this.phoneNumberService.formatPhoneNumber(normalized.data.attributes.value);
      }

      return normalized;
    },
    serialize: function serialize(snapshot) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      var json = this._super.apply(this, [snapshot].concat(args));

      if (snapshot.id.indexOf('turnaround') === -1 && snapshot.id.indexOf('max') === -1) {
        if (json.value === true) {
          json.value = '1';
        } else if (json.value === false) {
          json.value = '0';
        }
      }

      if (snapshot.id === 'notification-phone-number') {
        json.value = this.phoneNumberService.serializePhoneNumber(json.value);
      }

      return json;
    }
  });

  _exports.default = _default;
});