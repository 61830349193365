define("lh4/models/processing/deposit", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // ID: 36233792, guessing this is the pK
    FileNumber: _emberData.default.attr('string'),
    BankNum: _emberData.default.attr('string'),
    AccountNum: _emberData.default.attr('string'),
    ACHPeriod: _emberData.default.attr('string'),
    TimeOfDay: _emberData.default.attr('string'),
    ACHTable: _emberData.default.attr('string'),
    ACHTableDesc: _emberData.default.attr('string'),
    ACHKey: _emberData.default.attr('string'),
    TransactionCode: _emberData.default.attr('string'),
    TransmissionNum: _emberData.default.attr('string'),
    ReferenceNumber: _emberData.default.attr('string'),
    AggAmount: _emberData.default.attr('number'),
    RoutingNumber: _emberData.default.attr('string'),
    DebitCredit: _emberData.default.attr('string'),
    OffsetDDA: _emberData.default.attr('string'),
    OffsetTR: _emberData.default.attr('string'),
    OffsetDescription: _emberData.default.attr('string'),
    Status: _emberData.default.attr('string'),
    BatchDate: _emberData.default.attr('string'),
    DepositDate: _emberData.default.attr('string'),
    TransactionDate: _emberData.default.attr('string'),
    BatchDesc: _emberData.default.attr('string'),
    SuspendFlag: _emberData.default.attr('string'),
    UpdateDateTime: _emberData.default.attr('string'),
    AssociationNumber: _emberData.default.attr('string'),
    DBAName: _emberData.default.attr('string'),
    GroupNumber: _emberData.default.attr('string')
  });

  _exports.default = _default;
});