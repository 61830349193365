define("lh4/templates/components/dashboard-widgets/social-media-exposure/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V0VnviV4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"permissionError\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"img\",true],[10,\"src\",\"/img/dashboard/social-media-signup.png\"],[10,\"alt\",\"Clocks and Forms\"],[8],[9],[0,\"\\n\\t\"],[7,\"span\",true],[8],[0,\"Manage all your social network accounts in one place\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted button\"],[8],[0,\"Schedule your first social network post\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"Reached\"],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[24,[\"data\",\"reached\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"Interactions\"],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[24,[\"data\",\"interactions\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"Shares\"],[9],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[24,[\"data\",\"shares\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/social-media-exposure/data.hbs"
    }
  });

  _exports.default = _default;
});