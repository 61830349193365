define("lh4/templates/components/tables/reservation-block-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nbAZyeXt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]]]],[8],[0,\"\\n\\t\"],[7,\"b\",true],[8],[0,\"Tables of \"],[1,[24,[\"table\",\"value\"]],false],[9],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n\\tAvailable: \"],[1,[24,[\"table\",\"count\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"center aligned\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui buttons\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui button \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]]]],[12,\"disabled\",[22,\"disabled\"]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"removeTable\"]],[8],[0,\"-\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"or\"],[11,\"data-text\",[29,[[22,\"selectedTableAmount\"]]]],[8],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary button \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]]]],[12,\"disabled\",[22,\"disabled\"]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"addTable\"]],[8],[0,\"+\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/tables/reservation-block-row.hbs"
    }
  });

  _exports.default = _default;
});