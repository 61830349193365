define("lh4/models/echo-pro/course", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Course Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    isOnHold: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    items: (0, _relationships.hasMany)('echo-pro/item'),
    departments: (0, _relationships.hasMany)('echo-pro/department')
  });

  _exports.default = _default;
});