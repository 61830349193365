define("lh4/models/echo-pro/setting/mobile-wallet", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    vnApiUrl: (0, _attr.default)('string'),
    vnAppKey: (0, _attr.default)('string'),
    vnAuthUrl: (0, _attr.default)('string'),
    vnDeviceId: (0, _attr.default)('string'),
    vnEnabled: (0, _attr.default)('string'),
    vnMerchantAccountId: (0, _attr.default)('string'),
    vnOrganizationName: (0, _attr.default)('string'),
    setting: (0, _relationships.belongsTo)('echo-pro/setting')
  });

  _exports.default = _default;
});