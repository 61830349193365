define("lh4/templates/authenticated/add-on-devices/tableside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ObzSIabk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segments tableside-container\"],[8],[0,\"\\n\"],[4,\"ui/header-segment\",null,null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"inline fields\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"label\",true],[8],[0,\"Default Tableside Printer\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"ui/dropdown-simple\",null,[[\"dropdownId\",\"collection\",\"selected\",\"idField\",\"displayField\",\"onChange\"],[[24,[\"dropdownId\"]],[24,[\"model\",\"printers\"]],[24,[\"defaultPrinter\"]],\"guid\",\"name\",\"changePrinter\"]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"echo-pro/tableside/download-tableside\",null,[[\"locationId\"],[[24,[\"model\",\"location\",\"id\"]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"classNames\",\"tagName\",\"route\"],[\"ui primary button\",\"button\",\"authenticated.add-on-devices.wizard\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\tAdd New Device\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui bottom attached segment\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"echo-pro/tableside/terminals-table\",null,[[\"terminals\"],[[24,[\"model\",\"terminals\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/add-on-devices/tableside.hbs"
    }
  });

  _exports.default = _default;
});