define("lh4/templates/authenticated/orders/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ks2uaurM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui segments\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui top attached segment\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"h3\",true],[8],[0,\"POS Systems and Supply Orders\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui attached segment\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"supply-orders/order-tracking-table\",null,[[\"orders\",\"merchantId\",\"showLink\"],[[24,[\"model\",\"orders\"]],null,false]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/orders/order.hbs"
    }
  });

  _exports.default = _default;
});