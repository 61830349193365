define("lh4/components/ui/dropdown-static", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    selectedValue: null,
    setSelectedValue: true,
    // Use a string value for the classes attribute, i.e. 'first class'
    // with a space seperating each class
    dropdownClass: null,
    options: [],
    actions: {
      onOptionInsert: function onOptionInsert(option) {
        this.get('options').addObject(option);
      },
      onOptionDestroy: function onOptionDestroy(option) {
        this.get('options').removeObject(option);
      },
      onSelect: function onSelect(selected) {
        if (this.get('selectedValue') === selected) {
          return;
        }

        if (this.get('setSelectedValue')) {
          this.set('selectedValue', selected);
        }

        this.sendAction('onSelect', selected);
      }
    }
  });

  _exports.default = _default;
});