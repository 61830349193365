define("lh4/templates/components/internet-payments/ui/hover-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eAgjHv7P",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"hover-list\"],[10,\"data-test\",\"hover-list\"],[8],[0,\"\\n\\t\"],[1,[23,0,[\"args\",\"title\"]],false],[0,\"\\n\"],[4,\"if\",[[23,0,[\"args\",\"list\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"ul\",true],[10,\"class\",\"list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"args\",\"list\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"li\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/hover-list.hbs"
    }
  });

  _exports.default = _default;
});