define("lh4/templates/components/ui/lightbox-single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "025bjioj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",false],[12,\"class\",\"ui fluid image lightbox-thumbnail\"],[12,\"alt\",[22,\"alt\"]],[12,\"src\",[29,[[22,\"img\"]]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"expand\"]],[8],[9],[0,\"\\n\"],[7,\"div\",false],[12,\"class\",[29,[\"lightbox-dimmer \",[28,\"if\",[[24,[\"active\"]],\"active\",\"inactive\"],null]]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"image content\"],[8],[0,\"\\n\\t\\t\"],[7,\"img\",true],[10,\"class\",\"ui center image lightbox-fullscreen\"],[11,\"alt\",[22,\"alt\"]],[11,\"src\",[29,[[22,\"img\"]]]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/lightbox-single.hbs"
    }
  });

  _exports.default = _default;
});