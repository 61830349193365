define("lh4/components/reputation/account-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedLocationId: null,
    locationMessage: 'Location is already used for this reputation service',
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('account.locationId') && !this.get('selectedLocationId')) {
        this.set('selectedLocationId', this.get('account.locationId'));
      }
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);

      this.set('selectedLocationId', null);
    },
    filteredLocations: Ember.computed('locations', 'usedLocations', 'account.locationId', function getter() {
      var _this = this;

      if (!this.get('locations')) {
        return [];
      }

      return this.get('locations').map(function (location) {
        var convertedLocation = location.toJSON();

        if (!_this.get('usedLocations').some(function (ul) {
          return ul.id === location.id;
        }) && _this.get('selectedLocationId') !== location.id) {
          Ember.set(convertedLocation, 'disabled', true);
        }

        convertedLocation.id = location.id;
        return convertedLocation;
      });
    }),
    actions: {
      selectLocation: function selectLocation(value) {
        this.set('account.locationId', value);
      }
    }
  });

  _exports.default = _default;
});