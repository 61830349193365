define("lh4/controllers/authenticated/locations/location/echo-pro/employee-schedule", ["exports", "moment", "jquery", "lh4/classes/locations/location/echo-pro/employee-schedule/schedule"], function (_exports, _moment, _jquery, _schedule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    intl: Ember.inject.service(),
    schedule: Ember.computed('model.shifts', 'model.shifts.@each', function getter() {
      var _this$get = this.get('model.range'),
          from = _this$get.from,
          to = _this$get.to;

      var employees = this.get('model.employees').toArray();
      var shifts = this.get('model.shifts').toArray();
      return new _schedule.default(shifts, employees, from, to);
    }),
    actions: {
      onDateRangeChanged: function onDateRangeChanged(from, to) {
        var dateFormat = this.get('model.dateFormat');
        this.transitionToRoute({
          queryParams: {
            from: from.format(dateFormat),
            to: to.format(dateFormat)
          }
        });
      },
      editShift: function editShift(shift) {
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule.shift.edit', shift.get('id'));
      },
      createShift: function createShift(index, employeeId, isSourceCalendar) {
        var headerIndex = index || 0;
        var headers = this.get('schedule.headers');
        var selectedDate = headers[headerIndex].date;
        var day = (0, _moment.default)(selectedDate).format(this.get('model.dateFormat'));
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule.shift.create', {
          queryParams: {
            day: day,
            employeeId: employeeId,
            isSourceCalendar: isSourceCalendar
          }
        });
      },
      saveAsTemplate: function saveAsTemplate() {
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule.template.create');
      },
      applyTemplate: function applyTemplate() {
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule.template.apply');
      },
      listTemplates: function listTemplates() {
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule.template.list');
      },
      clearAll: function clearAll() {
        (0, _jquery.default)('#clear-all-dialog').modal('show');
      },
      clearAllConfirmed: function clearAllConfirmed() {
        var _this = this;

        var ids = this.get('model.shifts').map(function (shift) {
          return shift.get('id');
        });

        if (ids.length === 0) {
          // will get 400 response anyways
          return;
        }

        this.set('isDeleting', true);
        this.ajax.put('/api/v1/echo-pro/employee-shifts/clear-shifts', {
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({
            locationId: this.get('model.location.id'),
            shifts: ids
          })
        }).then(function () {
          return _this.get('model.shifts').clear();
        }).finally(function () {
          return _this.set('isDeleting', false);
        });
      },
      printSchedule: function printSchedule() {
        var printWindow = window.open('', 'PRINT');

        var _this$get2 = this.get('model.range'),
            from = _this$get2.from,
            to = _this$get2.to;

        var dateFormat = this.intl.formats.date.MEDIUM_WEEKDAY_NO_YEAR;
        this.metrics.trackEvent('MixpanelCustom', {
          event: 'Employee Schedule Update Completed',
          'Update Name': 'Print Schedule',
          'Schedule Start Date': this.model.range.from.format('DD/MM/YYYY'),
          'Missing Recipients': true,
          locationId: this.location.model.id,
          merchantId: this.location.model.merchantId
        });
        printWindow.document.write("<html><head><title>".concat(document.title, "</title>"));
        printWindow.document.write('<link rel="stylesheet" href="/assets/vendor.css" type="text/css" />');
        printWindow.document.write('<link rel="stylesheet" href="/assets/lh4.css" type="text/css" />');
        printWindow.document.write('</head><body class="print">');
        printWindow.document.write("<h4 class=\"print-title\">".concat(this.intl.formatDate(from, dateFormat)) + " - ".concat(this.intl.formatDate(to, dateFormat), "</h4>"));
        printWindow.document.write((0, _jquery.default)('#calendar')[0].outerHTML);
        printWindow.document.write('<script type="text/javascript">document.addEventListener("DOMContentLoaded", ' + 'function() {setTimeout(function() {window.print();window.close();}, 1000);});</script>');
        printWindow.document.write('</body></html>');
        printWindow.document.close(); // necessary for IE >= 10

        printWindow.focus(); // necessary for IE >= 10*/
      },
      emailSchedule: function emailSchedule() {
        this.transitionToRoute('authenticated.locations.location.echo-pro.employee-schedule.publish.email');
      }
    }
  });

  _exports.default = _default;
});