define("lh4/templates/components/ui/icons/s4/four-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zba3zOBy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"44\"],[10,\"height\",\"74\"],[10,\"viewBox\",\"0 0 44 74\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"id\",\"Vector\"],[10,\"d\",\"M36.3632 0.89322L0.98516 46.3443C0.86232 46.5899 0.739502 46.7128 0.739502 46.9585V52.1178C0.739502 52.732 1.23086 53.2233 1.84506 53.2233H33.5379C34.1521 53.2233 34.6434 53.7147 34.6434 54.3289V72.755C34.6434 73.3692 35.1348 73.8606 35.749 73.8606H42.5052C43.1194 73.8606 43.6108 73.3692 43.6108 72.755V1.50742C43.6108 0.89322 43.1194 0.401855 42.5052 0.401855H37.3459C36.9774 0.524696 36.6089 0.647539 36.3632 0.89322ZM12.7778 43.3961L32.678 17.9681C33.2922 17.1082 34.6434 17.5995 34.6434 18.5823V44.0103C34.6434 44.6245 34.1521 45.1159 33.5379 45.1159H13.6377C12.655 45.2387 12.1636 44.1331 12.7778 43.3961Z\"],[10,\"fill\",\"#090304\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/s4/four-icon.hbs"
    }
  });

  _exports.default = _default;
});