define("lh4/routes/authenticated/locations/location/echo-pro/reservations/calendar", ["exports", "moment", "lh4/mixins/authenticated-route-mixin"], function (_exports, _moment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    queryParams: {
      month: {
        refreshModel: true
      },
      year: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      var month = params.month ? params.month : (0, _moment.default)().format('MMMM');
      var year = params.year ? params.year : (0, _moment.default)().format('YYYY');
      var dateFormat = "01 ".concat(month, " ").concat(year);
      var location = this.modelFor('authenticated.locations.location');
      var now = (0, _moment.default)(dateFormat, 'DD MMMM YYYY').startOf('month').toISOString();
      var end = (0, _moment.default)(dateFormat, 'DD MMMM YYYY').endOf('month').toISOString();
      this.store.unloadAll('reservation');
      return Ember.RSVP.hash({
        location: location,
        reservation: this.store.query('reservation', {
          location: location.id,
          'gte[partyTime]': now,
          'lte[partyTime]': end
        }).then(function () {
          return _this.store.peekAll('reservation');
        }),
        table: this.store.query('reservations/table', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('reservations/table');
        }),
        section: this.store.query('reservations/section', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('reservations/section');
        })
      });
    }
  });

  _exports.default = _default;
});