define("lh4/mirage/factories/solo-item", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id: function id() {
      return _faker.default.datatype.uuid();
    },
    name: 'Beer',
    basePrice: 2,
    // FLAT or PERCENT
    taxType: 'PERCENT',
    taxValue: 10,
    fullPrice: 2.20
  });

  _exports.default = _default;
});