define("lh4/enums/ip-export-customer-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EXPORT_CUSTOMER_TYPE = Object.freeze({
    TRANSACTIONS: 'transactions',
    SEARCH_RESULTS: 'search_results',
    PROFILE: 'profile'
  });
  var _default = EXPORT_CUSTOMER_TYPE;
  _exports.default = _default;
});