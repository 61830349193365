define("lh4/templates/components/ui/icons/nav-bar/v2/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P8hFpi0I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12C4 14.5201 5.17229 16.7723 7 18.24V20H12Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M7 19H5V22H12V20H7V19Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M9.5 13H7.5V11H9.5V13Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M13 13H11V11H13V13Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M16.5 13H14.5V11H16.5V13Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/nav-bar/v2/chat.hbs"
    }
  });

  _exports.default = _default;
});