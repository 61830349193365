define("lh4/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageScenariosServer;

  function setMirageScenariosServer(server) {
    /*
       Seed your development database using your factories.
       This data will not be loaded in your tests.
        Make sure to define a factory for each model you want to create.
     */
    server.createList('location', 85);
    server.createList('support-ticket', 85);
    server.createList('overview', 85);
    server.createList('statement', 1);
    server.createList('loyalty-program', 2);
  }
});