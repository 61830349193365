define("lh4/components/echo-pro/employee-schedule/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentColumn: 0,
    actions: {
      navigateBack: function navigateBack() {
        if (this.get('currentColumn') > 0) {
          this.decrementProperty('currentColumn');
        }
      },
      navigateForward: function navigateForward() {
        if (this.get('currentColumn') < 6) {
          this.incrementProperty('currentColumn');
        }
      }
    }
  });

  _exports.default = _default;
});