define("lh4/models/location", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    merchantId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    sicCode: _emberData.default.attr('string'),
    phone: _emberData.default.attr('phone'),
    email: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    addressLine1: _emberData.default.attr('string'),
    addressLine2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    product: _emberData.default.attr('string'),
    productConfiguration: _emberData.default.attr(),
    features: _emberData.default.attr(),
    corpId: _emberData.default.attr('number'),
    isCorpHq: _emberData.default.attr('boolean'),

    /**
     * {Array<string>} terminalProducts
     *
     * Careful, relying on anything other than 'gpatt' value should be done very carefully
     * as other values in the list could be wrong.
     */
    terminalProducts: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    terminalMaxVersions: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    terminalMinVersions: _emberData.default.attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    services: _emberData.default.attr(),
    locationKey: _emberData.default.attr('string'),
    channel: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    logoUrl: _emberData.default.attr('string'),
    aId: _emberData.default.attr('string'),
    usesShift4PaymentProcessor: _emberData.default.attr('boolean'),
    countryCode: _emberData.default.attr('string'),
    currency: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    loyaltyProgram: (0, _relationships.belongsTo)('loyalty/program'),
    accountStatus: _emberData.default.attr('string'),
    isMenuComplete: _emberData.default.attr('boolean'),
    coordinates: _emberData.default.attr(),
    legalName: _emberData.default.attr('string'),
    shift4Mid: _emberData.default.attr('string'),
    shift4Serial: _emberData.default.attr('string')
  });

  _exports.default = _default;
});