define("lh4/controllers/authenticated/epro/menu/items/edit", ["exports", "lh4/controllers/general-form-controller", "lh4/mixins/controllers/authenticated/epro/menu/stylesheet-saving"], function (_exports, _generalFormController, _stylesheetSaving) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend(_stylesheetSaving.default, {
    fullBlob: null,
    thumbBlob: null,
    metrics: Ember.inject.service(),
    locale: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    isAnyScheduleOverlapping: false,
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),
    previewImageUrl: Ember.computed('changeset.imageUrl', 'shouldDeleteImage', function getter() {
      if (this.shouldDeleteImage) {
        return null;
      }

      return this.changeset.get('imageUrl');
    }),
    shouldHandleImage: Ember.computed('shouldSaveImage', 'shouldDeleteImage', function getter() {
      return this.shouldSaveImage || this.shouldDeleteImage;
    }),
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (_ref) {
        var id = _ref.id;
        return !changeset.findBy('id', id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    saveImage: function saveImage(_ref2) {
      var itemId = _ref2.itemId;
      var formData = new FormData();
      formData.append('full', this.fullBlob, "".concat(itemId, "-full.png"));
      formData.append('thumb', this.thumbBlob, "".concat(itemId, "-thumb.png"));
      return this.ajax.request("/api/v1/echo-pro/items/".concat(itemId, "/image"), {
        method: 'POST',
        data: formData,
        contentType: false,
        processData: false
      }).catch(function (error) {
        var errorMessage = 'Something went wrong, image could not be saved';

        if (error.payload && error.payload.error && error.payload.error.message) {
          errorMessage = "".concat(errorMessage, ": ").concat(error.payload.error.message);
        }

        return Ember.RSVP.reject(errorMessage);
      });
    },
    deleteImage: function deleteImage(_ref3) {
      var _this = this;

      var itemId = _ref3.itemId;
      return this.ajax.delete("/api/v1/echo-pro/items/".concat(itemId, "/image")).then(function () {
        return _this.setProperties({
          fullBlob: null,
          thumbBlob: null
        });
      }).catch(function () {
        return Ember.RSVP.resolve('There was an unexpected error while removing your image');
      });
    },
    handleImageError: function handleImageError(error) {
      var _this2 = this;

      // TODO normalize how we handle these error messages
      var updateError = 'Something went wrong, image could not be saved';
      var errorMessage = error || updateError;
      this.set('serverError', errorMessage);
      this.set('serverErrorTimeoutId', Ember.run.later(this, function () {
        _this2.set('serverError', null);
      }, this.serverErrorTimeoutDelay));
    },
    unloadChildRecords: function unloadChildRecords() {
      var _this$model$item$item, _this$model$item$dyna, _this$model$item$get;

      // Old "Pricing Schedules" functionality
      (_this$model$item$item = this.model.item.itemPriceScheduleMaps) === null || _this$model$item$item === void 0 ? void 0 : _this$model$item$item.toArray().forEach(function (map) {
        map.unloadRecord();
      }); // New "Dynamic Pricing" functionality

      (_this$model$item$dyna = this.model.item.dynamicPricing) === null || _this$model$item$dyna === void 0 ? void 0 : _this$model$item$dyna.toArray().forEach(function (map) {
        map.unloadRecord();
      });
      (_this$model$item$get = this.model.item.get('availability.schedules')) === null || _this$model$item$get === void 0 ? void 0 : _this$model$item$get.toArray().forEach(function (map) {
        map.unloadRecord();
      });
    },
    validateAndSaveChangeset: function validateAndSaveChangeset(changesets, key) {
      var _this3 = this;

      return changesets.map(function (record) {
        return record.validate().then(function () {
          if (!record.isValid && !record.get('isVoided')) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.execute();
          record.rollback();

          _this3.model.item.get(key).pushObject(record.data);

          return true;
        }).catch(function () {
          _this3.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    saveChangeset: function saveChangeset() {
      var _this4 = this;

      var shouldCloseTray = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var cloning = arguments.length > 1 ? arguments[1] : undefined;
      var snapshot = this.changeset.snapshot();
      this.createStposUiStylesheetObjectLink({
        locationId: this.changeset.get('locationId'),
        isActive: true,
        itemGuid: this.changeset.get('id')
      });
      return this.changeset.save().then(function (data) {
        // Reload child items fully to get proper formatting of the .priceValue
        // otherwise child elements stay in the dirty stay forever where
        // dirtyType: 'updated'
        _this4.unloadChildRecords();

        if (shouldCloseTray) {
          if (cloning) {
            _this4.duplicateItem(data.id);
          }

          _this4.clearTemporaryData();

          _this4.closeTray();
        }
      }).catch(function (_ref4) {
        var errors = _ref4.errors;

        if (!(errors && errors[0])) {
          return false;
        }

        _this4.changeset.restore(snapshot);

        _this4.changeset.data.rollbackAttributes();

        return Ember.RSVP.reject([_this4.handleServerErrors(errors)]);
      });
    },
    // Remove maps that have no priceValue set - that's the only property that can indicate us
    // that this is being deleted
    removeDeletedPriceScheduleMaps: function removeDeletedPriceScheduleMaps() {
      this.changeset.priceScheduleMapChangesets = this.changeset.priceScheduleMapChangesets.reduce(function (result, option) {
        if (option.get('priceValue')) {
          result.push(option);
        }

        return result;
      }, []);
    },
    saveAvailability: function saveAvailability() {
      var _this5 = this;

      if (this.changeset.get('defaultAvailabilityString') === 'true') {
        return Ember.RSVP.resolve();
      }

      var availability = this.changeset.availabilityChangeset;
      return availability.validate().then(function () {
        if (!availability.isValid) {
          _this5.set('areChildrenInvalid', true);

          return Ember.RSVP.reject(availability.get('errors'));
        }

        availability.execute();
        availability.rollback();

        _this5.model.item.set('availability', availability.data);

        return Ember.RSVP.resolve();
      }).then(function () {
        if (_this5.changeset.get('availabilityChangeset').get('timeOption') === 'Custom Schedule') {
          var schedulePromises = _this5.validateAndSaveChangeset(availability.scheduleChangesets, 'availability.schedules');

          return Ember.RSVP.all(schedulePromises);
        }

        return Ember.RSVP.resolve();
      });
    },
    handleLocalErrors: function handleLocalErrors(errors) {
      var _this6 = this;

      // We currently don't have a field component for checkboxes
      // Because of this there is no standard way of displaying
      // error messages for boolean fields
      // https://jira.shift4payments.com/browse/LH-8853
      if (errors.length) {
        var booleanError = errors.find(function (error) {
          return typeof error.value === 'boolean';
        });

        if (booleanError) {
          this.set('booleanError', booleanError.validation);
          this.set('serverErrorTimeoutId', Ember.run.later(this, function () {
            _this6.set('booleanError', null);
          }, this.serverErrorTimeoutDelay));
        } else {
          // Used to catch custom validation error that validates models one to another
          // For unique name
          var error = errors[0];

          if (error && error.validation) {
            this.set('customError', error.validation);
          }
        }
      }
    },
    handleServerErrors: function handleServerErrors(errors) {
      var _this7 = this;

      // TODO normalize how we handle these error messages
      var updateError = "Something went wrong, ".concat(this.instanceName, " could not be updated.");
      errors.forEach(function (error) {
        var errorMessage = error.details && error.details[0] || error.message || updateError;

        _this7.set('serverError', errorMessage);

        _this7.set('serverErrorTimeoutId', Ember.run.later(_this7, function () {
          _this7.set('serverError', null);
        }, _this7.serverErrorTimeoutDelay));
      });
      return updateError;
    },
    saveOldPricing: function saveOldPricing() {
      var _this8 = this;

      // Save old "Pricing Schedules" functionality
      this.removeDeletedPriceScheduleMaps(); // Logic to warn user that with Flat Discount in price schedules item can be prized at 0 (or lower)

      var priceScheduleZeroRecords = this.changeset.priceScheduleMapChangesets.filter(function (record) {
        return record.get('priceType') === 'Flat Discount';
      }).filter(function (record) {
        var priceValue = parseFloat(record.get('priceValue'));
        return priceValue >= parseFloat(_this8.changeset.get('price'));
      });

      if (priceScheduleZeroRecords.length > 0) {
        var confirmed = window.confirm("One of the prices affected by Scheduled Pricing rules will result to ".concat(this.locale.currencySymbol, "0. Would you like to proceed?"));

        if (!confirmed) {
          this.set('isSaving', false);
          return Ember.RSVP.reject(new Error('Please confirm'));
        }
      }

      var itemPriceScheduleMapPromises = this.validateAndSaveChangeset(this.changeset.priceScheduleMapChangesets, 'itemPriceScheduleMaps');
      return Ember.RSVP.all(itemPriceScheduleMapPromises);
    },
    saveNewPricing: function saveNewPricing() {
      // Save new "Dynamic Pricing" functionality
      if (this.changeset.get('useDepartmentPricingString') === 'true') {
        return Ember.RSVP.resolve();
      }

      if (this.changeset.dynamicPricingChangesets.some(function (c) {
        return c.get('hasZeroPrice') === true;
      })) {
        var confirmed = window.confirm("The item price when affected by Dynamic Pricing rules will result to ".concat(this.locale.currencySymbol, "0. Would you like to proceed?"));

        if (!confirmed) {
          this.set('isSaving', false);
          return Promise.reject(new Error('Please confirm'));
        }
      }

      var dynamicPricingPromises = this.validateAndSaveChangeset(this.changeset.dynamicPricingChangesets, 'dynamicPricing');
      return Ember.RSVP.all(dynamicPricingPromises);
    },
    duplicateItem: function duplicateItem(itemId) {
      var _this9 = this;

      Ember.run.later(this, function () {
        _this9.transitionToRoute('authenticated.epro.menu.items.create', {
          queryParams: {
            clone: itemId
          }
        });
      }, 500);
    },
    trackUpdateCompleted: function trackUpdateCompleted() {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Completed',
        'Entry Type': 'Edit',
        'Entry ID': this.changeset.get('id'),
        'Number of Updates': Object.keys(this.changeset.change).length,
        'Entries Updated': Object.keys(this.changeset.change),
        'Menu Component': 'Item'
      });
    },
    actions: {
      setImageBlobs: function setImageBlobs(_ref5) {
        var fullBlob = _ref5.fullBlob,
            thumbBlob = _ref5.thumbBlob;

        if (!(fullBlob instanceof Blob) || !(thumbBlob instanceof Blob)) {
          throw new Error('fullBlob & thumbBlob must be of type Blob');
        } // ensure change will be detected and discard dialog will be shown
        // value doesn't matter as this will be updated after image upload


        this.changeset.set('imageUrl', 'any');
        this.setProperties({
          fullBlob: fullBlob,
          thumbBlob: thumbBlob,
          shouldSaveImage: true
        });
      },
      deleteImage: function deleteImage() {
        // ensure change will be detected and discard dialog will be shown
        this.changeset.set('imageUrl', null);
        this.setProperties({
          shouldSaveImage: false,
          shouldDeleteImage: true
        });
      },
      update: function update() {
        var _this10 = this;

        var cloning = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError(); // we have to check for pristine before we validate and save

        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.item.itemPriceScheduleMaps, this.changeset.priceScheduleMapChangesets);
        Ember.RSVP.all([this.saveOldPricing(), this.saveNewPricing(), this.saveAvailability() // eslint-disable-next-line arrow-body-style
        ]).then(function () {
          return _this10.changeset.validate().then(function () {
            if (!_this10.changeset.get('isValid') || _this10.areChildrenInvalid) {
              return Ember.RSVP.reject(_this10.changeset.get('errors'));
            }

            var itemId = _this10.changeset.get('id');

            _this10.trackUpdateCompleted();

            if (isPristine && !_this10.shouldHandleImage) {
              if (cloning) {
                _this10.duplicateItem(itemId);
              }

              _this10.clearTemporaryData();

              _this10.closeTray();

              return Ember.RSVP.resolve();
            }

            _this10.removeRecordsFromModel(_this10.model.item.itemPriceScheduleMaps, _this10.changeset.priceScheduleMapChangesets);

            if (!_this10.shouldHandleImage) {
              return _this10.saveChangeset(true, cloning);
            }

            return _this10.saveChangeset(false, cloning).then(function () {
              var imagePromise = _this10.shouldSaveImage ? _this10.saveImage({
                itemId: itemId
              }) : _this10.deleteImage({
                itemId: itemId
              });
              return imagePromise.then(function () {
                if (cloning) {
                  _this10.duplicateItem(itemId);
                }

                _this10.clearTemporaryData();

                _this10.closeTray();

                return Ember.RSVP.resolve();
              }).catch(function (error) {
                return _this10.handleImageError(error);
              });
            });
          }).catch(function (errors) {
            return _this10.handleLocalErrors(errors);
          });
        }).catch(function (errors) {
          return _this10.handleLocalErrors(errors);
        }).finally(function () {
          return _this10.setProperties({
            isSaving: false
          });
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      selectedPriceGroupChanged: function selectedPriceGroupChanged(data) {
        return this.selectedPriceGroupChanged(data);
      },
      updateAndDuplicate: function updateAndDuplicate() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.send.apply(this, ['update', true].concat(args));
      },
      setScheduleValidation: function setScheduleValidation(isAnyScheduleOverlapping) {
        this.set('isAnyScheduleOverlapping', isAnyScheduleOverlapping);
      }
    }
  });

  _exports.default = _default;
});