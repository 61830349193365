define("lh4/templates/components/ui/icons/sidebar/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UNQNMuK3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"g\",true],[10,\"clip-path\",\"url(#clip0_7910_25762)\"],[8],[0,\"\\n\\t\\t\"],[7,\"path\",true],[10,\"d\",\"M17 15H19V17H17V15ZM17 11H19V13H17V11ZM17 7H19V9H17V7ZM13.74 7L15 7.84V7H13.74Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"path\",true],[10,\"d\",\"M10 3V4.51L12 5.84V5H21V19H17V21H23V3H10Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"path\",true],[10,\"d\",\"M8.17 5.7L15 10.25V21H1V10.48L8.17 5.7ZM10 19H13V11.16L8.17 8.09L3 11.38V19H6V13H10V19Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"defs\",true],[8],[0,\"\\n\\t\\t\"],[7,\"clipPath\",true],[10,\"id\",\"clip0_7910_25762\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"rect\",true],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/locations.hbs"
    }
  });

  _exports.default = _default;
});