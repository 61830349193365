define("lh4/enums/key-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KEY_CODE = Object.freeze({
    ENTER: 13,
    TAB: 9,
    ESC: 27,
    SPACE: 32,
    ARROW_UP: 38,
    ARROW_DOWN: 40
  });
  var _default = KEY_CODE;
  _exports.default = _default;
});