define("lh4/components/modal/walkthrough-videos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['walkthrough-videos'],
    walkthrough: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    modalsManager: Ember.inject.service('modals-manager'),
    didInsertElement: function didInsertElement() {
      this.set('walkthrough.key', this.key);

      if (this.shouldShow) {
        this.set('active', this.videos[0].id);
        this.modalsManager.pushToList('walkthrough-videos', this.$('.ui.modal'));
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.shouldShow) {
        this.modalsManager.pushToList('walkthrough-videos', this.$('.ui.modal')); // Restore the video after the walkthrough modal was closed & opened again

        var videoId = this.videos[0].id;
        this.set('active', videoId);
        this.trackTutorialViewed(videoId);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.set('walkthrough.key', null);
    },

    /**
     * Since modals are only hidden and not removed from DOM when closed, we have to manually remove iFrame
     * from the DOM to stop the video
     * */
    removeVideoFrame: function removeVideoFrame() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.set('active', null);
    },
    trackTutorialViewed: function trackTutorialViewed(videoId) {
      var video = this.videos.find(function (v) {
        return v.id === videoId;
      });
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Tutorial Viewed',
        'Tutorial Name': (video === null || video === void 0 ? void 0 : video.title) || this.title,
        'Feature Name': this.title
      });
    },
    trackTutorialCompleted: function trackTutorialCompleted() {
      this.metrics.invoke('addToUserPropertyList', 'MixpanelCustom', {
        list: 'Tutorial Completed',
        value: this.title
      });
    },
    actions: {
      onHide: function onHide() {
        this.removeVideoFrame();
      },
      onHidden: function onHidden() {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.modalsManager.deleteFromList('walkthrough-videos');
        this.removeVideoFrame();
        this.set("walkthrough.data.".concat(this.key), true);
        this.walkthrough.saveData();
        this.trackTutorialCompleted();
      },
      close: function close() {
        this.modalsManager.deleteFromList('walkthrough-videos');
      },
      accordionChange: function accordionChange() {
        if (this.get('isDestroyed') || this.get('isDestroying')) {
          return;
        }

        var videoId = this.$('.content.active').attr('id');
        this.set('active', videoId);
        this.trackTutorialViewed(videoId);
      }
    }
  });

  _exports.default = _default;
});