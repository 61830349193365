define("lh4/adapters/echo-pro/employee-shift", ["exports", "moment", "lh4/adapters/echo-pro/application"], function (_exports, _moment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(queryParameters) {
      var queryParams = queryParameters;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (!queryParams.from || !queryParams.to) {
        return this._super.apply(this, [queryParams].concat(args));
      }

      var fromLeft = "gte[shift]=".concat((0, _moment.default)(queryParams.from).add(-1, 'milliseconds').toISOString());
      var fromRight = "gte[shift]=".concat(queryParams.from.toISOString());
      var toLeft = "lte[shift]=".concat((0, _moment.default)(queryParams.to).add(-1, 'milliseconds').toISOString());
      var toRight = "lte[shift]=".concat(queryParams.to.toISOString());
      delete queryParams.from;
      delete queryParams.to;
      return "".concat(this._super.apply(this, [queryParams].concat(args)), "?").concat(fromLeft, "&").concat(fromRight, "&").concat(toLeft, "&").concat(toRight);
    }
  });

  _exports.default = _default;
});