define("lh4/components/overview-chart", ["exports", "moment", "d3-scale", "d3-axis", "d3-selection", "d3-array", "d3-format"], function (_exports, _moment, _d3Scale, _d3Axis, _d3Selection, _d3Array, _d3Format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['overview-chart'],
    init: function init() {
      this._super.apply(this, arguments);

      this.margin = {
        top: 25,
        right: 20,
        bottom: 30,
        left: 50
      };
      this.width = 960 - this.margin.left - this.margin.right;
      this.height = 400 - this.margin.top - this.margin.bottom;
      this.x = (0, _d3Scale.scaleBand)().range([0, this.width]).padding(0.05);
      this.y = (0, _d3Scale.scaleLinear)().range([this.height, 0]);
      this.updateAxes();
    },
    updateAxes: function updateAxes() {
      var makeMoney = (0, _d3Format.format)('$');
      this.xAxis = (0, _d3Axis.axisBottom)(this.x);
      this.yAxis = (0, _d3Axis.axisLeft)(this.y).ticks(10).tickFormat(makeMoney);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var svg = (0, _d3Selection.select)('#bar-chart').attr('width', '100%').attr('height', '100%').attr('viewBox', '0 0 960 400').attr('preserveAspectRatio', 'xMinYMin').append('g').attr('transform', "translate(".concat(this.margin.left, ", ").concat(this.margin.top, ")"));
      svg.append('g').attr('class', 'x axis').attr('transform', "translate(0, ".concat(this.height, ")")).call(this.xAxis);
      svg.append('g').attr('class', 'y axis').call(this.yAxis);
      this.updateChart();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.updateChart();
    },
    updateChart: function updateChart() {
      var _this = this;

      var data = this.get('data');
      this.x.domain(data.map(function (d) {
        return (0, _moment.default)(d.TransDate).format('MM/DD/YYYY');
      }));
      this.y.domain([0, (0, _d3Array.max)(data, function (d) {
        return d.TotalAmt;
      })]);
      this.updateAxes();
      var svg = (0, _d3Selection.select)('#bar-chart').select('g');
      svg.select('.x.axis').call(this.xAxis);
      svg.select('.y.axis').call(this.yAxis);
      var bar = svg.selectAll('.bar').data(data, function (d) {
        return d.TransDate;
      });
      bar.exit().remove(); // exit

      bar.enter().append('rect') // enter
      .attr('class', 'bar').attr('x', function (d) {
        return _this.x((0, _moment.default)(d.TransDate).format('MM/DD/YYYY'));
      }).attr('width', this.x.bandwidth()).attr('y', function (d) {
        return _this.y(d.TotalAmt);
      }).attr('height', function (d) {
        return _this.height - _this.y(d.TotalAmt);
      }).merge(bar) // update
      .attr('class', 'bar').attr('fill', '#00C0F4').style('stroke-width', '1px').style('stroke', '#006EA6');
      bar.enter().append('text').attr('class', 'text').attr('x', function (d) {
        return _this.x((0, _moment.default)(d.TransDate).format('MM/DD/YYYY')) + _this.x.bandwidth() / 2;
      }).attr('y', function (d) {
        return _this.y(d.TotalAmt) - 10;
      }).attr('fill', '#000000').style('text-anchor', 'middle').text(function (d) {
        return "$ ".concat((0, _d3Format.format)('.2f')(d.TotalAmt));
      });
    }
  });

  _exports.default = _default;
});