define("lh4/components/hospitality/employee-multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: Ember.computed('employee.employeesPrivileges.@each.isAllowed', function getter() {
      if (!this.get('employee.employeesPrivileges.length')) {
        return [];
      }

      return this.get('employee.employeesPrivileges').filter(function (x) {
        return x.get('isAllowed');
      });
    }),
    collectionUnselected: Ember.computed('employee.employeesPrivileges.@each.isAllowed', function getter() {
      if (!this.get('employee.employeesPrivileges.length')) {
        return [];
      }

      return this.get('employee.employeesPrivileges').filter(function (x) {
        return !x.get('isAllowed');
      });
    }),
    actions: {
      selectAll: function selectAll() {
        this.get('employee.employeesPrivileges').forEach(function (x) {
          return x.set('isAllowed', true);
        });
      },
      selectNone: function selectNone() {
        this.get('employee.employeesPrivileges').forEach(function (x) {
          return x.set('isAllowed', false);
        });
      },
      selectItem: function selectItem(item) {
        item.set('isAllowed', true);
      },
      unselectItem: function unselectItem(item) {
        item.set('isAllowed', false);
      }
    }
  });

  _exports.default = _default;
});