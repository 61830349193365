define("lh4/templates/authenticated/locations/location/hospitality/manage-employees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oKEpV/0C",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"tables/employee-table\",null,[[\"showJobs\",\"type\",\"pagination\",\"jobsSelected\",\"employees\",\"limit\",\"offset\",\"activeLocationId\",\"currentSearchTerm\",\"employeeSearchTerm\",\"clearSearch\",\"updateLimit\",\"walkthrough\"],[false,\"hospitality\",[24,[\"model\",\"pagination\"]],[24,[\"model\",\"jobsSelected\"]],[24,[\"model\",\"employee\"]],[24,[\"limit\"]],[24,[\"offset\"]],[24,[\"model\",\"activeLocationId\"]],[24,[\"searchName\"]],[28,\"action\",[[23,0,[]],\"employeeNameSearch\"],null],[28,\"action\",[[23,0,[]],\"clearSearch\"],null],[28,\"action\",[[23,0,[]],\"updateLimit\"],null],[24,[\"model\",\"walkthrough\",\"managePos\",\"hospitality\",\"manageEmployees\"]]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/hospitality/manage-employees.hbs"
    }
  });

  _exports.default = _default;
});