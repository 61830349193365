define("lh4/templates/authenticated/customer-engagement/settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q+GbNRFr",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"customer-engagement/settings-links\",null,[[\"settings\",\"social\",\"currentLocation\",\"goBack\"],[[24,[\"model\",\"settings\"]],[24,[\"model\",\"social\"]],[24,[\"location\",\"model\"]],[28,\"action\",[[23,0,[]],\"goBack\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/customer-engagement/settings/index.hbs"
    }
  });

  _exports.default = _default;
});