define("lh4/templates/components/ui/icons/login/planet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vibDLNRL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"16\"],[10,\"height\",\"16\"],[10,\"viewBox\",\"0 0 16 16\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 14.56C11.623 14.56 14.56 11.623 14.56 8C14.56 4.37701 11.623 1.44 8 1.44C4.37701 1.44 1.44 4.37701 1.44 8C1.44 11.623 4.37701 14.56 8 14.56Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M8 16C10.2091 16 12 12.4183 12 8C12 3.58172 10.2091 0 8 0C5.79086 0 4 3.58172 4 8C4 12.4183 5.79086 16 8 16ZM8 14.56C9.41385 14.56 10.56 11.623 10.56 8C10.56 4.37701 9.41385 1.44 8 1.44C6.58615 1.44 5.44 4.37701 5.44 8C5.44 11.623 6.58615 14.56 8 14.56Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\"],[7,\"rect\",true],[10,\"x\",\"1.5\"],[10,\"y\",\"5\"],[10,\"width\",\"13\"],[10,\"height\",\"1.5\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\\t\"],[7,\"rect\",true],[10,\"x\",\"1.5\"],[10,\"y\",\"9.5\"],[10,\"width\",\"13\"],[10,\"height\",\"1.5\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/login/planet.hbs"
    }
  });

  _exports.default = _default;
});