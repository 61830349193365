define("lh4/components/tables/multiselect-table", ["exports", "lh4/classes/components/tables/multiselect-table-row"], function (_exports, _multiselectTableRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['horizontal', 'scroll'],
    warningOnMissing: null,
    disableUnselected: false,
    validateSelection: false,
    rows: Ember.computed('items', 'items.@each', function getter() {
      var items = this.get('items') || [];
      var selected = this.get('selected') || [];
      return items.map(function (i) {
        if (i.get('validations.altKey')) {
          return _multiselectTableRow.default.create({
            content: i,
            isSelected: selected.includes(i),
            isValid: i.get(i.get('validations.altKey')).isValid
          });
        }

        return _multiselectTableRow.default.create({
          content: i,
          isSelected: selected.includes(i)
        });
      });
    }),
    allSelected: Ember.computed('rows.@each.validations', 'selected.[]', function getter() {
      return this.getSelectable().length === this.get('selected.length');
    }),
    getSelectable: function getSelectable() {
      return this.validateSelection ? this.rows.filter(function (row) {
        return row.get('validations').isValid;
      }) : this.rows;
    },
    actions: {
      toggleSelection: function toggleSelection(row) {
        var validationAltKey = row.get('validations.altKey');

        if (this.validateSelection && !row.get(validationAltKey || 'validations').isValid) {
          return;
        }

        row.toggleProperty('isSelected');
        this.send('updateSelected');
      },
      toggleFullSelection: function toggleFullSelection(checked) {
        var selectable = this.getSelectable();
        selectable.setEach('isSelected', checked);
        this.send('updateSelected');
      },
      updateSelected: function updateSelected() {
        var selected = this.rows.filterBy('isSelected', true).map(function (s) {
          return s.get('content');
        });
        this.set('selected', selected);
      }
    }
  });

  _exports.default = _default;
});