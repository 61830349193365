define("lh4/components/error-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['error-modal'],
    actions: {
      reloadPage: function reloadPage() {
        window.location.reload(true);
      },
      goBack: function goBack() {
        window.history.back();
      },
      goToHome: function goToHome() {
        window.location.href = '/';
      }
    }
  });

  _exports.default = _default;
});