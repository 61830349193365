define("lh4/components/ui/table-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['ui selectable table'],
    currentPage: 1,
    pageSize: 20,
    noRecordsMessage: 'No records to display',
    totalPages: Ember.computed('collection.[]', 'pageSize', function getter() {
      var total = this.get('collection.length');
      return Math.ceil(total / this.get('pageSize'));
    }),
    collection: [],
    collectionPaged: Ember.computed('collection.[]', 'currentPage', 'pageSize', function getter() {
      if (!this.get('pageSize')) {
        return this.get('collection');
      }

      var from = (this.get('currentPage') - 1) * this.get('pageSize');
      return this.get('collection').toArray().splice(from, this.get('pageSize'));
    }),
    actions: {
      onPageChange: function onPageChange(pageNo) {
        this.set('currentPage', pageNo);
      }
    }
  });

  _exports.default = _default;
});