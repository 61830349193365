define("lh4/utils/increment-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = incrementName;

  /**
   * Transform Name adding '(1)' or increasing existing digit
   *
   * @param {string} name - current name
   * @returns {string} transformed name
   */
  function incrementName(name) {
    var number = 1;
    var stringEnding = name.match(/\(\d+\)$/);

    if (stringEnding !== null) {
      number = stringEnding[0].replace('(', '').replace(')', '');
      number = parseInt(number, 10) + 1;
      return name.replace("".concat(stringEnding[0]), "(".concat(number, ")"));
    }

    return "".concat(name, " (").concat(number, ")");
  }
});