define("lh4/templates/authenticated/internet-payments/insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0SFRdwJY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n\\tInsights\\n\"],[9],[0,\"\\n\"],[5,\"internet-payments/insights/insights-controls\",[],[[\"@maxDate\",\"@maxDays\",\"@ranges\",\"@startDate\",\"@endDate\",\"@selectedStatus\",\"@statusOptions\",\"@onDateRangeChanged\",\"@onStatusSelect\"],[[23,0,[\"maxDate\"]],[23,0,[\"maxDays\"]],[23,0,[\"ranges\"]],[23,0,[\"start\"]],[23,0,[\"end\"]],[28,\"or\",[[23,0,[\"router\",\"currentRoute\",\"queryParams\",\"acquirerResponseType\"]],\"all\"],null],[23,0,[\"statusOptions\"]],[28,\"fn\",[[23,0,[\"applyDateRange\"]]],null],[28,\"fn\",[[23,0,[\"applyStatus\"]]],null]]]],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"insightsData\",\"insightsSummaryAggregate\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"internet-payments/insights/insights-cards\",[],[[\"@summary\",\"@isUniqueByCustomer\"],[[23,0,[\"model\",\"insightsData\",\"insightsSummaryAggregate\"]],[23,0,[\"isUniqueByCustomer\"]]]]],[0,\"\\n\\t\"],[5,\"ui/tab-elements\",[[12,\"class\",\"standard\"]],[[\"@routes\"],[[23,0,[\"routeData\"]]]]],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui fluid\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/insights.hbs"
    }
  });

  _exports.default = _default;
});