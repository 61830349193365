define("lh4/routes/authenticated/epro/accounting/gratuities/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'gratuity',
    model: function model() {
      return Ember.RSVP.hash({
        gratuity: this.store.createRecord('echo-pro/gratuity', {
          locationId: this.location.model.id
        }),
        taxSets: this.modelFor('authenticated.epro.accounting.gratuities').taxSets,
        orderTypes: this.modelFor('authenticated.epro.accounting.gratuities').orderTypes
      });
    }
  });

  _exports.default = _default;
});