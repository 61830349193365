define("lh4/templates/components/table-layout/section-settings-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YRrtFafM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui middle aligned grid section-settings\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"one column row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"header first\"],[8],[0,\"\\n\\t\\t\\t\\tModify Section\\n\\t\\t\\t\\t\"],[1,[28,\"ui-checkbox\",null,[[\"class\",\"checked\",\"label\",\"onChange\"],[\"toggle\",[24,[\"section\",\"isActive\"]],\"Active\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"section\",\"isActive\"]]],null]],null]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"two column row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"six wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"h4\",true],[8],[0,\"Section Name\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui input\"],[8],[1,[28,\"input\",null,[[\"type\",\"class\",\"change\",\"value\"],[\"text\",\"table-name fluid\",[28,\"action\",[[23,0,[]],\"onChange\"],null],[24,[\"section\",\"name\"]]]]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/table-layout/section-settings-edit.hbs"
    }
  });

  _exports.default = _default;
});