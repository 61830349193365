define("lh4/templates/components/dashboard-widgets/deposits/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/fohi9nc",
    "block": "{\"symbols\":[\"@settings\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui form padded stackable\"],[8],[0,\"\\n\\t\"],[5,\"ui-checkbox\",[],[[\"@class\",\"@label\",\"@checked\",\"@onChange\"],[\"toggle\",\"Exclude Fees\",[23,1,[\"excludeFees\"]],[28,\"fn\",[[28,\"mut\",[[23,1,[\"excludeFees\"]]],null]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/deposits/settings.hbs"
    }
  });

  _exports.default = _default;
});