define("lh4/routes/authenticated/reputation/accounts/edit", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/services/social-media/facebook", "lh4/services/reputation/google-my-business", "lh4/services/reputation/yelp"], function (_exports, _authenticatedRouteMixin, _facebook, _googleMyBusiness, _yelp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    accountApi: Ember.inject.service('reputation/account-api'),
    queryParams: {
      showFields: {
        replace: true
      }
    },
    model: function model(_ref) {
      var _this = this;

      var accountId = _ref.accountId;

      var _this$modelFor = this.modelFor('authenticated.reputation'),
          locations = _this$modelFor.locations;

      return this.accountApi.findAccount(accountId).catch(function (err) {
        _this.errorHandler.handleResponseError(err, 'Could not load social media account');

        _this.transitionTo('authenticated.reputation.accounts');

        return {};
      }).then(function (response) {
        if (_this.accountApi.isFbPage(response.account.type)) {
          return Ember.RSVP.hash({
            locations: locations,
            usedLocations: _this.loadExcludedLocations(_facebook.ACCOUNT_TYPE_FB_PAGE),
            account: response.account,
            pages: _this.accountApi.getAccountPages(accountId).catch(function (err) {
              _this.errorHandler.handleResponseError(err, 'Could not load Facebook pages');

              return {};
            })
          });
        }

        if (_this.accountApi.isGoogleBusiness(response.account.type)) {
          return Ember.RSVP.hash({
            locations: locations,
            usedLocations: _this.loadExcludedLocations(_googleMyBusiness.ACCOUNT_TYPE_GOOGLE_BUSINESS),
            account: response.account,
            googleAccounts: _this.accountApi.getGoogleAccounts(accountId).catch(function (err) {
              _this.errorHandler.handleResponseError(err, 'Could not load Google accounts');

              return {};
            })
          });
        }

        return Ember.RSVP.hash({
          locations: locations,
          usedLocations: _this.loadExcludedLocations(_yelp.ACCOUNT_TYPE_YELP),
          account: response.account
        });
      });
    },
    loadExcludedLocations: function loadExcludedLocations(accountType) {
      var _this2 = this;

      return this.accountApi.getExcludedLocations(accountType).then(function (results) {
        return results.locations;
      }).catch(function (err) {
        _this2.errorHandler.handleResponseError(err);

        return [];
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isLoading', false);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }
  });

  _exports.default = _default;
});