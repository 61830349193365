define("lh4/templates/components/ui/copy-to-clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uw7YQZNM",
    "block": "{\"symbols\":[\"@value\",\"&default\",\"@position\"],\"statements\":[[7,\"span\",false],[12,\"class\",\"copy-to-clipboard\"],[12,\"data-test\",\"copy-to-clipboard\"],[3,\"did-insert\",[[23,0,[\"elementLoaded\"]]]],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"copy-value\"],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[0,\"\\t\"],[7,\"i\",false],[12,\"class\",[29,[\"clipboard-icon copy outline icon \",[23,3,[]]]]],[12,\"style\",[23,0,[\"clipboardIconOffset\"]]],[12,\"data-test\",\"clipboard-copy\"],[3,\"on\",[\"click\",[23,0,[\"copyToClipboard\"]]]],[8],[9],[0,\"\\n\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"confirm-copy\",[28,\"if\",[[23,0,[\"isVisible\"]],\" visible\"],null],\" \",[23,3,[]]]]],[10,\"data-test\",\"clipboard-confirm\"],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"check icon\"],[8],[9],[0,\"\\n\\t\\tCopied to clipboard\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/copy-to-clipboard.hbs"
    }
  });

  _exports.default = _default;
});