define("lh4/models/hospitality/printer", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    printerId: (0, _attr.default)('number')
  });

  _exports.default = _default;
});