define("lh4/components/amcharts3/sparkline-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNames: ['sparkline-chart'],
    options: {},
    init: function init() {
      this._super.apply(this, arguments);

      this.chart = null;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var _this$options = this.options,
          _this$options$backgro = _this$options.backgroundAlpha,
          backgroundAlpha = _this$options$backgro === void 0 ? 1 : _this$options$backgro,
          _this$options$backgro2 = _this$options.backgroundColor,
          backgroundColor = _this$options$backgro2 === void 0 ? '#ffffff' : _this$options$backgro2,
          categoryField = _this$options.categoryField,
          graph = _this$options.graph;
      var chart = window.AmCharts.makeChart(this.element, {
        type: 'serial',
        path: '/amcharts',
        dataProvider: this.data,
        hideCredits: true,
        backgroundAlpha: backgroundAlpha,
        backgroundColor: backgroundColor,
        // stretch the chart to the borders of the containers
        autoMargins: false,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
        chartCursor: {
          enabled: false
        },
        // x axis
        categoryField: categoryField,
        categoryAxis: {
          labelsEnabled: false,
          gridAlpha: 0,
          axisAlpha: 0,
          startOnAxis: true
        },
        // y axis
        valueAxes: [{
          labelsEnabled: false,
          gridAlpha: 0,
          axisAlpha: 0
        }],
        // sparkline will only support one graph on the chart
        graphs: [_objectSpread({
          type: 'smoothedLine',
          showBalloon: false,
          lineColor: '#136EF6',
          lineThickness: 1.5,
          bullet: 'none'
        }, graph)]
      });
      this.chart = chart;
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.chart) {
        this.chart.dataProvider = this.data;
        this.chart.validateData();
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.chart) {
        this.chart.clear();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});