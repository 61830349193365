define("lh4/templates/components/reputation/review-respond-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3IvmESbT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui form\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[1,[28,\"textarea\",null,[[\"value\",\"placeholder\",\"cols\",\"maxlength\",\"rows\"],[[24,[\"responseText\"]],\"Your comment\",\"10\",[24,[\"respondTextMaxLength\"]],\"8\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"review-respond-form-buttons\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui button left floated\",[28,\"unless\",[[24,[\"isTextValid\"]],\" disabled\"],null],[28,\"if\",[[24,[\"isLoading\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"discard\"]],[8],[0,\"\\n\\t\\t\\tDiscard\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui primary button right floated\",[28,\"unless\",[[24,[\"isTextValid\"]],\" disabled\"],null],[28,\"if\",[[24,[\"isLoading\"]],\" loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"sendResponse\"]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"respondButtonText\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reputation/review-respond-form.hbs"
    }
  });

  _exports.default = _default;
});