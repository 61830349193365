define("lh4/mirage/routes/corporate/menu-updates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageMenuUpdatesServer;

  function setMirageMenuUpdatesServer(server) {
    server.get('/corporations/:corpId/menu-updates', function (schema) {
      return {
        menuVersions: schema.eproMenuVersions.all().models,
        meta: {
          count: schema.eproMenuVersions.all().models.length
        }
      };
    });
    server.get('/corporations/:corpId/menu-updates/:guid', function (schema, request) {
      var menuVersionValue = schema.eproMenuVersions.where({
        guid: request.params.guid
      }).models[0];
      var id = menuVersionValue.id,
          guid = menuVersionValue.guid,
          corpId = menuVersionValue.corpId,
          name = menuVersionValue.name,
          scheduledFor = menuVersionValue.scheduledFor,
          createdAt = menuVersionValue.createdAt,
          updatedAt = menuVersionValue.updatedAt,
          status = menuVersionValue.status,
          isActive = menuVersionValue.isActive,
          notes = menuVersionValue.notes;
      return {
        id: id,
        guid: guid,
        corpId: corpId,
        name: name,
        scheduledFor: scheduledFor,
        createdAt: createdAt,
        updatedAt: updatedAt,
        status: status,
        isActive: isActive,
        notes: notes
      };
    });
  }
});