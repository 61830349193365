define("lh4/mirage/fixtures/internet-payments/insights/cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INSIGHTS_CARDS_FIXTURE = void 0;
  var INSIGHTS_CARDS_FIXTURE = {
    insightsSummaryAggregate: {
      authApproval: {
        response: {
          authorizationApprovalRate: 83.77,
          authorizationApprovalRateByUniqueCustomer: 84.58,
          authorizationApprovalAmount: 80072585.1,
          authorizationApprovalAmountByUniqueCustomer: 79164813.96,
          authorizationApprovalCount: 484626,
          authorizationApprovalCountByUniqueCustomer: 482382
        }
      },
      riskApproval: {
        response: {
          riskApprovalRate: 89.41,
          riskApprovalRateByUniqueCustomer: 89.41,
          riskApprovalAmount: 0,
          riskApprovalAmountByUniqueCustomer: 0,
          riskApprovalCount: 167255,
          riskApprovalCountByUniqueCustomer: 167255
        }
      },
      refunds: {
        response: {
          refundsRate: 0.89,
          refundsRateByUniqueCustomer: 0.95,
          refundsAmount: -1886547.66,
          refundsAmountByUniqueCustomer: -1886547.66,
          refundsCount: 6879,
          refundsCountByUniqueCustomer: 6879
        }
      },
      chargebackRate: {
        response: {
          chargebacksRate: 0.16,
          chargebacksRateByUniqueCustomer: 0.16,
          chargebacksAmount: 605056.35,
          chargebacksAmountByUniqueCustomer: 605056.35,
          chargebacksCount: 943,
          chargebacksCountByUniqueCustomer: 943
        }
      },
      cardVerify: {
        response: {
          cardVerifyRate: 88.1618,
          cardVerifyRateByUniqueCustomer: 88.1618,
          cardVerifyAmount: 64994121.1,
          cardVerifyAmountByUniqueCustomer: 64994121.1,
          cardVerifyCount: 138486,
          cardVerifyCountByUniqueCustomer: 138486
        }
      },
      insightsSummary: {
        response: {
          transactionApprovalRate: 80.82744449874161,
          transactionApprovalRateByUniqueCustomer: 81.57025212641832,
          totalAuthorizationRequestCount: 599581,
          totalAuthorizationRequestCountByUniqueCustomer: 591370,
          authorizationApprovalCount: 484626,
          authorizationApprovalCountByUniqueCustomer: 482382,
          authorizationDeclinedCount: 114955,
          authorizationDeclinedCountByUniqueCustomer: 108988
        }
      }
    }
  };
  _exports.INSIGHTS_CARDS_FIXTURE = INSIGHTS_CARDS_FIXTURE;
  var _default = INSIGHTS_CARDS_FIXTURE;
  _exports.default = _default;
});