define("lh4/routes/authenticated/locations/location/echo-pro/reservations/blocks", ["exports", "ember-onbeforeunload/mixins/confirmation", "lh4/mixins/authenticated-route-mixin"], function (_exports, _confirmation, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_confirmation.default, _authenticatedRouteMixin.default, {
    model: function model() {
      var _this = this;

      this.store.unloadAll('reservations/block');
      return Ember.RSVP.hash({
        block: this.store.query('reservations/block', {
          location: this.modelFor('authenticated.locations.location').id
        }).then(function () {
          return _this.store.peekAll('reservations/block');
        }),
        table: this.modelFor('authenticated.locations.location.echo-pro.reservations').table
      });
    },
    afterModel: function afterModel() {
      // make sure block isn't selected that has been deactivated since it wasn't saved
      this.controllerFor('authenticated.locations.location.echo-pro.reservations.blocks').set('currentBlock', null);
    }
  });

  _exports.default = _default;
});