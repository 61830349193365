define("lh4/components/epro/payments/tender-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ActiveLockedNames = ['Cash', 'Credit', 'House Account'];
  var ChangeLockedNames = ['Cash', 'Credit', 'Gift Card', 'House Account', 'Debit'];
  var TipLockedNames = ['Cash', 'Credit', 'Gift Card', 'Debit'];
  var PromptLockedNames = ['Credit', 'Debit'];
  var StandardNames = ['Credit', 'Gift Card', 'House Account', 'Debit'];

  var _default = Ember.Component.extend({
    corporation: Ember.inject.service(),
    intl: Ember.inject.service(),
    location: Ember.inject.service(),
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.referenceOptions = [{
        name: this.intl.t('components:epro:payments:tender-form:reference-options:no-reference-required'),
        value: 1
      }, {
        name: this.intl.t('components:epro:payments:tender-form:reference-options:requires-numeric-reference'),
        value: 2
      }, {
        name: this.intl.t('components:epro:payments:tender-form:reference-options:alphanumeric-reference'),
        value: 3
      }];
    },
    isCustomerRequiredDisabled: Ember.computed('tender.name', 'tender.isLocked', function getter() {
      return this.tender.get('name') === 'House Account' && this.tender.get('isLocked');
    }),
    isReferenceDisabled: Ember.computed('tender.name', 'tender.isLocked', function getter() {
      return this.tender.get('name') === 'Cash' && this.tender.get('isLocked');
    }),
    isOpenDrawerDisabled: Ember.computed('tender.name', 'tender.isLocked', function getter() {
      return this.tender.get('name') === 'Cash' && this.tender.get('isLocked');
    }),
    typeAllowsChange: Ember.computed('tender.name', 'tender.isLocked', function getter() {
      var isLockedName = ChangeLockedNames.includes(this.tender.get('name'));
      return this.tender.get('isLocked') ? !isLockedName : true;
    }),
    isTipAllowedDisabled: Ember.computed('tender.name', 'tender.isLocked', function getter() {
      return TipLockedNames.includes(this.tender.get('name')) && this.tender.get('isLocked');
    }),
    isActiveDisabled: Ember.computed('tender.name', 'tender.isLocked', function getter() {
      return ActiveLockedNames.includes(this.tender.get('name')) && this.tender.get('isLocked');
    }),
    promptForTipOnDeviceDisabled: Ember.computed('tender.name', 'locationSettings.business.content.finalizeCreditCardPaymentsImmediatelyWithNoTip', function getter() {
      if (PromptLockedNames.includes(this.tender.get('name'))) {
        return this.locationSettings.business.content.finalizeCreditCardPaymentsImmediatelyWithNoTip === '1';
      }

      return true;
    }),
    postsToPmsEnabled: Ember.computed('tender.name', 'isReferenceDisabled', 'corporation.isMenuManagedByCorporate', function getter() {
      return StandardNames.includes(this.tender.get('name')) && !this.isReferenceDisabled;
    }),
    isAllowsChangeEnabled: Ember.computed('typeAllowsChange', 'tender.opensDrawer', 'tender.postsToPms', function getter() {
      return this.typeAllowsChange && this.tender.get('opensDrawer') && !this.tender.get('postsToPms');
    }),
    isAllowManualEntryViewable: Ember.computed('tender.name', 'locationSettings.processing.content.name', 'terminalHasOnboardReader', function getter() {
      return this.tender.get('name') === 'Credit' && this.locationSettings.processing.content.name === 'shift4' && this.terminalHasOnboardReader;
    }),
    actions: {
      toggleOpensDrawer: function toggleOpensDrawer(checked) {
        if (!checked) {
          this.tender.set('allowsChange', false);
        }

        this.tender.set('opensDrawer', checked);
      },
      togglePostsToPms: function togglePostsToPms(checked) {
        if (checked) {
          this.tender.set('allowsChange', false);
        }

        this.tender.set('postsToPms', checked);
      }
    }
  });

  _exports.default = _default;
});