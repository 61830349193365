define("lh4/controllers/authenticated/epro/accounting/taxes", ["exports", "lh4/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
      this.headers = [{
        name: this.intl.t('controllers:authenticated:epro:accounting:taxes:headers:name'),
        field: 'name',
        width: 'five',
        sortable: true
      }, {
        name: this.intl.t('controllers:authenticated:epro:accounting:taxes:headers:amount'),
        field: 'value',
        width: 'five',
        aligned: 'right',
        sortable: true,
        type: 'number'
      }, {
        name: this.intl.t('controllers:authenticated:epro:accounting:taxes:headers:status'),
        field: 'active',
        width: 'six',
        sortable: true,
        type: 'active'
      }];
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super((_this$location = this.location) === null || _this$location === void 0 ? void 0 : (_this$location$model = _this$location.model) === null || _this$location$model === void 0 ? void 0 : _this$location$model.id);
    })
  });

  _exports.default = _default;
});