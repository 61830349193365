define("lh4/utils/reports-handle-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = handleError;

  /**
   * Handle an error thrown by reports api request
   *
   * @param {Error} error - error instance
   */
  function handleError(_ref) {
    var _payload$error;

    var payload = _ref.payload;
    throw new Error(JSON.stringify({
      header: 'Error generating report!',
      message: payload === null || payload === void 0 ? void 0 : (_payload$error = payload.error) === null || _payload$error === void 0 ? void 0 : _payload$error.message,
      details: payload === null || payload === void 0 ? void 0 : payload.details
    }));
  }
});