define("lh4/templates/components/ui/day-part-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MVIBeDcU",
    "block": "{\"symbols\":[\"intradayPeriodGroupOption\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"field \",[28,\"if\",[[24,[\"displayErrors\"]],\"error\"],null]]]],[10,\"data-test\",\"day-part-select\"],[8],[0,\"\\n\\t\"],[7,\"label\",true],[8],[0,\"Select a Day Part\"],[9],[0,\"\\n\"],[4,\"ui-dropdown\",null,[[\"class\",\"forceSelection\",\"fullTextSearch\",\"selected\",\"onChange\"],[\"fluid search selection report-select\",false,\"exact\",[24,[\"reportTypeSelected\"]],[24,[\"onChange\"]]]],{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"default text\"],[8],[0,\"Select a Day Part\"],[9],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"intradayPeriodGroupOptions\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"item\"],[11,\"data-value\",[29,[[23,1,[\"intradayPeriodGroupName\"]]]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[23,1,[\"intradayPeriodGroupName\"]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/day-part-select.hbs"
    }
  });

  _exports.default = _default;
});