define("lh4/templates/components/ui/icons/sidebar/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SUozh1r6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M6.99098 11L3.00098 15L6.99098 19V16H14.001V14H6.99098V11ZM21.001 9L17.011 5V8H10.001V10H17.011V13L21.001 9Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/transactions.hbs"
    }
  });

  _exports.default = _default;
});