define("lh4/mirage/routes/gpatt", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageGpattServer;

  function setMirageGpattServer(server) {
    server.get('gpatt/settings/location/:locationId', function (_ref) {
      var gpattSettings = _ref.gpattSettings;
      return {
        settings: gpattSettings.all().models
      };
    });
    server.put('gpatt/settings/location/:locationId', function (schema, request) {
      var bodyJson = JSON.parse(request.requestBody);
      var tip1 = bodyJson.settings['tip-1'];

      if (tip1 > 100 || tip1 < 0) {
        return new _emberCliMirage.Response(400, {}, {
          error: {
            code: 400,
            message: 'invalid parameters',
            details: "".concat(tip1, " must be an integer between 0 and 100")
          }
        });
      }

      return bodyJson;
    });
  }
});