define("lh4/mirage/fixtures/location-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Array.from('x'.repeat(10)).map(function (group, index) {
    return {
      guid: "".concat(index),
      createdAt: '2024-08-28T10:38:13.703Z',
      updatedAt: '2024-08-28T10:38:13.703Z',
      locationGroupName: "Location Group ".concat(index),
      isActive: index % 2 === 0,
      createdUserId: 1,
      updatedUserId: 1,
      createdUserEmail: 'fake@example.com',
      updatedUserEmail: 'fake@example.com',
      createdUserName: 'Fakey McFakerson',
      updatedUserName: 'Fakey McFakerson',
      locationGroupItems: [{
        createdAt: '2024-08-28T10:38:13.703Z',
        updatedAt: '2024-08-28T10:38:13.703Z',
        locationId: 1,
        locationGroupGuid: "".concat(index),
        ordinal: 0,
        userId: 1,
        locationName: 'Test Location 2',
        merchantId: '0',
        shift4Serial: '0',
        shift4Mid: '0',
        emailAddress: 'fake@example.com'
      }, {
        createdAt: '2024-08-28T10:38:13.703Z',
        updatedAt: '2024-08-28T10:38:13.703Z',
        locationId: 4,
        locationGroupGuid: "".concat(index),
        ordinal: 1,
        userId: 1,
        locationName: 'Test Location 1',
        merchantId: '0',
        shift4Serial: '0',
        shift4Mid: '0',
        emailAddress: 'fake@example.com'
      }]
    };
  });

  _exports.default = _default;
});