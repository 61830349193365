define("lh4/components/logo-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    permissions: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('logoUrl', this.get('location.logoUrl'));
    },
    cropperOptions: {
      aspectRatio: 2 / 1
    },
    actions: {
      logoEdit: function logoEdit(_ref) {
        var _this = this;

        var fullBlob = _ref.fullBlob;
        var locationId = this.get('location.id');
        var formData = new FormData();
        formData.append('logo', fullBlob, "".concat(locationId, "-logo.png"));
        return this.ajax.request("/api/v1/locations/".concat(locationId, "/logo"), {
          method: 'POST',
          data: formData,
          contentType: false,
          processData: false
        }).then(function (response) {
          _this.set('logoUrl', response.url);

          _this.set('cacheBust', Date.now());

          _this.flashMessagesHelper.pushMessage('Logo successfully updated', 'success');
        }).catch(function () {
          _this.flashMessagesHelper.pushMessage('There was an unexpected error saving your new logo', 'error');

          return Ember.RSVP.Promise.reject();
        });
      },
      removeImageModal: function removeImageModal() {
        this.$('.ui.modal.remove-image').modal('show');
      },
      removeImage: function removeImage() {
        var _this2 = this;

        this.ajax.delete("/api/v1/locations/".concat(this.get('location.id'), "/logo")).then(function () {
          _this2.set('logoUrl', null);

          _this2.flashMessagesHelper.pushMessage('Logo successfully removed', 'success');
        }).catch(function () {
          _this2.flashMessagesHelper.pushMessage('There was an unexpected error removing your logo', 'error');
        });
      }
    }
  });

  _exports.default = _default;
});