define("lh4/components/report-box", ["exports", "lh4/components/reporting", "lh4/utils/date-moment", "moment"], function (_exports, _reporting, _dateMoment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_GATEWAY_EOD = '12:00 AM'; // Default gateway EOD

  var _default = _reporting.default.extend({
    launchDarkly: Ember.inject.service('launch-darkly'),
    store: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    reportPeriod: Ember.computed('enabledProd', 'selectedLocations.[]', function getter() {
      var _this = this;

      var excludeYearRange = this.enabledProd === 'echo' && this.selectedLocations.length > 1;
      var eod = this.getGatewayEndOfTime(this.selectedLocations, this.primaryLocation, this.eodSettings);
      var settings = {
        eod: eod,
        weekStart: this.reportsStartOfWeek
      };
      var dateRangeOptions = excludeYearRange ? (0, _dateMoment.getReportingDateRangeOptionsWithoutYears)(settings) : (0, _dateMoment.getReportingDateRangeOptionsDefault)(settings);

      if (excludeYearRange) {
        if (this.reportPeriodSelected && dateRangeOptions.indexOf(this.reportPeriodSelected) === -1) {
          // without this run.next I get double modify in a single render errors
          Ember.run.next(this, function () {
            _this.selectReportPeriod(dateRangeOptions[dateRangeOptions.length - 1]);
          });
        }
      }

      return dateRangeOptions;
    }),
    reportWithoutEndTime: Ember.computed('reportTypeSelected', function hasReportTimePicker() {
      var _params$start, _params$end;

      var params = this.get('selectedReport.params');

      if (!params) {
        return false;
      }

      return !((_params$start = params.start) !== null && _params$start !== void 0 && _params$start.time) && !((_params$end = params.end) !== null && _params$end !== void 0 && _params$end.time);
    }),
    dateDisabled: Ember.computed('reportTypeSelected', function getter() {
      var params = this.get('selectedReport.params') || {};
      return !params.start && !params.end;
    }),
    subscribeDisabled: Ember.computed('reportTypeSelected', function getter() {
      return !this.get('selectedReport.subscribable');
    }),
    defaultStartTime: Ember.computed('selectedLocations.[]', 'eodSettings', 'primaryLocation', function getter() {
      var eod = this.getGatewayEndOfTime(this.selectedLocations, this.primaryLocation, this.eodSettings);
      var parsedTime = (0, _moment.default)(eod, 'hh:mm A');
      return (0, _moment.default)().startOf('day').hour(parsedTime.hour()).minute(parsedTime.minute());
    }),
    getGatewayEndOfTime: function getGatewayEndOfTime(selectedLocations, primaryLocation, eodSettings) {
      try {
        var eodLocation = selectedLocations.includes(primaryLocation.toString()) ? primaryLocation : parseInt(selectedLocations[0], 10);

        if (eodSettings[eodLocation]) {
          return eodSettings[eodLocation];
        }

        return DEFAULT_GATEWAY_EOD;
      } catch (e) {
        var _this$store$peekRecor = this.store.peekRecord('preferences', this.tokenData.data.sub),
            eod = _this$store$peekRecor.eod;

        return eod;
      }
    },
    actions: {
      generateReport: function generateReport() {
        var _this2 = this;

        this.set('displayErrors', true);
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this2.set('displayErrors', false);

            _this2.generateReport();
          }
        });
      }
    }
  });

  _exports.default = _default;
});