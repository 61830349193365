define("lh4/controllers/authenticated/customer-engagement/campaigns/overview/details", ["exports", "jquery", "lh4/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    campaignApi: Ember.inject.service('customer-engagement/campaign-api'),
    isLoading: false,
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    showCompleteButton: Ember.computed('model.campaign.status', function getter() {
      return this.get('model.campaign.status') !== 'Complete' && this.get('model.campaign.status') !== 'Draft';
    }),
    iframeSrc: Ember.computed('tokenData.data.token', 'model.campaign.{locationId,type,headerText,bodyText,backgroundColor,twitterUrl,facebookUrl}', function getter() {
      var url = "".concat(_environment.default.host, "/api/v1/customer-engagement/campaign-templates/preview");

      var qs = _jquery.default.param({
        token: this.get('tokenData.data.token'),
        locationId: this.get('model.campaign.locationId'),
        type: this.get('model.campaign.type'),
        headerText: this.get('model.campaign.headerText'),
        bodyText: this.get('model.campaign.bodyText'),
        backgroundColor: this.get('model.campaign.backgroundColor'),
        twitterUrl: this.get('model.campaign.twitterUrl'),
        facebookUrl: this.get('model.campaign.facebookUrl')
      });

      return "".concat(url, "?").concat(qs);
    }),
    actions: {
      deleteCampaignModal: function deleteCampaignModal() {
        (0, _jquery.default)('.delete-campaign').modal('show');
      },
      deleteCampaign: function deleteCampaign(campaignId) {
        var _this = this;

        this.set('isLoading', true);
        this.campaignApi.deleteCampaign(campaignId).then(function () {
          _this.flashMessagesHelper.pushMessage('Campaign deleted successfully', 'success');

          Ember.run.later(function () {
            _this.transitionToRoute('authenticated.customer-engagement');
          }, 1000);
        }).catch(function (err) {
          _this.errorHandler.handleResponseError(err);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      duplicateCampaign: function duplicateCampaign(campaignId) {
        this.transitionToRoute('authenticated.customer-engagement.campaigns.create', {
          queryParams: {
            clone: campaignId
          }
        });
      },
      completeCampaign: function completeCampaign() {
        var _this2 = this;

        var campaign = this.get('model.campaign');
        campaign.set('status', 'Complete');
        campaign.save().then(function () {
          _this2.flashMessagesHelper.pushMessage('Campaign completed', 'success');

          Ember.run.later(function () {
            _this2.transitionToRoute('authenticated.customer-engagement');
          }, 1000);
        });
      }
    }
  });

  _exports.default = _default;
});