define("lh4/controllers/authenticated/epro/menu/order-types/edit", ["exports", "lh4/controllers/general-form-controller", "jquery"], function (_exports, _generalFormController, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    shouldCloseTray: false,
    isAnyAvailabilityOverlapping: false,
    validateAndSaveChangesets: function validateAndSaveChangesets(changesets, key) {
      var _this = this;

      return changesets.map(function (record) {
        return record.validate().then(function () {
          if (!record.isValid) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.set('orderType', _this.model.orderType); // record.save was throwing because models don't have a save endpoint

          record.execute();
          record.rollback();

          _this.model.orderType.get(key).pushObject(record.data);

          return true;
        }).catch(function () {
          _this.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (_ref) {
        var id = _ref.id;
        return !changeset.findBy('id', id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    duplicateOrderType: function duplicateOrderType(clone) {
      this.transitionToRoute('authenticated.epro.menu.order-types.create', {
        queryParams: {
          clone: clone
        }
      });
      (0, _jquery.default)('.fullscreen-modal > .content').animate({
        scrollTop: 0
      }, 'fast');
    },
    trackUpdateCompleted: function trackUpdateCompleted() {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Completed',
        'Entry Type': 'Edit',
        'Entry ID': this.changeset.get('id'),
        'Number of Updates': Object.keys(this.changeset.change).length,
        'Entries Updated': Object.keys(this.changeset.change),
        'Menu Component': 'Order Types'
      });
    },
    actions: {
      close: function close() {
        this.set('shouldCloseTray', false);
        this.send('setAvailabilityValidation', false);
        this.transitionToRoute('authenticated.epro.menu.order-types');
      },
      update: function update() {
        var _this2 = this;

        var cloning = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError(); // we have to check for pristine before we validate and save

        var isPristine = this.isModelAndChangesetPristine();
        this.changeset.validate().then(function () {
          if (_this2.changeset.isInvalid || _this2.areChildrenInvalid) {
            return Ember.RSVP.reject(_this2.changeset.errors);
          }

          _this2.trackUpdateCompleted();

          if (isPristine) {
            _this2.clearTemporaryData();

            if (cloning) {
              _this2.duplicateOrderType(_this2.changeset.get('id'));
            } else {
              _this2.closeModal();
            }

            return Ember.RSVP.resolve();
          }

          var snapshot = _this2.changeset.snapshot();

          return _this2.changeset.save().then(function (data) {
            _this2.clearTemporaryData();

            if (cloning) {
              _this2.duplicateOrderType(data.id);
            } else {
              _this2.closeModal();
            }
          }).catch(function (_ref2) {
            var errors = _ref2.errors;

            if (!(errors && errors[0])) {
              return;
            }

            _this2.changeset.restore(snapshot);

            _this2.changeset.data.rollbackAttributes(); // TODO normalize how we handle these error messages


            var updateError = "Something went wrong, ".concat(_this2.instanceName, " could not be updated.");
            errors.forEach(function (error) {
              var errorMessage = error.details && error.details[0] || error.message || updateError;

              _this2.set('serverError', errorMessage);

              _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                _this2.set('serverError', null);
              }, _this2.serverErrorTimeoutDelay));
            });
          });
        }).catch(function (errors) {
          // We currently don't have a field component for checkboxes
          // Because of this there is no standard way of displaying
          // error messages for boolean fields
          // https://jira.shift4payments.com/browse/LH-8853
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this2.set('booleanError', booleanError.validation);

              _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                _this2.set('booleanError', null);
              }, _this2.serverErrorTimeoutDelay));
            }
          }
        }).finally(function () {
          return _this2.set('isSaving', false);
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      updateAndDuplicate: function updateAndDuplicate() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.send.apply(this, ['update', true].concat(args));
      },
      setAvailabilityValidation: function setAvailabilityValidation(isAnyAvailabilityOverlapping) {
        this.set('isAnyAvailabilityOverlapping', isAnyAvailabilityOverlapping);
      }
    }
  });

  _exports.default = _default;
});