define("lh4/templates/components/internet-payments/insights/insights-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cR8c25NY",
    "block": "{\"symbols\":[\"@error\",\"@rate\",\"@amount\",\"@count\",\"@title\"],\"statements\":[[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@dataTestId\"],[[23,5,[]],\"insights-card\"]],{\"statements\":[[0,\"\\n\"],[4,\"unless\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"percent\"],[10,\"data-test\",\"insights-card-percent\"],[8],[1,[28,\"round-decimal-no-zeros\",[[23,2,[]]],null],false],[0,\"%\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"card-row\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"amount\"],[10,\"data-test\",\"insights-card-amount\"],[8],[1,[28,\"usd-number-format\",[[23,3,[]]],null],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"count\"],[10,\"data-test\",\"insights-card-count\"],[8],[1,[28,\"short-number\",[[23,4,[]]],null],false],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/insights/insights-card.hbs"
    }
  });

  _exports.default = _default;
});