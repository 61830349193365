define("lh4/templates/components/ui/icons/s4/arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8HQFvhA+",
    "block": "{\"symbols\":[\"@class\"],\"statements\":[[7,\"svg\",true],[11,\"class\",[23,1,[]]],[10,\"width\",\"10\"],[10,\"height\",\"5\"],[10,\"viewBox\",\"0 0 10 5\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M0 5L5 0L10 5H0Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/s4/arrow.hbs"
    }
  });

  _exports.default = _default;
});