define("lh4/templates/components/internet-payments/info-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JuN8MxEX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"info-tile\"],[11,\"data-test-ip\",[29,[[23,0,[\"args\",\"dataTestId\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"args\",\"riskStatus\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[4,\"internet-payments/risk/risk-block-list-icon-tooltip\",null,[[\"dataName\",\"dataStatus\"],[[23,0,[\"args\",\"riskName\"]],[23,0,[\"args\",\"riskStatus\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[5,\"internet-payments/color-dot-label\",[],[[\"@label\",\"@value\",\"@reverse\"],[[23,0,[\"args\",\"label\"]],[23,0,[\"args\",\"color\"]],\"reverse\"]]],[0,\"\\n\\t\"],[1,[23,0,[\"args\",\"value\"]],false],[0,\"\\n\\t\"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/info-tile.hbs"
    }
  });

  _exports.default = _default;
});