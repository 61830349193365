define("lh4/templates/components/ui/icons/sidebar/shopping-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q0tVUa/p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M5.00088 21C4.71754 21 4.45921 20.9127 4.22588 20.738C3.99254 20.5627 3.83421 20.3333 3.75088 20.05L0.950878 9.95C0.867544 9.71667 0.904878 9.5 1.06288 9.3C1.22154 9.1 1.43421 9 1.70088 9H6.75088L11.1509 2.45C11.2342 2.31667 11.3509 2.20833 11.5009 2.125C11.6509 2.04167 11.8092 2 11.9759 2C12.1425 2 12.3009 2.04167 12.4509 2.125C12.6009 2.20833 12.7175 2.31667 12.8009 2.45L17.2009 9H22.3009C22.5675 9 22.7802 9.1 22.9389 9.3C23.0969 9.5 23.1342 9.71667 23.0509 9.95L20.2509 20.05C20.1675 20.3333 20.0092 20.5627 19.7759 20.738C19.5425 20.9127 19.2842 21 19.0009 21H5.00088ZM9.17588 9H14.8009L11.9759 4.8L9.17588 9ZM12.0009 17C12.5509 17 13.0219 16.8043 13.4139 16.413C13.8052 16.021 14.0009 15.55 14.0009 15C14.0009 14.45 13.8052 13.979 13.4139 13.587C13.0219 13.1957 12.5509 13 12.0009 13C11.4509 13 10.9802 13.1957 10.5889 13.587C10.1969 13.979 10.0009 14.45 10.0009 15C10.0009 15.55 10.1969 16.021 10.5889 16.413C10.9802 16.8043 11.4509 17 12.0009 17ZM5.50088 19H18.5009L20.7009 11H3.30088L5.50088 19Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/shopping-basket.hbs"
    }
  });

  _exports.default = _default;
});