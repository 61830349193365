define("lh4/routes/authenticated/virtual-terminal", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    location: Ember.inject.service(),
    permissions: Ember.inject.service(),
    model: function model() {
      if (this.location.model.usesShift4PaymentProcessor) {
        var locationId = this.location.model.id;
        var origin = '';

        if (!this.session.isSSO) {
          origin = this.ajax.request('/api/v1/virtual-terminal/origin', {
            data: {
              locationId: locationId
            }
          }).then(function (response) {
            return response.origin;
          });
        }

        return Ember.RSVP.hash({
          origin: origin,
          refundSettings: this.permissions.isOwner && _environment.default.featureFlags.vtRefundOptOut ? this.store.findRecord('virtual-terminal/refund-settings', locationId) : {}
        });
      }

      return this.transitionTo('/');
    }
  });

  _exports.default = _default;
});