define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/blocks", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns only valid blocks
   * @param {Promise<Ember.Model>} blockModel - whole block model
   * @returns {Promise<Array<Object>>} - valid blocks
   */
  function validatedBlocks(blockModel) {
    var blocks = blockModel.filter(function (block) {
      return block.get('hasDirtyAttributes');
    }).map(function (block) {
      return block.validate();
    });
    return Ember.RSVP.all(blocks);
  }

  var _default = Ember.Controller.extend({
    isSaving: false,
    tabs: _moment.default.weekdays(),
    actions: {
      setDay: function setDay(day) {
        this.set('currentBlock', null);
        this.set('currentDay', day);
      },
      setBlock: function setBlock(block) {
        this.set('currentBlock', block);
      },
      saveChanges: function saveChanges() {
        var _this = this;

        this.flashMessages.clearMessages();
        this.set('isSaving', true);
        validatedBlocks(this.model.block).then(function (blocks) {
          if (!blocks.length) {
            _this.set('isSaving', false);

            return;
          }

          var isAllBlocksValid = blocks.every(function (_ref) {
            var validations = _ref.validations;
            return validations.get('isValid');
          });

          if (isAllBlocksValid) {
            Ember.RSVP.all(blocks.map(function (_ref2) {
              var model = _ref2.model;
              return model.save();
            })).then(function () {
              _this.flashMessages.success('Block saved successfully', {
                class: 'ui success message'
              });
            }).catch(function () {
              _this.flashMessages.danger('There was an error when saving your block', {
                class: 'ui error message'
              });
            }).finally(function () {
              return _this.set('isSaving', false);
            });
          } else {
            blocks.forEach(function (_ref3) {
              var validations = _ref3.validations;

              if (validations.get('message')) {
                _this.flashMessages.danger("".concat(validations.get('message'), "."), {
                  class: 'ui error message'
                });
              }
            });

            _this.set('isSaving', false);
          }
        });
      },
      discardChanges: function discardChanges() {
        this.model.block.forEach(function (block) {
          return block.rollbackAttributes();
        });
        this.set('currentBlock', null);
      }
    }
  });

  _exports.default = _default;
});