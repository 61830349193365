define("lh4/mirage/factories/hospitality-job", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: function name(i) {
      return "Test Job ".concat(i);
    },
    description: '',
    basePay: 0,
    isActive: true,
    isBlind: false,
    ordinal: null,
    areaSetId: 0,
    screenCategorySetId: 0,
    closesCashAutomatically: false,
    allowsFastPay: true,
    overrideAutoLogout: true,
    isDishwasher: false,
    overtimePayRate: 10.00,
    discountSetId: 0,
    groupId: null,
    lastClosedMode: 'open',
    allTicketsBehavior: 'cashier',
    hidePreauths: true,
    payrollDeptId: null,
    role: '',
    isBartender: false,
    canClockout: false,
    allowDelivery: false,
    canTransferByBarcode: false,
    printsPacketOnTransfer: false,
    restrictsAllTicketsSection: false,
    defaultSection: 0,
    revenueCenterId: 0,
    doesNotDeclareTips: false,
    seeAllTickets: false,
    allowHold: false,
    allowDiscountedHold: 1,
    rvJobs: [],
    rowguid: function rowguid() {
      return _faker.default.datatype.uuid();
    },
    isPaidHourly: true,
    useStatus: false,
    useHost: false,
    jobCode1: '',
    jobCode2: '',
    departmentCode: '',
    jobId: 1
  });

  _exports.default = _default;
});