define("lh4/abilities/day-part", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service(),
    canViewDayParts: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.DAY_PARTS_VISIBILITY] && this.hasLocationPermission(_permissions.default.BUSINESS_SETTINGS.MANAGE_DAY_PARTS_AND_REVENUE_CENTERS) && this.hasLocationPermission(_permissions.default.HBR.MENU) && this.hasProduct('echo-pro');
    })
  });

  _exports.default = _default;
});