define("lh4/utils/format-to-usa-phone-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatToUsaPhoneNumber;

  /**
   * Format string input to USA phone number. Examples:
   * - 01234567890 => (012) 345 - 6789
   * - 123 => (123
   * - 1234 => (123) 4
   * @param {string} value - input value
   * @returns {string} formatted USA phone number
   */
  function formatToUsaPhoneNumber(value) {
    var input = value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only

    var areaCode = input.substring(0, 3);
    var middle = input.substring(3, 6);
    var last = input.substring(6, 10);
    var phoneNumber = '';

    if (input.length > 6) {
      phoneNumber = "(".concat(areaCode, ") ").concat(middle, " - ").concat(last);
    } else if (input.length > 3) {
      phoneNumber = "(".concat(areaCode, ") ").concat(middle);
    } else if (input.length > 0) {
      phoneNumber = "(".concat(areaCode);
    }

    return phoneNumber;
  }
});