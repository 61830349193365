define("lh4/validators/starts-at-is-before-ends-at", ["exports", "ember-cp-validations/validators/base", "moment"], function (_exports, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var timeFormat = 'hh:mmA';

  var StartsAtIsBeforeEndsAt = _base.default.extend({
    validate: function validate(value, options, model) {
      var startsAt = (0, _moment.default)(model.get('startsAt'), timeFormat);
      var endsAt = (0, _moment.default)(value, timeFormat);

      if (value === (0, _moment.default)().startOf('day').format(timeFormat)) {
        endsAt = (0, _moment.default)(value, timeFormat).endOf('day');
      }

      return startsAt.isBefore(endsAt) ? true : "Start time ".concat(model.get('startsAt'), " cannot be after end time ").concat(endsAt.format(timeFormat));
    }
  });

  StartsAtIsBeforeEndsAt.reopenClass({
    getDependentsFor: function getDependentsFor() {
      return ['model.startsAt'];
    }
  });
  var _default = StartsAtIsBeforeEndsAt;
  _exports.default = _default;
});