define("lh4/templates/authenticated/internet-payments/developers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dJiHugRU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"shouldRender\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"h1\",true],[10,\"class\",\"ui header\"],[8],[0,\"\\n\\t\\tDevelopers\\n\\t\"],[9],[0,\"\\n\\t\"],[5,\"ui/tab-elements\",[[12,\"class\",\"standard\"]],[[\"@routes\"],[[23,0,[\"routeData\"]]]]],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/developers.hbs"
    }
  });

  _exports.default = _default;
});