define("lh4/utils/launch-control/ooc-image-resize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resizeImage = resizeImage;
  _exports.loadFileAsDataURL = loadFileAsDataURL;

  /**
   * Logic taken from market place for resizing an image for online ordering logo
   */

  /**
   * @function
   * @description Converts html canvas to a blob of specified type
   * @param {HTMLCanvasElement} canvas html canvas
   * @param {string} type mime type
   * @returns {Promise<Blob>}
   */
  function getCanvasBlob(canvas, type) {
    return new Promise(function (resolve) {
      canvas.toBlob(function (blob) {
        resolve(blob);
      }, type);
    });
  }
  /**
   * @function
   * @description Converts html canvas to a blob of specified type
   * @param {CanvasRenderingContext2D} ctx
   * @param {HTMLImageElement} img
   * @param {number} x
   * @param {number} y
   * @param {number} w
   * @param {number} h
   * @param offsetX = 0.5,
   * @param offsetY numerb defauts to0.5
   * @param {string} type mime type
   * @returns {Promise<Blob>}
   */


  function drawImageProp(ctx, img, x, y, w, h) {
    var initOffsetX = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 0.5;
    var initOffsetY = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : 0.5;
    var offsetX = initOffsetX;
    var offsetY = initOffsetY; // keep bounds [0.0, 1.0]

    if (offsetX < 0) {
      offsetX = 0;
    }

    if (offsetY < 0) {
      offsetY = 0;
    }

    if (offsetX > 1) {
      offsetX = 1;
    }

    if (offsetY > 1) {
      offsetY = 1;
    }

    var iw = img.width;
    var ih = img.height;
    var r = Math.min(w / iw, h / ih);
    var nw = iw * r; // new prop. width

    var nh = ih * r; // new prop. height

    var cx;
    var cy;
    var cw;
    var ch;
    var ar = 1; // decide which gap to fill

    if (nw < w) {
      ar = w / nw;
    }

    if (Math.abs(ar - 1) < 1e-14 && nh < h) {
      ar = h / nh;
    }

    nw *= ar;
    nh *= ar; // calc source rectangle

    cw = iw / (nw / w);
    ch = ih / (nh / h);
    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY; // make sure source rectangle is valid

    if (cx < 0) {
      cx = 0;
    }

    if (cy < 0) {
      cy = 0;
    }

    if (cw > iw) {
      cw = iw;
    }

    if (ch > ih) {
      ch = ih;
    } // fill image in dest. rectangle


    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
  }
  /**
   * Retrieves image data uri selected by user and resize it to the IMAGE_SIZE sizes ex: (500px*250px)
   * stretch image width to the 500px and crop part of the image if aspect ratio is not 2x1
   *
   * @param {string} base64Str
   * @param {IImageDimensions} requiredDimensions
   * @param {string} type - output file mime type
   * @returns {Promise<Blob>}
   */


  function resizeImage(base64Str, requiredDimensions, initType) {
    var type = initType;

    if (!type) {
      type = base64Str.substring('data:'.length, base64Str.indexOf(';base64'));
    }

    return new Promise(function (resolve) {
      var img = new Image();
      img.src = base64Str;

      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        ctx.canvas.width = requiredDimensions.WIDTH;
        ctx.canvas.height = requiredDimensions.HEIGHT;
        drawImageProp(ctx, img, 0, 0, requiredDimensions.WIDTH, requiredDimensions.HEIGHT);
        resolve(getCanvasBlob(canvas, type));
      };
    });
  }

  function loadFileAsDataURL(file) {
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (event) {
        resolve(event);
      };

      reader.onerror = reject;
    });
  }
});