define("lh4/services/reports/product-availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    loadAdditionalProducts: function loadAdditionalProducts(selectedLocations) {
      if (!selectedLocations || !selectedLocations.length) {
        return Ember.RSVP.resolve([]);
      }

      var getGpattProduct = this.ajax.request('/api/v1/reports/products/gpatt', {
        method: 'POST',
        data: {
          locations: selectedLocations
        }
      });
      var getSOLOProduct = this.ajax.request('/api/v1/reports/products/solo', {
        method: 'POST',
        data: {
          locations: selectedLocations
        }
      });
      var getLoyaltyProduct = this.ajax.request('/api/v1/reports/products/loyalty', {
        method: 'POST',
        data: {
          locations: selectedLocations
        }
      });
      return Ember.RSVP.all([getGpattProduct, getLoyaltyProduct, getSOLOProduct]).then(function (results) {
        var _results = _slicedToArray(results, 3),
            hasGpatt = _results[0].reportProducts.gpatt,
            hasLoyalty = _results[1].reportProducts.loyalty,
            hasSOLO = _results[2].reportProducts.solo;

        var additionalProducts = [];

        if (hasLoyalty) {
          additionalProducts.push('loyalty');
        }

        if (hasGpatt) {
          additionalProducts.push('gpatt');
        }

        if (hasSOLO) {
          additionalProducts.push('solo');
        }

        return additionalProducts;
      });
    },
    loadLocationProducts: function loadLocationProducts(locationId) {
      return this.ajax.request("/api/v1/reports/products/location/".concat(locationId)).then(function (_ref) {
        var reportProducts = _ref.reportProducts;
        return Object.keys(reportProducts).filter(function (p) {
          return reportProducts[p];
        }).sort();
      });
    }
  });

  _exports.default = _default;
});