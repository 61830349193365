define("lh4/adapters/echo-pro/application", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    urlForFindRecord: function urlForFindRecord() {
      var location = this.get('location');
      return "".concat(this._super.apply(this, arguments), "?location=").concat(location.model.id);
    }
  });

  _exports.default = _default;
});