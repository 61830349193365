define("lh4/utils/times-in-day", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTimesInDay;

  /**
   * Returns an array of name/value strings representing a single
   * days worth of time options based on interval.
   * (ex. interval = 15 => ([
   *	{ name: 12:00 AM, value: 00:00 },
   *	{ name: 12:15 AM, value: 00:15 },
   *	{ name: 12:30 AM, value: 00:30 }, ...]))
   *
   * @method getTimesInDay
   * @param {Number} minuteInterval minutes between each option defaults to 15
   * @returns {Array} timeInDayOptions
   */
  function getTimesInDay() {
    var intervalMinutes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 15;
    var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'hh:mmA';
    var date = (0, _moment.default)([1970, 0, 1, 0, 0, 0, 0]);
    var timeInDayOptions = [];
    var minuteInterval = intervalMinutes;

    if (!Number.isInteger(minuteInterval)) {
      minuteInterval = 15;
    }

    while (date.date() === 1) {
      // (ex. 03:00 AM, 02:00 PM)
      var time = date.format(format);
      timeInDayOptions.push(time);
      date.minutes(date.minutes() + Math.max(minuteInterval, 1));
    }

    return timeInDayOptions;
  }
});