define("lh4/models/dispute", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _emberData.default.Model.extend(Validations, {
    caseId: _emberData.default.attr('number'),
    locationId: _emberData.default.attr('number'),
    locationName: _emberData.default.attr('string'),
    merchantId: _emberData.default.attr('string'),
    cardNumber: _emberData.default.attr('string'),
    cardType: _emberData.default.attr('string'),
    caseType: _emberData.default.attr('string'),
    disputeAmt: _emberData.default.attr('number'),
    fileDate: _emberData.default.attr('string'),
    dueDate: _emberData.default.attr('string'),
    reasonCode: _emberData.default.attr('string'),
    reasonTitle: _emberData.default.attr('string'),
    reasonDesc: _emberData.default.attr('string'),
    requestToken: _emberData.default.attr('string'),
    status: _emberData.default.attr('string', {
      defaultValue: 'Awaiting%20Merchant%20Response'
    }),
    transAmt: _emberData.default.attr('number'),
    transDate: _emberData.default.attr('string'),
    ARN: _emberData.default.attr('string'),
    authcode: _emberData.default.attr('string'),
    documents: _emberData.default.attr(),
    questionnaires: _emberData.default.attr(),
    events: _emberData.default.attr(),
    requiredUploads: _emberData.default.attr(),
    isExpired: _emberData.default.attr('boolean'),
    closedDate: _emberData.default.attr()
  });

  _exports.default = _default;
});