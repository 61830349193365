define("lh4/mirage/routes/disputes", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageDisputesServer;
  var questionnaire = {
    id: 1,
    name: 'Dispute Form Test Data',
    status: 'Active',
    itemNumber: 1,
    value: [{
      // Message Values
      // =======================
      // Index: 0
      '@type': 'ConstantValue',
      type: 'String',
      value: true
    }, {
      // Index: 1
      '@type': 'ConstantValue',
      type: 'String',
      value: 'Message Title'
    }, {
      // Radio button values
      // =======================
      // Index: 2
      '@type': 'ConstantValue',
      type: 'String',
      value: 'Radio Buttons'
    }, {
      // Index: 3
      '@type': 'ConstantValue',
      type: 'String',
      value: 'radioOption1'
    }, {
      // Dropdown
      // =======================
      // Index: 4
      '@type': 'ConstantValue',
      type: 'String',
      value: 'Dropdown Values'
    }, {
      // Index: 5
      '@type': 'ConstantValue',
      type: 'String',
      value: 'option1'
    }, {
      // Text input
      // =======================
      // Index: 6
      '@type': 'ConstantValue',
      type: 'String',
      value: 'Text Input Label'
    }, {
      // Index: 7
      '@type': 'EditableValue',
      key: '0',
      name: 'Text input',
      type: 'String',
      maxLen: 5,
      value: 'Input Value',
      initValue: 'Text Input Text'
    }, {
      // Text area
      // =======================
      // Index: 8
      '@type': 'ConstantValue',
      type: 'String',
      value: 'Text Area Label'
    }, {
      // Index: 9
      '@type': 'EditableValue',
      key: '1',
      name: 'Text Area',
      type: 'String',
      value: 'Text Area Text',
      maxLen: 5,
      initValue: 'Text Area Text'
    }, {
      // Checkbox
      // =======================
      // Index: 10
      '@type': 'ConstantValue',
      type: 'String',
      value: false
    }, {
      // Index: 11
      '@type': 'ConstantValue',
      type: 'String',
      value: 'Checkbox Label'
    }, {
      // Index: 12
      '@type': 'Operator',
      operatorClass: 'BooleanNot',
      operandIndex1: 8,
      operandIndex2: null,
      operandIndex3: null
    }, {
      // Index: 13
      '@type': 'EditableValue',
      key: '8',
      name: 'Radio Buttons',
      type: 'String',
      maxLen: 100,
      initValue: 'radioOption1'
    }, {
      // Index: 14
      '@type': 'EditableValue',
      key: '9',
      name: 'Dropdown',
      type: 'String',
      maxLen: 100,
      initValue: 'option1'
    }, {
      // Index: 15
      '@type': 'EditableValue',
      key: '10',
      type: 'String',
      maxLen: 100,
      initValue: true
    }],
    field: [{
      // Message Field
      applicableIndex: 0,
      instructionType: 'Simple',
      labelIndex: 1
    }, {
      // Radio Buttons
      applicableIndex: 0,
      selectType: 'RadioButtons',
      valueIndex: 13,
      readOnlyIndex: 2,
      requiredIndex: 0,
      labelIndex: 2,
      numberOfLinesIndex: -1,
      listItem: [{
        code: 'radioOption1',
        display: 'Radio Option 1'
      }, {
        code: 'radioOption2',
        display: 'Radio Option 2'
      }]
    }, {
      // Dropdown
      applicableIndex: 0,
      selectType: 'DropDown',
      valueIndex: 14,
      readOnlyIndex: 2,
      requiredIndex: 0,
      labelIndex: 4,
      numberOfLinesIndex: -1,
      listItem: [{
        code: 'option1',
        display: 'Option 1'
      }, {
        code: 'option2',
        display: 'Option 2'
      }, {
        code: 'option3',
        display: 'Option 3'
      }]
    }, {
      // Checkbox
      applicableIndex: 0,
      selectType: 'RadioButtons',
      valueIndex: 15,
      readOnlyIndex: 2,
      requiredIndex: 0,
      labelIndex: 11,
      numberOfLinesIndex: -1,
      listItem: [{
        code: 'true',
        display: 'Checkbox Option'
      }]
    }, {
      // Text input
      applicableIndex: 0,
      groupId: null,
      valueIndex: 7,
      readOnlyIndex: 3,
      requiredIndex: 3,
      labelIndex: 6,
      labelStyleIndex: -1,
      editStyleIndex: -1,
      displayLenIndex: -1,
      labelLayoutIndex: -1,
      columnSpanIndex: 5,
      numberOfLinesIndex: 5,
      toolTipTextIndex: -1
    }, {
      // Text area
      selectType: 'TextArea',
      applicableIndex: 0,
      groupId: null,
      valueIndex: 9,
      readOnlyIndex: 3,
      requiredIndex: 3,
      labelIndex: 8,
      labelStyleIndex: -1,
      editStyleIndex: -1,
      displayLenIndex: -1,
      labelLayoutIndex: -1,
      columnSpanIndex: 10,
      numberOfLinesIndex: 10,
      toolTipTextIndex: -1
    }]
  };

  function setMirageDisputesServer(server) {
    server.get('/disputes', function (schema, request) {
      if (request.queryParams.format === 'csv') {
        return new _emberCliMirage.Response(400, {}, {
          error: {
            code: 400,
            message: 'invalid format'
          }
        });
      }

      if (request.queryParams.format === 'xlsx') {
        var headers = {};
        headers['content-disposition'] = 'filename=test.tif';
        return new _emberCliMirage.Response(200, headers, new Blob());
      }

      return {
        cases: schema.disputes.all().models
      };
    });
    server.get('/disputes/:disputeId', function () {
      return {
        fileDate: '2021-04-04T00:00:00.000Z',
        caseId: 54321,
        caseType: 'Dispute',
        reasonCode: '12345',
        reasonTitle: 'test reason title',
        reasonDesc: 'test reason description',
        status: 'Win',
        transDate: '2021-03-04T00:00:00.000Z',
        transAmt: '40.00',
        disputeAmt: '40.00',
        cardType: 'test',
        cardNumber: '************5678',
        ARN: '71234567890123456789012',
        authCode: '12345',
        documents: [],
        questionnaires: [questionnaire],
        events: []
      };
    });
    server.put('/disputes/:disputeId/questionnaire/:questionnaireId', function () {
      return new _emberCliMirage.Response(400, {}, {
        error: {
          code: 400,
          message: 'answer must have a key'
        }
      });
    });
  }
});