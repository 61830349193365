define("lh4/controllers/authenticated/support/contact/details", ["exports", "ember-changeset", "ember-changeset-cp-validations"], function (_exports, _emberChangeset, _emberChangesetCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    title: Ember.computed('model.ticket.id', function getter() {
      return "Ticket #".concat(this.get('model.ticket.id'));
    }),
    createNewComment: function createNewComment() {
      var comment = this.store.createRecord('support/comment', {
        // Created these to pass through to when building the URL in the adapter.
        ticketId: this.model.ticket.id,
        locationId: this.model.ticket.locationId
      });

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(comment),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(comment, validateFn, validationMap, {
        skipValidate: true
      });
      this.set('changeset', changeset);
    },
    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.support.contact');
      },
      postComment: function postComment() {
        var _this = this;

        this.set('showErrors', true);
        this.changeset.validate().then(function () {
          if (_this.changeset.get('isValid')) {
            return _this.changeset.save().then(function () {
              _this.model.ticket.comments.unshiftObject(_this.changeset.get('data'));

              _this.createNewComment();

              _this.set('showErrors', false);
            });
          }

          return false;
        });
      }
    }
  });

  _exports.default = _default;
});