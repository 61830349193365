define("lh4/components/ui/buttons/button-confirm-modal", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    buttonText: '',
    buttonClass: '',
    onApproveDelay: 1000,
    icon: 'trash',
    confirmationQuestion: 'Are you sure?',
    confirmationDescription: 'Do you want to proceed',
    confirmationApproveText: 'Yes',
    confirmationDenyText: 'Cancel',
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      this.set('id', "button-confirm-modal-confirmation-".concat((0, _emberUuid.v4)()));
    },
    actions: {
      displayConfirmModal: function displayConfirmModal() {
        this.$("#".concat(this.get('id'))).modal('show');
      },
      actionConfirmed: function actionConfirmed() {
        var _this = this;

        this.$('button').prop('disabled', true);
        Ember.run.later(this, function () {
          _this.$('button').prop('disabled', false);

          _this.sendAction('onApprove');
        }, this.get('onApproveDelay'));
      }
    }
  });

  _exports.default = _default;
});