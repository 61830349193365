define("lh4/components/internet-payments/disputes/dispute-list", ["exports", "lh4/enums/ip-dispute-statuses", "lh4/enums/ip-dispute-case-types"], function (_exports, _ipDisputeStatuses, _ipDisputeCaseTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    field: 'date',
    name: 'Dispute Date'
  }, {
    field: 'caseNumber',
    name: 'Case Number'
  }, {
    field: 'caseType',
    name: 'Case Type'
  }, {
    field: 'daysToAct',
    name: 'Days to Act'
  }, {
    field: 'disputeAmount',
    name: 'Dispute Amount'
  }, {
    field: 'status',
    name: 'Status'
  }, {
    field: 'reason',
    name: 'Reason'
  }, {
    field: 'transactionDate',
    name: 'Transaction Date'
  }, {
    field: 'transactionAmount',
    name: 'Transaction Amount'
  }, {
    field: 'transactionId',
    name: 'Transaction ID'
  }, {
    field: 'acquirerRefNumber',
    name: 'Acquirer Reference Number'
  }, {
    field: 'merchantReference',
    name: 'Merchant Reference'
  }, {
    field: 'card',
    name: 'Credit Card'
  }];

  var _default = Ember.Component.extend({
    classNames: ['dispute-list'],
    router: Ember.inject.service(),
    headers: HEADERS,
    rowOptions: [10, 25, 50, 100],
    isViewFirstPageVisible: false,
    isViewLastPageVisible: false,

    get isDaysToActVisible() {
      return this.router.currentRouteName === 'authenticated.internet-payments.disputes.all';
    },

    get disputeStatus() {
      return _ipDisputeStatuses.DISPUTE_STATUS;
    },

    get status() {
      return _ipDisputeStatuses.DISPUTE_STATUS_DISPLAY;
    },

    get disputeCaseType() {
      return _ipDisputeCaseTypes.DISPUTE_CASE_TYPE;
    },

    get caseType() {
      return _ipDisputeCaseTypes.DISPUTE_CASE_TYPE_DISPLAY;
    },

    init: function init() {
      this._super();

      var currentRoute = this.router.currentRouteName;
      var headers = HEADERS;

      if (currentRoute === 'authenticated.internet-payments.disputes.in-review' || currentRoute === 'authenticated.internet-payments.disputes.won' || currentRoute === 'authenticated.internet-payments.disputes.lost') {
        headers = HEADERS.filter(function (header) {
          return header.field !== 'daysToAct';
        });
      }

      this.set('headers', headers);
    }
  });

  _exports.default = _default;
});