define("lh4/serializers/gpatt/settings", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',
    attrs: {
      'ratings-threshold-recipients': {
        embedded: 'always'
      }
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'gpatt/settings';
    },
    serialize: function serialize() {
      return {
        settings: this._super.apply(this, arguments)
      };
    }
  });

  _exports.default = _default;
});