define("lh4/templates/components/dashboard-widgets/ticket-turnaround/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MhuI84H1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"value\"],[8],[1,[24,[\"data\",\"time\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/ticket-turnaround/data.hbs"
    }
  });

  _exports.default = _default;
});