define("lh4/enums/ip-dispute-case-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DISPUTE_CASE_TYPE_DISPLAY = _exports.DISPUTE_CASE_TYPE = void 0;
  var DISPUTE_CASE_TYPE = Object.freeze({
    RETRIEVAL_REQUEST: 'RETRIEVAL_REQUEST',
    CHARGEBACK: 'CHARGEBACK',
    PRE_ARBITRATION: 'PRE_ARBITRATION',
    ARBITRATION: 'ARBITRATION'
  });
  _exports.DISPUTE_CASE_TYPE = DISPUTE_CASE_TYPE;
  var DISPUTE_CASE_TYPE_DISPLAY = Object.freeze({
    RETRIEVAL_REQUEST: 'Retrieval Request',
    CHARGEBACK: 'Chargeback',
    PRE_ARBITRATION: 'Pre-arbitration',
    ARBITRATION: 'Arbitration'
  });
  _exports.DISPUTE_CASE_TYPE_DISPLAY = DISPUTE_CASE_TYPE_DISPLAY;
});