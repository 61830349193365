define("lh4/serializers/echo-pro/availability-price/dynamic-price", ["exports", "lh4/serializers/echo-pro/availability-price/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _configuration.default.extend({
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.schedules) {
        json.schedules = json.schedules.filter(function (schedule) {
          return !schedule.isVoided;
        });
      }

      return json;
    }
  });

  _exports.default = _default;
});