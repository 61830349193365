define("lh4/transforms/subscription-time", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return (0, _moment.default)(serialized, 'HH:mm').format('hh:mmA');
    },
    serialize: function serialize(deserialized) {
      return (0, _moment.default)(deserialized, 'hh:mmA').format('HH:mm');
    }
  });

  _exports.default = _default;
});