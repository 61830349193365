define("lh4/controllers/authenticated/updates", ["exports", "lh4/controllers/general-list-controller", "moment"], function (_exports, _generalListController, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    corporation: Ember.inject.service(),
    location: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super((_this$location = this.location) === null || _this$location === void 0 ? void 0 : (_this$location$model = _this$location.model) === null || _this$location$model === void 0 ? void 0 : _this$location$model.id);
    }),
    queryParams: ['selectedStatus', 'searchName', 'offset', 'limit'],
    headers: [{
      field: 'name',
      name: 'Update Name',
      width: 'five',
      sortable: false
    }, {
      field: 'createdAt',
      name: 'Creation Date',
      width: 'three',
      sortable: false
    }, {
      field: 'scheduledFor',
      name: 'Application Date',
      width: 'three',
      sortable: false
    }, {
      field: 'status',
      name: 'Status',
      width: 'three',
      sortable: false
    }],
    statusOptions: [{
      name: 'All Updates',
      value: 'all'
    }, {
      name: 'Pending',
      value: 'pending'
    }, {
      name: 'Complete',
      value: 'complete'
    }, {
      name: 'Error',
      value: 'error'
    }],
    selectedStatus: 'all',
    hasPendingInitialUpdates: Ember.computed('model.location.merchantId', 'model.updates', function hasPendingUpdates() {
      function filterUpdatesByStatus(objects) {
        return objects.filter(function (obj) {
          return obj.status === 'pending' && obj.scheduledFor === (0, _moment.default)().format('YYYY-MM-DD');
        });
      }

      var filteredObjects = filterUpdatesByStatus(this.model.updates);
      return filteredObjects.length > 0;
    }),
    hasDeploymentLocations: Ember.computed('model.locations', function deploymentAvailable() {
      return this.model.locations.filter(function (loc) {
        return !loc.isLocationManaged;
      }).length > 0;
    }),
    actions: {
      handleStatusChange: function handleStatusChange(status) {
        this.setProperties({
          offset: 0
        });
        this.set('selectedStatus', status);
      },
      deploy: function deploy() {
        this.transitionToRoute('authenticated.updates.deploy');
      }
    }
  });

  _exports.default = _default;
});