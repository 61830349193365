define("lh4/mixins/routes/moment-query-param-mixin", ["exports", "lh4/enums/date-time-format", "moment"], function (_exports, _dateTimeFormat, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    serializeQueryParam: function serializeQueryParam() {
      var _this$queryParams, _this$queryParams$key;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var value = args[0],
          key = args[1];

      if (((_this$queryParams = this.queryParams) === null || _this$queryParams === void 0 ? void 0 : (_this$queryParams$key = _this$queryParams[key]) === null || _this$queryParams$key === void 0 ? void 0 : _this$queryParams$key.type) === 'moment') {
        return value instanceof _moment.default ? value.format(_dateTimeFormat.default.ISO_8601_MS) : null;
      }

      return this._super.apply(this, args);
    },
    deserializeQueryParam: function deserializeQueryParam() {
      var _this$queryParams2, _this$queryParams2$ke;

      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      var value = args[0],
          key = args[1];

      if (((_this$queryParams2 = this.queryParams) === null || _this$queryParams2 === void 0 ? void 0 : (_this$queryParams2$ke = _this$queryParams2[key]) === null || _this$queryParams2$ke === void 0 ? void 0 : _this$queryParams2$ke.type) === 'moment') {
        var deserializedValue = (0, _moment.default)(value);
        return deserializedValue.isValid() ? deserializedValue : null;
      }

      return this._super.apply(this, args);
    }
  });

  _exports.default = _default;
});