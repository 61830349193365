define("lh4/services/response-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * Convert normalized error response (adapters/application.js does this) to Error Messages
     * @param {Object} err error object from normalized error response.
     * @param {string} defaultErrorMessage - default error message
     * @returns {Array} error messages
     */
    getResponseErrors: function getResponseErrors(err) {
      var _this = this;

      var defaultErrorMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Operation Failed. Please contact the administrator';

      if (typeof err === 'string') {
        return [err];
      }

      if (Ember.get(err, 'errors.firstObject.detail.error.message') && Array.isArray(err.errors)) {
        return err.errors.map(function (e) {
          var details = e.detail.error.details && Array.isArray(e.detail.error.details) ? e.detail.error.details.join(', ') : '';
          return details ? "".concat(e.detail.error.message, ": ").concat(details) : e.detail.error.message;
        });
      }

      if (Ember.get(err, 'errors.details.length') && Array.isArray(err.errors.details)) {
        return err.errors.details;
      }

      if (Ember.get(err, 'errors.details')) {
        return [err.errors.details];
      }

      if (Ember.get(err, 'errors.firstObject.details')) {
        var details = err.errors[0].details;
        var message = Array.isArray(details) ? details[0] : details;

        if (message) {
          return [message];
        }
      }

      if (Ember.get(err, 'errors.firstObject.message')) {
        return [err.errors[0].message];
      }

      if (Ember.get(err, 'payload.error.message')) {
        return [err.payload.error.message];
      }

      if (err && Array.isArray(err.errors)) {
        return err.errors.map(function (e) {
          if (Ember.get(e, 'detail.error.message')) {
            return e.detail.error.message;
          }

          if (e.title) {
            return e.title;
          }

          return defaultErrorMessage;
        });
      }

      if (Array.isArray(err) && err.length) {
        return err.reduce(function (messages, errObj) {
          return messages.concat(_this.getResponseErrors(errObj));
        }, []);
      }

      return [defaultErrorMessage];
    }
  });

  _exports.default = _default;
});