define("lh4/components/social-media/post-preview/post-content-preview", ["exports", "lh4/utils/cron"], function (_exports, _cron) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui', 'segment', 'post-content'],
    postFrequency: Ember.computed('scheduleRepeat', function getter() {
      if (!this.get('scheduleRepeat')) {
        return null;
      }

      var _calculateFrequency = (0, _cron.calculateFrequency)(this.get('scheduleRepeat')),
          frequency = _calculateFrequency.frequency,
          days = _calculateFrequency.days;

      return (0, _cron.getFormattedFrequencyString)(frequency, null, days);
    })
  });

  _exports.default = _default;
});