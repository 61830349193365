define("lh4/components/dashboard-widgets/clocked-in-employees/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['clocked-in-employees-data', 'table-widget'],
    skeletonData: {
      employees: [{
        name: 'Nick Markulics',
        time: '8h 55m'
      }, {
        name: 'Keith Paff',
        time: '7h 55m'
      }, {
        name: 'Jillian Stewart',
        time: '7h 55m'
      }, {
        name: 'Alicia Baker',
        time: '7h 55m'
      }, {
        name: 'Louis Bloom',
        time: '7h 55m'
      }]
    },
    employees: Ember.computed('expanded', 'data', function getter() {
      if (this.get('expanded')) {
        return this.get('data.employees');
      }

      return this.get('data.employees').slice(0, 5);
    }),
    thereIsMore: Ember.computed('data', function getter() {
      return this.get('loading') || this.get('data.employees.length') > 5;
    })
  });

  _exports.default = _default;
});