define("lh4/utils/compare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = compareNumerics;

  /**
   * Compares two values and returns -1 if a < b, 0 if a = b and 1 if a > b
   *
   * Uses valueOf if arguments implements it.  Solves issue when comparing moment values as Ember.compare always returns 0
   * @see https://github.com/emberjs/rfcs/issues/207
   *
   * @param {number} a First value to compare
   * @param {number} b Second value to compare
   * @returns {number} -1 if a < b, 0 if a = b and 1 if a > b
   */
  function compareNumerics(a, b) {
    var aValue = a && a.valueOf ? a.valueOf() : a;
    var bValue = b && b.valueOf ? b.valueOf() : b;
    return Ember.compare(aValue, bValue);
  }
});