define("lh4/mirage/factories/gpatt-setting", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    'tip-1': 18,
    'tip-2': 20,
    'tip-3': 25,
    'default-tip': 'tip-2',
    'tip-calculation-method': 'post-tax',
    'collect-more-info': false,
    'collect-more-info-label': 'Full name',
    'collect-more-info-message': 'Please enter your name and email to receive special offers',
    'collect-ratings': true,
    'ratings-threshold': 5,
    'ratings-threshold-recipients': [{
      name: 'Test',
      email: null,
      isEmailActive: false,
      isPhoneActive: false,
      phone: '+37061111111',
      settings: '162'
    }],
    'ratings-threshold-should-notify': false,
    'allow-partial-payment': false,
    'enable-tip-screen': true,
    'enable-force-tip-selection': false,
    'enable-signature-capture': false,
    'allow-cash-payments': false,
    'enable-order-at-the-table': true,
    'enable-guests-in-ordering': true,
    'show-brand-logo': false,
    'brand-logo-uri': 'test',
    'ncc-enabled': true,
    'ncc-description': 'Non-Cash Charge',
    'ncc-percentage': 0.0
  });

  _exports.default = _default;
});