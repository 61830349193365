define("lh4/templates/components/state-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qRDQZ/Qr",
    "block": "{\"symbols\":[\"state\"],\"statements\":[[4,\"ui-dropdown\",null,[[\"class\",\"selected\",\"onChange\"],[[28,\"concat\",[\"selection \",[24,[\"class\"]]],null],[24,[\"model\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"model\"]]],null]],null]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"text default\"],[8],[1,[22,\"placeholder\"],false],[9],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"states\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"item\"],[11,\"data-value\",[29,[[23,1,[\"abbr\"]]]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[23,1,[\"state\"]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/state-selector.hbs"
    }
  });

  _exports.default = _default;
});