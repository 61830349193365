define("lh4/models/hospitality/revenue-class", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    isLiquor: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    canExport: (0, _attr.default)('boolean', {
      defaultValue: null
    }),
    exportAlias: (0, _attr.default)('string', {
      defaultValue: null
    }),
    lastExportedAt: (0, _attr.default)('date', {
      defaultValue: null
    }),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    isGiftcard: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    includeInFavorites: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    favoritesCount: (0, _attr.default)('number', {
      defaultValue: 2
    }),
    isNonSales: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    includeInTipOut: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    tipOutPercent: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    includeInGratuity: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    exportId: (0, _attr.default)('string', {
      defaultValue: null
    }),
    revenueClass: (0, _attr.default)('number')
  });

  _exports.default = _default;
});