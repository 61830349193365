define("lh4/utils/cron", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateFrequency = calculateFrequency;
  _exports.getFormattedFrequencyString = getFormattedFrequencyString;
  _exports.createCronExpression = createCronExpression;
  _exports.getFutureRecurrenceTime = getFutureRecurrenceTime;
  _exports.CronFrequencies = void 0;

  /**
   * @constant
   * @type {Object}
   */
  var CronFrequencies = Object.freeze({
    BI_WEEKLY: 'Bi Weekly',
    DAILY: 'Daily',
    MONTHLY: 'Monthly',
    WEEKLY: 'Weekly',
    YEARLY: 'Yearly'
  });
  /**
   * An object that returns a frequency string and days array if applicable
   * @typedef {Object} FrequencyRecurrence
   * @property {number[]} days - An array of numbers for days of the week
   * @property {string} frequency - A value defined in CronFrequencies constant
   */

  /**
   * Given a cron expression return the frequency of recurrence
   *
   * @param {string} cronExpression - a cron string that will be parsed to
   * determine repeat frequency
   * @returns {?FrequencyRecurrence} - cron frequency
   */

  _exports.CronFrequencies = CronFrequencies;

  function calculateFrequency(cronExpression) {
    if (!cronExpression) {
      throw new Error('cronExpression cannot be empty');
    }

    var dayMonCron = cronExpression.split(' ')[2];
    var monCron = cronExpression.split(' ')[3];
    var dayWeekCron = cronExpression.split(' ')[4];
    var days = [];

    switch ("".concat(dayMonCron, " ").concat(monCron, " ").concat(dayWeekCron)) {
      case '* * *':
        return {
          days: days,
          frequency: CronFrequencies.DAILY
        };

      case "* * ".concat(dayWeekCron):
        return {
          days: dayWeekCron.split(',').map(function (day) {
            return +day;
          }),
          frequency: CronFrequencies.WEEKLY
        };

      case "".concat(dayMonCron, " * *"):
        return {
          days: days,
          frequency: CronFrequencies.MONTHLY
        };

      case "".concat(dayMonCron, " ").concat(monCron, " *"):
        return {
          days: days,
          frequency: CronFrequencies.YEARLY
        };

      default:
        break;
    }

    return null;
  }
  /**
   * Returns a formatted string to show the user when recurrence will happen
   * @param {string} frequency - how often the cron should run
   * @param {string} [repeatEnd] - when the cron will stop recurring
   * @param {number[]|string[]} [days=[]] - When using CronFrequencies.WEEKLY the days
   * of the week it should repeat on (numbers or strings)
   * @returns {string} - formatted frequency string
   */


  function getFormattedFrequencyString(frequency, repeatEnd) {
    var days = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var weekPart = '';
    var untilPart = '';

    if (frequency.indexOf('Weekly') !== -1 && days.length > 0) {
      var daysString = days.map(function (day) {
        return Number.isInteger(day) ? _moment.default.weekdays(day % 7) : day;
      }).reduce(function (accumulator, weekday, i) {
        return accumulator + (i + 1 !== days.length ? ', ' : ' and ') + weekday;
      });
      weekPart = " on ".concat(daysString);
    }

    if (repeatEnd) {
      untilPart = " until ".concat((0, _moment.default)(repeatEnd).format('MMMM DD, YYYY'));
    }

    return "".concat(frequency).concat(weekPart).concat(untilPart);
  }
  /**
   * Create a cron expression from a start date and frequency, will
   * default to a daily cron if frequency or days are undefined.
   *
   * @param {(Moment|string)} startsAt - basis for start of cron expression
   * @param {string} [frequency=CronFrequencies.DAILY] - how often the cron
   * should run
   * @param {number[]} [days=[]] - When using CronFrequencies.WEEKLY the days of the
   * week it should repeat on
   * @param {boolean} [forceUserTimezone=true] - whether or not allow a timezone
   * different than the users day
   * @returns {string} - cron expression
   */


  function createCronExpression(startsAt, frequency) {
    var days = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var forceUserTimezone = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    var startsFromMoment;

    if (_moment.default.isMoment(startsAt)) {
      startsFromMoment = startsAt;
    } else if (typeof startsAt === 'string') {
      startsFromMoment = _moment.default.parseZone(startsAt);
    } else {
      throw new Error('startsAt must be either moment instance or string');
    }

    if (forceUserTimezone && startsFromMoment.utcOffset() !== new Date().getTimezoneOffset() * -1) {
      throw new Error('must pass date in local browsers timezone');
    }

    var hour = startsFromMoment.format('HH');
    var minute = startsFromMoment.format('mm');
    var day = startsFromMoment.format('D');
    var month = startsFromMoment.format('M');
    var dayExpression = days && days.length > 0 ? days.join(',') : '*';

    switch (frequency) {
      case CronFrequencies.WEEKLY:
        return "".concat(minute, " ").concat(hour, " * * ").concat(dayExpression);

      case CronFrequencies.MONTHLY:
        return "".concat(minute, " ").concat(hour, " ").concat(day, " * *");

      case CronFrequencies.YEARLY:
        return "".concat(minute, " ").concat(hour, " ").concat(day, " ").concat(month, " *");

      case CronFrequencies.DAILY:
      default:
        return "".concat(minute, " ").concat(hour, " * * *");
    }
  }
  /**
   * Given a known recurrence date and the future start of a week
   * return the first applicable time of recurrence.
   * (Currently we know at least the first time is applicable or it would not
   * be returned by the api)
   * @param {Moment} recurrenceTime - Known recurrence time
   * @param {Moment} fromTime - Start of future timerange
   * @param {FrequencyRecurrence} frequencyRecurrence - frequency string and days
   * @returns {Moment} - future recurrence time
   */


  function getFutureRecurrenceTime(recurrenceTime, fromTime, frequencyRecurrence) {
    // Currently responses from the api are either the current week (where
    // this type of adjustment is not needed) or at the start of a week in the
    // future.
    //
    // Since this is the case we can safely choose the first applicaple date
    // after the fromTime.
    switch (frequencyRecurrence.frequency) {
      case CronFrequencies.DAILY:
        return (0, _moment.default)(recurrenceTime).date(fromTime.date()).month(fromTime.month()).year(fromTime.year());

      case CronFrequencies.WEEKLY:
        return (0, _moment.default)(recurrenceTime).date(fromTime.date()).month(fromTime.month()).year(fromTime.year()).day(frequencyRecurrence.days[0]);

      case CronFrequencies.MONTHLY:
        return (0, _moment.default)(recurrenceTime).month(fromTime.month()).year(fromTime.year());

      case CronFrequencies.YEARLY:
        return (0, _moment.default)(recurrenceTime).year(fromTime.year());

      default:
        return recurrenceTime;
    }
  }
});