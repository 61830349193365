define("lh4/templates/components/solo/solo-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+3p3gV/v",
    "block": "{\"symbols\":[\"@item\",\"@showErrors\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui form padded stackable grid\"],[8],[0,\"\\n\\t\"],[7,\"h2\",true],[8],[0,\"\\n\\t\\tGeneral Information\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[5,\"ui/form-fields/input-field\",[[12,\"data-test-solo-name\",\"\"]],[[\"@changeset\",\"@label\",\"@type\",\"@property\",\"@placeholder\",\"@showErrors\"],[[23,1,[]],\"Name\",\"text\",\"name\",\"\",[23,2,[]]]]],[0,\"\\n\\n\\t\"],[5,\"ui/form-fields/input-field\",[[12,\"data-test-solo-base-price\",\"\"]],[[\"@changeset\",\"@onChange\",\"@label\",\"@type\",\"@property\",\"@showErrors\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"setValueAndCalculate\",\"basePrice\"],null],\"Base Price ($)\",\"number\",\"basePrice\",[23,2,[]]]]],[0,\"\\n\\n\\t\"],[5,\"ui/form-fields/input-field\",[[12,\"data-test-solo-tax-value\",\"\"]],[[\"@onChange\",\"@changeset\",\"@label\",\"@type\",\"@property\",\"@showErrors\"],[[28,\"action\",[[23,0,[]],\"setValueAndCalculate\",\"taxValue\"],null],[23,1,[]],\"Tax Value\",\"number\",\"taxValue\",[23,2,[]]]]],[0,\"\\n\\n\\t\"],[5,\"ui/form-fields/select-field\",[[12,\"data-test-solo-tax-type\",\"\"]],[[\"@changeset\",\"@onChange\",\"@label\",\"@options\",\"@property\",\"@showErrors\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"setValueAndCalculate\",\"taxType\"],null],\"Tax Type ($)\",[22,\"soloTypes\"],\"taxType\",[23,2,[]]]]],[0,\"\\n\\n\\t\"],[5,\"ui/form-fields/input-field\",[[12,\"data-test-solo-full-price\",\"\"]],[[\"@changeset\",\"@label\",\"@type\",\"@property\",\"@disabled\",\"@showErrors\"],[[23,1,[]],\"Total Price (tax inclusive, $)\",\"number\",\"fullPrice\",\"true\",[23,2,[]]]]],[0,\"\\n\\n\\t\"],[5,\"ui/form-fields/required-fields\",[],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/solo/solo-form.hbs"
    }
  });

  _exports.default = _default;
});