define("lh4/templates/components/dashboard-widgets/tickets/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P3gOWq4S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"Count\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[24,[\"data\",\"count\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"Value\"],[9],[0,\"\\n\\t\"],[7,\"span\",true],[10,\"class\",\"content\"],[8],[1,[28,\"currency-format\",[[28,\"or\",[[24,[\"data\",\"value\"]],0],null]],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard-widgets/tickets/data.hbs"
    }
  });

  _exports.default = _default;
});