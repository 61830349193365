define("lh4/templates/components/internet-payments/disputes/case/case-summary-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nuo//9bN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"case-summary-panel\"],[10,\"data-test-ip\",\"case-summary-panel\"],[8],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@dataTestId\"],[\"Dispute Amount\",\"dispute-amount\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"currency\"],[8],[0,\"USD\"],[9],[0,\" \"],[1,[22,\"disputeAmount\"],false],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@dataTestId\"],[\"Payment Method\",\"dispute-payment\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"internet-payments/credit-card\",[],[[\"@cardNumber\",\"@cardType\"],[[23,0,[\"args\",\"dispute\",\"card\",\"last4\"]],[23,0,[\"args\",\"dispute\",\"card\",\"brand\"]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@value\",\"@color\",\"@dataTestId\"],[\"Status\",[23,0,[\"disputeStatus\"]],[23,0,[\"disputeStatus\"]],\"dispute-status\"]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/disputes/case/case-summary-panel.hbs"
    }
  });

  _exports.default = _default;
});