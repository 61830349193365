define("lh4/routes/authenticated/locations/location/echo-pro/manage-pos/table-layout", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    requiredPermissions: 'manage echo-pro/table-layout',
    titleToken: 'Table Layout',
    model: function model() {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      return this.store.query('reservations/section', {
        location: location.get('id')
      }).then(function () {
        return _this.store.peekAll('reservations/section');
      }).then(function (section) {
        return Ember.RSVP.hash({
          location: location,
          tables: _this.store.query('reservations/table', {
            location: location.get('id')
          }).then(function () {
            return _this.store.peekAll('reservations/table');
          }),
          section: section
        });
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var controller = this.controllerFor(this.routeName);

        if (controller.tables.some(function (section) {
          return section.hasDirtyAttributes;
        }) || controller.sections.some(function (section) {
          return section.hasDirtyAttributes;
        })) {
          controller.set('previousTransition', transition);
          controller.send('transitionModal');
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});