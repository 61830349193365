define("lh4/adapters/application", ["exports", "ember-inflector", "ember-data", "lh4/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberInflector, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    pathForType: function pathForType(type) {
      var dashes = Ember.String.dasherize(type);
      return (0, _emberInflector.pluralize)(dashes);
    },
    authorize: function authorize(xhr) {
      var _this$session$data, _this$session;

      var _this$session$data$au = (_this$session$data = this.session.data) === null || _this$session$data === void 0 ? void 0 : _this$session$data.authenticated,
          token = _this$session$data$au.token;

      if (token) {
        xhr.setRequestHeader('X-Access-Token', token);
      }

      if ((_this$session = this.session) !== null && _this$session !== void 0 && _this$session.ownerToken) {
        xhr.setRequestHeader('x-verified-owner-token', this.session.ownerToken);
      }
    },
    namespace: 'api/v1',
    host: _environment.default.host,
    ensureResponseAuthorized: function ensureResponseAuthorized(status, headers, payload) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        var message = payload.error.message;

        if (message === 'invalid token' || message === 'missing authentication') {
          this.session.invalidate();
        }
      }
    },
    // normalizeErrorResponse is a private function. Caution when updating
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (payload && _typeof(payload) === 'object' && payload.error) {
        return [payload.error];
      }

      return [{
        status: "".concat(status),
        title: 'The backend responded with an error.',
        detail: "".concat(payload)
      }];
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var _snapshot$adapterOpti;

      var url = this._super(modelName, id, snapshot, requestType, query);

      if (!(snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.query)) {
        return url;
      }

      var _URL = new URL(url, this.host || 'http://example.com'),
          pathname = _URL.pathname,
          searchParams = _URL.searchParams;

      var queryParams = new URLSearchParams([].concat(_toConsumableArray(Array.from(searchParams.entries())), _toConsumableArray(Object.entries(snapshot.adapterOptions.query))));
      return "".concat(this.host).concat(pathname, "?").concat(queryParams);
    }
  });

  _exports.default = _default;
});