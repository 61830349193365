define("lh4/components/reputation/reviews-table", ["exports", "lh4/mixins/reputation/reputation-account-types"], function (_exports, _reputationAccountTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend((0, _reputationAccountTypes.default)('account'), {
    accountApi: Ember.inject.service('reputation/account-api'),
    errorHandler: Ember.inject.service(),
    metrics: Ember.inject.service(),
    headers: [{
      name: 'Date',
      field: 'createdAt',
      width: 'three',
      sortable: false
    }, {
      name: 'Rating',
      field: 'rating',
      width: 'two',
      sortable: false
    }, {
      name: 'Review',
      field: 'text',
      width: 'seven',
      sortable: false
    }, {
      name: 'Status',
      field: 'text',
      width: 'three',
      sortable: false
    }, {
      name: '',
      field: '',
      width: 'one',
      sortable: false
    }],
    reviewStatuses: [{
      name: '',
      status: null
    }, {
      name: 'IN_PROGRESS',
      status: 'In Progress'
    }, {
      name: 'DO_NOT_RESPOND',
      status: 'Do not respond'
    }, {
      name: 'COMPLETE',
      status: 'Complete'
    }],
    selectedReview: null,
    selectedReply: null,
    reviewModalId: 'selected-review-modal',
    actions: {
      selectReview: function selectReview(review) {
        if (review === this.get('selectedReview')) {
          this.set('selectedReview', null);
          return;
        }

        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'Reputation Management',
          eventAction: 'Review Read'
        });
        this.set('selectedReview', review);
        this.set('selectedReply', review.id);
        this.$("#".concat(this.get('reviewModalId'))).modal('show');
      },
      unselectReview: function unselectReview() {
        this.set('selectedReply', null);
        this.set('selectedReview', null);
        this.$("#".concat(this.get('reviewModalId'))).modal('hide');
      },
      viewReviewSource: function viewReviewSource(review) {
        if (review.url) {
          window.open(review.url);
        }
      },
      statusColumnSelect: function statusColumnSelect() {// Do nothing, just to prevent event bubbling
      },
      changeReviewStatus: function changeReviewStatus(account, review, currentStatus) {
        var _this = this;

        if (this.get('isLoading')) {
          return;
        }

        this.set('isLoading', true);
        var status = null;

        if (currentStatus) {
          status = currentStatus;
        }

        this.accountApi.updateReviewStatus(account.id, review.id, status).then(function () {
          Ember.set(review, 'status', {
            status: status
          });
        }).catch(function (err) {
          _this.errorHandler.handleResponseError(err, 'Could not update status');
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});