define("lh4/controllers/authenticated/supply-orders/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    supplyCart: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    actions: {
      selectedLocation: function selectedLocation() {
        var _this = this;

        var address = this.get('model.address');
        var supplyCart = this.supplyCart;
        var data = {
          street1: this.get('supplyCart.locationStreet1'),
          street2: this.get('supplyCart.locationStreet2'),
          city: this.get('supplyCart.locationCity'),
          state: this.get('supplyCart.locationState'),
          zip: this.get('supplyCart.locationZip'),
          name: this.get('supplyCart.locationName'),
          phone: this.get('supplyCart.locationPhone'),
          email: this.get('supplyCart.locationEmail')
        };
        this.ajax.request('/api/v1/supply-orders/addresses', {
          type: 'POST',
          data: JSON.stringify({
            address: data
          })
        }).then(function (locationObj) {
          _this.set('loading', false);

          address.setProperties({
            id: locationObj.address.id,
            addressId: locationObj.address.id,
            name: locationObj.address.name,
            street1: locationObj.address.street1,
            street2: locationObj.address.street2,
            city: locationObj.address.city,
            state: locationObj.address.state,
            zip: locationObj.address.zip,
            email: locationObj.address.email,
            extendedZip: locationObj.address.extendedZip,
            phone: locationObj.address.phone,
            isCommercial: locationObj.address.isCommercial,
            isValid: locationObj.address.isValid,
            candidates: locationObj.address.candidates
          });
          supplyCart.set('addressId', locationObj.address.id);

          _this.send('transitionToEquipment');
        }).catch(function () {
          _this.flashMessages.danger('Location information request failed', {
            class: 'ui error message'
          });

          _this.set('loading', false);
        });
      },
      transitionToEquipment: function transitionToEquipment() {
        this.transitionToRoute('authenticated.supply-orders.equipment');
      }
    }
  });

  _exports.default = _default;
});