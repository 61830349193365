define("lh4/components/table-layout/s4/settings/controls/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="control">
  	<div class="header">{{@header}}</div>
  	<div class="button-group">
  		<div class="square">
  			<Ui::S4::Buttons::Button @class="rounded-text" @type="text" @disabled={{@disabled}} @onClick={{action @decrease}}>
  				<:leadingIcon>
  					<Ui::Icons::Table-Layout::Minus/>
  				</:leadingIcon>
  			</Ui::S4::Buttons::Button>
  		</div>
  		<div class="square middle-value">
  			{{@value}}
  		</div>
  		<div class="square">
  			<Ui::S4::Buttons::Button @class="rounded-text" @type="text" @disabled={{@disabled}} @onClick={{action @increase}}>
  				<:leadingIcon>
  					<Ui::Icons::Table-Layout::Plus/>
  				</:leadingIcon>
  			</Ui::S4::Buttons::Button>
  		</div>
  	</div>
  </div>
  
  */
  {
    id: "Nw6frG74",
    block: "{\"symbols\":[\"__arg0\",\"__arg0\",\"@header\",\"@disabled\",\"@decrease\",\"@value\",\"@increase\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"control\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"button-group\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"square\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@class\",\"@type\",\"@disabled\",\"@onClick\",\"@namedBlocksInfo\"],[\"rounded-text\",\"text\",[23,4,[]],[28,\"action\",[[23,0,[]],[23,5,[]]],null],[28,\"hash\",null,[[\"leadingIcon\"],[0]]]]],{\"statements\":[[4,\"if\",[[28,\"-is-named-block-invocation\",[[23,2,[]],\"leadingIcon\"],null]],null,{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[5,\"ui/icons/table-layout/minus\",[],[[],[]]],[0,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]},null]],\"parameters\":[2]}],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"square middle-value\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[23,6,[]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"square\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@class\",\"@type\",\"@disabled\",\"@onClick\",\"@namedBlocksInfo\"],[\"rounded-text\",\"text\",[23,4,[]],[28,\"action\",[[23,0,[]],[23,7,[]]],null],[28,\"hash\",null,[[\"leadingIcon\"],[0]]]]],{\"statements\":[[4,\"if\",[[28,\"-is-named-block-invocation\",[[23,1,[]],\"leadingIcon\"],null]],null,{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[5,\"ui/icons/table-layout/plus\",[],[[],[]]],[0,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]},null]],\"parameters\":[1]}],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/table-layout/s4/settings/controls/number.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});