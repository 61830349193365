define("lh4/templates/components/ui/form-fields/static-comment-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tQQOmbWp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"comment-header\"],[8],[0,\"\\n\\t\"],[1,[24,[\"comment\",\"author\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"comment-time\"],[8],[0,\"\\n\\t\"],[7,\"p\",true],[8],[0,\"\\n\\t\\t\"],[1,[28,\"ui/date/last-visit\",null,[[\"date\",\"outputFormat\"],[[24,[\"comment\",\"createdAt\"]],\"MM/DD/YYYY hh:mm:ss A\"]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[10,\"class\",\"comment\"],[8],[0,\"\\n\\t\"],[1,[24,[\"comment\",\"comment\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/static-comment-field.hbs"
    }
  });

  _exports.default = _default;
});