define("lh4/templates/components/ui/dropdown-static", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VVqK8dD8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ui-dropdown\",null,[[\"class\",\"selected\",\"onChange\"],[[28,\"concat\",[\"selection \",[24,[\"dropdownClass\"]]],null],[24,[\"selectedValue\"]],[28,\"action\",[[23,0,[]],\"onSelect\"],null]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"text ui-dropdown-static-selected\"],[8],[9],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\\t\\t\"],[14,1,[[28,\"hash\",null,[[\"option\"],[[28,\"component\",[\"ui/dropdown-static/dropdown-static-option\"],[[\"onOptionInsert\",\"onOptionDestroy\"],[[28,\"action\",[[23,0,[]],\"onOptionInsert\"],null],[28,\"action\",[[23,0,[]],\"onOptionDestroy\"],null]]]]]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/dropdown-static.hbs"
    }
  });

  _exports.default = _default;
});