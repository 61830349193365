define("lh4/components/ui/images/image-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showRemove: true,
    size: 'medium',
    link: null,
    date: Ember.computed('link', function () {
      return Date.now();
    }),
    cacheBustedLink: Ember.computed('link', function getter() {
      var newLink;

      if (this.link) {
        try {
          newLink = new URL(this.link);
          newLink.searchParams.append('version', this.date);
        } catch (error) {
          return newLink;
        }
      }

      return newLink;
    }),
    actions: {
      onDelete: function onDelete() {
        this.set('link', null);
        this.sendAction('onDelete');
      }
    }
  });

  _exports.default = _default;
});