define("lh4/templates/components/ui/form-fields/phone-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y/zJoLTf",
    "block": "{\"symbols\":[\"@disabled\",\"@data-test-field\",\"@fullWidth\",\"@property\",\"@changeset\",\"@onChange\",\"@placeholder\",\"@labelWidth\",\"@class\",\"@label\"],\"statements\":[[1,[28,\"ui/form-fields/input-field\",null,[[\"label\",\"class\",\"labelWidth\",\"placeholder\",\"changeset\",\"type\",\"onChange\",\"blurField\",\"property\",\"showErrors\",\"fullWidth\",\"data-test-field\",\"disabled\"],[[23,10,[]],[23,9,[]],[23,8,[]],[23,7,[]],[23,5,[]],\"text\",[23,6,[]],[28,\"action\",[[23,0,[]],\"onBlur\",[23,5,[]]],null],[23,4,[]],true,[23,3,[]],[23,2,[]],[23,1,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/phone-number.hbs"
    }
  });

  _exports.default = _default;
});