define("lh4/utils/period-to-days-array", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = periodToDaysArray;

  /**
   * Returns day dates between two dates
   *
   * @method   periodToDaysArray
   *
   * @param    {Date} from - Date from
   * @param    {Date} to - Date to
   * @returns  {Array} Array containing day dates between from and to
   */
  function periodToDaysArray(from, to) {
    var result = [];
    var current = from;

    while (current <= to) {
      result.push(current);
      current = (0, _moment.default)(current).add(1, 'days').toDate();
    }

    return result;
  }
});