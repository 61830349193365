define("lh4/mirage/routes/echo-pro/day-parts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageEchoProDayPartsServer;

  function setMirageEchoProDayPartsServer(server) {
    server.get('/echo-pro/:locationId/day-parts', function (schema, request) {
      var locationId = request.params.locationId;

      if (locationId.toString() === '104') {
        return [{
          daysOfWeek: ['TUE'],
          periods: [{
            groupGuid: '6666',
            begin: '03:00:00',
            // start time too early
            end: '08:00:00'
          }, {
            groupGuid: '7777',
            begin: '18:00:00',
            end: '30:00:00' // end time too late

          }]
        }];
      }

      return [];
    });
    server.put('/echo-pro/:locationId/day-parts', function (schema, _ref) {
      var requestBody = _ref.requestBody;
      return JSON.parse(requestBody);
    });
  }
});