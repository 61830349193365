define("lh4/templates/authenticated/locations/location/echo-pro/manage-pos/discounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wY/NRaEg",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"modals\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"modal/walkthrough-videos\",null,[[\"key\",\"videos\",\"title\",\"shouldShow\"],[\"discounts\",[24,[\"tutorialVideos\"]],\"Discounts Tutorials\",[28,\"not\",[[24,[\"walkthrough\",\"data\",\"discounts\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/manage-pos/discounts.hbs"
    }
  });

  _exports.default = _default;
});