define("lh4/templates/components/reputation/location-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PLB4O0c7",
    "block": "{\"symbols\":[\"location\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"item\"],[8],[0,\"\\n\"],[4,\"ui-dropdown\",null,[[\"class\",\"action\"],[\"selection\",\"hide\"]],{\"statements\":[[4,\"if\",[[24,[\"location\",\"name\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[24,[\"location\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[1,[22,\"nothingSelectedText\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"locationObjects\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",false],[12,\"class\",\"item\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"locationSelected\",[23,1,[]]]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reputation/location-filter.hbs"
    }
  });

  _exports.default = _default;
});