define("lh4/components/supply-orders/step-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    supplyCart: Ember.inject.service(),
    classNames: ['step-list'],
    init: function init() {
      this._super.apply(this, arguments);

      var supplyCart = this.supplyCart;

      if (supplyCart.locationId === null) {
        this.setProperties({
          disableEquipment: 'step disabled',
          disableSupplies: 'step disabled',
          disableOrder: 'step disabled'
        });
      } else if (supplyCart.equipmentId === null) {
        this.setProperties({
          disableSupplies: 'step disabled',
          disableOrder: 'step disabled'
        });
      } else if (supplyCart.orderDetails.length === 0) {
        this.set('disableOrder', 'step disabled');
      }
    },
    disableEquipment: 'step',
    disableSupplies: 'step',
    disableOrder: 'step'
  });

  _exports.default = _default;
});