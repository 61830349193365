define("lh4/components/bank-account/credentials-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    location: Ember.inject.service(),
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    actions: {
      uploadFile: function uploadFile(file) {
        this.uploadFile(file);
      }
    }
  });

  _exports.default = _default;
});