define("lh4/routes/authenticated/reputation/reviews", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Reputation Reviews',
    requiredPermissions: 'manage reviews for reputation',
    accountApi: Ember.inject.service('reputation/account-api'),
    errorHandler: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        locations: this.store.peekAll('location'),
        accounts: this.accountApi.findAccounts().catch(function (err) {
          _this.errorHandler.handleErrorCustomMessage(err, 'Could not load accounts');

          return [];
        })
      }).then(function (_ref) {
        var locations = _ref.locations,
            accounts = _ref.accounts;
        return {
          accounts: accounts,
          locations: locations.filter(function (_ref2) {
            var id = _ref2.id;
            return accounts.find(function (acc) {
              return "".concat(acc.locationId) === "".concat(id);
            });
          }),
          selectedLocation: null
        };
      });
    }
  });

  _exports.default = _default;
});