define("lh4/models/support/ticket", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    description: {
      description: 'Description',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 10000,
        message: 'Field character limit is 10000'
      })]
    },
    groupId: (0, _emberCpValidations.validator)('presence', true),
    locationId: (0, _emberCpValidations.validator)('presence', true),
    subject: {
      description: 'Subject',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 256,
        message: 'Field character limit is 256'
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    assignee: _emberData.default.attr('string'),
    customerEmail: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    groupId: _emberData.default.attr('number'),
    dateUpdated: _emberData.default.attr('date'),
    description: _emberData.default.attr('string'),
    department: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    subject: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    locationMIDAndName: Ember.computed(function getter() {
      var location = this.store.peekRecord('location', this.locationId);

      if (location) {
        return "".concat(location.merchantId, " - ").concat(location.name);
      }

      return false;
    }),
    comments: _emberData.default.hasMany('support/comment')
  });

  _exports.default = _default;
});