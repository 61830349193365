define("lh4/components/reports/echo-pro/activity-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    elementId: 'activity-summary',
    buckets: Ember.computed('currentReport.buckets', function getter() {
      return this.currentReport.buckets.map(function (bucket) {
        return {
          name: bucket.name,
          reports: bucket.reports.map(function (_ref) {
            var name = _ref.name,
                results = _ref.rows,
                extra = _ref.extra,
                info = _ref.info;
            return {
              name: name,
              results: results,
              extra: extra,
              info: info
            };
          })
        };
      });
    })
  });

  _exports.default = _default;
});