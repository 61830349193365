define("lh4/components/dynamic-checklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dynamic-checklist'],
    columns: 1,
    selectAll: false,
    selected: null,

    /* ID Mode params - pass IDs to `selectedIds` instead of regular object mode with `selected` */
    selectedIds: null,
    idField: null,
    ulStyle: Ember.computed('columns', function getter() {
      var cols = this.columns;
      return Ember.String.htmlSafe("column-count: ".concat(cols, "; -moz-column-count: ").concat(cols, "; -webkit-column-count: ").concat(cols, "; padding-left:0;"));
    }),
    filteredCollection: Ember.computed('filterBy', 'collection', function getter() {
      if (!this.filterBy) {
        return this.collection;
      }

      return this.collection.filterBy('category', this.filterBy);
    }),
    isIdMode: function isIdMode() {
      return this.selectedIds && this.idField;
    },
    findById: function findById(id) {
      var _this = this;

      return this.collection.find(function (col) {
        return "".concat(Ember.get(col, _this.idField)) === "".concat(id);
      });
    },
    selectedObjects: Ember.computed('collection.[]', 'selected.[]', 'selectedIds.[]', function getter() {
      var _this2 = this;

      if (this.isIdMode()) {
        return this.selectedIds.map(function (id) {
          return _this2.findById(id);
        });
      }

      return this.selected;
    }),
    actions: {
      selectAll: function selectAll() {
        var _this3 = this;

        if (this.isIdMode()) {
          return this.set('selectedIds', this.filteredCollection.map(function (element) {
            return Ember.get(element, _this3.idField);
          }));
        }

        return this.set('selected', this.filteredCollection.toArray());
      },
      selectNone: function selectNone() {
        this.set('selected', []);
      },
      alterRelation: function alterRelation(item) {
        if (this.isIdMode()) {
          var itemId = Ember.get(item, this.idField);
          var existing = this.selectedIds.find(function (id) {
            return "".concat(id) === "".concat(itemId);
          });

          if (existing) {
            this.selectedIds.removeObject(existing);
          } else {
            this.selectedIds.pushObject(itemId);
          }

          return;
        }

        if (this.selected.includes(item)) {
          this.selected.removeObject(item);
        } else {
          this.selected.pushObject(item);
        }
      }
    }
  });

  _exports.default = _default;
});