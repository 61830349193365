define("lh4/templates/components/internet-payments/ui/page-top-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zfa8l79M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-top-nav\"],[10,\"data-test-ip\",\"page-top-nav\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"page-top-nav-back\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"circular-button secondary\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"handleBackClick\"]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"chevron left icon\"],[10,\"data-test-ip\",\"page-top-nav-back-button\"],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"h2\",true],[10,\"data-test-ip\",\"page-top-nav-title-name\"],[8],[1,[23,0,[\"args\",\"titleName\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"page-top-nav-buttons\"],[8],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/page-top-nav.hbs"
    }
  });

  _exports.default = _default;
});