define("lh4/controllers/authenticated/add-on-devices/gpatt", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('cacheBust', Date.now());
    },
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    cropperOptions: {
      aspectRatio: 2 / 1
    },
    showErrors: false,
    showRecipientErrors: false,
    recipientValidationMessages: [],
    openRecipient: null,
    ratingsThresholdRecipients: [],
    isLoading: false,

    /**
     * Default Tips Settings
     * ************************* */
    defaultTipOptions: [{
      name: 'Tip 1',
      value: 'tip-1'
    }, {
      name: 'Tip 2',
      value: 'tip-2'
    }, {
      name: 'Tip 3',
      value: 'tip-3'
    }],

    /**
     * Tip calculation method
     * ************************* */
    defaultTipCalculationMethodOptions: [{
      name: 'Include taxes when calculating tip suggestions',
      value: 'post-tax'
    }, {
      name: 'Do not include taxes when calculating tip suggestions',
      value: 'pre-tax'
    }],

    /**
     * Guest Settings
     * ************************* */
    defaultMessageWording: Ember.computed('location.model.name', function getter() {
      return "Please enter your name and email to receive special offers for ".concat(this.location.model.name, ".");
    }),

    /**
     * Guest Ratings Settings
     * ************************* */
    ratingValueOptions: [{
      name: '< 2 Stars',
      value: 2
    }, {
      name: '< 3 Stars',
      value: 3
    }, {
      name: '< 4 Stars',
      value: 4
    }, {
      name: '< 5 Stars',
      value: 5
    }],
    headers: [{
      name: 'Name',
      width: 'four'
    }, {
      name: 'Phone',
      width: 'four'
    }, {
      name: 'Email',
      width: 'six'
    }, {
      name: '',
      width: 'two'
    }],
    recipientsSnapshots: [],
    setRecipientsSnapshots: function setRecipientsSnapshots(recipients) {
      var recipientsSnapshots = recipients.map(function (recipient) {
        return JSON.stringify(recipient.toJSON());
      });
      this.set('recipientsSnapshots', recipientsSnapshots);
    },
    actions: {
      addRecipient: function addRecipient() {
        var recipient = this.model.addRecipient();
        this.set('openRecipient', recipient);
        this.ratingsThresholdRecipients.pushObject(recipient);
      },
      editRecipient: function editRecipient(recipient) {
        this.set('openRecipient', recipient);
      },
      saveRecipient: function saveRecipient() {
        var _this = this;

        var openRecipient = this.openRecipient;
        this.set('showRecipientErrors', true);
        openRecipient.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.isValid && _this.model.validations.attrs['ratings-threshold-recipients'].isValid) {
            // Neither phone nor email are required, but if the user is ready
            // to save these changes we'll want to make sure to deactivate
            // if the fields are empty.
            if (!openRecipient.phone) {
              openRecipient.set('isPhoneActive', false);
            }

            if (!openRecipient.email) {
              openRecipient.set('isEmailActive', false);
            }

            _this.setProperties({
              openRecipient: null,
              showRecipientErrors: false,
              recipientValidationMessages: []
            });
          } else {
            _this.set('recipientValidationMessages', validations.messages.reverse());
          }
        });
      },
      showDeleteRecipientModal: function showDeleteRecipientModal() {
        (0, _jquery.default)('#delete-recipient-modal').modal('show');
      },
      deleteRecipient: function deleteRecipient() {
        var openRecipient = this.openRecipient;

        if (openRecipient.isNew) {
          openRecipient.unloadRecord();
        } else {
          openRecipient.deleteRecord();
        }

        var oldRecords = this.model['ratings-threshold-recipients'].filterBy('isDeleted', false);
        this.model.set('ratings-threshold-recipients', oldRecords);
        this.setProperties({
          openRecipient: null,
          showRecipientErrors: false,
          ratingsThresholdRecipients: this.model['ratings-threshold-recipients'].filterBy('isDeleted', false),
          recipientValidationMessages: []
        });
      },
      beforeCloseRecipient: function beforeCloseRecipient() {
        if (this.openRecipient && this.openRecipient.isDirty) {
          return window.confirm('You have unsaved changes. Are you sure you want to leave this page?');
        }

        return true;
      },
      closeRecipient: function closeRecipient() {
        var openRecipient = this.openRecipient;

        if (openRecipient.isNew) {
          openRecipient.unloadRecord();
        } else {
          openRecipient.rollbackAttributes();
        }

        this.setProperties({
          openRecipient: null,
          showRecipientErrors: false,
          recipientValidationMessages: []
        });
      },
      logoEdit: function logoEdit(_ref2) {
        var _this2 = this;

        var fullBlob = _ref2.fullBlob;
        var locationId = this.get('location.model.id');
        var formData = new FormData();
        formData.append('logo', fullBlob, "".concat(locationId, "-logo.png"));
        return this.ajax.request("/api/v1/gpatt/settings/location/logo/".concat(locationId), {
          method: 'POST',
          data: formData,
          contentType: false,
          processData: false
        }).then(function (response) {
          _this2.set('model.brand-logo-uri', response.uri);

          _this2.set('cacheBust', Date.now());

          _this2.flashMessagesHelper.pushMessage('Logo successfully updated', 'success');
        }).catch(function () {
          _this2.flashMessagesHelper.pushMessage('There was an unexpected error saving your new logo', 'error');

          return Ember.RSVP.reject();
        });
      },
      save: function save() {
        var _this3 = this;

        this.flashMessagesHelper.clearMessages();
        return this.model.validate().then(function (_ref3) {
          var validations = _ref3.validations;

          if (!validations.isValid) {
            _this3.set('showErrors', true);

            _this3.flashMessagesHelper.tryPushValidationResults(validations, 'error', false);

            return false;
          }

          _this3.set('isLoading', true);

          return _this3.model.save().then(function () {
            // Since we're using name as the primary id we want to remove
            // local copies of recipients without an id since a version is coming
            // from the server with the same data, but setting id to the new name.
            var clientRecipients = _this3.model['ratings-threshold-recipients'].filter(function (recipient) {
              return !recipient.id;
            });

            clientRecipients.forEach(function (recipient) {
              return recipient.unloadRecord();
            });

            _this3.flashMessagesHelper.pushMessage('Your SkyTab settings have been saved', 'success');

            _this3.setRecipientsSnapshots(_this3.model['ratings-threshold-recipients']);

            _this3.setProperties({
              openRecipient: null,
              showRecipientErrors: false,
              ratingsThresholdRecipients: _this3.model['ratings-threshold-recipients'].filterBy('isDeleted', false),
              recipientValidationMessages: []
            });

            return false;
          }).catch(function (err) {
            _this3.errorHandler.handleResponseError(err, 'There was an unexpected error when saving your SkyTab settings');
          }).finally(function () {
            _this3.set('isLoading', false);
          });
        }).catch(function () {
          _this3.flashMessagesHelper.pushMessage('There was an unexpected error when validating your SkyTab settings', 'error');
        });
      }
    }
  });

  _exports.default = _default;
});