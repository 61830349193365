define("lh4/services/employee-schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var maxShiftsCountForWeek = 200;

  var _default = Ember.Service.extend({
    shifts: null,
    wrapShifts: function wrapShifts(shiftList) {
      var shifts = shiftList.toArray();
      this.set('shifts', shifts);
      return shifts;
    },
    add: function add(shifts) {
      this.get('shifts').addObjects(shifts);
      return this;
    },
    update: function update(shift) {
      this.get('shifts').removeObject(shift);
      this.get('shifts').addObject(shift);
      return this;
    },
    remove: function remove(shift) {
      this.get('shifts').removeObject(shift);
      return this;
    },
    hasConflictWith: function hasConflictWith(shift) {
      return this.get('shifts').some(function (existing) {
        return existing.hasConflictWith(shift);
      });
    },
    canAdd: function canAdd(shifts) {
      var currentCount = this.get('shifts').length;
      var addCount = shifts.length;
      return currentCount + addCount <= maxShiftsCountForWeek;
    }
  });

  _exports.default = _default;
});