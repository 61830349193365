define("lh4/templates/authenticated/reputation/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8dCIS6Ye",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"flash-messages\",null,[[\"flashMessages\"],[[24,[\"flashMessages\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui basic segment account-header-list\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/reputation/reviews.hbs"
    }
  });

  _exports.default = _default;
});