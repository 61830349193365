define("lh4/components/ui/buttons/sub-account-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    loading: false,
    resendRegistration: false,
    actions: {
      resendConfEmail: function resendConfEmail(userId) {
        var _this = this;

        this.set('loading', true);
        var url = "/api/v1/accounts/".concat(this.get('tokenData.data.sub'), "/sub-account/").concat(userId, "/resend");
        this.ajax.request(url, {
          type: 'POST'
        }).then(function () {
          _this.set('loading', false);

          _this.set('resendRegistration', true);

          _this.flashMessages.success('Registration Email Sent', {
            class: 'ui success message'
          });

          setTimeout(function () {
            _this.set('resendRegistration', false);
          }, 3000);
        }).catch(function (e) {
          _this.set('loading', false);

          _this.flashMessages.danger("Information failed to save: ".concat(e), {
            class: 'ui error message'
          });
        });
      }
    }
  });

  _exports.default = _default;
});