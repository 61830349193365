define("lh4/utils/build-child-changeset", ["exports", "ember-changeset", "lh4/utils/validations"], function (_exports, _emberChangeset, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildChildChangeset;

  /**
   * Builds changeset
   *
   * @param {Model} model Ember store model
   * @returns {Changeset} Ember store changeset
   */
  function buildChildChangeset(model) {
    var _buildChangesetDepend = (0, _validations.buildChangesetDependentValidations)(model),
        validateFn = _buildChangesetDepend.validateFn,
        validationMap = _buildChangesetDepend.validationMap;

    return new _emberChangeset.default(model, validateFn, validationMap, {
      skipValidate: true
    });
  }
});