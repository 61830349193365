define("lh4/mirage/routes/users/location", ["exports", "lh4/mirage/fixtures/users/location"], function (_exports, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageUsersLocationServer;

  function setMirageUsersLocationServer(server) {
    server.get('/users/locations', {
      locations: _location.default
    });
  }
});