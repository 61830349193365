define("lh4/templates/authenticated/locations/location/echo-pro/reservations/reservation/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SDZ47aCd",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"reservations/reservation-details\",null,[[\"type\",\"save\",\"clear\",\"isLoading\",\"notifyCustomer\",\"cancelReservation\",\"model\",\"settings\"],[\"edit\",[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"clear\"],null],[24,[\"isLoading\"]],[28,\"action\",[[23,0,[]],\"notifyCustomer\"],null],[28,\"action\",[[23,0,[]],\"cancelReservation\"],null],[24,[\"model\",\"reservation\"]],[24,[\"model\",\"settings\"]]]]],false],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"ui-modal\",null,[[\"id\",\"centered\",\"onApprove\"],[\"reservation-changed\",false,[28,\"action\",[[23,0,[]],\"discardChanges\"],null]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n\\t\\tReservation Changed\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column center aligned\"],[8],[0,\"\\n\\t\\t\\t\\tYou made some changes to the reservation.  You can go back and update or you can discard your changes.\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"actions\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column right aligned\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui deny button\"],[8],[0,\"Go Back\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui red approve button\"],[8],[0,\"Discard Changes\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/reservations/reservation/edit.hbs"
    }
  });

  _exports.default = _default;
});