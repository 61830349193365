define("lh4/routes/authenticated/gift-card-orders", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Gift Card Order Management',
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    giftCard: Ember.inject.service('gift-card'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('manage virtual-gift-card in general')) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    model: function model() {
      var locationId = this.location.model.id;
      return Ember.RSVP.hash({
        locationId: locationId,
        giftCardIframe: this.giftCard.getGiftCardIframeData(locationId)
      });
    }
  });

  _exports.default = _default;
});