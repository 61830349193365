define("lh4/serializers/solo/settings", ["exports", "lh4/utils/currency/converter", "lh4/serializers/application"], function (_exports, _converter, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FIELDS_IN_CENTS = ['fastPayOne', 'fastPayTwo', 'fastPayThree', 'offlineAmountLimit', 'tipThresholdOptionOne', 'tipThresholdOptionTwo', 'tipThresholdOptionThree', 'tipThresholdAmount'];

  var _default = _application.default.extend({
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'solo/settings';
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attributes);
      }
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      FIELDS_IN_CENTS.forEach(function (field) {
        if (json[field]) {
          json[field] = (0, _converter.toCents)(json[field]);
        }
      });
      return json;
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;
      FIELDS_IN_CENTS.forEach(function (field) {
        json[field] = (0, _converter.toDollars)(json[field]);
      });

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    }
  });

  _exports.default = _default;
});