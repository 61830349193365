define("lh4/mixins/components/outside-event-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * General purpose Mixin for handling browser events (ex. click, mousedown) that happen outside of the component
   */
  var _default = Ember.Mixin.create({
    outsideEventType: 'click',
    outsideEventHandler: null,
    verifyOutsideEvent: function verifyOutsideEvent(event) {
      var $element = this.$();
      var $target = (0, _jquery.default)(event.target);

      if (!$target.closest($element).length) {
        this.send('handleOutsideEvent');
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.outsideEventHandler = this.get('verifyOutsideEvent').bind(this);
      (0, _jquery.default)(document).on(this.outsideEventType, this.outsideEventHandler);
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(document).off(this.outsideEventType, this.outsideEventHandler);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});