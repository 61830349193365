define("lh4/serializers/reservations/block", ["exports", "moment", "lh4/enums/reservations", "lh4/serializers/reservations/application"], function (_exports, _moment, _reservations, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    timeFormat: 'hh:mm:ss',
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if ((0, _moment.default)(json.endsAt).format(this.timeFormat) === (0, _moment.default)().endOf('day').format(this.timeFormat)) {
        json.endsAt = (0, _moment.default)(json.endsAt).startOf('day').toISOString();
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      var partOfDayUpper = json.partOfDay.toUpperCase();

      if (!json.isEnabled && (0, _moment.default)(json.startsAt).isSameOrAfter((0, _moment.default)(json.endsAt)) && _reservations.default[partOfDayUpper]) {
        // There is a constraint in DB that requires reservation block startsAt to be before endsAt.
        // This place manualy resets time to default values when block is not enabled.
        var defaultStartDate = _reservations.default[partOfDayUpper].START;
        var defaultEndDate = _reservations.default[partOfDayUpper].END;
        json.startsAt = (0, _moment.default)(defaultStartDate).toISOString();
        json.endsAt = (0, _moment.default)(defaultEndDate).toISOString();
      }

      if ((0, _moment.default)(json.endsAt).format(this.timeFormat) === (0, _moment.default)().startOf('day').format(this.timeFormat)) {
        json.endsAt = (0, _moment.default)(json.endsAt).endOf('day').toISOString();
      }

      return json;
    }
  });

  _exports.default = _default;
});