define("lh4/models/processing/customer/address", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/utils/states", "lh4/utils/array-join"], function (_exports, _emberData, _attr, _relationships, _emberCpValidations, _states, _arrayJoin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.processingAddressTypes = _exports.default = void 0;

  var _Ember;

  var processingAddressTypes = [{
    name: 'Primary'
  }, {
    name: 'Alternate'
  }, {
    name: 'Work'
  }, {
    name: 'Other'
  }];
  _exports.processingAddressTypes = processingAddressTypes;
  var KEY_ATTRIBUTES = ['streetAddress1', 'streetAddress2', 'city', 'state', 'postalCode'];
  var Validations = (0, _emberCpValidations.buildValidations)({
    city: {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    streetAddress1: {
      description: 'Street Address 1',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    streetAddress2: {
      description: 'Street Address 2',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    postalCode: {
      description: 'ZIP',
      validators: [(0, _emberCpValidations.validator)('zip-code', {
        allowBlank: true
      })]
    },
    state: {
      description: 'State',
      validators: [(0, _emberCpValidations.validator)(function (value) {
        return !value || (0, _states.isValidStateName)(value) || 'State has an incorrect format';
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    city: (0, _attr.default)('string'),
    streetAddress1: (0, _attr.default)('string'),
    streetAddress2: (0, _attr.default)('string'),
    postalCode: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    customer: (0, _relationships.belongsTo)('processing/customer'),
    createdAt: (0, _attr.default)('date'),
    addressTypes: Ember.computed(function () {
      return processingAddressTypes.map(function (_ref) {
        var name = _ref.name;
        return {
          name: name,
          value: name
        };
      });
    }),
    full: (_Ember = Ember).computed.apply(_Ember, KEY_ATTRIBUTES.concat([function getter() {
      return (0, _arrayJoin.default)(this, KEY_ATTRIBUTES, ', ');
    }])),
    contains: function contains(term) {
      var _this = this;

      return KEY_ATTRIBUTES.some(function (attribute) {
        return (_this.get(attribute) || '').toLowerCase().includes(term);
      });
    }
  });

  _exports.default = _default;
});