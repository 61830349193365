define("lh4/controllers/reset-password", ["exports", "jquery", "lh4/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['token'],
    session: Ember.inject.service(),
    ENV: _environment.default,
    password1: '',
    password2: null,
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    branding: _environment.default.branding,
    isLoading: false,
    class: Ember.computed('isLoading', 'isPasswordValid', function getter() {
      if (this.isLoading) {
        return 'disabled ui fluid large primary blue submit loading button';
      }

      if (this.isPasswordValid) {
        return 'ui fluid large primary blue submit button';
      }

      return 'disabled ui fluid large primary blue submit button';
    }),
    actions: {
      updatePassword1: function updatePassword1(password1) {
        this.set('password1', password1);
      },
      updatePassword2: function updatePassword2(password2) {
        this.set('password2', password2);
      },
      onPasswordValidationUpdate: function onPasswordValidationUpdate(isPasswordValid) {
        this.setProperties({
          isPasswordValid: isPasswordValid
        });
      },
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('isLoading', true);

        if (this.password1 !== this.password2) {
          this.flashMessages.danger('Passwords need to match', {
            class: 'ui error message'
          });
          (0, _jquery.default)('.password-input-container').addClass('error');
          this.set('isLoading', false);
          return;
        }

        this.ajax.request('/api/v1/auth/reset-password', {
          type: 'POST',
          data: JSON.stringify({
            token: this.token,
            password: this.get('password1')
          })
        }).then(function () {
          (0, _jquery.default)('.password-input-container').removeClass('error');

          _this.set('isLoading', false);

          _this.flashMessages.success('Password successfully reset', {
            class: 'ui success message'
          });

          setTimeout(function () {
            _this.transitionToRoute('sign-in');
          }, 1500);
        }).catch(function () {
          _this.set('isLoading', false);

          _this.flashMessages.danger('An error occured when reseting your password', {
            class: 'ui error message'
          });
        });
      }
    }
  });

  _exports.default = _default;
});