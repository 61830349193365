define("lh4/mirage/routes/corporate/deploy-analysis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageCorporateDeploymentAnalysisServer;

  function setMirageCorporateDeploymentAnalysisServer(server) {
    server.get('/corporations/:corpId/deployment/:locationId', function () {
      return [{
        model: 'items',
        result: {
          addedEntities: [{
            locationEntity: null,
            corporateEntity: {
              guid: '2eaaf820-3391-4f3b-9965-a894fa2980d2',
              name: 'Corporate item',
              price: '22.00'
            }
          }],
          editedEntities: [{
            corporateEntity: {
              guid: '180572fd-3fe0-4051-b18f-ab8afad1b211',
              name: 'Item 1',
              price: '12.00'
            },
            locationEntity: {
              guid: 'f3b146c1-353f-4308-93c0-487d72edf291',
              name: 'Item 1-1',
              price: '13.00'
            }
          }, {
            corporateEntity: {
              guid: '855382e2-188b-4e7e-9d3c-6e324f8696e3 ',
              name: 'Item 2',
              price: '12.00'
            },
            locationEntity: {
              guid: 'd4963f38-3022-4b4f-853a-0e5142a5f546',
              name: 'Item 2-2',
              price: '14.00'
            }
          }, {
            corporateEntity: {
              guid: '855382e2-188b-4e7e-9d3c-6e324f8696e3 ',
              name: 'Item 4',
              price: '12.00'
            },
            locationEntity: {
              guid: 'd4963f38-3022-4b4f-853a-0e5142a5f546',
              name: 'Item 4',
              price: '12.00'
            }
          }],
          deletedEntities: [{
            corporateEntity: null,
            locationEntity: {
              guid: 'ffd65a71-f1a1-4f27-815c-05f972d4e90a',
              name: 'Item to delete',
              price: '2.00'
            }
          }, {
            corporateEntity: null,
            locationEntity: {
              guid: '861acc1f-7c4c-47d8-b445-636bd81ddc64',
              name: 'Item to delete 2',
              price: '3.00'
            }
          }, {
            corporateEntity: null,
            locationEntity: {
              guid: '9a883dcd-8cf4-4c48-bd4a-56ebc985da7a ',
              name: 'Item to delete 3',
              price: '32.00'
            }
          }]
        }
      }];
    });
  }
});