define("lh4/routes/authenticated/locations/location/day-parts/edit", ["exports", "lh4/routes/general-edit-route"], function (_exports, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalEditRoute.default.extend({
    corporation: Ember.inject.service(),
    router: Ember.inject.service(),
    modelName: 'intradayPeriodGroup',
    beforeModel: function beforeModel() {
      if (this.corporation.isMenuManagedByCorporate) {
        this.router.transitionTo('authenticated.locations.location.day-parts');
      }
    },
    model: function model(_ref) {
      var categoryGuid = _ref.categoryGuid;
      return Ember.RSVP.hash({
        intradayPeriodGroup: this.store.findRecord('echo-pro/intraday-period-group', categoryGuid)
      });
    }
  });

  _exports.default = _default;
});