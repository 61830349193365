define("lh4/mixins/changeset-transition-route-mixin", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    confirmMessage: 'You have unsaved changes. Are you sure you want to leave this page?',
    hasDirtyChangesets: function hasDirtyChangesets() {
      var _this$controller = this.controller,
          changeset = _this$controller.changeset,
          childChangesetsSnapshotMap = _this$controller.childChangesetsSnapshotMap,
          modelName = this.modelName,
          childChangesets = this.childChangesets;

      if (changeset.isDirty) {
        return true;
      } // skip checking of child changesets if modelName & childChangesets are not set on route


      if (!childChangesets || !modelName) {
        return false;
      }

      return Object.keys(childChangesets).some(function (childModelName) {
        var childChangesetName = childChangesets[childModelName];

        if (changeset[childChangesetName].some(function (childChangeset) {
          return childChangeset.isDirty;
        })) {
          return true;
        }

        var previousVersions = childChangesetsSnapshotMap[childChangesetName]; // check if any changeset was added/removed

        if (changeset[childChangesetName].length !== previousVersions.length) {
          return true;
        } // if lengths match, check if both previous and current versions of childChangesets don't match


        var currentVersions = changeset[childChangesetName].map(function (c) {
          return JSON.stringify(c.data.toJSON());
        });
        return currentVersions.some(function (c) {
          return !previousVersions.includes(c);
        });
      });
    },
    rollbackChanges: function rollbackChanges() {
      if (this.controller.clearTemporaryData) {
        this.controller.clearTemporaryData();
      } // effectively rollback all changes


      this.setupController(this.controller, this.controller.model);
    },
    setupController: function setupController(controller) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [controller].concat(args)); // Changeset needs to be defined when used. Unfortunately because
      // we don't know what the model is at the mixin level we can't
      // assume that the model will have be suitable for a changeset
      // Below is a template for creating a changeset that will work with
      // our model validation functions and prevent validation until
      // the user first clicks save to validate.
      // const { validateFn, validationMap } = buildChangeset(model);
      // const changeset = new Changeset(model, validateFn, validationMap, { skipValidate: true });


      controller.setProperties({
        showConfirmation: false,
        showErrors: false,
        onBeforeClose: function onBeforeClose() {
          if (!_this.hasDirtyChangesets()) {
            return true;
          }

          if (window.confirm(_this.confirmMessage)) {
            _this.rollbackChanges();

            return true;
          }

          return false;
        }
      });
    },
    actions: {
      // prevent accidental form closing without saving
      willTransition: function willTransition(transition) {
        // show form closing notification if:
        if ( // transitioning into different route
        (transition.from.name !== transition.targetName // or just route params are different e. g. switching to different item edit form
        || transition.from.params !== transition.to.params) && // and form is not in saving state
        !this.controller.isSaving // and if attributes are just dirty
        && this.hasDirtyChangesets() // and if user cancels notification abort transition
        && !window.confirm(this.confirmMessage)) {
          // TODO: When we decide to pass this down to the controller
          // we'll also want to save and pass along the transition.
          // This would ne to be handled differently than a close
          // since we would be delaying navigation, cleaning the dirty
          // state of our changeset and than reattempting the transition.
          transition.abort();
          return;
        }

        if (this.controller.clearTemporaryData) {
          this.controller.clearTemporaryData();
        } // NOTE: So this guy is a bit tricksy. Since we are using a modal/tray
        // for create/edit pages, we often don't leave the list page.
        // Unfortunately if we're using this.store.query, we don't have a
        // clear layer to have this parent route know about added/removed
        // records. This is more of a problem once we factor in sorting
        // pagination and filtering happening on the server side.
        // There are likely times this refresh is not needed, but we kept it
        // here mainly to avoid repetition and keep this pattern limited


        Ember.getOwner(this).lookup("route:".concat(transition.to.parent.name)).refresh();
      }
    }
  });

  _exports.default = _default;
});