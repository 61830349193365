define("lh4/templates/components/customer-engagement/campaign-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zA/PbPCB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui fluid container campaign\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segments\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui center aligned three column grid\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"left aligned column\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showBackToList\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"ui left labeled icon button\",\"authenticated.customer-engagement\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"left arrow icon\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\\tBack\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"middle aligned column\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"h4\",true],[10,\"class\",\"ui header\"],[8],[1,[28,\"if\",[[24,[\"campaign\",\"isNew\"]],\"New Campaign\",\"Edit campaign\"],null],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\\t\\t\"],[14,1],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/customer-engagement/campaign-container.hbs"
    }
  });

  _exports.default = _default;
});