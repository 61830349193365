define("lh4/templates/components/internet-payments/ui/shared/yield-basic-arg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b4Gsv+Bx",
    "block": "{\"symbols\":[\"@arg\",\"&default\",\"@namedBlocksInfo\"],\"statements\":[[4,\"if\",[[28,\"-has-block\",[[23,3,[]],\"default\",[25,2]],null]],null,{\"statements\":[[0,\"\\t\"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/shared/yield-basic-arg.hbs"
    }
  });

  _exports.default = _default;
});