define("lh4/routes/authenticated/internet-payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    location: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.location.hasInternetPaymentProduct) {
        this.transitionTo('/');
      }
    }
  });

  _exports.default = _default;
});