define("lh4/mirage/factories/department", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: 'Appetizers',
    guid: function guid() {
      return _faker.default.datatype.uuid();
    }
  });

  _exports.default = _default;
});