define("lh4/templates/components/ui/field-selector-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0LAMJkmX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"field-selector\"],[11,\"data-test-field-selector-group\",[22,\"groupName\"]],[8],[0,\"\\n\\t\"],[1,[28,\"ui-checkbox\",null,[[\"checked\",\"onChange\",\"disabled\"],[[24,[\"checked\"]],[28,\"action\",[[23,0,[]],\"handleChange\"],null],[24,[\"disabled\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"field-main ui grid\"],[8],[0,\"\\n\\t\"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/field-selector-group.hbs"
    }
  });

  _exports.default = _default;
});