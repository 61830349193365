define("lh4/models/preferences", ["exports", "ember-data", "lh4/enums/date-range-title"], function (_exports, _emberData, _dateRangeTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    currentOrLastDay: _emberData.default.attr('string', {
      defaultValue: _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST
    }),
    tableOrChart: _emberData.default.attr('string', {
      defaultValue: 'Chart'
    }),
    weekStart: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    eod: _emberData.default.attr('subscription-time'),
    timeZone: _emberData.default.attr('string'),
    oldDashboard: _emberData.default.attr('boolean'),
    dashboardOrder: _emberData.default.attr(),
    defaultLocationId: _emberData.default.attr('number'),
    internetPayments: _emberData.default.attr()
  });

  _exports.default = _default;
});