define("lh4/routes/authenticated/customer-engagement/campaigns/overview/activity/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model(_ref) {
      var _this = this;

      var customerId = _ref.customerId;

      var getCustomerByProduct = function getCustomerByProduct(_ref2) {
        var _ref2$customer = _ref2.customer,
            product = _ref2$customer.product,
            externalId = _ref2$customer.externalId;
        return Ember.RSVP.hash({
          product: product,
          customer: _this.store.findRecord("".concat(product, "/customer"), externalId)
        });
      };

      return this.ajax.request("/api/v1/customer-engagement/customers/".concat(customerId)).then(getCustomerByProduct);
    }
  });

  _exports.default = _default;
});