define("lh4/mirage/fixtures/ticket-details-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TICKET_DETAILS_DOWNLOAD = {
    guid: '14d1af46-9487-467d-bcde-a30143eea699',
    url: 'https://test.com'
  };
  var _default = TICKET_DETAILS_DOWNLOAD;
  _exports.default = _default;
});