define("lh4/routes/authenticated/locations/location/echo-pro/employee-schedule/publish/email", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var location = this.modelFor('authenticated.locations.location');

      var _this$modelFor = this.modelFor('authenticated.locations.location.echo-pro.employee-schedule'),
          employees = _this$modelFor.employees,
          range = _this$modelFor.range;

      return {
        employees: employees,
        range: range,
        location: location
      };
    }
  });

  _exports.default = _default;
});