define("lh4/components/dashboard-widgets/ip-notifications/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['ip-notifications-data', 'table-widget'],
    widgetSlug: 'ip-notifications',
    skeletonData: []
  });

  _exports.default = _default;
});