define("lh4/routes/authenticated/supply-orders/location", ["exports", "lh4/enums/country-code", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin"], function (_exports, _countryCode, _lighthousePermissionsMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'view order',
    supplyCart: Ember.inject.service(),
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    router: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var address = this.store.createRecord('supply-orders/address');
      var supplyCart = this.supplyCart;
      return this.location.loadLocation(params.locationId).then(function (record) {
        _this.supplyCart.selectLocation(record);

        if (record.countryCode !== _countryCode.default.US) {
          _this.router.transitionTo('/');
        }

        return record;
      }).then(function () {
        var data = {
          street1: _this.get('location.model.addressLine1'),
          street2: _this.get('location.model.addressLine2'),
          city: _this.get('location.model.city'),
          state: _this.get('location.model.state'),
          zip: _this.get('location.model.zip'),
          name: _this.get('location.model.name'),
          phone: _this.get('location.model.phone'),
          email: _this.get('location.model.email')
        };
        return _this.ajax.request('/api/v1/supply-orders/addresses', {
          type: 'POST',
          data: JSON.stringify({
            address: data
          })
        }).then(function (locationObj) {
          address.setProperties({
            id: locationObj.address.id,
            addressId: locationObj.address.id,
            name: locationObj.address.name,
            street1: locationObj.address.street1,
            street2: locationObj.address.street2,
            city: locationObj.address.city,
            state: locationObj.address.state,
            zip: locationObj.address.zip,
            email: locationObj.address.email,
            extendedZip: locationObj.address.extendedZip,
            phone: locationObj.address.phone,
            isCommercial: locationObj.address.isCommercial,
            isValid: locationObj.address.isValid,
            candidates: locationObj.address.candidates
          });
          supplyCart.set('addressId', locationObj.address.id);
        }).catch(function () {
          _this.flashMessages.danger('Location information request failed', {
            class: 'ui error message'
          });

          _this.set('loading', false);
        });
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var locationId = this.supplyCart.locationId;

        if (locationId === null) {
          if (transition.targetName === 'supply-orders.location.equipment' || transition.targetName === 'supply-orders.location.supplies' || transition.targetName === 'supply-orders.location.order') {
            transition.abort();
          }
        }
      }
    }
  });

  _exports.default = _default;
});