define("lh4/authenticators/sso", ["exports", "lh4/config/environment", "lh4/authenticators/jwt-custom"], function (_exports, _environment, _jwtCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwtCustom.default.extend({
    init: function init() {
      this._super.apply(this, arguments); // overwrite the endpoint


      this.serverTokenEndpoint = "".concat(_environment.default.host, "/api/v1/auth/sso");
    },
    getAuthenticateData: function getAuthenticateData(credentials) {
      var authentication = {
        token: credentials.token
      };
      return authentication;
    }
  });

  _exports.default = _default;
});