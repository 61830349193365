define("lh4/helpers/flat-query-params", ["exports", "flat"], function (_exports, _flat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flatQueryParams = flatQueryParams;
  _exports.default = void 0;

  /*
   * This helper mimics the built-in `query-params` helper, but is slightly different.
   * This will take any nested objects and flatten them. This is for routes that have qps
   * that look like: `dates.startsAtTime=12:00AM`. We specifically do this in reports.
   *
   * This is kind of a hack since I had to look into the ember internals to see how the
   * `query-params` helper actually works and what it returns. Be wary when upgrading ember
   * to make sure this didn't break.
   *
   * References:
   * https://github.com/emberjs/ember.js/blob/41f1657f68735f214efeaa96eac96e8e093b4981
   *   /packages/%40ember/-internals/glimmer/lib/helpers/query-param.ts#L26
   * https://github.com/emberjs/ember.js/blob/41f1657f68735f214efeaa96eac96e8e093b4981
   *   /packages/%40ember/-internals/routing/lib/system/query_params.ts
   */
  function flatQueryParams(params, hash) {
    var values = (0, _flat.default)(hash, {
      safe: true
    }); // preserve arrays

    return {
      isQueryParams: true,
      values: values
    };
  }

  var _default = Ember.Helper.helper(flatQueryParams);

  _exports.default = _default;
});