define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/reservation/create", ["exports", "lh4/mixins/edit-controller-mixin"], function (_exports, _editControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_editControllerMixin.default, {
    modelName: 'model.reservation',
    actions: {
      afterSaveHook: function afterSaveHook() {
        var _this = this;

        this.get('model.reservations').addObject(this.get('model.reservation'));
        setTimeout(function () {
          _this.transitionToRoute('authenticated.locations.location.echo-pro.reservations.reservation.edit', _this.get('model.reservation.id'));
        }, 500);
      },
      clear: function clear() {
        // We're overriding EditControllerMixin.actions.clear here since
        // calling rollbackAttributes on a new object would destroy the object.
        // It is possible to handle this scenario in edit-controller-mixin,
        // but this should likely be a separate ticket to include proper testing
        // since it's a very common mixin.
        this.get('model.reservation').destroyRecord();
        this.set('model.reservation', this.store.createRecord('reservation'));
        this.flashMessagesHelper.clearMessages().pushMessage('Changes have been discarded', 'success');
      }
    }
  });

  _exports.default = _default;
});