define("lh4/routes/authenticated/reporting/subscriptions", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    ajax: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    productAvailability: Ember.inject.service('reports/product-availability'),
    requiredPermissions: 'manage report subscriptions in general',
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('create own subscriptions in general')) {
        this.transitionTo('authenticated.reporting.index');
      }

      if (window.location.pathname === '/settings/account') {
        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'subscriptions',
          eventAction: 'redirect',
          eventLabel: 'Subscription redirecting from Settings to Reporting'
        });
      }
    },
    model: function model() {
      var _this$modelFor = this.modelFor('authenticated'),
          locations = _this$modelFor.locations;

      var types = ['processing'];
      locations.forEach(function (location) {
        var product = location.product;

        if (!types.includes(product) && product !== 'terminal') {
          types.push(product);
        }
      });
      var products = this.productAvailability.loadAdditionalProducts(locations.map(function (loc) {
        return loc.id;
      })).catch(function () {
        return [];
      }) // continue on error
      .then(function (additionalProducts) {
        return [].concat(types, _toConsumableArray(additionalProducts)).sort();
      });
      return Ember.RSVP.hash({
        locations: locations,
        products: products,
        reportTypes: this.store.queryRecord('report', {
          locationIds: [this.location.model.id]
        }),
        subscriptions: this.store.findAll('reports/subscription')
      });
    }
  });

  _exports.default = _default;
});