define("lh4/serializers/echo-pro/external-order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'origin',
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, payload.externalOrderSources, id, requestType);
    }
  });

  _exports.default = _default;
});