define("lh4/templates/components/internet-payments/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RL6krqdR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"ip-credit-card \",[23,0,[\"cardClass\"]]]]],[10,\"data-test-ip\",\"credit-card\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"args\",\"issuerBIN\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"card-number\"],[10,\"data-test-ip\",\"card-number\"],[8],[1,[23,0,[\"args\",\"issuerBIN\"]],false],[0,\"****\"],[1,[23,0,[\"args\",\"cardNumber\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[10,\"class\",\"card-number\"],[10,\"data-test-ip\",\"card-number\"],[8],[0,\"*\"],[1,[23,0,[\"args\",\"cardNumber\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/credit-card.hbs"
    }
  });

  _exports.default = _default;
});