define("lh4/templates/components/internet-payments/ui/dropdown/shared/dropdown-list-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f2b3I54W",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"list-container\"],[8],[0,\"\\n\\t\"],[7,\"h3\",true],[10,\"class\",\"title\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"],[0,\"\\t\"],[14,2],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/dropdown/shared/dropdown-list-container.hbs"
    }
  });

  _exports.default = _default;
});