define("lh4/components/hardware/no-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="no-image"></div>
  */
  {
    id: "NkcPTzte",
    block: "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"no-image\"],[8],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/hardware/no-image.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});