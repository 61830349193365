define("lh4/controllers/authenticated/settings/users/create-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    responseMessages: Ember.inject.service(),
    modelName: 'model.user',
    loading: false,
    actions: {
      save: function save(permissionSets, normalizedPermissionSets) {
        var _this = this;

        if (this.loading) {
          return;
        }

        this.set('loading', true);
        this.set('changeset.permissionSets', permissionSets);
        this.flashMessagesHelper.clearMessages();
        this.changeset.validate().then(function () {
          if (!_this.changeset.isValid) {
            _this.set('loading', false);

            return Ember.RSVP.reject(_this.changeset.errors);
          }

          return _this.changeset;
        }).then(function (changeset) {
          return changeset.save({
            adapterOptions: {
              normalizedPermissionSets: normalizedPermissionSets
            }
          }).then(function () {
            _this.flashMessagesHelper.pushMessage('User profile saved', 'success');

            Ember.run.later(_this, function () {
              _this.send('refresh');

              _this.transitionToRoute('authenticated.settings.users');
            }, 1500);
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (errors && errors[0]) {
              var userCreationError = 'Something went wrong, user could not be created.';
              errors.forEach(function (error) {
                var err = error.message || userCreationError;

                _this.flashMessagesHelper.pushMessage(err);
              });
            }
          }).finally(function () {
            return _this.set('loading', false);
          });
        }).catch(function (validations) {
          if (validations.length) {
            validations.forEach(function (v) {
              _this.flashMessagesHelper.pushMessage(v.validation, 'error');
            });
          }
        });
      },
      modalDeny: function modalDeny() {
        if (this.get('model.user.isNew')) {
          this.get('model.user').deleteRecord();
        }

        this.transitionToRoute('authenticated.settings.users');
      }
    }
  });

  _exports.default = _default;
});