define("lh4/classes/locations/location/echo-pro/employee-schedule/publish/employee-with-editable-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmployeeWithEditableEmail = Ember.ObjectProxy.extend({
    init: function init(_ref) {
      var content = _ref.content;
      this.set('editEnabled', false);
      this.set('isSaving', false);
      this.set('content', content);
      this.set('hasEmail', !!content.get('email'));
    }
  });
  var _default = EmployeeWithEditableEmail;
  _exports.default = _default;
});