define("lh4/components/tables/reservation-block-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    compressArray: function compressArray(original) {
      var compressed = []; // make a copy of the input array

      var copy = original.slice(0); // first loop goes over every element

      for (var i = 0; i < original.length; i += 1) {
        var myCount = 0; // loop over every element in the copy and see if it's the same

        for (var w = 0; w < copy.length; w += 1) {
          if (original[i] === copy[w]) {
            // increase amount of times duplicate is found
            myCount += 1; // sets item to undefined

            delete copy[w];
          }
        }

        if (myCount > 0) {
          var a = {};
          a.value = original[i];
          a.count = myCount;
          compressed.push(a);
        }
      }

      return compressed;
    },
    classNames: ['ui three column stackable grid reservation-block-table'],
    blockType: ['Breakfast', 'Lunch', 'Dinner'],
    disabled: false,
    day: 'Sunday',
    filterByDay: Ember.computed('day', 'model', function getter() {
      return this.get('model.block').filterBy('dayOfWeek', this.get('day'));
    }),
    dayBlockSort: Ember.computed.sort('filterByDay', function sortCallback(a, b) {
      var blockType = this.get('blockType');

      if (blockType.indexOf(a.get('partOfDay')) > blockType.indexOf(b.get('partOfDay'))) {
        return 1;
      }

      if (blockType.indexOf(a.get('partOfDay')) < blockType.indexOf(b.get('partOfDay'))) {
        return -1;
      }

      return 0;
    }),
    tableTypes: Ember.computed('model.table.[]', function getter() {
      var tableTypes = [];
      this.model.table.forEach(function (table) {
        if (table.get('shape') !== 'label' && table.get('isActive')) {
          tableTypes.push(table.get('maxOccupants'));
        }
      });
      return this.compressArray(tableTypes).sort(function (a, b) {
        return a.value - b.value;
      });
    })
  });

  _exports.default = _default;
});