define("lh4/components/ui/form-fields/file-upload-field", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    classNames: ['file-upload'],
    buttonText: 'Upload',
    dataTest: 'form-field-file-upload-field',
    actions: {
      onFileUpload: function onFileUpload(event) {
        var _this = this;

        var reader = new FileReader();
        var file = event.target.files[0];

        reader.onload = function () {
          _this.uploadFile(file);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
  });

  _exports.default = _default;
});