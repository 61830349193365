define("lh4/routes/authenticated/customer-engagement/campaigns/create", ["exports", "lh4/mixins/routes/customer-engagement/edit-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin"], function (_exports, _editRouteMixin, _lighthousePermissionsMixin, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line require-jsdoc
  function createNewCampaign(context, clone) {
    if (clone) {
      var originalCampaign = context.store.peekRecord('customer-engagement/campaign', clone);
      return originalCampaign.cloneShallow({
        guid: null,
        scheduleTime: null,
        scheduleRepeat: null,
        scheduleRepeatTz: null,
        scheduleRepeatStart: null,
        scheduleRepeatEnd: null
      });
    }

    return context.store.createRecord('customer-engagement/campaign');
  }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _editRouteMixin.default, {
    requiredPermissions: 'manage campaigns for customer-engagement',
    routeSuffix: 'create',
    queryParams: {
      clone: {
        refreshModel: true
      }
    },
    permissions: Ember.inject.service(),
    model: function model(_ref) {
      var clone = _ref.clone;
      return Ember.RSVP.hash({
        clone: clone,
        campaign: createNewCampaign(this, clone),
        templates: this.store.findAll('customer-engagement/campaign-template'),
        locations: this.modelFor('authenticated.customer-engagement').locations
      });
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      controller.set('step', model.clone ? 1 : 0);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // do not use sticky query params
        // https://guides.emberjs.com/v3.15.0/routing/query-params/#toc_sticky-query-param-values
        controller.set('clone', undefined);
      }
    }
  });

  _exports.default = _default;
});