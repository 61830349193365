define("lh4/templates/components/social-media/account-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tFugGsJD",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"social-media/account-image\",null,[[\"src\",\"account\"],[[24,[\"account\",\"pictureUri\"]],[24,[\"account\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\"],[1,[24,[\"account\",\"name\"]],false],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"sub header\"],[8],[1,[24,[\"account\",\"externalName\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"eq\",[[24,[\"account\",\"isConfiguredProperly\"]],false],null],[24,[\"showWarning\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"social-media/link-configuration-warning\",null,[[\"account\",\"isWarningTooltip\",\"namespace\"],[[24,[\"account\"]],[24,[\"isWarningTooltip\"]],\"social-media\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/social-media/account-header.hbs"
    }
  });

  _exports.default = _default;
});