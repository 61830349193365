define("lh4/components/link-to-outbound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    attributeBindings: ['href', 'target', 'rel'],
    tagName: 'a',
    target: '_blank',
    rel: 'noopener',
    href: Ember.computed('params.[]', function getter() {
      return this.params && this.params.join('');
    }),
    eventCategory: 'Outbound Link',
    eventAction: 'click',
    eventLabel: null,
    click: function click() {
      try {
        var eventLabel = this.eventLabel;

        if (!eventLabel) {
          // url hostname + url path
          // remove leading and trailing slashes from pathname for consistency across browsers
          eventLabel = "".concat(this.element.host, " - ").concat(this.element.pathname.replace(/^\/|\/$/g, ''));
        }

        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: this.eventCategory,
          eventAction: this.eventAction,
          eventLabel: eventLabel
        });
      } catch (e) {// not handling caught errors at the moment
      }
    }
  }).reopenClass({
    positionalParams: 'params'
  });

  _exports.default = _default;
});