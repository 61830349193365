define("lh4/mirage/factories/transaction", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    date: '2022-10-03 08:01:29',
    processingStatus: 'unsettled',
    transactionId: 1,
    cardType: 'visa',
    cardNumber: '1119',
    customerName: 'SDK TestName',
    customerEmail: 'sdkTest@kountsdktestdomain.com',
    customerIpAddress: '88.223.151.26',
    amount: '1234.56',
    type: 'VOID',
    riskStatus: null,
    riskId: 'EA6E18A8-B9A1-4388-9706-A18AE0F1DEDD',
    acquirerResponse: 'N7 - ',
    invoice: '0903180059',
    cardToken: 1,
    merchantReference: 'simulator:0903180059',
    merchantBatchNumber: null,
    merchantBatchDateTz: null,
    fundingSource: null,
    transType: 'VOID',
    transbaseId: 'transbaseId'
  });

  _exports.default = _default;
});