define("lh4/components/dashboard-widgets/ip-notifications/types/suspect-transactions", ["exports", "moment", "lh4/enums/ip-risk-statuses"], function (_exports, _moment, _ipRiskStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_FORMAT = 'YYYY-MM-DD';

  var _default = Ember.Component.extend({
    data: undefined,
    router: Ember.inject.service(),
    tagName: '',
    linkProperties: Ember.computed(function () {
      var riskStatusQueryParams = JSON.stringify([_ipRiskStatuses.default.DECLINED]);
      var date = (0, _moment.default)().format(DATE_FORMAT);
      return {
        riskStatusQueryParams: riskStatusQueryParams,
        dateQueryParams: {
          start: date,
          end: date
        },
        path: 'authenticated.internet-payments.transactions'
      };
    })
  });

  _exports.default = _default;
});