define("lh4/adapters/internet-payments/transaction", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    urlForFindRecord: function urlForFindRecord(id, type, snapshot) {
      var _adapterOptions$locat;

      var adapterOptions = snapshot.adapterOptions;
      return "".concat(this._super(id, type, snapshot), "/detail?locationId=").concat((_adapterOptions$locat = adapterOptions === null || adapterOptions === void 0 ? void 0 : adapterOptions.locationId) !== null && _adapterOptions$locat !== void 0 ? _adapterOptions$locat : this.location.model.id);
    },
    query: function query(store, type, _query) {
      var url = '';

      if (this.can.can('view mlm transactions locations filter in feature-flag')) {
        url = "".concat(this.host, "/api/v2/internet-payments/transactions");
        var locationIds = _query.locationIds;
        var queryString = Object.entries(_query).filter(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              val = _ref2[1];

          if (Array.isArray(val) && !val.length) {
            return false;
          }

          if (key === 'locationIds' || key === 'locationId') {
            return false;
          }

          return val !== undefined && val !== '';
        }).map(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
              key = _ref4[0],
              val = _ref4[1];

          var paramVal = val;
          var paramKey = key; // dates need to be encode colons

          if (key === 'start' || key === 'end') {
            paramVal = encodeURIComponent(val);
          } // searchTerm needs to be empty string


          if (val === null) {
            paramVal = '';
          }

          if (Array.isArray(val)) {
            var compoundVal = [];

            for (var i = 0; i < val.length; i += 1) {
              compoundVal.push("".concat(key, "%5B%5D=").concat(val[i]));
            }

            return compoundVal.join('&');
          }

          return "".concat(paramKey, "=").concat(paramVal);
        }).join('&');
        return this.ajax("".concat(url, "?").concat(queryString), 'POST', {
          data: {
            locationIds: Array.isArray(locationIds) && locationIds.length ? locationIds.map(Number) : [Number(this.location.model.id)]
          }
        });
      }

      url = "".concat(this.host, "/api/v1/internet-payments/transactions");
      return this.ajax(url, 'GET', {
        data: _query
      });
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      for (var _len = arguments.length, args = new Array(_len > 4 ? _len - 4 : 0), _key = 4; _key < _len; _key++) {
        args[_key - 4] = arguments[_key];
      }

      var response = this._super.apply(this, [status, headers, payload, requestData].concat(args));

      if (!response.transactions) {
        return response;
      }

      var transactions = response.transactions,
          count = response.count;
      return {
        transactions: transactions.map(function (transaction) {
          return _objectSpread(_objectSpread({}, transaction), {}, {
            serverName: transaction.serverName === null || transaction.serverName === '' ? 'Unspecified' : transaction.serverName
          });
        }),
        meta: {
          count: transactions.length ? count : 0
        }
      };
    }
  });

  _exports.default = _default;
});