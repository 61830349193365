define("lh4/components/reports/processing/processing-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'segments', 'dashboard', 'processing-detail'],
    selectedBatch: null,
    actions: {
      showTransactions: function showTransactions(batchID) {
        this.set('selectedBatch', null);

        if (this.$(".".concat(batchID)).is(':visible')) {
          this.$(".".concat(batchID)).hide();
        } else {
          this.$('.transactions:visible').hide();
          this.$(".".concat(batchID)).toggle();
          this.set('selectedBatch', batchID);
        }
      }
    }
  });

  _exports.default = _default;
});