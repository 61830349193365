define("lh4/serializers/loyalty/member", ["exports", "lh4/serializers/loyalty/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      delete json.pointsBalance;
      delete json.accumulateTotalPoints;
      delete json.redeemTotalPoints;
      delete json.visitCount;
      return json;
    }
  });

  _exports.default = _default;
});