define("lh4/templates/authenticated/customer-engagement/campaigns/overview/activity/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w8wYXzWg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal/route-modal\",null,[[\"class\"],[\"small\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"ui/modal/dialog-header\",null,[[\"title\",\"close\"],[\"Edit Customer\",[28,\"action\",[[23,0,[]],\"clear\"],null]]]],false],[0,\"\\n\"],[4,\"ui/modal/dialog-content\",null,null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"model\",\"product\"]],\"echo-pro\"],null]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"echo-pro/manage-pos/customers/edit\",null,[[\"customer\"],[[24,[\"model\",\"customer\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\tnot implemented for \"],[1,[24,[\"model\",\"product\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\t\"],[1,[28,\"ui/modal/dialog-buttons\",null,[[\"accept\",\"decline\",\"acceptText\",\"isLoading\"],[[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"clear\"],null],\"Save\",[24,[\"isLoading\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/customer-engagement/campaigns/overview/activity/customer.hbs"
    }
  });

  _exports.default = _default;
});