define("lh4/mirage/factories/launch-control/workflow", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    createdAt: '2024-04-16T19:24:26.622Z',
    updatedAt: '2024-04-16T19:24:26.622Z',
    workflowId: function workflowId() {
      return _faker.default.datatype.uuid();
    },
    workflowName: 'launch-control-v1',
    locationId: 5,
    completedAt: null
  });

  _exports.default = _default;
});