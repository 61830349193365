define("lh4/serializers/solo/item", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _application.default.extend({
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'solo/item';
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (_typeof(json) === 'object') {
        var _json$tax, _json$tax2;

        json.taxType = (_json$tax = json.tax) === null || _json$tax === void 0 ? void 0 : _json$tax.type;
        json.taxValue = (_json$tax2 = json.tax) === null || _json$tax2 === void 0 ? void 0 : _json$tax2.value;
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    }
  });

  _exports.default = _default;
});