define("lh4/components/ui/tab-container/tab-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeTab: null,
    tabName: ''
  });

  _exports.default = _default;
});