define("lh4/models/echo-pro/item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/boolean-property-array", "lh4/utils/has-many-ids", "lh4/utils/stylesheet-to-color", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _modelCloneable, _booleanPropertyArray, _hasManyIds, _stylesheetToColor, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;

  var _Ember;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true,
        description: 'Menu Item Name'
      }), (0, _emberCpValidations.validator)('length', {
        max: 100,
        description: 'Menu Item Name'
      })]
    },
    price: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Default Price'
    }), (0, _emberCpValidations.validator)('number', {
      description: 'Default Price',
      allowString: true,
      positive: true
    })],
    departmentGuid: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Department'
    }),
    taxSetGuid: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Revenue Class'
    }),
    cost: [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      description: 'Item Cost',
      allowString: true,
      positive: true
    })],
    notes: {
      description: 'Description',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255,
        description: 'Menu Item Description'
      })]
    },
    kitchenName: {
      description: 'Kitchen Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    itemUpc: {
      description: 'Item UPC',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      }), (0, _emberCpValidations.validator)(function (value) {
        var errorMessage = 'Item UPC input field only accepts numbers and letters';
        var regex = /^[A-Za-z0-9]*$/;

        if (!regex.test(value)) {
          return errorMessage;
        }

        return true;
      })]
    },
    defaultQty: {
      description: 'Default Quantity',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        integer: true,
        gte: 1
      })]
    },
    qtyOnHand: {
      description: 'Quantity On Hand',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        integer: true,
        positive: true,
        gte: 0,
        lte: 99999
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Quantity On Hand must be filled if Track items is checked',
        disabled: Ember.computed.not('model.isDisplayedOnItemTracking')
      })]
    },
    minQty: {
      description: 'Alert Quantity',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true,
        integer: true
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Alert quantity must be filled if Low stock indicator is checked',
        disabled: Ember.computed.not('model.showQtyAlert')
      })]
    },
    taxQuantity: {
      description: 'Tax Quantity',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true
      })]
    },
    shouldNotPrintOnCustomerReceipt: {
      description: 'shouldNotPrintOnCustomerReceipt',
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        var errorMessage = '"Default Price $" must be $0 when' + ' "Do not print item to customer receipt" setting is turned on'; // Due to a bug in ember-changeset, the value passed into the validator is always opposite of
        // what we'd expect. Getting the value using model.get(<key>) is also incorrect, and the
        // opposite of what we'd expect. To account for this, we must check if the array of keys
        // (advanced) includes the key in question(shouldNotPrintOnCustomerReceipt). The reason advanced
        // is unaffected by the bug, is most likely due to a delay in when the save is made to the
        // model. Meaning the changeset maintains proper state for this property.

        if (model.get('advanced').includes('shouldNotPrintOnCustomerReceipt') && model.get('price') > 0) {
          return errorMessage;
        }

        return true;
      }, {
        dependentKeys: ['price', 'advanced']
      })]
    },
    isOpenItem: {
      description: 'isOpenItem',
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('advanced').includes('isOpenItem') && model.get('advanced').includes('shouldNotPrintOnCustomerReceipt')) {
          return '"Do not print item to customer receipt" and ' + '"Open items (Show keypad to enter price)" cannot be enabled at the same time';
        }

        return true;
      }, {
        dependentKeys: ['advanced']
      })]
    },
    tareWeight: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        var errorMessage = 'Weight must be positive with at most three decimals';
        var regex = /^\d+([.,]\d{0,3})?$/;

        if (!regex.test(value) && model.get('isWeighted')) {
          return errorMessage;
        }

        return true;
      }, {
        dependentKeys: ['isWeighted']
      })]
    },
    quantityDecimalPlaces: {
      description: 'Quantity Decimal Places',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: false,
        integer: true,
        positive: true,
        gte: 0,
        lte: 3
      })]
    },
    defaultUnit: {
      description: 'Default Unit',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 60
      })]
    },
    onlineName: {
      description: 'Online Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    onlinePrice: {
      description: 'Online price',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true,
        gte: 0
      })]
    },
    onlineDescription: {
      description: 'Online Description',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    prepTimeText: {
      description: 'Item Prep Time',
      validators: [(0, _emberCpValidations.validator)(function (value) {
        if (value === '' || value === '0') {
          return true;
        }

        var _value$split = value.split(':'),
            _value$split2 = _slicedToArray(_value$split, 2),
            minutes = _value$split2[0],
            seconds = _value$split2[1];

        var regex = /^([0-9]{1,4}):([0-5][0-9])$/; // Validation to allow only mm:ss time format, up to 23:59:59

        if (value && !regex.test(value) || parseInt(minutes, 10) > 1439 || parseInt(seconds, 10) > 59) {
          return 'Enter the time in minutes and seconds. Example: 2:30';
        }

        return true;
      })]
    },
    modifierSetsWithDefaults: {
      validators: [(0, _emberCpValidations.validator)(function (value) {
        if (!value) {
          return true;
        } // do not allow to save until all prep times in a table are valid


        var regex = /^[0-9]{1,}:?[0-5]{0,1}[0-9]{0,1}$/; // eslint-disable-next-line arrow-body-style

        var isInvalid = value.find(function (set) {
          return set.modifiers.find(function (_ref) {
            var prepTimeText = _ref.prepTimeText;
            return prepTimeText && !regex.test(prepTimeText);
          });
        });

        if (isInvalid) {
          return 'Enter the time in minutes and seconds. Example: 2:30';
        }

        return true;
      })]
    }
  });
  _exports.Validations = Validations;
  var ADVANCED_ATTRIBUTES = {
    shouldNotPrintOnCustomerReceipt: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    isOpenItem: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    showSpecialRequest: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    canBuyWithGiftCard: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    })
  };

  var _default = _model.default.extend(Validations, _modelCloneable.default, ADVANCED_ATTRIBUTES, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    // General
    guid: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    locationId: (0, _attr.default)('number', {
      isCopyable: true
    }),
    courseGuid: (0, _attr.default)('string', {
      isCopyable: true
    }),
    glyph: (0, _attr.default)('string'),
    // General Tab
    name: (0, _attr.default)('string'),
    kitchenName: (0, _attr.default)('string'),
    itemUpc: (0, _attr.default)('string'),
    price: (0, _attr.default)('number', {
      isCopyable: true
    }),
    cost: (0, _attr.default)('number', {
      isCopyable: true
    }),
    isDepartmentOpenKey: (0, _attr.default)('boolean'),
    isDisplayedOnItemTracking: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    isEbtItem: (0, _attr.default)('boolean'),
    isFavorite: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    isItemPrinted: (0, _attr.default)('boolean'),
    // Advanced Tab
    isWeighted: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    isManualQuantity: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    quantityDecimalPlaces: (0, _attr.default)('number', {
      defaultValue: 3,
      isCopyable: true
    }),
    defaultUnit: (0, _attr.default)('string', {
      defaultValue: 'LB',
      isCopyable: true
    }),
    notes: (0, _attr.default)('string', {
      isCopyable: true
    }),
    // Accounting Tab
    inheritTaxes: (0, _attr.default)('boolean'),
    inheritedTaxes: (0, _attr.default)(),
    defaultQty: (0, _attr.default)('number', {
      defaultValue: 1,
      isCopyable: true
    }),
    discountSets: (0, _attr.default)(),
    minQty: (0, _attr.default)('number', {
      isCopyable: true
    }),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    qtyOnHand: (0, _attr.default)('number', {
      isCopyable: true
    }),
    showQtyAlert: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    tareWeight: (0, _attr.default)('string', {
      isCopyable: true
    }),
    taxSetGuid: (0, _attr.default)('string', {
      isCopyable: true
    }),
    departmentGuid: (0, _attr.default)('string', {
      isCopyable: true
    }),
    allowZeroQtyOrdering: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    department: (0, _relationships.belongsTo)('echo-pro/department', {
      isCopyable: true
    }),
    // Online Ordering Tab
    onlineActive: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    onlineName: (0, _attr.default)('string'),
    onlinePrice: (0, _attr.default)('number', {
      isCopyable: true
    }),
    onlineDescription: (0, _attr.default)('string', {
      isCopyable: true
    }),
    imageUrl: (0, _attr.default)('string'),
    prepTimeSeconds: (0, _attr.default)('number', {
      defaultValue: 0,
      isCopyable: true
    }),
    prepTimeText: (0, _attr.default)('string', {
      defaultValue: '0:00',
      isCopyable: true
    }),
    modifierSetsWithDefaults: (0, _attr.default)({
      defaultValue: function defaultValue(_this) {
        if (_this.get('launchDarkly').featureFlags[_launchDarklyFeatureFlags.default.DEFAULT_MODIFIERS_ENABLED]) {
          return [];
        }

        return undefined;
      },
      isCopyable: true
    }),
    modifierSets: (0, _relationships.hasMany)('echo-pro/modifier-set'),
    modifierSetIds: Ember.computed('modifierSets', (0, _hasManyIds.default)('modifierSets', 'echo-pro/modifier-set')),
    printers: (0, _relationships.hasMany)('echo-pro/printer'),
    taxes: (0, _relationships.hasMany)('echo-pro/tax'),
    taxQuantity: (0, _attr.default)('number', {
      isCopyable: true
    }),
    itemTags: (0, _relationships.hasMany)('echo-pro/item-tag'),
    itemPriceScheduleMaps: (0, _relationships.hasMany)('echo-pro/item-price-schedule-map'),
    taxIds: Ember.computed('taxes', (0, _hasManyIds.default)('taxes', 'echo-pro/tax')),
    printerIds: Ember.computed('printers', (0, _hasManyIds.default)('printers', 'echo-pro/printer')),
    itemTagIds: Ember.computed('itemTags', (0, _hasManyIds.default)('itemTags', 'echo-pro/item-tag')),
    advanced: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(ADVANCED_ATTRIBUTES)).concat([(0, _booleanPropertyArray.default)(Object.keys(ADVANCED_ATTRIBUTES))])),
    stposUiStylesheetObjectLinks: (0, _relationships.hasMany)('echo-pro/stylesheet-object-link'),
    color: Ember.computed('stposUiStylesheetObjectLinks.@each.stposUiStylesheetGuid', (0, _stylesheetToColor.default)('stposUiStylesheetObjectLinks')),
    dynamicPricing: (0, _relationships.hasMany)('echo-pro/availability-price/price-setting'),
    useDepartmentPricing: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    useDepartmentPricingString: Ember.computed('useDepartmentPricing', {
      get: function get() {
        return this.get('useDepartmentPricing') ? 'true' : 'false';
      },
      set: function set(key, value) {
        if (typeof value === 'string') {
          this.set('useDepartmentPricing', value === 'true');
        }

        return value;
      }
    }),
    availability: (0, _relationships.belongsTo)('echo-pro/availability-price/availability'),
    defaultAvailability: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    defaultAvailabilityString: Ember.computed('defaultAvailability', {
      get: function get() {
        return this.get('defaultAvailability') ? 'true' : 'false';
      },
      set: function set(key, value) {
        if (typeof value === 'string') {
          this.set('defaultAvailability', value === 'true');
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});