define("lh4/enums/ip-redirect-reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REDIRECT_REPORTS = Object.freeze([{
    name: 'Transactions',
    slug: 'transaction',
    product: 'internet-payments',
    category: 'operational',
    description: 'Transactions displays all payments that occurred during selected period of time',
    params: {
      route: 'authenticated.internet-payments.transactions.master-transactions',
      end: {
        type: 'date',
        date: true,
        time: false
      },
      start: {
        type: 'date',
        date: true,
        time: false
      }
    }
  }]);
  var _default = REDIRECT_REPORTS;
  _exports.default = _default;
});