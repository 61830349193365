define("lh4/mirage/routes/corporate/groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageCorporateGroupsServer;

  function setMirageCorporateGroupsServer(server) {
    server.get('/corporations/:corpId/groups', function (schema) {
      return {
        corporateGroups: schema.corporateGroups.all().models
      };
    });
    server.get('/corporations/:corpId/groups/:id', function (schema, request) {
      return {
        corporateGroups: schema.corporateGroups.where({
          id: request.params.id
        }).models
      };
    });
  }
});