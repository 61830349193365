define("lh4/mirage/factories/integration/s4/event", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id: function id() {
      return _faker.default.datatype.guid();
    },
    date: function date() {
      return _faker.default.date.past();
    },
    environment: 'Development',
    errorMessage: null,
    locationId: 1,
    name: 'SALE',
    requestBody: function requestBody() {
      return _faker.default.datatype.json();
    },
    status: function status() {
      return this.statusCode === 200 ? 'SUCCESS' : 'FAIL';
    },
    statusCode: 200,
    webhookId: null
  });

  _exports.default = _default;
});