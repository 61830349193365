define("lh4/mirage/factories/loyalty-member", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    loyaltyProgram: function loyaltyProgram() {
      return _faker.default.datatype.uuid();
    },
    loyaltyProgramName: function loyaltyProgramName() {
      return _faker.default.commerce.productName();
    },
    phoneNumber: function phoneNumber() {
      return _faker.default.phone.phoneNumber();
    },
    isActive: true,
    firstName: function firstName() {
      return _faker.default.name.firstName();
    },
    lastName: function lastName() {
      return _faker.default.name.lastName();
    },
    emailAddress: function emailAddress() {
      return _faker.default.internet.email();
    },
    addressLine1: function addressLine1() {
      return _faker.default.address.streetName();
    },
    addressLine2: function addressLine2() {
      return _faker.default.address.streetName();
    },
    city: function city() {
      return _faker.default.address.city();
    },
    state: function state() {
      return _faker.default.address.state();
    },
    postalCode: function postalCode() {
      return _faker.default.address.zipCode();
    },
    country: function country() {
      return _faker.default.address.country();
    },
    pointsBalance: 20,
    accumulateTotalPoints: 20,
    redeemTotalPoints: 0,
    visitCount: 1
  });

  _exports.default = _default;
});