define("lh4/adapters/reservations/setting", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'reservations/settings';
    },
    updateRecord: function updateRecord(store, type, snapshot, options) {
      // overriding this so that I can add the location query parameter to the save
      var serializer = store.serializerFor(type.modelName);
      var data = serializer.serialize(snapshot, options);
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      return this.ajax("".concat(url, "?location=").concat(data.locationId), 'PUT', {
        data: data
      });
    }
  });

  _exports.default = _default;
});