define("lh4/controllers/authenticated/epro/menu/pricing-table", ["exports", "lh4/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PLACEHOLDER_WIDTH = ['eight', 'six', 'four', 'two'];
  var DEFAULT_PLACEHOLDER_WIDTH = 'zero';
  var CELL_WIDTH = ['twelve', 'eight', 'four', 'three'];
  var DEFAULT_CELL_WIDTH = 'twelve';

  var _default = _generalListController.default.extend({
    corporation: Ember.inject.service(),
    queryParams: ['departmentGuid', 'searchValue', 'limit', 'order', 'offset'],
    headers: Ember.computed('corporateGroupHeaders', function getter() {
      var headers = [{
        field: 'name',
        name: 'Name',
        width: 'five',
        sortable: true,
        fixed: true
      }, {
        field: 'price',
        name: 'Default Price',
        width: 'two',
        sortable: true,
        fixed: true,
        type: 'number'
      }]; // add all pricing groups as a column

      this.corporateGroupHeaders.forEach(function (h) {
        return headers.push(h);
      }); // add a placeholder column to fill empty space to the right of screen when there are few pricing groups

      var getRemainingWidth = function getRemainingWidth(count) {
        return PLACEHOLDER_WIDTH[count || 0] || DEFAULT_PLACEHOLDER_WIDTH;
      };

      headers.push({
        field: 'placeholder',
        name: '',
        width: getRemainingWidth(this.model.corporateGroups.length),
        sortable: false
      });
      return headers;
    }),
    corporateGroupHeaders: Ember.computed('model.corporateGroups', function getter() {
      var _this = this;

      var getWidth = function getWidth(count) {
        return CELL_WIDTH[count || 0] || DEFAULT_CELL_WIDTH;
      };

      return (this.model.corporateGroups || []).map(function (group) {
        return {
          field: "corporateGroup".concat(group.id),
          name: group.name,
          width: getWidth(_this.model.corporateGroups.length),
          sortable: false,
          type: 'number',
          corporateGroupId: group.id
        };
      });
    }),
    selectionCount: 0,
    includeList: [],
    isMultipleItemsSelected: Ember.computed('selectionCount', function getter() {
      return this.selectionCount > 1;
    }),
    departmentOptions: Ember.computed('model.departments', 'departmentGuid', function getter() {
      var _this2 = this;

      var isAllSelected = typeof this.departmentGuid === 'undefined' || this.departmentGuid === null;
      return (this.model.departments || []).map(function (department) {
        var _this2$departmentGuid;

        return {
          id: department.guid,
          name: department.name,
          isChecked: isAllSelected || ((_this2$departmentGuid = _this2.departmentGuid) === null || _this2$departmentGuid === void 0 ? void 0 : _this2$departmentGuid.includes(department.guid)),
          isActive: department.isActive
        };
      });
    }),
    actions: {
      handleTermUpdated: function handleTermUpdated(searchValue) {
        this.setProperties({
          searchValue: searchValue,
          offset: 0
        });
      },
      handleDepartmentChange: function handleDepartmentChange(selectedIds) {
        var isAllSelected = selectedIds.length === this.departmentOptions.length;
        this.setProperties({
          offset: 0,
          departmentGuid: !isAllSelected ? selectedIds : undefined
        });
      },
      handleBulkSelectionChanged: function handleBulkSelectionChanged(includeList, selectionCount) {
        this.setProperties({
          includeList: includeList,
          selectionCount: selectionCount
        });
      },
      handleBulkEditClicked: function handleBulkEditClicked() {
        if (this.isMultipleItemsSelected) {
          this.transitionToRoute('authenticated.epro.menu.pricing-table.bulk-edit');
        }
      },
      handleStatusFilterChange: function handleStatusFilterChange(selectedIds) {
        var isAllSelected = selectedIds.length > 1;
        this.setProperties({
          offset: 0,
          statusFilter: !isAllSelected ? selectedIds : undefined
        });
      }
    }
  });

  _exports.default = _default;
});