define("lh4/templates/components/internet-payments/transactions/insights/summary-line-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "30vUEAxE",
    "block": "{\"symbols\":[\"Chart\",\"@data\",\"&default\"],\"statements\":[[5,\"internet-payments/widgets/container\",[],[[\"@className\"],[\"summary-line-chart\"]],{\"statements\":[[0,\"\\n\\t\"],[6,[23,1,[\"Header\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"h3\",true],[8],[0,\"Authorization Rate\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[6,[23,1,[\"Body\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"amcharts3/line-chart\",[],[[\"@data\",\"@options\"],[[23,2,[]],[23,0,[\"options\"]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[6,[23,1,[\"Footer\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[14,3],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/transactions/insights/summary-line-chart.hbs"
    }
  });

  _exports.default = _default;
});