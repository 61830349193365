define("lh4/helpers/multiply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multiply = multiply;
  _exports.default = void 0;

  /**
   * Courtesy of https://github.com/shipshapecode/ember-math-helpers/
   * Multiplies two or more numbers
   *
   * ```hbs
   * {{multiply a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to multiply
   * @return {number} The product of all the passed numbers
   */
  function multiply(numbers) {
    if (!numbers) {
      return '';
    }

    return numbers.reduce(function (a, b) {
      return Number(a) * Number(b);
    });
  }

  var _default = Ember.Helper.helper(multiply);

  _exports.default = _default;
});