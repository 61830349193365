define("lh4/routes/authenticated/locations/location/echo-pro/employee-schedule/template/list", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        location: location,
        templates: this.store.query('echo-pro/schedule-template', {
          location: location.id,
          order: 'name',
          sort: 'asc'
        })
      });
    }
  });

  _exports.default = _default;
});