define("lh4/routes/authenticated/locations/location/echo-pro/reservations/waitlist", ["exports", "jquery", "lh4/mixins/authenticated-route-mixin"], function (_exports, _jquery, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      Ember.run.schedule('afterRender', this, function () {
        (0, _jquery.default)('.ui.modal').modal('setting', 'closable', false);
        (0, _jquery.default)('.res-roll').css('max-height', (0, _jquery.default)('.res-form').outerHeight());
      });
    }
  });

  _exports.default = _default;
});