define("lh4/templates/components/echo-pro/tableside/device-setup-wizard/wizard-complete-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hLhrZKOU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid center aligned middle aligned one column\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"thirteen wide column\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui basic segment\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"p\",true],[8],[0,\"Your Tableside device has been successfully registered. If you are having trouble with the device check out the Tableside FAQ.\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui big primary button wizard-complete-step-close\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"proceed\"]],[8],[0,\"\\n\\t\\t\\t\\t\\tExit Setup\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/echo-pro/tableside/device-setup-wizard/wizard-complete-step.hbs"
    }
  });

  _exports.default = _default;
});