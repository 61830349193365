define("lh4/mirage/routes/users/transactions/filter-templates", ["exports", "lh4/mirage/fixtures/users/transactions/filter-templates"], function (_exports, _filterTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMirageInternetPaymentsFilterTemplatesServer;

  function getMirageInternetPaymentsFilterTemplatesServer(server) {
    server.get('users/transactions/filter-templates', function (_, _ref) {
      var queryParams = _ref.queryParams;
      return {
        templates: _filterTemplates.default.filter(function (_ref2) {
          var product = _ref2.product;
          return product === queryParams.product;
        })
      };
    });
    server.delete('users/transactions/filter-templates/:id', function () {
      return {};
    });
  }
});