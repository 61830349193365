define("lh4/enums/reservations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAUL_RESERVATION_TIMES = Object.freeze({
    BREAKFAST: {
      START: '2024-01-01 07:00:00',
      END: '2024-01-01 11:00:00'
    },
    LUNCH: {
      START: '2024-01-01 11:00:00',
      END: '2024-01-01 14:00:00'
    },
    DINNER: {
      START: '2024-01-01 16:00:00',
      END: '2024-01-01 20:00:00'
    }
  });
  var _default = DEFAUL_RESERVATION_TIMES;
  _exports.default = _default;
});