define("lh4/templates/components/internet-payments/disputes/modals/next-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sN/3Zief",
    "block": "{\"symbols\":[\"document\",\"@title\",\"@description\",\"@explanation\",\"@documentsNeeded\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"what-to-do-next\"],[10,\"data-test-evidence\",\"next-steps-component\"],[8],[0,\"\\n\\t\"],[7,\"h3\",true],[8],[1,[23,2,[]],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"bold\"],[8],[1,[23,3,[]],false],[9],[0,\"\\n\\t\"],[7,\"p\",true],[8],[1,[23,4,[]],false],[9],[0,\"\\n\"],[4,\"each\",[[23,5,[]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"p\",true],[10,\"class\",\"info-message\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/disputes/modals/next-steps.hbs"
    }
  });

  _exports.default = _default;
});