define("lh4/utils/camel-case-to-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = camelCaseToDash;

  /**
   * Converts a camelCase string to a kebab-case (lowercase with dashes).
   *
   * This function replaces any instances of a lowercase letter followed by an uppercase letter
   * with the lowercase letter, a dash (`-`), and the uppercase letter in lowercase.
   *
   * @param {string} input - The camelCase string to be converted.
   * @returns {string} The input string converted to kebab-case (e.g., 'camelCase' becomes 'camel-case').
   */
  function camelCaseToDash(input) {
    return input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }
});