define("lh4/helpers/time-format", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeFormat = timeFormat;
  _exports.default = void 0;

  /**
   * Formats a moment's time
   * @param {Array<Object>} params - function parameters
   * @returns {String} - first parameter formatted as time using AM/PM notation
   */
  function timeFormat(params) {
    return (0, _moment.default)(params[0]).format('h:mm A');
  }

  var _default = Ember.Helper.helper(timeFormat);

  _exports.default = _default;
});