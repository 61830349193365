define("lh4/utils/no-op", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * No operation function. Literally does nothing.
   */
  var noOp = function noOp() {};

  var _default = noOp;
  _exports.default = _default;
});