define("lh4/models/echo-pro/ingredient", ["exports", "ember-data", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/has-many-ids"], function (_exports, _emberData, _relationships, _emberCpValidations, _modelCloneable, _hasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Ingredient Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100,
        min: 1
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, _modelCloneable.default, {
    name: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    guid: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    itemsMap: (0, _relationships.hasMany)('echo-pro/ingredient-item-map', {
      async: false
    }),
    itemIds: Ember.computed('items', (0, _hasManyIds.default)('itemsMap', 'echo-pro/ingredient-item-map')),
    modifiersMap: (0, _relationships.hasMany)('echo-pro/ingredient-modifier-map', {
      async: false
    }),
    modifierIds: Ember.computed('modifiersMap', (0, _hasManyIds.default)('modifiersMap', 'echo-pro/ingredient-modifier-map')),
    addItem: function addItem(item) {
      return this.store.peekRecord('echo-pro/ingredient-item-map', item.id) || this.store.createRecord('echo-pro/ingredient-item-map', _objectSpread(_objectSpread({}, item), {}, {
        quantity: 1
      }));
    },
    addModifier: function addModifier(modifier) {
      return this.store.peekRecord('echo-pro/ingredient-modifier-map', modifier.id) || this.store.createRecord('echo-pro/ingredient-modifier-map', _objectSpread(_objectSpread({}, modifier), {}, {
        quantity: 1
      }));
    }
  });

  _exports.default = _default;
});