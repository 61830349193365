define("lh4/components/hospitality/choices-and-choice-sets/choices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    choices: Ember.computed('showInactiveChoices', 'choiceSet.choiceChoiceSets', 'allChoices', function getter() {
      // TODO: On cold load, model.editChoiceSets.modifiers returns null.
      if (!this.get('choiceSet.choiceChoiceSets')) {
        return [];
      }

      var itemsArray = this.get('choiceSet.choiceChoiceSets').map(function (item) {
        return item.choiceId;
      });
      var filteredArray = this.get('allChoices').filter(function (x) {
        return itemsArray.includes(x.get('choiceId'));
      }).sortBy('name');
      filteredArray = !this.get('showInactiveChoices') ? filteredArray.filterBy('isActive', true) : filteredArray;
      return filteredArray;
    })
  });

  _exports.default = _default;
});