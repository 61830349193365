define("lh4/routes/authenticated/locations/location/hospitality/manage-employees", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Manage Employees',
    requiredPermissions: 'manage hospitality/employee-and-job',
    router: Ember.inject.service(),
    queryParams: {
      searchName: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      location: {
        refreshModel: true
      },
      isActive: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      showJobs: {
        refreshModel: true
      }
    },
    model: function model(param) {
      var currentRoute = this.router.currentRoute;
      var queryParams = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.queryParams;
      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        employee: this.store.query('hospitality/employee', {
          isActive: param.isActive,
          limit: 1000,
          searchName: param.searchName,
          location: location.id
        }),
        location: this.modelFor('authenticated').locations,
        activeLocationId: location.id,
        jobsSelected: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.showJobs) === 'true',
        isActive: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.isActive) === 'true',
        pagination: this.store.query('hospitality/employee', {
          jobFilter: param.jobFilter,
          isActive: param.isActive,
          location: location.id,
          offset: param.offset,
          limit: param.limit,
          searchName: param.searchName
        }),
        currentLocation: this.modelFor('authenticated.locations.location')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('location', model.activeLocationId);
    }
  });

  _exports.default = _default;
});