define("lh4/utils/phone-number", ["exports", "libphonenumber"], function (_exports, _libphonenumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parsePhoneNumber = parsePhoneNumber;
  _exports.serializePhoneNumber = serializePhoneNumber;
  _exports.formatPhoneNumber = formatPhoneNumber;
  _exports.formatPhoneToInternationalFormat = formatPhoneToInternationalFormat;

  var phoneUtil = _libphonenumber.default.PhoneNumberUtil.getInstance();

  var PNF = _libphonenumber.default.PhoneNumberFormat;

  function phoneUtilSafeParse() {
    try {
      return phoneUtil.parse.apply(phoneUtil, arguments);
    } catch (_error) {
      return null;
    }
  }
  /**
   * Parses phone number to libphonenumber
   *
   * @export
   * @param {string} input - phone number written in any format.
   * @param {string} [countryCode=null] - users country code. Used to parse phone number when it is passed in national
   * format.
   *
   * @returns {libphonenumber}
   */


  function parsePhoneNumber(input) {
    var countryCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var phone = String(input);
    var parsedPhone = phoneUtilSafeParse(phone);

    if (!parsedPhone && countryCode) {
      parsedPhone = phoneUtilSafeParse(phone, String(countryCode).toUpperCase());
    }

    if (parsedPhone && phoneUtil.isValidNumber(parsedPhone)) {
      return parsedPhone;
    }

    throw new Error('Phone number must be in National or E164 format');
  }
  /**
   * Formats phone number to E164 format
   *
   * @export
   * @param {string} phoneNumber - phone number written in any format.
   * @param {string} [countryCode=null] - users country code. Used to parse phone number when it is passed in national
   * format.
   *
   * @example
   * // returns +18882762108
   * serializePhoneNumber('+1 888-276-2108', 'US')
   *
   * @example
   * // returns +18882762108
   * serializePhoneNumber('(888) 276-2108', 'US')
   *
   * @returns {string} phone number written in E164 format.
   */


  function serializePhoneNumber(phoneNumber) {
    var countryCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    try {
      return phoneUtil.format(parsePhoneNumber(String(phoneNumber), countryCode), PNF.E164);
    } catch (_error) {
      return phoneNumber != null ? String(phoneNumber) : null;
    }
  }

  function getPhoneNumberFormat(parsedPhone, countryCode) {
    var phoneCountryCode = phoneUtil.getRegionCodeForNumber(parsedPhone);

    if (phoneCountryCode.toLowerCase() !== countryCode.toLowerCase()) {
      return PNF.E164;
    }

    return PNF.NATIONAL;
  }
  /**
   * Formats phone number to desired (if specified) or national/E164 format.
   *
   * @export
   * @param {string} phoneNumber - phone number written in any format.
   * @param {string} [countryCode=null] - users country code. Used to parse phone number when it is passed in national
   * @param {libphonenumber.PhoneNumberFormat} [phoneNumberFormat=null] - phone number format.
   * format.
   *
   * @example
   * // returns +18882762108
   * formatPhoneNumber('+18882762108', 'GB')
   *
   * @example
   * // returns (888) 276-2108
   * formatPhoneNumber('+18882762108', 'US')
   *
   * @example
   * // returns (888) 276-2108
   * formatPhoneNumber('888-276-2108', 'US')
   *
   * @returns {string} phone number written in internation format.
   */


  function formatPhoneNumber(phoneNumber) {
    var countryCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var phoneNumberFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var phone = String(phoneNumber);
    var parsedPhone;

    try {
      parsedPhone = parsePhoneNumber(phone, countryCode);
    } catch (_error) {
      return phoneNumber;
    }

    var format = phoneNumberFormat || getPhoneNumberFormat(parsedPhone, countryCode);
    return phoneUtil.format(parsedPhone, format);
  }
  /**
   * Formats phone number to international format.
   *
   * @export
   * @param {string} phoneNumber - phone number written in any format.
   * @param {string} [countryCode=null] - users country code. Used to parse phone number when it is passed in national
   * format.
   *
   * @example
   * // returns +1 888-276-2108
   * formatPhoneToInternationalFormat('+18882762108')
   *
   * @returns {string} phone number written in internation format.
   */


  function formatPhoneToInternationalFormat(phoneNumber) {
    var countryCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return formatPhoneNumber(phoneNumber, countryCode, PNF.INTERNATIONAL);
  }
});