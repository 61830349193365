define("lh4/mirage/factories/loyalty-program", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    guid: function guid() {
      return _faker.default.datatype.uuid();
    },
    name: function name() {
      return _faker.default.commerce.productName();
    },
    pointName: function pointName() {
      return _faker.default.commerce.productName().slice(0, 10);
    },
    ownerUserId: function ownerUserId() {
      return 1;
    },
    user: function user() {
      return {
        firstName: _faker.default.name.firstName(),
        lastName: _faker.default.name.lastName()
      };
    },
    isActive: true
  });

  _exports.default = _default;
});