define("lh4/serializers/echo-pro/customer/application", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return this._super("echo-pro/customer/".concat(payloadKey));
    },
    keyForRelationship: function keyForRelationship() {
      return 'customerGuid';
    },
    attrs: {
      customer: {
        deserialize: 'id',
        serialize: 'id'
      }
    }
  });

  _exports.default = _default;
});