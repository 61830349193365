define("lh4/enums/ip-webhook-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WEBHOOK_EVENTS = Object.freeze({
    SALE: 'SALE',
    REFUND: 'REFUND',
    DISPUTE: 'DISPUTE',
    PAYOUT: 'PAYOUT',
    PROCESSING_FEES: 'PROCESSING_FEES'
  });
  var _default = WEBHOOK_EVENTS;
  _exports.default = _default;
});