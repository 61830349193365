define("lh4/components/internet-payments/ui/tree/checkbox-tree", ["exports", "lh4/components/ui/tree/checkbox-tree"], function (_exports, _checkboxTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _checkboxTree.default.extend({});

  _exports.default = _default;
});