define("lh4/components/echo-pro/tableside/terminal-status-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    terminal: null,
    statusClass: Ember.computed('terminal.statusDisplay', function getter() {
      if (this.get('terminal.statusDisplay') === 'Online') {
        return 'status-active';
      }

      if (this.get('terminal.statusDisplay') === 'Offline') {
        return 'status-semi-active';
      }

      return 'status-inactive';
    })
  });

  _exports.default = _default;
});