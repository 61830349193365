define("lh4/templates/components/ui/modal/dialog-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kzUvnLpw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"onToggleButton\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"ui-checkbox\",[[12,\"data-test-dialog-header-checkbox\",\"\"]],[[\"@class\",\"@label\",\"@checked\",\"@onChange\"],[\"toggle vertical-toggle\",[22,\"toggleButtonLabel\"],[22,\"toggleButtonChecked\"],[23,0,[\"onToggleButton\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"h3\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"close\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\",false],[12,\"class\",\"close icon\"],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"close\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/modal/dialog-header.hbs"
    }
  });

  _exports.default = _default;
});