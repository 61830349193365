define("lh4/components/epro/menu/price-scheduling-form", ["exports", "lh4/utils/date/dates-overlap", "moment"], function (_exports, _datesOverlap, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid', 'price-scheduling-form'],
    showErrors: false,
    corporation: Ember.inject.service(),
    dayOfWeekOptions: [{
      id: 'isOnMonday',
      name: 'Monday'
    }, {
      id: 'isOnTuesday',
      name: 'Tuesday'
    }, {
      id: 'isOnWednesday',
      name: 'Wednesday'
    }, {
      id: 'isOnThursday',
      name: 'Thursday'
    }, {
      id: 'isOnFriday',
      name: 'Friday'
    }, {
      id: 'isOnSaturday',
      name: 'Saturday'
    }, {
      id: 'isOnSunday',
      name: 'Sunday'
    }],
    activePriceSchedules: Ember.computed('priceScheduleGroup.priceScheduleChangesets', 'priceScheduleGroup.priceScheduleChangesets.@each.isVoided', function getter() {
      return this.priceScheduleGroup.priceScheduleChangesets.filterBy('isVoided', false).sortBy('createdAt');
    }),
    validateSchedules: function validateSchedules() {
      var _this = this;

      this.setScheduleValidation(false);
      var schedules = this.activePriceSchedules; // We just assume that the first schedule is the source of truth in case of overlaps

      var correctSchedule = schedules[0]; // Reset temporary messages

      schedules.forEach(function (s) {
        return s.set('overlapErrorMessage', null);
      });

      for (var i = 0; i < schedules.length; i += 1) {
        // We only want to compare schedules once between each other, so we reduce potential checks here
        var next = i + 1;

        var _loop = function _loop(j) {
          var schedule1 = schedules[i];
          var schedule2 = schedules[j]; // Do not validate against correct schedule & itself

          if (schedule2 !== correctSchedule && schedule1 !== schedule2) {
            // normally an if(this.dayOfWeekOptions) should be here, however,
            // this.dayOfWeekOptions is hard coded at the top and is not changed.
            // though the complexity is small, not adding it now.
            _this.dayOfWeekOptions.forEach(function (day) {
              if (!(schedule1.get('daysOfWeek').includes(day.id) && schedule2.get('daysOfWeek').includes(day.id))) {
                return;
              }

              try {
                if (schedule1.get('allDay') || schedule2.get('allDay') || (0, _datesOverlap.default)(schedule1.get('startsAt'), schedule1.get('endsAt'), schedule2.get('startsAt'), schedule2.get('endsAt'))) {
                  schedule2.set('overlapErrorMessage', "Schedule \"".concat(schedule2.get('name'), "\" overlaps schedule \"").concat(schedule1.get('name'), "\""));

                  _this.setScheduleValidation(true);
                }
              } catch (error) {// Do nothing, start date must be before end date - validation fails
                // Helper throws because it can't determine the correct result
                // And ember validator will handle this
              }
            });
          }
        };

        for (var j = next; j < schedules.length; j += 1) {
          _loop(j);
        }
      }
    },
    actions: {
      addSchedule: function addSchedule() {
        var schedulesCount = this.priceScheduleGroup.priceScheduleChangesets.length;
        var newSchedule = this.priceScheduleGroup.data.addSchedule(schedulesCount);
        var changeset = this.buildChildChangeset(newSchedule);
        this.priceScheduleGroup.priceScheduleChangesets.pushObject(changeset);
      },
      deleteSchedule: function deleteSchedule(record) {
        // If record was added and deleted before hitting 'Save' - remove it altogether
        if (record.data.isNew) {
          this.priceScheduleGroup.priceScheduleChangesets.removeObject(record);
        } else {
          record.set('isVoided', true);
        }

        this.validateSchedules();
      },
      onDayOfWeekChange: function onDayOfWeekChange(record, value) {
        record.set('daysOfWeek', value);
        this.validateSchedules();
      },
      onTimePickerChange: function onTimePickerChange(record, value, property) {
        // do nothing if values are the same as this makes changeset dirty even on initial load
        // also for performance reasons, as validateSchedules gets called even if there were no changes
        if (record.get(property).isSame(value)) {
          return;
        }

        record.set(property, value);
        this.validateSchedules();
      },
      onAllDayChanged: function onAllDayChanged(record, isAllDay) {
        if (isAllDay) {
          record.set('startsAt', _moment.default.utc('1970-01-01 12:00 AM', 'YYYY-MM-DD LT'));
          record.set('endsAt', _moment.default.utc('1970-01-01 11:59:59.999 PM'));
          this.validateSchedules();
        } else {
          record.set('startsAt', _moment.default.utc('1970-01-01 08:00 AM', 'YYYY-MM-DD LT'));
          record.set('endsAt', _moment.default.utc('1970-01-01 10:00 AM', 'YYYY-MM-DD LT'));
        }

        record.set('allDay', isAllDay);
      }
    }
  });

  _exports.default = _default;
});