define("lh4/abilities/echo-pro/menu-availability", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags", "semver", "lh4/enums/products"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags, _semver, _products) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service(),
    store: Ember.inject.service(),
    canViewMenuAvailability: (0, _abilityMixin.computedAbility)('launchDarkly.stposFeatureFlags', function getter() {
      if (!this.location.model || !this.location.model.get('terminalMaxVersions')) {
        return false;
      }

      var validTerminalVersion = true;

      if (this.location.model.isCorpHq) {
        var currCorpId = this.location.model.corpId;
        var filteredLocations = this.store.peekAll('location').filter(function (_ref) {
          var corpId = _ref.corpId,
              isCorpHq = _ref.isCorpHq;
          return +corpId === +currCorpId && !isCorpHq;
        });
        filteredLocations.forEach(function (loc) {
          var ewTerminalInfo = loc.terminalMinVersions.find(function (item) {
            return item.product === _products.default.EW;
          });
          validTerminalVersion = validTerminalVersion && ewTerminalInfo && ewTerminalInfo.minVersion ? _semver.default.gte(_semver.default.coerce(ewTerminalInfo.minVersion), _semver.default.coerce('1.100')) : false;
        });
      } else {
        var ewTerminalInfo = this.location.model.get('terminalMaxVersions').find(function (item) {
          return item.product === _products.default.EW;
        });
        validTerminalVersion = ewTerminalInfo && ewTerminalInfo.maxVersion ? _semver.default.gte(_semver.default.coerce(ewTerminalInfo.maxVersion), _semver.default.coerce('1.100')) : false;
      }

      return this.launchDarkly.stposFeatureFlags[_launchDarklyFeatureFlags.default.EW_LH_MENU_AVAILABILITY] && validTerminalVersion;
    })
  });

  _exports.default = _default;
});