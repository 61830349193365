define("lh4/components/ui/label/ticket-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    color: Ember.computed('status', function getter() {
      switch (this.status) {
        case 'Resolved':
          return 'green';

        case 'In Progress':
        case 'Waiting on Customer':
          return 'yellow';

        default:
          return 'grey';
      }
    })
  });

  _exports.default = _default;
});