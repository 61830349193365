define("lh4/components/virtual-terminal/refund-settings/steps/questions-verify/index", ["exports", "lh4/mixins/components/data-testable-component"], function (_exports, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#ui/step-container/form-content stepHeader=stepHeader}}
  	{{ui/form-fields/radio-list
  		dataTest="question-field"
  		changeset=changeset
  		items=changeset.answers
  		label=changeset.prompt
  		property="answer"
  		showErrors=showErrors
  	}}
  	{{ui/form-fields/required-fields dataTest="required-indicator"}}
  {{/ui/step-container/form-content}}
  
  */
  {
    id: "igJieeNk",
    block: "{\"symbols\":[],\"statements\":[[4,\"ui/step-container/form-content\",null,[[\"stepHeader\"],[[24,[\"stepHeader\"]]]],{\"statements\":[[0,\"\\t\"],[1,[28,\"ui/form-fields/radio-list\",null,[[\"dataTest\",\"changeset\",\"items\",\"label\",\"property\",\"showErrors\"],[\"question-field\",[24,[\"changeset\"]],[24,[\"changeset\",\"answers\"]],[24,[\"changeset\",\"prompt\"]],\"answer\",[24,[\"showErrors\"]]]]],false],[0,\"\\n\\t\"],[1,[28,\"ui/form-fields/required-fields\",null,[[\"dataTest\"],[\"required-indicator\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/virtual-terminal/refund-settings/steps/questions-verify/index.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'virtual-terminal-refund-settings-steps-questions-verify',
    classNames: ['questions-verify-step']
  }));

  _exports.default = _default;
});