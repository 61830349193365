define("lh4/templates/authenticated/customer-engagement/campaigns/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w2ORG+UY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"customer-engagement/campaign-container\",null,[[\"campaign\"],[[24,[\"model\",\"campaign\"]]]],{\"statements\":[[0,\"\\t\"],[1,[28,\"customer-engagement/campaign-edit-wizard\",null,[[\"campaign\",\"locations\",\"templates\",\"step\",\"saveLocationSettings\",\"selectedSettings\",\"canGoForward\",\"isLoading\",\"preview\",\"goForward\",\"goBackward\",\"discardChanges\",\"delete\",\"saveAs\",\"beforeDelete\"],[[24,[\"model\",\"campaign\"]],[24,[\"model\",\"locations\"]],[24,[\"model\",\"templates\"]],[24,[\"step\"]],[24,[\"saveLocationSettings\"]],[24,[\"selectedSettings\"]],[24,[\"canGoForward\"]],[24,[\"isLoading\"]],[24,[\"preview\"]],[28,\"action\",[[23,0,[]],\"goForward\"],null],[28,\"action\",[[23,0,[]],\"goBackward\"],null],[28,\"action\",[[23,0,[]],\"discardChanges\"],null],[28,\"action\",[[23,0,[]],\"delete\"],null],[28,\"action\",[[23,0,[]],\"saveAs\"],null],[28,\"action\",[[23,0,[]],\"beforeDelete\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/customer-engagement/campaigns/create.hbs"
    }
  });

  _exports.default = _default;
});