define("lh4/components/reputation/rating-history-chart", ["exports", "moment", "ember-data"], function (_exports, _moment, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ChartPeriods = {
    ONE_WEEK: '1w',
    ONE_MONTH: '1m',
    ONE_YEAR: '1y',
    ALL: 'all'
  };
  /**
   * Convert period string ('1w', '1m', '1y') to date range object
   *
   * @param {ChartPeriods} period - range period
   * @returns {{from: moment, to: moment}} date range object
   */

  function toDateRange(period) {
    var from;

    switch (period) {
      case ChartPeriods.ALL:
        from = null;
        break;

      case ChartPeriods.ONE_YEAR:
        from = (0, _moment.default)().subtract(1, 'years');
        break;

      case ChartPeriods.ONE_MONTH:
        from = (0, _moment.default)().subtract(1, 'months');
        break;

      case ChartPeriods.ONE_WEEK:
        from = (0, _moment.default)().subtract(1, 'weeks');
        break;

      default:
        break;
    }

    return {
      from: from,
      to: (0, _moment.default)()
    };
  }

  var _default = Ember.Component.extend({
    classNames: ['chart-overview'],
    accountApi: Ember.inject.service('reputation/account-api'),
    errorHandler: Ember.inject.service(),
    ChartPeriods: ChartPeriods,
    period: ChartPeriods.ONE_WEEK,
    ratingHistory: Ember.computed('period', function getter() {
      var _this = this;

      if (!this.account.externalId) {
        return {
          ratings: []
        };
      }

      var _toDateRange = toDateRange(this.period),
          from = _toDateRange.from,
          to = _toDateRange.to;

      var offset = (0, _moment.default)().tz(_moment.default.tz.guess()).utcOffset();
      return _emberData.default.PromiseObject.create({
        promise: this.accountApi.getRatingHistory(this.account.externalId, {
          from: from && from.format(),
          to: to && to.format(),
          offset: offset
        }).then(function (results) {
          var ratings = (results.ratings || []).filter(function (rating) {
            return !rating.errorInfo;
          });
          return {
            ratings: ratings,
            from: from && from.toDate(),
            to: to && to.toDate()
          };
        }).catch(function (err) {
          _this.errorHandler.handleResponseError(err);

          return {
            ratings: []
          };
        })
      });
    }),
    chartOptions: {
      categoryField: 'date',
      dataDateFormat: _moment.default.HTML5_FMT.DATE,
      parseDates: true,
      valueAxesOptions: {
        minimum: 0,
        maximum: 5,
        integersOnly: true
      },
      valueField: 'value'
    },
    dataArray: Ember.computed('ratingHistory.ratings.[]', function getter() {
      var ratings = this.ratingHistory.get('content.ratings') || [];
      return ratings.map(function (r) {
        return {
          date: (0, _moment.default)(r.createdAt).format(_moment.default.HTML5_FMT.DATE),
          value: parseFloat(r.reviewAverage)
        };
      }).sortBy('date');
    })
  });

  _exports.default = _default;
});