define("lh4/routes/authenticated/hospitality/customer-database/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.peekRecord('hospitality/customer', params.customerId);
    }
  });

  _exports.default = _default;
});