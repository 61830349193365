define("lh4/serializers/settings/user", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'id',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'settings/user';
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.email = json.emailAddress; // This is a must since we can sometimes send 1-2k+ arrays to endpoint that break aws-xray-sdk-express package

      delete json.userPermissionMaps;
      return json;
    }
  });

  _exports.default = _default;
});