define("lh4/enums/timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIMEZONE = Object.freeze({
    PST: 'America/Los_Angeles',
    UTC: 'Etc/UTC'
  });
  var _default = TIMEZONE;
  _exports.default = _default;
});