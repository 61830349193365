define("lh4/components/sign-in/alerts", ["exports", "ember-data", "lh4/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'alerts'],
    ajax: Ember.inject.service(),
    request: {
      alerts: []
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (_environment.default.maintenanceMessagesUrl) {
        this.set('request', _emberData.default.PromiseObject.create({
          promise: this.ajax.request(_environment.default.maintenanceMessagesUrl)
        }));
      }
    }
  });

  _exports.default = _default;
});