define("lh4/templates/components/ui/tab-container/tab-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "13PTAbf7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"tabName\"]],[24,[\"activeTab\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/tab-container/tab-content.hbs"
    }
  });

  _exports.default = _default;
});