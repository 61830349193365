define("lh4/templates/authenticated/internet-payments/account-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uz/AMSaH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isAccountSettingsActive\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"iframe\",true],[11,\"src\",[22,\"decisionsIframeURL\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/account-settings.hbs"
    }
  });

  _exports.default = _default;
});