define("lh4/models/overview", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    terminals: _emberData.default.attr(),
    tickets: _emberData.default.attr(),
    batches: _emberData.default.attr(),
    statements: _emberData.default.attr(),
    labor: _emberData.default.attr(),
    clockedIn: _emberData.default.attr(),
    notices: _emberData.default.hasMany(),
    '1099s': _emberData.default.attr()
  });

  _exports.default = _default;
});