define("lh4/components/customer-engagement/campaign-edit-wizard/email-template-editor", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADER_SELECTOR = '#header-text';
  var BODY_SELECTOR = '#body-text';
  var IMAGE_SELECTOR = '#image';
  var SUPPORTED_MIME_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif']; // eslint-disable-next-line require-jsdoc

  function subscribeEditable(element, onChange) {
    if (!element) {
      return;
    }

    element.text('');
    element.attr('contenteditable', true);
    element.css({
      'border-color': 'rgb(237, 237, 237)',
      'border-width': '1px',
      'border-style': 'solid',
      'border-radius': '2px'
    });
    element.on('blur keyup paste input', function () {
      return onChange(element.text());
    });
    element.keypress(function (e) {
      return e.which !== 13;
    });
  } // eslint-disable-next-line require-jsdoc


  function subscribeImage(element, onClick) {
    if (!element.length) {
      return;
    }

    element.on('click', function (e) {
      return onClick(e);
    });
    element.css('cursor', 'pointer');
    element.parent().attr('data-tooltip', 'Click to select an image');
  }

  var _default = Ember.Component.extend({
    campaign: null,
    template: null,
    isLoading: false,
    headerElement: null,
    bodyElement: null,
    imageElement: null,
    didInsertElement: function didInsertElement() {
      var handleImageChange = Ember.run.bind(this, this.actions.handleImageChange);
      var input = this.$('input:file');
      input.on('change', handleImageChange);
      input.attr('accept', SUPPORTED_MIME_TYPES.join());
    },
    templateChanged: Ember.observer('template', function callback() {
      var _this = this;

      if (!this.get('template')) {
        this.set('headerElement', null);
        this.set('bodyElement', null);
        this.set('imageElement', null);
        return;
      }

      Ember.run.later(function () {
        var headerElement = (0, _jquery.default)(HEADER_SELECTOR);
        var bodyElement = (0, _jquery.default)(BODY_SELECTOR);
        var imageElement = (0, _jquery.default)(IMAGE_SELECTOR);

        var campaign = _this.get('campaign');

        _this.set('headerElement', headerElement);

        _this.set('bodyElement', bodyElement);

        _this.set('imageElement', imageElement);

        subscribeEditable(headerElement, function (value) {
          return campaign.set('headerText', value);
        });
        subscribeEditable(bodyElement, function (value) {
          return campaign.set('bodyText', value);
        });
        subscribeImage(imageElement, function (e) {
          return _this.send('browse', e);
        });

        _this.send('setValues');
      }, 100);
    }).on('init'),
    campaignChanged: Ember.observer('campaign.headerText', 'campaign.bodyText', 'campaign.imageUrl', function callback() {
      this.send('setValues');
    }),
    actions: {
      handleImageChange: function handleImageChange(event) {
        var file = event.target.files[0] || null;
        (0, _jquery.default)(IMAGE_SELECTOR).attr('src', URL.createObjectURL(file));
        this.sendAction('onImageSelected', file);
      },
      browse: function browse(event) {
        if (event.target.type !== 'file') {
          this.$('input:file').click();
        }
      },
      setValues: function setValues() {
        var campaign = this.get('campaign');
        var headerElement = this.get('headerElement');
        var bodyElement = this.get('bodyElement');
        var imageElement = this.get('imageElement');

        if (headerElement && headerElement.text() !== campaign.get('headerText')) {
          headerElement.text(campaign.get('headerText'));
        }

        if (bodyElement && bodyElement.text() !== campaign.get('bodyText')) {
          bodyElement.text(campaign.get('bodyText'));
        }

        if (imageElement) {
          imageElement.attr('src', this.get('campaign.imageUrl'));
        }
      }
    }
  });

  _exports.default = _default;
});