define("lh4/models/customer-engagement/customer", ["exports", "ember-data", "ember-data/relationships", "lh4/models/customer-engagement/customer/email"], function (_exports, _emberData, _relationships, _email) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var typeComparator = function typeComparator(a, b, types) {
    var aOrderIndex = types.findIndex(function (type) {
      return type.name === a.get('type');
    });
    var bOrderIndex = types.findIndex(function (type) {
      return type.name === b.get('type');
    });
    return aOrderIndex - bOrderIndex;
  };

  var primaryFromArray = function primaryFromArray(array, collectionName) {
    return Ember.computed("".concat(collectionName, ".@each.type"), function getter() {
      return this.get(collectionName).toArray().sort(function (a, b) {
        return typeComparator(a, b, array);
      }).get('firstObject');
    });
  };

  var _default = _emberData.default.Model.extend({
    guid: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    firstName: _emberData.default.attr('string'),
    middleName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    lookup: _emberData.default.attr('string'),
    taxExempt: _emberData.default.attr('boolean'),
    birthday: _emberData.default.attr('string'),
    anniversary: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    subscribed: _emberData.default.attr('boolean'),
    externalRefId: _emberData.default.attr('string'),
    emails: (0, _relationships.hasMany)('customer-engagement/customer/email'),
    primaryEmail: primaryFromArray(_email.emailTypes, 'emails')
  });

  _exports.default = _default;
});