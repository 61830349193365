define("lh4/mirage/factories/subscriptionsRecipient", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    reportSubscriptionId: 1,
    destination: 't@t.com',
    lastDeliveredAt: null,
    lastError: null
  });

  _exports.default = _default;
});