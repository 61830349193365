define("lh4/templates/components/modal/workforce-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ExtoONPO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ui-modal\",null,[[\"centered\",\"onHidden\"],[false,[28,\"action\",[[23,0,[]],\"onHidden\"],null]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"title\"],false],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",\"close icon\"],[12,\"role\",\"button\"],[12,\"data-test-wf-modal\",\"close\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"message\"],false],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"do-not-remind-me\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"ui-checkbox\",null,[[\"class\",\"label\",\"checked\",\"onChange\"],[\"toggle\",\"Don’t remind me again\",[24,[\"hideWfNotification\"]],[28,\"action\",[[23,0,[]],\"setHideWfNotification\"],null]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/modal/workforce-notification.hbs"
    }
  });

  _exports.default = _default;
});