define("lh4/components/ui/client-pagination-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    location: Ember.inject.service(),
    tagName: '',
    totalPages: 0,
    currentPage: 1,
    maxPagesShown: undefined,
    showPageLinks: true,
    _offset: Ember.computed('currentPage', function getter() {
      if (this.get('maxPagesShown') && this.get('maxPagesShown') < this.get('totalPages')) {
        var half = Math.ceil(this.get('maxPagesShown') / 2); // ending portion

        if (this.get('currentPage') - half + this.get('maxPagesShown') >= this.get('totalPages')) {
          return this.get('totalPages') - this.get('maxPagesShown'); // beginning portion
        }

        if (this.get('currentPage') > half) {
          return this.get('currentPage') - half;
        }
      }

      return 0;
    }),
    pageNumbers: Ember.computed('totalPages', '_offset', function getter() {
      var offset = this.get('_offset');
      var max = this.get('totalPages');

      if (this.get('maxPagesShown') && this.get('maxPagesShown') < this.get('totalPages')) {
        max = this.get('maxPagesShown');
      }

      var pageNumbers = [];

      for (var i = 1; i <= max; i += 1) {
        if (offset >= 1 && i <= 2) {
          if (i === 1) {
            pageNumbers.push(1);
          }

          if (i === 2) {
            pageNumbers.push('...');
          }
        } else if (offset + max < this.get('totalPages') && i >= max - 1) {
          if (i === max - 1) {
            pageNumbers.push('...');
          }

          if (i === max) {
            pageNumbers.push(this.get('totalPages'));
          }
        } else {
          pageNumbers.push(i + offset);
        }
      }

      return pageNumbers;
    }),
    disableFirst: Ember.computed('currentPage', 'totalPages', function getter() {
      return this.get('totalPages') === 0 || this.get('currentPage') === 1;
    }),
    disableNext: Ember.computed('currentPage', 'totalPages', function getter() {
      return this.get('totalPages') === 0 || this.get('currentPage') >= this.get('totalPages');
    }),
    disablePrevious: Ember.computed('currentPage', 'totalPages', function getter() {
      return this.get('totalPages') === 0 || this.get('currentPage') === 1;
    }),
    disableLast: Ember.computed('currentPage', 'totalPages', function getter() {
      return this.get('totalPages') === 0 || this.get('currentPage') >= this.get('totalPages');
    }),
    actions: {
      pageChanged: function pageChanged(pageNo) {
        if (pageNo === '...' || this.currentPage === pageNo) {
          return;
        }

        this.sendAction('onPageChange', pageNo);
      },
      viewFirstPage: function viewFirstPage() {
        if (this.get('disableFirst')) {
          return;
        }

        var firstPage = 1;
        this.sendAction('onPageChange', firstPage);
      },
      pageIncrement: function pageIncrement() {
        if (this.get('disableNext')) {
          return;
        }

        this.sendAction('onPageChange', this.get('currentPage') + 1);
      },
      pageDecrement: function pageDecrement() {
        if (this.get('disablePrevious')) {
          return;
        }

        this.sendAction('onPageChange', this.get('currentPage') - 1);
      },
      viewLastPage: function viewLastPage() {
        if (this.get('disableLast')) {
          return;
        }

        var lastPage = this.get('totalPages');
        this.sendAction('onPageChange', lastPage);
      }
    }
  });

  _exports.default = _default;
});