define("lh4/models/echo-pro/employee-date", ["exports", "ember-data", "ember-data/relationships", "ember-cp-validations"], function (_exports, _emberData, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateTypes = _exports.default = void 0;
  var dateTypes = [{
    name: 'Hired',
    value: 'Hired'
  }, {
    name: 'Review',
    value: 'Review'
  }, {
    name: 'Terminated',
    value: 'Termination'
  }];
  _exports.dateTypes = dateTypes;
  var Validations = (0, _emberCpValidations.buildValidations)({
    date: {
      description: 'Date',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    type: (0, _emberCpValidations.validator)(function (value) {
      return dateTypes.some(function (type) {
        return type.value === value;
      }) || 'Incorrect Date Type';
    })
  });

  var _default = _emberData.default.Model.extend(Validations, {
    date: _emberData.default.attr('string'),
    locationId: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    dateTypes: dateTypes,
    employee: (0, _relationships.belongsTo)('echo-pro/employee')
  });

  _exports.default = _default;
});