define("lh4/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VC8emLLx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"wrap-content\"],[11,\"class\",[29,[[28,\"if\",[[24,[\"location\",\"hasInternetPaymentProduct\"]],\"internet-payments-wrapper\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showLaunchControl\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"launch-control/launch-control-modal\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\n\"],[4,\"if\",[[24,[\"isMicrofrontend\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"main\",true],[11,\"class\",[29,[\"site-content \",[28,\"if\",[[28,\"not\",[[24,[\"location\",\"hasInternetPaymentProduct\"]]],null],\"look-and-feel-v2\"],null],\" microfrontend\"]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"main\",true],[11,\"class\",[29,[\"site-content \",[28,\"if\",[[28,\"not\",[[24,[\"location\",\"hasInternetPaymentProduct\"]]],null],\"look-and-feel-v2\"],null],\" \",[28,\"if\",[[24,[\"sidebar\",\"isOpen\"]],\"sidebar-open\"],null]]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[7,\"div\",true],[10,\"id\",\"tray\"],[8],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"id\",\"modals\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"ui/s4/snackbar/snackbar\",[],[[],[]]],[0,\"\\n\"],[5,\"zendesk\",[],[[],[]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/application.hbs"
    }
  });

  _exports.default = _default;
});