define("lh4/helpers/uppercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upperCase = upperCase;
  _exports.default = void 0;

  /**
   * Converts string to uppercase
   * @param {String} value - string to be converted
   * @returns {String} - new string representing the calling one converted to upper case
   */
  function upperCase(value) {
    return value.toString().toUpperCase();
  }

  var _default = Ember.Helper.helper(upperCase);

  _exports.default = _default;
});