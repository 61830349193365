define("lh4/components/ui/tray/sliding-tray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /* @param {string} errorMessage - Message to display for form level error */
    errorMessage: null,
    shouldClose: false,
    isTrayOpen: false,
    onClose: function onClose() {},
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.shouldClose) {
        return Ember.run.next(function () {
          return _this.send('close');
        });
      } // Make sure element is not destroyed and tray is not open yet. This could happen when tray is open,
      // fast clicking Save and item in the list to open tray again.
      // @see https://jira.shift4payments.com/browse/LH-8182


      return Ember.run.next(function () {
        return _this.element && !_this.isTrayOpen && _this.set('isTrayOpen', true);
      });
    },
    actions: {
      close: function close() {
        if (!this.onBeforeClose || this.onBeforeClose()) {
          this.onClose();
        }
      }
    }
  });

  _exports.default = _default;
});