define("lh4/controllers/authenticated/locations/location/echo-pro/manage-pos/table-layout", ["exports", "ember-uuid", "jquery"], function (_exports, _emberUuid, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    logger: Ember.inject.service(),
    editRecord: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    isLoading: false,
    addedRecord: null,
    addedSection: null,
    sectionLoading: false,
    sections: Ember.computed.filter('model.section', function filterCallback(section) {
      return section.locationId === parseInt(this.model.location.id, 10);
    }),
    tables: Ember.computed.filter('model.tables', function filterCallback(table) {
      return table.locationId === parseInt(this.model.location.id, 10);
    }),
    actions: {
      elementSectionChanged: function elementSectionChanged(id, selectedSection) {
        this.send('updateTableProps', id, {
          sectionGuid: selectedSection,
          sectionId: selectedSection.id
        });
      },
      updateTableProps: function updateTableProps(id, properties) {
        var rec = this.store.peekAll('reservations/table').findBy('guid', id);
        rec.setProperties(properties);
      },
      createRecord: function createRecord(type, recordAttributes) {
        var attributes = recordAttributes;
        attributes.locationId = parseInt(this.get('model.location.id'), 10);

        if (type === 'table') {
          var section = this.store.peekRecord('reservations/section', attributes.sectionId || attributes.sectionGuid);
          section = section || this.get('sections').find(function (s) {
            return s.get('id') === attributes.sectionId;
          });
          attributes.sectionGuid = section;
        }

        var rec = this.store.createRecord("reservations/".concat(type), attributes);

        if (type === 'table') {
          this.set('addedRecord', rec);
        } else if (type === 'section') {
          rec.set('id', (0, _emberUuid.v4)());
          this.set('addedSection', rec);
        }
      },
      saveChanges: function saveChanges() {
        var _this = this;

        this.set('isLoading', true);
        var newSections = this.store.peekAll('reservations/section').filterBy('isNew', true);
        newSections.forEach(function (section) {
          section.set('id', null);
        });
        var saveSections = this.store.peekAll('reservations/section').filterBy('hasDirtyAttributes', true);
        var tables = this.store.peekAll('reservations/table');
        var tablesMovedToNewSection = tables.filter(function (table) {
          return table.get('sectionGuid.isNew');
        });
        this.editRecord.validateAndSaveRecords(saveSections).then(function () {
          return _this.editRecord.validateAndSaveRecords(tables.filter(function (t) {
            return t.hasDirtyAttributes || tablesMovedToNewSection.includes(t);
          }));
        }).then(function () {
          _this.flashMessagesHelper.pushMessage('Changes saved successfully', 'success');
        }).catch(function (errorMessages) {
          if (errorMessages && errorMessages.length > 0) {
            _this.logger.log("".concat(errorMessages[0], " error occurred when saving reservations/table changes"));

            _this.flashMessagesHelper.pushMessages(errorMessages, 'error');
          } else {
            _this.logger.log("".concat(errorMessages, " error occurred when saving reservations/table changes"));

            _this.flashMessagesHelper.pushMessage('Error occurred while saving table reservations', 'error');
          }
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      },
      transitionModal: function transitionModal() {
        (0, _jquery.default)('#discard-table-changes').modal('show');
      },
      discardChanges: function discardChanges() {
        this.tables.forEach(function (table) {
          if (table.hasDirtyAttributes) {
            table.rollbackAttributes();
          }
        });
        this.sections.forEach(function (section) {
          if (section.hasDirtyAttributes) {
            section.rollbackAttributes();
          }
        });
        this.previousTransition.retry();
      }
    }
  });

  _exports.default = _default;
});