define("lh4/templates/components/ui/form-fields/textarea-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zSk3LrAm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"fieldClasses\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[4,\"ui/form-fields/shared/label\",null,[[\"fullWidth\",\"required\"],[[24,[\"fullWidth\"]],[24,[\"required\"]]]],{\"statements\":[[1,[22,\"label\"],false]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\"],[7,\"textarea\",true],[11,\"class\",[22,\"textareaClass\"]],[11,\"value\",[28,\"get\",[[24,[\"changeset\"]],[24,[\"property\"]]],null]],[11,\"disabled\",[28,\"if\",[[24,[\"disabled\"]],\"disabled\",null],null]],[11,\"maxlength\",[22,\"maxLength\"]],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"onChange\"],[[\"value\"],[\"target.value\"]]]],[11,\"onblur\",[28,\"action\",[[23,0,[]],\"blurField\"],null]],[11,\"onfocus\",[28,\"action\",[[23,0,[]],\"focusField\"],null]],[11,\"placeholder\",[22,\"placeholder\"]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showErrorTooltip\"]]],null,{\"statements\":[[4,\"ui/popup/error-popup\",null,null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"get\",[[28,\"get\",[[24,[\"changeset\",\"error\"]],[24,[\"property\"]]],null],\"validation\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/form-fields/textarea-field.hbs"
    }
  });

  _exports.default = _default;
});