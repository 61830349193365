define("lh4/components/ui/navigation/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    location: Ember.inject.service(),
    hasNotification: Ember.computed('notificationList', function getter() {
      var _this = this;

      // Remove notifications from Locations menu item for CorpHq locations
      return this.notificationList.some(function (item) {
        if (_this.location.model.isCorpHq && item.toLowerCase() === 'location') {
          return false;
        }

        return item.toLowerCase() === _this.name.toLowerCase();
      });
    })
  });

  _exports.default = _default;
});