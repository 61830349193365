define("lh4/components/dashboard/data-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showChartOrTable: Ember.computed('selected', function getter() {
      return this.get('selected') === 'Table';
    }),
    chartName: null,
    singleLocation: false,
    chartId: Ember.computed('chartName', function getter() {
      return Ember.String.dasherize(this.get('chartName'));
    }),
    barChartFill: Ember.computed(function getter() {
      // dynamically get this color from the css
      return this.$('.color-box').css('background-color');
    }),
    toggleIcon: Ember.computed('showChartOrTable', function getter() {
      return this.get('showChartOrTable') ? 'bar chart' : 'table';
    }),
    cleanData: Ember.computed('data', function getter() {
      if (this.data.length === 1) {
        this.set('singleLocation', true);
      }

      return this.data.sortBy('total').map(function (_ref) {
        var _ref$location = _ref.location,
            name = _ref$location.name,
            merchantId = _ref$location.merchantId,
            total = _ref.total;
        return {
          name: name,
          merchantId: merchantId,
          total: total
        };
      });
    }),
    chartOptions: Ember.computed(function getter() {
      return {
        balloonText: '$[[value]]',
        categoryField: 'name',
        valueAxesOptions: {
          precision: 2,
          unit: '$',
          unitPosition: 'left'
        },
        valueField: 'total',
        color: this.barChartFill
      };
    }),
    actions: {
      tabSelection: function tabSelection() {
        this.toggleProperty('showChartOrTable');
      }
    }
  });

  _exports.default = _default;
});