define("lh4/routes/authenticated/epro/manage-employees/jobs", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    location: Ember.inject.service(),
    requiredPermissions: 'manage echo-pro/employee-and-job',
    queryParams: {
      searchName: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      isActive: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var isActive = _ref.isActive,
          offset = _ref.offset,
          limit = _ref.limit,
          searchName = _ref.searchName,
          order = _ref.order,
          sort = _ref.sort;
      var jobs = this.store.query('echo-pro/job', {
        isActive: isActive,
        location: this.location.model.id,
        offset: offset,
        limit: limit,
        searchName: searchName,
        order: order,
        sort: sort
      });
      return Ember.RSVP.hash({
        jobs: jobs
      });
    }
  });

  _exports.default = _default;
});