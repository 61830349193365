define("lh4/components/social-media/post-schedule", ["exports", "moment", "ember-cp-validations", "lh4/utils/validation-check", "lh4/services/social-media/facebook", "lh4/services/social-media/twitter"], function (_exports, _moment, _emberCpValidations, _validationCheck, _facebook, _twitter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IMG_PANEL_UPLOAD = 'panelImageUpload';
  var IMG_PANEL_URL = 'panelImageUrl';
  var PostValidations = (0, _emberCpValidations.buildValidations)({
    'post.text': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'post.scheduleTime': {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed('model.isInstant', function getter() {
          return this.get('model.isInstant');
        })
      }), (0, _emberCpValidations.validator)(function (value) {
        return !value || (0, _moment.default)(value).isAfter((0, _moment.default)());
      })]
    }
  });

  var _default = Ember.Component.extend(PostValidations, {
    isInstant: true,
    isRepeating: true,
    isFrequencyValid: true,
    media: Ember.inject.service(),
    location: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('isNew')) {
        this.setProperties({
          isRepeating: false,
          'post.scheduleRepeatStart': null,
          'post.scheduleRepeat': null,
          'post.scheduleRepeatTz': null
        });
      }

      if (this.get('post.scheduleRepeatStart') || this.get('post.scheduleTime') && !this.get('isNew')) {
        this.set('isInstant', false);
      }

      if (typeof this.get('post.scheduleRepeat') === 'string') {
        this.set('isRepeating', true);
        this.set('post.scheduleTime', _moment.default.tz(this.get('post.scheduleRepeatNextTime'), this.get('post.scheduleRepeatTz')).format());
      } else if (this.get('post.scheduleRepeat') == null) {
        this.set('isRepeating', false);
      }

      if (this.get('post.picture1Uri')) {
        this.set('imageUploadPanel', IMG_PANEL_URL);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('imageUri', this.get('post.picture1Uri'));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().parent().bind('scroll', function () {
        var calendar = _this.$('.date-picker-input-form > .fields > .field:nth-child(2) > input');

        var clock = _this.$('.date-picker-input-form > .fields > .field:last-child > .clockpicker > input');

        if (calendar.is(':focus')) {
          // Calendar
          calendar.blur();
        } else if (clock.is(':focus')) {
          // Clock
          clock.blur();

          _this.$('.segment').trigger('click');
        }
      });
    },
    tagName: 'div',
    classNames: ['ui segments sm-post-schedule'],

    /**
     * @param {object} account - social media account
     */
    account: null,

    /**
     * @param {boolean} isLoading - parent component's loading control switch
     */
    isLoading: false,

    /**
     * @param {object} post - social media post
     */
    post: null,
    accountApi: Ember.inject.service('social-media/account-api'),
    flashMessagesHelper: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    ajax: Ember.inject.service(),
    imageUploadPanels: [{
      text: 'Upload Image',
      value: IMG_PANEL_UPLOAD
    }, {
      text: 'Image from web',
      value: IMG_PANEL_URL
    }],
    imageUploadPanel: IMG_PANEL_UPLOAD,
    imageUri: null,
    cropperOptions: {
      zoomable: false,
      scalable: false,
      viewMode: 1
    },
    isNew: false,
    isDeleteConfirmationOpen: false,
    resetPicture: Ember.observer('imageUploadPanel', function callback() {
      if (this.get('imageUploadPanel') === IMG_PANEL_UPLOAD) {
        this.set('post.picture1Uri', this.get('previousPicture1UploadUri'));
      } else {
        this.set('post.picture1Uri', null);
      }
    }),
    showErrors: false,
    postMaxLength: Ember.computed('account.type', function getter() {
      var accountType = this.get('account.type');

      switch (accountType) {
        case _facebook.ACCOUNT_TYPE_FB_PAGE:
          return _facebook.POST_MAX_LENGTH;

        case _twitter.ACCOUNT_TYPE_TWITTER:
          return _twitter.POST_MAX_LENGTH;

        default:
          return 280;
      }
    }),
    minDate: Ember.computed(function () {
      return (0, _moment.default)().toDate();
    }),
    scheduleTimeChanged: Ember.observer('post.scheduleTime', 'post.scheduleRepeat', function callback() {
      /*
      	Only change timezone if time has changed - to persist last user's timezone.
      	Otherwise time wasn't changed so timezone should be from previous user.
      */
      this.set('post.scheduleRepeatTz', this.location.getLocationTimezone());
    }),
    actions: {
      onAfterSelect: function onAfterSelect(instance, date) {
        this.set('post.scheduleTime', date);
      },
      onTimeChange: function onTimeChange(instance, date) {
        this.set('post.scheduleTime', date);
      },
      onMobileChange: function onMobileChange(date) {
        this.set('post.scheduleTime', date);
      },
      handleFreqValidationResults: function handleFreqValidationResults(isValid) {
        this.set('isFrequencyValid', isValid);
      },
      cropImage: function cropImage(_ref) {
        var _this2 = this;

        var fullBlob = _ref.fullBlob;
        this.set('isLoading', true);
        return this.accountApi.uploadImage(this.get('account.id'), fullBlob).then(function (picture1Uri) {
          _this2.set('post.picture1Uri', picture1Uri);

          _this2.set('previousPicture1UploadUri', picture1Uri);
        }).catch(function (e) {
          _this2.flashMessagesHelper.pushMessage("There was an unexpected error saving your new image ".concat(e.message), 'error');
        }).finally(function () {
          return _this2.set('isLoading', false);
        });
      },
      deleteImage: function deleteImage() {
        var _this3 = this;

        if (this.get('post').id) {
          // Do not delete actual image - user might discard changes
          this.set('post.picture1Uri', null);
          return;
        }

        this.accountApi.deleteImage(this.get('account.id'), this.get('post')).then(function () {
          _this3.set('post.picture1Uri', null);
        });
      },
      selectPostType: function selectPostType(value) {
        this.set('isInstant', value);

        if (value) {
          this.set('post.isActive', true);
        }
      },
      selectRepeating: function selectRepeating(checked) {
        this.set('isRepeating', checked);

        if (!checked) {
          this.set('post.scheduleRepeatEnd', null);
          this.set('post.scheduleRepeat', null);
        }
      },
      schedulePost: function schedulePost() {
        var _this4 = this;

        if (!this.isFrequencyValid) {
          this.set('showFrequencyErrors', true);
          return;
        }

        this.setProperties({
          showFrequencyErrors: false,
          showErrors: false
        });
        this.validate().then(_validationCheck.default).then(function () {
          if (_this4.get('isInstant')) {
            _this4.setProperties({
              isRepeating: false,
              'post.scheduleRepeatStart': null,
              'post.scheduleTime': null,
              'post.scheduleRepeat': null,
              'post.scheduleRepeatTz': null
            });
          }

          return _this4.sendAction('onSchedulePost', _this4.get('post'), !_this4.get('isRepeating'));
        }).catch(function (e) {
          if (e && e.validations) {
            _this4.set('showErrors', true);
          }
        }).finally(function () {
          return _this4.set('isLoading', false);
        });
      },
      togglePostDelete: function togglePostDelete() {
        this.toggleProperty('isDeleteConfirmationOpen');
      },
      deleteConfirmed: function deleteConfirmed() {
        this.sendAction('onDelete', this.get('post'));
        this.toggleProperty('isDeleteConfirmationOpen');
      },
      cancel: function cancel() {
        this.sendAction('onCancel');
      }
    }
  });

  _exports.default = _default;
});