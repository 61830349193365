define("lh4/mirage/fixtures/internet-payments/risk/risk-block-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RISK_BLOCK_LIST_FIXTURES = {
    emails: {
      'approve@test.com': {
        parameter: 'email',
        result: 'S',
        status: 'A',
        value: 'approve@test.com'
      },
      'decline@test.com': {
        parameter: 'email',
        result: 'S',
        status: 'D',
        value: 'decline@test.com'
      },
      'review@test.com': {
        parameter: 'email',
        result: 'S',
        status: 'R',
        value: 'review@test.com'
      },
      NOTFOUND: {
        parameter: 'email',
        result: 'N',
        value: ''
      }
    },
    devices: {
      'deviceId1234.approve': {
        parameter: 'deviceId',
        result: 'S',
        status: 'A',
        value: 'deviceId1234.approve'
      },
      'deviceId1234.decline': {
        parameter: 'deviceId',
        result: 'S',
        status: 'D',
        value: 'deviceId1234.decline'
      },
      'deviceId1234.review': {
        parameter: 'deviceId',
        result: 'S',
        status: 'R',
        value: 'deviceId1234.review'
      },
      NOTFOUND: {
        parameter: 'deviceId',
        result: 'N',
        value: ''
      }
    },
    tokens: {
      12345678: {
        parameter: 'token',
        result: 'S',
        status: 'D',
        value: '12345678'
      },
      'token-1': {
        parameter: 'token',
        result: 'S',
        status: 'D',
        value: 'token-1'
      },
      'token-3': {
        parameter: 'token',
        result: 'S',
        status: 'D',
        value: 'token-3'
      },
      NOTFOUND: {
        parameter: 'token',
        result: 'N',
        value: ''
      }
    },
    emailDomains: {
      'test.com': {
        parameter: 'emailDomain',
        result: 'S',
        status: 'A',
        value: 'test.com'
      },
      'domain-2': {
        parameter: 'emailDomain',
        result: 'S',
        status: 'D',
        value: 'domain-2'
      }
    },
    fullNames: {
      'John Smith': {
        parameter: 'fullName',
        result: 'S',
        status: 'A',
        value: 'John Smith'
      },
      'customer-2': {
        parameter: 'fullName',
        result: 'S',
        status: 'D',
        value: 'customer-2'
      }
    }
  };
  var _default = RISK_BLOCK_LIST_FIXTURES;
  _exports.default = _default;
});