define("lh4/components/ui/s4/icons/box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.1173 5.41184L9.6155 0.937371C9.18595 0.681684 8.65073 0.681684 8.22119 0.937371L8.22034 0.938223L0.719466 5.41182C0.307822 5.65898 0.0555384 6.10387 0.0546875 6.5837V15.3535C0.0555397 15.8342 0.307822 16.2782 0.719466 16.5254L8.22034 20.9999H8.22119C8.65075 21.2556 9.18682 21.2547 9.61721 20.999L17.1172 16.5254C17.5288 16.2783 17.7811 15.8334 17.782 15.3535V6.58372C17.7811 6.10304 17.5289 5.659 17.1173 5.41184ZM8.91841 2.10923L15.758 6.18901L12.7606 7.93787L6.33187 3.65168L8.91841 2.10923ZM8.91841 10.1785L2.07885 6.18901L5.03456 4.42566L11.4495 8.70246L8.91841 10.1785ZM1.41841 15.3535V7.38224L8.2366 11.3597V19.4213L1.41841 15.3535ZM9.60023 19.4215V11.3599L12.3275 9.76872V13.0142C12.3275 13.3909 12.6326 13.696 13.0093 13.696C13.386 13.696 13.6911 13.3909 13.6911 13.0142V8.97344L16.4184 7.38224V15.3544L9.60023 19.4215Z" fill="black"/>
  </svg>
  
  */
  {
    id: "IPqE6cyR",
    block: "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"18\"],[10,\"height\",\"22\"],[10,\"viewBox\",\"0 0 18 22\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n    \"],[7,\"path\",true],[10,\"d\",\"M17.1173 5.41184L9.6155 0.937371C9.18595 0.681684 8.65073 0.681684 8.22119 0.937371L8.22034 0.938223L0.719466 5.41182C0.307822 5.65898 0.0555384 6.10387 0.0546875 6.5837V15.3535C0.0555397 15.8342 0.307822 16.2782 0.719466 16.5254L8.22034 20.9999H8.22119C8.65075 21.2556 9.18682 21.2547 9.61721 20.999L17.1172 16.5254C17.5288 16.2783 17.7811 15.8334 17.782 15.3535V6.58372C17.7811 6.10304 17.5289 5.659 17.1173 5.41184ZM8.91841 2.10923L15.758 6.18901L12.7606 7.93787L6.33187 3.65168L8.91841 2.10923ZM8.91841 10.1785L2.07885 6.18901L5.03456 4.42566L11.4495 8.70246L8.91841 10.1785ZM1.41841 15.3535V7.38224L8.2366 11.3597V19.4213L1.41841 15.3535ZM9.60023 19.4215V11.3599L12.3275 9.76872V13.0142C12.3275 13.3909 12.6326 13.696 13.0093 13.696C13.386 13.696 13.6911 13.3909 13.6911 13.0142V8.97344L16.4184 7.38224V15.3544L9.60023 19.4215Z\"],[10,\"fill\",\"black\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/box.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});