define("lh4/mirage/factories/internet-payments/dispute-management/dispute", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    acceptedAsLost: false,
    date: '2023-01-01 11:22:12',
    caseNumber: '2384927429',
    caseType: 'RETRIEVAL_REQUEST',
    daysToAct: 10,
    disputeAmount: 2100,
    status: 'NEED_RESPONSE',
    reasonCode: '3344',
    dueBy: '2022-12-30 05:22:12',
    reason: {
      description: 'test-description',
      longDescription: 'test-long-description',
      documents: ['document-1', 'document-2']
    },
    transaction: {
      date: '2022-12-30 05:22:12',
      id: '43534tert34t',
      amount: 2100,
      currency: 'USD',
      acquirerRefNumber: 'DEF43432F',
      merchantReference: 'mkflm34r332f'
    },
    card: {
      last4: '3423',
      brand: 'Visa',
      ipAddress: '123.3432.342'
    },
    evidence: {
      type: 'object',
      properties: {
        productDescription: 'test',
        customerName: 'test1',
        customerEmail: 'test2',
        customerPurchaseIp: 'test3',
        signatureId: 'test4',
        receiptId: 'test5',
        communicationId: 'test6',
        serviceDocId: 'test7',
        serviceDate: 'test8',
        additionalInfoId: 'test9',
        additionalInfoText: 'test10'
      }
    }
  });

  _exports.default = _default;
});