define("lh4/templates/components/ui/icons/sidebar/update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZvxobqXR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M14.8 16.201L11 12.401V7.00098H13V11.601L16.2 14.801L14.8 16.201ZM12 21.001C10.75 21.001 9.57933 20.7636 8.488 20.289C7.396 19.8136 6.446 19.1716 5.638 18.363C4.82933 17.555 4.18733 16.605 3.712 15.513C3.23733 14.4216 3 13.251 3 12.001C3 10.751 3.23733 9.57998 3.712 8.48798C4.18733 7.39664 4.82933 6.44664 5.638 5.63798C6.446 4.82998 7.396 4.18831 8.488 3.71298C9.57933 3.23831 10.75 3.00098 12 3.00098C13.3667 3.00098 14.6627 3.29264 15.888 3.87598C17.1127 4.45931 18.15 5.28431 19 6.35098V4.00098H21V10.001H15V8.00098H17.75C17.0667 7.06764 16.225 6.33431 15.225 5.80098C14.225 5.26764 13.15 5.00098 12 5.00098C10.05 5.00098 8.396 5.67998 7.038 7.03798C5.67933 8.39664 5 10.051 5 12.001C5 13.951 5.67933 15.605 7.038 16.963C8.396 18.3216 10.05 19.001 12 19.001C13.75 19.001 15.279 18.4343 16.587 17.301C17.8957 16.1676 18.6667 14.7343 18.9 13.001H20.95C20.7 15.2843 19.721 17.1883 18.013 18.713C16.3043 20.2383 14.3 21.001 12 21.001Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/update.hbs"
    }
  });

  _exports.default = _default;
});