define("lh4/adapters/overview", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      return type;
    },
    namespace: 'api/v1/locations',
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      // overriding this so that I can add the merchantId query parameter to the find
      var _snapshot$adapterOpti = snapshot.adapterOptions,
          merchantId = _snapshot$adapterOpti.merchantId,
          zone = _snapshot$adapterOpti.zone;
      return "".concat(this._super(id, modelName, snapshot), "?merchantId=").concat(merchantId, "&zone=").concat(encodeURIComponent(zone));
    }
  });

  _exports.default = _default;
});