define("lh4/controllers/authenticated/hospitality/customer-database/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    shouldCloseTray: false,
    actions: {
      close: function close() {
        this.set('shouldCloseTray', false);
        this.transitionToRoute('authenticated.hospitality.customer-database');
      }
    }
  });

  _exports.default = _default;
});