define("lh4/templates/authenticated/customer-engagement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "emzY9+W7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"customer-engagement\"],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"sixteen wide column\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"modals\"]],{\"statements\":[[0,\"\\t\"],[1,[28,\"modal/walkthrough-videos\",null,[[\"key\",\"videos\",\"title\",\"shouldShow\"],[\"customerEngagement\",[24,[\"tutorialVideos\"]],\"Customer Engagement Tutorial\",[28,\"not\",[[24,[\"walkthrough\",\"data\",\"customerEngagement\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/customer-engagement.hbs"
    }
  });

  _exports.default = _default;
});