define("lh4/routes/authenticated/epro/menu/dynamic-pricing", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'manage echo-pro/menu',
    location: Ember.inject.service(),
    queryParams: {
      searchName: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        revenueCenterOptions: this.store.query('echo-pro/revenue-center', {
          location: this.location.model.id
        }).then(function (revenueCenters) {
          return revenueCenters.map(function (_ref) {
            var guid = _ref.guid,
                revenueCenterName = _ref.revenueCenterName;
            return {
              value: guid,
              name: revenueCenterName
            };
          });
        }),
        intradayPeriodGroups: this.store.query('echo-pro/intraday-period-group', {
          location: this.location.model.id
        }).then(function (group) {
          return group.map(function (_ref2) {
            var guid = _ref2.guid,
                intradayPeriodGroupName = _ref2.intradayPeriodGroupName;
            return {
              value: guid,
              name: intradayPeriodGroupName
            };
          });
        }),
        dynamicPrices: this.store.query('echo-pro/availability-price/dynamic-price', _objectSpread({}, params))
      });
    },
    redirect: function redirect() {
      if (this.can.cannot('view dynamic pricing in echo-pro/dynamic-pricing', this.location.model)) {
        this.transitionTo('authenticated.epro.menu.items');
      }
    }
  });

  _exports.default = _default;
});