define("lh4/components/ui/modal/dialog-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    acceptText: 'Save',
    declineText: 'Cancel',
    otherwiseText: 'Delete',
    accept: '',
    decline: '',
    otherwise: ''
  });

  _exports.default = _default;
});