define("lh4/utils/route-location-valid-parent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPathValues = getPathValues;
  _exports.getNextValidParentName = getNextValidParentName;

  /**
   * Returns an array of routeNames and parameter values for a given currentRoute object
   *
   * @node {object} - ember router service currentRoute object.
   * @returns  {array} array of routeNames and parameter values
   */
  function getPathValues(node) {
    var values = [];

    function extractValues(dataNode) {
      if (dataNode.params) {
        values.push({
          routeName: dataNode.name,
          values: Object.values(dataNode.params)
        });
      }

      if (dataNode.parent) {
        extractValues(dataNode.parent);
      }
    }

    extractValues(node);
    return values.reverse();
  }
  /**
   * Returns an array of routeNames and parameter values for a given currentRoute object.
   *
   * Used for switching between locations while on a location specific page.
   * Assumes that only the location id can be trusted, all other sub parameters are specific to that location.
   *
   * @node {object} - ember router service currentRoute object.
   * @returns  {string} route name of the most specific parent route.
   */


  function getNextValidParentName(node) {
    var paths = getPathValues(node);
    var bestPath = '';
    var paramCount = 0;
    paths.forEach(function (path) {
      if (path.values.length > 0) {
        paramCount += 1;
      }

      if (paramCount <= 1) {
        bestPath = path.routeName;
      }
    });
    return bestPath;
  }
});