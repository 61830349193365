define("lh4/services/flash-messages-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MessageTypes = Object.freeze({
    error: 'danger',
    success: 'success',
    negative: 'danger',
    warning: 'warning'
  });
  /**
   * Proxy for `flashMessages` service with additional features.
   */

  var _default = Ember.Service.extend({
    flashMessages: Ember.inject.service(),
    pushMessage: function pushMessage(message) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';

      if (!this.flashMessages[MessageTypes[type]]) {
        return false;
      }

      var msg = message.endsWith('.') || message.endsWith('!') || message.endsWith('?') ? message : "".concat(message, ".");
      this.flashMessages[MessageTypes[type]]("".concat(msg), {
        class: "ui ".concat(type, " message")
      });
      return this;
    },
    pushMessages: function pushMessages(messages) {
      var _this = this;

      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      messages.forEach(function (msg) {
        return _this.pushMessage(msg, type);
      });
      return this;
    },
    tryPushValidationResults: function tryPushValidationResults(validations) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      var showAttributes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (!validations.errors || !validations.errors.length) {
        return false;
      }

      var messages = validations.errors.map(function (_ref) {
        var message = _ref.message,
            attribute = _ref.attribute;
        return showAttributes ? "".concat(message, " \"").concat(attribute, "\"") : "".concat(message);
      });
      this.pushMessages(messages, type);
      return true;
    },
    clearMessages: function clearMessages() {
      this.flashMessages.clearMessages();
      return this;
    }
  });

  _exports.default = _default;
});