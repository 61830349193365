define("lh4/models/echo-pro/permission", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Find out what attribute decides manager/non-manager
  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    employee: (0, _relationships.hasMany)('echo-pro/employee')
  });

  _exports.default = _default;
});