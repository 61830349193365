define("lh4/routes/authenticated/locations/location/hospitality/customize-menu/category/create-category", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        newCategory: this.store.createRecord('hospitality/menu-category', {
          locationId: location.id,
          ordinal: 0
        })
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var model = this.modelFor(this.routeName);

        if (model.newCategory.get('isNew')) {
          this.controllerFor('authenticated.locations.location.hospitality.customize-menu').set('previousTransition', transition);
          this.controllerFor('authenticated.locations.location.hospitality.customize-menu').send('transitionModal');
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});