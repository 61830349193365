define("lh4/routes/authenticated/social-media/accounts/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    errorHandler: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        // Refresh accounts
        accounts: this.store.findAll('social-media/account', {
          reload: true
        }).catch(function (err) {
          return _this.errorHandler.handleErrorCustomMessage(err, 'Could not load social media accounts');
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('searchText', '');
    },
    actions: {
      addSocialNetworkDialog: function addSocialNetworkDialog() {
        this.transitionTo('authenticated.social-media.accounts.add');
      }
    }
  });

  _exports.default = _default;
});