define("lh4/controllers/authenticated/locations/location/echo-pro/reservations/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend({
    flashMessagesHelper: Ember.inject.service(),
    editRecord: Ember.inject.service(),
    turnaroundSettings: Ember.computed.filter('model.settings', function (setting) {
      return setting.get('id').includes('max') || setting.get('id').includes('turnaround');
    }),
    notificationSettings: Ember.computed.filter('model.settings', function (setting) {
      return setting.get('id').includes('notification');
    }),
    validations: Ember.computed('turnaroundSettings.[].validations', 'notificationSettings.[].validations', function getter() {
      var turnaroundSettingsValidations = this.get('turnaroundSettings').map(function (setting) {
        return setting.get('validations');
      });
      var notificationSettingsValidations = this.get('notificationSettings').map(function (setting) {
        return setting.get('validations');
      });
      return [].concat(_toConsumableArray(turnaroundSettingsValidations), _toConsumableArray(notificationSettingsValidations));
    }),
    saving: false,
    actions: {
      saveChanges: function saveChanges() {
        var _this = this;

        this.flashMessagesHelper.clearMessages();
        var validationsMessages = this.get('validations').map(function (validation) {
          return validation.get('message');
        }).filter(function (message) {
          return message;
        });

        if (validationsMessages.length > 0) {
          this.flashMessagesHelper.pushMessages(validationsMessages);
          return;
        }

        if (this.get('model.settings').findBy('id', 'max-party-size').get('value') > 50) {
          this.flashMessagesHelper.pushMessage('Party size cannot be over 50 people', 'error');
          return;
        }

        this.set('saving', true);
        this.editRecord.saveRecords(this.get('model.settings').filter(function (setting) {
          return setting.get('hasDirtyAttributes');
        })).then(function () {
          _this.flashMessagesHelper.pushMessage('Settings was successfully saved', 'success');
        }).catch(function (errorMessages) {
          _this.flashMessagesHelper.pushMessages(errorMessages);
        }).finally(function () {
          return _this.set('saving', false);
        });
      },
      discardChanges: function discardChanges() {
        this.flashMessagesHelper.clearMessages();
        this.get('model.settings').forEach(function (setting) {
          return setting.rollbackAttributes();
        });
        this.flashMessagesHelper.pushMessage('Changes was successfully discarded', 'success');
      }
    }
  });

  _exports.default = _default;
});