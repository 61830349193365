define("lh4/components/ui/radio-accordion/radio-accordion-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isVisible: Ember.computed('for', 'current', 'selectedValue', function getter() {
      return this.get('for') === this.get('selectedValue') && this.get('current') === this.get('selectedValue');
    })
  });

  _exports.default = _default;
});