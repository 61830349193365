define("lh4/templates/components/reports/report-table-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/935ROOM",
    "block": "{\"symbols\":[\"root\",\"@onLinkClick\",\"@showCustomNodeTypes\",\"@isLoading\",\"@generatedReport\",\"@currentReport\",\"@generatedReportType\",\"@reportType\",\"@enabledProd\",\"@selectedLocations\",\"@selectedReport\",\"@onToggleFavorite\",\"@endsAt\",\"@startsAt\",\"@tableTreeWrapperClasses\"],\"statements\":[[5,\"reports/table-tree-wrapper\",[],[[\"@isLoading\",\"@generatedReport\",\"@currentReport\",\"@generatedReportType\",\"@reportType\",\"@enabledProd\",\"@selectedLocations\",\"@selectedReport\",\"@onToggleFavorite\",\"@endsAt\",\"@startsAt\",\"@classes\"],[[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]],[23,15,[]]]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"ui segment \",[28,\"if\",[[23,0,[\"isLoading\"]],\"loading disabled\"],null]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"id\",\"report-table-tree\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"id\",\"report-table-data-wrapper\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"tree\",\"roots\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"report-node \",[23,1,[\"nodeType\"]]]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"table\",true],[11,\"class\",[29,[\"ui celled unstackable table sticky-height-\",[23,1,[\"children\",\"0\",\"rows\",\"length\"]]]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[28,\"ui/tree/report-node\",null,[[\"node\",\"noData\",\"showCustomNodeTypes\",\"onLinkClick\"],[[23,1,[]],[28,\"not\",[[23,1,[\"children\",\"length\"]]],null],[23,3,[]],[23,2,[]]]]],false],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reports/report-table-tree.hbs"
    }
  });

  _exports.default = _default;
});