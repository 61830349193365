define("lh4/components/reports/hospitality/ticket-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortByMap: [{
      id: 'Ticket #'
    }, {
      table: 'Table Name'
    }, 'Created', 'Closed', 'Subtotal', 'Taxes', {
      discount: 'Discounts'
    }, 'Total']
  });

  _exports.default = _default;
});