define("lh4/templates/components/verify-owner/security-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LdJJBBCX",
    "block": "{\"symbols\":[\"question\",\"index\"],\"statements\":[[7,\"h2\",true],[10,\"class\",\"ui header\"],[8],[0,\"Security Questions\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"changeset\",\"questionChangesets\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"ui/form-fields/radio-list\",null,[[\"changeset\",\"items\",\"label\",\"name\",\"property\",\"showErrors\"],[[23,1,[]],[23,1,[\"answers\"]],[23,1,[\"prompt\"]],[28,\"concat\",[\"answer\",[23,2,[]]],null],\"answer\",true]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/verify-owner/security-form.hbs"
    }
  });

  _exports.default = _default;
});