define("lh4/controllers/authenticated/customer-engagement/settings", ["exports", "lh4/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    routeData: [{
      name: 'authenticated.customer-engagement.campaigns',
      title: 'Campaigns'
    }, {
      name: 'authenticated.customer-engagement.settings',
      title: 'Settings'
    }]
  });

  _exports.default = _default;
});