define("lh4/adapters/corporate-group-location-map", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query) {
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(query.corporation, "/groups/location-maps");
    },
    urlForQueryRecord: function urlForQueryRecord(query) {
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(query.corporation, "/groups/location-maps");
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var corpId = snapshot.record.corporateGroup.corpId;
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(corpId, "/groups/location-maps");
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var corpId = snapshot.record.corporateGroup.corpId;
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(corpId, "/groups/location-maps/").concat(id);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var corpId = snapshot.record.corporateGroup.corpId;
      return "".concat(this.host, "/").concat(this.namespace, "/corporations/").concat(corpId, "/groups/location-maps/").concat(id);
    }
  });

  _exports.default = _default;
});