define("lh4/utils/escape-regexp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
  var reHasRegExpChar = RegExp(reRegExpChar.source);
  /**
   * Escape RegExp special characters
   *
   * @param {string} string - string to escape
   * @returns {string} - escaped string
   */

  var escapeRegExp = function escapeRegExp(string) {
    return string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, '\\$&') : string;
  };

  var _default = escapeRegExp;
  _exports.default = _default;
});