define("lh4/mirage/routes/dashboard2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageDashboardV2Server;

  function setMirageDashboardV2Server(server) {
    server.get('/api/v2/dashboard/widgets', function () {
      return {
        availableWidgets: [{
          id: 43,
          name: 'Summary data card',
          slug: 'summary-data-card',
          settings: [],
          deniedProducts: ['echo-pro', 'hospitality', 'mozart', 'retail', 'echo', 'salon', 'qsr', 'terminal'],
          requiredProducts: ['internet-payments'],
          requiredPerm: 10,
          isTitleHidden: true
        }]
      };
    });
    server.get('/api/v2/dashboard/layouts', function () {
      return [{
        id: 2,
        slug: 'internet-payments',
        name: 'Internet Payments Dashboard',
        deniedProducts: [],
        requiredProducts: ['internet-payments'],
        widgets: [{
          slug: 'summary-data-card',
          settings: {}
        }, {
          slug: 'ip-notifications',
          settings: {}
        }, {
          slug: 'summary-bar-chart-card',
          settings: {}
        }, {
          slug: 'ip-transactions',
          settings: {}
        }, {
          slug: 'ip-transactions-risk',
          settings: {}
        }]
      }];
    });
    server.get('/api/v2/dashboard/widgets/:widgetId', function () {
      return {
        id: 1,
        name: 'name',
        slug: 'widget slug',
        data: {}
      };
    });
    server.get('http://localhost:4200/api/v1/development/slides.json', function () {
      return {
        ads: []
      };
    });
    server.get('http://localhost:4200/api/v1/development/skytab-slides.json', function () {
      return {
        ads: [{
          color: '#000000',
          link: 'http://localhost:4200/hardware/skytab-glass',
          active: true,
          headerText: 'Skytab Glass',
          subText: 'Supercharge your servers with fully mobile POS',
          images: {
            mobile: 'https://lh-banner.s3.amazonaws.com/images/skytab-glass-320x204.png',
            tablet: 'https://lh-banner.s3.amazonaws.com/images/skytab-glass-645x363.png',
            desktop: 'https://lh-banner.s3.amazonaws.com/images/skytab-glass-798x453.png'
          }
        }]
      };
    });
    server.get('/api/v2/dashboard/widgets/summary-data-card', function () {
      return {
        gross: [{
          date: '2022-01-28',
          firstChart: '10',
          secondChart: '13'
        }, {
          date: '2022-01-29',
          firstChart: '5',
          secondChart: '0'
        }, {
          date: '2022-01-30',
          firstChart: '35',
          secondChart: '12'
        }, {
          date: '2022-01-31',
          firstChart: '3',
          secondChart: '39'
        }, {
          date: '2022-02-01',
          firstChart: '40',
          secondChart: '15'
        }],
        net: [{
          date: '2022-01-28',
          firstChart: '100',
          secondChart: '20'
        }, {
          date: '2022-01-29',
          firstChart: '5',
          secondChart: '14'
        }, {
          date: '2022-01-30',
          firstChart: '35',
          secondChart: '12'
        }],
        transactions: [{
          date: '2022-01-28',
          firstChart: '0',
          secondChart: '1'
        }, {
          date: '2022-01-29',
          firstChart: '0',
          secondChart: '14'
        }, {
          date: '2022-01-30',
          firstChart: '19.9900000000000000',
          secondChart: '12'
        }, {
          date: '2022-01-31',
          firstChart: '80.9300000000000000',
          secondChart: '39'
        }, {
          date: '2022-02-01',
          firstChart: '0',
          secondChart: '15'
        }, {
          date: '2022-02-02',
          firstChart: '49.9500000000000000',
          secondChart: '24'
        }, {
          date: '2022-02-03',
          firstChart: '0',
          secondChart: '10'
        }],
        interval: 'currentWeek'
      };
    });
  }
});