define("lh4/templates/components/ui/header-segment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CRTcozQY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"headerText\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"span\",true],[8],[1,[22,\"headerText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/header-segment.hbs"
    }
  });

  _exports.default = _default;
});