define("lh4/routes/authenticated/internet-payments/customers/details/transaction/refund-transaction", ["exports", "lh4/config/environment", "lh4/routes/authenticated/internet-payments/customers/details/create-transaction"], function (_exports, _environment, _createTransaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _createTransaction.default.extend({
    requiredPermissions: ['refund transactions in transaction'],
    model: function model(_, transition) {
      var _this$store$peekRecor = this.store.peekRecord('internet-payments/transaction', transition.to.parent.params.transactionId),
          invoice = _this$store$peekRecor.invoice,
          cardToken = _this$store$peekRecor.cardToken,
          total = _this$store$peekRecor.amount;

      var qs = new URLSearchParams({
        token: this.tokenData.data.token,
        locationId: this.location.model.id,
        type: 'refund',
        invoice: invoice,
        cardToken: cardToken,
        tipAmount: 0,
        total: total
      });
      return Ember.RSVP.hash({
        origin: this.modelFor('authenticated.internet-payments.customers').origin,
        url: "".concat(_environment.default.host, "/api/v1/virtual-terminal/url?").concat(qs.toString())
      });
    }
  });

  _exports.default = _default;
});