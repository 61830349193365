define("lh4/routes/authenticated/bank-account/security", ["exports", "ember-changeset", "ember-changeset-cp-validations", "lh4/mixins/changeset-transition-route-mixin"], function (_exports, _emberChangeset, _emberChangesetCpValidations, _changesetTransitionRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_changesetTransitionRouteMixin.default, {
    location: Ember.inject.service(),
    model: function model() {
      var locationId = this.location.model.id;
      return Ember.RSVP.hash({
        security: this.store.peekRecord('verify-owner/security', locationId)
      });
    },
    afterModel: function afterModel(model) {
      if (!model.security) {
        this.transitionTo('authenticated.bank-account.verify-owner');
      }
    },
    deactivate: function deactivate() {
      // When the user leaves this page we'll clear existing models
      // This allows us to go through the process again without existing
      // stored values/state. If the owner token was set the verify-owner
      // page will immediately redirect to the bank-account/edit page and
      // these items won't be needed until that token expires.
      this.store.unloadAll('verify-owner/owner');
      this.store.unloadAll('verify-owner/security');
      this.store.unloadAll('verify-owner/question');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var changeset = new _emberChangeset.default(model.security);
      changeset.questionChangesets = model.security.questions.map(this.buildChildChangeset);
      controller.set('changeset', changeset);
    },
    buildChildChangeset: function buildChildChangeset(model) {
      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(model),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      return new _emberChangeset.default(model, validateFn, validationMap, {
        skipValidate: true
      });
    }
  });

  _exports.default = _default;
});