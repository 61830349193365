define("lh4/enums/drill-down-report-error-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DRILL_DOWN_REPORT_ERROR_CODES = void 0;
  var DRILL_DOWN_REPORT_ERROR_CODES = Object.freeze({
    LOCATION_NOT_FOUND: 'LOCATION_NOT_FOUND',
    TIMEZONE_NOT_FOUND: 'TIMEZONE_NOT_FOUND',
    NODE_NOT_FOUND: 'NODE_NOT_FOUND'
  });
  _exports.DRILL_DOWN_REPORT_ERROR_CODES = DRILL_DOWN_REPORT_ERROR_CODES;
  var _default = DRILL_DOWN_REPORT_ERROR_CODES;
  _exports.default = _default;
});