define("lh4/templates/components/ui/modal/fullscreen-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X879nfUf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"internet-payments/spinners/loader-circle\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"top-title header \",[28,\"if\",[[24,[\"errorMessage\"]],\"error\"],null],[28,\"if\",[[24,[\"isWhiteHeader\"]],\"white\"],null]]]],[8],[0,\"\\n\\t\\t\"],[7,\"h2\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\\t\"],[7,\"i\",false],[12,\"class\",[29,[\"big times icon close-button\",[28,\"if\",[[24,[\"isBlackCloseButton\"]],\"black\"],null]]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"close\"]],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted red segment error-message\"],[10,\"data-test-message\",\"error\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui icon exclamation triangle\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[1,[22,\"errorMessage\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"successMessage\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui inverted green segment success-message\"],[10,\"data-test-message\",\"success\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[10,\"class\",\"ui check icon\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[1,[22,\"successMessage\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[14,1,[[28,\"hash\",null,[[\"content\",\"actions\"],[[28,\"component\",[\"ui/shared/yield-basic\"],[[\"defaultClassNames\"],[\"content\"]]],[28,\"component\",[\"ui/modal/fullscreen-modal/fullscreen-modal-actions\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/modal/fullscreen-modal.hbs"
    }
  });

  _exports.default = _default;
});