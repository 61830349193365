define("lh4/models/loyalty/member", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "ember-data/relationships"], function (_exports, _model, _attr, _emberCpValidations, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    phoneNumber: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('phone-number', {
      message: 'Phone number is incorrect or empty'
    })],
    loyaltyProgramGuid: {
      description: 'Loyalty Program',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true
      })]
    },
    emailAddress: (0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'Email address is incorrect',
      allowBlank: true
    })
  });
  var TRANSACTION_STATISTICS = {
    pointsBalance: (0, _attr.default)('number'),
    accumulateTotalPoints: (0, _attr.default)('number'),
    redeemTotalPoints: (0, _attr.default)('number'),
    visitCount: (0, _attr.default)('number')
  };

  var _default = _model.default.extend(Validations, TRANSACTION_STATISTICS, {
    guid: (0, _attr.default)('string'),
    loyaltyProgramGuid: (0, _attr.default)('string'),
    loyaltyProgram: (0, _relationships.belongsTo)('loyalty/program'),
    loyaltyProgramName: (0, _attr.default)('string'),
    phoneNumber: (0, _attr.default)('phone'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    emailAddress: (0, _attr.default)('string'),
    addressLine1: (0, _attr.default)('string'),
    addressLine2: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    postalCode: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    transactions: (0, _relationships.hasMany)('loyalty/transaction')
  });

  _exports.default = _default;
});