define("lh4/components/supply-orders/select-supply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    supplyCart: Ember.inject.service(),
    classNames: ['supply-orders'],
    supplies: Ember.computed('supplyCart', function getter() {
      return this.get('supplyCart.supplies');
    }),
    actions: {
      selectSupply: function selectSupply(item) {
        this.supplyCart.add(item);
        this.sendAction('selectedSupply');
      }
    }
  });

  _exports.default = _default;
});