define("lh4/routes/authenticated/add-on-devices/customer-display", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "ember-onbeforeunload/mixins/confirmation"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin, _confirmation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _confirmation.default, {
    requiredPermissions: 'manage customer display for add-on-devices',
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return this.store.findRecord('customer-display/settings', this.location.model.id).then(function (settings) {
        return settings;
      }).catch(function (e) {
        if (e.errors.firstObject.message === 'missing setting') {
          return _this.ajax.request("/api/v1/customer-display/settings/".concat(_this.location.model.id, "/defaults"), {
            method: 'POST'
          }).then(function (response) {
            return _this.store.push({
              data: {
                id: _this.location.model.id,
                type: 'customer-display/settings',
                attributes: {
                  restaurantName: response.settings.restaurantName,
                  idleScreenLogoUrl: response.settings.idleScreenLogoUrl,
                  orderScreenLogoUrl: response.settings.orderScreenLogoUrl
                }
              }
            });
          });
        }

        _this.errorHandler.handleResponseError(e);

        return {};
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting && this.isPageDirty(controller.model)) {
        controller.model.rollback();
      }
    }
  });

  _exports.default = _default;
});