define("lh4/models/loyalty/promotion", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "lh4/utils/boolean-property-array", "moment"], function (_exports, _model, _attr, _emberCpValidations, _booleanPropertyArray, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PROMOTION_TYPES = void 0;

  var _Ember;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var PROMOTION_TYPES = {
    SIGN_UP: 'SIGN_UP',
    MULTIPLIER: 'MULTIPLIER'
  };
  _exports.PROMOTION_TYPES = PROMOTION_TYPES;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Promotion Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    type: {
      description: 'Promotion Type',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    },
    loyaltyProgramGuid: {
      description: 'Loyalty Program',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    },
    value: {
      description: 'Value Amount',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('number', {
        positive: true,
        integer: true,
        allowBlank: false,
        allowString: true,
        gte: 1,
        lte: 1000
      })]
    },
    startDate: {
      description: 'Start Date',
      validators: [(0, _emberCpValidations.validator)('date', {
        onOrBefore: Ember.computed.alias('model.endDate'),
        disabled: Ember.computed.alias('model.isAllowedAnyDate')
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.isAllowedAnyDate')
      })]
    },
    endDate: {
      description: 'End Date',
      validators: [(0, _emberCpValidations.validator)('date', {
        onOrAfter: Ember.computed.alias('model.startDate'),
        disabled: Ember.computed.alias('model.isAllowedAnyDate')
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.isAllowedAnyDate')
      })]
    },
    startTime: {
      description: 'Start Time',
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(value, options, model) {
          if (model.get('isAllowedAnyTime')) {
            return true;
          }

          var startTime = (0, _moment.default)(value, 'h:mma');
          var endTime = (0, _moment.default)(model.get('endTime'), 'h:mma');

          if (startTime.isAfter(endTime)) {
            return 'Start Time must be before End Time';
          }

          return true;
        }
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.isAllowedAnyTime')
      })]
    },
    endTime: {
      description: 'End Time',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.isAllowedAnyTime')
      })]
    }
  });
  var WEEKDAY_ATTRIBUTES = {
    isAllowedSunday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedMonday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedTuesday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedWednesday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedThursday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedFriday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedSaturday: (0, _attr.default)('boolean', {
      defaultValue: true
    })
  };
  var MULTIPLIER_PROMOTION_ATTRIBUTES = {
    isAllowedAnyDate: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedAnyTime: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    startDate: (0, _attr.default)('string'),
    endDate: (0, _attr.default)('string'),
    startTime: (0, _attr.default)('time'),
    endTime: (0, _attr.default)('time'),
    weekdays: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(WEEKDAY_ATTRIBUTES)).concat([(0, _booleanPropertyArray.default)(Object.keys(WEEKDAY_ATTRIBUTES))])),
    isRepeatedYearly: (0, _attr.default)('boolean')
  };

  var _default = _model.default.extend(Validations, WEEKDAY_ATTRIBUTES, MULTIPLIER_PROMOTION_ATTRIBUTES, {
    guid: (0, _attr.default)('string'),
    loyaltyProgramGuid: (0, _attr.default)('string'),
    loyaltyProgramName: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    value: (0, _attr.default)('number'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    })
  });

  _exports.default = _default;
});