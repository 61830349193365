define("lh4/templates/components/ui/icons/login/valid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NMyYQbUU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"10\"],[10,\"height\",\"10\"],[10,\"viewBox\",\"0 0 10 10\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"g\",true],[10,\"id\",\"Misc/check\"],[8],[0,\"\\n\\t\\t\"],[7,\"path\",true],[10,\"id\",\"Vector\"],[10,\"d\",\"M3.39644 8.58166L0.14644 5.33166C-0.0488135 5.1364 -0.0488135 4.81982 0.14644 4.62455L0.85353 3.91744C1.04878 3.72216 1.36539 3.72216 1.56064 3.91744L3.75 6.10677L8.43935 1.41744C8.6346 1.22218 8.95121 1.22218 9.14646 1.41744L9.85355 2.12455C10.0488 2.3198 10.0488 2.63638 9.85355 2.83166L4.10355 8.58168C3.90828 8.77693 3.59169 8.77693 3.39644 8.58166Z\"],[10,\"fill\",\"#48AA2F\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/login/valid.hbs"
    }
  });

  _exports.default = _default;
});