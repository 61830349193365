define("lh4/components/ui/form-fields/image-upload-field", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    /**
     * @param {string} [buttonText] - button label displayed in the UI
     */
    buttonText: 'Upload Image',

    /**
     * @param {string} [buttonClasses] - CSS classes for the input button
     */
    buttonClasses: 'ui primary basic button',

    /**
     * @param {boolean} isPreviewVisible - determines if image should be previewed after selecting it
     */
    isPreviewVisible: false,

    /**
     * @param {function} uploadImage - callback
     */
    uploadImage: function uploadImage() {},

    /**
     * @param {function} imageRemoved - callback
     */
    removeImage: function removeImage() {},

    /**
     * @param {string} [imageId] - used to reference & remove the image
     */
    imageId: null,

    /**
     * @param {string} [imageUrl] - used to display image in the field
     */
    imageUrl: null,

    /**
     * @param {Date} cacheBust - used to prevent image from being cached by a browser
     */
    _cacheBust: Date.now(),

    /**
     * @param {object} cropperOptions - cropper options
     */
    cropperOptions: null,
    imageUrlHasProtocol: Ember.computed('imageUrl', function getter() {
      if (this.get('imageUrl') && (this.get('imageUrl').indexOf('https://') === 0 || this.get('imageUrl').indexOf('http://') === 0)) {
        return true;
      }

      return false;
    }),
    imageUrlHContainsFull: Ember.computed('imageUrl', function getter() {
      if (this.get('imageUrl') && this.get('imageUrl').indexOf(':full') > 0) {
        return true;
      }

      return false;
    }),
    dataTest: 'form-field-image-upload-field',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('_cacheBust', Date.now());
    },
    setPreview: function setPreview(_ref) {
      var fullBlob = _ref.fullBlob;
      var urlCreator = window.URL || window.webkitURL;
      var previewUrl = urlCreator.createObjectURL(fullBlob);
      this.set('previewUrl', previewUrl);
    },
    actions: {
      uploadImage: function uploadImage(_ref2) {
        var fullBlob = _ref2.fullBlob,
            thumbBlob = _ref2.thumbBlob;
        this.setPreview({
          fullBlob: fullBlob
        });
        this.uploadImage({
          fullBlob: fullBlob,
          thumbBlob: thumbBlob
        });
      },
      removeImage: function removeImage() {
        // Even though image may not have id and/or url, we still want to notify parent element
        // that it's being removed from the preview
        this.removeImage(this.imageId, this.imageUrl);
        this.set('previewUrl', null);
      },
      togglePreview: function togglePreview(visible) {
        this.set('isPreviewVisible', visible);
      }
    }
  });

  _exports.default = _default;
});