define("lh4/routes/authenticated/locations/location/hospitality/customize-item-groups", ["exports", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin"], function (_exports, _lighthousePermissionsMixin, _authenticatedRouteMixin, _mobileScrollRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    titleToken: 'Customize Items',
    requiredPermissions: 'manage hospitality/item-group',
    model: function model() {
      var _this = this;

      var location = this.modelFor('authenticated.locations.location');
      return Ember.RSVP.hash({
        location: location,
        itemGroups: this.store.query('hospitality/item-group', {
          location: location.id
        }).then(function () {
          return _this.store.peekAll('hospitality/item-group');
        })
      });
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('hospitality/item-group');
    }
  });

  _exports.default = _default;
});