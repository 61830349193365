define("lh4/templates/authenticated/locations/location/echo-pro/reservations/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "opoGj5dM",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segments reservation-body\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"flash-message\",null,[[\"flash\",\"class\"],[[23,1,[]],[23,1,[\"class\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"segment\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide computer sixteen wide mobile column\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"reservations/reservation-turnaround\",[],[[\"@turnaroundSettings\"],[[22,\"turnaroundSettings\"]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide computer sixteen wide mobile column\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"reservations/reservation-link\",null,[[\"channel\"],[[24,[\"model\",\"channel\"]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"reservations/reservation-notifications\",null,[[\"notificationSettings\"],[[24,[\"notificationSettings\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"align\",\"right\"],[10,\"class\",\"reservation-header\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"discardChanges\"]],[8],[0,\"Discard\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui submit primary button \",[28,\"if\",[[24,[\"saving\"]],\"loading\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"saveChanges\"]],[8],[0,\"Save\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/locations/location/echo-pro/reservations/settings.hbs"
    }
  });

  _exports.default = _default;
});