define("lh4/components/ui/checklist/item-checklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNames: ['item-checklist'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.registerResetSearchAction) {
        this.registerResetSearchAction(this.actions.resetSearch.bind(this));
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
    },

    /**
     * @param {Boolean} includeInactiveStyle - decides whether to render items where isActive == false with
     * a different style. NOTE: could be removed someday if the inactive style is used in all pages of the application
     */
    includeInactiveStyle: false,
    // Empty string as in general-search component to prevent recalculation of itemsArray after search input onBlur.
    // This prevents bug with race conditions when first click on item checkbox was not working
    // @see https://jira.shift4payments.com/browse/LH-7802
    searchTerm: '',
    showSelectAll: true,
    items: [],
    selected: [],
    // mapping searchTerm to speed up search performance
    searchableItems: Ember.computed.map('items', function (_ref) {
      var id = _ref.id,
          isActive = _ref.isActive,
          description = _ref.description,
          name = _ref.name,
          isCheckboxDisabled = _ref.isCheckboxDisabled;
      return {
        id: id,
        isActive: isActive,
        isCheckboxDisabled: isCheckboxDisabled,
        description: description,
        name: name,
        searchTerm: name ? name.toUpperCase() : null
      };
    }),
    filteredItems: Ember.computed('searchableItems', 'searchTerm', function getter() {
      if (!this.searchTerm) {
        return this.searchableItems;
      }

      var upperTerm = this.searchTerm.toUpperCase();
      return this.searchableItems.filter(function (_ref2) {
        var searchTerm = _ref2.searchTerm;
        return searchTerm.indexOf(upperTerm) !== -1;
      });
    }),
    itemsMap: Ember.computed('filteredItems', function getter() {
      // creating map by id to speed up item selection
      return this.filteredItems.reduce(function (acc, _ref3) {
        var id = _ref3.id,
            isActive = _ref3.isActive,
            name = _ref3.name,
            description = _ref3.description,
            isCheckboxDisabled = _ref3.isCheckboxDisabled;
        return acc.set(id, {
          id: id,
          isActive: isActive,
          name: name,
          description: description,
          isCheckboxDisabled: isCheckboxDisabled
        });
      }, new Map());
    }),
    itemsArray: Ember.computed('itemsMap', 'selected.[]', function getter() {
      var clone = new Map(this.itemsMap); // iterating over selected items only as this increases performance dramatically

      this.selected.reduce(function (acc, cur) {
        if (!acc.has(cur)) {
          return acc;
        } // cloning as setting value expects setter to exist


        return acc.set(cur, _objectSpread(_objectSpread({}, acc.get(cur)), {}, {
          isChecked: true
        }));
      }, clone); // map iterator to array for support of length checking in "all" checkbox

      return _toConsumableArray(clone.values());
    }),
    actions: {
      selectItem: function selectItem(id, isChecked) {
        if (isChecked) {
          this.selected.pushObject(id);
        } else {
          this.selected.removeObject(id);
        }
      },
      selectAllItems: function selectAllItems() {
        if (this.selected.length === this.searchableItems.length) {
          this.selected.clear();
        } else {
          this.selected.setObjects(this.searchableItems.map(function (_ref4) {
            var id = _ref4.id;
            return id;
          }));
        }
      },
      resetSearch: function resetSearch() {
        Ember.set(this, 'searchTerm', '');
      }
    }
  });

  _exports.default = _default;
});