define("lh4/components/reports/echo/discount-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    items: null,
    actions: {
      deployModal: function deployModal(ticket) {
        this.set('items', ticket.items);
        this.$('.ui.modal.discount-details').modal('show');
      }
    }
  });

  _exports.default = _default;
});