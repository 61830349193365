define("lh4/templates/components/internet-payments/spinners/loader-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EODn2QVu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"loader-container\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"loader-text\"],[8],[0,\"\\n\\t\\t\"],[5,\"ui/icons/internet-payments/four-icon\",[],[[],[]]],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"main-container\"],[8],[0,\"\\n\\t\\t\"],[5,\"ui/icons/internet-payments/spinner\",[],[[],[]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/spinners/loader-circle.hbs"
    }
  });

  _exports.default = _default;
});