define("lh4/components/dashboard-widgets/skytab-ratings/data", ["exports", "lh4/enums/date-range-title", "lh4/mixins/dashboard-widget-mixin", "moment", "lh4/formats"], function (_exports, _dateRangeTitle, _dashboardWidgetMixin, _moment, _formats) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    locale: Ember.inject.service(),
    classNames: ['skytab-ratings-data'],
    classNameBindings: ['widgetRootClass'],
    skeletonData: {
      total: 840
    },
    widgetRootClass: Ember.computed('settings.view', function getter() {
      return this.get('settings.view') === 'simple' ? 'percent-widget' : 'table-widget';
    }),
    chartData: Ember.computed('data', function getter() {
      return this.data.periods.map(function (_ref) {
        var date = _ref.date,
            value = _ref.avgRating;
        return {
          date: (0, _moment.default)(date).format(_moment.default.HTML5_FMT.DATE),
          value: value
        };
      });
    }),
    isPercentChangePositive: Ember.computed('data', function getter() {
      return this.data.percentChange > 0;
    }),
    periodLabel: Ember.computed('settings.period', function getter() {
      switch (this.settings.period) {
        case 'month':
          return _dateRangeTitle.DATE_RANGE_TITLE.MONTH.LAST;

        case 'week':
          return _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST;

        case 'last-day':
          return _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST;

        default:
          return _dateRangeTitle.DATE_RANGE_TITLE.YESTERDAY;
      }
    }),
    onChartRender: function onChartRender(event) {
      var periods = this.data.periods;
      var period = this.settings.period;
      var start;

      switch (period) {
        case 'week':
          start = periods[periods.length - 8];
          break;

        case 'month':
          start = periods.firstObject;
          break;

        default:
          start = periods[periods.length - 2];
          break;
      }

      var end = periods.lastObject;
      this.set('chart', event.chart);
      var startDate = (0, _moment.default)(start.date).toDate();
      var endDate = (0, _moment.default)(end.date).toDate();
      event.chart.zoomToDates(startDate, endDate);
    },
    chartOptions: Ember.computed('data', function getter() {
      var _this = this;

      return {
        balloonText: '[[value]]',
        categoryField: 'date',
        chartScrollbar: {
          autoGridCount: true,
          backgroundColor: '#F2F2F2',
          color: '#666',
          dragIcon: 'dragIconRectBig',
          dragIconHeight: 38,
          dragIconWidth: 38,
          enabled: true,
          graphFillAlpha: 0,
          graphLineAlpha: 1,
          graphLineColor: '#009BF4',
          graphType: 'line',
          gridAlpha: 1,
          gridCount: 6,
          maximum: 5,
          minimum: 0,
          offset: 14,
          scrollbarHeight: 30,
          scrollDuration: 30,
          selectedBackgroundColor: '#CCEBFD',
          selectedGraphFillAlpha: 0,
          selectedGraphLineAlpha: 1,
          selectedGraphLineColor: '#009BF4',
          tabIndex: 4,
          updateOnReleaseOnly: true
        },
        dataDateFormat: _moment.default.HTML5_FMT.DATE,
        listeners: [{
          event: 'rendered',
          method: this.onChartRender.bind(this)
        }],
        parseDates: true,
        valueAxesOptions: {
          unitPosition: 'left'
        },
        valueField: 'value',
        chartCursor: {
          categoryBalloonFunction: function categoryBalloonFunction(value) {
            return _this.locale.formatDateForAmChart(value, _this.useIntlDates, _formats.default.date.MEDIUM, 'SALES-CURSOR');
          }
        },
        categoryAxis: {
          labelFunction: function labelFunction(value, data) {
            return _this.locale.formatDateForAmChart(value, _this.useIntlDates, _formats.default.date.MEDIUM_NO_YEAR, data, 'SALES-AXIS');
          }
        }
      };
    })
  });

  _exports.default = _default;
});