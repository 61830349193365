define("lh4/components/loyalty/loyalty-promotion-form", ["exports", "lh4/models/loyalty/promotion"], function (_exports, _promotion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    types: [{
      name: 'Sign Up',
      value: _promotion.PROMOTION_TYPES.SIGN_UP
    }, {
      name: 'Multiplier',
      value: _promotion.PROMOTION_TYPES.MULTIPLIER
    }],
    loyaltyPrograms: Ember.computed.map('programs', function (_ref) {
      var name = _ref.name,
          value = _ref.guid,
          isActive = _ref.isActive;
      return {
        name: name,
        value: value,
        isActive: isActive
      };
    }),
    weekdayOptions: [{
      id: 'isAllowedMonday',
      name: 'Monday'
    }, {
      id: 'isAllowedTuesday',
      name: 'Tuesday'
    }, {
      id: 'isAllowedWednesday',
      name: 'Wednesday'
    }, {
      id: 'isAllowedThursday',
      name: 'Thursday'
    }, {
      id: 'isAllowedFriday',
      name: 'Friday'
    }, {
      id: 'isAllowedSaturday',
      name: 'Saturday'
    }, {
      id: 'isAllowedSunday',
      name: 'Sunday'
    }],
    isAllDaysSelected: Ember.computed('promotion.weekdays.[]', function getter() {
      return this.promotion.get('weekdays').length === 7;
    }),
    isPromotionTypeMultiplier: Ember.computed('promotion.type', function getter() {
      return this.get('promotion.type') === _promotion.PROMOTION_TYPES.MULTIPLIER;
    }),
    actions: {
      toggleAllDays: function toggleAllDays(value) {
        var options = this.weekdayOptions;
        var weekdays = value ? options.map(function (_ref2) {
          var id = _ref2.id;
          return id;
        }) : [];
        this.promotion.set('weekdays', weekdays);
      }
    }
  });

  _exports.default = _default;
});