define("lh4/mirage/routes/customer-engagement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageCustomerEngagementServer;

  function setMirageCustomerEngagementServer(server) {
    // Customer Engagement
    server.get('customer-engagement/settings/:locationId', function (schema, request) {
      return {
        settings: schema.customerEngagementSettings.where({
          locationId: request.params.locationId
        }).models
      };
    });
  }
});