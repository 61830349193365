define("lh4/routes/authenticated/add-on-devices/tableside", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'manage tableside for add-on-devices',
    editRecord: Ember.inject.service('edit-record'),
    location: Ember.inject.service(),
    model: function model() {
      var _this = this;

      this.store.unloadAll('echo-pro/terminal');
      var location = this.location.model;
      return Ember.RSVP.hash({
        isLoading: false,
        location: location,
        terminals: this.store.query('echo-pro/terminal', {
          location: location.id,
          isMobile: true,
          isActive: true,
          order: 'createdAt',
          sort: 'desc'
        }).then(function () {
          return _this.store.peekAll('echo-pro/terminal');
        }),
        printers: this.store.query('echo-pro/printer', {
          location: location.id
        })
      });
    }
  });

  _exports.default = _default;
});