define("lh4/templates/components/internet-payments/developers/webhooks/details/webhook-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qnljdwyp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"webhook-cards\"],[8],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@dataTestId\"],[\"Status\",\"webhook-card-status\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"internet-payments/color-dot-label\",[],[[\"@label\",\"@value\"],[[28,\"if\",[[23,0,[\"args\",\"webhook\",\"isActive\"]],\"Active\",\"Not Active\"],null],[28,\"if\",[[23,0,[\"args\",\"webhook\",\"isActive\"]],\"green\",\"gray\"],null]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@dataTestId\"],[\"Listening for\",\"webhook-card-listening-for\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"internet-payments/ui/hover-list\",[],[[\"@title\",\"@list\"],[[28,\"concat\",[[23,0,[\"args\",\"webhook\",\"eventTypes\",\"length\"]],\" \",[28,\"if\",[[28,\"eq\",[[23,0,[\"args\",\"webhook\",\"eventTypes\",\"length\"]],1],null],\"Event\",\"Events\"],null]],null],[23,0,[\"args\",\"webhook\",\"eventTypes\"]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[5,\"internet-payments/info-tile\",[],[[\"@label\",\"@dataTestId\"],[\"Signing Secret\",\"webhook-card-signing-secret\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"internet-payments/ui/value-revealer\",[],[[\"@value\"],[[23,0,[\"args\",\"webhook\",\"signingSecret\"]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/developers/webhooks/details/webhook-cards.hbs"
    }
  });

  _exports.default = _default;
});