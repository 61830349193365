define("lh4/templates/authenticated/launch-control/discounts/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Eh2LiSe",
    "block": "{\"symbols\":[],\"statements\":[[5,\"ui/launch-control/fullscreen-modal\",[],[[\"@title\",\"@isWhiteHeader\",\"@isBlackCloseHeader\",\"@isBlackCloseButton\",\"@isLaunchControl\",\"@isLoading\"],[\"title\",true,true,true,true,true]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/launch-control/discounts/loading.hbs"
    }
  });

  _exports.default = _default;
});