define("lh4/templates/components/reports/echo-pro/weekly-financial-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fB/GCBTw",
    "block": "{\"symbols\":[\"table\"],\"statements\":[[4,\"reports/table-wrapper\",null,[[\"generatedReport\",\"currentReport\",\"onToggleFavorite\",\"reportType\",\"generatedReportType\",\"enabledProd\",\"selectedLocations\",\"exportTypes\",\"startsAt\",\"endsAt\",\"intradayPeriodGroupGuids\",\"revenueCenterGuids\",\"weeklyDateFormat\",\"weekOfSelectedDay\"],[[24,[\"generatedReport\"]],[24,[\"currentReport\"]],[24,[\"onToggleFavorite\"]],[24,[\"reportType\"]],[24,[\"generatedReportType\"]],[24,[\"enabledProd\"]],[24,[\"selectedLocations\"]],[24,[\"exportTypes\"]],[24,[\"startsAt\"]],[24,[\"endsAt\"]],[24,[\"intradayPeriodGroupGuids\"]],[24,[\"revenueCenterGuids\"]],true,[24,[\"weekOfSelectedDay\"]]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"bar-tables ui grid\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"currentReport\",\"reports\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"eight wide computer sixteen wide tablet sixteen wide mobile column\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"tables/show-more-table\",null,[[\"data\",\"show\",\"firstRowHeader\"],[[23,1,[]],\"5\",true]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reports/echo-pro/weekly-financial-overview.hbs"
    }
  });

  _exports.default = _default;
});