define("lh4/enums/ip-risk-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RISK_STATUSES = Object.freeze({
    APPROVED: 'approved',
    REVIEW: 'review',
    VERIFIED: 'verified',
    DECLINED: 'declined',
    ESCALATION: 'escalation'
  });
  var _default = RISK_STATUSES;
  _exports.default = _default;
});