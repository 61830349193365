define("lh4/mirage/routes/documents", ["exports", "lh4/mirage/fixtures/documents"], function (_exports, _documents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(server) {
    server.get('/documents', function () {
      return {
        DOCUMENTS_FIXTURE: _documents.default
      };
    });
    server.get('/documents/:id', function () {
      return {
        documents: {}
      };
    });
  };

  _exports.default = _default;
});