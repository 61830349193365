define("lh4/routes/authenticated/epro/menu/pricing-table/bulk-edit", ["exports", "ember-changeset", "ember-changeset-cp-validations", "lh4/routes/general-edit-route"], function (_exports, _emberChangeset, _emberChangesetCpValidations, _generalEditRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _generalEditRoute.default.extend({
    location: Ember.inject.service(),
    modelName: 'priceChange',
    beforeModel: function beforeModel() {
      if (!this.location.model.isCorpHq) {
        this.transitionTo('authenticated.dashboard2');
      }

      if (this.controllerFor('authenticated.epro.menu.pricing-table').get('selectionCount') < 2) {
        this.transitionTo('authenticated.epro.menu.pricing-table');
      }
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
    },
    // overriding hasDirtyChangesets function set by ChangesetTransitionRouteMixin
    // for custom isDirty check before closing modal
    // because we have unusual situation with two separate changesets (not child changeset)
    hasDirtyChangesets: function hasDirtyChangesets() {
      var changeset = this.controller.changeset;

      if ( // checking if "main" changeset is dirty. Basically it holds values for allowing active toggle overrides
      // and allow price editing override
      changeset.isDirty) {
        return true;
      }

      return false;
    },
    model: function model() {
      return Ember.RSVP.hash({
        priceChange: this.store.createRecord('echo-pro/menu/pricing-table-bulk'),
        corporateGroupOptions: this.store.query('corporate-group', {
          corporation: this.location.model.corpId
        }).then(function (data) {
          return [// adding default option
          {
            value: 0,
            name: 'Default Price'
          }].concat(_toConsumableArray(data.map(function (item) {
            return {
              value: +item.id,
              name: item.name
            };
          })));
        })
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var priceChange = model.priceChange;

      var _buildChangeset = (0, _emberChangesetCpValidations.buildChangeset)(priceChange),
          validateFn = _buildChangeset.validateFn,
          validationMap = _buildChangeset.validationMap;

      var changeset = new _emberChangeset.default(priceChange, validateFn, validationMap, {
        skipValidate: true
      });
      controller.setProperties({
        changeset: changeset,
        // overriding some controller values set by ChangesetTransitionRouteMixin
        // for custom isDirty check before closing modal
        showConfirmation: false,
        showErrors: false,
        onBeforeClose: function onBeforeClose() {
          if (!_this.hasDirtyChangesets()) {
            return true;
          }

          if (window.confirm(_this.confirmMessage)) {
            _this.rollbackChanges();

            return true;
          }

          return false;
        }
      });
    }
  });

  _exports.default = _default;
});