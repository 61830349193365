define("lh4/components/hospitality/manage-employees/employee/info", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      dateSelect: function dateSelect(prop, date) {
        this.set(prop, _moment.default.utc([date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()]).toISOString());
      }
    }
  });

  _exports.default = _default;
});