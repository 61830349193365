define("lh4/mirage/fixtures/drill-down-report-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DRILL_DOWN_REPORT_LIST_FIXTURE = {
    'echo-pro': [{
      name: 'Hourly Sales & Labor',
      slug: 'hourly-labor',
      product: 'echo-pro',
      exports: {
        csv: true,
        pdf: true,
        xls: true
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: true
        },
        end: {
          type: 'date',
          date: true,
          time: true
        },
        locations: {
          type: 'array'
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          maxDurationIso8601: 'PT24H',
          isMaxDurationLimitingStart: true,
          singleDate: false,
          isDateTimeBusinessDay: true,
          rangesEnabled: ['BUSINESS_DAY.CURRENT', 'BUSINESS_DAY.LAST']
        }
      },
      subscribable: true,
      custom: false,
      isHidden: false,
      menchiesOnly: false
    }],
    'gift-card': [{
      subscribable: false,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      product: 'gift-card',
      slug: 'transaction-summary',
      name: 'Transaction Summary',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: false
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: false
        },
        end: {
          type: 'date',
          date: true,
          time: false
        },
        datePickerOptions: {
          maxDurationIso8601: 'P90D',
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: false,
          singleDate: false,
          isDateTimeBusinessDay: false,
          rangesEnabled: ['WEEK.TO_DATE', 'WEEK.LAST', 'MONTH.TO_DATE', 'MONTH.LAST']
        }
      }
    }, {
      subscribable: false,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      product: 'gift-card',
      slug: 'transaction-detail',
      name: 'Transaction Detail',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: false
      },
      params: {
        start: {
          type: 'date',
          date: true,
          time: false
        },
        end: {
          type: 'date',
          date: true,
          time: false
        },
        datePickerOptions: {
          maxDurationIso8601: 'P90D',
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: false,
          singleDate: false,
          isDateTimeBusinessDay: false,
          rangesEnabled: ['WEEK.TO_DATE', 'WEEK.LAST', 'MONTH.TO_DATE', 'MONTH.LAST']
        }
      }
    }, {
      subscribable: false,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      product: 'gift-card',
      slug: 'balances',
      name: 'Balances',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: false
      },
      params: {
        isSingleLocationReport: true,
        start: {
          type: 'date',
          date: true,
          time: false
        },
        end: {
          type: 'date',
          date: true,
          time: false
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: true,
          singleDate: true,
          isDateTimeBusinessDay: false,
          maxDurationIso8601: 'PT24H',
          rangesEnabled: []
        }
      }
    }, {
      subscribable: false,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      product: 'gift-card',
      slug: 'cross-use-summary',
      name: 'Cross Use Summary',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: false
      },
      params: {
        isSingleLocationReport: false,
        start: {
          type: 'date',
          date: true,
          time: false
        },
        end: {
          type: 'date',
          date: true,
          time: false
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: true,
          singleDate: true,
          isDateTimeBusinessDay: false,
          maxDurationIso8601: 'PT24H',
          rangesEnabled: []
        }
      }
    }, {
      subscribable: false,
      custom: false,
      isHidden: false,
      menchiesOnly: false,
      product: 'gift-card',
      slug: 'cross-use-detail',
      name: 'Cross Use Detail',
      exports: {
        csv: true,
        pdf: true,
        xls: true,
        html: false
      },
      params: {
        isSingleLocationReport: false,
        start: {
          type: 'date',
          date: true,
          time: false
        },
        end: {
          type: 'date',
          date: true,
          time: false
        },
        datePickerOptions: {
          hasMinutesDisabled: true,
          isMaxDurationLimitingStart: true,
          singleDate: true,
          isDateTimeBusinessDay: false,
          maxDurationIso8601: 'PT24H',
          rangesEnabled: []
        }
      }
    }]
  };
  var _default = DRILL_DOWN_REPORT_LIST_FIXTURE;
  _exports.default = _default;
});