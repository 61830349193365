define("lh4/components/dashboard-widgets/social-media-exposure/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['social-media-exposure-data', 'table-widget'],
    classNameBindings: ['permissionError:show-signup'],
    skeletonData: {
      reached: 15000,
      interactions: 100,
      shares: 12
    }
  });

  _exports.default = _default;
});