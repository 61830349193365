define("lh4/templates/components/ui/icons/table-layout/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QzDzT044",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"currentColor\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M21 12L16.65 18.15C16.4667 18.4167 16.2292 18.625 15.9375 18.775C15.6458 18.925 15.3333 19 15 19H5C4.45 19 3.97917 18.8042 3.5875 18.4125C3.19583 18.0208 3 17.55 3 17V7C3 6.45 3.19583 5.97917 3.5875 5.5875C3.97917 5.19583 4.45 5 5 5H15C15.3333 5 15.6458 5.075 15.9375 5.225C16.2292 5.375 16.4667 5.58333 16.65 5.85L21 12ZM18.55 12L15 7H5V17H15L18.55 12ZM5 12V17V7V12Z\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/table-layout/label.hbs"
    }
  });

  _exports.default = _default;
});