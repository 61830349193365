define("lh4/models/hospitality/employee", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations"], function (_exports, _model, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Screen Name',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    login: {
      description: 'Login Pin',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        is: Ember.computed('model.locationId', function getter() {
          return parseInt(this.model.store.peekRecord('hospitality/preference', this.model.locationId).pinLength, 10);
        }),
        disabled: Ember.computed('model.login', function getter() {
          var _this$model$login;

          return (_this$model$login = this.model.login) === null || _this$model$login === void 0 ? void 0 : _this$model$login.match(/^\*+$/);
        })
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^(\*+|\d+)$/
      })]
    },
    email: (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'email',
      description: 'Email'
    }),
    phone1: (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'phone',
      description: 'Phone #1'
    }),
    phone2: (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'phone',
      description: 'Phone #2'
    }),
    emergencyPhone: (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      type: 'phone',
      description: 'Emergency Phone'
    })
  });

  var _default = _model.default.extend(Validations, {
    name: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    lastName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    middleName: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    login: (0, _attr.default)('string'),
    payrollId: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    bornAt: (0, _attr.default)('string', {
      defaultValue: null
    }),
    hiredAt: (0, _attr.default)('string', {
      defaultValue: null
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    email: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    state: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    city: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    liquorCardExpiresAt: (0, _attr.default)('string', {
      defaultValue: null
    }),
    healthCardExpiresAt: (0, _attr.default)('string', {
      defaultValue: null
    }),
    street: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    street2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    cardswipe: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    clockInAt: (0, _attr.default)('date', {
      defaultValue: null
    }),
    currentTimesheetId: (0, _attr.default)('number', {
      defaultValue: null
    }),
    emergencyPhone: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    employeeNumber: (0, _attr.default)('string', {
      defaultValue: 0
    }),
    expectedClockOutAt: (0, _attr.default)('date', {
      defaultValue: null
    }),
    isAdmin: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isClockedIn: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isLoggedIn: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    locationId: (0, _attr.default)('number'),
    lockedOutAt: (0, _attr.default)('date', {
      defaultValue: null
    }),
    ordinal: (0, _attr.default)('number', {
      defaultValue: null
    }),
    phone1: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    phone2: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    postal: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    reviewedAt: (0, _attr.default)('date', {
      defaultValue: null
    }),
    rowguid: (0, _attr.default)('string'),
    securityTemplateId: (0, _attr.default)('number', {
      defaultValue: -1
    }),
    ssn: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    employeeId: (0, _attr.default)('number'),
    employeesPrivileges: (0, _attr.default)(),
    employeesJobs: (0, _attr.default)(),
    terminatedAt: (0, _attr.default)('date', {
      defaultValue: null
    }),
    ticketListSchema: (0, _attr.default)('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});