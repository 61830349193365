define("lh4/components/tables/bulkselect-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var REVERSED_SORT_TYPES = ['active'];

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    classNames: ['bulkselect-table'],

    /**
     * @param {Array} - Array of objects of { name: {string}, field: {string}, width: {string} }.
     */
    headers: [],

    /**
     * @param {Array} - Array of any type to display.
     */
    rows: [],

    /**
     * @param {Array} - Array of all ids currently shown on screen
     */
    guids: [],

    /**
     * @param {Array} - Array of included or excluded fields
     */
    includeList: [],

    /**
     * @param {Integer} - Count of all items currently selected
     */
    selectionCount: 0,

    /**
     * @param {Integer} - The name of the data field to use in the includeList
     */
    selectionFieldName: 'id',

    /**
     * @param {Boolean} - Disabling selection makes component render like a normal table (without checkboxes)
     */
    selectionDisabled: false,
    onSelectionChanged: null,

    /**
     * @param {Boolean} - Excludes ember data rows where isNew === true
     */
    excludeNew: false,
    fixed: false,
    onFilterChange: null,
    onRowClick: null,
    limit: 50,
    maxPagesShown: 5,
    offset: 0,
    isScrollable: false,
    totalCount: 0,
    sortAsc: true,
    sortBy: 'product',
    intl: Ember.inject.service(),
    currentPage: Ember.computed('offset', 'limit', function getter() {
      // Offset / Limit + 1
      // Math.floor is likely unneeded, but it helps read that this should
      // be an integer.
      return Math.floor(this.offset / this.limit) + 1;
    }),
    totalPageCount: Ember.computed('totalCount', 'limit', function getter() {
      // numberOfRows divided by limit with a minimum of 1
      return Math.max(1, Math.ceil(this.totalCount / this.limit));
    }),
    desktopHeaderOptions: Ember.computed('headers', 'sortBy', 'sortAsc', function desktopHeaderOptions() {
      var _this = this;

      return this.headers.map(function (header) {
        if (!header.sortable) {
          return header;
        }

        var ascClass = REVERSED_SORT_TYPES.includes(header.type) ? 'down' : 'up';
        var descClass = REVERSED_SORT_TYPES.includes(header.type) ? 'up' : 'down';
        var isSortActive = _this.sortBy === header.field;
        var sortDir = 'up';

        if (isSortActive) {
          sortDir = _this.sortAsc ? ascClass : descClass;
        }

        return _objectSpread(_objectSpread({}, header), {}, {
          isSortActive: isSortActive,
          sortDir: sortDir
        });
      });
    }),
    mobileSortOptions: Ember.computed('headers', function mobileSortOptions() {
      var _this2 = this;

      var sortableHeaders = this.headers.filter(function (header) {
        return header.sortable;
      });
      var options = [];
      sortableHeaders.forEach(function (header) {
        switch (header.type) {
          case 'active':
            options.push({
              value: "".concat(header.field, "-desc"),
              label: _this2.intl.t('components:tables:bulkselect-table:mobile-sort-options:active-to-not-active')
            });
            options.push({
              value: "".concat(header.field, "-asc"),
              label: _this2.intl.t('components:tables:bulkselect-table:mobile-sort-options:not-active-to-active')
            });
            break;

          case 'date':
            options.push({
              value: "".concat(header.field, "-desc"),
              label: _this2.intl.t('components:tables:bulkselect-table:mobile-sort-options:new-to-old', {
                headerName: header.name
              })
            });
            options.push({
              value: "".concat(header.field, "-asc"),
              label: _this2.intl.t('components:tables:bulkselect-table:mobile-sort-options:old-to-new', {
                headerName: header.name
              })
            });
            break;

          case 'number':
            options.push({
              value: "".concat(header.field, "-desc"),
              label: _this2.intl.t('components:tables:bulkselect-table:mobile-sort-options:high-to-low', {
                headerName: header.name
              })
            });
            options.push({
              value: "".concat(header.field, "-asc"),
              label: _this2.intl.t('components:tables:bulkselect-table:mobile-sort-options:low-to-high', {
                headerName: header.name
              })
            });
            break;

          default:
            options.push({
              value: "".concat(header.field, "-asc"),
              label: _this2.intl.t('components:tables:bulkselect-table:mobile-sort-options:a-to-z', {
                headerName: header.name
              })
            });
            options.push({
              value: "".concat(header.field, "-desc"),
              label: _this2.intl.t('components:tables:bulkselect-table:mobile-sort-options:z-to-a', {
                headerName: header.name
              })
            });
            break;
        }
      });
      return options;
    }),
    selectedSortOption: Ember.computed('sortAsc', 'sortBy', function selectedSortOption() {
      return "".concat(this.sortBy, "-").concat(this.sortAsc ? 'asc' : 'desc');
    }),
    hasSortOptions: Ember.computed('mobileSortOptions', function hasSortOptions() {
      return this.mobileSortOptions.length > 0;
    }),
    rowCount: Ember.computed('rows', 'totalCount', function getter() {
      return this.totalCount ? this.totalCount : this.rows.length;
    }),
    rowOptions: Ember.computed('rows', 'includeList', function getter() {
      var _this3 = this;

      return (this.rows || []).map(function (row) {
        var _this3$includeList;

        var id = row[_this3.selectionFieldName];
        return {
          isSelected: (_this3$includeList = _this3.includeList) === null || _this3$includeList === void 0 ? void 0 : _this3$includeList.includes(id),
          data: row
        };
      });
    }),
    isNoneSelected: Ember.computed('includeList', function getter() {
      var _this$includeList,
          _this4 = this;

      return ((_this$includeList = this.includeList) === null || _this$includeList === void 0 ? void 0 : _this$includeList.filter(function (x) {
        var _this4$guids;

        return (_this4$guids = _this4.guids) === null || _this4$guids === void 0 ? void 0 : _this4$guids.includes(x);
      }).length) === 0;
    }),
    isAllSelected: Ember.computed('rowCount', 'includeList', function getter() {
      var _this$includeList2,
          _this5 = this;

      return ((_this$includeList2 = this.includeList) === null || _this$includeList2 === void 0 ? void 0 : _this$includeList2.filter(function (x) {
        var _this5$guids;

        return (_this5$guids = _this5.guids) === null || _this5$guids === void 0 ? void 0 : _this5$guids.includes(x);
      }).length) === this.rowCount;
    }),
    selectAllCheckboxClass: Ember.computed('isAllSelected', 'isNoneSelected', 'rows', function getter() {
      return !this.isAllSelected && !this.isNoneSelected ? 'partially-selected' : '';
    }),
    changeFilters: function changeFilters(changes) {
      if (!this.onFilterChange) {
        return;
      }

      var limit = this.limit,
          sortAsc = this.sortAsc,
          sortBy = this.sortBy;

      var filters = _objectSpread({
        limit: limit,
        offset: 0,
        sortAsc: sortAsc,
        sortBy: sortBy
      }, changes);

      this.onFilterChange(filters);
    },
    performSelectAll: function performSelectAll() {
      var _this$guids,
          _this6 = this;

      var guidsToAdd = (_this$guids = this.guids) === null || _this$guids === void 0 ? void 0 : _this$guids.filter(function (x) {
        var _this6$includeList;

        return ((_this6$includeList = _this6.includeList) === null || _this6$includeList === void 0 ? void 0 : _this6$includeList.indexOf(x)) === -1;
      }); // add all selections that are currently shown on screen

      this.setProperties({
        includeList: [].concat(_toConsumableArray(this.includeList), _toConsumableArray(guidsToAdd)),
        selectionCount: this.selectionCount + guidsToAdd.length
      });
    },
    performSelectNone: function performSelectNone() {
      var _this7 = this,
          _this$guids2;

      // remove all selections that are currently shown on screen
      this.setProperties({
        includeList: this.includeList.filter(function (x) {
          var _this7$guids;

          return !((_this7$guids = _this7.guids) !== null && _this7$guids !== void 0 && _this7$guids.includes(x));
        }),
        selectionCount: this.selectionCount - ((_this$guids2 = this.guids) === null || _this$guids2 === void 0 ? void 0 : _this$guids2.length)
      });
    },
    actions: {
      handleLimitChange: function handleLimitChange(limitStr) {
        var _this8 = this;

        Ember.run.next(function () {
          var limit = parseInt(limitStr, 10);

          _this8.changeFilters({
            limit: limit
          });
        });
      },
      handleRowClick: function handleRowClick(record) {
        if (this.onRowClick) {
          this.onRowClick(record);
        }
      },
      handlePageChange: function handlePageChange(pageNumber) {
        var offset = (pageNumber - 1) * this.limit;
        this.changeFilters({
          offset: offset
        });
      },
      handleSortChange: function handleSortChange(header) {
        var sortBy = header.field;
        var sortAsc = sortBy === this.sortBy ? !this.sortAsc : !REVERSED_SORT_TYPES.includes(header.type);
        this.changeFilters({
          sortAsc: sortAsc,
          sortBy: sortBy
        });
      },
      handleMobileSortChange: function handleMobileSortChange(value) {
        var _value$split = value.split('-'),
            _value$split2 = _slicedToArray(_value$split, 2),
            sortBy = _value$split2[0],
            direction = _value$split2[1];

        var sortAsc = direction === 'asc';
        this.changeFilters({
          sortAsc: sortAsc,
          sortBy: sortBy
        });
      },
      handleSelectAll: function handleSelectAll() {
        if (this.isAllSelected) {
          this.performSelectNone();
        } else {
          this.performSelectAll();
        }

        if (this.onSelectionChanged) {
          this.onSelectionChanged(this.includeList, this.selectionCount);
        }
      },
      handleSelect: function handleSelect(checked, cbx) {
        var id = cbx.record[this.selectionFieldName];
        var newSelectionCount = this.selectionCount + (checked ? 1 : -1);
        this.setProperties({
          includeList: checked ? [].concat(_toConsumableArray(this.includeList), [id]) : this.includeList.filter(function (v) {
            return v !== id;
          }),
          selectionCount: newSelectionCount
        });

        if (this.onSelectionChanged) {
          this.onSelectionChanged(this.includeList, this.selectionCount);
        }
      },
      handleCheckboxClick: function handleCheckboxClick(event) {
        // prevent a click in the checkbox from triggering the row onClick function
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});