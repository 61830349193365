define("lh4/enums/ip-ach-service-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ACH_SERVICE_STATUS = Object.freeze({
    PENDING_UNDERWRITING: 'pending_underwriting',
    PENDING_ENABLEMENT: 'pending_enablement',
    SUCCESS: 'success',
    FAILED: 'failed',
    BLOCKED_UNDERWRITING: 'blocked_underwriting'
  });
  var _default = ACH_SERVICE_STATUS;
  _exports.default = _default;
});