define("lh4/components/ip-range-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rangeOptions: [],
    onRangeSelect: null,
    actions: {
      selectRange: function selectRange(range) {
        var _this = this;

        // Wrapping selection into next to prevent semantic transition error interference with ember render
        // selection tries to set value before the render and semantic transition throws error that DOM animation
        // cant be set on item that does not exist
        // error: Transition: Element is no longer attached to DOM. Unable to animate.
        // Use silent setting to surpress this warning in production. slide up out
        Ember.run.next(function () {
          _this.onRangeSelect(range);
        });
      }
    }
  });

  _exports.default = _default;
});