define("lh4/controllers/authenticated/updates/deploy", ["exports", "lh4/controllers/general-form-controller", "moment", "jquery", "lh4/config/environment"], function (_exports, _generalFormController, _moment, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    ajax: Ember.inject.service(),
    ajaxDownload: Ember.inject.service(),
    dynamicPricing: Ember.inject.service(),
    step: 'first',
    selectedItem: 'items',
    loadingAnalysis: false,
    downloadingCSV: false,
    itemsToMatch: Ember.computed(function getter() {
      var locationId = this.getSelectedLocation().id;
      var pricingListItem = this.dynamicPricing.isDynamicPricingEnabled(locationId) ? {
        itemKey: 'schedule-titles',
        title: 'Dynamic Pricing'
      } : {
        itemKey: 'price-schedule-groups',
        title: 'Scheduled pricing'
      };
      return [{
        itemKey: 'items',
        title: 'Items'
      }, {
        itemKey: 'departments',
        title: 'Departments'
      }, {
        itemKey: 'modifiers',
        title: 'Modifiers'
      }, {
        itemKey: 'modifier-sets',
        title: 'Modifier Sets'
      }, {
        itemKey: 'order-types',
        title: 'Order Types'
      }, pricingListItem, {
        itemKey: 'courses',
        title: 'Coursing'
      }, {
        itemKey: 'discounts',
        title: 'Discounts'
      }, {
        itemKey: 'gratuities',
        title: 'Gratuities'
      }, {
        itemKey: 'tenders',
        title: 'Tenders'
      }, {
        itemKey: 'taxes',
        title: 'Taxes'
      }, {
        itemKey: 'surcharges',
        title: 'Surcharges'
      }, {
        itemKey: 'revenue-centers',
        title: 'Revenue centers'
      }, {
        itemKey: 'tax-sets',
        title: 'Revenue classes'
      }, {
        itemKey: 'intraday-period-groups',
        title: 'Day parts'
      }];
    }),
    selectedLocationObserver: Ember.observer('changeset.selectedLocation', function callback() {
      if (this.changeset.get('selectedLocation')) {
        var selectedLocation = this.getSelectedLocation();

        if (selectedLocation) {
          this.changeset.set('name', "".concat(selectedLocation.name, " Deployment"));
        }
      }
    }),
    getSelectedLocation: function getSelectedLocation() {
      var _this = this;

      return this.get('model').formattedCorpLocationsOverview.filteredLocationOverviews.find(function (item) {
        return item.id === _this.changeset.get('selectedLocation');
      });
    },
    loadDeploymentsAnalysis: function loadDeploymentsAnalysis() {
      var _this2 = this;

      this.set('loadingAnalysis', true);
      this.ajax.request("/api/v1/corporations/".concat(this.location.model.corpId, "/deployment/").concat(this.changeset.get('selectedLocation'))).then(function (analysisData) {
        _this2.changeset.set('analysis', analysisData);
      }).finally(function () {
        _this2.set('loadingAnalysis', false);
      });
    },
    actions: {
      applyImmediately: function applyImmediately(value) {
        this.changeset.set('instant', value);
        var element = (0, _jquery.default)('.date-picker-input');
        var picker = element.data('calentim');
        picker.elem.value = (0, _moment.default)().format('MM/DD/YYYY');
        this.changeset.set('scheduledFor', (0, _moment.default)().format('MM/DD/YYYY'));
      },
      changeSelectedMatchItem: function changeSelectedMatchItem(itemToMatch) {
        this.set('selectedItem', itemToMatch);
      },
      prev: function prev() {
        if (this.isSaving) {
          return;
        }

        this.set('step', 'first');
      },
      create: function create() {
        var _this3 = this;

        if (!window.confirm('Warning! – Deploying menu will Delete ALL un-matched records on the child location menu and replace with Corporate menu records. This cannot be undone by Product Support.  Please double-check location to be deployed.   Would you like to continue?')) {
          return;
        }

        if (this.isSaving || this.loadingAnalysis) {
          return;
        }

        if (this.isModelAndChangesetPristine()) {
          this.closeModal();
        }

        this.changeset.validate().then(function () {
          if (_this3.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this3.changeset.get('errors'));
          }

          _this3.setProperties({
            isSaving: true,
            showErrors: true
          });

          return _this3.changeset.save().then(function () {
            _this3.closeModal();
          }).catch(function () {
            var errorMessage = 'Something went wrong, deployment could not be saved.';

            _this3.set('serverError', errorMessage);

            _this3.set('serverErrorTimeoutId', Ember.run.later(_this3, function () {
              _this3.set('serverError', null);
            }, _this3.serverErrorTimeoutDelay));
          });
        }).finally(function () {
          return _this3.set('isSaving', false);
        });
      },
      deploy: function deploy() {
        var _this4 = this;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.changeset.validate().then(function () {
          if (_this4.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this4.changeset.get('errors'));
          }

          _this4.set('step', 'second');

          _this4.loadDeploymentsAnalysis();

          return false;
        }).catch(function () {}).finally(function () {
          return _this4.set('isSaving', false);
        });
      },
      exportCsv: function exportCsv() {
        var _this5 = this;

        if (this.downloadingCSV) {
          return;
        }

        this.setProperties({
          downloadingCSV: true,
          showErrors: true,
          errorMessage: null
        });
        Ember.run.cancel(this.serverErrorTimeoutId);
        var url = "".concat(_environment.default.host, "/api/v1/corporations/").concat(this.location.model.corpId, "/deployment/").concat(this.changeset.get('selectedLocation'), "/export");
        this.ajaxDownload.request(url, 'file.csv', function () {
          _this5.set('errorMessage', 'CSV download failed. Please try again later.');

          _this5.set('serverErrorTimeoutId', Ember.run.later(_this5, function () {
            _this5.set('errorMessage', null);
          }, _this5.serverErrorTimeoutDelay));
        }, function () {
          _this5.set('showErrors', false);
        }, false, 'blob', function () {
          _this5.set('downloadingCSV', false);
        });
      }
    }
  });

  _exports.default = _default;
});