define("lh4/models/hospitality/customer", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "lh4/utils/array-join", "lh4/models/hospitality/customer/email", "lh4/models/hospitality/customer/phone", "lh4/models/hospitality/customer/address"], function (_exports, _model, _attr, _relationships, _arrayJoin, _email, _phone, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "emailTypes", {
    enumerable: true,
    get: function get() {
      return _email.emailTypes;
    }
  });
  Object.defineProperty(_exports, "phoneTypes", {
    enumerable: true,
    get: function get() {
      return _phone.phoneTypes;
    }
  });
  Object.defineProperty(_exports, "addressTypes", {
    enumerable: true,
    get: function get() {
      return _address.addressTypes;
    }
  });
  _exports.default = void 0;

  var typeComparator = function typeComparator(a, b, types) {
    var aOrderIndex = types.findIndex(function (type) {
      return type.name === a.get('type');
    });
    var bOrderIndex = types.findIndex(function (type) {
      return type.name === b.get('type');
    });
    return aOrderIndex - bOrderIndex;
  };

  var primaryFromArray = function primaryFromArray(array, collectionName) {
    return Ember.computed("".concat(collectionName, ".@each.type"), function getter() {
      return this.get(collectionName).toArray().sort(function (a, b) {
        return typeComparator(a, b, array);
      }).get('firstObject');
    });
  };

  var _default = _model.default.extend({
    locationId: (0, _attr.default)('number'),
    firstName: (0, _attr.default)('string'),
    middleName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    fullName: Ember.computed('firstName', 'lastName', function getter() {
      return (0, _arrayJoin.default)(this, ['firstName', 'lastName'], ' ');
    }),
    notes: (0, _attr.default)('string'),
    lookup: (0, _attr.default)('string'),
    taxExempt: (0, _attr.default)('boolean'),
    birthday: (0, _attr.default)('date'),
    anniversary: (0, _attr.default)('date'),
    isActive: (0, _attr.default)('boolean'),
    subscribed: (0, _attr.default)('boolean'),
    emails: (0, _relationships.hasMany)('hospitality/customer/email'),
    phones: (0, _relationships.hasMany)('hospitality/customer/phone'),
    addresses: (0, _relationships.hasMany)('hospitality/customer/address'),
    source: (0, _attr.default)('string'),
    primaryEmail: primaryFromArray(_email.emailTypes, 'emails'),
    primaryPhone: primaryFromArray(_phone.phoneTypes, 'phones'),
    primaryAddress: primaryFromArray(_address.addressTypes, 'addresses')
  }); // Exporting because we plan on having editable customer record


  _exports.default = _default;
});