define("lh4/enums/ip-date-ranges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INTERNET_PAYMENT_DATE_RANGES = Object.freeze({
    TODAY: Object.freeze({
      NAME: 'Today',
      VALUE: 'today'
    }),
    YESTERDAY: Object.freeze({
      NAME: 'Yesterday',
      VALUE: 'yesterday'
    }),
    THIS_WEEK: Object.freeze({
      NAME: 'This Week',
      VALUE: 'this-week'
    }),
    LAST_WEEK: Object.freeze({
      NAME: 'Last Week',
      VALUE: 'last-week'
    }),
    THIS_MONTH: Object.freeze({
      NAME: 'This Month',
      VALUE: 'this-month'
    }),
    LAST_MONTH: Object.freeze({
      NAME: 'Last Month',
      VALUE: 'last-month'
    })
  });
  var _default = INTERNET_PAYMENT_DATE_RANGES;
  _exports.default = _default;
});