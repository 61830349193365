define("lh4/serializers/note", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'note';
    },
    serialize: function serialize(snapshot, options) {
      var note = this._super(snapshot, options);

      return {
        message: note.message
      };
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var payloadData = _objectSpread({}, payload);

      if (!payloadData.cases) {
        payloadData = {
          dispute: payloadData
        };
      }

      return this._super(store, primaryModelClass, payloadData, id, requestType);
    }
  });

  _exports.default = _default;
});