define("lh4/components/ui/header-segment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'segment', 'header', 'clearing', 'header-segment']
  });

  _exports.default = _default;
});