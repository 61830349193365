define("lh4/abilities/customer-engagement", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    walkthrough: Ember.inject.service(),
    canManageCampaigns: (0, _abilityMixin.computedAbility)('walkthrough.data.premiumWelcome.customerEngagement', function getter() {
      return this.hasLocationPermission(_permissions.default.CUSTOMER_ENGAGEMENT.ACCESS) && this.hasService('lighthouse-premium') && this.get('walkthrough.data.premiumWelcome.customerEngagement');
    })
  });

  _exports.default = _default;
});