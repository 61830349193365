define("lh4/models/echo-pro/customer/email", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailTypes = _exports.default = void 0;
  var emailTypes = [{
    name: 'Primary',
    id: 0
  }, {
    name: 'Secondary',
    id: 1
  }, {
    name: 'Work',
    id: 2
  }, {
    name: 'Unknown',
    id: 3
  }];
  _exports.emailTypes = emailTypes;
  var Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      description: 'Email',
      validators: [(0, _emberCpValidations.validator)('format', {
        type: 'email',
        allowBlank: false
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('customer.emails')) {
          var emails = model.get('customer.emails').map(function (e) {
            if (e.get('email')) {
              return e.get('email').toLowerCase();
            }

            return false;
          });
          var unique = emails.length === emails.uniq().length;
          return unique || 'Customer has duplicated email addresses. (Email addresses are not case sensitive)';
        }

        return true;
      })]
    },
    type: {
      description: 'Type',
      validators: [(0, _emberCpValidations.validator)(function (value) {
        return emailTypes.some(function (type) {
          return type.name === value;
        }) || 'Incorrect Email Type';
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    email: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    customer: (0, _relationships.belongsTo)('echo-pro/customer'),
    createdAt: (0, _attr.default)('date'),
    emailTypes: emailTypes.map(function (_ref) {
      var name = _ref.name;
      return {
        name: name,
        value: name
      };
    }),
    contains: function contains(term) {
      return this.get('email').toLowerCase().includes(term);
    }
  });

  _exports.default = _default;
});