define("lh4/components/ui/modal/fullscreen-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fullscreen-modal'],
    classNameBindings: ['isLightModal:light-modal'],
    isLightModal: false,

    /* @param {string} errorMessage - Message to display for form level error */
    errorMessage: null,
    previousBodyOverflow: undefined,
    onClose: function onClose() {},
    didInsertElement: function didInsertElement() {
      // In the situation that we're moving from one fullscreen modal to another
      // it's possible that hidden has yet to be removed. For now we'll still
      // attempt to set back to a previous body overflow value unless that
      // value was hidden. If this causes future issues we may need to rethink
      // the setTimeout pattern we have currently.
      var previousBodyOverflow = document.body.style.overflow === 'hidden' ? '' : document.body.style.overflow;
      this.set('previousBodyOverflow', previousBodyOverflow); // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
      // the responsiveness for some reason. Setting within a setTimeout fixes this.

      setTimeout(function () {
        document.body.style.overflow = 'hidden';
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      setTimeout(function () {
        document.body.style.overflow = _this.previousBodyOverflow;
      });
    },
    actions: {
      close: function close() {
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});