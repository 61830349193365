define("lh4/components/dashboard-widgets/email-campaigns/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['email-campaigns-data', 'table-widget'],
    classNameBindings: ['showSignup'],
    skeletonData: {
      campaigns: [{
        name: 'Special Cocktail Night',
        opens: '2',
        clicks: '1',
        unsubs: '1',
        bounces: '0'
      }, {
        name: 'Friday Night',
        opens: '2',
        clicks: '1',
        unsubs: '1',
        bounces: '0'
      }, {
        name: 'New Years',
        opens: '2',
        clicks: '1',
        unsubs: '1',
        bounces: '0'
      }]
    },
    campaigns: Ember.computed('expanded', 'data', function getter() {
      if (this.get('expanded')) {
        return this.get('data.campaigns');
      }

      return this.get('data.campaigns').slice(0, 3);
    }),
    thereIsMore: Ember.computed('data', function getter() {
      return this.get('loading') || this.get('data.campaigns.length') > 3;
    }),
    showSignup: Ember.computed('permissionError', 'data.campaigns.[]', function getter() {
      return this.get('permissionError') || this.get('data.campaigns.length') === 0;
    })
  });

  _exports.default = _default;
});