define("lh4/components/epro/menu/order-type-form", ["exports", "lh4/utils/times-in-day", "lh4/models/echo-pro/order-type"], function (_exports, _timesInDay, _orderType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * @param {Changeset} orderType - echo-pro/order-type
     */
    orderType: null,

    /**
     * @param {Object[]} sections - reservations/section
     */
    sections: null,

    /**
     * @param {Object[]} printers - echo-pro/printer
     */
    printers: null,

    /**
     * @param {Array} weekdays - array of weekdays starting on locations start of the week day
     */
    weekdays: null,
    classNames: ['ui', 'form', 'padded', 'stackable', 'grid', 'order-type-form'],
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    showErrors: false,
    colors: Object.values(_orderType.COLORS),
    sectionLayoutOptions: Ember.computed.map('sections', function (_ref) {
      var value = _ref.id,
          name = _ref.name,
          isActive = _ref.isActive;
      return {
        value: value,
        name: name,
        isActive: isActive
      };
    }),
    printerOptions: Ember.computed.map('printers', function (_ref2) {
      var value = _ref2.id,
          name = _ref2.name,
          isActive = _ref2.active;
      return {
        value: value,
        name: name,
        isActive: isActive
      };
    }),
    timeOptions: (0, _timesInDay.default)(5),
    customOrderTypeOptions: [{
      id: 'isPrintedDistinctly',
      name: 'Print in Red/Bold'
    }, {
      id: 'isCustomNameRequired',
      name: 'Require ticket name'
    }],
    ruleOptions: Ember.computed('orderType.orderTypeRules.[]', function getter() {
      return [{
        id: 'isCustomerRequired',
        name: 'Require customer'
      }, {
        id: 'requiresAddress',
        name: 'Require address for customer',
        disabled: !this.orderType.get('orderTypeRules').includes('isCustomerRequired')
      }];
    }),
    isRulesDisabled: Ember.computed('orderType.customOptions.[]', function getter() {
      return this.orderType.get('customOptions').includes('isCustomNameRequired');
    }),
    actions: {
      onChange: function onChange(record, value, property) {
        // do nothing if values are the same as this makes changeset dirty even on initial load
        if (record.get(property) === value) {
          return;
        }

        record.set(property, value);
      },
      onOrderTypeRulesChange: function onOrderTypeRulesChange(typeRules) {
        var orderTypeRules = typeRules; // checking if typeRules does not include checkbox id of isCustomerRequired
        // then setting variable orderTypeRules to new filtered array without the id of requiresAddress

        if (!typeRules.includes('isCustomerRequired')) {
          orderTypeRules = typeRules.filter(function (rule) {
            return rule !== 'requiresAddress';
          });
        }

        this.orderType.set('orderTypeRules', orderTypeRules);
      }
    }
  });

  _exports.default = _default;
});