define("lh4/controllers/authenticated/customer-engagement/campaigns/overview/activity", ["exports", "jquery", "lh4/config/environment", "lh4/enums/date-range-title", "moment"], function (_exports, _jquery, _environment, _dateRangeTitle, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var URL = "".concat(_environment.default.host, "/api/v1/reports/lighthouse");

  var buildHeader = function buildHeader(name, field, width) {
    return {
      name: name,
      field: field,
      width: width
    };
  };

  var _default = Ember.Controller.extend({
    queryParams: ['offset', 'limit', 'to', 'from', 'status', 'sortBy', 'sortAsc'],
    offset: 0,
    limit: 25,
    status: null,
    statusTypes: ['All Status Types', 'Opened', 'Clicked', 'Unsubscribed', 'Bounced'],
    selectedStatus: 'all status types',
    sortBy: 'opened',
    sortAsc: true,
    from: (0, _moment.default)().startOf('week').format('YYYY-MM-DD'),
    to: (0, _moment.default)().endOf('week').format('YYYY-MM-DD'),
    session: Ember.inject.service(),
    media: Ember.inject.service(),
    maxDate: (0, _moment.default)(),
    rangeTitle: _dateRangeTitle.DATE_RANGE_TITLE.WEEK.THIS,
    verticalRangeWidth: 160,
    headers: [buildHeader('Name', 'fullName', 'two print-two'), buildHeader('Phone', 'status', 'two print-two'), buildHeader('Email', 'email', 'two print-two'), buildHeader('Opened', 'opened', 'two print-two'), buildHeader('Clicked', 'clicked', 'two print-two'), buildHeader('Unsubscribed', 'unsubscribed', 'two print-two'), buildHeader('Bounced', 'bounced', 'two print-two')],
    queryParamsComp: Ember.computed('from', 'to', 'status', 'model.locationId', function getter() {
      return _jquery.default.param({
        from: this.from,
        to: this.to,
        status: this.get('status'),
        'locations[]': [this.get('model.locationId')],
        campaignGuid: this.get('model.campaignId'),
        token: this.session.get('data.authenticated').token
      });
    }),
    pdfURL: Ember.computed('queryParamsComp', function getter() {
      return "".concat(URL, "/pdf/customer-engagement-campaign-customer-activity?").concat(this.get('queryParamsComp'));
    }),
    csvURL: Ember.computed('queryParamsComp', function getter() {
      return "".concat(URL, "/csv/customer-engagement-campaign-customer-activity?").concat(this.get('queryParamsComp'));
    }),
    xlsURL: Ember.computed('queryParamsComp', function getter() {
      return "".concat(URL, "/xls/customer-engagement-campaign-customer-activity?").concat(this.get('queryParamsComp'));
    }),
    actions: {
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        this.set('from', startDate.format('YYYY-MM-DD'));
        this.set('to', endDate.format('YYYY-MM-DD'));
      },
      onMobileChange: function onMobileChange(rangeTitle, startDate, endDate) {
        this.set('rangeTitle', rangeTitle);

        if (startDate) {
          this.set('from', startDate.format('YYYY-MM-DD'));
        }

        if (endDate) {
          this.set('to', endDate.format('YYYY-MM-DD'));
        }
      },
      onRangeSelect: function onRangeSelect(instance, range) {
        var startDate = range.startDate,
            endDate = range.endDate,
            title = range.title;
        instance.setStart(startDate.startOf('day'));
        instance.setEnd(endDate.endOf('day'));
        this.set('from', startDate.format('YYYY-MM-DD'));
        this.set('rangeTitle', title);
        this.set('to', endDate.format('YYYY-MM-DD'));
      },
      filterChanged: function filterChanged(value) {
        this.set('sortAsc', value.sortAsc);
        this.set('sortBy', value.sortBy);
        this.set('limit', value.limit);
        this.set('offset', value.offset);
      },
      statusChanged: function statusChanged(selected) {
        this.set('selectedStatus', selected);

        if (selected === 'all status types') {
          this.set('status', null);
        } else {
          this.set('status', selected);
        }
      },
      customerSelected: function customerSelected(customer) {
        this.transitionToRoute('authenticated.customer-engagement.campaigns.overview.activity.customer', customer.guid);
      }
    }
  });

  _exports.default = _default;
});