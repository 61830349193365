define("lh4/components/ui/date/last-visit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MOMENT_LOCALE_EN_ABBR = void 0;
  var MOMENT_LOCALE_EN_ABBR = {
    parentLocale: 'en',
    relativeTime: {
      future: '%s',
      past: '%s',
      s: function s(number, withoutSuffix) {
        return withoutSuffix ? 'now' : 'a few seconds';
      },
      m: '1 min.',
      mm: '%d min.',
      h: '1 h.',
      hh: '%d h.',
      d: '1 d.',
      dd: '%d d.',
      M: '1 m.',
      MM: '%d m.',
      y: '1 y.',
      yy: '%d y.'
    }
  };
  _exports.MOMENT_LOCALE_EN_ABBR = MOMENT_LOCALE_EN_ABBR;

  _moment.default.defineLocale('en-abbr', MOMENT_LOCALE_EN_ABBR);

  var _default = Ember.Component.extend({
    classNames: ['last-visit'],
    parseFormat: 'ddd MMM DD YYYY HH:mm:ss GMT+-HHmm',
    outputFormat: 'MM/DD/YYYY',
    isDateValid: Ember.computed('date', function getter() {
      return (0, _moment.default)(this.date).isValid();
    }),
    daysFrom: Ember.computed('date', function getter() {
      return (0, _moment.default)(this.date).locale('en-abbr').fromNow();
    }),
    formattedDate: Ember.computed('date', function getter() {
      return (0, _moment.default)(this.date, this.parseFormat).local().format(this.outputFormat);
    })
  });

  _exports.default = _default;
});