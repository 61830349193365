define("lh4/components/dashboard-widgets/ip-transactions-risk/data", ["exports", "ember-changeset", "lh4/mixins/dashboard-widget-mixin", "lh4/enums/ip-date-ranges"], function (_exports, _emberChangeset, _dashboardWidgetMixin, _ipDateRanges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['ip-transactions-risk', 'table-widget-group'],
    widgetSlug: 'ip-transactions-risk',
    sessionStorage: Ember.inject.service('session-storage'),
    init: function init() {
      this._super.apply(this, arguments);

      var sessionStorageData = this.sessionStorage.get(this.widgetSlug);
      this.selectedRange = sessionStorageData ? sessionStorageData === null || sessionStorageData === void 0 ? void 0 : sessionStorageData.range : _ipDateRanges.default.THIS_WEEK.VALUE;
      this.selectedRangeDispute = sessionStorageData ? sessionStorageData === null || sessionStorageData === void 0 ? void 0 : sessionStorageData.range : _ipDateRanges.default.LAST_MONTH.VALUE;
    },
    chargebackValue: Ember.computed('data', function chargebackValue() {
      var pctChargeback = this.data.pctChargeback;
      var numberChargeback = Number(pctChargeback);
      return numberChargeback.toFixed(2);
    }),
    authorizedTransactionRate: Ember.computed('data', function authorizedTransactionRate() {
      var authorizedTransactions = this.data.authorizedTransactions;
      var formatttedAuthorizedTransactions = Number(authorizedTransactions).toFixed(2);
      return formatttedAuthorizedTransactions;
    }),
    skeletonData: {
      pctChargeback: 0.0,
      authorizedTransactions: 0,
      riskStatus: [{
        status: 'Approved',
        value: 0
      }, {
        status: 'Review',
        value: 0
      }, {
        status: 'Verified',
        value: 0
      }, {
        status: 'Declined',
        value: 0
      }]
    },
    rangeOptions: [_ipDateRanges.default.THIS_WEEK, _ipDateRanges.default.LAST_WEEK, _ipDateRanges.default.THIS_MONTH, _ipDateRanges.default.LAST_MONTH],
    actions: {
      onRangeSelect: function onRangeSelect(range) {
        this.setSettings(new _emberChangeset.default({
          range: range
        }));
        this.sessionStorage.update(this.widgetSlug, {
          range: range
        });
      }
    }
  });

  _exports.default = _default;
});