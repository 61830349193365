define("lh4/components/input-merchant-ids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    newMerchId: null,
    ssnLastFour: null,
    actions: {
      addMerchant: function addMerchant() {}
    }
  });

  _exports.default = _default;
});