define("lh4/templates/components/internet-payments/ui/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hE3LzhRB",
    "block": "{\"symbols\":[\"radioData\",\"@buttonName\",\"@labelName\",\"@buttonValue\",\"@currentSelected\",\"@isDisabled\",\"@onChange\",\"@data\"],\"statements\":[[4,\"if\",[[23,8,[]]],null,{\"statements\":[[4,\"each\",[[23,8,[]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"ui-radio\",[],[[\"@name\",\"@class\",\"@label\",\"@value\",\"@current\",\"@disabled\",\"@onChange\"],[[23,2,[]],\"radio-button (if radioData.className (concat ' ' radioData.className))\",[23,1,[\"labelName\"]],[23,1,[\"buttonValue\"]],[23,5,[]],[23,1,[\"isDisabled\"]],[28,\"fn\",[[23,7,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[5,\"ui-radio\",[],[[\"@name\",\"@class\",\"@label\",\"@value\",\"@current\",\"@disabled\",\"@onChange\"],[[23,2,[]],\"radio-button (if radioData.className (concat ' ' radioData.className))\",[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[28,\"fn\",[[23,7,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/radio-button.hbs"
    }
  });

  _exports.default = _default;
});