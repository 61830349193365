define("lh4/mixins/edit-controller-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * General purpose Mixin for handling record create/edit.
   * Provides generic actions for saving and discarding changes.
   *
   * Usage:
   * When extending, must specify "modelName" otherwise default "model.record" will be used.
   * Additionally can override actions.
   */
  var _default = Ember.Mixin.create({
    /**
     * Override when extending if needed
     */
    modelName: 'model.record',
    editRecord: Ember.inject.service('edit-record'),
    flashMessagesHelper: Ember.inject.service('flash-messages-helper'),
    isLoading: false,
    showErrors: false,
    setLoading: Ember.computed('isLoading', function getter() {
      return this.get('isLoading') ? 'loading' : '';
    }),
    actions: {
      onCustomValidationError: function onCustomValidationError(messages) {
        this.flashMessagesHelper.clearMessages().pushMessages(messages);
      },
      save: function save() {
        var _this = this;

        if (this.get('isLoading')) {
          return;
        }

        this.flashMessagesHelper.clearMessages();
        this.set('isLoading', true);
        var modelName = this.get('modelName');
        var model = this.get(modelName);
        this.editRecord.validate(model).then(function () {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return _this.send.apply(_this, ['saveAction'].concat(args));
        }).catch(function (validationMessages) {
          _this.set('showErrors', true);

          _this.flashMessagesHelper.pushMessages(validationMessages, 'negative');

          _this.set('isLoading', false);
        });
      },
      saveAction: function saveAction() {
        var _this2 = this;

        var modelName = this.get('modelName');
        var model = this.get(modelName);
        this.set('isLoading', true);
        this.editRecord.save(model).then(function (savedModel) {
          _this2.set('showErrors', false);

          _this2.flashMessagesHelper.pushMessage('Saved successfully', 'success');

          return _this2.actions.afterSaveHook.call(_this2, savedModel);
        }).catch(function (errorMessages) {
          _this2.flashMessagesHelper.pushMessages(errorMessages, 'negative');
        }).finally(function () {
          return _this2.set('isLoading', false);
        });
      },
      afterSaveHook: function afterSaveHook(savedModel) {
        return savedModel;
      },
      clear: function clear() {
        this.get(this.get('modelName')).rollbackAttributes();
        this.flashMessagesHelper.clearMessages().pushMessage('Changes have been discarded', 'success');
      },
      delayedTransition: function delayedTransition(route, params) {
        var _this3 = this;

        var args = params ? [route, params] : [route];
        Ember.run.later(function () {
          return _this3.transitionToRoute.apply(_this3, args);
        }, 2000);
      }
    }
  });

  _exports.default = _default;
});