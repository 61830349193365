define("lh4/utils/text-parser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.spreadsheetToValuesMatrix = spreadsheetToValuesMatrix;
  _exports.textToValuesMatrix = textToValuesMatrix;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var isValuableCell = function isValuableCell(cell) {
    return /^[A-Z]+[0-9]+$/.test(cell);
  };

  var parseIndex = function parseIndex(cell) {
    return parseInt(cell.match(/[0-9]+/)[0], 10);
  };

  var parseLetter = function parseLetter(cell) {
    return cell.match(/[A-Z]+/)[0];
  };

  var parseValue = function parseValue(value) {
    return value.t === 's' ? value.v.trim() : value.v || null;
  };
  /**
   * Returns array of cvs values
   *
   * @method   parseAsCsv
   *
   * @param    {String} line - csv line to parse
   * @returns  {Array} - result line values
   */


  function parseAsCsv(line) {
    // eslint-disable-next-line
    var regexValid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/; // eslint-disable-next-line

    var regexValue = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;

    if (!regexValid.test(line)) {
      return null;
    }

    var a = [];
    line.replace(regexValue, function (m0, m1, m2, m3) {
      if (m1 !== undefined) {
        a.push(m1.replace(/\\'/g, "'"));
      } else if (m2 !== undefined) {
        a.push(m2.replace(/\\"/g, '"'));
      } else if (m3 !== undefined) {
        a.push(m3);
      }

      return '';
    });

    if (/,\s*$/.test(line)) {
      a.push('');
    }

    return a;
  }
  /**
   * Returns array of tvs values
   *
   * @method   parseAsTsv
   *
   * @param    {String} line - tsv line to parse
   * @returns  {Array} - result line values
   */


  function parseAsTsv(line) {
    return line.split('\t');
  }
  /**
   * Extract all valuable row indexes as an array.
   *
   * @method   extractIndexes
   *
   * @param    {Array} existingCells - all sheet cells, that were parsed
   * @param    {String} range - sheet !ref attribute value, that points to actual sheet cells range
   * @returns  {Array} - array of row indexes
   */


  function extractIndexes(existingCells, range) {
    var indexes = existingCells.map(function (cell) {
      return parseIndex(cell);
    }).uniq();

    if (range) {
      var _range$split = range.split(':'),
          _range$split2 = _slicedToArray(_range$split, 2),
          from = _range$split2[0],
          to = _range$split2[1];

      var fromIndex = parseIndex(from);
      var toIndex = parseIndex(to);
      indexes = indexes.filter(function (index) {
        return index >= fromIndex && index <= toIndex;
      });
    }

    return indexes.sort(function (a, b) {
      return a - b;
    });
  }
  /**
   * Since xlsx parser do not include missing cells between filled cells, generate their names manually.
   *
   * @method   addMissingCells
   *
   * @param    {Array} columns - all column names
   * @param    {Array} indexes - all row indexes
   * @returns  {Array} - array of all cells with values to import
   */


  function generateRows(columns, indexes) {
    return indexes.map(function (index) {
      return columns.map(function (column) {
        return "".concat(column).concat(index);
      });
    });
  }
  /**
   * Returns array of arrays of first sheet records
   *
   * @method   spreadsheetToValuesMatrix
   *
   * @param    {Object} workbook - xlsx workbook
   * @returns  {Array} - array of arrays of records
   */


  function spreadsheetToValuesMatrix(workbook) {
    if (!workbook || !workbook.Sheets) {
      return [];
    }

    var parseSheet = function parseSheet(sheet) {
      var existingCells = Object.keys(sheet).filter(function (cell) {
        return isValuableCell(cell);
      });
      var rowIndexes = extractIndexes(existingCells, sheet['!ref']);
      var columnLetters = existingCells.map(function (cell) {
        return parseLetter(cell);
      }).uniq();
      var rows = generateRows(columnLetters, rowIndexes);
      return rows.map(function (row) {
        return row.map(function (cell) {
          return parseValue(sheet[cell] || {});
        });
      });
    };

    var firstSheetName = Object.keys(workbook.Sheets)[0];
    return parseSheet(workbook.Sheets[firstSheetName]);
  }
  /**
   * Returns callback function to parse tsv or csv text
   *
   * @method   guessSplitter
   *
   * @param    {String} line - any csv or tsv text line
   * @returns  {Function} - array of arrays of records
   */


  function guessSplitter(line) {
    var counts = ['\t', ','].map(function (separator) {
      return line.split(separator).length - 1;
    });
    var maxRepeats = Math.max.apply(Math, _toConsumableArray(counts));
    var index = counts.indexOf(maxRepeats);

    if (index === 0) {
      return function (row) {
        return parseAsTsv(row);
      };
    }

    return function (row) {
      return parseAsCsv(row);
    };
  }
  /**
   * Returns array of arrays of csv or tsv text
   *
   * @method   textToValuesMatrix
   *
   * @param    {String} text - csv or tsv text
   * @returns  {Array} - array of arrays of records
   */


  function textToValuesMatrix(text) {
    var rows = text.split('\n').filter(function (row) {
      return !Ember.isBlank(row);
    });

    if (!rows.length) {
      return [];
    }

    var splitter = guessSplitter(rows[0]);
    return rows.map(function (row) {
      var cells = splitter(row);
      return cells ? cells.map(function (record) {
        return record ? record.trim() : '';
      }) : [];
    }).filter(function (row) {
      return !Ember.isEmpty(row) && row.some(function (cell) {
        return !Ember.isBlank(cell);
      });
    });
  }
});