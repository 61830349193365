define("lh4/mirage/fixtures/reservations/reservations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RESERVATIONS = void 0;
  var RESERVATIONS = {
    reservations: [{
      partyDate: null,
      partyTime: '2023-12-26T22:00:00.000Z',
      arrivedAt: null,
      createdAt: '2023-12-27T14:13:49.131Z',
      updatedAt: '2023-12-27T14:15:40.159Z',
      guid: 'ea058de7-7f43-4ee4-a3d3-3552cd453e91',
      locationId: 1,
      statusId: 2,
      sectionGuid: null,
      tableLayoutId: null,
      type: 'reservation',
      customerGuid: null,
      customerFullName: 'Hagrids birthday party!',
      customerPhone: '+37062215123',
      customerPhoneType: 'Cell',
      customerEmail: null,
      notes: '',
      partySize: 2,
      notificationType: null,
      serverCreatedAt: '2023-12-27T14:13:49.131Z',
      serverUpdatedAt: '2023-12-27T14:15:40.159Z',
      tableLayoutGuid: null
    }],
    meta: {
      count: 1
    }
  };
  _exports.RESERVATIONS = RESERVATIONS;
  var _default = RESERVATIONS;
  _exports.default = _default;
});