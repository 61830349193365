define("lh4/abilities/kitchen-display-system", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    features: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    canView: (0, _abilityMixin.computedAbility)('location.model', function getter() {
      return this.location.model.terminalProducts.includes('kds');
    }),
    canViewPreparableItems: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.hasProduct('echo-pro') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.KDS_PREP_ITEM];
    }),
    canViewPreparationTimes: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.hasProduct('echo-pro') && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.KDS_PREPARATION_TIMES];
    }),
    canViewOrderReadyScreen: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.KDS_ORDER_READY_SCREEN];
    })
  });

  _exports.default = _default;
});