define("lh4/enums/transactions-filters", ["exports", "lh4/enums/ip-risk-statuses", "lh4/enums/transaction-types"], function (_exports, _ipRiskStatuses, _transactionTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GIFT_CARD_EVENT_STATUSES = _exports.EVENT_STATUS_OPTIONS_BASE = _exports.TRANSACTION_STATUS_OPTIONS_BASE = _exports.RISK_OPTIONS_BASE = _exports.TYPE_OPTIONS_BASE = _exports.CARD_TYPES_OPTIONS_BASE = void 0;
  var TYPE_OPTIONS_BASE = Object.freeze([{
    id: _transactionTypes.TRANSACTION_TYPE.AUTH,
    name: _transactionTypes.TRANSACTION_TYPE_DISPLAY_VALUE.AUTH
  }, {
    id: _transactionTypes.TRANSACTION_TYPE.SALE,
    name: _transactionTypes.TRANSACTION_TYPE_DISPLAY_VALUE.SALE
  }, {
    id: _transactionTypes.TRANSACTION_TYPE.REFUND,
    name: _transactionTypes.TRANSACTION_TYPE_DISPLAY_VALUE.REFUND
  }, {
    id: _transactionTypes.TRANSACTION_TYPE.VOID,
    name: _transactionTypes.TRANSACTION_TYPE_DISPLAY_VALUE.VOID
  }]);
  _exports.TYPE_OPTIONS_BASE = TYPE_OPTIONS_BASE;
  var RISK_OPTIONS_BASE = Object.freeze([{
    id: _ipRiskStatuses.default.APPROVED,
    name: 'Approved'
  }, {
    id: _ipRiskStatuses.default.DECLINED,
    name: 'Declined'
  }, {
    id: _ipRiskStatuses.default.REVIEW,
    name: 'Review'
  }, {
    id: _ipRiskStatuses.default.ESCALATION,
    name: 'Escalation'
  }]);
  _exports.RISK_OPTIONS_BASE = RISK_OPTIONS_BASE;
  var TRANSACTION_STATUS_OPTIONS_BASE = Object.freeze([{
    id: 'SETTLED',
    name: 'Settled'
  }, {
    id: 'UNSETTLED',
    name: 'Unsettled'
  }, {
    id: 'DECLINED',
    name: 'Declined'
  }, {
    id: 'FAILED',
    name: 'Failed'
  }, {
    id: 'CHARGEBACK',
    name: 'Chargeback'
  }]);
  _exports.TRANSACTION_STATUS_OPTIONS_BASE = TRANSACTION_STATUS_OPTIONS_BASE;
  var CARD_TYPES_OPTIONS_BASE = Object.freeze([{
    id: 'Credit Card',
    name: 'Credit Card'
  }, {
    id: 'Debit Card',
    name: 'Debit Card'
  }, {
    id: 'Prepaid Debit Card',
    name: 'Prepaid Debit Card'
  }, {
    id: 'Gift Card',
    name: 'Gift Card'
  }, {
    id: 'Unknown',
    name: 'Unknown'
  }]);
  _exports.CARD_TYPES_OPTIONS_BASE = CARD_TYPES_OPTIONS_BASE;
  var EVENT_STATUS_OPTIONS_BASE = Object.freeze([{
    id: 'RISK_APPROVED',
    longName: 'Risk Approved'
  }, {
    id: 'RISK_DECLINED',
    longName: 'Risk Declined'
  }, {
    id: 'RISK_REVIEW',
    longName: 'Risk Review'
  }, {
    id: 'RISK_ESCALATION',
    longName: 'Risk Escalation'
  }, {
    id: 'CARD_VERIFY_APPROVED',
    longName: 'Card Verify Approved'
  }, {
    id: 'CARD_VERIFY_DECLINED',
    longName: 'Card Verify Declined'
  }, {
    id: 'AUTH_APPROVED',
    longName: 'Auth Approved'
  }, {
    id: 'AUTH_DECLINED',
    longName: 'Auth Declined'
  }, {
    id: 'CAPTURED',
    longName: 'Captured'
  }, {
    id: 'VOIDED',
    longName: 'Voided'
  }, {
    id: 'SENT_FOR_SETTLEMENT',
    longName: 'Sent for Settlement'
  }, {
    id: 'SETTLED',
    longName: 'Settled'
  }, {
    id: 'AUTO_SETTLED',
    longName: 'Auto Settled',
    isChecked: false
  }, {
    id: 'SETTLEMENT_FAILED',
    longName: 'Settlement Failed'
  }, {
    id: 'AUTH_EXPIRED',
    longName: 'Auth Expired'
  }, {
    id: 'AUTH_REFERRAL',
    longName: 'Auth Referral'
  }, {
    id: 'REFUND_REQUESTED',
    longName: 'Refund Requested'
  }, {
    id: 'REFUNDED',
    longName: 'Refunded'
  }, {
    id: 'REFUND_FAILED',
    longName: 'Refund Failed'
  }, {
    id: 'NOTIFICATION_OF_DISPUTE',
    longName: 'Notification of Dispute'
  }, {
    id: 'RETRIEVAL_REQUEST',
    longName: 'Retrieval Request'
  }]);
  _exports.EVENT_STATUS_OPTIONS_BASE = EVENT_STATUS_OPTIONS_BASE;
  var GIFT_CARD_EVENT_STATUSES = Object.freeze([{
    id: 'BALANCE CHECK',
    longName: 'Balance Check'
  }, {
    id: 'LOADED',
    longName: 'Loaded'
  }, {
    id: 'DEACTIVATED',
    longName: 'Deactivated'
  }]);
  _exports.GIFT_CARD_EVENT_STATUSES = GIFT_CARD_EVENT_STATUSES;
});