define("lh4/templates/components/internet-payments/ui/dates/datetime-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "omK8lpdm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"inline\"]],[24,[\"inlineDesktop\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[7,\"input\",true],[10,\"class\",\"datetime-range-picker-input\"],[10,\"type\",\"hidden\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"ui input dropdown standard desktop-datepicker\",[28,\"if\",[[23,0,[\"isActive\"]],\" active\"],null]]]],[10,\"tabindex\",\"-1\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"togglePicker\"],null]],[10,\"data-test-datetime-range-picker\",\"container\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"label\"],[10,\"data-test-datetime-range-picker\",\"label\"],[8],[1,[23,0,[\"label\"]],false],[9],[0,\"\\n\\t\\t\"],[7,\"input\",true],[10,\"class\",\"datetime-range-picker-input\"],[11,\"onfocus\",[28,\"action\",[[23,0,[]],\"handleInputFocus\"],null]],[11,\"oninput\",[28,\"action\",[[23,0,[]],\"handleInput\"],null]],[11,\"onkeydown\",[28,\"action\",[[23,0,[]],\"handleInputKeyDown\"],null]],[10,\"data-test-datetime-range-picker\",\"input\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"icon chevron down\"],[10,\"data-test-datetime-range-picker\",\"icon\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/dates/datetime-range-picker.hbs"
    }
  });

  _exports.default = _default;
});