define("lh4/serializers/internet-payments/transaction", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'transbaseId',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'transactions') {
        return this._super('internet-payments/transactions');
      }

      return this._super('internet-payments/transaction');
    }
  });

  _exports.default = _default;
});