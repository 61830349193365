define("lh4/adapters/support/ticket", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery: function urlForQuery() {
      return "".concat(this.host, "/api/v1/support/tickets/tickets");
    },
    urlForCreateRecord: function urlForCreateRecord() {
      return "".concat(this.host, "/api/v1/support/tickets/ticket");
    },
    urlForFindAll: function urlForFindAll() {
      return "".concat(this.host, "/api/v1/support/tickets/tickets");
    },
    urlForFindRecord: function urlForFindRecord(id, type, model) {
      return "".concat(this.host, "/api/v1/support/tickets/ticket/").concat(model.record.locationId, "/").concat(id);
    }
  });

  _exports.default = _default;
});