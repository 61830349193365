define("lh4/mixins/query-param-reset-mixin", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Mixin.create({
    resetFutureDate: function resetFutureDate(startParam, endParam) {
      var params = new URL(window.location.href).searchParams;
      var start = params.get(startParam);
      var end = params.get(endParam);

      if (!start || !end) {
        return;
      }

      var m = (0, _moment.default)();
      var today = m.format('YYYY-MM-DD');
      var yesterday = m.subtract(1, 'days').format('YYYY-MM-DD');
      var momentStart = (0, _moment.default)(start);
      var momentEnd = (0, _moment.default)(end);

      var checkInvalidDates = function checkInvalidDates(date) {
        var momentDate = (0, _moment.default)(date);
        return momentDate.format('DD') === 'Invalid date' || momentDate.format('MM') === 'Invalid date';
      }; // if we start in the future, adjust start and end


      if (momentStart.isAfter(m, 'day')) {
        var _queryParams;

        this.replaceWith({
          queryParams: (_queryParams = {}, _defineProperty(_queryParams, startParam, yesterday), _defineProperty(_queryParams, endParam, today), _queryParams)
        });
      } // if start is present, but end is in future, adjust it


      if (momentEnd.isAfter(m, 'day')) {
        this.replaceWith({
          queryParams: _defineProperty({}, endParam, today)
        });
      } // if month or day are invalid reset dates


      if (checkInvalidDates(momentStart) || checkInvalidDates(momentEnd)) {
        var _queryParams3;

        this.replaceWith({
          queryParams: (_queryParams3 = {}, _defineProperty(_queryParams3, startParam, yesterday), _defineProperty(_queryParams3, endParam, today), _queryParams3)
        });
      }
    }
  });

  _exports.default = _default;
});