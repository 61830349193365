define("lh4/models/echo-pro/customer/phone", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.phoneTypes = _exports.default = void 0;
  var phoneTypes = [{
    name: 'Home',
    id: 0
  }, {
    name: 'Mobile',
    id: 1
  }, {
    name: 'Work',
    id: 2
  }, {
    name: 'Emergency',
    id: 3
  }, {
    name: 'Unknown',
    id: 4
  }];
  _exports.phoneTypes = phoneTypes;

  var trimPunctuation = function trimPunctuation(value) {
    return value.replace(/[^a-zA-Z0-9]/, '');
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    number: {
      description: 'Phone number',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('phone-number', {
        message: 'Phone number has incorrect format'
      })]
    },
    type: (0, _emberCpValidations.validator)(function (value) {
      return phoneTypes.some(function (type) {
        return type.name === value;
      }) || 'Incorrect Phone Type';
    })
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    number: (0, _attr.default)('phone'),
    type: (0, _attr.default)('string'),
    customer: (0, _relationships.belongsTo)('echo-pro/customer'),
    createdAt: (0, _attr.default)('date'),
    phoneTypes: phoneTypes.map(function (_ref) {
      var name = _ref.name;
      return {
        name: name,
        value: name
      };
    }),
    contains: function contains(term) {
      var trimmed = trimPunctuation(term);

      if (!trimmed && term) {
        return false;
      }

      var rawNumber = trimPunctuation(this.get('number') || '');
      return rawNumber.includes(trimmed);
    }
  });

  _exports.default = _default;
});