define("lh4/utils/route-rephrase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getIndexRoute;

  /**
   * Returns specific nested route's parent index route name
   *
   * @param    {string} [route] - name to rephrase
   * @returns  {string} rephrased/initial route name
   */
  function getIndexRoute(route) {
    var routeNames = [{
      name: 'authenticated.locations.location.echo-pro.manage-pos.customize-menu.department.edit-department',
      reroute: 'authenticated.locations.location.echo-pro.manage-pos.customize-menu'
    }, {
      name: 'authenticated.locations.location.echo-pro.manage-pos.modifiers.edit-modifier',
      reroute: 'authenticated.locations.location.echo-pro.manage-pos.modifiers'
    }, {
      name: 'authenticated.locations.location.echo-pro.manage-pos.modifiers-and-modifier-sets.set.edit-set',
      reroute: 'authenticated.locations.location.echo-pro.manage-pos.modifiers-and-modifier-sets'
    }, {
      name: 'authenticated.locations.location.echo-pro.manage-pos.order-types.edit',
      reroute: 'authenticated.locations.location.echo-pro.manage-pos.order-types'
    }, {
      name: 'authenticated.locations.location.echo-pro.manage-pos.accounting.surcharges.edit-surcharge',
      reroute: 'authenticated.locations.location.echo-pro.manage-pos.accounting.surcharges'
    }, {
      name: 'authenticated.locations.location.echo-pro.manage-pos.accounting.taxes.edit-tax',
      reroute: 'authenticated.locations.location.echo-pro.manage-pos.accounting.taxes'
    }, {
      name: 'authenticated.locations.location.echo-pro.manage-pos.accounting.tax-sets.edit-set',
      reroute: 'authenticated.locations.location.echo-pro.manage-pos.accounting.tax-sets'
    }, {
      name: 'authenticated.locations.location.echo-pro.reservations',
      reroute: 'authenticated.locations.location.echo-pro.reservations'
    }];
    var result = routeNames.find(function (routeName) {
      return route.includes(routeName.name);
    });
    return result ? result.reroute : route;
  }
});