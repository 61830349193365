define("lh4/templates/components/internet-payments/ui/value-revealer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NwY4jWCV",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[7,\"span\",false],[12,\"class\",\"value-revealer\"],[12,\"data-test\",\"value-revealer\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"toggleReveal\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isRevealed\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"ui/copy-to-clipboard\",[],[[\"@value\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\tReveal\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/value-revealer.hbs"
    }
  });

  _exports.default = _default;
});