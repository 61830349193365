define("lh4/templates/components/internet-payments/ui/fields/copy-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/JS9CTkE",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"div\",false],[13,1],[12,\"class\",[29,[\"internet-payments-input-container copy ui form \",[28,\"if\",[[23,0,[\"args\",\"containerClass\"]],[23,0,[\"args\",\"containerClass\"]]],null]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"args\",\"inputClass\"]]]]],[8],[1,[23,0,[\"args\",\"value\"]],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n\\t\\t\"],[5,\"copy-button\",[[12,\"title\",\"Copy to clipboard\"]],[[\"@clipboardText\"],[[23,0,[\"args\",\"value\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[5,\"ui/icons/internet-payments/copy\",[],[[],[]]],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/fields/copy-input.hbs"
    }
  });

  _exports.default = _default;
});