define("lh4/routes/authenticated/epro/menu/price-schedule-groups", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    requiredPermissions: 'manage echo-pro/menu',
    location: Ember.inject.service(),
    queryParams: {
      searchName: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
    },
    model: function model(params) {
      var priceScheduleGroups = this.store.query('echo-pro/price-schedule-group', _objectSpread(_objectSpread({}, params), {}, {
        location: this.location.model.id,
        // Active price schedule group need to be always on the top
        order: "isActive DESC,".concat(params.order)
      }));
      return Ember.RSVP.hash({
        priceScheduleGroups: priceScheduleGroups
      });
    },
    redirect: function redirect() {
      if (this.can.can('view dynamic pricing in echo-pro/dynamic-pricing', this.location.model)) {
        this.transitionTo('authenticated.epro.menu.dynamic-pricing');
      }
    }
  });

  _exports.default = _default;
});