define("lh4/templates/components/reports/report-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F292aK9S",
    "block": "{\"symbols\":[],\"statements\":[[4,\"reports/table-wrapper\",null,[[\"isLoading\",\"generatedReport\",\"searchError\",\"searchReport\",\"currentReport\",\"generatedReportType\",\"onToggleFavorite\",\"reportType\",\"enabledProd\",\"selectedLocations\",\"exportTypes\",\"startsAt\",\"endsAt\",\"intradayPeriodGroupGuids\",\"revenueCenterGuids\"],[[24,[\"isLoading\"]],[24,[\"generatedReport\"]],[24,[\"searchError\"]],[28,\"action\",[[23,0,[]],\"searchReport\"],null],[24,[\"currentReport\"]],[24,[\"generatedReportType\"]],[24,[\"onToggleFavorite\"]],[24,[\"reportType\"]],[24,[\"enabledProd\"]],[24,[\"selectedLocations\"]],[24,[\"exportTypes\"]],[24,[\"startsAt\"]],[24,[\"endsAt\"]],[24,[\"intradayPeriodGroupGuids\"]],[24,[\"revenueCenterGuids\"]]]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"ui segment \",[28,\"if\",[[24,[\"isLoading\"]],\"loading disabled\"],null]]]],[8],[0,\"\\n\\t\\t\"],[1,[28,\"reports/report-table-raw\",null,[[\"currentReport\",\"displayedRows\"],[[24,[\"currentReport\"]],[24,[\"displayedRows\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reports/report-table.hbs"
    }
  });

  _exports.default = _default;
});