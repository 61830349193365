define("lh4/adapters/internet-payments/customer/details", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    location: Ember.inject.service(),
    pathForType: function pathForType() {
      return 'internet-payments/customers';
    },
    urlForFindRecord: function urlForFindRecord(id, type, snapshot) {
      var _adapterOptions$locat;

      var adapterOptions = snapshot.adapterOptions;
      return "".concat(this._super(id, type, snapshot), "?locationId=").concat((_adapterOptions$locat = adapterOptions === null || adapterOptions === void 0 ? void 0 : adapterOptions.locationId) !== null && _adapterOptions$locat !== void 0 ? _adapterOptions$locat : this.location.model.id);
    }
  });

  _exports.default = _default;
});