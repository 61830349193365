define("lh4/controllers/authenticated/virtual-gift-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    giftCardIframeData: Ember.computed('model.giftCardIframe', function giftCardIframeData() {
      return this.model.giftCardIframe.data;
    }),
    giftCardIframeError: Ember.computed('model.giftCardIframe', function giftCardIframeError() {
      return this.model.giftCardIframe.error;
    })
  });

  _exports.default = _default;
});