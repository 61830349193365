define("lh4/mirage/fixtures/users/transactions/filter-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FILTER_TEMPLATES_FIXTURE = [{
    id: '26058c13-7ef2-4e20-90b4-da6df5098a90',
    guid: '26058c13-7ef2-4e20-90b4-da6df5098a90',
    name: 'Template 1',
    product: 'internet-payments',
    options: [{
      id: 'PERIOD',
      data: {
        filterTrayCalendarEnd: '2024-02-05',
        filterTrayCalendarStart: '2023-11-07'
      }
    }, {
      id: 'STATUS',
      data: {
        status: ['SETTLED', 'UNSETTLED']
      }
    }, {
      id: 'RISK',
      data: {
        riskStatus: []
      }
    }, {
      id: 'TYPE',
      data: {
        type: []
      }
    }, {
      id: 'TRANSACTION AMOUNT',
      data: {}
    }, {
      id: 'RISK RULES',
      data: {
        riskRules: []
      }
    }, {
      id: 'EVENT STATUS',
      data: {
        eventStatus: []
      }
    }, {
      id: 'ACQUIRER RESPONSE',
      data: {
        acquirerResponses: []
      }
    }]
  }, {
    id: '16058b14-5ab3-2c20-14g5-hb2ad3198g71',
    guid: '16058b14-5ab3-2c20-14g5-hb2ad3198g71',
    name: 'Template 2',
    product: 'internet-payments',
    options: [{
      id: 'PERIOD',
      data: {
        filterTrayCalendarEnd: '2024-02-05',
        filterTrayCalendarStart: '2023-11-07'
      }
    }, {
      id: 'STATUS',
      data: {
        status: ['SETTLED', 'UNSETTLED']
      }
    }, {
      id: 'RISK',
      data: {
        riskStatus: []
      }
    }, {
      id: 'TYPE',
      data: {
        type: []
      }
    }, {
      id: 'TRANSACTION AMOUNT',
      data: {}
    }, {
      id: 'RISK RULES',
      data: {
        riskRules: []
      }
    }, {
      id: 'EVENT STATUS',
      data: {
        eventStatus: []
      }
    }, {
      id: 'ACQUIRER RESPONSE',
      data: {
        acquirerResponses: []
      }
    }]
  }, {
    id: '04058a21-5ef2-4e20-90b4-ab6df6098g10',
    guid: '04058a21-5ef2-4e20-90b4-ab6df6098g10',
    name: 'Template 1',
    product: 'echo-pro',
    options: [{
      id: 'PERIOD',
      data: {
        filterTrayCalendarEnd: '2024-02-05',
        filterTrayCalendarStart: '2023-11-07'
      }
    }, {
      id: 'STATUS',
      data: {
        status: ['SETTLED', 'UNSETTLED']
      }
    }, {
      id: 'RISK',
      data: {
        riskStatus: []
      }
    }, {
      id: 'TYPE',
      data: {
        type: []
      }
    }, {
      id: 'TRANSACTION AMOUNT',
      data: {}
    }, {
      id: 'RISK RULES',
      data: {
        riskRules: []
      }
    }, {
      id: 'EVENT STATUS',
      data: {
        eventStatus: []
      }
    }, {
      id: 'ACQUIRER RESPONSE',
      data: {
        acquirerResponses: []
      }
    }]
  }, {
    id: '14058b24-6ab3-2c20-12g6-cb2ad3127g72',
    guid: '14058b24-6ab3-2c20-12g6-cb2ad3127g72',
    name: 'Template 2',
    product: 'echo-pro',
    options: [{
      id: 'PERIOD',
      data: {
        filterTrayCalendarEnd: '2024-02-05',
        filterTrayCalendarStart: '2023-11-07'
      }
    }, {
      id: 'STATUS',
      data: {
        status: ['SETTLED', 'UNSETTLED']
      }
    }, {
      id: 'RISK',
      data: {
        riskStatus: []
      }
    }, {
      id: 'TYPE',
      data: {
        type: []
      }
    }, {
      id: 'TRANSACTION AMOUNT',
      data: {}
    }, {
      id: 'RISK RULES',
      data: {
        riskRules: []
      }
    }, {
      id: 'EVENT STATUS',
      data: {
        eventStatus: []
      }
    }, {
      id: 'ACQUIRER RESPONSE',
      data: {
        acquirerResponses: []
      }
    }]
  }];
  var _default = FILTER_TEMPLATES_FIXTURE;
  _exports.default = _default;
});