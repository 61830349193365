define("lh4/utils/is-positive-decimal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * (?!0*(\.0+)?$) - disallows 0 on it's own but allows in front of full number, i.e. 0 is invalid, 0.3 is ok
   * (\d+|\d+[.,]\d{1,2}) - matches either integer (\d+) or a float with two decimals (\d+[.,]\d{1,2})
   */
  var POSITIVE_DECIMAL_REGEX = /^(?!0*(\.0+)?$)(\d+|\d+[.,]\d{1,2})$/;
  /**
   * (?!0*(\.0+)?$) - disallows 0 on it's own but allows in front of full number, i.e. 0 is invalid, 0.3 is ok
   * (\d+|\d+[.,]\d{1,3}) - matches either integer (\d+) or a float with three decimals (\d+[.,]\d{1,3})
   */

  var POSITIVE_3_DECIMAL_REGEX = /^(?!0*(\.0+)?$)(\d+|\d+[.,]\d{1,3})$/;
  /**
   * Validate if given value is positive decimal with precision of parameter passed in
   *
   * @param {string|number} value - value to validate
   * @param {number} precision - (Default value 2) value to calculate precision by
   * @returns {boolean} - true if value is decimal
   */

  var isPositiveDecimal = function isPositiveDecimal(value) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    return precision === 3 ? POSITIVE_3_DECIMAL_REGEX.test(value) : POSITIVE_DECIMAL_REGEX.test(value);
  };

  var _default = isPositiveDecimal;
  _exports.default = _default;
});