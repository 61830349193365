define("lh4/templates/components/reports/report-table-raw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MCo/Y+nZ",
    "block": "{\"symbols\":[\"row\",\"cell\",\"header\"],\"statements\":[[7,\"thead\",true],[8],[0,\"\\n\\t\"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"currentReport\",\"headers\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"th\",true],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"rows\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"td\",true],[8],[1,[23,2,[]],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[11,\"colspan\",[29,[[24,[\"currentReport\",\"headers\",\"length\"]]]]],[10,\"class\",\"no-data\"],[8],[0,\"No data to display.\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reports/report-table-raw.hbs"
    }
  });

  _exports.default = _default;
});