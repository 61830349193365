define("lh4/utils/transition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abortTransitionToTheSameRoute = abortTransitionToTheSameRoute;
  _exports.default = void 0;

  function abortTransitionToTheSameRoute(transition, shouldAbort) {
    if (transition.from && transition.to && transition.from.name === transition.to.name && shouldAbort) {
      // When a transition occurs because of a change in query parameters, transition.abort() in the
      // willTransition hook does not work
      // https://discuss.emberjs.com/t/transition-abort-with-query-params-does-not-abort-transition/8830.
      // Because of that, the transition is aborted in the beforeModel hook again.
      // Explanation:
      // First, the willTransition hook is called and transitionModalIsOpen is set to true. Then, in the same
      // hook, the transition is aborted—unsuccessfully. After that, because the transition happens to the same
      // route (as only the query parameters were changed), the beforeModel hook is called and the transition is
      // aborted again, this time successfully.
      transition.abort();
      return true;
    }

    return false;
  }

  var _default = abortTransitionToTheSameRoute;
  _exports.default = _default;
});