define("lh4/templates/components/ui/menu-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ByUxzHGd",
    "block": "{\"symbols\":[\"menuItem\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"menu-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"menuItems\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\",false],[12,\"data-test-ip\",[23,1,[\"buttonId\"]]],[12,\"disabled\",[23,1,[\"disabled\"]]],[12,\"class\",[29,[\"menu-item \",[28,\"if\",[[28,\"eq\",[[23,0,[\"selectedItem\"]],[23,1,[\"buttonId\"]]],null],\"menu-item-selected\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"onUpdateSelectedItem\",[23,1,[\"buttonId\"]]]],[8],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"button-name\"],[8],[1,[23,1,[\"buttonName\"]],false],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/menu-list.hbs"
    }
  });

  _exports.default = _default;
});