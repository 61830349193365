define("lh4/components/statements-picker", ["exports", "moment", "lh4/mixins/components/data-testable-component"], function (_exports, _moment, _dataTestableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dataTestableComponent.default, {
    dataTest: 'statements-picker',
    classNames: ['ui', 'calendar'],
    enabledDates: [],
    didInsertElement: function didInsertElement() {
      var that = this;
      var month = this.get('month');
      this.$().calendar({
        type: 'month',
        className: {
          cell: 'link item'
        },
        initialDate: month ? (0, _moment.default)(month, 'MMMM, YYYY').local().toDate() : null,
        isDisabled: function isDisabled(originalDate) {
          var date = (0, _moment.default)(originalDate).local().format('YYYYMM');
          return !that.get('enabledDates').any(function (enabled) {
            return parseInt(enabled, 10) === parseInt(date, 10);
          });
        },
        onChange: function onChange(originalDate) {
          var date = (0, _moment.default)(originalDate).local();
          that.set('month', date.format('MMMM, YYYY'));
          that.sendAction('onChange', date);
        }
      });
    }
  });

  _exports.default = _default;
});