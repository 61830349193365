define("lh4/models/reservations/section", ["exports", "ember-data", "ember-uuid", "ember-cp-validations"], function (_exports, _emberData, _emberUuid, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Section name',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    locationId: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    isActive: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    guid: Ember.computed('id', function () {
      return (0, _emberUuid.v4)();
    })
  });

  _exports.default = _default;
});