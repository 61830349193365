define("lh4/models/processing/batch", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // pK is BatchID
    MerchantId: _emberData.default.attr('number'),
    TransDate: _emberData.default.attr('date'),
    TotalAmt: _emberData.default.attr('number'),
    AmtVisa: _emberData.default.attr('number'),
    AmtMasterCard: _emberData.default.attr('number'),
    AmtAmex: _emberData.default.attr('number'),
    AmtDiscover: _emberData.default.attr('number'),
    AmtDebit: _emberData.default.attr('number'),
    AmtEBT: _emberData.default.attr('number'),
    AmtPrivateLabel: _emberData.default.attr('number'),
    AmtReturns: _emberData.default.attr('number'),
    AmtWrightExpress: _emberData.default.attr('number'),
    AmtVoyager: _emberData.default.attr('number'),
    AmtVisaReturns: _emberData.default.attr('number'),
    AmtMCReturns: _emberData.default.attr('number'),
    AmtAmexReturns: _emberData.default.attr('number'),
    AmtDiscoverReturns: _emberData.default.attr('number'),
    AmtDebitReturns: _emberData.default.attr('number'),
    AmtEBTReturns: _emberData.default.attr('number')
  });

  _exports.default = _default;
});