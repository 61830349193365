define("lh4/controllers/internet-payments/general-list-controller", ["exports", "lh4/enums/date-range-title", "moment", "lh4/enums/sorting-direction", "lh4/enums/ip-pagination-limit"], function (_exports, _dateRangeTitle, _moment, _sortingDirection, _ipPaginationLimit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_FORMAT = 'YYYY-MM-DD';

  var _default = Ember.Controller.extend({
    headers: [],
    media: Ember.inject.service(),
    session: Ember.inject.service(),
    maxDays: 1,
    rangeTitle: _dateRangeTitle.DATE_RANGE_TITLE.TODAY,
    ranges: [],
    errorMessage: null,
    maxDate: (0, _moment.default)().endOf('day'),
    offset: 0,
    limit: _ipPaginationLimit.default.LIMIT,
    searchTerm: null,
    sortDir: _sortingDirection.default.DESC,
    from: Ember.computed('start', function getter() {
      if (this.start) {
        return (0, _moment.default)(this.start, DATE_FORMAT).startOf('day');
      }

      return (0, _moment.default)().startOf('day');
    }),
    to: Ember.computed('end', function getter() {
      if (this.end) {
        return (0, _moment.default)(this.end, DATE_FORMAT).endOf('day');
      }

      return (0, _moment.default)().endOf('day');
    }),
    buttonTitle: Ember.computed(function getter() {
      return this.session.isSSO ? 'Disabled for Single Sign On' : '';
    }),
    updateDateParameters: function updateDateParameters(startMoment, endMoment) {
      this.setProperties({
        start: startMoment.format(DATE_FORMAT),
        end: endMoment.format(DATE_FORMAT),
        offset: 0
      });
    },
    actions: {
      onMobileRangeChange: function onMobileRangeChange(rangeTitle, initialStartDate, initialEndDate) {
        this.set('rangeTitle', rangeTitle);
        var startDate;
        var endDate;

        if (!initialStartDate) {
          startDate = (0, _moment.default)();
          endDate = (0, _moment.default)();
        } else if (initialStartDate > initialEndDate) {
          startDate = initialStartDate;
          endDate = initialStartDate.clone().add(this.maxDays, 'days');
        } else {
          startDate = initialStartDate;
          endDate = initialEndDate;
        }

        this.updateDateParameters(startDate, endDate);
      },
      onBeforeShow: function onBeforeShow() {
        document.querySelectorAll('body .internet-payments-wrapper .desktop-datepicker').forEach(function (e) {
          return e.classList.add('active');
        });
        document.querySelectorAll('body .s4cal-container.s4cal-popup').forEach(function (e) {
          return e.classList.add('desktop-calendar');
        });
      },
      onBeforeHide: function onBeforeHide() {
        document.querySelectorAll('body .internet-payments-wrapper .desktop-datepicker').forEach(function (e) {
          return e.classList.remove('active');
        });
        document.querySelectorAll('body .s4cal-container.s4cal-popup').forEach(function (e) {
          return e.classList.remove('desktop-calendar');
        });
      },
      onFirstSelect: function onFirstSelect(instance, firstDate) {
        // Since we allow the user to select either the beginning or the end
        // of the date at the beginning of their selection we'll limit both
        // min and max date so they can get no more than 14 days in their selection
        var maxDate = _moment.default.min((0, _moment.default)(), firstDate.clone().add(this.maxDays, 'days'));

        var minDate = firstDate.clone().subtract(this.maxDays, 'days');
        instance.setMinDate(minDate.startOf('day'));
        instance.setMaxDate(maxDate.endOf('day'));
      },
      onBeforeSelect: function onBeforeSelect(instance) {
        instance.setMinDate(null);
        instance.setMaxDate((0, _moment.default)().endOf('day'));
        return true;
      },
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        // Reset minDate and maxDate so a new selection can be made
        instance.setMinDate(null);
        instance.setMaxDate((0, _moment.default)().endOf('day'));
        this.updateDateParameters(startDate, endDate);
      },
      // calentim won't render without onRangeSelect
      onRangeSelect: function onRangeSelect(instance) {
        instance.setMinDate(null);
        instance.setMaxDate((0, _moment.default)().endOf('day'));
        instance.reDrawCalendars();
      },
      // TODO: LH-21801, extract or remove this copy pasta
      onDesktopBlur: function onDesktopBlur(_ref) {
        var view = _ref.view,
            target = _ref.target;
        var instance = view.$(target).data('calentim');
        var parts = target.value.split('-');
        var inputStart = (0, _moment.default)(parts[0].trim(), instance.options.format);
        var inputEnd = (0, _moment.default)(parts[1].trim(), instance.options.format); // validate dates entered and fallback to previous values if they're invalid

        var start = inputStart.isValid() ? inputStart.startOf('day') : instance.config.startDate;
        var end = inputEnd.isValid() ? inputEnd.endOf('day') : instance.config.endDate; // nothing changed, exit early

        if (start.isSame(instance.config.startDate) && end.isSame(instance.config.endDate)) {
          return instance.updateInput();
        }

        var maxDate = (0, _moment.default)().endOf('day'); // if start or end is after maxDate, set to today

        if (start.isAfter(maxDate)) {
          start = maxDate.clone().startOf('day');
        }

        if (end.isAfter(maxDate)) {
          end = maxDate;
        } // if start is after end, set start to the same day


        if (start.isAfter(end)) {
          start = end.clone().startOf('day');
        } // validate max report range


        var maxEnd = start.clone().add(this.maxDays, 'days');

        if (maxEnd.isAfter(maxDate)) {
          maxEnd = maxDate;
        }

        if (end.isAfter(maxEnd)) {
          end = maxEnd;
        } // remember ranges to check later if active range changed and deselect it


        var rangesBefore = JSON.stringify(instance.config.ranges); // update active ranges

        instance.config.ranges.forEach(function (aRange) {
          var range = aRange;
          range.selected = range.startDate.isSame(start, 'day') && range.endDate.isSame(end, 'day');
        });
        instance.setStart(start);
        instance.setEnd(end);
        instance.updateInput();
        this.updateDateParameters(start, end); // checks if current range changed and deselects
        // later is used, because user can blur by clicking on new range button

        Ember.run.later(function () {
          // ranges changed, unselect previous active range
          if (JSON.stringify(instance.config.ranges) !== rangesBefore) {
            instance.reDrawCalendars();
          }
        }, 200);
        return false;
      },
      onFilterChanged: function onFilterChanged(filter) {
        var limit = filter.limit,
            offset = filter.offset,
            sortDir = filter.sortDir,
            order = filter.sortBy;
        this.setProperties({
          limit: limit,
          offset: offset,
          order: order,
          sortDir: sortDir === _sortingDirection.default.ASC ? _sortingDirection.default.ASC : _sortingDirection.default.DESC
        });
      }
    }
  });

  _exports.default = _default;
});