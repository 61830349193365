define("lh4/enums/ip-export-formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EXPORT_FORMATS = Object.freeze({
    CSV: 'csv',
    EXCEL: 'xls',
    PDF: 'pdf'
  });
  var _default = EXPORT_FORMATS;
  _exports.default = _default;
});