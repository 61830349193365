define("lh4/models/echo-pro/time-clock-shift", ["exports", "ember-cp-validations", "ember-data", "ember-data/relationships", "lh4/enums/duration", "moment"], function (_exports, _emberCpValidations, _emberData, _relationships, _duration, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    clockedOutAt: {
      validators: [(0, _emberCpValidations.validator)('shift-dates')]
    },
    breakTotalSeconds: {
      description: 'Break Duration',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        gte: 0
      }), (0, _emberCpValidations.validator)('break-duration')]
    },
    jobGuid: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Job'
    }),
    tips: {
      description: 'Cash Tips',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        gte: 0,
        lte: 9999999.99
      })]
    },
    notes: {
      description: 'Notes',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    overtimeHourlyPayRate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Overtime Hourly Pay Rate'
    }),
    normalHourlyPayRate: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Normal Hourly Pay Rate'
    })
  });

  var getFormattedDate = function getFormattedDate(date) {
    // ember-pikaday component returns selected value in browser timezone but displays UTC value of date.
    // As we display everything in location timezone, we want just to show correct date in date picker field.
    // So picking up just date portion of date string and making new date with 12:00 AM time value
    // We don't care what time value is at this point because this shows only date part.
    // Correct time still sits in clockedInAt and clockedOutAt properties
    var valueDateString = (0, _moment.default)(date).format('YYYY-MM-DD');
    var newDate = (0, _moment.default)("".concat(valueDateString, " 12:00 AM"), 'YYYY-MM-DD hh:mm A');
    return date ? newDate.toISOString() : null;
  };

  var _default = _emberData.default.Model.extend(Validations, {
    locationId: _emberData.default.attr('number'),
    guid: _emberData.default.attr('string'),
    voided: _emberData.default.attr('boolean'),
    tips: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    notes: _emberData.default.attr('string'),
    registerId: _emberData.default.attr('number'),
    payRate: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    clockedInAt: _emberData.default.attr('string'),
    clockedOutAt: _emberData.default.attr('string'),
    breakTotalSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    overtimeCalculation: _emberData.default.attr('string'),
    overtimeThreshold: _emberData.default.attr('number'),
    payPeriodStartDay: _emberData.default.attr('string'),
    payPeriodStartedAt: _emberData.default.attr('string'),
    normalHourlyPayRate: _emberData.default.attr('number'),
    overtimeHourlyPayRate: _emberData.default.attr('number'),
    grossReceipts: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    doubleOvertimeTotalSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    standardOvertimeTotalSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    standardWorkTimeTotalSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    clockedInSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // this is total shift time
    clockedOutSeconds: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    createdAt: _emberData.default.attr('string'),
    updatedAt: _emberData.default.attr('string'),
    jobGuid: (0, _relationships.belongsTo)('echo-pro/job'),
    employeeGuid: (0, _relationships.belongsTo)('echo-pro/employee'),
    payPeriodGuid: _emberData.default.attr('string'),
    location: Ember.inject.service(),
    isLegacyRecord: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    breakIsPaid: Ember.computed({
      get: function get() {
        var shiftBreak = this.store.peekAll('echo-pro/time-clock-break').filterBy('timeClockShiftGuid', this.id).filterBy('voided', false)[0];

        if (shiftBreak) {
          return shiftBreak.isPaid;
        }

        return true;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    timeClockSegments: (0, _relationships.hasMany)('echo-pro/time-clock-segment'),
    addSegment: function addSegment() {
      return this.addItem('timeClockSegments', 'echo-pro/time-clock-segment', {
        isBreak: true
      });
    },
    addItem: function addItem(collectionName, modelName, options) {
      var data = {
        locationId: this.locationId,
        createdAt: new Date()
      };
      return this.store.createRecord(modelName, Object.assign(data, options));
    },
    formatTime: function formatTime(hours, minutes) {
      return this.location.model.features.newTimeClockManagement ? "".concat(hours ? "".concat(hours, " h") : '', " ").concat(minutes ? "".concat(minutes, " min") : '') : "".concat(hours ? "".concat(hours, " H") : '', " ").concat(minutes ? "".concat(minutes, " MIN") : '');
    },
    breakTotalSecondsV2: Ember.computed('clockedOutSeconds', 'breakTotalSeconds', function getter() {
      return this.payPeriodGuid ? this.clockedOutSeconds : this.breakTotalSeconds;
    }),
    breakTotalFormattedV2: Ember.computed('breakTotalSecondsV2', function getter() {
      var hours = Math.floor(this.breakTotalSecondsV2 / _duration.default.HOUR.IN_SECONDS);
      var minutes = Math.floor(this.breakTotalSecondsV2 % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS);
      return this.formatTime(hours, minutes);
    }),
    workHoursTotalFormattedV2: Ember.computed('clockedInSeconds', 'breakTotalSecondsV2', function getter() {
      var seconds = this.clockedInSeconds - this.breakTotalSecondsV2;
      var hours = Math.floor(seconds / _duration.default.HOUR.IN_SECONDS);
      var minutes = Math.floor(seconds % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS);
      return this.formatTime(hours, minutes);
    }),
    clockedInDate: Ember.computed('clockedInAt', function getter() {
      return getFormattedDate(this.clockedInAt);
    }),
    clockedInTime: Ember.computed('clockedInAt', {
      get: function get() {
        var clockedIn = this.clockedInAt;
        return clockedIn ? (0, _moment.default)(clockedIn).format('hh:mmA') : null;
      },
      set: function set(key, timeString) {
        var value = timeString || '12:00AM';
        var time = (0, _moment.default)(value, 'hh:mmA');
        var newDate = this.clockedInAt ? (0, _moment.default)(this.clockedInAt) : (0, _moment.default)();
        newDate.set({
          hours: time.format('HH'),
          minutes: time.format('mm')
        });
        this.set('clockedInAt', newDate.toISOString());
        return value;
      }
    }),
    clockedInValue: Ember.computed('clockedInAt', function getter() {
      var clockedIn = this.clockedInAt;
      return clockedIn ? (0, _moment.default)(clockedIn).format('MM/DD/YYYY hh:mm A') : null;
    }),
    clockedOutDate: Ember.computed('clockedOutAt', function getter() {
      return getFormattedDate(this.clockedOutAt);
    }),
    clockedOutTime: Ember.computed('clockedOutAt', {
      get: function get() {
        var clockedOut = this.clockedOutAt;
        return clockedOut ? (0, _moment.default)(clockedOut).format('hh:mmA') : null;
      },
      set: function set(key, timeString) {
        var value = timeString || '12:00AM';
        var time = (0, _moment.default)(value, 'hh:mmA');
        var newDate = this.clockedOutAt ? (0, _moment.default)(this.clockedOutAt) : (0, _moment.default)();
        newDate.set({
          hours: time.format('HH'),
          minutes: time.format('mm')
        });
        this.set('clockedOutAt', newDate.toISOString());
        return value;
      }
    }),
    clockedOutValue: Ember.computed('clockedOutAt', function getter() {
      var clockedOut = this.clockedOutAt;
      return clockedOut ? (0, _moment.default)(clockedOut).format('MM/DD/YYYY hh:mm A') : null;
    }),
    breakTotalFormatted: Ember.computed('breakTotalSeconds', function getter() {
      var hours = Math.floor(this.breakTotalSeconds / _duration.default.HOUR.IN_SECONDS);
      var minutes = Math.floor(this.breakTotalSeconds % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS);
      return this.formatTime(hours, minutes);
    }),
    breakHours: Ember.computed('breakTotalSeconds', {
      get: function get() {
        var breakTotal = this.breakTotalSeconds;
        return Math.floor(breakTotal / _duration.default.HOUR.IN_SECONDS);
      },
      set: function set(key, value) {
        var minutes = this.breakMinutes;
        this.set('breakTotalSeconds', value * _duration.default.HOUR.IN_SECONDS + minutes * _duration.default.MINUTE.IN_SECONDS);
        return value;
      }
    }),
    breakMinutes: Ember.computed('breakTotalSeconds', {
      get: function get() {
        var breakTotal = this.breakTotalSeconds;
        return Math.floor(breakTotal % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS);
      },
      set: function set(key, value) {
        var hours = this.breakHours;
        this.set('breakTotalSeconds', hours * _duration.default.HOUR.IN_SECONDS + value * _duration.default.MINUTE.IN_SECONDS);
        return value;
      }
    }),
    totalComputed: Ember.computed('clockedInAt', 'clockedOutAt', 'breakTotalSeconds', 'breakIsPaid', function getter() {
      var clockedIn = (0, _moment.default)(this.clockedInAt).set({
        seconds: 0,
        milliseconds: 0
      });
      var clockedOut = (0, _moment.default)(this.clockedOutAt).set({
        seconds: 0,
        milliseconds: 0
      });
      var breakDuration = this.breakTotalSeconds;

      var total = _moment.default.duration();

      if (this.clockedOutAt) {
        total = _moment.default.duration(clockedOut.diff(clockedIn));

        if (!this.breakIsPaid) {
          total = total.subtract(breakDuration, 'seconds');
        }
      }

      var hours = Math.floor(total.asHours());
      var minutes = total.minutes();
      return hours >= 0 ? "".concat(hours ? "".concat(hours, " HR") : '', " ").concat(minutes ? "".concat(minutes, " MIN") : '') : '';
    }),
    tipsText: Ember.computed('tips', {
      get: function get() {
        var tips = this.tips || 0;
        return parseFloat(tips).toFixed(2);
      },
      set: function set(key, value) {
        this.set('tips', value);
        return value;
      }
    }),
    workedHoursTotalFormatted: Ember.computed('standardWorkTimeTotalSeconds', function getter() {
      var formatSeconds = (0, _moment.default)().hour(0).minutes(0).seconds(this.standardWorkTimeTotalSeconds).toISOString();
      var totalSeconds = (0, _moment.default)(formatSeconds);
      var total = totalSeconds.second() >= 31 ? totalSeconds.add(1, 'minute').startOf('minute') : totalSeconds.startOf('minute');
      var hours = parseInt(total.format('H'), 10);
      var minutes = parseInt(total.format('m'), 10);
      return this.formatTime(hours, minutes);
    }),
    standardHours: Ember.computed('standardWorkTimeTotalSeconds', {
      get: function get() {
        var standardTotal = this.standardWorkTimeTotalSeconds;
        return Math.floor(standardTotal / _duration.default.HOUR.IN_SECONDS);
      },
      set: function set(key, value) {
        var minutes = this.standardMinutes;
        this.set('standardWorkTimeTotalSeconds', value * _duration.default.HOUR.IN_SECONDS + minutes * _duration.default.MINUTE.IN_SECONDS);
        return value;
      }
    }),
    standardMinutes: Ember.computed('standardWorkTimeTotalSeconds', {
      get: function get() {
        var standardTotal = this.standardWorkTimeTotalSeconds;
        return Math.floor(standardTotal % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS);
      },
      set: function set(key, value) {
        var hours = this.standardHours;
        this.set('standardWorkTimeTotalSeconds', hours * _duration.default.HOUR.IN_SECONDS + value * _duration.default.MINUTE.IN_SECONDS);
        return value;
      }
    }),
    otHoursTotalFormatted: Ember.computed('standardOvertimeTotalSeconds', function getter() {
      var duration = this.standardOvertimeTotalSeconds;
      var hours = Math.floor(duration / _duration.default.HOUR.IN_SECONDS);
      var minutes = Math.floor(duration % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS);
      return "".concat(hours ? "".concat(hours, " HR") : '', " ").concat(minutes ? "".concat(minutes, " MIN") : '');
    }),
    otHours: Ember.computed('standardOvertimeTotalSeconds', {
      get: function get() {
        var standardTotal = this.standardOvertimeTotalSeconds;
        return Math.floor(standardTotal / _duration.default.HOUR.IN_SECONDS);
      },
      set: function set(key, value) {
        var minutes = this.otMinutes;
        this.set('standardOvertimeTotalSeconds', value * _duration.default.HOUR.IN_SECONDS + minutes * _duration.default.MINUTE.IN_SECONDS);
        return value;
      }
    }),
    otMinutes: Ember.computed('standardOvertimeTotalSeconds', {
      get: function get() {
        var standardTotal = this.standardOvertimeTotalSeconds;
        return Math.floor(standardTotal % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS);
      },
      set: function set(key, value) {
        var hours = this.otHours;
        this.set('standardOvertimeTotalSeconds', hours * _duration.default.HOUR.IN_SECONDS + value * _duration.default.MINUTE.IN_SECONDS);
        return value;
      }
    }),
    dotHoursTotalFormatted: Ember.computed('doubleOvertimeTotalSeconds', function getter() {
      var duration = this.doubleOvertimeTotalSeconds;
      var hours = Math.floor(duration / _duration.default.HOUR.IN_SECONDS);
      var minutes = Math.floor(duration % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS);
      return "".concat(hours ? "".concat(hours, " HR") : '', " ").concat(minutes ? "".concat(minutes, " MIN") : '');
    }),
    dotHours: Ember.computed('doubleOvertimeTotalSeconds', {
      get: function get() {
        var standardTotal = this.doubleOvertimeTotalSeconds;
        return Math.floor(standardTotal / _duration.default.HOUR.IN_SECONDS);
      },
      set: function set(key, value) {
        var minutes = this.dotMinutes;
        this.set('doubleOvertimeTotalSeconds', value * _duration.default.HOUR.IN_SECONDS + minutes * _duration.default.MINUTE.IN_SECONDS);
        return value;
      }
    }),
    dotMinutes: Ember.computed('doubleOvertimeTotalSeconds', {
      get: function get() {
        var standardTotal = this.doubleOvertimeTotalSeconds;
        return Math.floor(standardTotal % _duration.default.HOUR.IN_SECONDS / _duration.default.MINUTE.IN_SECONDS);
      },
      set: function set(key, value) {
        var hours = this.dotHours;
        this.set('doubleOvertimeTotalSeconds', hours * _duration.default.HOUR.IN_SECONDS + value * _duration.default.MINUTE.IN_SECONDS);
        return value;
      }
    })
  });

  _exports.default = _default;
});