define("lh4/components/reports/hospitality/financial-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reportTables: Ember.computed('currentReport', function getter() {
      return this.get('currentReport').reports.filter(function (x) {
        return x.name !== 'Statistics' && x.name !== 'Cash Summary';
      });
    }),
    showAll: false,
    actions: {
      beforePrint: function beforePrint() {
        this.toggleProperty('showAll');
      },
      afterPrint: function afterPrint() {
        this.toggleProperty('showAll');
      }
    }
  });

  _exports.default = _default;
});