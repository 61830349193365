define("lh4/mirage/routes/reservations", ["exports", "lh4/mirage/fixtures/reservations/settings", "lh4/mirage/fixtures/reservations/reservations"], function (_exports, _settings, _reservations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageReservationsServer;

  function setMirageReservationsServer(server) {
    // Reservations
    server.get('/reservations/sections', function (schema, request) {
      var record = {
        sections: schema.reservationsSections.where({
          locationId: request.queryParams.location
        }).models
      };
      return record;
    });
    server.get('/reservations/table-layout', function () {
      return {
        tableLayouts: [],
        meta: {
          count: 0
        }
      };
    });
    server.get('/reservations/settings', function () {
      return _settings.RESERVATIONS_SETTINGS;
    });
    server.get('/reservations', function () {
      return _reservations.RESERVATIONS;
    });
    server.get('/reservations/:guid', function (_schema, request) {
      var reservation = _reservations.RESERVATIONS.reservations.find(function (it) {
        return it.guid === request.params.guid;
      });

      return {
        reservation: reservation,
        meta: {
          count: 1
        }
      };
    });
  }
});