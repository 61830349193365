define("lh4/models/support/comment", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    comment: {
      description: 'comment',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 10000
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    comment: _emberData.default.attr('string'),
    author: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    // Used for building URL in the adapter.
    locationId: _emberData.default.attr('number'),
    ticketId: _emberData.default.attr('number')
  });

  _exports.default = _default;
});