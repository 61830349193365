define("lh4/routes/authenticated/reputation/reviews/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    location: Ember.inject.service(),
    model: function model(params) {
      var parentModel = this.modelFor('authenticated.reputation.reviews');
      return Ember.RSVP.hash({
        selectedLocation: this.location.loadLocation(params.locationId),
        accounts: parentModel.accounts
      });
    }
  });

  _exports.default = _default;
});