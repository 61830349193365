define("lh4/templates/components/ui/icons/internet-payments/check-wrong", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yTWnXUMA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"width\",\"10\"],[10,\"height\",\"10\"],[10,\"viewBox\",\"0 0 10 10\"],[10,\"fill\",\"none\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M9.66671 1.27301L8.72671 0.333008L5.00004 4.05967L1.27337 0.333008L0.333374 1.27301L4.06004 4.99967L0.333374 8.72634L1.27337 9.66634L5.00004 5.93967L8.72671 9.66634L9.66671 8.72634L5.94004 4.99967L9.66671 1.27301Z\"],[10,\"fill\",\"#FAFAFA\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/internet-payments/check-wrong.hbs"
    }
  });

  _exports.default = _default;
});