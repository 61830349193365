define("lh4/utils/has-many-ids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getHasManyIds;

  /**
   * Returns an object with a getter/setter used to return an array of
   * ids representing classes associate with a store. (Used to work around
   * bugs related to changesets directly altering relationships)
   * When calling the setter the ids in the array will be mapped to the
   * appropriate store and associate the correct records
   *
   * @method getHasManyIds
   * @param {string} dependentKey hasMany property we're mapping over
   * @param {string} modelName name of the hasMany property store
   * @returns {Object} hasManyIds
   */
  function getHasManyIds(dependentKey, modelName) {
    return {
      get: function get() {
        return this[dependentKey].mapBy('id');
      },
      set: function set(key, values) {
        var _this = this;

        var records = [];
        values.forEach(function (id) {
          var record = _this.store.peekRecord(modelName, id);

          if (record) {
            records.push(record);
          }
        });
        this.set(dependentKey, records);
        return values;
      }
    };
  }
});