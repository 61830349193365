define("lh4/templates/components/dashboard/data-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kGJ/sFOq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui segments dash-data\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui segment\"],[8],[0,\"\\n\"],[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"color-box\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"i\",true],[11,\"class\",[29,[[22,\"icon\"]]]],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"],[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"dash-title\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"chartName\"],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"dash-label\"],[8],[1,[22,\"detailsLabel\"],false],[9],[0,\"\\n\"],[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"dash-dd\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\",false],[12,\"class\",[29,[[22,\"toggleIcon\"],\" large icon\"]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"tabSelection\"]],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"ui segment \",[28,\"if\",[[24,[\"loading\"]],\"loading\"],null]]]],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"loading\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"showChartOrTable\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"dashboard/dashboard-table\",null,[[\"showMerchantId\",\"tableName\",\"data\",\"singleLocation\"],[true,[24,[\"chartName\"]],[24,[\"cleanData\"]],[24,[\"singleLocation\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"fin-overview-charts\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"amcharts3/column-chart\",null,[[\"data\",\"options\"],[[24,[\"cleanData\"]],[24,[\"chartOptions\"]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/dashboard/data-dash.hbs"
    }
  });

  _exports.default = _default;
});