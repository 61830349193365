define("lh4/routes/authenticated/loyalty/programs/create", ["exports", "lh4/routes/general-create-route"], function (_exports, _generalCreateRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalCreateRoute.default.extend({
    modelName: 'program',
    permissions: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        program: this.store.createRecord('loyalty/program'),
        locations: this.store.peekAll('location').filter(function (location) {
          return _this.permissions.hasProduct('echo-pro', location);
        }),
        programs: this.store.query('loyalty/program', {
          limit: 'all'
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Loyalty Programs Update Started',
        'Entry Type': 'New'
      });
    }
  });

  _exports.default = _default;
});