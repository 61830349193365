define("lh4/mirage/fixtures/processing-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PROCESSING_REPORT = {
    trees: [{
      isExpandable: true,
      level: 0,
      nodeType: 'header',
      parentGuid: null,
      children: [{
        isExpandable: false,
        level: 1,
        nodeType: 'settlement',
        parentGuid: 'c8329f10-dbfb-541b-bc7b-30b0a787c775',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'US',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 4619,
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '12/11/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'settled',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'USD',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 4141507.48,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: -82224.61,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4059282.87',
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 30316,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4619',
            dataType: 'string'
          }],
          type: 'data'
        }],
        guid: '5d50d3f1-3b89-530b-9c1b-cfee162ffbc6'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'settlement',
        parentGuid: 'c8329f10-dbfb-541b-bc7b-30b0a787c775',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'US',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 4619,
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '12/11/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'refunded',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'USD',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: -9874.01,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 0,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '-9874.01',
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 48,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4619',
            dataType: 'string'
          }],
          type: 'data'
        }],
        guid: 'aef9c8a9-7296-510b-b5aa-226c28b97ca4'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'settlement',
        parentGuid: 'c8329f10-dbfb-541b-bc7b-30b0a787c775',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'US',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 4619,
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '12/11/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'chargeback',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'USD',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: -49898.08,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 0,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '-49898.08',
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 76,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4619',
            dataType: 'string'
          }],
          type: 'data'
        }],
        guid: '88eab621-768d-5255-b679-2d72bf3689d1'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'settlement',
        parentGuid: 'c8329f10-dbfb-541b-bc7b-30b0a787c775',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'US',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 4619,
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '12/11/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'payout',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'USD',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 0,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 0,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '(3999510.78)',
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 0,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4619',
            dataType: 'string'
          }],
          type: 'data'
        }],
        guid: '158a600f-04a7-505a-b503-b3edd2933379'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'settlement',
        parentGuid: 'c8329f10-dbfb-541b-bc7b-30b0a787c775',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'US',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 4621,
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '12/13/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'settled',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'USD',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 3160996.35,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: -62716.86,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '3098279.49',
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 23341,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4621',
            dataType: 'string'
          }],
          type: 'data'
        }],
        guid: 'adfd5a71-920d-5f93-80a0-335ea3d69eaf'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'settlement',
        parentGuid: 'c8329f10-dbfb-541b-bc7b-30b0a787c775',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'US',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 4621,
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '12/13/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'refunded',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'USD',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: -47443.53,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 0,
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '-47443.53',
            dataType: 'float'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 170,
            dataType: 'integer'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4621',
            dataType: 'string'
          }],
          type: 'data'
        }],
        guid: 'e163ddf2-f823-5fbc-99b3-dec8c0e1b5c4'
      }],
      rows: [{
        cols: [{
          colSpan: 1,
          rowSpan: 1,
          value: 'Country',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Batch Number',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Batch Closed Date',
          dataType: 'date',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Transaction Type',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Deposit Currency',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Gross Amount',
          dataType: 'float',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Bank Fees',
          dataType: 'float',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Net Amount',
          dataType: 'float',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Transaction Count',
          dataType: 'integer',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Sub-Batch',
          dataType: 'string',
          columnType: 'header'
        }],
        type: 'header'
      }],
      guid: 'c8329f10-dbfb-541b-bc7b-30b0a787c775'
    }],
    title: 'Settlement Report',
    start: '2023-12-01T00:00:00.000+00:00',
    end: '2023-12-31T23:59:59.999+00:00',
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: 24
    }
  };
  var _default = PROCESSING_REPORT;
  _exports.default = _default;
});