define("lh4/templates/components/customer-engagement/campaign-edit-wizard/email-template-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c8J+Il9V",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"template\"],true],[0,\"\\n\"],[7,\"input\",true],[10,\"style\",\"display: none;\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/customer-engagement/campaign-edit-wizard/email-template-editor.hbs"
    }
  });

  _exports.default = _default;
});