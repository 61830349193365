define("lh4/serializers/internet-payments/users/configurable-column", ["exports", "lh4/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    modelNameFromPayloadKey: function modelNameFromPayloadKey() {
      return 'internet-payments/users/configurable-column';
    }
  });

  _exports.default = _default;
});