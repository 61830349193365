define("lh4/models/echo-pro/advantage/setting", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'current.advantageProgramPercent': {
      description: 'Advantage Program Rate',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        gte: 0,
        lte: 5,
        message: 'Advantage Program Rate should be a number from 0 to 5'
      })]
    },
    'current.options.tipReductionPercent': {
      description: 'Tip Reduction Rate',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true,
        gte: 0,
        message: 'Tip Reduction Rate should be a number'
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    mid: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    current: _emberData.default.attr(),
    pending: _emberData.default.attr()
  });

  _exports.default = _default;
});