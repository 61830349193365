define("lh4/routes/authenticated/virtual-terminal/transactions", ["exports", "moment", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _moment, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_lighthousePermissionsMixin.default, {
    titleToken: 'Virtual Terminal',
    ajax: Ember.inject.service(),
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    session: Ember.inject.service(),
    redirect: function redirect() {
      if (this.can.cannot('view transactions in general')) {
        this.transitionTo('/');
      }
    },
    model: function model(params) {
      if (this.location.model.usesShift4PaymentProcessor) {
        var locationId = this.location.model.id;
        var searchTerm = params.searchTerm;
        var start = params.start,
            end = params.end;

        if (!start) {
          start = (0, _moment.default)().subtract(1, 'days').format('YYYY-MM-DD');
        }

        if (!end) {
          end = (0, _moment.default)().format('YYYY-MM-DD');
        }

        var transactionParams = {
          start: start,
          end: end,
          searchTerm: searchTerm,
          locationId: locationId
        };
        return Ember.RSVP.hash({
          origin: this.modelFor('authenticated.virtual-terminal').origin,
          refundSettings: this.modelFor('authenticated.virtual-terminal').refundSettings,
          transactions: this.store.query('virtual-terminal/transaction', transactionParams)
        });
      }

      return this.transitionTo('authenticated.dashboard2');
    },
    actions: {
      generateNewSearch: function generateNewSearch() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});