define("lh4/components/dashboard-widgets/daily-top-items/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['daily-top-items-settings'],
    types: [{
      value: 'by-sales',
      name: 'By Sales'
    }, {
      value: 'by-count',
      name: 'By Count'
    }]
  });

  _exports.default = _default;
});