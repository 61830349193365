define("lh4/components/modal/route-modal", ["exports", "semantic-ui-ember/components/ui-modal"], function (_exports, _uiModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var linkElements = function linkElements(source, target, forward) {
    source.on('keydown', function (e) {
      var keyCode = e.keyCode || e.which;
      var shouldLink = forward ? forward && !e.shiftKey : e.shiftKey;

      if (keyCode === 9 && shouldLink) {
        e.preventDefault();
        target.focus();
      }
    });
  };

  var _default = _uiModal.default.extend({
    classNames: ['ui', 'modal', 'route-modal'],
    init: function init() {
      this._super.apply(this, arguments);

      this.attrs.autofocus = this.attrs.autofocus || false;
      this.attrs.closable = this.attrs.closable || false;
      this.attrs.observeChanges = this.attrs.observeChanges || true;
      this.attrs.duration = this.attrs.duration || 0;
      this.attrs.centered = this.attrs.centered || false;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$().modal('show');
      this.createCycleTabNavigation();
    },
    willDestroyElement: function willDestroyElement() {
      this.$().modal('hide');

      this._super.apply(this, arguments);
    },
    createCycleTabNavigation: function createCycleTabNavigation() {
      var elements = this.getFocusableElements();

      if (elements.length < 1) {
        return;
      }

      var first = this.$(elements[0]);
      var last = this.$(elements[elements.length - 1]);
      linkElements(first, last, false);
      linkElements(last, first, true);
    },
    getFocusableElements: function getFocusableElements() {
      return this.$().find('a, button, :input, [tabindex]');
    },
    onDeny: function onDeny() {
      return false;
    },
    actions: {
      questionDecline: function questionDecline() {
        this.sendAction('questionDecline');
      },
      questionConfirm: function questionConfirm() {
        this.sendAction('questionConfirm');
      }
    }
  });

  _exports.default = _default;
});