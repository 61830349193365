define("lh4/models/solo/settings", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "lh4/utils/is-positive-decimal-or-zero", "lh4/utils/is-positive-percentage-or-zero"], function (_exports, _model, _attr, _emberCpValidations, _isPositiveDecimalOrZero, _isPositivePercentageOrZero) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var positiveValueMessage = 'Value must be a positive number with no more than two decimal places.';
  var outOfRangeMessage = 'Provided value is out of range';
  var LARGEST_INT4_VALUE = 2147483647;
  var dollarValidators = [(0, _emberCpValidations.validator)('number', {
    allowString: true,
    gte: 0,
    message: positiveValueMessage
  }), (0, _emberCpValidations.validator)(function (value) {
    return (0, _isPositiveDecimalOrZero.default)(value) || positiveValueMessage;
  }), (0, _emberCpValidations.validator)(function (value) {
    return value <= LARGEST_INT4_VALUE || outOfRangeMessage;
  })];
  var tipThresholdValidators = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    disabled: Ember.computed('model.{isTippingEnabled,isTipThresholdEnabled}', function isDisabled() {
      return !this.get('model.isTippingEnabled') || !this.get('model.isTipThresholdEnabled');
    })
  }), (0, _emberCpValidations.validator)('number', {
    allowString: true,
    gte: 0,
    disabled: Ember.computed('model.{isTippingEnabled,isTipThresholdEnabled}', function isDisabled() {
      return !this.get('model.isTippingEnabled') || !this.get('model.isTipThresholdEnabled');
    })
  })];
  var Validations = (0, _emberCpValidations.buildValidations)({
    businessPhone: {
      description: 'Phone',
      validators: [(0, _emberCpValidations.validator)('phone-number', {
        message: 'Phone number has incorrect format',
        allowBlank: true
      })]
    },
    fastPayOne: [].concat(dollarValidators),
    fastPayTwo: [].concat(dollarValidators),
    fastPayThree: [].concat(dollarValidators),
    offlineAmountLimit: [].concat(dollarValidators),
    nccDescription: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 24
    })],
    nccPercentage: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value) {
      return (0, _isPositivePercentageOrZero.default)(value);
    })],
    defaultTipEnabled: [(0, _emberCpValidations.validator)('presence', true)],
    tipPercentageOptionOne: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.{isTippingEnabled,defaultTipEnabled}', function isDisabled() {
        return !this.get('model.isTippingEnabled') || !this.get('model.defaultTipEnabled');
      })
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 0,
      lte: 100,
      disabled: Ember.computed('model.{isTippingEnabled,defaultTipEnabled}', function isDisabled() {
        return !this.get('model.isTippingEnabled') || !this.get('model.defaultTipEnabled');
      })
    })],
    tipPercentageOptionTwo: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.{isTippingEnabled,defaultTipEnabled}', function isDisabled() {
        return !this.get('model.isTippingEnabled') || !this.get('model.defaultTipEnabled');
      })
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 0,
      lte: 100,
      disabled: Ember.computed('model.{isTippingEnabled,defaultTipEnabled}', function isDisabled() {
        return !this.get('model.isTippingEnabled') || !this.get('model.defaultTipEnabled');
      })
    })],
    tipPercentageOptionThree: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.{isTippingEnabled,defaultTipEnabled}', function isDisabled() {
        return !this.get('model.isTippingEnabled') || !this.get('model.defaultTipEnabled');
      })
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 0,
      lte: 100,
      disabled: Ember.computed('model.{isTippingEnabled,defaultTipEnabled}', function isDisabled() {
        return !this.get('model.isTippingEnabled') || !this.get('model.defaultTipEnabled');
      })
    })],
    tipDefaultOption: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.defaultTipEnabled')
    })],
    isTipThresholdEnabled: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isTippingEnabled')
    })],
    tipThresholdAmount: [].concat(dollarValidators, [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.{defaultTipEnabled,isTipThresholdEnabled}', function isDisabled() {
        return !this.get('model.defaultTipEnabled') || !this.get('model.isTipThresholdEnabled');
      })
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      message: positiveValueMessage,
      disabled: Ember.computed('model.{defaultTipEnabled,isTipThresholdEnabled}', function isDisabled() {
        return !this.get('model.defaultTipEnabled') || !this.get('model.isTipThresholdEnabled');
      })
    })]),
    tipThresholdOptionOne: [].concat(dollarValidators, tipThresholdValidators),
    tipThresholdOptionTwo: [].concat(dollarValidators, tipThresholdValidators),
    tipThresholdOptionThree: [].concat(dollarValidators, tipThresholdValidators),
    supplementalFeeDescription: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.supplementalFeeEnabled')
    }), (0, _emberCpValidations.validator)('length', {
      max: 24
    })],
    supplementalFeePercentage: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.supplementalFeeEnabled')
    }), (0, _emberCpValidations.validator)(function (value, _options, model) {
      return (0, _isPositivePercentageOrZero.default)(value, 2, !model.get('supplementalFeeEnabled'));
    })],
    cashDiscountPercentage: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.cashDiscountEnabled')
    }), (0, _emberCpValidations.validator)(function (value, _options, model) {
      return (0, _isPositivePercentageOrZero.default)(value, 2, !model.get('cashDiscountEnabled'));
    })],
    dualPricingPercentage: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.dualPricingEnabled')
    }), (0, _emberCpValidations.validator)(function (value, _options, model) {
      return (0, _isPositivePercentageOrZero.default)(value, 2, !model.get('dualPricingEnabled'));
    })]
  });

  var _default = _model.default.extend(Validations, {
    fastPayOne: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    fastPayTwo: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    fastPayThree: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    acceptingOfflinePaymentsEnabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    creditCardEnabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    pinDebitEnabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    cashEnabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    tipAboveOfflineLimitEnabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    offlineAmountLimit: (0, _attr.default)('number', {
      defaultValue: 100.00
    }),
    nccEnabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    nccDescription: (0, _attr.default)('string', {
      defaultValue: 'Non-Cash Charge'
    }),
    nccPercentage: (0, _attr.default)('number', {
      defaultValue: 0.00
    }),
    isTippingEnabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    defaultTipEnabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    tipDefaultOption: (0, _attr.default)('string', {
      defaultValue: 'tipPercentageOptionTwo'
    }),
    tipPercentageOptionOne: (0, _attr.default)('number', {
      defaultValue: 18.00
    }),
    tipPercentageOptionTwo: (0, _attr.default)('number', {
      defaultValue: 20.00
    }),
    tipPercentageOptionThree: (0, _attr.default)('number', {
      defaultValue: 25.00
    }),
    isTipThresholdEnabled: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    tipThresholdOptionOne: (0, _attr.default)('number', {
      defaultValue: 1.00
    }),
    tipThresholdOptionTwo: (0, _attr.default)('number', {
      defaultValue: 2.00
    }),
    tipThresholdOptionThree: (0, _attr.default)('number', {
      defaultValue: 3.00
    }),
    tipThresholdAmount: (0, _attr.default)('number', {
      defaultValue: 10.00
    }),
    supplementalFeeEnabled: (0, _attr.default)('boolean'),
    supplementalFeeDescription: (0, _attr.default)('string'),
    supplementalFeePercentage: (0, _attr.default)('number'),
    cashDiscountEnabled: (0, _attr.default)('boolean'),
    cashDiscountPercentage: (0, _attr.default)('number'),
    dualPricingEnabled: (0, _attr.default)('boolean'),
    dualPricingPercentage: (0, _attr.default)('number', {
      defaultValue: 4
    }),
    isPrintReceiptDisplayTipLineEnabled: (0, _attr.default)('boolean'),
    isPrintReceiptDisplaySignatureLineEnabled: (0, _attr.default)('boolean'),
    isPrintTwoReceiptsEnabled: (0, _attr.default)('boolean'),
    isEmployeeLoginEnabled: (0, _attr.default)('boolean'),
    addressLine: (0, _attr.default)('string'),
    businessName: (0, _attr.default)('string'),
    businessPhone: (0, _attr.default)('phone'),
    payCustomAmountEnabled: (0, _attr.default)('boolean'),
    isQuickMenuEnabled: (0, _attr.default)('boolean'),
    isIDCheckEnabled: (0, _attr.default)('boolean'),
    isGiftCardEnabled: (0, _attr.default)('boolean'),
    isPinProtectEnabled: (0, _attr.default)('boolean'),
    kioskModeEnabled: (0, _attr.default)('boolean'),
    logoUrl: (0, _attr.default)('string')
  });

  _exports.default = _default;
});