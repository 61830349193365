define("lh4/mirage/factories/hospitality-item-group", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    locationId: function locationId(i) {
      return i;
    },
    priceCategoryId: function priceCategoryId(i) {
      return i;
    },
    description: '',
    dollarAmount: 0.00,
    priceCategoryOrdinal: 0,
    courseGroupId: 0,
    preferredCourseId: 0,
    exportId: null,
    rvPriceCategory: [],
    rowguid: function rowguid() {
      return _faker.default.datatype.uuid();
    },
    menuItems: []
  });

  _exports.default = _default;
});