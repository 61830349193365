define("lh4/routes/authenticated/epro/menu/pricing-table/edit", ["exports", "lh4/routes/general-edit-route", "lh4/utils/currency/converter"], function (_exports, _generalEditRoute, _converter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _generalEditRoute.default.extend({
    metrics: Ember.inject.service(),
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    modelName: 'pricingTable',
    childChangesets: {
      priceOverrides: 'priceOverrideChangesets'
    },
    convertRecordToModel: function convertRecordToModel(record) {
      var _this = this;

      var _this$store$peekRecor = this.store.peekRecord('echo-pro/department', record.departmentGuid),
          name = _this$store$peekRecor.name;

      var corporateGroupItemOverrides = (record.corporateGroupItemOverrides || []).map(function (o) {
        return _this.store.createRecord('corporate-group-item-override', o);
      });
      return this.store.createRecord('echo-pro/menu/pricing-table', _objectSpread(_objectSpread({}, record), {}, {
        itemGuid: record.guid,
        defaultPrice: record.price,
        departmentGuid: record.departmentGuid,
        departmentName: name,
        corporateGroupItemOverrides: corporateGroupItemOverrides
      }));
    },
    model: function model(_ref) {
      var _this2 = this;

      var itemGuid = _ref.itemGuid;
      var corporation = this.location.model.corpId;
      var queryParams = {
        limit: 'all',
        order: 'name',
        sort: 'asc'
      };
      return this.ajax.request("/api/v1/corporations/".concat(corporation, "/overrides/items/").concat(itemGuid, "/price")).then(function (record) {
        return Ember.RSVP.hash({
          pricingTable: _this2.convertRecordToModel(record),
          corporateGroups: _this2.store.query('corporate-group', _objectSpread(_objectSpread({}, queryParams), {}, {
            corporation: corporation
          }))
        });
      });
    },
    afterModel: function afterModel(model) {
      var _this3 = this;

      var item = model.pricingTable;
      var priceOverrides = model.corporateGroups.map(function (group) {
        var priceOverrideRecord = item.corporateGroupItemOverrides.find(function (o) {
          return o.property === 'price' && +o.corporateGroupId === +group.id;
        });

        if (priceOverrideRecord) {
          priceOverrideRecord.setProperties({
            corporateGroupName: group.name,
            value: (0, _converter.formatCurrency)(priceOverrideRecord.value)
          });
          return priceOverrideRecord;
        }

        return _this3.store.createRecord('corporate-group-item-override', {
          itemGuid: item.itemGuid,
          corpId: group.corpId,
          corporateGroupId: group.id,
          corporateGroupName: group.name,
          property: 'price',
          value: null
        });
      });
      item.set('priceOverrides', priceOverrides);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.store.unloadAll('echo-pro/menu/pricing-table');
      this.store.unloadAll('corporate-group-item-override');
    },
    redirect: function redirect() {// Do nothing as GeneralEditRoute's redirect looks at locationId
      // that doesn't exist in the price groups
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Menu Update Started',
        'Entry Type': 'Edit',
        'Entry ID': model.pricingTable.itemGuid,
        'Menu Component': 'Pricing Table'
      });
    }
  });

  _exports.default = _default;
});