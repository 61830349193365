define("lh4/controllers/authenticated/epro/accounting/taxes/create", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _generalFormController.default.extend({
    corporation: Ember.inject.service(),
    flashMessagesHelper: Ember.inject.service(),
    flashMessages: null,
    validateAndSaveChangeset: function validateAndSaveChangeset(changeset, key) {
      var _this = this;

      return changeset.map(function (record) {
        return record.validate().then(function () {
          if (record.isValid) {
            record.set('tax', _this.model.tax);
            record.execute();
            record.rollback();

            _this.model.tax.get(key).pushObject(record.data);

            return Ember.RSVP.resolve(true);
          }

          return Ember.RSVP.reject(record.get('errors'));
        }).catch(function (validations) {
          if (validations.length) {
            validations.forEach(function (v) {
              _this.flashMessagesHelper.pushMessage(v.validation, 'error');
            });
          }
        });
      });
    },
    areChildrenPristine: function areChildrenPristine(model, changeset) {
      // check for any records added or removed from the array
      if (model.filter(function (record) {
        return !record.isNew;
      }).length !== changeset.length) {
        return false;
      }

      return changeset.every(function (c) {
        return c.isPristine;
      });
    },
    removeRecordsFromModel: function removeRecordsFromModel(model, changeset) {
      model.filter(function (_ref) {
        var id = _ref.id;
        return !changeset.findBy('id', id);
      }).forEach(function (record) {
        return model.removeObject(record);
      });
    },
    actions: {
      createTax: function createTax() {
        var _this2 = this;

        if (this.isSaving) {
          return;
        }

        this.flashMessagesHelper.clearMessages();
        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError(); // we have to check for pristine before we validate and save

        var isPristine = !this.model.tax.isDirty && this.changeset.get('isPristine') && this.areChildrenPristine(this.model.tax.taxRules, this.changeset.taxRuleChangesets) && this.areChildrenPristine(this.model.tax.itemTaxMap, this.changeset.itemTaxMapChangesets) && this.areChildrenPristine(this.model.tax.taxSetTaxMap, this.changeset.taxSetTaxMapChangesets);
        Ember.RSVP.all([].concat(_toConsumableArray(this.validateAndSaveChangeset(this.changeset.taxRuleChangesets, 'taxRules')), _toConsumableArray(this.validateAndSaveChangeset(this.changeset.itemTaxMapChangesets, 'itemTaxMap')), _toConsumableArray(this.validateAndSaveChangeset(this.changeset.taxSetTaxMapChangesets, 'taxSetTaxMap')))).then(function (values) {
          var areChildrenValid = values.every(function (value) {
            return value;
          });
          return _this2.changeset.validate().then(function () {
            if (!(_this2.changeset.get('isValid') && areChildrenValid)) {
              return Ember.RSVP.reject(_this2.changeset.get('errors'));
            }

            _this2.removeRecordsFromModel(_this2.model.tax.taxRules, _this2.changeset.taxRuleChangesets);

            _this2.removeRecordsFromModel(_this2.model.tax.itemTaxMap, _this2.changeset.itemTaxMapChangesets);

            _this2.removeRecordsFromModel(_this2.model.tax.taxSetTaxMap, _this2.changeset.taxSetTaxMapChangesets);

            if (isPristine) {
              _this2.clearTemporaryData();

              _this2.closeModal();

              return Ember.RSVP.resolve();
            }

            if (_this2.changeset.get('isInvalid')) {
              return Ember.RSVP.reject(_this2.changeset.get('errors'));
            }

            return _this2.changeset.save().then(function () {
              _this2.clearTemporaryData();

              _this2.closeModal();
            });
          });
        }).catch(function (err) {
          var errors = err.length ? err : err.errors;

          if (errors && errors.length) {
            errors.forEach(function (error) {
              _this2.flashMessagesHelper.pushMessage(error.validation || error.message, 'error');
            });
          }
        }).finally(function () {
          return _this2.set('isSaving', false);
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      }
    }
  });

  _exports.default = _default;
});