define("lh4/constants/launch-control/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PROGRESS_CIRCUMFERENCE = _exports.MODULE = _exports.CONFIRMATION_MODAL_TEXT = _exports.ERRORS = void 0;
  var ERRORS = Object.freeze({
    serverError: 'An error occured on our side, please try again later.'
  });
  _exports.ERRORS = ERRORS;
  var CONFIRMATION_MODAL_TEXT = Object.freeze({
    save: 'SAVE',
    dontSave: 'DON\'T SAVE',
    exit: 'EXIT',
    cancel: 'CANCEL',
    saveSetupText: 'Do you want to save changes?',
    exitSetupText: 'Do you want to exit?',
    saveTitle: 'Save Changes',
    exitTitle: 'Exit Setup',
    yes: 'YES',
    subText: 'You can set this up later at the end.',
    skipModuleTitle: 'Are you sure?',
    skipTextSTO: 'Restaurants that sign up for Online Ordering could typically see 20% boost in revenue.',
    skipTextIntegrations: 'Restaurants that sign up for Integrations typically see 10% to 20% boost in revenue.',
    skipTextGiftCards: 'Restaurants that sign up for Gift Cards typically see 10% to 30% boost in revenue.',
    skipTextLoyalty: 'Restaurants that sign up for Loyalty typically see 5% to 20% boost in revenue.',
    skipTextSkytabBi: 'Restaurants that sign up for Skytab BI typically see 20% to 30% boost in revenue.',
    skipTextTableLayout: 'Restaurants that sign up for Table Layout typically see 10% to 15% boost in revenue.',
    skipTextWebsiteBuilder: 'Restaurants that sign up for Website Builder typically see 15% to 25% boost in revenue.'
  });
  _exports.CONFIRMATION_MODAL_TEXT = CONFIRMATION_MODAL_TEXT;
  var MODULE = Object.freeze({
    WEBSITE_BUILDER: 'Website',
    SKYTAB_BI: 'Business Intelligence'
  });
  _exports.MODULE = MODULE;
  var PROGRESS_CIRCUMFERENCE = 439.6;
  _exports.PROGRESS_CIRCUMFERENCE = PROGRESS_CIRCUMFERENCE;
});