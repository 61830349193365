define("lh4/components/internet-payments/ui/event-list", ["exports", "lh4/enums/ip-dispute-statuses", "lh4/enums/ip-dispute-case-types"], function (_exports, _ipDisputeStatuses, _ipDisputeCaseTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    get disputeStatus() {
      return _ipDisputeStatuses.DISPUTE_STATUS;
    },

    get status() {
      return _ipDisputeStatuses.DISPUTE_STATUS_DISPLAY;
    },

    get disputeCaseType() {
      return _ipDisputeCaseTypes.DISPUTE_CASE_TYPE;
    },

    get caseType() {
      return _ipDisputeCaseTypes.DISPUTE_CASE_TYPE_DISPLAY;
    }

  });

  _exports.default = _default;
});