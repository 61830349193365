define("lh4/helpers/short-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shortNumber = shortNumber;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Converts number to a shorter, human-readable notation
   * @param {Number} value - number to be converted
   * @returns {String} - number representation using trillions, billions, etc. notations
   */
  function shortNumber(_ref
  /* , hash */
  ) {
    var _ref2 = _slicedToArray(_ref, 1),
        value = _ref2[0];

    // stolen from https://github.com/cfj/short-number/blob/master/index.js
    var num = value;

    if (typeof num !== 'number') {
      num = Number(num);
    }

    if (Number.isNaN(num)) {
      num = 0;
    }

    if (num > 1e19) {
      num = 0;
    }

    if (num < -1e19) {
      num = 0;
    }

    if (Math.abs(num) < 1000) {
      return num;
    }

    var sign = num < 0 ? '-' : '';
    var suffixes = {
      K: 6,
      M: 9,
      B: 12,
      T: 16
    };
    num = Math.abs(num);
    var size = Math.floor(num).toString().length;
    var exponent = size % 3 === 0 ? size - 3 : size - size % 3;
    var short = Math.round(10 * (num / Math.pow(10, exponent))) / 10;
    var suffix = Object.keys(suffixes).find(function (s) {
      return exponent < suffixes[s];
    });
    return sign + short + suffix;
  }

  var _default = Ember.Helper.helper(shortNumber);

  _exports.default = _default;
});