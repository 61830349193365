define("lh4/components/ui/checkbox-unescaped", ["exports", "semantic-ui-ember/components/ui-checkbox"], function (_exports, _uiCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Only different template is used to display unescaped labels (ie, for search highlights)
  var _default = _uiCheckbox.default.extend({
    click: function click(event) {
      if (this.attrs.onClick) {
        this.attrs.onClick(event);
      }
    }
  });

  _exports.default = _default;
});