define("lh4/components/social-media/account-facebook-page-edit", ["exports", "lh4/config/environment", "lh4/services/social-media/facebook"], function (_exports, _environment, _facebook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ENV: _environment.default,
    tagName: '',
    facebook: Ember.inject.service('social-media/facebook'),
    isLoading: null,
    isReputation: false,

    /**
     * @param {object} - social media account
     */
    account: null,

    /**
     * @param {Array<object>} - facebook pages
     */
    pages: [],
    initialExternalId: Ember.computed('account', function getter() {
      return this.get('account.externalId');
    }),
    availablePages: Ember.computed('pages.[]', function getter() {
      if (!this.get('pages')) {
        return [];
      }

      return this.get('pages').filter(function (page) {
        return page.linkStatus === _facebook.FB_PAGE_STATUS_CAN_LINK;
      });
    }),
    noPagesSelected: Ember.computed('account.externalId', 'availablePages.[]', function getter() {
      var pageId = this.get('account.externalId');

      if (!pageId) {
        return true;
      }

      var selectedPage = this.get('availablePages').find(function (p) {
        return p.id === pageId;
      });
      return !selectedPage;
    }),
    headerRouteName: Ember.computed('isReputation', function getter() {
      return this.get('isReputation') ? 'reputation/account-header' : 'social-media/account-header';
    }),
    disabledPages: Ember.computed('pages.[]', function getter() {
      if (!this.get('pages')) {
        return [];
      }

      return this.get('pages').filter(function (page) {
        return page.linkStatus !== _facebook.FB_PAGE_STATUS_CAN_LINK;
      });
    }),
    actions: {
      /**
       * Relink account or page with bad linkStatus
       * @param {Object} page - page from list returned by `/social-media/accounts/{account.id}/options`
       */
      relinkAccount: function relinkAccount(page) {
        this.set('isLoading', true);
        this.facebook.redirectToAccountRelink(this.get('account'), page, this.get('isReputation'));
      },

      /**
       * In case not all facebook user pages are available
       * user that granted partial permissions might give account wide perm, but leave out specific page
       */
      rerequestAllScopes: function rerequestAllScopes() {
        this.set('isLoading', true);
        this.facebook.redirectToAccountRererequest(this.get('account'), this.get('isReputation'));
      }
    }
  });

  _exports.default = _default;
});