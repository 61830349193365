define("lh4/components/ui/tree/checkbox-node", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['checkbox-node'],
    actions: {
      selectNode: function selectNode() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        // action is called recursively, so always take only first callers arguments
        // to bubble only single arguments object to parent components action
        // seems like all options to prevent event bubbling is not working with recursive components
        var _ref = [].concat(args),
            node = _ref[0],
            value = _ref[1];

        var nextArgs = typeof value === 'boolean' ? {
          node: node,
          value: value
        } : [].concat(args).pop();
        this.onCheck(nextArgs);
      },
      toggleIsExpanded: function toggleIsExpanded() {
        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        // action is called recursively, so always take only first callers arguments
        // to bubble only single arguments object to parent components action
        // seems like all options to prevent event bubbling is not working with recursive components
        this.onToggleIsExpanded([].concat(args).pop());
      }
    }
  });

  _exports.default = _default;
});