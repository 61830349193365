define("lh4/serializers/location", ["exports", "lh4/services/permissions", "lh4/serializers/application"], function (_exports, _permissions, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    phoneNumber: Ember.inject.service(),
    primaryKey: 'id',
    normalize: function normalize(modelClass, jsonToNormalize) {
      var _json$services,
          _this = this;

      var json = jsonToNormalize;
      var loyaltyLocationService = (_json$services = json.services) === null || _json$services === void 0 ? void 0 : _json$services.find(function (_ref) {
        var serviceId = _ref.serviceId;
        return serviceId === _permissions.services['lighthouse-loyalty'];
      });

      if (loyaltyLocationService) {
        var _loyaltyLocationServi;

        json.loyaltyProgram = (_loyaltyLocationServi = loyaltyLocationService.configuration) === null || _loyaltyLocationServi === void 0 ? void 0 : _loyaltyLocationServi.loyaltyProgramGuid.toLowerCase();
      }

      if (jsonToNormalize.services && Array.isArray(jsonToNormalize.services)) {
        jsonToNormalize.services.forEach(function (iter) {
          if (iter.locationService && iter.locationService.configuration && iter.locationService.configuration.decorations && iter.locationService.configuration.decorations.phone) {
            // eslint-disable-next-line no-param-reassign
            iter.locationService.configuration.decorations.phone = _this.phoneNumber.formatPhoneNumber(iter.locationService.configuration.decorations.phone);
          }
        });
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    }
  });

  _exports.default = _default;
});