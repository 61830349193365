define("lh4/components/dashboard-widgets/labor-statistics/data", ["exports", "lh4/mixins/dashboard-widget-mixin"], function (_exports, _dashboardWidgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dashboardWidgetMixin.default, {
    classNames: ['labor-statistics-data', 'table-widget'],
    skeletonData: {
      hours: 84,
      laborPct: 10,
      costPerGuest: 8.40,
      cost: 840
    }
  });

  _exports.default = _default;
});