define("lh4/templates/terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xhmRk9fC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"terms\"],[8],[0,\"\\n\\t\"],[7,\"iframe\",true],[10,\"class\",\"terms-frame\"],[10,\"src\",\"/website-terms-of-use.pdf\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/terms.hbs"
    }
  });

  _exports.default = _default;
});