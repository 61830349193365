define("lh4/templates/components/ui/icons/sidebar/card-membership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDxnlzS5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M4.00098 13V15H20.001V13H4.00098ZM4.00098 2H20.001C20.551 2 21.022 2.19567 21.414 2.587C21.8053 2.979 22.001 3.45 22.001 4V15C22.001 15.55 21.8053 16.021 21.414 16.413C21.022 16.8043 20.551 17 20.001 17H16.001V22L12.001 20L8.00098 22V17H4.00098C3.45098 17 2.98031 16.8043 2.58898 16.413C2.19698 16.021 2.00098 15.55 2.00098 15V4C2.00098 3.45 2.19698 2.979 2.58898 2.587C2.98031 2.19567 3.45098 2 4.00098 2ZM4.00098 10H20.001V4H4.00098V10Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.87\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/sidebar/card-membership.hbs"
    }
  });

  _exports.default = _default;
});