define("lh4/templates/authenticated/reputation/reviews/locations/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AtV51nWy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[8],[0,\"Account - \"],[1,[24,[\"model\",\"activeAccount\",\"name\"]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"activeAccount\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"reputation/rating-history-chart\",null,[[\"account\"],[[24,[\"model\",\"activeAccount\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"ui clearing basic segment\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[22,\"ui/loading-segment\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"reputation/reviews-table\",null,[[\"account\",\"reviewData\"],[[24,[\"model\",\"activeAccount\"]],[24,[\"model\",\"reviewData\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"activeAccountIsPageable\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui right floated basic segment review-pagination-segment\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"ui/client-pagination-buttons-prev-next\",null,[[\"hasPrevPage\",\"hasNextPage\",\"onSelectPreviousPage\",\"onSelectNextPage\"],[[24,[\"model\",\"reviewData\",\"meta\",\"pagination\",\"before\"]],[24,[\"model\",\"reviewData\",\"meta\",\"pagination\",\"after\"]],[28,\"action\",[[23,0,[]],\"selectPreviousPage\"],null],[28,\"action\",[[23,0,[]],\"selectNextPage\"],null]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/reputation/reviews/locations/account.hbs"
    }
  });

  _exports.default = _default;
});