define("lh4/templates/components/internet-payments/ui/button/list-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NjKU/ZKB",
    "block": "{\"symbols\":[\"@listLength\",\"@label\",\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"list-button\"],[12,\"data-test\",\"list-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"on\",[\"click\",[23,0,[\"onPrimaryAction\"]]]],[8],[0,\"\\n\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"label \",[28,\"if\",[[28,\"gt\",[[23,1,[]],0],null],\"hasList\"],null]]]],[8],[1,[23,2,[]],false],[9],[0,\"\\n\\t\"],[14,3],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"chevron right icon\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/button/list-button.hbs"
    }
  });

  _exports.default = _default;
});