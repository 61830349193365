define("lh4/templates/components/reputation/review-view-source-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ll8MUAYC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isLinkSupported\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"ui grid view-source\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isFbPage\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"button\",false],[12,\"class\",[29,[\"ui \",[28,\"if\",[[24,[\"isLoading\"]],\"loading\"],null],\" button left floated reasonably-wide\"]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectPost\"]],[8],[0,\"\\n\\t\\t\\t\\tComment\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"span\",false],[12,\"class\",[29,[\"ui \",[22,\"additionalClasses\"]]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"viewReviewSource\",[24,[\"review\"]]],[[\"bubbles\"],[false]]],[8],[0,\"\\n\\t\\t\\t\\tView on Facebook\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isYelp\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",false],[12,\"class\",[29,[\"ui \",[22,\"additionalClasses\"]]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"viewReviewSource\",[24,[\"review\"]]],[[\"bubbles\"],[false]]],[8],[0,\"\\n\\t\\t\\t\\tView on Yelp\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",false],[12,\"class\",[29,[\"ui \",[22,\"additionalClasses\"]]]],[12,\"role\",\"button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"viewReviewSource\",[24,[\"review\"]]],[[\"bubbles\"],[false]]],[8],[0,\"\\n\\t\\t\\t\\tView Source\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/reputation/review-view-source-button.hbs"
    }
  });

  _exports.default = _default;
});