define("lh4/serializers/echo-pro/accounting/application", ["exports", "ember-data", "lh4/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'guid',
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return this._super(payloadKey.replace("".concat(payloadKey), "echo-pro/accounting/".concat(payloadKey)));
    }
  });

  _exports.default = _default;
});