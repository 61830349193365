define("lh4/routes/authenticated/deposit-breakdown", ["exports", "lh4/enums/date-range-title", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "moment"], function (_exports, _dateRangeTitle, _authenticatedRouteMixin, _mobileScrollRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    location: Ember.inject.service(),
    reportApi: Ember.inject.service('reports/report-api'),
    router: Ember.inject.service(),
    titleToken: 'Deposit Breakdown',
    model: function model() {
      var currentRoute = this.router.currentRoute;

      var _currentRoute$queryPa = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.queryParams,
          from = _currentRoute$queryPa.from,
          to = _currentRoute$queryPa.to;

      var start = from ? (0, _moment.default)(from) : (0, _moment.default)().startOf('week');
      var end = to ? (0, _moment.default)(to) : (0, _moment.default)();
      var rangeTitle = !to && !from ? _dateRangeTitle.DATE_RANGE_TITLE.WEEK.TO_DATE : _dateRangeTitle.DATE_RANGE_TITLE.CUSTOM;
      return Ember.RSVP.hash({
        from: start,
        location: this.location.model,
        rangeTitle: rangeTitle,
        to: end
      });
    },
    redirect: function redirect(model) {
      var product = model.location.get('product');

      if (!this.reportApi.conditions.canViewTransactionExplorer(product)) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      controller.set('from', model.from);
      controller.set('rangeTitle', model.rangeTitle);
      controller.set('to', model.to);
    }
  });

  _exports.default = _default;
});