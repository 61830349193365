define("lh4/mirage/routes/echo-pro/external-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageEchoProExternalOrdersServer;

  function setMirageEchoProExternalOrdersServer(server) {
    server.get('/echo-pro/external-orders/:locationId', function (_, request) {
      var _request$queryParams = request.queryParams,
          limit = _request$queryParams.limit,
          offset = _request$queryParams.offset,
          order = _request$queryParams.order,
          searchName = _request$queryParams.searchName,
          sort = _request$queryParams.sort;
      return {
        externalOrderSources: [{
          origin: 'doordash',
          name: 'DoorDash',
          logoUrl: 'https://lighthouse-image-uploads.s3.amazonaws.com/marketplace/Menu_manager_items/Doordash.svg'
        }, {
          origin: 'olo-operational-eta',
          name: 'Online Ordering #2',
          logoUrl: null
        }],
        meta: {
          limit: limit,
          offset: offset,
          order: order,
          searchName: searchName,
          sort: sort
        }
      };
    });
  }
});