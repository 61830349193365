define("lh4/services/ajax-download", ["exports", "lh4/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    host: _environment.default.host,
    session: Ember.inject.service(),
    headers: Ember.computed('session.data', 'session.isAuthenticated', 'session.ownerToken', {
      get: function get() {
        var headers = {};

        if (this.session.isAuthenticated) {
          headers['X-Access-Token'] = this.session.data.authenticated.token;
        }

        if (this.session.ownerToken) {
          headers['x-verified-owner-token'] = this.session.ownerToken;
        }

        return headers;
      }
    }),
    request: function request(url, defaultFileName, onError, onSuccess) {
      var suppressHeaders = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var responseType = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 'blob';
      var onComplete = arguments.length > 6 ? arguments[6] : undefined;

      // I would have preferred to use AjaxService (ember-ajax) but it does not appear that the current version
      // supports the xhrField responseType parameter
      _jquery.default.ajax(url, {
        type: 'GET',
        headers: suppressHeaders ? null : this.headers,
        xhrFields: {
          responseType: responseType
        },
        success: function success(data, status, xhr) {
          if (status === 'success') {
            var disposition = xhr.getResponseHeader('content-disposition');
            var filename = disposition ? disposition.split('filename=')[1].replace(/['"]+/g, '') : defaultFileName;
            var blob = new Blob([data]);
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = filename;
            a.click();

            if (onSuccess) {
              onSuccess(filename);
            }

            window.URL.revokeObjectURL(blob);
          } else if (onError) {
            onError(new Error('Request did not return export data'));
          }
        },
        error: function error(xhr) {
          if (onError) {
            onError(xhr);
          }
        },
        complete: function complete() {
          if (onComplete) {
            onComplete();
          }
        }
      });
    }
  });

  _exports.default = _default;
});