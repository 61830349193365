define("lh4/templates/components/internet-payments/ui/range-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XPP0dGCG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"range-indicator\"],[10,\"ip-data-test\",\"range-indicator\"],[8],[0,\"\\n\\t\"],[7,\"span\",true],[8],[1,[23,0,[\"args\",\"value\"]],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"indicator\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"range \",[22,\"riskRange\"]]]],[10,\"ip-data-test\",\"indicator-slider\"],[8],[0,\" \"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/ui/range-indicator.hbs"
    }
  });

  _exports.default = _default;
});