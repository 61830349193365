define("lh4/enums/report-type-slug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var REPORT_TYPE_SLUG = {
    FUNDS_IN_TRANSIT: 'funds-in-transit',
    TRANSACTION: 'transaction',
    RISK: 'risk'
  };
  var _default = REPORT_TYPE_SLUG;
  _exports.default = _default;
});