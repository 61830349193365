define("lh4/components/ui/buttons/form-confirmation-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-confirmation-buttons'],
    actions: {
      clone: function clone() {
        this.sendAction('clone');
      },
      discard: function discard() {
        this.sendAction('discard');
      },
      save: function save() {
        this.sendAction('save');
      }
    }
  });

  _exports.default = _default;
});