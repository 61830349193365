define("lh4/models/bank-account/credential", ["exports", "ember-data", "ember-data/attr", "ember-cp-validations"], function (_exports, _emberData, _attr, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Name',
      disabled: Ember.computed('model.rfiType', function getter() {
        return this.model.rfiType !== 'contact-info';
      }),
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      })]
    },
    phone: {
      description: 'Phone number',
      disabled: Ember.computed('model.rfiType', function getter() {
        return this.model.rfiType !== 'contact-info';
      }),
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('phone-number', {
        message: 'Phone number has incorrect format'
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    changeTracker: {
      auto: false
    },
    name: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    context: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),
    rfiType: (0, _attr.default)('string'),
    salt: (0, _attr.default)('string')
  });

  _exports.default = _default;
});