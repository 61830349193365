define("lh4/mirage/fixtures/internet-payments/customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CUSTOMERS_RESPONSE_FIXTURE = {
    customers: [{
      id: 'token',
      nameList: ['SDK TestName', 'cardholder test', 'stone sommers'],
      emailList: ['sdkTest@kountsdktestdomain.com', 'test@credorax.com'],
      cardNumber: '0015',
      cardNetwork: 'visa',
      cardToken: 'token',
      customerId: 1,
      createDate: '2022-08-30T10:15:21.000Z',
      totalSpent: '14125.16'
    }, {
      id: 'token-1',
      nameList: ['SDK TestName', 'stone sommers'],
      emailCount: 2,
      emailList: ['sdkTest@kountsdktestdomain.com', 'test@credorax.com'],
      cardNumber: '0015',
      cardNetwork: 'mastercard',
      cardToken: 'token-1',
      customerId: 2,
      createDate: '2022-08-30T10:15:21.000Z',
      totalSpent: '1234.56'
    }, {
      id: 'token-2',
      nameList: ['SDK TestName', 'stone sommers'],
      emailList: ['sdkTest@kountsdktestdomain.com', 'test@credorax.com'],
      cardNumber: '0015',
      cardNetwork: 'mastercard',
      cardToken: 'token-2',
      customerId: 3,
      createDate: '2022-08-30T10:15:21.000Z',
      totalSpent: '1234.56'
    }, {
      id: 'token-3',
      nameList: ['SDK TestName', 'stone sommers'],
      emailCount: 4,
      emailList: ['approve@test.com', 'decline@test.com', 'review@test.com', 'random@test.com'],
      cardNumber: '0015',
      cardNetwork: 'mastercard',
      cardToken: 'token-3',
      customerId: 4,
      createDate: '2022-08-30T10:15:21.000Z',
      totalSpent: '1234.56'
    }]
  };
  var _default = CUSTOMERS_RESPONSE_FIXTURE;
  _exports.default = _default;
});