define("lh4/controllers/authenticated/loyalty/promotions", ["exports", "lh4/controllers/general-list-controller", "lh4/mixins/list-status-options"], function (_exports, _generalListController, _listStatusOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend(_listStatusOptions.default, {
    location: Ember.inject.service(),
    queryParams: ['loyaltyProgramGuid', 'offset', 'limit', 'order', 'sort'],
    headers: [{
      name: 'Name',
      field: 'name',
      width: 'five',
      sortable: true
    }, {
      name: 'Program',
      field: 'loyaltyProgramName',
      width: 'five',
      sortable: true
    }, {
      name: 'Type',
      field: 'type',
      width: 'two',
      sortable: true
    }, {
      name: 'Value',
      field: 'value',
      width: 'two',
      sortable: true,
      type: 'number',
      aligned: 'right'
    }, {
      name: 'Status',
      field: 'isActive',
      width: 'two',
      sortable: true,
      type: 'active'
    }],
    init: function init() {
      this._super.apply(this, arguments);

      this.locationChanged();
    },
    locationChanged: Ember.observer('location.model', function locationChanged() {
      var _this$location, _this$location$model;

      this._super((_this$location = this.location) === null || _this$location === void 0 ? void 0 : (_this$location$model = _this$location.model) === null || _this$location$model === void 0 ? void 0 : _this$location$model.id);
    }),
    selectionAllItemName: 'All Programs',
    loyaltyProgramGuid: ['all'],
    programSortOptions: ['isActive:desc', 'name:asc'],
    sortedPrograms: Ember.computed.sort('model.programs', 'programSortOptions'),
    programs: Ember.computed('sortedPrograms', function getter() {
      var _this = this;

      return this.sortedPrograms.map(function (_ref) {
        var id = _ref.guid,
            name = _ref.name;
        return {
          id: id,
          name: name,
          isChecked: _this.loyaltyProgramGuid.includes(id) || _this.loyaltyProgramGuid.includes('all')
        };
      });
    }),
    actions: {
      onSelectionChange: function onSelectionChange(loyaltyProgramGuid) {
        this.set('loyaltyProgramGuid', loyaltyProgramGuid); // hacky fix since ember does not serialize default value in url

        if (!loyaltyProgramGuid.length) {
          this.set('loyaltyProgramGuid', ['none']);
        } else if (loyaltyProgramGuid.length === this.programs.length) {
          this.set('loyaltyProgramGuid', ['all']);
        }
      }
    }
  });

  _exports.default = _default;
});