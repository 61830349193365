define("lh4/components/tables/transaction-table", ["exports", "lh4/components/tables/show-more-table"], function (_exports, _showMoreTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _showMoreTable.default.extend();

  _exports.default = _default;
});