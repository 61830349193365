define("lh4/serializers/echo-pro/deploy", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'guid',
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, primaryModelClass, payload) {
      return this.normalize(primaryModelClass, payload);
    }
  });

  _exports.default = _default;
});