define("lh4/components/ui/buttons/step-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['steps-container'],
    onStepSelect: function onStepSelect() {},
    steps: Ember.computed('count', function getter() {
      return Array(this.get('count')).fill().map(function (e, i) {
        return i;
      });
    }),
    actions: {
      selectStep: function selectStep(step) {
        this.set('current', step);
        this.onStepSelect(step);
      }
    }
  });

  _exports.default = _default;
});