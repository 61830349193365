define("lh4/mirage/routes/corporate/location-maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageCorporateLocationMapsServer;

  function setMirageCorporateLocationMapsServer(server) {
    server.get('/corporations/:corpId/groups/location-maps', function (schema, request) {
      return {
        corporateGroupLocationMap: schema.corporateGroupLocationMaps.where({
          locationId: request.queryParams.location
        }).models
      };
    });
    server.get('/corporations/:corpId/groups/location-maps/:guid', function (schema, request) {
      return {
        corporateGroupLocationMap: schema.corporateGroupLocationMaps.where({
          guid: request.params.guid
        }).models
      };
    });
    server.put('/corporations/:corpId/groups/location-maps/:guid', function (_ref, _ref2) {
      var corporateGroupLocationMaps = _ref.corporateGroupLocationMaps,
          corporateGroups = _ref.corporateGroups;
      var requestBody = _ref2.requestBody;

      var _JSON$parse = JSON.parse(requestBody),
          locationId = _JSON$parse.locationId,
          corporateGroupId = _JSON$parse.corporateGroupId;

      var corpGroup = corporateGroups.findBy({
        id: corporateGroupId
      });
      var groupMap = corporateGroupLocationMaps.findBy({
        locationId: locationId
      });
      groupMap.update({
        corporateGroup: {
          corpId: corpGroup.corpId,
          id: Number(corpGroup.id),
          name: corpGroup.name
        },
        corporateGroupId: corporateGroupId
      });
      var result = {
        corporateGroupLocationMap: corporateGroupLocationMaps.findBy({
          locationId: locationId
        }).toJSON()
      };
      delete result.corporateGroupLocationMap.corporateGroup;
      return result;
    });
    server.delete('/corporations/:corpId/groups/location-maps/:guid', function (_ref3, _ref4) {
      var corporateGroupLocationMaps = _ref3.corporateGroupLocationMaps;
      var params = _ref4.params;
      var groupMap = corporateGroupLocationMaps.findBy({
        guid: params.guid
      });
      groupMap.destroy();
      return {};
    });
  }
});