define("lh4/utils/set-reports-default-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setReportsDefaultLocation;

  /**
   * Set default location for reports controllers
   *
   * @param {Object} controller - controller params
   * @param {number} currentLocationId - current location id
   * @returns {Array<string>} - available location's id
   */
  function setReportsDefaultLocation(controller, currentLocationId) {
    var allowedLocations = controller.allowedLocations,
        selectedLocations = controller.selectedLocations;

    if (allowedLocations.length === 1) {
      controller.set('selectedLocations', [allowedLocations.firstObject.id]);
    } else if (selectedLocations.length === 0) {
      var _allowedLocations$fir;

      var currentLocation = allowedLocations.find(function (location) {
        return location.id === currentLocationId;
      });
      var defaultLocationId = (currentLocation === null || currentLocation === void 0 ? void 0 : currentLocation.id) || ((_allowedLocations$fir = allowedLocations.firstObject) === null || _allowedLocations$fir === void 0 ? void 0 : _allowedLocations$fir.id);
      controller.set('selectedLocations', defaultLocationId ? [defaultLocationId] : []);
    } else {
      var _currentLocation = allowedLocations.find(function (location) {
        return location.id === currentLocationId;
      });

      controller.set('selectedLocations', [_currentLocation ? _currentLocation.id : allowedLocations.firstObject.id]);
    }
  }
});