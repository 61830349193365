define("lh4/mirage/routes/internet-payments/risk/risk-rules", ["exports", "lh4/mirage/fixtures/internet-payments/risk/risk-rules-list", "lh4/mirage/fixtures/internet-payments/risk/risk-rule"], function (_exports, _riskRulesList, _riskRule) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsRiskRulesServer;

  function setMirageInternetPaymentsRiskRulesServer(server) {
    server.get('/internet-payments/risk-rules/:locationId', function (db, request) {
      return request.params.locationId !== '22' ? _riskRulesList.default : [];
    });
    server.get('/internet-payments/risk-rules/:locationId/:ruleGuid', function (db, request) {
      return _riskRule.default[request.params.ruleGuid];
    });
  }
});